// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from './models';

import { loadPreviousAppointments, UserAppointment } from '../api/Users/Appointments';

type Params = {
  userId: number;
  providerId: number;
};

type Error = {

};

type Data = {
  results: UserAppointment[];
};

type State = {
  loading: boolean;
  byProvider: {
    [key: number]: ({
      previousAppointments: Data;
    } | {
      error: Error;
    } | undefined);
  };
};

const model = createModel<RootModel>()({
  name: 'previousAppointments',

  state: {
    loading: false,
    byProvider: {},
  } as State,

  reducers: {
    onLoaded(
      state: State,
      { params, previousAppointments }: { params: Params; previousAppointments: Data },
    ): State {
      return {
        ...state,
        loading: false,
        byProvider: {
          ...state.byProvider,
          [params.providerId]: {
            previousAppointments,
          },
        },
      };
    },

    onError(state: State, { params, error }: { params: Params; error: Error }): State {
      return {
        ...state,
        loading: false,
        byProvider: {
          ...state.byProvider,
          [params.providerId]: {
            error,
          },
        },
      };
    },

    onLoadStarted(state: State): State {
      return {
        ...state,
        loading: true,
      };
    },
  },

  effects: dispatch => ({
    load: async (params: Params) => {
      await dispatch.previousAppointments.onLoadStarted();
      try {
        const {
          userId, providerId, ...rest
        } = params;
        const previousAppointments = await loadPreviousAppointments(
          userId,
          {
            provider_id: providerId,
            ...rest,
          },
        );
        await dispatch.previousAppointments.onLoaded(
          { params, previousAppointments },
        );
      } catch (error) {
        await dispatch.previousAppointments.onError({
          params, error,
        });
      }
    },
  }),
});

export default model;
