// @ts-strict-ignore
import moment, { MomentInput } from 'moment';
import { Moment } from '../../dateUtils';
import formatFromNow from '../../formatters/formatFromNow';
import {
  APPOINTMENT_PROMO_LAST_MINUTE_CANCELLATION_ID,
  APPOINTMENT_PROMO_LAST_MINUTE_CANCELLATION_NAME,
  APPOINTMENT_PROMO_NEW_CLIENT_DELIVERY_ID,
  APPOINTMENT_PROMO_NEW_CLIENT_DELIVERY_NAME,
  APPOINTMENT_PROMO_SMART_PRICING_ID,
  APPOINTMENT_PROMO_SMART_PRICING_NAME,
} from '../../../components/provider/calendar/constants';
import { PrivilegedProvider, getPrivilegedProvider } from '../../../api/Providers';
import { stateManager } from '../../user/stateManager';
import { CALENDAR_SETTINGS_DRAG_DROP_KEY, CALENDAR_SETTINGS_RANGE_KEY } from './constants';
import nonCriticalException from '../../exceptionLogger';
import { DateString } from '../../../types';

export type PromoAppointmentsData = {
  provider: PrivilegedProvider | null;
  hasSeenNCDPromo: boolean | null;
  hasSeenSPPromo: boolean | null;
  hasSeenLMCPromo: boolean | null;
};

export type PromoAppointment = {
  id: number;
  time_blocks: {
    abbr_name: string;
    local_end: string;
    local_start: string;
    id: number;
  }[];
  client: { name: string };
};

export enum CalendarModeOptions {
  Day = 'day',
  ThreeDay = '3day',
  Week = 'week',
}

export interface CalendarSettings {
  displayMode: CalendarModeOptions;
  enableDragDrop: boolean;
}

function getWeekStartOfDate(day: Date | string) {
  const newDay = new Date(day);

  if (newDay.getDay() === 6) {
    // it's already sunday
    return newDay;
  }

  const sunday = newDay.getDate() - newDay.getDay();
  return new Date(newDay.setDate(sunday));
}

function addDaysToDate(date: Date, days: number) {
  const newDay = new Date(date);
  const dayOfMonth = date.getDate();
  return new Date(newDay.setDate(dayOfMonth + days));
}

/**
 * Returns true if the pro is considered "new"
 * @param provider
 * @returns {bool}
 */
function isNewPro(provider: PrivilegedProvider): boolean {
  const creationTime = moment.utc(provider.creation_time);
  const now = moment.utc();
  const between = moment.duration(now.diff(creationTime));
  const days = between.asDays();
  const sixWeeks = 42;
  return days < sixWeeks;
}

/**
 * Fake appointment used to launch promotional modals
 */
function createPromoAppointment(
  id: number,
  type,
  name,
  localStart,
  localEnd,
): PromoAppointment {
  return {
    id,
    time_blocks: [
      {
        id,
        abbr_name: 'Click me to find out more',
        local_start: localStart,
        local_end: localEnd,
      },
    ],
    client: {
      name,
    },
  };
}

export function getDateWithoutTime(date: Date) {
  return date.toISOString().split('T')[0];
}

export function getRangeLoadSettings(range: CalendarModeOptions, selectedDate: string) {
  const daysToLoad = 7;
  const startDate = getWeekStartOfDate(new Date(selectedDate));

  return {
    numberOfDays: daysToLoad,
    startDate: startDate.toISOString(),
    nextDate: addDaysToDate(startDate, daysToLoad).toISOString(),
  };
}

/**
 * Gets data necessary to possibly display promo appointments on the calendar
 * @param {number} providerId
 * @returns {Promise<PromoAppointmentsData>}
 */
export async function getPromoData(providerId: number): Promise<PromoAppointmentsData> {
  const [
    provider, hasSeenNCDPromo, hasSeenSPPromo, hasSeenLMCPromo,
  ] = await Promise.all([
    getPrivilegedProvider(providerId, false),
    stateManager.getActionValue(APPOINTMENT_PROMO_NEW_CLIENT_DELIVERY_NAME),
    stateManager.getActionValue(APPOINTMENT_PROMO_SMART_PRICING_NAME),
    stateManager.getActionValue(APPOINTMENT_PROMO_LAST_MINUTE_CANCELLATION_NAME),
  ]);

  return {
    provider,
    hasSeenNCDPromo,
    hasSeenSPPromo,
    hasSeenLMCPromo,
  };
}

/**
 *
 * @param promoData
 * @param {bool} proHasAppointments
 * @returns {any[] | null}
 */
export function getPromoAppointments(
  promoData: PromoAppointmentsData,
  proHasAppointments: boolean,
): PromoAppointment[] {
  if (!isNewPro(promoData.provider) || proHasAppointments) {
    return null;
  }

  const result = [];
  const forDate = moment();
  const {
    hasSeenNCDPromo, hasSeenSPPromo, hasSeenLMCPromo,
  } = promoData;

  if (!hasSeenNCDPromo) {
    result.push(createPromoAppointment(
      APPOINTMENT_PROMO_NEW_CLIENT_DELIVERY_ID,
      APPOINTMENT_PROMO_NEW_CLIENT_DELIVERY_NAME,
      'New Client',
      forDate.hour(10).minute(0).format(),
      forDate.hour(10).minute(45).format(),
    ));
  }

  if (!hasSeenSPPromo) {
    result.push(createPromoAppointment(
      APPOINTMENT_PROMO_SMART_PRICING_ID,
      APPOINTMENT_PROMO_SMART_PRICING_NAME,
      'Smart Pricing',
      forDate.hour(11).minute(0).format(),
      forDate.hour(11).minute(45).format(),
    ));
  }

  if (!hasSeenLMCPromo) {
    result.push(createPromoAppointment(
      APPOINTMENT_PROMO_LAST_MINUTE_CANCELLATION_ID,
      APPOINTMENT_PROMO_LAST_MINUTE_CANCELLATION_NAME,
      'Last Minute Cancellation',
      forDate.hour(12).minute(0).format(),
      forDate.hour(12).minute(45).format(),
    ));
  }

  return result;
}

/**
 * Formats the friendly (or relative) date
 * @param date Moment object
 * @param mode Calendar mode
 * @returns The friendly formatted date
 */
export function formatFriendlyDate(date: Moment, mode: CalendarModeOptions): string {
  const unit = mode === CalendarModeOptions.ThreeDay ? CalendarModeOptions.Day : mode;
  const start = moment().startOf('week');
  const diff = date.clone().startOf(unit).diff(start, unit);
  const isCurrentWeek = diff < 7;

  if (mode === 'day' && isCurrentWeek) {
    return formatFromNow.day(date);
  }

  return formatFromNow.week(date);
}

/**
 * Formats the date or date range based on mode
 * @param   {Object} date Moment object
 * @param   {String} mode Calendar mode
 * @returns {String}
 */
export function formatModeDate(date: Moment, mode: CalendarModeOptions): string {
  if (mode === 'day') {
    return date.format('MMMM D, YYYY');
  }

  return date.format('MMMM YYYY');
}

/**
 * Checks if date is currently within the current view on calendar
 *
 * @param apptDate date to evaluate
 * @param options A description of the current calendar view and view window. Either fromMode or
 * mode is required.
 * @returns True if the date is in view, otherwise false
 */
export function isDateInView(dateInput: MomentInput, options: {
  day?: number;
  month?: number;
  year?: number;
  fromMode?: CalendarModeOptions;
  mode?: CalendarModeOptions;
}): boolean {
  if (
    !options.month
    || !options.day
    || !options.year
  ) {
    return false;
  }

  const testDate = moment(dateInput);
  const calDate = moment()
    .month(options.month - 1)
    .date(options.day)
    .year(options.year);
  const calendarMode = options.fromMode || options.mode;
  const diffDays = testDate.diff(calDate, 'days');

  switch (calendarMode) {
    case CalendarModeOptions.Day:
      return testDate.isSame(calDate, 'day');
    case CalendarModeOptions.ThreeDay:
      return diffDays >= 0 && diffDays < 2;
    case CalendarModeOptions.Week:
      return testDate.isSame(calDate, 'week');
    default:
      return false;
  }
}

/**
 * Parses date from route params if possible, otherwise, returns start of current day
 * @param routeParams The route params
 */
export function parseDateFromParams(
  routeParams: Record<string, string | boolean | number>,
): Moment {
  const {
    year, month, day,
  } = routeParams;
  const date = moment([
    year,
    month,
    day,
  ].join('-'), 'YYYY-M-D');

  return date.isValid() ? date : moment().startOf('day');
}

/**
 * Stores calendar display preferences to local state
 * @param settings The settings values
 */
export function saveCalendarSettings(settings: Partial<CalendarSettings>) {
  stateManager.addAction(CALENDAR_SETTINGS_DRAG_DROP_KEY, settings.enableDragDrop);
  if (settings.displayMode) {
    stateManager.addAction(CALENDAR_SETTINGS_RANGE_KEY, settings.displayMode);
  }
}

/**
 * Loads calendar display preferences stored in local state
 * @returns The display preferences
 */
export async function loadCalendarSettings(): Promise<CalendarSettings> {
  const {
    [CALENDAR_SETTINGS_RANGE_KEY]: displayModeValue,
    [CALENDAR_SETTINGS_DRAG_DROP_KEY]: enableDragDropValue,
  } = await stateManager.getActionValues(
    CALENDAR_SETTINGS_RANGE_KEY,
    CALENDAR_SETTINGS_DRAG_DROP_KEY,
  );

  return {
    displayMode: displayModeValue || 'week',
    enableDragDrop: typeof enableDragDropValue === 'undefined' || enableDragDropValue,
  };
}

export const getCalendarMode = async (mode?: string): Promise<CalendarModeOptions> => {
  if (mode) {
    return mode as CalendarModeOptions;
  }

  try {
    return (await loadCalendarSettings()).displayMode;
  } catch (e) {
    nonCriticalException(e);
  }

  return CalendarModeOptions.Week;
};

export const normalizeDateParams = (params: {
  year?: string;
  month?: string;
  day?: string;
} = {}) => {
  const {
    year: routeYear,
    month: routeMonth,
    day: routeDay,
  } = params;

  const now = moment();
  const year = routeYear ? Number(routeYear) : now.year();
  const month = routeMonth ? Number(routeMonth) : now.month() + 1;
  const day = routeDay ? Number(routeDay) : now.date();

  return {
    year,
    month,
    day,
  };
};

export const parseSelectedDateFromParams = (params: {
  year?: number | string;
  month?: number | string;
  day?: number | string;
}) => parseDateFromParams(params).format('YYYY-MM-DD');

export const isNormalizedDateSame = (params: {
  year?: string;
  month?: string;
  day?: string;
}) => {
  const {
    year: routeYear,
    month: routeMonth,
    day: routeDay,
  } = params;
  const {
    year, month, day,
  } = normalizeDateParams(params);

  return (
    Number(routeDay) === day
    && Number(routeMonth) === month
    && Number(routeYear) === year
  );
};

export const parseDateParamsFromDateString = (date: DateString) => {
  const dateMoment = moment(date, 'YYYY-MM-DD');
  return {
    year: dateMoment.year(),
    month: dateMoment.month() + 1,
    day: dateMoment.date(),
  };
};
