// @ts-strict-ignore
import type {
  IAnalyticsTracker,
  AnalyticsUserData,
  AnalyticsDataSource,
  AnalyticsErrorHandler,
  AnalyticsEventIdSource,
  AnalyticsLocationData,
  AnalyticsScreenData,
  UTMState,
} from './types';

import { sanitize, SanitizedProperties } from './sanitize';

export type DefaultProperties = {
  event_id?: string;
  event_time_iso_8610?: string;
  event_timezone?: number;
  logged_in?: boolean;
  app_version?: string;
  responsive_layout?: 'mobile' | 'desktop';
  tracking_cookie?: string;
  ss_tracking_cookie?: string;
  device?: string;
  session_id?: string;
  platform?: string;
  window_location_origin?: string;
  window_location_pathname?: string;
  window_location_hash?: string;
  window_location_search?: string;
  window_location_href?: string;
  device_model?: string;
  referrer?: string;
  user_agent?: string;
  page_route?: string;
  previous_route?: string;
  page_key?: string;
  user_data?: {
    user_id: number | string | null;
    provider_id?: number | string;
    email?: string;
    hashed_email?: string;
    date_joined?: string;
    phone_number?: string;
    first_name?: string;
    last_name?: string;
    username?: string;
    provider_name?: string;
    provider_plan?: string;
    external_id?: string;
  } & AnalyticsLocationData;
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  screen?: AnalyticsScreenData;
  timezone_name?: string;
  os?: {
    name: string;
    version: string;
  };
  locale?: string;
  page?: {
    path: string;
    search: string;
    title: string;
    url: string;
    referrer: string;
  };
};

export default class Analytics {
  trackers: IAnalyticsTracker[];

  onError: AnalyticsErrorHandler;

  getEventId: AnalyticsEventIdSource;

  getAnalyticsData: AnalyticsDataSource;

  locationData: AnalyticsLocationData | null;

  /**
   * Initializes the analytics library.
   * @param onError - what happens when an error occurrs.
   * @param getEventId - used to generate a new event id
   * @param getAnalyticsData - called to get base tracking data
   * @param trackers - an optional array of extra trackers
   */
  constructor({
    onError,
    trackers,
    getEventId,
    getAnalyticsData,
  }: {
    onError: AnalyticsErrorHandler;
    trackers?: IAnalyticsTracker[];
    getEventId: AnalyticsEventIdSource;
    getAnalyticsData: AnalyticsDataSource;
  }) {
    this.onError = onError;
    this.getEventId = getEventId;
    this.getAnalyticsData = getAnalyticsData;
    this.trackers = trackers || [];
  }

  getTracker<ITracker extends IAnalyticsTracker>(id: string): ITracker {
    return this.trackers.find(tracker => tracker.id === id) as ITracker;
  }

  private async getDefaultProps(eventId: string): Promise<DefaultProperties> {
    const now = new Date();
    const data = await this.getAnalyticsData();
    return {
      event_id: eventId,
      event_time_iso_8610: now.toISOString(),
      event_timezone: now.getTimezoneOffset(),
      locale: data.locale,
      logged_in: data.user.is_logged_in,
      app_version: data.appVersion,
      responsive_layout: data.device.layout,
      tracking_cookie: data.user.ss_tracking_cookie,
      ss_tracking_cookie: data.user.ss_tracking_cookie,
      device: data.device.device,
      screen: data.device.screen,
      session_id: data.user.session_id,
      window_location_origin: data.route.window_location_origin,
      window_location_pathname: data.route.window_location_pathname,
      window_location_hash: data.route.window_location_hash,
      window_location_search: data.route.window_location_search,
      window_location_href: data.route.window_location_href,
      device_model: data.device.device_model,
      referrer: data.route.referrer,
      user_agent: data.device.user_agent,
      page: {
        path: data.route.window_location_pathname,
        referrer: data.route.referrer,
        search: data.route.window_location_search,
        title: data.route.title,
        url: data.route.window_location_href,
      },
      page_route: data.route.page_route,
      previous_route: data.route.previous_route,
      page_key: data.route.page_key,
      ...data.route.last_touch_utm_params,
      ...(data.route?.utm_parameters || {}),
      ...(data.extra || {}),
      timezone_name: data.timezone_name,
      user_data: {
        user_id: data.user.user_id,
        provider_id: data.user.provider_id,
        email: data.user.email,
        hashed_email: data.user?.hashed_email,
        date_joined: data.user?.date_joined,
        phone_number: data.user?.phone_number,
        first_name: data.user?.first_name,
        last_name: data.user?.last_name,
        username: data.user?.username,
        provider_name: data.user?.provider_name,
        provider_plan: data.user?.provider_plan,
        external_id: data.user?.user_token || data.user?.client_handle,
        ...(this.locationData || {}),
      },
      os: data.os,
    };
  }

  private async invokeTracker(
    tracker: IAnalyticsTracker,
    eventName: string,
    props: SanitizedProperties & DefaultProperties,
    options,
  ) {
    try {
      await tracker.trackEvent(eventName, props, options);
    } catch (e) {
      this.onError(`analytics (${tracker.name}) Error calling tracker`, e);
    }
  }

  async track(eventName: string, properties?: object, options?: object) {
    const eventId = this.getEventId();
    const defaultProps = await this.getDefaultProps(eventId);

    const props: SanitizedProperties & DefaultProperties = {
      ...defaultProps,
      ...sanitize(properties),
    };

    const calls = this.trackers.map(
      tracker => this.invokeTracker(tracker, eventName, props, options),
    );
    return Promise.all(calls);
  }

  trackPageView(path?: string) {
    this.trackers.forEach(tracker => tracker.trackPageView(path));
  }

  updateUser(userData: AnalyticsUserData) {
    this.trackers.forEach(tracker => {
      try {
        tracker.updateUser(userData);
      } catch (e) {
        this.onError(`analytics (${tracker.name}) Error calling tracker`, e);
      }
    });
  }

  updateUTMs(utmData: UTMState) {
    this.trackers.forEach(tracker => {
      try {
        tracker.updateUTMs(utmData);
      } catch (e) {
        this.onError(`analytics (${tracker.name}) Error calling tracker`, e);
      }
    });
  }

  updateLocation(locationData: AnalyticsLocationData) {
    this.locationData = locationData;
  }
}
