import SimpleCache from '../../../modules/SimpleCache';
import ssFetch, { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';
import { ProviderService } from '../Services';

enum WaitlistCreatedBy {
  Pro = 1,
  Client = 2,
}

export type WaitlistClient = {
  id: number;
  name: string;
  thumbnail: string;
};

export type WaitlistService = {
  id: number;
  name: string;
};

export interface IWaitlistItem {
  id: number;
  client: WaitlistClient;
  client_id: number;
  client_note: string;
  created_by: WaitlistCreatedBy;
  created_by_client: boolean;
  creation_time: string;
  deletion_time: string;
  completion_time: string;
  note: string;
  services: WaitlistService[];

  // provider and appointment also come through in some form, but don't seem to be used
  provider: number;
  appointment: unknown;
}

/**
 * Data as returned by the ProviderWaitlistSerializer
 */
export interface IAddedWaitlistItem {
  client_id: number;
  note: string;
  provider: number;
}

/**
 * Data as returned by the ProviderWaitlistUpdateDeleteSerializer
 */
export interface IWaitlistItemDetails extends IWaitlistItem {
  services: ProviderService[];
}

type WaitlistItemPayload = {
  note?: string;
  client_note?: string;
  client_id: number;
};

const waitlistCache = new SimpleCache(300); // 5 minute expiration

export function clearWaitlistCache() {
  waitlistCache.clear();
}

/**
 * Get the premium appointment requests for a pro
 *
 * @param providerId The provider ID
 * @return A list of premium appointment requests objects
 */
export async function getPremiumRequestsList(
  providerId: number,
): Promise<IDRFResponse<IWaitlistItem>> {
  return ssFetchJSON<IDRFResponse<IWaitlistItem>>(
    `/api/v2/providers/${providerId}/waitlist?premium_requests_only=true`,
  );
}

/**
 * Get the full waitlist for a pro
 *
 * @param providerId The provider ID
 * @return A list of waitlist items
 */
export async function getWaitlist(providerId: number): Promise<IDRFResponse<IWaitlistItem>> {
  return ssFetchJSON<IDRFResponse<IWaitlistItem>>(
    `/api/v2/providers/${providerId}/waitlist`,
  );
}

/**
 * Get a single waitlist item for a pro
 *
 * @param providerId The provider ID
 * @param itemId
 * @return A list of waitlist items
 */
export async function getWaitlistItem(
  providerId: number,
  itemId: number,
): Promise<IWaitlistItemDetails> {
  return ssFetchJSON<IWaitlistItemDetails>(
    `/api/v2/providers/${providerId}/waitlist/${itemId}`,
  );
}

/**
 * Create a waitlist item
 *
 * @param providerId The provider ID
 * @param item The waitlist item object.
 * @return The full created item
 */
export async function addWaitlistItem(
  providerId: number,
  item: WaitlistItemPayload,
): Promise<IAddedWaitlistItem> {
  clearWaitlistCache();
  return ssFetchJSON<IAddedWaitlistItem>(
    `/api/v2/providers/${providerId}/waitlist`,
    {
      method: 'POST',
      body: {
        ...item,
        provider: providerId,
      },
    },
  );
}

/**
 * Update a waitlist item
 *
 * @param providerId The provider ID
 * @param itemId The ID of the item to update
 * @param item The waitlist item object.
 * @return The full created item
 */
export async function updateWaitlistItem(
  providerId: number,
  itemId: number,
  item: Omit<WaitlistItemPayload, 'client_id'>,
): Promise<IWaitlistItem> {
  clearWaitlistCache();
  return ssFetchJSON<IWaitlistItem>(
    `/api/v2/providers/${providerId}/waitlist/${itemId}`,
    {
      method: 'POST',
      body: {
        ...item,
        provider: providerId,
      },
    },
  );
}

/**
 * Update a waitlist item's note
 *
 * @param providerId The provider ID
 * @param itemId The ID of the item to update
 * @param note The updated note text.
 * @return The full created item
 */
export async function updateWaitlistItemNote(
  providerId: number,
  itemId: number,
  note: string,
): Promise<IWaitlistItem> {
  clearWaitlistCache();
  return ssFetchJSON<IWaitlistItem>(
    `/api/v2/providers/${providerId}/waitlist/${itemId}`,
    {
      method: 'PATCH',
      body: {
        note,
      },
    },
  );
}

/**
 * Delete a waitlist item
 *
 * @param providerId The provider ID
 * @param itemId The ID of the item to delete
 */
export async function deleteWaitlistItem(
  providerId: number,
  itemId: number,
): Promise<void> {
  clearWaitlistCache();
  await ssFetch(
    `/api/v2/providers/${providerId}/waitlist/${itemId}`,
    {
      method: 'DELETE',
    },
  );
}
