import { DimensionListener } from './Responsive.types';

/**
 * Subscribes to changes in screen height. Should not be used outside of Responsive module.
 * @param listener The callback to call when height changes. Called immediately with the present
 * with.
 * @returns A function to call to unsubscribe from notification
 */
export function listenToHeight(listener: DimensionListener): () => void {
  const listen = () => {
    listener(window.innerHeight);
  };

  window.addEventListener('resize', listen);
  listener(window.innerHeight);

  return () => {
    window.removeEventListener('resize', listen);
  };
}
