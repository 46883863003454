// @ts-strict-ignore
import { matchPath, generatePath } from 'react-router';
import {
  createBrowserHistory,
  History,
  Location,
} from 'history';
import { isEqual } from 'underscore';
import { RouterHistory } from '../StateRouterHistory/RouterHistory';

import type {
  IRouteState,
  IRouterGoOptions,
  LocationGetter,
  RouteConfig,
  IStateRouterAdapter,
  NavigationHandler,
  ExceptionHandler,
} from '../types';

/**
 * This Router's objective is to keep our applications in sync
 * in terms of routing and browsing history. Before angular loads this
 * router simply pushes new states to history but once angular is loaded
 * it also calls on $state to update its internal history
 */
export class StateRouter {
  /**
   * router history used to figure out where to navigate back to
   * also used to peek into what the previous route was.
   */
  public history = new RouterHistory();

  /**
   * the browser's history API (wrapped by react-router's history module).
   * Used to navigate.
   */
  public sessionHistory: History<IRouteState>;

  /**
   * Function that returns the current location object.
   * In the case of the browser, window.location.
   * This helps in unit testing.
   */
  // eslint-disable-next-line class-methods-use-this
  public locationGetter: LocationGetter = () => this.sessionHistory?.location || window.location;

  private routeConfigs: RouteConfig[] = [];

  private adapters: IStateRouterAdapter[] = [];

  private onNavigationHandlers: NavigationHandler[] = [];

  private onExceptionHandlers: ExceptionHandler[] = [];

  private lastResolvedURL = null;

  private hrefResolveCache = {};

  /**
   * Sets up the router with the given route configs
   * @param routeConfigs
   * @param browserHistory
   */
  public setup(
    routeConfigs: RouteConfig[],
    browserHistory?: History<IRouteState>,
  ) {
    this.routeConfigs = routeConfigs;
    this.sessionHistory = browserHistory;
    if (!this.sessionHistory) {
      this.sessionHistory = createBrowserHistory<IRouteState>();
    }

    const initial = this.getRouteForCurrentURL();
    if (initial) {
      this.history.go(initial.name, initial.params, initial.data);
    }

    // browser history calls this whenever the user interacts with the url outside of calling "go"
    // (like pressing the back button or clicking a link)
    this.sessionHistory.listen((location: Location<IRouteState>) => (
      this.handleBrowserNavigated(location)
    ));
  }

  /**
   * Gets default values for required URL params
   * in the target route from the existing values
   * in the current route
   * @param {IRouteState} currentState
   * @param {RouteConfig} targetRoute
   * @returns {any}
   * @private
   */
  private static getInheritedParams(
    currentState: IRouteState,
    targetRoute: RouteConfig,
  ) {
    if (!targetRoute) {
      return {};
    }

    const urls = Array.isArray(targetRoute.url) ? targetRoute.url : [targetRoute.url];

    const urlParams = urls.reduce((combined, url) => {
      const match = url.match(/[:]\w+/g);
      return match ? combined.concat(match.map(str => str.substring(1))) : combined;
    }, []);

    return urlParams.reduce((result, paramName) => ({
      ...result,
      [paramName]: currentState?.params?.[paramName],
    }), {});
  }

  /**
   * Take the user to a new route state or update their params
   * @param stateName
   * @param params
   * @param options
   */
  public async go(
    stateName: string,
    params?: Record<string, any>,
    options: IRouterGoOptions = {},
  ) {
    const combinedOptions = {
      notify: true,
      ...options,
    };

    const result = this.resolveFromAdapter('go', stateName, params, combinedOptions);

    // if an adapter returned a value, we won't do it ourselves
    if (result) {
      return result;
    }

    const currentLocation = this.locationGetter();
    const currentURL = currentLocation.pathname + currentLocation.search;
    const currentState = this.history.current;
    const targetStateName = stateName === '.' ? currentState?.name : stateName;
    const targetParams = this.getTargetRouteParams(currentState, targetStateName, params, options);

    // if the params also didn't change and we're in the same state... there's nothing to do
    if (stateName === currentState?.name && isEqual(currentState?.params, targetParams)) {
      return Promise.resolve();
    }

    const targetURL = this.href(targetStateName, targetParams);

    if (currentURL !== targetURL) {
      if (targetURL) {
        if (combinedOptions.replace) {
          this.history.pop();
        }

        this.navigateTo(targetURL, options);
      }

      this.notifyOfRouteChange({
        name: targetStateName,
        params: targetParams,
      });
    }

    return Promise.resolve();
  }

  /**
   * Install a router adapter/plugin. Allows external hooking into router functionality
   * to override behavior.
   * @param adapter
   */
  public installAdapter(adapter: IStateRouterAdapter) {
    this.adapters.push(adapter);
  }

  /**
   * Pass a callback to be fired whenever the router navigates to a new URL/state
   * @param cb
   */
  public onNavigation(cb) {
    const idx = this.onNavigationHandlers.length;
    this.onNavigationHandlers.push(cb);
    return () => {
      this.onNavigationHandlers.splice(idx, 1, () => { });
    };
  }

  /**
   * A function to clear out navigation handlers, especially useful in tests
   */
  public clearNavigationHandlers() {
    this.onNavigationHandlers = [];
  }

  /**
   * Pass a callback to be fired whenever a non critical router exception is caught
   * @param cb
   */
  public onException(cb) {
    const idx = this.onExceptionHandlers.length;
    this.onExceptionHandlers.push(cb);
    return () => {
      this.onExceptionHandlers.splice(idx, 1, () => { });
    };
  }

  /**
   * Returns the route the user was in prior to the current one
   */
  public getPreviousRoute(): IRouteState | undefined {
    return this.history?.previous;
  }

  public getCurrentRoute() {
    return this.history?.current;
  }

  /**
   * Returns the router state for the URL the user is in
   */
  public getRouteForCurrentURL(): IRouteState | undefined {
    // go through our adapters and see if any of them want to respond to this call
    const result = this.resolveFromAdapter('getCurrentState', null);

    // if an adapter returned a value, we won't do it ourselves
    if (result) {
      return result;
    }

    const location = this.locationGetter();
    const currentURL = location.pathname + location.search;

    // we haven't changed locations, no need to do work again
    if (currentURL === this.lastResolvedURL) {
      return this.history.current;
    }

    this.lastResolvedURL = currentURL;
    const queryParams = StateRouter.urlParamsToObject(new URLSearchParams(location.search));
    return this.getRouteForURL(location.pathname, queryParams);
  }

  private findMatchingRouteByName(
    route: string,
    forRoutes: RouteConfig[] = this.routeConfigs,
  ): RouteConfig | undefined {
    // eslint-disable-next-line no-restricted-syntax
    for (const routeConfig of forRoutes) {
      let match = routeConfig.stateName === route ? routeConfig : undefined;
      if (!match && route?.startsWith?.(routeConfig.stateName)) {
        match = this.findMatchingRouteByName(route, routeConfig.routes || []);
      }
      if (match) {
        return match;
      }
    }

    return undefined;
  }

  /**
   * Generate a URL for the given route and params
   * @param route
   * @param params
   */
  public href(route: string, params?: Record<string, any>): string | undefined {
    let result = this.resolveFromAdapter('href', route, params, this.routeConfigs);

    if (result) {
      return result;
    }

    // href can get called quite a lot so it's worth caching prior calls
    const cache = this.hrefResolveCache[route] || new Map();

    if (cache.has(params)) {
      return cache.get(params);
    }

    const routeConfig = this.findMatchingRouteByName(route);

    if (!routeConfig) {
      return undefined;
    }

    result = StateRouter.compileRouteToURL(routeConfig, params);
    cache.set(params, result);
    this.hrefResolveCache[route] = cache;
    return result;
  }

  /**
   * Go back to the previous state. If no prior state exists, go to the given default
   * state and params
   * @param defaultState
   * @param defaultStateParams
   * @param defaultOptions
   */
  public goBack(
    defaultState: string,
    defaultStateParams?: Record<string, any>,
    defaultOptions?: Record<string, any>,
  ): Promise<void> | void {
    const routeOptions = {
      replace: true, ...defaultOptions,
    };
    const result = this.resolveFromAdapter(
      'goBack',
      defaultState,
      defaultStateParams,
      routeOptions,
    );

    if (result) {
      return result;
    }

    if (this.history.length > 0 && this.sessionHistory.length > 1) {
      return this.goBackSteps(1);
    }

    return this.go(defaultState, defaultStateParams || {}, routeOptions);
  }

  /**
   * Goes back a given number of routes visited
   * @param steps
   */
  public goBackSteps(steps: number = 1): void {
    this.sessionHistory.go(-1 * Math.abs(steps));
  }

  /**
   * Get the full state name if the state has parents
   * @param state
   */
  public getFullStateName(state: string): string | undefined {
    const result = this.resolveFromAdapter(
      'fullStateNameResolver',
      state,
    );

    if (result) {
      return result;
    }

    return state;
  }

  private navigateTo(url?: string, options: IRouterGoOptions = {}) {
    if (options.replace) {
      this.sessionHistory.replace(url, this.history.current);
    } else {
      this.sessionHistory.push(url, this.history.current);
    }
  }

  /**
   * Get the query params from a provided object.
   * It removes any possible url params from the query params list
   * Example: if the url is "/provider/:id/" and the params are {id: 123, query: "hello"}
   * then the returned QUERY params are {query: "hello"} since id is a URL param
   * @param url
   * @param params
   * @private
   */
  private static getRouteQueryParams(url: string, params: any) {
    const queryParams = new URLSearchParams(params);

    // prevent our url params (/:id/, /:vanity_url.. etc) from becoming
    // query params (?someFilter=abc).
    const urlParams = url.match(/[:]\w+/g);
    if (urlParams?.length > 0) {
      urlParams.forEach(urlParam => {
        const paramName = urlParam.substr(1, urlParam.length - 1);
        queryParams.delete(paramName);
      });
    }

    const deleteList: string[] = [];

    // remove undefined values
    queryParams.forEach((paramValue, paramName) => {
      if (paramValue.trim() === '' || paramValue === 'undefined' || paramValue === 'null') {
        // can't iterate and delete at the same time
        deleteList.push(paramName);
      }
    });

    deleteList.forEach(item => queryParams.delete(item));

    queryParams.sort();
    return queryParams;
  }

  /**
   * Turns a route config (and the provided params values) into
   * an URL string
   * @param routeConfig
   * @param params
   * @private
   */
  private static compileRouteToURL(routeConfig: RouteConfig, params: any): string | undefined {
    const rawURLs = Array.isArray(routeConfig.url) ? routeConfig.url : [routeConfig.url];
    let url;
    let queryParams;

    // eslint-disable-next-line no-restricted-syntax
    for (const routeURL of rawURLs) {
      try {
        url = generatePath(routeURL, params);
        queryParams = StateRouter.getRouteQueryParams(routeURL, params);
        break;
      } catch (e) {
        // noop
      }
    }

    if (!url) {
      return undefined;
    }

    const queryParamsString = queryParams.toString();

    if (queryParamsString) {
      url += `?${queryParamsString}`;
    }

    return url;
  }

  private static canUseDOM() {
    return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  }

  /**
   * Transforms the URLParams iterator into an object
   * @param URLParams
   * @private
   */
  private static urlParamsToObject(URLParams: any): { [key: string]: any } {
    const paramsArray: any[] = Array.from(
      // https://github.com/facebook/react-native/issues/23922
      // eslint-disable-next-line no-underscore-dangle
      StateRouter.canUseDOM() ? URLParams.entries() : URLParams._searchParams,
    );
    return paramsArray.reduce((result, [key, value]) => ({
      ...result,
      [key]: value === '' ? true : value,
    }), {});
  }

  /**
   * Goes through our adapters calling the given hook (function) name
   * until one of the calls does not return undefined
   * @param hookName
   * @param restArgs
   * @private
   */
  private resolveFromAdapter<T extends keyof IStateRouterAdapter>(
    hookName: T,
    ...restArgs: Parameters<IStateRouterAdapter[T]>
  ): ReturnType<IStateRouterAdapter[T]> {
    const adapters: IStateRouterAdapter[] = this.adapters.filter(adapter => !!adapter[hookName]);
    // eslint-disable-next-line no-restricted-syntax
    for (const adapter of adapters) {
      try {
        // Angular's UI router will sometimes error out when it can't find the route
        // In this case, we want React to attempt to handle it - so catch any errors here
        const result = adapter[hookName](...restArgs);
        if (result) {
          return result as ReturnType<IStateRouterAdapter[T]>;
        }
      } catch (e) {
        this.handleException(e);
      }
    }

    return undefined;
  }

  private handleException(e: any): void {
    this.onExceptionHandlers.forEach(handler => (
      handler(e)
    ));
  }

  /**
   * Called once the browser has navigated to either a new page or back to a previous one
   * @param location
   * @private
   */
  private handleBrowserNavigated(location: Location<IRouteState>): void {
    const newState = this.getRouteForCurrentURL();
    if (newState) {
      this.history.go(newState.name, newState.params);
    }

    this.onNavigationHandlers.forEach(handler => (
      handler({
        url: (location?.pathname || '') + (location?.search || ''),
        state: newState,
      })
    ));
  }

  public notifyOfRouteChange(newState: IRouteState) {
    this.history.go(newState.name, newState.params);
    const location = this.locationGetter();
    this.onNavigationHandlers.forEach(handler => (
      handler({
        url: (location?.pathname || '') + (location?.search || ''),
        state: newState,
      })
    ));
  }

  /**
   * Given a url/params we return a matching route (or undefined)
   * @param url
   * @param params
   * @param forRoutes
   * @private
   */
  private getRouteForURL(
    url: string,
    params: any,
    forRoutes: RouteConfig[] = this.routeConfigs,
  ): IRouteState | undefined {
    // eslint-disable-next-line no-restricted-syntax
    for (const routeConfig of forRoutes) {
      const match = matchPath(url, {
        path: routeConfig.url,
        exact: true,
      });

      if (match) {
        return {
          name: routeConfig.stateName,
          params: { ...params, ...match.params },
          data: routeConfig.data,
          previous: this.getPreviousRoute(),
        };
      }
      const subRouteMatch = this.getRouteForURL(url, params, routeConfig?.routes || []);
      if (subRouteMatch) {
        return subRouteMatch;
      }
    }

    return undefined;
  }

  /**
   * Calculates the values for the url/query params
   * for the route we're navigating to
   * @param {IRouteState | null} currentState
   * @param {string} targetStateName
   * @param {Record<string, any>} targetParamValues
   * @param {IRouterGoOptions} options
   * @returns {any}
   * @private
   */
  private getTargetRouteParams(
    currentState: IRouteState | null,
    targetStateName: string,
    targetParamValues: Record<string, any>,
    options: IRouterGoOptions = {},
  ) {
    // force all defined param values to strings for consistency
    let targetParams = Object.entries(targetParamValues || {})
      .reduce((acc, [paramKey, paramValue]) => {
        acc[paramKey] = paramValue === undefined || paramValue === null
          ? paramValue
          : String(paramValue);
        return acc;
      }, {});

    if (options?.clearParams) {
      return { ...targetParams };
    }

    // if we're just updating the params, then combine the existing ones
    if (targetStateName === currentState?.name) {
      targetParams = { ...currentState?.params, ...targetParams };
    }

    const isGoingToChild = currentState?.name && targetStateName.indexOf(currentState?.name) === 0;
    const isGoingToParent = currentState?.name && (currentState?.name || '').indexOf(targetStateName) === 0;

    // if we're going into or out of a child route we bring over
    // any required url params (so we don't have to pass the full list back and forth)
    if (isGoingToChild || isGoingToParent) {
      const targetRoute = this.findMatchingRouteByName(targetStateName);
      targetParams = {
        ...StateRouter.getInheritedParams(currentState, targetRoute),
        ...targetParams,
      };
    }

    return targetParams;
  }
}

export default new StateRouter();
