export enum DeepLinkSub {
  DeepLinkSub1 = 'deep_link_sub1',
  DeepLinkSub2 = 'deep_link_sub2',
  DeepLinkSub3 = 'deep_link_sub3',
  DeepLinkSub4 = 'deep_link_sub4',
  DeepLinkSub5 = 'deep_link_sub5',
  DeepLinkSub6 = 'deep_link_sub6',
  DeepLinkSub7 = 'deep_link_sub7',
  DeepLinkSub8 = 'deep_link_sub8',
  DeepLinkSub9 = 'deep_link_sub9',
  DeepLinkSub10 = 'deep_link_sub10',
}

export type DeepLinkSubs = {
  [key in DeepLinkSub]: any;
};

export type DeepLinkResponseData = {
  deep_link_value: string;
  install_time: string;
  af_dp: string;
  path: string;
  scheme: string;
  host: string;
  pid: string;
} & DeepLinkSubs;

export type DeepLinkResponse = {
  type: string;
  status: string;
  data: DeepLinkResponseData;
};

/**
 * RouteProps type represents the properties of a route.
 * @typedef {Object} RouteProps
 * @property {string} routeName - The route name that comes form route-names file.
 * @property {string[]} [paramKeys] - Optional parameters names for the route.
 */
export type RouteProps = {
  routeName: string;
  paramKeys?: string[];
};
