// @ts-strict-ignore
import React, {
  useState,
  useMemo,
  useCallback,
  ReactNode,
  createContext,
  useEffect,
  useRef,
} from 'react';
import { BrandColor } from '@styleseat/brand';
import { Snackbar } from '../../ui/Snackbar';

export type SnackbarContextType = {
  open: (state: Partial<SnackbarState>) => void;
  close: () => void;
};

type SnackbarButtonAction = {
  text: string;
  action?: () => any;
};
export type DeprecatedIconClass =
  'info-icon'
  | 'error-icon'
  | 'success-icon'
  | 'auto-checkout-icon'
  | 'sparkles-icon';

export type SnackbarState = {
  show: boolean;
  /** Used for titling a snackbar */
  header: string;
  /** Shown as a bolded start to a description */
  inlineHighlight: string;
  /**
   * When used with inlineHighlight, this is the remainder of the description that is not bold.
   * When used alone, this is simply the description text
   */
  subHeader: ReactNode;
  /** Props for the confirmation button text and onClick action */
  confirm: SnackbarButtonAction;
  /** Props for the dismiss button text and onClick action */
  dismiss: SnackbarButtonAction;
  /** @deprecated - Use sideContent to render icons */
  icon: DeprecatedIconClass | '';
  /**
   * If you'd like to replace the entire snackbar with a custom component,
   * use this together with mainContent/sideContent
   */
  removeFormatting?: boolean;
  /**
   * The left border color of the snackbar.
   * Set this to transparent or empty to remove the color
   */
  sideColor: string;
  /**
   * Custom rendered left side section. This replaces any `icon` passed in
   */
  sideContent: ReactNode;
  /**
   * Custom rendered main (right side) content.
   * This replaces the content that uses header, inlineHighlight, subHeader,
   * confirm, dismiss, and confirmWidget.
   */
  mainContent: ReactNode;
  /**
   * Custom rendered button section (displays beneath inlineHightlight/subHeader)
   */
  confirmWidget?: ReactNode;
  /**
   * Set an automatic time out period (in milliseconds) for dismissing the snackbar
   */
  timeOut?: number;
};

const defaultState: SnackbarState = {
  show: false,
  header: '',
  inlineHighlight: '',
  subHeader: '',
  confirm: {
    text: '',
    action: () => { },
  },
  dismiss: {
    text: '',
    action: () => { },
  },
  /** @deprecated */
  icon: 'info-icon',
  sideColor: BrandColor.DarkBlue,
  sideContent: null,
  mainContent: null,
  confirmWidget: null,
  timeOut: null,
};

export const SnackbarContext = createContext<SnackbarContextType>({
  open: () => {},
  close: () => {},
});

export const SnackbarProvider = ({
  children,
}) => {
  const [snackState, setSnackState] = useState<SnackbarState>(defaultState);
  const timeoutRef = useRef(null);

  const handleClose = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setSnackState({ ...defaultState, show: false });
  }, []);

  const resetTimeout = useCallback((timeout?: number) => {
    clearTimeout(timeoutRef.current);
    if (timeout) {
      timeoutRef.current = setTimeout(handleClose, timeout);
    }
  }, [handleClose]);

  const handleOpen = useCallback((state: Partial<SnackbarState>) => {
    resetTimeout(state.timeOut);
    setSnackState({
      ...defaultState,
      ...state,
      show: true,
    });
  }, [resetTimeout]);

  const value = useMemo(() => ({
    open: handleOpen,
    close: handleClose,
  }), [
    handleOpen,
    handleClose,
  ]);

  useEffect(() => {
    resetTimeout(snackState.timeOut);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [
    resetTimeout,
    snackState.timeOut,
  ]);

  return (
    <SnackbarContext.Provider value={value}>
      {snackState?.show && (<Snackbar {...snackState} onClose={handleClose} />)}
      {children}
    </SnackbarContext.Provider>
  );
};
