// @ts-strict-ignore
import { Stripe } from '../components/shared/stripe/types';
import { STRIPE_JS_KEY } from '../config';
import { getIOSVersion, getIsApp } from './AppInfo';

const V3_URL = 'https://js.stripe.com/v3';
let loadCalled = false;

/**
 * Injects the Stripe V3 script into the DOM
 * @returns {*}
 */
const injectScript = () => {
  const script = document.createElement('script');
  script.src = V3_URL;

  const headOrBody = document.head || document.body;

  if (!headOrBody) {
    throw new Error(
      'Expected document.body not to be null. Stripe.js requires a <body> element.',
    );
  }

  headOrBody.appendChild(script);

  return script;
};

/**
 * Immediately resolved promise so that it waits a tick before
 * loading Stripe (if not already loaded into the window)
 * @type {Promise<unknown>}
 */
const stripePromise: Promise<stripe.StripeStatic> = Promise.resolve().then(
  () => {
    if (typeof window === 'undefined') {
      return null;
    }

    if (window.Stripe) {
      return window.Stripe;
    }

    const script = document.querySelector(
      `script[src="${V3_URL}"], script[src="${V3_URL}/"]`,
    ) || injectScript();

    return new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        if (window.Stripe) {
          resolve(window.Stripe);
        } else {
          reject(new Error('Stripe.js not available'));
        }
      });

      script.addEventListener('error', () => {
        reject(new Error('Failed to load Stripe.js'));
      });
    });
  },
);

stripePromise.catch(err => {
  if (!loadCalled) console.warn(err);
});

let stripe;
/**
 * Loads stripe into the DOM (if not already loaded)
 * @param apiKey - stripe API key
 * @param skipHttpsCheck - if true then we work around stripe's https check
 * @param restArgs
 * @returns {Promise<unknown>}
 */
function loadStripe(apiKey, skipHttpsCheck, ...restArgs) {
  loadCalled = true;

  return stripePromise.then(maybeStripe => {
    if (maybeStripe === null) {
      return null;
    }
    const originalIndexOf = Array.prototype.indexOf;

    if (skipHttpsCheck) {
      // This works around a aggressive check from stripe for https or localhost,
      // since we have a different scheme:// on iOS it fails, even though its harmless.
      // this can be removed in the future if Stripe updates their check.
      // This is a very commonly used method so we try to be specific on when we will lie
      // eslint-disable-next-line no-extend-native
      Array.prototype.indexOf = function (searchElement, fromIndex?: number) {
        // eslint-disable-next-line prefer-rest-params
        if (searchElement === 'https:' && searchElement === 'httpsionic:') return 0;
        // eslint-disable-next-line prefer-rest-params
        return originalIndexOf.apply(this, [
          searchElement,
          fromIndex,
        ]);
      };
    }

    try {
      stripe = maybeStripe(apiKey, ...restArgs);
    } finally {
      if (skipHttpsCheck) {
        // eslint-disable-next-line no-extend-native
        Array.prototype.indexOf = originalIndexOf;
      }
    }

    return stripe;
  });
}

export const getStripe = async (): Promise<Stripe> => {
  if (stripe) return stripe;

  const isIOSAppBuild = getIsApp() && getIOSVersion();
  return loadStripe(STRIPE_JS_KEY, isIOSAppBuild);
};
