import { API_ROOT } from '../../../config';
import { fetchAllPages } from '../../../modules/fetchAllPages';
import type { WorkPeriod } from '../../../modules/provider/WorkPeriod';
import SimpleCache from '../../../modules/SimpleCache';
import ssFetch, { ssFetchJSON } from '../../../modules/ssFetch';
import type { SecondsTimeString24Hr, DateString } from '../../../types';

export type WeekdayOrdinal = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export enum WorkPeriodPriority {
  OneDay = 1,
  Regular = 2,
}

export type ApiWorkPeriod = {
  day_of_week: WeekdayOrdinal;
  start_time: SecondsTimeString24Hr;
  end_time: SecondsTimeString24Hr;
  priority: WorkPeriodPriority;
  start_date: DateString;
  end_date: DateString;
  id: number;
};

export interface ICurrentStateWorkPeriodsResponse {
  current: ApiWorkPeriod[];
  upcoming: ApiWorkPeriod[];
  oneday: ApiWorkPeriod[];
}

const WorkPeriodCache = new SimpleCache(900);

export function clearCache() {
  WorkPeriodCache.clear();
}

export async function createWorkPeriods(
  providerId: number,
  workPeriods: WorkPeriod[],
): Promise<ApiWorkPeriod[]> {
  const url = new URL(`/api/v2/providers/${providerId}/workperiods`, API_ROOT);
  clearCache();
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    body: workPeriods,
  });
}

export async function deleteWorkPeriod(providerId: number, workPeriodId: number) {
  const url = new URL(`/api/v2/providers/${providerId}/workperiods/${workPeriodId}`, API_ROOT);
  clearCache();
  return ssFetch(url.toString(), {
    method: 'DELETE',
  });
}

export async function deleteManyWorkPeriods(providerId: number, workPeriodIds: number[]) {
  const url = new URL(`/api/v2/providers/${providerId}/workperiods`, API_ROOT);
  clearCache();
  return ssFetch(url.toString(), {
    method: 'DELETE',
    body: { ids: workPeriodIds },
  });
}

export async function getWorkPeriodsCurrentState(
  providerId: number,
): Promise<ICurrentStateWorkPeriodsResponse> {
  const url = new URL(`/api/v2/providers/${providerId}/workperiods/currentstate`, API_ROOT);
  return ssFetchJSON(url.toString());
}

export async function getAllWorkPeriods(providerId: number): Promise<ApiWorkPeriod[]> {
  const url = new URL(`/api/v2/providers/${providerId}/workperiods`, API_ROOT);
  return fetchAllPages(url.toString(), { ssCache: WorkPeriodCache });
}
