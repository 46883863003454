// @ts-strict-ignore
import { API_ROOT } from '../../../config';
import ssFetch, { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';
import { GalleryImageType, IGalleryImage } from './types';
import { buildImageQueryUrl } from './buildUrl';
import { galleryImageCache } from './cache';

type GalleryImagesParams = {
  next?: string;
  page?: number;
  serviceId?: number | string;
  showServiceImageOnly?: boolean;
};

/**
 * Gets a list of gallery images associated with the given provider
 * @param providerId The provider ID to use
 * @param next The URL to retrieve a subsequent page of images
 * @returns A list wrapper structure containing the images requested
 */
export async function getProviderGalleryImages(
  providerId: number | string,
  opts: GalleryImagesParams = {},
  withCache: boolean = true,
): Promise<IDRFResponse<IGalleryImage>> {
  let finalUrl: string = opts.next;

  if (!opts.next) {
    const uri = new URL(`${API_ROOT}/api/v2/providers/${providerId}/gallery_images`);

    if (opts.showServiceImageOnly) {
      uri.searchParams.append('show_service_image_only', String(opts.showServiceImageOnly));
    } else {
      uri.searchParams.append('page', String(opts.page || 1));

      if (opts.serviceId) {
        uri.searchParams.append('service_id', String(opts.serviceId));
      }
    }

    finalUrl = uri.toString();
  }
  return ssFetchJSON<IDRFResponse<IGalleryImage>>(
    finalUrl,
    {
      method: 'GET',
      ssCache: withCache ? galleryImageCache : false,
    },
  );
}

/**
 * Gets a single gallery image associated with a provider
 * @param providerId The provider ID to use
 * @param imageId The ID of the image to retrieve
 * @returns The image record requested
 */
export async function getProviderGalleryImage(
  providerId: number | string,
  imageId: number | string,
): Promise<IGalleryImage> {
  return ssFetchJSON<IGalleryImage>(
    `/api/v2/providers/${providerId}/gallery_images/${imageId}`,
    { method: 'GET' },
  );
}

export function loadImages(
  type: GalleryImageType,
  providerId: number,
  authToken?: string,
): Promise<Array<IGalleryImage>> {
  const listUrl = buildImageQueryUrl(type, providerId, undefined, authToken);

  return ssFetch<IDRFResponse<IGalleryImage>>(listUrl)
    .then(response => response.json())
    .then(data => data.results);
}
