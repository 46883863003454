// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import { ICheckoutSettingsData } from '../../modules/Api.types';
import { getCheckoutSettings, updateCheckoutSettings } from '../../api/Providers/CheckoutSettings';
import type { RootState, RootModel } from '../models';

type CheckoutSettingsPayload = {
  providerId: number;
  settings: ICheckoutSettingsData;
};

type CheckoutFailedPayload = { providerId: number | string; errorMessage?: string };

type CheckoutSettingsErrorState = {
  error: true;
  errorMessage?: string;
};

type CheckoutSettingsLoadedState = ICheckoutSettingsData & {
  error: false;
  errorMessage?: string;
};

type CheckoutSettingsEntry = CheckoutSettingsLoadedState | CheckoutSettingsErrorState;

export type CheckoutSettingState = {
  [id: number]: CheckoutSettingsEntry;
};

const ProviderCheckoutSettings = createModel<RootModel>()({
  name: 'proCheckoutSettings',

  state: {} as CheckoutSettingState,

  reducers: {
    onPatched: produce<CheckoutSettingState, [CheckoutSettingsPayload]>((
      state: CheckoutSettingState,
      payload: CheckoutSettingsPayload,
    ) => {
      const {
        providerId: id,
        settings = {},
      } = payload;
      state[id] = {
        service_tax_rate: '0.00',
        product_tax_rate: '0.00',
        client_service_rate: '0.00',
        ...state[id],
        ...(Object.keys(settings).reduce((result, settingKey) => ({
          ...result,
          [settingKey]: Number(settings[settingKey]),
        }), {})),
        error: false,
      };
    }),

    onLoaded: produce<CheckoutSettingState, [CheckoutSettingsPayload]>((
      state: CheckoutSettingState,
      payload: CheckoutSettingsPayload,
    ) => {
      const {
        providerId: id,
        settings = {},
      } = payload;
      state[id] = {
        service_tax_rate: '0.00',
        product_tax_rate: '0.00',
        client_service_rate: '0.00',
        ...(Object.keys(settings).reduce((result, settingKey) => ({
          ...result,
          [settingKey]: Number(settings[settingKey]),
        }), {})),
        error: false,
      };
    }),

    onFailed: produce<CheckoutSettingState, [CheckoutFailedPayload]>((
      state: CheckoutSettingState,
      payload: CheckoutFailedPayload,
    ) => {
      const {
        providerId,
        errorMessage,
      } = payload;
      state[providerId] = {
        error: true,
        errorMessage,
      };
    }),
  },

  effects: dispatch => ({
    load: async (
      payload: { providerId: number; skipCache?: boolean },
      rootState: RootState,
    ): Promise<ICheckoutSettingsData | undefined> => {
      const existingCheckoutSettings = rootState.proCheckoutSettings[payload.providerId];

      // if the pro data is already loaded and no error occurred
      // we don't need to load it again
      if (
        !payload.skipCache
        && existingCheckoutSettings
        && existingCheckoutSettings?.error === false
      ) {
        return existingCheckoutSettings;
      }

      try {
        const checkoutSettingData = await getCheckoutSettings(payload.providerId);
        dispatch.proCheckoutSettings.onLoaded({
          providerId: payload.providerId,
          settings: checkoutSettingData,
        });
        return checkoutSettingData;
      } catch (e) {
        dispatch.proCheckoutSettings.onFailed({
          providerId: payload.providerId,
          errorMessage: e.message,
        });
      }
      return undefined;
    },

    update: async (
      payload: CheckoutSettingsPayload,
    ): Promise<ICheckoutSettingsData | undefined> => {
      try {
        const updatedSettings = await updateCheckoutSettings(payload.providerId, payload.settings);
        dispatch.proCheckoutSettings.onPatched({
          providerId: payload.providerId,
          settings: updatedSettings,
        });
        return updatedSettings;
      } catch (e) {
        dispatch.proCheckoutSettings.onFailed({
          providerId: payload.providerId,
          errorMessage: e?.message,
        });
      }
      return undefined;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    forProvider: hasProps((_, props: { providerId: number }) => createSelector(
      slice,
      (state: CheckoutSettingState): CheckoutSettingsEntry => state[props.providerId],
    )),
    all() {
      return slice;
    },
  }),
});

export default ProviderCheckoutSettings;
