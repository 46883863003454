// @ts-strict-ignore
import { ssFetchJSON } from '../../modules/ssFetch';
import SimpleCache from '../../modules/SimpleCache';
import { API_ROOT } from '../../config';
import { PublicProvider } from '../Providers';
import { BaseProvider, ProviderOrSalonLocation } from '../Providers/Providers';

const salonCache = new SimpleCache(60 * 60 * 12); // 12 hours

export enum SubType {
  Unknown = 0,
  Renter = 1,
  Employee = 2,
  Owner = 3,
  Salon = 4,
  Receptionist = 5,
}

// see LocationProviderSerializer
export type SalonSubProvider = BaseProvider & {
  role: SubType | null;
};

// see SalonSerializer
export type PublicSalon = PublicProvider & ProviderOrSalonLocation & {
  providers: SalonSubProvider[];
};

/**
 * Executes a "V2" request to get salon information, with a 12 hour cache
 * @param {Number} salonId The ID of the salon to load
 */
export function getSalon(salonId): Promise<PublicSalon> {
  return ssFetchJSON<PublicSalon>(`${API_ROOT}/api/v2/salons/${salonId}`, { ssCache: salonCache });
}

/**
 * Clears the cache wrapping the `getSalon` function (the default export)
 */
export function clearCache() {
  salonCache.clear();
}

export default getSalon;
