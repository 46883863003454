export enum MultiFactorAuthMethod {
  SMS = 1,
  Email = 2,
}

export type MultiFactorAuthChoiceType = [MultiFactorAuthMethod, string];

export enum MultiFactorAuthStatus {
  SelectDeliveryMethod = 'SELECT_DELIVERY_METHOD',
  New = 'CREATED_AND_SENT',
  Expired = 'INVALID_MUST_RESTART',
  NoAttemptsLeft = 'INVALID_MUST_RESTART',
  TryAgain = 'INVALID_CAN_RETRY',
  ErrorInvalidDeliveryMethod = 'USER_ERROR',
}
