// @ts-strict-ignore
// Per https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import {
  useEffect,
  useRef,
  useState,
} from 'react';

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();
  const [intervalValue, setIntervalValue] = useState(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      setIntervalValue(id);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);

  return intervalValue;
}
