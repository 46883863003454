// @ts-strict-ignore
import moment from 'moment';
import _ from 'underscore';
import { copyValue } from '../utils/copyValue';
import { loopOverDatasets } from '../utils/loopOverDatasets';
import {
  DataFields,
  DataSet,
  GraphableDataSet,
  MasterDataSet,
  StatDataSet,
} from '../types';

export function groupDatasetBy<F extends DataFields = DataFields>(
  dataset: StatDataSet<F>,
  type: 'week' | 'month' | 'year',
): MasterDataSet[F];

export function groupDatasetBy<F extends DataFields = DataFields>(
  dataset: MasterDataSet<F>,
  type: 'week' | 'month' | 'year',
): MasterDataSet<F>;

export function groupDatasetBy(
  dataset: GraphableDataSet,
  type: 'week' | 'month' | 'year',
): GraphableDataSet;

export function groupDatasetBy<F extends DataFields = DataFields>(
  dataset: Record<string, StatDataSet<F>>,
  type: 'week' | 'month' | 'year',
): Record<string, StatDataSet<F>>;

/**
 * Group the dataset by a specific type of range: month, year, etc
 *
 * For example:
 * ```
 *   var groups = groupBy(data, 'month');
 * ```
 *
 * @param {Object} dataset The dataset returned by the API.
 * @param {String} type How to group the data ("week", month", "year")
 *
 * @returns {Object} An updated dataset object
 */
export function groupDatasetBy<T extends DataSet = DataSet>(
  dataset: T,
  type: 'week' | 'month' | 'year',
) {
  const retDataset = [];
  let groupValue: { date?: moment.Moment } = {};
  let groupDate = null;

  // Recursive call for all value sets
  if (!Array.isArray(dataset)) {
    return (
      loopOverDatasets(dataset, valueSet => groupDatasetBy(valueSet, type))
    );
  }

  // Merge two dataset values
  function merge(val1: any, val2: any) {
    if (!val2) {
      return val1;
    }
    if (typeof val1 === 'number') {
      return val1 + val2;
    }
    if (!val1 && typeof val2 === 'number') {
      return val2;
    }
    if (typeof val1 === 'object') {
      const keys = _.union(Object.keys(val1), Object.keys(val2));
      // Add all the matching property values
      keys.forEach(key => {
        // eslint-disable-next-line no-param-reassign
        val1[key] = merge(val1[key], val2[key]);
      });
    }
    return val1;
  }

  // Check if these dates are in the same group
  function shouldGroup(date1, date2) {
    if (!date1 || !date2) {
      return false;
    }

    const diff = date1.diff(date2, 'days');
    if (date1.isSame(date2)) {
      return true;
    }
    if (type === 'year' && date1.year() === date2.year()) {
      return true;
    }
    if (type === 'month' && date1.month() === date2.month() && date1.year() === date2.year()) {
      return true;
    }
    if (type === 'week' && date1.week() === date2.week() && Math.abs(diff) <= 7) {
      return true;
    }

    return false;
  }

  // Loop through data
  dataset.forEach(value => {
    // No date, skip
    if (typeof value.date === 'undefined') {
      return;
    }

    // Start new grouping
    if (!groupDate || !shouldGroup(groupDate, value.date)) {
      groupDate = moment(value.date).startOf(type);
      groupValue = copyValue(value);
      groupValue.date = groupDate;

      retDataset.push(groupValue);
    } else {
      // Merge this value into the current group
      merge(groupValue, value);
    }
  });

  return (retDataset);
}
