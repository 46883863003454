import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';
import SimpleCache from '../../../modules/SimpleCache';
import { WhyOptions } from '../../../components/provider/appointment/NcdAcceptanceDeclineModal/modals/NCDAppointmentDeclineQuestionnaireModal/constants';
import { DepositStatus } from './Appointments';

interface InternalNcdAppointmentCancellationReason {
  text: string;
  internal_name: string;
  comms_enabled: boolean;
}

const cancellationReasonsRequestCache = new SimpleCache(600); // cache for 10 min

export function cancelAppointmentById(
  providerId: number,
  appointmentId: number,
  cancelAll: boolean = false,
  reason?: string,
): Promise<unknown> {
  const url = new URL(`/api/v2.1/providers/${providerId}/appointments/${appointmentId}/cancellations`, API_ROOT);
  const body: {
    cancel_all?: boolean;
    ncd_cancellation_survey_reason?: string;
  } = cancelAll ? { cancel_all: !!cancelAll } : {};

  if (reason) {
    body.ncd_cancellation_survey_reason = reason;
  }

  return ssFetchJSON(url.toString(), {
    method: 'POST',
    body,
  });
}

interface DeclineNCDAppointmentResponse {
  deposit_status: DepositStatus | null;
  success: true;
}

export function declineNcdAppointmentById(
  providerId: number,
  appointmentId: number,
  declineReason: WhyOptions,
): Promise<DeclineNCDAppointmentResponse> {
  const url = new URL(`/api/v2.1/providers/${providerId}/appointments/${appointmentId}/ncdc_decline`, API_ROOT);
  return ssFetchJSON<DeclineNCDAppointmentResponse>(url.toString(), {
    method: 'POST',
    includeResponseOnError: true,
    body: {
      ncd_decline_reason: declineReason,
    },
  });
}

interface AcceptNCDAppointmentResponse {
  deposit_status: DepositStatus | null;
  success: true;
}

export function acceptNcdAppointmentById(
  providerId: number,
  appointmentId: number,
): Promise<AcceptNCDAppointmentResponse> {
  const url = new URL(`/api/v2.1/providers/${providerId}/appointments/${appointmentId}/ncdc_accept`, API_ROOT);
  return ssFetchJSON<AcceptNCDAppointmentResponse>(url.toString(), {
    method: 'POST',
    includeResponseOnError: true,
  });
}

export function getNcdAppointmentCancellationReasons(
  cache: SimpleCache = cancellationReasonsRequestCache,
): Promise<InternalNcdAppointmentCancellationReason[]> {
  const url = new URL('api/v1/ncd/cancellation-reasons', API_ROOT);
  return ssFetchJSON<InternalNcdAppointmentCancellationReason[]>(url.toString(), {
    method: 'GET',
    ...(cache && { ssCache: cache }),
  });
}
