// @ts-strict-ignore
/* eslint-disable no-param-reassign */
import { createModel } from '@rematch/core';
import produce from 'immer';
import { getTaskStatus, UploadProgressState } from '../../api/Providers/GalleryImages';
import nonCriticalException from '../../modules/exceptionLogger';

import type { RootModel } from '../models';

const WAIT_DELAY_MS = 500;

interface ProviderGalleryState {
  providerId: number | null;
  tasksByStatus: Record<UploadProgressState, string[]>;
  uploadTasks: string[];
  uploadErrorMessage?: string;
}

function getInitialState(): ProviderGalleryState {
  return {
    providerId: null,
    tasksByStatus: {
      [UploadProgressState.Error]: [],
      [UploadProgressState.Pending]: [],
      [UploadProgressState.Success]: [],
    },
    uploadTasks: [],
  };
}

interface OnUploadStartPayload {
  providerId: number;
  tasks: string[];
}

interface BulkUploadPayload {
  providerId: number;
  tasks: string[];
  timeout?: number;
  providerVanity?: string;
}

export const providerGallery = createModel<RootModel>()({
  name: 'providerGallery',
  state: getInitialState(),

  reducers: {
    onUploadStart: (_: ProviderGalleryState, {
      providerId,
      tasks,
    }: OnUploadStartPayload) => ({
      providerId,
      uploadTasks: [...tasks],
      tasksByStatus: {
        [UploadProgressState.Error]: [],
        [UploadProgressState.Pending]: [...tasks],
        [UploadProgressState.Success]: [],
      },
    }),

    onUploadProgress: produce<ProviderGalleryState, [Record<string, UploadProgressState>]>((
      state: ProviderGalleryState,
      payload: Record<string, UploadProgressState>,
    ) => {
      const pendingTasks = [];
      const successTasks = [];
      const errorTasks = [];

      Object.entries(payload).forEach(([taskId, status]) => {
        if (status === UploadProgressState.Success) {
          successTasks.push(taskId);
        } else if (status === UploadProgressState.Error) {
          errorTasks.push(taskId);
        } else {
          pendingTasks.push(taskId);
        }
      });

      state.tasksByStatus = {
        [UploadProgressState.Success]: successTasks,
        [UploadProgressState.Error]: errorTasks,
        [UploadProgressState.Pending]: pendingTasks,
      };

      state.uploadTasks = Object.keys(payload);
    }),

    onUploadComplete: (state: ProviderGalleryState, errorMessage: string) => ({
      ...state,
      uploadErrorMessage: errorMessage,
    }),
  },

  effects: dispatch => ({
    bulkUpload: ({
      providerId,
      tasks,
      timeout,
      providerVanity,
    }: BulkUploadPayload): Promise<void> => {
      const startTime = new Date().getTime();

      dispatch.providerGallery.onUploadStart({
        providerId,
        tasks,
      });

      return new Promise<void>((resolve, reject) => {
        function completeUpload(errorMessage?: string) {
          dispatch.providerGallery.onUploadComplete(errorMessage || '');

          if (providerVanity) {
            dispatch.profileGalleries.loadProfileGallery({
              providerVanity,
              noCache: true,
            });
          }

          if (errorMessage) {
            reject(new Error(errorMessage));
          } else {
            resolve();
          }
        }

        function updateProgress() {
          const elapsedSec = (new Date().getTime() - startTime) / 1000;
          const isTimedOut = timeout && timeout < elapsedSec;
          if (isTimedOut) {
            completeUpload('Uploads timed out');
            return;
          }
          if (tasks.length) {
            let finished: boolean = false;

            getTaskStatus(tasks).then(
              response => {
                dispatch.providerGallery.onUploadProgress(response.tasks);

                // if there aren't any pending tasks, we're done here
                if (!Object.values(response.tasks).some(
                  (status => status === UploadProgressState.Pending),
                )) {
                  finished = true;
                  completeUpload();
                }
              },
              reason => {
                nonCriticalException(`Failed to update progress: ${reason}`);
              },
            ).finally(() => {
              if (!finished) {
                setTimeout(updateProgress, WAIT_DELAY_MS);
              }
            });
          } else {
            completeUpload();
          }
        }

        updateProgress();
      });
    },
  }),

  selectors: (slice, createSelector) => ({
    hasErrors: () => createSelector(
      slice,
      (state: ProviderGalleryState): boolean => (
        state.tasksByStatus[UploadProgressState.Error].length > 0
        || !!state.uploadErrorMessage
      ),
    ),

    isFinished: () => createSelector(
      slice,
      (state: ProviderGalleryState): boolean => (
        state.tasksByStatus[UploadProgressState.Pending].length <= 0
      ),
    ),
  }),
});
