// @ts-strict-ignore
/**
 * A holder for info about the user's initial landing, e.g. referrer, URL.
 */

const SESSION_STORAGE_LANDING_INFO_KEY = 'ss_landing_info';

export type LandingInfo = {
  page: string;
  url: string;
  referrer: string;
};

let info = null;

export function setLandingInfo(landingInfo: LandingInfo) {
  info = landingInfo;
  sessionStorage.setItem(SESSION_STORAGE_LANDING_INFO_KEY, JSON.stringify(info));
}

export function getLandingInfo(): LandingInfo {
  if (!info) {
    const infoFromSession = sessionStorage.getItem(SESSION_STORAGE_LANDING_INFO_KEY);
    if (infoFromSession) {
      info = JSON.parse(infoFromSession);
    }
  }
  return info;
}
