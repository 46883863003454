/* eslint-disable no-param-reassign */
import { createModel } from '@rematch/core';

import type { RootModel } from '../models';
import { getNcdAppointmentCancellationReasons } from '../../api/Providers/Appointments/AppointmentsCancellation';

export type NCDProviderCancellationReason = {
  text: string;
  value: string;
};

export interface NCDProviderCancellationReasonsState {
  reasons: NCDProviderCancellationReason[];
  loading: boolean;
}

function getInitialState(): NCDProviderCancellationReasonsState {
  return {
    reasons: [],
    loading: true,
  };
}

export const ncdAppointmentCancellationReasons = createModel<RootModel>()({
  name: 'ncdAppointmentCancellationReasons',
  state: getInitialState(),

  reducers: {
    onStartLoading: (state: NCDProviderCancellationReasonsState) => ({ ...state, loading: true }),
    onFinishLoading:
        (state: NCDProviderCancellationReasonsState, reasons: NCDProviderCancellationReason[]) => ({
          ...state, reasons, loading: false,
        }),
  },

  effects: dispatch => ({
    async load(): Promise<void> {
      dispatch.ncdAppointmentCancellationReasons.onStartLoading();

      const reasons = await getNcdAppointmentCancellationReasons();
      dispatch.ncdAppointmentCancellationReasons.onFinishLoading(reasons.map(reason => ({
        text: reason.text,
        value: reason.internal_name,
      })));
    },
  }),

  selectors: (slice, createSelector) => ({
    reasons: () => createSelector(
      slice,
      (
        state: NCDProviderCancellationReasonsState,
      ): NCDProviderCancellationReason[] => state.reasons,
    ),
  }),
});
