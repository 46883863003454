import moment from 'moment';
import FeatureFlags from '../../../FeatureFlags';
import { PROVIDER_REFERRAL_PROGRAM_MVP_FLAG_NAME } from '../../Refer';

export interface AmbassadorToolkitNotification {
  type: 'ambassador_toolkit_announcement';
  uuid: 'ambassador_toolkit_announcement';
  creation_time: '2020-12-03T23:00:00Z';
  persistent: true;
  providerId: number;
}

export const shouldShowAmbassadorToolkitNotification = async () => {
  const isEligibleDate = moment.utc() < moment.utc('2020-12-18T18:00:00Z');
  const isInTest = await FeatureFlags.isEnabled(PROVIDER_REFERRAL_PROGRAM_MVP_FLAG_NAME);

  return isInTest && isEligibleDate;
};

export const createAmbassadorToolkitNotification = (
  providerId: number,
): AmbassadorToolkitNotification => ({
  type: 'ambassador_toolkit_announcement',
  uuid: 'ambassador_toolkit_announcement',
  creation_time: '2020-12-03T23:00:00Z',
  persistent: true,
  providerId,
});
