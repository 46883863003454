// @ts-strict-ignore
import {
  getProProfileClientView,
  ClientProviderProfile,
  ProfileClientViewDeleted,
  getProfileLocation,
  getBusinessName,
} from '../../../api/ProfileClientView/ProfileClientView';
import { getServiceGroups, ProviderServiceGroup } from '../../../api/Providers/ServiceGroups';
import { PublicProviderProfile } from '../../Api.types';
import { SITE_ROOT } from '../../../config';
import type { ICrossPromotedProvider } from '../../../components/shared/providerProfile/CrossPromotion';

import type { ISearchResult } from '../../consumer/search/Search';
import { getProviderGalleryImages, IGalleryImage } from '../../../api/Providers/GalleryImages';
import { PublicProvider } from '../../../api/Providers';
import type { RatingsSummary } from '../../../store/RatingsSummary.model';

export type RelatedSalon = Omit<ICrossPromotedProvider, 'total_duration_minutes' | 'cost' | 'service_id' | 'service_name' | 'show_and_up'>;

export type ProfilePageData = {
  profile: ClientProviderProfile;
  serviceGroups: ProviderServiceGroup[];
  galleryImages: IGalleryImage[];
  galleryImagesCount: number;
  relatedSalons?: RelatedSalon[];
};

export const isVanityUrl = (proIdOrVanity: number | string): boolean => (
  Number.isNaN(Number(proIdOrVanity))
);

/**
 * Loads the view only profile data for endpoints that are not
 * a redux model
 * returns a FullProfile without the functions added by the Profile class
 * (profile + services + gallery images)
 * @param proId
 * @param routeName
 */
export const loadProfileViewData = async (
  proId: number | string,
  routeName?: string,
): Promise<ProfilePageData | ProfileClientViewDeleted> => {
  let relatedSalons: RelatedSalon[] = [];
  const profilePromise = getProProfileClientView(proId);
  let providerId = Number(proId);

  // Handle vanity URL by loading provider first
  if (isVanityUrl(proId)) {
    const loadedProfile = await profilePromise;
    providerId = loadedProfile.id;
  }

  const [
    // if we awaited the provider promise above it will
    // resolve without making a new request
    profile,
    serviceGroupsResults,
    galleryImagesResults,
  ] = await Promise.all([
    profilePromise,
    getServiceGroups({ providerId }),
    getProviderGalleryImages(providerId),
  ]);

  if ('profile_deleted_time' in profile) {
    return profile;
  }

  if (profile.is_disabled) {
    // imported here due to dependency cycle
    // eslint-disable-next-line global-require
    const RelatedSalons = require('../../consumer/search/RelatedSalons').default;
    const relatedSalonResults: ISearchResult[] = await RelatedSalons.getRelatedSalonsForPro(
      profile.id,
      { origin: routeName },
    );
    relatedSalons = relatedSalonResults.map((result: ISearchResult) => ({
      provider_id: result.provider_id,
      average_rating: result.average_rating,
      num_ratings: result.num_ratings,
      name: result.salon_name,
      vanity_url: result.vanity_url || '',
      profile_photo: result.profile_photo || '',
      profession: result.profession,
      service_id: undefined,
      service_name: undefined,
      total_duration_minutes: undefined,
      cost: undefined,
      show_and_up: undefined,
      city_state: `${result.location.city}, ${result.location.state}`,
    }));
  }

  return {
    profile,
    serviceGroups: serviceGroupsResults?.results || [],
    galleryImages: galleryImagesResults.results,
    galleryImagesCount: galleryImagesResults.count,
    relatedSalons,
  };
};

const generateProfileMetaTitle = (profile: Pick<PublicProvider, 'name' | 'profession'>) => {
  // apparently google trims after 65, so we should do the trimming instead
  const MAX_TITLE_LENGTH = 65;
  const {
    name,
    profession,
  } = profile;
  let title = `${name} ${profession} | Book Online with StyleSeat`;

  if (title.length > MAX_TITLE_LENGTH) {
    // their name is still important, even if name and profession make the title too long
    title = `${name} | Book Online with StyleSeat`;
  }

  if (title.length > MAX_TITLE_LENGTH) {
    // if they just have a really long name for some reason
    title = `${name.slice(0, 53)} | StyleSeat`;
  }

  return title;
};

const getProfileMetaPhoto = (
  profile: Pick<PublicProvider, 'profile_photo'>,
  galleryImages: IGalleryImage[],
) => {
  const profilePhoto = profile.profile_photo;

  if (profilePhoto) {
    return `${profilePhoto}_720x540.jpg`;
  }

  if (galleryImages.length > 0) {
    const [galleryImage] = galleryImages;
    return `${galleryImage.url}_720x540.jpg`;
  }
  return '';
};

export const getProfileMetaInfo = (
  profile: PublicProvider | ClientProviderProfile,
  galleryImages: Array<IGalleryImage>,
  shareOpts: { shareImage?: string; shareTitle?: string },
) => {
  const {
    name,
    profession,
  } = profile;
  const location = getProfileLocation(profile);

  const locationPart = (location?.city && location?.state) ? ` in ${location?.city}, ${location?.state}` : '';
  const description = `Book online with ${name}, a ${profession}${locationPart}. See reviews, services, and pictures of ${name}’s work. Book Now.`;
  const canonicalUrl = `${SITE_ROOT}/m/v/${profile.vanity_url}`;

  const metaTitle = shareOpts.shareTitle ? decodeURI(shareOpts.shareTitle) : (
    generateProfileMetaTitle(profile)
  );

  const metaImage = shareOpts.shareImage ? decodeURI(shareOpts.shareImage) : (
    getProfileMetaPhoto(profile, galleryImages)
  );

  return {
    title: metaTitle,
    description,
    type: 'business.business',
    image: metaImage,
    canonical: canonicalUrl,
    robots: 'index, follow',
    twitterCard: 'summary_large_image',
  };
};

export type ProviderProfileForReviews = Pick<ClientProviderProfile, 'id' | 'rating_stars' | 'ratings_count' | 'ratings_count_by_stars' | 'name' | 'profile_photo' | 'business_name' | 'is_disabled' | 'smart_pricing_enabled' | 'vanity_url' | 'is_effectively_online_bookable'>;

/**
 * Converts a privileged profile to a subset of the client profile suitable for reviews
 * @param profile The profile to convert
 * @param ratingsSummary Ratings information to merge into the resulting profile
 * @returns A profile object suitable for displaying reviews
 */
export function toClientProfile(
  profile: PublicProviderProfile,
  ratingsSummary?: RatingsSummary,
): ProviderProfileForReviews {
  const businessName = getBusinessName(profile);
  return {
    ...profile,
    ratings_count: ratingsSummary?.total || 0,
    ratings_count_by_stars: ratingsSummary?.stars || {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
    business_name: businessName || profile.name,
  };
}
