import {
  TopProCriteria,
  TopProCriteriaNames,
  TopProSummaryStats,
} from '../api/Providers/TopPro';
import { TopProMonthlyStats } from './ProviderTopPro.model.types';

const percentageFields = [
  TopProCriteriaNames.NcdAcceptanceRate,
  TopProCriteriaNames.ProviderCancellationRate,
];

// flatten criteria from a list of criteria to a single dictionary keyed by criteria name
const parseCriteria = (criteria: TopProCriteria[]) => criteria.reduce((acc, curr) => ({
  ...acc,
  [curr.name]: {
    value: percentageFields.includes(curr.name)
      ? (Number(curr.value) * 100).toFixed(0).toString()
      : curr.value,
    isTopPro: curr.top_pro,
  },
}), {});

export const parseTopProStats = (stats: TopProSummaryStats[]): TopProMonthlyStats[] => {
  const parsed = stats.map(stat => ({
    providerId: stat.provider_id,
    month: stat.month,
    isTopPro: stat.top_pro,
    criteria: parseCriteria(stat.criteria),
  }));

  return parsed;
};

/**
 * Pros will be shown a celebration modal when they visit their home page upon attaining
 * Top Pro status if they did not qualify as one the previous month
 *
 * @returns boolean: if the pro attained Top Pro this month, and was not one the previous
 */
export const attainedTopProThisMonth = (stats: TopProSummaryStats[]): boolean => {
  const isCurrentlyTopPro = stats[1]?.top_pro || false;
  const wasTopProLastMonth = stats[2]?.top_pro || false;

  return isCurrentlyTopPro && !wasTopProLastMonth;
};
