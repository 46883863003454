// @ts-strict-ignore
/* eslint-disable */
import * as Cookies from 'js-cookie';
import { subscribe } from 'redux-subscriber';
// These all need to be relative imports, as this module is used by static pages
import SimpleCache from '../SimpleCache';
import promiseBreaker from '../promiseBreaker';
import { API_ROOT } from '../../config';
import { trackingIdReady } from '../trackingIdReady';
import storeReady from '../../store/ready';
import type { RootState } from '../../store/models';
import { serializeParams } from './params';
import { Platform } from 'react-native';

/**
 * A lightweight StyleSeat wrapper to fetch.
 * If the user is logged in, all requests are made with the user's Authorization token.
 *
 * Example:
 * --------
 *
 * ```
 *   import ssFetch from 'modules/ssFetch';
 *
 *   ssFetch('/accounts/whoami/')
 *     .then(response => response.json())
 *     .then(json => {
 *       console.log('Whoami JSON response', json);
 *     });
 * ```
 *
 * Caching
 * -------
 * Use the `ssCache` option to enable caching on GET requests. Possible values:
 *
 *    - `true`    - Will cache the response for the default number of seconds (DEFAULT_CACHE_SECS).
 *    - <seconds> - Pass the number of seconds you want the response cached for.
 *    - SimpleCache - An instance of SimpleCache you want to use for caching.
 *
 * ```
 *   // Default caching
 *   ssFetch('/accounts/whoami/', { ssCache: true })
 *
 *   // Cache for 30 seconds
 *   ssFetch('/v2/pro/32/some/api', { ssCache: 30 })
 * ```
 *
 * More info on fetch:
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 */

/**
 * Options argument for the ssFetch call
 */
export interface SSFetchOptions extends RequestInit {
  headers?: Headers | { [key: string]: string };
  ssCache?: boolean | SimpleCache | number;
  form?: Record<string, any>;
  params?: Record<string, any>;
  body?: BodyInit | Record<string, any> | any;
  throwOnHttpError?: boolean;
  useFormContentType?: boolean;
  addRequestedWith?: boolean;
  noAuthHeader?: boolean;
  includeCsrfToken?: boolean | undefined;
}

type SSFetchJSONOptions = SSFetchOptions & {
  includeResponseOnError?: boolean;
};

/**
 * Represents an HTTP request to be made to a specific resource
 */
export interface SSRequest extends SSFetchOptions {
  url: string;
  headers: Headers;
}

export class SSFetchError extends Error {
  public data: unknown;
  public code: number | string;

  constructor(message: string, code: number | string, data: unknown) {
    super(message);
    this.name = 'SSFetchError';
    this.code = code;
    this.data = data;
  }
}

export interface IResponse<T = any> extends Response {
  ok: boolean;

  json<Q = T>(): Promise<Q>;
}

export function isResponse<T = any>(e: unknown): e is IResponse<T> {
  const cast = e as IResponse<T>;

  return (
    typeof e === 'object'
    && 'status' in e && typeof cast.status === 'number'
    && 'json' in e && typeof cast.json === 'function'
  );
}

type AbortablePromise<T = any> = Promise<T> & {
  abortCntrl: AbortController;
}

type RequestInterceptor = (req: SSRequest) => SSRequest;
type ResponseInterceptor<T = any> = (res: IResponse<T>) => IResponse<T> | Promise<IResponse<T>>;

export type InterceptorConfig<T = any> = {
  request?: RequestInterceptor;
  response?: ResponseInterceptor<T>;
};

export type Interceptors = {
  request: RequestInterceptor[];
  response: ResponseInterceptor[];
};

/**
 * The token for making authenticated requests to StyleSeat's API.
 */
let authToken: string | undefined;
subscribe('user.auth_token', (state: RootState) => {
  authToken = state?.user?.auth_token;
});

/**
 * The UUID used for analytics/tracking in the current browser.
 */
let trackingId: string | null | undefined;
subscribe('trackingId.trackingId', (state: RootState) => {
  trackingId = state?.trackingId?.trackingId;
});

const interceptors: Interceptors = {
  request: [],
  response: [],
};

const DEFAULT_CACHE_SECS = 10;
const DEFAULT_OPTS: SSFetchOptions = {
  method: 'GET',
  cache: 'no-cache',
  ssCache: false,
  credentials: 'same-origin',
  headers: new Headers({ 'Content-Type': 'application/json' }),
  includeCsrfToken: true,
};

export const ssFetchCache = new SimpleCache(DEFAULT_CACHE_SECS);

const apiPrefix = `${API_ROOT}/`;

const applyResponseInterceptors = async (response: IResponse) => {
  let result = response.clone();
  for (const interceptor of ssFetch.interceptors.response) {
    result = await interceptor(result);
  }

  return result;
};

const applyRequestInterceptors = (reqConfig: SSRequest) => {
  return ssFetch
    .interceptors
    .request
    .reduce<SSRequest>((result, interceptor) => (
      interceptor ? interceptor(result) : result
    ), reqConfig);
};

/**
 * Request a resource from the server with fetch.
 * @method ssFetch
 * @param {String} url - The URL to load
 * @param {Object} opts - Request options
 * @param {boolean|SimpleCache} opts.ssCache - Use cache. Either boolean or pass the SimpleCache
 *                                             object to use for this request. Even if you pass
 *                                             false, the response for GET requests will still be
 *                                             cached for future requests.
 * @param {Object | Headers} opts.headers - The headers to send
 * @param {Object} opts.form - The form data to send. This sets the content-type header accordingly.
 * @param {Boolean} opts.useFormContentType - True to set the content-type header to support a url
 * encoded form, otherwise false. Defaults to `true` when `opts.form` is also supplied.
 * @param {Boolean} opts.throwOnHttpError - True to throw an error if the response `ok` property is
 * false.
 * @param {Boolean} opts.addRequestedWith - Some old Django pages use a request.is_ajax() function
 * that fails if 'X-Requested-With' is absent or != 'XMLHttpRequest'
 * https://stackoverflow.com/questions/8587693/django-request-is-ajax-returning-false/27240729
 */
export function ssFetchRequest<T = any>(url: string, opts?: SSFetchOptions): Promise<IResponse<T>> {
  const options: SSRequest = {
    ...DEFAULT_OPTS,
    ...opts || {},
    headers: new Headers(opts?.headers || DEFAULT_OPTS.headers),
    url,
  };

  const useCache = !!options.ssCache;
  const {
    form,
    throwOnHttpError,
  } = options;
  const isGet = (String(options.method).toLowerCase() === 'get');
  let { useFormContentType } = options;

  // We use `ssCache` to not be confused with the native `cache` option
  if (typeof opts?.cache !== 'undefined' && typeof opts?.ssCache === 'undefined') {
    // eslint-disable-next-line no-console
    console.warn(`ssFetch: Did you really mean to use the ssCache option? ("cache" is not a supported option of ssFetch)
If this was intentional, set ssCache to a value (i.e. true) to silence this warning`);
  }

  if (typeof url !== 'string') {
    return Promise.reject(new Error('Invalid url.'));
  }

  if (form) {
    useFormContentType = true;
  }

  // Add API root if the URL starts with '/'
  if (API_ROOT && url[0] === '/') {
    options.url = `${API_ROOT}${url}`;
  }
  let cacheTime: number | undefined = DEFAULT_CACHE_SECS;
  let cacheInstance = ssFetchCache;
  let cacheKey = url;
  if (typeof options.ssCache === 'number') {
    cacheTime = options.ssCache;
  } else if (options.ssCache instanceof SimpleCache) {
    cacheTime = undefined;
    cacheInstance = options.ssCache;
  }

  const isStyleSeatRequest = options.url.indexOf(apiPrefix) === 0;
  if (
    options.includeCsrfToken
    // URL is to a StyleSeat API endpoint
    && isStyleSeatRequest
    // Is a non-CSRF exempt HTTP method
    && !(/^(GET|HEAD|OPTIONS|TRACE)$/i.test(String(options.method)))
  ) {
    options.headers.append('X-CSRFToken', Cookies.get('csrftoken'));
  }

  if (useFormContentType) {
    options.headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  }

  if (typeof form === 'object') {
    options.body = serializeParams(form);
  } else if (
    options.body
    && typeof options.body === 'object'
    && options.headers.get('Content-Type') === 'application/json'
  ) {
    // passed an object, want to send JSON, so stringify
    options.body = JSON.stringify(options.body);
  }

  if (isStyleSeatRequest) {
    // We don't have the tracking cookie in-app, so add it as a header
    if (trackingId) {
      options.headers.set('TKM', trackingId);
    }

    // Add auth token to header
    if (authToken && !options.noAuthHeader) {
      if (!options.headers.has('AUTHORIZATION')) {
        options.headers.set('AUTHORIZATION', `Token: ${authToken}`);
      }
      cacheKey = `${cacheKey}:${authToken}`;
    }
  }

  // Check cache
  if (useCache && isGet && cacheInstance.hasItem(cacheKey)) {
    const cachedResponse = cacheInstance.getItem(cacheKey);
    return responseCloner(cachedResponse);
  }

  // Abort controller
  let abortCntrl;
  if (AbortController) {
    abortCntrl = new AbortController();
    options.signal = abortCntrl.signal;
  }

  // add requested with
  if (options.addRequestedWith) {
    // some old Django pages add weird redirects if this field is absent
    options.headers.set('X-Requested-With', 'XMLHttpRequest');
  }

  // apply request interceptors
  const request = applyRequestInterceptors(options);

  // Make request
  const responsePromise = fetch(request.url, request);

  // Cache request - expire cache on failure. Even if ssCache is false, we want to cache the
  // response in case the next request passes ssCache as true.
  if (isGet) {
    const cacheReq = responseCloner<T>(responsePromise, throwOnHttpError);
    cacheInstance.addItem(cacheKey, cacheReq, cacheTime);

    // remove cache on error
    responsePromise.catch(() => {
      cacheInstance.expireItem(cacheKey);
    });
  }

  return responseCloner<T>(responsePromise, throwOnHttpError, abortCntrl);
}

/**
 * ssFetch requests cannot be made until these promises have resolved.
 */
const ssFetchReady = Promise.all([
  storeReady,
  trackingIdReady,
]);

/**
 * Main ssFetch entrypoint which waits for the system to be ready before
 * making the request.
 * @see ssFetchRequest
 */
function ssFetch<T = any>(url: string, opts?: SSFetchOptions) {
  return ssFetchReady.then(() => ssFetchRequest<T>(url, opts));
}

/**
 * Interceptors allow the reshaping of API requests and responses (in the future)
 */
ssFetch.interceptors = interceptors;
ssFetch.intercept = (interceptorConfig: InterceptorConfig) => {
  if ('request' in interceptorConfig) {
    ssFetch.interceptors.request.push(interceptorConfig.request);
  }

  if ('response' in interceptorConfig) {
    ssFetch.interceptors.response.push(interceptorConfig.response);
  }
};

ssFetch.resetInterceptors = () => {
  ssFetch.interceptors.request = [];
  ssFetch.interceptors.response = [];
};

/**
 * Wrap the fetch request object so it always return a clone of the responses, for caching purposes.
 *
 * This is because the body of the resulting response object, per the standard, can only be read
 * once and will, otherwise, throw an error. (more info: https://github.com/whatwg/fetch/issues/196)
 * If you have 5 unrelated calls to ssFetch for the same URL, the last 4 will be cached objects. If
 * they were all the same object, only the first one to call `.json()` would work, because the
 * stream closes after the first call.
 *
 * @param {Request} fetchResponse - The return object from a `fetch` call.
 * @param {Boolean} throwOnHttpError - True to throw an error if the response `ok` property is
 * false.
 * @param {AbortController} [abortCntrl] - The controller which can abort the request.
 *
 * @return {Promise} A promise which can be canceled (@see promiseBreaker)
 */
function responseCloner<T = any>(
  fetchResponse: AbortablePromise<IResponse<T>> | Promise<IResponse<T>>,
  throwOnHttpError?: boolean,
  abortCntrl?: AbortController,
) {
  // If no abort controller passed, the `fetchReq` might already have it.
  const useAbortCntrl = 'abortCntrl' in fetchResponse ? fetchResponse.abortCntrl : abortCntrl;

  const onCancel = () => {
    if (useAbortCntrl && useAbortCntrl.abort) {
      useAbortCntrl.abort();
    }
  };

  const response: Promise<IResponse<T>> & { abortCntrl?: AbortController } = promiseBreaker(
    (new Promise(async (resolve, reject) => {
      let response!: IResponse<T>;

      try {
        response = await fetchResponse;
      } catch (err) {
        if (!response) {
          return reject(err);
        } else {
          return reject(response.clone ? response.clone() : response);
        }
      }

      if (!response.ok && throwOnHttpError) {
        const err = new SSFetchError(response.statusText, response.status, response.statusText);
        try {
          const errorResponse = await applyResponseInterceptors(response);
          err.data = await errorResponse.json();
        } catch {
        }
        return reject(err);
      }

      return resolve(response.clone ? response.clone() : response);
    }).then(applyResponseInterceptors)
    ), { onCancel });

  response.abortCntrl = useAbortCntrl;

  return response;
}

/**
 * Wrapper around ssFetch which parses JSON and throws an error if the HTTP response is non-ok.
 *
 * @returns {Promise}
 * @param url
 * @param opts
 */
export async function ssFetchJSON<T = any>(url: string, opts?: SSFetchJSONOptions): Promise<T> {
  const { includeResponseOnError, ...ssFetchOpts } = opts || {};
  return ssFetch(url, ssFetchOpts)
    .then((res: IResponse<T>) => {
      if (!res.ok) {
        if (includeResponseOnError) {
          return Promise.reject(res);
        }
        return res.text().then(Promise.reject.bind(Promise));
      }
      return res.json();
    });
}

export default ssFetch;
