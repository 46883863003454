import ssFetch from '../../../modules/ssFetch';
import { clearProviderCache } from './cache';
import { IGalleryImage } from './types';
import { buildImageQueryUrl } from './buildUrl';

/**
 * Delete the image.
 */
export async function deleteImage(image: IGalleryImage, authToken?: string) {
  const deleteUrl = buildImageQueryUrl(image.type, image.provider, image.id, authToken);

  const response = await ssFetch(deleteUrl, { method: 'DELETE' });
  await clearProviderCache();
  return response;
}
