import { isEmpty } from 'underscore';
import { ssFetchJSON } from '../../modules/ssFetch';

export enum UserAutocheckoutPreference {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

interface IUserPaymentSetting {
  default_tip_pct: number;
  autocheckout_preference: UserAutocheckoutPreference;
}

export async function getUserPaymentSettings(authToken?: string) {
  return ssFetchJSON<IUserPaymentSetting>('/api/v1/user/payment_setting.json', {
    method: 'GET',
    params: {
      access_token: authToken,
    },
  });
}

export async function updateUserPaymentSettings(payload: Partial<IUserPaymentSetting>) {
  if (isEmpty(payload)) return null;

  return ssFetchJSON<IUserPaymentSetting>('/api/v1/user/payment_setting.json', {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}
