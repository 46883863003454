/**
 * Load the environment specific configuration.
 */

let selectedConfig;

try {
  // eslint-disable-next-line global-require, import/no-dynamic-require
  selectedConfig = require(`./${process.env.ENV_NAME}`);
} catch (e) {
  selectedConfig = null;
}

const testConfig = require('./test');

// defaulting to testConfig if "selected config" is null to support storyshots in jest
const defaultConfig = selectedConfig || testConfig;
let localConfig = {};
let envConfig = {};

// Local overrides
try {
  // eslint-disable-next-line global-require,import/no-unresolved,import/extensions
  localConfig = require('./local');
} catch (e) {
  localConfig = {};
}

if (typeof process.env.ENV_APP_CONFIG_OVERRIDES === 'object') {
  envConfig = process.env.ENV_APP_CONFIG_OVERRIDES;
}

function choose(key) {
  let result = defaultConfig[key];

  if (typeof localConfig[key] !== 'undefined') {
    result = localConfig[key];
  }

  if (typeof envConfig[key] !== 'undefined') {
    result = envConfig[key];
  }

  return result;
}

export const ENV = choose('ENV');
export const API_ROOT = choose('API_ROOT');
export const SITE_ROOT = choose('SITE_ROOT');
export const SEARCH_API_ROOT = choose('SEARCH_API_ROOT');
export const STATIC_ASSET_URL = choose('STATIC_ASSET_URL');
export const STRIPE_JS_KEY = choose('STRIPE_JS_KEY');
export const GOOGLE_API_KEY = choose('GOOGLE_API_KEY');
export const GOOGLE_CLIENT_ID = choose('GOOGLE_CLIENT_ID');
export const GOOGLE_AUTH_SCOPE = choose('GOOGLE_AUTH_SCOPE');
export const GOOGLE_SIGNIN_CLIENT_ID = choose('GOOGLE_SIGNIN_CLIENT_ID');
export const INSTAGRAM_APP_ID = choose('INSTAGRAM_APP_ID');
export const SHUTTLE_ENDPOINT = choose('SHUTTLE_ENDPOINT');
export const FB_ANALYTICS_ENABLED = choose('FB_ANALYTICS_ENABLED');
export const MIXPANEL_KEY = choose('MIXPANEL_KEY');
export const IOS_CAMPAIGN_ID = choose('IOS_CAMPAIGN_ID');
export const ANDROID_CAMPAIGN_ID = choose('ANDROID_CAMPAIGN_ID');
export const RECOMMENDATIONS_SOURCE_URL = choose('RECOMMENDATIONS_SOURCE_URL');
export const RECOMMENDATIONS_ENGINE_PREFIX = choose('RECOMMENDATIONS_ENGINE_PREFIX');
export const RECOMMENDATIONS = choose('RECOMMENDATIONS');
export const SEO_SERVICE_READ = choose('SEO_SERVICE_READ');
export const STATE_MANAGER_ENDPOINT = choose('STATE_MANAGER_ENDPOINT');
export const TALKJS_APP_ID = choose('TALKJS_APP_ID');

export const LOYALTY_METROS_FILE = choose('LOYALTY_METROS_FILE');
export const TOP_SERVICES_ENGINE_PREFIX = choose('TOP_SERVICES_ENGINE_PREFIX');
export const FACEBOOK_APP_ID = choose('FACEBOOK_APP_ID');
export const SEGMENT_WRITE_KEY = choose('SEGMENT_WRITE_KEY');
export const BRAZE_KEY = choose('BRAZE_KEY');

export const DEBUG = choose('DEBUG');
export const APP = choose('APP');
export const ENABLE_APP_SIMULATOR = choose('ENABLE_APP_SIMULATOR');
export const TRACKING_ENABLED = choose('TRACKING_ENABLED');
export const LOGROCKET_KEY = choose('LOGROCKET_KEY');
export const APP_ASSETS_URL = choose('APP_ASSETS_URL');
export const APPSFLYER_DEBUG = choose('APPSFLYER_DEBUG');
export const STRIPE_TERMINAL_LOCATION_ID = choose('STRIPE_TERMINAL_LOCATION_ID');

export const AWS_AUTOCOMPLETE_REGION = choose('AWS_AUTOCOMPLETE_REGION');
export const AWS_AUTOCOMPLETE_COGNITO_IDENTITY_POOL_ID = choose('AWS_AUTOCOMPLETE_COGNITO_IDENTITY_POOL_ID');
export const AWS_AUTOCOMPLETE_INDEX_NAME = choose('AWS_AUTOCOMPLETE_INDEX_NAME');
export const AWS_AUTOCOMPLETE_ESRI_INDEX_NAME = choose('AWS_AUTOCOMPLETE_ESRI_INDEX_NAME');
