/**
 * Module with utilities for converting between snake_case and camelCase.
 */

/**
 * Convert a snake_case string to a camelCase string.
 */
export const camelCaseString = string => {
  let prefix = '';
  let suffix = '';
  const camel = string
    .replace(/^(_+)/, $1 => {
      prefix = $1;
      return '';
    })
    .replace(/(_+)$/, $1 => {
      suffix = $1;
      return '';
    })
    .replace(/([-_]+[\w])/ig, $1 => ($1
      .toUpperCase()
      .replace(/[-_]+/g, '')));
  return `${prefix}${camel}${suffix}`;
};

/**
 * Convert an object containing snake_case keys to an object containing camelCase keys.
 *
 * @param obj - the object to copy.
 * @param deep - whether to convert nested objects or not.
 * @returns {Object} - a camelCased copy of the object.
 */
export default function camelCase(obj, deep = true) {
  if (typeof obj !== 'object' || !obj) {
    throw new Error(`Cannot camelize ${obj}`);
  }
  const camel = {};
  Object.entries(obj).forEach(([key, value]) => {
    const goDeep = deep && value !== null && typeof value === 'object';
    camel[camelCaseString(key)] = goDeep ? camelCase(value) : value;
  });
  return camel;
}

/**
 * Convert a camelCase string to a snake_case string.
 */
export const snakeCaseString = string => string
  .replace(/[a-z0-9]([A-Z])/g, m => `${m[0]}_${m[1]}`)
  .replace(/[0-9]([a-zA-Z])/g, m => `${m[0]}_${m[1]}`)
  .replace(/[a-zA-Z]([0-9])/g, m => `${m[0]}_${m[1]}`)
  .toLowerCase();

/**
 * Convert an object containing camelCase keys to an object containing snake_case keys.
 *
 * @param obj - the object to copy.
 * @param deep - whether to convert nested objects or not.
 * @returns {Object} - a snake_cased copy of the object.
 */
export function snakeCase(obj, deep = true) {
  if (typeof obj !== 'object' || !obj) {
    throw new Error(`Cannot camelize ${obj}`);
  }
  const snake = {};
  Object.entries(obj).forEach(([key, value]) => {
    const goDeep = deep && value !== null && typeof value === 'object';
    snake[snakeCaseString(key)] = goDeep ? snakeCase(value) : value;
  });
  return snake;
}
