export const INCENTIVES_DISCOUNT_CODE = 'SSINCENTIVESNEWPRO';

// Note: When cleaning up the tests referenced in this file, existing incentives checks
// will still need to be supported.
//
// Legacy tests (no longer in prod):

// Active tests below:
export const CLIENT_INCENTIVES_PRESELECTED_USER_ACCOUNT_FLAG_NAME: string = 'client_incentives_preselected_user_account_20210115';
export const CLIENT_INCENTIVES_PRESELECTED_URL_FLAG_NAME: string = 'client_incentives_preselected_url_20210115';
export const CLIENT_INCENTIVES_SOCIAL_PROMO_FLAG_NAME: string = 'client_incentives_social_promo_20210215';
export const CLIENT_INCENTIVES_IDLE_TOASTER_FEATURE_FLAG_NAME: string = 'client_incentives_idle_profile_20210120';
export const CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME: string = 'client_referral_incentives_profile_20210821';
export const LOCAL_STORAGE_PERMANENT_KEY = 'incentives_preselected_20210115';
export const LOCAL_STORAGE_ONE_APPOINTMENT_KEY = 'incentives_show_for_one_appointment_20210121';
export const LOCAL_STORAGE_ONE_APPOINTMENT_DISENROLLEMENT_KEY = 'incentives_show_for_one_appointment_used';
export const CLIENT_EXIT_BOOKING_CROSS_SELL_FLAG_NAME: string = 'client_incentives_exit_booking_cross_sell_20210224';

export const CLIENT_BOOKING_SUCCESS_INCENTIVE_CROSS_SELL_FLAG_NAME: string = 'client_booking_incentivescrosssell_atl3965_20211018';
