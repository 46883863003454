// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import analytics from '../modules/analytics';
import { UTMState } from '../../../packages/analytics/types';

const defaultState: UTMState = {
  utm_source: null,
  utm_campaign: null,
  utm_content: null,
  utm_term: null,
  utm_medium: null,
  referrer: null,
  created_at: null,
};

export const utmFields = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'referrer',
];

const MILLISECONDS_PER_DAY = 1000 * 3600 * 24;
const MAX_UTM_DAYS = 14;

export const utmParameters = createModel<RootModel>()({
  name: 'utmParameters',

  state: defaultState,

  reducers: {
    setUTMParams(state, payload: Partial<UTMState>) {
      const newState = {
        ...state,
        ...payload,
      };
      return Object.keys(newState).reduce((acc, curr) => {
        if (newState[curr]) {
          acc[curr] = newState[curr];
        }
        return acc;
      }, {});
    },

    resetUTMParams() {
      return defaultState;
    },
  },

  effects: dispatch => ({
    async loadUTMParams(_?: undefined, rootState?): Promise<void> {
      const {
        route: {
          params,
        },
        userState: {
          values: {
            utmParams,
          },
        },
      } = rootState;
      const expired = await dispatch.utmParameters.checkExpiry();
      const filteredRouteParams = utmFields.reduce((acc, curr) => {
        if (params[curr]) {
          acc[curr] = params[curr];
        }
        return acc;
      }, {});
      const mergedUTMParams = {
        ...(expired ? {} : utmParams),
        ...filteredRouteParams,
      };
      if (!utmParams || utmFields.some(field => mergedUTMParams[field] !== utmParams[field])) {
        mergedUTMParams.created_at = (new Date()).toUTCString();
      }
      dispatch.utmParameters.setUTMParams(mergedUTMParams);
      await dispatch.userState.addValues({
        values: {
          utmParams: mergedUTMParams,
        },
        preferLocalKeys: ['utmParams'],
      });
      analytics.updateUTMs(mergedUTMParams);
    },

    async updateFromUserState(_?: undefined, rootState?): Promise<void> {
      const {
        userState: {
          values: {
            utmParams,
          },
        },
      } = rootState;
      await dispatch.utmParameters.setUTMParams(utmParams);
      analytics.updateUTMs(utmParams);
    },

    async clearUTMParams(): Promise<void> {
      await dispatch.userState.addValues({
        values: {
          utmParams: null,
        },
        preferLocalKeys: ['utmParams'],
      });
      dispatch.utmParameters.resetUTMParams();
      analytics.updateUTMs(null);
    },

    async checkExpiry(_?: undefined, rootState?): Promise<boolean> {
      const {
        utmParameters: {
          created_at,
        },
      } = rootState;
      // Check if created more than 14 days ago
      const expired = created_at && (
        Math.ceil((Date.now() - Date.parse(created_at)) / MILLISECONDS_PER_DAY) > MAX_UTM_DAYS
      );
      if (expired) {
        await dispatch.utmParameters.clearUTMParams();
      }
      return expired;
    },
  }),
});

export default utmParameters;
