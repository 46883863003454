// @ts-strict-ignore
import type { IRouteState } from '../types';

/**
 * a Router history keeps a stack of router states
 * This class used to be the History service and is still used by the angular
 * side so any changes here should be verified with the the history service
 * This is not a 1:1 with browser history. It's meant to be used
 * as a way to go "back" within the allowed states in the app.
 */
export class RouterHistory {
  stack: IRouteState[] = [];

  previousState: IRouteState;

  /**
   * Returns the item on the top of the history stack
   */
  public get current(): IRouteState | undefined {
    return this.stack.length ? this.stack[this.stack.length - 1] : undefined;
  }

  /**
   * Returns the previously visited state (independent of current stack)
   */
  public get previous() {
    return this.previousState;
  }

  /**
   * Returns true if we've visited the given state name
   * @param stateName
   */
  public has(stateName) {
    return !!this.getStateFromStack(stateName);
  }

  /**
   * Updates our stack by setting the given state to the top of the stack
   * @param stateName
   * @param stateParams
   * @param data
   */
  public go(stateName: string, stateParams?: Record<string, string | boolean>, data?: any) {
    if (this.current && this.current.name !== stateName) {
      this.previousState = { ...this.current };
    }

    const state = this.getStateFromStack(stateName);
    if (state) {
      state.params = stateParams;
      state.previous = this.previousState;
      state.data = data;
    }

    const newState: IRouteState = state || {
      name: stateName,
      params: stateParams,
      data,
      previous: this.previous,
    };

    this.setState(newState);
  }

  public peek() {
    // we return the second to last history item, i.e. the previous state.
    // The real top of the stack is actually the current state.
    // We rely on the overridden "this.length" to keep things consistent.
    return this.stack[this.length - 1];
  }

  /**
   * Navigates back by the given number of steps. Using the browser history back ensures
   * that the browser history is popped when navigating.
   *
   * Keep in mind that the historyService stack is not always representative of the brower
   * history stack because it is not accounting for route replacement.
   *
   * @param {number} steps - The number of pages to go back
   */
  public back(steps: number = 1) {
    const index = this.length - Math.abs(steps);
    const targetState = this.stack[index];

    if (targetState) {
      this.previousState = { ...this.current };
      this.setState(targetState);
      return targetState;
    }

    return undefined;
  }

  /**
   * Remove the last item in the stack. This is particularly useful for
   * fixing issues with routes that you want to use location replace on
   */
  public pop() {
    if (this.stack.length > 0) {
      this.stack.pop();
    }
  }

  public reset(newState?: IRouteState) {
    const current = newState ? { ...newState } : this.current;
    this.stack.length = 0;
    if (current) {
      this.go(current.name, current.params);
    }
  }

  public get length() {
    return Math.max(this.stack.length - 1, 0);
  }

  public set length(v: number) {
    this.stack.length = v;
  }

  private setState(state) {
    const index = this.stack.indexOf(state);
    if (index >= 0) {
      this.stack.splice(index + 1, this.length);
    } else {
      this.stack.push(state);
    }
  }

  private getStateFromStack(stateName) {
    return this.stack.find(state => state.name === stateName);
  }
}
