import React from 'react';
import { ConnectedComponent, Provider } from 'react-redux';
import store from '../store';

/**
 * @deprecated - All components rendered under the main React app are already wrapped in
 * the store Provider by default. Using this wrapper is redundant. Please only use this for outdated
 * tests or if you truly know why you are using it.
 *
 * An HOC which provides the shared rematch store to wrapped components, allowing them to use data
 * and actions on the rematch store.
 * Note that this HOC should only be used at the top level of a component tree.
 * @param {ReactComponent} WrappedComponent The component to wrap.
 *
 * @example
 * ```jsx
 * import { connect } from 'react-redux';
 * import withStore from 'components/withStore';
 * class MyComponent extends React.Component {
 *   render() {
 *     return <div></div>
 *   }
 * }
 *
 * const mapStateToProps = state => {
 *   return {
 *     prop: state.obj.prop,
 *     ...
 *   };
 * };
 *
 * const mapDispatchToProps = dispatch => {
 *   return {
 *     doTheThing: dispatch.obj.doTheThing,
 *     ...
 *   }
 * };
 *
 * export default withStore(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
 * ```
 */
export default function withStore<P>(
  WrappedComponent: React.ComponentType<P> | ConnectedComponent<any, P>,
) {
  const StoreWrapperComponent = React.forwardRef((props: P, ref) => (
    <Provider store={store}>
      <WrappedComponent {...props} ref={ref} />
    </Provider>
  ));

  /** @deprecated Use Typescript */
  StoreWrapperComponent.propTypes = WrappedComponent.propTypes;
  /** @deprecated Use Typescript */
  StoreWrapperComponent.defaultProps = WrappedComponent.defaultProps;

  return StoreWrapperComponent;
}
