import SimpleCache from '../../../../modules/SimpleCache';
import { API_ROOT } from '../../../../config';
import { ssFetchJSON } from '../../../../modules/ssFetch';

const serviceGroupColorCache = new SimpleCache(60 * 15); // 15 minutes

export type ServiceGroupColor = {
  id: number;
  color: string;
};

/**
 * ProviderServiceGroupColorsView
 * GET: api/v2/providers/${providerId}/servicegroups/colors
 */
export type ServiceGroupColorsResponse = {
  results: ServiceGroupColor[];
};

export function getServiceGroupColors(
  providerId: number | string,
): Promise<ServiceGroupColorsResponse> {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/servicegroups/colors`);

  return ssFetchJSON(
    url.toString(),
    { ssCache: serviceGroupColorCache },
  );
}
