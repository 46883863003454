// @ts-strict-ignore
import moment from 'moment';

import {
  getProvider,
  ProfileEditErrorResponse,
  PublicProvider,
} from '../../../api/Providers';
import { UserAppointment, PrepayStatus } from '../../../api/Users/Appointments';
import {
  AppointmentDetailsStatus,
  AppointmentStatusDetailsProvider,
  loadAppointmentStatusDetails,
} from '../../../api/Users/Appointments/AppointmentsStatusDetails';
import { AppointmentOverviewToday, loadAppointmentsToday } from '../../../api/Users/Appointments/AppointmentsToday';
import type { UserAppointmentWithProviderWithLocation } from '../../UserAppointmentState';

export const DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss';
export const GET_HELP_TEST_FLAG = 'receipt-ux-get-help-20210910';
export const GET_HELP_TEST_PERCENT = 1;

export interface IAppointmentsOverviewToday {
  [AppointmentDetailsStatus.Upcoming]: AppointmentOverviewToday | null;
  [AppointmentDetailsStatus.Current]: AppointmentOverviewToday | null;
  [AppointmentDetailsStatus.Completed]: AppointmentOverviewToday | null;
}

export interface IGroupedAppointments {
  current: UserAppointmentWithProviderWithLocation[];
  future: UserAppointmentWithProviderWithLocation[];
  past: UserAppointmentWithProviderWithLocation[];
}

/**
 * Filters a list of today's appointments to find the most relevant eligible
 * upcoming or current appointment.
 * @param apptsToday
 */
export function filterTodayAppointments(
  apptsToday: AppointmentOverviewToday[],
): IAppointmentsOverviewToday {
  const filteredAppts: IAppointmentsOverviewToday = {
    [AppointmentDetailsStatus.Upcoming]: null,
    [AppointmentDetailsStatus.Current]: null,
    [AppointmentDetailsStatus.Completed]: null,
  };

  if (!apptsToday?.length) {
    return filteredAppts;
  }

  apptsToday.forEach(appt => {
    if (filteredAppts[appt.details_status] === null) {
      filteredAppts[appt.details_status] = appt;
    }
  });
  return filteredAppts;
}

/**
 * Retrieves v2 provider objects for a list of appointments to transform each Appointment
 * object into a UserAppointmentWithProviderWithLocation object
 */
export const getProsForAppointments = async (
  userId: number,
  apptList: UserAppointment[],
): Promise<UserAppointmentWithProviderWithLocation[]> => {
  const providers = await Promise.all(apptList.map(async appt => {
    let provider: ProfileEditErrorResponse | PublicProvider | AppointmentStatusDetailsProvider;

    try {
      provider = await getProvider(appt.provider);
    } catch (err) {
      // Provider is possibly inactive and the getProvider lookup has failed;
      // we can fallback to the provider attached to the appointment details
      provider = (await loadAppointmentStatusDetails(userId, appt.id))?.provider;
    }

    return provider;
  }));

  const providersById = providers.reduce((result, pro) => {
    const errorResponse = pro as ProfileEditErrorResponse;
    const provider = pro as PublicProvider;

    if (errorResponse.error) {
      return result;
    }

    return { ...result, [provider.id]: pro };
  }, {});

  return apptList.map(appt => ({ ...appt, provider: providersById[appt.provider] || {} }));
};

/**
 * Separate appointments into current, future, and past groups
 *
 * @param {Array} appts - array of appointment objects
 * @returns {Object} containing { current: [], future: [], past: [] }
 */
export function groupAppointments(
  appts: UserAppointmentWithProviderWithLocation[],
): IGroupedAppointments {
  const now = moment();
  const current = [];
  const future = [];
  const past = [];

  appts.filter(
    appt => appt.prepay_status !== PrepayStatus.PrepayFailed,
  ).forEach(appt => {
    const startDiff = appt.startDate.diff(now, 'hours');
    const endDiff = appt.endDate.diff(now, 'hours');
    // appts that start 12 hours or less in the future,
    // or ended 12 hours or less in the past
    const isCurrent = (startDiff >= 0 && startDiff <= 12) || (endDiff <= 0 && endDiff >= -12);
    const canceled = !!appt.cancellation_time;

    if (!canceled && isCurrent) {
      current.push(appt);
    } else if (!canceled && appt.startDate >= now) {
      future.push(appt);
    } else {
      past.push(appt);
    }
  });

  [
    current,
    future,
  ].forEach(group => {
    // sort appts in chronological order
    group.sort((a, b) => a.startDate - b.startDate);
  });

  // sort past appts in reverse chronological order
  past.sort((a, b) => b.startDate - a.startDate);
  return {
    current, future, past,
  };
}

/**
 * If a user has an appointment today, returns it.
 * Returns a single appointment in case of multiple appointments in one day,
 *  in priority order:
 *    1. a current appointment (in progress)
 *    2. an upcoming appointment (not yet started)
 *    3. a completed appointment
 *
 * @param userId logged in userId
 */
export const getTodayAppt = async (
  userId: number,
): Promise<AppointmentOverviewToday | undefined> => {
  if (!userId) {
    return undefined;
  }

  const {
    [AppointmentDetailsStatus.Current]: currentAppt,
    [AppointmentDetailsStatus.Upcoming]: upcomingAppt,
    [AppointmentDetailsStatus.Completed]: completedAppt,
  } = await loadAppointmentsToday(userId).then(filterTodayAppointments);

  return currentAppt || upcomingAppt || completedAppt || undefined;
};
