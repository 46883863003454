// @ts-strict-ignore
import { BrandColor } from './brandColors';
import {
  Color,
  RGBAColor,
  RGBColor,
} from './types';

/**
 * Parses a hex color and returns an array containing each color value
 * in R,G,B order.
 * @param hex - 6 digit hex value (eg: #FF99CC)
 */
const hexToRGBParts = (hex: string): string[] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result.slice(1).map(h => parseInt(h, 16).toString());
};

/**
 * **Contact design if using a color that's not in BrandColor**
 *
 * Convert a hex color representation to its corresponding rgb() representation.
 * @param {string} hex - 6 digit hex value (eg: #FF99CC)
 */
export function toRGB(hex: string): RGBColor {
  const parts = hexToRGBParts(hex);
  return `rgb(${parts[0]}, ${parts[1]}, ${parts[2]})`;
}

const RGB_OR_RGBA_REGEX = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:|,\s*([0-9.]+)\s*)\)$/;
const isTransparent = (
  color: Color,
): color is BrandColor.Transparent => color === BrandColor.Transparent;

/**
 * Convert a hex color representation to its corresponding rgba() representation.
 *
 * @param {Color} color
 * @param {name} opacity - the opacity value (0 to 1) to attach to the rgba result; If color is
 *  already rgba, this overrides the opacity on that color.
 */
export function toRGBA(color: Color, opacity: number | string): RGBAColor {
  let matches: string[];
  if (isTransparent(color)) {
    return 'rgba(0,0,0,0)';
  }

  let parts: [string, string, string, string];

  if (color.startsWith('#')) {
    matches = hexToRGBParts(color);
    parts = [
      matches[0],
      matches[1],
      matches[2],
      opacity.toString(),
    ];
  } else if (color.startsWith('rgb')) {
    matches = color.match(RGB_OR_RGBA_REGEX);
    parts = [
      matches[1],
      matches[2],
      matches[3],
      opacity.toString(),
    ];
  } else {
    throw new TypeError(`Unsupported color: ${color}`);
  }

  return `rgba(${parts[0]},${parts[1]},${parts[2]},${parts[3]})`;
}
