export const CLIENT_BOOKING_SERVICE_LIST_PREVIOUS_APPT_TEST_KEY = 'client_booking_servicelist_prevappt_20191104';
export const CLIENT_BOOKING_SERVICE_LIST_PREVIOUS_APPT_TEST_PERCENT = 0.5;
export const CLIENT_PROPROFILE_DESKTOP_IMAGE_GRID_TEST = 'client_proprofile_desktop_image_grid_atl3838_20220310';
export const CLIENT_PROPROFILE_DESKTOP_IMAGE_GRID_TEST_PERCENT = 1.0;
export const CLIENT_PROPROFILE_CLARIFY_LOCATION_TEST_NAME = 'client_proprofile_clarifylocation_atl3955_20210826';
export const CLIENT_PROPROFILE_CLARIFY_LOCATION_TEST_PERCENT = 0.5;
export const CLIENT_PROPROFILE_POPULAR_TAG_TEST_NAME = 'client_proprofile_populartag_atl3971_20210824';
export const CLIENT_PROPROFILE_POPULAR_TAG_TEST_PERCENT = 0.5;

export const COM_1252_TEST_TOP_SERVICES_ADJUSTMENT_NAME = 'client_proprofile_top_services_adjustment_com1252_20230810';
export const COM_1252_TEST_TOP_SERVICES_ADJUSTMENT_PERCENT = 0.5;

export const CLIENT_REVIEW_TAGS_TEST_NAME: string = 'client_profile_review_tags_ui_and_modal_atl3979_20210901';
export const CLIENT_REVIEW_TAGS_TEST_PERCENT: number = 0.5;

export const MAX_CONTENT_WIDTH = 950;

export const SS_MESSAGING_SMS_OPTED_IN = 'client_proprofile_client_opted_into_ss_messaging_sms_subscription_group';

export const MAX_TOP_SERVICES = 5;
export const MAX_TOP_SERVICE_IMAGES = 5;
