import { useSelector } from 'react-redux';
import { PROFILE_EDIT_ROUTE, PROFILE_ROUTE } from '../route-names';
import type { RootState } from '../store/models';

export default function useOnProfile(): boolean {
  return useSelector<RootState, boolean>(state => (
    [
      PROFILE_ROUTE,
      PROFILE_EDIT_ROUTE,
    ].includes(state.route.name)
  ));
}
