// @ts-strict-ignore
import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';

import PortalElement from './PortalElement';
import PortalElementManager from './PortalElementManager';
import { PortalOverlayProps } from './types';

const PortalOverlay: React.FCWithChildren<PortalOverlayProps> = ({
  name,
  color,
  children,
  className,
  onBackdropClick,
  isTransparentOnMobile,
  isTransparent,
  overflowScroll,
  zIndex = undefined,
  closeOthers,
  onRequestExternalClose,
  disableAnimation = false,
  animationStyle = 'slideUp',
  hideBackdrop = false,
  includeMobileNav = false,
  forceTop = false,
}: PortalOverlayProps): ReactPortal => {
  const [element, setElement] = React.useState<PortalElement | null>(null);

  React.useEffect(() => {
    if (!element) {
      const newElement = PortalElementManager.getElement({
        color,
        isTransparentOnMobile,
        isTransparent,
        className,
        zIndex,
        overflowScroll,
        closeOthers,
        animationStyle,
        disableAnimation,
        hideBackdrop,
        includeMobileNav,
        forceTop,
      });
      setElement(newElement);
    }

    return () => {
      if (element) {
        element.remove();
      }
    };
  }, [
    name,
    element,
  ]);

  React.useEffect(() => {
    if (element && onBackdropClick) {
      element.setClickHandler(onBackdropClick);
    }
  }, [
    element,
    onBackdropClick,
  ]);

  React.useEffect(() => {
    if (element && onRequestExternalClose) {
      element.setRequestExternalCloseHandler(onRequestExternalClose);
    }
  }, [
    element,
    onRequestExternalClose,
  ]);

  React.useEffect(() => {
    if (element && name) {
      element.setTestId(name);
    }
  }, [
    name,
    element,
  ]);

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element.getElement());
};

export default PortalOverlay;
