// @ts-strict-ignore
import { APP } from '../../config';
import { getGeoIP } from '../consumer/DeviceLocation';
import { setLandingInfo } from '../landingInfo';
import analytics from './index';
import type { IRouteState } from '../../../../packages/router';
import { HOME_ROUTE, SEARCH_ROUTE } from '../../route-names';
import { storeParams as storeCJTrackingParams } from '../CJTracking/CJTracking';

type LandingProps = {
  landing_page: string;
  landing_route_state: string;
  latitude: number | null;
  longitude: number | null;
  ncd_source?: string;
  ncd_term?: string;
  referring_tracking_cookie?: string;
};

export const fireLanding = async (routeState: IRouteState) => {
  const oldStateNameToLandingPage = {
    start: HOME_ROUTE,
    'search.list': SEARCH_ROUTE,
    profile: 'profile',
  };

  const landingPage = APP
    ? routeState.name
    : (oldStateNameToLandingPage[routeState.name] || routeState.name);

  const location = await getGeoIP();

  setLandingInfo({
    page: landingPage,
    url: window.document.location.href,
    referrer: window.document.referrer,
  });

  const landingProperties: LandingProps = {
    landing_page: landingPage,
    landing_route_state: routeState.name,
    latitude: location && location.latitude ? Number(location.latitude) : null,
    longitude: location && location.longitude ? Number(location.longitude) : null,
  };

  if (routeState.params?.ncd_source) {
    landingProperties.ncd_term = routeState.params.ncd_term;
    landingProperties.ncd_source = routeState.params.ncd_source;
    landingProperties.referring_tracking_cookie = routeState.params.referral_id;
  }

  analytics.land(landingProperties);
};

/*
  * When the user goes to a new route run navigational analytics.
  */
export const handleNavigationAnalytics = (route: IRouteState) => {
  // Determine where the user was.
  const previousLocationPath: string | null = window.document.referrer
    ? window.document.referrer
    // The application was navigated "manually" by the user through the browser's URL.
    : null;
  const previousLocationPathIsFromReload = (() => {
    // https://caniuse.com/mdn-api_performancenavigationtiming
    if (window.performance && window.performance.getEntriesByType) {
      return (
        window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming
      )?.type === 'reload';
    }
    return false;
  })();

  // When the user is coming from a different site/page for the *first* time.
  //
  // Using direct access to `window.location.href` because there are currently two routers
  // in this application.
  if (!previousLocationPathIsFromReload) {
    // NOTE: I think we should be tracking every navigational change from any site/page.
    //       But that would mean changing the definition of a "landing event" for the data team.
    //
    //       This whitelist conditional is how we are currently tracking landing events.
    //
    // The landing event is triggered under the following conditions:
    if (
      // The user navigated to our application for the first time ever.
      previousLocationPath === null
      // The user navigated from an external site.
      || previousLocationPath.indexOf(window.location.origin) <= -1
      // The user navigated from a whitelisted internal page.
      || previousLocationPath.indexOf(`${window.location.origin}/blog/`) >= 0
      || previousLocationPath.indexOf(`${window.location.origin}/promo/`) >= 0
    ) {
      fireLanding(route);
    }
  }

  if (route.params?.TYPE) {
    storeCJTrackingParams({
      CJEVENT: route.params.CJEVENT,
      TYPE: route.params.TYPE,
    });
  }
};
