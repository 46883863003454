import { getIsApp } from '../../../AppInfo';
import { isTest } from '../../../env';
import { grantOfflineAccess, getGoogleAuthAPI } from '../../../../api/thirdParty/GoogleAuth';

function isStyleSeatOrigin(origin: string): boolean {
  return /^https?:\/\/[\w\-.]+\.styleseat\.com$/.test(origin);
}

/**
 * Allow proxying google oauth info between styleseat domains.
 * Since Google's API doesn't allow wildcard JavaScript origins,
 * it makes it impossible to directly test Google Calendar Sync on
 * Launch Control instances, which have dynamic domains.  To workaround this
 * we have the below event handler running on www.styleseat.com, which listens
 * for messages from Launch Control instances, requests a google oauth token
 * on behalf of those instances, and then responds with the retrieved token.
 */
function requestMessageListener(event: any): void {
  if (!isStyleSeatOrigin(event.origin)) {
    return;
  }
  if (event.data.method === 'grantOfflineAccess') {
    if (event.data.direction === 'request') {
      // request offline access, then respond to the
      grantOfflineAccess(true).then(code => {
        event.source.postMessage({
          method: 'grantOfflineAccess',
          direction: 'response',
          status: 'resolve',
          data: {
            code,
          },
        }, event.origin);
      }, error => {
        event.source.postMessage({
          method: 'grantOfflineAccess',
          direction: 'response',
          status: 'reject',
          data: {
            error,
          },
        }, event.origin);
      });
    }
  }
}

export async function listen() {
  if (!isTest() && !getIsApp()) {
    await getGoogleAuthAPI(); // make sure google API is available in the DOM

    window.addEventListener('message', requestMessageListener);
  }
}
