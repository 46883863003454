const SESSION_ID_KEY = 'sessionStorage_ID';

/**
 * This is a utility function shared by the landing page and mobile web to get
 * the user's current session ID from session storage. Note: this will only operate
 * on the web platform.
 * @param getId - This is a function which should generate a new session id uuid.
 *  This is passed for dependency agnosticism, each consuming app can choose what
 *  library/implementation of getId to use.
 */
export function getSessionId(getId: () => string): string | undefined {
  try {
    if (!sessionStorage.getItem(SESSION_ID_KEY)) {
      sessionStorage.setItem(SESSION_ID_KEY, getId());
    }

    return sessionStorage.getItem(SESSION_ID_KEY) || undefined;
  } catch (e) {
    console.warn('sessionStorage not available on this device');
  }

  return undefined;
}
