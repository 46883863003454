import moment from 'moment';
import { compareData } from '..';
import { byCurrentRange, filterByDate } from '../filters';
import { groupDatasetBy } from '../groupBy';
import type { MasterDataSet, YearlyRevenueData } from '../types';

export const getYearlyRevenue = (
  stats: MasterDataSet<'actual_revenue' | 'projected_revenue'>,
): YearlyRevenueData => {
  const startOfThisYear = moment().startOf('year');
  const endOfThisYear = moment().endOf('year');
  const lastYear = moment().subtract(1, 'year');
  const startOfLastYear = lastYear.clone().startOf('year');
  const endOfLastYear = lastYear.clone().endOf('year');

  const currentYearToTodayData = filterByDate(
    stats as MasterDataSet<'actual_revenue' | 'projected_revenue'>,
    startOfThisYear,
    moment(),
  );

  const currentYearData = byCurrentRange(stats, 'year');

  const {
    compared: lastYearData,
  } = compareData(
    stats.actual_revenue,
    {
      start: startOfThisYear,
      end: endOfThisYear,
    },
    {
      start: startOfLastYear,
      end: endOfLastYear,
    },
  );
  return groupDatasetBy({
    actual_revenue: currentYearToTodayData.actual_revenue,
    projected_revenue: currentYearData.projected_revenue,
    lastYear: lastYearData,
  }, 'month') as unknown as YearlyRevenueData;
};
