import { useSelector } from 'react-redux';
import { createSelector as createReduxSelector } from 'reselect';
import { IRouteState } from '../../../packages/router';
import type { RootState } from '../store/models';

// We can't use a selector from the store here because it breaks several unrelated tests, since the
// store essentially needs to initialize before anything that imports hooks runs, and initializing
// the store triggers a call to `whoami` which becomes sad if `ssFetch` is mocked, or else might
// issue actual requests
const getCurrentParams = createReduxSelector<
RootState,
IRouteState,
Record<string, string | undefined>
>(
  state => state.route,
  (route: IRouteState): Record<string, string | undefined> => route?.params || {},
);

export default () => (
  useSelector<RootState, Record<string, string | undefined>>(
    getCurrentParams,
  )
);
