import { createModel } from '@rematch/core';
import produce from 'immer';
import { ssFetchJSON } from '../modules/ssFetch';

/**
 * @returns {{
 *   next: null,
 *   gift_card_enabled: boolean,
 *   total_amount_in_cents: number,
 *   previous: null,
 *   count: number,
 *   results: Array
 * }}
 */
const getProviderDataSkeleton = (
  {
    is_payments_pro,
    gift_card_enabled,
  },
) => ({
  count: 0,
  next: null,
  results: [],
  previous: null,
  is_payments_pro,
  gift_card_enabled,
  total_amount_in_cents: 0,
});

export default createModel()({
  state: {},

  reducers: {
    /**
     * This updates the model when new provider data is loaded, because this model
     * can potentially hold multiple providers info by id's shape is the following:
     * {
     *   "<providerId>": {
     *     count: number,
     *     next: null | url,
     *     results: array<{
     *       {
              "id": number,
              "state": number,
              "payment": number,
              "client": number,
              "client_user": number,
              "code": string,
              "provider": number,
              "message": string | null,
              "purchase_datetime": date,
              "amount_in_cents": number,
              "client_name": string,
              "client_email": string,
              "provider_note": string,
              "error": object,
            }
     *     }>,
     *     previous: null | url,
     *     gift_card_enabled: boolean,
     *     total_amount_in_cents: number,
     *   },
     *   "<second provider id>": {
     *     ... see above.
     *   }
     * }
     *
     * @param state
     * @param newState
     */
    onLoadComplete(state, newState) {
      const {
        response,
        providerId,
      } = newState;

      const providerState = state[providerId] || {
        results: [],
      };
      const idToIndex = {};
      const results = [];

      // if page 2 or greater, add in what we have so far (and hope)
      if (response.previous) {
        // make sure each result is unique - if `onLoadComplete` gets called twice without clearing,
        // we can get duplicate records
        providerState.results.forEach(gc => {
          idToIndex[gc.id] = results.length;
          results.push(gc);
        });
      }

      (response.results || []).forEach(gc => {
        if (typeof idToIndex[gc.id] !== 'undefined') {
          results[idToIndex[gc.id]] = gc;
        } else {
          idToIndex[gc.id] = results.length;
          results.push(gc);
        }
      });

      return {
        ...state,
        [providerId]: {
          ...providerState,
          ...response,
          results,
        },
      };
    },

    onLoadNextStart(state, payload) {
      const {
        providerId,
      } = payload;

      return {
        ...state,
        [providerId]: {
          ...state[providerId],
          next: null,
        },
      };
    },

    replaceGiftCard: produce((state, { providerId, giftCard }) => {
      const index = state[providerId].results.findIndex(gc => gc.id === giftCard.id);

      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        state[providerId].results[index] = giftCard;
      }
    }),

    setGiftCardError: produce((state, {
      providerId, giftCard, error,
    }) => {
      const index = state[providerId].results.findIndex(gc => gc.id === giftCard.id);

      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        state[providerId].results[index].error = error;
      }
    }),

    'user/logout': () => ({}),
  },

  effects: dispatch => ({
    async updateGiftCard({ providerId, giftCard }) {
      try {
        const sanitizedGiftCard = { ...giftCard };
        delete sanitizedGiftCard.provider_id;
        delete sanitizedGiftCard.error;
        const json = await ssFetchJSON(
          `/api/v1/providers/${providerId}/gift_cards/${giftCard.id}`,
          {
            body: JSON.stringify(sanitizedGiftCard),
            method: 'PUT',
          },
        );

        dispatch.providerGiftCard.replaceGiftCard({ providerId, giftCard: json });
      } catch (error) {
        dispatch.providerGiftCard.setGiftCardError({
          providerId, giftCard, error,
        });
      }
    },

    async load({ url, providerId }) {
      try {
        const response = await ssFetchJSON(url);
        await dispatch.providerGiftCard.onLoadComplete({
          response: {
            ...response,
            gift_card_enabled: true,
          },
          providerId,
        });
      } catch (e) {
        const errorData = JSON.parse(e);
        await dispatch.providerGiftCard.onLoadComplete({
          response: getProviderDataSkeleton(errorData),
          providerId,
        });
      }
    },

    async loadProvider(providerId) {
      return dispatch.providerGiftCard
        .load({
          providerId,
          url: `/api/v1/providers/${providerId}/gift_cards`,
        });
    },

    async loadNextPage(providerId, rootState) {
      const {
        providerGiftCard: {
          [providerId]: {
            next,
          },
        },
      } = rootState;

      if (next) {
        await dispatch.providerGiftCard.onLoadNextStart({
          providerId,
        });
        return dispatch.providerGiftCard.load({
          url: next,
          providerId,
        });
      }

      return [];
    },
  }),
});
