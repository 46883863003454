// @ts-strict-ignore
import _ from 'underscore';
import { createModel } from '@rematch/core';
import { getServiceGroupColors, ServiceGroupColor } from '../../api/Providers/ServiceGroups/Colors';
import { updateOrder as updateServiceGroupOrder } from '../../api/Providers/ServiceGroups/UpdateOrder';
import { updateOrder as updateServicesOrder } from '../../api/Providers/Services/UpdateOrder';
import { deleteService } from '../../api/Providers/Services';
import nonCriticalException from '../../modules/exceptionLogger';
import { serviceGroupToCreateGroupPayload, serviceGroupToUpdateGroupPayload } from '../../modules/provider/ServiceGroups';
import type { RootModel } from '../models';
import {
  createServiceGroup,
  deleteServiceGroup,
  getServiceGroups,
  ProviderServiceGroup,
  saveServiceGroup,
} from '../../api/Providers/ServiceGroups';

export type ProviderManageServiceGroupsData = {
  groups: Record<number, ProviderServiceGroup>;
  orderedGroups: ProviderServiceGroup[];
  colors: ServiceGroupColor[];
  loading: boolean;
};

type LandOnMenuPayload = {
  providerId: number;
};

type CreateServiceGroupPayload = {
  providerId: number;
  group: Partial<ProviderServiceGroup>;
};

type UpdateServiceGroupsOrderPayload = {
  providerId: number;
  groups: ProviderServiceGroup[];
};

type UpdateServicesOrderPayload = {
  providerId: number;
  group: ProviderServiceGroup;
  serviceIds: number[];
};

type EditServiceGroupPayload = {
  providerId: number;
  group: ProviderServiceGroup;
};

type DeleteServicePayload = {
  providerId: number;
  serviceId: number;
};

type DeleteServiceGroupPayload = {
  providerId: number;
  groupId: number;
};

type OnLandingOnEditPayload = {
  providerId: number;
};

type OnSaveErrorPayload = {
  providerId: number;
  error: any;
};

type OnLoadErrorPayload = {
  providerId: number;
  error: any;
};

type OnManageModalLandedPayload = {
  providerId: number;
  groups: ProviderServiceGroup[];
  colors: ServiceGroupColor[];
};

type OnServiceGroupCreatedPayload = {
  group: ProviderServiceGroup;
  providerId: number;
};

type OnServiceGroupsOrderUpdatedPayload = {
  groups: ProviderServiceGroup[];
  providerId: number;
};

type OnServiceGroupUpdatedPayload = {
  group: ProviderServiceGroup;
  providerId: number;
};

type OnServiceGroupDeletedPayload = {
  groupId: number;
};

type OnServiceDeletedPayload = {
  serviceId: number;
};

const getInitialState = (): ProviderManageServiceGroupsState => ({
  loading: true,
  error: null,
  groupsById: {},
  colors: [],
  providerId: null,
});

/**
 * Rematch model for the provider side manage service groups screen(s)
 */
export type ProviderManageServiceGroupsState = {
  loading: boolean;
  error?: any;
  groupsById: {
    [id: number]: ProviderServiceGroup;
  };
  colors: ServiceGroupColor[];
  providerId: number | string | null;
};

export const providerManageServiceGroups = createModel<RootModel>()({
  name: 'providerManageServiceGroups',

  state: getInitialState() as ProviderManageServiceGroupsState,

  reducers: {
    'user/logout': () => getInitialState(),

    onLanding(
      state: ProviderManageServiceGroupsState,
      payload: OnLandingOnEditPayload,
    ): ProviderManageServiceGroupsState {
      const {
        providerId,
      } = payload;

      return {
        ...getInitialState(),
        loading: true,
        providerId,
      };
    },

    onLanded(
      state: ProviderManageServiceGroupsState,
      payload: OnManageModalLandedPayload,
    ): ProviderManageServiceGroupsState {
      const {
        providerId,
        colors,
        groups,
      } = payload;

      if (state.providerId !== providerId) return state;

      return {
        ...state,
        loading: false,
        groupsById: _.indexBy(groups, 'id'),
        colors,
      };
    },

    onServiceGroupCreated(
      state: ProviderManageServiceGroupsState,
      payload: OnServiceGroupCreatedPayload,
    ): ProviderManageServiceGroupsState {
      const { group } = payload;
      const groups = { ...state.groupsById, [group.id]: group };
      return {
        ...state,
        groupsById: groups,
      };
    },

    onServiceGroupsOrderUpdated(
      state: ProviderManageServiceGroupsState,
      { groups }: OnServiceGroupsOrderUpdatedPayload,
    ): ProviderManageServiceGroupsState {
      const orderedGroups = groups.map((group, index) => ({
        ...group,
        order: index,
      }));

      return {
        ...state,
        groupsById: _.indexBy(orderedGroups, 'id'),
      };
    },

    onServiceGroupUpdated(
      state: ProviderManageServiceGroupsState,
      { group }: OnServiceGroupUpdatedPayload,
    ): ProviderManageServiceGroupsState {
      const groups = { ...state.groupsById, [group.id]: group };
      return {
        ...state,
        groupsById: groups,
      };
    },

    onServiceGroupDeleted(
      state: ProviderManageServiceGroupsState,
      { groupId }: OnServiceGroupDeletedPayload,
    ): ProviderManageServiceGroupsState {
      const groups = { ...state.groupsById };
      delete groups[groupId];
      return {
        ...state,
        groupsById: groups,
      };
    },

    onServiceDeleted(
      state: ProviderManageServiceGroupsState,
      { serviceId }: OnServiceDeletedPayload,
    ): ProviderManageServiceGroupsState {
      const groups = Object.values(state.groupsById).map(group => ({
        ...group,
        services: group.services.filter(srv => srv.id !== serviceId),
      }));

      return {
        ...state,
        groupsById: _.indexBy(groups, 'id'),
      };
    },

    onLoadError(
      state: ProviderManageServiceGroupsState,
      payload: OnLoadErrorPayload,
    ): ProviderManageServiceGroupsState {
      const {
        error,
      } = payload;

      return {
        ...state,
        loading: false,
        error,
        groupsById: {},
      };
    },

    onSaveError(
      state: ProviderManageServiceGroupsState,
      payload: OnSaveErrorPayload,
    ): ProviderManageServiceGroupsState {
      const {
        error,
      } = payload;

      return {
        ...state,
        error,
      };
    },
  },

  effects: dispatch => ({
    /**
     * Call when a provider lands on the manage services modal screen
     * @param {LandOnMenuPayload} payload
     * @returns {Promise<void>}
     */
    landOnMenu: async (payload: LandOnMenuPayload) => {
      const { providerId } = payload;
      dispatch.providerManageServiceGroups.onLanding({ providerId });
      const [
        groups,
        colors,
      ] = await Promise.all([
        getServiceGroups({
          providerId,
          serviceFilter: 'all',
          size: 'all',
        }),
        getServiceGroupColors(providerId),
      ]);

      dispatch.providerManageServiceGroups.onLanded({
        providerId,
        groups: groups.results,
        colors: colors.results,
      });
    },

    /**
     * Call to create a new service group and add the new group
     * to our list of service groups
     * @param {CreateServiceGroupPayload} payload
     * @param rootState
     * @returns {Promise<ProviderServiceGroup>}
     */
    createServiceGroup: async (
      payload: CreateServiceGroupPayload,
      rootState,
    ): Promise<ProviderServiceGroup> => {
      const {
        providerId,
        group,
      } = payload;
      const colors = rootState.providerManageServiceGroups.colors || [];
      const groups: ProviderServiceGroup[] = Object.values(
        rootState.providerManageServiceGroups.groupsById,
      );

      try {
        const newGroup = await createServiceGroup(
          serviceGroupToCreateGroupPayload(providerId, group, colors),
        );

        await dispatch.providerManageServiceGroups.onServiceGroupCreated({
          providerId,
          group: newGroup,
        });

        const defaultGroup = groups.find(grp => grp.is_default);

        const newServiceGroups = [
          defaultGroup,
          newGroup,
          ...groups.filter(grp => !grp.is_default),
        ];

        await dispatch.providerManageServiceGroups.updateServiceGroupsOrder({
          providerId,
          groups: newServiceGroups,
        });

        return newGroup;
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServiceGroups.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    /**
     * Updates the order of service groups on the API and on the store
     * @param {UpdateServiceGroupsOrderPayload} payload
     * @returns {Promise<ProviderServiceGroup[]>}
     */
    updateServiceGroupsOrder: async (
      payload: UpdateServiceGroupsOrderPayload,
      rootState,
    ): Promise<ProviderServiceGroup[]> => {
      const {
        providerId,
        groups,
      } = payload;
      const { groupsById } = rootState.providerManageServiceGroups || {};
      const defaultGroup = Object.values(groupsById).find(group => group.is_default);
      const reorderedGroups = [
        defaultGroup,
        ...groups.filter(group => !group.is_default),
      ];

      // default group ID always goes first in order
      try {
        await updateServiceGroupOrder(
          providerId,
          reorderedGroups.map(grp => grp.id),
        );

        await dispatch.providerManageServiceGroups.onServiceGroupsOrderUpdated({
          providerId,
          groups: reorderedGroups,
        });
        return reorderedGroups;
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServiceGroups.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    /**
     * Updates the order of services within a service group
     * @param {UpdateServicesOrderPayload} payload
     * @returns {Promise<ProviderServiceGroup>}
     */
    updateServicesOrder: async (
      payload: UpdateServicesOrderPayload,
    ): Promise<ProviderServiceGroup> => {
      const {
        providerId,
        group,
        serviceIds,
      } = payload;

      if (!serviceIds) return group;

      try {
        await updateServicesOrder(
          providerId,
          group.id,
          serviceIds,
        );

        await dispatch.providerManageServiceGroups.onServiceGroupUpdated({
          providerId,
          group,
        });
        return group;
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServiceGroups.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    /**
     * Saves any changes to the given service group
     * @param {EditServiceGroupPayload} payload
     * @param {RootState} rootState
     * @returns {Promise<ProviderServiceGroup>}
     */
    editServiceGroup: async (
      payload: EditServiceGroupPayload,
      rootState,
    ): Promise<ProviderServiceGroup> => {
      const {
        providerId,
        group,
      } = payload;
      const colors = rootState.providerManageServiceGroups.colors || [];

      try {
        const newGroup = await saveServiceGroup(
          serviceGroupToUpdateGroupPayload(providerId, group, colors),
        );

        await dispatch.providerManageServiceGroups.onServiceGroupUpdated({
          providerId,
          group: newGroup,
        });

        return newGroup;
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServiceGroups.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    /**
     * Removes a service group (from store and API)
     * @param {DeleteServiceGroupPayload} payload
     * @returns {Promise<void>}
     */
    deleteServiceGroup: async (
      payload: DeleteServiceGroupPayload,
      rootState,
    ) => {
      const {
        providerId,
        groupId,
      } = payload;

      try {
        const { groupsById } = rootState.providerManageServiceGroups;
        const serviceIdsInGroup = (groupsById[groupId]?.services || []).map(service => service.id);

        await deleteServiceGroup(
          providerId,
          groupId,
        );

        await dispatch.providerManageServiceGroups.onServiceGroupDeleted({
          groupId,
        });

        // move services in previous group to top default group
        const defaultGroup = Object.values(groupsById).find(group => group.is_default);
        const defaultGroupServiceIds = groupsById[defaultGroup.id].services.map(
          service => service.id,
        );
        const combinedIds = [
          ...serviceIdsInGroup,
          ...defaultGroupServiceIds,
        ];
        await dispatch.providerManageServiceGroups.updateServicesOrder({
          providerId,
          group: defaultGroup,
          serviceIds: combinedIds,
        });
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServiceGroups.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    /**
     * Deletes a service
     * @param {DeleteServicePayload} payload
     * @returns {Promise<void>}
     */
    deleteService: async (payload: DeleteServicePayload) => {
      const {
        providerId,
        serviceId,
      } = payload;

      try {
        await deleteService(
          providerId,
          serviceId,
        );

        await dispatch.providerManageServiceGroups.onServiceDeleted({ serviceId });
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServiceGroups.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },
  }),

  selectors: (slice, createSelector) => ({
    manageServicesMenuData: () => (
      createSelector(
        slice((state: ProviderManageServiceGroupsState) => state.groupsById),
        slice((state: ProviderManageServiceGroupsState) => state.colors),
        slice((state: ProviderManageServiceGroupsState) => state.loading),
        (
          groups: ProviderServiceGroup[],
          colors: ServiceGroupColor[],
          loading: boolean,
        ): ProviderManageServiceGroupsData => ({
          groups,
          orderedGroups: Object.values(groups || {}).sort((a, b) => a.order - b.order),
          colors,
          loading,
        }),
      )
    ),
  }),
});
