import type { ICard } from '../../../../store/PaymentMethods';
import { Stripe, StripeElement } from '../../../shared/stripe/types';

export enum PaymentMethodType {
  Card = 'card',
  CardPresent = 'card_present',
  CardOnFile = 'card_on_file',
  Klarna = 'klarna',
  Unknown = 'unknown',
  Cash = 'cash',
  DumbCredit = 'dumb_credit',
  Check = 'check',
  GiftCertificate = 'gift_cert',
  StripeConnect = 'stripe_connect',
}

interface IBaseCardChangeEvent {
  stripe: Stripe,
  valid: boolean;
  errors: string | null;
}

export interface ICardChangeEventCard extends IBaseCardChangeEvent {
  type: PaymentMethodType.Card;
  cvcToken: string | null;
  selectedCard: ICard | null;
  newCardStripeElement: StripeElement | null;
}

export interface ICardChangeEventKlarna extends IBaseCardChangeEvent {
  type: PaymentMethodType.Klarna;
}

export type OnCardChangeEvent = ICardChangeEventCard | ICardChangeEventKlarna;

export function isCardEvent(evt: OnCardChangeEvent): evt is ICardChangeEventCard {
  return evt.type === PaymentMethodType.Card;
}
