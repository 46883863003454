// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { Selector } from 'reselect';
import type { RootModel, RootState } from './models';
import nonCriticalException from '../modules/exceptionLogger';
import {
  checkLocationPermissionsNotDenied,
  getGeoIP,
  getUserLocation,
  LocationSource,
  UserLocation,
} from '../modules/consumer/DeviceLocation';
import {
  getItem,
  setItem,
  removeItem,
} from '../modules/KeyValueStorage';

export type LocationState = {
  latitude: number | null;
  longitude: number | null;
  address: string | null;
  source: LocationSource | null;
  created: number | null;
  loading: boolean;
  error: string | null;
};

export type LocationRecord = Pick<LocationState, 'address' | 'latitude' | 'longitude'> & {
  placeId?: string;
};

type CachedUserLocation = UserLocation & { created: number };

/**
 * Cached for a week. This is just for initialization purposes as geolocation
 * can take some time to respond and we would rather have a faster if less-accurate
 * response at app initialization than a more accurate but slower response.
 */
export const INITIALIZATION_LOCATION_CACHE_MAX_AGE: number = 7 /* days */
  * 24 /* hours */
  * 60 /* minutes */
  * 60 /* seconds */
  * 1000; /* milliseconds */

type OnLocationLoadedPayload = UserLocation & { created?: number };

type OnLocationErrorPayload = {
  error: string;
};

function getDefaultState(): LocationState {
  return {
    loading: false,
    latitude: null,
    longitude: null,
    address: null,
    source: null,
    created: null,
    error: null,
  };
}

const saveLocationToCache = (location: UserLocation) => {
  if (location.source !== LocationSource.Device) return;

  setItem('geolocation', { ...location, created: Date.now() });
};

/**
 * The location model is used to load and cache a user's Geolocation
 * via the browser's geolocation API (when available).
 * It initializes when the store does, using the latest cache from localforage
 * if it exists, otherwise loading the current geolocation.
 *
 * Falls back to using geoIP if geolocation fails to load.
 *
 * Consumers may handle max age/expiration separately by reading the
 * `created` timestamp attribute
 */
const model = createModel<RootModel>()({
  name: 'location',

  state: getDefaultState(),

  reducers: {
    onLocationLoaded: (state: LocationState, payload: OnLocationLoadedPayload) => ({
      ...state,
      created: Date.now(),
      ...payload,
      loading: false,
    }),
    onLocationLoading: (state: LocationState) => ({
      ...state,
      loading: true,
    }),
    onLocationError: (state: LocationState, { error }: OnLocationErrorPayload) => ({
      ...state,
      loading: false,
      ...{ error },
    }),
  },

  effects: dispatch => ({
    loadGeolocation: async (): Promise<void> => {
      dispatch.location.onLocationLoading();
      try {
        const locationResult = await getUserLocation();
        saveLocationToCache(locationResult);
        dispatch.location.onLocationLoaded({ ...locationResult });
      } catch (e) {
        dispatch.location.onLocationError({ error: e.message });
      }
    },

    initialize: async (): Promise<void> => {
      // Check the cache, initialize from that
      dispatch.location.onLocationLoading();
      const location = await getItem('geolocation') as CachedUserLocation;

      if (location
        && (location?.created || 0) + INITIALIZATION_LOCATION_CACHE_MAX_AGE > Date.now()) {
        dispatch.location.onLocationLoaded({ ...location, source: LocationSource.Cache });
        return;
      }

      // Cache is nonexistant or old, so remove anything that's there
      removeItem('geolocation');

      try {
        const hasPermission = await checkLocationPermissionsNotDenied();
        if (hasPermission) {
          const locationResult = await getUserLocation();
          saveLocationToCache(locationResult);
          dispatch.location.onLocationLoaded({ ...locationResult });
        } else {
          const geoIPResult = await getGeoIP();
          if (!geoIPResult) throw new Error('GeoIP did not return a valid location');

          dispatch.location.onLocationLoaded({
            latitude: Number(geoIPResult.latitude),
            longitude: Number(geoIPResult.longitude),
            address: [
              geoIPResult.city,
              geoIPResult.region,
            ].filter(Boolean).join(', '),
            source: LocationSource.GeoIP,
          });
        }
      } catch (e) {
        nonCriticalException(e);
        dispatch.location.onLocationError({ error: e.message });
      }
    },
  }),

  selectors: slice => ({
    userLocation(): Selector<RootState, LocationState> {
      return slice(state => state);
    },
  }),
});

export default model;
