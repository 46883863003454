// @ts-strict-ignore
import nonCriticalException from '../../modules/exceptionLogger';
import { GOOGLE_CLIENT_ID, GOOGLE_AUTH_SCOPE } from '../../config';
import { getIsApp } from '../../modules/AppInfo';

let googleAuth: gapi.auth2.GoogleAuthBase;

/**
 * Loads the google API script.
 * @returns A promise resolving to the google auth instance once it has loadeed
 */
function loadGoogleAuthAPI(): Promise<gapi.auth2.GoogleAuth> {
  return new Promise((resolve, reject) => {
    let gapiLoadTimedOut: boolean = false;

    const gapiTimeout = setTimeout(
      () => { gapiLoadTimedOut = true; },
      20000,
    );

    function load() {
      if (gapiLoadTimedOut) {
        reject(new Error('Could not load Google tooling'));
      } else if (!window.gapi || !window.gapi.client) {
        clearTimeout(gapiTimeout);
        setTimeout(load, 100);
      } else {
        clearTimeout(gapiTimeout);
        window.gapi.load('auth2', () => {
          resolve(window.gapi.auth2.init({
            client_id: GOOGLE_CLIENT_ID,
            scope: GOOGLE_AUTH_SCOPE,
          }));
        });
      }
    }

    // adaptation of the FB script loading snippet for google
    (function (d, s, id) {
      const gjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      const js = d.createElement(s) as HTMLScriptElement; js.id = id;
      js.src = '//apis.google.com/js/client.js';
      gjs.parentNode.insertBefore(js, gjs);
    }(document, 'script', 'google-signin'));

    load();
  });
}

/**
 * Gets the google auth API reference, loading the API script if needed
 * @returns The google auth API
 */
export async function getGoogleAuthAPI(): Promise<gapi.auth2.GoogleAuth> {
  let googleAuthValue = googleAuth;

  if (!googleAuthValue && !getIsApp()) {
    try {
      googleAuthValue = await loadGoogleAuthAPI();
    } catch (e) {
      nonCriticalException(e);
    }
  }

  return googleAuthValue;
}

/**
 * Calls the `grantOfflineAccess` function of the google auth API. There does not seem to be a way
 * to determine if the user declined or canceled auth.
 * @param force True to prompt for consent, otherwise false.
 * @returns The response code needed to relay to the API for further interactions
 * @see
 * https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
 */
export async function grantOfflineAccess(force: boolean): Promise<string> {
  const result = await (await getGoogleAuthAPI()).grantOfflineAccess({
    // @ts-expect-error
    redirect_uri: 'postmessage',
    prompt: force ? 'consent' : null,
  });

  return result.code;
}
