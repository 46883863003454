// @ts-strict-ignore
import { createModel, RematchRootState } from '@rematch/core';
import { StoreSelectors } from '@rematch/select';
import { IProviderClient } from '../../../../api/Providers/Clients/types';
import { PrivilegedProvider } from '../../../../api/Providers';
import type { RootModel } from '../../../../store/models';
import { NCC_HUB_SWITCH } from '../../../provider/today/NCCHub/constants';

type State = {
  loading: boolean;
};

export type ProClientListPageData = State & {
  clients: IProviderClient[];
  provider: PrivilegedProvider;
};

export type ProClientDetailPageData = {
  client: IProviderClient;
};

const defaultState = {
  loading: false,
};

const model = createModel<RootModel>()({
  name: 'proClientList',

  state: defaultState,

  reducers: {
    onLoading: () => ({
      loading: true,
    }),

    onLoaded: () => ({
      loading: false,
    }),
  },

  effects: dispatch => ({
    land: async (
      payload: { providerId: number },
    ) => {
      const { providerId } = payload;
      dispatch.proClientList.onLoading();
      dispatch.providers.loadProvider({ providerId, setActive: true });
      await dispatch.providerClients.loadAll({ providerId });
      dispatch.proClientList.onLoaded();
    },

    detailsLand: async (
      payload: { providerId: number; clientId: number },
    ) => {
      const { providerId, clientId } = payload;
      await dispatch.providerClients.loadClient({ clientId, providerId });
      await dispatch.providerClients.loadClientSMSStatus({
        providerId,
        clientId,
      });
    },
  }),

  selectors: (slice, createSelector) => ({
    getListPageData: (models: StoreSelectors<RootModel, Record<string, never>>) => createSelector(
      slice,
      models.providerClients.sortedClients,
      state => state.providerClients.providerId,
      state => state.providers.providersById,
      state => state.abTest.assignments,
      /**
       * Return clients filtered by removing unconfirmed ncd clients,
       * as it means the provider has not accepted the appointment, so
       * it should not appear on the client list yet.
       * Reference: APO-4190
       * Hide when pro_appointment_acceptdecline_apo4190_20231114_2023 is on
       */
      (state, clients, providerId, providers, assignments): ProClientListPageData => {
        const provider = providers[providerId]?.result as PrivilegedProvider || null;
        const shouldFilterPendingClients = assignments[NCC_HUB_SWITCH]?.isEnabled;
        const filteredClients = shouldFilterPendingClients
          ? clients.filter(client => client.is_unconfirmed_ncd !== true)
          : clients;
        return {
          ...state,
          clients: filteredClients,
          provider,
        };
      }
      ,
    ),

    getDetailsPageData: () => createSelector<
    RematchRootState<RootModel>,
    Record<number, IProviderClient>,
    string,
    ProClientDetailPageData
    >(
      state => state.providerClients.clients,
      state => state.route.params?.clientId,
      (clients, clientId): ProClientDetailPageData => ({
        client: clients[Number(clientId)],
      }),
    ),
  }),
});

export default model;
