// @ts-strict-ignore
import { ProviderSearchRankStats } from '../modules/Api.types';
import { SearchRankStats, SearchRankStatsTenDays } from './ProviderSearchRank.types';

// Criteria for current Search Rank page
const TOP_PRO_CRITERIA = {
  clientPayments: 65,
  newClientPayments: 90,
  reviews: 90,
  refunds: 5,
};

// Criteria for revised search rank page (RES-266 AB Test)
const STATS_TEN_DAY_CRITERIA = {
  appointmentsPaidOnStyleseat: {
    mediumTarget: 20,
    highTarget: 60,
  },
  ncdFeePaidAppointments: {
    mediumTarget: 40,
    highTarget: 60,
  },
  newProAppointmentCountThreshold: 5,
};

/**
  Calculates values to display on SearchRank page and progress bars
*/
export function calculateStatsLastThirty(stats: ProviderSearchRankStats): SearchRankStats {
  const {
    completedAppointmentCount: appointments,
    completedOnStyleseatAppointmentCount: payments,
    dateRangeStart,
    ncdCompletedAppointmentCount: ncdAppointments,
    ncdCompletedOnStyleseatAppointmentCount: ncdPayments,
    ncdDateRangeStart,
    reviewTotalCount: allReviews,
    reviewPositiveCount: positiveReviews,
    reviewDateRangeStart,
    ncdRefundedAppointmentCount: refundedCount,
  } = stats;
  const calculatedStats: SearchRankStats = {
    clientPayments: {
      actual: Math.round((payments / appointments) * 100),
      target: TOP_PRO_CRITERIA.clientPayments,
      comparison: '>=',
      startDate: dateRangeStart,
      raw: {
        numerator: payments,
        denominator: appointments,
      },
    },
    newClientPayments: {
      actual: Math.round((ncdPayments / ncdAppointments) * 100),
      target: TOP_PRO_CRITERIA.newClientPayments,
      startDate: ncdDateRangeStart,
      comparison: '>=',
      raw: {
        numerator: ncdPayments,
        denominator: ncdAppointments,
      },
    },
    reviews: {
      actual: Math.round((positiveReviews / allReviews) * 100),
      target: TOP_PRO_CRITERIA.reviews,
      startDate: reviewDateRangeStart,
      comparison: '>=',
      raw: {
        numerator: positiveReviews,
        denominator: allReviews,
      },
    },
    refunds: {
      actual: Math.round((refundedCount / ncdPayments) * 100),
      target: TOP_PRO_CRITERIA.refunds,
      startDate: ncdDateRangeStart,
      comparison: '<=',
      raw: {
        numerator: refundedCount,
        denominator: ncdAppointments,
      },
    },
  };

  const criteriaSatisfied = Object
    .keys(calculatedStats)
    .reduce((previous: number, current: string) => {
      const stat = calculatedStats[current];

      if (stat.comparison === '>=') {
        if (stat.actual >= stat.target) {
          return previous + 2;
        }

        if (stat.actual >= (stat.target / 2)) {
          return previous + 1;
        }
      }

      if (stat.comparison === '<=') {
        if (stat.actual <= stat.target) {
          return previous + 2;
        }

        if (stat.actual <= (stat.target / 2)) {
          return previous + 1;
        }
      }

      return previous;
    }, 0);

  const parsedStats = {
    ...calculatedStats,
    criteriaSatisfied,
  };

  return parsedStats;
}

/**
  Calculates values to display on revised SearchRank page (RES-266 AB Test)
  - Pro must meet all high criteria to be marked high
  - Pro must meet all medium criteria to me marked medium (if missing any high marks)
  - Pros that disable NCD will be marked low rank regardless
  - New pros (less than 5 appointments total) will be marked low rank regardless
*/
export function calculateStatsLastTen(stats: ProviderSearchRankStats): SearchRankStatsTenDays {
  const {
    ncdEnabled,
    lastTenAppointments: {
      completedAppointmentCount: appointmentsLastTen,
      completedOnStyleseatAppointmentCount: paymentsLastTen,
      ncdCompletedAppointmentCount: ncdAppointmentsLastTen,
      ncdMonetizedCount: ncdMonetizedLastTen,
    },
  } = stats;

  const calculatedStats = {
    appointmentsPaidOnStyleseat: {
      actual: appointmentsLastTen > 0
        ? Math.round((paymentsLastTen / appointmentsLastTen) * 100)
        : 0,
      mediumTarget: STATS_TEN_DAY_CRITERIA.appointmentsPaidOnStyleseat.mediumTarget,
      highTarget: STATS_TEN_DAY_CRITERIA.appointmentsPaidOnStyleseat.highTarget,
      raw: {
        numerator: paymentsLastTen,
        denominator: appointmentsLastTen,
      },
    },
    ncdFeePaidAppointments: {
      actual: ncdAppointmentsLastTen > 0
        ? Math.round((ncdMonetizedLastTen / ncdAppointmentsLastTen) * 100)
        : 0,
      mediumTarget: STATS_TEN_DAY_CRITERIA.ncdFeePaidAppointments.mediumTarget,
      highTarget: STATS_TEN_DAY_CRITERIA.ncdFeePaidAppointments.highTarget,
      raw: {
        numerator: ncdMonetizedLastTen,
        denominator: ncdAppointmentsLastTen,
      },
    },
  };

  const criteriaSatisfiedLastTen = Object
    .keys(calculatedStats)
    .reduce((previous: number, current: string) => {
      const stat = calculatedStats[current];

      if (stat.actual >= stat.highTarget) {
        return previous;
      }

      if (stat.actual >= stat.mediumTarget) {
        return Math.min(previous, 1);
      }

      return 0;
    }, 2);

  const isNewPro = appointmentsLastTen < STATS_TEN_DAY_CRITERIA.newProAppointmentCountThreshold;

  const parsedStats = {
    ...calculatedStats,
    criteriaSatisfiedLastTen: (ncdEnabled && !isNewPro) ? criteriaSatisfiedLastTen : 0,
    ncdEnabled,
    isNewPro,
  };

  return parsedStats;
}

export function isTopProEligible(stats: SearchRankStatsTenDays) {
  return stats.ncdEnabled
    && stats.appointmentsPaidOnStyleseat.actual >= stats.appointmentsPaidOnStyleseat.mediumTarget
    && (
      stats.ncdFeePaidAppointments.raw.denominator === 0
      || stats.ncdFeePaidAppointments.actual >= stats.ncdFeePaidAppointments.mediumTarget
    );
}
