import { SegmentEventData } from '@styleseat/analytics/trackers/segment/events/types';

export enum OnboardingSegmentProperty {
  GoalSelected = 'goal_selected',
  Last4CC = 'last_4_cc',
  AvailabilityDaysCount = 'availability_days_count',
  ServicesCount = 'services_count',
  BusinessAddress = 'business_address',
  BusinessName = 'business_name',
  ProviderProfilePhoto = 'provider_profile_photo',
  GalleryImageCount = 'gallery_image_count',
  NextBestAction = 'tip',
}

export enum AnalyticsEvent {
  Viewed = 'Viewed',
  Completed = 'Completed',
  Failed = 'Failed',
}

type OnboardingEventConfig = {
  name: string;
  segmentData?: SegmentEventData;
};

export type TrackingEventsProp = Partial<Record<AnalyticsEvent, OnboardingEventConfig>>;
