// @ts-strict-ignore
import breakpoints from '../responsive_config';
import { getWidth } from './getWidth';

/**
 * Validate if the browser window width is above a specified breakpoint.
 * @param {Object} breakpoint - Object with a min and/or max value.
 * @return {boolean}
 */
export function isAbove(breakpointName) {
  const breakpoint = breakpoints[breakpointName];
  if (breakpoint.max && getWidth() > breakpoint.max) {
    return true;
  }
  return false;
}
