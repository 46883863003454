// @ts-strict-ignore
import moment from 'moment';
import { copyValue } from '../utils/copyValue';
import { isDefined } from '../utils/is';
import { loopOverDatasets } from '../utils/loopOverDatasets';
import { sortDatasetByDate } from '../sort/byDate';
import {
  DataFields,
  DataSet,
  GraphableDataSet,
} from '../types';

/**
 * Fill in date gaps in the data for a specific field.
 *
 * This will go through the dates for a field and enter a value for
 * any date that seems to be missing in the middle.
 *
 * EXAMPLE:
 * Let's say our data looks like this (date strings would actually be timestamps):
 * ```
 *   data = {
 *     appts_booked: [
 *       { date: <2016-01-01>, appts_count: 1 },
 *       { date: <2016-01-02>, appts_count: 5 },
 *
 *       { date: <2016-01-06>, appts_count: 3 },
 *       { date: <2016-01-07>, appts_count: 8 },
 *     ]
 *   };
 * ```
 *
 * Notice how the dates go from Jan 2, to Jan 6 (missing 3, 4 and 5).
 * We could fill in these values with 0, by doing this:
 *
 * ```
 *   var filledData = fill(data, { appts_count: 0 });
 * ```
 *
 * @param {Object} dataset The dataset object
 * @param {String|Object} fillValue The value to use for missing dates.
 * @param {String|Moment} start (optional) The date to start from in the dataset
 * @param {String|Moment} end   (optional) The date to end at in the dataset
 *
 * @return {Object} A new dataset with empty dates filled in
 */
export function fillDataset<F extends DataFields = DataFields>(
  dataset: DataSet<F> | GraphableDataSet,
  fillValue: any,
  start?: string | moment.Moment,
  end?: string | moment.Moment,
) {
  // Recursive call for all value sets
  if (!Array.isArray(dataset)) {
    return loopOverDatasets(dataset, valueSet => (
      fillDataset(valueSet, fillValue, start, end)
    ));
  }
  if (!dataset.length && (!isDefined(start) || !isDefined(end))) {
    return dataset;
  }

  // Sort data
  const fieldData = [...dataset];
  sortDatasetByDate(fieldData);

  const startDate = isDefined(start) ? moment(start, 'YYYY-MM-DD') : fieldData[0].date;
  const endDate = isDefined(end) ? moment(end, 'YYYY-MM-DD') : fieldData[fieldData.length - 1].date;

  startDate.startOf('day');
  endDate.startOf('day');

  // Make sure the end date is present (or a day after)
  // This simplifies the fill logic in the loop.
  if (!fieldData.length || fieldData[fieldData.length - 1].date.isBefore(endDate)) {
    const endValue = copyValue(fillValue);
    endValue.date = endDate.clone();
    fieldData.push(endValue);
  }

  // Loop through all the data and fill in missing values between the range
  const currentDate = startDate.clone();
  for (let i = 0; i < fieldData.length; i++) {
    let value = fieldData[i];
    const { date } = value;

    if (date && !date.isBefore(startDate)) {
      if (date.isAfter(endDate)) {
        // We're at the end of the range
        break;
      }

      // Fill in missing dates
      if (date.isAfter(currentDate)) {
        while (currentDate.isBefore(date)) {
          value = copyValue(fillValue);
          value.date = currentDate.clone();

          fieldData.splice(i, 0, value);
          i++;

          currentDate.add(1, 'day');
        }
        i--;
      } else {
        currentDate.add(1, 'day');
      }
    }
  }

  return fieldData;
}
