import type { ProviderCancellationPolicy } from '../Api.types';

type PaymentSettingsBankAccount = {
  last4: string;
};

export type VerificationAddressType = (
  'line1' | 'city' | 'state' | 'postal_code'
);

export enum VerificationStatus {
  OK = 'ok',
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
  EMPTY = 'empty',
}

export type VerificationError = {
  code: string;
  field: string;
  reason: string;
};

export type PaymentSettingsIdentityVerification = {
  status: VerificationStatus;
  in_probation: boolean;
  has_address: boolean;
  needs_full_ssn: boolean;
  needs_legal_document: boolean;
  needs_company_ein: boolean;
  needs_valid_address: VerificationAddressType[];
  needs_valid_phone: boolean;
  errors: VerificationError[];
};

export enum VerificationDataMissing {
  General = 'general',
  EIN = 'needCompanyEIN',
  LegalDoc = 'legalDoc',
  FullSSN = 'needFullSSN',
}

export type PaymentSettingsInstantDeposits = {
  allowed: boolean;
  /** Decimal Field */
  minimum: string;
  /** Decimal Field */
  maximum: string;
  /** ISO8601 Date */
  next_available_time: string;
  /** Number. 5 = 5% */
  fee_percentage: number;
};

export type PaymentsOnboardingSteps = 'bank' | 'identity' | 'address';

export interface IPaymentSettingsV3 {
  autocharge_enabled: boolean;
  autocharge_approved: boolean;
  bank_account: PaymentSettingsBankAccount | null;
  autocheckout_enabled: boolean | null;
  /** ISO8601 Date */
  autocheckout_enabled_time: string | null;
  cancellation_policy: ProviderCancellationPolicy;
  identity_verification: PaymentSettingsIdentityVerification | null;
  instant_deposits: PaymentSettingsInstantDeposits | null;
  gift_card_enabled: boolean;
  on_commission_plan: boolean;
  payments_onboarding_steps: PaymentsOnboardingSteps[];
  is_payments_setup_mandatory: boolean;
  stripe_account_id: string | null;
}
