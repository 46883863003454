// @ts-strict-ignore
import { Platform } from 'react-native';
import { getIsApp } from '../../../modules/AppInfo';
import nonCriticalException from '../../../modules/exceptionLogger';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { clearHistoryCache } from '../Clients/History';
import { ExifSupport } from './CreateImage';
import {
  BatchImportCancelledError,
  BatchImportError,
  BatchUploadResponse,
  BatchUploadResult,
  GalleryImageType,
  ImageUploadParams,
  ImageUploadResult,
  PhotoPayload,
  PrivacyChoices,
  UploadProgressEvent,
} from './types';
import { buildImageCreationUrl } from './buildUrl';
import { clearImageCache } from './cache';

// see BaseImageImportSerializer, GalleryImageImportSerializer, BaseGalleryImageSerializer
type ImageUploadPayload = {
  blurb?: string;
  order?: number;
  uploader_instagram_id?: string;
  instagram_id?: string;
  rotate?: number;
  crop_left?: number;
  crop_top?: number;
  crop_right?: number;
  crop_bottom?: number;
  browser_exif_support?: ExifSupport;
  client?: number;
  appointment_id?: number;
  privacy_status?: PrivacyChoices;
  service_id?: number;
  cover_image?: 0 | 1;
};

/**
 * Transform image upload parameters to the format expected by the API.
 * @param param0 Input parameters
 * @param param0.crop The crop object, which should contain `crop_top`, `crop_left`,
 * `crop_bottom`, `crop_right`, `rotate` props
 * @param param0.serviceId The service ID
 * @param param0.apptId The appointment ID
 * @param param0.isCover True if this should be added as a cover photo
 * @param param0.privacy The privacy status
 * @param param0.clientId The client ID
 * @param param0.order The order for the image
 * @param param0.blurb The image caption
 * @returns The transformed parameters
 */
function transformUploadParams({
  crop,
  serviceId,
  apptId,
  isCover,
  privacy,
  clientId,
  blurb,
  uploaderInstagramID,
}: ImageUploadParams): ImageUploadPayload {
  const result: ImageUploadPayload = {};

  result.privacy_status = privacy;

  if (blurb) {
    result.blurb = blurb;
  }

  if (clientId !== null && clientId !== undefined) {
    result.client = clientId;
  }

  if (serviceId) {
    result.service_id = serviceId;
  }

  if (apptId) {
    result.appointment_id = apptId;
  }

  if (isCover) {
    result.cover_image = 1;
  }

  if (uploaderInstagramID) {
    result.uploader_instagram_id = uploaderInstagramID;
  }

  if (typeof crop?.rotate === 'number') {
    result.rotate = crop.rotate;
  }

  if (crop?.box) {
    const {
      top,
      bottom,
      left,
      right,
    } = crop.box;

    if (left || right || bottom || top) {
      result.crop_top = top;
      result.crop_left = left;
      result.crop_bottom = bottom;
      result.crop_right = right;
    }
  }

  return result;
}

function importImageFromUrl(
  providerId: number,
  url: string,
  imageType: GalleryImageType,
  params: ImageUploadParams,
): ImageUploadResult {
  const fullUrl = buildImageCreationUrl(imageType, providerId, 'import');
  const optionalParams = {};
  const abortController = new AbortController();
  const cancel: () => void = () => {
    abortController.abort();
  };

  Object.entries(transformUploadParams(params)).forEach(([key, value]) => {
    optionalParams[key] = value;
  });

  return {
    cancel,
    result: ssFetchJSON<BatchUploadResponse>(fullUrl, {
      method: 'POST',
      body: {
        url,
        ...optionalParams,
      },
      signal: abortController.signal,
    }),
  };
}

function importImageFromFile(
  providerId: number,
  file: File,
  imageType: GalleryImageType,
  params: ImageUploadParams,
  userAuthToken?: string,
): ImageUploadResult {
  const fullUrl = buildImageCreationUrl(imageType, providerId, 'upload');
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  let cancel: () => void = () => { };

  const acceptedType = [
    'image/jpeg',
    'image/png',
    'image/jpg',
  ].find(type => type === file.type);

  const filePromise = new Promise<any>((resolveFile, rejectFile) => {
    if (acceptedType) {
      if (getIsApp() && Platform.OS === 'web') {
        /**
         * In the app, we *will* get an instance of `File`, but it doesn't seem that this inherits
         * from `Blob` like it does in the browser. Because of this, `FormData.append` does not
         * work out of the box; the file is appended as `[object Object]`. So we have to load it
         * into a blob and then send it ahead for uploading.
         */
        const reader = new FileReader();
        reader.onloadend = () => {
          // because of our call to `readAsArrayBuffer`, `reader.result` is an ArrayBuffer
          resolveFile(new Blob([reader.result], { type: file.type }));
        };
        reader.onerror = err => rejectFile(err);
        reader.readAsArrayBuffer(file);
      } else {
        resolveFile(file);
      }
    } else {
      rejectFile(new Error('Unsupported File Type'));
    }
  });

  const result = new Promise<BatchUploadResponse>((resolve, reject) => {
    if (acceptedType) {
      xhr.open('POST', fullUrl, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else if (xhr.readyState === 4) {
          // Failed requests didn't have a JSON response
          reject(xhr.statusText);
        }
      };

      filePromise.then(fileData => {
        // Populate Form data for post request
        fd.append('file', fileData, file.name);

        Object.entries(transformUploadParams(params)).forEach(([key, value]) => {
          fd.append(key, typeof value === 'number' ? String(value) : value);
        });

        // Image uploads are a secure api
        xhr.setRequestHeader('Authorization', `Token: ${userAuthToken}`);
        xhr.send(fd);

        cancel = () => {
          xhr.abort();
          reject(new Error('Cancelled'));
        };
      }).catch(err => {
        reject(err);
      });
    } else {
      reject(new Error('Unsupported File Type'));
    }
  });

  return {
    cancel,
    result,
  };
}

function importImageFromFileOrUrl(
  providerId: number,
  payload: File | string,
  imageType: GalleryImageType,
  params: ImageUploadParams,
  userAuthToken?: string,
): ImageUploadResult {
  if (typeof payload === 'string') {
    return importImageFromUrl(
      providerId,
      payload,
      imageType,
      params,
    );
  }

  return importImageFromFile(
    providerId,
    payload,
    imageType,
    params,
    userAuthToken,
  );
}

type ImportPhotosParameters = {
  photos: Array<PhotoPayload>;
  providerId: number;
  // these actually should be strings instead of the GalleryImageType enum
  imageType: 'gallery' | 'profile' | 'newsletter';
  onProgress?: (event: UploadProgressEvent) => void;
  userAuthToken?: string;
  clientId?: number;
  serviceId?: number;
  apptId?: number;
  uploadParallel?: boolean;
};

/*
  Import photos for a given import method in this service (`importFn`)
  Requests are chained
  Notifies of overall progress updates and resolves with task ids
  Takes an array of photo objects with `url` and `caption` properties
  Optionally takes a `cancelPromise` that when resolved will cancel the import
*/
export function importPhotos({
  photos,
  providerId,
  imageType,
  onProgress,
  userAuthToken,
  clientId,
  serviceId,
  apptId,
  uploadParallel = false,
}: ImportPhotosParameters): BatchUploadResult {
  let cancelled = false;
  let currentCancel: () => void = () => { };
  const cancel: () => void = () => {
    currentCancel();
    cancelled = true;
  };

  onProgress?.({
    numTotal: photos.length,
    numComplete: 0,
  });

  const result = new Promise<Array<string>>((resolve, reject) => {
    const tasks: Array<string> = [];

    function importPhoto(index: number) {
      const photo = photos[index];
      let privacy = photo.public === false ? PrivacyChoices.Private : PrivacyChoices.Public;
      let derivedImageType: number = GalleryImageType.ProviderGalleryImport;
      const errors = [];

      if (imageType === 'profile') {
        derivedImageType = GalleryImageType.ProviderProfileImport;
      } else if (imageType === 'newsletter') {
        // Newsletter images are internally gallery images with a privacy set to 3.
        derivedImageType = GalleryImageType.ProviderGalleryImport;
        privacy = PrivacyChoices.Newsletter;
      }

      const { result: importResult, cancel: cancelImport } = importImageFromFileOrUrl(
        providerId,
        photo.url,
        derivedImageType,
        {
          ...photo,
          uploaderInstagramID: photo.userId,
          clientId: photo.clientId || clientId,
          serviceId: photo.serviceId || serviceId,
          apptId,
          privacy,
        },
        userAuthToken,
      );

      currentCancel = cancelImport;

      importResult.then(response => {
        if ('status' in response && response.status === 'OK') {
          // Clear image caches
          clearImageCache();
          if (clientId) {
            clearHistoryCache();
          }
          tasks.push(response.task_id);
        } else if ('status' in response && response.status === 'error') {
          nonCriticalException(`Failed to import photo: ${response.error}`);
          errors.push(response.error);
        } else if (response.message) {
          nonCriticalException(`Failed to import photo: ${response.message}`);
          errors.push(response.message);
        }
      }, reason => {
        nonCriticalException(`Failed to import photo: ${reason}`);
        errors.push(reason);
      }).finally(() => {
        if (cancelled) {
          reject(new BatchImportCancelledError('Cancelled'));
        } else if (index === photos.length - 1) {
          // If the tasks array is still empty after all photos have been imported, then all imports
          // have failed.
          // Reject the promise in this case with the list of all errors
          if (!tasks.length) {
            reject(new BatchImportError('Import failed', errors));
          } else {
            // If at least one of the import succeeds, resolve the promise to track the progress of
            // that import
            resolve(tasks);
          }
        } else {
          onProgress?.({
            numTotal: photos.length,
            numComplete: index + 1,
          });

          if (!uploadParallel) {
            importPhoto(index + 1);
          }
        }
      });
    }

    if (uploadParallel) {
      photos.forEach((_, index) => {
        importPhoto(index);
      });
    } else {
      importPhoto(0);
    }
  });

  return {
    cancel,
    result,
  };
}
