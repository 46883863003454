// @ts-strict-ignore
import loadable from '@loadable/component';
import type { RouteConfig } from '../../packages/router';
import { isPreStage, isProduction } from './modules/env';
import { AUTH_MESSAGES } from './modules/user/constants';
import {
  BOOKING_ROUTE,
  BOOKING_SUCCESS_ROUTE,
  CLIENT_PAYMENT_METHOD_ADD,
  CLIENT_PAYMENT_METHOD_DETAIL,
  CLIENT_PAYMENT_METHOD_LIST,
  CLIENT_PAYMENT_METHOD_PARENT,
  PROFILE_EDIT_ROUTE,
  PROFILE_EDIT_SERVICE_INFO_ROUTE,
  PROFILE_ROUTE,
  PROVIDER_SEARCH_RANK,
  PROVIDER_SEARCH_RANK_NEW_CLIENTS,
  PROVIDER_SEARCH_RANK_PAYMENTS,
  SALON_ROUTE,
  ADJUST_TIP,
  ADJUST_TIP_THANK_YOU,
  ADJUST_TIP_DEFAULT,
  ADJUST_TIP_REFUNDED,
  CLIENT_ACCOUNT_SETTINGS_ROUTE,
  CLIENT_CONTACT_INFORMATION_ROUTE,
  CLIENT_PROMOTIONS_LIST_ROUTE,
  CLIENT_CHANGE_PASSWORD_ROUTE,
  CLIENT_DEFAULT_TIP_SETTING_ROUTE,
  CLIENT_APPTS_LIST,
  CLIENT_APPTS_LIST_DEFAULT,
  CLIENT_APPTS_LIST_FUTURE,
  PROVIDER_CLIENT_NOTE,
  PRO_PERSONAL_APPOINTMENT_VIEW,
  PRO_PERSONAL_APPOINTMENT_EDIT,
  PRO_CALENDAR_REFUND_APPOINTMENT,
  TRACKED_CLIENT_SIGNUP,
  CLIENT_PAYMENT_REQUEST_CHECKOUT,
  CLIENT_INVITED_TO_BOOK,
  USER_DEPENDENT_LEGAL,
  USER_INDEPENDENT_LEGAL,
  PRO_CLIENT_IMPORT,
  PROVIDER_EDIT_REVIEWS,
  CHAT,
  PRO_PERSONAL_INFO_SETTINGS,
  PRO_CLIENT_NOTIFICATION_SETTINGS,
  PRO_NOTIFICATION_SETTINGS,
  PROVIDER_SETTINGS_SOCIAL,
  PRO_ADJUST_UPCOMING_HOURS,
  PRO_TAPTOPAY_ONBOARD_SETTINGS,
  PRO_TAPTOPAY_ONBOARD_SETTINGS_DEEP_LINK,
  PRO_BUSINESS_INFO_SETTINGS,
  PRO_MANAGE_VACATION,
  PRO_CLIENT_LIST,
  PRO_CLIENT_VIEW_APPOINTMENTS,
  PRO_CALENDAR_NEW_APPOINTMENT,
  CALENDAR_ROUTE,
  PRO_MANAGE_ONGOING_SCHEDULE,
  PRO_SERVICE_MENU,
  PRO_CALENDAR_VIEW_APPOINTMENT,
  PRO_CALENDAR_EDIT_APPOINTMENT,
  PRO_CLIENT_VIEW,
  PRO_CLIENT_VIEW_APPOINTMENT,
  PRO_IMAGE_LIST,
  EXPIRED_APP_BLOCKER_ROUTE,
  PROVIDER_SETTINGS_ONLINE_BOOKING_MENU,
  PROVIDER_SETTINGS_ONLINE_BOOKING,
  PRINTABLE_CALENDAR_ROUTE,
  PRO_ADD_SERVICE,
  PRO_ADD_ADD_ON,
  PRO_EDIT_ADD_ON,
  PROVIDER_FEES,
  CLIENT_INBOX,
  PRO_NCDC_APPROVE_APPOINTMENT,
  PRO_EDIT_SERVICE,
  PRO_CALENDAR_GOOGLE_SYNC,
  PRO_EMAIL_MARKETING_DASHBOARD,
  PRO_SMART_PRICE_SETTINGS,
  PROVIDER_GROWTH_GIFT_CARDS,
  PRO_PROMOTIONS_LIST,
  PRO_PROMOTION_DETAIL,
  PRO_PROMOTION_CREATE,
  PRO_PROMOTION_EDIT,
  PRO_SERVICE_ONBOARDING,
  PROVIDER_GROWTH_SUMMARY,
  PROVIDER_GROWTH_EARNINGS,
  PROVIDER_GROWTH_REPORTING,
  PRO_EMAIL_MARKETING_CREATE_FLOW_ROUTE,
  PRO_EMAIL_MARKETING_EDIT_FLOW_ROUTE,
  PROVIDER_SETTINGS_PAYMENTS_MENU,
  PROVIDER_SETTINGS_PAYMENTS_ADDRESS,
  PROVIDER_SETTINGS_PAYMENTS_CHECKOUT,
  PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
  PROVIDER_SETTINGS_PAYMENTS_ONBOARDING,
  PROVIDER_SETTINGS_PAYMENTS_POLICY,
  PRO_MESSAGES_LIST,
  PROVIDER_EDIT_NSLC_POLICY,
  PRO_BANK_ACCOUNT_SETTINGS,
  CLIENT_PHONE_SETTINGS,
  PROVIDER_MARKETING_HUB,
  PRO_SETTINGS_PLAN_SELECTION,
  AUTH_LOGIN,
  HOME_ROUTE,
  PRO_ACCOUNT_MENU,
  PRO_PLAN_SETTINGS,
  PROVIDER_GROWTH_TRANSACTIONS,
  PRO_TODAY_TAB_HOME,
  PRO_NOTIFICATIONS_LIST,
  PRO_NOTIFICATION_SETTINGS_SUBMENU,
  PROVIDER_SETTINGS_FRONT_DESK,
  PRO_CHAT_INBOX,
  PROVIDER_SETTINGS_MORE,
  PROVIDER_GROWTH_PAYOUTS,
  PROVIDER_SIGNUP_ROUTE,
  LANDING_ROUTE,
  MOBILE_APP_START_ROUTE,
  PRO_NEW_CLIENT_DELIVERY_SETTINGS,
  SEARCH_ROUTE,
  CITY_SEARCH_LANDING,
  PROFILE_PHOTO,
  PROVIDER_VANITY_PHOTO,
  PRO_GALLERY,
  CLIENT_APPT_STATUS_DETAILS,
  CLIENT_GET_HELP,
  CLIENT_PREPAY_APPT,
  GUEST_LANDING,
  CLIENT_ADD_CARD,
  CLIENT_LIST_CARD,
  SALON_PHOTO,
  ERROR_ROUTE,
  CLIENT_DISCOUNT_ENROLLMENT,
  PRO_CHANGE_PASSWORD,
  PRO_FEEDBACK,
  CLIENT_MANAGE_APPT,
  CLIENT_MORE_MENU,
  CLIENT_PASSWORD_RESET_CONFIRM,
  AUTH_WITH_STYLESEAT,
  PRO_CHANGE_EMAIL,
  PRO_CHECKOUT_SETTINGS,
  ADMIN_PROS,
  ADMIN_USERS,
  ERROR_BOUNDARY,
  COMPARE_ENV_FLAGS_SUMMARY,
  PROFILE_MESSAGE,
  PROFILE_SERVICE_INFO,
  PRO_PROMOTE_PROFILE,
  PRO_PROMOTE_PROFILE_SOCIAL,
  PRO_PROMOTE_PROFILE_INBOUND,
  PRO_PROMOTE_PROFILE_IN_PERSON,
  PROVIDER_TOP_PRO,
  PROVIDER_ONBOARDING_SIGNUP,
  PROVIDER_ONBOARDING_PROFILE,
  PROVIDER_ONBOARDING_ADDRESS,
  PROVIDER_ONBOARDING_SERVICES,
  PROVIDER_ONBOARDING_AVAILABILITY,
  PROVIDER_ONBOARDING_SETUP_SUBSCRIPTION,
  PROVIDER_ONBOARDING_PAYMENTS,
  PROVIDER_ONBOARDING_PLAN_SELECT,
  PROVIDER_ONBOARDING_GOAL_SELECT,
  PROVIDER_ONBOARDING_ROUTE,
  PROFILE_EDIT_ROUTE_ONBOARDING,
  PROVIDER_ONBOARDING_GALLERY,
  PRO_SETTINGS_PLAN_SELECTION_DEEP_LINK,
  PRO_PRODUCT_PAGE,
  PRO_EDIT_PRODUCT,
  PRO_FORM_SETTINGS,
  PRO_FORM_SETTINGS_QUESTION,
  PRO_TAX_SETTINGS,
  PRO_TAX_SETTINGS_EDELIVERY_ROUTE,
  PROVIDER_SETTINGS_ONLINE_BOOKING_DEEP_LINK,
  PROVIDER_SETTINGS_BUSINESS_AND_PROFILE,
  PROVIDER_SETTINGS_CALENDAR,
  PROVIDER_SETTINGS_MARKETING,
  PROVIDER_SETTINGS_EARNINGS_AND_REPORTING,
  PROVIDER_SETTINGS_PAYMENTS_AND_POLICIES,
  PROVIDER_SETTINGS_ACCOUNT,
  PROVIDER_SETTINGS_INSTAGRAM_BOOK_BUTTON,
  PROVIDER_SETTINGS_CALENDAR_VIEW_SETTINGS,
  MARKETING_UNSUBSCRIBE,
  GENERIC_DEEP_LINK,
  PROVIDER_SETTINGS_PAYMENTS_UPDATE_CREDIT_CARD,
  PROVIDER_SETTINGS_PAYMENTS_UPDATE_CREDIT_CARD_REDIRECT,
  PROVIDER_SETTINGS_PAYMENTS_DEPOSIT,
  PROVIDER_ONBOARDING_FREE_TRIAL_EXPLANATION,
  CLIENT_AUTO_CHECKOUT_SETTINGS_ROUTE,
  PROVIDER_GROWTH_LOANS,
  MARKETPLACE_RULES,
  PRO_TRUSTED_CLIENT_SETTINGS,
  PRO_CLIENT_GET_MORE,
} from './route-names';

export type RouteGuardConfig = {
  /**
   * True if a user must be logged in to view this route
   */
  loginRequired?: boolean;
  /**
   * The route state name to redirect to if user does not authenticate
   */
  loginFailedRedirect?: string; // should this support name / params, too?
  /**
   * The authentication origin to use for tracking
   */
  loginOrigin?: string;
  /**
   * A message to display to the user if logging in
   */
  loginMessage?: string;
  /**
   * A document title to set if the user is logged out
   */
  loggedOutTitle?: string;

  /**
   * True if the user needs an active subscription to see this route
   */
  subsRequired?: boolean;
};

export type StyleSeatRouteConfig = RouteConfig & RouteGuardConfig & {
  routes?: (StyleSeatRouteConfig & { isChild?: boolean })[];
};

export const routeConfigs: StyleSeatRouteConfig[] = [
  {
    stateName: LANDING_ROUTE,
    exact: true,
    url: [
      '/m/',
      '/',
      '/m',
      '',
    ],
    data: { trackingName: 'landing' },
    component: loadable(() => import('./components/pages/LandingRedirect')),
  },
  {
    stateName: GENERIC_DEEP_LINK,
    exact: true,
    url: ['/m/generic-deep-link'],
    component: loadable(() => import('./components/pages/DeepLinkRedirect')),
  },
  {
    stateName: EXPIRED_APP_BLOCKER_ROUTE,
    url: '/m/expired_app',
    component: loadable(() => import('./components/pages/ExpiredApp/ExpiredApp')),
  },
  {
    stateName: AUTH_LOGIN,
    url: '/m/login',
    component: loadable(() => import('./components/pages/LoginPage/LoginPage')),
    data: {
      trackingName: 'Login',
      landingPageOnAuth: true,
    },
  },
  {
    stateName: SEARCH_ROUTE,
    url: [
      '/m/search/:loc/:q',
      '/m/search//:q',
      '/m/search',
    ],
    component: loadable(() => import('./components/pages/Search')),
  },
  {
    stateName: CITY_SEARCH_LANDING,
    url: ['/m/search/:loc'],
    component: loadable(() => import('./components/pages/CitySearch')),
  },
  {
    stateName: CLIENT_INBOX,
    exact: true,
    url: ['/m/client-inbox'],
    data: { trackingName: 'client-inbox' },
    component: loadable(() => import('./components/pages/Chat/ClientInboxSplitter')),
  },
  {
    stateName: MARKETING_UNSUBSCRIBE,
    url: ['/m/marketing/unsubscribe/:external_id'],
    component: loadable(() => import('./components/pages/MarketingUnsubscribe')),
  },
  {
    stateName: HOME_ROUTE,
    exact: true,
    url: ['/m/'],
    data: { trackingName: 'homepage' },
    component: loadable(() => import('./components/pages/Home')),
  },
  {
    stateName: PROVIDER_SIGNUP_ROUTE,
    url: [
      '/m/pro-signup/account',
      '/m/pro-signup',
    ],
    component: loadable(() => import('./components/pages/ProviderOnboardingSignupRedirect')),
  },
  {
    stateName: PROVIDER_ONBOARDING_SIGNUP,
    url: '/m/provider/onboarding/signup',
    loginRequired: false,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/SignupPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_PROFILE,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/profile`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/ProfilePage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_ADDRESS,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/address`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/AddressPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_SERVICES,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/services`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/ServicesSelectionPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_GALLERY,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/gallery`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/GalleryPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_AVAILABILITY,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/availability`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/AvailabilityPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_PAYMENTS,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/payments`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/OnboardingPaymentsPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_PLAN_SELECT,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/plan`,
    loginRequired: true,
    subsRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/SelectPlanPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_GOAL_SELECT,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/goal`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/SelectGoalPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_FREE_TRIAL_EXPLANATION,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/freetrial`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/FreeTrialExplanationPage')),
  },
  {
    stateName: PROVIDER_ONBOARDING_SETUP_SUBSCRIPTION,
    url: `/m/provider/${PROVIDER_ONBOARDING_ROUTE}/card`,
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/onboarding/steps/SetupPaymentsPage')),
  },
  {
    stateName: PROFILE_PHOTO,
    url: '/m/p/:providerId/photo/:imageId',
    component: loadable(() => import('./components/pages/profile/Image')),
  },
  {
    stateName: PROVIDER_VANITY_PHOTO,
    url: '/m/v/:providerId/photo/:imageId',
    component: loadable(() => import('./components/pages/profile/Image')),
  },
  {
    stateName: PROFILE_EDIT_ROUTE_ONBOARDING,
    url: '/m/p/:providerId/edit',
    component: loadable(() => import('./components/pages/provider/profileEdit/ProfileEditOnboarding')),
  },
  {
    stateName: PROFILE_EDIT_ROUTE,
    url: '/m/p/:providerId/edit',
    component: loadable(() => import('./components/pages/provider/profileEdit/ProfileEdit')),
    routes: [
      {
        stateName: PROFILE_EDIT_SERVICE_INFO_ROUTE,
        url: '/m/p/:providerId/edit/service/:serviceId',
        component: loadable(() => import('./components/pages/ServiceInfoEditPage')),
      },
    ],
  },
  {
    stateName: PRO_GALLERY,
    url: '/m/p/:providerId/gallery',
    component: loadable(() => import('./components/pages/profile/Gallery')),
  },
  {
    stateName: PROVIDER_EDIT_NSLC_POLICY,
    url: '/m/p/:providerId/nslc_policy',
    component: loadable(() => import('./components/pages/provider/payments/ProPaymentPolicy/ProPaymentPolicy')),
  },
  {
    url: '/m/appointments',
    stateName: CLIENT_APPTS_LIST,
    data: { trackingName: 'appointments' },
    loginRequired: true,
    loginMessage: AUTH_MESSAGES.appointments,
    loggedOutTitle: 'Login to Your StyleSeat Appointments Page',
    exact: true,
    component: loadable(() => import('./components/pages/ClientAppointmentsList/ClientAppointmentsListRouter')),
  },
  {
    url: '/m/chat/:chatId',
    stateName: CHAT,
    loginRequired: true,
    component: loadable(() => import('./components/pages/Chat/ChatPage')),
  },
  {
    stateName: PROVIDER_EDIT_REVIEWS,
    url: '/m/provider/:providerId/reviews',
    component: loadable(() => import('./components/pages/provider/profileEdit/ProReviews')),
  },
  {
    loginRequired: true,
    stateName: PRO_NCDC_APPROVE_APPOINTMENT,
    url: '/m/provider/:providerId/calendar/approve/:appointmentId',
    component: loadable(() => import('./components/pages/provider/NCDC/NCDCPage')),
  },
  {
    stateName: PROVIDER_FEES,
    url: '/m/provider/:providerId/fees/:dateFilterStart/:dateFilterEnd',
    component: loadable(() => import('./components/pages/provider/payments/Fees')),
  },
  {
    stateName: PRO_PLAN_SETTINGS,
    url: '/m/provider/:providerId/subscription',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/settings/PlanSettingsPage')),
  },
  // Pro settings menu
  {
    stateName: PROVIDER_SETTINGS_ONLINE_BOOKING_MENU,
    url: '/m/provider/:providerId/settings_menu/online_booking',
    component: loadable(() => import('./components/pages/provider/settings/OnlineBookingMenuPage')),
    routes: [
      {
        stateName: PROVIDER_SETTINGS_ONLINE_BOOKING,
        url: '/m/provider/:providerId/settings_menu/online_booking/online_booking',
        component: loadable(() => import('./components/pages/provider/settings/OnlineBookingSettings')),
      },
    ],
  },
  {
    stateName: PROVIDER_SETTINGS_ONLINE_BOOKING_DEEP_LINK,
    url: '/m/settings/online-booking-page',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/settings/ProviderLandingPage')),
  },
  {
    stateName: PRO_SMART_PRICE_SETTINGS,
    url: '/m/provider/:providerId/smart_price_settings',
    component: loadable(() => import('./components/pages/provider/settings/SmartPricingSettingsPage')),
  },
  // Pro payment settings menu
  {
    stateName: PROVIDER_SETTINGS_PAYMENTS_UPDATE_CREDIT_CARD_REDIRECT,
    url: '/m/settings/update-card',
    component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsSubscriptionCardRedirectPage')),
    loginRequired: true,
  },
  {
    stateName: PROVIDER_SETTINGS_PAYMENTS_UPDATE_CREDIT_CARD,
    url: '/m/provider/:providerId/settings/update-card',
    component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsSubscriptionCardPage')),
    loginRequired: true,
  },
  {
    stateName: PROVIDER_SETTINGS_PAYMENTS_MENU,
    url: '/m/provider/:providerId/settings_menu/payments',
    component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsMenuPage')),
    loginRequired: true,
    routes: [
      {
        stateName: PROVIDER_SETTINGS_PAYMENTS_ADDRESS,
        url: '/m/provider/:providerId/settings_menu/payments/address',
        component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsAddressPage')),
      },
      {
        stateName: PROVIDER_SETTINGS_PAYMENTS_CHECKOUT,
        url: '/m/provider/:providerId/settings_menu/payments/checkout',
        component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsCheckoutPage')),
      },
      {
        stateName: PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
        url: '/m/provider/:providerId/settings_menu/payments/identity',
        component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsIdentityPage')),
      },
      {
        stateName: PROVIDER_SETTINGS_PAYMENTS_ONBOARDING,
        url: '/m/provider/:providerId/settings_menu/payments/onboarding',
        component: loadable(() => import('./components/pages/provider/settings/payments/onboarding/PaymentsOnboardingPage')),
      },
      {
        stateName: PROVIDER_SETTINGS_PAYMENTS_POLICY,
        url: '/m/provider/:providerId/settings_menu/payments/policy',
        component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsPolicyPage')),
      },
      {
        stateName: PROVIDER_SETTINGS_PAYMENTS_DEPOSIT,
        url: '/m/provider/:providerId/settings_menu/payments/deposit',
        component: loadable(() => import('./components/pages/provider/settings/payments/PaymentsDepositPage')),
      },
    ],
  },
  {
    stateName: PRO_BANK_ACCOUNT_SETTINGS,
    loginRequired: true,
    url: '/m/provider/:providerId/settings/bank_account',
    component: loadable(() => import('./components/pages/provider/settings/BankAccountPage')),
    data: {
      trackingName: 'P Bank Account',
    },
  },
  // TODO: Create a settings parent here with individual children
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_MORE,
    url: '/m/provider/:providerId/settings_menu/more',
    component: loadable(() => import('./components/provider/settings/SettingsDashboard/SettingsDashboard')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_BUSINESS_AND_PROFILE,
    url: '/m/provider/:providerId/settings_menu/more/business_profile',
    component: loadable(() => import('./components/pages/provider/settings/mobile/BusinessProfileMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_CALENDAR,
    url: '/m/provider/:providerId/settings_menu/more/calendar',
    component: loadable(() => import('./components/pages/provider/settings/mobile/CalendarMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_CALENDAR_VIEW_SETTINGS,
    url: '/m/provider/:providerId/settings_menu/more/calendar/view-settings',
    component: loadable(() => import('./components/pages/provider/settings/mobile/CalendarViewSettingsMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_MARKETING,
    url: '/m/provider/:providerId/settings_menu/more/marketing',
    component: loadable(() => import('./components/pages/provider/settings/mobile/MarketingMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_INSTAGRAM_BOOK_BUTTON,
    url: '/m/provider/:providerId/settings_menu/more/instagram-book-button',
    component: loadable(() => import('./components/pages/provider/settings/mobile/InstagramBookButtonPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_EARNINGS_AND_REPORTING,
    url: '/m/provider/:providerId/settings_menu/more/earnings',
    component: loadable(() => import('./components/pages/provider/settings/mobile/EarningsReportingMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_PAYMENTS_AND_POLICIES,
    url: '/m/provider/:providerId/settings_menu/more/payments',
    component: loadable(() => import('./components/pages/provider/settings/mobile/PaymentsPoliciesMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_ACCOUNT,
    url: '/m/provider/:providerId/settings_menu/more/account',
    component: loadable(() => import('./components/pages/provider/settings/mobile/AccountMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PRO_FORM_SETTINGS,
    url: '/m/provider/:providerId/settings_menu/forms',
    component: loadable(() => import('./components/pages/provider/settings/forms/FormVersionSwitcher')),
    routes: [
      {
        isChild: true,
        stateName: PRO_FORM_SETTINGS_QUESTION,
        url: '/m/provider/:providerId/settings_menu/forms/question/:questionId',
        component: loadable(() => import('./components/pages/provider/settings/forms/FormSettingsPageV1')),
      },
    ],
  },
  {
    loginRequired: true,
    stateName: PRO_TRUSTED_CLIENT_SETTINGS,
    url: '/m/provider/:providerId/settings_menu/trusted_clients',
    component: loadable(() => import('./components/pages/provider/settings/TrustedClientsPage')),
  },
  {
    loginRequired: true,
    stateName: PRO_NEW_CLIENT_DELIVERY_SETTINGS,
    url: '/m/provider/:providerId/settings_menu/new_client_delivery',
    component: loadable(() => import('./components/pages/provider/settings/NewClientDeliverySettingsPage')),
  },
  {
    loginRequired: true,
    stateName: PRO_NOTIFICATION_SETTINGS_SUBMENU,
    url: '/m/provider/:providerId/settings_menu/notification_settings',
    component: loadable(() => import('./components/pages/provider/settings/NotificationSettingsMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PROVIDER_SETTINGS_FRONT_DESK,
    url: '/m/provider/:providerId/settings_menu/front_desk',
    component: loadable(() => import('./components/pages/provider/settings/FrontDeskMenuPage')),
  },
  {
    loginRequired: true,
    stateName: PRO_ACCOUNT_MENU,
    url: '/m/provider/:providerId/settings_menu/account',
    component: loadable(() => import('./components/pages/provider/settings/AccountMenuPage')),
  },
  {
    stateName: PROVIDER_SETTINGS_SOCIAL,
    url: '/m/provider/:providerId/settings/social',
    component: loadable(() => import('./components/pages/provider/settings/SocialMedia')),
  },
  {
    stateName: PRO_PERSONAL_INFO_SETTINGS,
    url: '/m/provider/:providerId/settings/personal',
    component: loadable(() => import('./components/pages/provider/profileEdit/ProPersonalInfoPage')),
    data: {
      trackingName: 'P Settings Personal Info',
    },
  },
  {
    stateName: PRO_SETTINGS_PLAN_SELECTION,
    url: '/m/provider/:providerId/settings/plan_selection',
    component: loadable(() => import('./components/pages/provider/settings/PlanSelectionPage')),
  },
  {
    stateName: PRO_SETTINGS_PLAN_SELECTION_DEEP_LINK,
    url: '/m/settings/plan-selection-page',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/settings/ProviderLandingPage')),
  },
  {
    stateName: PRO_MESSAGES_LIST,
    url: '/m/provider/:providerId/settings/messages',
    component: loadable(() => import('./components/pages/provider/settings/MessageListPage')),
  },
  {
    stateName: PRO_BUSINESS_INFO_SETTINGS,
    url: '/m/provider/:providerId/settings/salon',
    component: loadable(() => import('./components/pages/provider/profileEdit/ProBusinessInfoPage')),
    data: {
      trackingName: 'P Settings Salon Info',
    },
  },
  {
    stateName: PRO_ADJUST_UPCOMING_HOURS,
    url: '/m/provider/:providerId/availability/upcoming',
    component: loadable(() => import('./components/pages/provider/profileEdit/ProAdjustUpcomingHoursPage')),
  },
  {
    stateName: PRO_MANAGE_ONGOING_SCHEDULE,
    url: '/m/provider/:providerId/settings/availability',
    component: loadable(() => import('./components/pages/provider/settings/ProManageOngoingAvailabilityPage')),
  },
  {
    stateName: PRO_MANAGE_VACATION,
    url: '/m/provider/:providerId/availability/vacation',
    component: loadable(() => import('./components/pages/provider/profileEdit/ProManageVacationPage')),
  },
  {
    stateName: PRO_IMAGE_LIST,
    url: '/m/provider/:providerId/images',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/profileEdit/ProImageManagement')),
  },
  {
    stateName: PRO_PRODUCT_PAGE,
    url: '/m/provider/:providerId/products-page',
    component: loadable(() => import('./components/pages/provider/products/ProductMenuPage')),
  },
  {
    stateName: PRO_EDIT_PRODUCT,
    url: '/m/provider/:providerId/products/edit-product',
    component: loadable(
      () => import('./components/provider/products/ProductMenu/EditProductPageContainer'),
    ),
  },
  {
    stateName: PRO_SERVICE_MENU,
    url: '/m/provider/:providerId/manage-services',
    component: loadable(() => import('./components/pages/provider/services/ServiceMenuPage')),
  },
  {
    stateName: PRO_ADD_SERVICE,
    url: '/m/provider/:providerId/services/add-services',
    component: loadable(() => import('./components/pages/provider/services/AddServicesPage')),
  },
  {
    stateName: PRO_ADD_ADD_ON,
    url: '/m/provider/:providerId/services/add-add-on',
    component: loadable(() => import('./components/provider/services/AddAddOn/AddAddOnPage')),
  },
  {
    stateName: PRO_EDIT_SERVICE,
    url: '/m/provider/:providerId/services/edit-services',
    component: loadable(() => import('./components/pages/provider/services/EditServicesPage')),
  },
  {
    stateName: PRO_EDIT_ADD_ON,
    url: '/m/provider/:providerId/services/edit-add-on',
    component: loadable(() => import('./components/provider/services/EditAddOn/EditAddOnPage')),
  },
  {
    stateName: PRO_SERVICE_ONBOARDING,
    url: '/m/pd/:providerId/service_menu_wizard',
    component: loadable(() => import('./components/pages/provider/services/ServiceOnboardingPage')),
  },
  {
    stateName: PRO_TAPTOPAY_ONBOARD_SETTINGS_DEEP_LINK,
    url: '/m/settings/tap-to-pay/setup',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/settings/ProviderLandingPage')),
  },
  {
    stateName: PRO_TAPTOPAY_ONBOARD_SETTINGS,
    url: '/m/provider/:providerId/settings/tap-to-pay/setup',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/settings/TapToPaySetupPage')),
  },
  {
    stateName: PRO_NOTIFICATION_SETTINGS,
    url: '/m/provider/:providerId/settings_menu/pro-notification-settings',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/settings/NotificationTextSettings')),
  },
  {
    stateName: PRO_CLIENT_NOTIFICATION_SETTINGS,
    url: '/m/provider/:providerId/settings_menu/client-notification-settings',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/settings/ProClientNotificationSettings')),
  },
  {
    stateName: PRO_CALENDAR_GOOGLE_SYNC,
    url: '/m/provider/:providerId/settings_menu/google-calendar-sync',
    loginRequired: true,
    component: loadable(() => import('./components/pages/provider/calendar/CalendarSyncPage')),
  },
  {
    url: '/m/appointments/list',
    stateName: CLIENT_APPTS_LIST_DEFAULT,
    data: { trackingName: 'appointments_list' },
    loginRequired: true,
    loginMessage: AUTH_MESSAGES.appointments,
    component: loadable(() => import('./components/pages/ClientAppointmentsList/ClientAppointmentsList')),
  },
  {
    url: '/m/appointments/future',
    stateName: CLIENT_APPTS_LIST_FUTURE,
    data: { trackingName: 'appointments_future' },
    loginRequired: true,
    loginMessage: AUTH_MESSAGES.appointments,
    component: loadable(() => import('./components/pages/ClientAppointmentsList/ClientAppointmentsListFuture')),
  },
  {
    url: '/m/appointment/:id/details',
    stateName: CLIENT_APPT_STATUS_DETAILS,
    data: { trackingName: 'appointment_status_details' },
    loginRequired: true,
    component: loadable(() => import('./components/pages/AppointmentStatusDetailsPage')),
  },
  {
    url: '/m/appointment/:id/get_help',
    stateName: CLIENT_GET_HELP,
    data: { trackingName: 'appointment_get_help' },
    loginRequired: true,
    component: loadable(() => import('./components/pages/AppointmentGetHelpPage')),
  },
  {
    url: '/m/appointment/:id/prepay',
    stateName: CLIENT_PREPAY_APPT,
    data: { trackingName: 'prepay_appt' },
    component: loadable(() => import('./components/pages/AppointmentPrepayPage')),
  },
  {
    url: '/m/guest_booking/:token',
    stateName: GUEST_LANDING,
    data: { trackingName: 'guest_booking' },
    component: loadable(() => import('./components/pages/GuestBookingManagementPage')),
  },
  {
    stateName: CLIENT_ACCOUNT_SETTINGS_ROUTE,
    url: '/m/my-account',
    loginRequired: true,
    component: loadable(() => import('./components/pages/MyAccount/MyAccountPage')),
    routes: [
      {
        url: '/m/my-profile',
        stateName: CLIENT_CONTACT_INFORMATION_ROUTE,
        isChild: true,
        data: { trackingName: 'consumer_profile' },
        component: loadable(() => import('./components/pages/ConsumerProfilePage')),
      },
      {
        url: '/m/promotions/list',
        stateName: CLIENT_PROMOTIONS_LIST_ROUTE,
        data: { trackingName: 'promotions_list' },
        isChild: true,
        component: loadable(() => import('./components/pages/ClientPromotionsList')),
      },
      {
        url: '/m/change-password',
        stateName: CLIENT_CHANGE_PASSWORD_ROUTE,
        isChild: true,
        component: loadable(() => import('./components/pages/ChangePassword')),
      },
      {
        url: '/m/default-tip-edit',
        stateName: CLIENT_DEFAULT_TIP_SETTING_ROUTE,
        isChild: true,
        component: loadable(() => import('./components/pages/MyAccount/DefaultTipEditPage')),
      },
      {
        url: '/m/autocheckout-settings',
        stateName: CLIENT_AUTO_CHECKOUT_SETTINGS_ROUTE,
        isChild: true,
        component: loadable(() => import('./components/pages/MyAccount/AutoCheckoutSettingsPage')),
      },
      {
        stateName: CLIENT_PAYMENT_METHOD_PARENT,
        url: '/m/user-credit-cards',
        isChild: true,
        component: loadable(() => import('./components/pages/client/paymentMethods/PaymentMethodParentPage')),
        loginRequired: true,
        loginMessage: AUTH_MESSAGES['update-payment'],
        routes: [
          {
            stateName: CLIENT_PAYMENT_METHOD_DETAIL,
            url: '/m/user-credit-cards/detail/:cardId',
            isChild: true,
            component: loadable(() => import('./components/pages/client/paymentMethods/PaymentMethodViewPage')),
          },
          {
            stateName: CLIENT_PAYMENT_METHOD_LIST,
            url: '/m/user-credit-cards/list',
            isChild: true,
            component: loadable(() => import('./components/pages/client/paymentMethods/PaymentMethodListPage')),
            routes: [
              {
                stateName: CLIENT_PAYMENT_METHOD_ADD,
                url: '/m/user-credit-cards/add',
                component: loadable(() => import('./components/pages/client/paymentMethods/PaymentMethodCreatePage')),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    stateName: USER_DEPENDENT_LEGAL,
    url: ['/m/legal/:userType/:docType'],
    component: loadable(() => import('./components/pages/LegalPage')),
  },
  {
    stateName: USER_INDEPENDENT_LEGAL,
    url: ['/m/legal/:docType/'],
    component: loadable(() => import('./components/pages/LegalPage')),
  },
  {
    stateName: CLIENT_ADD_CARD,
    url: '/add-card',
    component: loadable(() => import('./components/pages/client/paymentMethods/PaymentMethodAddRedirectPage')),
  },
  {
    stateName: CLIENT_LIST_CARD,
    url: '/card-list',
    component: loadable(() => import('./components/pages/client/paymentMethods/PaymentMethodAddRedirectPage')),
  },
  {
    stateName: SALON_PHOTO,
    url: '/m/s/:providerId/photo/:imageId',
    component: loadable(() => import('./components/pages/profile/Image')),
  },
  {
    stateName: SALON_ROUTE,
    url: '/m/s/:salonId',
    component: loadable(() => import('./components/pages/Salon')),
  },
  {
    stateName: CLIENT_INVITED_TO_BOOK,
    url: '/m/invite',
    component: loadable(() => import('./components/pages/client/InvitedToBook')),
  },
  {
    stateName: PRO_PROMOTE_PROFILE,
    url: '/m/p/:providerId/promote',
    component: loadable(() => import('./components/pages/PromoteProfile/PromoteProfilePage')),
    routes: [
      {
        stateName: PRO_PROMOTE_PROFILE_SOCIAL,
        url: '/m/p/:providerId/promote/social',
        isChild: true,
        component: loadable(() => import('./components/provider/PromoteProfile/PromoteProfilePage/pages/PromoteProfileSocialPage')),
      },
      {
        stateName: PRO_PROMOTE_PROFILE_INBOUND,
        url: '/m/p/:providerId/promote/inbound',
        isChild: true,
        component: loadable(() => import('./components/provider/PromoteProfile/PromoteProfilePage/pages/PromoteProfileInboundPage')),
      },
      {
        stateName: PRO_PROMOTE_PROFILE_IN_PERSON,
        url: '/m/p/:providerId/promote/in-person',
        isChild: true,
        component: loadable(() => import('./components/provider/PromoteProfile/PromoteProfilePage/pages/PromoteProfileInPersonPage')),
      },
    ],
  },
  {
    stateName: PROVIDER_SEARCH_RANK,
    url: '/m/p/:providerId/search-rank',
    component: loadable(() => import('./components/pages/SearchRank')),
  },
  {
    stateName: PROVIDER_SEARCH_RANK_NEW_CLIENTS,
    url: '/m/p/:providerId/search-rank/new-clients',
    component: loadable(() => import('./components/pages/SearchRankNewClients')),
  },
  {
    stateName: PROVIDER_SEARCH_RANK_PAYMENTS,
    url: '/m/p/:providerId/search-rank/payments',
    component: loadable(() => import('./components/pages/SearchRankPayments')),
  },
  {
    stateName: PROVIDER_TOP_PRO,
    url: '/m/p/:providerId/top-pro',
    component: loadable(() => import('./components/pages/provider/TopPro')),
  },
  // Handle vanity URL rerouting
  {
    stateName: ERROR_ROUTE,
    url: '/m/404',
    exact: true,
    component: loadable(() => import('./components/pages/404')),
  },
  {
    url: '/m/c/:discountCode',
    stateName: CLIENT_DISCOUNT_ENROLLMENT,
    data: { trackingName: 'discount_enroll' },
    component: loadable(() => import('./components/pages/DiscountEnrollmentPage')),
  },
  {
    stateName: PRO_PROMOTIONS_LIST,
    url: '/m/provider/:providerId/promotions',
    component: loadable(() => import('./components/pages/provider/promotions/PromotionsList')),
    routes: [
      {
        stateName: PRO_PROMOTION_CREATE,
        url: '/m/provider/:providerId/promotions/create',
        exact: true,
        component: loadable(() => import('./components/pages/provider/promotions/CreatePromotion')),
      },
      {
        stateName: PRO_PROMOTION_DETAIL,
        url: '/m/provider/:providerId/promotions/:promotionId',
        component: loadable(() => import('./components/pages/provider/promotions/PromotionDetails')),
      },
      {
        stateName: PRO_PROMOTION_EDIT,
        url: '/m/provider/:providerId/promotions/:promotionId/edit',
        component: loadable(() => import('./components/pages/provider/promotions/EditPromotion')),
      },
    ],
  },
  {
    stateName: PRINTABLE_CALENDAR_ROUTE,
    url: '/m/printCalendar/:year/:month/:day/:mode/:providerId',
    component: loadable(() => import('./components/pages/provider/PrintCalendar/PrintCalendar')),
  },
  {
    stateName: CALENDAR_ROUTE,
    url: [
      '/m/provider/:providerId/calendarNext/:year/:month/:day/:mode',
      '/m/provider/:providerId/calendarNext/:year/:month/:day',
      '/m/provider/:providerId/calendarNext',
    ],
    component: loadable(() => import('./components/pages/provider/calendar/Calendar')),
    loginRequired: true,
    subsRequired: true,
    routes: [
      {
        stateName: PRO_CALENDAR_NEW_APPOINTMENT,
        url: [
          '/m/provider/:providerId/calendarNext/:year/:month/:day/:mode/new_appointment',
          '/m/provider/:providerId/calendarNext/new_appointment',
        ],
        component: loadable(() => import('./components/pages/provider/CreateAppointment/CreateAppointmentPage')),
        data: {
          trackingName: 'P Create Calendar Appointment',
        },
      },
      {
        stateName: PRO_PERSONAL_APPOINTMENT_VIEW,
        url: [
          '/m/provider/:providerId/calendarNext/:year/:month/:day/:mode/personal-appointment/:viewApptId',
          '/m/provider/:providerId/calendarNext/personal-appointment/:viewApptId',
        ],
        data: {
          trackingName: 'P View Calendar Personal Appointment',
        },
        component: loadable(() => import('./components/pages/provider/PersonalAppointment/PersonalAppointmentViewPage')),
        loginRequired: true,
        routes: [
          {
            stateName: PRO_PERSONAL_APPOINTMENT_EDIT,
            url: [
              '/m/provider/:providerId/calendarNext/:year/:month/:day/:mode/personal-appointment/:viewApptId/edit',
              '/m/provider/:providerId/calendarNext/personal-appointment/:viewApptId/edit',
            ],
            data: {
              trackingName: 'P Edit Calendar Personal Appointment',
            },
            loginRequired: true,
            component: loadable(() => import('./components/pages/provider/PersonalAppointment/PersonalAppointmentEditPage')),
          } as StyleSeatRouteConfig,
        ],
      },
      {
        stateName: PRO_CALENDAR_VIEW_APPOINTMENT,
        url: [
          '/m/provider/:providerId/calendarNext/:year/:month/:day/:mode/appointment/:viewApptId',
          '/m/provider/:providerId/calendarNext/appointment/:viewApptId',
        ],
        component: loadable(() => import('./components/pages/provider/AppointmentDetails/AppointmentDetails')),
        routes: [
          {
            stateName: PRO_CALENDAR_EDIT_APPOINTMENT,
            url: [
              '/m/provider/:providerId/calendarNext/:year/:month/:day/:mode/appointment/:viewApptId/edit',
              '/m/provider/:providerId/calendarNext/appointment/:viewApptId/edit',
            ],
            component: loadable(() => import('./components/pages/provider/AppointmentDetails/AppointmentDetailsEdit')),
          },
          {
            stateName: PRO_CALENDAR_REFUND_APPOINTMENT,
            url: [
              '/m/provider/:providerId/calendarNext/:year/:month/:day/:mode/appointment/:viewApptId/refund',
              '/m/provider/:providerId/calendarNext/appointment/:viewApptId/refund',
            ],
            component: loadable(() => import('./components/pages/provider/AppointmentDetails/AppointmentRefund')),
          },
        ],
      },
    ],
  },
  {
    stateName: PRO_CHANGE_PASSWORD,
    url: ['/m/provider/:providerId/account/change-password'],
    data: { trackingName: 'Change Password' },
    component: loadable(() => import('./components/provider/settings/ChangePassword/ChangePasswordContainer')),
  },
  {
    stateName: PRO_EMAIL_MARKETING_DASHBOARD,
    url: '/m/provider/:providerId/email_campaigns',
    component: loadable(() => import('./components/pages/provider/emailMarketing/EmailMarketingDashboardPage')),
    loginRequired: true,
  },
  {
    stateName: PRO_EMAIL_MARKETING_CREATE_FLOW_ROUTE,
    url: '/m/provider/:providerId/email_campaigns/create/:step',
    component: loadable(() => import('./components/pages/provider/emailMarketing/EmailMarketingFlowPage')),
    loginRequired: true,
  },
  {
    stateName: PRO_EMAIL_MARKETING_EDIT_FLOW_ROUTE,
    url: '/m/provider/:providerId/email_campaigns/:campaignId/edit/:step',
    component: loadable(() => import('./components/pages/provider/emailMarketing/EmailMarketingFlowPage')),
    loginRequired: true,
  },
  {
    stateName: PROVIDER_MARKETING_HUB,
    url: '/m/provider/:providerId/marketing',
    component: loadable(() => import('./components/pages/MarketingHubPage')),
    loginRequired: true,
  },
  {
    url: '/m/feedback/pro/:vanityUrl',
    stateName: PRO_FEEDBACK,
    component: loadable(() => import('./components/pages/ClientFeedback')),
  },
  {
    url: '/m/signupwall',
    stateName: MOBILE_APP_START_ROUTE,
    component: loadable(() => import('./components/pages/WelcomeWall')),
  },
  {
    stateName: MARKETPLACE_RULES,
    url: '/m/rules',
    component: loadable(() => import('./components/pages/MarketplaceRulesPage')),
  },
  {
    url: '/m/manage-appointment',
    stateName: CLIENT_MANAGE_APPT,
    component: loadable(() => import('./components/pages/ClientManageAppointment')),
  },
  {
    url: '/m/more-options',
    stateName: CLIENT_MORE_MENU,
    component: loadable(() => import('./components/pages/ClientMore')),
  },
  {
    url: '/m/password_reset/confirm/:userId/:token/',
    stateName: CLIENT_PASSWORD_RESET_CONFIRM,
    component: loadable(() => import('./components/pages/PasswordResetConfirm')),
  },
  {
    url: '/m/consumer/adjust_tip/:token',
    stateName: ADJUST_TIP,
    component: loadable(() => import('./components/pages/AdjustTip')),
    routes: [
      {
        url: '/m/consumer/adjust_tip/:token/thank_you',
        stateName: ADJUST_TIP_THANK_YOU,
        component: () => null,
      },
      {
        url: '/m/consumer/adjust_tip/:token/default',
        stateName: ADJUST_TIP_DEFAULT,
        component: () => null,
      },
      {
        url: '/m/consumer/adjust_tip/:token/refunded',
        stateName: ADJUST_TIP_REFUNDED,
        component: () => null,
      },
    ],
  },
  // Note that this route is used in Facebook configuration for redirect during FB connect flow
  {
    url: '/m/fb/auth-with-styleseat',
    stateName: AUTH_WITH_STYLESEAT,
    component: loadable(() => import('./components/provider/AuthWithStyleSeat/AuthWithStyleSeatContainer')),
  },
  {
    stateName: PRO_CHANGE_EMAIL,
    url: ['/m/provider/:providerId/account/change-email'],
    data: { trackingName: 'Change Email' },
    component: loadable(() => import('./components/provider/settings/ChangeEmail')),
  },
  {
    stateName: PRO_TAX_SETTINGS_EDELIVERY_ROUTE,
    url: '/m/provider/:providerId/settings_menu/tax/edelivery',
    component: loadable(() => import('./components/pages/provider/settings/TaxEDeliveryConsentPage')),
    loginRequired: true,
  },
  {
    stateName: PRO_CHECKOUT_SETTINGS,
    url: '/m/provider/:providerId/settings/checkout',
    component: loadable(() => import('./components/pages/AdjustTax')),
  },
  {
    stateName: PRO_TAX_SETTINGS,
    url: '/m/provider/:providerId/settings/tax',
    component: loadable(() => import('./components/pages/provider/settings/TaxSettingsPage')),
    loginRequired: true,
  },
  {
    stateName: PROVIDER_CLIENT_NOTE,
    url: '/m/provider/:providerId/client/:clientId/notes',
    data: { trackingName: 'pro_addnote_viewed' },
    component: loadable(() => import('./components/pages/provider/AddClientNote')),
  },
  {
    stateName: PRO_CLIENT_LIST,
    url: '/m/provider/:providerId/clients',
    data: { trackingName: 'P Clients' },
    component: loadable(() => import('./components/pages/provider/client/ProClientListPage')),
    routes: [
      {
        stateName: PRO_CLIENT_GET_MORE,
        url: '/m/provider/:providerId/clients/get-more',
        data: { trackingName: 'P Get More Clients' },
        component: loadable(() => import('./components/pages/provider/client/GetMoreClientsPage')),
        isChild: true,
      },
      {
        stateName: PRO_CLIENT_VIEW,
        url: '/m/provider/:providerId/clients/:clientId',
        data: { trackingName: 'P Client' },
        component: loadable(() => import('./components/pages/provider/client/ProClientDetailPage')),
        isChild: true,
        routes: [
          {
            stateName: PRO_CLIENT_VIEW_APPOINTMENT,
            url: '/m/provider/:providerId/clients/:clientId/appointment/:viewApptId',
            component: loadable(() => import('./components/pages/provider/client/ProClientAppointmentDetailsPage')),
          },
          {
            stateName: PRO_CLIENT_VIEW_APPOINTMENTS,
            url: '/m/provider/:providerId/clients/:clientId/appointments',
            component: loadable(() => import('./components/pages/provider/client/FutureClientAppointmentsPage')),
          },
        ],
      },
      {
        stateName: PRO_CLIENT_IMPORT,
        url: '/m/provider/:providerId/clients/import',
        data: { trackingName: 'pro_client_import' },
        component: loadable(() => import('./components/pages/provider/client/ImportContactPage')),
      },
    ],
  },
  {
    stateName: CLIENT_PHONE_SETTINGS,
    url: '/m/client/:clientId/phone-settings',
    data: { trackingName: 'Tracked Client phone settings' },
    component: loadable(() => import('./components/pages/client/PhoneSettings/PhoneSettings')),
  },
  {
    stateName: CLIENT_PAYMENT_REQUEST_CHECKOUT,
    url: '/m/payment_request/:token',
    component: loadable(() => import('./components/pages/PaymentRequestCheckout/PaymentRequestCheckout')),
  },
  {
    stateName: TRACKED_CLIENT_SIGNUP,
    url: '/m/client-signup',
    component: loadable(() => import('./components/pages/TrackedSignupPage')),
  },
  {
    stateName: PROVIDER_GROWTH_GIFT_CARDS,
    url: '/m/provider/:providerId/growth_tab/gift_cards',
    component: loadable(() => import('./components/pages/provider/growth/GiftCards')),
  },
  {
    stateName: PROVIDER_GROWTH_SUMMARY,
    url: '/m/provider/:providerId/growth_tab/summary',
    component: loadable(() => import('./components/pages/provider/growth/GrowthSummaryPage')),
  },
  {
    stateName: PROVIDER_GROWTH_TRANSACTIONS,
    url: '/m/provider/:providerId/growth_tab/transactions',
    data: { trackingName: 'P Orders' },
    component: loadable(() => import('./components/pages/provider/growth/GrowthTransactionsPage')),
  },
  {
    stateName: PROVIDER_GROWTH_PAYOUTS,
    url: '/m/provider/:providerId/growth_tab/payouts',
    component: loadable(() => import('./components/pages/provider/growth/GrowthPayoutsPage')),
  },
  {
    stateName: PROVIDER_GROWTH_EARNINGS,
    url: '/m/provider/:providerId/growth_tab/net_earnings/:dateFilterStart/:dateFilterEnd',
    component: loadable(() => import('./components/pages/provider/growth/NetEarningsPage')),
  },
  {
    stateName: PROVIDER_GROWTH_REPORTING,
    url: '/m/provider/:providerId/stats',
    component: loadable(() => import('./components/pages/provider/growth/ReportingPage')),
  },
  {
    loginRequired: true,
    subsRequired: true,
    stateName: PROVIDER_GROWTH_LOANS,
    url: '/m/provider/:providerId/growth_tab/loans',
    component: loadable(() => import('./components/pages/provider/growth/GrowthLoansPage')),
  },
  {
    loginRequired: true,
    subsRequired: true,
    stateName: PRO_TODAY_TAB_HOME,
    url: [
      '/m/provider/:providerId/todayNext',
      '/m/provider/:providerId',
    ],
    component: loadable(() => import('./components/pages/provider/today/TodayPage')),
  },
  {
    loginRequired: true,
    stateName: PRO_NOTIFICATIONS_LIST,
    url: '/m/provider/:providerId/notifications',
    component: loadable(() => import('./components/pages/provider/today/NotificationsListPage')),
  },
  {
    loginRequired: true,
    stateName: PRO_CHAT_INBOX,
    url: '/m/provider/:providerId/inbox',
    component: loadable(() => import('./components/pages/provider/today/ProChatInboxPage')),
  },
];

// CSCS admin routes
routeConfigs.push({
  loginRequired: true,
  stateName: ADMIN_USERS,
  url: '/m/admin/users',
  component: loadable(() => import('./components/pages/admin/AdminUsersPage')),
});
routeConfigs.push({
  loginRequired: true,
  stateName: ADMIN_PROS,
  url: '/m/admin/providers',
  component: loadable(() => import('./components/pages/admin/AdminProvidersPage')),
});

if (isPreStage()) {
  routeConfigs.push({
    url: '/m/errorboundary',
    stateName: ERROR_BOUNDARY,
    component: loadable(() => import('./components/pages/examples/ErrorBoundaryPage')),
  });
}

if (!isProduction()) {
  routeConfigs.push({
    stateName: COMPARE_ENV_FLAGS_SUMMARY,
    url: [
      '/m/compare-env-flags-summary',
      '/m/checkered-flags',
    ],
    component: loadable(() => import('./components/pages/CompareEnvFlagsSummary')),
  });
}

// NOTE: this route needs to be last, as it acts as a catchall to allow pros
// to link to `styleseat.com/{provider_vanity}`
routeConfigs.push({
  stateName: PROFILE_ROUTE,
  url: [
    '/m/v/:providerId',
    '/m/p/:providerId',
    '/m/:providerId',
    '/:providerId',
  ],
  component: loadable(() => import('./components/pages/ProProfile')),
  routes: [
    {
      stateName: BOOKING_ROUTE,
      url: '/m/p/:providerId/booking/service/:serviceId/schedule',
      component: loadable(() => import('./components/pages/BookingPage')),
    },
    {
      stateName: BOOKING_SUCCESS_ROUTE,
      url: '/m/p/:providerId/booking/service/:serviceId/confirmation/:appointmentId',
      component: loadable(() => import('./components/pages/BookingSuccessPage')),
    },
    {
      stateName: PROFILE_SERVICE_INFO,
      url: '/m/p/:providerId/service/:serviceId',
      component: loadable(() => import('./components/pages/ServiceInfoPage')),
    },
    {
      stateName: PROFILE_MESSAGE,
      url: '/m/p/:providerId/message-pro',
      component: loadable(() => import('./components/pages/profile/GuardedMessagePage')),
    },
  ],
});

export default routeConfigs;
