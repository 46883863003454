/**
 * Rematch model for state related to global sidebar.
 */

import { createModel } from '@rematch/core';

const Sidebar = createModel()({
  state: {
    open: false,
  },

  reducers: {
    setOpen: (_, open) => ({ open }),
    close: () => ({ open: false }),
    toggle: ({ open }) => ({ open: !open }),
  },
});

export default Sidebar;
