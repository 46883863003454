// @ts-strict-ignore
import {
  useCallback,
  useEffect,
  useRef,
} from 'react';

/**
 * A hook identical to `useEffect` except:
 *  - the invocation of the effect is debounced by a delay
 *  - the effect is not invoked on first load unless there is already data in
 *    the dependencies array.
 *
 * @param {function} effect - The debounced effect function to be called.
 * @param {array} dependencies - An array whose change in data evokes the
 *                               debounced effect function.
 * @param {number} delay - time in milliseconds before firing effect
 */
export const useDebouncedEffect = (effect, dependencies, delay = 1000) => {
  const callback = useCallback(effect, dependencies);
  const isFirstLoad = useRef(true);
  useEffect(() => {
    if (isFirstLoad.current && !dependencies.find(Boolean)) {
      isFirstLoad.current = false;
      return () => {};
    }
    const handler = setTimeout(() => {
      callback();
    }, delay);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line array-element-newline
  }, [callback, delay]);
};

export default useDebouncedEffect;
