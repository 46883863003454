import { useEffect } from 'react';

/**
 * A hook for running a function on mount.
 *
 * @param {function} fn - The function to be called on mount.
 */
const useMount = fn => {
  // Uses a useEffect to hook into mount. The passed-in fn is wrapped to ensure
  // there is no return value - which would try to be run on cleanup, in this
  // case on unmount. (As a bonus, it also means async functions don't have to
  // be wrapped explictly which they otherwise would due to useEffect not
  // accepting an async callback). If you _want_ to return something from the
  // passed-in fn for the tandem unmount functionality, you're solving the
  // problem within the "hook" paradigm and should proudly use useEffect
  // directly. This abstraction is an escape back to "lifecycle" thinking (for
  // those problems where such a paradigm may be better suited), not an "ideal"
  // hook to always reach for.
  useEffect(() => {
    fn();
    // eslint-disable-next-line
  }, []);
};

export default useMount;
