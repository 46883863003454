/**
 * Store analytics event names to a single place.
 * Please keep list alphabetized.
 *
 * Use:
 * analytics.track(AnalyticEvent.AppointmentCancelled, ...properties)
*/
export enum AnalyticEvent {
  AppointmentBooked = 'Appointment Booked',
  AppointmentCancelled = 'Appointment Cancelled',
  AppsFlyerInstallAttribution = 'Install Attributed',
  CalendarViewed = 'Calendar Viewed',
  CheckoutComplete = 'Checkout Complete',
  DiscountCodeApplied = 'Discount Code Applied',
  DiscountCodeEnrolled = 'Discount Code Enrolled',
  DiscountCodeRedeemed = 'Discount Code Redeemed',
  HomePageViewed = 'Home Page Viewed',
  ProfileViewed = 'Profile Viewed',
  ProfileBookClicked = 'Profile Book Clicked',
  ReviewSubmitted = 'Review Submitted',
  SearchResultClicked = 'Search Result Clicked',
  SearchResultViewed = 'Search Results Viewed',
  TapToPaySetupCompleted = 'Tap to Pay Setup Completed',
  TapToPaySetupFailed = 'Tap To Pay Setup Failed',
  ProviderOnboardingNextBestActionClicked = 'Pro Onboarding Attract Client Tip Clicked',
  BankAccountAction = 'P Payments Bank Account Action',
}
