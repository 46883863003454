// @ts-strict-ignore
import { v4 as uuidv4 } from 'react-native-uuid';
import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import type { AppliedDiscount } from './UserState.model';
import { ssFetchJSON } from '../modules/ssFetch';
import analytics from '../modules/analytics';
import {
  StoredDiscountInfo,
  NCD_TYPES,
  DiscountDetails,
  RawNCDResponse,
  DiscountDetailsWithKey,
  PromoTypeValues,
} from './NCDAndDiscountedPros.types';
import { INCENTIVES_DISCOUNT_CODE } from '../components/consumer/incentives/constants';
import {
  selectDiscounts,
  ONE_TIME_USE_CODE,
  hasLoadedDeferred,
} from './UserState.model';
import { DiscountStatus } from './ClientPromotions.types';
import { getPriorNCDAttribution } from '../modules/getPriorNCDAttribution';

export const BOOK_ME_PRO_DISCOUNT_CODE_SWITCH = 'book_me_pro_discount_code_atl4414_20220617';
export const BOOK_ME_DISCOUNT_CODE = 'BOOKME5';

/**
 * Get's ncd source from root start using the following cascade:
 * 1. If we have ncd_source in the url use that.
 * 2. If we have an older ncd_source cached for this user's session use that.
 * @param rootState
 */
function getNCDSourceFromRootState(rootState): string {
  return (
    rootState.route.params.ncd_source || rootState.ncdAndDiscountedPros.latestSource
  );
}

/**
 * Get's the ncd term from the url grabbing the ncd_term first and then
 * the utm_term if there is no ncd_term present.
 * @param rootState
 */
function getNCDTermFromRootState(rootState): string {
  return rootState.route.params.ncd_term;
}

function getDiscountCode(discounts: AppliedDiscount[]): string | null {
  const discountValue = discounts.find(discount => discount.status === DiscountStatus.Available);
  if (!discountValue?.code) {
    return null;
  }

  return `${discountValue.code}${discountValue.reward_id ? `&reward_id=${discountValue.reward_id}` : ''}`;
}

export function getNumberOrNull(value: string | number): number | null {
  let parsedNumber: number;
  if (typeof value === 'number') {
    parsedNumber = value;
  } else {
    parsedNumber = Number.parseFloat(value);
  }

  if (Number.isNaN(parsedNumber)) {
    return null;
  }
  return Math.floor(parsedNumber);
}

type DiscountDetailsWithKeyAndStatus = DiscountDetailsWithKey & { status: DiscountStatus };

export type State = {
  hasPendingRequest: boolean;
  latestSource: string | null;
  providers: {
    [id: number]: StoredDiscountInfo;
  };
} & DiscountDetails;

type RawResults = {
  code: string;
  max_discount: number;
  discount_amount: string;
  discount_percent: string;
  promo_type?: PromoTypeValues;
  results: {
    [id: string]: boolean;
  };
};

interface IBulkCouponResponse {
  code?: string;
  max_discount?: number;
  discount_amount?: string;
  discount_percent?: string;
  promo_type?: PromoTypeValues;
  reward_id?: number;
}

export type DiscountEligibilityPayload = {
  providerId: number | string;
  providerIds?: null;
  discountCode?: string;
  rewardId?: number;
} | {
  providerId?: null;
  providerIds: Array<number | string>;
  discountCode?: string;
  rewardId?: number;
} | {
  providerId?: null;
  providerIds?: null;
  discountCode?: string;
  rewardId?: number;
};

export type DiscountEligibilityResult = Record<number | string, StoredDiscountInfo>;

function transformBulkResponseToDiscountDetails(
  response: IBulkCouponResponse,
  isNewClientAppointment: boolean,
  overrideEligible?: boolean | undefined,
): DiscountDetails {
  const discountAmount = getNumberOrNull(response.discount_amount);
  const discountPercent = getNumberOrNull(response.discount_percent);

  return {
    discountEligible: typeof overrideEligible !== 'undefined' ? overrideEligible : !!response.code,
    discountMax: response.max_discount,
    discountCode: response.code,
    discountAmount,
    discountPercent,
    isNewClientAppointment,
    promoType: response.promo_type || null,
    rewardId: response.reward_id || null,
  };
}

const emptyState: State = {
  providers: {},
  latestSource: null,
  discountCode: null,
  discountAmount: null,
  discountPercent: null,
  hasPendingRequest: false,
  discountEligible: false,
  discountMax: 0,
  isNewClientAppointment: false,
};

const model = createModel<RootModel>()({
  name: 'ncdAndDiscountedPros',

  state: emptyState as State,

  reducers: {
    onSetPro(
      state: State,
      {
        providerId,
        discountInfo,
        latestSource,
      }: {
        latestSource: string | null;
        providerId: string | number;
        discountInfo: StoredDiscountInfo;
      },
    ) {
      return {
        ...state,
        latestSource,
        hasPendingRequest: false,
        providers: {
          ...state.providers,
          [providerId]: {
            ...discountInfo,
            wasFullVerification: true,
          },
        },
      };
    },

    onSetPros(
      state: State,
      {
        providers,
        discountCode,
        discountMax,
        discountAmount,
        discountPercent,
      }: {
        discountCode: string;
        discountMax: number | null;
        discountAmount: number | null;
        discountPercent: number | null;
        providers: {
          [id: number]: StoredDiscountInfo;
        };
      },
    ) {
      return {
        ...state,
        discountCode,
        discountMax,
        discountAmount,
        discountPercent,
        hasPendingRequest: false,
        providers: {
          ...state.providers,
          ...providers,
        },
      };
    },

    onSetCode(
      state: State,
      {
        discountCode,
        discountAmount,
        discountPercent,
        discountMax,
      }: DiscountDetails,
    ) {
      return {
        ...state,
        discountCode,
        discountMax,
        discountAmount,
        discountPercent,
        hasPendingRequest: false,
      };
    },

    clearProvider(
      state: State,
      {
        providerId,
      }: {
        providerId: number;
      },
    ) {
      const {
        [providerId]: _,
        ...providers
      } = state.providers;

      return {
        ...state,
        providers: {
          ...providers,
        },
      };
    },

    onHasPendingRequest(state: State) {
      return {
        ...state,
        hasPendingRequest: true,
      };
    },

    onClearPendingRequest(state: State) {
      return {
        ...state,
        hasPendingRequest: false,
      };
    },

    clearAll() {
      return {
        ...emptyState,
      };
    },
  },

  effects: dispatch => ({
    doCheckSingleProDiscountEligibility: async (
      {
        providerId,
        discountCode,
        rewardId,
      }: {
        discountCode: string;
        providerId: number | string;
        rewardId?: number;
      },
      rootState,
    ): Promise<StoredDiscountInfo> => {
      let fromRoute = true;
      let ncdTerm = getNCDTermFromRootState(rootState);
      let ncdSource = getNCDSourceFromRootState(rootState);

      if (!ncdSource || !ncdTerm) {
        const ncdDetails = await getPriorNCDAttribution(providerId);

        if (ncdDetails?.ncd_source && ncdDetails?.ncd_term) {
          ncdSource = ncdDetails.ncd_source;
          ncdTerm = ncdDetails.ncd_term;
          fromRoute = false;
        }
      }

      const encodedNcdParams = encodeURIComponent(JSON.stringify((ncdSource && ncdTerm) ? {
        ncd_source: ncdSource,
        ncd_term: ncdTerm,
      } : {}));
      const ncd_tracking_token = uuidv4();
      const rawNcdData: RawNCDResponse = await ssFetchJSON(
        `/api/v2/providers/${providerId}/ncd_details?${[
          (discountCode && discountCode !== INCENTIVES_DISCOUNT_CODE) ? (
            `discount_code=${discountCode}`
          ) : '',
          rewardId && `&reward_id=${rewardId}`,
          `ncd_params=${encodedNcdParams}`,
          `ncd_tracking_token=${ncd_tracking_token}`,
        ].filter(line => !!line).join('&')}`,
      );

      const ncdData: any = {
        ncd_type: rawNcdData.ncd_type,
        ncd_fee_percent: rawNcdData.ncd_fee_percent,
        is_smart_price_eligible: rawNcdData.is_smart_price_eligible,
        is_new_client_appointment: rawNcdData.is_new_client_appointment,
      };

      if (rawNcdData.cip_new_pro_code === discountCode) {
        ncdData.cip_new_pro_code = rawNcdData.cip_new_pro_code;
        ncdData.cip_new_pro_percent = rawNcdData.cip_new_pro_percent;
        ncdData.cip_new_pro_max_amount = rawNcdData.cip_new_pro_max_amount;
      }

      analytics.track('ncd_details_response', {
        ...rawNcdData,
        ncd_tracking_token,
      });

      const emptyCIPData = {
        cip_new_pro_code: null,
        cip_new_pro_percent: null,
        cip_new_pro_max_amount: null,
      };
      const discountEligible = (rawNcdData.code || rawNcdData.cip_new_pro_code) === discountCode;

      const discountData: DiscountDetails = {
        discountEligible,
        isNewClientAppointment: rawNcdData.is_new_client_appointment,
        discountMax: discountEligible ? getNumberOrNull(
          rawNcdData.max_discount || rawNcdData.cip_new_pro_max_amount,
        ) : null,
        discountCode: (
          discountCode === rawNcdData.code || discountCode === rawNcdData.cip_new_pro_code
        ) ? discountCode : null,
        discountAmount: discountEligible ? getNumberOrNull(rawNcdData.discount_amount) : null,
        discountPercent: discountEligible ? getNumberOrNull(
          rawNcdData.discount_percent || rawNcdData.cip_new_pro_percent,
        ) : null,
        promoType: rawNcdData.promo_type || null,
        rewardId: rawNcdData.reward_id || null,
      };

      const discountInfo: StoredDiscountInfo = {
        ...emptyCIPData,
        ...ncdData,
        ...discountData,
        is_known: true,
        ncd_source: ncdSource,
        ncd_tracking_token,
      };

      await dispatch.ncdAndDiscountedPros.onSetPro({
        providerId,
        latestSource: (fromRoute) ? ncdSource : rootState.ncdAndDiscountedPros.latestSource,
        discountInfo,
      });

      return discountInfo;
    },

    doCheckMultipleProDiscountEligibility: async (
      {
        providerIds,
        discountCode,
        rewardId,
      }: {
        providerIds: Array<number | string>;
        discountCode: string;
        rewardId?: number;
      },
    ): Promise<Record<number | string, StoredDiscountInfo>> => {
      const providers: Record<number, StoredDiscountInfo> = {};
      const endpointUrl = '/api/v2/providers_bulk/coupon_eligibility_check';
      const rawResult: RawResults = await ssFetchJSON(
        `${endpointUrl}?code=${discountCode}${rewardId ? `&reward_id=${rewardId}` : ''}&ids=${(
          providerIds.join(',')
        )}`,
      );

      const discountAmount = getNumberOrNull(rawResult.discount_amount);
      const discountPercent = getNumberOrNull(rawResult.discount_percent);

      Object.keys(rawResult.results).forEach(providerId => {
        const value = rawResult.results[providerId];
        const discountDetails: DiscountDetails = value ? transformBulkResponseToDiscountDetails(
          rawResult,
          value,
        ) : ({
          discountMax: null,
          discountCode: null,
          discountAmount: null,
          discountPercent: null,
          discountEligible: false,
          isNewClientAppointment: false,
        });

        providers[providerId] = {
          is_known: true,
          ncd_type: value ? NCD_TYPES.NCD : NCD_TYPES.NOT_NCD,
          ncd_fee_percent: null,
          ncd_source: 'generic_search',
          is_smart_price_eligible: null,
          is_new_client_appointment: value,
          cip_new_pro_code: rawResult.code,
          cip_new_pro_max_amount: rawResult.max_discount,
          cip_new_pro_percent: discountPercent,
          promoType: null,
          rewardId: null,
          ...discountDetails,
        };
      });

      await dispatch.ncdAndDiscountedPros.onSetPros({
        providers,
        discountAmount,
        discountPercent,
        discountMax: rawResult.max_discount,
        discountCode: rawResult.code,
      });

      return providers;
    },

    doCheckProviderDiscountEligibility: async (
      {
        providerId,
        providerIds,
        discountCode,
        rewardId,
      }: {
        providerId: number | string;
        providerIds?: null;
        discountCode?: string;
        rewardId?: number;
      } | {
        providerId?: null;
        providerIds: Array<number | string>;
        discountCode?: string;
        rewardId?: number;
      },
      rootState,
    ): Promise<Record<number | string, StoredDiscountInfo>> => {
      let code: string = discountCode || getDiscountCode(
        selectDiscounts(rootState),
      );

      if (!code && rootState.showIncentives.showIncentives) {
        code = INCENTIVES_DISCOUNT_CODE;
      }

      if (!code && !rootState.showIncentives.showIncentives) {
        const incentivesChecks = await dispatch.showIncentives.checkIncentivesStatus();
        const showIncentives: boolean = Object.keys(incentivesChecks)
          .some(key => incentivesChecks[key]?.isCurrentlyEnrolled);
        code = showIncentives ? INCENTIVES_DISCOUNT_CODE : null;
      }
      // If we don't have a discount code then we don't need to actually check anything.
      if (!code && !providerId) {
        dispatch.ncdAndDiscountedPros.onClearPendingRequest();
        return {};
      }

      if (providerId) {
        return {
          [providerId]: await dispatch.ncdAndDiscountedPros.doCheckSingleProDiscountEligibility({
            providerId,
            discountCode: code,
            rewardId,
          }),
        };
      }
      return dispatch.ncdAndDiscountedPros.doCheckMultipleProDiscountEligibility({
        providerIds,
        discountCode: code,
        rewardId,
      });
    },

    checkProviderDiscountEligibility: async (
      {
        providerId = null,
        providerIds = [],
        discountCode,
        rewardId,
      }: DiscountEligibilityPayload,
      rootState,
    ): Promise<DiscountEligibilityResult> => {
      if (rootState.ncdAndDiscountedPros.hasPendingRequest) {
        return {};
      }

      await dispatch.ncdAndDiscountedPros.onHasPendingRequest();
      await hasLoadedDeferred.promise;

      if (providerId) {
        // eslint-disable-next-line consistent-return
        return dispatch.ncdAndDiscountedPros.doCheckProviderDiscountEligibility({
          providerId,
          discountCode,
          rewardId,
        });
      }

      // eslint-disable-next-line consistent-return
      return dispatch.ncdAndDiscountedPros.doCheckProviderDiscountEligibility({
        providerIds,
        discountCode,
        rewardId,
      });
    },

    applyDiscountCode: async (
      {
        code,
        source,
        rewardId,
      }: {
        code?: string;
        source: string;
        rewardId?: number;
      },
      rootState,
    ): Promise<DiscountDetailsWithKey> => {
      const existingDiscounts = selectDiscounts(rootState);

      const existingDiscount = existingDiscounts.find(discount => (
        (discount.source === source && discount.code === code)
        || (discount.promo_type === PromoTypeValues.Reward && discount.reward_id === rewardId)
      ));

      let discountKey: string;

      if (!existingDiscount) {
        const endpointUrl = '/api/v2/providers_bulk/coupon_eligibility_check';
        const rawResult: RawNCDResponse = await ssFetchJSON(
          `${endpointUrl}${code ? `?code=${code}` : ''}${rewardId ? `&reward_id=${rewardId}` : ''}`,
        );

        const discountExists = existingDiscounts.find(discount => (
          (discount.code === rawResult.code && discount.source === source)
          || (discount.reward_id === rewardId && !!rewardId)
        ));
        // This runs the same lookup twice, which is still cheaper than a backend call.
        if (rawResult.code && !discountExists) {
          const discountAmount = getNumberOrNull(rawResult.discount_amount);
          const discountPercent = getNumberOrNull(rawResult.discount_percent);

          dispatch.ncdAndDiscountedPros.onSetCode(
            transformBulkResponseToDiscountDetails(
              rawResult,
              rawResult.is_new_client_appointment,
            ),
          );

          discountKey = `available_discount_${Date.now()}`;
          if (rawResult?.promo_type === PromoTypeValues.Reward) {
            discountKey = rawResult?.key_name;
          }

          if (rawResult?.code === BOOK_ME_DISCOUNT_CODE) {
            discountKey = ONE_TIME_USE_CODE;
          }

          await dispatch.userState.addValues({
            values: {
              [discountKey]: {
                code: rawResult.code,
                status: DiscountStatus.Available,
                source,
                promo_type: rawResult.promo_type ? rawResult.promo_type : null,
                reward_id: rawResult.reward_id || null,
              },
            },
          });

          const {
            email,
            first_name,
            last_name,
            user_id,
          } = rootState.user;

          const data = {
            discount_code: code,
            source,
            user_id,
            email,
            first_name,
            last_name,
          };
          analytics.track('discount_code_applied', data);

          return {
            discountKey,
            discountAmount,
            discountPercent,
            discountCode: rawResult.code,
            discountEligible: !!rawResult.code,
            discountMax: rawResult.max_discount,
            isNewClientAppointment: rawResult.is_new_client_appointment,
            promoType: rawResult.promo_type,
            rewardId: rawResult.reward_id,
          };
        }
      } else {
        discountKey = existingDiscount.key;
      }

      const {
        discountMax,
        discountCode,
        discountAmount,
        discountPercent,
        discountEligible,
        isNewClientAppointment,
        promoType,
      } = rootState.ncdAndDiscountedPros;

      return {
        discountKey,
        discountMax,
        discountCode,
        discountAmount,
        discountPercent,
        discountEligible,
        isNewClientAppointment,
        promoType,
        rewardId,
      };
    },

    /**
     * This effect is really just a holder and will need more work later
     * to make it do something other than return a value!
     * @param code
     * @param cost
     * @param source
     */
    async applyDiscountToAppointment({
      code,
      cost,
      providerId,
      source,
      forceAdd,
      rewardId,
    }: {
      code: string;
      cost: string | number;
      providerId: string | number;
      source: string;
      forceAdd?: boolean;
      rewardId?: number;
    }, rootState): Promise<DiscountDetailsWithKey> {
      const rewardValue = `${rewardId ? `&reward_id=${rewardId}` : ''}`;
      const ncd_tracking_token = uuidv4();
      const response: RawNCDResponse = await ssFetchJSON(
        `/api/v2/providers/${providerId}/ncd_details?discount_code=${code}${rewardValue}&appointment_cost=${cost}&ncd_tracking_token=${ncd_tracking_token}`,
      );

      if (response.code) {
        const existingDiscounts = selectDiscounts(rootState);
        const existingDiscount = existingDiscounts.find(discount => (
          (discount.source === source && discount.code === code)
          || (!!rewardId && discount.reward_id === rewardId)
        ));
        let discountKey = `available_discount_${Date.now()}`;
        if (response.promo_type === PromoTypeValues.Reward) {
          discountKey = response.key_name;
        }

        if (response.code === BOOK_ME_DISCOUNT_CODE) {
          discountKey = ONE_TIME_USE_CODE;
        }

        if (!existingDiscount || forceAdd) {
          await dispatch.userState.addValues({
            values: {
              [discountKey]: {
                code: response.code,
                status: DiscountStatus.Available,
                source,
                promo_type: response.promo_type,
              },
            },
          });
        }

        const ncdData: any = {
          ncd_type: response.ncd_type,
          ncd_fee_percent: response.ncd_fee_percent,
          is_smart_price_eligible: response.is_smart_price_eligible,
          is_new_client_appointment: response.is_new_client_appointment,
        };

        if (response.cip_new_pro_code === code) {
          ncdData.cip_new_pro_code = response.cip_new_pro_code;
          ncdData.cip_new_pro_percent = response.cip_new_pro_percent;
          ncdData.cip_new_pro_max_amount = response.cip_new_pro_max_amount;
        }

        analytics.track('ncd_details_response', {
          ...response,
          ncd_tracking_token,
        });

        const emptyCIPData = {
          cip_new_pro_code: null,
          cip_new_pro_percent: null,
          cip_new_pro_max_amount: null,
        };
        const discountEligible = (response.code || response.cip_new_pro_code) === code;

        const discountData: DiscountDetailsWithKey = {
          discountKey: forceAdd ? discountKey : (existingDiscount?.key || discountKey),
          discountEligible,
          isNewClientAppointment: response.is_new_client_appointment,
          promoType: response.promo_type,
          discountMax: discountEligible ? getNumberOrNull(
            response.max_discount || response.cip_new_pro_max_amount,
          ) : null,
          discountCode: (
            code === response.code || code === response.cip_new_pro_code
          ) ? code : null,
          rewardId: response.reward_id,
          discountAmount: discountEligible ? getNumberOrNull(response.discount_amount) : null,
          discountPercent: discountEligible ? getNumberOrNull(
            response.discount_percent || response.cip_new_pro_percent,
          ) : null,
        };

        const discountInfo: StoredDiscountInfo = {
          ...emptyCIPData,
          ...ncdData,
          ...discountData,
          is_known: true,
          ncd_tracking_token,
        };

        await dispatch.ncdAndDiscountedPros.onSetPro({
          latestSource: source,
          providerId,
          discountInfo,
        });

        return discountData;
      }

      return {
        discountKey: null,
        discountMax: null,
        discountCode: null,
        discountAmount: null,
        discountPercent: null,
        discountEligible: false,
        isNewClientAppointment: false,
      };
    },

    removeDiscountCode: async ({
      code,
      source,
      rewardId,
    }: {
      code: string;
      source?: string;
      rewardId?: number;
    }, rootState) => {
      const discounts = selectDiscounts(rootState);

      const foundDiscount = discounts.find(discount => (
        discount.code === code && (
          (!rewardId && (!source || discount.source === source))
          || rewardId === discount.reward_id
        )
      ));

      const reset = async () => {
        dispatch.ncdAndDiscountedPros.clearAll();
        await dispatch.ncdAndDiscountedPros.checkProviderDiscountEligibility({});
      };

      if (foundDiscount) {
        const {
          key,
          ...restDiscount
        } = foundDiscount;
        await dispatch.userState.addValues({
          values: {
            [foundDiscount.key]: {
              ...restDiscount,
              status: DiscountStatus.Redeemed,
            },
          },
          preferLocalKeys: [foundDiscount.key],
        });
        await reset();
        const {
          email,
          first_name,
          last_name,
          user_id,
        } = rootState.user;

        const data = {
          discount_code: code,
          source,
          user_id,
          email,
          first_name,
          last_name,
        };
        // Making an assumption that the code has been redeemed at this stage
        analytics.track('discount_code_redeemed', data);
      } else if (!foundDiscount && code === INCENTIVES_DISCOUNT_CODE) {
        await dispatch.showIncentives.leaveOneAppointmentEnrollment();
        await reset();
      }
    },

    loadValidCodes: async ({
      providerId,
    }: {
      providerId?: number | string;
    }, rootState): Promise<Array<DiscountDetailsWithKeyAndStatus>> => {
      const endpointUrl = '/api/v2/providers_bulk/coupon_eligibility_check';
      // in general, this should just send a single request.
      const results: Array<DiscountDetailsWithKeyAndStatus> = (await Promise.all(
        selectDiscounts(rootState).map(
          async (appliedDiscount: AppliedDiscount): Promise<DiscountDetailsWithKeyAndStatus> => {
            try {
              const ncdResponse = await ssFetchJSON<RawNCDResponse>(
                `${endpointUrl}?code=${appliedDiscount.code}${appliedDiscount.reward_id ? `&reward_id=${appliedDiscount.reward_id}` : ''}${providerId ? `&ids=${providerId}` : ''}`,
              );

              return {
                ...transformBulkResponseToDiscountDetails(
                  ncdResponse,
                  ncdResponse.is_new_client_appointment,
                ),
                discountKey: appliedDiscount.key,
                status: appliedDiscount.status,
              };
            } catch (err) {
              return null;
            }
          },
        ),
      )).filter(Boolean);

      return results;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    providers: () => createSelector(
      slice(state => state.providers),
      (
        providers: Record<number, StoredDiscountInfo>,
      ): Record<number, StoredDiscountInfo> => providers,
    ),
    discountDetails: () => createSelector(
      slice(state => state),
      (state: State): DiscountDetails => ({
        discountEligible: state.discountEligible,
        discountMax: state.discountMax,
        discountCode: state.discountCode,
        discountAmount: state.discountAmount,
        discountPercent: state.discountPercent,
        isNewClientAppointment: state.isNewClientAppointment,
        promoType: state.promoType,
        rewardId: state.rewardId,
      }),
    ),
    eligibleDiscountInfo: hasProps((_, { providerId }: { providerId: number }) => (
      createSelector(
        slice(state => state.providers),
        (
          providers: Record<number, StoredDiscountInfo>,
        ): StoredDiscountInfo | null => {
          if (providers?.[providerId] && providers[providerId].discountEligible) {
            return providers[providerId];
          }

          return null;
        },
      )
    )),
  }),
});

export default model;
