export enum ChatRoles {
  Client = 'client',
  Provider = 'provider',
  Moderator = 'moderator',
}

export type ProfileInfo = {
  role: ChatRoles;
  name: string;
  id: string;
  photoUrl: string;
};
