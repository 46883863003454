// @ts-strict-ignore
import { createModel } from '@rematch/core';
import moment from 'moment/moment';
import loadAvailability from '../../../modules/provider/loadAvailability';
import { getApplicableSmartPrice } from '../../../modules/provider/smartPricing';
import { SmartPricingPreferences } from '../../provider/smartPricing/types';
import type { RootModel } from '../../../store/models';
import analytics from '../../../modules/analytics';

export class EmptyServicesError extends Error {
  constructor() {
    super('No services selected.');
  }
}

type BookingPageState = {
  usePreselectedSlot: boolean;
  resolvedSmartPrice: SmartPricingPreferences;
  atl4064Variant?: string;
};

const BookingPage = createModel<RootModel>()({
  name: 'bookingPage',

  state: {
    usePreselectedSlot: false,
    resolvedSmartPrice: null,
  } as BookingPageState,

  reducers: {
    onLand: (state: BookingPageState, payload: Partial<BookingPageState>) => ({
      ...state,
      ...payload,
    }),
  },

  effects: dispatch => ({
    async loadData({
      providerId,
    }: {
      providerId: number;
    }) {
      const provider = await dispatch.providers.loadProvider({ providerId });
      dispatch.providers.setActiveProviderId(provider.id);
      await dispatch.profileServiceGroups.loadProvider({
        providerId: provider?.id,
        loadPromotions: true,
      });
      return provider;
    },
    async land({
      providerId,
      rescheduleAppointmentId,
      allServices,
      servicesIds,
      timeSlot,
      source,
      date,
      bookingHasAddons = false,
    }: {
      providerId: number;
      /** The CSV list of selected services, if more than one */
      allServices?: string;
      /** The service ID, if only one */
      servicesIds?: number[];
      /** The slot to pre-select, if any */
      timeSlot?: number;
      /** Where the user came from */
      source?: string;
      /** The date to pre-select, if any */
      date?: Date;
      // if we're rescheduling, we need to know the appointment ID
      rescheduleAppointmentId?: number;
      bookingHasAddons?: boolean;
    }) {
      // whenever we enter the booking flow, reset the settings
      // if no addons are already added
      if (!bookingHasAddons) {
        dispatch.booking.onStart({
          providerId,
          rescheduleAppointmentId,
        });
      }

      const provider = await dispatch.bookingPage.loadData({
        providerId,
      });

      const services = allServices ? allServices.split(',') : [servicesIds];
      const servicesById = services.map(id => ({ id: parseInt(id, 10) }));
      const dateFormatted = moment(date).format('YYYY-MM-DD');

      const usePreselectedSlot = !!servicesIds && !!date && !!(timeSlot || timeSlot === 0);
      let resolvedSmartPrice: SmartPricingPreferences;
      let atl4064Variant: string;

      // now that all the pro data has loaded, let's see if we need to preconfigure the booking
      // first, figure out if we need to pre-select a time slot
      if (usePreselectedSlot) {
        const schedule = await loadAvailability(
          providerId,
          servicesById,
          { days: 1, startDate: date },
        );

        // find the first item matching the specified date
        const availability = schedule.available_days.find(day => (
          day.date === dateFormatted
        ));

        const selectResult = await dispatch.booking.selectTimeSlot({
          providerId,
          timeSlot,
          date,
          // smartPrice: avail?.bookable_slots.smart_prices?.[timeSlot],
          smartPrice: getApplicableSmartPrice(
            provider,
            availability?.bookable_slots.smart_prices?.[timeSlot],
            date,
          ),
        });
        resolvedSmartPrice = selectResult.resolvedSmartPrice;
        atl4064Variant = selectResult.atl4064Variant;
      }

      // expose some values to our presentation
      dispatch.bookingPage.onLand({
        usePreselectedSlot,
        resolvedSmartPrice,
        atl4064Variant,
      });

      if (source) {
        analytics.track('client_booking_landed_with_source', { source });
      }

      // preconfigure the booking with preselected services
      let serviceIdsToSelect = [];
      if (allServices) {
        // Rescheduling flow
        serviceIdsToSelect = allServices.split(',')
          .map(id => parseInt(id, 10));
      } else {
        serviceIdsToSelect = servicesIds;
      }

      if (!bookingHasAddons) {
        if (!serviceIdsToSelect) {
          return;
        }

        if (serviceIdsToSelect?.length) {
          dispatch.booking.selectServicesByIds({
            providerId,
            serviceIds: serviceIdsToSelect,
          });
        } else {
          throw new EmptyServicesError();
        }
      }
    },
  }),
});

export default BookingPage;
