// @ts-strict-ignore
/**
 * Initializes and exports the rematch store. Generally, this module will only need to be modified
 * if adding a model.
 */
import LogRocket from 'logrocket';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { init, RematchRootState } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import selectPlugin from '@rematch/select';
import initSubscriber from 'redux-subscriber';
import { checkAttPermission } from '../modules/analytics/AppTrackingTransparency';
import { selectors as userSelectors } from './CurrentUser.model';
import { models, RootModel } from './models';
import { initABTestModel } from './ABTest';
import storeReady, { storeReadyResolver } from './ready';
import { stateManager } from '../modules/user/stateManager';
import { logUserAgent } from '../api/LogUserAgent';

/**
 * A plugin which caches data to localforage
 */
const persistPlugin = createRematchPersist<RematchRootState<RootModel>, RootModel>(
  {
    whitelist: [
      'user',
      'providerClients',
    ], // add the key of any model you wish to have cached
    throttle: 50,
    key: 'root',
    keyPrefix: 'ss',
    version: 2,
    storage: localforage,
    stateReconciler: autoMergeLevel2,
    writeFailHandler: err => {
      // eslint-disable-next-line no-console
      console.error('Error writing to rematch persistent storage (rematch persist plugin)', err);
    },
  },
  undefined,
  undefined,
  () => storeReadyResolver.resolve(),
);

const readctPasswordFromLoggedReduxActions = action => {
  if (
    action?.type === 'user/signupClient'
    || action?.type === 'user/signupUser'
    || action?.type === 'user/login'
  ) {
    return {
      ...action,
      payload: {
        ...action.payload,
        password: 'REDACTED',
        password1: 'REDACTED',
        password2: 'REDACTED',
      },
    };
  }
  if (action?.type === 'user/updatePassword') {
    return {
      ...action,
      payload: {
        ...action.payload,
        oldPassword: 'REDACTED',
        newPassword: 'REDACTED',
      },
    };
  }
  if (action?.type === 'passwordResetConfirmPage/resetPassword') {
    return {
      ...action,
      payload: {
        ...action.payload,
        password: 'REDACTED',
        passwordConfirm: 'REDACTED',
      },
    };
  }

  return action;
};

// this module currently gets loaded three times during bootup, so we need to cache the value to
// ensure we don't have three copies. There are some odd circular dependencies that seem to prevent
// standard module caching from working in this scenario.
const store = init<RootModel>({
  models,
  redux: {
    middlewares: [
      LogRocket.reduxMiddleware({
        actionSanitizer: action => readctPasswordFromLoggedReduxActions(action),
      }),
    ],
  },
  plugins: [
    persistPlugin,
    selectPlugin(),
    // add any additional plugins here
  ],
});

// this is needed for unit testing in `analytics` and `profileServiceGroups` test suites
window.reduxStore = store;
stateManager?.updateUser(store.dispatch, {});

// Initialize redux-subscriber
initSubscriber(store);

// Initialize the ab test module
initABTestModel(store);

store.dispatch.favorites.initialize();

store.dispatch.location.initialize();

store.dispatch.recentSearches.initialize();

storeReady.then(() => {
  store.dispatch.stripeIdentityVerification.load();
  store.dispatch.user.notifyAnalytics();

  if (userSelectors.isLoggedIn(store.getState())) {
    checkAttPermission();
    logUserAgent();
  }
});

export default store;
