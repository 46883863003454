import {
  loadSalon,
  loadSalonGalleryImages,
  PublicSalon,
} from '../../api/Salons';
import { ProProfileGalleryImageData } from '../Api.types';
import type { ICrossPromotedProvider } from '../../components/shared/providerProfile/CrossPromotion';
import type { ISearchResult } from '../consumer/search/Search';

export type RelatedSalon = Omit<ICrossPromotedProvider, 'total_duration_minutes' | 'cost' | 'service_id' | 'service_name' | 'show_and_up'>;

/**
 * Salon's profile combined including images
 */
export type SalonProfileWithImages = PublicSalon & ProProfileGalleryImageData;

export type SalonProfilePageData = {
  profile: SalonProfileWithImages;
  relatedSalons?: RelatedSalon[];
};

/**
 * Loads the profile data for endpoints that are not a redux model
 * returns an instance of the Profile class as a FullProfile
 * (profile + services + gallery images)
 * @param salonId
 * @param routeName
 */
export const loadSalonProfileViewData = async (
  salonId: number,
  routeName?: string,
): Promise<SalonProfilePageData> => {
  let relatedSalons: RelatedSalon[] = [];

  const [
    // if we awaited the provider promise above it will
    // resolve without making a new request
    salon,
    galleryImagesResults,
  ] = await Promise.all([
    loadSalon(salonId),
    loadSalonGalleryImages(salonId),
  ]);

  if (salon?.is_disabled) {
    // imported here due to dependency cycle
    // eslint-disable-next-line global-require
    const RelatedSalons = require('../consumer/search/RelatedSalons').default;
    const relatedSalonResults: ISearchResult[] = await RelatedSalons.getRelatedSalonsForPro(
      salon.id,
      { origin: routeName },
    );
    relatedSalons = relatedSalonResults.map((result: ISearchResult) => ({
      provider_id: result.provider_id,
      average_rating: result.average_rating,
      num_ratings: result.num_ratings,
      name: result.salon_name,
      vanity_url: result.vanity_url || '',
      profile_photo: result.profile_photo || '',
      profession: result.profession,
      service_id: undefined,
      service_name: undefined,
      total_duration_minutes: undefined,
      cost: undefined,
      show_and_up: undefined,
      city_state: `${result.location.city}, ${result.location.state}`,
    }));
  }

  return {
    profile: {
      ...salon,
      gallery_images: galleryImagesResults?.results || [],
      gallery_images_count: galleryImagesResults?.count || 0,
    },
    relatedSalons,
  };
};
