import { createModel } from '@rematch/core';
import type { RootModel } from '../models';
import { getHomepageSlotData } from './SlotData';

export type MarketingVideosSlots = 'homepage';

export type MarketingVideo = {
  videoSources: {
    src: string;
    type: string;
  }[];
  id?: string;
  videoTitle?: string;
  videoThumbnail: string;
  videoDescription: string;
  thumbnailDescription?: string;
  videoDescriptionLink?: {
    text: string;
    url: string;
  };
};

export type MarketingVideosSlot = {
  slot: MarketingVideosSlots;
  videos: MarketingVideo[];
};

export type MarketingVideosState = {
  slots: MarketingVideosSlot[];
};

export const MarketingVideos = createModel<RootModel>()({
  name: 'marketingVideos',

  state: {
    slots: [],
  } as MarketingVideosState,

  reducers: {
    onSlotLoaded(state: MarketingVideosState, {
      slot,
      videos,
    }: {
      slot: MarketingVideosSlots;
      videos: MarketingVideo[];
    }) {
      const slots = state.slots.filter(filterSlot => filterSlot.slot !== slot);
      return {
        ...state,
        slots: [
          ...slots,
          {
            slot,
            videos,
          },
        ],
      };
    },
  },

  effects: dispatch => ({
    loadSlot: async (slot: MarketingVideosSlots) => {
      if (slot === 'homepage') {
        const videos = getHomepageSlotData();
        dispatch.marketingVideos.onSlotLoaded({
          slot,
          videos,
        });
      }
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    bySlotName: hasProps((_, {
      slotName,
    }: {
      slotName: string;
    }) => createSelector(
      slice,
      (state: MarketingVideosState) => state.slots.find(slot => slot.slot === slotName),
    )),
  }),
});
