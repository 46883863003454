/* eslint-disable no-console */
import { APP, FACEBOOK_APP_ID } from '../config';
import { getIOSVersion, getAndroidVersion } from './AppInfo';

/**
 * This module wraps the Facebook API methods that are used in our application,
 * so that it covers both FB JS API methods as well as cordova plugin methods,
 * which can have slightly different names and function signatures.
 *
 * We use both the web-based FB JS SDK as well as the cordova plugin for
 * iOS and Android apps. This module helps determine which one to use and
 * contains necessary initialization logic for web.
 *
 * FB JS SDK reference: https://developers.facebook.com/docs/javascript
 * Plugin reference: https://github.com/jeduan/cordova-plugin-facebook4
 */

const CORDOVA_PLUGIN_CHECK_INTERVAL_MS = 100;

export default {
  /**
   * Gets the wrapped FB API reference
   */
  getFBApi() {
    return window.facebookConnectPlugin || window.FB;
  },

  /**
   * Gets a value indicating whether or not we are using the cordova facebook plugin
   * @returns {Boolean} True if the cordova plugin is detected, otherwise false.
   */
  usingFacebookConnectPlugin() {
    return window.facebookConnectPlugin !== undefined;
  },

  waitForFBPlugin(timeout = 10000) {
    return new Promise((resolve, reject) => {
      const maxTicks = timeout / CORDOVA_PLUGIN_CHECK_INTERVAL_MS;
      let tick = 0;
      const interval = setInterval(() => {
        if (this.usingFacebookConnectPlugin()) {
          clearInterval(interval);
          resolve(true);
        } else if (tick >= maxTicks) {
          clearInterval(interval);
          reject();
        }
        tick++;
      }, CORDOVA_PLUGIN_CHECK_INTERVAL_MS);
    });
  },

  /**
   * Initialize Facebook SDK by pulling required resources on web.
   * (In app this is basically a no-op).
   * Note: This should be called early on prior to invoking any Facebook methods.
   */
  init() {
    const isApp = window.cordova && (getIOSVersion() || getAndroidVersion());

    if (!window.fbInitializationPromise) {
      window.fbInitializationPromise = new Promise((resolve, reject) => {
        if (isApp && this.usingFacebookConnectPlugin()) {
          // We are in the app and ready to go.
          resolve();
        } else if (isApp && !this.usingFacebookConnectPlugin()) {
          // wait for it to load
          this.waitForFBPlugin().then(resolve, reject);
        } else {
          // eslint-disable-next-line func-names
          (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            const js = d.createElement(s); js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));

          window.fbAsyncInit = () => {
            this.getFBApi().init({
              appId: FACEBOOK_APP_ID,
              cookie: true,
              xfbml: false,
              version: 'v13.0',
              status: true,
            });
            resolve();
          };
        }
      });
    }
    return window.fbInitializationPromise;
  },

  /**
   * Logs an event to Facebook.
   *
   * @param {string} eventName
   * @param {Object} properties
   * @returns {undefined}
   */
  logEvent(eventName, properties) {
    return this.init().then(() => {
      if (this.usingFacebookConnectPlugin()) {
        // cordova plugin
        window.facebookConnectPlugin.logEvent(eventName, properties);
      } else if (window.FB) {
        // FB JS SDK
        window.FB.AppEvents.logEvent(eventName, null, properties);
      }
    });
  },

  /**
   * Checks if the user is logged into Facebook.
   *
   * @returns {Promise} resolves or rejects with FB login response depending on if logged in.
   */
  getLoginStatus() {
    return this.init().then(() => new Promise((resolve, reject) => {
      this.getFBApi().getLoginStatus(response => {
        if (response.status === 'connected') {
          resolve(response);
        } else {
          reject(response);
        }
      });
    }));
  },

  /**
   * Asks user to connect their Facebook account to StyleSeat.
   *
   * @param {Array} permissions - array of permissions to request.
   *    Note: anything more than the defaults will require FB app review.
   * @returns {Promise} resolves with FB login response.
   */
  login(permissions = ['public_profile', 'email']) {
    return this.init().then(() => new Promise((resolve, reject) => {
      if (this.usingFacebookConnectPlugin()) {
        this.getFBApi().login(
          permissions,
          resolve,
          reject,
        );
      } else {
        this.getFBApi().login(response => {
          if (response.status === 'connected') {
            resolve(response);
          } else {
            reject(response);
          }
        }, {
          scope: permissions.toString(),
          return_scopes: true,
        });
      }
    }));
  },

  /**
   * Pull first name, last name, and email from Facebook
   * This requires that the user is connected to Facebook first via login().
   *
   * @returns {Promise} - resolves with an object containing user data.
   */
  getUserData() {
    return this.init().then(() => new Promise((resolve, reject) => {
      const path = '/me?fields=first_name,email,last_name';
      if (this.usingFacebookConnectPlugin()) {
        this.getFBApi().api(path, [], resolve, reject);
      } else {
        this.getFBApi().api(path, 'GET', {}, res => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
      }
    }));
  },

  /**
   * Logs user out of Facebook on our site and possibly also Facebook.
   * Note: This does NOT revoke any previously accepted permissions
   * nor does it remove the stored Facebook account on our end.
   *
   * @returns {Promise} - resolves when logged out.
   */
  logout() {
    return this.init().then(() => new Promise((resolve, reject) => {
      this.getFBApi().logout(resolve, reject);
    }));
  },

  /**
   * Shows various types of Facebook dialogs e.g. a share dialog.
   * Reference: https://developers.facebook.com/docs/javascript/reference/FB.ui/
   *
   * @param {Object} opts - see reference
   * @returns {Promise} resolves when dialog is acted upon.
   */
  showDialog(opts) {
    return this.init().then(() => new Promise((resolve, reject) => {
      if (this.usingFacebookConnectPlugin()) {
        this.getFBApi().showDialog(opts, resolve, reject);
      } else {
        this.getFBApi().ui(opts, res => {
          if (res?.error_message) {
            reject(res.error_message);
          } else {
            resolve();
          }
        });
      }
    }));
  },

  /**
   * Calls the FB API to update the user ID.
   * @param  {String} userId The user ID to pass to `setUserID` in the FB API
   * @returns {Promise} A promise which resolves when the operation is complete
   */
  setUserID(userId) {
    return this.init().then(() => new Promise(resolve => {
      if (this.usingFacebookConnectPlugin()) {
        // there's no support for this in apps, so don't do anything
        resolve();
      } else if (window.FB) {
        window.FB.AppEvents.setUserID(userId);
        resolve();
      }
    }));
  },

  /**
   * Calls the FB API to update user properties.
   * @param  {Object} params The parameters to pass to `updateUserProperties` in the FB API
   * @returns {Promise} A promise which resolves when the operation is complete
   */
  updateUserProperties(params) {
    return this.init().then(() => new Promise(resolve => {
      if (this.usingFacebookConnectPlugin()) {
        // there's no support for this in apps, so don't do anything
        resolve();
      } else if (window.FB) {
        window.FB.AppEvents.updateUserProperties(params, () => resolve());
      }
    }));
  },

  share(params) {
    const packageString = getIOSVersion() ? 'com.apple.social.facebook' : 'com.facebook.katana';
    if (APP) {
      window.plugins.socialsharing.canShareVia(
        packageString,
        null,
        null,
        null,
        null,
        this.shareViaFacebookApp.bind(this, params),
        this.shareViaFacebookConnect.bind(this, params),
      );
    } else {
      this.shareViaFacebookConnect(params);
    }
  },

  shareViaFacebookApp(params) {
    /* Android will either share to installed FB app or use web-based FB
        share in-app as a backup option. */

    /* On iOS <12, FB share is native to the operating system - user will be asked to
        add their FB account to their system settings.
        On iOS >=12, native FB share has been removed and sharing must be done via installed
        FB app or web-based FB share */

    window.plugins.socialsharing.shareViaFacebook(
      `${params.text}: ${params.url}`,
      params.image,
      params.url,
      () => {
        if (params.shareCallback) {
          params.shareCallback({ channel: 'facebook' });
        }
      },
      err => {
        console.error('shareViaFacebook failed', err);
      },
    );
  },

  shareViaFacebookConnect(params) {
    this.getLoginStatus().then(this.postToFeed(params), () => {
      this.login(['public_profile', 'email'], this.postToFeed(params));
    });
  },

  postToFeed(params) {
    // Reference: https://developers.facebook.com/docs/sharing/reference/feed-dialog/v3.1#params
    const opts = {
      method: 'feed',
      link: params.url,
      picture: params.image,
      description: params.text,
      name: params.title,
      href: params.url,
      contentURL: params.url,
      imageURL: params.image,
      contentDescription: params.text,
      contentTitle: params.title,
      ref: 'FBSProfileShare',
    };

    if (APP && getIOSVersion()) {
      // this is the only option available for iOS App that makes it here
      opts.share_feedWeb = true;
    }
    this.showDialog(opts).then(() => {
      if (params.shareCallback) {
        params.shareCallback({ channel: 'facebook' });
      }
    }, err => {
      console.error('shareViaFacebookConnect failed', err);
    });
  },
};
