// @ts-strict-ignore
import produce from 'immer';
import { createModel, Action } from '@rematch/core';
import { hasLoadedDeferred } from './ABTest/ABTest.model';
import type { RootModel } from './models';

import { selectors as userSelectors } from './CurrentUser.model';
import { selectors as providerSelectors } from './Providers.model';

import { getProviderSearchRankStats } from '../modules/Api';
import { ProviderSearchRankStats } from '../modules/Api.types';
import { PrivilegedProvider, PublicProvider } from '../api/Providers';
import { HOME_ROUTE } from '../route-names';

import {
  calculateStatsLastThirty,
  calculateStatsLastTen,
  isTopProEligible,
} from './ProviderSearchRank.util';
import {
  SearchRankState,
  SearchRankStats,
  SearchRankStatsTenDays,
} from './ProviderSearchRank.types';

export const MASTER_SWITCH = 'search_ranking_enabled_FLY-3206_02022022';
export const SCHEDULE_PRO_SWITCH = 'search_ranking_enabled_scheduling_FLY-3242_02222022';

const TIME_THRESHOLD_DAYS = 14;

const model = createModel<RootModel>()({
  name: 'providerSearchRank',

  state: {
    provider: null,
    stats: null,
    loading: null,
    eligible: null,
  } as SearchRankState,

  reducers: {
    onLoading: produce<SearchRankState>((state: SearchRankState) => {
      state.loading = true;
    }),

    onAllLoaded: produce<SearchRankState>((state: SearchRankState) => {
      state.loading = false;
    }),

    onProviderLoaded: produce<SearchRankState, [PublicProvider]>((
      state: SearchRankState,
      provider: PublicProvider,
    ) => {
      state.provider = provider;
    }),

    onStatsLoaded: produce<SearchRankState, [SearchRankStats & SearchRankStatsTenDays]>((
      state: SearchRankState,
      stats: SearchRankStats & SearchRankStatsTenDays,
    ) => {
      state.stats = stats;
    }),

    onEligibilityCalculated: produce<SearchRankState, [boolean]>((
      state: SearchRankState,
      eligible: boolean,
    ) => {
      state.eligible = eligible;
    }),

  },

  effects: dispatch => ({
    populateProvider: async (
      payload?: undefined,
      rootState?,
    ): Promise<PublicProvider> => {
      let provider: PublicProvider;
      dispatch.providerSearchRank.onLoading();
      const providerId: number = userSelectors.getSuperAwareProviderId(rootState);
      const hasAccessToProvider: boolean = userSelectors.hasPrivilegedAccessToProvider(
        rootState,
        providerId,
      );

      // if not provider or super user, route to home
      if (!hasAccessToProvider) {
        dispatch.route.go({ route: HOME_ROUTE });
        return null;
      }

      dispatch.providers.setActiveProviderId(providerId);
      provider = providerSelectors.activeProvider(rootState);

      if (!provider) {
        provider = await dispatch.providers.loadProvider({ providerId });
      }

      dispatch.providerSearchRank.onProviderLoaded(provider);
      return provider;
    },
    calculateEligibility: async (
      payload?: undefined,
      rootState?,
    ): Promise<Action<boolean, void>> => {
      // Eligibility Handling with Switch
      //  * User must be on SS for at least 14 days
      //  * User must not have the search boost container visible
      let provider = rootState.providerSearchRank.provider as PrivilegedProvider;
      if (!provider) {
        provider = await dispatch.providerSearchRank.populateProvider() as PrivilegedProvider;
      }

      if (!rootState.abTest.isLoaded) {
        await hasLoadedDeferred.promise;
      }

      if (!rootState.abTest.assignments[
        MASTER_SWITCH
      ]?.isEnabled) {
        return dispatch.providerSearchRank.onEligibilityCalculated(false);
      }

      // check scheduling plan switch
      if (!provider.on_commission_plan && !rootState.abTest.assignments[
        SCHEDULE_PRO_SWITCH
      ]?.isEnabled) {
        return dispatch.providerSearchRank.onEligibilityCalculated(false);
      }

      // finally check criteria and assign test
      const joinedDate = Date.parse(provider.creation_time);
      const now = Date.now();
      const daysSinceJoined = (now - joinedDate)
        / (1000 * 60 * 60 * 24);

      const searchBoostExpired = provider.profile_search_boost_earning_end_date
        ? Date.parse(provider.profile_search_boost_earning_end_date) < now
        : false;

      if (
        daysSinceJoined > TIME_THRESHOLD_DAYS
        && provider.profile_phase_one_complete_date
        && searchBoostExpired
      ) {
        return dispatch.providerSearchRank.onEligibilityCalculated(true);
      }
      return dispatch.providerSearchRank.onEligibilityCalculated(false);
    },
    calculateStats: async ({ providerId }) => {
      const statsResult: ProviderSearchRankStats = await getProviderSearchRankStats(providerId);

      const statsLastThirty = calculateStatsLastThirty(statsResult);
      const statsLastTen = calculateStatsLastTen(statsResult);

      dispatch.providerSearchRank.onStatsLoaded({ ...statsLastThirty, ...statsLastTen });
    },
    land: async () => {
      const { id: providerId } = await dispatch.providerSearchRank.populateProvider();
      await dispatch.providerSearchRank.calculateStats({
        providerId,
      });
      await dispatch.providerSearchRank.calculateEligibility();

      dispatch.providerSearchRank.onAllLoaded();
      dispatch.loader.setIsLoading(false);
    },
  }),

  selectors: (slice, createSelector) => ({
    stats() {
      return slice((state: SearchRankState) => state?.stats);
    },
    pageData() {
      return slice((state: SearchRankState) => state);
    },
    eligible() {
      return slice((state: SearchRankState) => state.eligible);
    },
    isTopProEligible() {
      return createSelector(
        slice((state: SearchRankState) => state?.stats),
        stats => !!stats && isTopProEligible(stats),
      );
    },
  }),
});

export default model;
