import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const ClientProprofileViewed: SegmentEventData = {
  eventName: AnalyticEvent.ProfileViewed,
  eventNameLegacy: 'client_proprofile_viewed',
  eventProperties: [
    'search_id',
    'provider_id',
    'profile_type',
    'vanityUrl',
  ],
};

export default ClientProprofileViewed;
