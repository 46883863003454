// @ts-strict-ignore
import produce from 'immer';
import { Action, createModel } from '@rematch/core';
import { deprecatedCheckoutAppointment } from '../../api/Checkout/CheckoutAppointment';
import {
  ProAppointment,
  ProAppointmentDetails,
  createProAppointment,
  getProAppointmentDetails,
  updateProAppointment,
  ClientCardOnFile,
  removeSmartPrice,
  PaymentOption,
  ProAppointmentUpdatePayload,
} from '../../api/Providers/Appointments';
import { cancelAppointmentById } from '../../api/Providers/Appointments/AppointmentsCancellation';
import { markNoShowAppointment } from '../../api/Providers/Appointments/AppointmentsNoShow';
import { deleteAppointment } from '../../api/Providers/Appointments/AppointmentsDelete';
import { undoCheckout } from '../../api/Providers/Appointments/AppointmentsUndoCheckout';
import nonCriticalException from '../../modules/exceptionLogger';
import {
  buildCheckoutDetails,
  canUseCardOnFile,
  CheckoutDetails,
  CheckoutState,
  deletePaymentMethod,
  getPaymentType,
} from '../../modules/provider/appointmentCheckout';
import {
  changeAppointmentDate,
  formatIntakeFormResponse,
  mergePromotionObjects,
} from '../../modules/provider/Appointments';
import { loadCard } from '../../modules/provider/ClientCard';
import { selectors as userSelectors } from '../CurrentUser.model';
import type { RootModel } from '../models';
import {
  ICard,
  InstrumentType,
  MinimalCard,
} from '../PaymentMethods';
import type { PrivilegedProvider } from '../../api/Providers';
import { getFormattedPromotions } from '../../api/Providers/Promotions';
import { getIntakeFormResponse } from '../../api/Providers/IntakeFormResponse';
import { AppointmentTotals } from '../../api/Providers/Appointments/AppointmentTotals';
import { USE_NEW_CHECKOUT_FEATURE_FLAG } from './constants';
import {
  CheckoutResponse,
  checkoutAppointment,
  isCheckoutPaymentError,
  isCheckoutSuccess,
} from '../../api/Providers/Appointments/Checkout';
import {
  RefundApiResponse,
  RefundTypeChoice,
  getRefunds,
  refundAppointment,
} from '../../api/Appointment/Refund';
import { AppointmentTransactionResponse, fetchAppointmentTransactions } from '../../api/Providers/Appointments/Transactions';
import { INTAKE_FORM_V1_SWITCH } from '../../components/shared/IntakeForm/constants';
import { fetchFormWithAnswers } from '../../api/Providers/FormSettings';

export type ProviderManageAppointmentState = {
  selectedAppointmentId: number | null;
  selectedAppointmentCancelReason?: string;
  providerId: number | null;
  details: {
    [id: number]: ProAppointmentDetails;
  };
  loading: boolean;

  checkout: CheckoutState;
  totals?: AppointmentTotals;
  refunds?: RefundApiResponse[];
  transactions?: AppointmentTransactionResponse;
};

type AppointmentPayload = {
  appointment: ProAppointmentDetails;
};

type OnLandPayload = {
  appointmentId: number;
};

type OnLoadedPayload = AppointmentPayload & {
  totals?: AppointmentTotals;
};

type OnLoadRefundsPayload = {
  refunds: RefundApiResponse[];
};

type OnLoadTransactionsPayload = {
  transactions: AppointmentTransactionResponse;
};

type AppointmentIdPayload = {
  appointmentId: number;
};

type IdPayload = AppointmentIdPayload & {
  providerId: number;
};

type LoadPayload = IdPayload & {
  tip?: string;
};

type RefundPayload = IdPayload & {
  amount: number;
  reason: string;
  notes?: string;
  refundType?: RefundTypeChoice;
};

type CancelPayload = IdPayload & {
  cancelAll?: boolean;
};

type NoShowPayload = IdPayload & {
  delete?: boolean;
  charge?: boolean;
};

type UpdatePayload = {
  appointment: ProAppointmentUpdatePayload;
  providerId: number;
};

type CheckoutPayload = {
  signature?: string;
};

export type UpdateCheckoutPayload = {
  tip?: string;
  chargeTipLater?: boolean;
  selectedPaymentType?: PaymentOption;
};

type OnCheckoutUpdatedPayload = {
  tip?: number;
  chargeTipLater?: boolean;
  selectedPaymentType?: PaymentOption;
};

type UpdatePaymentTypePayload = {
  paymentType: PaymentOption;
  card: MinimalCard | null;
};

type OnPaymentTypeUpdatedPayload = UpdatePaymentTypePayload;

type CreateCheckoutPayload = UpdatePaymentTypePayload & {
  tip: string;
  cardOnFile?: ClientCardOnFile | null;
};

type UpdateTipPayload = {
  tip: string | number;
  chargeTipLater?: boolean;
};

type OnTipUpdatedPayload = UpdateTipPayload;

function getInitialState(): ProviderManageAppointmentState {
  return {
    providerId: null,
    selectedAppointmentId: null,
    selectedAppointmentCancelReason: undefined,
    loading: false,
    details: {},
    checkout: {
      tip: '0',
      chargeTipLater: false,
      paymentType: null,
      card: undefined,
      cardOnFile: undefined,
    },
    refunds: undefined,
    transactions: undefined,
  };
}

interface AppointmentLandResult {
  appointment: ProAppointmentDetails;
  provider: PrivilegedProvider;
}

type CreateCheckoutResult = Action<UpdatePaymentTypePayload & {
  tip: string;
  cardOnFile?: ClientCardOnFile;
}, void>;

/**
 *  providerManageAppointment model
 *  exposes a model that allows a provider to manage an appointment -
 *  used in providerDetails, providerAppointmentEdit, providerAppointmentCreate and
 *  the providerCalendar.
 * */
export const providerManageAppointment = createModel<RootModel>()({
  name: 'providerManageAppointment',

  state: getInitialState() as ProviderManageAppointmentState,

  reducers: {
    'user/logout': () => getInitialState(),

    onLoad(
      state: ProviderManageAppointmentState,
      payload: Partial<IdPayload>,
    ): ProviderManageAppointmentState {
      return {
        ...state,
        ...payload,
        loading: true,
      };
    },

    onLoaded: produce<ProviderManageAppointmentState, [OnLoadedPayload]>((
      state: ProviderManageAppointmentState,
      { appointment }: OnLoadedPayload,
    ): ProviderManageAppointmentState => {
      state.details[appointment.id] = appointment;
      state.selectedAppointmentId = appointment.id;

      state.loading = false;
      return state;
    }),

    onLoadRefunds: produce<ProviderManageAppointmentState, [OnLoadRefundsPayload]>((
      state: ProviderManageAppointmentState,
      { refunds }: OnLoadRefundsPayload,
    ): ProviderManageAppointmentState => {
      state.refunds = refunds;
      return state;
    }),

    onLoadTransactions: produce<ProviderManageAppointmentState, [OnLoadTransactionsPayload]>((
      state: ProviderManageAppointmentState,
      { transactions }: OnLoadTransactionsPayload,
    ): ProviderManageAppointmentState => {
      state.transactions = transactions;
      return state;
    }),

    onLand: (
      state: ProviderManageAppointmentState,
      { appointmentId }: OnLandPayload,
    ): ProviderManageAppointmentState => ({
      ...getInitialState(),
      providerId: state.providerId,
      selectedAppointmentId: appointmentId,
    }),

    onLanded: produce<ProviderManageAppointmentState, [AppointmentPayload]>((
      state: ProviderManageAppointmentState,
      { appointment }: AppointmentPayload,
    ): ProviderManageAppointmentState => {
      state.details[appointment.id] = appointment;
      state.selectedAppointmentId = appointment.id;
      state.loading = false;
      return state;
    }),

    // note: the calendar model also listens to this reducer
    onCreated: produce<ProviderManageAppointmentState, [AppointmentPayload]>((
      state: ProviderManageAppointmentState,
      { appointment }: AppointmentPayload,
    ): ProviderManageAppointmentState => {
      state.details[appointment.id] = appointment;
      return state;
    }),

    // note: the calendar model also listens to this reducer
    onUpdated: produce<ProviderManageAppointmentState, [AppointmentPayload]>((
      state: ProviderManageAppointmentState,
      { appointment }: AppointmentPayload,
    ): ProviderManageAppointmentState => {
      state.details[appointment.id] = appointment;
      return state;
    }),

    // note: the calendar model also listens to this reducer
    onRescheduled: produce<ProviderManageAppointmentState, [AppointmentPayload]>((
      state: ProviderManageAppointmentState,
      { appointment }: AppointmentPayload,
    ): ProviderManageAppointmentState => {
      state.details[appointment.id] = appointment;
      return state;
    }),

    // note: the calendar model also listens to this reducer
    onCancellation: produce<ProviderManageAppointmentState, [AppointmentIdPayload]>((
      state: ProviderManageAppointmentState,
      { appointmentId }: AppointmentIdPayload,
    ) => {
      delete state.details[appointmentId];
      return state;
    }),

    onCancelReasonSubmitted: produce<ProviderManageAppointmentState, [string]>((
      state: ProviderManageAppointmentState,
      selected_reason: string,
    ) => {
      state.selectedAppointmentCancelReason = selected_reason;
      return state;
    }),

    onCheckoutCreated: produce<ProviderManageAppointmentState, [CreateCheckoutPayload]>((
      state: ProviderManageAppointmentState,
      {
        tip,
        paymentType,
        card,
        cardOnFile,
      }: CreateCheckoutPayload,
    ) => {
      state.checkout.tip = tip;
      state.checkout.paymentType = paymentType;
      state.checkout.card = card as ICard;
      state.checkout.cardOnFile = cardOnFile;
      return state;
    }),

    onCheckoutUpdated: produce<ProviderManageAppointmentState, [OnCheckoutUpdatedPayload]>((
      state: ProviderManageAppointmentState,
      {
        tip,
        chargeTipLater,
        selectedPaymentType,
      }: OnCheckoutUpdatedPayload,
    ) => {
      state.checkout.tip = typeof tip !== 'undefined' ? String(tip) : state.checkout.tip;
      state.checkout.chargeTipLater = (
        typeof chargeTipLater !== 'undefined'
          ? chargeTipLater
          : state.checkout.chargeTipLater
      );
      state.checkout.paymentType = selectedPaymentType || state.checkout.paymentType;
      state.loading = false;
    }),

    onTipUpdated: produce<ProviderManageAppointmentState, [OnTipUpdatedPayload]>((
      state: ProviderManageAppointmentState,
      {
        tip,
        chargeTipLater = false,
      }: OnTipUpdatedPayload,
    ) => {
      const parsedTip = Number(tip);
      const correctedTip = Number.isNaN(parsedTip) ? 0 : parsedTip;
      const roundedTip = correctedTip.toFixed(2);
      state.checkout.tip = roundedTip;
      state.checkout.chargeTipLater = chargeTipLater;
    }),

    onPaymentTypeUpdated: produce<ProviderManageAppointmentState, [OnPaymentTypeUpdatedPayload]>((
      state: ProviderManageAppointmentState,
      {
        paymentType, card,
      }: OnPaymentTypeUpdatedPayload,
    ) => {
      state.checkout.paymentType = paymentType;
      state.checkout.card = card as ICard;
    }),
  },

  effects: dispatch => ({
    async land(
      { appointmentId }: AppointmentIdPayload,
      rootState,
    ): Promise<AppointmentLandResult> {
      dispatch.providerManageAppointment.onLand({ appointmentId });
      const providerId = userSelectors.getSuperAwareProviderId(rootState);
      const isUsingV1Form = rootState.abTest.assignments[INTAKE_FORM_V1_SWITCH]?.isEnabled;
      let appointment = await getProAppointmentDetails(providerId, appointmentId);
      // if there are promotions, map the IDs to objects
      if (appointment.pro_promotion_discounts.length) {
        appointment = mergePromotionObjects(
          appointment,
          await getFormattedPromotions(providerId, {}),
        );
      }

      if (isUsingV1Form) {
        const intakeFormResponse = await fetchFormWithAnswers(appointment.id);
        if (intakeFormResponse) {
          appointment.intake_form_v1_response = intakeFormResponse;
        }
      } else if (appointment.intake_form_id) {
        const intakeFormResponse = await getIntakeFormResponse(appointment.intake_form_id);
        if (intakeFormResponse) {
          appointment.intake_form_response = formatIntakeFormResponse(
            appointment,
            intakeFormResponse,
          );
        }
      }

      await dispatch.providerAppointmentTotals.load({
        appointmentId: appointment.id,
        providerId: appointment.provider,
      });

      const refunds = await getRefunds(appointment.id);
      await dispatch.providerManageAppointment.onLoadRefunds({ refunds });

      const provider = await dispatch.providers.loadProvider({
        providerId,
        setActive: true,
        noLoading: true,
      }) as PrivilegedProvider;
      await dispatch.providerManageAppointment.createCheckout({ appointment, provider });
      dispatch.providerManageAppointment.onLanded({
        appointment,
      });
      return {
        appointment,
        provider,
      };
    },

    async load({
      appointmentId,
      providerId,
    }: LoadPayload, rootState): Promise<ProAppointmentDetails> {
      dispatch.providerManageAppointment.onLoad({ appointmentId, providerId });
      await dispatch.proCheckoutSettings.load({
        providerId,
      });
      let appointment = await getProAppointmentDetails(providerId, appointmentId);

      // if there are promotions, map the IDs to objects
      if (appointment.pro_promotion_discounts.length) {
        appointment = mergePromotionObjects(
          appointment,
          await getFormattedPromotions(providerId, {}),
        );
      }

      if (appointment.intake_form_id) {
        const intakeFormResponse = await getIntakeFormResponse(appointment.intake_form_id);
        if (intakeFormResponse) {
          appointment.intake_form_response = formatIntakeFormResponse(
            appointment,
            intakeFormResponse,
          );
        }
      }

      const refunds = await getRefunds(appointment.id);
      await dispatch.providerManageAppointment.onLoadRefunds({ refunds });

      await dispatch.providerAppointmentTotals.load({
        appointmentId: appointment.id,
        providerId: appointment.provider,
      });

      if (!appointment.checkout) {
        dispatch.providerAppointmentTotals.updateCheckoutTotals({
          appointmentId: appointment.id,
          providerId: appointment.provider,
          tip: rootState.providerManageAppointment.checkout.tip,
          selectedPaymentType: rootState.providerManageAppointment.checkout.paymentType,
        });
      }

      dispatch.providerManageAppointment.onLoaded({
        appointment,
      });
      return appointment;
    },

    async create(
      { appointment, providerId }: UpdatePayload,
      rootState,
    ): Promise<ProAppointment> {
      const response = await createProAppointment(providerId, {
        ...appointment,
        booked_by: rootState?.user?.user_id ?? rootState?.user?.userId,
      });

      dispatch.providerManageAppointment.onCreated({ appointment: response });
      return response;
    },

    async update(
      { appointment, providerId }: UpdatePayload,
    ): Promise<void> {
      const response = await updateProAppointment(providerId, appointment);
      dispatch.providerManageAppointment.onUpdated({ appointment: response });
      await dispatch.providerManageAppointment.load({
        providerId,
        appointmentId: appointment.id,
      });
    },

    async cancel({
      appointmentId, providerId, cancelAll,
    }: CancelPayload, rootState) {
      const {
        selectedAppointmentCancelReason,
      } = rootState.providerManageAppointment as ProviderManageAppointmentState;

      const response = await cancelAppointmentById(
        providerId,
        appointmentId,
        cancelAll,
        selectedAppointmentCancelReason,
      );
      dispatch.providerManageAppointment.onCancellation({ appointmentId });
      return response;
    },

    async checkout({ signature }: CheckoutPayload = {}, rootState) {
      // Rebuild all checkoutDetails from store
      const {
        checkout: checkoutState,
        details,
        selectedAppointmentId,
      } = rootState.providerManageAppointment as ProviderManageAppointmentState;
      const {
        checkoutRequest,
        totals,
      } = rootState.providerAppointmentTotals;
      const checkoutTotals = {
        ...totals,
        ...(checkoutRequest?.totals || {}),
      };
      const appointment = details[selectedAppointmentId];
      const checkoutDetails = buildCheckoutDetails(
        appointment,
        checkoutState,
        checkoutTotals,
      );

      if (!checkoutDetails) {
        throw new Error('Not enough information to generate checkout');
      }

      let response: CheckoutResponse;
      // Actually check out the appointment
      if (
        Number(checkoutTotals.deposit_amount) > 0
        || rootState.abTest.assignments[USE_NEW_CHECKOUT_FEATURE_FLAG]?.isEnabled
      ) {
        // if there's a deposit, or the flag is on, use the new checkout endpoint
        response = await checkoutAppointment(appointment.provider, appointment.id, {
          ...checkoutDetails,
          signature,
        });
      } else {
        // if no deposit or flag is off, use the old checkout endpoint
        response = await deprecatedCheckoutAppointment(appointment.id, {
          ...checkoutDetails,
          signature,
        });
      }

      if (!isCheckoutSuccess(response)) {
        if (isCheckoutPaymentError(response)) {
          throw new Error(response.error_message);
        }

        // eslint-disable-next-line no-underscore-dangle
        throw new Error(response?.__all__?.[0] || 'Error occurred checking out this appointment');
      }

      const updatedAppt = await getProAppointmentDetails(appointment.provider, appointment.id);
      dispatch.providerManageAppointment.onUpdated({ appointment: updatedAppt });

      // localstorage cleanup
      deletePaymentMethod(appointment.id);
      return appointment;
    },

    async undoCheckout({ appointmentId, providerId }: IdPayload) {
      await undoCheckout(providerId, appointmentId);
      const updatedAppt = await getProAppointmentDetails(providerId, appointmentId);
      dispatch.providerManageAppointment.onUpdated({ appointment: updatedAppt });
      return updatedAppt;
    },

    async markNoShow({
      appointmentId,
      providerId,
      delete: markDeleted,
      charge,
    }: NoShowPayload, rootState) {
      const opts: { attempt_charge?: boolean; ncd_cancellation_survey_reason?: string } = {
        attempt_charge: charge,
      };

      const {
        selectedAppointmentCancelReason,
      } = rootState.providerManageAppointment as ProviderManageAppointmentState;

      if (selectedAppointmentCancelReason) {
        opts.ncd_cancellation_survey_reason = selectedAppointmentCancelReason;
      }

      if (markDeleted) {
        await deleteAppointment(appointmentId, opts);
      } else {
        await markNoShowAppointment(appointmentId, opts);
      }
      const updatedAppt = await getProAppointmentDetails(providerId, appointmentId);
      await dispatch.providerManageAppointment.onUpdated({ appointment: updatedAppt });
      dispatch.providerClients.loadClient({
        providerId,
        clientId: updatedAppt.client?.id,
      });
      return updatedAppt;
    },

    async removeSmartPricing({
      appointmentId,
      providerId,
    }: IdPayload) {
      await removeSmartPrice(providerId, appointmentId);
      await dispatch.providerManageAppointment.load({
        appointmentId,
        providerId,
      });
    },

    async loadTransactions({ appointmentId, providerId }: IdPayload) {
      const transactions = await fetchAppointmentTransactions(providerId, appointmentId);
      await dispatch.providerManageAppointment.onLoadTransactions({ transactions });
    },

    async reschedule({
      appointmentId, providerId, newDate,
    }: IdPayload & { newDate: string }): Promise<any> {
      const apptPayload = changeAppointmentDate(
        await getProAppointmentDetails(providerId, appointmentId),
        newDate,
      );
      const response = await updateProAppointment(providerId, apptPayload);
      dispatch.providerManageAppointment.onRescheduled({ appointment: response });
      return response;
    },

    async createCheckout({
      appointment,
      provider,
    }: {
      appointment: ProAppointmentDetails;
      provider: PrivilegedProvider;
    }, rootState): Promise<CreateCheckoutResult> {
      const proCheckoutSettingsPromise = dispatch.proCheckoutSettings.load({
        providerId: appointment.provider,
      });

      let card: ClientCardOnFile | null = null;
      if (appointment.client?.id) {
        try {
          [card] = await loadCard(appointment.client?.id, appointment.id);
        } catch (e) {
          nonCriticalException(e);
          console.error('Could not load the client card on file', e);
        }
      }
      const paymentType = getPaymentType(appointment, provider, card);
      const cardForCheckout: ICard | null = card ? {
        ...card,
        is_default: false,
        instrument_type: InstrumentType.Card,
      } : null;

      const updateCheckoutTotalsPromise = dispatch.providerAppointmentTotals.updateCheckoutTotals({
        providerId: appointment.provider,
        appointmentId: appointment.id,
        tip: (
          rootState.providerManageAppointment.checkout?.tip
            || appointment.checkout?.tip
            || '0'
        ),
        selectedPaymentType: paymentType,
      });

      await Promise.all([
        proCheckoutSettingsPromise,
        updateCheckoutTotalsPromise,
      ]);

      return dispatch.providerManageAppointment.onCheckoutCreated({
        tip: appointment.checkout?.tip || '0',
        paymentType,
        card: canUseCardOnFile(appointment, provider) ? cardForCheckout : null,
        cardOnFile: card,
      });
    },

    async updateCheckout({
      tip: tipAmount,
      chargeTipLater,
      selectedPaymentType,
    }: UpdateCheckoutPayload, rootState) {
      dispatch.providerManageAppointment.onLoad({});
      const {
        checkout,
        details,
        selectedAppointmentId,
      } = rootState.providerManageAppointment as ProviderManageAppointmentState;
      const appointment = details[selectedAppointmentId];

      let tip = Number(checkout?.tip
        || appointment.checkout?.tip
        || 0);
      if (tipAmount || typeof tipAmount === 'number' || typeof tipAmount === 'string') {
        tip = Number(Number(tipAmount).toFixed(2));
      }
      const paymentType = selectedPaymentType || checkout?.paymentType;

      await dispatch.providerAppointmentTotals.updateCheckoutTotals({
        providerId: appointment.provider,
        appointmentId: appointment.id,
        tip: tip.toString(),
        selectedPaymentType: paymentType,
      });

      dispatch.providerManageAppointment.onCheckoutUpdated({
        tip,
        chargeTipLater,
        selectedPaymentType,
      });
    },

    async updatePaymentType({
      paymentType,
      card,
    }: UpdatePaymentTypePayload, rootState) {
      const {
        details,
        selectedAppointmentId,
        checkout,
      } = rootState.providerManageAppointment;
      const appointment = details[selectedAppointmentId];

      dispatch.providerAppointmentTotals.updateCheckoutTotals({
        providerId: appointment.provider,
        appointmentId: appointment.id,
        selectedPaymentType: paymentType,
        tip: checkout?.tip || appointment.checkout?.tip,
      });

      dispatch.providerManageAppointment.onPaymentTypeUpdated({
        paymentType,
        card,
      });
    },

    async refund({
      appointmentId,
      providerId,
      amount,
      reason,
      notes,
      refundType = RefundTypeChoice.CheckoutRefund,
    }: RefundPayload, rootState) {
      // remember if we had loaded transactions, since they get dropped on load
      const hadTransactions = !!rootState.providerManageAppointment.transactions;
      await refundAppointment(appointmentId, {
        amount,
        reason,
        notes,
        refund_type: refundType,
      });

      await dispatch.providerManageAppointment.load({ appointmentId, providerId });

      if (hadTransactions) {
        await dispatch.providerManageAppointment.loadTransactions({ appointmentId, providerId });
      }
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    // returns the selected appointment
    appointment(models: any) {
      return createSelector(
        slice(state => state.details),
        models.providerCalendar.appointmentsById,
        slice(state => state.selectedAppointmentId),
        (appointments, calendarAppts, apptId): ProAppointmentDetails => (
          appointments[apptId] ?? calendarAppts[apptId] as ProAppointmentDetails
        ),
      );
    },

    forId: hasProps((models: any, id: number) => (
      createSelector(
        slice(state => state.details),
        details => details[id],
      )
    )),

    appointmentDetails(models: any) {
      return createSelector(
        slice(state => state.checkout),
        models.providerManageAppointment.appointment,
        models.providerAppointmentTotals.totals,
        (
          checkoutState: CheckoutState,
          appointment: ProAppointmentDetails,
          checkoutTotals: AppointmentTotals | null,
        ): CheckoutDetails | undefined => buildCheckoutDetails(
          appointment,
          checkoutState,
          checkoutTotals,
        ),
      );
    },

    checkoutDetails(models: any) {
      return createSelector(
        slice(state => state.checkout),
        models.providerManageAppointment.appointment,
        models.providerAppointmentTotals.checkoutTotals,
        (
          checkoutState: CheckoutState,
          appointment: ProAppointmentDetails,
          checkoutTotals: AppointmentTotals | null,
        ): CheckoutDetails | undefined => buildCheckoutDetails(
          appointment,
          checkoutState,
          checkoutTotals,
        ),
      );
    },

    isUsingCardOnFile() {
      return createSelector(
        slice(state => state.checkout.card),
        slice(state => state.checkout.cardOnFile),
        (checkoutCard, cardOnFile): boolean => (
          !!checkoutCard
          && checkoutCard.id === cardOnFile?.id
        ),
      );
    },
  }),
});
