// @ts-strict-ignore
import moment from 'moment';
import { API_ROOT } from '../../../config';
import ssFetch from '../../../modules/ssFetch';
import { DayOfWeek } from '../../../types/dateTime';
import { ProviderAvailability } from '../Availability';

type EditAvailabilityDay = {
  day_of_week: 1;
  work: 1 | 0;
  start_time: 1 | 0;
  start_time_0: string;
  start_time_1: string;
  end_time: 1 | 0;
  end_time_0: string;
  end_time_1: string;
};

// Backend requires the AvailabilityDay fields for each day of the week, eg:
// "Monday-start_time", "Tuesday_start_time"... etc.
// this type maps all the fields to each day of the week
type EditAvailabilityDays<Day extends DayOfWeek = DayOfWeek> = {
  [K in keyof EditAvailabilityDay as `${Day & string}-${K}`]: EditAvailabilityDay[K];
};

export type EditAvailabilityPayload = {
  enabled_online_booking: 1 | 0;
  timezone: string;
} & EditAvailabilityDays;

/**
 * Converts a provider availability object into a valid
 * edit availability payload
 * @param {ProviderAvailability} availability
 * @returns {any}
 */
export const providerAvailabilityToPayload = (
  availability: ProviderAvailability,
): Partial<EditAvailabilityPayload> => (
  availability.working_days.reduce<Partial<EditAvailabilityPayload>>((
    combined,
    day,
  ) => {
    const start = moment(day.start_time, 'H:mm');
    const end = moment(day.end_time, 'H:mm');

    let result = {
      ...combined,
      [`${day.day_of_week}-work`]: day.work ? 1 : 0,
      [`${day.day_of_week}-day_of_week`]: 1,
      [`${day.day_of_week}-start_time`]: day.work ? 1 : 0,
      [`${day.day_of_week}-start_time_0`]: start.format('h:mm'),
      [`${day.day_of_week}-start_time_1`]: start.format('a'),
      [`${day.day_of_week}-end_time`]: day.work ? 1 : 0,
      [`${day.day_of_week}-end_time_0`]: end.format('h:mm'),
      [`${day.day_of_week}-end_time_1`]: end.format('a'),
      [`${day.day_of_week}-start_lunch`]: day.start_lunch ? 1 : 0,
      [`${day.day_of_week}-end_lunch`]: day.end_lunch ? 1 : 0,
    };

    if (day.start_lunch) {
      const lunchStart = moment(day.start_lunch, 'H:mm');
      const lunchEnd = moment(day.end_lunch, 'H:mm');

      result = {
        ...result,
        [`${day.day_of_week}-start_lunch_0`]: lunchStart.format('h:mm'),
        [`${day.day_of_week}-start_lunch_1`]: lunchStart.format('a'),
        [`${day.day_of_week}-end_lunch_0`]: lunchEnd.format('h:mm'),
        [`${day.day_of_week}-end_lunch_1`]: lunchEnd.format('a'),
      };
    }

    return result;
  }, {
    enabled_online_booking: availability.enabled_online_booking ? 1 : 0,
    timezone: availability.timezone,
  })
);

export type EditAvailabilityResult = {
  status?: number;
  [keyError: string]: string | number;
};

export const editAvailability = (
  providerId: number | string,
  availability: Partial<EditAvailabilityPayload>,
) => {
  const url = new URL(`/provider/${providerId}/edit_availability`, API_ROOT);

  return ssFetch<EditAvailabilityResult>(
    url.toString(),
    {
      method: 'POST',
      addRequestedWith: true,
      form: availability,
    },
  );
};
