import { Linking, Platform } from 'react-native';

/**
 * Cross platform function to open a URL
 *
 * @param url - the url to open.
 * @param target - the target window/tab for the url if on web.
 */
export const openExternalUrl = (url: string, target: string = '_system', features?: string) => {
  if (target && Platform.OS === 'web') {
    window.open(url, target, features);
  } else {
    Linking.openURL(url);
  }
};
