// @ts-strict-ignore
import ssFetch, { ssFetchJSON, IResponse } from '../../modules/ssFetch';
import { API_ROOT } from '../../config';
import nonCriticalException from '../../modules/exceptionLogger';
import type { ICard } from '../../store/PaymentMethods/PaymentMethods.types';
import type { Stripe, StripeElement } from '../../components/shared/stripe/types';
import type { IGuestClient } from '../../modules/consumer/GuestBooking';
import { buildUrl as buildClientUrl } from '../../modules/provider/ClientCard';
import { InstrumentType } from '../../store/PaymentMethods/PaymentMethods.types';

export type PaymentClient = {
  userId?: number;
  user_id?: number;
  name?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
};

/**
 * Options for filtering by purpose of payment instrument
 */
export enum InstrumentFilter {
  /** Filter for instruments used to transfer funds to pros */
  Payout = 'payout',
  /** Filter for instruments used to transfer funds to a pro from a client */
  ClientPayment = 'paypro',
  /** Filter for instruments used to transfer funds to styleseat */
  ChargeFee = 'payss',
}

/**
 * Get the user's list of instruments (payment methods)
 * @param {Object} client
 * @param {Number} [client.userId] - The id of the client to fetch cards for
 */
export const getCardsForUser = (
  client: PaymentClient,
  filter?: InstrumentFilter,
): Promise<Array<ICard>> => {
  let url = `${API_ROOT}/api/v1/users/${client.userId || client.user_id}/instruments.json`;

  if (filter) {
    url += `?filter=${filter}`;
  }

  return ssFetchJSON<Array<ICard>>(url);
};

export function filterPaymentCards(instruments: Array<ICard>): Array<ICard> {
  return instruments.filter(instrument => (
    [
      InstrumentType.CreditCard,
      InstrumentType.DebitCard,
      InstrumentType.PrepaidCard,
      InstrumentType.Card,
    ].includes(instrument.instrument_type)
  ));
}

export function filterPaymentBankAccounts(instruments: Array<ICard>): Array<ICard> {
  return instruments.filter(
    instrument => instrument.instrument_type === InstrumentType.BankAccount,
  );
}

/**
 * Get a single records of the user's instruments (payment methods)
 * @param {Object} client
 * @param {Number} [client.userId] - The id of the client to fetch cards for
 * @param {number} cardId The unique internal ID of the payment method to retrieve
 */
export const getCardForUser = (client: PaymentClient, cardId: number): Promise<ICard> => {
  const url = `${API_ROOT}/api/v1/users/${client.userId || client.user_id}/instruments/${cardId}.json`;

  return ssFetchJSON<ICard>(url);
};

/**
 * Returns the default payment card given a list of payment methods
 * @param cards
 * @return {*}
 */
export const getDefaultCardFromList = (cards: Array<ICard>): ICard => {
  const paymentCards = filterPaymentCards(cards);
  return paymentCards.length === 1
    ? paymentCards[0]
    : paymentCards.find(card => card.is_default);
};

/**
 * Set card as the default card for the user
 * @param {Object} client
 * * @param {Number} client.userId - The id of the client to fetch cards for
 * @param {Object} card
 * @param {Number} card.id - The id of the card to update
 */
export const setDefaultCardForUser = async (
  client: PaymentClient,
  card: ICard,
  cvcToken?: string,
): Promise<ICard> => {
  const userId = client.userId || client.user_id;
  const url = `${API_ROOT}/api/v1/users/${userId}/instruments/${card.id}.json`;

  const params: ICard = { ...card, is_default: true };
  if (cvcToken) {
    params.cvc_token = cvcToken;
  }

  return ssFetchJSON<ICard>(url, {
    method: 'PUT',
    body: JSON.stringify({
      ...card,
      ...params,
    }),
  });
};

async function addCardToEntity(
  stripeAPI: Stripe,
  client: PaymentClient,
  cardElement: StripeElement,
  url: string,
  params: {
    save_for_future?: boolean;
    payout_instrument?: boolean;
  },
): Promise<ICard> {
  const { token } = await stripeAPI.createToken(cardElement, {
    name: client.name || `${client.first_name} ${client.last_name}`,
    currency: 'USD',
  });

  const postData = {
    token: token.id,
    // save card for future for guest booking too
    save_for_future: true,
    payout_instrument: false,
    is_default: true,
    ...params,
  };

  return ssFetchJSON<ICard>(url, {
    method: 'POST',
    body: JSON.stringify(postData),
  });
}

/**
 * Adds the provided stripe card instrument
 * to the list of this user's instruments (payment methods)
 * @param stripeAPI
 * @param client
 * @param cardElement
 * @returns {Promise<ICard>}
 */
export async function addCardToUser(
  stripeAPI: Stripe,
  client: PaymentClient,
  cardElement: StripeElement,
  payoutInstrument: boolean = false,
): Promise<ICard> {
  return addCardToEntity(
    stripeAPI,
    client,
    cardElement,
    `${API_ROOT}/api/v1/users/${client.userId || client.user_id}/instruments.json`,
    { payout_instrument: payoutInstrument },
  );
}

/**
 * Adds the provided stripe card instrument
 * to the list of this client's instruments (payment methods)
 * @param stripeAPI
 * @param client
 * @param cardElement
 * @param saveForFuture
 * @returns {Promise<ICard>}
 */
export async function addCardToClient(
  stripeAPI: Stripe,
  client: PaymentClient,
  cardElement: StripeElement,
  saveForFuture: boolean,
): Promise<ICard> {
  return addCardToEntity(
    stripeAPI,
    client,
    cardElement,
    `${API_ROOT}${buildClientUrl(client.id || client.user_id, null)}`,
    { save_for_future: saveForFuture },
  );
}

/**
 * Removes the instrument with the given ID from the list of this user's instruments (payment
 * methods)
 * @param stripeAPI
 * @param client The client from whom to remove the instrument
 * @param cardId The ID of the card to remove
 * @returns {Promise<IResponse<void>>}
 */
export async function removeCardFromUser(
  client: PaymentClient,
  cardId: number,
): Promise<IResponse<void>> {
  return ssFetch<void>(
    `${API_ROOT}/api/v1/users/${client.userId || client.user_id}/instruments/${cardId}.json`,
    {
      method: 'DELETE',
    },
  );
}

export async function addGuestCard(
  stripe: Stripe,
  guest: IGuestClient,
  card: any,
  params?: Record<string, any>,
) {
  const url = `${API_ROOT}/api/v1/pay/guest/add_card`;
  const { name } = guest;
  let stripeTokenResult;

  try {
    stripeTokenResult = await stripe.createToken(card, {
      name,
      currency: 'USD',
    });
  } catch (e) {
    let error = 'Sorry - something went wrong. Please try again later.';
    if (e && e.message) {
      error = e.message;
    }
    // Inform newrelic if there was an error
    nonCriticalException(error, {
      extra: {
        source: 'Payments.addGuestCard Error Stripe Tokenization',
      },
    });
    throw new Error(error);
  }

  const postData = {
    token: stripeTokenResult.token.id,
    zipcode: card.zipcode,
    settings_token: guest.token,
    // save card for future for guest booking too
    save_for_future: true,
    ...params,
  };

  try {
    const result = await ssFetchJSON<ICard>(url, {
      method: 'POST',
      body: JSON.stringify(postData),
    });
    return result;
  } catch (e) {
    const [message] = e?.data || [];
    const error = message || 'Sorry - something went wrong. Please try again later.';

    // Stripe errors currently come with a request id which is irrelevant to users
    // This is being removed, but we'll have check for it for now
    // TODO: remove this parsing when the backend is fixed
    const matched = error.match(/req_[^:]+: (.*)/);
    const errorMessage = (matched && matched.length > 1) ? matched[1] : error;

    // Inform newrelic if there was an error
    nonCriticalException(errorMessage, {
      extra: {
        source: 'Payments.addGuestCard Error Stripe Tokenization',
      },
    });
    throw new Error(errorMessage);
  }
}

type CanDeleteResult = { can_delete: boolean };
export async function checkCanDelete(card: ICard): Promise<boolean> {
  const result = await ssFetchJSON<CanDeleteResult>(`${API_ROOT}/api/v1/instruments/${card.id}/can_delete.json`);

  return result?.can_delete;
}
