// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel, RootState } from '../models';
import type { IRouteState } from '../../../../packages/router';

import { Responsive } from '../../modules/Responsive';

import {
  MarketplaceProfileModelState,
  MarketplaceProfileModelUI,
  MARKETPLACE_PROFILE_UI,
} from './MarketplaceProfile.types';
import { PROFILE_ROUTE } from '../../route-names';

export function isFromExternalSource(route: IRouteState, currentTargetRoute: string): boolean {
  if (!route?.previous) {
    return true;
  }
  let currentRoute = route;

  while (currentRoute) {
    if (!currentRoute.name.includes(currentTargetRoute)) {
      return false;
    }
    currentRoute = currentRoute.previous;
  }
  return true;
}
export function isFromInternalSource(route: IRouteState): boolean {
  if (route?.previous?.name === 'home') {
    return true;
  }
  return false;
}

export function isFromSearch(route: IRouteState): boolean {
  return !!route?.params?.sid;
}

const marketplaceProfile = createModel<RootModel>()({
  name: 'marketplaceProfile' as string,

  state: {
    shouldRender: false,
  } as MarketplaceProfileModelState,

  reducers: {
    setUI: (state: MarketplaceProfileModelState, payload: MarketplaceProfileModelUI) => ({
      ...state,
      ...payload,
    }),
  },

  effects: dispatch => ({
    assignUI: async (
      { providerId }: { providerId: number },
      rootState: RootState,
    ): Promise<any> => {
      const isMobile: boolean = Responsive.is.mobile || Responsive.is.tablet;

      if (
        !!rootState.user?.providerId
        || !rootState.recommendations?.providers?.[providerId]
        || isFromSearch(rootState.route)
      ) {
        // If the user is a pro or there are no recs or coming from search, don't show the MP UI
        await dispatch.marketplaceProfile.setUI({
          ui: MARKETPLACE_PROFILE_UI.NOT_ELIGIBLE,
          serviceListCount: 0,
          shouldRender: false,
        });
      } else if (
        isMobile
        && isFromExternalSource(rootState.route, PROFILE_ROUTE)
      ) {
        await dispatch.marketplaceProfile.setUI({
          ui: MARKETPLACE_PROFILE_UI.SERVICE_TILES_MOBILE,
          serviceListCount: 4,
          shouldRender: true,
        });
      }
    },
    resetUI: async (): Promise<void> => {
      await dispatch.marketplaceProfile.setUI({
        ui: MARKETPLACE_PROFILE_UI.NOT_ELIGIBLE,
        serviceListCount: 0,
        shouldRender: false,
      });
    },
  }),

  selectors: (slice, createSelector) => ({
    getMarketplaceProfile: () => createSelector(
      slice,
      ((state: MarketplaceProfileModelState): MarketplaceProfileModelState => state),
    ),
  }),
});

export default marketplaceProfile;
