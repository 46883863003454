import { useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Hook which retrieves the current route name from state and allows the value to be updated without
 * affecting state
 */
export default (): [string, (routeName: string) => void] => {
  const initialRouteName = useSelector(state => (state as any).route.name);
  return useState<string>(initialRouteName);
};
