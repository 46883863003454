// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Session } from 'talkjs/all';
import type { IRootDispatch, RootState } from '../store/models';
import useFeatureFlagRedux from './useFeatureFlagRedux';
import {
  CHAT_CLIENT_INBOX_SWITCH,
  CHAT_GENERAL_SWITCH,
  CHAT_CLIENT_HAS_SENT_MESSAGE,
  CHAT_CLIENT_HAS_SEEN_FAKE_DOOR,
} from '../modules/chat/constants';
import { ChatRoles } from '../modules/chat/types';
import ChatManager from '../modules/chat/ChatManager';
import useUserStateRedux from './useUserStateRedux';
import analytics from '../modules/analytics';
import { useGetTalkJsUserIdFromSsUserOrProviderId } from '../modules/chat/utils';

const useGetChatUnreadCounterForClient = () => {
  const dispatch: IRootDispatch = useDispatch();
  const chatState = useSelector((state: RootState) => state.chat);
  const unreadCounter = chatState.unreadMessages;
  const { clientHasSeenInboxIcon } = chatState;
  const { hasUpdatedUnreadCounter } = chatState;
  const [chatSession, setChatSession] = useState<Promise<Session>>();
  const currentUser = useSelector((state: RootState) => state.user);
  const {
    enabled: isChatEnabledForClients,
  } = useFeatureFlagRedux(CHAT_GENERAL_SWITCH);
  const {
    enabled: isClientInboxEnabled,
  } = useFeatureFlagRedux(CHAT_CLIENT_INBOX_SWITCH);
  const isUserPro = !!currentUser.providerId;
  const talkJsUserId = useGetTalkJsUserIdFromSsUserOrProviderId(
    currentUser?.user_id,
    ChatRoles.Client,
  );
  const [
    clientHasSentMessage, , clientHasSentMessageLoaded,
  ] = useUserStateRedux(CHAT_CLIENT_HAS_SENT_MESSAGE);
  const [clientHasSeenFakeDoor] = useUserStateRedux(CHAT_CLIENT_HAS_SEEN_FAKE_DOOR);
  const clientHasSeenFakeDoorLoaded = !!(useSelector((state: RootState) => state.userState.userId));
  const [chatUser, setChatUser] = useState(null);
  const isInboxAccessible = isChatEnabledForClients
    && isClientInboxEnabled;
  const isInboxAccessibleForClient = clientHasSentMessageLoaded
    && clientHasSentMessage;
  const shouldCreateTalkJSSession = isInboxAccessibleForClient && chatUser && !chatSession;
  const isUserUsingFakeInbox = clientHasSeenFakeDoorLoaded
    && !clientHasSeenFakeDoor
    && clientHasSentMessageLoaded
    && !clientHasSentMessage;

  useEffect(() => {
    if (shouldCreateTalkJSSession) {
      const session = ChatManager.getOrCreateSession(chatUser, unread => {
        dispatch.chat.updateUnreadCounter(unread);
        analytics.track('client_nav_bar_unread_counter_updated', {
          client_user_id: String(currentUser.userId),
          unread_chats: unread,
        });
      });
      setChatSession(session);
    }
  }, [
    chatSession,
    chatUser,
    shouldCreateTalkJSSession,
  ]);

  useEffect(() => {
    if (isUserUsingFakeInbox && !clientHasSeenInboxIcon) {
      dispatch.chat.onClientHasSeenInboxIcon();
      analytics.track('client_nav_bar_chat_icon_viewed', {
        client_user_id: String(currentUser.userId),
        unread_chats: 0,
      });
    } else if (hasUpdatedUnreadCounter && !clientHasSeenInboxIcon) {
      dispatch.chat.onClientHasSeenInboxIcon();
      analytics.track('client_nav_bar_chat_icon_viewed', {
        client_user_id: String(currentUser.userId),
        unread_chats: unreadCounter,
      });
    }
  }, [
    hasUpdatedUnreadCounter,
    unreadCounter,
    isUserUsingFakeInbox,
  ]);

  useEffect(() => {
    if (
      !isUserPro
      && isInboxAccessible
      && isInboxAccessibleForClient
    ) {
      if (!chatUser) {
        const newChatUser = ChatManager.createChatUser(currentUser, ChatRoles.Client, talkJsUserId);
        setChatUser(newChatUser);
      }
    }
  }, [
    chatUser,
    currentUser,
    isInboxAccessible,
    isInboxAccessibleForClient,
    isUserPro,
  ]);

  return isUserUsingFakeInbox ? 1 : unreadCounter;
};

export default useGetChatUnreadCounterForClient;
