// @ts-strict-ignore
/**
 * Wrappers for various platform-dependent sharing methods.
 *
 * On cordova apps, wraps window.plugins.socialsharing.share().
 * On mobile (Android Chrome and iOS Safari), wraps navigator.share().
 * On macOS Safari, wraps navigator.share().
 * Also provides download support on all platforms.
 *
 * Probably of most use are the `shareCurried` and `downloadCurried` functions.
 * These are asynchronous functions which generate synchronous functions
 * that can be called later. Sharing and downloading in browsers can only be
 * initiated in synchronous response to a user event, such as a click - so we
 * are limited in what we can do immediately before triggering a share. By
 * currying functions with all required arguments ahead of time, we step around
 * this browser limitation.
 *
 * To open a generic native share modal:
 *
 *   import { shareCurried, canShare } from '../path/to/modules/share';
 *
 *   const params = {
 *     title: 'the optional title of the post',
 *     text: 'the optional body of the post',
 *     url: 'the optional link for the post',
 *     // the optional image for the post
 *     image: <string | URL | Blob | File | HTMLImageElement | HTMLCanvasElement>,
 *   };
 *   let onClick = () => {};
 *   if (await canShare(params)) {
 *     onClick = await shareCurried(
 *       params,
 *       () => console.log('Shared!'),
 *       (err) => console.error('User cancelled or error occured.', err),
 *     );
 *   }
 *   <View onClick={onClick} />
 *
 * To share directly to some platform (on desktop Safari, specifying target
 * will just open a generic native share modal):
 *
 *   import {
 *     shareCurried,
 *     canShare,
 *     Target,
 *   } from '../path/to/modules/share';
 *
 *   const params = {
 *     target: Target.Facebook | Target.Twitter,
 *     title: 'the optional title of the post',
 *     text: 'the optional body of the post',
 *     url: 'the optional link for the post',
 *     // the optional image for the post
 *     image: <string | URL | Blob | File | HTMLImageElement | HTMLCanvasElement>,
 *   };
 *   let onClick = () => {};
 *   if (await canShare(params)) {
 *     onClick = await shareCurried(
 *       params,
 *       () => console.log('Shared!'),
 *       (err) => console.error('User cancelled or error occured.', err),
 *     );
 *   }
 *   <View onClick={onClick} />
 *
 * To share directly to Instagram (on desktop Safari, specifying target
 * will just open a generic native share modal):
 *
 *   import {
 *     shareCurried,
 *     canShare,
 *     Target,
 *   } from '../path/to/modules/share';
 *
 *   const params = {
 *     target: Target.Instagram,
 *     image: <string | URL | Blob | File | HTMLImageElement | HTMLCanvasElement>,
 *   };
 *   let onClick = () => {};
 *   if (await canShare(params)) {
 *     onClick = await shareCurried(
 *       params,
 *       () => console.log('Shared!'),
 *       (err) => console.error('User cancelled or error occured.', err),
 *     );
 *   }
 *   <View onClick={onClick} />
 *
 *
 * To download:
 *
 *   import { downloadCurried } '../path/to/modules/share';
 *   // No need to call canShare or wrap in a try/catch for download.
 *   const onClick = await downloadCurried(
 *     <string | URL | Blob | File | HTMLImageElement | HTMLCanvasElement>,
 *   );
 *   <View onClick={onClick} />
 *
 * Alternatively:
 *
 *   import { shareCurried, Target } from '../path/to/modules/share';
 *
 *   // No need to call canShare or wrap in a try/catch for download.
 *   const onClick = await shareCurried({
 *     target: Target.Download,
 *     image: <string | URL | Blob | File | HTMLImageElement | HTMLCanvasElement>,
 *   });
 *   <View onClick={onClick} />
 *
 */
import { saveAs } from 'file-saver';
import {
  getIOSVersion,
  getIsApp,
  getOS,
} from '../AppInfo';

import {
  toBlob,
  toDataUri,
  BlobType,
} from '../blobUtils';
import nonCriticalException from '../exceptionLogger';
import {
  INavigator,
  INavigatorShareData,
  IShareParams,
  ISocialSharingPlugin,
  SocialSharingError,
  SocialSharingShareArgs,
  SocialSharingChannel,
  ShareChannel,
} from './share.types';

/**
 * Type annotated wrapper for retrieving plugins.socialsharing.
 */
export function socialSharing(): ISocialSharingPlugin | undefined {
  // @ts-ignore
  return window.plugins?.socialsharing;
}

/**
 * Type annotated wrapper for retrieving window.navigator.
 */
export function nav(): INavigator {
  // @ts-ignore
  return window.navigator;
}

/**
 * Convert IShareParams, passed to this modules's share() function to a tuple
 * of data suitable for passing as the first 4 arguments to
 * plugins.socialsharing.share().
 */
async function toSocialSharingShareArgs(
  params: IShareParams,
): Promise<SocialSharingShareArgs> {
  const { image } = params;
  let imageString;
  if (image) {
    let url;
    if (typeof image === 'string') {
      try {
        url = new URL(image);
      } catch (err) {
        // Not a URL
      }
    } else if (image instanceof URL) {
      url = image;
    }
    if (
      url
      && (
        url.protocol === 'https:'
        || url.protocol === 'http:'
      )
      && url.host !== 'app-internal.styleseat.com'
    ) {
      // Image should be downloaded internally by plugin
      imageString = url.toString();
    } else {
      imageString = await toDataUri(image);
    }
  }
  return [
    params.text || null,
    params.title || null,
    imageString || null,
    params.url ? params.url.toString() : null,
    params.recipients || null,
  ];
}

/**
 * Convert IShareParams, passed to this modules's share() function, to
 * INavigatorShareData, as passed to navigator.share().
 */
async function toNavigatorShareData(
  params: IShareParams,
): Promise<INavigatorShareData> {
  const shareData: INavigatorShareData = {};
  if (params.text) {
    shareData.text = params.text;
  }
  if (params.title) {
    shareData.title = params.title;
  }
  if (params.url) {
    shareData.url = params.url.toString();
  }
  if (params.image) {
    let blob;
    try {
      blob = await toBlob(params.image);
    } catch (err) {
      nonCriticalException(err, {
        extra: 'Error fetching image',
      });
    }
    if (blob) {
      const file = new File([blob], 'StyleSeat.png', { type: blob.type });
      // @ts-ignore - libdom stub doesn't have this property, but it's valid.
      shareData.files = [file];
    }
  }
  if (params.recipients) {
    let url = 'sms://';
    if (getOS() === 'ios') url += `open?addresses=${params.recipients.join()}`;
    else url += params.recipients.join();
    if (params.text) {
      // Specially crafted to work on iOS and Android. The semicolon ends the list of phone numbers
      // while he ampersand is used on iOS and the question mark is used on Android as the start
      // of the sequence to add a body. All together this works cross platform.
      url += `;?&body=${encodeURIComponent(params.text)}`;
    }
    shareData.url = url;
  }
  return shareData;
}

/**
 * Convert a Target value to a corresponding SocialSharingTarget value.
 */
function toSocialSharingTarget(target: ShareChannel): SocialSharingChannel {
  if (target === ShareChannel.SMS) {
    return SocialSharingChannel.SMS;
  }
  if (target === ShareChannel.Email) {
    return SocialSharingChannel.Email;
  }
  if (getIOSVersion()) {
    if (target === ShareChannel.Facebook) {
      return SocialSharingChannel.FacebookiOS;
    }
    if (target === ShareChannel.Twitter) {
      return SocialSharingChannel.TwitteriOS;
    }
    if (target === ShareChannel.Instagram) {
      return SocialSharingChannel.Instagram;
    }
  } else {
    // Android
    if (target === ShareChannel.Facebook) {
      return SocialSharingChannel.FacebookAndroid;
    }
    if (target === ShareChannel.Twitter) {
      return SocialSharingChannel.TwitterAndroid;
    }
    if (target === ShareChannel.Instagram) {
      return SocialSharingChannel.Instagram;
    }
  }
  return SocialSharingChannel.None;
}

/**
 * Trigger a native share via
 *
 * plugins.socialsharing.share,
 * plugins.socialsharing.shareVia,
 * plugins.socialsharing.shareViaSMS,
 * or plugins.socialSharing.shareViaInstagram,
 *
 * depending on params.
 */
async function socialSharingShare(
  params: IShareParams,
): Promise<ShareChannel> {
  const [
    text, title, image, url, recipients,
  ] = await toSocialSharingShareArgs(params);
  if (!text && !title && !url && !image) {
    throw new Error('No data provided to share.');
  }
  return new Promise((resolve, reject) => {
    const target = toSocialSharingTarget(params.channel);
    if (target === SocialSharingChannel.None) {
      if (text && url && getIOSVersion()) {
        // on IOS when a link and text are passed the text is ignored
        // on some phones, when using the sms share
        // from the native menu.
        // In addition, the url is ignored when using the direct
        // copy share option.
        // So we just put the url in the text
        socialSharing()?.share(
          `${text}\n${url}`,
          title,
          image,
          null,
          () => resolve(ShareChannel.None),
          reject,
        );
      } else {
        socialSharing()?.share(
          text,
          title,
          image,
          url,
          () => resolve(ShareChannel.None),
          reject,
        );
      }
    } else if (target === SocialSharingChannel.Instagram) {
      socialSharing()?.shareViaInstagram(
        text,
        image,
        () => resolve(ShareChannel.Instagram),
        reject,
      );
    } else if (target === SocialSharingChannel.SMS) {
      socialSharing()?.shareViaSMS(
        text,
        recipients,
        () => resolve(ShareChannel.SMS),
        reject,
      );
    } else {
      socialSharing()?.shareVia(
        target,
        text,
        title,
        image,
        url,
        () => resolve(params.channel),
        reject,
      );
    }
  });
}

async function navigatorShareCurried(
  params: IShareParams,
  onResolve: any = () => { },
  onReject: any = () => { },
): Promise<() => Promise<ShareChannel>> {
  const shareData = await toNavigatorShareData(params);
  if (!Object.keys(shareData).length) {
    throw new Error('No shareable data provided.');
  }
  return () => nav().share(shareData).then(onResolve, onReject);
}

/**
 * Returns a function which synchronously triggers a download of the given item.
 */
export async function downloadCurried(
  item: BlobType,
  {
    fileName = 'StyleSeat.png',
    onResolve = () => { },
    onReject = () => { },
  }: {
    fileName?: string;
    onResolve?: () => void;
    onReject?: () => void;
  } = {},
): Promise<() => Promise<ShareChannel>> {
  const blob = await toBlob(item);
  if (getIsApp()) {
    // Cordova download
    return () => {
      let downloadLocation;
      if (getIOSVersion()) {
        // @ts-ignore
        downloadLocation = window.cordova.file.sharedDirectory;
      } else {
        // @ts-ignore
        downloadLocation = window.cordova.file.externalDataDirectory;
      }
      return new Promise((resolve, reject) => {
        // @ts-ignore
        window.resolveLocalFileSystemURL(
          downloadLocation,
          dir => {
            dir.getFile(
              fileName,
              { create: true },
              file => {
                file.createWriter(
                  fileWriter => {
                    // eslint-disable-next-line no-param-reassign
                    fileWriter.onwriteend = () => {
                      resolve(ShareChannel.Download);
                    };

                    // eslint-disable-next-line no-param-reassign
                    fileWriter.onerror = err => {
                      reject(err);
                    };

                    fileWriter.write(blob);
                  },
                  reject,
                );
              },
              reject,
            );
          },
          reject,
        );
      });
    };
  }

  // Browser download
  return () => {
    let promise;
    try {
      saveAs(blob, fileName);
      promise = Promise.resolve();
    } catch (err) {
      promise = Promise.reject(err);
    }
    promise.then(onResolve, onReject);
    return promise;
  };
}

/**
 * Trigger a download of the given item.
 *
 * Note that in many browsers this will fail because the download needs to
 * happen in the same synchronous call stack as a user-initiated event (click).
 * Instead, use downloadCurried() to create a callback function that will
 * perform the download synchronously.
 */
export async function download(
  item: BlobType,
  fileName: string = 'StyleSeat.png',
): Promise<ShareChannel> {
  return (await downloadCurried(item, { fileName }))();
}

/**
 * Returns a function which synchronously triggers a native share or download.
 */
export async function shareCurried(
  params: IShareParams,
  onResolve: any = () => { },
  onReject: any = () => { },
): Promise<() => Promise<ShareChannel>> {
  if (params.channel === ShareChannel.Download) {
    if (!params.image) {
      throw new Error('Cannot download with given params.');
    }
    return downloadCurried(params.image, { onResolve, onReject });
  }
  if (socialSharing()) {
    return () => socialSharingShare(params).then(onResolve, onReject);
  }
  if (nav().share) {
    return navigatorShareCurried(params, onResolve, onReject);
  }
  throw new Error('No native share functionality available.');
}

/**
 * Trigger a native share or download.
 *
 * Note that in many browsers this will fail because the share needs to
 * happen in the same synchronous call stack as a user-initiated event (click).
 * Instead, use shareCurried() to create a callback function that will perform
 * the share synchronously.
 */
export async function share(params: IShareParams): Promise<ShareChannel> {
  return (await shareCurried(params))();
}

/**
 * Determine if navigator.share or plugins.socialsharing are available.
 */
export function hasShareBackend(): boolean {
  return Boolean(nav().share || socialSharing());
}

/**
 * Determine if this browser/app can share the given parameters.
 */
export async function canShare(params: IShareParams): Promise<boolean> {
  if (params.channel === ShareChannel.Download) {
    // All platforms can download.
    return true;
  }
  const noTarget = (
    // No passed target
    (!params.channel)
    // Passed target is None
    || (params.channel === ShareChannel.None)
  );

  if (socialSharing()) {
    const target = toSocialSharingTarget(params.channel);
    if (noTarget || (target === SocialSharingChannel.None)) {
      // Generic share using socialSharing is always possible.
      return true;
    }
    // Some kind of direct share, check with plugin for installed apps.
    const [
      text, title, image, url,
    ] = await toSocialSharingShareArgs(params);
    return new Promise((resolve, reject) => {
      socialSharing()?.canShareVia(
        target,
        text,
        title,
        image,
        url,
        () => resolve(true),
        err => {
          if (err === SocialSharingError.NotAvailable) {
            resolve(false);
          } else {
            reject(err);
          }
        },
      );
    });
  }
  if (noTarget && nav().share) {
    // navigator.share() only supports generic share.
    const shareData = await toNavigatorShareData(params);
    if (nav().canShare) {
      // Mobile Chrome supports navigator.canShare()
      // navigator.share() might be able to share, depends on params
      return nav().canShare(shareData);
    }
    // navigator.share() is defined, but not navigator.canShare(), so this
    // must be Safari (iOS or macOS). Safari doesn't support sharing files -
    // so if we're not trying to share files, we're good.
    return Object.keys(shareData).length > 0 && !shareData.files?.length;
  }

  return false;
}
