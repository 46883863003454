// @ts-strict-ignore
import { createModel } from '@rematch/core';
import moment from 'moment';
import type { RootModel } from './models';
import { PrivilegedProvider } from '../api/Providers';
import { fetchProviderOnboardingDetails, OnboardingGoal } from '../modules/api/providers';
import { fetchPaymentState } from '../modules/provider/PaymentSettings';
import FeatureFlags from '../modules/FeatureFlags';
// eslint-disable-next-line no-restricted-imports
import { PROVIDER_ONBOARDING_EXPERIENCE } from '../components/provider/Onboarding/Tips/constants';

export const PROVIDER_ONBOARDING_TIPS_VIEWED_KEY = 'provider-onboarding-tip-viewed-ids';

export enum OnboardingTips {
  AddImages = 'add_images',
  Reviews = 'reviews',
  AboutMe = 'about_me',
  UpdatePolicies = 'update_policies',
  SetUpPayments = 'set_up_payments',
  UploadClientList = 'upload_client_list',
  SetupInstantPayouts = 'setup_instant_payouts',
}

export type ProviderGoalsState = {
  providerId: number;
  isInitialized: boolean;
  goal: OnboardingGoal;
  // All existing tips (tips can be in multiple goals)
  tips: {
    [key in OnboardingTips]: boolean
  };
  // Tips for each goal
  goalTips: {
    [key in OnboardingGoal]: OnboardingTips[]
  };
  // Status of each goal (if all tips are completed)
  goals: {
    [key in OnboardingGoal]: boolean;
  };
  is2024TipsFlagEnabled: boolean;
  hasBankAccount?: boolean;
};

export const DEFAULT_STATE: ProviderGoalsState = {
  providerId: null,
  isInitialized: false,
  tips: {
    [OnboardingTips.AboutMe]: false,
    [OnboardingTips.AddImages]: false,
    [OnboardingTips.Reviews]: false,
    [OnboardingTips.SetUpPayments]: false,
    [OnboardingTips.SetupInstantPayouts]: false,
    [OnboardingTips.UpdatePolicies]: false,
    [OnboardingTips.UploadClientList]: false,
  },
  goalTips: {
    [OnboardingGoal.NewClients]: [
      OnboardingTips.Reviews,
      OnboardingTips.SetUpPayments,
      OnboardingTips.AddImages,
      OnboardingTips.UpdatePolicies,
    ],
    [OnboardingGoal.ManageClients]: [
      OnboardingTips.UploadClientList,
      OnboardingTips.UpdatePolicies,
      OnboardingTips.SetUpPayments,
      OnboardingTips.SetupInstantPayouts,
    ],
    [OnboardingGoal.OnboardingGoals]: [
      OnboardingTips.SetUpPayments,
      OnboardingTips.UploadClientList,
      OnboardingTips.AddImages,
      OnboardingTips.Reviews,
      OnboardingTips.UpdatePolicies,
    ],
  },
  goal: null,
  goals: {
    [OnboardingGoal.NewClients]: false,
    [OnboardingGoal.ManageClients]: false,
    [OnboardingGoal.OnboardingGoals]: false,
  },
  is2024TipsFlagEnabled: false,
  hasBankAccount: false,
};

const isGoalCompleted = (
  goal: OnboardingGoal,
  tips: ProviderGoalsState['tips'],
  goalTips: ProviderGoalsState['goalTips'],
): boolean => {
  if (!goal) {
    return false;
  }

  const currentTips = goalTips[goal];
  return currentTips.reduce((isComplete, tip) => isComplete && tips[tip], true);
};

const getGoalsCompleted = (
  tips: ProviderGoalsState['tips'],
  is2024TipsFlagEnabled = false,
): ProviderGoalsState['goals'] => ({
  [OnboardingGoal.NewClients]: is2024TipsFlagEnabled ? true : isGoalCompleted(
    OnboardingGoal.NewClients,
    tips,
    DEFAULT_STATE.goalTips,
  ),
  [OnboardingGoal.ManageClients]: is2024TipsFlagEnabled ? true : isGoalCompleted(
    OnboardingGoal.ManageClients,
    tips,
    DEFAULT_STATE.goalTips,
  ),
  [OnboardingGoal.OnboardingGoals]: isGoalCompleted(
    OnboardingGoal.OnboardingGoals,
    tips,
    DEFAULT_STATE.goalTips,
  ),
});

const getGoalStatus = (
  currentGoal: OnboardingGoal,
  goals: ProviderGoalsState['goals'],
  is2024TipsFlagEnabled: boolean,
) => {
  let goal = currentGoal;
  if (currentGoal === OnboardingGoal.NewClients
      && goals[OnboardingGoal.NewClients] === true) {
    goal = OnboardingGoal.ManageClients;
  } else if (currentGoal === OnboardingGoal.ManageClients
      && goals[OnboardingGoal.ManageClients] === true) {
    goal = OnboardingGoal.NewClients;
  }

  if (goals[OnboardingGoal.ManageClients] === true
    && goals[OnboardingGoal.NewClients] === true
    && (is2024TipsFlagEnabled === false
      || (is2024TipsFlagEnabled === true && goals[OnboardingGoal.OnboardingGoals] === true)
    )) {
    goal = null;
  }

  // Should not show goal even is is selected if 2024 tips flag is not enabled
  if (currentGoal === OnboardingGoal.OnboardingGoals
    && !is2024TipsFlagEnabled) {
    goal = null;
  }

  return goal;
};

const checkSetupPayment = async rootState => {
  const activeProvider = (
    rootState.providers.providersById[rootState.providers.activeProviderId]?.result
  );

  if (activeProvider) {
    const paymentSettings = await fetchPaymentState(rootState.providers.activeProviderId);
    return paymentSettings;
  }

  return null;
};

export const ProviderGoals = createModel<RootModel>()({
  name: 'providerGoals',

  state: DEFAULT_STATE,

  reducers: {
    onClear(): ProviderGoalsState {
      return {} as ProviderGoalsState;
    },
    setGoalsStatus: (
      state,
      payload: Partial<ProviderGoalsState>,
    ) => {
      const goal = getGoalStatus(
        payload.goal ?? state.goal,
        payload.goals ?? state.goals,
        payload.is2024TipsFlagEnabled ?? state.is2024TipsFlagEnabled,
      );
      return {
        ...state, ...payload, goal,
      };
    },
    setCurrentGoal: (
      state,
      currentGoal: OnboardingGoal,
    ) => ({ ...state, goal: currentGoal }),
  },

  effects: dispatch => ({
    async load(
      {
        providerId,
      }: { providerId: number | undefined | null },
      rootState,
    ): Promise<void> {
      const [
        onboardingDetails, setupPayment, is2024TipsFlagEnabled,
      ] = await Promise.all([
        fetchProviderOnboardingDetails(providerId),
        checkSetupPayment(rootState),
        FeatureFlags.isEnabled(PROVIDER_ONBOARDING_EXPERIENCE),
      ]);

      const { goal_selected } = onboardingDetails;

      // Check tips status from user state
      const savedTips = rootState.userState.values[PROVIDER_ONBOARDING_TIPS_VIEWED_KEY]
      || DEFAULT_STATE.tips;

      const tips = {
        ...savedTips,
        [OnboardingTips.SetUpPayments]: setupPayment?.isOnboarded() ?? false,
      };

      dispatch.providerGoals.setGoalsStatus({
        ...DEFAULT_STATE,
        isInitialized: true,
        providerId,
        tips,
        goal: is2024TipsFlagEnabled ? OnboardingGoal.OnboardingGoals : goal_selected,
        goals: getGoalsCompleted(tips, is2024TipsFlagEnabled),
        is2024TipsFlagEnabled: !!is2024TipsFlagEnabled,
        hasBankAccount: setupPayment?.hasBankAccount() ?? false,
      });
    },
    async completeTip(tip: OnboardingTips, rootState): Promise<void> {
      let setupPayment = null;
      if (tip === OnboardingTips.SetUpPayments) {
        setupPayment = await checkSetupPayment(rootState);
        if (!setupPayment?.isOnboarded()) {
          return;
        }
      }

      const savedTips = rootState.userState.values[PROVIDER_ONBOARDING_TIPS_VIEWED_KEY]
      || DEFAULT_STATE.tips;

      const tips = {
        ...savedTips,
        [tip]: true,
      };

      const goals = getGoalsCompleted(tips);

      dispatch.providerGoals.setGoalsStatus({
        ...rootState.providerGoals,
        tips,
        goals,
        hasBankAccount: setupPayment?.hasBankAccount() ?? false,
      });
      await dispatch.userState.addValues({
        values: {
          [PROVIDER_ONBOARDING_TIPS_VIEWED_KEY]: tips,
        },
        preferLocalKeys: [PROVIDER_ONBOARDING_TIPS_VIEWED_KEY],
      });
    },
  }),

  selectors: (
    slice,
    createSelector,
    hasProps,
  ) => ({
    isGoalsInitialized: () => createSelector(
      slice(state => state?.isInitialized),
      (isInitialized: boolean): boolean => (isInitialized),
    ),
    isGoalCompleted: hasProps((__, props: { goal: OnboardingGoal }) => createSelector(
      () => props.goal,
      slice(state => state.tips),
      slice(state => state.goalTips),
      isGoalCompleted,
    )),
    areGoalsCompleted: () => createSelector(
      slice(state => state.tips),
      (tips): boolean => {
        const goals = getGoalsCompleted(tips);
        return goals[OnboardingGoal.NewClients] && goals[OnboardingGoal.ManageClients];
      },
    ),
    isSetupPaymentStepCompleted: () => createSelector(
      slice(state => state?.tips),
      (tips): boolean => tips?.[OnboardingTips.SetUpPayments],
    ),
    providerHasBankAccount: () => createSelector(
      slice(state => state?.hasBankAccount),
      (hasBankAccount): boolean => hasBankAccount,
    ),
    isWithinCreationTimeWindow: models => createSelector(
      models.providers.activeProvider,
      (activeProvider: PrivilegedProvider): boolean => {
        if (!activeProvider) {
          return false;
        }
        const creationTimeWindow = moment('2023-09-04');
        const providerCreationTime = moment(activeProvider.creation_time);
        const providerCreatedWithinWindow = providerCreationTime.isAfter(creationTimeWindow, 'day');
        return providerCreatedWithinWindow;
      },
    ),
    showNextBestActions: models => createSelector(
      slice(state => state.goal),
      (models as any).providerGoals.isWithinCreationTimeWindow,
      (goal, isWithinCreationTimeWindow: boolean): boolean => goal !== null
        && isWithinCreationTimeWindow,
    ),
    selectedGoal: () => createSelector(
      slice(state => state.goal),
      (goal): OnboardingGoal => goal,
    ),
  }),
});
