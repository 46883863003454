// @ts-strict-ignore
// CJ is a tool to track affilate network referrals.
// approach doc:
// https://styleseat.atlassian.net/wiki/spaces/EN/pages/1896415335/CJ+Tracking+-+Frontend
import localforage from 'localforage';

export type CJParams = {
  TYPE: string;
  CJEVENT: string;
};

export const storeParams = (cjParams: CJParams) => localforage.setItem('cjParams', cjParams);

export const getParams = (): Promise<CJParams> => (
  localforage.getItem('cjParams')
);

export const clearParams = () => storeParams({
  CJEVENT: '',
  TYPE: '',
});
