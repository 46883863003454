// @ts-strict-ignore
import {
  CHAT,
  CLIENT_APPTS_LIST_DEFAULT,
  CLIENT_APPT_STATUS_DETAILS,
  PROFILE_ROUTE,
  PROVIDER_GROWTH_REPORTING,
  PROVIDER_GROWTH_SUMMARY,
  PROVIDER_SETTINGS_ONLINE_BOOKING_DEEP_LINK,
  PRO_CALENDAR_VIEW_APPOINTMENT,
  PRO_SETTINGS_PLAN_SELECTION_DEEP_LINK,
  PRO_TAPTOPAY_ONBOARD_SETTINGS_DEEP_LINK,
  SEARCH_ROUTE,
} from '../../route-names';
import { RouteProps } from './types';

export const APPSFLYER_DEV_KEY = 'qnHjYCy8kR2xWVQ3hVsR9d';
export const APPSFLYER_APP_ID = '414313281';

// https://support.appsflyer.com/hc/en-us/articles/360014381317-SparkPost-integration-with-AppsFlyer
export const resolveDeepLinkURLs = [
  'link.styleseat.com',
  'link.styleseat.works',
  'spgo.io',
];

export enum DeepLinkType {
  ProviderId = 'provider_id',
  TapToPaySetup = 'ttp_setup',
  ChatId = 'chat_id',
  AppointmentList = 'appointment_list',
  ProviderStats = 'provider_stats',
  ProviderGrowthTabSummary = 'provider_growth_tab_summary',
  SearchPage = 'search',
  ProviderSettingsPlanSelection = 'provider_settings_plan_selection',
  ProviderSettingsOnlineBooking = 'provider_settings_online_booking',
  ClientIntakeForm = 'client_intake_form',
  ProviderViewIntakeForm = 'provider_view_intake_form',
  // Generic route with provider Id as a parameter
  Generic = 'generic',
}

/**
 * A mapping of DeepLinkType to RouteProps that
 * represents the destination parameters for each deep link type.
 * params = parameters names
 * params is an array of ordered named parameters for routes. It is used to
 * map the values that comes from the deeplink to the route parameters.
 * @type {Record<DeepLinkType, RouteProps>}
 */
export const deepLinkTypeToRouteProps: Record<DeepLinkType, RouteProps> = {
  // Simple routes with no parameters
  [DeepLinkType.TapToPaySetup]: { routeName: PRO_TAPTOPAY_ONBOARD_SETTINGS_DEEP_LINK },
  [DeepLinkType.AppointmentList]: { routeName: CLIENT_APPTS_LIST_DEFAULT },
  [DeepLinkType.ProviderSettingsPlanSelection]:
  { routeName: PRO_SETTINGS_PLAN_SELECTION_DEEP_LINK },
  [DeepLinkType.ProviderSettingsOnlineBooking]:
  { routeName: PROVIDER_SETTINGS_ONLINE_BOOKING_DEEP_LINK },

  // Routes with providerId parameter
  [DeepLinkType.ProviderId]: { routeName: PROFILE_ROUTE, paramKeys: ['providerId'] },
  [DeepLinkType.ProviderStats]: { routeName: PROVIDER_GROWTH_REPORTING, paramKeys: ['providerId'] },
  [DeepLinkType.ProviderGrowthTabSummary]: { routeName: PROVIDER_GROWTH_SUMMARY, paramKeys: ['providerId'] },

  // Routes with 2 parameters
  [DeepLinkType.ChatId]: {
    routeName: CHAT,
    paramKeys: [
      'chatId',
      'requiredRole',
    ],
  },
  [DeepLinkType.ClientIntakeForm]: {
    routeName: CLIENT_APPT_STATUS_DETAILS,
    paramKeys: [
      'id',
      'showIntakeForm',
    ],
  },

  // Routes with 3 parameters
  [DeepLinkType.ProviderViewIntakeForm]: {
    routeName: PRO_CALENDAR_VIEW_APPOINTMENT,
    paramKeys: [
      'providerId',
      'viewApptId',
      'showIntakeFormResponse',
    ],
  },
  [DeepLinkType.SearchPage]: {
    routeName: SEARCH_ROUTE,
    paramKeys: ['q'],
  },

  // Generic route with provider Id
  [DeepLinkType.Generic]: { routeName: undefined, paramKeys: undefined },
};
