// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import type { RootModel } from './models';

import nonCriticalException from '../modules/exceptionLogger';

import { TopProSummaryData, getProviderTopProStats } from '../api/Providers/TopPro';

import { attainedTopProThisMonth, parseTopProStats } from './ProviderTopPro.model.utils';

type TopProState = {
  stats: TopProSummaryData;
};

const model = createModel<RootModel>()({
  name: 'providerTopPro',

  state: {
    stats: null,
  } as TopProState,

  reducers: {
    onStatsLoaded: produce<TopProState, [TopProSummaryData]>((
      state: TopProState,
      stats: TopProSummaryData,
    ) => {
      state.stats = stats;
    }),
  },

  effects: dispatch => ({
    calculateStats: async ({ providerId }) => {
      try {
        const response: TopProSummaryData = await getProviderTopProStats(providerId);
        dispatch.providerTopPro.onStatsLoaded(response);
      } catch (err) {
        nonCriticalException(err);
        dispatch.providerTopPro.onStatsLoaded({ data: [] });
      }
    },
  }),

  selectors: (slice, createSelector) => ({
    stats() {
      return createSelector(
        slice((state: TopProState) => state.stats?.data),
        statsData => (statsData ? parseTopProStats(statsData) : null),
      );
    },
    attainedTopPro() {
      return createSelector(
        slice((state: TopProState) => state.stats?.data),
        statsData => (statsData ? attainedTopProThisMonth(statsData) : false),
      );
    },
    isTopPro() {
      return createSelector(
        slice((state: TopProState) => state.stats?.data),
        statsData => (statsData?.length > 1 ? statsData[1].top_pro : false),
      );
    },
  }),
});

export default model;
