// @ts-strict-ignore
import moment, { MomentInput } from 'moment';
import { DateRange } from 'moment-range';
import _ from 'underscore';
import type { ApiWorkPeriod, WeekdayOrdinal } from '../../../api/Providers/WorkPeriods';
import { DateString } from '../../../types';
import { Moment } from '../../dateUtils';
import {
  roundToNearestTimeslot,
  slotToDate,
  TimeSlotInput,
  timeToSlot,
} from '../../timeSlotUtils';

export type WorkPeriod = Omit<ApiWorkPeriod, 'id'>;

export type TimeRange = {
  start: Moment;
  end: Moment;
};

type DateTimeRange = TimeRange & {
  date: DateString;
};

type DateTimeOptionalRange = Partial<TimeRange> & {
  date: DateString;
};

export interface ITransformedWorkPeriod {
  id: number | string;
  formattedDate?: string;
  dayName: string;
  date: Moment;
  isAvailable?: boolean;
  hasBreak?: boolean;
  isInPast?: boolean;
  startTime: number; // time slot
  endTime: number; // time slot
  breakStartTime?: number; // time slot
  breakEndTime?: number; // time slot
}

export type ParsedWorkPeriods = {
  recurring: ApiWorkPeriod[];
  overrides: ApiWorkPeriod[];
};

const MIDNIGHT = '00:00:00';
const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_WITH_SECONDS = 'HH:mm:ss';
const TIME_WITHOUT_SECONDS = 'HH:mm';

export const mapWeekday = (day: number): WeekdayOrdinal => {
  const weekday = day - 1;
  if (weekday < 0) {
    return 6;
  }
  return weekday as WeekdayOrdinal;
};

const formatTime = time => {
  if (!time) {
    return MIDNIGHT;
  }
  return time.format(TIME_WITH_SECONDS);
};

/**
 * Create a date range for a work period on a given date
 */
function createDateRange(workPeriod, date: Moment): TimeRange {
  const start = moment(
    [
      date.format(DATE_FORMAT),
      workPeriod.start_time,
    ].join(),
    [
      DATE_FORMAT,
      TIME_WITH_SECONDS,
    ].join(),
  );

  const end = moment(
    [
      date.format(DATE_FORMAT),
      workPeriod.end_time,
    ].join(),
    [
      DATE_FORMAT,
      TIME_WITH_SECONDS,
    ].join(),
  );

  return { start, end };
}

/**
 * Check if two date ranges overlap, including outer bounds
 */
function dateRangesOverlap(range1: TimeRange, range2: TimeRange): boolean {
  return range1.start <= range2.end && range1.end >= range2.start;
}

/**
 * Return a new date range that combines two date ranges
 */
function combineDateRanges(range1: TimeRange, range2: TimeRange): TimeRange {
  const start = moment.min(range1.start, range2.start);
  const end = moment.max(range1.end, range2.end);
  return { start, end };
}

/**
 * Build a list of available date ranges on a given date
 * Note: assumes work periods are stored by start date
 */
function buildAvailability(
  workPeriods: WorkPeriod[],
  date: Moment,
  recurring: boolean = false,
): Array<TimeRange> {
  let ranges = [];

  workPeriods.forEach(workPeriod => {
    let dayOfWeek;
    const start = moment(workPeriod.start_date);
    const end = moment(workPeriod.end_date);

    if (start > date) {
      return false;
    }

    if (end >= date) {
      if (recurring) {
        dayOfWeek = (workPeriod.day_of_week + 1) % 7;
        if (date.day() !== dayOfWeek) return true;
      }
      ranges.push(createDateRange(workPeriod, date));
    }

    return true;
  });

  ranges = _.sortBy(ranges, 'start');

  return ranges.reduce((result, current) => {
    const prev = (result.length > 0 ? result[result.length - 1] : undefined);
    if (prev && dateRangesOverlap(prev, current)) {
      result.splice(-1, 1, combineDateRanges(prev, current));
    } else {
      result.push(current);
    }
    return result;
  }, []);
}

/**
 * Checks if a Moment's time is equal to midnight
 */
export function timeIsMidnight(time: Moment) {
  return time.format('HH:mm:ss') === MIDNIGHT;
}

/**
 * Get availability (working schedule) for a particular date
 *
 * @param date The date for which to retrieve availability
 * @param workPeriods Object containing workperiods:
 *  { recurring: Array<workperiods>, overrides: Array<workperiods> }
 * @returns Available start/end times for this date
 */
export const availabilityOn = (
  date: MomentInput,
  workPeriods: { overrides: Array<WorkPeriod>; recurring: Array<WorkPeriod> },
): Array<TimeRange> => {
  const formattedDate = moment(date);
  // First, build availability using work period overrides
  let availability = buildAvailability(workPeriods.overrides, formattedDate);
  // If there is no availability for this date, fall back to recurring workperiods
  if (availability.length === 0) {
    availability = buildAvailability(workPeriods.recurring, formattedDate, true);
  }

  return _.reject(availability, range => range.start.isSame(range.end));
};

/**
 * Check if availability includes a given date/time
 *
 * @param availability The availability for a given day
 * @param date A moment containing the time to check for availability
 * @returns True if there is availability, otherwise false
 */
export const availableOn = (availability: Array<TimeRange>, date: MomentInput): boolean => {
  const time = moment(date);
  if (!availability.length) {
    return false;
  }
  return availability.some(avail => time.isSameOrAfter(avail.start, 'minute') && time.isBefore(avail.end, 'minute'));
};

/**
 * Creates a workperiod object that will block
 * out an entire day
 * @param range Object with the following properties
 *  {String} date: the date for the workperiod in 'YYYY-MM-DD' format
 *  {Moment} start: the start time for the workperiod (ignored if allDay is true)
 *  {Moment} end: the end time for the workperiod (ignored if allDay is true)
 * @param   allDay Whether to block off the entire day
 * @returns Workperiod object
 */
export const createWorkPeriod = (range: DateTimeOptionalRange, allDay?: boolean): WorkPeriod => {
  const day = moment(range.date, DATE_FORMAT);
  let startTime;
  let endTime;

  if (allDay) {
    startTime = MIDNIGHT;
    endTime = MIDNIGHT;
  } else {
    startTime = formatTime(range.start);
    endTime = formatTime(range.end);
  }

  return {
    day_of_week: mapWeekday(day.day()),
    start_time: startTime,
    end_time: endTime,
    priority: 1,
    start_date: day.format(DATE_FORMAT) as DateString,
    end_date: day.format(DATE_FORMAT) as DateString,
  };
};

const createFirstWorkPeriod = (
  date: DateString,
  workPeriod: TimeRange,
  breakPeriod: TimeRange,
): DateTimeRange => ({
  date,
  start: moment(workPeriod.start, TIME_WITHOUT_SECONDS),
  end: moment(breakPeriod.start, TIME_WITHOUT_SECONDS),
});

const createSecondWorkPeriod = (
  date: DateString,
  workPeriod: TimeRange,
  breakPeriod: TimeRange,
): DateTimeRange => ({
  date,
  start: moment(breakPeriod.end, TIME_WITHOUT_SECONDS),
  end: moment(workPeriod.end, TIME_WITHOUT_SECONDS),
});

const createWorkPeriodWithBreak = (
  date: DateString,
  workPeriod: TimeRange,
  breakPeriod: TimeRange,
): Array<WorkPeriod> => [
  createWorkPeriod(createFirstWorkPeriod(date, workPeriod, breakPeriod)),
  createWorkPeriod(createSecondWorkPeriod(date, workPeriod, breakPeriod)),
];

/**
 * Creates list of workperiods to be consumed by backend
 * @param   {Array} workPeriods List of workperiods used by UI
 * @returns {Array}             List of workperiods for backend
 */
export const mapWorkPeriodsForBackend = (
  workPeriods: Array<ITransformedWorkPeriod>,
) => _.flatten(workPeriods.map(workPeriod => {
  if (!workPeriod.isAvailable) {
    return createWorkPeriod({
      date: workPeriod.date.format(DATE_FORMAT) as DateString,
    }, true);
  }
  if (!workPeriod.hasBreak) {
    return createWorkPeriod({
      date: workPeriod.date.format(DATE_FORMAT) as DateString,
      start: slotToDate(workPeriod.startTime, workPeriod.date),
      end: slotToDate(workPeriod.endTime, workPeriod.date),
    }, false);
  }

  return createWorkPeriodWithBreak(
    workPeriod.date.format(DATE_FORMAT) as DateString,
    {
      start: slotToDate(workPeriod.startTime, workPeriod.date),
      end: slotToDate(workPeriod.endTime, workPeriod.date),
    },
    {
      start: slotToDate(workPeriod.breakStartTime, workPeriod.date),
      end: slotToDate(workPeriod.breakEndTime, workPeriod.date),
    },
  );
}));

export function parseWorkPeriods(workPeriods: WorkPeriod[]): ParsedWorkPeriods {
  const overrides = [];
  const recurring = [];
  _.each(workPeriods, workPeriod => {
    if (workPeriod.priority === 1) {
      overrides.push(workPeriod);
    } else if (workPeriod.priority === 2) {
      recurring.push(workPeriod);
    }
  });

  return {
    recurring: _.sortBy(recurring, 'start_date'),
    overrides: _.sortBy(overrides, 'start_date'),
  };
}

/**
 * Creates day object used to populate the UI
 * @param   {Object} day  Moment object
 * @returns {Object}      Object consumed by UI
 */
function makeDay(
  day: Moment,
  workPeriods: ParsedWorkPeriods,
  now: Moment,
): ITransformedWorkPeriod {
  const availabilities = availabilityOn(day, workPeriods);

  let start: MomentInput = '09:00';
  let end: MomentInput = '18:00';
  let breakStart: TimeSlotInput | null = null;
  let breakEnd: TimeSlotInput | null = null;

  if (availabilities.length) {
    start = roundToNearestTimeslot(availabilities[0].start);
    end = roundToNearestTimeslot(availabilities[availabilities.length - 1].end);

    if (availabilities.length > 1) {
      breakStart = roundToNearestTimeslot(availabilities[0].end);
      breakEnd = roundToNearestTimeslot(availabilities[1].start);
    }
  }

  return {
    id: day.format('YYYY-MM-DD'),
    formattedDate: day.format('MMM D, YYYY'),
    dayName: day.format('dddd'),
    date: day,
    breakStartTime: timeToSlot(breakStart),
    breakEndTime: timeToSlot(breakEnd),
    startTime: timeToSlot(start),
    endTime: timeToSlot(end),
    isAvailable: availabilities.length > 0,
    hasBreak: availabilities.length > 1,
    isInPast: now.isAfter(day, 'day'),
  };
}

export function mapWorkPeriodsToTimeRanges<T = any>(
  workPeriods: ParsedWorkPeriods,
  rangeCache: Record<string, ITransformedWorkPeriod & T> | null,
  buildExtraAttributes: (item: ITransformedWorkPeriod) => T,
  startDate: Moment,
  endDate: Moment,
  now: Moment = moment(),
): Array<ITransformedWorkPeriod & T> {
  const results: Array<ITransformedWorkPeriod & T> = [];
  const workDays = [...(new DateRange(startDate, endDate)).by('days')];

  workDays.forEach((weekday: Moment) => {
    const dayString = weekday.format('YYYY-MM-DD');

    if (rangeCache?.[dayString]) {
      results.push(rangeCache[dayString]);
    } else {
      const newDay = makeDay(weekday, workPeriods, now);
      results.push({
        ...newDay,
        ...buildExtraAttributes(newDay),
      });
    }
  });

  return results;
}
