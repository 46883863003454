import React from 'react';
import {
  DimensionValue,
  Text,
  TextProps,
  TextStyle,
} from 'react-native';

import './BodyText.module.scss';

export type BodyTextAppearance =
  | 'x-large'
  | 'large'
  | 'larger'
  | 'normal'
  | 'medium'
  | 'small'
  | 'smaller'
  | 'smaller-underline'
  | 'legal'
  | 'link'
  | 'small-link'
  | 'small-link-lowercase'
  | 'medium-link-lowercase'
  | 'normal-link-lowercase';

export type BodyTextWeight = 'thin' | 'normal' | 'bold' | 'medium' | 'extra-bold' | 'heavy';

export type TextAlignOptions = 'auto' | 'left' | 'right' | 'center' | 'justify';

export interface IBodyTextProps extends TextProps {
  /** The level of the header */
  appearance?: BodyTextAppearance;
  /** @deprecated We're standardizing on the client theme */
  theme?: 'client' | 'provider';
  /** Apply a font-weight to the text */
  weight?: BodyTextWeight;
  /** How to align the text */
  textAlign?: TextAlignOptions;
  /** A color to override the default text color */
  color?: string;
  /** @deprecated Use weight prop 'bold' instead */
  emphasize?: boolean;
  whiteSpace?: string;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'full-width' | 'full-size-kana';
  textDecoration?: 'overline' | 'line-through' | 'underline' | 'strike-through';
  style?: TextStyle & {
    width?: DimensionValue | number | string;
    textOverflow?: string | 'clip' | 'ellipsis';
    whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
  };
}

/**
 * Allows for display of body / description text using a particular client or provider header level
 * based on design guidelines available here (for client)
 * https://styleseat.atlassian.net/wiki/spaces/PROD/pages/78807073/Client+Side+Design+Guidelines
 *
 * and here (for provider)
 * https://app.zeplin.io/project/576dbcd42bbb9162542be76d/screen/5aeca01bbea3aab2be02d69e
 *
 * The body text component *MUST* be enclosed in a View component
 *
 * To translate design font sizes to text size, use this quick table: small: 12px normal: 14px
 *
 * @param {object} restProps
 * @param {string} [restProps.appearance = 'normal']
 * @param {boolean} [restProps.emphasize = false] Deprecated; use Weight instad
 * @param {string} [restProps.theme = 'client'] Deprecated; provider theme is doing away
 */
const BodyText: React.FCWithChildren<IBodyTextProps> = ({
  children,
  appearance,
  emphasize,
  theme,
  weight,
  style,
  textAlign,
  color,
  whiteSpace,
  textTransform,
  textDecoration,
  testID,
  ...restProps
}) => {
  let emphasis = weight || 'normal';

  if (!weight && emphasize) {
    emphasis = 'bold';
  }

  return (
    <Text
      styleName={`${theme}-${appearance} ${emphasis}`}
      className={restProps.className}
      style={[
        style,
        {
          textAlign: textAlign || style?.textAlign,
          textTransform: textTransform || style?.textTransform,
          // @ts-expect-error This will need work when moving to RN support
          textDecorationLine: textDecoration || style?.textDecoration,
          color: color || style?.color,
          borderColor: color || style?.color,
          whiteSpace: whiteSpace || style?.whiteSpace,
        } as TextStyle,
      ]}
      testID={testID}
      {...restProps}
    >
      {children}
    </Text>
  );
};

export const APPEARANCE_OPTIONS = [
  'x-large', // font-size:36px / line-height: 24px
  'larger', // font-sze: 24px (pro only)
  'large', // font-size: 18px
  'normal', // font-size: 14px / line-height: 20px
  'medium', // font-size: 12px / line-height: 18px
  'small', // font-size: 12px / line-height: 14px
  'smaller', // font-size: 10px
  'smaller-underline', // font-size: 10px
  'legal', // font-size: 11px
  'link', // font-size: 15px
  'small-link', // font-size: 10px
  'small-link-lowercase', // font-size: 12px
  'medium-link-lowercase', // font-size: 12px / line-height: 18px
  'normal-link-lowercase', // font-size: 12px / line-height: 20px
];

export const WEIGHT_OPTIONS = [
  'thin',
  'normal',
  'bold',
  'extra-bold',
  'heavy',
  'medium',
  undefined,
];

export const THEME_OPTIONS = [
  'client',
  'provider',
];

export const TEXT_ALIGN_OPTIONS = [
  'auto',
  'left',
  'right',
  'center',
  'justify',
];

export const TEXT_TRANSFORM_OPTIONS = [
  undefined,
  'none',
  'capitalize',
  'uppercase',
  'lowercase',
  'full-width',
  'full-size-kana',
];

export const TEXT_DECORATION_OPTIONS = [
  undefined,
  'overline',
  'line-through',
  'underline',
];

BodyText.defaultProps = {
  appearance: 'normal',
  emphasize: false,
  theme: 'client',
  weight: undefined,
  style: undefined,
  textAlign: undefined,
  color: undefined,
  whiteSpace: undefined,
  textTransform: undefined,
  textDecoration: undefined,
};

export default BodyText;
