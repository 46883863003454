// @ts-strict-ignore
import produce from 'immer';
import moment, { MomentInput } from 'moment';
import { createModel } from '@rematch/core';
import { DateRange } from 'moment-range';
import { ssFetchJSON } from '../modules/ssFetch';
import SimpleCache from '../modules/SimpleCache';
import camelCase from '../modules/camelCase';
import { selectors as routeSelectors } from './RouteState.model';
import { orderedRange } from '../modules/dateRangeUtils';
import type { RootModel } from './models';
import { DateTimeStamp } from '../types/dateTime';

export const cache = new SimpleCache(12 * 60 * 60);

export const dateFormat = 'YYYY-MM-DD';

interface NetEarningsResponse {
  appointments_count: number;
  checked_out_appointments_count: number;
  fees_refunded: string;
  gross_earnings: string;
  growth_and_processing_fees: string;
  loan_paydowns: string;
  lmc_fee: string;
  net_earnings: string;
  net_revenue: string;
  new_client_delivery_fee: string;
  premium_appointment_fee: string;
  processing_fee: string;
  refunds: string;
  smart_pricing_fee: string;
  tax: string;
  tip: string;
  total_collected: string;
  total_collected_card: string;
  total_collected_other: string;
}

export interface NetEarningsReport {
  appointmentsCount: number;
  checkedOutAppointmentsCount: number;
  feesRefunded: string;
  grossEarnings: string;
  growthAndProcessingFees: string;
  loanPaydowns: string;
  netEarnings: string;
  netRevenue: string;
  newClientDeliveryFee: string;
  premiumAppointmentFee: string;
  processingFee: string;
  refunds: string;
  smartPricingFee: string;
  tax: string;
  tip: string;
  totalCollected: string;
  totalCollectedCard: string;
  totalCollectedOther: string;
}

export interface NetEarningsReportRange {
  start: DateTimeStamp;
  end: DateTimeStamp;
}

export interface FetchPayload {
  providerId?: number | null;
  reportRange: {
    start: MomentInput;
    end: MomentInput;
  };
}

interface NetEarningsState {
  /**
   * Whether the net earnings report is currently being fetched.
   */
  isFetching: boolean;
  /**
   * Any errors incurred while fetching the report.
   */
  errors: any[];
  /**
   * The fetched net earnings report data.
   */
  report: NetEarningsReport | null;
  /**
   * The moment.range() used to fetch the report
   */
  reportRange: NetEarningsReportRange;
}

const defaultState = (): NetEarningsState => ({
  isFetching: false,
  errors: null,
  report: null,
  reportRange: {
    start: moment().startOf('day').toISOString(),
    end: moment().endOf('day').toISOString(),
  },
});

const NetEarnings = createModel<RootModel>()({
  state: defaultState(),

  reducers: {
    /**
     * Called when a fetch operation has started
     * @returns {payload} date range to fetch
     */
    fetchStart: produce<NetEarningsState, [NetEarningsReportRange]>((
      state,
      payload: NetEarningsReportRange,
    ) => {
      state.reportRange = payload;
      state.isFetching = true;
      state.errors = null;
      state.report = null;
    }),

    /**
     * Called after a successful fetch operation
     * @param state
     * @param payload
     * @returns {object} new state
     */
    fetchSuccess: produce<NetEarningsState, [NetEarningsResponse]>((state, payload) => {
      // Cast snake_case to camelCase
      state.isFetching = false;
      state.report = camelCase(payload);
    }),

    /**
     * Called when a fetch operation failed
     * so that it sets the errors in the store
     * @param state
     * @param payload
     * @returns {object} new state
     */
    fetchFailure: produce<NetEarningsState, [any[]]>((state, payload) => {
      state.isFetching = false;
      state.errors = payload;
    }),
  },

  effects: dispatch => ({
    /**
     * Fetches the provider's net earnings report from the API
     * @param providerId - the provider to fetch stats for
     *    (defaults to state.route.params.providerId)
     * @param reportRange - the moment.range() to fetch stats for
     * @param state - the redux model state
     * @returns {Promise<{report: {}, isFetching: boolean, errors: *}>}
     */
    fetch: async ({ reportRange, providerId = null }: FetchPayload, state) => {
      const proId = providerId || routeSelectors.getProviderId(state);
      if (!proId) {
        throw new Error('Cannot fetch report, no providerId provided.');
      }
      if (!reportRange) {
        throw new Error('Cannot fetch report, no reportRange provided.');
      }
      const range = orderedRange(
        moment.min(moment(), moment(reportRange.start)),
        moment.min(moment(), moment(reportRange.end)),
      );
      const start = range.start.format(dateFormat);
      const end = range.end.format(dateFormat);
      dispatch.netEarnings.fetchStart({
        start: range.start.toISOString(),
        end: range.end.toISOString(),
      });
      const url = `/api/v2/providers/${proId}/earnings_report/${start}/${end}`;
      const cutoff = moment().subtract(3, 'days').format(dateFormat);
      const opts = {
        method: 'GET',
        // Don't cache data for the past 3 days, since those calculations are subject to change.
        ssCache: end >= cutoff ? null : cache,
        throwOnHttpError: true,
      };
      await ssFetchJSON(url, opts)
        .then(dispatch.netEarnings.fetchSuccess)
        .catch(dispatch.netEarnings.fetchFailure);
    },
  }),

  selectors: (slice, createSelector) => ({
    reportDateRange: () => createSelector(
      slice(s => s.reportRange),
      (range: NetEarningsReportRange): DateRange => (
        new DateRange(moment(range.start), moment(range.end))
      ),
    ),
  }),
});

export default NetEarnings;

export const selectors = {
  getReport: state => state?.netEarnings?.report,
};
