import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const SearchClick: SegmentEventData = {
  eventName: AnalyticEvent.SearchResultClicked,
  eventNameLegacy: 'search_click',
  eventProperties: [
    'initial_query',
    'provider_average_rating',
    'provider_estimated_service_category',
    'provider_name',
    'provider_profile_photo',
    'provider_salon_name',
    'provider_service_name',
    'provider_url',
  ],
};

export default SearchClick;
