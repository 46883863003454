import { IPaymentSettingsV3, VerificationStatus } from './provider/PaymentSettings.types';

/**
 * Model representing a pro's payment state.
 *
 * The config object passed to this model comes from
 * ProviderPaymentConfigSerializer.
 */
export default class PaymentsState {
  /**
   * Constant representing the steps required for payments onboarding.
   */
  static ONBOARDING_STEPS = {
    BANK: 2,
    IDENTITY: 3,
    ADDRESS: 4,
  };

  /**
   * Constant mapping steps in ONBOARDING_STEPS to strings for display.
   */
  static ONBOARDING_STEPS_NAMES = {
    2: 'BANK ACCOUNT INFORMATION',
    3: 'VERIFYING YOUR IDENTITY',
    4: 'MAILING ADDRESS',
  };

  config: IPaymentSettingsV3;

  /**
   * @param {Object} config - Response from payment config endpoint.
   */
  constructor(config: IPaymentSettingsV3) {
    this.config = config;
  }

  get onboardingSteps(): Array<number> {
    const steps = PaymentsState.ONBOARDING_STEPS;
    const onboardingSteps = [];
    if (this.config.payments_onboarding_steps.indexOf('identity') >= 0) {
      onboardingSteps.push(steps.IDENTITY);
    }
    if (this.config.payments_onboarding_steps.indexOf('address') >= 0) {
      onboardingSteps.push(steps.ADDRESS);
    }
    if (this.config.payments_onboarding_steps.indexOf('bank') >= 0) {
      onboardingSteps.push(steps.BANK);
    }
    return onboardingSteps;
  }

  /**
   * Next onboarding step number.
   */
  get nextOnboardingStep(): number {
    return this.onboardingSteps[0] || PaymentsState.ONBOARDING_STEPS.IDENTITY;
  }

  /**
   * Onboarding is complete and Stripe is ready to process.
   *
   * @returns {boolean}
   */
  isEnabled(): boolean {
    return !!this.config.autocharge_approved;
  }

  /**
   * Onboarding is complete.
   *
   * @returns {boolean}
   */
  isOnboarded(): boolean {
    return !!this.config.autocharge_approved;
  }

  /**
   * Has pro selected a no-show/cancellation policy?
   * Zero is the default (no policy).
   *
   * @returns {boolean}
   */
  hasNSLCPolicy(): boolean {
    return this.config.cancellation_policy !== 0;
  }

  /**
   * Does the pro have a bank account?
   *
   * @returns {boolean}
   */
  hasBankAccount(): boolean {
    return !!this.config.bank_account;
  }

  /**
   * Get last 4 digits for bank account, if bank account exists.
   *
   * @returns {string|null}
   */
  getBankAccountLast4(): string | null {
    return this.config.bank_account && this.config.bank_account.last4;
  }

  /**
   * Get the current status of identity verification.
   *
   * @returns {VerificationStatus} current status
   */
  getIdentityVerificationStatus(): VerificationStatus | null {
    return this.config.identity_verification?.status || null;
  }

  /**
   * Has the pro submitted identity verification?
   *
   * @returns {boolean}
   */
  hasIdentityVerification(): boolean {
    return this.getIdentityVerificationStatus() !== VerificationStatus.EMPTY;
  }

  /**
   * Is the pro in probation?
   *
   * @returns {boolean}
   */
  inProbation(): boolean {
    return this.config.identity_verification?.in_probation || false;
  }

  /**
   * Does the pro have an address?
   * @return {boolean}
   */
  hasAddress(): boolean {
    return this.config.identity_verification?.has_address || false;
  }

  /**
   * Did pro flip into an unverified state?
   *
   * @returns {boolean}
   */
  isUnverified(): boolean {
    return this.isOnboarded()
      && this.getIdentityVerificationStatus() === VerificationStatus.UNVERIFIED;
  }

  /**
   * Has the onboarding flow been started?
   *
   * @returns {boolean}
   */
  hasStartedOnboarding(): boolean {
    return this.onboardingSteps.length < Object.keys(
      PaymentsState.ONBOARDING_STEPS,
    ).length;
  }

  /**
   * Is setting up payments mandatory for the pro to book an appointment?
   * Looks at the pro's plan and current payments state.
   *
   * @returns {boolean}
   */
  isPaymentsRequiredToBook(): boolean {
    return this.config.is_payments_setup_mandatory;
  }

  /**
   * Is the pro currently on a commission plan?
   *
   * @returns {boolean}
   */
  isOnCommissionPlan(): boolean {
    return this.config.on_commission_plan;
  }

  /**
   * The number of steps remaining in the onboarding flow.
   *
   * @returns {number}
   */
  get stepsRemainingCount(): number {
    return this.onboardingSteps.length;
  }

  /**
   * Get the next onboarding step from a given step number.
   *
   * @param {number} step
   * @returns {null|number} next step number or null if no steps
   */
  getNextOnboardingStep(step: number): number | null {
    if (this.onboardingSteps.length === 1) {
      return null;
    }
    const index = this.onboardingSteps.indexOf(step);
    return this.onboardingSteps[index + 1] || null;
  }
}
