import { ChannelType } from './types';

export const REMOVE_NAME_FIELDS_TEST_NAME = 'provider_signup_remove_name_fields_102820';
export const REMOVE_NAME_FIELDS_TEST_PERCENT = 0.5;

export const SIGNUP_MARKETING_TEST_FLAG = 'pro_signup_marketing_ev3044_20201105';
export const SIGNUP_MARKETING_TEST_PERCENT = 0.5;

export const REMOVE_SOCIAL_SSO_OPTIONS = 'pro_signup_remove_social_sso_fly4030_20230726';

export const CHANNEL_OPTIONS = [
  ChannelType.ReferredByPro,
  ChannelType.ReferredByClient,
  ChannelType.Instagram,
  ChannelType.Facebook,
  ChannelType.Pinterest,
  ChannelType.Google,
  ChannelType.AppStore,
];
