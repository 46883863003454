// @ts-strict-ignore
import { ssFetchJSON } from '../../modules/ssFetch';
import { DateTimeStamp } from '../../types/dateTime';

export interface APISubscriptionCoupon {
  amount_off: number;
  currency: string;
  duration_in_months: number | null;
  duration: 'once' | 'repeating' | 'forever';
  external_created: DateTimeStamp;
  external_id: string;
  max_redemptions: number | null;
  metadata: Record<string, any>;
  percent_off: number | null;
  redeem_by: DateTimeStamp;
  times_redeemed: number;
  valid: boolean;
}

export type SubscriptionCoupon = Pick<APISubscriptionCoupon, 'amount_off' | 'duration_in_months' | 'metadata'>;

/**
 * Apply a Subscription Coupon to a Pro
 *
 * @param {number} providerId
 * @param {string} couponId
 * @returns {Promise<APISubscriptionCoupon>}
 */
export async function applySubscriptionCoupon(
  providerId: number,
  couponId: string,
): Promise<APISubscriptionCoupon> {
  const response = await ssFetchJSON<APISubscriptionCoupon>(
    `/api/v1/providers/${providerId}/subscription-coupon/${couponId}`,
    {
      method: 'POST',
    },
  );
  return response;
}

/**
 * Fetch a Subscription Coupon for a Pro
 *
 * @param {number} providerId
 * @param {string | null} couponId
 * @returns {Promise<APISubscriptionCoupon>}
 */
export async function fetchSubscriptionCoupon(
  providerId: number,
  couponId: string | undefined,
): Promise<APISubscriptionCoupon> {
  const baseUrl = `/api/v1/providers/${providerId}/subscription-coupon/${couponId ?? ''}`;

  const response = await ssFetchJSON<APISubscriptionCoupon>(
    baseUrl,
    {
      method: 'GET',
    },
  );
  return response;
}

/**
 * Fetch a Subscription Coupon by ID
 *
 * @param {string} couponId
 * @returns {Promise<APISubscriptionCoupon>}
 */
export async function fetchCouponById(couponId: string) {
  if (!couponId) return null;

  const response = await ssFetchJSON<APISubscriptionCoupon>(
    `/api/v1/coupons/${couponId}`,
    {
      method: 'GET',
    },
  );

  return response;
}

/**
 * Fetch a Subscription Coupon
 *
 * @param {number} providerId
 * @param {string | undefined} couponId
 * @returns {Promise<APISubscriptionCoupon>}
 */
export async function fetchCoupon(
  providerId: number,
  couponId: string | undefined,
): Promise<APISubscriptionCoupon> {
  if (!providerId) return fetchCouponById(couponId);

  return fetchSubscriptionCoupon(providerId, couponId);
}
