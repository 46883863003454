import { useDispatch } from 'react-redux';

/**
 * Hook to use Rematch model effects.
 *
 * @example: Using RouteState.go
 * import { RootDispatch } from '[path-to]/store';
 * import useRematchDispatch from '[path-to]/hooks/useRematchDispatch';
 * ...
 * const { go } = useRematchDispatch(dispatch: RootDispatch) => ({ go: dispatch.route.go });
 * ...
 * go({ route, params, notify });
 *
 * type D is the redux dispatch
 * type MD is the rematch dispatch
*/
export default <D extends {}, MD>(selector: (dispatch: D) => MD) => {
  const dispatch = useDispatch<D>();
  return selector(dispatch);
};
