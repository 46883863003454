// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { ModelSelectorFactories } from '@rematch/select';
import produce from 'immer';
import moment from 'moment';
import _ from 'underscore';
import { PrivilegedProvider, PublicProvider } from '../../api/Providers';
import {
  getProAppointmentList,
  NCDState,
  ProAppointment,
  ProAppointmentDetails,
} from '../../api/Providers/Appointments';
import { fetchFraudStatus, FraudResponse } from '../../api/Providers/Fraud';
import { fetchAllScheduledAppointments, ScheduleResponse } from '../../api/Providers/Schedules/Scheduled';
import * as SignupSettings from '../../api/Providers/SignupSettings';
import { IWaitlistItem } from '../../api/Providers/Waitlist';
import {
  createExternalCalendar,
  getExternalCalendars,
  getGoogleCredential,
  getRemoteGoogleCalendars,
  GoogleCalendar,
  GoogleCredential,
  updateExternalCalendar,
} from '../../api/Users/GoogleCalendar';
import nonCriticalException from '../../modules/exceptionLogger';
import {
  isCancelled,
  isCheckedOutLateCancel,
  isClientAppt,
} from '../../modules/ProAppointmentState';
import {
  compareAppointmentsByStartTime,
  getNotCancelledAppointments,
  keyAppointmentsById,
  keyByDate,
} from '../../modules/provider/Appointments';
import {
  CalendarModeOptions,
  getDateWithoutTime,
  getPromoAppointments,
  getPromoData,
  getRangeLoadSettings,
  parseSelectedDateFromParams,
  PromoAppointment,
  PromoAppointmentsData,
} from '../../modules/provider/Calendar';
import {
  CalendarSyncStatus,
  determineSyncStatus,
  mapSyncStatus,
  MergedCalendar,
  mergeExternalCalendars,
} from '../../modules/provider/Calendar/ExternalCalendar';
import { ParsedWorkPeriods } from '../../modules/provider/WorkPeriod';
import { DateString } from '../../types';
import type { RootModel } from '../models';
import { NCDExperience, checkShouldAdjudicateNCD } from '../../modules/provider/Appointments/NCD';
import { ADJUDICATED_APPOINTMENTS } from '../NCDC/constants';
import { NCC_HUB_SWITCH } from '../../components/provider/today/NCCHub/constants';

export type CalendarAppointment = (ProAppointment | ProAppointmentDetails) & {
  ncdExperience?: NCDExperience;
  isNCDExperienceQualified?: boolean;
};

export type CalendarAppointments = {
  // Calendar loads ProAppointment, but can be updated with ProAppointmentDetails from
  // the ProviderManageAppointment model.
  // It is not guaranteed to have any ProAppointmentDetails properties.
  [id: number]: CalendarAppointment;
};

export type CalendarData = {
  provider: PrivilegedProvider | PublicProvider;
  selectedDate: DateString;
  selectedAppt: CalendarAppointment | null;
  mode: CalendarModeOptions;
  loaded: boolean;
  error: boolean;
  waitlist: IWaitlistItem[];
  workPeriods: ParsedWorkPeriods;
};

interface ExternalCalendarData {
  mergedCalendars: MergedCalendar[];
  loading: boolean;
  fetched: boolean;
  googleCredential: GoogleCredential | null;
  syncStatusById: Record<number, CalendarSyncStatus>;
}

export interface ExternalCalendarStatusListItem {
  id: number | string;
  summary: string;
  isSyncing: boolean;
  isUnsyncing: boolean;
  isSynced: boolean;
  shouldReceiveEvents: boolean;
  fullCalendar: MergedCalendar;
}

export type ProviderCalendarState = {
  providerId: number | null;
  loaded: boolean;
  error: boolean;
  promoData: PromoAppointmentsData & { loading: boolean };
  externalCalendars: ExternalCalendarData;
  hasAppointments: boolean;
  appointments: CalendarAppointments;
  fraudStatuses: FraudResponse[];
  schedule: ScheduleResponse[];
};

type OnLoadPayload = {
  providerId: number;
};

type LoadUpcomingForProvider = {
  providerId: number;
  days?: number;
};

type OnLoadUpcomingForProvider = {
  providerId: number;
};

type LoadSchedulePayload = {
  providerId: number;
  month: string;
};

type LoadPromoDataPayload = {
  providerId: number;
};

type LoadFraudStatusPayload = {
  providerId: number;
};

type OnLoadedPromoDataPayload = {
  provider: PrivilegedProvider;
  hasSeenNCDPromo: boolean;
  hasSeenSPPromo: boolean;
  hasSeenLMCPromo: boolean;
};

type OnFraudStatusLoadedPayload = {
  providerId: number;
  statuses: FraudResponse[];
};

type OnScheduleLoadedPayload = {
  providerId: number;
  schedule: ScheduleResponse[];
};

type OnCancellationPayload = {
  appointmentId: number;
};

type OnListPageLoadedPayload = {
  appointments: ProAppointment[];
};

type LoadRangePayload = {
  providerId: number;
  startDate: string;
  days?: number;
  page?: number;
  size?: number;
};

interface LoadExternalCalendarsPayload {
  providerId: number;
  userId: number;
}

interface RefreshExternalCalendarsPayload {
  userId: number;
}

interface SetExternalCalendarSyncPayload {
  providerId: number;
  calendar: MergedCalendar;
  shouldReceiveEvents: boolean;
}

interface OnLoadExternalCalendarsStartPayload {
  providerId: number;
}

type OnLoadExternalCalendarsCompletePayload = Pick<ExternalCalendarData, 'googleCredential' | 'mergedCalendars'>;

interface OnRefreshExternalCalendarsPayload {
  calendars: GoogleCalendar[];
}

interface OnSetExternalCalendarSyncPayload {
  calendar: MergedCalendar;
  shouldReceiveEvents: boolean;
}

type AppointmentsList = (CalendarAppointment | PromoAppointment)[];

const todayDateForPromo = getDateWithoutTime(new Date());

const appointmentUpdatedReducer = produce<ProviderCalendarState, [
  { appointment: ProAppointmentDetails },
]>(
  (
    state: ProviderCalendarState,
    { appointment }: { appointment: ProAppointmentDetails },
  ) => {
    // only update the appointment if it's for the current provider
    if (appointment.provider === state.providerId) {
      state.appointments ??= {};
      state.appointments[appointment.id] = appointment;
    }

    return state;
  },
);

const checkAnyCalendarUpdating = (state: ProviderCalendarState): boolean => (
  Object.values(state.externalCalendars.syncStatusById).some(status => (
    status === CalendarSyncStatus.Syncing
    || status === CalendarSyncStatus.Unsyncing
  ))
);

function getInitialState(): ProviderCalendarState {
  return {
    providerId: null,
    appointments: {},
    loaded: false,
    error: false,
    fraudStatuses: null,
    promoData: {
      loading: false,
      provider: null,
      hasSeenNCDPromo: null,
      hasSeenSPPromo: null,
      hasSeenLMCPromo: null,
    },
    externalCalendars: {
      loading: false,
      fetched: false,
      mergedCalendars: [],
      syncStatusById: {},
      googleCredential: null,
    },
    hasAppointments: false,
    schedule: [],
  };
}

export const providerCalendar = createModel<RootModel>()({
  name: 'providerCalendar',

  state: getInitialState(),

  reducers: {
    'user/logout': () => getInitialState(),

    onLoad: (state: ProviderCalendarState, {
      providerId,
    }: OnLoadPayload): ProviderCalendarState => ({
      ...state,
      providerId,
      // if we're re-landing with a different provider, clear the previous appointments
      appointments: providerId === state.providerId ? state.appointments : {},
      promoData: { ...state.promoData, loading: true },
    }),

    onLoadUpcomingForProvider: (state: ProviderCalendarState, {
      providerId,
    }: OnLoadUpcomingForProvider): ProviderCalendarState => ({
      ...state,
      providerId,
    }),

    onLoaded: (
      state: ProviderCalendarState,
    ): ProviderCalendarState => ({
      ...state,
      loaded: true,
    }),

    onFailed: (
      state: ProviderCalendarState,
    ) => ({
      ...state,
      error: true,
      loaded: true,
    }),

    onClearAppointments: (state: ProviderCalendarState): ProviderCalendarState => ({
      ...state,
      appointments: {},
    }),

    onLoadedPromoData: (
      state: ProviderCalendarState,
      payload: OnLoadedPromoDataPayload,
    ): ProviderCalendarState => {
      if (payload?.provider?.id !== state.providerId) {
        return state;
      }
      return ({
        ...state,
        promoData: { ...payload, loading: false },
      });
    },

    onListPageLoaded: (
      state: ProviderCalendarState,
      payload: OnListPageLoadedPayload,
    ): ProviderCalendarState => {
      const apptsById = keyAppointmentsById(payload.appointments);

      return ({
        ...state,
        hasAppointments: state.hasAppointments || payload.appointments?.length > 0,
        appointments: {
          ...state.appointments,
          ...apptsById,
        },
      });
    },

    // respond to updates to any appointments
    'providerManageAppointment/onCreated': appointmentUpdatedReducer,
    'providerManageAppointment/onUpdated': appointmentUpdatedReducer,
    'providerManageAppointment/onRescheduled': appointmentUpdatedReducer,

    'providerManageAppointment/onCancellation': produce<ProviderCalendarState, [OnCancellationPayload]>(
      (
        state: ProviderCalendarState,
        { appointmentId }: OnCancellationPayload,
      ): ProviderCalendarState => {
        delete state.appointments[appointmentId];
        return state;
      },
    ),

    onLoadExternalCalendarsStart: produce<ProviderCalendarState, [
      OnLoadExternalCalendarsStartPayload,
    ]>((
      state: ProviderCalendarState,
      payload: OnLoadExternalCalendarsStartPayload,
    ) => {
      state.providerId = payload.providerId;
      state.externalCalendars.loading = true;
    }),

    onLoadExternalCalendarsComplete: (
      state: ProviderCalendarState,
      payload: OnLoadExternalCalendarsCompletePayload,
    ): ProviderCalendarState => ({
      ...state,
      externalCalendars: {
        ...payload,
        loading: false,
        fetched: true,
        syncStatusById: mapSyncStatus(payload.mergedCalendars),
      },
    }),

    onRefreshExternalCalendars: produce<ProviderCalendarState, [
      OnRefreshExternalCalendarsPayload,
    ]>((
      state: ProviderCalendarState,
      { calendars }: OnRefreshExternalCalendarsPayload,
    ) => {
      const calendarLookup: Record<number | string, GoogleCalendar> = {};

      calendars.forEach(calendar => {
        calendarLookup[calendar.id] = calendar;
        calendarLookup[calendar.calendar_id] = calendar;
      });

      state.externalCalendars.mergedCalendars = state.externalCalendars.mergedCalendars.map(
        mergedCalendar => {
          const newCalendar = calendarLookup[mergedCalendar.id];

          if (newCalendar) {
            state.externalCalendars.syncStatusById[
              newCalendar.id
            ] = determineSyncStatus(newCalendar);

            delete state.externalCalendars.syncStatusById[newCalendar.calendar_id];

            return {
              ...mergedCalendar,
              ...newCalendar,
            };
          }

          return mergedCalendar;
        },
      );
    }),

    onSetExternalCalendarSync: produce<ProviderCalendarState, [OnSetExternalCalendarSyncPayload]>((
      state: ProviderCalendarState,
      {
        calendar,
        shouldReceiveEvents,
      }: OnSetExternalCalendarSyncPayload,
    ) => {
      let newSyncStatus: CalendarSyncStatus;

      if (shouldReceiveEvents) {
        newSyncStatus = CalendarSyncStatus.Syncing;
      } else {
        newSyncStatus = CalendarSyncStatus.Unsyncing;
      }

      state.externalCalendars.syncStatusById[
        calendar.id
      ] = newSyncStatus;

      let foundCalendarIdIndex = -1;
      let foundIndex = -1;

      state.externalCalendars.mergedCalendars.forEach((mergedCalendar, idx) => {
        if (mergedCalendar.id === calendar.calendar_id) {
          foundCalendarIdIndex = idx;
        } else if (mergedCalendar.id === calendar.id) {
          foundIndex = idx;
        }
      });

      if (foundCalendarIdIndex !== -1) {
        // if we're adding a new calendar record for an existing external calendar
        state.externalCalendars.mergedCalendars[foundCalendarIdIndex] = calendar;
      } else if (foundIndex === -1) {
        // if neither an existing external calendar nor an internal calendar were found
        state.externalCalendars.mergedCalendars.push(calendar);
      }
    }),

    onFraudStatusLoaded: (
      state: ProviderCalendarState,
      { statuses }: OnFraudStatusLoadedPayload,
    ): ProviderCalendarState => ({
      ...state,
      fraudStatuses: statuses,
    }),

    onScheduleLoaded: (
      state: ProviderCalendarState,
      { schedule }: OnScheduleLoadedPayload,
    ): ProviderCalendarState => ({
      ...state,
      schedule,
    }),
  },

  effects: dispatch => ({
    async load({
      providerId,
      selectedDate,
    }) {
      dispatch.providerCalendar.onLoad({
        providerId,
      });

      try {
        await dispatch.providerCalendar.loadThisWeek({
          date: selectedDate,
        });
        dispatch.providerCalendar.onLoaded();
      } catch {
        dispatch.providerCalendar.onFailed();
        return;
      }

      // No need to wait for these
      dispatch.providerCalendar.loadNextWeek({
        date: selectedDate,
      });
      dispatch.providerCalendar.loadPromoData({ providerId });
      dispatch.providerWaitlist.land({ providerId });
      dispatch.providerWorkPeriods.load({ providerId });
      dispatch.providerCalendar.loadFraudStatus({ providerId });
      dispatch.userState.refreshUserState();

      dispatch.providerCalendar.loadSchedule({
        providerId,
        month: selectedDate,
      });
    },

    async loadUpcomingForProvider({
      providerId,
      days = 1,
    }: LoadUpcomingForProvider): Promise<void> {
      dispatch.providerCalendar.onLoadUpcomingForProvider({
        providerId,
      });

      const startDate = `${moment().format('YYYY-MM-DD')}T00:00:00Z`;

      await dispatch.providerCalendar.loadRange({
        providerId,
        startDate,
        days,
      });
    },

    async loadSchedule({
      providerId,
      month,
    }: LoadSchedulePayload): Promise<void> {
      const start = moment(month).startOf('month').utc().format();
      const end = moment(month).endOf('month').utc().format();

      const scheduledAppts = await fetchAllScheduledAppointments(
        providerId,
        start,
        end,
      );

      dispatch.providerCalendar.onScheduleLoaded({
        providerId,
        schedule: scheduledAppts,
      });
    },

    async completeGoogleSync(): Promise<void> {
      await dispatch.providerCalendar.onClearAppointments();

      await Promise.all([
        dispatch.providerCalendar.loadThisWeek(),
        dispatch.providerCalendar.loadNextWeek(),
      ]);
    },

    // appointment was drag'n'dropped onto new date
    dropOnNewDate(payload: {
      appointmentId: number;
      providerId: number;
      newDate: string;
    }): Promise<any> {
      return dispatch.providerManageAppointment.reschedule(payload);
    },

    async loadThisWeek(
      payload?: { date: DateString },
      rootState?,
    ): Promise<void> {
      const {
        providerId,
      } = rootState.providerCalendar;
      const selectedDate = payload?.date || parseSelectedDateFromParams(rootState.route.params);

      const rangeLoadSettings = getRangeLoadSettings(
        CalendarModeOptions.Week,
        selectedDate,
      );

      return dispatch.providerCalendar.loadRange({
        providerId,
        days: rangeLoadSettings.numberOfDays,
        startDate: rangeLoadSettings.startDate,
      });
    },

    async loadNextWeek(
      payload?: { date: DateString },
      rootState?,
    ): Promise<void> {
      const {
        providerId,
      } = rootState.providerCalendar;
      const selectedDate = payload?.date || parseSelectedDateFromParams(rootState.route.params);
      const rangeLoadSettings = getRangeLoadSettings(
        CalendarModeOptions.Week,
        selectedDate,
      );

      return dispatch.providerCalendar.loadRange({
        providerId,
        days: rangeLoadSettings.numberOfDays,
        startDate: rangeLoadSettings.nextDate,
      });
    },

    async loadRange({
      providerId, startDate, days, page = 1, size = 1000,
    }: LoadRangePayload, rootState): Promise<void> {
      if (rootState.providerCalendar.providerId
        && providerId !== rootState.providerCalendar.providerId) {
        return;
      }

      const response = await getProAppointmentList(
        {
          providerId,
          days,
          start_date: startDate,
          size,
          page,
        },
      );

      dispatch.providerCalendar.onListPageLoaded({
        appointments: response.results,
      });

      if (response.next) {
        await dispatch.providerCalendar.loadRange({
          providerId,
          startDate,
          days,
          page: page + 1,
          size,
        });
      }
    },

    async loadPromoData({ providerId }: LoadPromoDataPayload): Promise<void> {
      dispatch.providerCalendar.onLoadedPromoData(await getPromoData(providerId));
    },

    async loadFraudStatus({ providerId }: LoadFraudStatusPayload): Promise<void> {
      const response = await fetchFraudStatus(providerId);
      dispatch.providerCalendar.onFraudStatusLoaded({
        providerId,
        statuses: response.results,
      });
    },

    async loadExternalCalendars({
      providerId,
      userId,
    }: LoadExternalCalendarsPayload): Promise<OnLoadExternalCalendarsCompletePayload> {
      dispatch.providerCalendar.onLoadExternalCalendarsStart({
        providerId,
      });

      const result: OnLoadExternalCalendarsCompletePayload = {
        googleCredential: null,
        mergedCalendars: [],
      };

      try {
        const account = await getGoogleCredential(userId);
        result.googleCredential = account;

        if (account) {
          const [googleCalendars, internalCalendars] = await Promise.all([
            // retrieve calendars from Google's API
            getRemoteGoogleCalendars(userId, account.access_token),

            // retrieve calendars stored in our database
            getExternalCalendars(userId),
          ]);

          result.mergedCalendars = mergeExternalCalendars(googleCalendars, internalCalendars);
        }
      } catch (e) {
        nonCriticalException(e);
      }

      dispatch.providerCalendar.onLoadExternalCalendarsComplete(result);

      return result;
    },

    async refreshExternalCalendars({
      userId,
    }: RefreshExternalCalendarsPayload, rootState): Promise<void> {
      const refreshedCalendars = await getExternalCalendars(userId);

      if (
        checkAnyCalendarUpdating(rootState.providerCalendar)
        && !refreshedCalendars.some(calendar => calendar.is_syncing || calendar.is_unsyncing)
      ) {
        // we were previously syncing and have just finished, so take note of that
        await dispatch.providerCalendar.completeGoogleSync();
      }

      await dispatch.providerCalendar.onRefreshExternalCalendars({ calendars: refreshedCalendars });
    },

    async setExternalCalendarSync(payload: SetExternalCalendarSyncPayload): Promise<void> {
      let syncingCalendar: GoogleCalendar;
      SignupSettings.setSyncedGoogleCal(payload.providerId, true);
      if (!payload.calendar.calendar_id && typeof payload.calendar.id === 'string') {
        // syncing by external calendar ID, so assume we're creating a new record
        syncingCalendar = await createExternalCalendar({
          user: payload.calendar.user,
          calendar_id: payload.calendar.id,
          should_receive_events: payload.shouldReceiveEvents,
        });
      } else {
        syncingCalendar = await updateExternalCalendar({
          user: payload.calendar.user,
          calendar_id: payload.calendar.id,
          should_receive_events: payload.shouldReceiveEvents,
        });
      }

      await dispatch.providerCalendar.onSetExternalCalendarSync({
        calendar: {
          ...payload.calendar,
          ...syncingCalendar,
        },
        shouldReceiveEvents: payload.shouldReceiveEvents,
      });
    },
  }),

  selectors: (
    slice,
    createSelector,
    hasProps,
  ): ModelSelectorFactories<RootModel, Record<string, never>> => ({
    underInvestigationStatus(models) {
      return createSelector(
        slice(state => state.fraudStatuses),
        models.user.isSuperUser,
        (statuses, isSuperUser): FraudResponse | null => {
          if (!statuses || !isSuperUser) return null;
          return (
            statuses.reverse().find(
              fraudInstance => fraudInstance.under_fraud_investigation,
            )
          );
        },
      );
    },

    // true if the provider has any appointments loaded
    hasAppointments: () => slice((state: ProviderCalendarState) => state.hasAppointments),

    // returns any promotional appointments the provider may have
    promoAppointments() {
      return createSelector(
        slice(state => state.promoData),
        slice(state => state.hasAppointments),
        (
          promoData: PromoAppointmentsData,
          proHasAppointments: boolean,
        ): PromoAppointment[] | null => (
          !promoData.provider ? null : getPromoAppointments(promoData, proHasAppointments)
        ),
      );
    },

    // returns today's appointments
    // note: this does not include promo appointments
    todaysAppointments(models) {
      return createSelector(
        models.providerCalendar.appointmentsByDate,
        (apptsByDate: Record<string, AppointmentsList>) => (
          apptsByDate?.[getDateWithoutTime(new Date())]
        ),
      );
    },

    // returns appointments that are coming up today, excluding promo appointments
    upcomingAppointments(models) {
      return createSelector(
        models.providerCalendar.todaysAppointments,
        (todaysAppointments: ProAppointment[]) => {
          const now = Date.now();
          return (
            todaysAppointments?.filter(appt => (
              new Date(appt.local_start).getTime() > now
            ))
          );
        },
      );
    },

    // returns any auto-checkout appointments that are upcoming
    upcomingAutoCheckoutAppointments(models) {
      return createSelector(
        models.providerCalendar.upcomingAppointments,
        (upcomingAppointments: ProAppointment[]) => (
          upcomingAppointments
            ?.filter(appt => appt.is_autocheckout)
            ?.sort(compareAppointmentsByStartTime)
        ),
      );
    },

    // returns all the appointments indexed by id
    appointmentsById: () => (
      slice((state: ProviderCalendarState) => state.appointments)
    ),

    // the complete array of appointments for the current provider
    appointments() {
      return (
        createSelector(
          slice(state => state.providerId),
          slice(state => state.appointments),
          (
            providerId: number,
            appointments: CalendarAppointments,
          ): CalendarAppointment[] => (
            Object.values(appointments).filter(appt => appt.provider === providerId)),
        )
      );
    },

    // returns the appointments list keyed by the date they start on
    appointmentsByDate(models) {
      return (
        createSelector(
          models.providerCalendar.appointments,
          (
            appointments: CalendarAppointment[],
          ): Record<DateString, ProAppointment[]> => keyByDate(appointments),
        )
      );
    },

    // creates a selector for appointments starting on the provided date
    appointmentsForDate: hasProps((models: any, date: string) => (
      createSelector(
        models.providerCalendar.appointmentsByDate,
        models.providerCalendar.promoAppointments,
        state => state.proNCDExperienceEligibility.experience,
        models.userState.valueByKey(ADJUDICATED_APPOINTMENTS),
        models.abTest.isEnabled(NCC_HUB_SWITCH),
        (
          appointments: Record<string, CalendarAppointment[]>,
          promoAppointments: PromoAppointment[],
          experience: NCDExperience,
          appointmentsAdjudicated: Record<number, number> = {},
          isNCCHubEnabled: boolean,
        ): AppointmentsList => {
          const extraAppointments = date === todayDateForPromo ? promoAppointments : [];
          const notCancelledAppointments = getNotCancelledAppointments(appointments[date]);
          const appointmentsWithNCD = notCancelledAppointments.map(
            appointment => ({
              ...appointment,
              ncdExperience: experience,
              isNCDExperienceQualified: isNCCHubEnabled
                ? appointment.client?.ncd?.state === NCDState.Pending
                : checkShouldAdjudicateNCD(
                  appointment,
                  appointmentsAdjudicated,
                  experience,
                ),
            }),
          );
          return [
            ...appointmentsWithNCD,
            ...(
              extraAppointments || []
            ),
          ];
        },
      )
    )),

    appointmentsSortedByStartTime(models) {
      return (
        createSelector(
          models.providerCalendar.appointments,
          (
            appointments: CalendarAppointment[],
          ) => getNotCancelledAppointments(appointments).sort(compareAppointmentsByStartTime),
        )
      );
    },

    clientAppointments(models) {
      return (
        createSelector(
          models.providerCalendar.appointments,
          (appointments: CalendarAppointment[]) => appointments.filter(appointment => (
            isClientAppt(appointment)
            && !isCancelled(appointment)
            && !isCheckedOutLateCancel(appointment)
          )).sort(compareAppointmentsByStartTime),
        )
      );
    },

    clientAppointmentsByDate(models) {
      return (
        createSelector(
          models.providerCalendar.clientAppointments,
          (
            appointments: CalendarAppointment[],
          ): Record<string, ProAppointment[]> => keyByDate(appointments),
        )
      );
    },

    anyExternalCalendarUpdating: () => (
      createSelector(
        slice,
        checkAnyCalendarUpdating,
      )
    ),

    anyExternalCalendarSyncing: () => (
      createSelector(
        slice,
        (state: ProviderCalendarState): boolean => (
          Object.values(state.externalCalendars.syncStatusById).some(status => (
            status === CalendarSyncStatus.Syncing
          ))
        ),
      )
    ),

    externalCalendarGoogleAccount: () => (
      createSelector(
        slice,
        (state: ProviderCalendarState): GoogleCredential | null => (
          state.externalCalendars.googleCredential
        ),
      )
    ),

    externalCalendarFetched: () => (
      createSelector(
        slice,
        (state: ProviderCalendarState): boolean => (
          !!state.externalCalendars?.fetched
        ),
      )
    ),

    monthSchedule: () => (
      createSelector(
        slice(state => state.schedule),
        (scheduledAppts): DateString[] => (
          _.uniq(scheduledAppts.map(
            schedule => schedule.local_start.split('T')[0] as DateString,
          ))
        ),
      )
    ),

    externalCalendarStatusList: () => (
      createSelector(
        slice,
        (state: ProviderCalendarState): ExternalCalendarStatusListItem[] => (
          state.externalCalendars.mergedCalendars.map(mergedCalendar => {
            const calendarStatus = state.externalCalendars.syncStatusById[mergedCalendar.id];
            const isSyncing = calendarStatus === CalendarSyncStatus.Syncing;
            const isUnsyncing = calendarStatus === CalendarSyncStatus.Unsyncing;
            let shouldReceiveEvents: boolean = !!mergedCalendar.should_receive_events;

            if (isSyncing) {
              shouldReceiveEvents = true;
            } else if (isUnsyncing) {
              shouldReceiveEvents = false;
            }

            return {
              isSynced: calendarStatus === CalendarSyncStatus.Synced,
              isSyncing,
              isUnsyncing,
              shouldReceiveEvents,
              id: mergedCalendar.id,
              summary: mergedCalendar.summary,
              fullCalendar: mergedCalendar,
            };
          })
        ),
      )
    ),
  }),
});
