// @ts-strict-ignore
import produce from 'immer';
import { createSelector } from 'reselect';
import { createModel, RematchRootState } from '@rematch/core';
import { IDiscountDisplay, DiscountStatus } from './ClientPromotions.types';
import { PublicProvider } from '../api/Providers';
import type { RootModel } from './models';
import {
  DiscountDetailsWithKey,
  StoredDiscountInfo,
  DiscountDetails,
  PromoTypeValues,
} from './NCDAndDiscountedPros.types';
import { getNumberOrNull } from './NCDAndDiscountedPros.model';
import { IncentivesCheck, REFERRAL_SHARE_KEY } from './ShowIncentives.model';
import { CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME, INCENTIVES_DISCOUNT_CODE } from '../components/consumer/incentives/constants';
import { formatDiscount } from '../components/consumer/promotions/helpers';
import nonCriticalException from '../modules/exceptionLogger';
import FeatureFlags from '../modules/FeatureFlags';
import { hasLoadedDeferred } from './UserState.model';

type State = {
  loading: boolean;
  error?: Error;
  promotions: Array<IDiscountDisplay>;
};

export interface PromotionSections {
  past: IDiscountDisplay[]; // not available or applied
  available: IDiscountDisplay[]; // available or applied
  applied: IDiscountDisplay[];
}

export class EligibilityError extends Error {
  description: string;

  constructor(message: string, description: string) {
    super(message);
    this.description = description;
  }
}

async function attemptApplyingToAppointment(
  activeProvider: Pick<PublicProvider, 'id' | 'name'>,
  discountCode: string,
  source: string,
  appointmentCost: number | string,
  dispatch,
  forceAdd?: boolean,
  rewardId?: number,
): Promise<DiscountDetailsWithKey> {
  try {
    const discountResult = await dispatch.ncdAndDiscountedPros.applyDiscountToAppointment({
      code: discountCode,
      providerId: activeProvider.id,
      cost: appointmentCost,
      source,
      forceAdd,
      rewardId,
    });
    return discountResult;
  } catch (err) {
    const isServerError = typeof err === 'string';
    const message = isServerError ? (JSON.parse(err).error) : 'Invalid promo code';

    if (!isServerError) {
      nonCriticalException(err);
    }
    throw new EligibilityError(
      `${activeProvider.name} isn't eligible for discount`,
      message,
    );
  }
}

async function getDiscountInfo(
  providerId: number,
  discountCode: string,
  rewardId: number | undefined,
  dispatch,
  rootState,
): Promise<StoredDiscountInfo> {
  let discountInfo: StoredDiscountInfo = rootState.ncdAndDiscountedPros.providers[providerId];

  // single check NCD data doesn't include CIP lookup, so if that's missing or the information
  // hasn't been loaded, do that before continuing
  if (!discountInfo || discountInfo.wasSingleCheck || discountInfo.discountCode !== discountCode) {
    const discountInfoMap = await dispatch.ncdAndDiscountedPros.checkProviderDiscountEligibility({
      providerId,
      discountCode,
      rewardId,
    });

    discountInfo = discountInfoMap[providerId];
  }

  return discountInfo;
}

function transformDiscountToPromotion(
  discountKey: string,
  discountPercent: number | string | null,
  discountAmount: number | string | null,
  discountCode: string,
  discountMax: number | null,
  discountStatus?: DiscountStatus,
  promoType?: PromoTypeValues,
  rewardId?: number | null,
): IDiscountDisplay {
  const percent = getNumberOrNull(discountPercent);
  const amount = getNumberOrNull(discountAmount);
  let type: IDiscountDisplay['type'] = 'amount';

  if (percent !== null) {
    type = 'percentage';
  } else if (amount === null) {
    return null;
  }

  const promotion: IDiscountDisplay = {
    id: discountKey,
    discountCode,
    discountMax,
    discountAmount: amount,
    discountPercent: percent,
    title: 'StyleSeat Offers',
    type,
    // if we get a record back, we can assume it's available
    // we don't get redeemed records back right now
    status: discountStatus || DiscountStatus.Available,
    discountEligible: !discountStatus || discountStatus === DiscountStatus.Available,
    promoType,
    rewardId,
  };
  promotion.rewardId = rewardId;
  if (discountCode === INCENTIVES_DISCOUNT_CODE || promoType === PromoTypeValues.Reward) {
    promotion.discountMax = 50;
    promotion.title = 'Better with Friends';
    promotion.description = 'Book a new pro on StyleSeat and receive 15% off of your booking up to $50.';
  } else {
    promotion.description = `Take ${formatDiscount(promotion)} your next appointment on us!`;
  }

  /* This is in case all else fails we want to make sure that referral rewards get the right
  rewardId and promoType passed in so that the behavior can be consistent */
  if (promotion.discountCode.includes('SSREFERRALS')) {
    promotion.promoType = PromoTypeValues.Reward;
    promotion.rewardId = Number(promotion.id.split('_').slice(-1)[0]) || 0;
  }
  return promotion;
}

function isAvailablePromotion(promotion: IDiscountDisplay) {
  return [
    DiscountStatus.Applied,
    DiscountStatus.Available,
  ].includes(promotion.status);
}

export const findAppliedPromotion = createSelector<
RematchRootState<RootModel>,
State,
IDiscountDisplay | null
>(
  (state): State => state.clientPromotions,
  (state: State): IDiscountDisplay | null => (
    state.promotions.find(promo => promo.status === DiscountStatus.Applied) || null
  ),
);

const model = createModel<RootModel>()({
  name: 'clientPromotions',

  state: {
    loading: false,
    promotions: [],
    promotionsById: {},
  } as State,

  reducers: {
    setPromotions: (state: State, payload: Array<IDiscountDisplay>) => ({
      ...state,
      promotions: [...payload],
    }),
    setLoading: (state: State, payload: boolean) => ({
      ...state,
      loading: payload,
    }),
    insertPromotion: produce<State, [IDiscountDisplay]>((
      state: State,
      payload: IDiscountDisplay,
    ) => {
      const copy = { ...payload };

      if (copy.status === DiscountStatus.Applied) {
        // can only apply one at a time
        state.promotions.forEach(promo => {
          if (promo.status === DiscountStatus.Applied) {
            promo.status = DiscountStatus.Available;
          }
        });
      }

      state.promotions.unshift(copy);
    }),
    updateDiscountStatus: produce<State, [{ id: string; status: DiscountStatus }]>((
      state: State,
      payload: { id: string; status: DiscountStatus },
    ) => {
      const { id, status: nextStatus } = payload;
      const promo = state.promotions.find(item => item.id === id);
      const prevStatus = promo.status;
      // statuses that are applied or available can be swapped back and forth as needed, but other
      // statuses can only be entered
      if (nextStatus === DiscountStatus.Applied) {
        if (prevStatus === DiscountStatus.Applied || prevStatus === DiscountStatus.Available) {
          // update the status to applied and the other "applied" statuses to available
          for (let i = 0; i < state.promotions.length; i++) {
            const item = state.promotions[i];

            if (item.id === id) {
              item.status = nextStatus;
            } else if (item.status === DiscountStatus.Applied) {
              item.status = DiscountStatus.Available;
            }
          }
        } else {
          throw new Error('This promotion has already been applied');
        }
      } else if (nextStatus === DiscountStatus.Available) {
        if (prevStatus === DiscountStatus.Applied) {
          // update the status to available
          promo.status = nextStatus;
        } else {
          throw new Error('This promotion cannot be re-used');
        }
      } else {
        // just do the update
        promo.status = nextStatus;
      }
    }),
  },
  effects: dispatch => ({
    addDiscountCode: async (payload:
    {
      discountCode: string;
      source: string;
      appointmentCost: undefined;
      rewardId: number | null;
    } |
    {
      discountCode: string;
      source: string;
      appointmentCost: undefined;
      rewardId: number | null;
    } | {
      discountCode: string;
      source: string;
      appointmentCost: number | string;
      rewardId: number | null;
    }, rootState): Promise<DiscountDetails> => {
      const {
        discountCode,
        source,
        appointmentCost,
        rewardId,
      } = payload;
      const activeProvider = (
        rootState.providers.providersById[rootState.providers.activeProviderId]?.result
      );
      let discountInfo: DiscountDetailsWithKey;

      // automatically apply valid promotion if there is an active booking
      if (activeProvider && typeof appointmentCost !== 'undefined') {
        discountInfo = await attemptApplyingToAppointment(
          activeProvider,
          discountCode,
          source,
          appointmentCost,
          dispatch,
          true,
          rewardId,
        );
        const promotion = transformDiscountToPromotion(
          discountInfo.discountKey,
          discountInfo.discountPercent,
          discountInfo.discountAmount,
          discountInfo.discountCode,
          discountInfo.discountMax,
          null,
          discountInfo.promoType,
          rewardId,
        );

        // if the pro is not eligible for the client incentives program...
        if (!promotion?.discountEligible) {
          // throw error if pro of current appointment is not NCD / incentives eligible
          throw new EligibilityError(
            `${activeProvider.name} isn't eligible for discount`,
            'This promotion is available when\nyou discover and book a new pro\non StyleSeat.',
          );
        }

        promotion.status = DiscountStatus.Applied;

        await dispatch.clientPromotions.insertPromotion(promotion);
      } else {
        const discountData = await dispatch.ncdAndDiscountedPros.applyDiscountCode({
          code: discountCode,
          source,
          rewardId,
        });
        const promotion = transformDiscountToPromotion(
          discountData.discountKey,
          discountData.discountPercent,
          discountData.discountAmount,
          discountData.discountCode,
          discountData.discountMax,
          null,
          discountData.promoType,
          rewardId,
        );

        if (!promotion?.discountCode) {
          throw new EligibilityError(
            'Invalid Promo Code',
            'Invalid promo code',
          );
        }

        promotion.status = DiscountStatus.Applied;

        discountInfo = discountData;

        await dispatch.clientPromotions.insertPromotion(promotion);
      }

      return discountInfo;
    },

    applyPromotion: async (payload: {
      promotion: IDiscountDisplay;
      source: string;
      appointmentCost: number | string;
    }, rootState) => {
      const {
        promotion,
        source,
        appointmentCost,
      } = payload;
      const activeProvider = (
        rootState.providers.providersById[rootState.providers.activeProviderId]?.result
      );
      const providerName = activeProvider.name;

      // Do any appointment / provider-related checks for eligibility here
      if (promotion.discountCode === INCENTIVES_DISCOUNT_CODE) {
        const discountInfo = await getDiscountInfo(
          activeProvider.id,
          promotion.discountCode,
          promotion.rewardId,
          dispatch,
          rootState,
        );

        // if the pro is not eligible for the client incentives program...
        if (!discountInfo?.discountEligible) {
          // throw error if pro of current appointment is not NCD / incentives eligible
          throw new EligibilityError(
            `${providerName} isn't eligible for discount`,
            'This promotion is available when\nyou discover and book a new pro\non StyleSeat.',
          );
        }
      } else {
        await attemptApplyingToAppointment(
          activeProvider,
          promotion.discountCode,
          source,
          appointmentCost,
          dispatch,
          null,
          promotion.rewardId,
        );
      }

      await dispatch.clientPromotions.updateDiscountStatus({
        id: promotion.id,
        status: DiscountStatus.Applied,
      });
    },

    unapplyPromotion: async (promotion: IDiscountDisplay): Promise<void> => {
      await dispatch.clientPromotions.updateDiscountStatus({
        id: promotion.id,
        status: DiscountStatus.Available,
      });
    },

    loadPromotionsForPro: async (
      providerId: number | undefined,
      rootState,
    ): Promise<Array<IDiscountDisplay>> => {
      // stub function that will need to get built out in the future as referrals / promotions
      // evolve
      await dispatch.clientPromotions.setLoading(true);
      let error: Error;

      try {
        const promotions: Array<IDiscountDisplay> = [];
        let currentPromotion: IDiscountDisplay | null = null;

        if (!rootState.abTest?.isLoaded) {
          // make sure feature flags / ab tests are there for everyone else
          await FeatureFlags.getFlags();
        }

        const [incentivesChecks] = await Promise.all([
          Promise.resolve(
            // if first check is completed and nothing else is happening, use the current value,
            // otherwise start the check or wait for the current check to complete
            (rootState.showIncentives?.firstCheckCompleted
              && !rootState.showIncentives.checkInProgress
            )
              ? rootState.showIncentives.incentivesChecks
              : dispatch.showIncentives.checkIncentivesStatus(),
          ),
          hasLoadedDeferred.promise,
        ]);

        const discountDetails = await dispatch.ncdAndDiscountedPros.loadValidCodes(
          { providerId },
        );

        discountDetails.forEach(discountData => {
          const promotion = transformDiscountToPromotion(
            discountData.discountKey,
            discountData.discountPercent,
            discountData.discountAmount,
            discountData.discountCode,
            discountData.discountMax,
            discountData.status as unknown as DiscountStatus,
            discountData.promoType,
            discountData.rewardId,
          );

          if (promotion && promotion.id && promotion.discountCode !== INCENTIVES_DISCOUNT_CODE) {
            // store the first valid promotion
            if (
              discountData.status === DiscountStatus.Available
              || discountData.status === DiscountStatus.Applied
            ) {
              currentPromotion = currentPromotion || promotion;
            }
            promotions.push(promotion);
          }
        });

        Object.values(
          incentivesChecks,
        ).forEach((check: IncentivesCheck) => {
          if (
            check.isCurrentlyEnrolled || check.wasPreviouslyEnrolled
          ) {
            // transform the incentive into a promotion
            let promotion: IDiscountDisplay;

            if (
              check.testName === REFERRAL_SHARE_KEY
              || check.testName === CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME
            ) {
              promotion = {
                id: check.testName,
                discountCode: INCENTIVES_DISCOUNT_CODE,
                discountMax: 50, // Always 50 right now for referrals
                discountPercent: 15, // Same - always 15
                discountAmount: null,
                type: 'percentage',
                title: 'Better with Friends',
                description: 'Book a new pro on StyleSeat and receive 15% off of your booking up to $50.',
                status: DiscountStatus.Redeemed,
                discountEligible: false,
                promoType: PromoTypeValues.ClientIncentivesProgramNewPro,
              };
            } else {
              promotion = {
                id: check.testName,
                discountCode: INCENTIVES_DISCOUNT_CODE,
                discountMax: 50,
                // limit: 50, // Always 50 right now for incentives
                discountPercent: 15, // Same - always 15
                discountAmount: null,
                type: 'percentage',
                title: 'StyleSeat Offers',
                description: '15% off (up to $50) when you\ndiscover & book a new pro.',
                status: DiscountStatus.Redeemed,
                discountEligible: false,
                promoType: PromoTypeValues.ClientIncentivesProgramNewPro,
              };
            }

            // if currently enrolled, update the status
            if (check.isCurrentlyEnrolled) {
              promotion.status = DiscountStatus.Available;
              promotion.discountEligible = true;
              // we just want to store the first current promotion
              currentPromotion = currentPromotion || promotion;
            }
            promotion.checkTestName = check.testName;
            promotions.push(promotion);
          }
        });

        if (currentPromotion !== null) {
          if (providerId) {
            const discountInfo = await getDiscountInfo(
              providerId,
              currentPromotion.discountCode,
              currentPromotion.rewardId,
              dispatch,
              rootState,
            );
            // if bookings with the current pro are eligible for incentives / promotions
            if (discountInfo?.discountEligible) {
              currentPromotion.status = DiscountStatus.Applied;
            }
          }
        }

        await dispatch.clientPromotions.setPromotions(promotions);
      } catch (err) {
        error = err;
      } finally {
        await dispatch.clientPromotions.setLoading(false);
      }

      if (error) {
        throw error;
      }

      return rootState.clientPromotions.promotions;
    },

    loadPromotions: async (_?: undefined, rootState?): Promise<Array<IDiscountDisplay>> => {
      const activeProvider = (
        rootState.providers.providersById[rootState.providers.activeProviderId]?.result
      );

      return dispatch.clientPromotions.loadPromotionsForPro(activeProvider?.id);
    },
  }),

  selectors: slice => ({
    appliedPromotion() {
      return findAppliedPromotion;
    },

    promotionSections() {
      return createSelector(
        slice(state => state.promotions),
        (promotions: IDiscountDisplay[]): PromotionSections => ({
          past: promotions.filter(promo => !isAvailablePromotion(promo)),
          available: promotions.filter(promo => isAvailablePromotion(promo)),
          applied: promotions.filter(promo => promo.status === DiscountStatus.Applied),
        }),
      );
    },
  }),
});

export default model;
