import { MomentInput } from 'moment';
import { BrandColor } from '@styleseat/brand';
import { MARKETPLACE_RULES } from '../../../../route-names';
import StateManagerInstance from '../../../user/stateManager';
import { NCD_POLICY_NOTIFICATION_ACTION } from '../../../newClientDelivery.constants';

export interface NCDCancellationPolicyNotification {
  creation_time: MomentInput;
  persistent: true;
  providerId: number;
  type: 'generic';
  sub_type: 'ncd_cancellation_policy';
  uuid: 'ncd-cancellation-policy-notification';
  urlLink: string;
  isUrlExternal: boolean;
  style: any;
}

export const shouldShowNcdCancellationPolicyNotification = async () => {
  const hasAcceptedNcdPolicy = await StateManagerInstance.getActionValue(
    NCD_POLICY_NOTIFICATION_ACTION,
  );
  return !hasAcceptedNcdPolicy;
};

export const createNcdCancellationPolicyNotification = (
  providerId: number,
): NCDCancellationPolicyNotification => ({
  creation_time: new Date(),
  persistent: true,
  providerId,
  type: 'generic',
  sub_type: 'ncd_cancellation_policy',
  uuid: 'ncd-cancellation-policy-notification',
  urlLink: MARKETPLACE_RULES,
  isUrlExternal: false,
  style: {
    iconName: 'NotificationsAlertCircleIcon',
    iconColor: BrandColor.YellowHighlight,
    highlightedTitle: 'Review updated marketplace rules.',
    buttonProps: {
      text: 'Review marketplace rules.',
      size: 'small',
      appearance: 'text-light',
    },
  },
});
