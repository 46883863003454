/**
 * Module for terms & conditions, privacy policy, etc, data.
 */
import { API_ROOT } from '../config';
import { ssFetchJSON } from './ssFetch';

// Current published version of terms
export const TERMS_VERSIONS = {
  pro_tos: '6.2',
  consumer_tos: '3.2',
  privacy_policy: '2.0',
  eula: '1.0',
};

export type TermsAcceptancePayload = {
  accepted_version: string;
  terms_type: 'tos' | 'eula' | 'privacy_policy';
  user: number;
  provider?: number; // presence of providerId indicates pro TOS, absence indicates consumer TOS
};

export type TermsAcceptanceResponse = {
  accepted_time: string; // isoformat, UTC
  accepted_version: string;
  id: number;
  ip_address: string;
  provider: number | null;
  terms_type: 'tos' | 'eula' | 'privacy_policy',
  user: number;
  user_agent: string;
};

export const acceptMultipleTerms = (terms: TermsAcceptancePayload[]) => {
  const url = new URL(`${API_ROOT}/api/v1/terms/accepted.json`);
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    throwOnHttpError: true,
    body: JSON.stringify(terms),
  });
};

/**
 * Accept all newest terms.
 */
export const acceptAllTerms = (userId: number, providerId?: number) => {
  const tosAcceptance: TermsAcceptancePayload = providerId ? {
    accepted_version: TERMS_VERSIONS.pro_tos,
    terms_type: 'tos',
    user: userId,
    provider: providerId,
  } : {
    accepted_version: TERMS_VERSIONS.consumer_tos,
    terms_type: 'tos',
    user: userId,
  };
  const eula: TermsAcceptancePayload = {
    accepted_version: TERMS_VERSIONS.eula,
    terms_type: 'eula',
    user: tosAcceptance.user,
  };
  const privacyPolicy: TermsAcceptancePayload = {
    accepted_version: TERMS_VERSIONS.privacy_policy,
    terms_type: 'privacy_policy',
    user: tosAcceptance.user,
  };

  const terms = [tosAcceptance, eula, privacyPolicy];
  return acceptMultipleTerms(terms);
};

/**
 * Gets all terms previously accepted by the user.
 */
export const getAcceptedTerms = async (): Promise<TermsAcceptanceResponse[]> => {
  const url = new URL(`${API_ROOT}/api/v1/terms/accepted.json`);
  return ssFetchJSON(url.toString());
};

/**
 * Check if current version of TOS has been accepted by user or not.
 * If isProvider is true, will check for pro TOS version only.
 * If isProvider is false, will check for consumer TOS version only.
 */
export const areTOSAcceptedByUser = (
  terms: TermsAcceptanceResponse[], isProvider: boolean,
): boolean => {
  const termsVersion = TERMS_VERSIONS[isProvider ? 'pro_tos' : 'consumer_tos'];
  return !!terms.filter(term => {
    if (term.terms_type !== 'tos') return false;
    if (isProvider && !term.provider) return false;
    if (!isProvider && term.provider) return false;
    return true;
  }).find(term => term.accepted_version === termsVersion);
};
