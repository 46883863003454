// @ts-strict-ignore
import URI from 'urijs';

import { ssFetchJSON } from '../ssFetch';
import { IDRFResponse } from '../../types';
import SimpleCache from '../SimpleCache';
import { IBasicImage } from '../../api/Providers/GalleryImages';

const reviewsCache = new SimpleCache(5 * 60); // 5 minute cache

/**
 * An image containing source URLs for 2 thumbnail sizes
 */
export interface IReviewImageWithSources extends IBasicImage {
  source?: string;
  sourceLarge?: string;
}

/**
 * A response to a review by the associated provider
 */
export interface IReviewResponse {
  text: string;
  id: number;
}

/**
 * A review, as returned by the (v2) ratings endpoints
 */
export interface IReview {
  id: number;
  modification_time: string;
  review_images: Array<IBasicImage>;
  pinned: boolean;
  creation_time: any;
  num_stars: number;
  review_text: string;
  about_hairtype?: string[];
  about_location?: string[];
  about_provider?: string[];
  response?: IReviewResponse;
  review?: {
    id: number;
    text: string;
    recommendation: number;
    modification_time: string;
  };
  autopin_enabled?: boolean;
  autopin_source?: 'provider' | 'model' | 'top_service';
  appointment: {
    service_name: string;
    client: {
      user: {
        first_name: string;
        last_name: string;
      };
    };
  };
}

/**
 * Adds two properties to the provided image so that it contains urls
 * for thumbnails (source and sourceLarge)
 * @param reviewImage
 */
export const addImageSourceURLs = (reviewImage: IBasicImage): IReviewImageWithSources => ({
  ...reviewImage,
  source: `${reviewImage.url}_100x100.jpg`,
  sourceLarge: `${reviewImage.url}_300x300.jpg`,
});

/**
 * Transforms the review, adding properties needed by child components, including `source`
 * properties for the images
 */
export const addReviewImageSources = (review: IReview): IReview => ({
  ...review,
  review_images: review.review_images?.map(addImageSourceURLs),
});

/**
 * Loads review information for a single review
 * @param reviewId The review ID
 * @param providerId The provider ID
 */
export async function loadReview(reviewId: number, providerId: number): Promise<IReview> {
  const uri = `/api/v2/providers/${providerId}/rating/${reviewId}`;
  return addReviewImageSources(await ssFetchJSON(uri));
}

type LoadReviewsParams = {
  stars_gte?: number;
  stars_lte?: number;
  service_id?: number;
  exclude_star_only?: boolean;
  require_text?: boolean;
  pinned?: boolean;
  page?: number;
  search_term?: string;
};

export type RatingTagInfo = {
  group: string;
  value: string;
  count: number;
};

type RatingTagResults = {
  count: number;
  next?: string;
  previous?: string;
  results: RatingTagInfo[];
};

export type ProviderReviewsResponse = IDRFResponse<IReview>;

/**
 * Loads a page of individual reviews
 * @param providerId The provider ID
 * @param params Parameters used to filter results
 * @returns The server response
 */
export async function loadReviews(
  providerId: number,
  params?: LoadReviewsParams,
): Promise<ProviderReviewsResponse> {
  const uri = URI(`/api/v2/providers/${providerId}/ratings`);
  uri.addQuery(params);
  const response: ProviderReviewsResponse = (
    await ssFetchJSON<ProviderReviewsResponse>(uri.toString())
  );

  return {
    ...response,
    results: response.results.map(addReviewImageSources),
  };
}

export const loadRatingTagInfo = async (
  providerId: number,
): Promise<RatingTagInfo[]> => (
  (await ssFetchJSON<RatingTagResults>(
    `/api/v2/providers/${providerId}/ratings-tag-summary/`,
  )).results
);

export type ReviewSummaryResponse = {
  total: number;
  stars: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
};

/**
 * Gets ratings summary data
 * @param salonId The salon to query
 * @param providerId The provider to query
 * @returns A summary, by star count, of reviews / ratings the pro has received
 */
export async function loadReviewSummary(
  salonId: number,
  providerId: number,
): Promise<ReviewSummaryResponse> {
  const uri = URI(`/api/v2/salons/${salonId}/ratings_summary`);
  uri.addQuery({ provider_id: providerId });

  return ssFetchJSON<ReviewSummaryResponse>(uri.toString(), {
    ssCache: reviewsCache,
  });
}

/**
 * Adds a response to a rating / review
 * @param providerId The provider ID for whom to create the response
 * @param ratingHistoryId The review record to which to attach the response
 * @param text The response text
 */
export async function createRatingResponse(
  providerId: number,
  ratingHistoryId: number,
  text: string,
): Promise<void> {
  const uri = URI(`/api/v2/providers/${providerId}/rating-response`);

  await ssFetchJSON(uri.toString(), {
    method: 'POST',
    body: {
      rating_history: ratingHistoryId,
      text,
    },
  });
}
