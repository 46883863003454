// @ts-strict-ignore
const DEFAULT_MS = 100;

type DebounceConstructor = ((fn: () => void) => void) & { stop: () => void };

/**
 * Prevent functionality from being too frequently by "debouncing" it.
 * Basically, if you try to call a function too frequently, it prevents
 * all calls except for the last one.
 *
 * A common example of this is a typeahead search field. You don't want to hit the API
 * for each character a user enters (if they're typing fast) but after a slight delay (~100ms)
 * after the final character.
 *
 * @example
 * import debouncer from 'modules/debouncer'
 *
 * debounceInput = debouncer();
 *
 * componentWillUnmount() {
 *   // Cancels any pending debounced function call
 *   debounceInput.stop();
 * }
 *
 * onChange = (value) => {
 *   debounceInput(() => {
 *     this.search(value);
 *   });
 * }
 *
 * @param {Number} debounceMS - Any requests more frequent than this number of milliseconds will
 *                              be ignored.
 * @return {Function} Call this function with the function you want to debounce.
 *                    This function returns with a promise.
 */
export default function debouncer(debounceMS: number = DEFAULT_MS): DebounceConstructor {
  let timer = null;

  const debounceConstructor = (debounceFn: () => void) => {
    debounceConstructor.stop();
    timer = setTimeout(() => {
      debounceFn();
      timer = null;
    }, debounceMS);
  };
  debounceConstructor.stop = () => {
    clearTimeout(timer);
  };

  return debounceConstructor;
}
