/**
 * Production configuration
 */

import { getRoot } from './getRoot';

const { isAdmin, root } = getRoot('styleseat.com');

export const ENV = 'production';
export const API_ROOT = root;
export const SITE_ROOT = API_ROOT;
export const SEARCH_API_ROOT = 'https://search.styleseat.com';
export const STATIC_ASSET_URL = 'https://d220aniogakg8b.cloudfront.net';
export const STRIPE_JS_KEY = 'pk_live_HVnZcrLxXd4vLSHbToCM0uzN';
export const GOOGLE_API_KEY = 'AIzaSyAU1CAaNVdY54boh1jWRXgg1-rtKMgx93U';
export const GOOGLE_CLIENT_ID = '1041300213311-dhr7k4b8m1q8p50p061knpfb7frt0p1l.apps.googleusercontent.com';
export const GOOGLE_SIGNIN_CLIENT_ID = '1016475266319-4nmrl694j04irgjeb1q3btbvkjacl33b.apps.googleusercontent.com';
export const GOOGLE_AUTH_SCOPE = 'https://www.googleapis.com/auth/calendar email';
export const INSTAGRAM_APP_ID = '1836884766445421';
export const FACEBOOK_APP_ID = '111891482183773';
export const SHUTTLE_ENDPOINT = isAdmin ? false : 'https://shuttle.styleseat.com/log';
export const FB_ANALYTICS_ENABLED = true;
export const MIXPANEL_KEY = '3cf1028ef24af2a5339fe76d6a771843'; // production key
export const IOS_CAMPAIGN_ID = 'kostyleseat-ios556f49f6d44f502492036c331d';
export const ANDROID_CAMPAIGN_ID = 'kostyleseat-android556f4a4116a065427890180fb0';
export const RECOMMENDATIONS_SOURCE_URL = 'https://d38u36p04f8gyu.cloudfront.net/';
export const RECOMMENDATIONS_ENGINE_PREFIX = 'prod';
export const RECOMMENDATIONS = 'prod_providers-by-city.json';
export const LOYALTY_METROS_FILE = 'prod/metros.json';
export const TIKTOK_PIXEL_ID = 'C1N2ABK98FMC4VRTLKSG';
export const SEGMENT_WRITE_KEY = 'mRzFWFl86nzi92odh7lEUUkundaAeURR';
export const BRAZE_KEY = '2d13bf9e-96d9-4e7f-b578-d7b3c1cfae0e';
export const TALKJS_APP_ID = 'VYlgx3yO';

export const SEO_SERVICE_READ = 'https://vtpbggza85.execute-api.us-east-1.amazonaws.com/seo_api';

export const DEBUG = false;
export const APP = !!process.env.APP_BUILD;
export const ENABLE_APP_SIMULATOR = false;

export const LOGROCKET_KEY = 'o98ogu/styleseat-prod';
export const APP_ASSETS_URL = 'https://d5zh2sh84s4bw.cloudfront.net/';
export const STATE_MANAGER_ENDPOINT = 'https://zcou4vksyc.execute-api.us-east-1.amazonaws.com/state_manager';
export const APPSFLYER_DEBUG = false;
export const STRIPE_TERMINAL_LOCATION_ID = 'tml_EsVi8wsoAyQc24';

export const AWS_AUTOCOMPLETE_REGION = 'us-east-1';
// https://us-east-1.console.aws.amazon.com/cognito/v2/identity/identity-pools?region=us-east-1
export const AWS_AUTOCOMPLETE_COGNITO_IDENTITY_POOL_ID = 'us-east-1:9f701fd3-c89e-44a6-a196-53dc7d8ffa3d';
// https://us-east-1.console.aws.amazon.com/location/places/home?region=us-east-1#/
export const AWS_AUTOCOMPLETE_INDEX_NAME = 'AutocompleteHEREIndex';
export const AWS_AUTOCOMPLETE_ESRI_INDEX_NAME = 'AutocompleteESRIIndex';
