// @ts-strict-ignore
import moment from 'moment';
import * as _ from 'underscore';
import {
  IProviderClient,
  IProviderClientPhone,
  ProviderClientPhoneType,
} from '../../../api/Providers/Clients/types';
import {
  IUIContactData,
  IUIContactPhone,
  parseBirthday,
} from '../UIContact';
import type { Address } from '../../../components/shared/AutocompleteAddressForm/AutocompleteAddressForm';

// Hours from creation_time to be considered a recent contact
export const RECENT_CONTACTS_HOURS = 24;

// Creation source constants
const BULK_IMPORT_CREATION_SOURCE = 4;
export const SINGLE_CLIENT_CREATION_SOURCE = 5;

// Constants used to map to creation source
const SINGLE_CREATION_SOURCE = 'single';
const BULK_CREATION_SOURCE = 'bulk';
const UPDATE_CONTACT = 'update';

type ContactList = IProviderClient[];
type PhoneMapOrArray = IUIContactData['phones'] | Partial<IProviderClientPhone>[];
type ProviderClientPhone = Omit<IProviderClientPhone, 'id'> & { id?: number } ;

interface IImportableContact {
  id?: number;
  name?: string;
  phones?: PhoneMapOrArray;
  email?: string;
  reminder_preference?: number;
  notifications?: { sms: boolean };
  creation_source?: number;
  location_address1?: string;
  location_address2?: string;
  location_city?: string;
  location_state?: string;
  location_zipcode?: string;
  birthday?: string;
}

/**
 * Given an array of contacts, return the ones created in the last 24 hours
 *
 * @param {ContactList} contacts an array of objects of type Contacts
 * @param {number} limit the number of contacts to return
 *
 * @returns {ContactList}
 */
export const getRecentContacts = (contacts: ContactList, limit?: number): ContactList => {
  const recentHours = moment.utc().add(-1 * RECENT_CONTACTS_HOURS, 'hours');
  return contacts.filter(contact => (
    moment.utc(contact.creation_time) > recentHours
  )).sort((a, b) => (
    Number(moment.utc(b.creation_time)) - Number(moment.utc(a.creation_time))
  )).slice(0, limit)
    .sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * Compares two clients and returns true if they are the same client
 * @param clientA
 * @param clientB
 */
export const clientEquals = (clientA: IProviderClient, clientB: IProviderClient): boolean => (
  _.isEqual(clientA, clientB)
);

/**
 * Compares two lists of clients and returns true if they contain none
 * of the clients changed
 * @param list1
 * @param list2
 */
export const clientsListsEqual = (list1: IProviderClient[], list2: IProviderClient[]): boolean => (
  _.isEqual(list1, list2)
);

/**
 * Creates and formats phone number record.  It
 * includes an id for proper update if present.
 * @param {Object} phone Phone number object
 * @param {Number} type Either MOBILE_PHONE_NUMERIC or HOME_PHONE_NUMERIC or WORK_PHONE_NUMERIC
 * @returns {Object} A phone number record
 */
export function makePhoneNumber(
  phone: Partial<IProviderClientPhone> & Pick<IProviderClientPhone, 'number'>,
  type: ProviderClientPhoneType,
): ProviderClientPhone {
  const phoneRecord: ProviderClientPhone = {
    number: phone.number,
    type,
  };

  if (phone.id) {
    phoneRecord.id = phone.id;
  }

  return phoneRecord;
}

/**
 * Is the value of contact.phones in a valid format
 * @param {Array} phones `contact.phones` array
 * @returns {Boolean}
 */
function isPhonesValid(phones: any): phones is Pick<IProviderClientPhone, 'type' | 'number'>[] {
  return Array.isArray(phones)
    && _.every(phones, phone => typeof phone.type === 'number' && !!phone.number);
}

function phoneTypeIsString(phone: any): phone is string {
  return typeof phone === 'string' && !!phone.length;
}

function phoneTypeIsObject(phone: any): phone is Pick<IProviderClientPhone, 'number'> {
  if (phone) {
    return !!phone.number;
  }
  return false;
}

function phoneNumberExists(phone: any): boolean {
  return phoneTypeIsString(phone) || phoneTypeIsObject(phone);
}

/**
 * Returns consistent phone number object to support
 * different phone number structures used by this service's
 * consumers
 * @param  {Object, String} phone Either a phone number object or a phone number string
 * @returns {Object} Default phone structure, at minimum {number: '555-555-5555'}
 */
function getPhoneNumber(phone: string | Pick<IProviderClientPhone, 'number'>): { number: string } {
  if (phoneTypeIsString(phone)) {
    return { number: phone };
  }
  return phone;
}

/**
 * Returns a list of phone numbers to be consumed by the provider
 * client API
 * @param   {Object} contact Contact to be added or updated
 * @returns {Array} List of phone numbers formatted to api specs
 */
export function getPhonesList(
  contact: { phones?: PhoneMapOrArray },
): ProviderClientPhone[] {
  const phones = [];

  // Return the list of numbers if already in `makePhoneNuber` format
  if (isPhonesValid(contact.phones)) {
    return contact.phones;
  }

  const {
    phones: {
      mobile,
      home,
      work,
    } = {},
  } = contact as Partial<IUIContactData>;

  if (phoneNumberExists(mobile)) {
    phones.push(makePhoneNumber(
      getPhoneNumber(mobile as IUIContactPhone),
      ProviderClientPhoneType.Mobile,
    ));
  }

  if (phoneNumberExists(home)) {
    phones.push(makePhoneNumber(
      getPhoneNumber(home as IUIContactPhone),
      ProviderClientPhoneType.Home,
    ));
  }

  if (phoneNumberExists(work)) {
    phones.push(makePhoneNumber(
      getPhoneNumber(work as IUIContactPhone),
      ProviderClientPhoneType.Work,
    ));
  }

  return phones;
}

const getClientPhoneByType = (
  client: IProviderClient,
  type: ProviderClientPhoneType,
): string => {
  const phone = client.phones?.find(clientPhone => clientPhone.type === type);
  return phone ? phone.number : '';
};

export const getClientMobilePhone = (
  client: IProviderClient,
) => getClientPhoneByType(client, ProviderClientPhoneType.Mobile);

function getEmail(contact: Partial<Pick<IUIContactData, 'email'>>): string | null {
  return contact.email ? contact.email : null;
}

function getBirthMoment(contact: IImportableContact): moment.Moment {
  return moment(contact.birthday, 'MM/DD');
}

function getBirthMonth(contact: IImportableContact): number | null {
  // eslint-disable-next-line radix
  return (contact && contact.birthday) ? parseInt(getBirthMoment(contact).format('M')) : null;
}

function getBirthDay(contact: IImportableContact): number | null {
  // eslint-disable-next-line radix
  return (contact && contact.birthday) ? parseInt(getBirthMoment(contact).format('D')) : null;
}

function getReminderPreference(contact: IImportableContact): number | null {
  if (typeof contact.reminder_preference === 'number') {
    return contact.reminder_preference;
  }

  if (contact.notifications && contact.notifications.sms) {
    return 1;
  }
  return null;
}

function isValidType(type: string): boolean {
  return (type === SINGLE_CREATION_SOURCE
    || type === BULK_CREATION_SOURCE
    || type === UPDATE_CONTACT);
}

function getCreationSource(type: string, exceptionHandler?: () => void): number | null {
  if (!isValidType(type)) {
    exceptionHandler?.();
  }

  if (type === SINGLE_CREATION_SOURCE) {
    return SINGLE_CLIENT_CREATION_SOURCE;
  }
  if (type === BULK_CREATION_SOURCE) {
    return BULK_IMPORT_CREATION_SOURCE;
  }

  return null; // type === UPDATE_CONTACT
}

function noReminderPreference(contact: PartialProviderClient, key: string): boolean {
  return key === 'reminder_preference' && contact[key] === null;
}

function isNecessary(key: string): boolean {
  return _.contains([
    'email',
    'address1',
    'address2',
    'city',
    'state',
    'zipcode',
    'birth_month',
    'birth_day',
  ], key);
}

function isBlockUpdating(contact: PartialProviderClient, key: string): boolean {
  return key === 'blocked' && (contact[key] === true || contact[key] === false);
}

/**
 * Checks to see that the value is non null.  However, email and
 * reminder preferences can accept null during update so they are
 * accepted
 * @param {Object} contact Contact to be added/updated
 * @param {String} field Key (field) on the contact object
 * @returns {Boolean} Returns true if the field is non-null or required for update operation
 */
function keyIsPresentOrNecessary(contact: PartialProviderClient, field: string): boolean {
  return (
    contact[field]
    || isNecessary(field)
    || noReminderPreference(contact, field)
    || isBlockUpdating(contact, field)
  );
}

export function filterEmptyFields(
  contact: PartialProviderClient,
): PartialProviderClient {
  const keys = Object.keys(contact);

  const contactToImport = { id: contact.id };
  keys.forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (contact.hasOwnProperty(key) && keyIsPresentOrNecessary(contact, key)) {
      if (!Array.isArray(contact[key])) {
        contactToImport[key] = contact[key];
      } else if (contact[key].length) {
        contactToImport[key] = contact[key];
      }
    }
  });

  return contactToImport;
}

export function disallowBlank(field: string): string | null {
  return !!field && !!String(field.length) ? field : null;
}

/**
 * Creates contact in structure for CRUD operations.
 * Unused fields will be filtered out
 * @param  {Object} contact Contact/client to be operated on
 * @param  {String} type Only necessary for add operations. Value can be 'single' or 'bulk'
 * @param  {Function} typeExceptionHandler Invoked if `type` is an invalid creation source
 * @returns {Object} client The client/contact object to be sent to server
 */
export function createContactForImport(
  contact: IImportableContact,
  type: string,
  typeExceptionHandler?: () => void,
) {
  let client: PartialProviderClient = {
    id: contact.id,
    name: contact.name,
    phones: getPhonesList(contact),
    email: getEmail(contact),
    reminder_preference: getReminderPreference(contact),
    creation_source: contact.creation_source || getCreationSource(type, typeExceptionHandler),
    address1: disallowBlank(contact.location_address1),
    address2: disallowBlank(contact.location_address2),
    city: disallowBlank(contact.location_city),
    state: disallowBlank(contact.location_state),
    zipcode: disallowBlank(contact.location_zipcode),
    birth_month: getBirthMonth(contact),
    birth_day: getBirthDay(contact),
  };

  client = filterEmptyFields(client);

  return client;
}

const addOrUpdatePhone = (
  phones: ProviderClientPhone[] = [],
  phone: ProviderClientPhone,
): ProviderClientPhone[] => {
  if (!phone.number) {
    return phones;
  }
  let result: ProviderClientPhone[] = phones || [];
  const existingPhoneSettings = (phones || []).find(p => (
    p.type === phone.type
  ));
  if (existingPhoneSettings) {
    result = result.reduce((res, p) => {
      let phoneInfo = p;
      if (p.type === phone.type) {
        phoneInfo = {
          ...p,
          number: phone.number,
        };
      }
      return [
        ...res,
        phoneInfo,
      ];
    }, []);
  } else {
    result = [
      ...result,
      phone,
    ];
  }
  return result;
};

export type PartialProviderClient = Partial<Omit<IProviderClient, 'phones'> & {
  phones: ProviderClientPhone[];
}>;

export type PartialProviderClientWithId = PartialProviderClient & { id: number };

type UpdatedClientPayload = {
  address: Address;
  birthday: string;
  mobilePhoneNumber: string;
  homePhoneNumber: string;
  workPhoneNumber: string;
  name: string;
  preferredPronouns: string;
  email: string;
};

export function updateContactFromInputs(client: PartialProviderClient, {
  address,
  birthday,
  mobilePhoneNumber,
  homePhoneNumber,
  workPhoneNumber,
  name,
  email,
}: Partial<UpdatedClientPayload>): PartialProviderClient {
  const { address: address1, ...restAddress } = address;
  const birthInfo = parseBirthday(birthday);
  const updatedMobilePhones = addOrUpdatePhone(client?.phones, {
    number: mobilePhoneNumber,
    type: ProviderClientPhoneType.Mobile,
  });
  const updatedHomeAndMobilePhones = addOrUpdatePhone(updatedMobilePhones, {
    number: homePhoneNumber,
    type: ProviderClientPhoneType.Home,
  });
  const updatedHomeMobileWorkPhones = addOrUpdatePhone(updatedHomeAndMobilePhones, {
    number: workPhoneNumber,
    type: ProviderClientPhoneType.Work,
  });
  const newClient = {
    ...client,
    address1,
    ...restAddress,
    ...birthInfo,
    name,
    email,
    phones: updatedHomeMobileWorkPhones,
  };

  return newClient;
}
