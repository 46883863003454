import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';

type AppointmentNoShowResponse = {
  code: NSLCCodeChoice.Success;
  status: 'OK';
};

export interface NSLCErrorResponse {
  code: NSLCCodeChoice;
}

export enum NSLCCodeChoice {
  Success = 1,
  FailureCcRejected = 2,
  FailurePastApptTime = 3,
  FailureMismatch = 4,
  FailureBeforeNoShowTime = 5,
  FailureAlreadyCheckedOut = 6,
}

export function markNoShowAppointment(
  appointmentId: number,
  opts: {
    attempt_charge?: boolean;
    ncd_cancellation_survey_reason?: string;
  },
): Promise<AppointmentNoShowResponse> {
  const url = new URL(
    `/api/v1/checkout/appointment/${appointmentId}/mark-no-show`,
    API_ROOT,
  );
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    body: opts,
    includeResponseOnError: true,
  });
}
