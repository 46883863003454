import moment from 'moment';
import {
  compareData,
  fill,
  groupBy,
} from '..';
import { ComparedDataSet } from '../compare';
import { filterByDate } from '../filters';
import type {
  MasterDataSet,
  MonthlyBookingsData,
  StatDataSet,
} from '../types';

export const getMonthlyBookings = (
  stats: MasterDataSet<'appts_booked'>,
): MonthlyBookingsData => {
  const startOfThisMonth = moment().startOf('month');
  const endOfThisMonth = moment().endOf('month');
  const lastMonth = moment().subtract(1, 'month');
  const startOfLastMonth = lastMonth.clone().startOf('month');
  const endOfLastMonth = lastMonth.clone().endOf('month');

  const data = filterByDate<'appts_booked'>(
    stats.appts_booked,
    startOfLastMonth,
    moment(),
  );

  const filledData = fill<'appts_booked'>(
    data as StatDataSet<'appts_booked'>,
    {
      appts_count: 0,
    },
    lastMonth,
    endOfThisMonth,
  );
  const comparedResult = compareData<'appts_booked'>(
    filledData as StatDataSet<'appts_booked'>,
    {
      start: startOfThisMonth,
      end: endOfThisMonth,
    },
    {
      start: startOfLastMonth,
      end: endOfLastMonth,
    },
  );
  const {
    source: currentMonthData,
    compared: lastMonthData,
  } = groupBy<'appts_booked'>(
    comparedResult as ComparedDataSet<'appts_booked'>['appts_booked'],
    'week',
  );

  return {
    currentMonth: currentMonthData,
    lastMonth: lastMonthData,
  };
};
