// @ts-strict-ignore
import TalkJS from 'talkjs';
import useIsEnabled from '../../hooks/useIsEnabled';
import { CHAT_IDS_MIGRATION_DONE } from './constants';
import { ChatRoles, ProfileInfo } from './types';

// Helper functions with business logic
export const styleSeatIdToTalkJsId = (ssUserId: number): string => `${ssUserId}-client`;

export const talkJsIdToStyleSeatId = (talkJsId: string): number => (talkJsId ? Number(talkJsId.split('-')[0]) : null);

// Hooks
export const useGetSsUserOrProviderIdFromTalkJSUserId = (
  talkJSUserId: string,
  role: 'provider' | 'client',
): number => {
  const migrationDone = useIsEnabled(CHAT_IDS_MIGRATION_DONE);
  if (role === 'provider') {
    return Number(talkJSUserId);
  }
  if (migrationDone) {
    return talkJsIdToStyleSeatId(talkJSUserId);
  }
  return Number(talkJSUserId);
};

export const useGetTalkJsUserIdFromSsUserOrProviderId = (
  ssUserOrProviderId: number,
  role: ChatRoles,
): string => {
  const migrationDone = useIsEnabled(CHAT_IDS_MIGRATION_DONE);
  if (role === 'provider') {
    return String(ssUserOrProviderId);
  }
  if (migrationDone) {
    return styleSeatIdToTalkJsId(ssUserOrProviderId);
  }
  return String(ssUserOrProviderId);
};

export const normalizeTalkJSUserInfo = (talkJSUser: TalkJS.UserData): ProfileInfo => ({
  ...talkJSUser as ProfileInfo,
  id: talkJSUser?.role === ChatRoles.Provider
    ? talkJSUser?.id as string
    : String(talkJsIdToStyleSeatId(talkJSUser?.id as string)),
});

export const createTalkJSUser = (userProfileInfo: ProfileInfo): TalkJS.User => new TalkJS.User({
  name: userProfileInfo.name,
  id: userProfileInfo.role === 'client' ? styleSeatIdToTalkJsId(Number(userProfileInfo.id)) : userProfileInfo.id,
});
