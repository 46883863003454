import {
  ENV,
  APP,
  API_ROOT,
} from '../config';

/**
 * Are we running on a production instance?
 * @returns {boolean}
 */
export const isProduction = () => ENV === 'production';

/**
 * Are we running on a stage instance?
 * @returns {boolean}
 */
export const isStage = () => ENV === 'staging';

/**
 * Are we running on a LC instance?
 * @returns {boolean}
 */
export const isLC = () => ENV === 'LC';

/**
 * Is this a production-like environment, like staging, ci or production itself.
 * @returns {boolean}
 */
export const isProductionLike = () => isProduction() || isStage() || isLC();

/**
 * Is this a test server?
 * @returns {boolean}
 */
export const isTest = () => ENV === 'test';

/**
 * Is this a test server?
 * @returns {boolean}
 */
export const isPreStage = () => !isProduction() && !isStage();

/**
 * Returns the root URL for this environment.
 * @returns {String}
 */
export const getExternalLinkOrigin = () => {
  if (APP) {
    return API_ROOT;
  }
  return window.location.origin || API_ROOT;
};
