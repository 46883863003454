import { useContext, useMemo } from 'react';
import { SnackbarContext, SnackbarContextType } from '../components/shared/SnackbarContext';

export type SnackbarFunctions = {
  open: SnackbarContextType['open'];
  close: SnackbarContextType['close'];
};

/**
 * A hook for accessing functionality to open and close a snackbar.
 */
export default function useSnackbar(): SnackbarFunctions {
  const ctx = useContext<SnackbarContextType>(SnackbarContext);

  return useMemo(() => ({
    open: ctx.open,
    close: ctx.close,
  }), [ctx]);
}
