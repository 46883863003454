/**
 * Wrapper around cordova's InAppBrowser with helpful utilities, such as location tracking
 * and a postMessage method.
 */

export default class SSInAppBrowser {
  static enabled() {
    return !!(window.cordova && window.cordova.InAppBrowser);
  }

  static open(...args) {
    const inAppBrowser = window.cordova.InAppBrowser.open(...args);
    const ssInAppBrowser = new SSInAppBrowser(inAppBrowser);
    ssInAppBrowser.location = new URL(args[0]);
    ssInAppBrowser.addEventListener('loadstart', ssInAppBrowser.onLoadStart, false);
    ssInAppBrowser.addEventListener('exit', ssInAppBrowser.onExit, false);
    return ssInAppBrowser;
  }

  location = null;

  constructor(inAppBrowser) {
    this.inAppBrowser = inAppBrowser;
  }

  onLoadStart = event => {
    this.location = new URL(event.url);
  };

  onExit = () => {
    this.closed = true;
    this.cleanup();
  };

  cleanup() {
    this.removeEventListener('loadstart', this.onLoadStart, false);
    this.removeEventListener('exit', this.onExit, false);
  }

  postMessage(message, targetOrigin) {
    const code = `(function(){
      var message = JSON.parse(decodeURIComponent("${encodeURIComponent(JSON.stringify(message))}"));
      window.postMessage(message, decodeURIComponent("${encodeURIComponent(targetOrigin)}"));
    })();`;
    return this.executeScript({
      code,
    });
  }

  dispatchEvent(...args) {
    // eslint-disable-next-line no-underscore-dangle
    return this.inAppBrowser._eventHandler(...args);
  }

  addEventListener(...args) {
    return this.inAppBrowser.addEventListener(...args);
  }

  removeEventListener(...args) {
    return this.inAppBrowser.removeEventListener(...args);
  }

  close(...args) {
    this.cleanup();
    const value = this.inAppBrowser.close(...args);
    try {
      // Execute a script to close as well, iOS InAppBrowser doesn't seem to respect
      // our attempt to close the window.
      const code = `(function(){
        window.close();
      })();`;
      this.executeScript({
        code,
      });
    } catch (err) {
      console.error(err);
    }
    return value;
  }

  show(...args) {
    return this.inAppBrowser.show(...args);
  }

  hide(...args) {
    return this.inAppBrowser.hide(...args);
  }

  executeScript(...args) {
    return this.inAppBrowser.executeScript(...args);
  }

  insertCSS(...args) {
    return this.inAppBrowser.insertCSS(...args);
  }
}
