// @ts-strict-ignore
import { isEqual } from 'underscore';
import * as config from '../../config';
import breakpoints from '../responsive_config';
import {
  BreakpointStates,
  LayoutState,
  ResponsiveListener,
} from './Responsive.types';
import { listenToWidth } from './listenToWidth';
import { waitForReady } from './waitForReady';
import { getWidth } from './getWidth';

/**
 * Convert dash-case to camelCase
 */
const toCamelCase = str => {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/-([a-z])/g, match => match[1].toUpperCase());
};

/**
 * Validate if the browser window width is within the responsive
 * breakpoint range.
 * @param {Object} breakpoint - Object with a min and/or max value.
 * @return {boolean}
 */
function widthMatchesBreakpoint(
  width: number,
  breakpoint: { min: number; max: number },
) {
  if (breakpoint.min && width < breakpoint.min) {
    return false;
  }
  if (breakpoint.max && width > breakpoint.max) {
    return false;
  }
  return true;
}

function createBreakpointStates(): BreakpointStates {
  return {
    mobile: false,
    mobileNarrow: false,
    tablet: false,
    desktop: false,
    desktopNarrow: false,
    wide: false,
  };
}

/**
 * A simple interface for determining the current responsive layout,
 * based on the same breakpoint widths that the CSS uses.
 *
 * NOTE: This should be used sparingly. In almost all cases
 * if you're using this instead of CSS, something is probably wrong.
 *
 * @example
 * ```
 *   import Responsive from 'modules/Responsive';
 *
 *   console.log(Responsive.layout.mobile);
 *
 *   // Be notified of when responsive values change
 *   Responsive.changeStream.subscribe(() => {
 *     console.log('Responsive breakpoints changed!');
 *   });
 * ```
 */
class ResponsiveModule {
  #isDomReady: boolean = false;
  /**
   * What breakpoint widths does the window size currently match
   */
  is: BreakpointStates = createBreakpointStates();

  listeners: Array<ResponsiveListener> = [];

  orderedBreakpoints = [
    'mobile',
    'tablet',
    'desktop',
  ];

  /**
   * The general layout the user is viewing: mobile or desktop.
   *
   * This is determined off the tablet responsive breakpoint.
   * Tablet width and below is mobile, else it's desktop.
   * Defaults to 'mobile'
   */
  layout: LayoutState = {
    mobile: true,
    desktop: false,
  };

  constructor() {
    listenToWidth(this.checkBreakpoints);
  }

  listen(listener: ResponsiveListener): () => void {
    this.listeners.push(listener);
    listener(this.is);

    return () => {
      this.listeners.filter(value => value !== listener);
    };
  }

  /**
   * Check the current browser width against the list of breakpoints.
   */
  checkBreakpoints = (width: number) => {
    const is = createBreakpointStates();

    // Check all breakpoints.
    Object.entries(breakpoints)
      .forEach(([name, range]) => {
        const key = toCamelCase(name);
        is[key] = widthMatchesBreakpoint(width, range as { min: number; max: number });

        // APP is always mobile, mobile narrow, or tablet
        if (config.APP && key !== 'mobileNarrow' && key !== 'mobile' && key !== 'tablet') {
          is[key] = false;
        }
      });

    // If APP doesn't match mobile, assume tablet
    if (config.APP && !is.mobile) {
      is.tablet = true;
    }

    // Set layout
    this.layout.desktop = is.desktop;
    this.layout.mobile = !is.desktop;

    const didChange = !isEqual(this.is, is);

    this.is = is;

    // Fire change stream
    if (didChange) {
      this.listeners.forEach(listener => listener(this.is));
    }

    return this;
  };

  /**
   * Chainable Promise that ensures the DOM is loaded before calculating layout info
   * @param {Number} interval
   * @param {Number} timeout
   */
  async isReady(interval = 50, timeout = 500): Promise<ResponsiveModule> {
    if (this.#isDomReady) {
      return this;
    }

    return waitForReady<ResponsiveModule>(this, interval, timeout).then(
      () => {
        this.#isDomReady = true;
        return this.checkBreakpoints(getWidth());
      },
    );
  }
}

export const Responsive = new ResponsiveModule();
