import { AppointmentRequestResponse } from '../api/AppointmentRequest';
import { PaymentMethodType } from '../components/consumer/booking/BookingFlow/types';
import { SmartPricingPreferences } from '../components/provider/smartPricing/types';
import { AutochargeStatus, PrepayStatus } from '../api/Users/Appointments';
import { Moment } from '../modules/dateUtils';
import { ClientPreferredCheckoutType } from '../api/Providers/Appointments';
import type { ProviderService } from '../api/Providers/Services';
import type { ICard } from './PaymentMethods';
import type { ProviderLocationCoords, PublicProvider } from '../api/Providers';
import type { DateTimeStamp } from '../types/dateTime';
import { IDiscountDisplay } from './ClientPromotions.types';

export interface IBookingConfirmation {
  status: 'OK';

  timeblocks: Array<{
    timeblock_id: number;
    is_expresspay: boolean;
    is_autocheckout: boolean;
    cost: number;
    service_name: string;
    service: number;
  }>;
  cost: string;

  appointment_id: number;
  provider_id: number;
  client_id: number;
  client_handle?: string;
  gcal_link: string;
  user_id: number;
  is_user_first_booking_with_pro: boolean;
  is_client_first_booking_with_pro: boolean;
  prepay_amount: number;
  cancellation_charge: number;
  noshow_charge: number;
  autocharge_status: AutochargeStatus;
  local_start: string; // in date format
  local_end: string; // in date format
  prepay_status: PrepayStatus;
  location: ProviderLocationCoords & {
    zipcode?: string;
  };
  is_monetized_new_client_delivery?: boolean;
  client_requested_checkout_type: ClientPreferredCheckoutType;
  manage_appointment_token: string;
  smart_priced?: boolean;
  smart_price_upcharge?: number;
  smart_price_upcharge_cap?: number;
  smart_price_take_rate?: number;
  smart_price_source?: string;
  booking_fee_charged?: number;
  payment_intent_client_secret?: string;
  revenue_take: string;
}

export enum BookingStatus {
  /** A field is missing, payment not selected, etc */
  NotReady = 'not-ready',
  /** An error has occurred attempting to book */
  Error = 'error',
  /** All fields filled, booking can proceed */
  Ready = 'ready',
  /** User needs to supply a phone number */
  RequestingPhoneNumber = 'phone-number',
  /** Prompt user for different card after auth failure (RES-119) */
  UpdatePaymentMethod = 'update-payment-method',
  /** User needs to agree to NSLC specifics */
  NSLCConfirmation = 'nslc-confirm',
  /** A booking is in progress */
  Pending = 'pending',
  /** A booking has completed successfully */
  Completed = 'completed',
}

export type BookingService = Pick<ProviderService, 'id' | 'cost' | 'duration_minutes' | 'name' | 'cost_and_up' | 'servicegroup' | 'blurb'> & {
  addOns?: ProviderService[];
};
export type BookingState = {
  services: BookingService[];
  slot?: number;
  date?: DateTimeStamp;
  checkoutPreference?: ClientPreferredCheckoutType;
  smartPrice?: SmartPricingPreferences;
  forceServerSmartPricingDisabled?: boolean;
  atl4064Variant?: string;
  note?: string;
  shouldChargePrepayment: boolean;
  status: BookingStatus;
  error?: Error;
  readyToBook: boolean;
  stripeCvcToken?: number | string;
  stripeInstrumentId?: number | string;
  stripeInstrumentValid?: boolean;
  smartPriceSource?: number | string;
  smsOptInShown?: boolean;
  confirmation?: IBookingConfirmation;
  selectedCard?: ICard;
  bookingFee?: string;
  paymentMethodType?: PaymentMethodType | null;
  tipPercentage?: number;
  tipAmount?: number;
  shouldAuthFailureHaltBooking?: boolean;
  cartTotals?: AppointmentRequestResponse['totals'];
  cartMetadata?: AppointmentRequestResponse['metadata'];
  cartLoaded?: boolean;
  rescheduleAppointmentId?: number;
};

export type BookingData = {
  activeBooking: BookingState;
  startMoment: Moment | null;
  endMoment: Moment | null;
};

export type BookingInfo = {
  activeBooking: BookingState;
  activeProvider: PublicProvider;
  appliedPromotion: IDiscountDisplay;
};
