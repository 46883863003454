// @ts-strict-ignore
import moment from 'moment';
import PromiseLock from '../PromiseLock';
import * as storage from '../KeyValueStorage';
import {
  SetCurrentUserPayload,
  WhoAmI,
  isWhoAmIAnonymous,
  isWhoAmIPro,
} from '../../store/CurrentUser.types';

type Listener<T = any> = (value: T) => void;

interface ClientStateValue<T = any> {
  action: string;
  value: T;
  user_id?: number;
  pro_id?: number;
  timestamp: string;
}

type WhoAmIEffect = (
  payload?: { force?: boolean; reIdentify?: boolean },
) => Promise<SetCurrentUserPayload>;

type DispatchWithUpdateUser = {
  user: {
    whoami: WhoAmIEffect;
  };
};

interface UserSource {
  whoami: WhoAmIEffect;
  retrieveCurrentUser: () => WhoAmI;
}

const CACHE_TIME_MS = 30 * 1000;

/**
 * This module provides user state caching for cross-device state management.
 *
 * It relies on caching state in S3 using user-specific presigned urls for access control.
 *
 * Cache data is stored in newline-separated JSON-log format in an S3 object.
 *
 * User state is cached in k/v format. Each item (action) is defined as a
 * resultant value indicating the user took some action. The value of
 * actions are freeform (anything that will serialize to json).
 *
 * AWS imposes transfer costs to _all_ incoming traffic (not just to S3).
 * Don't abuse the storage call. Be smart about when you call saveActions.
 *
 * @example
 * ```js
 *  import StateManager from 'modules/user/stateManager';
 *  stateManager.getActions()
 *     .then( () => stateManager.addAction("foo", "bar") )
 *      .then(stateManager.getActions)
 *      .then(() => stateManager.getActionValue("foo"))
 *      .then( (value) => {
 *         alert(value);
 *      })
 *      .catch( (e) => {
 *          alert(e.message)
 *      });
 * ```
 *
 * @deprecated Please use the UserState redux model
 * */
export class StateManager {
  lastUserId: number;
  storage: typeof storage;
  clientState: Record<string, ClientStateValue>;
  listeners: Record<string, Array<Listener>>;
  userSource: UserSource;

  /**
   * Create a new StateManager. This constructor allows overriding of the default user retrieval
   * functions in angular land.
   * @param {Object} userSource Object with `whoami` and `retrieveCurrentUser` functions.
   */
  constructor() {
    this.lastUserId = -1;
    this.storage = storage;
    this.listeners = {};

    storage.getItem<Record<string, ClientStateValue>>('client_state').then(clientState => {
      this.setClientState(clientState);
    });
  }

  updateUser(dispatch: DispatchWithUpdateUser, userState) {
    if (this.lastUserId !== userState.userId) {
      if (this.lastUserId !== -1) {
        this.clearCache();
        this.storeLocalState(null);
      }
      this.lastUserId = userState.userId;
    }

    this.userSource = {
      whoami: dispatch.user.whoami,
      retrieveCurrentUser: () => userState,
    };
  }

  addActionLock = new PromiseLock();

  saveActionsLock = new PromiseLock();

  getActionsPromise = null;

  cache: Record<string, ClientStateValue> = null;

  cacheBuster = null;

  /**
   * Persist actions to S3 in JSON-log format
   *
   * @returns {Promise}
   */
  saveActions() {
    // Save cached actions to S3
    let resolve;
    let reject;
    let flatState = '';
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    this.saveActionsLock.acquireForPromise(promise).then(() => {
      storage.getItem('client_state').then(clientState => {
        if (clientState === undefined) {
          // no state to save, no-op
          resolve();
          return;
        }

        Object.keys(clientState).forEach(key => {
          const stateObj = clientState[key];
          flatState += `${JSON.stringify(stateObj)}\n`;
        });

        this.userSource.whoami()
          .then(() => {
            const user = this.userSource.retrieveCurrentUser();
            const url = isWhoAmIAnonymous(user) ? null : user.state_post_url;
            if (!url) {
              resolve();
              return;
            }

            fetch(
              url,
              {
                method: 'PUT',
                body: flatState,
              },
            )
              .then(resolve, reject)
              .then(() => {
                // Update ssDevTools
                if (typeof window !== 'undefined'
                  // @ts-expect-error
                  && typeof window.ssDevTools !== 'undefined'
                  // @ts-expect-error
                  && window.ssDevTools.loadUserState
                ) {
                  try {
                    // @ts-expect-error
                    window.ssDevTools.loadUserState();
                  } catch (err) {
                    // we don't really care if an error happens in devtools
                  }
                }
              });
          }, reject);
      }).catch(reject);
    });
    return promise;
  }

  /**
   * Retrieve cached actions from S3
   *
   * @returns {Promise}
   */
  getActions(): Promise<Record<string, ClientStateValue>> {
    if (this.cache && typeof this.cache === 'object') {
      return Promise.resolve(this.cache);
    }

    if (this.getActionsPromise) {
      return this.getActionsPromise;
    }

    this.getActionsPromise = new Promise((resolve, reject) => {
      this.userSource.whoami()
        .then(() => {
          const user = this.userSource.retrieveCurrentUser();
          const url = isWhoAmIAnonymous(user) ? null : user.state_get_url;
          if (!url) {
            resolve({});
            return;
          }
          fetch(url, {
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
            },
          })
            .then(response => response.text())
            .then(data => {
              // Parse flattened json state
              const clientState = {};

              data.split('\n').forEach(line => {
                try {
                  const entry = JSON.parse(line);
                  if (entry.action) {
                    clientState[entry.action] = entry;
                  }
                } catch (err) {
                  // We really don't care? If there's bad state we want to throw it away
                  // May want to fire a notification of some kind
                }
              });

              this.setCache(clientState);
              this.storeLocalState(clientState);
              resolve(clientState);
            }).catch(response => {
              if (response.status === 404) {
                this.storeLocalState({});
                resolve({});
              } else {
                reject(response);
              }
            });
        })
        .catch(reject)
        .finally(() => {
          this.getActionsPromise = null;
        });
    });
    return this.getActionsPromise;
  }

  /**
   * Add multiple actions to the local state cache, update remote cache
   *
   * @param {Object<{action: value}>} actions - The actions hash
   * @returns {Promise}
   */
  addActions(actions: Record<string, any>) {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    this.addActionLock.acquireForPromise(promise).then(() => {
      const currentUser = this.userSource.retrieveCurrentUser();
      storage.getItem('client_state').then(localState => {
        const addActionsToState = state => {
          const newState = { ...state };
          const userId = !isWhoAmIAnonymous(currentUser) ? currentUser.user_id : undefined;
          const providerId = isWhoAmIPro(currentUser) ? currentUser.provider_id : undefined;
          Object.entries(actions).forEach(([action, value]) => {
            newState[action] = {
              action,
              value,
              user_id: userId,
              pro_id: providerId,
              timestamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            };
          });
          this.storeLocalState(newState);
          this.saveActions()
            .then(() => {
              this.getActionsPromise = null;
              this.clearCache();
              resolve();
            })
            .catch(reject);
        };

        if (localState) {
          addActionsToState(localState);
        } else {
          this.getActions().then(addActionsToState);
        }
      }).catch(reject);
    });
    return promise;
  }

  /**
   * Add an action to the local state cache, update remote cache
   *
   * @param {String} action - The action name
   * @param value - The value to save for this action (must be JSON serializable)
   * @returns {Promise}
   */
  addAction = (action: string, value: any) => this.addActions({ [action]: value });

  /**
   * Retrieve action values from the local cache, fetching from S3 if necessary
   *
   * @param {Array<String>} keys - The action keys - i.e., getActionValues(key1, key2, key3)
   * @returns {Promise}
   */
  getActionValues(...keys): Promise<Record<string, any>> {
    return new Promise((resolve, reject) => {
      if (!keys.length) {
        resolve({});
        return;
      }
      storage.getItem('client_state').then(localState => {
        if (localState) {
          const state = {};
          keys.forEach(key => {
            if (key in localState) {
              state[key] = localState[key].value;
            }
          });
          // all items were found in local store, resolve
          if (Object.keys(state).length === keys.length) {
            resolve(state);
            return;
          }
        }
        // items missing from S3, fetch them
        this.getActions().then(remoteState => {
          const actionValues = {};
          keys.forEach(key => {
            if (key in remoteState) {
              actionValues[key] = remoteState[key].value;
            } else {
              // default to undefined
              actionValues[key] = undefined;
            }
          });
          resolve(actionValues);
        }).catch(reject);
      }).catch(reject);
    });
  }

  /**
   * Retrieve an action value from the local cache, fetching from S3 if necessary
   *
   * @param {String} key - The action key
   * @returns {Promise}
   */
  getActionValue<T = any>(key): Promise<T> {
    return this.getActionValues(key).then(hash => hash[key] as T);
  }

  private setClientState(newClientState: Record<string, ClientStateValue>) {
    this.clientState = newClientState;

    Object.entries(this.listeners)?.forEach(([key, listeners]) => {
      if (this.clientState !== null) {
        const value = this.clientState[key]?.value || null;

        if (listeners !== null) {
          listeners.forEach(listener => listener(value));
        }
      }
    });
  }

  /**
   *
   * @param key The key to listen to
   * @param listener Function called with new value whenever a change to the given key occurs. This
   * function is also called immediately with the current value
   * @returns A function to call to stop listening for updates with the given function.
   */
  listenForUpdates<T = any>(key: string, listener: Listener<T>): () => void {
    this.listeners[key] ??= [];
    this.listeners[key].push(listener);

    if (this.clientState) {
      listener(this.clientState[key]?.value || null);
    } else {
      // No actions yet, skip this update and trigger a fetch. Update
      // will be re-triggered when the fetched values are stored in local
      // storage.
      this.getActionValue(key);
    }

    return () => {
      this.listeners[key] = this.listeners[key].filter(value => value !== listener);
    };
  }

  /**
   * Store user state in local storage and stream.
   * @param {Object} newState - the actions hash to store in local storage and stream
   */
  storeLocalState(newState: Record<string, ClientStateValue>) {
    storage.setItem('client_state', newState).then(() => {
      this.setClientState(newState);
    });
  }

  /**
   * Cache action data to memory and clear it after CACHE_TIME_MS milliseconds
   *
   * @param {Object} data - Data to cache.
   */
  setCache(data) {
    this.clearCache();
    this.cache = data;
    this.cacheBuster = setTimeout(() => this.clearCache(), CACHE_TIME_MS);
  }

  /**
   * Clear data cached in memory.
   */
  clearCache() {
    this.cache = null;
    this.getActionsPromise = null;
    if (this.cacheBuster) {
      clearTimeout(this.cacheBuster);
      this.cacheBuster = null;
    }
  }
}

/**
 * @deprecated Please use the UserState redux model
 */
export const stateManager = new StateManager();

export default stateManager;
