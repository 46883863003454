import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { DayOfWeek } from '../../../types/dateTime';

export type ProviderAvailability = {
  timezone: string;
  enabled_online_booking: boolean;
  working_days: ProviderAvailabilityWorkingDay[];
};

export type ProviderAvailabilityWorkingDay = {
  day_of_week: DayOfWeek;
  work: boolean;
  start_time: string;
  end_time: string;
  start_lunch?: string;
  end_lunch?: string;
};

export const getAvailability = (providerId: number | string) => {
  const url = new URL(`/api/v1/provider/${providerId}/availability.json`, API_ROOT);

  return ssFetchJSON<ProviderAvailability>(
    url.toString(),
  );
};
