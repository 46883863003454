// @ts-strict-ignore
import { API_ROOT, ENV } from '../config';
import { ssFetchJSON } from './ssFetch';
import { openExternalUrl } from './openExternalUrl';

/**
 * Regex that matches strings such as:
 *
 * https://styleseat.com
 * httpsionic://app-internal.styleseat.com/foo
 * http://ev2899.lc.styleseat.rocks
 */
const styleSeatOriginPattern = /^https?(ionic)?:\/\/(\.?[\w-]+\.){0,2}styleseat\.(com|rocks)/i;

/**
  * Regex that matches strings such as:
  *
  * http://192.168.1.1
  * https://10.0.0.3/foo
  * https://127.0.0.1/bar
  */
const localIpPattern = /^https?:\/\/(10|127|192)\.\d+\.\d+\.\d+/i;

export interface IDeepLinkEvent {
  path: string;
  params: Record<string, any>;
  url: string;
}

/**
 * Returns true if the path should only open on the web site, false if it can
 * open on web site or app.
 * @param {string} path - a URL path
 */
function isWebOnlyPath(path: string): boolean {
  return (
    path.indexOf('/ssadminx') === 0
    || path.indexOf('/join') === 0
  );
}

/**
 * Return true if the given URL is a StyleSeat URL that can be opened in app,
 * or is a URL for a local IP address (and ENV=development).
 * Returns false otherwise.
 * @param {string} url - a URL such as 'http://www.styleseat.com'
 */
function canOpenInApp(url: string): boolean {
  const u = new URL(url);
  return (
    !isWebOnlyPath(u.pathname)
    && (
      styleSeatOriginPattern.test(url)
      || (ENV === 'development' && localIpPattern.test(url))
    )
  );
}

const expandShortLink = async (path: string): Promise<URL> => {
  const apiUrl = `${API_ROOT}/api/v1/expand-short-url${path}`;
  const response = await ssFetchJSON(apiUrl);
  return new URL(response.url);
};

/**
 * Handle incoming direct links to web URLs that can be opened in the app.
 * Currently supports iOS >= 9.
 * Requires cordova-universal-links-plugin.
 */
class UniversalLinks {
  private static instance: UniversalLinks;

  private constructor() {
    // empty
  }

  public static getInstance(): UniversalLinks {
    if (!UniversalLinks.instance) {
      UniversalLinks.instance = new UniversalLinks();
    }

    return UniversalLinks.instance;
  }

  appInit(trackEvent: typeof UniversalLinks.prototype.trackEvent) {
    this.lastPath = undefined;
    this.lastParams = {};
    this.trackEvent = trackEvent;
  }

  angularInit(onLocationUpdate: typeof UniversalLinks.prototype.onLocationUpdated) {
    this.onLocationUpdated = onLocationUpdate;
  }

  lastPath?: string = undefined;

  lastParams: Record<string, any> = {};

  onLocationUpdated?: (path: string, params: Record<string, any>) => Promise<any>;

  trackEvent?: (eventName: string, params: Record<string, any>) => Promise<any>;

  handleFullLinkRequested = (event: IDeepLinkEvent) => {
    // Strip leading /m
    const path = event.path.replace(/^\/m\//i, '/');

    this.lastPath = path;
    this.lastParams = event.params;
    if (typeof this.onLocationUpdated === 'function') {
      this.onLocationUpdated(this.lastPath, this.lastParams).finally(() => {
        this.lastPath = null;
        this.lastParams = {};
      });
    }

    this.trackEvent?.('app_deep_link_to_styleseat', { event });
  };

  handleShortLinkRequested = (event: IDeepLinkEvent) => {
    /**
     * An incoming link that triggers this will look like `styl.se/AbCdEf`
     * or `link.styleseat.rocks/bFdggg`
     * This function accesses a styleseat api endpoint that returns the redirect location of the
     * short link, then redirects to that url if it's within our app (https://styleseat.com domain)
     * or opens a new in-app browser window if it's not.
     */
    expandShortLink(event.path).then((url: URL) => {
      if (canOpenInApp(url.toString())) {
        // if it's within styleseat, send to path within the app
        this.trackEvent?.('app_deep_link_short_link_to_styleseat', { event, url });

        this.lastPath = url.pathname.replace(/^\/m\//i, '/');
        this.lastParams = {};
        url.searchParams.forEach((value, key) => { this.lastParams[key] = value; });

        if (typeof this.onLocationUpdated === 'function') {
          this.onLocationUpdated(this.lastPath, this.lastParams).finally(() => {
            this.lastPath = null;
            this.lastParams = {};
          });
        }
      } else {
        // if it's a valid shortlink but not to be opened in-app
        // (i.e., zendesk, Django admin, etc), redirect there.
        this.trackEvent?.('app_deep_link_short_link_to_external', { event, url });
        openExternalUrl(url.toString(), '_blank');
      }
    }).catch(err => {
      // if we get backend error, just open previous page
      this.trackEvent?.('app_deep_link_short_link_error', { event, err });
      openExternalUrl(event.url, '_blank');
    });
  };

  /**
   * Get most recent universal link path.
   *
   * @name getPath
   * @returns {string} path
   */
  getPath(): string {
    return this.lastPath;
  }

  getParams(): Record<string, any> {
    return this.lastParams;
  }
}

export default UniversalLinks.getInstance();
