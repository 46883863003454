// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { formatProviderPlanConfiguration, ProviderPlanConfiguration } from '../../modules/provider/ProviderPlanConfiguration';
import { ProviderPlan } from '../../types/provider';
import {
  billingHistoryHasPendingPayment,
  buildBillingHistoryItems,
  getDaysRemaining,
  getIntoLateTrial,
  getPrice,
  getStatusFromSubscriptionState,
  SubscriptionStatus,
  trackSubscriptionSuccess,
} from '../../modules/provider/Subscription';
import { IProviderSignupSettingsData } from '../../modules/Api.types';
import {
  loadSignupSettings,
  reenrollProSubscription,
  startProSubscription,
  updateStripeInstrument,
} from '../../api/Providers/SignupSettings';
import type { RootModel } from '../models';
import {
  APISubscriptionCharge,
  getSubscriptionCharges,
  SubscriptionChargeStatus,
} from '../../api/Providers/SubscriptionCharges';
import analytics from '../../modules/analytics';
import { IProMembershipProgram, updateProviderSelectedPlan } from '../../api/Providers/Plan';
import { InstrumentFilter } from '../../api/Users/Instruments';
import { ICard } from '../PaymentMethods';
import {
  APISubscriptionCoupon,
  applySubscriptionCoupon,
  fetchCoupon,
} from '../../api/Providers/SubscriptionCoupon';
import nonCriticalException from '../../modules/exceptionLogger';
import { ProviderRecord } from '../Providers.model';
import { PrivilegedProvider } from '../../api/Providers';
import { getProMembershipPrograms } from '../../api/ProMembershipPrograms';
import { calculateCanShowSubscriptionFeatures } from '../../hooks/useShowSubscriptionFeatures';

export const PROVIDER_ONBOARDING_EXPERIENCE: string = 'pro_onboarding_experience_apo4294_20240305';

const buildStoredPaymentMethod = (storedMethodId: number, methods: ICard[]): ICard | null => (
  ((methods || []).find(method => method.id === storedMethodId) || null)
);

export interface DerivedProviderPlanState {
  status: SubscriptionStatus;
  data: IProviderSignupSettingsData;
  provider_id: number;
  price: number;
  subscription_state: string | null;
  daysRemaining: number;
  isIntoLateTrial: boolean;
  requiresPaymentUpdate: boolean;
  charges: APISubscriptionCharge[];
  coupon: APISubscriptionCoupon | undefined;
  planConfigurations: Array<IProMembershipProgram>;
}

interface LoadingProviderPlanState extends Partial<DerivedProviderPlanState> {
  loading: true;
}

interface LoadedProviderPlanState extends DerivedProviderPlanState {
  loading: false;
}

export type ProviderPlanState = LoadingProviderPlanState | LoadedProviderPlanState;

function getInitialState(): ProviderPlanState {
  return {
    loading: true, charges: [],
  };
}

type OnPlanLoadedPayload = {
  providerId: number;
  signupSettings: IProviderSignupSettingsData;
};

type LoadPlanPayload = {
  providerId: number;
};

type UpdatePlanPayload = {
  providerId: number;
  providerPlan: string;
  plan: ProviderPlanConfiguration;
  isRestoringSubscription?: boolean;
  paymentMethod?: ICard;
  withoutRedirect?: boolean;
};

type ApplyCouponPayload = {
  providerId?: number;
  couponId?: string;
};

type LoadCouponPayload = {
  providerId?: number;
  couponId?: string;
};

export type SubscriptionCharge = Pick<APISubscriptionCharge,
'id' | 'amount_dollars' | 'external_created'> & {
  status: SubscriptionChargeStatus | 'upcoming';
  source?: string;
};

export const providerPlan = createModel<RootModel>()({
  name: 'providerPlan',
  state: getInitialState(),
  reducers: {
    onPlanLoading: (state: ProviderPlanState): ProviderPlanState => ({
      ...state,
      loading: true,
      charges: [],
    }),
    onPlanLoaded: (state: ProviderPlanState, payload: OnPlanLoadedPayload): ProviderPlanState => {
      const { providerId, signupSettings } = payload;
      return {
        ...state,
        loading: false,
        charges: state.charges || [],
        coupon: state.coupon || undefined,
        status: getStatusFromSubscriptionState(signupSettings.subscription_state),
        data: signupSettings,
        provider_id: providerId,
        price: getPrice(signupSettings),
        subscription_state: signupSettings.subscription_state,
        daysRemaining: getDaysRemaining(signupSettings),
        isIntoLateTrial: getIntoLateTrial(signupSettings),
        requiresPaymentUpdate: signupSettings.requires_payment_update,
        planConfigurations: state.planConfigurations,
      };
    },
    onChargesLoaded: (
      state: ProviderPlanState,
      payload: APISubscriptionCharge[],
    ): ProviderPlanState => ({
      ...state,
      charges: payload,
    }),
    onCouponLoaded: (
      state: ProviderPlanState,
      payload: APISubscriptionCoupon,
    ): ProviderPlanState => ({
      ...state,
      coupon: payload,
    }),
    onPlanConfigurationsLoaded: (
      state: ProviderPlanState,
      payload: Array<IProMembershipProgram>,
    ): ProviderPlanState => ({
      ...state,
      planConfigurations: payload,
    }),
  },
  effects: dispatch => ({
    async loadPlan(
      { providerId }: LoadPlanPayload,
    ): Promise<void> {
      await dispatch.providerPlan.onPlanLoading();
      await dispatch.subsBlocker.update();
      const signupSettings = await loadSignupSettings(providerId);

      const charges = await getSubscriptionCharges(providerId);
      await dispatch.providerPlan.loadPlanConfigurationData();
      await dispatch.providerPlan.onChargesLoaded(charges);
      await dispatch.paymentMethods.loadFiltered(InstrumentFilter.ChargeFee);

      await dispatch.providerPlan.onPlanLoaded({ providerId, signupSettings });
    },

    async applyCoupon({ providerId, couponId }: ApplyCouponPayload): Promise<void> {
      if (!providerId || !couponId) {
        return;
      }

      try {
        const coupon = await applySubscriptionCoupon(providerId, couponId);
        dispatch.providerPlan.onCouponLoaded(coupon);
      } catch (err) {
        // NOOP: No matching coupon found
        if (err?.code === 404) return;

        nonCriticalException(err);
      }
    },

    async loadCoupon({
      providerId,
      couponId,
    }: LoadCouponPayload): Promise<void> {
      try {
        const parsedCouponId = (
          typeof couponId === 'boolean' || [
            'true',
            'false',
          ].includes(couponId)
        ) ? undefined : couponId;

        if (!providerId && !parsedCouponId) throw new Error('Provider ID or Coupon ID must be provided');

        const coupon = await fetchCoupon(providerId, parsedCouponId);

        if (coupon) dispatch.providerPlan.onCouponLoaded(coupon);
      } catch (err) {
        if (err?.code === 404) return null; // No matching coupon found

        nonCriticalException(err);
      }
    },

    async loadPlanConfigurationData(): Promise<void> {
      const canShowSubscriptionFeatures = await calculateCanShowSubscriptionFeatures();
      let planConfigurationData = await getProMembershipPrograms();
      if (!canShowSubscriptionFeatures) {
        // If this is iOS app and subscription features are hidden
        // then override backend's default_program with commission/basic plan.
        planConfigurationData = planConfigurationData.map(
          p => {
            const plan = { ...p };

            if (plan.plan_name === ProviderPlan.Commission) {
              plan.default_program = true;
            } else {
              plan.default_program = false;
            }
            return plan;
          },
        );
        // Hide deluxe/premium plan on iOS app when subscription features are hidden
        planConfigurationData = planConfigurationData.filter(
          p => p.plan_name !== ProviderPlan.Deluxe,
        );
      }

      dispatch.providerPlan.onPlanConfigurationsLoaded(planConfigurationData);
    },

    async updateProviderSelectedPlan(
      payload: { providerId: number; plan: string },
    ): Promise<Response> {
      const { providerId, plan } = payload;
      return updateProviderSelectedPlan(providerId, plan);
    },

    async updatePlan(payload: UpdatePlanPayload, rootState): Promise<void> {
      const {
        plan,
        providerPlan: currentPlan,
        providerId,
        isRestoringSubscription,
        withoutRedirect,
      } = payload;

      // We're restoring the pro
      if (isRestoringSubscription) {
        await dispatch.subsBlocker
          .unblock({
            plan,
            withoutRedirect,
            stripeInstrumentId: payload.paymentMethod?.id || null,
          });
        await dispatch.user.whoami({ force: true });

        analytics.track('pro_restoration_successful', {
          plan: plan?.plan_name,
          eligibleForTrial: false,
        });

        return;
      }

      // Pro's plan is changing
      if (plan && plan.plan_name !== currentPlan) {
        // Update the plan, then the payment instrument if necessary
        await dispatch.providerPlan.updateProviderSelectedPlan({
          providerId,
          plan: plan.plan_name,
        });

        if (payload.paymentMethod && plan.numericAmount > 0) {
          await updateStripeInstrument(providerId, payload.paymentMethod.id);
        }

        await dispatch.user.whoami({ force: true });
        await dispatch.providerPlan.loadPlan({
          providerId,
        });

        return;
      }

      const subscriptionStatus = (
        rootState.providerPlan.loading
          ? null
          : (rootState.providerPlan as LoadedProviderPlanState).status
      );

      // if the subscription is "active"
      if (
        payload.paymentMethod && subscriptionStatus === SubscriptionStatus.Active) {
        // Update the payment instrument
        await updateStripeInstrument(providerId, payload.paymentMethod.id);

        await dispatch.providerPlan.loadPlan({
          providerId,
        });
        trackSubscriptionSuccess(rootState.providerPlan.data, payload.paymentMethod, false);
        return;
      }

      if (subscriptionStatus === SubscriptionStatus.Canceled) {
        // uncancel
        await reenrollProSubscription(providerId, payload.paymentMethod?.id);
        await dispatch.providerPlan.loadPlan({
          providerId,
        });
        trackSubscriptionSuccess(rootState.providerPlan.data, payload.paymentMethod, false);
        return;
      }
      // Status is in trial or expired

      // Subscribe pro and set payment instrument
      await startProSubscription(providerId, payload.paymentMethod?.id);
      await dispatch.providerPlan.loadPlan({
        providerId,
      });
      trackSubscriptionSuccess(rootState.providerPlan.data, payload.paymentMethod, true);
    },
  }),
  selectors: (slice, createSelector) => ({
    currentState() {
      return createSelector(
        slice(s => s),
        state => state,
      );
    },
    billingHistoryItems() {
      return createSelector(
        slice(s => s.status),
        slice(s => s.data),
        slice(s => s.loading),
        slice(s => s.charges),
        buildBillingHistoryItems,
      );
    },

    storedPaymentMethod() {
      return createSelector(
        slice(s => s?.data?.preview_subscription_stripe_instrument),
        state => state.paymentMethods.methods,
        buildStoredPaymentMethod,
      );
    },

    hasPendingPayment() {
      return createSelector(
        slice(s => s.status),
        slice(s => s.data),
        slice(s => s.loading),
        slice(s => s.charges),
        slice(s => s?.data?.preview_subscription_stripe_instrument),
        state => state.paymentMethods.methods,
        (status, data, loading, charges, storedMethodId, methods): boolean => {
          const billingHistoryItems = buildBillingHistoryItems(status, data, loading, charges);
          const storedPaymentMethod = buildStoredPaymentMethod(storedMethodId, methods);

          return !!storedPaymentMethod && billingHistoryHasPendingPayment(
            billingHistoryItems,
            storedPaymentMethod,
          );
        },
      );
    },

    currentPlanConfiguration() {
      return createSelector(
        rootState => (
          rootState.providers.providersById as Record<number, ProviderRecord>
        ),
        slice(s => s.provider_id),
        slice(s => s.planConfigurations),
        (
          providersById: Record<number, ProviderRecord>,
          providerId: number,
          configurations: IProMembershipProgram[],
        ): ProviderPlanConfiguration | null => {
          const provider = providersById?.[providerId]?.result;
          const config = (configurations || []).find(
            configuration => configuration.plan_name === (provider as PrivilegedProvider)?.plan,
          );

          return config ? formatProviderPlanConfiguration([config])[0] : null;
        },
      );
    },

    planConfigurations() {
      return createSelector(
        slice(s => s.planConfigurations),
        (planConfigurations): Array<ProviderPlanConfiguration> => (
          formatProviderPlanConfiguration(planConfigurations)
        ),
      );
    },

    defaultPlanConfiguration() {
      return createSelector(
        slice(s => {
          let planConfigurations = s.planConfigurations?.filter(p => p.default_program) || [];
          if (!planConfigurations.length && s.planConfigurations?.length) {
            planConfigurations = [s.planConfigurations[0]];
          }
          return planConfigurations;
        }),
        (planConfigurations): ProviderPlanConfiguration => (
          planConfigurations.length ? formatProviderPlanConfiguration(planConfigurations)[0] : null
        ),
      );
    },
  }),
});
