// @ts-strict-ignore
import { API_ROOT } from '../../config';
import { getOS } from '../../modules/AppInfo';
import Currency from '../../modules/Currency';
import { ssFetchJSON } from '../../modules/ssFetch';
import { ICard } from '../../store/PaymentMethods';
import { PaymentOption } from '../Providers/Appointments';
import { CheckoutResponse } from '../Providers/Appointments/Checkout';

/**
 * @deprecated Use `CheckoutPayloadParams` from
 * `app/scripts/api/Providers/Appointments/Checkout.ts` instead.
 */
export type DeprecatedCheckoutPayloadParams = {
  'form-0-amount': string;
  'form-0-card'?: string;
  'form-0-description': string;
  'form-0-typ': string;
  'form-INITIAL_FORMS': string;
  'form-MAX_NUM_FORMS': string;
  'form-TOTAL_FORMS': string;
  cost: string;
  creation_source: string;
  discount_code: string;
  do_not_send_client_receipt: false;
  charge_tip_later: number;
  product_cost: string | number;
  signature: string;
  tip: string | number;
};

export type CheckoutPayload = {
  product_cost: string;
  chargeTipLater: boolean;
  cost: string;
  depositAmount: string | number;
  tip?: string;
  balanceRemainingBeforeDiscount: number;
  paymentType: PaymentOption;
  card?: ICard;
  signature?: string;
};

export function buildCheckoutParams(checkout: CheckoutPayload): DeprecatedCheckoutPayloadParams {
  const checkoutParams: Partial<DeprecatedCheckoutPayloadParams> = {
    'form-INITIAL_FORMS': '0',
    'form-MAX_NUM_FORMS': '',
    'form-0-description': '',
    signature: checkout.signature,
    product_cost: checkout.product_cost,
    do_not_send_client_receipt: false,
    charge_tip_later: checkout.chargeTipLater ? 1 : null,
    creation_source: getOS(),
  };

  checkoutParams.cost = (
    Currency.parse(checkout.cost) - Currency.parse(checkout.depositAmount)
  ).toString();

  if (Number(checkout?.tip) > 0) {
    checkoutParams.tip = checkout.tip;
  }

  const total = parseFloat(checkout.balanceRemainingBeforeDiscount.toString()).toFixed(2);

  checkoutParams['form-TOTAL_FORMS'] = '1';
  checkoutParams['form-0-amount'] = total;
  checkoutParams['form-0-typ'] = checkout.paymentType;

  if (checkout.paymentType === 'stripe_connect') {
    checkoutParams['form-0-card'] = checkout.card.id.toString();
  }

  return checkoutParams as DeprecatedCheckoutPayloadParams;
}

/**
 * @deprecated Use `checkoutAppointment` from
 * `app/scripts/api/Providers/Appointments/Checkout.ts` instead.
 */
export function deprecatedCheckoutAppointment(
  appointmentId: number,
  checkout: CheckoutPayload,
): Promise<CheckoutResponse> {
  const checkoutParams = buildCheckoutParams(checkout);
  const url = new URL(`/checkout/appointment/${appointmentId}`, API_ROOT);
  return ssFetchJSON<CheckoutResponse>(url.toString(), {
    method: 'POST',
    form: checkoutParams,
  });
}
