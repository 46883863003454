// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import { ssFetchJSON } from '../modules/ssFetch';
import { SEO_SERVICE_READ } from '../config';

export type FAQ = {
  question: string;
  answer: string;
};

type RelatedLink = {
  title: string;
  slug: string;
};

export type SEOData = {
  slug?: string;
  type?: 'city' | 'service';
  title?: string;
  description: string;
  blurb?: string;
  faq?: FAQ[];
  relatedServices?: RelatedLink[];
  meta_description?: string;
};

type SEODataResponse = {
  items: SEOData[];
};

type State = {
  slugs: {
    [key: string]: SEOData;
  };
};

const stripHtml: (input: string) => string = input => input.replace(/(<([^>]+)>)/gi, '');

const model = createModel<RootModel>()({
  name: 'seoData',

  state: {
    slugs: {},
  } as State,

  reducers: {
    onItemsLoaded(state: State, {
      items,
    }: SEODataResponse) {
      const newItems = {};

      items.forEach((item: SEOData) => {
        newItems[item.slug] = item;
      });

      return {
        ...state,
        slugs: {
          ...state.slugs,
          ...newItems,
        },
      };
    },
  },

  effects: dispatch => ({
    loadSEOData: async (slugs: string) => {
      try {
        const seoData: SEODataResponse = await ssFetchJSON(`${SEO_SERVICE_READ}/lookup?slugs=${slugs}`);

        if (seoData?.items?.length > 0) {
          dispatch.seoData.onItemsLoaded(seoData);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    },
  }),

  selectors: (slice, createSelector) => ({
    searchSeoData: () => createSelector(
      state => state.route.params?.loc,
      state => state.route.params?.q,
      slice(state => state.slugs),
      (loc: string, q: string, seoDataSlugs: Record<string, SEOData>): SEOData => {
        const city: SEOData | null = seoDataSlugs?.[loc] || null;
        const service: SEOData | null = seoDataSlugs?.[q] || null;
        const union: SEOData | null = seoDataSlugs?.[`${loc}/${q}`] || null;

        const relatedServices = city ? (
          (service?.relatedServices || []).map((relatedService: RelatedLink): RelatedLink => ({
            title: relatedService.title,
            slug: `${city.slug}/${relatedService.slug}`,
          }))
        ) : [];

        return {
          title: union?.title || service?.title || city?.title,
          description: union?.description || service?.description || city?.description,
          blurb: stripHtml(union?.blurb || service?.blurb || city?.blurb || ''),
          faq: [
            ...(union?.faq || []),
            ...(service?.faq || []),
            ...(city?.faq || []),
          ],
          relatedServices: [
            ...relatedServices,
            ...(union?.relatedServices || []),
          ],
          meta_description: (
            union?.meta_description || service?.meta_description || city?.meta_description
          ),
        };
      },
    ),
  }),
});

export default model;
