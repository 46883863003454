// these both need to be relative, otherwise static builds don't work
import ssFetch from './ssFetch';
import fbAPI from './fbAPI';

const ACCOUNT_LINKED = '/sharing/fb_account_linked';
const LOGIN = '/sharing/fb_login';
const DISCONNECT = '/sharing/fb_disconnect';

/**
 * Sends a request using form encoding.
 * @param {String} url The request url.
 * @param {Object} form A hash of form data to send.
 */
const sendFormRequest = (url, form) => ssFetch(url, {
  form,
  method: 'POST',
  headers: {
    /* On the server, request.is_ajax() is used to determine whether a request should be
      redirected versus return a json response, but only on the "fb_login" route. This header is
      what determines the result of that check, so we need it to make sure we don't get redirected
      to the same URL, which results in an error because of missing credentials. */
    'X-Requested-With': 'XMLHttpRequest',
  },
});

/**
 * Determine whether or not the FB account provided is already linked to a user.
 * @param {Object} loginStatus The login status returned from the facebook API
 * @yields {Boolean} True if the account is linked, otherwise false.
 */
const checkAccountLinked = async loginStatus => {
  const response = await sendFormRequest(ACCOUNT_LINKED, {
    fb_data: JSON.stringify(loginStatus),
  });
  const json = await response.json();

  // "LINKED_TO_OTHER_USER" is returned when the current user is anonymous, as opposed to when we're
  // somehow authenticating using another facebook account.
  let isLinked = false;

  if (response.ok) {
    // the parens here aren't necessary, but they just look nice
    isLinked = (json.fb_linked_status === 'LINKED_TO_THIS_USER' || json.fb_linked_status === 'LINKED_TO_OTHER_USER');
  }

  return isLinked;
};

/**
 * Attempts to log into facebook using the Facebook JS SDK. First checks the login status and, if an
 * error occurs, attempts a login.
 * @yields {Object} The login status information.
 */
const tryLogin = async () => {
  let loginStatus;
  try {
    loginStatus = await fbAPI.getLoginStatus();
  } catch (e) {
    try {
      loginStatus = await fbAPI.login(['public_profile', 'email']);
    } catch (e2) {
      const err = new Error('user_declined_auth');
      err.fb_user_declined_auth_reason = e2;
      throw err;
    }
  }

  return loginStatus;
};

/**
 * Attempts to authenticate via facebook. If a user account is linked to the facebook account, that
 * account information is yielded in a `whoami` property. Facebook authentication results are always
 * yielded in a `fb` property.
 * @yields {Object} An object with `fb` and, possibly, `whoami` properties.
 */
export const auth = async () => {
  await fbAPI.init();
  const loginStatus = await tryLogin();
  const { authResponse } = loginStatus;
  const isAccountLinked = await checkAccountLinked(loginStatus);
  const fbUserData = await fbAPI.getUserData();

  if (!authResponse) {
    throw new Error('fb_response_missing_auth');
  }

  let { userID } = authResponse;

  if (!userID) {
    /*
     * In the native iOS application, the following sequence of user actions
     * yields an empty string for authResponse.userID:
     *   1) Signup with facebook
     *   2) Logout
     *   3) Login with facebook
     * This bug likely manifests on Android, and may also be reproducible
     * in other login flows.
     * The backend requires authResponse.userID to contain a valid
     * facebook user id, so, if the authResponse does not include that
     * property, retrieve it with a facebook API call.
     */
    if (!fbUserData.id) {
      throw new Error('fb_link_me_response');
    }

    if (!userID) {
      userID = fbUserData.id;
    }
  }

  const data = {
    fbAuthStatus: loginStatus,
    fbUserData,
  };

  if (isAccountLinked) {
    // do login, passing loginStatus
    const response = await sendFormRequest(LOGIN, {
      fb_data: JSON.stringify(loginStatus),
    });
    const json = await response.json();
    data.whoami = json.whoami;
  } // we'll handle signup somewhere else. our work is done here.

  return data;
};

/**
 * Deauthorizes facebook on the current user's account.
 * @yields {Response} The fetch response.
 */
export const disconnect = async () => ssFetch(DISCONNECT, { method: 'POST' });
