// @ts-strict-ignore
import nonCriticalException from '../../../modules/exceptionLogger';
import { InterceptorConfig } from '../../../modules/ssFetch';
import { LANDING_ROUTE } from '../../../route-names';
import store from '../../../store';

const redirectToLanding = async () => {
  await store.dispatch.user.logout();
  await store.dispatch.route.go({ route: LANDING_ROUTE });
};

/**
 * Intercepts backend responses for the whoami endpoint
 * and redirects to home page if we get a 403
 * @type {{response: (res) => Promise<IResponse<any>>}}
 */
export const authRedirectInterceptor: InterceptorConfig = {
  response: async res => {
    if (res.url.includes('/accounts/whoami/')) {
      if (res.status === 403) {
        await redirectToLanding();
      } else if ((res.status === 401 && res.statusText === 'Unauthorized')) {
        // Instead of dying forever on Invalid Token, we logout the user
        // and redirect to landing page
        try {
          const data = await res.json();
          if (data && data.detail === 'Invalid token.') {
            await redirectToLanding();
          }
        } catch (e) {
          nonCriticalException(e);
        }
      }
    }
    return res;
  },
};
