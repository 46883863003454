import React from 'react';
import { useDispatch } from 'react-redux';
import type { IRootDispatch } from '../store/models';
import { ABTestVariationDetails } from '../store/ABTest';
import { Assignment } from '../store/ABTest/ABTest.types';

type AssignTest = (recalculatedPercent?: number) => Promise<Assignment>;

export default function useAssignTest(
  testName: string,
  percent?: number,
  variations?: ABTestVariationDetails,
): AssignTest {
  const dispatch = useDispatch<IRootDispatch>();
  if (!(percent || variations) && percent !== 0) {
    throw new Error('useAssignTest, either percent or variations must be specified');
  }

  if (percent && variations) {
    throw new Error('useAssignTest, use percent or variations not both');
  }

  return React.useCallback<AssignTest>(recalculatedPercent => {
    const percentage = typeof recalculatedPercent === 'number'
      ? recalculatedPercent : percent;

    if (typeof percentage === 'number') {
      return dispatch.abTest.assignTest({
        name: testName,
        percent: percentage,
      });
    }

    return dispatch.abTest.assignTest({
      name: testName,
      variations,
    });
  }, [
    testName,
    percent,
    variations,
    dispatch.abTest,
  ]);
}
