import { PrivilegedProvider } from '../../api/Providers';
import { ProAppointment } from '../../api/Providers/Appointments';
import { STRIPE_MIN_TRANSACTION_AMOUNT } from '../../components/provider/checkout/TapToPayFlow/constants';
import type { PaymentIntent } from '../../store/StripeTerminal';
import {
  getIOSVersion,
  getIsTablet,
  getIsApp,
  getOS,
} from '../AppInfo';
import { isProduction } from '../env';
import { isAppointmentFrozen } from '../ProAppointmentState';
import { ssFetchJSON } from '../ssFetch';
import { bookedWithKlarna } from '../UserAppointmentState';
import { CordovaTapToPayErrorPayload } from '../CordovaPlugins.types';

type StripeConnectionTokenResponse = {
  object: 'terminal.connection_token';
  secret: string;
};

/**
 * Get stripe connection token
 *
 * @returns response from `/api/v1/payments/stripe_connection_token` API
 */
export async function getStripeConnectionToken(): Promise<string> {
  const { secret } = await ssFetchJSON<StripeConnectionTokenResponse>(
    '/api/v1/payments/stripe_connection_token',
    {
      method: 'POST',
    },
  );
  return secret;
}

type BuildFormParamsParams = {
  productCost: string | number;
  serviceCost: string | number;
  amount: number;
  tipAmount: number;
  discountCode?: string;
};

/** This is temporary for EV-3869 - to be replaced with version from END-411 */
const buildFormParams = ({
  productCost, serviceCost, amount, tipAmount, discountCode,
}: BuildFormParamsParams) => ({
  'form-INITIAL_FORMS': 0,
  'form-MAX_NUM_FORMS': 1,
  'form-TOTAL_FORMS': 1,
  'form-0-typ': 'stripe_connect',
  'form-0-amount': (amount + tipAmount).toFixed(2),
  'form-0-card_present': true,
  cost: serviceCost,
  product_cost: productCost,
  tip: tipAmount.toFixed(2),
  creation_source: getOS(),
  do_not_send_client_receipt: false,
  ...(discountCode && { discount_code: discountCode }),
});

export type CreatePaymentIntentParams = {
  /** Pro Appointment Details */
  appointment: ProAppointment;
  /** should be from proAppointmentCheckout.Checkout.total */
  amount: number;
  /** Tip, in dollars */
  tipAmount: number;
};

export interface ICreatePaymentResult {
  payment_intent: PaymentIntent;
}
/**
 * Make call to set up appointment for tap2pay
 */
export async function createPaymentIntent({
  appointment,
  amount,
  tipAmount = 0,
}: CreatePaymentIntentParams): Promise<ICreatePaymentResult> {
  const {
    id: appointmentId,
    provider: providerId,
    discount_code_redemption: discountCodeRedemption,
    product_cost: productCost,
    cost: serviceCost,
  } = appointment;
  const form = buildFormParams({
    amount,
    discountCode: discountCodeRedemption?.discount_code.code,
    productCost,
    serviceCost,
    tipAmount,
  });
  const url = `/api/v1/providers/${providerId}/appointments/${appointmentId}/payment`;
  return ssFetchJSON<ICreatePaymentResult>(url, {
    method: 'POST',
    form,
  });
}

/**
 * Process payment for tap2pay
 */
export async function processPayment(paymentIntentId: string): Promise<PaymentIntent> {
  const url = `/api/v1/payments/stripe_simulate_reader_payment/${paymentIntentId}`;

  return ssFetchJSON(url, {
    method: 'POST',
  });
}

/**
 * Capture payment for tap2pay
 */
export async function capturePayment(
  providerId: number,
  appointmentId: number,
  paymentIntentId: string,
): Promise<PaymentIntent> {
  const url = `/api/v1/providers/${providerId}/appointments/${appointmentId}/payments/${paymentIntentId}/checkout`;

  return ssFetchJSON(url, {
    method: 'POST',
    throwOnHttpError: true,
  });
}

export const inIOSApp = () => !!(getIsApp() && getIOSVersion() && !getIsTablet());

// We want to allow testing in non prod, non-app environments
export const isSimulated = () => !isProduction() && !getIsApp();
export const isProdAndNotInIOS = () => isProduction() && !inIOSApp();

/**
 * Determine whether an appointment is eligible for Tap to Pay
 */
export const isEligibleForTapToPay = (
  appointment: ProAppointment,
  provider: PrivilegedProvider,
  checkoutTotal?: number,
) => !isProdAndNotInIOS()
&& provider?.can_process_payments
&& !bookedWithKlarna(appointment)
&& !appointment.auth_amount
&& !isAppointmentFrozen(appointment)
&& checkoutTotal
&& checkoutTotal >= STRIPE_MIN_TRANSACTION_AMOUNT
  && !appointment.discount_code_redemption;

export const isCordovaTapToPayErrorPayload = (e: unknown): e is CordovaTapToPayErrorPayload => (
  typeof e === 'object' && e !== null && 'name' in e && 'description' in e
);
