import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const ClientReviewSubmitted: SegmentEventData = {
  eventName: AnalyticEvent.ReviewSubmitted,
  eventNameLegacy: 'client_review_submitted',
  eventProperties: [
    'appointment_id',
    'comments',
    'stars',
    'provider_id',
    'provider_name',
    'service_name',
  ],
};

export default ClientReviewSubmitted;
