// @ts-strict-ignore
import { Platform } from 'react-native';
import { UAParser, IOS } from 'ua-parser-js';

export type DeviceScreenSize = {
  density: number;
  width: number;
  height: number;
};

export const getDeviceScreenSize = (): DeviceScreenSize => {
  if (Platform.OS === 'web') {
    return {
      density: window?.devicePixelRatio,
      width: window?.screen?.width,
      height: window?.screen?.height,
    };
  }
  return null;
};

export const getDeviceTimeZoneName = (): string => {
  if (Platform.OS === 'web') {
    return Intl?.DateTimeFormat().resolvedOptions().timeZone;
  }
  return null;
};
export const getDeviceOS = (): IOS => {
  const parser = new UAParser();
  return parser.getResult().os;
};

export const getDeviceLocale = (): string => window?.navigator.language;
