// @ts-strict-ignore
import type { State as CurrentUserState } from '../../store/CurrentUser.types';

/**
 * Module which provides a default, unauthenticated user state.
 */

const createDefaultUser = (state?: CurrentUserState): CurrentUserState => ({
  username: '',
  email: '',
  hashed_email: '',
  first_name: '',
  last_name: '',
  auth_token: null,
  userId: null,
  phone_number: '',
  is_superuser: false,
  is_anon: true,
  facebook_id: null,
  has_usable_password: false,
  errors: null,
  ss_tracking_cookie: state?.ss_tracking_cookie || null,
  jwt_token: null,
  is_new_user: false,
  preferred_pronouns: null,
});

export default createDefaultUser;
