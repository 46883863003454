import { subscribe } from 'redux-subscriber';
import { isEqual } from 'underscore';
import { API_ROOT } from '../config';
import SimpleCache from './SimpleCache';
import ssFetch from './ssFetch';
import createDefaultUser from './user/createDefaultUser';

const API_URL = `${API_ROOT}/api/v1/user/feature-flags`;
const CACHE_TIME = 600; // This is 10 minutes

/**
 * Check the SS Dev tools chrome extension to see if this flag should be overridden.
 * @param {String} flagName - The name of the flag to check
 * @param {boolean} defaultValue - The value to use if an override is not defined.
 * @return {boolean} Either the override or the default value
 */
function checkOverride(flagName, defaultValue) {
  const devtools = window?.ssDevTools || {};
  let useValue = defaultValue;
  if (typeof devtools.getFeatureFlagOverride === 'function') {
    const overrideValue = devtools.getFeatureFlagOverride(flagName);
    if (overrideValue !== undefined) {
      useValue = overrideValue;
    }

    // Log value to dev tools
    if (typeof devtools.logFeatureFlag === 'function') {
      devtools.logFeatureFlag(flagName, useValue);
    }
  }
  return useValue;
}

/**
 * Query the feature flag systems (waffle) and get the value of each.
 *
 * @example
 * ```js
 *   FeatureFlags.isEnabled('my-feature')
 *   .then((enabled) => {
 *     console.log('Enabled?', enabled);
 *   });
 * ```
 */
class FeatureFlagsModule {
  cache = new SimpleCache(CACHE_TIME);
  assignmentPromises = [];
  user = null;
  reduxABTestModelCallback = null;
  #latestFlags = null;

  constructor() {
    // Reload flags when the user changes
    subscribe('user', state => {
      const user = state.user || createDefaultUser();

      if (!this.user || user.userId !== this.user.userId) {
        this.user = user;
        this.cache.clear();
        this.getFlags();
      }
    });
  }

  setReduxABTestModelCallback(onFlagChange) {
    this.reduxABTestModelCallback = onFlagChange;
  }

  /**
   * Get the list of all feature flags for the current user.
   * @return {Promise<Object>}
   */
  getFlags() {
    return ssFetch(API_URL, { ssCache: this.cache })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return {};
      })
      .then(data => {
        // Data format: flags: [{ key: 'flag-name' }]
        const flagData = data.flags || [];
        const flags = {};
        flagData.forEach(f => {
          flags[f.key] = true;
        });
        if (
          typeof this.reduxABTestModelCallback === 'function'
          && !isEqual(flags, this.#latestFlags)
        ) {
          this.reduxABTestModelCallback(flags);
        }
        this.#latestFlags = flags;
        return flags;
      })
      .catch(err => {
        console.error(err);
        return {};
      });
  }

  /**
   * Get a flag value, by name.
   * We'll first check the known flag assocations, if the flag is not
   * present, we'll then post to the server for a new association for this flag.
   *
   * @param {String} flagName - Name of the flag to get the value for.
   * @return {Promise<boolean>}
   */
  getFlagValue(flagName) {
    return this.getFlags()
      .then(flags => {
        const flagValue = flags[flagName];
        return checkOverride(flagName, flagValue);
      });
  }

  /**
   * Is this flag enabled
   * @param {String} flagName - The name of the flag to check.
   * @return {Promise<boolean>}
   */
  isEnabled(flagName) {
    return this.getFlagValue(flagName).then(value => value === true);
  }

  /**
   * Is this flag disabled
   * @param {String} flagName - The name of the flag to check.
   * @return {Promise<boolean>}
   */
  isDisabled(flagName) {
    return this.getFlagValue(flagName).then(value => !value);
  }

  /**
   * Checks if multiple flags are enabled
   * @param {Array} flagNames - A list of flags to check
   * @return {Promise<object>} - An object with { <flagName>: <boolean> }
   */
  areEnabled(flagNames) {
    const flagPromises = flagNames.map(name => this.isEnabled(name));
    return Promise.all(flagPromises)
      .then(flagValues => {
        const flagStatus = {};
        flagNames.forEach((name, i) => {
          flagStatus[name] = flagValues[i];
        });
        return flagStatus;
      });
  }
}

// Ensure there is only one instance.
window.FeatureFlags = new FeatureFlagsModule();
export default window.FeatureFlags;
