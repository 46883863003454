// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { isUserOptedInToSMS, saveUserSettings } from '../../../modules/user/phoneSettings';
import { getLastEditStatusForType } from '../../../modules/provider/accountNotification';
import analytics from '../../../modules/analytics';
import type { RootModel } from '../../../store/models';

type ConsumerProfilePageState = {
  loading: boolean;
  smsOptedIn: boolean;
  accountUpdateDisabledForEmail: boolean;
  accountUpdateDisabledForPhone: boolean;
  lastChangedField: string | null;
};

export type ProfileFieldValues = {
  firstName: string;
  preferredPronouns: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  isOptedIn: boolean;
};

const consumerProfilePage = createModel<RootModel>()({
  name: 'consumerProfilePage',

  state: {
    loading: false,
    smsOptedIn: false,
    accountUpdateDisabledForEmail: false,
    accountUpdateDisabledForPhone: false,
    lastChangedField: null,
  } as ConsumerProfilePageState,

  reducers: {
    onLand: (state: ConsumerProfilePageState) => ({
      ...state,
      loading: true,
    }),

    onLoaded: (_: ConsumerProfilePageState, payload: Omit<ConsumerProfilePageState, 'loading'>) => ({
      ...payload,
      loading: false,
    }),
  },

  effects: dispatch => ({
    async land(_?: undefined, rootState?) {
      await dispatch.consumerProfilePage.onLand();

      const smsOptedIn = await isUserOptedInToSMS(rootState.user.userId);
      let accountUpdateDisabledForEmail = false;
      let accountUpdateDisabledForPhone = false;
      let lastChangedField: string | null = null;

      if (rootState.user.providerId) {
        const [
          lastChangedEmail,
          lastChangedPhone,
        ] = await Promise.all([
          getLastEditStatusForType(rootState.user.providerId, 'email'),
          getLastEditStatusForType(rootState.user.providerId, 'phone number'),
        ]);

        accountUpdateDisabledForEmail = !!lastChangedEmail;
        accountUpdateDisabledForPhone = !!lastChangedPhone;
        lastChangedField = lastChangedEmail || lastChangedPhone;

        if (lastChangedField) {
          analytics.track('P Blocked Update Critical Account Info', {
            updating_field: lastChangedField === 'email' ? 'email' : 'phone',
          });
        }
      }

      await dispatch.consumerProfilePage.onLoaded({
        smsOptedIn,
        accountUpdateDisabledForEmail,
        accountUpdateDisabledForPhone,
        lastChangedField,
      });
    },

    async save(payload: ProfileFieldValues, rootState) {
      await dispatch.user.updateUser({
        first_name: payload.firstName,
        last_name: payload.lastName,
        email: payload.email,
        phone_number: payload.phoneNumber,
        smsOptedIn: payload.isOptedIn,
        preferredPronouns: payload.preferredPronouns,
      });

      await saveUserSettings(
        rootState.user.userId,
        {
          name: `${payload.firstName} ${payload.lastName}`,
          appointment_sms_enabled: payload.isOptedIn,
          mobile_phone_number: payload.phoneNumber,
          source: 'account > contact info form',
        },
      );

      await dispatch.user.whoami({ force: true, reIdentify: true });
    },
  }),
});

export default consumerProfilePage;
