/**
 * Contains utilities for simplifying interaction with tracking
 * @typedef {string|Object<string, Object>|Function} TrackingInfo
 */

import analytics from '.';

/**
 * Composes the provided function (which may be falsy) with a tracking call
 */
export function wrapWithTracking(func, resolveTrackingInfo) {
  return (...eventArgs) => {
    const { event, params } = resolveTrackingInfo(...eventArgs);

    analytics.track(event, params);

    if (typeof func === 'function') {
      return func(...eventArgs);
    }

    return undefined;
  };
}

/**
 * Normalize tracking info
 * @param {TrackingInfo} value Tracking info to normalize into a { event, params } object.
 * @returns {Object} An object which contains an `event` string and `params` object.
 */
export function normalizeTrackingInfo(value) {
  if (typeof value === 'string') {
    return {
      event: value,
    };
  }

  if (typeof value === 'object' && value) {
    const [event] = Object.keys(value);
    const params = value[event];
    return {
      event,
      params,
    };
  }

  return {};
}

/**
 * Generates a function which returns an object with an `event` key and a `params` key, intended to
 * be used alongside tracking calls
 *
 * Given tracking information, which may be:
 * - A string, which results in a function returning { event: <the given string> }
 * - An object, which results in a function returning { event: <the single object key>, params: <the
 *   single object value> }
 * - A function which, given the arguments to the prop function, returns one of the above.
 * @param {TrackingInfo} tracking Tracking info from which to generate the resolver function
 * @returns {Function} A function which returns an object with an `event` key and a `params` key.
 *
 * @example
 * const resolveTrackingInfo = createTrackingResolver('some tracking');
 * const { event, params } =  resolveTrackingInfo(someArg, someOtherArg);
 * analytics.track(event, params);
 */
export function createTrackingResolver(tracking) {
  /**
   * @function
   * @returns {Object} The `event` and `params` to use for tracking
   */
  let resolveTrackingInfo;

  if (typeof tracking === 'function') {
    // if the value is a function, we expect it to return tracking info given the event params
    resolveTrackingInfo = (...args) => normalizeTrackingInfo(tracking(...args));
  } else {
    // otherwise, we have static params, so return the normalized data directly
    const trackingInfo = normalizeTrackingInfo(tracking);
    resolveTrackingInfo = () => trackingInfo;
  }

  return resolveTrackingInfo;
}
