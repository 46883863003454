// These zIndex constants are modeled from _z-index.scss
// Add more as-needed
export const Z_FORCE_BG = -1;
export const Z_BG = 0;
export const Z_FG = 1;
export const Z_FORCE_FG = 2;

export const Z_NAVIGATION = 10;

// Z_NAVIGATION - 1 - render just below z nav
export const Z_SUB_NAVIGATION = 9;

export const Z_MODAL = 21;
export const Z_STACKED_MODAL = 22;

// Loaders
export const Z_LOADER = 51;

// Nothing should be higher than this
export const Z_TOP_OF_THE_WORLD = 999;
