import { API_ROOT } from '../../config';
import { parseResponse } from '../../modules/Api';
import ssFetch, { ssFetchJSON } from '../../modules/ssFetch';
import type { ICheckoutSettingsData } from '../../modules/Api.types';
import { clearApiCache, ApiCache } from './Providers';

/**
 * Get a provider's checkout settings.
 *
 * The returned data is expected to be ICheckoutSettingsData.
 *
 * @param {number} providerId
 * @returns {Promise<ICheckoutSettingsData>}
 */
export function getCheckoutSettings(
  providerId: number | string,
): Promise<ICheckoutSettingsData> {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/checkout_settings`);

  return ssFetchJSON(
    url.toString(),
    { ssCache: ApiCache },
  );
}

/**
 * Change the checkout settings for the given provider
 *
 * @param {number|string} providerId The provider ID
 * @param {Object} taxRates
 * @param {Object} taxRates.service_tax_rate Service tax rate for the provider
 * @param {Object} taxRates.product_tax_rate Product tax rate for the provider
 * @param {Object} taxRates.client_service_rate this is always '0.00'
 * @returns {Promise<ICheckoutSettingsData>}
 */
export async function updateCheckoutSettings(
  providerId: number | string,
  taxRates: ICheckoutSettingsData,
): Promise<ICheckoutSettingsData> {
  const response = await ssFetch(
    `/api/v2/providers/${providerId}/checkout_settings`,
    {
      body: taxRates,
      method: 'PATCH',
    },
  );
  clearApiCache();
  return parseResponse(response);
}
