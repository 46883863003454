// @ts-strict-ignore
// Any updates to the below sources should also include updates to the model in the BE
// class AppointmentSmartPrice in styleseat/schedule/models.py
export enum SmartPriceSource {
  LastMinuteClientBooked = 1,
  Model = 2,
  ScheduleGapFill = 3,
  OutsideAvailabilityProBooked = 4,
  LastMinuteOptimizedClientBookedModel = 5,
}

/**
 * Returns the smart price source description
 * @param {object} smartPrice - the smart price object
 */
export const getSmartPriceSourceDescription = smartPrice => {
  if (!smartPrice) {
    return null;
  }

  switch (smartPrice.source) {
    case SmartPriceSource.LastMinuteClientBooked:
      return 'LAST_MINUTE_CLIENT_BOOKED';
    case SmartPriceSource.Model:
      return 'MODEL';
    case SmartPriceSource.ScheduleGapFill:
      return 'SCHEDULE_GAP_FILL';
    case SmartPriceSource.OutsideAvailabilityProBooked:
      return 'OUTSIDE_AVAILABILITY_PRO_BOOKED';
    case SmartPriceSource.LastMinuteOptimizedClientBookedModel:
      return 'LAST_MINUTE_OPTIMIZED_CLIENT_BOOKED_MODEL';
    default:
      return 'MODEL';
  }
};

export const SMART_PRICE_DEFAULT_SERVICE_UPCHARGE = 20; // 20%
export const SMART_PRICE_DEFAULT_SERVICE_UPCHARGE_CAP = 50; // $50

export const CLIENT_BOOKING_FLOW_SMART_PRICE_TEST_NAME = 'client_bookingflow_smartprice_20200218_fly2739';
export const CLIENT_BOOKING_FLOW_SMART_PRICE_TEST_PERCENT = 1.0;

export const CLIENT_BOOKING_FLOW_SMART_PRICE_NEW_MODEL_TEST_PERCENT = 0.50;
