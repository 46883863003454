// @ts-strict-ignore
import moment from 'moment';
import { createModel } from '@rematch/core';
import type { RootModel } from '../../../store/models';
import { groupAppointments, IGroupedAppointments } from '../../../modules/consumer/appointments';
import {
  getUserRatings,
  RatingHistoryModel,
  transformUserRatings,
} from '../../../modules/consumer/ratings';
import { UserAppointmentWithProviderWithLocation } from '../../../modules/UserAppointmentState';
import { UserAppointmentListResults } from '../../../api/Users/Appointments';

// https://styleseat.atlassian.net/browse/ATL-4445
// There is an issue with future cancelled appointments and the appointments list. 50 is the
// amount we have historically fetched, and the issue is much less likely to occur in that case.
// https://styleseat.atlassian.net/browse/ATL-4450 will track fixing this issue properly.
export const FUTURE_APPTS_TO_FETCH = 50;

export const PAST_APPTS_TO_FETCH = 25;

export interface IAppointmentRatings {
  [id: number]: number;
}

type State = {
  appointmentRatings: IAppointmentRatings;
  morePastAppts: boolean;
  loading: boolean;
  pastApptPagination: number;
};

export type ClientAppointmentListData = Omit<State, 'pastApptPagination'> & {
  groupedAppointments: IGroupedAppointments;
};

const defaultState = {
  appointmentRatings: {},
  loading: false,
  pastApptPagination: 1,
  morePastAppts: false,
};

const model = createModel<RootModel>()({
  name: 'clientAppointmentsList',

  state: defaultState,

  reducers: {
    onStartLoadingAppts: () => ({ ...defaultState, loading: true }),
    onFinishLoadingAppts: (state: State, payload: {
      ratingsResponse?: RatingHistoryModel[];
      allPastApptsCount?: number;
    }) => {
      const appointmentRatings = payload.ratingsResponse
        ? transformUserRatings(payload.ratingsResponse)
        : state.appointmentRatings;

      // this block checks if there are additional past appts yet to
      const totalPastResults = state.pastApptPagination * PAST_APPTS_TO_FETCH;
      const morePastAppts = payload.allPastApptsCount > totalPastResults;
      const pastApptPagination = morePastAppts
        ? state.pastApptPagination + 1
        : state.pastApptPagination;

      return {
        ...state,
        appointmentRatings,
        morePastAppts,
        pastApptPagination,
        loading: false,
      };
    },
  },

  effects: dispatch => ({
    land: async (
      payload: { userId: number },
      rootState,
    ) => {
      dispatch.clientAppointmentsList.onStartLoadingAppts();
      const { userId } = payload;

      const futureAppts = dispatch.userAppointments.loadAppointmentsWithProviders({
        userId,
        params: {
          start_date: moment().startOf('day').toISOString(),
          size: FUTURE_APPTS_TO_FETCH,
          ordering: 'start',
        },
      });

      const pastAppts = dispatch.userAppointments.loadAppointmentsWithProviders({
        userId,
        params: {
          start_date: moment().subtract(2, 'year').startOf('year').toISOString(),
          end_date: moment().startOf('day').toISOString(),
          page: rootState.clientAppointmentsList.pastApptPagination,
          size: PAST_APPTS_TO_FETCH,
        },
      });

      const [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _,
        pastResults,
        ratingsResponse,
      ] = await Promise.all([
        futureAppts,
        pastAppts,
        getUserRatings(),
      ]);

      dispatch.clientAppointmentsList.onFinishLoadingAppts({
        ratingsResponse,
        allPastApptsCount: pastResults.allCount,
      });
    },

    landFuture: async (
      payload: { userId: number },
    ) => {
      dispatch.clientAppointmentsList.onStartLoadingAppts();
      const { userId } = payload;

      await dispatch.userAppointments.loadAppointmentsWithProviders({
        userId,
        params: {
          start_date: moment().startOf('day').toISOString(),
          size: 'all',
          ordering: 'start',
        },
      });

      dispatch.clientAppointmentsList.onFinishLoadingAppts({
        allPastApptsCount: 0,
      });
    },

    loadPastAppointments: async (
      payload: { userId: number },
      rootState,
    ): Promise<UserAppointmentListResults> => {
      const { userId } = payload;

      const pastResults = await dispatch.userAppointments.loadAppointmentsWithProviders({
        userId,
        params: {
          start_date: moment().subtract(2, 'year').startOf('year').toISOString(),
          end_date: moment().startOf('day').toISOString(),
          page: rootState.clientAppointmentsList.pastApptPagination,
          size: PAST_APPTS_TO_FETCH,
        },
      });

      dispatch.clientAppointmentsList.onFinishLoadingAppts({
        allPastApptsCount: pastResults.allCount,
      });
      return pastResults;
    },
  }),

  selectors: (slice, createSelector) => ({
    getPageData: models => createSelector(
      slice,
      models.userAppointments.getUserAppointments,
      (state, userAppts): ClientAppointmentListData => {
        const {
          appointmentRatings, loading, morePastAppts,
        } = state;
        const apptsToGroup: UserAppointmentWithProviderWithLocation[] = userAppts
          ? Object.values(userAppts)
          : [];
        const groupedAppointments = groupAppointments(apptsToGroup);

        return {
          groupedAppointments,
          appointmentRatings,
          morePastAppts,
          loading,
        };
      },
    ),
  }),
});

export default model;
