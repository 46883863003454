import SimpleCache from '../../../modules/SimpleCache';
import { clearProviderInfoCache } from '../Info';

export const galleryImageCache = new SimpleCache(60); // expires in 1 minute

/**
 * Clears the image cache
 */
export function clearImageCache() {
  galleryImageCache.clear();
}

/**
 * Clear provider caches containing gallery images.
 */
export async function clearProviderCache() {
  clearImageCache();
  clearProviderInfoCache();
}
