import { useEffect, useRef } from 'react';

/**
 * A hook which will call the function the first time the tracked value
 * changes from false to true, this is useful for firing tracking once
 * but after data has loaded!
 * @param fn
 * @param value
 */
export default function useIsLoaded(fn, value) {
  const oldValue = useRef(value);

  useEffect(() => {
    if (!oldValue.current && value) {
      oldValue.current = value;
      fn();
    }
  }, [value]);
}
