// @ts-strict-ignore
import moment, { MomentInput } from 'moment';
import {
  ProAppointment,
  ProAppointmentDetails,
  ProAppointmentUpdatePayload,
  ProAppointmentUpdatePayloadTimeBlock,
} from '../../../api/Providers/Appointments';
import {
  mapTimeBlocksToItems,
  shiftTimeBlockItems,
  TimeBlockItem,
} from './TimeBlock';
import { AppointmentTypes } from '../../../api/Users/Appointments';
import { isCheckedOutLateCancel, isCancelled } from '../../ProAppointmentState';
import { ALL_TIME_SLOTS, slotToTime } from '../../timeSlotUtils';
import { Promotion } from '../../../api/Providers/Promotions';
import { IntakeFormResponse } from '../../../api/Providers/IntakeFormResponse';
import type { CompletedIntakeForm } from '../../../components/provider/IntakeForms/IntakeFormResponse/IntakeFormResponse.types';

export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const PRO_BOOKING_METHODS = {
  PRO_BOOKED_MOBILE: 3,
  PRO_BOOKED_MOBILE_RECURRING: 101,
  PRO_BOOKED_PREMIUM_APPT: 128,
  PRO_BOOKED_PREMIUM_APPT_V2: 129,
};

export type RawAppointmentPayload = {
  appointment: Partial<ProAppointmentDetails>;
  items: TimeBlockItem[];
  cost?: number;
  productCost?: number;
  localStartInput?: MomentInput;
  smartPriceSource?: string;
};

/**
 * Generates an appointment payload which can be serialized for saving and updating via API calls
 * @param cost The total service cost for the appointment
 * @param localStartInput The start date of the appointment
 * @param items The time blocks in the appointment
 * @param productCost The total product cost for the appointment
 * @param appointment The raw appointment object
 * @param smartPriceSource The smart pricing data source
 * @returns The appointment payload
 */
export function getPayload({
  appointment,
  items,
  cost = Number(appointment.cost),
  localStartInput = appointment.local_start,
  smartPriceSource,
}: RawAppointmentPayload): ProAppointmentUpdatePayload {
  const {
    id,
    title,
    note,
    client,
    provider,
    followup_note: followupNote,
    recurrence_interval: recurrenceInterval,
    booked_by: bookedBy,
  } = appointment;

  const localStart = moment(localStartInput);
  const localStartFormatted = localStart.format(PAYLOAD_DATE_FORMAT);
  let bookingMethod;

  // only override booking method in payload if it hasn't been set already
  if (!appointment.booking_method) {
    if (appointment.is_premium_appointment) {
      bookingMethod = PRO_BOOKING_METHODS.PRO_BOOKED_PREMIUM_APPT_V2;
    } else if (recurrenceInterval) {
      bookingMethod = PRO_BOOKING_METHODS.PRO_BOOKED_MOBILE_RECURRING;
    } else {
      bookingMethod = PRO_BOOKING_METHODS.PRO_BOOKED_MOBILE;
    }
  }

  let payload: ProAppointmentUpdatePayload = {
    local_start: localStartFormatted,
    booking_method: bookingMethod,
    smart_price_source: smartPriceSource,
    provider,
    id: undefined,
    followup_note: undefined,
    note: undefined,
  };

  if (id) {
    payload.id = id;
  }

  if (bookedBy) {
    payload.booked_by = bookedBy;
  }

  // Note and title fields.
  if (appointment.type === AppointmentTypes.PersonalTime) {
    payload.title = title || '';
  }

  payload.followup_note = followupNote || '';
  payload.note = note || '';

  if (appointment.type === AppointmentTypes.ClientAppointment) {
    payload = Object.assign(payload, {
      type: AppointmentTypes.ClientAppointment,
      local_end: undefined,
      client,
      // Client id is used for creating/updating appts only
      client_id: client ? client.id : client,
      recurrence_interval: recurrenceInterval,
      cost: cost.toFixed(2),
      waitlist_id: appointment.waitlist_id ? appointment.waitlist_id : undefined,
      time_blocks: items.map(timeBlock => {
        const timeBlockPayload: ProAppointmentUpdatePayloadTimeBlock = {
          local_start: timeBlock.local_start,
          local_end: timeBlock.local_end,
          service: timeBlock.service,
        };

        if (timeBlock.id) {
          timeBlockPayload.id = timeBlock.id;
        }

        return timeBlockPayload;
      }),
    });
  } else if (appointment.type === AppointmentTypes.PersonalTime) {
    payload = Object.assign(payload, {
      type: AppointmentTypes.PersonalTime,
      // personal time will have a single timeblock
      local_end: localStart.clone().add(items[0].duration, 'minutes')
        .format(PAYLOAD_DATE_FORMAT),
    });
  }

  return payload;
}

/**
 * Returns the appointment with a different start date
 * @param {Appointment} appt
 * @param {string} newDate new date/time
 */
export function changeAppointmentDate(appt: ProAppointmentDetails, newDate: string) {
  let items = mapTimeBlocksToItems(appt.time_blocks);

  items = shiftTimeBlockItems(items, moment(appt.local_start), moment(newDate));

  return getPayload({
    appointment: appt,
    items,
    localStartInput: newDate,
  });
}

/**
 * Generates an appointment payload from a given appointment
 */
export function toAppointmentPayload(appt: ProAppointmentDetails) {
  const items = mapTimeBlocksToItems(appt.time_blocks);

  return getPayload({
    appointment: appt,
    items,
  });
}

/**
 * Returns the list of appointments keyed by the desired field
 * NOTE: The "keyof Pick<...>" will need to be extended as needed because not all fields
 *    on ProAppointment are keyable
 * @param {Appointment[]} appointments
 * @param {keyof Appointment} field
 * @returns {any}
 */
export function keyAppointmentsBy(
  appointments: ProAppointment[] = [],
  field: keyof Pick<ProAppointment, 'id'>,
): Record<keyof ProAppointment, ProAppointment> {
  return appointments.reduce((result, appointment) => ({
    ...result,
    [appointment[field]]: appointment,
  }), {} as any);
}

/**
 * Returns the list of appointments keyed by their id field
 * ie: {1: {id: 1...}, 345: {id: 345...}}
 * @param {Appointment[]} appointments
 * @returns {any}
 */
export function keyAppointmentsById(
  appointments: ProAppointment[] = [],
): Record<number, ProAppointment> {
  return keyAppointmentsBy(appointments, 'id');
}

/**
 * Gets the start date (without time) for the appointment
 * @param {Appointment} appointment
 * @returns {string}
 */
export function getAppointmentDate(appointment: ProAppointment): string {
  return (appointment.local_start || '').split('T')[0];
}

/**
 * Returns the list of appointments organized by their start date, ie:
 * {
 *   '2022-06-10': [{id: 1...}, {id: 2...}],
 *   '2022-06-11': [{id: 3...}, {id: 4...}],
 * }
 * @param {Appointment[]} appointments
 * @returns {Record<DateString, Appointment>}
 */
export function keyByDate(
  appointments: ProAppointment[] = [],
): Record<string, ProAppointment[]> {
  return appointments.reduce((
    result: any,
    appointment: ProAppointment,
  ) => {
    const startDate = getAppointmentDate(appointment);
    return ({
      ...(result),
      [startDate]: [
        ...(result[startDate] || []),
        appointment,
      ],
    });
  }, {});
}

/**
 * Filters appointments list and only selects appointments that have not been
 * cancelled.
 * @param {any[]} appointments
 * @returns {any}
 */
export function getNotCancelledAppointments(
  appointments: ProAppointment[] = [],
): ProAppointment[] {
  return appointments.filter(appt => !(isCancelled(appt) || isCheckedOutLateCancel(appt)));
}

export const APPOINTMENT_TIME_OPTIONS = ALL_TIME_SLOTS.map(timeSlot => ({
  key: slotToTime(timeSlot, 'h:mm A'),
  value: slotToTime(timeSlot, 'H:mm'),
}));

export const compareAppointmentsByStartTime = (a: ProAppointment, b: ProAppointment) => (
  new Date(a.local_start).getTime() - new Date(b.local_start).getTime()
);

export const mergePromotionObjects = <APPT extends ProAppointmentDetails>(
  appointment: APPT,
  promotions: Promotion[],
): APPT => ({
    ...appointment,
    pro_promotion_discounts: appointment.pro_promotion_discounts.map(promo => ({
      ...promo,
      pro_promotion: promotions.find(
        promoDetail => promoDetail.id === promo.pro_promotion,
      ).getSerializedObject(),
    })),
  });

export const formatIntakeFormResponse = (
  appointment: ProAppointmentDetails,
  intakeFormResponse: IntakeFormResponse,
): CompletedIntakeForm => {
  const clientResponses = intakeFormResponse.assigned_form.questions.map((q, index) => ({
    question: q.title,
    answer: intakeFormResponse.responses[index],
  }));

  const date = new Date(appointment.local_start);
  const appointmentDate = date.toLocaleDateString(
    'en-US',
    {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
  );

  const appointmentTime = date.toLocaleTimeString(
    'en-US',
    {
      hour: 'numeric',
      minute: '2-digit',
    },
  );

  return {
    clientName: appointment.client.name,
    serviceName: appointment.time_blocks[0].service_name,
    appointmentDate,
    appointmentTime,
    clientResponses,
  };
};
