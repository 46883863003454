import { AssignTestRequest } from '../../../store/ABTest/ABTest.types';

export const SEARCH_PARAM_RESET_CODE = '-';

// DP-146
export const SEARCH_GEO_DETRACTOR_TEST_NAME = 'search_geo_detractor_filter_dp146_20230223';
export const SEARCH_GEO_DETRACTOR_TEST_PERCENT = 0.5;

// RES-187
export const SEARCH_RECOMMENDED_PRO_TEST = 'top_pro_badging_res187_20230226';
export const SEARCH_RECOMMENDED_PRO_TEST_PERCENT = 0.5;
// Pro must be considered "payment adopters": >=60% of payments completed for their appointments
export const SEARCH_RECOMMENDED_PRO_TEST_PAY_FREQUENCY_THRESHOLD = 0.6;

// ML-model alternative flag - dp-410
export const NEW_LTR_MODEL = 'new_ltr_model_dp410_test_20240227';
export const NEW_LTR_MODEL_PERCENT = 0.5;

// ML-rerank flag
export const ML_RERANK_FLAG = 'skip_ml_rerank';
// if flag is on, always skip ml rerank
export const ML_RERANK_PERCENT = 1.0;

// Impressions highly bookable new pros experiment - DP-437
export const IMPRESSIONS_NEW_PROS_TEST = 'impressions_new_pros_dp437_20240326';
// search-api handles which searches are tested
export const IMPRESSIONS_NEW_PROS_PERCENT = 1.0;

// Impressions to bookings conversion experiment - DP-576
export const IMPRESSIONS_TO_BOOKINGS_RATIO_TEST = 'impressions_to_bookings_ratio_dp576_20240724';
export const IMPRESSIONS_TO_BOOKINGS_RATIO_PERCENT = 0.5;

type SearchTestConfig = AssignTestRequest & { trackingKey: string };

/**
 * Steps to add a search test:
 * 1. Add constants for flag name and percentage toward the top of this file
 * 2. Below, add an entry corresponding to those constants just added
 * 3. Enjoy!
 * @example
 * my_query_parameter_name: {
 *  name: MY_TEST_CONSTANT_NAME,
 *  percent: MY_TEST_CONSTANT_PERCENT,
 *  trackingKey: 'team999',
 * },
 *
 * For this entry, `my_query_parameter_name=true` will be sent in request parameters
 * to the search API for users in the test, and `my_query_parameter_name=false` will
 * be sent for users not in the test. When tracking the search, typically with the
 * `"search"` event name, `team999: true` will be sent in the `tests` attribute
 * for users in the test, and `team999: false` will be sent for users not in the test.
 * MY_TEST_CONSTANT_NAME should be a constant containing the flag name from Django admin.
 * MY_TEST_CONSTANT_NAME, my_query_parameter_name, and the trackingKey may all be different,
 * but should at least all contain the abbreviation of the ticket introducing the test
 * (TEAM-999 in this example).
 */
export const SEARCH_TEST_CONFIG: Record<string, SearchTestConfig> = {
  geographical_detractor_test_dp146: {
    name: SEARCH_GEO_DETRACTOR_TEST_NAME,
    percent: SEARCH_GEO_DETRACTOR_TEST_PERCENT,
    trackingKey: 'dp146',
  },
  // Support QA bypass of ML model for testing purposes (only intended to be turned on for LC/Stage)
  skip_ml_rerank: {
    name: ML_RERANK_FLAG,
    percent: ML_RERANK_PERCENT,
    trackingKey: 'ml_rerank', // DP-260
  },
  new_ml_model_dp410: {
    name: NEW_LTR_MODEL,
    percent: NEW_LTR_MODEL_PERCENT,
    trackingKey: 'dp410',
  },
  impressions_new_pros_dp437: {
    name: IMPRESSIONS_NEW_PROS_TEST,
    percent: IMPRESSIONS_NEW_PROS_PERCENT,
    trackingKey: 'dp437',
  },
  impressions_to_bookings_ratio_dp576: {
    name: IMPRESSIONS_TO_BOOKINGS_RATIO_TEST,
    percent: IMPRESSIONS_TO_BOOKINGS_RATIO_PERCENT,
    trackingKey: 'dp576',
  },
};
