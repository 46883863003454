// @ts-strict-ignore
import { API_ROOT } from '../../config';
import type { IAcceptedTermsData, ProviderCancellationPolicy } from '../../modules/Api.types';
import SimpleCache from '../../modules/SimpleCache';
import ssFetch, { IResponse, ssFetchJSON } from '../../modules/ssFetch';
import { DateTimeStamp } from '../../types/dateTime';
import type { IGalleryImage } from './GalleryImages';

/**
 * A shared cache for all fetchable Provider data.
 *
 * Data is cached for 5s. The cache is cleared whenever a
 * POST/PATCH/DELETE API call is made.
 */
export const ApiCache = new SimpleCache(5);

export enum BookingFeeConfigType {
  Amount = 'amount',
  Percent = 'percent',
}

export interface IBookingFeeAmount {
  type: BookingFeeConfigType;
  amount: number;
}

export interface IBookingFeePercent {
  type: BookingFeeConfigType.Percent;
  percent: string;
  min: number;
  max: number;
}

export type IBookingFeeConfig = IBookingFeeAmount | IBookingFeePercent;

export function isPercentFee(feeConfig: IBookingFeeConfig): feeConfig is IBookingFeePercent {
  return feeConfig.type === BookingFeeConfigType.Percent;
}

export enum BookingFeeConfig {
  Default = 'default',
  OneDollar = 'flat_one_dollar',
  ExperimentControl = 'experiment_control',
  ExperimentTreatment = 'experiment_treatment',
}

/**
 * Guard which checks if the passed in data fulfills the PrivilegedProvider interface
 * @param provider
 */
export function instanceOfPrivilegedProvider(provider: any): provider is PrivilegedProvider {
  return (
    provider !== null
    && typeof provider === 'object'
    && 'accepted_terms' in provider
  );
}

export enum CardRequiredChoices {
  Unset = 0,
  Never = 1,
  Always = 2,
  FirstAppointment = 3,
}

export type ProviderLocationCoords = {
  latitude: number | null;
  longitude: number | null;
};

// See ProviderChatResponsivenessSerializer
export enum ChatResponsivenessBuckets {
  Default = 'DEFAULT',
  Unresponsive = 'UNRESPONSIVE',
  Responsive = 'RESPONSIVE',
  VeryResponsive = 'VERY_RESPONSIVE',
}

export enum ChatContextBannerType {
  BookedOk = 'BOOKED_OK',
  BookedCancelled = 'BOOKED_CANCELLED',
  BookedLateCancelled = 'BOOKED_LATE_CANCELLED',
  BookedNoShow = 'BOOKED_NO_SHOW',
  NewClient = 'NEW_CLIENT',
  BookedNewClient = 'BOOKED_NEW_CLIENT',
  PastAppointment = 'PAST_APPOINTMENT',
}

export type ChatContextBannerAppointment = {
  type: ChatContextBannerType;
  local_start: DateTimeStamp;
  appointment_id: string | null;
  service_name: string;
  cancelled_by: 'client' | 'pro' | null;
};

export type ChatContextBanner = {
  client_first_name: string;
  appointment: null | ChatContextBannerAppointment;
};

export type ChatResponsiveness = {
  provider_id: number;
  bucket: ChatResponsivenessBuckets;
};

// See BaseLocationSerializer
export type ProviderOrSalonLocation = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  neighborhood: string | null;
  location_instructions: string | null;
  timezone: string | null;
  timezoneoffset: number | null;
  mobile_business: boolean;
  coords: ProviderLocationCoords;
  full_location_string: string;
};

// see ProviderLocationSerializer
export interface PublicProviderLocation extends ProviderOrSalonLocation {
  id: number;
  name: string;
  vanity_url: string;
}

// See V2 BaseProviderSerializer
export interface BaseProvider {
  blog_url: string;
  blurb: string | null;
  cancellation_notice_hours: number | null;
  cancellation_policy_text: string;
  cancellation_policy: ProviderCancellationPolicy;
  creation_time: DateTimeStamp;
  deposit_policy_text: string | null;
  facebook_url: string;
  field_group: number | null;
  gallery_cover_image: IGalleryImage | null;
  id: number;
  instagram: string;
  is_effectively_online_bookable: boolean;
  is_profile_complete: boolean;
  late_cancellation_fee_percent: number;
  name: string;
  no_show_fee_percent: number;
  on_commission_plan: boolean;
  // Always same as public_phone_number
  phone_number: string;
  pixel_id: string;
  profession: string;
  profile_photo: string;
  profile_search_boost_eligible_date: DateTimeStamp | null;
  profile_search_boost_num_appts: number;
  public_phone_number: string;
  rating_stars: number;
  time_zone: string;
  twitter: string;
  vanity_url: string;
  website: string;
  yelp_url: string;
}

/**
 * see V2 ProviderSerializer
 */
export interface PublicProvider extends BaseProvider {
  autocheckout_enabled: boolean;
  booking_fees: Record<string, IBookingFeeConfig> | null;
  can_process_payments: boolean;
  disabled_payments: boolean;
  enabled_online_booking_multi: boolean;
  gift_card_enabled: boolean;
  is_chat_enabled: boolean;
  is_disabled: boolean;
  is_paid_ncd_eligible: boolean;
  klarna_enabled: boolean;
  locations: PublicProviderLocation[];
  ncd_enabled: boolean;
  ncd_fee_percent: string;
  open_for_in_person_appointments: boolean;
  payment_authorization_enabled: false;
  preauth_enabled: boolean;
  preferred_pronouns: string | null;
  premium_upcharge_rate: number;
  pro_waitlist_user_requested_enabled: boolean;
  profile_phase_one_complete_date: DateTimeStamp | null;
  require_card: CardRequiredChoices;
  service_tax_rate: string;
  smart_pricing_enabled: boolean;
  total_booked_appointments_count: number | null;
  latest_ncd_timeouts_penalty_end_time?: DateTimeStamp | null;
}

/**
 * Data as returned by provider.v2.serializers_api.PrivilegedProviderSerializer
*/
export interface PrivilegedProvider extends PublicProvider {
  accepted_terms: IAcceptedTermsData[];
  autocharge_approved: boolean;
  autocharge_enabled_time: DateTimeStamp | null;
  email: string;
  is_autocharge: boolean;
  is_oldie: boolean;
  is_rejoined_oldie: boolean;
  joined_time: DateTimeStamp;
  phone_number: string | null;
  plan: string;
  private_sms_phone_number: string | null;
  profile_search_boost_earning_end_date: DateTimeStamp;
  profile_search_boost_earning_start_date: DateTimeStamp;
  profile_search_boost_extension_creation_time: DateTimeStamp;
  smart_pricing_enabled_time: DateTimeStamp | null;
  user_id: number;
  was_oldie: boolean;
  ncd_enabled: boolean;
  late_cancellation_fee_percent: number;
  no_show_fee_percent: number;
  bank_status?: number;
  refunds_enabled?: boolean;
  can_send_email_campaigns?: boolean;
}

export type ProfileEditErrorResponse = {
  error: true;
  route: {
    name: string;
    params: Record<string, string>;
  };
};

export type PayLaterEligibility = {
  proId: string | number;
  creationTime: string;
  eligible: boolean;
  feePaidCount: number;
};

export function isProvider(
  providerCandidate: PublicProvider | PrivilegedProvider | ProfileEditErrorResponse,
): providerCandidate is PublicProvider | PrivilegedProvider {
  return !(providerCandidate as ProfileEditErrorResponse).error;
}

/**
 * Get a provider's basic info.
 *
 * The returned data may either be PublicProvider or PrivilegedProvider,
 * depending on whether the authenticated user issuing the request has
 * access to privileged data for the provider.
*/
export function getProvider(
  providerId: number | string,
  includeAppointmentCount?: boolean,
): Promise<PublicProvider | PrivilegedProvider | ProfileEditErrorResponse> {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}`);

  if (includeAppointmentCount) {
    url.searchParams.append('includeAppointmentCount', 'true');
  }

  return ssFetchJSON(
    url.toString(),
    { ssCache: ApiCache },
  );
}

/**
 * Get provider's chat responsiveness info.
 *
 * Returns which response time bucket the pro falls into
*/
export function getChatResponsiveness(
  providerId: number | string,
): Promise<ChatResponsiveness> {
  const url = new URL(`${API_ROOT}/api/v1/providers/${providerId}/chat-responsiveness`);

  return ssFetchJSON(
    url.toString(),
    { ssCache: ApiCache },
  );
}

export async function getPrivilegedProvider(
  providerId: number | string,
  includeAppointmentCount?: boolean,
): Promise<PrivilegedProvider> {
  return (await getProvider(providerId, includeAppointmentCount)) as PrivilegedProvider;
}

export function clearApiCache() {
  ApiCache.clear();
}

export async function updateProvider(
  providerId: number | string,
  values: Partial<PrivilegedProvider>,
): Promise<void> {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}`);

  return ssFetchJSON(
    url.toString(),
    {
      method: 'PATCH',
      body: values,
    },
  ).then(clearApiCache);
}

export async function createProvider(
  values: Partial<PrivilegedProvider>,
): Promise<{ id: number }> {
  const url = new URL(`${API_ROOT}/api/v2/providers`);

  return ssFetchJSON(
    url.toString(),
    {
      method: 'POST',
      body: values,
    },
  );
}

export async function getPayLaterEligibility(
  providerId: number | string,
): Promise<PayLaterEligibility> {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/pay-later-eligible`);

  return ssFetchJSON(
    url.toString(),
  );
}

export async function recordSearchAppearance(
  proIds: (number | string)[],
): Promise<IResponse> {
  return ssFetch(`${API_ROOT}/api/v1/providers/record-search-appearance`, {
    method: 'POST',
    body: { proIds },
  });
}
