import { createModel } from '@rematch/core';
import produce from 'immer';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'react-native-uuid';
import * as storage from '../modules/KeyValueStorage';
import { selectors as userSelectors } from './CurrentUser.model';

/**
 * The name of the cookie where the trackingId is stored.
 *
 * Don't change this value; the backend uses it extensively.
 * What does TKM stand for? Nobody remembers... :)
 * @type {string}
 */
export const cookieName = 'tkm';

/**
 * The AsyncStorage key where the trackingId is stored.
 *
 * This is used, for instance, in BaseTracker.generateDefaultProps.
 * (Can't be imported there b/c circular imports).
 */
export const storageName = 'ss_tracking_cookie';

const TrackingId = createModel()({
  state: {
    trackingId: null,
  },

  reducers: {
    setTrackingId: produce((state, payload) => {
      state.trackingId = payload;
    }),
  },

  effects: dispatch => ({
    /**
     * Restore the trackingId from storage or cookie, and ensure the cookie set.
     *
     * If no trackingId exists, one is generated using uuidv4().
     * @returns {Promise<void>}
     */
    initializeTrackingId: async () => {
      let trackingId = Cookies.get(cookieName, { secure: true })
        || Cookies.get(cookieName, { secure: false });
      if (!trackingId) {
        trackingId = await storage.getItem(storageName);
        if (!trackingId) {
          trackingId = uuidv4();
        }
      }
      dispatch.trackingId.setTrackingId(trackingId);
      await dispatch.trackingId.storeTrackingId(trackingId);
    },

    /**
     * Update the trackingId in storage and cookie.
     *
     * @param payload - the trackingId value
     * @returns {Promise<void>}
     */
    storeTrackingId: async payload => {
      const trackingId = payload || null;
      if (!trackingId) {
        // clear
        Cookies.remove(cookieName, { secure: true });
        Cookies.remove(cookieName, { secure: false });
        await storage.removeItem(storageName);
      } else {
        Cookies.set(cookieName, trackingId, { secure: true });
        Cookies.set(cookieName, trackingId, { secure: false });
        await storage.setItem(storageName, trackingId);
      }
    },

    /**
     * Synchronize trackingId from the CurrentUser to the TrackingId models.
     *
     * If CurrentUser has a value and it doesn't match the stored value,
     * we overwrite the stored value.
     * If CurrentUser has no value and there is a stored value, attach the
     * stored value to CurrentUser.
     */
    syncTrackingId: async (_, state) => {
      const userTrackingId = userSelectors.getTrackingId(state);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const storedTrackingId = selectors.getTrackingId(state);
      if (userTrackingId && userTrackingId !== storedTrackingId) {
        // CurrentUser has an updated trackingId, store it.
        dispatch.trackingId.setTrackingId(userTrackingId);
        await dispatch.trackingId.storeTrackingId(userTrackingId);
      } else if (!userTrackingId && storedTrackingId) {
        // CurrentUser has no trackingId, set it.
        dispatch.user.setTrackingId(storedTrackingId);
      }
    },
  }),
});

export const selectors = {
  getTrackingId: state => state?.trackingId?.trackingId || null,
};

export default TrackingId;
