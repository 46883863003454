// @ts-strict-ignore
import { BackdropManager } from './BackdropManager';
import PortalElement from './PortalElement';
import { PortalElementArgs } from './types';

type GetElementArgs = PortalElementArgs & {
  closeOthers?: boolean;
  /** When supplied, indicates that a dedicated backdrop should be used and that this is the
   * appropriate test ID */
  dedicatedBackdropTestID?: string;
};

class PortalElementManager {
  private static instance: PortalElementManager = new PortalElementManager();

  public static getInstance() {
    return PortalElementManager.instance;
  }

  // To make the constructor private I need to define an empty private
  // constructor, which upsets the linter hence the ignore!
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
  }

  private elements: PortalElement[] = [];

  getElement = ({
    closeOthers,
    ...restArgs
  }: GetElementArgs): PortalElement => {
    const backdropManager = new BackdropManager('portal-overlay-backdrop', restArgs);

    const element = new PortalElement(
      backdropManager,
      restArgs,
    );

    if (closeOthers) {
      this.elements.forEach(el => el.requestExternalClose());
    }

    this.elements.push(element);

    return element;
  };
}

export default PortalElementManager.getInstance();
