import { useMemo } from 'react';
import useUnmount from './useUnmount';
import Janitor from '../modules/Janitor';

/**
 * A hook for using janitor in functional components that calls cleanup on unmount.
 *
 * @return {Janitor} - Return an instance of Janitor.
 */
export const useJanitor = () => {
  const janitor = useMemo(() => new Janitor(), []);

  useUnmount(() => {
    janitor.cleanup();
  }, []);

  return janitor;
};

export default useJanitor;
