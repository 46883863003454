// @ts-strict-ignore
import React from 'react';

type ReactChildArray = ReturnType<typeof React.Children.toArray>;

/**
 * Flattens children
 *
 * Similar as https://www.npmjs.com/package/react-flatten-children but adding the parentKey parameter option
 * to avoid having children with same keys
 * @param {ReactChildren} children
 * @param {string} parentKey
 * @returns {ReactChildren}
 * Discussion: https://styleseat.slack.com/archives/C01F2FG0J9E/p1680029958830219
 * Fix: https://codesandbox.io/s/blissful-matsumoto-94c17y?file=/App.js
 */
export const flattenChildren = (children: React.ReactNode, parentKey: string | number = 'parentKey') => {
  const childrenArray = React.Children.toArray(children);
  return childrenArray.reduce((flatChildren: ReactChildArray, child: any) => {
    if ((child as React.ReactElement<any>).type === React.Fragment) {
      return flatChildren.concat(
        flattenChildren((child as React.ReactElement<any>).props.children, parentKey + child.key ?? 'childKey'),
      );
    }
    flatChildren.push({ ...child, key: parentKey + child.key });
    return flatChildren;
  }, []);
};
