// @ts-strict-ignore
import {
  GetPlaceCommand,
  GetPlaceCommandInput,
  GetPlaceCommandOutput,
  LocationClient,
  SearchForPositionResult,
  SearchForSuggestionsResult,
  SearchPlaceIndexForPositionCommand,
  SearchPlaceIndexForPositionCommandInput,
  SearchPlaceIndexForSuggestionsCommand,
  SearchPlaceIndexForSuggestionsCommandInput,
} from '@aws-sdk/client-location';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import {
  AWS_AUTOCOMPLETE_INDEX_NAME,
  AWS_AUTOCOMPLETE_REGION,
  AWS_AUTOCOMPLETE_COGNITO_IDENTITY_POOL_ID,
  AWS_AUTOCOMPLETE_ESRI_INDEX_NAME,
} from '../../config';
import nonCriticalException from '../../modules/exceptionLogger';
import type { LocationRecord } from '../../store/Location.model';

/**
 * Docs for the client-location AWS SDK are here:
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-client-location/
 *
 * SDK-provided TypeScript definitions are also very helpful to understand the API.
 */

const locationClient = new LocationClient({
  region: AWS_AUTOCOMPLETE_REGION,
  credentials: fromCognitoIdentityPool({
    identityPoolId: AWS_AUTOCOMPLETE_COGNITO_IDENTITY_POOL_ID,
    clientConfig: { region: AWS_AUTOCOMPLETE_REGION },
  }),
});

export async function fetchLocationSuggestions(
  inputText: string,
  biasPosition: Pick<LocationRecord, 'latitude' | 'longitude'> | null,
  maxResults: number = 5,
  useFiltered: boolean = false,
): Promise<SearchForSuggestionsResult[]> {
  const input: SearchPlaceIndexForSuggestionsCommandInput = {
    IndexName: AWS_AUTOCOMPLETE_INDEX_NAME,
    Text: inputText,
    FilterCountries: ['USA'],
    MaxResults: maxResults,
    Language: 'en',
  };

  if (useFiltered) {
    input.IndexName = AWS_AUTOCOMPLETE_ESRI_INDEX_NAME;
    input.FilterCategories = [
      'NeighborhoodType',
      'MunicipalityType',
      'PostalCodeType',
    ];
  }

  if (biasPosition) {
    input.BiasPosition = [
      biasPosition.longitude,
      biasPosition.latitude,
    ];
  }

  const command = new SearchPlaceIndexForSuggestionsCommand(input);
  try {
    const response = await locationClient.send(command);
    const results = response.Results;
    return results;
  } catch (e) {
    nonCriticalException(e);
    return [];
  }
}

export async function fetchPlaceFromId(
  placeId: string,
  useFiltered: boolean = false,
): Promise<GetPlaceCommandOutput> {
  const placeInput: GetPlaceCommandInput = {
    IndexName: (useFiltered) ? AWS_AUTOCOMPLETE_ESRI_INDEX_NAME : AWS_AUTOCOMPLETE_INDEX_NAME,
    PlaceId: placeId,
  };

  const command = new GetPlaceCommand(placeInput);
  try {
    const response = await locationClient.send(command);
    return response;
  } catch (e) {
    nonCriticalException(e);
    return null;
  }
}

export async function fetchPlaceSuggestionsFromPosition(
  position: Pick<LocationRecord, 'latitude' | 'longitude'> | null,
  useFiltered: boolean = false,
): Promise<SearchForPositionResult[]> {
  const placeInput: SearchPlaceIndexForPositionCommandInput = {
    IndexName: (useFiltered) ? AWS_AUTOCOMPLETE_ESRI_INDEX_NAME : AWS_AUTOCOMPLETE_INDEX_NAME,
    Position: [
      position.longitude,
      position.latitude,
    ],
  };

  const command = new SearchPlaceIndexForPositionCommand(placeInput);
  try {
    const response = await locationClient.send(command);
    return response.Results;
  } catch (e) {
    nonCriticalException(e);
    return [];
  }
}
