// @ts-strict-ignore
import LogRocket from 'logrocket';
import { Assignments } from 'app/scripts/store/ABTest/ABTest.model';
import { APP, APP_ASSETS_URL } from '../../config';
import { DEBUG } from '../../config/index.native';
import { State as CurrentUserState } from '../../store/CurrentUser.types';
import { APP_VERSION } from '../AppConstants';

let identifiedUserId: number | null = null;

export interface IRequest {
  reqId: string;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
}

export interface IResponse {
  url: string;
  reqId: string;
  status?: number;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

/**
 * We will only record the network request/response bodies for the following urls:
 */
const recordNetworkURLs = [
  /schedule\/book/,
  /xxgeoip/,
  /v3.0\/salons.json/,
  /schedules\/availability/,
  /record-search-appearance/,
  /providers_bulk\/cip_eligibility_check/,
  /v2\/providers/,
  /appointment-request/,
];

export const assignmentsToString = (assignments: Assignments) => {
  const testNames = Object.keys(assignments)
    .filter(testName => assignments[testName].isAssigned && assignments[testName].isEnabled)
    .map(testName => `${testName} - ${assignments[testName].variationName}`)
    .join(' | ');

  return testNames;
};

// let LogRocket know the identity of the current user
export const identify = (newUser: Partial<CurrentUserState>, assignments: Assignments = {}) => {
  if (newUser?.user_id && newUser?.user_id !== identifiedUserId) {
    identifiedUserId = newUser?.user_id;
    LogRocket.identify(newUser?.ss_tracking_cookie, {
      name: `${newUser?.first_name} ${newUser?.last_name}`,
      email: newUser?.email,
      user_id: newUser.user_id,
      provider_id: newUser?.providerId,
      plan: newUser.plan,
      assignments: assignmentsToString(assignments),
    });
  }
};

export function networkTransmissionSanitizer<T extends IRequest | IResponse>(transmission: T): T {
  if (transmission.body && !recordNetworkURLs.some(urlReg => transmission.url?.match(urlReg))) {
    // scrub out the body
    // eslint-disable-next-line no-param-reassign
    transmission.body = null;
  }
  // eslint-disable-next-line no-param-reassign
  delete transmission?.headers?.authorization;
  return transmission;
}

/**
 * Initialize LogRocket session recording
 * @param logRocketKey
 */
export const init = (logRocketKey: string) => {
  LogRocket.init(logRocketKey, {
    release: APP_VERSION,
    shouldDebugLog: DEBUG,
    dom: {
      baseHref: APP && APP_ASSETS_URL ? APP_ASSETS_URL : undefined,
    },
    network: {
      isEnabled: true,
      requestSanitizer: networkTransmissionSanitizer,
      responseSanitizer: networkTransmissionSanitizer,
    },
  });
};
