export const getRoot = domain => {
  const host = document?.location.host;
  const prefixes = [
    'ssadmin',
    'admin.ss-prod',
    'admin.ss-stage',
  ];

  const prefix = prefixes.find(p => host?.startsWith(p));
  if (prefix) {
    return {
      isAdmin: true,
      root: `https://${prefix}.${domain}`,
    };
  }

  return {
    isAdmin: false,
    root: `https://www.${domain}`,
  };
};
