// @ts-strict-ignore
import BaseTracker, { PlatformProperty, TrackerProperties } from './BaseTracker';

const AppsFlyerEventsMap = {
  'PS Completed': 'af_complete_registration',
  'Pro Onboarding Profile Picture Screen Completed': 'Pro Profile Completed',
  client_appointment_ncd_booked: 'af_purchase',
  client_appointment_booked: 'af_client_appointment_booked',
  client_appointment_attributed_newclient_booked: 'af_purchase_nab',

};

export class AppsFlyerTracker extends BaseTracker {
  constructor(env: string, platform: PlatformProperty) {
    super('AppsFlyer', 'Apps Flyer Tracker', env, platform);
  }

  // eslint-disable-next-line class-methods-use-this
  trackEvent(eventName: string, properties: TrackerProperties | object): void | Promise<void> {
    if (window.plugins?.appsFlyer?.logEvent && AppsFlyerEventsMap[eventName]) {
      window.plugins.appsFlyer.logEvent(AppsFlyerEventsMap[eventName], properties, null, null);
    }
  }
}
