import produce from 'immer';
import { createModel } from '@rematch/core';
import { ssFetchJSON } from '../modules/ssFetch';
import type { RootModel, RootState } from './models';
import nonCriticalException from '../modules/exceptionLogger';
import { ProfileGalleryImage } from '../api/Providers/GalleryImages';

export type ProfileGallery = {
  provider: {
    id: number;
    vanity_url: string;
    provider_gallery_images: ProfileGalleryImage[];
  };
};

type State = {
  profileGalleriesByVanity: {
    [key: string]: {
      loading: boolean;
      result?: ProfileGallery;
    };
  };
};

type ProviderVanityPayload = { providerVanity: string };

const model = createModel<RootModel>()({
  name: 'profileGalleries',

  state: {
    profileGalleriesByVanity: {},
  } as State,

  reducers: {
    onLoading: produce<State, [ProviderVanityPayload]>((
      state: State,
      payload: ProviderVanityPayload,
    ) => {
      const { providerVanity } = payload;
      state.profileGalleriesByVanity[providerVanity] = { loading: true };
    }),
    onLoaded: produce<State, [ProfileGallery]>((
      state: State,
      payload: ProfileGallery,
    ) => {
      const { provider: { vanity_url: providerVanity } } = payload;
      state.profileGalleriesByVanity[providerVanity] = {
        loading: false,
        result: payload,
      };
    }),
    onFailed: produce<State, [ProviderVanityPayload]>((
      state: State,
      payload: ProviderVanityPayload,
    ) => {
      const { providerVanity } = payload;
      state.profileGalleriesByVanity[providerVanity] = { loading: false };
    }),
  },

  effects: dispatch => ({
    loadProfileGallery: async (
      payload: { providerVanity: string, noCache?: boolean, },
      rootState: RootState,
    ) => {
      const { providerVanity, noCache } = payload;
      if (noCache || !rootState.profileGalleries.profileGalleriesByVanity[providerVanity]) {
        try {
          await dispatch.profileGalleries.onLoading({ providerVanity });
          // TODO https://styleseat.atlassian.net/browse/ATL-3906
          // use a route that doesn't return a bunch of stuff we don't need
          // and/or fix the N+1 problem with the query not prefetching provider_service
          const provider: ProfileGallery = await ssFetchJSON(`/providers/url/${providerVanity}`);
          await dispatch.profileGalleries.onLoaded(provider);
          return {};
        } catch (e) {
          nonCriticalException(e);
          await dispatch.profileGalleries.onFailed({ providerVanity });
          return null;
        }
      }
      return null;
    },
  }),
});

export default model;
