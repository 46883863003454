// @ts-strict-ignore
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createHashHistory,
  createMemoryHistory,
  History,
} from 'history';
import { Platform } from 'react-native';
import {
  Route,
  RouteComponentProps,
  Router as ReactRouter,
  Switch,
} from './EnvRouter';
import nonCriticalException from '../../../modules/exceptionLogger';
import {
  useLinkTo,
  useMount,
  useParams,
} from '../../../hooks';
import Router, { IRouteState } from '../../../../../packages/router';
import type { IRootDispatch, RootState } from '../../../store/models';
import SSErrorBoundary from '../../SSErrorBoundary';
import PageLoadError from '../PageLoadError';
import type { StyleSeatRouteConfig } from '../../../routes';
import { GuardableRoute } from './GuardableRoute';
import { getIsApp } from '../../../modules/AppInfo';
import { ERROR_ROUTE, LANDING_ROUTE } from '../../../route-names';
import { handleNavigationAnalytics } from '../../../modules/analytics/landing';

function createHistory(): History<IRouteState> | undefined {
  if (Platform.OS !== 'web') {
    // RN case
    return createMemoryHistory<IRouteState>();
  }

  if (getIsApp()) {
    // cordova case
    return createHashHistory<IRouteState>();
  }

  // use default history
  return undefined;
}

type StateRouterProps = {
  routes: StyleSeatRouteConfig[];
};

/**
 * This makes the state name mimic a URL so React Router can do partial matches
 * @param stateName - The route's state name (i.e. profile.book)
 * @returns String - the route formatted like a url i.e. /profile/book
 */
const stateNameToPath = stateName => `/${stateName?.replace(/\./g, '/')}`;

const SubRoute = ({ route }: { route: StyleSeatRouteConfig }) => {
  const {
    url: childUrl,
    stateName,
    component: PageComponent,
    ...restRouteProps
  } = route;
  const childSubRoutes = route?.routes?.filter(r => r.isChild);
  const subRoutes = route?.routes?.filter(r => !r.isChild);
  return (
    <GuardableRoute
      path={stateNameToPath(stateName)}
      key={stateName}
      {...restRouteProps}
    >
      <PageComponent>
        {childSubRoutes?.map?.(r => (<SubRoute route={r} key={r.stateName} />))}
      </PageComponent>
      {subRoutes?.map?.(r => (<SubRoute route={r} key={r.stateName} />))}
    </GuardableRoute>
  );
};

const NotFound: React.FCWithChildren<RouteComponentProps> = () => {
  const linkTo = useLinkTo();

  if (getIsApp()) {
    linkTo(LANDING_ROUTE);
  } else {
    linkTo(ERROR_ROUTE);
  }

  return null;
};

/**
 * Router that works off of state names rather than urls
 *
 * this component not only switches the display according to what route is active
 * it also initializes and synchronizes the active route to the redux store
 * @param children
 * @param routes
 * @constructor
 */
export function StateRouterConnector({
  routes,
}: StateRouterProps) {
  const [routerReady, setRouterReady] = useState<boolean>(false);
  const dispatch = useDispatch<IRootDispatch>();
  const routeName = useSelector<RootState, string>(state => state.route?.name);
  const userStateInitialized = useSelector<RootState, Boolean>(
    state => state.userState?.initialized,
  );
  const params = useParams();

  const pathname = stateNameToPath(routeName);
  const search = new URLSearchParams(params).toString();

  // whenever the user navigates, update our store's state with the router's state
  const updateRouterLocation = async () => {
    const currentRoute = Router.getCurrentRoute();
    if (currentRoute) {
      dispatch.route.setRoute(currentRoute);
      if (!userStateInitialized) {
        await dispatch.userState?.refreshUserState();
        await dispatch.utmParameters?.updateFromUserState();
      }
      await dispatch.utmParameters?.loadUTMParams();
      handleNavigationAnalytics(currentRoute);
      setRouterReady(true);
    }
  };

  useMount(() => {
    Router.setup(routes, createHistory());
    Router.onNavigation(updateRouterLocation);
    Router.onException(nonCriticalException);
    updateRouterLocation();
  });

  if (!Router.sessionHistory || !routerReady) {
    return null;
  }

  return (
    <ReactRouter history={Router.sessionHistory}>
      <Switch
        location={{
          pathname,
          search,
          state: undefined,
          hash: undefined,
        }}
      >
        {[
          ...routes.map(routeConfig => {
            const {
              url,
              stateName,
              component: PageComponent,
              routes: childRoutes,
              ...restRouteProps
            } = routeConfig;
            const childSubRoutes = childRoutes?.filter(r => r.isChild);
            const subRoutes = childRoutes?.filter(r => !r.isChild);
            return (
              <GuardableRoute
                path={stateNameToPath(stateName)}
                key={stateName}
                {...restRouteProps}
                render={props => (
                  <SSErrorBoundary
                    info="StateRouterConnector wrapper"
                    renderFallback={() => <PageLoadError />}
                  >
                    <PageComponent {...props}>
                      {/* Child sub routes are passed as children to the parent route page */}
                      {childSubRoutes?.map?.(route => (
                        <SubRoute
                          route={route}
                          key={route.stateName}
                        />
                      ))}
                    </PageComponent>
                    {/* Sub routes are matchable, but rendered separately from the Parent route */}
                    {subRoutes?.map?.(route => (
                      <SubRoute
                        route={route}
                        key={route.stateName}
                      />
                    ))}
                  </SSErrorBoundary>
                )}
              />
            );
          }),
          (
            <Route key="not-found-path" path="*" render={props => (<NotFound {...props} />)} />
          ),
        ]}
      </Switch>
    </ReactRouter>
  );
}
