/**
 * Contains a collection of functions relating to setting and resetting of passwords.
 */

import ssFetch from './ssFetch';

/**
 * Submits the email to which to send a password reset email. Resolves if the email is sent.
 * @param {String} email The email to which to send a password reset email
 */
export const confirmEmailForPasswordReset = function (email) {
  return ssFetch('/api/v2/auth/password/reset/', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
    .then(response => response.json())
    .then(json => ({ data: json }));
};
