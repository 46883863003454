// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import { PrivilegedProviderProfile } from '../../../../modules/Api.types';
import type { Modify } from '../../../../utils/UtilityTypes';
import type { RootModel } from '../../../../store/models';
import { loadProfileEditData, ProfileEditPageData } from '../../../../modules/provider/ProfileEdit/ProfileEdit';
import { ProfileEditErrorResponse } from '../../../../api/Providers';
import { UserData } from '../../ProProfile/ProProfile.model';

export type ProfileEditLoadingState = Modify<ProfileEditLoadedState, {
  loading: true;
}>;

export type ProfileEditErrorState = Modify<ProfileEditLoadedState, {
  error: true;
  errorMessage?: string;
  errorRoute?: ProfileEditErrorResponse['route'];
  loading: false;
}>;

export type ProfileEditLoadedState = ProfileEditPageData & {
  error: false;
  errorMessage?: string | undefined;
  errorRoute?: ProfileEditErrorResponse['route'];
  loading: false;
};

export type ProfileEditEntry =
  ProfileEditLoadingState | ProfileEditErrorState | ProfileEditLoadedState;

type State = {
  [id: number]: ProfileEditEntry;
};

export type CompleteProfileEditData = Modify<ProfileEditEntry, {
  profile: PrivilegedProviderProfile;
}>;

type OnFailedPayload = {
  providerId: number | string;
  errorMessage?: string;
  errorRoute?: ProfileEditErrorResponse['route'];
};

/**
 * This model represents the provider profile page/feature
 * NOT specifically the backend providers/profile API
 */
const model = createModel<RootModel>()({
  name: 'proProfileEdit',

  state: {} as State,

  reducers: {
    onLoading: produce<State, [{ providerId: number }]>((
      state: State,
      payload: { providerId: number },
    ) => {
      const { providerId } = payload;
      state[providerId] = {
        loading: true,
        profile: undefined,
        error: undefined,
        galleryImages: undefined,
        galleryImagesCount: undefined,
        serviceGroups: undefined,
      };
    }),

    onLoaded: produce<State, [ProfileEditPageData]>((
      state: State,
      payload: ProfileEditPageData,
    ) => {
      const { id } = payload.profile;
      state[id] = {
        ...payload,
        loading: false,
        error: false,
      };
    }),

    onFailed: produce<State, [OnFailedPayload]>((
      state: State,
      payload: OnFailedPayload,
    ) => {
      const {
        providerId,
        errorMessage,
        errorRoute,
      } = payload;
      state[providerId] = {
        error: true,
        errorMessage,
        profile: undefined,
        errorRoute,
      };
    }),
  },

  effects: dispatch => ({
    /**
     * Pro has landed on the profile (edit, not view) page
     */
    land: async (
      payload: { providerId: number },
    ): Promise<ProfileEditPageData | undefined> => {
      try {
        const profileData = await loadProfileEditData(payload.providerId);
        dispatch.proProfileEdit.onLoaded(profileData);
        return profileData;
      } catch (e) {
        dispatch.proProfileEdit.onFailed({
          providerId: payload.providerId,
          errorMessage: e.message,
          errorRoute: e.route,
        });
      } finally {
        dispatch.loader.setIsLoading(false);
      }
      return undefined;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    forProvider: hasProps((_, props: { providerId: number }) => createSelector(
      slice,
      (providers): ProfileEditEntry => providers[props.providerId],
    )),

    /**
     * selects user data relevant to the profile edit page
     * @param models
     * @return {any}
     */
    userData: hasProps((
      models,
      props: { providerId: number },
    ) => createSelector(
      models.user.getId,
      models.user.providerId,
      models.user.isSuperUser,
      models.user.providers,
      (userId, proId, isSuperUser, editableProviders): UserData => ({
        userId,
        isAPro: !!proId,
        hasEditPrivilege: (
          !!proId
          && (
            isSuperUser
            || !!editableProviders.find(p => p.id === props.providerId)
          )
        ),
      }),
    )),

    getCompleteProfile: hasProps((_, props: { providerId: number }) => createSelector(
      slice,
      (providers): CompleteProfileEditData => {
        const profileEntry = providers[props.providerId];
        if (!profileEntry) return profileEntry as CompleteProfileEditData;

        return {
          ...profileEntry,
          profile: {
            ...profileEntry.profile,
            gallery_images: profileEntry.galleryImages,
            service_groups: profileEntry.serviceGroups,
          } as PrivilegedProviderProfile,
        };
      },
    )),
  }),

});

export default model;
