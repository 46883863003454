// @ts-strict-ignore
export type SignupProps = {
  loading: boolean;
  showName: boolean;
  canSubmit: boolean;
  serverError?: string | boolean;
  firstName: string;
  firstNameError?: string | boolean;
  lastName: string;
  lastNameError?: string | boolean;
  email: string;
  emailError?: string | boolean;
  password: string;
  passwordError?: string | boolean;
  phoneNumber: string;
  phoneNumberError?: string | boolean;
  channel: string;
  channels: ChannelType[];
  channelError?: string | boolean;
  showSignupChannel: boolean;
  subscribed: boolean;
  showBackButton: boolean;
  showAppleSignup: boolean;
  showFacebookSignup?: boolean;
  facebookSignupDisabled: boolean;
  showPassword: boolean;
  showPhoneNumber: boolean;
  onSubmit: () => void;
  onFirstNameChange: (text: string) => void;
  onLastNameChange: (text: string) => void;
  onEmailChange: (text: string) => void;
  onPasswordChange: (text: string) => void;
  onPhoneNumberChange: (text: string) => void;
  onSubscribeChange: (subscribed: boolean) => void;
  onChannelChange: (channel: ChannelType) => void;
  onBackNavigation: () => void;
  onSelectFacebookSignup: () => void;
  onSelectAppleSignup: () => void;
  onShowTermsOfService: () => void;
  onShowPrivacyPolicy: () => void;
  onLogin: () => void;
  plan: string;
  showMarketingModal: boolean;
  onMarketingModalIndexChanged: (index: number) => void;
  showPricingScreen: boolean;
  hideSignupButton: boolean;
  onEmailFocusChange?: (boolean) => void;
};

export type SignupContainerProps = {
  onComplete: (providerId?: number) => void;
  onFailed?: () => void;
  shouldSubscribeByDefault?: boolean;
};

export enum ChannelType {
  ReferredByPro = 'Referred by a friend',
  ReferredByClient = 'Referred by my client',
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Pinterest = 'Pinterest',
  Google = 'Google',
  AppStore = 'App store',
}
