// @ts-strict-ignore
import moment from 'moment';
import { ApiError, IProviderSignupSettingsData } from '../../modules/Api.types';
import SimpleCache from '../../modules/SimpleCache';
import ssFetch, { IResponse, ssFetchJSON } from '../../modules/ssFetch';

/**
 * Module for everything to do with calling the signup_settings endpoint +
 * caching related to it.
 */

/**
 * Cache for Signup Settings, cache for 15 minutes (ported from ProviderService)
 */
const SignupSettingsCache = new SimpleCache(15 * 60);

export function clearCache() {
  SignupSettingsCache.clear();
}

const url = (providerId: number): string => `/api/v2/providers/${providerId}/signup_settings`;

async function parseResponse(
  response: IResponse<IProviderSignupSettingsData>,
): Promise<IProviderSignupSettingsData> {
  let json: IProviderSignupSettingsData;
  let rawText: string;
  try {
    json = await response.json();
  } catch (err) {
    try {
      rawText = await response.text();
    } catch (conversionError) {
      throw new ApiError(response.status, response);
    }
  }

  if (!response.ok) {
    throw new ApiError(response.status, json || rawText);
  }

  return json;
}
/**
 * Get a pro's signup settings
 *
 * @param {number} providerId
 * @returns {Promise<IProviderSignupSettingsData>}
 */

export async function loadSignupSettings(
  providerId: number,
): Promise<IProviderSignupSettingsData> {
  return ssFetchJSON<IProviderSignupSettingsData>(
    url(providerId),
    { ssCache: SignupSettingsCache },
  );
}

/**
 * Update Stripe Instrument ID
 *
 * @param {number} providerId
 * @param {number} stripeInstrumentId
 * @returns {Promise<IProviderSignupSettingsData>}
 */

export async function updateStripeInstrument(
  providerId: number,
  stripeInstrumentId: IProviderSignupSettingsData['preview_subscription_stripe_instrument'],
  planName?: string,
): Promise<IProviderSignupSettingsData> {
  const response = await ssFetch<IProviderSignupSettingsData>(
    url(providerId),
    {
      body: {
        preview_subscription_stripe_instrument: stripeInstrumentId,
        plan: planName,
      },
      method: 'PATCH',
    },
  );
  clearCache();
  return parseResponse(response);
}

/**
 * Subscribe a pro
 *
 * @param {number} providerId
 * @param {number} stripeInstrumentId
 * @returns {Promise<IProviderSignupSettingsData>}
 */
export async function startProSubscription(
  providerId: number,
  stripeInstrumentId: IProviderSignupSettingsData['preview_subscription_stripe_instrument'],
): Promise<IProviderSignupSettingsData> {
  const response = await ssFetch<IProviderSignupSettingsData>(
    url(providerId),
    {
      body: {
        preview_subscription_stripe_instrument: stripeInstrumentId,
        preview_subscription_start_time: moment.utc().format(),
        preview_subscription_cancellation_time: null,
      },
      method: 'PATCH',
    },
  );
  clearCache();
  return parseResponse(response);
}

/**
 * Reenroll a pro
 *
 * @param {number} providerId
 * @param {number} stripeInstrumentId
 * @returns {Promise<IProviderSignupSettingsData>}
 */
export async function reenrollProSubscription(
  providerId: number,
  stripeInstrumentId: IProviderSignupSettingsData['preview_subscription_stripe_instrument'],
): Promise<IProviderSignupSettingsData> {
  const response = await ssFetch<IProviderSignupSettingsData>(
    url(providerId),
    {
      body: {
        preview_subscription_stripe_instrument: stripeInstrumentId,
        preview_subscription_cancellation_time: null,
      },
      method: 'PATCH',
    },
  );
  clearCache();
  return parseResponse(response);
}

/**
 * Cancel a Pro's subscription
 *
 * @param {number} providerId
 * @param {number} stripeInstrumentId
 * @returns {Promise<IProviderSignupSettingsData>}
 */
export async function cancelProSubscription(
  providerId: number,
): Promise<IProviderSignupSettingsData> {
  const response = await ssFetch<IProviderSignupSettingsData>(
    url(providerId),
    {
      body: {
        preview_subscription_cancellation_time: moment.utc().format(),
      },
      method: 'PATCH',
    },
  );
  clearCache();
  return parseResponse(response);
}

/** Set the pro's synced_google_cal flag
 *
 * @param {number} providerId
 * @param {boolean} syncedGoogleCal
 * @returns {Promise<IProviderSignupSettingsData>}
 */
export async function setSyncedGoogleCal(
  providerId: number,
  syncedGoogleCal: boolean,
): Promise<IProviderSignupSettingsData> {
  const response = await ssFetch<IProviderSignupSettingsData>(
    url(providerId),
    {
      body: {
        synced_google_cal: syncedGoogleCal,
      },
      method: 'PATCH',
    },
  );
  clearCache();
  return parseResponse(response);
}
