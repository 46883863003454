import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const ClientProCalendarViewed: SegmentEventData = {
  eventName: AnalyticEvent.CalendarViewed,
  eventNameLegacy: 'client_procalendar_viewed',
  eventProperties: [
    'provider_id_viewed',
    'service_ids',
  ],
};

export default ClientProCalendarViewed;
