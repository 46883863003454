import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const AppsFlyerInstallAttribution: SegmentEventData = {
  eventName: AnalyticEvent.AppsFlyerInstallAttribution,
  eventProperties: [
    'adgroup_id',
    'adset',
    'adset_id',
    'af_siteid',
    'af_status',
    'agency',
    'campaign',
    'campaign_id',
    'click_time',
    'http_referrer',
    'install_time',
    'is_first_launch',
    'media_source',
    'retargeting_conversion_type',
    // The following _may_ appear, but not always
    'af_channel', // i.e. Facebook, Google, etc.
    'af_cpi',
    'cost_cents_USD',
    'orig_cost',
  ],
};

export default AppsFlyerInstallAttribution;
