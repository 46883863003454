import { API_ROOT } from '../../../config';
import ssFetch from '../../../modules/ssFetch';
import { clearProviderCache } from './cache';
import { IGalleryImage } from './types';

/**
 * Patch the image.
 */
export async function patchImage(image: IGalleryImage, patches: Partial<IGalleryImage>) {
  const providerId = image.provider;

  const response = await ssFetch(`${API_ROOT}/api/v2/providers/${providerId}/gallery_images/${image.id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patches),
  });
  await clearProviderCache();
  return response;
}
