// @ts-strict-ignore
import moment from 'moment';
import {
  IProClientsInLast30DaysCount,
  IClientCount,
  IProfileFeedback,
  ISearchAppearances,
  ApiError,
  IProviderSelfPromotionDates,
  IProReferralData,
  IProviderMetric,
  ProviderSearchRankStats,
  INCDDetails,
} from './Api.types';
/**
 * A module containing various Provider-related API methods.
 *
 * When adding methods to this module, strive to follow these patterns:
 * * If the method does a POST/PATCH/DELETE, call ApiCache.clear().
 * * If a method does a GET, use ApiCache.
 * * Make update methods as specific as possible; for instance, instead of a
 *   generic method called `updateProvider`, consider adding a method which
 *   updates specific data, such as `setPhoneNumber`. This allows a single
 *   entry-point for any data pre-or-post-processing, and allows for multiple
 *   API calls to be made within a single function if necessary.
 * * When possible, use method and variable names that match the backend field
 *   names. For type interfaces, try to match the serializer name. This makes
 *   the relationship between frontend and backend data clear.
 *
 *  NOTE!
 *  We're currently considering adopting a directory structure that would
 *  dictate predictable locations for all of these endpoint-style functions
 *  to live in- and reconsidering the use of Caches in general in favor
 *  of "redux for everything".
 *
 *  Stay tuned and add to this file cautiously.
 *
 */

import { API_ROOT } from '../config';
import ssFetch, { IResponse, ssFetchJSON } from './ssFetch';
import SimpleCache from './SimpleCache';

import nonCriticalException from './exceptionLogger';
import {
  ApiCache,
  PrivilegedProvider,
  PublicProvider,
} from '../api/Providers/Providers';
import { ProNCCPendingAppointment } from '../api/Providers/Appointments';

export const NCDDetailsCache = new SimpleCache(15 * 60);

/**
 * If response is an error, throw it.
 * If response is successful, return parsed JSON or raw text.
 */
export async function parseResponse<T>(response: IResponse<T>): Promise<T | string> {
  let data: T | string;
  try {
    data = await response.json();
  } catch (err) {
    // Could not parse JSON
    data = await response.text();
  }
  if (!response.ok) {
    throw new ApiError(response.status, data);
  }
  return data;
}

/**
 * Get a provider's basic info.
 *
 * The returned data may either be PublicProvider or PrivilegedProvider,
 * depending on whether the authenticated user issuing the request has
 * access to privileged data for the provider.
 *
 * @param {number} providerId
 * @param includeAppointmentCount
 * @returns {Promise<PublicProvider | PrivilegedProvider>}
 * @deprecated please use getProvider in app/scripts/api/Providers
 */
export function loadProvider(
  providerId: number | string,
  includeAppointmentCount?: boolean,
): Promise<PublicProvider | PrivilegedProvider> {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}`);

  if (includeAppointmentCount) {
    url.searchParams.append('includeAppointmentCount', 'true');
  }

  return ssFetchJSON(
    url.toString(),
    { ssCache: ApiCache },
  );
}

/**
 * Get a list of ncc appointments for the given provider pending to approve
 * @param {number | string} providerId
 * @returns {Promise<ProNCCPendingAppointment[]>}
 */
export async function loadNCCPendingClientsAppointments(
  providerId: number | string,
): Promise<ProNCCPendingAppointment[]> {
  const url = new URL(
    `${API_ROOT}/api/v2/providers/${providerId}/appointments/ncc-pending-clients`,
  );

  const resp = await ssFetchJSON(
    url.toString(),
  );

  return resp;
}

export async function loadClientsInLast30DaysCount(
  providerId: number,
): Promise<number> {
  const url = new URL(
    `${API_ROOT}/api/v2/providers/${providerId}/appointments/last-30day-client-count`,
  );

  try {
    const resp: IProClientsInLast30DaysCount = await ssFetchJSON(
      url.toString(),
      { ssCache: ApiCache },
    );

    return resp.booked_clients_in_last_30_days_count;
  } catch (error) {
    nonCriticalException(error);
    return 0;
  }
}

export async function getClientCount(
  providerId: number | string,
  cache?: SimpleCache,
): Promise<number> {
  const url = new URL(`${API_ROOT}/provider/${providerId}/client_count`);

  try {
    const resp: IClientCount = await ssFetchJSON(
      url.toString(),
      { ssCache: cache || ApiCache },
    );
    return resp.client_count;
  } catch (error) {
    nonCriticalException(error);
    return 0;
  }
}

/**
 * Sends profile feedback to the specified provider
 *
 * @param {number|string} providerId The provider ID
 * @param {string} message The feedback message
 * @param {number} stars number of stars
 * @param {number} choiceSelected the choice selected from the multi choice
 *                                option when less than 3 stars
 * @returns {Promise<IProfileFeedback>}
 */
export async function sendProfileFeedback(
  providerId: number | string,
  message: string,
  stars: number,
  choiceSelected: number,
): Promise<IProfileFeedback> {
  const response = await ssFetch(
    `/api/v1/providers/${providerId}/profile_feedback`,
    {
      body: {
        stars,
        message,
        choice_selected: choiceSelected,
      },
      method: 'POST',
    },
  );
  // no need to clear cache as this doesn't change the profile in any way
  return parseResponse(response);
}

/**
 * Get the number of time the provider has appeared in the Search Results Page.
 *
 * @param {number|string} providerId The provider ID
 * @param {string} message The feedback message
 * @param {number} stars number of stars
 * @returns {Promise<ISearchAppearances>}
 */
export async function getNumberOfSearchAppearances(
  providerId: number | string,
): Promise<ISearchAppearances> {
  return ssFetchJSON(`/api/v1/providers/${providerId}/search-appearances`);
}

/**
 * Stores the latest dates on which a pro last performed a self promotion action, e.g. last
 * time they promoted using their profile, or last time they invited a client to book.
 * These values are useful for things like sending specialized comms to pros that haven't
 * promoted in
 * a while or
 * showing specific CTAs in the UI.
 * @param {number} providerId: the provider whose self promotion dates we'll update.
 * @param {string[]} actions: The names of the self promote dates to be updated.
 */
export async function updateSelfPromotionDates(
  providerId: number | string,
  actions: string[],
): Promise<IProviderSelfPromotionDates> {
  const now = moment();
  const body = actions.reduce((obj, name) => {
    // eslint-disable-next-line no-param-reassign
    obj[name] = now;
    return obj;
  }, {});
  const response = await ssFetch(
    `/api/v2/providers/${providerId}/self_promotion_dates`,
    {
      body,
      method: 'PATCH',
    },
  );
  // no need to clear cache as this doesn't change the profile in any way
  return parseResponse(response);
}

export const LAST_SELF_PROMOTED = 'last_self_promoted_date';
export const LAST_INVITED_TO_BOOK = 'last_invited_to_book_date';

/**
 * Retrieves dates for last promoted and last invite to book actions
 * @param {number} providerId: the provider
 */
export async function getSelfPromotionDates(
  providerId: number | string,
): Promise<IProviderSelfPromotionDates> {
  const response = await ssFetch(
    `/api/v2/providers/${providerId}/self_promotion_dates`,
    {
      method: 'GET',
    },
  );
  return parseResponse(response);
}

/**
 * Extends the search boost eligible end date by 7 days
 * @param {number} providerId: the provider
 */
export async function extendSearchBoostEligibleDate(
  providerId: number | string,
): Promise<IProviderSelfPromotionDates> {
  const response = await ssFetch(
    `/api/v2/providers/${providerId}/extend-boost-earning-period`,
    {
      method: 'POST',
    },
  );

  return parseResponse(response);
}

export async function loadProReferralPlan(
  providerId: number | string,
): Promise<IProReferralData> {
  const url = new URL(`${API_ROOT}/api/v1/referrals/${providerId}/plan`);
  const resp = await ssFetchJSON(
    url.toString(),
  );
  return resp;
}

export function recordServiceInteraction(
  providerId: number | string,
  serviceId: number | string,
): void {
  ssFetch(
    `/api/v2/providers/${providerId}/service/${serviceId}/record-client-interaction`,
    {
      method: 'POST',
    },
  );
}

export async function getProviderMetrics(providerId: number | string): Promise<IProviderMetric> {
  const url = `/api/v1/providers/${providerId}/marketing-metrics`;
  const response = await ssFetchJSON(url);
  return response;
}

export async function getProviderInsights(providerId: number, appVersion?: number | string) {
  const url = `/api/v2/providers/${providerId}/insights?${appVersion ? `appVersion=${appVersion}` : ''}`;
  const response = await ssFetch(
    url,
    {
      method: 'GET',
      ssCache: ApiCache,
    },
  );

  return parseResponse(response);
}

export function postProviderInsightAction(
  providerId: number,
  insightId: number,
  action: number,
  metadata?: {},
) {
  const actionType = action;
  const url = `/api/v2/providers/${providerId}/insight_actions`;
  const data = JSON.stringify(metadata);
  ssFetch(
    url,
    {
      body: {
        insight: insightId,
        action_type: actionType,
        metadata: data,
      },
      method: 'POST',
    },
  );
}

export type SpecialtiesResponse = {
  specialized_services: Array<{
    service_id: number;
    service_name: string;
  }>;
};

export const getSpecialties = async (providerId: number | string): Promise<SpecialtiesResponse> => {
  const url = `${API_ROOT}/api/v2/providers/${providerId}/specializations`;
  const response = await ssFetch(
    url,
    {
      method: 'GET',
      ssCache: ApiCache,
    },
  );
  return parseResponse(response);
};

export const getNCDDetails = (providerId: number | string, appointmentId: number | string) => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/appointments/${appointmentId}/ncd_details`);

  return ssFetchJSON<INCDDetails>(url.toString(), {
    method: 'GET',
    ssCache: NCDDetailsCache,
  });
};

export const getProviderSearchRankStats = async (
  providerId: number,
): Promise<ProviderSearchRankStats> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/search-rank/summaries`);

  return ssFetchJSON(url.toString(), { ssCache: ApiCache });
};
