import React from 'react';
import { useSelector } from 'react-redux';
import store from '../../../store';
import { LoadingLion } from './LoadingLion';

export const LoadingLionContainer: React.FCWithChildren = () => {
  const loading = useSelector(store.select.loader.loading);

  if (!loading) {
    return null;
  }

  return (
    <LoadingLion />
  );
};
