/* eslint-disable class-methods-use-this  */
import * as braze from '@braze/web-sdk';
import { AnalyticsUserData } from '@styleseat/analytics/types';
import { ContentCards, InitializationOptions } from '@braze/web-sdk';
import BaseTracker from '../../../../packages/analytics/trackers/BaseTracker';
import { BrazePlugin } from '../CordovaPlugins.types';
import {
  APP,
  ENV,
  BRAZE_KEY,
} from '../../config';

/**
 * Class for connectivity to Braze for user communications (in-app messaging, push notifications).
 */
class BrazeTracker extends BaseTracker {
  #brazeKey: string;

  brazeSdk: BrazePlugin | typeof braze;
  private brazeId: string | undefined;
  private acceptedEventsList = [
    'braze_test_event',
    'Show Education Modal',
    'client_proprofile_tab_clicked',
    // necessary for triggering Braze in-app messages
    'Content Card Clicked',
  ];

  private brazeSDKInitParams: InitializationOptions;

  public isInitialized = false;

  constructor() {
    super('braze', 'Braze', ENV, APP ? 'app' : 'web', APP);
    this.#brazeKey = BRAZE_KEY;
    this.brazeSdk = this.isApp ? (window.BrazePlugin as BrazePlugin) : braze;
    this.isInitialized = false;
    this.brazeSDKInitParams = {
      baseUrl: 'sdk.iad-05.braze.com',
    };

    if (!this.isApp) {
      this.brazeSDKInitParams.enableLogging = true;
      this.brazeSDKInitParams.allowUserSuppliedJavascript = true;
      this.brazeSDKInitParams.minimumIntervalBetweenTriggerActionsInSeconds = 0;
    }
  }

  initializeBraze() {
    if (!this.isInitialized) {
      // The cordova plugin for Braze is initialized on app startup, and does not contain the same
      // initialization functions around IAMs, as they should work out of the box. The web SDk
      // isn't initialized until the command is explicitly called, and needs the associated IAM
      // functions. Additionally, the content card functions are different between the web sdk and
      // their cordova plugin
      this.isInitialized = braze.initialize(this.#brazeKey, this.brazeSDKInitParams);

      if (!this.isApp) {
        braze.automaticallyShowInAppMessages();
        braze.subscribeToInAppMessage(inAppMessage => {
          braze?.showInAppMessage(inAppMessage);
        });
      }

      if (this.isInitialized) {
        braze.requestContentCardsRefresh();
      }
    }
  }

  /**
   * Logs the user's current device with Braze.
   * @global window.AppboyPlugin The Braze SDK. Only loaded in the mobile app.
   * @param userData Data from the analytics package that identifies the current user.
   */
  updateUser(userData: AnalyticsUserData) {
    const {
      client_handle, is_logged_in, user_token,
    } = userData;
    const brazeUserId = is_logged_in ? user_token : client_handle;

    if (brazeUserId) {
      if (brazeUserId !== this.brazeId) {
        this.initializeBraze();
        this.brazeSdk?.changeUser(brazeUserId);
        this.brazeId = brazeUserId;
        if (this.isApp) {
          braze.changeUser(brazeUserId);
        } else {
          braze.openSession();
        }
        braze.requestContentCardsRefresh();
      }
    }
  }

  /**
   * Registers custom event with Braze
   * @param eventName name of event to be sent to braze
   * @param properties data to be sent in the Braze custom event
   */
  trackEvent(eventName: string, properties?: object) {
    const eventProperties = {
      external_id: this.brazeId,
      ...properties,
    };

    if (this.acceptedEventsList.includes(eventName)) {
      this.brazeSdk?.logCustomEvent(eventName, eventProperties);
    }
  }

  isPushBlocked() {
    return this.isInitialized && this.brazeSdk.isPushBlocked?.();
  }

  isPushSupported() {
    return this.isInitialized && this.brazeSdk.isPushSupported?.();
  }

  isPushPermissionGranted() {
    return this.isInitialized && this.brazeSdk.isPushPermissionGranted?.();
  }

  requestPushPermission() {
    return this.isInitialized && this.brazeSdk.requestPushPermission?.();
  }

  openSession() {
    return braze.openSession();
  }

  logContentCardClick(card: braze.Card) {
    braze.logContentCardClick(card);
  }

  logContentCardImpression(card: braze.Card) {
    braze.logContentCardImpressions([card]);
  }

  requestContentCardRefresh() {
    braze.requestContentCardsRefresh();
  }

  subscribeToContentCardsUpdates(callbackFn: (update: ContentCards) => void) {
    if (!this.isInitialized) {
      this.initializeBraze();
    }

    const ref = braze.subscribeToContentCardsUpdates(callbackFn);
    this.requestContentCardRefresh();
    return ref;
  }

  removeSubscription(subscriptionGuid: string) {
    return braze.removeSubscription(subscriptionGuid);
  }
}

export const brazeTracker = new BrazeTracker();
