// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { indexBy } from 'underscore';
import type { RootModel } from './models';

import {
  LOCAL_STORAGE_PERMANENT_KEY,
  LOCAL_STORAGE_ONE_APPOINTMENT_KEY,
  CLIENT_EXIT_BOOKING_CROSS_SELL_FLAG_NAME,
  CLIENT_INCENTIVES_SOCIAL_PROMO_FLAG_NAME,
  CLIENT_INCENTIVES_PRESELECTED_URL_FLAG_NAME,
  LOCAL_STORAGE_ONE_APPOINTMENT_DISENROLLEMENT_KEY,
  CLIENT_INCENTIVES_IDLE_TOASTER_FEATURE_FLAG_NAME,
  CLIENT_INCENTIVES_PRESELECTED_USER_ACCOUNT_FLAG_NAME,
  CLIENT_BOOKING_SUCCESS_INCENTIVE_CROSS_SELL_FLAG_NAME,
  CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME,
  INCENTIVES_DISCOUNT_CODE,
} from '../components/consumer/incentives/constants';
import { CLIENT_REFERRALS_SHARE_CARD_UI_TEST_NAME } from '../components/consumer/sharing/constants';

import stateManagerSrv from '../modules/user/stateManager';
import { getItem as kvGetItem, setItem as kvSetItem } from '../modules/KeyValueStorage';
import { hasLoadedDeferred as hasABTestLoaded } from './UserState.model';

import Deferred from '../testUtilities/deferred';
import { DiscountStatus } from './ClientPromotions.types';

export type IncentivesChecks = {
  [incentivesKey: string]: IncentivesCheck;
};

type EnrollmentType = 'singleAppointment' | 'permanent';

export type IncentivesCheck = {
  testName: string;
  enrollmentType: EnrollmentType;
  testIsEnabled?: boolean;
  isCurrentlyEnrolled?: boolean;
  wasPreviouslyEnrolled?: boolean;
  checkFailed?: boolean;
};

export type State = {
  showIncentives: boolean;
  checkInProgress: boolean;
  firstCheckCompleted: boolean;
  incentivesChecks: IncentivesChecks;
};

const hasLoadedDeferred = new Deferred<State>();

export const REFERRAL_SHARE_KEY = 'profile_referral';

const urlTestMap = {
  [CLIENT_INCENTIVES_PRESELECTED_URL_FLAG_NAME]: 'styleseat_offers_earned',
  [CLIENT_INCENTIVES_SOCIAL_PROMO_FLAG_NAME]: 'social_offer',
};

const ONE_APPOINTMENT_TEST_NAMES = [
  REFERRAL_SHARE_KEY,
  CLIENT_INCENTIVES_IDLE_TOASTER_FEATURE_FLAG_NAME,
  CLIENT_EXIT_BOOKING_CROSS_SELL_FLAG_NAME,
  CLIENT_BOOKING_SUCCESS_INCENTIVE_CROSS_SELL_FLAG_NAME,
  CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME,
  CLIENT_REFERRALS_SHARE_CARD_UI_TEST_NAME,
];

async function checkIncentivesPermanentEnrollment(
  testName: string,
  rootState,
): Promise<IncentivesCheck> {
  const enrollmentType = 'permanent';
  try {
    const LOCAL_STORAGE_KEY = testName === CLIENT_INCENTIVES_PRESELECTED_URL_FLAG_NAME ? LOCAL_STORAGE_PERMANENT_KEY : `incentives_permanent_${testName}`;

    if (rootState.abTest.assignments[testName]?.isEnabled) {
      let isEnrolled = await stateManagerSrv.getActionValue(LOCAL_STORAGE_KEY);

      isEnrolled = isEnrolled || await kvGetItem(LOCAL_STORAGE_KEY);
      isEnrolled = isEnrolled || rootState.route.params?.[urlTestMap[testName]] !== undefined;

      if (isEnrolled) {
        await kvSetItem(LOCAL_STORAGE_KEY, true);
        await stateManagerSrv.addAction(LOCAL_STORAGE_KEY, true);
      }

      return {
        enrollmentType,
        testName,
        testIsEnabled: true,
        isCurrentlyEnrolled: isEnrolled,
      };
    }

    return {
      enrollmentType,
      testName,
      testIsEnabled: false,
      isCurrentlyEnrolled: false,
    };
  } catch (err) {
    return {
      enrollmentType,
      testName,
      checkFailed: true,
    };
  }
}

async function checkIncentivesOneAppointmentTest(
  testName: string,
  rootState,
): Promise<IncentivesCheck> {
  const enrollmentType = 'singleAppointment';
  try {
    const enrollmentKey = (
      testName === CLIENT_INCENTIVES_IDLE_TOASTER_FEATURE_FLAG_NAME
    ) ? LOCAL_STORAGE_ONE_APPOINTMENT_KEY : `incentives_onetime_${testName}`;
    const disenrollmentKey = (
      testName === CLIENT_INCENTIVES_IDLE_TOASTER_FEATURE_FLAG_NAME
    ) ? LOCAL_STORAGE_ONE_APPOINTMENT_DISENROLLEMENT_KEY : `incentives_onetime_${testName}_exit`;

    const { assignments } = rootState.abTest;
    if (
      assignments[testName]?.isEnabled
      || (
        testName === REFERRAL_SHARE_KEY
        && assignments[CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME]?.isEnabled
      )
    ) {
      let isEnrolled = await stateManagerSrv.getActionValue(enrollmentKey);
      let isDisenrolled = await stateManagerSrv.getActionValue(disenrollmentKey);

      if (!isDisenrolled) {
        isDisenrolled = await kvGetItem(disenrollmentKey);

        if (isDisenrolled) {
          await stateManagerSrv.addAction(disenrollmentKey, true);
        }
      }

      if (!isEnrolled) {
        isEnrolled = isEnrolled || await kvGetItem(enrollmentKey);

        if (isEnrolled) {
          await stateManagerSrv.addAction(enrollmentKey, true);
        }
      }

      return {
        enrollmentType,
        testName,
        testIsEnabled: true,
        isCurrentlyEnrolled: !!isEnrolled && !isDisenrolled,
        wasPreviouslyEnrolled: !!isDisenrolled,
      };
    }

    return {
      enrollmentType,
      testName,
      testIsEnabled: false,
      isCurrentlyEnrolled: false,
    };
  } catch (err) {
    return {
      enrollmentType,
      testName,
      checkFailed: true,
    };
  }
}

/**
 * I need to share this state across a few hooks.
 */
const model = createModel<RootModel>()({
  name: 'showIncentives',

  state: {
    showIncentives: false,
    checkInProgress: false,
    firstCheckCompleted: false,
    incentivesChecks: {},
  } as State,

  reducers: {
    onCheckStarted(state: State) {
      return {
        ...state,
        checkInProgress: true,
      };
    },
    onCheckCompleted(state: State, payload: IncentivesChecks) {
      const allIncentivesChecks = {
        ...state.incentivesChecks,
        ...payload,
      };
      hasLoadedDeferred.resolve({
        ...state,
        checkInProgress: false,
        firstCheckCompleted: true,
        incentivesChecks: allIncentivesChecks,
      });
      return {
        ...state,
        checkInProgress: false,
        firstCheckCompleted: true,
        incentivesChecks: allIncentivesChecks,
      };
    },
    onSingleCheckCompleted(state: State, payload: IncentivesCheck): State {
      return {
        ...state,
        incentivesChecks: {
          ...state.incentivesChecks,
          [payload.testName]: payload,
        },
        showIncentives: state.showIncentives || payload.isCurrentlyEnrolled,
      };
    },
    onShowIncentives(state: State, {
      showIncentives,
      incentivesChecks,
    }: {
      showIncentives: boolean;
      incentivesChecks?: IncentivesChecks;
    }) {
      return {
        ...state,
        showIncentives,
        checkInProgress: false,
        incentivesChecks,
      };
    },
  },

  effects: dispatch => ({
    async checkIncentivesStatus(_?: undefined, rootState?): Promise<IncentivesChecks> {
      if (!rootState.abTest.isLoaded) {
        await hasABTestLoaded.promise;
      }

      return dispatch.showIncentives.doCheckIncentivesStatus();
    },

    async doCheckIncentivesStatus(_?: undefined, rootState?): Promise<IncentivesChecks> {
      if (rootState.showIncentives.checkInProgress) {
        const state: State = await hasLoadedDeferred.promise;
        return state.incentivesChecks;
      }

      dispatch.showIncentives.onCheckStarted();

      let preselectedIncentives = false;
      // Run the sync check first
      if (
        rootState.abTest.assignments[
          CLIENT_INCENTIVES_PRESELECTED_USER_ACCOUNT_FLAG_NAME
        ]?.isEnabled
      ) {
        preselectedIncentives = true;
      }

      // Batch the additional async checks
      const asyncIncentivesChecks = [
        await checkIncentivesPermanentEnrollment(
          CLIENT_INCENTIVES_PRESELECTED_URL_FLAG_NAME,
          rootState,
        ),
        await checkIncentivesPermanentEnrollment(
          CLIENT_INCENTIVES_SOCIAL_PROMO_FLAG_NAME,
          rootState,
        ),
        ...ONE_APPOINTMENT_TEST_NAMES.map(
          testName => checkIncentivesOneAppointmentTest(testName, rootState),
        ),
      ];

      const checkResults = await Promise.all(asyncIncentivesChecks);

      // Uncomment the line below to debug current enrollment status
      // console.log('INCENTIVES ENROLLMENT:', checkResults);

      const checkResultMap = indexBy(checkResults, 'testName');

      await dispatch.showIncentives.onShowIncentives({
        showIncentives: (
          checkResults.some(result => result?.isCurrentlyEnrolled)
          || preselectedIncentives
        ),
        incentivesChecks: checkResultMap,
      });

      await dispatch.showIncentives.onCheckCompleted(checkResultMap);

      return checkResultMap;
    },

    async checkSingleIncentiveStatus({
      programName,
      enrollmentType,
    }: {
      programName: string;
      enrollmentType: EnrollmentType;
    }, rootState): Promise<IncentivesCheck> {
      if (!rootState.abTest.isLoaded) {
        await hasABTestLoaded.promise;
      }

      return dispatch.showIncentives.doCheckSingleIncentiveStatus({ programName, enrollmentType });
    },

    async doCheckSingleIncentiveStatus({
      programName,
      enrollmentType,
    }: {
      programName: string;
      enrollmentType: EnrollmentType;
    }, rootState): Promise<IncentivesCheck> {
      let check: IncentivesCheck;

      if (rootState.showIncentives.incentivesChecks[programName]) {
        return rootState.showIncentives.incentivesChecks[programName];
      }

      const { checkInProgress } = rootState.showIncentives;
      if (checkInProgress) {
        const state: State = await hasLoadedDeferred.promise;
        return state.incentivesChecks[programName];
      }

      if (enrollmentType === 'permanent') {
        check = await checkIncentivesPermanentEnrollment(programName, rootState);
      } else {
        check = await checkIncentivesOneAppointmentTest(programName, rootState);
      }

      await dispatch.showIncentives.onSingleCheckCompleted(check);

      return check;
    },

    enterOneAppointmentEnrollment: async (
      testName: string,
    ): Promise<void> => {
      /* (ATL-4104) New referrals are being taken care of by backend/state manager
      so we don't want to create any more referral-related incentives
      locally */
      if (
        testName === REFERRAL_SHARE_KEY
        || testName === CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME
      ) {
        return;
      }

      const localStorageKey = (
        testName === CLIENT_INCENTIVES_IDLE_TOASTER_FEATURE_FLAG_NAME
      ) ? LOCAL_STORAGE_ONE_APPOINTMENT_KEY : `incentives_onetime_${testName}`;

      await kvSetItem(localStorageKey, true);
      await stateManagerSrv.addAction(localStorageKey, true);

      dispatch.showIncentives.onShowIncentives({ showIncentives: true });
    },

    // returns true to continue displaying incentives, otherwise false
    async leaveOneAppointmentEnrollment(_?: undefined, rootState?): Promise<boolean> {
      dispatch.showIncentives.onCheckStarted();

      let displayIncentives = rootState.showIncentives.showIncentives;
      const appliedPromotion = rootState.clientPromotions.promotions.find(
        promo => promo.status === DiscountStatus.Applied,
      );

      if (appliedPromotion?.discountCode === INCENTIVES_DISCOUNT_CODE) {
        const testName = appliedPromotion.id;
        const { isCurrentlyEnrolled } = await checkIncentivesOneAppointmentTest(
          testName,
          rootState,
        );

        if (isCurrentlyEnrolled) {
          if (testName === CLIENT_INCENTIVES_IDLE_TOASTER_FEATURE_FLAG_NAME) {
            await kvSetItem(LOCAL_STORAGE_ONE_APPOINTMENT_DISENROLLEMENT_KEY, true);
            await stateManagerSrv.addAction(LOCAL_STORAGE_ONE_APPOINTMENT_DISENROLLEMENT_KEY, true);
          } else {
            await kvSetItem(`incentives_onetime_${testName}_exit`, true);
            await stateManagerSrv.addAction(`incentives_onetime_${testName}_exit`, true);
          }

          await dispatch.clientPromotions.updateDiscountStatus({
            id: appliedPromotion.id,
            status: DiscountStatus.Redeemed,
          });

          displayIncentives = rootState.clientPromotions.promotions.some(promo => (
            promo.id !== appliedPromotion.id
            && promo.discountCode === INCENTIVES_DISCOUNT_CODE
            && promo.status === DiscountStatus.Available
          ));
        }
      }

      await dispatch.showIncentives.onShowIncentives({
        showIncentives: displayIncentives,
      });

      return displayIncentives;
    },
  }),
});

export default model;
