export function isNonStandardError(status: number): boolean {
  return status === 0 || status >= 401;
}

export function isForbidden(status: number): boolean {
  return status === 403;
}

export function getStatusError(status: number): string {
  if (status === 0) {
    return 'Please check your Internet connection';
  }
  if (status >= 500) {
    return 'Something went wrong';
  }
  return 'An unknown error occurred';
}

/**
 * Error class for throwing errors associated with non-OK HTTP errors
 */
export class StatusCodeError extends Error {
  code: number;

  constructor(status: number) {
    super(getStatusError(status));
    this.code = status;
  }
}
