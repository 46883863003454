// @ts-strict-ignore
import localforage from 'localforage';

export function setItem<T = any>(key: string, value: T): Promise<T> {
  let result;

  if (typeof value === 'object') {
    result = localforage.setItem(key, JSON.stringify(value));
  } else {
    result = localforage.setItem(key, value);
  }

  return result;
}

export function getItem<T = any>(key: string): Promise<T> {
  let parsed;

  return localforage.getItem<string>(key).then(value => {
    try {
      parsed = JSON.parse(value);
    } catch (e) {
      parsed = value;
    }

    return parsed;
  });
}

export function removeItem(key: string): Promise<void> {
  return localforage.removeItem(key);
}

export function clear() {
  return localforage.clear();
}
