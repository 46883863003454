// @ts-strict-ignore
import URI from 'urijs';
import { APP } from '../config';
import universalLinks from './universalLinks';

export function filterParamsByPattern(params: Record<string, any>, patternString: string = '^utm_'): Record<string, any> {
  const paramsCopy = { ...params };
  const pattern = new RegExp(patternString);
  Object.keys(paramsCopy).forEach(paramName => {
    if (!pattern.test(paramName)) {
      delete paramsCopy[paramName];
    }
  });
  return paramsCopy;
}

/**
 * Get url query string key value pairs (this includes utm params)
 * @param {Boolean} [isApp] Truthy to defer to the universalLinks service for
 * obtaining UTM params. Otherwise use location.href.
 * @param {String} [href=window.location.href] The current URL.
 * @returns {Object} Query string key value pairs
 */
export function getParamsByPattern(
  isApp: boolean,
  href: string,
  patternString: string = '^utm_',
): Record<string, any> {
  let params: Record<string, any>;

  if (isApp) {
    params = universalLinks.getParams();
  } else {
    const url = href || window.location.href;
    params = url ? URI(url).query(true) : {};
  }

  return filterParamsByPattern(params, patternString);
}

/**
 * Convenience method for keying UTM params as last touch params
 * for mixpanel tracking. UTM params are pulled from the URL for web
 * and from the `universalLinks` service for mobile.
 *
 * @name getLastTouchUtms
 * @param {String} [href=window.location.href] The current URL.
 * @returns {Object} Utm params keyed as "last touch" params
 */
export function getLastTouchUtms(href: string = window.location.href): Record<string, any> {
  const utmParams = getParamsByPattern(APP, href) || {};
  const lastTouchParams = {};
  let utmParam;
  Object.keys(utmParams).forEach(param => {
    utmParam = param.split('_')[1];
    utmParam = utmParam.charAt(0).toUpperCase() + utmParam.slice(1);
    lastTouchParams[`UTM ${utmParam} [last touch]`] = utmParams[param];
  });

  return lastTouchParams;
}
