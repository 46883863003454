// @ts-strict-ignore
import React from 'react';
import {
  Box,
  IBoxProps,
  PaddingSize,
  flattenChildren,
} from '@styleseat/ui';

import type { View } from 'react-native';

export interface StackProps extends Omit<IBoxProps, 'flexDirection'> {
  space?: PaddingSize;
  key?: string | number;
}

/*
 * Renders components in "Stack" layout.
 *
 * @param props.space - How much px spacing between children components.
 */
const Stack: React.FunctionComponent<StackProps> = React.forwardRef<
View,
React.PropsWithChildren<StackProps>
>(
  (
    {
      children,
      key = '',
      space = 0,
      className = '',
      ...rest
    },
    ref,
  ) => {
    const childrenFlattened = flattenChildren(children)
      .filter(child => React.isValidElement(child));

    return (
      <Box
        {...rest}
        flexDirection="column"
        className={className}
        ref={ref}
      >
        {React.Children.map(childrenFlattened, (child, index) => (
          <React.Fragment key={key}>
            {child}
            {index !== childrenFlattened.length - 1 && !!space && (
              <Box paddingBottom={space} />
            )}
          </React.Fragment>
        ))}
      </Box>
    );
  },
);

export default Stack;
