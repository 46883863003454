/**
 * Formats a percentage as a string followed by the `%` symbol. Non-parseable strings are
 * interpreted as `0` for formatting purposes.
 * @param value The value to format
 * @param precision The number of digits after the decimal. E.g. `2` for `'5.25%'`.
 * @returns The formatted value
 */
export function formatPercent(value: number | string, precision: number = 0): string {
  let valueToFormat: number;

  if (typeof value === 'string') {
    valueToFormat = Number(value);

    if (Number.isNaN(valueToFormat)) {
      valueToFormat = 0;
    }
  } else {
    valueToFormat = value;
  }

  return `${valueToFormat.toFixed(precision)}%`;
}
