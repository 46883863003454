/**
 * Determines if two strings are equal, ignoring case and extra surrounding whitespace
 * @param {String} str1 The first string to compare
 * @param {String} str2 The second string to compare
 */
export function matchIgnoringCase(str1, str2) {
  if (typeof str1 === 'string' && typeof str2 === 'string') {
    return str1.trim().toLowerCase() === str2.trim().toLowerCase();
  }

  return !str1 === !str2;
}

/**
 * Returns a copy of the given string in slug case.
 *
 * @name toSlugCase
 * @param {String} str - The string to slug case.
 * @returns {String} - The slug-cased copy of the given string.
 */
export function toSlugCase(str) {
  if (str) {
    return str.toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-+]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  return str;
}

/**
 * Determines if two strings are equal, ignoring case and extra surrounding whitespace
 * @param {String} str1 The first string to compare
 * @param {String} str2 The second string to compare
 */
export function matchSlugCase(str1, str2) {
  if (typeof str1 === 'string' && typeof str2 === 'string') {
    return toSlugCase(str1.trim()) === toSlugCase(str2.trim());
  }

  return !str1 === !str2;
}

/**
 * Returns a copy of the given string in title case.
 *
 * @name toTitleCase
 * @param {String} str - The string to title case.
 * @returns {String} - The title-cased copy of the given string.
 */
export function toTitleCase(str) {
  if (!str) {
    return str;
  }

  const parts = str.toLowerCase()
    .split(' ');

  return parts.map(part => {
    if (!part?.length) {
      return part;
    }
    const firstLetter = part[0].toUpperCase();
    const remainingLetters = part.substr(1, part.length);

    return firstLetter + remainingLetters;
  })
    .join(' ');
}

/**
 * Returns a copy of the given slug as a title-cased string without dashes and with possessives
 * replaced with the correct apostrophes.
 *
 * @name toSlugCase
 * @param {String} str - The string to unslugify.
 * @returns {String} - The slug-cased copy of the given string.
 */
export function unSlugify(slug) {
  if (slug) {
    // Remove slug characters ("-" to " ")
    let result = slug.toString()
      .toLowerCase()
      .replace(/-+/g, ' ');

    // Replace possessives that we are certain mean what they mean ("mens" is certainly "men's").
    const possessives = {
      mens: 'men\'s',
      womens: 'women\'s',
      childrens: 'children\'s',
      childs: 'child\'s',
      kids: 'kid\'s',
    };
    const re = new RegExp(Object.keys(possessives)
      .join('|'), 'gi');
    result = result.replace(re, matched => possessives[matched]);

    // special case for "Kids" when used as a category all on it's own, shouldn't be converted to
    // possessive.
    if (result === 'kid\'s') {
      result = 'kids';
    }

    return toTitleCase(result);
  }

  return slug;
}

export const US_STATE_ABBREVIATIONS = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const US_STATE_NAMES = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Federated States Of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const isSlug = slug => !!slug && slug.indexOf('-') > -1 && slug.indexOf(' ') < 0;

/**
 * Returns a copy of the given slug as a title-cased string with a capitalized state after a comma
 * if the state abreviation is found in the states abreviations list (currently only US states), or
 * with the title cased state name after the comma.
 *
 * This will only operate on strings we have determined to be slugified
 * (contains a hyphen and no spaces)
 *
 * @name unSlugifyAddress
 * @param {String} slug - The address to unsluggify.
 * @returns {String} - The unsluggified copy of the given address slug.
 */
export function unSlugifyAddress(slug) {
  if (isSlug(slug)) {
    const unslugified = unSlugify(slug);
    let result = unslugified;
    const addressTokens = unslugified.trim().split(' ');
    const potentialStateOrAbreviation = String(addressTokens[addressTokens.length - 1]);
    const addressWithoutState = addressTokens.slice(0, addressTokens.length - 1).join(' ');

    const isStateAbbreviation = US_STATE_ABBREVIATIONS
      .includes(potentialStateOrAbreviation.toUpperCase());
    if (isStateAbbreviation
      || US_STATE_NAMES.includes(potentialStateOrAbreviation)) {
      const state = isStateAbbreviation
        ? potentialStateOrAbreviation.toUpperCase() : potentialStateOrAbreviation;
      result = `${addressWithoutState.replace(/,+$/g, '')}, ${state}`;
    }
    return result;
  }

  return slug;
}

/**
 * Converts a string with or without spaces to camelCase
 *
 * @name camelize
 * @param {String} str - string to convert
 * @returns {String} - camelCased version of str
 */
export const camelize = str => str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
  if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
  return index === 0 ? match.toLowerCase() : match.toUpperCase();
});
