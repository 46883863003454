import type { PaymentIntent } from '../store/StripeTerminal';
import type {
  SCPReaderDisplayMessage,
  TapToPayConnectionStatus,
  TapToPayGeolocationAuthorizationStatus,
  TapToPayPluginEvent,
} from './TapToPay';

// Maps to values on https://github.com/chemerisuk/cordova-plugin-idfa#tracking-permission-values
export enum IDFATrackingPermission {
  NotDetermined = 0,
  Restricted = 1,
  Denied = 2,
  Authorized = 3,
}

// From https://github.com/chemerisuk/cordova-plugin-idfa/blob/master/types/index.d.ts
export type IdfaData = {
  trackingLimited: boolean;
  idfa?: string;
  trackingPermission?: IDFATrackingPermission;
  aaid?: string;
};

export type IdfaPlugin = {
  readonly TRACKING_PERMISSION_NOT_DETERMINED: IDFATrackingPermission.NotDetermined;
  readonly TRACKING_PERMISSION_RESTRICTED: IDFATrackingPermission.Restricted;
  readonly TRACKING_PERMISSION_DENIED: IDFATrackingPermission.Denied;
  readonly TRACKING_PERMISSION_AUTHORIZED: IDFATrackingPermission.Authorized;
  getInfo(): Promise<IdfaData>;
  requestPermission(): Promise<IDFATrackingPermission | null>;
};

export type DiagnosticPlugin = {
  // copied from https://github.com/dpa99c/cordova-diagnostic-plugin/blob/master/cordova.plugins.diagnostic.d.ts
  /**
     * Returns the location authorization status for the application.
     * @param successCallback
     * @param errorCallback
     */
  getLocationAuthorizationStatus?: (
    successCallback: (status: string) => void,
    errorCallback: (error: string) => void
  ) => void;

  /**
     * Constants for requesting and reporting the various permission states.
     * @type {Object}
     */
  permissionStatus: {
    // Android only
    'DENIED_ONCE': 'DENIED_ONCE';

    // iOS only
    'RESTRICTED': 'restricted';
    'EPHEMERAL': 'ephemeral';
    'PROVISIONAL': 'provisional';

    // Both iOS and Android
    'GRANTED': 'authorized' | 'GRANTED';
    'GRANTED_WHEN_IN_USE': 'authorized_when_in_use';
    'NOT_REQUESTED': 'not_determined' | 'NOT_REQUESTED';
    'DENIED_ALWAYS': 'denied_always' | 'DENIED_ALWAYS';
  };
};

type AppsFlyerPluginOptions = {
  devKey: string;
  appId: string;
  isDebug?: boolean;
  useUninstallSandbox?: boolean;
  collectIMEI?: boolean;
  collectAndroidID?: false;
  onInstallConversionDataListener?: boolean;
  waitForATTUserAuthorization?: number;
  onDeepLinkListener?: boolean;
};

type AppsFlyerPlugin = {
  initSdk(
    options: AppsFlyerPluginOptions,
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ): void;
  registerOnAppOpenAttribution(
    onSuccess: (message: string) => void,
    onError: (message: string) => void,
  ): void;
  logEvent(
    eventName: string,
    eventValues: object,
    onSuccess: null,
    onError: null,
  ): void;
  registerDeepLink(
    onSuccess: (message: string) => void
  ): void;
  setResolveDeepLinkURLs(
    urls: string[],
  ): void;
  // More as needed
};

// Appboy === Braze
export type BrazePlugin = {
  changeUser(userId: string): void;
  logCustomEvent(eventName: string, eventProperties?: object): boolean;
  getUser: () => object;
  getDeviceId: (callback: (deviceId: string) => void) => void;
  isPushBlocked: () => boolean;
  isPushSupported: () => boolean;
  isPushPermissionGranted: () => boolean;
  requestPushPermission: () => boolean;
};

export type CordovaTapToPayInitSuccessPayload = string | {
  event: TapToPayPluginEvent.DidReportUnexpectedReaderDisconnect
  | TapToPayPluginEvent.DidChangeLocationAuthorization;
};

type DetectDeviceSuccessNoPayloadEvent = TapToPayPluginEvent.DidStartInstallingUpdate
| TapToPayPluginEvent.DidFinishInstallingUpdate
| TapToPayPluginEvent.DidCancelDiscoverReaders
| TapToPayPluginEvent.DidConnectReader
| TapToPayPluginEvent.DidRequestReaderInput;

export type CordovaTapToPayDetectDeviceSuccessPayload = { event: DetectDeviceSuccessNoPayloadEvent }
| {
  event: TapToPayPluginEvent.DidFinishDiscoverReaders;
  payload: { connectionStatus: TapToPayConnectionStatus };
}
| { event: TapToPayPluginEvent.DidReportReaderSoftwareUpdateProgress; payload: number }
| { event: TapToPayPluginEvent.DidRequestReaderDisplayMessage; payload: SCPReaderDisplayMessage };

export type CordovaTapToPayConnectionStatusPayload = {
  event: TapToPayPluginEvent.DidRequestConnectionStatus;
  payload: TapToPayConnectionStatus;
};

type CollectPaymentSuccessEvent = TapToPayPluginEvent.DidCollectPaymentMethod
| TapToPayPluginEvent.DidProcessPaymentIntent;

export type CordovaTapToPayCollectPaymentSuccessPayload = string |
{ event: CollectPaymentSuccessEvent; payload: PaymentIntent['id'] };

export type CordovaTapToPayErrorPayload = { name: string; description: string; code?: number };

export type DeviceOwnerAuthenticationSuccessPayload = {
  passcodeEnabled: boolean;
};
export type DeviceOwnerAuthenticationErrorPayload = {
  passcodeEnabled: boolean;
  error: CordovaTapToPayErrorPayload;
};

// CordovaTapToPay
export interface CordovaTapToPay {
  isTapToPayPluginLoaded: boolean;
  init(
    onSuccess: (payload: CordovaTapToPayInitSuccessPayload) => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  registerConnectionTokenFetchHandler(
    onSuccess: () => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  completeConnectionTokenFetchSuccess(
    connectionToken: string,
    onSuccess: () => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  completeConnectionTokenFetchError(
    errorMessage: string,
    onSuccess: () => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  detectDevices(
    locationId: string,
    merchantName: string,
    onBehalfOfId: string,
    onSuccess: (payload: CordovaTapToPayDetectDeviceSuccessPayload) => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  disconnectReader(
    force: boolean,
    onSuccess: () => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  collectPayment(
    paymentIntentId: string,
    onSuccess: (payload: CordovaTapToPayCollectPaymentSuccessPayload) => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  locationAuthorizationStatus(
    onSuccess: (payload: TapToPayGeolocationAuthorizationStatus) => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  openSettingsApp(
    onSuccess: () => void,
    onError: (payload?: CordovaTapToPayErrorPayload) => void,
  ): void;
  connectionStatus(
    onSuccess: (payload: CordovaTapToPayConnectionStatusPayload) => void,
  ): void;
  deviceOwnerAuthenticationConfigured(
    onSuccess: (payload: DeviceOwnerAuthenticationSuccessPayload) => void,
    onError: (payload: DeviceOwnerAuthenticationErrorPayload) => void,
  ): void;
}

interface GoogleLoginPayload {
  scopes: string;
  webClientId: string;
  offline: boolean;
}

interface GoogleLoginResult {
  email: string;
  idToken: string;
  serverAuthCode: string;
  accessToken: string;
  refreshToken: string;
  userId: string;
  displayName: string;
  givenName: string;
  familyName: string;
  imageUrl: string;
}

interface GooglePlus {
  /** Get the version of Cordova running on the device. */
  login(option: GoogleLoginPayload, onSuccess: (dict: GoogleLoginResult) => void,
    onError: (message: string) => void): void;
  trySilentLogin(array: [string], onSuccess: (dict: GoogleLoginResult) => void,
    onError: (message: string) => void): void;
  logout(onSuccess: (dict: GoogleLoginResult) => void,
    onError: (message: string) => void): void;
  /** Indicates that Cordova initialize successfully. */
  isAvailable(onSuccess: (dict: GoogleLoginResult) => void): void;
  disconnect(onSuccess: (dict: GoogleLoginResult) => void,
    onError: (message: string) => void): void;
  getSigningCertificateFingerprint(onSuccess: (dict: GoogleLoginResult) => void,
    onError: (message: string) => void): void;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    plugins?: {
      appsFlyer?: AppsFlyerPlugin;
      socialsharing?: unknown;
      calendar?: {
        createEventInteractively: (
          title: string,
          eventLocation: string,
          notes: string,
          startDate: Date,
          endDate: Date,
          success: () => void,
          error: () => void,
        ) => void;
      };
      googleplus?: GooglePlus;
    };
    cordova?: {
      plugins?: {
        idfa?: IdfaPlugin;
        firebase?: unknown;
        diagnostic?: DiagnosticPlugin;
      };
      file?: {
        sharedDirectory: string;
        externalDataDirectory: string;
      };
      InAppBrowser?: {
        open: (url: string, target?: string, options?: string) => ({
          executeScript(
            script: { code: string } | { file: string },
            callback: (result: any) => void): void;
          close: () => void;
        });
      };
    };
    device?: {
      model: unknown;
    };
    // Braze SDK, mobile build only
    BrazePlugin?: BrazePlugin;
    // Cordova Tap to Pay, mobile build only
    cordovaTapToPay?: CordovaTapToPay;
    // AppsFlyer SDK
    AF?: any;
    reduxStore: any;
    sms?: {
      send: (
        phoneNumber: string,
        message: string,
        options: { android?: { intent?: 'INTENT' | '' } },
        success: () => void,
        failure: () => void,
      ) => void;
    };
  }
}
