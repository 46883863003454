import ssFetch, { ssFetchJSON } from '../ssFetch';

/**
 * Gets a list of products for the given provider
 * @param providerId
 * @returns {Promise}
 */
export function getProviderProducts(providerId) {
  return ssFetchJSON(`/api/v1/providers/${providerId}/products`);
}

export function deleteProviderProduct(providerId, productId) {
  return ssFetch(`/api/v1/providers/${providerId}/products/${productId}`, { method: 'delete' });
}

export function createProviderProduct(providerId, product) {
  return ssFetchJSON(`/api/v1/providers/${providerId}/products/`, {
    body: JSON.stringify({
      ...product,
      provider: providerId,
    }),
    method: 'post',
  });
}

export function updateProviderProduct(providerId, product) {
  return ssFetchJSON(`/api/v1/providers/${providerId}/products/${product.id}`, {
    body: JSON.stringify({
      ...product,
      provider: providerId,
    }),
    method: 'put',
  });
}
