// @ts-strict-ignore
import DefaultServices from './DefaultServicesMap.json';

export enum BlurbPreference {
  NoPreference = 0,
  EmptyBlurb = 1,
  DefaultBlurb = 2,
  CustomBlurb = 3,
}

export const normalizeServiceName = (name: string | undefined): string => {
  if (!name) return '';
  let withoutSpecials = name.toLowerCase()
    .replace(/’/g, '')
    .replace(/'/g, '')
    .replace(/"/g, '')
    .replace(/\//g, ' ');

  while (withoutSpecials.indexOf('  ') > -1) {
    withoutSpecials = withoutSpecials.replace('  ', ' ');
  }

  return withoutSpecials;
};

export const getBlurb = (blurb: string, defaultValue: string): [string, BlurbPreference] => {
  if (blurb === defaultValue) {
    return [
      '',
      BlurbPreference.DefaultBlurb,
    ];
  }
  if (blurb === '') {
    return [
      '',
      BlurbPreference.EmptyBlurb,
    ];
  }
  return [
    blurb,
    BlurbPreference.CustomBlurb,
  ];
};

const interpretBlurb = (blurb: string, defaultValue: string, blurbPreference: number): string => {
  if (blurbPreference === BlurbPreference.CustomBlurb) return blurb;
  if (blurbPreference === BlurbPreference.EmptyBlurb) return blurb;
  if (blurbPreference === BlurbPreference.DefaultBlurb) return defaultValue;

  // NO_PREFERENCE
  if (blurb === '') return defaultValue;
  return blurb;
};

export const computeBlurb = (name: string | undefined, blurb: string, blurbPreference: number) => {
  const defaultedBlurb = DefaultServices[normalizeServiceName(name)] || '';
  return [
    defaultedBlurb,
    interpretBlurb(blurb, defaultedBlurb, blurbPreference),
  ];
};

export const getDescriptionByServiceName = (
  unNormalizedServiceName: string,
) => (
  DefaultServices[normalizeServiceName(unNormalizedServiceName)] || ''
);
