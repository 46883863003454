import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const DiscountCodeApplied: SegmentEventData = {
  eventName: AnalyticEvent.DiscountCodeApplied,
  eventNameLegacy: 'discount_code_applied',
  eventProperties: [
    'email',
    'first_name',
    'last_name',
    'discount_code',
    'source',
  ],
};

export default DiscountCodeApplied;
