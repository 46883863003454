import { useEffect, useState } from 'react';
import type { MutableRefObject } from 'react';
import { getTargetElement } from './domTarget';
import createIntersectionObserver from '../useIntersectionTrigger/createIntersectionObserver';

type TargetValue<T> = T | undefined | null;

type TargetType = HTMLElement | Element | Window | Document;

export type BasicTarget<T extends TargetType = Element> =
  | (() => TargetValue<T>)
  | TargetValue<T>
  | MutableRefObject<TargetValue<T>>;

type CallbackType = (entry: IntersectionObserverEntry) => void;

export interface Options {
  rootMargin?: string;
  threshold?: number | number[];
  root?: BasicTarget<Element>;
  callback?: CallbackType;
}

/**
 * A React hook that detects when an element enters the viewport.
 * @param target The target element to detect when it enters the viewport.
 * @param options The options for the IntersectionObserver.
 */
export function useElementEnteredViewport(target: BasicTarget, options?: Options) {
  const { callback, ...option } = options || {};

  const [callbackFired, setCallbackFired] = useState<boolean>(false);

  useEffect(
    () => {
      const elem = getTargetElement(target);

      if (!elem) {
        return () => {};
      }

      const observer = createIntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting && callback && !callbackFired) {
              callback(entry);
              setCallbackFired(true);
            }
          });
        },
        {
          ...option,
          root: getTargetElement(options?.root),
        },
      );

      observer.observe(elem);

      return () => {
        observer.disconnect();
      };
    },
    [
      options?.rootMargin,
      options?.threshold,
      callback,
      options?.root,
      target,
      option,
      callbackFired,
    ],
  );
}
