import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';

/*
 * Renders markdown input using `ReactMarkdown`.
 */
export const Markdown: FunctionComponent<any> = ({
  children,
  ...props
}) => (
  <ReactMarkdown {...props}>
    {children}
  </ReactMarkdown>
);
