// @ts-strict-ignore
import { createModel } from '@rematch/core';
import {
  invert,
  isEmpty,
  memoize,
} from 'underscore';
import type { RootModel } from './models';
import { stateManager } from '../modules/user/stateManager';
import {
  calculatePercentComplete,
  fetchProviderOnboardingPromptStatus,
  fetchProviderOnboardingStatus,
  OverallStatus,
  ProviderOnboardingPromptStatus,
  ProviderOnboardingStatus,
  ProviderOnboardingSteps,
  ProviderOnboardingUpdateRequest,
  Step,
  StepStatus,
  updateProviderOnboardingStatus,
} from '../modules/api/providers';
import {
  ERROR_ROUTE,
  HOME_ROUTE,
  PRO_TODAY_TAB_HOME,
  PROFILE_EDIT_ROUTE_ONBOARDING,
  PROVIDER_ONBOARDING_ADDRESS,
  PROVIDER_ONBOARDING_AVAILABILITY,
  PROVIDER_ONBOARDING_FREE_TRIAL_EXPLANATION,
  PROVIDER_ONBOARDING_GALLERY,
  PROVIDER_ONBOARDING_GOAL_SELECT,
  PROVIDER_ONBOARDING_PAYMENTS,
  PROVIDER_ONBOARDING_PLAN_SELECT,
  PROVIDER_ONBOARDING_PROFILE,
  PROVIDER_ONBOARDING_SERVICES,
  PROVIDER_ONBOARDING_SETUP_SUBSCRIPTION,
  PROVIDER_ONBOARDING_SIGNUP,
} from '../route-names';
import nonCriticalException from '../modules/exceptionLogger';
import { Responsive } from '../modules/Responsive';
import { calculateCanShowSubscriptionFeatures } from '../hooks/useShowSubscriptionFeatures';
import { PROVIDER_ONBOARDING_EXPERIENCE } from '../components/provider/Onboarding/Tips/constants';
import { ProviderPlan } from '../types/provider';

export enum StepFormAction {
  Invalid,
  Valid,
  CanSubmit,
  Submitting,
  Submitted,
  Loading,
}

export type ProviderOnboardingState = {
  providerId: number;
  isFetching: boolean;
  status?: ProviderOnboardingPromptStatus;
  inOnboardingFlow: boolean;
  currentStep: Step | null;
  currentStepStatus: StepFormAction;
  overallStatus: OverallStatus;
  stepOrder: Step[];
  steps: ProviderOnboardingSteps | {};
  isAllowed: boolean;
  isInitialized: boolean;
  /**
   * This is used to store the back and next actions for the current step in the onboarding flow
   * if they are provided. This is used to control the back and next buttons in the onboarding flow.
   * also the returned value is used to override or not the back default action
   * */
  backAction?: () => Promise<boolean>;
  /**
   * This is used to store the back and next actions for the current step in the onboarding flow
   * if they are provided. This is used to control the back and next buttons in the onboarding flow.
   * also the returned value is used to propagate or not the next default button
   */
  nextAction?: () => Promise<boolean>;
  nextActionDisabled?: boolean;
  actionIsLoading?: boolean;
};

export type StepsStats = {
  stepsTotalCount: number;
  stepsCompletedCount: number;
  stepsRemainingCount: number;
  stepsCompletedPercent: number;
};

type StatusSelectorProps = {
  providerId: number;
  excludeSubscribeStep: boolean;
};

export const stepsToPaths: { [key in Step]?: string } = {
  [Step.Signup]: PROVIDER_ONBOARDING_SIGNUP,
  [Step.Address]: PROVIDER_ONBOARDING_ADDRESS,
  [Step.Availability]: PROVIDER_ONBOARDING_AVAILABILITY,
  [Step.GalleryImage]: PROVIDER_ONBOARDING_GALLERY,
  [Step.PaymentsOnboarding]: PROVIDER_ONBOARDING_PAYMENTS,
  [Step.ProfilePhoto]: PROVIDER_ONBOARDING_PROFILE,
  [Step.Services]: PROVIDER_ONBOARDING_SERVICES,
  [Step.SetupSubscription]: PROVIDER_ONBOARDING_SETUP_SUBSCRIPTION,
  [Step.PlanSelect]: PROVIDER_ONBOARDING_PLAN_SELECT,
  [Step.GoalSelect]: PROVIDER_ONBOARDING_GOAL_SELECT,
  [Step.FreeTrialExplanation]: PROVIDER_ONBOARDING_FREE_TRIAL_EXPLANATION,
};

export const pathsToSteps: { string: Step } = invert(stepsToPaths);

export const defaultStepsOrder = [
  Step.Signup,
  Step.Address,
  Step.Services,
  Step.Availability,
  Step.ProfilePhoto,
  Step.PlanSelect,
  Step.FreeTrialExplanation,
];

export const PAYMENT_STEPS = [
  Step.PaymentsOnboarding,
  Step.SetupSubscription,
];

export const DEFAULT_STATE: ProviderOnboardingState = {
  isFetching: false,
  currentStep: null,
  overallStatus: OverallStatus.NotStarted,
  stepOrder: defaultStepsOrder,
  steps: {},
  inOnboardingFlow: false,
  currentStepStatus: StepFormAction.Invalid,
  providerId: null,
  isAllowed: false,
  isInitialized: false,
};

const isStatusState = (state: any): boolean => typeof state === 'object' && state.providerId !== undefined;

const getSkipActionName = (step: string): string => {
  // We changed the step names, but want to retain skip state so have to do a little funky
  // string manipulation here:
  const part = step.replace(/^prompt_for_/, 'has_');
  return `proOnboardingStatusStream:skip:${part}`;
};

/**
 * Transform fetched data by checking for skipped or invalid steps
 * @param steps - fetched onboarding prompt status data
 * @returns {Promise<{}>}
 */
const transformPromptStatus = async (
  steps: ProviderOnboardingPromptStatus,
): Promise<ProviderOnboardingPromptStatus> => {
  const onboardingSteps: ProviderOnboardingPromptStatus = { ...steps };

  const stepsToCheck = [];
  Object.keys(steps).forEach(step => {
    stepsToCheck.push(getSkipActionName(step));
  });
  const stepsSkipped = await stateManager.getActionValues(...stepsToCheck);
  Object.keys(steps).forEach(step => {
    if (stepsSkipped[getSkipActionName(step)]) {
      onboardingSteps[step] = false;
    }
  });

  return onboardingSteps;
};

const calculateOnboardingStatus = memoize((
  state: ProviderOnboardingState,
  providerId: number,
  excludeSubscribeStep: boolean,
): ProviderOnboardingStatus | undefined => {
  if (!isStatusState(state) || state.providerId !== providerId) {
    return undefined;
  }

  const promptStatus = { ...state.status };
  if (excludeSubscribeStep) {
    delete promptStatus.prompt_for_subscribe;
  }

  return {
    promptStatus,
    percentComplete: calculatePercentComplete(promptStatus),
  };
}, (state, providerId, excludeSubscribeStep) => `${JSON.stringify(state)}.${providerId}.${excludeSubscribeStep}`);

const getStepsOrder = (steps: Step[]): Step[] => {
  if (!steps || steps?.length === 0) {
    return null;
  }

  return steps;
};

export const ProviderOnboarding = createModel<RootModel>()({
  name: 'providerOnboarding',

  state: DEFAULT_STATE,

  reducers: {
    onClear(): ProviderOnboardingState {
      return {} as ProviderOnboardingState;
    },

    onFetchStart(
      state: ProviderOnboardingState,
      { providerId, noLoading = false }: { providerId: number; noLoading: boolean },
    ): ProviderOnboardingState {
      return {
        isInitialized: state.isInitialized,
        overallStatus: state.overallStatus,
        ...(isStatusState(state) && state.providerId === providerId
          ? state
          : { providerId }),
        isFetching: !noLoading,
        currentStep: !noLoading ? null : state.currentStep,
        stepOrder: !noLoading ? defaultStepsOrder : state.stepOrder,
        steps: !noLoading ? {} : state.steps,
        inOnboardingFlow: !noLoading ? false : state.inOnboardingFlow,
        currentStepStatus: !noLoading ? StepFormAction.Invalid : state.currentStepStatus,
        isAllowed: false,
      };
    },

    onFetchSuccess(
      state: ProviderOnboardingState,
      onboardingStatus,
    ): ProviderOnboardingState {
      let data = {};
      if (isStatusState(state) && state.providerId === onboardingStatus.providerId) {
        data = {
          ...onboardingStatus,
          isFetching: false,
        };
      }

      return {
        ...state,
        ...data,
        isInitialized: true,
      };
    },

    onFetchFail(
      state: ProviderOnboardingState,
      { providerId }: Pick<ProviderOnboardingState, 'providerId'>,
    ): ProviderOnboardingState {
      return {
        ...state,
        ...(isStatusState(state) && state.providerId === providerId
          ? {
            isFetching: false,
          }
          : {}),
        isInitialized: true,
      };
    },

    setInOnboardingFlow: (state, payload: boolean) => ({
      ...state,
      inOnboardingFlow: payload,
    }),

    setStepStatus: (state, payload: StepFormAction) => ({
      ...state,
      currentStepStatus: payload,
    }),

    setCurrentStep: (state, payload: Step) => ({
      ...state,
      currentStep: payload,
    }),

    setIsFormValid: (state, payload: boolean) => ({
      ...state,
      currentStepStatus: payload
        ? StepFormAction.Valid : StepFormAction.Invalid,
    }),
    setOnboardingStatus: (
      state,
      payload: Partial<ProviderOnboardingState>,
    ) => ({ ...state, ...payload }),
    setNextBackActions(
      state,
      payload: {
        backAction: () => Promise<boolean>;
        nextAction: () => Promise<boolean>;
        nextActionDisabled?: boolean;
        actionIsLoading?: boolean;
      },
    ) {
      return {
        ...state,
        backAction: payload.backAction,
        nextAction: payload.nextAction,
        nextActionDisabled: payload.nextActionDisabled,
        actionIsLoading: payload.actionIsLoading,
      };
    },
  },

  effects: dispatch => ({
    async init(
      {
        routeName,
        providerId,
      }: { routeName: string; providerId: number | undefined | null },
    ): Promise<void> {
      dispatch.providerOnboarding.setStepStatus(StepFormAction.Loading);
      if (providerId) {
        await dispatch.providers.loadProvider({
          providerId, noCache: true, setActive: true,
        });
        await dispatch.providerPlan.loadPlan({ providerId });
        await dispatch.providerOnboarding.fetch({ providerId });
      }

      dispatch.providerOnboarding.setStepStatus(StepFormAction.Invalid);

      if (routeName) {
        const canShowSubscriptionFeaturs = await calculateCanShowSubscriptionFeatures();
        const currentStep = pathsToSteps[routeName];

        dispatch.providerOnboarding.setOnboardingStatus({
          currentStep,
          isAllowed: canShowSubscriptionFeaturs,
          inOnboardingFlow: true,
        });
      }
    },
    async fetch(
      payload: { providerId: ProviderOnboardingState['providerId']; noLoading?: boolean },
      rootState,
    ): Promise<void> {
      const { providerOnboarding } = rootState;
      const { providerId, noLoading } = payload;
      const {
        providerId: currentProviderId,
        isFetching,
      } = isStatusState(providerOnboarding)
        ? providerOnboarding
        : {
          providerId: undefined,
          isFetching: undefined,
        };

      if (providerId === currentProviderId && isFetching) {
        return;
      }

      dispatch.providerOnboarding.onFetchStart({ providerId, noLoading });
      try {
        let promptStatus = await fetchProviderOnboardingPromptStatus(providerId);
        promptStatus = await transformPromptStatus(promptStatus);
        const onboardingStatus = await fetchProviderOnboardingStatus(providerId);
        const stepOrder = getStepsOrder(onboardingStatus?.stepOrder);
        const status = {
          providerId,
          status: { ...promptStatus },
          overallStatus: onboardingStatus?.status ?? StepStatus.NotStarted,
          stepOrder,
          steps: onboardingStatus?.steps ?? DEFAULT_STATE.steps,
          currentStep: stepOrder === null ? ERROR_ROUTE
            : rootState.providerOnboarding.currentStep,
        };

        dispatch.providerOnboarding.onFetchSuccess(status);
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerOnboarding.onFetchFail({ providerId });
      }
    },
    async setOnboardingDetails(
      payload: { providerId: ProviderOnboardingState['providerId']; request: ProviderOnboardingUpdateRequest },
    ): Promise<Response> {
      return updateProviderOnboardingStatus(payload.providerId, payload.request);
    },
    async navigateToStep(
      step: Step,
    ): Promise<void> {
      const route = stepsToPaths[step];
      if (route) {
        dispatch.providerOnboarding.setCurrentStep(step);
        await dispatch.route.go({
          route,
          notify: true,
          reload: false,
          replace: false,
        });
      }
    },
  }),

  selectors: (
    slice,
    createSelector,
    hasProps,
  ) => ({
    status: hasProps((__, props: StatusSelectorProps) => createSelector(
      slice,
      () => props.providerId,
      () => props.excludeSubscribeStep,
      calculateOnboardingStatus,
    )),
    excludedStepOrder: hasProps((__, excluded: Set<Step>) => createSelector(
      slice(state => state.stepOrder),
      () => excluded,
      (stepOrder: Step[], excludedSteps: Set<Step>): Step[] => (
        stepOrder.filter(step => !excludedSteps.has(step))
      ),
    )),
    steps: () => createSelector(
      slice(state => state.steps),
      steps => (steps),
    ),
    stepOrder: () => createSelector(
      slice(state => state.stepOrder),
      stepOrder => (stepOrder),
    ),
    stepsStats: () => createSelector(
      slice(state => state.steps),
      (steps: ProviderOnboardingSteps): StepsStats => {
        const stepsTotalCount = Object.values(steps).length;
        const stepsCompletedCount = Object.values(steps)
          .filter(step => step.status === StepStatus.Completed).length;
        const stepsRemainingCount = Object.values(steps)
          .filter(step => step.status !== StepStatus.Completed).length;
        const stepsCompletedPercent = Math.round((stepsCompletedCount / stepsTotalCount) * 100);
        return {
          stepsTotalCount,
          stepsCompletedCount,
          stepsRemainingCount,
          stepsCompletedPercent,
        };
      },
    ),
    isStepCompleted: () => createSelector(
      slice(state => state.currentStep),
      slice(state => state?.steps),
      (currentStep, steps: ProviderOnboardingSteps) => steps[currentStep]
        ?.status === StepStatus.Completed,
    ),
    isFetching: () => createSelector(
      slice(state => state.isFetching),
      (isFetching: boolean): boolean => (isFetching),
    ),
    isOnboardingInitialized: () => createSelector(
      slice(state => state.isInitialized),
      (isInitialized: boolean): boolean => (isInitialized),
    ),
    isOnboardingComplete: () => createSelector(
      slice(state => state.overallStatus),
      (overallStatus: OverallStatus): boolean => (
        overallStatus === OverallStatus.Completed
      ),
    ),
    inOnboardingFlow: () => createSelector(
      slice(state => state.inOnboardingFlow),
      inOnboardingFlow => inOnboardingFlow,
    ),
    currentStep: models => createSelector(
      slice(state => state.currentStep),
      (models as any).providerOnboarding.filteredStepOrder,
      (currentStep: Step, stepOrder: Step[]) => {
        const currentIndex = stepOrder.indexOf(currentStep);
        const currentStepName = stepOrder[currentIndex];
        const currentStepPath = stepsToPaths[currentStepName];
        return {
          step: currentStep,
          path: currentStepPath,
          index: currentIndex,
        };
      },
    ),
    currentStepSelectedValue: () => createSelector(
      slice(state => state.steps),
      slice(state => state.currentStep),
      (steps, currentStep): unknown => steps[currentStep]?.selectedValue,
    ),
    filteredStepOrder: models => createSelector(
      slice(state => state.stepOrder),
      slice(state => state.steps),
      models.providerPlan.currentPlanConfiguration,
      slice(state => state.isAllowed),
      models.abTest.isEnabled(PROVIDER_ONBOARDING_EXPERIENCE),
      (
        order: Step[],
        steps: ProviderOnboardingSteps,
        currentPlanConfig,
        isAllowed,
        is2024OnboardingFlagEnabled,
      ) => {
        if (isEmpty(order) || isEmpty(steps)) {
          return defaultStepsOrder;
        }
        const stepsWithSignup = order.find(step => step === Step.Signup) === undefined
          ? [
            Step.Signup,
            ...order,
          ] : order;

        /*
          NOTE: This is not included in the steps on the backend
          because it is not a form to be completed, and we don't need to track
          when it is acknowledged.
        */
        const stepsWithFreeTrialExplainer = (
          is2024OnboardingFlagEnabled
          && isAllowed
          && currentPlanConfig?.plan_name === ProviderPlan.Deluxe
          && stepsWithSignup.find(
            step => step === Step.FreeTrialExplanation,
          ) === undefined)
          ? [
            ...stepsWithSignup,
            Step.FreeTrialExplanation,
          ] : stepsWithSignup;

        return stepsWithFreeTrialExplainer
          .filter(step => {
            const stepInfo = steps[step];
            if (!stepInfo) {
              return true;
            }
            return steps[step].status !== StepStatus.Optional;
          });
      },
    ),
    backEnabledForCurrentStep: () => createSelector(
      slice(state => state.stepOrder),
      slice(state => state.currentStep),
      (
        order: Step[],
        currentStep: Step,
      ): boolean => {
        if (isEmpty(order) || !currentStep) {
          return false;
        }

        return order.indexOf(currentStep) !== 0 || currentStep === Step.Signup;
      },
    ),
    isOptionalStep: () => createSelector(
      slice(state => state.currentStep),
      slice(state => state.steps),
      (currentStep: Step, steps: ProviderOnboardingSteps) => {
        if (!currentStep || !steps) {
          return false;
        }
        const step = steps[currentStep];
        return step?.status === StepStatus.Optional;
      },
    ),
    isLastStep: models => createSelector(
      slice(state => state.currentStep),
      (models as any).providerOnboarding.filteredStepOrder,
      (currentStep: Step, stepOrder: Step[]) => {
        if (!currentStep || stepOrder?.length === 0) return false;
        const index = stepOrder.indexOf(currentStep);
        // If APO4071 is not enabled, the last step is the last one in the list
        if (index === -1
          || PAYMENT_STEPS.includes(currentStep)) {
          return true;
        }
        return stepOrder.indexOf(currentStep) + 1 === stepOrder?.length;
      },
    ),
    isFirstStep: models => createSelector(
      slice(state => state.currentStep),
      (models as any).providerOnboarding.filteredStepOrder,
      (currentStep: Step, stepOrder: Step[]) => {
        if (!currentStep) return false;
        if (stepOrder.length === 0) return true;

        return stepOrder.indexOf(currentStep) === 0;
      },
    ),
    signupUrl: () => createSelector(
      slice(state => state.currentStep),
      () => stepsToPaths[Step.Signup],
    ),
    landingUrl: () => createSelector(
      slice(state => state.currentStep),
      () => (Responsive.is.mobile || Responsive.is.tablet
        ? PRO_TODAY_TAB_HOME : PROFILE_EDIT_ROUTE_ONBOARDING),
    ),
    nextStep: models => createSelector(
      slice(state => state.currentStep),
      slice(state => state.stepOrder),
      slice(state => state.isAllowed),
      (models as any).providerOnboarding.filteredStepOrder,
      (models as any).providerOnboarding.isLastStep,
      (models as any).providerOnboarding.landingUrl,
      (
        currentStep: Step,
        stepOrder: Step[],
        isAllowed: boolean,
        filteredStepOrder: Step[],
        isLastStep: boolean,
        landingUrl: string,
      ) => {
        if (isLastStep) {
          return {
            path: landingUrl,
            index: null,
            step: null,
          };
        }

        let currentIndex = filteredStepOrder.indexOf(currentStep);
        let nextStepName: string;

        // If current step is an optional one will fail.
        // Fallbacks to the unfiltered list to go next step
        if (currentIndex === -1) {
          currentIndex = stepOrder.indexOf(currentStep);
          nextStepName = stepOrder[currentIndex + 1];
        } else {
          nextStepName = filteredStepOrder[currentIndex + 1];
        }

        // ----------------------------------------------------------
        // Conditional important for IOS APP Approval
        // Check if next step is plan selection and if is not allowed
        // redirect to profile
        if (nextStepName === Step.PlanSelect && isAllowed === false) {
          return {
            path: landingUrl, index: null, step: null,
          };
        }
        // ----------------------------------------------------------

        const nextStepPath = stepsToPaths[nextStepName];
        return {
          path: nextStepPath, index: currentIndex + 1, step: nextStepName as Step,
        };
      },
    ),
    previousStep: models => createSelector(
      slice(state => state.currentStep),
      slice(state => state.stepOrder),
      (models as any).providerOnboarding.filteredStepOrder,
      (models as any).providerOnboarding.isFirstStep,
      models.user.isLoggedIn,
      models.user.isProviderUser,
      models.user.isClientUser,
      (models as any).providerOnboarding.landingUrl,
      (
        currentStep: Step,
        stepOrder: Step[],
        filteredStepOrder: Step[],
        isFirstStep: boolean,
        isLoggedIn: boolean,
        isProviderUser: boolean,
        isClientUser: boolean,
        landingUrl: string,
      ) => {
        // No current step, redirect to signup
        if (!currentStep) {
          return { path: stepsToPaths[Step.Signup], index: 0 };
        }

        // If not logged in will redirect to home
        if (isFirstStep && (!isLoggedIn || isClientUser)) {
          return { path: HOME_ROUTE, index: null };
        }

        // If user is logged in back button should redirect to profile
        if (currentStep === stepOrder[0] && isLoggedIn && isProviderUser) {
          return { path: landingUrl, index: null };
        }

        // Payments steps should redirect to subscribe as only can be accessed
        // after subscribe step
        if (PAYMENT_STEPS.includes(currentStep)) {
          return { path: stepsToPaths[Step.PlanSelect], index: stepOrder.indexOf(Step.PlanSelect) };
        }

        let currentIndex = filteredStepOrder.indexOf(currentStep);
        let previousStepName: string;

        // If current step is an optional one will fail.
        // Fallbacks to the unfiltered list to go next step
        if (currentIndex === -1) {
          currentIndex = stepOrder.indexOf(currentStep);
          previousStepName = stepOrder[currentIndex - 1];
        } else {
          previousStepName = filteredStepOrder[currentIndex - 1];
        }

        const previousStepPath = stepsToPaths[previousStepName];
        return { path: previousStepPath, index: currentIndex - 1 };
      },
    ),
    shouldRedirectToHome: models => createSelector(
      models.user.isProviderUser,
      slice(state => state.isFetching),
      slice(state => state.currentStepStatus),
      slice(state => state.currentStep),
      (models as any).providerOnboarding.isOnboardingComplete,
      (
        isProviderUser: boolean,
        isFetching: boolean,
        currentStepStatus: StepFormAction,
        currentStep: Step,
        isOnboardingComplete: boolean,
      ): boolean => !isFetching
        && currentStepStatus !== StepFormAction.Loading
        && currentStepStatus !== StepFormAction.Submitted
        && currentStepStatus !== StepFormAction.Submitting
        && currentStep === Step.Signup && isProviderUser
        && isOnboardingComplete,
    ),
    isReady: models => createSelector(
      models.user.isProviderUser,
      models.user.isClientUser,
      models.user.isNotLoggedIn,
      slice(state => state.isFetching),
      slice(state => state.currentStepStatus),
      slice(state => state.currentStep),
      (
        isProviderUser: boolean,
        isClientUser: boolean,
        isAnon: boolean,
        isFetching: boolean,
        currentStepStatus: StepFormAction,
        currentStep: Step,
      ): boolean => (
        !isFetching
        && currentStepStatus !== StepFormAction.Loading
        && (
          (currentStep === Step.Signup && isAnon)
          // This works to show signup page for logged in client users that wants to
          // convert to providers and for providers that have use apple sign up
          // but still have pending information to fill in.
          || (currentStep === Step.Signup && isClientUser)
          || (currentStep !== Step.Signup && isProviderUser)
        )
      ),
    ),
  }),
});

export type Effects = {
  fetch: (params: { providerId: ProviderOnboardingState['providerId']; noLoading?: boolean }) => Promise<void>;
};
