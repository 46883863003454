// @ts-strict-ignore
import BaseTracker, { PlatformProperty } from '../BaseTracker';
import { UTMState } from '../../types';

/**
 * Sends events to internal StyleSeat event logger, gated by a feature flag.
 */
class ShuttleTracker extends BaseTracker {
  appVersion: string;

  private readonly fetch;

  constructor(
    env: string,
    platform: PlatformProperty,
    isApp: boolean,
    appVersion: string,
    private shuttleEndpoint: string,
  ) {
    super('shuttle', 'Tracker (Shuttle)', env, platform);
    this.appVersion = appVersion;
    this.isApp = isApp;
    this.fetch = fetch.bind(window);
    this.utmData = {};
  }

  private generateLogBody(level: string, eventId: any, context: any) {
    const {
      ss_tracking_cookie,
      provider_id,
      user_id,
      event_time_shuttle,
      session_id,
      user_data,
      ...restContext
    } = context;

    const utmProperties = this.validateUTMs();

    const body = {
      log_level: level,
      // NOTE: time precision varies by browser; what matters is sequence of events
      time: event_time_shuttle,
      cookie_id: ss_tracking_cookie,
      session_id,
      event: eventId,
      platform: this.platform,
      user_data,
      user_id: user_data?.user_id,
      pro_id: user_data?.provider_id,
      context: {
        postal_code: user_data?.postal_code,
        ...restContext,
        url: restContext.window_location_href,
        userData: user_data,
        ...utmProperties,
      },
    };

    if ('provider_id' in context) {
      body.context.provider_id = context.provider_id;
    }

    return body;
  }

  private sendLog(body: any = {}) {
    // No endpoint, skip
    if (!this.shuttleEndpoint) {
      return Promise.resolve({});
    }

    if (typeof this.fetch !== 'function') {
      // it's very unlikely that we would get here
      return Promise.resolve();
    }

    return this.fetch(this.shuttleEndpoint, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  trackEvent(eventName, properties) {
    const eventProps = {
      app_version: this.appVersion,
      event_time_shuttle: new Date().toISOString().replace('T', ' ').replace('Z', ''),
      ...properties,
    };

    const body = this.generateLogBody('INFO', eventName, eventProps);

    this.debugLog('shuttle', eventName, body);

    if (this.env !== 'test') {
      return this.sendLog(body);
    }

    return Promise.resolve({});
  }

  async updateUTMs(utmData: UTMState | null) {
    if (!utmData) {
      this.utmData = {};
    } else {
      this.utmData = utmData;
    }
  }
}

export default ShuttleTracker;
