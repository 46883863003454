// @ts-strict-ignore
import moment from 'moment';
import nonCriticalException from '../../../exceptionLogger';
import { copyValue } from '../utils/copyValue';
import { loopOverDatasets } from '../utils/loopOverDatasets';
import { sortDatasetByDate } from '../sort/byDate';
import {
  DataFields,
  DataSet,
  MasterDataSet,
  StatDataSet,
} from '../types';

export function filterByDate<F extends DataFields = DataFields>(
  dataset: StatDataSet<F>,
  start: moment.Moment | string,
  end?: moment.Moment | string,
): StatDataSet<F>;

export function filterByDate<F extends DataFields = DataFields>(
  dataset: MasterDataSet<F>,
  start: moment.Moment | string,
  end?: moment.Moment | string,
): MasterDataSet<F>;

/**
 * Filter out any data not within this date range.
 *
 * ```
 * var filtered = filterByDate(dataset, '2016-01-02', '2016-01-04');
 * ```
 *
 * @param {Object} data - The dataset object
 * @param {String|Moment} start - The start date (moment object or date string as YYYY-MM-DD)
 * @param {String|Moment} end   - (optional) The end date
 * (moment object or date string as YYYY-MM-DD)
 * If not specified, it will be assumed to be the same as startDate.
 *
 * @return {Object} An updated dataset
 */
export function filterByDate<F extends DataFields = DataFields>(
  data: DataSet<F>,
  start: moment.Moment | string,
  end?: moment.Moment | string,
) {
  const filtered = [];

  // Argument validation to make sure a dataset hasn't been explicitly passed while chaining.
  if (start && typeof start !== 'string' && !moment.isMoment(start) && !moment.isDate(start)) {
    nonCriticalException(`Start date should be a string or moment object. You sent an ${typeof start}`);
  }

  // Recursive call for all value sets
  if (!Array.isArray(data)) {
    return (
      loopOverDatasets<F>(data, valueSet => filterByDate(valueSet, start, end))
    );
  }

  // Ensure the dates are moment objects
  const startDate = moment(start, 'YYYY-MM-DD').startOf('day');
  // If no endDate, assume same as startDate
  const endDate = moment(end || start, 'YYYY-MM-DD').endOf('day');

  // Sort the data
  const dataset = sortDatasetByDate(copyValue(data));

  // Loop through data
  for (let i = 0; i < dataset.length; i++) {
    const value = dataset[i];
    const thisDate = value.date;

    // No date, skip
    if (typeof thisDate !== 'undefined') {
      // Within range
      if (thisDate.isSameOrAfter(startDate) && thisDate.isSameOrBefore(endDate)) {
        filtered.push(copyValue(value));
      } else if (thisDate.isAfter(endDate)) {
        // Past range, stop
        break;
      }
    }
  }

  return filtered;
}

export function byCurrentRange<F extends DataFields = DataFields>(
  dataset: StatDataSet<F>,
  timeRange: 'day' | 'week' | 'month' | 'year',
): StatDataSet<F>;

export function byCurrentRange<F extends DataFields = DataFields>(
  dataset: MasterDataSet<F>,
  timeRange: 'day' | 'week' | 'month' | 'year',
): MasterDataSet<F>;

export function byCurrentRange<F extends DataFields = DataFields>(
  stats,
  timeRange,
) {
  const start = moment().startOf(timeRange);
  const end = moment().endOf(timeRange);

  return filterByDate<F>(
    stats,
    start,
    end,
  );
}
