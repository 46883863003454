/**
 * A collection of utility functions for determining the state of an appointment
 */
import moment from 'moment';
import type { GuestClientAppointment } from '../api/GuestClientAppointment';
import { PublicProvider } from '../api/Providers';
import {
  CheckoutStatus,
  UserAppointment,
  AutochargeStatus,
} from '../api/Users/Appointments';
import { AppointmentDetailsStatus, AppointmentStatusDetails } from '../api/Users/Appointments/AppointmentsStatusDetails';
import { AppointmentOverviewToday } from '../api/Users/Appointments/AppointmentsToday';
import { PaymentMethodType } from '../components/consumer/booking/BookingFlow/types';

import { hasNSLCPolicy } from './ProviderState';
import { DepositStatus } from '../api/Providers/Appointments';

/**
 * Client appointments module
 */
export const PRECHECKOUT_STATUSES: CheckoutStatus[] = [
  CheckoutStatus.NoCheckoutAttempted,
  CheckoutStatus.Uncompleted,
  CheckoutStatus.AttemptMade,
  CheckoutStatus.UnNoShow,
];

export type AppointmentStatePro = Pick<PublicProvider,
| 'is_disabled'
| 'cancellation_notice_hours'
| 'cancellation_policy'
>;

type AnyUserAppointment = UserAppointment
| UserAppointmentWithProviderWithLocation
| AppointmentStatusDetails
| AppointmentOverviewToday
| GuestClientAppointment;

export type UserAppointmentWithProviderWithLocation = Omit<UserAppointment, 'provider'> & {
  provider: PublicProvider;
};

const isUserAppointment = (
  appointment: AnyUserAppointment,
): appointment is UserAppointment => (
  'is_client_initiated_prepaid' in appointment
  && 'can_change_payment_type' in appointment);

/**
 * Returns `true` if `appointment` can be rebooked; `false` otherwise.
 *
 * @name isRebookable
 * @param {Object} provider The provider object
 * @param {Object} appointment The appointment to check.
 * @returns {Boolean} `true` if `appointment` can be rebooked; `false` otherwise.
 */
export function isRebookable(
  provider: Pick<AppointmentStatePro, 'is_disabled'>,
  appointment: Pick<UserAppointment, 'time_blocks' | 'service_name' | 'is_rebookable'>,
): boolean {
  if (!provider || provider.is_disabled
    || typeof appointment !== 'object'
    || !Array.isArray(appointment.time_blocks)
    || !appointment.service_name) {
    return false;
  }

  return !!appointment.is_rebookable;
}

/**
 * If the appointment has already started.
 *
 * @param appointment The appointment object.
 * @return {boolean}
 */
export function hasStarted(appointment: Pick<UserAppointment, 'startDate'>): boolean {
  return moment().isAfter(appointment.startDate);
}

/**
 * Is this appointment over (past the end time)
 *
 * @param {Object} appointment
 * @return {boolean}
 */
export function isFinished(appointment: Pick<UserAppointment, 'endDate'>): boolean {
  return moment().isAfter(appointment.endDate);
}

/**
 * If this appointment was successfully completed. In other words, this appointment is not in any
 * pre-checkout state and not CHECKOUT_STATUS.CANCELLED nor CHECKOUT_STATUS.NO_SHOW.
 * Note: this is the same logic as "is_checked_out" on the Appointment model.
 *
 * @param appointment The appointment object.
 * @returns {Boolean}
 */
export function checkedOut(appointment: Pick<UserAppointment, 'checkout_status'>): boolean {
  return [
    CheckoutStatus.Completed,
    CheckoutStatus.CompletedWTip,
  ].includes(appointment.checkout_status);
}

/**
 * Can this appointment still charge a cancellation fee
 *
 * @param {Object} appointment
 * @return {Boolean}
 */
export function hasCancellationCharge(
  appointment: Pick<UserAppointment, 'cancellation_charge' | 'checkout_status'>,
): boolean {
  return !!appointment.cancellation_charge && !checkedOut(appointment);
}

/**
 * Is this appointment paid ahead
 *
 * @param {Object} appointment
 * @return {Boolean}
 */
export function isPaidAhead(
  appointment: Pick<UserAppointment, 'checkout_status' | 'startDate'>,
): boolean {
  return checkedOut(appointment) && !hasStarted(appointment);
}

/**
 * Is it within 24 hours of the start of this appointment.
 *
 * @param {Object} appointment
 * @return {boolean}
 */
export function isWithin24Hours(appointment: Pick<UserAppointment, 'startDate'>): boolean {
  // TODO: this should operate on UTC, not local time as the local time for the client could be
  //  different from the local time of the provider at the moment of execution (i.e. client
  //  might be on a trip in a different timezone)
  return appointment && moment(appointment.startDate).diff(moment(), 'days') < 1;
}

/**
 * Does this appointment have a prepayment charge
 *
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export function hasDeposit(appointment: Pick<UserAppointment, 'deposit_status'>): boolean {
  return appointment?.deposit_status === DepositStatus.Successful;
}

/**
 * Does this appointment have a refunded prepayment
 *
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export function hasRefundedDeposit(appointment: Pick<UserAppointment, 'deposit_status'>): boolean {
  return appointment?.deposit_status === DepositStatus.Refunded;
}

/**
 * Does this appointment have a partial prepayment or NCD prepayment associated with it
 *
 * @param  {Object} appointment Appointment object
 * @return {Boolean}
 */
export function isDepositAppointment(appointment: Pick<UserAppointment, 'deposit_status'>): boolean {
  return (
    hasDeposit(appointment)
    || hasRefundedDeposit(appointment)
  );
}

/**
 * If this appointment can potentially be checked out (considered to be in a "pre-checkout" state).
 *
 * @param appointment The appointment object.
 * @returns {Boolean}
 */
export function canBeCheckedOut(appointment: Pick<UserAppointment, 'checkout_status'>): boolean {
  return PRECHECKOUT_STATUSES.includes(appointment.checkout_status);
}

/**
 * Checks whether or not the user can cancel the appointment.
 *
 * @name canCancel
 * @param {Object} appointment The appointment object.
 * @param {Object} provider The provider object
 * @returns {bool} True if the user can cancel an appointment,
 *  false otherwise.
 */
export function canCancel(
  appointment: Pick<UserAppointment, 'startDate' | 'checkout_status'>,
  provider?: PublicProvider,
): boolean {
  if (!provider) return false;
  if (hasStarted(appointment)) return false;
  if (isPaidAhead(appointment)) return false;
  if (isWithin24Hours(appointment) && hasNSLCPolicy(provider)) {
    return false;
  }
  if (!hasNSLCPolicy(provider) && 'cancellation_notice_hours' in provider) {
    const cancellationNoticeHours = parseInt(String(provider.cancellation_notice_hours), 10);
    if (!Number.isNaN(cancellationNoticeHours)
      && appointment.startDate.diff(moment(), 'hours') < cancellationNoticeHours
    ) {
      return false;
    }
  }
  return canBeCheckedOut(appointment);
}

/**
 * Checks whether the appointment was booked with Klarna or not
 *
 * @param appointment The appointment object.
 * @returns {Boolean} True if the appointment is booked with Klarna
 */
export function bookedWithKlarna(
  appointment: Pick<UserAppointment, 'payment_method_type'> | GuestClientAppointment,
): boolean {
  return ('payment_method_type' in appointment)
    && appointment?.payment_method_type === PaymentMethodType.Klarna;
}

/**
 * Checks whether or not the user can reschedule the appointment.
 *
 * @param {Object} provider
 * @param {Object} appointment
 * @returns {Boolean}
 */
export function canReschedule(
  appointment: Pick<UserAppointment,
  'checkout_status'
  | 'startDate'
  | 'endDate'
  | 'time_blocks'
  | 'service_name'
  | 'is_pro_booked'
  | 'payment_method_type'
  >,
  provider?: PublicProvider,
): boolean {
  if (
    appointment.is_pro_booked
    || !provider
    || ('is_disabled' in provider && provider.is_disabled)
    || isFinished(appointment)
    || !Array.isArray(appointment.time_blocks)
    || appointment.time_blocks.length === 0
    || !appointment.service_name
    || bookedWithKlarna(appointment)
  ) {
    return false;
  }

  return canCancel(appointment, provider);
}

/**
 * Checks whether or not an appointment is prepaid
 * 1) Appointment has not yet begun
 * 2) Appt is either:
 *    is_client_initiated_prepaid
 *    pro-initiated prepaid (successful payment request)
 *
 * @param appointment
 * @returns True if the appointment is pre-paid, otherwise false
 */
export const isPrepaid = (
  appointment: UserAppointment | UserAppointmentWithProviderWithLocation | AppointmentStatusDetails,
): boolean => {
  const isBeforeStart = moment().isBefore(appointment.startDate);
  return isUserAppointment(appointment)
    ? isBeforeStart && (appointment.is_client_initiated_prepaid || appointment.is_checked_out)
    : isBeforeStart && !canBeCheckedOut(appointment);
};

/**
 * If this appointment has an autocharge status that indicates it could be paid through StyleSeat.
 *
 * @param appointment The appointment object.
 * @returns {Boolean}
 */
export function paymentsEligible(appointment: Pick<UserAppointment, 'autocharge_status'>): boolean {
  return [
    AutochargeStatus.EPAuto,
    AutochargeStatus.EPManual,
  ].includes(appointment.autocharge_status);
}

/**
 * If this appointment can be paid for ahead of the appointment time slot. This indicates that the
 * provider is on the "Marketing plan" or on the "Scheduling plan" with pre-payment enabled.
 * Additionally, if this is the day of the appointment, we allow for pre-payment no matter what.
 *
 * The logic described above is implemented on the backend in determining
 * `is_client_initiated_prepay_eligible`.
 *
 * @param appointment The appointment object.
 * @returns {Boolean}
 */
export function prepayEligible(appointment: UserAppointment): boolean {
  return appointment.is_client_initiated_prepay_eligible && canBeCheckedOut(appointment);
}

/**
 * If this appointment is a payments appointment that is automatically checked out. This would
 * indicate a provider on the "Marketing plan" or on the "Scheduling plan" and with auto-checkout
 * enabled.
 *
 * @param appointment The appointment object.
 * @returns {Boolean}
 */
export function isAutoCheckout(
  appointment: AppointmentStatusDetails | AppointmentOverviewToday,
): boolean {
  return appointment.autocharge_status === AutochargeStatus.EPAuto;
}

/**
 * Checks whether the appointment start time is today or not
 *
 * @param appointment The appointment object.
 * @returns {Boolean} True if the appointment is today
 */
export const isToday = (appointment: Pick<UserAppointment, 'startDate'>): boolean => (
  appointment.startDate.isSame(moment(), 'day')
);

/**
 * If this appointment can be paid through StyleSeat.
 *
 * @param appointment The appointment object.
 * @returns {Boolean}
 */
export function paymentsEnabled(
  appointment: Pick<AppointmentStatusDetails,
  'client_card_on_file'
  | 'payment_method_type'
  | 'autocharge_status'>,
): boolean {
  return (
    (!!appointment.client_card_on_file || bookedWithKlarna(appointment))
    && paymentsEligible(appointment)
  );
}

export function canRate(
  appt: Pick<UserAppointment, 'startDate' | 'endDate' | 'cancellation_time' | 'is_no_show'>,
): boolean {
  const now = moment();
  return (
    !appt.startDate.isAfter(now)
    && !appt.cancellation_time
    && !appt.is_no_show
    && !now.clone().subtract(30, 'days').isAfter(appt.endDate)
  );
}

export function appointmentStatus(apptDetailsStatus: AppointmentDetailsStatus | null) {
  // `null` represents an appointment that has ended but is not checked out;
  // we'll treat it as completed for the client's UX
  if (apptDetailsStatus === null) return AppointmentDetailsStatus.Completed;

  return apptDetailsStatus;
}
