import { HistoryRecord } from './types';
import SimpleCache from '../../../modules/SimpleCache';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';

export const clientHistoryCache = new SimpleCache(60 * 30); // caches for 30 minutes

/**
 * Get past appointments, notes, and photos for a client.
 *
 * @param providerId The provider ID
 * @param clientId The client ID
 * @param size The number of records to retrieve
 * @param page The page to retrieve
 * @returns Array of client history objects
 */
export async function getClientHistory(
  providerId: number | string,
  clientId: number | string,
  size: number = 50,
  page: number = 1,
): Promise<IDRFResponse<HistoryRecord>> {
  return ssFetchJSON<IDRFResponse<HistoryRecord>>(
    `/api/v2/providers/${providerId}/clients/${clientId}/history?size=${size}&page=${page}`,
    {
      ssCache: clientHistoryCache,
    },
  );
}

/**
 * Clears the client history cache
 */
export function clearHistoryCache() {
  clientHistoryCache.clear();
}
