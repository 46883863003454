import { PaymentMethodType } from '../../../components/consumer/booking/BookingFlow/types';
import { API_ROOT } from '../../../config';
import nonCriticalException from '../../../modules/exceptionLogger';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';
import { DateTimeStamp } from '../../../types/dateTime';
import { PublicProvider } from '../../Providers';
import { ClientCardOnFile } from '../../Providers/Appointments';
import {
  AutochargeStatus,
  CheckoutStatus,
  transformAppointment,
  TransformAppointment,
} from './Appointments';
import { AppointmentDetailsStatus } from './AppointmentsStatusDetails';

/**
 * UserAppointmentsTodayOverviewSerializer
 * /users/{userId}/appointments/today
 */
export type AppointmentOverviewTodayResponse = {
  autocharge_status: AutochargeStatus;
  checkout_status: CheckoutStatus;
  client_card_on_file: Pick<ClientCardOnFile, 'bank' | 'last4'> | null;
  cost: string;
  details_status: AppointmentDetailsStatus | null;
  id: number;
  local_end: DateTimeStamp;
  local_start: DateTimeStamp;
  payment_method_type: PaymentMethodType | null;
  provider: Pick<PublicProvider, 'id' | 'name' | 'profile_photo'>;
  service_name: string;
  subtotal: string;
};

export type AppointmentOverviewToday = TransformAppointment<AppointmentOverviewTodayResponse>;

/**
 * Loads a list of appointments a user has for today, sorted for consumption
 * @param {int} userId
 */
export async function loadAppointmentsToday(
  userId: number,
): Promise<AppointmentOverviewToday[] | null> {
  const url = new URL(`/api/v2/users/${userId}/appointments/today`, API_ROOT);
  let apptsResponse: IDRFResponse<AppointmentOverviewToday>;
  try {
    apptsResponse = await ssFetchJSON<IDRFResponse<AppointmentOverviewToday>>(url.toString());
    if (apptsResponse.count === 0) return null;
  } catch (error) {
    nonCriticalException(error);
    return null;
  }

  return apptsResponse.results.map(appt => transformAppointment(appt));
}
