// @ts-strict-ignore
import moment, { MomentInput } from 'moment/moment';
import { PublicProvider } from '../../api/Providers';
import {
  SMART_PRICE_DEFAULT_SERVICE_UPCHARGE,
  SMART_PRICE_DEFAULT_SERVICE_UPCHARGE_CAP,
  SmartPriceSource,
} from '../../components/provider/smartPricing/constants';
import { SmartPricingPreferences } from '../../components/provider/smartPricing/types';
import { loadProvider } from '../Api';

/**
 * Calculates the service cost upcharge with smart pricing
 * @param serviceCost The service cost
 * @param smartPriceData
 * @returns the service cost
 */
export function calculateSmartPriceUpcharge(
  serviceCost: number,
  smartPriceData: SmartPricingPreferences,
): number {
  let upcharge = 0;
  if (smartPriceData) {
    upcharge = Math.ceil(serviceCost * Number(smartPriceData.service_upcharge)) / 100;

    if (smartPriceData.service_upcharge_cap) {
      upcharge = Math.min(upcharge, Number(smartPriceData.service_upcharge_cap));
    }
  }
  return upcharge;
}

/**
 * Check if the current user booking with the provider would incur a smart price.
 *
 * @returns {Promise<boolean>}
 * @param providerId
 */
export async function getIsSmartPriceEligible(providerId): Promise<boolean> {
  const {
    smart_pricing_enabled: smartPricingEnabled,
    can_process_payments: canProcessPayments,
  } = await loadProvider(providerId);

  return smartPricingEnabled && canProcessPayments;
}

export const getApplicableSmartPrice = (
  provider: Pick<PublicProvider, 'smart_pricing_enabled' | 'can_process_payments' | 'id'>,
  smartPrice: SmartPricingPreferences | undefined,
  date: MomentInput,
): SmartPricingPreferences | null => {
  const defaultSource: SmartPriceSource = SmartPriceSource.Model;

  // the default source for smart price is model,
  // unless in new model test, then it is optimized model
  if (smartPrice) {
    // eslint-disable-next-line no-param-reassign
    smartPrice.source = defaultSource;
  }

  const proHasSmartPriceEnabled = (
    provider.smart_pricing_enabled
    && provider.can_process_payments
  );

  const withinNextDay = moment(date).isBefore(moment().add(1, 'day'));
  const overrideSmartPrice = (
    !smartPrice
    && withinNextDay
    && proHasSmartPriceEnabled
  );

  const smartPriceOverride: SmartPricingPreferences = {
    service_upcharge: SMART_PRICE_DEFAULT_SERVICE_UPCHARGE,
    service_upcharge_cap: SMART_PRICE_DEFAULT_SERVICE_UPCHARGE_CAP,
    source: SmartPriceSource.LastMinuteClientBooked,
  };

  return overrideSmartPrice ? smartPriceOverride : smartPrice;
};
