// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import nonCriticalException from '../modules/exceptionLogger';
import { ssFetchJSON } from '../modules/ssFetch';
import analytics from '../modules/analytics';
import {
  STATE_FAVORITED_FIRST_PRO_KEY,
  STATE_DISMISSED_FAVORITE_NOTIFICATION_BUBBLE_KEY,
  STATE_VISITED_HOME_KEY,
} from './Favorites.constants';
import type { PublicProvider } from '../api/Providers';
import type { RootState, RootModel } from './models';
import { stateManager } from '../modules/user/stateManager';

type State = {
  providers: PublicProvider[];

  // luciano says writing and immediately reading from statemanager is bad
  // i say statemanager should be a model :)
  // someday
  cachedFavoritedFirstProStateValue: boolean;
  cachedVisitedHomeStateValue: boolean;
  cachedDismissedNotificationBubbleStateValue: boolean;
  initialized: boolean;
};

/**
 * Determines whether or not a particular pro has been favorited by the user.
 * @param {Object} state The current root redux state
 * @param {Number} proId The ID of the pro of which to determine favorite state
 */
export function isProviderFavorited(state: RootState, proId: number) {
  return !!state.favorites.providers.find(item => item.id === proId);
}

type Response = {
  status: string;
  isOn: boolean;
  heartCount: number;
  ctype: string;
  objectId: number;
};

const TOOLTIP_KEY = 'find_your_favorites';

const Favorites = createModel<RootModel>()({
  name: 'favorites',

  state: {
    providers: [],
    cachedFavoritedFirstProStateValue: false,
    cachedVisitedHomeStateValue: false,
    cachedDismissedNotificationBubbleStateValue: false,
    initialized: false,
  } as State,

  reducers: {
    // payload should be a provider object
    addFavorite: produce<State, [PublicProvider]>((state: State, payload) => {
      state.providers.push(payload);
    }),

    // payload should be a provider ID
    removeFavorite: produce<State, [number]>((state: State, payload) => {
      const index = state.providers.findIndex(item => item.id === payload);

      state.providers.splice(index, 1);
    }),

    clearFavorites: () => ({
      providers: [],
      cachedFavoritedFirstProStateValue: false,
      cachedVisitedHomeStateValue: false,
      cachedDismissedNotificationBubbleStateValue: false,
      initialized: false,
    }),

    // payload should be an array
    setFavorites: (state: State, payload: PublicProvider[]) => ({
      ...state,
      providers: [...(payload || [])],
      initialized: true,
    }),

    // listen to logout of `CurrentUser` model and clear favorites if that occurs
    'user/logout': () => ({
      providers: [],
      cachedFavoritedFirstProStateValue: false,
      cachedVisitedHomeStateValue: false,
      cachedDismissedNotificationBubbleStateValue: false,
      initialized: false,
    }),

    updateCache: (state: State, payload: {
      cachedFavoritedFirstProStateValue?: boolean;
      cachedVisitedHomeStateValue?: boolean;
      cachedDismissedNotificationBubbleStateValue?: boolean;
    }) => ({
      ...state,
      ...payload,
    }),
  },

  effects: dispatch => ({
    initialize: async () => {
      try {
        const payload = {
          cachedFavoritedFirstProStateValue: await stateManager.getActionValue(
            STATE_FAVORITED_FIRST_PRO_KEY,
          ),
          cachedVisitedHomeStateValue: await stateManager.getActionValue(STATE_VISITED_HOME_KEY),
          cachedDismissedNotificationBubbleStateValue: await stateManager.getActionValue(
            STATE_DISMISSED_FAVORITE_NOTIFICATION_BUBBLE_KEY,
          ),
        };

        dispatch.favorites.updateCache(payload);
      } catch (e) {
        nonCriticalException(e);
      }
    },

    onClientNavBarRendered: async (payload?: undefined, rootState?: RootState) => {
      if (rootState.favorites.cachedFavoritedFirstProStateValue) {
        const dismissed = await stateManager.getActionValue(
          STATE_DISMISSED_FAVORITE_NOTIFICATION_BUBBLE_KEY,
        );
        const visitedHome = rootState.favorites.cachedVisitedHomeStateValue;

        if (!visitedHome) {
          analytics.track('favorites_reprompted', {
            dismissed,
          });
        }
      }
    },

    onNavigatedToHome: async (payload?: undefined, rootState?: RootState) => {
      if (rootState.favorites.cachedFavoritedFirstProStateValue) {
        const dismissed = rootState.favorites.cachedDismissedNotificationBubbleStateValue;
        const visitedHome = rootState.favorites.cachedVisitedHomeStateValue;
        if (!dismissed) {
          await stateManager.addAction(STATE_DISMISSED_FAVORITE_NOTIFICATION_BUBBLE_KEY, true);
          await dispatch.favorites.updateCache({
            cachedDismissedNotificationBubbleStateValue: true,
          });
        }
        if (!visitedHome) {
          await stateManager.addAction(STATE_VISITED_HOME_KEY, true);
          await dispatch.favorites.updateCache({
            cachedVisitedHomeStateValue: true,
          });
        }
        if (!dismissed || !visitedHome) {
          analytics.track('favorites_prompt_cleared', {
            dismissed,
            visited_home: visitedHome,
          });
        }
      }
    },

    onHomeBubbleTooltipClosed: async ({
      previousProps,
    }: {
      previousProps: RootState['clientNavBar']['homeBubbleTooltipProps'];
    }) => {
      if (previousProps?.key === TOOLTIP_KEY) {
        stateManager.addAction(STATE_DISMISSED_FAVORITE_NOTIFICATION_BUBBLE_KEY, true);
        await dispatch.favorites.updateCache({
          cachedDismissedNotificationBubbleStateValue: true,
        });
        analytics.track('favorites_prompt_dismissed');
      }
    },

    // payload should be provider ID
    favoriteProvider: async (payload: number): Promise<Response> => {
      const json = await ssFetchJSON(`/hearting/provider.provider/${payload}/heart_on/`);
      const provider = await ssFetchJSON(`/api/v2/providers/${payload}`);

      analytics.track('C Favorite Pro', {
        pro_id: payload,
        favorite_action: 'favorite',
      });
      dispatch.favorites.addFavorite(provider);

      if (!await stateManager.getActionValue(STATE_FAVORITED_FIRST_PRO_KEY)) {
        stateManager.addAction(STATE_FAVORITED_FIRST_PRO_KEY, true);
        await dispatch.favorites.updateCache({
          cachedFavoritedFirstProStateValue: true,
        });
        dispatch.clientNavBar.showHomeUnreadBadge();
        dispatch.clientNavBar.showHomeBubbleTooltip({
          text: 'find your favorites on the homepage',
          key: TOOLTIP_KEY,
          testID: 'favorites-home-bubble-tooltip',
        });
        analytics.track('favorites_prompted');
      }
      return json;
    },

    // payload should be a provider ID
    unfavoriteProvider: async (payload: number): Promise<Response> => {
      const json = await ssFetchJSON(`/hearting/provider.provider/${payload}/heart_off/`);

      analytics.track('C Unfavorite Pro', {
        pro_id: payload,
        favorite_action: 'unfavorite',
      });
      dispatch.favorites.removeFavorite(payload);

      return json;
    },

    loadFavorites: async (payload?: undefined | null, rootState?) => {
      // can only load favorites for a logged in user
      if (rootState.user.is_anon) {
        return undefined;
      }

      // for now, this function will supply different data than what would be added via
      // favoriteProvider or unfavoriteProvider
      const json: { hearted: PublicProvider[] } = await ssFetchJSON('/hearting/providers');

      dispatch.favorites.setFavorites(json.hearted);

      return json;
    },

    /**
     * Determines whether or not a given pro is favorited, and if so, flips their state. payload
     * should be a pro ID
     */
    toggleFavoritedProvider: async (payload: number, rootState: RootState): Promise<Response> => {
      if (isProviderFavorited(rootState, payload)) {
        return dispatch.favorites.unfavoriteProvider(payload);
      }

      return dispatch.favorites.favoriteProvider(payload);
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    /**
     * Determines whether or not a particular pro has been favorited by the user.
     * @param {Object} models The current root redux state
     * @param {Number} providerId The ID of the pro of which to determine favorite state
     */
    isProviderFavorited: hasProps((
      _,
      { providerId }: { providerId: number },
    ) => createSelector(
      slice((state: State) => state.providers),
      // eslint-disable-next-line eqeqeq
      (providers: PublicProvider[]): boolean => providers.some(item => item.id == providerId),
    )),
  }),
});

export default Favorites;
