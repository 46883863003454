// @ts-strict-ignore
/**
 * Utilities for working with moment.range() objects
 */

import moment, { MomentInput, unitOfTime } from 'moment';
import { DateRange } from 'moment-range';
import { Moment } from './dateUtils';

interface RangeInput {
  start: MomentInput;
  end: MomentInput;
}

export type Range = {
  start: Moment;
  end: Moment;
};

function normalizeRange(input: RangeInput): Range {
  return {
    start: moment(input.start),
    end: moment(input.end),
  };
}

/**
 * Return a moment.range, ensuring start is <= end.
 * @param start
 * @param end
 */
export function orderedRange(start: Moment, end: Moment): DateRange {
  return new DateRange(moment.min(start, end), moment.max(start, end));
}

/**
 * Ensure the range's start <= the range's end.
 * @param input - the moment.range object to reorder
 */
export function reorderRange(input: RangeInput): DateRange {
  const { start, end } = normalizeRange(input);
  return orderedRange(start, end);
}

/**
 * Snap a range to the nearest second/minute/hour/day/week/month/year
 * @param range
 * @param type - one of second/minute/hour/day/week/month/year
 */
export function snapRange(input: RangeInput, type: unitOfTime.StartOf = 'day'): DateRange {
  const range = normalizeRange(input);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  validateRangeType(type);
  return new DateRange(
    range.start.clone().startOf(type),
    range.end.clone().endOf(type),
  );
}

/**
 * Pretty-prints a date range.
 *
 * Note that this does not compare or render time information.
 *
 * @param {DateRange} range - the date range to pretty print
 * @param {Boolean} relative
 *        If true, relative date ranges will be rendered "This Year", "This Month",
 *        "This Week", "Today".
 *        If false, relative date ranges will not be rendered.
 * @param {moment.Moment} today - The date to consider "today" for relative rendering.
 *        Probably only useful for unit testing.
 */
export function humanizeDateRange(
  range: RangeInput,
  relative: boolean = true,
  today: Moment = moment(),
): string {
  const dateRange = snapRange(reorderRange(range), 'day');

  if (relative) {
    const todayStart = today.clone().startOf('day');
    const todayEnd = today.clone().endOf('day');

    const weekStart = today.clone().startOf('week');
    const weekEnd = today.clone().endOf('week');
    const monthStart = today.clone().startOf('month');
    const monthEnd = today.clone().endOf('month');
    const yearStart = today.clone().startOf('year');
    const yearEnd = today.clone().endOf('year');

    const thisDay = new DateRange(todayStart, todayEnd);
    const thisWeek = new DateRange(weekStart, weekEnd);
    const thisMonth = new DateRange(monthStart, monthEnd);
    const thisYear = new DateRange(yearStart, yearEnd);

    if (dateRange.isSame(thisYear)) {
      return 'This Year';
    }
    if (dateRange.isSame(thisMonth)) {
      return 'This Month';
    }
    if (dateRange.isSame(thisWeek)) {
      return 'This Week';
    }
    if (dateRange.isSame(thisDay)) {
      return 'Today';
    }
  }

  const { start, end } = dateRange;

  if (dateRange.isSame(snapRange(dateRange, 'year'))) {
    if (dateRange.diff('year') === 0) {
      // Single full year
      return start.format('YYYY');
    }
    // Multiple full years
    return `${start.format('YYYY')} — ${end.format('YYYY')}`;
  }
  if (dateRange.isSame(snapRange(dateRange, 'month'))) {
    if (dateRange.diff('month') === 0) {
      // Single full month
      return start.format('MMMM YYYY');
    }
    // Multiple full months
    return `${start.format('MMM YYYY')} — ${end.format('MMM YYYY')}`;
  }
  if (start.year() === end.year()) {
    if (start.month() === end.month()) {
      if (start.date() === end.date()) {
        // No full months/years, same year, same month, same day
        return start.format('MMM D, YYYY');
      }
      // No full months/years, same year, same month, not same day
      return `${start.format('MMM D')} — ${end.format('D, YYYY')}`;
    }
    // No full months/years, same year, different months
    return `${start.format('MMM D')} — ${end.format('MMM D, YYYY')}`;
  }
  // No full months/years, different years
  return `${start.format('MMM D, YYYY')} — ${end.format('MMM D, YYYY')}`;
}

/**
 * Throw error if type is not one of day, week, month, year.
 */
export function validateRangeType(type: unitOfTime.StartOf) {
  if ([
    'day',
    'week',
    'month',
    'year',
  ].indexOf(type) < 0) {
    throw new Error(`Invalid range type ${type}`);
  }
}

/**
 * Get the current range (today, this week, this month, this year).
 * @param type - one of day, week, month, year.
 * @returns {DateRange}
 */
export function getCurrentRange(type: unitOfTime.StartOf = 'day'): DateRange {
  validateRangeType(type);
  return snapRange(new DateRange(moment(), moment()), type);
}
