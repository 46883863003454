export class TokenBucket {
  private readonly capacity: number;
  private readonly refillIntervalSec: number;
  private available: number;
  private lastRefillAt: Date;

  constructor(capacity: number, refillIntervalSec: number) {
    this.capacity = capacity;
    this.refillIntervalSec = refillIntervalSec;

    this.available = capacity;
    this.lastRefillAt = new Date();
  }

  forceRefill() {
    this.available = this.capacity;
    this.lastRefillAt = new Date();
  }

  refillIfAllowed() {
    const now = new Date();
    const elapsed = (now.getTime() - this.lastRefillAt.getTime()) / 1000;
    if (elapsed >= this.refillIntervalSec) {
      this.forceRefill();
    }
  }

  canRedeemToken(): boolean {
    this.refillIfAllowed();
    return this.available >= 1;
  }

  attemptRedeemToken(): boolean {
    const canRedeem = this.canRedeemToken();
    if (canRedeem) {
      this.available -= 1;
    }
    return canRedeem;
  }
}
