import nonCriticalException from '../exceptionLogger';
import { getStripe } from '../stripeV3';

/** Fetches a paymnt intent by its client secret and tell us if its status is requires_capture */
export async function paymentIntentRequiresCapture(clientSecret: string): Promise<boolean> {
  const stripe = await getStripe();
  let paymentIntent: any = {};

  try {
    // and the official typings track the latest API version.
    // We're still on API Version 2016-02-03.
    const result = await stripe.retrievePaymentIntent(clientSecret);
    // @ts-expect-error We don't have typings for Stripe
    if (result.err) {
      // @ts-expect-error We don't have typings for Stripe
      throw new Error(result.err);
    }

    paymentIntent = result.paymentIntent;
  } catch (err) {
    nonCriticalException(
      err,
      { message: `paymentIntentRequiresCapture() - Failed to retrieve Payment Intent: ${err}` },
    );
    return false;
  }

  if (paymentIntent.status !== 'requires_capture') {
    return false;
  }

  return true;
}
