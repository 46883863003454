// @ts-strict-ignore
export default class Deferred<T> {
  #isFulfilled: boolean;
  promise: Promise<T>;
  resolve: (arg?: T | PromiseLike<T>) => void;
  reject: (err: any) => any;

  get isFulfilled() {
    return this.#isFulfilled;
  }

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = (value: T | PromiseLike<T>) => {
        this.#isFulfilled = true;
        resolve(value);
      };
      this.reject = (reason?: any) => {
        this.#isFulfilled = true;
        reject(reason);
      };
    });
  }
}
