import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BrandColor } from '@styleseat/brand';
import { Box } from '@styleseat/ui';

import Analytics from '../../../modules/analytics';
import nonCriticalException from '../../../modules/exceptionLogger';
import StateLink from '../../StateLink';
import ButtonLoadingAnim from '../ButtonLoadingAnimation/ButtonLoadingAnimation';
import Button from './Button';

export const APPEARANCE_OPTIONS = [
  'danger',
  'link',
  'link-accent',
  'link-accent-lowercase',
  'link-lowercase',
  'link-no-underline',
  'none',
  'primary',
  'primary-neue',
  'primary-fresh',
  'text-light',
  'outline',
  'outline-thin',
  'text-primary',
  'text-light-thin',
  'text',
  'select',
  'silent',
  'select-form-field',
];

export const SIZE_OPTIONS = [
  'small',
  'medium',
  'medium-wide',
  'large',
  'x-large',
];
export const DEFAULT_APPEARANCE = 'primary';
export const DEFAULT_SIZE = 'large';

/**
 * Official buttons and links for StyleSeat UI.
 * Can also be used in angular via react-button directive.
 *
 *   Basic button (defaults to large primary teal button):
 *     <Button text="My Button" onClick={handler} />
 *
 *   Small button (size can be small/medium/large):
 *     <Button text="My Button" size="small" onClick={handler} />
 *
 *   Disabled button:
 *     <Button text="My Button" disabled={true} onClick={handler} />
 *
 *   Text only primary (navy) button:
 *     <Button text="My Button" appearance="text-primary" onClick={handler} />
 *
 *   Text only light (charcoal) button:
 *     <Button text="My Button" appearance="text-light" onClick={handler} />
 *
 *   Content as child elements
 *     <Button onClick={handler}>
 *       My Button
 *     </Button>
 *
 *   Pass contextual data to handler without using an inner function expression
 *     {buttons.map(({ id, text }) => {
 *       <Button key={id} text={text} onClick={handler}, clickParam={id} />
 *     })
 */
export default class ButtonContainer extends React.Component {
  static propTypes = {
    /** Button text. */
    text: PropTypes.string,
    /** Element which should be the content of the button. */
    children: PropTypes.any,
    /** Button size */
    size: PropTypes.oneOf(SIZE_OPTIONS),
    /** @deprecated Button style (Deprecated) */
    style: PropTypes.oneOfType([
      PropTypes.object,
      // NOTE: style as a string value is considered DEPRECATED, use appearance prop instead
      PropTypes.oneOf(APPEARANCE_OPTIONS),
    ]),
    /** Button appearance */
    appearance: PropTypes.oneOf(APPEARANCE_OPTIONS),
    /** Keep the button prepetually in its hover/active state */
    active: PropTypes.bool,
    /** Disable button */
    disabled: PropTypes.bool,
    /** Proxy for normal HTML button type attr */
    type: PropTypes.oneOf([
      'button',
      'submit',
    ]),
    /** Additional class names to apply to the button. */
    className: PropTypes.string,
    /** Click handler */
    onClick: PropTypes.func,
    /** Optional contextual value to be passed to onClick */
    clickParam: PropTypes.any,
    includeClickEvent: PropTypes.any,
    /** Optional determines if the onClick handler stops the propagation of the click event. */
    stopPropagation: PropTypes.bool,
    /** StateLink to route */
    link: PropTypes.string,
    /** Params for StateLink */
    linkParams: PropTypes.object,
    /** Either a string or an object used to track the click to mixpanel. See doc above */
    clickTracking: PropTypes.oneOfType([
      /** The name of the mixpanel event to track. */
      PropTypes.string,
      /** An object with `name` and `params` values */
      PropTypes.shape({
        name: PropTypes.string,
        params: PropTypes.object,
      }),
    ]),
    buttonRef: PropTypes.any,
    /** A direct link URL */
    href: PropTypes.string,
    /** The target to specify when opening a link, if any. e.g. `_blank` */
    linkTarget: PropTypes.string,
    /** True to show the loading indicator, or a string to show the loading indicator and replace
     * the text */
    loading: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    loaderColor: PropTypes.string,
    /**
     * A unique ID used for querying during unit testing
     */
    testID: PropTypes.string,
  };

  static defaultProps = {
    text: null,
    size: DEFAULT_SIZE,
    style: undefined,
    appearance: undefined,
    disabled: false,
    active: false,
    type: 'button',
    className: '',
    onClick: () => { },
    clickParam: undefined,
    includeClickEvent: undefined,
    stopPropagation: false,
    children: null,
    link: '',
    linkParams: {},
    buttonRef: undefined,
    clickTracking: undefined,
    href: undefined,
    loading: false,
    linkTarget: undefined,
    testID: null,
    loaderColor: BrandColor.WhiteWash,
  };

  // Disables the link click
  static disableLink = evt => {
    evt.preventDefault();
  };

  // Handle tracking for the button element
  // (handled internally for links)
  handleClick = event => {
    const {
      clickTracking,
      onClick,
      clickParam,
      includeClickEvent,
      stopPropagation,
      loading,
    } = this.props;

    if (loading) {
      return;
    }

    if (clickTracking) {
      try {
        let name = clickTracking;
        let params = {};

        if (typeof clickTracking === 'object' && clickTracking.name) {
          ({ name } = clickTracking);
          params = clickTracking.params || {};
        }

        Analytics.track(name, params);
      } catch (e) {
        console.error(e);
        nonCriticalException(e);
      }
    }

    if (stopPropagation) {
      event.stopPropagation();
    }

    if (typeof onClick === 'function') {
      const args = typeof clickParam !== 'undefined' ? [clickParam] : [];
      if (includeClickEvent) {
        args.push(event);
      }
      onClick(...args);
    }
  };

  // Calls onClick with clickParam
  // This falls back to sending `evt` for backwards compatibility
  linkClick = evt => {
    const { onClick, clickParam } = this.props;
    onClick(clickParam || evt);
  };

  appearanceType = () => {
    let determinedAppearance = DEFAULT_APPEARANCE;

    const { appearance, style } = this.props;

    // backwards compatibility check for deprecated prop
    if (typeof style === 'string' && style) {
      determinedAppearance = style;
    }

    if (appearance) {
      determinedAppearance = appearance;
    }

    return determinedAppearance;
  };

  render() {
    const {
      children,
      text,
      size,
      disabled,
      active,
      onClick,
      clickParam,
      className,
      type,
      link,
      linkParams,
      clickTracking,
      buttonRef,
      href,
      loading,
      style,
      linkTarget,
      testID,
      loaderColor,
    } = this.props;

    const disableLink = evt => {
      evt.preventDefault();
    };

    const ctaText = (typeof loading === 'string') ? loading : (text || children);
    const appearance = this.appearanceType();

    const allClassNames = classNames(
      size,
      appearance,
      className,
      {
        'is-loading': loading,
        'ss-button': appearance !== 'silent',
      },
    );

    const linkOnClick = (loading) ? disableLink : (onClick && this.linkClick);

    return (
      (link || href)
        ? (
          <StateLink
            href={href}
            to={link}
            params={linkParams}
            className={allClassNames}
            clickTracking={clickTracking}
            onClick={linkOnClick}
            clickParam={clickParam}
            disabled={disabled}
            target={linkTarget}
            style={typeof style !== 'string' ? style : null}
            testID={testID}
          >
            {ctaText}
            {loading && (
              <React.Fragment>
                <Box width={8} />
                <ButtonLoadingAnim color={loaderColor} />
              </React.Fragment>
            )}
          </StateLink>
        ) : (
          <Button
            ref={buttonRef}
            disabled={disabled}
            onClick={this.handleClick}
            size={size}
            active={active}
            appearance={appearance}
            loading={!!loading}
            style={typeof style !== 'string' ? style : null}
            className={className}
            type={type}
            testID={testID}
          >
            {ctaText}
            {loading && (
              <React.Fragment>
                <Box width={8} />
                <ButtonLoadingAnim color={loaderColor} />
              </React.Fragment>
            )}
          </Button>
        )
    );
  }
}
