// @ts-strict-ignore
import moment from 'moment';
import { stateManager } from './user/stateManager';
import { NCD_ATTRIBUTION_KEY_PREFIX, NCD_ATTRIBUTION_WINDOW_HOURS } from './newClientDelivery.constants';

export type NCDAttribution = {
  ncd_source?: string;
  ncd_term?: string;
  hours_into_attribution_window: number;
  booked_within_attribution_window: boolean;
  search_id: string | undefined;
  seo_referrer?: string;
};

type SerializedAttribution = {
  searchId: string | undefined;
  viewedTimestamp: number;
  proId: string;
  ncdParams: {
    ncd_source: string;
    ncd_term: string;
  };
  seoReferrer?: string;
};

function processSerializedNCDAttribution(
  storedAttribution: SerializedAttribution | null,
  providerId: string | number,
): NCDAttribution {
  if (
    storedAttribution
     && Number(storedAttribution.proId) === Number(providerId)
  ) {
    // add NCD attribution window hours to the time the NCD was determined
    const {
      searchId,
      viewedTimestamp,
      ncdParams,
      seoReferrer,
    } = storedAttribution;
    const cutOffTime = viewedTimestamp + (1000 * 60 * 60 * NCD_ATTRIBUTION_WINDOW_HOURS);
    const withinNCDAttributionWindow = Date.now() < cutOffTime;
    const hoursIntoAttributionWindow = moment(Date.now()).diff(moment(viewedTimestamp), 'hours');

    if (withinNCDAttributionWindow) {
      const storedNCDParams = {
        hours_into_attribution_window: hoursIntoAttributionWindow,
        booked_within_attribution_window: true,
        search_id: searchId,
        ...ncdParams,
      } as NCDAttribution;
      if (seoReferrer) {
        storedNCDParams.seo_referrer = seoReferrer;
      }
      return storedNCDParams;
    }
    return {
      hours_into_attribution_window: hoursIntoAttributionWindow,
      booked_within_attribution_window: false,
      search_id: searchId,
    };
  }
  return {} as NCDAttribution;
}

/**
 * Since this file relies on stateManager there is a circular dependency
 * when it is used from within a redux model, therefore it needs to be
 * dynamically imported in that model, since newClientDelivery.js imports
 * angular things, dynamically importing that file directly breaks the
 * angular jasmine test suite, the simplest solution is separating this
 * out into its own file!!
 *
 * Gets recommended pro NCD information from localStorage or user state
 * @param {number} providerId - the provider ID
 * @return {promise} the recommended pro NCD info
 */
export function getPriorNCDAttribution(
  providerId: string | number,
): Promise<NCDAttribution> {
  const key = `${NCD_ATTRIBUTION_KEY_PREFIX}-${providerId}`;

  const localStorageAttribution = JSON.parse(localStorage.getItem(key));
  if (localStorageAttribution) {
    return Promise.resolve(processSerializedNCDAttribution(localStorageAttribution, providerId));
  }
  return stateManager.getActionValue(key)
    .then(userStateData => processSerializedNCDAttribution(userStateData, providerId));
}
