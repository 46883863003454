// @ts-strict-ignore
import _ from 'underscore';
import { createModel } from '@rematch/core';
import { getProvider, PrivilegedProvider } from '../../api/Providers';
import { getServiceGroupColors, ServiceGroupColor } from '../../api/Providers/ServiceGroups/Colors';
import {
  createServiceV2,
  deleteService,
  editService,
  getAllServices,
  getServiceById,
  ProviderService,
  ProviderServiceWriteOnly,
} from '../../api/Providers/Services';
import { getStdCategories, StdCategory } from '../../api/StdServiceCategories';
import nonCriticalException from '../../modules/exceptionLogger';
import type { RootModel } from '../models';
import { getServiceGroups, ProviderServiceGroup } from '../../api/Providers/ServiceGroups';
import { fetchDepositPolicySettings } from '../../api/Providers/DepositPolicy';

type LandPayload = {
  serviceId?: number;
  providerId: number;
  includeAddOns?: boolean;
};

type EditServicePayload = {
  providerId: number;
  service: Partial<ProviderService>;
};

type CreateServicesPayload = {
  providerId: number;
  services: Partial<ProviderServiceWriteOnly>[];
};

type OnLandingPayload = {
  providerId: number;
};

type OnLandedPayload = {
  providerId: number;
  service?: ProviderService;
  groups?: ProviderServiceGroup[];
  colors?: ServiceGroupColor[];
  stdCategories?: StdCategory[];
  provider?: PrivilegedProvider;
  allServices?: ProviderService[];
  enableRequireDeposits: boolean;
};

type OnServiceSavedPayload = {
  service: Partial<ProviderService>;
  providerId: number;
};

type OnServicesCreatedPayload = {
  services: ProviderService[];
  providerId: number;
};

export interface ServiceMenuData {
  services: Record<number, ProviderService>;
  loading: boolean;
}

export interface AddServiceData {
  loading: boolean;
  provider: PrivilegedProvider | null;
  allServices: ProviderService[] | null;
  selected_services: ProviderService[] | null;
  stdCategories: StdCategory[] | null;
  enable_require_deposits: boolean;
}

export interface EditServiceData {
  groups: ProviderServiceGroup[];
  service: ProviderService;
  colors: ServiceGroupColor[];
  loading: boolean;
  enable_require_deposits: boolean;
}

export type ProviderManageServicesState = {
  loading: boolean;
  error?: any;
  groups: ProviderServiceGroup[] | null;
  service: Partial<ProviderService> | null;
  selected_services?: ProviderService[] | null;
  allServices: ProviderService[] | null;
  stdCategories: StdCategory[] | null;
  groupsById: {
    [id: number]: ProviderServiceGroup;
  } | null;
  colors: ServiceGroupColor[] | null;
  enable_require_deposits: boolean;
  providerId: number | string | null;
  provider: PrivilegedProvider | null;
};

const getInitialState = (): ProviderManageServicesState => ({
  error: null,
  groups: null,
  service: null,
  allServices: null,
  stdCategories: null,
  groupsById: null,
  colors: null,
  providerId: null,
  provider: null,
  loading: true,
  enable_require_deposits: false,
});

/**
 * Rematch model for the provider side create/edit service screen(s)
 */
export const providerManageServices = createModel<RootModel>()({
  name: 'providerManageServices',

  state: getInitialState(),

  reducers: {
    'user/logout': () => getInitialState(),

    onLanding(
      state: ProviderManageServicesState,
      payload: OnLandingPayload,
    ): ProviderManageServicesState {
      const {
        providerId,
      } = payload;

      return {
        ...state,
        loading: true,
        providerId,
      };
    },

    onLanded(
      state: ProviderManageServicesState,
      payload: OnLandedPayload,
    ): ProviderManageServicesState {
      const {
        providerId,
        service,
        colors,
        groups,
        allServices,
        stdCategories,
        provider,
      } = payload;

      // in case data is still coming for a previous provider we want to ignore it
      if (state.providerId !== providerId) return state;

      return {
        ...state,
        loading: false,
        groups,
        groupsById: _.indexBy(groups, 'id'),
        service,
        stdCategories,
        allServices,
        provider,
        colors,
        enable_require_deposits: payload.enableRequireDeposits,
      };
    },

    onServiceSaved(
      state: ProviderManageServicesState,
      payload: OnServiceSavedPayload,
    ): ProviderManageServicesState {
      const { service } = payload;

      return {
        ...state,
        service,
        loading: false,
      };
    },

    onMultipleServicesCreated(
      state: ProviderManageServicesState,
      payload: OnServicesCreatedPayload,
    ) {
      const { services } = payload;

      return {
        ...state,
        selected_services: services,
      };
    },

    onServicesCreated(
      state: ProviderManageServicesState,
      payload: OnServicesCreatedPayload,
    ): ProviderManageServicesState {
      const { services } = payload;

      return {
        ...state,
        service: services[0],
      };
    },

    onServiceDeleted(
      state: ProviderManageServicesState,
      serviceId?: number,
    ): ProviderManageServicesState {
      const allServices = state.allServices ?? [];
      const selectedServices = state.selected_services ?? [];

      return {
        ...state,
        service: null,
        allServices: serviceId
          ? allServices.filter(service => service.id !== serviceId)
          : allServices,
        selected_services: serviceId
          ? selectedServices.filter(service => service.id !== serviceId)
          : selectedServices,
      };
    },

    onLoadError(
      state: ProviderManageServicesState,
      payload: { providerId: number; error },
    ): ProviderManageServicesState {
      const {
        error,
      } = payload;

      return {
        ...getInitialState(),
        loading: false,
        error,
      };
    },

    onSaveError(
      state: ProviderManageServicesState,
      payload: { providerId: number; error },
    ): ProviderManageServicesState {
      const {
        error,
      } = payload;

      return {
        ...state,
        loading: false,
        error,
      };
    },
  },

  effects: dispatch => ({
    /**
     * Called when the provider lands on the "add a new service" page
     * @param {LandPayload} payload
     * @returns {Promise<void>}
     */
    landOnMenu: async (payload: LandPayload) => {
      const { providerId } = payload;
      const includeAddOns = payload.includeAddOns || false;
      dispatch.providerManageServices.onLanding({ providerId });

      try {
        const [
          provider,
          groups,
          allServices,
          depositPolicyResult,
        ] = await Promise.all([
          getProvider(providerId) as Promise<PrivilegedProvider>,
          getServiceGroups({
            providerId,
            serviceFilter: 'all',
            size: 'all',
          }),
          getAllServices({
            providerId, size: 'all', includeAddOns,
          }),
          fetchDepositPolicySettings(providerId).catch(() => null),
        ]);

        const services = allServices.results.map(service => ({
          ...service,
          total_duration_minutes: service.duration_minutes
            + service.finishing_time_minutes
            + service.processing_time_minutes,
        }));

        dispatch.providerManageServices.onLanded({
          providerId,
          provider,
          groups: groups.results,
          allServices: services,
          enableRequireDeposits: !!depositPolicyResult?.policy,
        });
      } catch (error) {
        nonCriticalException(error);
        dispatch.providerManageServices.onLoadError({ providerId, error });
      }
    },
    /**
     * Called when the provider lands on the "add a new service" page
     * @param {LandPayload} payload
     * @returns {Promise<void>}
     */
    landOnAdd: async (payload: LandPayload) => {
      const { providerId, serviceId } = payload;
      const includeAddOns = payload.includeAddOns || false;
      dispatch.providerManageServices.onLanding({ providerId });

      try {
        const [
          service,
          provider,
          allServices,
          groups,
          depositPolicyResult,
        ] = await Promise.all([
          serviceId ? getServiceById(providerId, serviceId) : null,
          getProvider(providerId) as Promise<PrivilegedProvider>,
          getAllServices({
            providerId, size: 'all', includeAddOns,
          }),
          getServiceGroups({
            providerId,
            serviceFilter: 'all',
            size: 'all',
          }),
          fetchDepositPolicySettings(providerId).catch(() => null),
        ]);

        // Return Stylist values for Professional
        const professionFieldGroup = provider?.field_group === 6 ? 1 : provider?.field_group;

        const stdCategories = await getStdCategories({
          professionId: professionFieldGroup,
          size: 'all',
        });

        dispatch.providerManageServices.onLanded({
          providerId,
          provider,
          service,
          stdCategories: stdCategories.results,
          allServices: allServices.results,
          groups: groups.results,
          enableRequireDeposits: !!depositPolicyResult?.policy,
        });
      } catch (error) {
        nonCriticalException(error);
        dispatch.providerManageServices.onLoadError({ providerId, error });
      }
    },

    /**
     * Called when the provider lands on the "edit service" page
     * @param {LandPayload} payload
     * @returns {Promise<void>}
     */
    landOnEdit: async (payload: LandPayload, rootState) => {
      const { providerId, serviceId } = payload;
      dispatch.providerManageServices.onLanding({ providerId });
      const { allServices } = rootState.providerManageServices;
      try {
        const [
          service,
          groups,
          colors,
          provider,
          depositPolicyResult,
        ] = await Promise.all([
          getServiceById(providerId, serviceId),
          getServiceGroups({
            providerId,
            serviceFilter: 'all',
            size: 'all',
          }),
          getServiceGroupColors(providerId),
          getProvider(providerId) as Promise<PrivilegedProvider>,
          fetchDepositPolicySettings(providerId).catch(() => null),
        ]);

        // Return Stylist values for Professional
        const professionFieldGroup = provider?.field_group === 6 ? 1 : provider?.field_group;

        const stdCategories = await getStdCategories({
          professionId: professionFieldGroup,
          size: 'all',
        });

        dispatch.providerManageServices.onLanded({
          providerId,
          service,
          colors: colors.results,
          groups: groups.results,
          allServices,
          stdCategories: stdCategories.results,
          provider,
          enableRequireDeposits: !!depositPolicyResult?.policy,
        });
      } catch (error) {
        nonCriticalException(error);
        dispatch.providerManageServices.onLoadError({ providerId, error });
        throw error;
      }
    },

    /**
     * Saves changes to the provided service
     * @param {EditServicePayload} payload
     * @returns {Promise<void>}
     */
    editService: async (payload: EditServicePayload) => {
      const {
        providerId,
        service,
      } = payload;

      const servicePayload: Partial<ProviderServiceWriteOnly> = {
        ...service,
        std_service_ids: (service.std_services || []).map(s => s.id),
        // Don't ever send null/undefined padding_minutes to BE
        padding_minutes: service.padding_minutes || 0,
      };

      try {
        await editService(
          providerId,
          servicePayload,
        );

        await dispatch.providerManageServices.onServiceSaved({
          providerId,
          service: servicePayload,
        });
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServices.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    /**
     * Creates new services and saves them on the API
     * @param {CreateServicesPayload} payload
     * @returns {Promise<any>}
     */
    createServices: async (payload: CreateServicesPayload) => {
      const {
        providerId,
        services,
      } = payload;

      try {
        const newServices = await createServiceV2(
          providerId,
          services,
        );

        await dispatch.providerManageServices.onServicesCreated({
          providerId,
          services: newServices,
        });
        return newServices;
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServices.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    selectServices: async (payload: CreateServicesPayload) => {
      const {
        providerId,
        services,
      } = payload;

      try {
        const newServices = services as ProviderService[];

        await dispatch.providerManageServices.onMultipleServicesCreated({
          providerId,
          services: newServices,
        });

        return newServices;
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServices.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    deleteService: async (
      payload: {
        providerId: number;
        serviceId: number;
      },
    ) => {
      const {
        providerId,
        serviceId,
      } = payload;

      try {
        await deleteService(
          providerId,
          serviceId,
        );

        await dispatch.providerManageServices.onServiceDeleted(serviceId);
      } catch (error) {
        nonCriticalException(error);
        await dispatch.providerManageServices.onSaveError({
          error,
          providerId,
        });
        throw error;
      }
    },

    getTotalServiceCount: async providerId => {
      const totalServices = await getAllServices(
        { providerId, size: 'all' },
      );
      return totalServices?.results?.length || 0;
    },
  }),

  selectors: (slice, createSelector) => ({
    serviceMenuData: () => (
      createSelector(
        slice((state: ProviderManageServicesState) => state.allServices),
        slice((state: ProviderManageServicesState) => state.loading),
        (
          allServices: ProviderService[],
          loading: boolean,
        ): ServiceMenuData => ({
          services: Object.assign(
            {},
            ...(allServices || []).map(service => ({ [service.id]: service })),
          ),
          loading,
        }),
      )
    ),
    totalServices: () => (
      createSelector(
        slice((state: ProviderManageServicesState) => state.allServices),
        (allServices: ProviderService[]): number => (allServices || []).length,
      )
    ),
    editServiceData: () => (
      createSelector(
        slice((state: ProviderManageServicesState) => state.groups),
        slice((state: ProviderManageServicesState) => state.service),
        slice((state: ProviderManageServicesState) => state.colors),
        slice((state: ProviderManageServicesState) => state.loading),
        slice((state: ProviderManageServicesState) => state.enable_require_deposits),
        (
          groups: ProviderServiceGroup[],
          service: ProviderService,
          colors: ServiceGroupColor[],
          loading: boolean,
          enableRequireDeposits: boolean,
        ): EditServiceData => ({
          groups,
          service,
          colors,
          loading,
          enable_require_deposits: enableRequireDeposits,
        }),
      )
    ),

    addServiceData: () => (
      createSelector(
        slice((state: ProviderManageServicesState) => state.stdCategories),
        slice((state: ProviderManageServicesState) => state.allServices),
        slice((state: ProviderManageServicesState) => state.selected_services),
        slice((state: ProviderManageServicesState) => state.provider),
        slice((state: ProviderManageServicesState) => state.loading),
        slice((state: ProviderManageServicesState) => state.enable_require_deposits),
        (
          stdCategories: StdCategory[],
          allServices: ProviderService[],
          selected_services: ProviderService[],
          provider,
          loading: boolean,
          enableRequireDeposits: boolean,
        ): AddServiceData => ({
          stdCategories,
          selected_services,
          allServices,
          provider,
          loading,
          enable_require_deposits: enableRequireDeposits,
        }),
      )
    ),
  }),
});
