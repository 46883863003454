import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const ClientAppointmentBooked: SegmentEventData = {
  eventName: AnalyticEvent.AppointmentBooked,
  eventNameLegacy: 'client_appointment_booked',
  eventProperties: [
    'local_start',
    'local_end',
    'provider_id',
    'is_new_client_appointment',
    'provider_name',
    'appointment_id',
    'client_id',
    'rescheduled_from_appointment_id',
    'user_id',
    'payment_method_type',
    'appt_booked_date',
    'created_by',
    'client_type',
    'pro_name',
    'salon_name',
    'pro_image',
    'pro_url',
    'pro_rating_stars',
    'pro_ratings_count',
    'pro_location',
    'pro_signup_date',
    'service_ids',
    'coupon_used',
    'is_first_client_pro_connection',
    'smart_priced',
    'smart_price_source',
    'is_monetized_ncd',
    'is_payment_pro',
    'cost',
    'discount_amount',
    'fee_amount',
    'days_until_appt',
    'default_tip_pct',
    'utm_campaign',
    'utm_medium',
    'utm_source',
    'utm_term',
    'referrer',
    'is_lmc',
    'is_smart_priced',
    'is_par',
    'order_id',
    'value',
    'currency',
  ],
};

export default ClientAppointmentBooked;
