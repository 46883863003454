import type { IRootDispatch } from '../models';
import FeatureFlags from '../../modules/FeatureFlags';
import { FlagList } from './ABTest.types';

export async function initABTestModel(store: {
  dispatch: IRootDispatch,
}) {
  FeatureFlags.setReduxABTestModelCallback((flags: FlagList) => {
    store.dispatch.abTest.updateFlags(flags);
  });
  await FeatureFlags.getFlags();
}
