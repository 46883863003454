import { API_ROOT } from '../../config';
import { ssFetchJSON } from '../../modules/ssFetch';
import { ApiCache } from './Providers';

export enum TopProCriteriaNames {
  AverageRating = 'average_rating_past_year',
  ClientBookings = 'client_bookings_past_month',
  NcdAcceptanceRate = 'ncd_acceptance_rate_past_year',
  ProviderCancellationRate = 'provider_cancellation_rate_past_year',
}

export type TopProCriteria = {
  name: TopProCriteriaNames;
  value: string;
  top_pro: boolean;
  numerator?: string;
  denominator?: string;
};

export type TopProSummaryStats = {
  criteria: TopProCriteria[];
  month: string; // format YYYY-MM
  provider_id: string;
  top_pro: boolean;
};

export type TopProSummaryData = {
  data: TopProSummaryStats[];
};

export const getProviderTopProStats = async (
  providerId: number,
): Promise<TopProSummaryData> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/top-pro/summaries`);

  return ssFetchJSON(url.toString(), { ssCache: ApiCache });
};
