// @ts-strict-ignore
import URI from 'urijs';
import { API_ROOT } from '../../../config';
import SimpleCache from '../../../modules/SimpleCache';

import { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';
import { ProAppointment } from '../Appointments';

export type ScheduleResponse = Pick<ProAppointment, 'local_start' | 'local_end'>;

const ScheduleCache = new SimpleCache(10);

async function fetchScheduledAppointments(
  apptList: ScheduleResponse[],
  url: string,
): Promise<ScheduleResponse[]> {
  const fetchResult = await ssFetchJSON<IDRFResponse<ScheduleResponse>>(
    url,
    { ssCache: ScheduleCache },
  );

  if (fetchResult.next) {
    return fetchScheduledAppointments(
      [
        ...apptList,
        ...fetchResult.results,
      ],
      fetchResult.next,
    );
  }

  return [
    ...apptList,
    ...fetchResult.results,
  ];
}

/**
 * Fetch our schedule for the specified date range.
 *
 * The response includes (for each appointment in the date range):
 *    local_start - appointment start date in pro tz
 *    local_end - appointment end in pro tz
 *
 * @param providerId
 * @param start
 * @param end
 */
export async function fetchAllScheduledAppointments(
  providerId: number | string,
  start: string,
  end: string,
): Promise<ScheduleResponse[]> {
  const uri: URI = URI(`${API_ROOT}/api/v2.1/providers/${providerId}/schedules/scheduled`);

  uri.addQuery({
    start,
    end,
    size: 'all',
  });

  return fetchScheduledAppointments([], uri.toString());
}
