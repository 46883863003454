// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { stateManager } from '../modules/user/stateManager';

type UserStateValue = string | boolean | any[] | number | object | null;

export type UserStateHook<T = any> = [
  T,
  (value: T) => void,
  boolean,
];

/**
 * A hook to set or retrieve a value from user state object
*/
const useUserState = <T = UserStateValue>(key: string): UserStateHook<T> => {
  const [storedKey] = useState(key);
  const [result, setResult] = useState<T | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const setValue = (value: T): void => {
    stateManager.addAction(storedKey, value);
    setResult(value);
  };

  useEffect(() => {
    const fetchValue = async () => {
      const storedResult = await stateManager.getActionValue(key) as T;

      setResult(storedResult);
      setLoaded(true);
    };

    fetchValue();
  }, []);

  return [
    result,
    setValue,
    loaded,
  ];
};

export default useUserState;
