import { API_ROOT } from '../../../../config';
import ssFetch from '../../../../modules/ssFetch';

/**
 * Data as it is returned from the UpdateServiceGroupOrderView (on backend)
 */
export type UpdateOrderResponse = {
  response: 'OK';
};

/**
 * GET: provider/${providerId}/servicegroup/update_order
 * @param providerId
 * @param orderedGroupIds
 */
export function updateOrder(
  providerId: number | string,
  orderedGroupIds: number[],
) {
  const url = new URL(`${API_ROOT}/provider/${providerId}/servicegroup/update_order`);

  return ssFetch<UpdateOrderResponse>(
    url.toString(),
    {
      method: 'POST',
      form: { 'servicegroupList[]': orderedGroupIds },
      addRequestedWith: true,
    },
  );
}
