import React from 'react';
import { BrandColor } from '@styleseat/brand';
import { Box } from '@styleseat/ui';
import { Absolute } from '../../ui';
import { Z_TOP_OF_THE_WORLD } from '../../ui/utilities/zIndex';
import Image from '../Image';
import loader from '../../../../images/loading-lion-anim.gif';

export const LoadingLion: React.FCWithChildren = () => (
  <Absolute
    all={0}
    color={BrandColor.WhiteWash}
    justifyContent="center"
    alignItems="center"
    zIndex={Z_TOP_OF_THE_WORLD}
    testID="loading-lion"
  >
    <Box style={{ filter: 'invert(100%) contrast(1.1)' }}>
      <Image
        source={{
          uri: loader,
          width: 200,
          height: 200,
        }}
      />
    </Box>
  </Absolute>
);
