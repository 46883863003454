import { useMemo } from 'react';
import useParams from './useParams';

export type UtmParams = {
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_term?: string;
};

const useGetUtmParams = (): UtmParams => {
  const params = useParams();

  return useMemo<UtmParams>(() => ({
    utm_source: params?.utm_source,
    utm_campaign: params?.utm_campaign,
    utm_content: params?.utm_content,
    utm_medium: params?.utm_medium,
    utm_term: params?.utm_term,
  }), [
    params?.utm_campaign,
    params?.utm_content,
    params?.utm_medium,
    params?.utm_source,
    params?.utm_term,
  ]);
};

export default useGetUtmParams;
