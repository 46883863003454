import { API_ROOT } from '../../config';
import PaymentsState from '../PaymentsState';
import SimpleCache from '../SimpleCache';
import { ssFetchJSON } from '../ssFetch';
import { IPaymentSettingsV3 } from './PaymentSettings.types';

export const PaymentSettingsCache = new SimpleCache(5);

export function clearPaymentSettingsCache() {
  PaymentSettingsCache.clear();
}

const url = (providerId: number) => `${API_ROOT}/api/v3/providers/${providerId}/payment_settings`;
/**
 * Retrieves payment settings
  *
 * @param {number} providerId
 * @returns {Promise<IPaymentSettingsV3>}
 */
export async function fetchPaymentSettings(
  providerId: number,
): Promise<IPaymentSettingsV3> {
  const results = await ssFetchJSON<IPaymentSettingsV3>(
    url(providerId),
    { ssCache: PaymentSettingsCache },
  );
  return results;
}

/**
 * Get a pro's payments state.
 *
 * @param {number} providerId
 * @returns {Promise<PaymentsState>}
 */
export async function fetchPaymentState(
  providerId: number,
): Promise<PaymentsState> {
  return new PaymentsState(await fetchPaymentSettings(providerId));
}
