// @ts-strict-ignore
import { SegmentEventData } from './types';

import AppsFlyerInstallAttribution from './AppsFlyerInstallAttribution';
import ClientAppointmentBooked from './ClientAppointmentBooked';
import ClientPaymentModalSuccess from './ClientPaymentModalSuccess';
import ClientProCalendarViewed from './ClientProCalendarViewed';
import ClientProprofileBookClick from './ClientProprofileBookClick';
import ClientProprofileViewed from './ClientProprofileViewed';
import ClientRequestPaymentCheckoutComplete from './ClientRequestPaymentCheckoutComplete';
import ClientReviewSubmitted from './ClientReviewSubmitted';
import ClientSearchPopoverBtnClicked from './ClientSearchPopoverBtnClicked';
import ClientSearchPopoverServiceItemClicked from './ClientSearchPopoverServiceItemClicked';
import DiscountCodeApplied from './DiscountCodeApplied';
import DiscountCodeRedeemed from './DiscountCodeRedeemed';
import DiscountEnrollPageViewed from './DiscountEnrollPageViewed';
import SearchClick from './SearchClick';
import SearchResultsReceived from './SearchResultsReceived';
import TapToPaySetupCompleted from './TapToPaySetupCompleted';
import ProviderOnboardingNextBestActionClicked from './ProviderOnboardingNextBestActionClicked';
import BankAccountAnalyticsAction from './BankAccountAnalyticsAction';

const SegmentEventMap: Record<string, SegmentEventData> = {
  [AppsFlyerInstallAttribution.eventName]: AppsFlyerInstallAttribution,
  [ClientAppointmentBooked.eventName]: ClientAppointmentBooked,
  [ClientPaymentModalSuccess.eventName]: ClientPaymentModalSuccess,
  [ClientProCalendarViewed.eventName]: ClientProCalendarViewed,
  [ClientProprofileBookClick.eventName]: ClientProprofileBookClick,
  [ClientProprofileViewed.eventName]: ClientProprofileViewed,
  [ClientRequestPaymentCheckoutComplete.eventName]: ClientRequestPaymentCheckoutComplete,
  [ClientReviewSubmitted.eventName]: ClientReviewSubmitted,
  [ClientSearchPopoverBtnClicked.eventName]: ClientSearchPopoverBtnClicked,
  [ClientSearchPopoverServiceItemClicked.eventName]: ClientSearchPopoverServiceItemClicked,
  [DiscountCodeApplied.eventName]: DiscountCodeApplied,
  [DiscountCodeRedeemed.eventName]: DiscountCodeRedeemed,
  [DiscountEnrollPageViewed.eventName]: DiscountEnrollPageViewed,
  [SearchClick.eventName]: SearchClick,
  [SearchResultsReceived.eventName]: SearchResultsReceived,
  [TapToPaySetupCompleted.eventName]: TapToPaySetupCompleted,
  [ProviderOnboardingNextBestActionClicked.eventName]: ProviderOnboardingNextBestActionClicked,
  [BankAccountAnalyticsAction.eventName]: BankAccountAnalyticsAction,
};

// NOTE: AMP-345 Due to name change convention, we still have to support legacy name convention
// Should be removed once we are fully migrated to *only* using new convention
export const SegmentEventLegacyMap: Record<string, SegmentEventData> = {
  [ClientAppointmentBooked.eventNameLegacy]: ClientAppointmentBooked,
  [ClientProCalendarViewed.eventNameLegacy]: ClientProCalendarViewed,
  [ClientProprofileBookClick.eventNameLegacy]: ClientProprofileBookClick,
  [ClientProprofileViewed.eventNameLegacy]: ClientProprofileViewed,
  [ClientRequestPaymentCheckoutComplete.eventNameLegacy]: ClientRequestPaymentCheckoutComplete,
  [ClientReviewSubmitted.eventNameLegacy]: ClientReviewSubmitted,
  [DiscountCodeApplied.eventNameLegacy]: DiscountCodeApplied,
  [DiscountCodeRedeemed.eventNameLegacy]: DiscountCodeRedeemed,
  [DiscountEnrollPageViewed.eventNameLegacy]: DiscountEnrollPageViewed,
  [SearchClick.eventNameLegacy]: SearchClick,
  [SearchResultsReceived.eventNameLegacy]: SearchResultsReceived,
};

export default SegmentEventMap;
