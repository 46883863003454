import moment from 'moment';

export const sortDatasetByDate = (items: { date: moment.Moment }[]) => (
  [...items].sort((a, b) => {
    if (!a.date || !b.date) {
      return 0;
    }
    return a.date.valueOf() - b.date.valueOf();
  })
);
