import { createModel } from '@rematch/core';
import type { RootModel } from '../../../../store/models';
import type { ProviderServiceGroup } from '../../../../api/Providers/ServiceGroups';
import type { ServiceGroupColor } from '../../../../api/Providers/ServiceGroups/Colors';
import type { ProviderService } from '../../../../api/Providers/Services';
import type { ServiceMenuData } from '../../../../store/ProviderManageServices';
import type { ProviderManageServiceGroupsData } from '../../../../store/ProviderManageServiceGroups';

export type ServicePagesState = {
  loading: boolean;
};

export type LandOnServiceMenuPayload = {
  providerId: number;
  includeAddOns?: boolean;
};

export type ServiceMenuPageData = {
  services: Record<number, ProviderService>;
  serviceGroups: Record<number, ProviderServiceGroup>;
  orderedGroups: ProviderServiceGroup[];
  serviceGroupColors: ServiceGroupColor[];
  loading: boolean;
};

export const ServicePages = createModel<RootModel>()({
  name: 'servicePages',

  state: {
    loading: true,
  } as ServicePagesState,

  reducers: {
  },

  effects: dispatch => ({
    landOnServiceMenu: async (payload: LandOnServiceMenuPayload) => {
      const { providerId } = payload;
      const includeAddOns = payload.includeAddOns || false;
      dispatch.providerManageServiceGroups.landOnMenu({
        providerId,
      });
      dispatch.providerManageServices.landOnMenu({
        providerId,
        includeAddOns,
      });
    },
  }),

  selectors: (slice, createSelector) => ({
    serviceMenuData: models => (
      createSelector(
        models.providerManageServices.serviceMenuData,
        models.providerManageServiceGroups.manageServicesMenuData,
        (
          serviceMenuData: ServiceMenuData,
          serviceGroupsData: ProviderManageServiceGroupsData,
        ): ServiceMenuPageData => ({
          services: serviceMenuData.services,
          serviceGroups: serviceGroupsData.groups,
          orderedGroups: serviceGroupsData.orderedGroups,
          serviceGroupColors: serviceGroupsData.colors,
          loading: serviceMenuData.loading || serviceGroupsData.loading,
        }),
      )
    ),
  }),
});

export default ServicePages;
