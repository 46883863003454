// @ts-strict-ignore
import moment from 'moment';
import { APISubscriptionCharge, SubscriptionChargeStatus } from '../../../api/Providers/SubscriptionCharges';
import type { ICard } from '../../../store/PaymentMethods';
import { IProviderSignupSettingsData } from '../../Api.types';
import { SubscriptionStatus } from './Subscription';

export type SubscriptionCharge = Pick<APISubscriptionCharge,
'id' | 'amount_dollars' | 'external_created'> & {
  status: SubscriptionChargeStatus | 'upcoming';
  source?: string;
};

export function buildBillingHistoryItems(
  status: SubscriptionStatus,
  signupSettings: IProviderSignupSettingsData,
  isLoading: boolean,
  charges: APISubscriptionCharge[],
): SubscriptionCharge[] {
  if (isLoading) {
    return [];
  }

  const hasUpcomingCharge = status === SubscriptionStatus.Active;
  let firstHistoryItem: SubscriptionCharge;

  if (hasUpcomingCharge && signupSettings) {
    firstHistoryItem = {
      id: 0,
      external_created: moment(
        signupSettings.preview_subscription_next_charge_time,
      ).format(),
      amount_dollars: String(signupSettings.preview_subscription_next_charge_amount),
      status: 'upcoming',
    };
  }

  return [
    ...(firstHistoryItem ? [firstHistoryItem] : []),
    ...charges,
  ];
}

export function billingHistoryHasPendingPayment(
  historyItems: SubscriptionCharge[],
  paymentMethod: ICard,
): boolean {
  return historyItems.some(
    item => (
      item.status === 'pending'
      && item.source
      && item.source === String(paymentMethod.id)
    ),
  );
}
