// @ts-strict-ignore
/* eslint-disable class-methods-use-this */
/**
 * Base tracker class which provides much of the plumbing for wrapping analytics engines. To extend,
 * create a `trackEvent` function. The constructor of the child class should call the super class
 * with an ID for the tracker and, optionally, a friendly tracker name.
 */
import {
  AnalyticsUserData,
  IAnalyticsTracker,
  UTMState,
} from '../types';
import type { SanitizedProperties } from '../sanitize';
import type { DefaultProperties } from '../index';

export type TrackerProperties = DefaultProperties & SanitizedProperties;
export type PlatformProperty = 'web' | 'app';

const MILLISECONDS_PER_DAY = 1000 * 3600 * 24;
const MAX_UTM_DAYS = 14;

export default abstract class BaseTracker implements IAnalyticsTracker {
  id: string;

  env: string;

  name: string;

  isApp: boolean;

  platform: PlatformProperty;

  utmData: UTMState;

  /**
   * Creates a tracker which provides a standardized interface for analytics libraries.
   * @param {String} id The id of the tracker.
   * @param {String} [name=id] A friendly tracker name. Defaults to the id parameter value.
   * @param env
   * @param platform
   * @param isApp
   */
  protected constructor(
    id: string,
    name: string,
    env: string,
    platform: PlatformProperty,
    isApp?: boolean,
  ) {
    this.id = id;
    this.env = env;
    this.name = name || id;
    this.isApp = isApp;
    this.platform = platform;
  }

  /**
   * Performs the actual tracking call.
   * @param {String} eventName The name of the event to track
   * @param {Object} properties The properties to track
   * @param options
   * @returns {Promise} Resolves when the event has been logged.
   * @abstract
   */
  abstract trackEvent(
    eventName: string,
    properties: TrackerProperties,
    options,
  ): void | Promise<void>;

  /**
   * Track a virtual page view
   * @param {String} path Explicit path to use otherwise retrieved from location
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackPageView(path?: string) {
    // We don't want this to be abstract, just a silent no-op if a given tracker
    // doesn't need this functionality calling it shouldn't break anything.
  }

  /**
   * Called when the user's data changes, used by some trackers.
   * @param userData
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateUser(userData: AnalyticsUserData | null) {
    // We don't want this to be abstract, just a silent no-op if a given tracker
    // doesn't need this functionality calling it shouldn't break anything.
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateUTMs(utmData: UTMState | null) {
    // We don't want this to be abstract, just a silent no-op if a given tracker
    // doesn't need this functionality calling it shouldn't break anything.
  }

  /**
   * Logs the data sent to the underlying analytics engine.
   * @param {String} methodName Log a call to a tracker method.
   * @param  {...any} args Arguments sent to the tracker.
   */
  debugLog(methodName, ...args) {
    const payload = JSON.stringify(args, null, 2);
    // SS Dev tools chrome extension
    // @ts-ignore
    if (typeof window.ssDevTools !== 'undefined' && window.ssDevTools.logTracking) {
      // @ts-ignore
      window.ssDevTools.logTracking(this.name, methodName, payload);
      return;
    }

    if (this.env === 'production') {
      return;
    }

    window.console.log(`(NOT PROD) (${this.name}) ${methodName} ${payload}`);
  }

  /**
   * Checks expiration of utms and clear them if they are expired
   * @param {utmData} args data to be examined
   */
  validateUTMs() {
    const { created_at, ...utmProperties } = this.utmData;

    if (
      created_at
      && Math.ceil((Date.now() - Date.parse(created_at)) / MILLISECONDS_PER_DAY) > MAX_UTM_DAYS
    ) {
      this.utmData = {};
      return {};
    }

    return utmProperties;
  }
}
