import {
  DefaultRootState,
  shallowEqual,
  useSelector,
} from 'react-redux';
import { isEqual } from 'underscore';

export const deepCompare = <T>(left: T, right: any): boolean => {
  if (left === right) return true;
  if (shallowEqual(left, right)) return true;
  return isEqual(left, right);
};

export const useDeepSelector = <TState = DefaultRootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
): TSelected => useSelector(selector, deepCompare);
