import {
  useRef,
  useEffect,
  useState,
} from 'react';
import { BreakpointStates, Responsive } from '../modules/Responsive';

export type ResponsiveResult = {
  desktop: boolean;
  desktopNarrow: boolean;
  mobile: boolean;
  mobileNarrow: boolean;
  tablet: boolean;
  wide: boolean;
};

/**
 * A hook for getting the current responsive layout, and optionally running
 * an side effect function.
 *
 * Example:
 * ```
 * const { mobile } = useResponsiveEffect();
 * console.log(mobile ? 'Mobile!' : 'Not mobile!');
 * // or
 * useResponsiveEffect(
 *   ({ mobile }) => console.log(mobile ? 'Mobile!' : 'Not mobile!')
 * );
 *
 * @return {
 *   desktop: boolean
 *   desktopNarrow: boolean
 *   mobile: boolean
 *   tablet: boolean
 *   wide: boolean
 * }
 *
 */
export default function useResponsiveEffect(
  onResize?: (is: BreakpointStates) => void,
): BreakpointStates {
  const [is, setIs] = useState<BreakpointStates>(Responsive.is);
  const initialized = useRef(false);

  useEffect(() => Responsive.listen(newIs => {
    setIs(newIs);
  }), []);

  useEffect(() => {
    // observers always automatically call the subscribing function with the initial value
    // we already initialized the value above with useState so we ignore this initial call
    if (!initialized.current) {
      initialized.current = true;
      return;
    }

    if (typeof onResize === 'function') {
      onResize(is);
    }
  }, [
    is,
    onResize,
  ]);

  return is;
}

/**
 * An alias for `useResponsiveEffect` that reflects that the layout is returned
 */
export const useResponsive = useResponsiveEffect;
