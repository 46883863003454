import { useSelector } from 'react-redux';
import { PrivilegedProvider, PublicProvider } from '../api/Providers';
import { RootState } from '../store/models';
import store from '../store';

export function useStoredProvider(providerId: number, needsAdmin: true): PrivilegedProvider | null;

export function useStoredProvider(providerId: number): PublicProvider;

export function useStoredProvider(providerId: number, needsAdmin: false): PublicProvider | null;

export function useStoredProvider(
  providerId: number,
  needsAdmin?: boolean,
): PrivilegedProvider | PublicProvider | null {
  const provider = useSelector<RootState, PublicProvider | null>(
    store.select.providers.providerById(providerId),
  );
  const privilegedProvider = useSelector<RootState, PrivilegedProvider | null>(
    store.select.providers.privilegedProviderById(providerId),
  );

  return needsAdmin ? privilegedProvider : provider;
}
