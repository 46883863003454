import React from 'react';
import type { IRouteDestination } from '../../../../../packages/router';
import { PROVIDER_SETTINGS_PAYMENTS_ADDRESS, PROVIDER_SETTINGS_PAYMENTS_IDENTITY } from '../../../route-names';
import type { VerificationIssueKey } from '../../../store/StripeIdentityVerification.types';

export type VerificationIssue = {
  key: VerificationIssueKey;
  ctaText: string;
  dismissable: boolean;
  message: string | React.ReactNode;
  action?: IRouteDestination;
  subhead?: string | React.ReactNode;
};

export const FULL_SSN: VerificationIssue = {
  key: 'full-ssn',
  action: {
    route: PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
  },
  ctaText: 'Update Social Security Number',
  dismissable: true,
  message: 'Social security number - not valid',
};

export const LEGAL_DOC: VerificationIssue = {
  key: 'legal-doc',
  ctaText: 'Okay, I Understand',
  dismissable: false,
  message: (
    <span>
      Email a photo of your valid driver&apos;s license to
      {' '}
      <a href="mailto:verification@styleseat.com" color="inherit">verification@styleseat.com</a>
    </span>
  ),
};

export const COMPANY_EIN_SUPPORT_LINK = 'https://support.stripe.com/questions/using-irs-documentation-as-reference-when-entering-business-name-and-tax-id-number-(tin)-for-us-based-businesses';

export const COMPANY_EIN: VerificationIssue = {
  key: 'company-ein',
  action: {
    route: PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
  },
  ctaText: 'Review My Information',
  dismissable: true,
  message: 'Employer Identification number - not valid (ensure company name is correct)',
  subhead: (
    <span>
      Ensure both the company name and EIN match exactly with IRS documentation.
      {' '}
      <a href={COMPANY_EIN_SUPPORT_LINK} color="inherit">This link</a> will provide you instructions
      and examples on how to confirm the registered Employment Identification Number and the company
      name.
    </span>
  ),
};

export const VALID_ADDRESS: VerificationIssue = {
  key: 'valid-address',
  action: {
    route: PROVIDER_SETTINGS_PAYMENTS_ADDRESS,
  },
  ctaText: 'Add Mailing Address',
  dismissable: true,
  message: 'Add your mailing address - missing',
};

export const VALID_PHONE: VerificationIssue = {
  key: 'valid-phone',
  action: {
    route: PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
  },
  ctaText: 'Update Phone Number',
  dismissable: true,
  message: 'Phone number - not valid',
};

export const CATCHALL: VerificationIssue = {
  key: 'catchall',
  ctaText: 'Review My Info',
  dismissable: true,
  message: '', // unused
  subhead: (
    <span>
      It looks like there were some issues with the information you provided. Please make sure that
      your Name, Phone Number, SSN, and Date of Birth are accurate.
    </span>
  ),
  action: {
    route: PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
  },
};
