import LogRocket from 'logrocket';
import { isProduction } from '../env';
import { isCordovaTapToPayErrorPayload } from './TapToPay';

export function formatError(payload: unknown): string {
  if (isCordovaTapToPayErrorPayload(payload)) {
    return `${payload.name}: ${payload.description}${(payload.code || payload.code === 0) ? ` ErrorCode(${payload.code})` : ''}`;
  }
  return String(payload);
}

export function log(...consoleArgs: any[]) {
  if (isProduction()) {
    LogRocket.debug('TapToPayLogger', ...consoleArgs);
    return;
  }

  // eslint-disable-next-line no-console
  console.error('TapToPayLogger', ...consoleArgs); // always use error to see them in Xcode
}
