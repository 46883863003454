import { ReactNode } from 'react';
import { IGalleryImage } from '../../api/Providers/GalleryImages';
import { PublicProviderLocation } from '../../api/Providers';
import { BlobType } from '../blobUtils';

export type AnonymousShareUser = {
  is_anon: true;
};

export type AuthenticatedShareUser = {
  is_anon: false;
  first_name?: string;
  user_id: number;
  provider_id?: number;
};

/**
 * User props used to build a profile share URL.
 */
export type ShareUser = AnonymousShareUser | AuthenticatedShareUser;

export function isAnonymous(user?: ShareUser): user is AnonymousShareUser {
  return !user || user.is_anon;
}

/**
 * Provider props used to build a profile share URL.
 */
export type ShareProvider = {
  id: number;
  name: string;
  biz_name?: string;
  gallery_images?: IGalleryImage[];
  type?: string;
  locations?: PublicProviderLocation[];
};

export type ShareMessageURLParam = {
  key: string;
  value: any;
};

/**
 * Message param overrides.
 */
export type ShareMessage = {
  title?: string;
  text?: string;
  image?: string;
  url?: string;
  urlParams?: Array<ShareMessageURLParam>;
};

export enum ShareTarget {
  PROFILE = 'profile',
  SEARCH = 'search',
  PLATFORM = 'platform',
}

export enum ShareChannel {
  None = 'none',
  Download = 'download',
  Instagram = 'instagram',
  Facebook = 'facebook',
  Twitter = 'twitter',
  SMS = 'sms',
  Email = 'email',
  CopyLink = 'copy_link',
  Share = 'more',
}

export type ShareChannelConfig = {
  id: ShareChannel;
  name: string;
  icon?: any; // the result of importing directly from a static image file
  iconReplacementComponent?: ReactNode;
  showInApp?: boolean;
  showInMobile?: boolean;
  showInDesktop?: boolean;
};

export enum SocialSharingChannel {
  None = 'none',
  Instagram = 'instagram',
  FacebookAndroid = 'facebook',
  FacebookiOS = 'com.apple.social.facebook',
  TwitterAndroid = 'twitter',
  TwitteriOS = 'com.apple.social.twitter',
  SMS = 'sms',
  Email = 'email',
}

export enum SocialSharingError {
  Cancelled = 'cancelled',
  NotAvailable = 'not available',
  InvalidAttachment = 'invalid attachment',
  NoValidImageWasPassed = 'no valid image was passed',
}

/**
 * Interface describing the single argument passed to this module's share() and
 * canShare() functions.
 */
export interface IShareParams {
  /* body of post */
  text?: string;
  /* title of post */
  title?: string;
  /* Image remote URL, data URI, Blob, <img>, or <canvas> */
  image?: BlobType | string;
  /* link in post */
  url?: string | URL;
  /* Which platform to share to */
  channel?: ShareChannel;
  /* Recipient phone numbers */
  recipients?: Array<string> | null;
}

/**
 * Tuple describing the [0, 4) arguments passed to
 * plugins.socialsharing.share() and the [1, 5) arguments passed to
 * plugins.socialsharing.shareVia() and plugins.socialsharing.canShareVia().
 */
export type SocialSharingShareArgs = [
  /** body of post */
  string | null,
  /** title of post */
  string | null,
  /** data URI of post image */
  string | null,
  /** link in post */
  string | null,
  /** recipient phone numbers */
  Array<string> | null,
];

/**
 * Interface describing plugins.socialsharing.
 */
export interface ISocialSharingPlugin {
  share: (
    text: string | null,
    title: string | null,
    image: string | null,
    url: string | null,
    success: () => void,
    failure: (err: SocialSharingError | string) => void,
  ) => void,
  shareVia: (
    target: SocialSharingChannel,
    text: string | null,
    title: string | null,
    image: string | null,
    url: string | null,
    success: () => void,
    failure: (err: SocialSharingError | string) => void,
  ) => void,
  shareViaInstagram: (
    text: string | null,
    image: string | null,
    success: () => void,
    failure: (err: SocialSharingError | string) => void,
  ) => void,
  shareViaFacebook: (
    text: string | null,
    image: string | null,
    url: string | null,
    success: () => void,
    failure: (err: SocialSharingError | string) => void,
  ) => void;
  shareViaEmail: (
    message: string | null,
    subject: string | null,
    to: Array<string> | null,
    cc: Array<string> | null,
    bcc: Array<string> | null,
    files: Array<string> | string | null,
    onSuccess: (shared: boolean) => void,
    onError: (err: SocialSharingError | string) => void,
  ) => void,
  shareViaSMS: (
    message: string | null,
    recipients: Array<string> | null,
    onSuccess: (shared: boolean) => void,
    onError: (err: SocialSharingError | string) => void,
  ) => void,
  shareViaTwitter: (
    message: string | null,
    file: string | null,
    url: string | null,
    onSuccess: (shared: boolean) => void,
    onError: (err: SocialSharingError | string) => void,
  ) => void,
  canShareVia: (
    target: SocialSharingChannel,
    text: string | null,
    title: string | null,
    image: string | null,
    url: string | null,
    success: (isShareable: boolean) => void,
    failure: (err: SocialSharingError | string) => void,
  ) => void,
}

/**
 * Interface describing the data accepted by navigator.share() and
 * navigator.canShare().
 */
export interface INavigatorShareData {
  url?: string,
  text?: string,
  title?: string,
  files?: Array<File>,
}

/**
 * Interface describing the Web Share API of window.navigator.
 */
export interface INavigator {
  share: (data: INavigatorShareData) => Promise<void>,
  canShare: (data: INavigatorShareData) => boolean,
}

/**
 * A function that generates the handler function to invoke sharing for a specific channel and
 * target
 */
export type ShareChannelFactory<T> = (
  target: T,
  url: string,
  user: ShareUser,
  onSuccess: () => void,
  onFail: (err: string) => void,
  message: ShareMessage,
) => () => void;

export type ShareChannelMap = {
  [ShareTarget.PROFILE]: ShareChannelFactory<ShareProvider>,
  [ShareTarget.SEARCH]: ShareChannelFactory<ShareProvider>,
  [ShareTarget.PLATFORM]: ShareChannelFactory<null>,
};
