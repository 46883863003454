import SimpleCache from '../../../modules/SimpleCache';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { NotificationResponse } from '../../../modules/provider/Notifications/Notifications.types';

const CACHE_FEED_SECONDS = 10;
const cache = new SimpleCache(CACHE_FEED_SECONDS);

export function fetchNotifications(
  providerId: number,
  opts: { cached: boolean },
): Promise<NotificationResponse> {
  // `return_ncd` was added because a FIRE bug was introduced in FLY-3396 that
  // necessitated a BE hotfix. The fix required all appointment notifications
  // to have the property `is_new_client_delivery: false` whether or not it was.
  // The param `return_ncd` will allow the BE to know that the bug has been fixed
  // and the FE will accept correct data again.
  // `return_pro_cancelled_appts` was added because a bug (FLY-3484) was introduced
  // in FLY-3416 that necessitated a BE hotfix. The fix required that appointment notifications
  // of the type "appointment_cancelled_provider" or "appointment_cancelled_provider_no_show"
  // be filtered out, and that appointment notifications of the type "client_appt_cancel" have
  // the "is_ncd" value set to false in order to avoid showing notifications related to the
  // appointments that are not changed an NCD fee.
  return ssFetchJSON<NotificationResponse>(
    `/api/v2/providers/${providerId}/notifications?return_ncd=true&return_pro_cancelled_appts=true`,
    { ssCache: opts.cached ? cache : false },
  );
}

export function clearNotificationCache() {
  cache.clear();
}
