// @ts-strict-ignore
import LogRocket from 'logrocket';
import nonCriticalException from '../exceptionLogger';
import FeatureFlags from '../FeatureFlags';
import { TokenBucket } from '../TokenBucket';
import { log } from './TapToPayLogger';
import { PASSCODE_NOT_SET_ERROR_CODE } from '../../components/provider/checkout/TapToPayFlow/constants';
import { StripeTerminalPluginNotFoundError } from '../../store/StripeTerminal/StripeTerminalPluginNotFoundError';
import { StripeTerminalConnectionTokenError } from '../../store/StripeTerminal/StripeTerminalConnectionTokenError';
import type {
  CordovaTapToPayCollectPaymentSuccessPayload,
  CordovaTapToPayConnectionStatusPayload,
  CordovaTapToPayDetectDeviceSuccessPayload,
  CordovaTapToPayErrorPayload,
  CordovaTapToPayInitSuccessPayload,
} from '../CordovaPlugins.types';
import {
  TapToPayConnectionStatus,
  TapToPayGeolocationAuthorizationStatus,
  TapToPayPluginEvent,
} from './TapToPayPlugin.types';

export const StripeTerminalSDKMinimumIOSVersion = 17;

const STRIPE_CONNECTION_TOKEN_IGNORE_RATE_LIMIT_FLAG = 'pro_taptopay_conn_token_ignore_rate_limit_ev4232_20230417';

/**
 * Note: If a call to a plugin results includes listening in on different events through the
 * onSuccess callback that's been kept alive on the Swift side, remember to include
 * onSuccess/onFailure arguments in the corresponding method's arguments in TapToPayPlugin.
 * See the init and detectDevices methods for examples.
 */
export class TapToPayPlugin {
  private static fetchToken?: () => Promise<string>;

  // A single connect operation might request more than one connection token. Most seen so
  // far is only two, but giving short term bucket a larger capacity for margin of safety.
  private static shortTermTokenBucket = new TokenBucket(5, 10);
  private static longTermTokenBucket = new TokenBucket(100, 10 * 60);
  private static shouldAllowTokenFetch = false;

  private readonly locationId: string;
  private readonly merchantName: string;
  private readonly onBehalfOfId: string;

  constructor({
    locationId,
    merchantName,
    onBehalfOfId,
  }: {
    locationId: string;
    merchantName: string;
    onBehalfOfId: string;
  }) {
    this.locationId = locationId;
    this.merchantName = merchantName;
    this.onBehalfOfId = onBehalfOfId;
  }

  static init(
    fetchToken: () => Promise<string>,
    onSuccess: (payload: CordovaTapToPayInitSuccessPayload) => Promise<void> | void = () => {},
    onFailure: (payload?: CordovaTapToPayErrorPayload) => Promise<void> | void = () => {},
  ): void {
    if (!window?.cordovaTapToPay) {
      onFailure();
      return;
    }
    this.fetchToken = fetchToken;
    window.cordovaTapToPay.registerConnectionTokenFetchHandler(
      () => this.fetchAndDeliverToken(),
      () => {},
    );
    window.cordovaTapToPay.init(onSuccess, onFailure);
  }

  public static setShouldFetchToken(value: boolean) {
    TapToPayPlugin.shouldAllowTokenFetch = value;
  }

  public static shouldFetchToken() {
    return TapToPayPlugin.shouldAllowTokenFetch;
  }

  private static async fetchAndDeliverToken() {
    if (!window?.cordovaTapToPay) return;

    const onSuccess = () => {};
    const onFailure = () => {};

    if (!TapToPayPlugin.shouldAllowTokenFetch) {
      window.cordovaTapToPay.completeConnectionTokenFetchError(
        'shouldFetchToken is false',
        onSuccess,
        onFailure,
      );
      return;
    }

    const deliverToken = (t: string) => {
      window.cordovaTapToPay.completeConnectionTokenFetchSuccess(t, onSuccess, onFailure);
    };
    const deliverError = (e: string) => {
      window.cordovaTapToPay.completeConnectionTokenFetchError(e, onSuccess, onFailure);

      let caughtError: Error;
      try {
        // Populate the callstack
        throw new StripeTerminalConnectionTokenError(e);
      } catch (thrownError) {
        caughtError = thrownError;
      }
      log('Stripe Terminal connection token fetch failed', caughtError);
      nonCriticalException(caughtError);
      LogRocket.captureException(caughtError, {
        tags: {
          TapToPay: 'FetchConnectionToken',
        },
      });
    };

    try {
      const isRateLimited = (!this.shortTermTokenBucket.canRedeemToken()
          || !this.longTermTokenBucket.canRedeemToken())
        && !(await FeatureFlags.isEnabled(STRIPE_CONNECTION_TOKEN_IGNORE_RATE_LIMIT_FLAG));
      if (isRateLimited) {
        deliverError('Fetch rate limited');
      } else {
        this.shortTermTokenBucket.attemptRedeemToken();
        this.longTermTokenBucket.attemptRedeemToken();
        const token = await this.fetchToken?.();
        if (token) {
          deliverToken(token);
        } else {
          deliverError('Fetch returned no token');
        }
      }
    } catch (error) {
      deliverError(`${error}`);
    }
  }

  private async detectDevices(
    onSuccess: (payload: CordovaTapToPayDetectDeviceSuccessPayload) => void | PromiseLike<void>,
    onFailure?: (payload?: CordovaTapToPayErrorPayload) => void | PromiseLike<void>,
  ): Promise<CordovaTapToPayDetectDeviceSuccessPayload> {
    return new Promise<CordovaTapToPayDetectDeviceSuccessPayload>(
      (resolve, reject) => {
        if (!window?.cordovaTapToPay) {
          throw new StripeTerminalPluginNotFoundError('cordovaTapToPay not loaded');
        }

        const resolvingEvents = [
          TapToPayPluginEvent.DidFinishDiscoverReaders,
          TapToPayPluginEvent.DidCancelDiscoverReaders,
        ];
        window.cordovaTapToPay.detectDevices(
          this.locationId,
          this.merchantName,
          this.onBehalfOfId,
          (payload: CordovaTapToPayDetectDeviceSuccessPayload) => {
            onSuccess(payload);
            if (payload.event in resolvingEvents) {
              resolve(payload);
            }
          },
          (payload: CordovaTapToPayErrorPayload = { name: 'unknown error', description: '' }) => {
            onFailure?.(payload);
            reject(payload);
          },
        );
      },
    );
  }

  static async connectionStatus(): Promise<TapToPayConnectionStatus> {
    return new Promise<TapToPayConnectionStatus>(
      resolve => {
        if (!window?.cordovaTapToPay) {
          throw new StripeTerminalPluginNotFoundError('cordovaTapToPay not loaded');
        }

        window.cordovaTapToPay.connectionStatus(
          ({ payload }: CordovaTapToPayConnectionStatusPayload) => {
            resolve(Number(payload));
          },
        );
      },
    );
  }

  connectToReader(
    onSuccess: (payload: CordovaTapToPayDetectDeviceSuccessPayload) => void | PromiseLike<void>,
    onFailure?: (payload?: CordovaTapToPayErrorPayload) => void | PromiseLike<void>,
  ): void {
    // The rate limit is meant to safeguard against the Stripe SDK doing too many requests
    // on its own. We know that a new token is needed whenever a new connection is made. We
    // are generally in control of this flow. Since it will definitely fail if the token gets
    // rate limited, we reset the quota before connecting to make sure a token is available.
    TapToPayPlugin.shortTermTokenBucket.forceRefill();
    TapToPayPlugin.longTermTokenBucket.forceRefill();

    this.detectDevices(onSuccess, onFailure);
  }

  static async disconnectReader(
    force: boolean = false,
  ): Promise<void> {
    return new Promise<void>(
      (resolve, reject) => {
        if (!window?.cordovaTapToPay) {
          throw new StripeTerminalPluginNotFoundError('cordovaTapToPay not loaded');
        }

        window.cordovaTapToPay.disconnectReader(force, resolve, reject);
      },
    );
  }

  static async collectPayment(
    clientSecret: string,
    onSuccess: (payload: CordovaTapToPayCollectPaymentSuccessPayload) => void | PromiseLike<void>,
    onFailure?: (payload?: CordovaTapToPayErrorPayload) => void | PromiseLike<void>,
  ) {
    return new Promise<CordovaTapToPayCollectPaymentSuccessPayload>((resolve, reject) => {
      if (!window?.cordovaTapToPay) {
        throw new StripeTerminalPluginNotFoundError('cordovaTapToPay not loaded');
      }

      window.cordovaTapToPay.collectPayment(
        clientSecret,
        (payload: CordovaTapToPayCollectPaymentSuccessPayload) => {
          onSuccess(payload);
          resolve(payload);
        },
        (payload: CordovaTapToPayErrorPayload = { name: 'unknown error', description: '' }) => {
          onFailure?.(payload);
          reject(payload);
        },
      );
    });
  }

  static async geolocationAuthorizationStatus(): Promise<TapToPayGeolocationAuthorizationStatus> {
    return new Promise<TapToPayGeolocationAuthorizationStatus>((resolve, reject) => {
      if (!window?.cordovaTapToPay) {
        throw new StripeTerminalPluginNotFoundError('cordovaTapToPay not loaded');
      }

      window.cordovaTapToPay.locationAuthorizationStatus(
        (payload: TapToPayGeolocationAuthorizationStatus) => {
          resolve(payload);
        },
        (payload?: CordovaTapToPayErrorPayload) => {
          reject(payload ?? 'geolocationAuthorizationStatus: unknown error');
        },
      );
    });
  }

  static async openSettingsApp() {
    return new Promise<void>((resolve, reject) => {
      if (!window?.cordovaTapToPay) {
        throw new StripeTerminalPluginNotFoundError('cordovaTapToPay not loaded');
      }

      window.cordovaTapToPay.openSettingsApp(
        () => {
          resolve();
        },
        (payload?: CordovaTapToPayErrorPayload) => {
          reject(payload ?? 'openSettingsApp: unknown error');
        },
      );
    });
  }

  static async isDeviceOwnerAuthenticationConfigured(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!window?.cordovaTapToPay) {
        throw new StripeTerminalPluginNotFoundError('cordovaTapToPay not loaded');
      }

      window.cordovaTapToPay.deviceOwnerAuthenticationConfigured(payload => {
        resolve(payload.passcodeEnabled);
      }, payload => {
        if (payload.error.code !== PASSCODE_NOT_SET_ERROR_CODE) {
          reject(new Error(`deviceOwnerAuthenticationConfigured: ${payload.error.code}`));
        } else {
          resolve(payload.passcodeEnabled);
        }
      });
    });
  }
}
