// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { ModelSelectorFactories } from '@rematch/select';
import { clearPaymentSettingsCache, fetchPaymentSettings } from '../modules/provider/PaymentSettings';
import type { RootModel } from './models';
import type {
  OnLoadPayload,
  SetFieldsPayload,
  StripeIdentityVerificationState,
} from './StripeIdentityVerification.types';
import { selectors as userSelectors } from './CurrentUser.model';
import { VerificationIssueBanner, VERIFICATION_ISSUE_BANNER } from '../components/provider/StripeVerificationBanner/StripeVerificationBanners';
import {
  PaymentSettingsIdentityVerification,
  VerificationAddressType,
  VerificationStatus,
} from '../modules/provider/PaymentSettings.types';

/* Every 3 days - 3 days * 86,400 s/day * 1000 ms/s  = 259,200,000ms */
export const DISMISSED_TTL = 259200000;

const STORAGE_KEY = 'stripeIdentityVerification.lastDismissed';

export const getDefaultState = (): StripeIdentityVerificationState => ({
  providerId: null,
  isOnboarded: false,
  onCommissionPlan: false,
  isPaymentsSetupMandatory: false,
  lastDismissed: 0,
  dismissed: true,
  status: VerificationStatus.EMPTY,
  in_probation: false,
  has_address: false,
  needs_full_ssn: false,
  needs_legal_document: false,
  needs_company_ein: false,
  needs_valid_address: [],
  needs_valid_phone: false,
  errors: [],
});

/** This store model handles the appearance of the verification modals for pros with verification
 *  issues
 */
const stripeIdentityVerificationModel = createModel<RootModel>()({
  name: 'stripeIdentityVerification',
  state: getDefaultState() as StripeIdentityVerificationState,
  reducers: {
    /** Please use showModal and dismissModal instead to avoid strange and wonderful bugs */
    setDismissed(
      state: StripeIdentityVerificationState,
      dismissed: boolean,
    ): StripeIdentityVerificationState {
      return {
        ...state,
        dismissed,
        lastDismissed: Date.now(),
      };
    },

    setLastDismissed(
      state: StripeIdentityVerificationState,
      lastDismissed: number,
    ): StripeIdentityVerificationState {
      return {
        ...state,
        lastDismissed,
      };
    },

    /** Please use load() if possible */
    setFields(
      state: StripeIdentityVerificationState,
      {
        onCommissionPlan,
        isOnboarded,
        isPaymentsSetupMandatory,
        status,
        has_address,
        in_probation,
        needs_full_ssn,
        needs_legal_document,
        needs_company_ein,
        needs_valid_address,
        needs_valid_phone,
        dismissed,
      }: SetFieldsPayload,
    ): StripeIdentityVerificationState {
      return {
        ...state,
        onCommissionPlan,
        isOnboarded,
        isPaymentsSetupMandatory,
        status,
        has_address,
        in_probation,
        needs_full_ssn,
        needs_legal_document,
        needs_company_ein,
        needs_valid_address,
        needs_valid_phone,
        dismissed: dismissed ?? state.dismissed,
      };
    },

    reset() {
      return getDefaultState();
    },

    onLoad(
      state: StripeIdentityVerificationState,
      {
        providerId,
      }: OnLoadPayload,
    ) {
      // Don't entirely clear the state if we're reloading for the same provider
      if (providerId && state.providerId === providerId) {
        return {
          ...state,
          providerId,
          errors: [],
        };
      }
      return {
        ...getDefaultState(),
        providerId,
      };
    },
  },
  selectors: (slice, createSelector): ModelSelectorFactories<RootModel, Record<string, never>> => ({
    dismissed: () => slice(state => state.dismissed),

    isOnboarded: () => slice(state => state.isOnboarded),

    needsFullSsn: () => slice(state => state.needs_full_ssn),

    needsCompanyEin: () => slice(state => state.needs_company_ein),

    needsLegalDocument: () => slice(state => state.needs_legal_document),

    needsValidAddress: () => slice(state => state.needs_valid_address),

    needsValidPhone: () => slice(state => state.needs_valid_phone),

    onCommissionPlan: () => slice(state => state.onCommissionPlan),
    isOnboardedMarketingPro(models) {
      return createSelector(
        models.stripeIdentityVerification.isOnboarded,
        models.stripeIdentityVerification.onCommissionPlan,
        (isOnboarded: boolean, onCommissionPlan: boolean) => isOnboarded && onCommissionPlan,
      );
    },

    isPending: () => slice(state => state.status === VerificationStatus.PENDING),

    isUnverified: () => slice(state => state.status === VerificationStatus.UNVERIFIED),

    isPaymentsSetupMandatory: () => slice(state => state.isPaymentsSetupMandatory),

    modalVisible(models) {
      return createSelector(
        models.stripeIdentityVerification.isOnboardedMarketingPro,
        models.stripeIdentityVerification.dismissed,
        models.stripeIdentityVerification.isUnverified,
        (isOnboardedMarketingPro: boolean, isDismissed: boolean, isUnverified: boolean) => (
          isOnboardedMarketingPro && !isDismissed && isUnverified
        ),
      );
    },

    banner(models) {
      return createSelector(
        [
          models.stripeIdentityVerification.isOnboardedMarketingPro,
          models.stripeIdentityVerification.needsFullSsn,
          models.stripeIdentityVerification.needsCompanyEin,
          models.stripeIdentityVerification.needsLegalDocument,
          models.stripeIdentityVerification.needsValidAddress,
          models.stripeIdentityVerification.needsValidPhone,
          models.stripeIdentityVerification.isPending,
          models.stripeIdentityVerification.isUnverified,
        ],
        (
          onboardedMarketingPro: boolean,
          fullSsn: boolean,
          companyEin: boolean,
          legalDoc: boolean,
          validAddress: VerificationAddressType[],
          validPhone: boolean,
          isPending: boolean,
          isUnverified: boolean,
        ): VerificationIssueBanner | null => {
          if (!onboardedMarketingPro || isPending) { return null; }
          if (fullSsn) { return VERIFICATION_ISSUE_BANNER['full-ssn']; }
          if (legalDoc) { return VERIFICATION_ISSUE_BANNER['legal-doc']; }
          if (companyEin) { return VERIFICATION_ISSUE_BANNER['company-ein']; }
          if (validAddress?.length > 0) { return VERIFICATION_ISSUE_BANNER['valid-address']; }
          if (validPhone) { return VERIFICATION_ISSUE_BANNER['valid-phone']; }
          if (isUnverified) { return VERIFICATION_ISSUE_BANNER.catchall; }
          return null;
        },
      );
    },
  }),
  effects: dispatch => ({
    dismissModal() {
      dispatch.stripeIdentityVerification.setDismissed(true);
      localStorage.setItem(STORAGE_KEY, Date.now().toString());
    },

    showModal() {
      dispatch.stripeIdentityVerification.setDismissed(false);
      localStorage.setItem(STORAGE_KEY, Date.now().toString());
    },

    async calculateDismiss(payload?: undefined, rootState?): Promise<void> {
      const { stripeIdentityVerification } = rootState;
      const storageValue = localStorage.getItem(STORAGE_KEY);

      const lastDismissed = storageValue
        ? parseInt(storageValue, 10)
        : stripeIdentityVerification.lastDismissed;

      await dispatch.stripeIdentityVerification.setDismissed(
        Date.now() < lastDismissed + DISMISSED_TTL,
      );
    },

    async load(
      payload?: undefined,
      state?,
    ): Promise<PaymentSettingsIdentityVerification | null> {
      const providerId = userSelectors.getSuperAwareProviderId(state);
      dispatch.stripeIdentityVerification.onLoad({ providerId });
      if (!providerId) return null;

      clearPaymentSettingsCache();

      const {
        identity_verification: verificationData,
        on_commission_plan: onCommissionPlan,
        autocharge_approved: autochargeApproved,
        is_payments_setup_mandatory: isPaymentsSetupMandatory,
      } = await fetchPaymentSettings(providerId);
      if (!verificationData) return null;

      await dispatch.stripeIdentityVerification.setFields({
        onCommissionPlan,
        isOnboarded: !!autochargeApproved,
        isPaymentsSetupMandatory,
        ...verificationData,
      });

      await dispatch.stripeIdentityVerification.calculateDismiss();

      return verificationData;
    },
  }),
});

export default stripeIdentityVerificationModel;
