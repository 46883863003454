// @ts-strict-ignore
import moment, { Moment } from 'moment';
import { ProAppointmentListPromotionDiscount, TimeBlock } from '../../../api/Providers/Appointments';
import { ProviderService } from '../../../api/Providers/Services';
import { ProPromotion, ProPromotionDiscount } from '../../../api/Users/Appointments';
import { DateTimeStamp } from '../../../types/dateTime';
import { formatPercent } from '../../formatters/formatPercent';
import { getDiscountObjectFromTimeBlock } from '../../ProAppointmentState';
import Currency from '../../Currency';

export const TIME_BLOCK_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export type TimeBlockItem = Omit<TimeBlock, 'id'> & {
  id?: number;
  smartPriceApplied?: boolean;
  localStart: Moment;
  localEnd: Moment;
  duration: number; // minutes
  finishing: boolean;
  originalCost?: number;
  discountQuantity?: number;
  discountType?: ProPromotion['discount_type'];
};

export type PersonalApptTimeBlock = {
  booked_by: number;
  booking_method: number;
  followup_note: string;
  local_end: DateTimeStamp;
  local_start: DateTimeStamp;
  note: string;
  provider: string;
  title: string;
  type: 2;
};

/**
 * Formats the time block item discount
 * @param service The time block item to use for formatting
 * @returns Either a formatted percent or formatted currency, depending on which is applicable
 */
export function formatTimeBlockDiscount(
  service: Pick<TimeBlockItem, 'discountType' | 'discountQuantity'>,
  shortCurrency: boolean = false,
): string {
  if (service.discountType === 'percentage') {
    return formatPercent(service.discountQuantity);
  }

  return shortCurrency
    ? Currency.shortFormat(service.discountQuantity)
    : Currency.format(service.discountQuantity);
}

/**
 * Converts time blocks to time block items by adding helpful temporary properties and sorting by
 * start date.
 * @param timeBlocks The time blocks to convert
 * @returns A list of "working" time blocks with helpful temporary properties
 */
export function mapTimeBlocksToItems(
  timeBlocks: TimeBlock[] = [],
  discounts: ProAppointmentListPromotionDiscount[] = [],
): TimeBlockItem[] {
  const splitServices: Record<number, boolean> = {};
  const items: Array<TimeBlockItem> = timeBlocks.map(timeBlock => {
    const localStart = moment(timeBlock.local_start);
    const localEnd = moment(timeBlock.local_end);
    const discount = discounts.find(d => d.service === timeBlock.service);
    const result: TimeBlockItem = {
      ...timeBlock,
      id: timeBlock.id,
      smartPriceApplied: true,
      localStart: moment(timeBlock.local_start),
      localEnd: moment(timeBlock.local_end),
      duration: localEnd.diff(localStart, 'minutes'),
      finishing: false,
    };

    if (discount) {
      const discountObj = getDiscountObjectFromTimeBlock(result, discount);

      if (discountObj.discount) {
        result.originalCost = result.service_cost_cents / 100.0;
        result.service_cost_cents = discountObj.actualCost * 100;
        result.discountQuantity = discountObj.discountQuantity;
        result.discountType = discountObj.discountType;
      }
    }

    if (timeBlock.service_is_powerbooked) {
      if (splitServices[timeBlock.service]) {
        delete splitServices[timeBlock.service];
        result.finishing = true;
      } else {
        splitServices[timeBlock.service] = true;
      }
    }

    return result;
  });
  items.sort((a, b) => (a.localStart > b.localStart ? 1 : -1));

  return items;
}

/**
 * Shifts a given moment to a target date, then shifts by a number of minutes
 * @param value The moment to shift
 * @param target The moment with the date to copy into the shifted moment
 * @param deltaMinutes The number of minutes by which to shift `value`
 * @returns The shifted moment
 */
function shiftMoment(value: Moment, target: Moment, deltaMinutes: number): Moment {
  return value
    .clone()
    .year(target.year())
    .month(target.month())
    .date(target.date())
    .add(deltaMinutes, 'minutes');
}

/**
 * Updates the time-related fields of a time block to reflect the new values provided
 * @param timeBlock The time block to shift
 * @param newStart The new start date of the time block
 * @param newEnd The new end date of the time block
 * @returns The shifted time block
 */
function shiftTimeBlock(timeBlock: TimeBlockItem, newStart: Moment, newEnd: Moment): TimeBlockItem {
  const formattedStart = newStart.format(TIME_BLOCK_DATE_FORMAT);
  const formattedEnd = newEnd.format(TIME_BLOCK_DATE_FORMAT);
  return {
    ...timeBlock,
    localStart: newStart,
    localEnd: newEnd,
    local_start: formattedStart,
    local_end: formattedEnd,
    start: formattedStart,
    end: formattedEnd,
  };
}

/**
 * Removes the item at the given index, as well as related power booking blocks, from the given
 * list.
 * @param items The list from which to remove the time block
 * @param index The index in `items` to remove
 * @returns The list with the item(s) removed
 */
export function removeTimeBlockItem(
  items: Array<TimeBlockItem>,
  index: number,
): Array<TimeBlockItem> {
  if (index >= items.length - 1) {
    const firstItems = items.slice(0, index);
    return [...firstItems];
  }

  const timeBlock = items[index];
  const nextTimeblock = items[index + 1];
  const numToRemove = nextTimeblock.finishing ? 2 : 1;
  const firstItems = items.slice(0, index);
  let lastItems = items.slice(index + numToRemove);
  const minutesDiff = (
    nextTimeblock.finishing
      ? nextTimeblock.localEnd
      : timeBlock.localEnd
  ).diff(timeBlock.localStart, 'minutes');

  // shift the times of all subsequent time blocks to accommodate the inserted ones
  lastItems = lastItems.map(item => {
    const newStart = item.localStart.clone().subtract(minutesDiff, 'minutes');
    const newEnd = item.localEnd.clone().subtract(minutesDiff, 'minutes');
    return shiftTimeBlock(item, newStart, newEnd);
  });

  return [
    ...firstItems,
    ...lastItems,
  ];
}

/**
 * Shifts a list of time block items to a new date and time
 * @param items The items to shift
 * @param previousDate The previous or current date
 * @param newDate The new date
 * @returns The shifted time block items
 */
export function shiftTimeBlockItems(
  items: TimeBlockItem[],
  previousDate: Moment,
  newDate: Moment,
): TimeBlockItem[] {
  // we want to find the difference between the new time and old time, ignoring day / month, so
  // use the same day for both and swap in the old times
  const oldStart = newDate.clone()
    .hour(previousDate.hour())
    .minutes(previousDate.minutes());
  const deltaMinutes = newDate.diff(oldStart, 'minutes');

  return items.map(item => {
    const itemLocalStart = shiftMoment(item.localStart, newDate, deltaMinutes);
    const itemLocalEnd = shiftMoment(item.localEnd, newDate, deltaMinutes);
    return shiftTimeBlock(item, itemLocalStart, itemLocalEnd);
  });
}

/**
 * Adds time blocks to the given list corresponding to the service provided
 * @param items The list to add to
 * @param service The service to add
 * @param localStart The start date of the list of time blocks
 * @param duration The current length of the list of time blocks
 * @param index The index at which to add the new item(s)
 * @returns The list with item(s) added
 */
export function addService(
  items: Array<TimeBlockItem>,
  service: ProviderService,
  localStart: Moment,
  duration: number,
  index: number,
  discount?: ProPromotionDiscount,
): Array<TimeBlockItem> {
  const shiftedTimeBlock = items[index];
  let itemLocalStart = localStart.clone().add(duration, 'minutes');

  // if inserting before an existing time block, copy that time block's start time
  if (shiftedTimeBlock) {
    itemLocalStart = shiftedTimeBlock.localStart.clone();
  } else if (index > 0) {
    itemLocalStart = items[index - 1].localEnd.clone();
  }

  let itemLocalEnd = itemLocalStart.clone().add(service.duration_minutes, 'minutes');

  const primaryTimeBlockItem: TimeBlockItem = {
    service: service.id,
    service_name: service.name,
    localStart: itemLocalStart,
    localEnd: itemLocalEnd,
    local_start: itemLocalStart.format(TIME_BLOCK_DATE_FORMAT),
    local_end: itemLocalEnd.format(TIME_BLOCK_DATE_FORMAT),
    service_cost_cents: service.cost_cents,
    smartPriceApplied: false,
    finishing: false,
    abbr_name: service.name,
    duration_minutes: String(service.duration_minutes),
    service_is_powerbooked: !!service.processing_time_minutes,
    service_padding_time: service.padding_minutes,
    start: itemLocalStart.format(TIME_BLOCK_DATE_FORMAT),
    end: itemLocalEnd.format(TIME_BLOCK_DATE_FORMAT),
    duration: service.duration_minutes,
  };

  if (discount) {
    const discountObj = getDiscountObjectFromTimeBlock(primaryTimeBlockItem, discount);

    if (discountObj.discount) {
      primaryTimeBlockItem.originalCost = primaryTimeBlockItem.service_cost_cents / 100.0;
      primaryTimeBlockItem.service_cost_cents = discountObj.actualCost * 100;
      primaryTimeBlockItem.discountQuantity = discountObj.discountQuantity;
      primaryTimeBlockItem.discountType = discountObj.discountType;
    }
  }

  // create time blocks based on the provided service
  const additionalTimeBlocks: TimeBlockItem[] = [primaryTimeBlockItem];

  // if power booking, add a time block for finishing time
  if (service.processing_time_minutes) { // Power Booking
    itemLocalStart = itemLocalEnd.clone().add(service.processing_time_minutes, 'm');
    itemLocalEnd = itemLocalStart.clone().add(service.finishing_time_minutes, 'm');

    additionalTimeBlocks.push({
      ...additionalTimeBlocks[0],
      service_cost_cents: 0,
      finishing: true,
      localStart: itemLocalStart,
      localEnd: itemLocalEnd,
      local_start: itemLocalStart.format(TIME_BLOCK_DATE_FORMAT),
      local_end: itemLocalEnd.format(TIME_BLOCK_DATE_FORMAT),
      start: itemLocalStart.format(TIME_BLOCK_DATE_FORMAT),
      end: itemLocalEnd.format(TIME_BLOCK_DATE_FORMAT),
    });
  }

  const firstItems = items.slice(0, index);
  let lastItems = items.slice(index);
  const minutesDiff = itemLocalEnd.diff(additionalTimeBlocks[0].localStart, 'minutes');

  // shift the times of all subsequent time blocks to accommodate the inserted ones
  lastItems = lastItems.map(item => {
    const newStart = item.localStart.clone().add(minutesDiff, 'minutes');
    const newEnd = item.localEnd.clone().add(minutesDiff, 'minutes');
    return shiftTimeBlock(item, newStart, newEnd);
  });

  const newStuff = [
    ...firstItems,
    ...additionalTimeBlocks,
    ...lastItems,
  ];

  return newStuff;
}

/**
 * Adjusts the overall duration of a list of time blocks by extending the last non-finishing time
 * block or shortening the last non-finishing time block with sufficient extra time, then shifting
 * other time blocks as needed.
 * @param items The items to update
 * @param oldDuration The old or current duration value
 * @param newDuration The new duration value
 * @returns The updated items
 */
export function changeAggregateDuration(
  items: TimeBlockItem[],
  oldDuration: number,
  newDuration: number,
): TimeBlockItem[] {
  const minutesDiff = newDuration - oldDuration;
  const nextItems = [...items];
  // eligible time block items include non-finishing time blocks which, in the case of reduced
  // duration, are long enough to reduce by the full amount
  const eligibleItems = nextItems.filter(
    item => {
      const isSubtractingTime = minutesDiff < 0;

      // only adjust non-finishing time blocks
      if (!item.finishing) {
        // if subtracting, only reduce time on blocks that have the room
        if (isSubtractingTime) {
          return item.duration > Math.abs(minutesDiff);
        }

        return true;
      }

      return false;
    },
  );
  const lastEligibleItem = eligibleItems[eligibleItems.length - 1];

  if (lastEligibleItem) {
    const index = nextItems.indexOf(lastEligibleItem);
    const newLocalEnd = nextItems[index].localEnd.clone().add(minutesDiff, 'minutes');

    nextItems[index] = {
      ...nextItems[index],
      localEnd: newLocalEnd,
      local_end: newLocalEnd.format(TIME_BLOCK_DATE_FORMAT),
      end: newLocalEnd.format(TIME_BLOCK_DATE_FORMAT),
      duration: nextItems[index].duration + minutesDiff,
      duration_minutes: String(nextItems[index].duration_minutes + minutesDiff),
    };
    const next = nextItems[index + 1];

    // if there is a time block after the adjusted one, shift all subsequent time blocks to
    // accommodate the original adjusted time block
    if (next) {
      for (let idx = index + 1; idx < nextItems.length; idx++) {
        const item = nextItems[idx];
        const itemLocalStart = shiftMoment(item.localStart, item.localStart, minutesDiff);
        const itemLocalEnd = shiftMoment(item.localEnd, item.localStart, minutesDiff);
        nextItems[idx] = shiftTimeBlock(item, itemLocalStart, itemLocalEnd);
      }
    }

    return nextItems;
  }

  return items;
}
