import { useEffect, useState } from 'react';
import { Responsive } from '../modules/Responsive';

export const useIsMobile: () => boolean = () => {
  const [isMobile, setIsMobile] = useState(Responsive.is.mobile || Responsive.is.tablet);

  useEffect(() => Responsive.listen(is => {
    setIsMobile(is.mobile || is.tablet);
  }), []);

  return isMobile;
};

export default useIsMobile;
