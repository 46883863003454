import SimpleCache from '../../../modules/SimpleCache';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';

const FraudCache = new SimpleCache(60);

enum FraudStatus {
  NotFraud = 'Not Fraud',
  NeedReview = 'Need Review',
  FraudConfirmed = 'Manually Confirmed Fraud',
  ClearedAuto = 'Cleared Automatically',
  ClearedManual = 'Cleared Manually',
}

export type FraudResponse = {
  id: number;
  under_fraud_investigation: boolean;
  fraud_status: FraudStatus;
  automated_extra_notes: string | null;
  manual_notes: string | null;
};

export function fetchFraudStatus(
  providerId: number,
): Promise<IDRFResponse<FraudResponse>> {
  return ssFetchJSON(
    `/api/v2/providers/${providerId}/fraud`,
    { ssCache: FraudCache },
  );
}
