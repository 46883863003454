// @ts-strict-ignore
import { Place, SearchForSuggestionsResult } from '@aws-sdk/client-location';
import {
  fetchLocationSuggestions,
  fetchPlaceFromId,
  fetchPlaceSuggestionsFromPosition,
} from '../../api/thirdParty/AwsPlaceIndex';

const STATES_MAP = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS', // check
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC', // check
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export type LocationSuggestionResult = {
  address: SearchForSuggestionsResult['Text'];
  placeId: SearchForSuggestionsResult['PlaceId'];
  categories?: SearchForSuggestionsResult['Categories'];
};

export type FormattedAddress = {
  address: string;
  city: string;
  state: string;
  zipcode: string;
};

function getAddressFromPlace(place: Place): FormattedAddress {
  return {
    address: [
      place.AddressNumber,
      place.Street,
    ].filter(Boolean).join(' '),
    city: place.Municipality,
    state: STATES_MAP[place.Region],
    zipcode: place.PostalCode.split('-')[0],
  };
}

function formatResponseResults(result: SearchForSuggestionsResult): LocationSuggestionResult {
  return {
    address: result.Text.split(', United States')[0].split(', USA')[0],
    placeId: result.PlaceId,
    categories: result.Categories,
  };
}

export async function getLocationSuggestions(
  inputText: string,
  biasPosition: { latitude: number; longitude: number } | null,
  maxResults: number = 5,
  useFiltered: boolean = false,
): Promise<LocationSuggestionResult[]> {
  if (!inputText) return [];

  const biasPositionInput = biasPosition?.longitude && biasPosition?.latitude
    ? biasPosition
    : null;

  const results = await fetchLocationSuggestions(
    inputText,
    biasPositionInput,
    maxResults,
    useFiltered,
  );
  return results.map(result => formatResponseResults(result))
    .filter((item, index, self) => {
      const indexInSelf = self.findIndex(i => i.address === item.address);
      return indexInSelf === index;
    });
}

export async function getAddressFromPosition(
  position: { latitude: number; longitude: number } | null,
  useFiltered: boolean = false,
): Promise<FormattedAddress | null> {
  if (!(position?.longitude && position?.latitude)) return null;
  const results = await fetchPlaceSuggestionsFromPosition(position, useFiltered);
  if (results.length) {
    return getAddressFromPlace(results[0].Place);
  }
  return null;
}

export async function getAddressFromSuggestion(
  suggestion: LocationSuggestionResult | null,
  useFiltered: boolean = false,
): Promise<FormattedAddress | null> {
  if (!suggestion) return null;
  const result = await fetchPlaceFromId(suggestion.placeId, useFiltered);
  if (!result?.Place) return null;
  return getAddressFromPlace(result.Place);
}
