// @ts-strict-ignore
import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { PaymentOption } from './Appointments';

// See AppointmentTotalsSerializer in the API for more details.
export interface AppointmentTotals {
  adjusted_discount_amount: string; // Decimal
  balance_remaining: string; // Decimal
  balance_remaining_before_discount: string; // Decimal
  balance_remaining_before_service_cost_adjustment: string; // Decimal
  deposit_amount: string; // Decimal
  discount_amount: string | null; // Decimal
  discount_code: string | null;
  is_total_adjusted: boolean;
  pro_adjustments: string; // Decimal
  service_cost_before_discount: string; // Decimal
  service_cost_sum_less_promotions: string; // Decimal
  service_cost_with_surcharge: string; // Decimal
  subtotal: string; // Decimal
  taxes: string; // Decimal
  total: string; // Decimal
  total_before_discount: string | null; // Decimal
}

export interface AppointmentTotalsParams {
  tip?: number;
  selected_payment_type?: PaymentOption;
  product_prices?: string[];
  service_ids?: string[];
}

export interface ProviderAppointmentEditRequestParams {
  tip?: number;
  cost_override?: number;
  selected_payment_type?: PaymentOption;
  product_prices?: string[];
  service_ids?: string[];
}

export async function fetchAppointmentTotals(
  providerId: number,
  appointmentId: number,
  params: AppointmentTotalsParams = {},
): Promise<AppointmentTotals> {
  const url = new URL(`/api/v2/providers/${providerId}/appointments/${appointmentId}/view-totals`, API_ROOT);
  Object.keys(params).forEach(key => {
    if (typeof params[key] !== 'undefined') {
      if (Array.isArray(params[key])) {
        params[key].forEach((value: string) => {
          url.searchParams.append(key, value);
        });
      } else {
        url.searchParams.append(key, params[key].toString());
      }
    }
  });

  const response = await ssFetchJSON<AppointmentTotals>(url.toString());
  return response;
}

export async function fetchAppointmentEditTotals(
  providerId: number,
  appointmentId: number,
  params: ProviderAppointmentEditRequestParams = {},
): Promise<AppointmentTotals> {
  const url = new URL(`/api/v2/providers/${providerId}/appointments/${appointmentId}/recalculate-totals`, API_ROOT);
  Object.keys(params).forEach(key => {
    if (typeof params[key] !== 'undefined') {
      if (Array.isArray(params[key])) {
        if (params[key].length === 0) {
          url.searchParams.append(`${key}_empty`, true.toString());
        }

        params[key].forEach((value: string) => {
          url.searchParams.append(key, value);
        });
      } else {
        url.searchParams.append(key, params[key].toString());
      }
    }
  });

  const response = await ssFetchJSON<AppointmentTotals>(url.toString());
  return response;
}
