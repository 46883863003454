/** Styleseat Brand Colors.
 * Post in #eng-fe-design when modifying this file.
 *
 * Don't forget to update toRGBA if the value is not 'transparent', or not in RGB, RGBA, or hex
 * format.
 *
 * https://www.figma.com/file/AeCdzGXeZEREAhfFxz9Euz/Base-Components?node-id=10302%3A1681&t=QwsYB0FGKplW7l71-0
 */
export enum BrandColor {
  // ------------------------
  // Utility
  // ------------------------
  Transparent = 'transparent',
  DropShadow = 'rgba(203, 203, 203, 0.5)',
  Veil = 'rgba(18, 17, 17, 0.15)',
  DeepVeil = 'rgba(18, 17, 17, 0.75)',

  // ------------------------
  // Primary Colors
  // ------------------------
  WhiteWash = '#ffffff',
  BackInBlack = '#121111',

  // ------------------------
  // Brand Colors
  // ------------------------
  FloraLight = '#fbfaf1',
  Flora = '#d1ceae',
  FloraDark = '#737056',

  Earth = '#d7b697',
  EarthMedium = '#ad8865',
  EarthDark = '#694e34',

  Bone = '#f4ebde',
  BoneDark = '#695e4e',
  BoneLight = '#fbf7f3',

  // ------------------------
  // Grays
  // ------------------------
  Metal = '#494948',
  Ash = '#737272',
  Silver = '#c7c6c5',
  LightGrey = '#f2f1f0',
  SuperLight = '#fafafa',

  // ------------------------
  // UI Colors
  // ------------------------
  LightBlue = '#f2fafc',
  Blue = '#4c7e8a',
  DarkBlue = '#385d66',
  BlueHighlight = '#6cbcee',

  Red = '#994d4d',
  LightRed = '#fcf2f2',
  DarkRed = '#663838',
  RedHighlight = '#f85f5f',

  Green = '#667d45',
  LightGreen = '#f6faf0',
  DarkGreen = '#495931',
  GreenHighlight = '#7bc711',

  // ------------------------
  // Yellow
  // ------------------------
  Yellow = '#e1bc4d',
  LightYellow = '#faf7ed',
  YellowHighlight = '#ffd65c',

  // Usage variables
  WarningBackground = LightYellow,
  ErrorBackground = LightRed,

  // ------------------------
  // Smart Pricing
  // ------------------------
  LightPurple = '#f3f0fa',
  Purple = '#5e5085',
  DarkPurple = '#3c3159',
  PurpleHighlight = '#9575ec',

  // ------------------------
  // Other
  // ------------------------
  InstagramBlue = '#2e5e86',
  TwitterBlue = '#64cbee',
  FacebookBlue = '#1877f2',
}

/** @deprecated please use BrandColor instead */
export const colors: Record<string, BrandColor> = {
  transparent: BrandColor.Transparent,
  whiteWash: BrandColor.WhiteWash,
  blackInBlack: BrandColor.BackInBlack,
  sunshine: BrandColor.Yellow,
  spark: BrandColor.BlueHighlight,
  itsOff: BrandColor.Bone,
  blue: BrandColor.Blue,
  metal: BrandColor.Metal,
  ash: BrandColor.Ash,
  silver: BrandColor.Silver,
  lightGrey: BrandColor.LightGrey,
  superLight: BrandColor.SuperLight,
  red: BrandColor.Red,
  green: BrandColor.Green,
  lightPurple: BrandColor.LightPurple,
  purple: BrandColor.Purple,
};
