// @ts-strict-ignore
import produce from 'immer';

import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import loadMLData from '../modules/loadMLData';
import type {
  ICrossPromotedProvider,
  ICrossPromotedProviderGalleryImage,
  ICrossPromotedServiceImage,
} from '../components/shared/providerProfile/CrossPromotion';

type Entry = {
  service_id: number;
  standard_service: string;
  metro: string;
};

type Mapping = {
  [serviceId: number]: Entry;
};

export interface IChildService {
  service_name: string;
  img_url: string;
  image_id: number;
  provider_id: number;
  service_id: number;
  metro: string;
  standard_service: string;
}

export type Data = {
  metro: string;
  standardService: string;
  images: ICrossPromotedProviderGalleryImage[];
  recommendations: ICrossPromotedProvider[];
  description?: string;
  price_lower?: number;
  price_upper?: number;
  duration?: string;
  related_services?: ICrossPromotedServiceImage[];
  service_name: string;
  general_service?: boolean;
  child_services?: IChildService[];
};

type State = {
  loading: boolean;
  serviceMappings: {
    [providerId: number]: null | {
      [serviceId: number]: Entry;
    };
  };
  pagesForMetro: {
    [metro: string]: null | {
      pagesForService: {
        [standardService: string]: Data;
      };
    };
  };
};

type OnMappingLoadedPayload = {
  providerId: number;
  mapping: Mapping;
};

type OnPageLoadedPayload = {
  metro: string; standardService: string; page: Data;
};

const model = createModel<RootModel>()({
  name: 'providerServiceToPageMapping',

  state: {
    serviceMappings: {},
    pagesForMetro: {},
  } as State,

  reducers: {
    onMappingLoaded: produce<State, [OnMappingLoadedPayload]>((
      mutableState: State,
      payload,
    ) => {
      mutableState.serviceMappings[payload.providerId] = payload.mapping;
    }),
    onPageLoaded: produce<State, [OnPageLoadedPayload]>((
      mutableState: State,
      payload: OnPageLoadedPayload,
    ) => {
      const { pagesForMetro } = mutableState;
      const {
        metro, standardService, page,
      } = payload;
      pagesForMetro[metro] = pagesForMetro[metro] ?? { pagesForService: {} };
      pagesForMetro[metro].pagesForService[standardService] = page;
    }),
  },

  effects: dispatch => ({
    /**
     * Fetch the service group data for a given pro
     * @param providerId
     */
    loadPageForProviderAndService: async (
      payload: { providerId: number; serviceId: number },
      state,
    ) => {
      const { providerId, serviceId } = payload;

      if (!state.providerServiceToPageMapping.serviceMappings[providerId]) {
        const entries = await loadMLData<Entry[]>('ATL-3038', 'service_to_page', providerId);
        const mapping: Mapping = {};
        entries.forEach(entry => {
          mapping[entry.service_id] = entry;
        });
        await dispatch.providerServiceToPageMapping.onMappingLoaded({
          providerId,
          mapping,
        });
      }
      await dispatch.providerServiceToPageMapping.loadService({ providerId, serviceId });
    },

    loadPage: async (
      payload: { metro: string; standardService: string },
      state,
    ): Promise<Data> => {
      const { metro, standardService } = payload;

      const cachedPage = state.providerServiceToPageMapping
        .pagesForMetro?.[metro]
        ?.pagesForService?.[standardService];

      // if not cached, then fetch
      if (!cachedPage) {
        const page = await loadMLData<Data>('ATL-3038', 'service_pages', metro, standardService);

        page.metro = metro;
        page.standardService = standardService;

        await dispatch.providerServiceToPageMapping.onPageLoaded({
          metro,
          standardService,
          page,
        });

        return page;
      }

      return cachedPage;
    },

    loadService: async (
      payload: { providerId: number; serviceId: number },
      state,
    ) => {
      const { providerId, serviceId } = payload;
      const serviceState = (
        state.providerServiceToPageMapping.serviceMappings[providerId]?.[serviceId]
      );

      if (!serviceState) {
        return;
      }

      const { metro, standard_service: standardService }: {
        metro: string;
        standard_service: string;
      } = serviceState;

      await dispatch.providerServiceToPageMapping.loadPage({ metro, standardService });
    },
  }),
});

export const selectors = {
  pageDataForMetroAndStandardService(
    state: { providerServiceToPageMapping: State },
    { metro, standardService }: {
      metro: string;
      standardService: string;
    },
  ) {
    return state.providerServiceToPageMapping
      .pagesForMetro?.[metro]
      ?.pagesForService?.[standardService] || null;
  },
  pageDataForServiceId(
    state: { providerServiceToPageMapping: State },
    { serviceId, providerId }: {
      serviceId: number;
      providerId: number;
    },
  ) {
    const serviceState = (
      state.providerServiceToPageMapping.serviceMappings?.[providerId]?.[serviceId]
    );
    if (!serviceState) return null;

    const { metro, standard_service: standardService }: {
      metro: string;
      standard_service: string;
    } = serviceState;

    return state.providerServiceToPageMapping
      .pagesForMetro?.[metro]
      ?.pagesForService?.[standardService] || null;
  },
};

export default model;
