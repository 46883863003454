import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect, useProviderId } from '../../../../hooks';
import { IRootDispatch, RootState } from '../../../../store/models';
import { NotificationsWithUnreadCount } from '../../../../store/ProviderNotifications';
import store from '../../../../store';
import { NotificationContext } from './NotificationContext';
import { selectors } from '../../../../store/CurrentUser.model';

interface NotificationProviderProps {
  children?: React.ReactNode;
}

export const NotificationProvider: React.FCWithChildren<NotificationProviderProps> = ({
  children,
}) => {
  const providerId = useProviderId();
  const hasProviderNotifications = useSelector<RootState, boolean>(state => (providerId ? (
    selectors.hasPrivilegedAccessToProvider(state, providerId)
  ) : false));
  const dispatch = useDispatch<IRootDispatch>();
  const notificationsWithUnreadCount = useSelector<RootState, NotificationsWithUnreadCount>(
    store.select.providerNotifications.notificationsWithUnreadCount,
  );

  useAsyncEffect(async () => {
    if (hasProviderNotifications) {
      await dispatch.providerNotifications.load({
        providerId,
      });
      await dispatch.providerNotifications.startPolling({
        providerId,
      });
    } else {
      await dispatch.providerNotifications.stopPolling();
    }
  }, [
    providerId,
    hasProviderNotifications,
  ]);

  return (
    <NotificationContext.Provider value={notificationsWithUnreadCount}>
      {children}
    </NotificationContext.Provider>
  );
};
