import moment from 'moment';
import { sumDataset } from '../sum';
import { Moment } from '../../../dateUtils';
import { filterByDate } from '../filters';
import { Last30DayClientData, MasterDataSet } from '../types';

function percentChange(total: number, val: number): number {
  let diffVal = 1;
  if (total > 0) {
    diffVal = parseFloat((val / total).toFixed(2));
  }
  return Math.round(diffVal * 100);
}

export function getLast30DayClientData(
  stats: MasterDataSet<'client_counts'>,
  today: Moment = moment(),
): Last30DayClientData {
  const lastMonth = today.clone().subtract(30, 'days');
  const lastMonthData = filterByDate(
    stats,
    lastMonth,
    today,
  );
  const lastMonthSum = sumDataset(lastMonthData.client_counts);
  const total = lastMonthSum.current_new + lastMonthSum.current_returning;

  // eslint-disable-next-line no-restricted-globals
  if (total === 0 || isNaN(total)) {
    return {
      newClientPercent: 0,
      newClients: 0,
      returningClientPercent: 0,
      returningClients: 0,
    };
  }

  return {
    newClients: lastMonthSum.current_new,
    newClientPercent: percentChange(total, lastMonthSum.current_new),
    returningClients: lastMonthSum.current_returning,
    returningClientPercent: percentChange(total, lastMonthSum.current_returning),
  };
}
