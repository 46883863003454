// @ts-strict-ignore
import { createModel } from '@rematch/core';
import moment from 'moment';
import { FormattedAppointment } from '../../components/provider/appointment/NcdAcceptanceModal/formatAppointment';
import nonCriticalException from '../../modules/exceptionLogger';

import { getPayLaterEligibility } from '../../api/Providers';
import type { ApptsFromNotificationFeed } from '../../components/provider/CelebrationModal/CelebrationModal.types';
import type { RootModel, RootState } from '../models';

import { ADJUDICATED_APPOINTMENTS } from './constants';
import { loadNCCPendingClientsAppointments } from '../../modules/Api';
import { ProNCCPendingAppointment } from '../../api/Providers/Appointments';

export type Appointments = {
  appointmentsToCelebrate: ApptsFromNotificationFeed[];
};

export type NCDRulesCompliance = {
  inGoodStanding: boolean;
  feePaidCount: number;
  lastChecked: string;
};

export type NCDCState = {
  formattedAppointments: FormattedAppointment[];
  appointmentsFromNotifications: ApptsFromNotificationFeed[];
  ncdRulesCompliance: NCDRulesCompliance;
  initialized: boolean;
  nccPendingAppointments: ProNCCPendingAppointment[];
};

const defaultState = () => ({
  formattedAppointments: [],
  appointmentsFromNotifications: [],
  ncdRulesCompliance: null,
  initialized: false,
  nccPendingAppointments: [],
});

export const ncdc = createModel<RootModel>()({
  name: 'ncdc',

  state: defaultState(),

  reducers: {
    onNCDRulesComplianceUpdate: (state: NCDCState, ncdRulesCompliance: NCDRulesCompliance) => ({
      ...state,
      ncdRulesCompliance,
    }),
    onNCCPendingAppointmentsUpdate: (
      state: NCDCState,
      nccPendingAppointments: ProNCCPendingAppointment[],
    ) => ({
      ...state,
      nccPendingAppointments,
    }),
  },
  effects: dispatch => ({
    recordAdjudication: async (appointmentId: number, state: RootState) => {
      const adjudicatedAppointments = state.userState.values[ADJUDICATED_APPOINTMENTS] || {};

      dispatch.userState.addValues({
        values: {
          [ADJUDICATED_APPOINTMENTS]: {
            ...adjudicatedAppointments,
            [appointmentId]: appointmentId,
          },
        },
        preferLocalKeys: [ADJUDICATED_APPOINTMENTS],
      });
    },
    checkPayLaterEligibility: async (providerId: string | number) => {
      let eligible;
      let feePaidCount;
      const lastChecked = moment().toISOString();
      try {
        const payLaterEligibility = await getPayLaterEligibility(providerId);
        eligible = payLaterEligibility.eligible;
        feePaidCount = payLaterEligibility.feePaidCount;
      } catch {
        eligible = true;
        feePaidCount = 0;
      }

      dispatch.ncdc.onNCDRulesComplianceUpdate({
        feePaidCount,
        inGoodStanding: eligible,
        lastChecked,
      });
    },
    loadNCCPendingClientsAppointments: async (providerId: string | number) => {
      try {
        const appointments = await loadNCCPendingClientsAppointments(
          providerId,
        );
        if (appointments !== undefined) {
          dispatch.ncdc.onNCCPendingAppointmentsUpdate(appointments);
        }
      } catch (error) {
        nonCriticalException(error);
      }
    },
  }),

  selectors: (slice, createSelector) => ({
    ncdRulesCompliance() {
      return createSelector(
        slice,
        state => state.ncdRulesCompliance,
      );
    },
    nccPendingAppointments() {
      return createSelector(
        slice,
        state => state.nccPendingAppointments,
      );
    },
  }),
});
