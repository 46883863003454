// @ts-strict-ignore
import moment from 'moment';
import { PublicProvider } from '../../../api/Providers';
import ssFetch, { ssFetchJSON } from '../../ssFetch';
import {
  ProviderOnboardingDetailsResponse,
  ProviderOnboardingPromptStatus,
  ProviderOnboardingResponse,
  ProviderOnboardingStatus,
  ProviderOnboardingUpdateRequest,
  SignupCountdownInfo,
} from './types';
import nonCriticalException from '../../exceptionLogger';
import { StatusCodeError } from '../../statusCode';
import FeatureFlags from '../../FeatureFlags';
import { PROVIDER_ONBOARDING_EXPERIENCE } from '../../../components/provider/Onboarding/Tips/constants';

const CACHE_TIMEOUT_SECONDS = 5;

export const getOnboardingStatusVersion = async (): Promise<string> => {
  const providerImprovedExperienceEnabled = await FeatureFlags
    .isEnabled(PROVIDER_ONBOARDING_EXPERIENCE);

  let versionToRequest = 'v3';

  if (providerImprovedExperienceEnabled) {
    versionToRequest = 'v5';
  }

  return versionToRequest;
};

export async function fetchProviderOnboardingPromptStatus(
  providerId: number,
): Promise<ProviderOnboardingPromptStatus> {
  return ssFetchJSON(
    `/api/v2/providers/${providerId}/onboarding-prompt-status.json`,
    { ssCache: CACHE_TIMEOUT_SECONDS },
    // Some page loads trigger multiple fetches in rapid succession
  );
}

export async function fetchProviderOnboardingStatus(
  providerId: number,
): Promise<ProviderOnboardingResponse> {
  const version = await getOnboardingStatusVersion();

  return ssFetchJSON(
    `/api/v2/providers/${providerId}/onboarding-status?version=${version}`,
    { ssCache: 1 },
    // Some page loads trigger multiple fetches in rapid succession
  );
}

export async function fetchProviderOnboardingDetails(
  providerId: number,
): Promise<ProviderOnboardingDetailsResponse> {
  return ssFetchJSON(
    `/api/v2/providers/${providerId}/onboarding-details`,
    { ssCache: 1 },
    // Some page loads trigger multiple fetches in rapid succession
  );
}

/**
 * Endpoint to update the provider onboarding status for a given provider that
 * does not have side effects over the BE. This endpoint is used to update the
 * status of the onboarding process
 * @param providerId Provider ID
 * @param request  ProviderOnboardingUpdateRequest object
 * @returns response of the BE
 */
export async function updateProviderOnboardingStatus(
  providerId: number | string,
  request: ProviderOnboardingUpdateRequest,
): Promise<Response> {
  const url = `/api/v2/providers/${providerId}/onboarding-details`;
  try {
    const response = await ssFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(request),
    });

    if (!response.ok) {
      const {
        status,
        statusText,
      } = response;
      const err = new StatusCodeError(response.status);

      nonCriticalException(err, {
        extra: {
          providerId,
          status,
          statusText,
          url,
        },
      });
      throw err;
    }
    return response;
  } catch (err) {
    if (err?.status === 0) {
      throw new StatusCodeError(0);
    }

    throw err;
  }
}
export function calculatePercentComplete(steps: ProviderOnboardingPromptStatus): number {
  const completedStepsCount = Object.values(steps)
    .filter(shouldPrompt => !shouldPrompt)
    .length;
  const totalStepsCount = Object.keys(steps).length;
  return Math.floor((completedStepsCount / totalStepsCount) * 100);
}

export function calculateSignupCountdownInfo(
  provider: Pick<PublicProvider, 'creation_time'>,
  onboardingStatus?: ProviderOnboardingStatus,
): SignupCountdownInfo {
  if (!provider.creation_time) {
    return { showCountdown: false };
  }

  const status = onboardingStatus?.promptStatus;
  const hasRemainingSteps = status?.prompt_for_availability
    || status?.prompt_for_services
    || status?.prompt_for_location
    || status?.prompt_for_gallery_image;
  const cutoff = moment.utc(provider.creation_time).add(7, 'days');

  return {
    showCountdown: !!(hasRemainingSteps && cutoff.isAfter(moment.utc())),
    cutoff,
  };
}
