import { createModel } from '@rematch/core';

const DEFAULT_STATE = {};

/**
 * The auto fill component adds references to this config object.
 * We need to strip that out later.
 * */
const SearchConfig = createModel()({
  state: DEFAULT_STATE,

  reducers: {},

  effects: () => ({}),
});

export default SearchConfig;
