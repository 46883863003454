import { useCallback, useRef } from 'react';

export function useCallOnce<T extends (...args: P[]) => R, P = {}, R = any>(
  callable: T,
  cancelValue: R,
  canCall?: (...args: P[]) => boolean): T {
  const callableCalled = useRef<boolean>();

  const wrappedCallable = useCallback((...args: P[]): R => {
    if (callableCalled.current || (canCall && !canCall(...args))) {
      return cancelValue;
    }

    callableCalled.current = true;

    return callable(...args);
  }, [callable, canCall, cancelValue]) as T;

  return wrappedCallable;
}
