// @ts-strict-ignore
import LogRocket from 'logrocket';
import BaseTracker, { PlatformProperty } from '../../../../packages/analytics/trackers/BaseTracker';

export class LogRocketTracker extends BaseTracker {
  shouldTrack: boolean;

  constructor(env: string, platform: PlatformProperty) {
    super('LogRocket', 'Tracker (LogRocket)', env, platform);
  }

  /**
   * Performs the actual tracking call.
   * @param {String} eventName The name of the event to track
   * @param properties
   * @returns {Promise} Resolves when the event has been logged.
   * */
  async trackEvent(eventName: string, properties: { [key: string]: any }) {
    LogRocket.track(eventName, properties);
    this.debugLog('trackEvent', eventName, properties);
  }
}
