import { ClientCardOnFile } from '../../api/Providers/Appointments';
import { ssFetchJSON } from '../ssFetch';

/**
 * Builds the URL path which can be used to read and update a client's payment instrument(s)
 * @param clientId The ID of the client
 * @param appointmentId The ID of the appointment
 * @returns {String} The path of the endpoint, beginning with a `/`
 */
export function buildUrl(clientId: number | string, appointmentId?: number | string): string {
  let url = `/api/v1/clients/${clientId}/instruments.json`;

  if (appointmentId) {
    url += `?appointmentId=${appointmentId}`;
  }

  return url;
}

/**
 * Retrieves card information for the given client and appointment.
 * @param {Number} clientId The ID of the client
 * @param {Number} [appointmentId] The ID of the appointment
 * @yields {Array} An array of card objects.
 */
export function loadCard(
  clientId: number | string,
  appointmentId?: number | string,
): Promise<ClientCardOnFile[]> {
  return ssFetchJSON<Array<ClientCardOnFile>>(buildUrl(clientId, appointmentId));
}
