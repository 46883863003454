import { formatError } from '../../modules/TapToPay/TapToPayLogger';

export class StripeTerminalCheckoutConnectionError extends Error {
  public code?: number | string;

  constructor(error: unknown, code?: number | string) {
    super(formatError(error));
    this.name = 'StripeTerminalCheckoutConnectionError';
    this.code = code;
  }
}
