// @ts-strict-ignore
import _ from 'underscore';

import { createModel } from '@rematch/core';
import type { RootModel, RootState } from '../models';

import { loadServiceGroups } from '../../modules/provider/loadServiceGroups';
import { computeBlurb } from '../../components/shared/providerProfile/ServiceList/DefaultServices';
import { ProviderServicePromotion } from '../../api/Providers/Promotions';
import { ProviderService } from '../../api/Providers/Services';
import { ProviderServiceGroup } from '../../api/Providers/ServiceGroups';
import Promotion from '../../api/Providers/Promotions/model';
import { Assignment } from '../ABTest';
import { CLIENT_PROPROFILE_POPULAR_TAG_TEST_NAME, CLIENT_PROPROFILE_POPULAR_TAG_TEST_PERCENT } from '../../components/shared/providerProfile/constants';
import { loadCurrentPromotions } from '../../api/Providers/Promotions/Promotions';
import { IGalleryImage, getProviderGalleryImages } from '../../api/Providers/GalleryImages';
import formatProfilePhoto from '../../modules/formatters/formatProfilePhoto';

const MAX_POPULAR_SERVICE_RATIO = 0.05;
const MAX_POPULAR_SERVICES = 4;

export type Service = ProviderService & {
  tags?: Array<string>;
};

type LoadProviderOptions = {
  page?: number;
  size?: number | 'all';
};

type LoadProviderPagingData = {
  page?: number;
  size?: number | 'all';
  count: number;
  previous: string | null;
  next: string | null;
};

export type ProfileServiceGroupsPayload = {
  opts: LoadProviderOptions;
  providerId: number;
  groups: ProviderServiceGroup[];
  paging: LoadProviderPagingData;
  promotions?: Array<Promotion>;
  popularTagAssignment?: Assignment;
  serviceGallery: Array<IGalleryImage>;
};

export type ProfileServiceGroupsState = {
  error?: any;
  groups: ProviderServiceGroup[];
  servicesById: {
    [id: number]: ProviderService;
  };
  groupsById: {
    [id: number]: ProviderServiceGroup;
  };
  promotionsByServiceId?: Record<number, Array<ProviderServicePromotion>>;
  promotedServices?: Array<ProviderService>;
  paging?: LoadProviderPagingData;
  loadedWithOpts?: LoadProviderOptions;
};

export type MultiProfileServiceGroupState = {
  loading: boolean;
  [providerId: number]: ProfileServiceGroupsState | undefined;
};

/**
 * Add a default blurb to services if possible
 * @param groups List of service groups to be transformed
 */
const calculateServiceBlurbs = (
  groups: ProviderServiceGroup[],
): ProviderServiceGroup[] => groups.map(group => ({
  ...group,
  services: group.services.map(service => {
    let isBlurbDefaulted = false;
    const [defaultBlurb, blurb] = computeBlurb(
      service.name,
      service.blurb,
      service.blurb_preference,
    );

    if (blurb !== '' && blurb === defaultBlurb) {
      isBlurbDefaulted = true;
    }

    return {
      ...service,
      blurb,
      isBlurbDefaulted,
    };
  }),
}));

const attachServiceImagesToService = (
  groups: ProviderServiceGroup[],
  images: IGalleryImage[],
): ProviderServiceGroup[] => {
  const imagesByServiceId = _.groupBy(images, 'service_id');

  return groups.map(group => ({
    ...group,
    services: group.services.map(service => ({
      ...service,
      imageUrls: imagesByServiceId[service.id]
        ?.map(image => formatProfilePhoto(image.url)).slice(0, 5) || [],
    })),
  }));
};

export const profileServiceGroups = createModel<RootModel>()({
  name: 'profileServiceGroups',

  state: {
    loading: false,
  } as MultiProfileServiceGroupState,

  reducers: {
    /**
     * Store service group data for a provider
     * @param state
     * @param payload
     */
    onServiceGroupsLoaded(
      state: MultiProfileServiceGroupState,
      payload: ProfileServiceGroupsPayload,
    ): MultiProfileServiceGroupState {
      const {
        providerId,
        paging,
        popularTagAssignment,
        opts,
        promotions = [],
      } = payload;
      const serviceGallery = payload.serviceGallery || [];
      const groupsWithImages = attachServiceImagesToService(payload.groups, serviceGallery);
      const groups = calculateServiceBlurbs(groupsWithImages);
      const services: Array<Service> = _.flatten(groups.map(g => g.services));
      const servicesById = _.indexBy(services, 'id');

      if (popularTagAssignment?.isEnabled) {
        // sort the services, find the top X%, and give those a "popular" tag
        const servicesByPopularity = services
          .filter(service => typeof service.popularity_percentage === 'number')
          .sort((a, b) => (b.popularity_percentage || 0) - (a.popularity_percentage || 0));

        const upperBound = Math.max(
          Math.ceil(services.length * MAX_POPULAR_SERVICE_RATIO),
          MAX_POPULAR_SERVICES,
        );

        for (let i = 0; i < upperBound && i < servicesByPopularity.length; i++) {
          servicesById[servicesByPopularity[i].id].tags = ['popular'];
        }
      }

      const promotionsByServiceId: Record<number, Array<ProviderServicePromotion>> = {};
      const promotedServices: Array<ProviderService> = [];
      promotions.forEach(promo => {
        promo.best_services.forEach((bestServiceId: number) => {
          if (promotionsByServiceId[bestServiceId]) {
            promotionsByServiceId[bestServiceId].push(promo);
          } else {
            promotionsByServiceId[bestServiceId] = [promo];
            // don't add services that have been deleted
            if (servicesById[bestServiceId]) {
              // if we have not yet encountered this service,
              // add it to the list of promoted services
              promotedServices.push(servicesById[bestServiceId]);
            }
          }
        });
      });

      return {
        ...state,
        loading: false,
        [providerId]: {
          groups,
          servicesById,
          groupsById: _.indexBy(groups, 'id'),
          promotionsByServiceId,
          loadedWithOpts: opts,
          paging,
          promotedServices,
        },
      };
    },

    /**
     * Store error for a provider,
     * @param state
     * @param payload
     */
    onServiceLoadError(
      state: MultiProfileServiceGroupState,
      payload: { providerId: number; error: any },
    ): MultiProfileServiceGroupState {
      const {
        error,
        providerId,
      } = payload;

      return {
        ...state,
        loading: false,
        [providerId]: {
          error,
          groups: [],
          servicesById: {},
          groupsById: {},
          paging: {
            count: 0,
            previous: null,
            next: null,
          },
        },
      };
    },

    /**
     * Set loading to true
     */
    onLoadingStarted(state: MultiProfileServiceGroupState) {
      return { ...state, loading: true };
    },
  },

  effects: dispatch => ({
    /**
     * Fetch the service group data for a given pro
     * @param payload
     * @param rootState
     */
    loadProvider: async (
      payload: {
        providerId: number;
        opts?: LoadProviderOptions;
        loadPromotions?: boolean;
        isViewingEditableProfile?: boolean;
      },
      rootState: RootState,
    ) => {
      const {
        providerId,
        opts = {},
        loadPromotions,
        isViewingEditableProfile,
      } = payload;

      await dispatch.profileServiceGroups.onLoadingStarted();

      let popularTagAssignment: Assignment | undefined;
      try {
        if (!isViewingEditableProfile) {
          popularTagAssignment = await dispatch.abTest.assignTest({
            name: CLIENT_PROPROFILE_POPULAR_TAG_TEST_NAME,
            percent: CLIENT_PROPROFILE_POPULAR_TAG_TEST_PERCENT,
          });
        }
        const { loadedWithOpts = {} } = rootState.profileServiceGroups?.[providerId] || {};
        const {
          page, size, ...rest
        } = loadedWithOpts;
        const useOpts = {
          ...rest,
          ...opts,
        };

        const loadServicesGallery = await getProviderGalleryImages(providerId, {
          showServiceImageOnly: true,
        });

        const serviceGroups = await loadServiceGroups({
          id: providerId,
        }, useOpts);
        let promotions: Array<Promotion> = [];

        if (loadPromotions) {
          promotions = await loadCurrentPromotions(providerId, {});
        }

        await dispatch.profileServiceGroups.onServiceGroupsLoaded({
          providerId,
          groups: serviceGroups.results,
          promotions,
          popularTagAssignment: popularTagAssignment || undefined,
          opts: useOpts,
          paging: {
            page: opts.page,
            size: opts.size,
            count: serviceGroups.count,
            previous: serviceGroups.previous || null,
            next: serviceGroups.next || null,
          },
          serviceGallery: loadServicesGallery.results,
        });
      } catch (error) {
        await dispatch.profileServiceGroups.onServiceLoadError({
          error,
          providerId,
        });
      }
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    groupsForPro: hasProps((__, props: { providerId: number }) => (
      createSelector(
        slice,
        (state: MultiProfileServiceGroupState): ProviderServiceGroup[] => (
          state[props.providerId]?.groups || []
        ),
      )
    )),

    servicesForPro: hasProps((__, props: { providerId: number }) => (
      createSelector(
        slice(state => state[props.providerId]?.groups),
        (serviceGroups: ProviderServiceGroup[]): ProviderService[] => (
          _.flatten((serviceGroups || []).map(group => group.services), true)
        ),
      )
    )),

    servicesAndAddOnsForPro: hasProps((__, props: { providerId: number }) => (
      createSelector(
        slice(state => state[props.providerId]?.groups),
        (serviceGroups: ProviderServiceGroup[]): ProviderService[] => (
          _.flatten((serviceGroups || []).map(group => [
            ...group.services,
            ...group.add_ons,
          ]), true)
        ),
      )
    )),

    serviceToCategoryMap: hasProps((__, props: { providerId: number }) => (
      createSelector(
        slice(state => state[props.providerId]?.groups),
        (serviceGroups: ProviderServiceGroup[]): Record<number, number | null> => {
          const services = (serviceGroups || []).map(group => group.services).flat();
          const result: Record<number, number | null> = {};

          services.forEach(svc => {
            result[svc.id] = svc.std_services?.[0]?.category_id || null;
          });

          return result;
        },
      )
    )),
  }),
});
