// @ts-strict-ignore
import {
  ApiProviderCreateServiceGroupParams,
  ApiProviderSaveServiceGroupParams,
  ProviderServiceGroup,
} from '../../../api/Providers/ServiceGroups';
import { ServiceGroupColor } from '../../../api/Providers/ServiceGroups/Colors';

/**
 * Converts a partial ProviderServiceGroup into an object that can be passed in
 * to the create service group endpoint
 * @param providerId
 * @param {Partial<ProviderServiceGroup>} group
 * @param  {ServiceGroupColor[]} colors available service group colors
 */
export function serviceGroupToCreateGroupPayload(
  providerId: number,
  group: Partial<ProviderServiceGroup>,
  colors: ServiceGroupColor[],
): ApiProviderCreateServiceGroupParams {
  const match = colors.find(color => (
    group.color === color.color
  ));

  return {
    providerId,
    name: group.name,
    color_id: match ? match.id : null,
  };
}

/**
 * Converts a partial ProviderServiceGroup into an object that can be passed in
 * to the create service group endpoint
 * @param providerId
 * @param {Partial<ProviderServiceGroup>} group
 * @param  {ServiceGroupColor[]} colors available service group colors
 */
export function serviceGroupToUpdateGroupPayload(
  providerId: number,
  group: Partial<ProviderServiceGroup>,
  colors: ServiceGroupColor[],
): ApiProviderSaveServiceGroupParams {
  const match = colors.find(color => (
    group.color === color.color
  ));

  return {
    providerId,
    id: group.id,
    name: group.name,
    color_id: match ? match.id : null,
  };
}

export function getUncategorizedGroups(groups: ProviderServiceGroup[]): ProviderServiceGroup[] {
  return groups.reduce(
    (unclassifiedList, serviceGroup) => unclassifiedList.concat(
      serviceGroup.services.filter(service => !service.std_services.length),
    ),
    [],
  );
}
