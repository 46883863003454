// @ts-strict-ignore
import { createGoogleCredential, GoogleCredential } from '../../../../api/Users/GoogleCalendar';
import { getIsApp } from '../../../AppInfo';
import { getGoogleAuthAPI } from '../../../../api/thirdParty/GoogleAuth';
import { GOOGLE_AUTH_SCOPE, GOOGLE_CLIENT_ID } from '../../../../config';
import nonCriticalException from '../../../exceptionLogger';

/**
 * Launch Control has dynamic hostnames and thus cannot directly makes requests
 * to the Google API. So to workaround this, we proxy google API
 * requests through an iframe pointed to https://www.styleseat.com/m/.
 *
 * Other note: this will stop working if someday down the line
 * GOOGLE_CLIENT_ID differs between prod and LC.
 *
 */
const IFRAME_ORIGIN = 'https://www.styleseat.com';

function isLaunchControlOrigin(origin: string): boolean {
  return /^https?:\/\/[\w-]+\.env\.styleseat\.com$/.test(origin);
}

function isStyleSeatOrigin(origin: string): boolean {
  return /^https?:\/\/[\w\-.]+\.styleseat\.com$/.test(origin);
}

let googleAuth: gapi.auth2.GoogleAuth;

// On mobile web, we get the code using google's client library.
// Note: there does not seem to be a way to determine
// if the user declined or cancelled auth.
async function grantOfflineAccess(force: boolean): Promise<string> {
  const result = await googleAuth.grantOfflineAccess({
    // @ts-expect-error
    redirect_uri: 'postmessage',
    prompt: force ? 'consent' : null,
  });

  return result.code;
}

async function grantOfflineAccessViaIFrame(): Promise<string> {
  let resolve;
  let reject;
  const promise = new Promise<string>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  // add a hidden iframe to the page
  const iframe = document.createElement('iframe') as HTMLIFrameElement;

  iframe.setAttribute('sandbox', 'allow-popups allow-popups-to-escape-sandbox allow-scripts allow-same-origin');
  iframe.src = `${IFRAME_ORIGIN}/m/`;
  iframe.setAttribute('style', 'display: none;');

  document.getElementsByTagName('body')[0].appendChild(iframe);

  // if no response message in 60s, reject the promise
  const timedOutTimer = setTimeout(() => {
    reject(new Error('IFrame Timeout'));
  }, 60000);

  function responseMessageListener(event) {
    if (!isStyleSeatOrigin(event.origin)) {
      return;
    }
    if (event.data === 'ssAppLoaded') {
      iframe.contentWindow.postMessage({
        method: 'grantOfflineAccess',
        direction: 'request',
      }, IFRAME_ORIGIN);
    } else if (event.data.method === 'grantOfflineAccess') {
      if (event.data.direction === 'response') {
        clearTimeout(timedOutTimer);
        if (event.data.status === 'resolve') {
          resolve(event.data.data.code);
        } else {
          reject(new Error(event.data.data.error));
        }
      }
    }
  }

  window.addEventListener('message', responseMessageListener, false);

  promise.finally(() => {
    window.removeEventListener('message', responseMessageListener);
    iframe.remove();
  });

  return promise;
}

export async function subscribeExternalCalendar(
  userId: number | string,
  force: boolean = false,
): Promise<GoogleCredential | null> {
  const isApp = getIsApp();

  if (!isApp && !googleAuth) {
    // @ts-expect-error
    googleAuth = await getGoogleAuthAPI();
  }

  if (!isApp) {
    if (isLaunchControlOrigin(window.location.origin)) {
      // Since launch control origins are dynamic and thus do not exist
      // in the Google API configured JavaScript origins, we proxy offline
      // access requests through an iframe pointed to https://www.styleseat.com
      const code = await grantOfflineAccessViaIFrame();
      return createGoogleCredential(userId, code);
    }

    let credential: GoogleCredential;

    try {
      const code = await grantOfflineAccess(force);
      credential = await createGoogleCredential(userId, code);
    } catch (err) {
      nonCriticalException(err);
    }

    return credential;
  }

  if (window.plugins?.googleplus) {
    return new Promise((resolve, reject) => {
      const redirectUri = 'http://localhost/googleoauth2callback';
      window.plugins.googleplus.login({
        scopes: GOOGLE_AUTH_SCOPE,
        // Client ID of our Web application.
        // On Android, this MUST be included to get an idToken.
        // On iOS, it is not required.
        webClientId: GOOGLE_CLIENT_ID,
        offline: true,
      }, response => {
        createGoogleCredential(userId, response.serverAuthCode, redirectUri).then(resolve, reject);
      }, reject);
    });
  }

  throw new Error('GooglePlus plugin not available');
}
