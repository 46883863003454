/* eslint-disable no-param-reassign */
import { createModel } from '@rematch/core';

import type { RootModel } from '../models';
import { clearPaymentSettingsCache, fetchPaymentState } from '../../modules/provider/PaymentSettings';
import { ProviderCancellationPolicy } from '../../modules/Api.types';
import PaymentsState from '../../modules/PaymentsState';

export interface IPaymentsState {
  paymentsState: PaymentsState;
  loading: boolean;
}

function getInitialState(): IPaymentsState {
  return {
    paymentsState: new PaymentsState({
      autocharge_enabled: false,
      autocharge_approved: false,
      bank_account: null,
      autocheckout_enabled: false,
      autocheckout_enabled_time: null,
      cancellation_policy: ProviderCancellationPolicy.NoPolicy,
      identity_verification: null,
      instant_deposits: null,
      gift_card_enabled: false,
      on_commission_plan: false,
      payments_onboarding_steps: [],
      is_payments_setup_mandatory: false,
      stripe_account_id: null,
    }),
    loading: true,
  };
}

export const paymentsState = createModel<RootModel>()({
  name: 'paymentsState',
  state: getInitialState(),

  reducers: {
    onStartLoading: (state: IPaymentsState) => ({ ...state, loading: true }),
    onFinishLoading:
        (state: IPaymentsState, newPaymentsState: PaymentsState) => ({
          paymentsState: newPaymentsState,
          loading: false,
        }),
  },

  effects: dispatch => ({
    async update(
      providerId: number,
    ): Promise<void> {
      dispatch.paymentsState.onStartLoading();
      clearPaymentSettingsCache();
      const newPaymentsState = await fetchPaymentState(providerId);
      dispatch.paymentsState.onFinishLoading(newPaymentsState);
    },
  }),

  selectors: (slice, createSelector) => ({
    isOnboarded() {
      return createSelector(
        slice((state: IPaymentsState) => state?.paymentsState?.isOnboarded()),
        (isOnboarded: boolean) => isOnboarded,
      );
    },
    isLoaded() {
      return createSelector(
        slice((state: IPaymentsState) => state?.loading === false),
        (loading: boolean) => loading,
      );
    },
  }),
});
