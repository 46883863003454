// @ts-strict-ignore
import {
  IBackdropManager,
  BackdropArgs,
  PortalElementArgs,
} from './types';
import styles from './PortalElementManager.module.scss';

export class BackdropManager implements IBackdropManager {
  private elOverlayBackdrop: HTMLElement | null = null;
  private testID: string;

  constructor(testID: string, args: BackdropArgs) {
    this.testID = testID;
    this.createBackdrop(args);
  }

  public createBackdrop = (args: BackdropArgs) => {
    const {
      isTransparentOnMobile,
      isTransparent = false,
      zIndex,
      hideBackdrop,
      includeMobileNav,
    } = args;
    this.elOverlayBackdrop = document.createElement('div');
    this.elOverlayBackdrop.dataset.testid = this.testID;
    this.elOverlayBackdrop.classList.add(styles.portalOverlayBackdrop);
    if (hideBackdrop) {
      this.elOverlayBackdrop.classList.add(styles.hide);
    }
    if (isTransparentOnMobile) {
      this.elOverlayBackdrop.classList.add(styles.transparentOnMobile);
      this.elOverlayBackdrop.classList.add(styles.portalOverlayNoFadeIn);
    }

    if (isTransparent) {
      this.elOverlayBackdrop.classList.add(styles.transparent);
      this.elOverlayBackdrop.classList.add(styles.portalOverlayNoFadeIn);
    }
    if (includeMobileNav) {
      this.elOverlayBackdrop.classList.add(styles.includeMobileNav);
    }

    if (typeof zIndex === 'number') {
      this.elOverlayBackdrop.style.zIndex = zIndex.toString();
    }
  };

  public get backdrop() {
    return this.elOverlayBackdrop;
  }

  public createChild(args: PortalElementArgs): HTMLElement {
    const {
      color,
      overflowScroll,
      isTransparentOnMobile,
      className,
    } = args;
    const elOverlayDest = document.createElement('div');

    if (color) {
      elOverlayDest.style.backgroundColor = color;
    }

    if (overflowScroll) {
      elOverlayDest.style.overflow = 'scroll';
    }

    if (isTransparentOnMobile) {
      elOverlayDest.classList.add(styles.transparentOnMobile);
    }

    if (className) {
      elOverlayDest.classList.add(className);
    }
    this.elOverlayBackdrop.appendChild(elOverlayDest);

    return elOverlayDest;
  }
}
