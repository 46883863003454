// @ts-strict-ignore
import React from 'react';
import { View, Text } from 'react-native';
import { BrandColor } from '@styleseat/brand';
import { IBoxProps } from './Box.types';

const chooseFrom = <A, B, C>(a: A, b: B, c?: C): A | B | C => {
  if (a !== null) return a;
  if (b !== null) return b;
  return c;
};

// Type annotations to help VS Code along … sometimes it thinks Box is
// any without it.
export const Box: React.ForwardRefExoticComponent<
IBoxProps & React.RefAttributes<View>
> = React.forwardRef<View, IBoxProps>(
  (
    {
      alignItems,
      alignSelf,
      borderBottomColor = null,
      borderBottomWidth = null,
      borderColor = null,
      borderLeftColor = null,
      borderLeftWidth = null,
      borderRadius,
      borderRadiusTop = null,
      borderRadiusBottom = null,
      borderRadiusLeft = null,
      borderRadiusRight = null,
      borderRightColor = null,
      borderRightWidth = null,
      borderStyle = null,
      borderTopColor = null,
      borderTopWidth = null,
      borderWidth = null,
      borderXColor = null,
      borderXWidth = null,
      borderYColor = null,
      borderYWidth = null,
      bottom,
      boxShadowColor = 'rgba(0, 0, 0, 0.2)',
      children = null,
      className = '',
      color = BrandColor.Transparent,
      flex,
      flexBasis,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      height,
      justifyContent,
      left,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      onLayout = () => {},
      overflow,
      overflowX,
      overflowY,
      padding = null,
      paddingBottom = null,
      paddingLeft = null,
      paddingRight = null,
      paddingTop = null,
      paddingX = null,
      paddingY = null,
      position,
      right,
      showBoxShadow = false,
      style: styleProp = {},
      testID = null,
      top,
      width,
      zIndex,
      ...viewProps
    },
    ref,
  ) => {
    let shadowOpts = {};
    if (showBoxShadow) {
      // these props only apply to iOS, not Android
      shadowOpts = {
        // box-shadow: 0 2px 4px rgba(black, 0.08) !important;
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowRadius: 4,
        shadowColor: boxShadowColor,
      };
    }

    let stylePropObject = {};
    let stylePropArray = [];

    if (Array.isArray(styleProp)) {
      stylePropArray = styleProp;
    } else if (typeof styleProp === 'number') {
      stylePropArray = [styleProp];
    } else {
      stylePropObject = styleProp;
    }

    const style = {
      paddingTop: chooseFrom(paddingTop, paddingY, padding),
      paddingBottom: chooseFrom(paddingBottom, paddingY, padding),
      paddingLeft: chooseFrom(paddingLeft, paddingX, padding),
      paddingRight: chooseFrom(paddingRight, paddingX, padding),
      borderTopWidth: chooseFrom(borderTopWidth, borderYWidth, borderWidth),
      borderBottomWidth: chooseFrom(
        borderBottomWidth,
        borderYWidth,
        borderWidth,
      ),
      borderLeftWidth: chooseFrom(borderLeftWidth, borderXWidth, borderWidth),
      borderRightWidth: chooseFrom(borderRightWidth, borderXWidth, borderWidth),
      borderTopColor: chooseFrom(borderTopColor, borderYColor, borderColor),
      borderBottomColor: chooseFrom(
        borderBottomColor,
        borderYColor,
        borderColor,
      ),
      borderLeftColor: chooseFrom(borderLeftColor, borderXColor, borderColor),
      borderRightColor: chooseFrom(borderRightColor, borderXColor, borderColor),
      borderStyle,
      borderRadius,
      borderTopLeftRadius: chooseFrom(borderRadiusTop, borderRadiusLeft, borderRadius),
      borderTopRightRadius: chooseFrom(borderRadiusTop, borderRadiusRight, borderRadius),
      borderBottomLeftRadius: chooseFrom(borderRadiusBottom, borderRadiusLeft, borderRadius),
      borderBottomRightRadius: chooseFrom(borderRadiusBottom, borderRadiusRight, borderRadius),
      backgroundColor: color,
      flex,
      flexBasis,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      alignItems,
      alignSelf,
      justifyContent,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      overflow,
      overflowX,
      overflowY,
      position,
      top,
      left,
      right,
      bottom,
      height,
      width,
      zIndex,
      ...shadowOpts,
      ...stylePropObject,
    };

    return (
      <View
        ref={ref}
        className={className}
        style={[
          style,
          ...stylePropArray,
        ]}
        testID={testID}
        onLayout={onLayout}
        {...viewProps}
      >
        {React.Children.map(children, child => {
          if (typeof child === 'string') {
            return <Text>{child}</Text>;
          }
          return child;
        })}
      </View>
    );
  },
);
