// @ts-strict-ignore
import breakpoints from '../responsive_config';
import { getWidth } from './getWidth';

/**
 * Validate if the browser window width is below a specified breakpoint.
 * @param {Object} breakpoint - Object with a min and/or max value.
 * @return {boolean}
 */
export function isBelow(breakpointName) {
  const breakpoint = breakpoints[breakpointName];
  if (breakpoint.min && getWidth() < breakpoint.min) {
    return true;
  }
  return false;
}
