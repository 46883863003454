import nonCriticalException from '../../modules/exceptionLogger';
import ssFetch from '../../modules/ssFetch';
import * as SignupSettings from './SignupSettings';
import { clearApiCache } from './Providers';

export type IProMembershipProgram = {
  id: number;
  display_name: string;
  plan_name: string;
  marketing_blurb: string;
  amount: string;
  interval: string;
  features: Array<IProMembershipProgramFeatureMap>;
  default_program: boolean;
  analytics_id: string;
  is_payments_setup_mandatory: boolean;
  trial_period_days: number;
  new_client_fee_percent: string;
  new_client_fee_max?: string;
};

export type IProMembershipProgramFeatureMap = {
  internal_name: string;
  display_name: string;
  help_text: string;
  available: boolean;
};

export async function updateProviderSelectedPlan(
  providerId: number | string,
  plan: string,
): Promise<Response> {
  const url = `/api/v2/providers/${providerId}/plan`;
  const response = await ssFetch(url, {
    method: 'PUT',
    body: JSON.stringify({ plan }),
  });

  if (!response.ok) {
    const {
      status,
      statusText,
    } = response;
    const err = new Error(`Plan Selection Failed: ${statusText}`);

    // Log the exception to new relic
    nonCriticalException(err, {
      extra: {
        plan,
        status,
        statusText,
        url,
      },
      tags: { source: 'PlanSelectionModalContainer:handleSave' },
    });
  }

  clearApiCache();
  SignupSettings.clearCache();

  return response;
}
