// @ts-strict-ignore
import { ssFetchJSON } from '../../modules/ssFetch';
import SimpleCache from '../../modules/SimpleCache';
import { IDRFResponse } from '../../types';
import { API_ROOT } from '../../config';
import { IGalleryImage } from '../Providers/GalleryImages';

const salonCache = new SimpleCache(60 * 60 * 12); // 12 hours

/**
 * Executes a "V2" request to get salon gallery image information, with a 12 hour cache
 * @param {Number} salonId The ID of the salon to load images from
 */
export async function getSalonGalleryImages(salonId) {
  return ssFetchJSON<IDRFResponse<IGalleryImage>>(
    `${API_ROOT}/api/v2/salons/${salonId}/gallery_images?sub_providers=all`,
    { method: 'GET', ssCache: salonCache },
  );
}

/**
 * Clears the cache wrapping the `getSalonGalleryImages` function (the default export)
 */
export function clearCache() {
  salonCache.clear();
}

export default getSalonGalleryImages;
