// @ts-strict-ignore
import URI from 'urijs';
import { ssFetchJSON } from '../ssFetch';
import IDRFResponse from '../../types/IDRFResponse';
import { ProviderServiceGroup } from '../../api/Providers/ServiceGroups';
import { PublicProvider } from '../../api/Providers';
import { ProviderService } from '../../api/Providers/Services';

/**
 * Normalize the service list values.
 *
 * This normalizes properties between salon and provider service groups
 * and ensures that all services have a cost.
 *
 * @param {Array} serviceGroups The list of service groups to normalize
 */
function normalizeServiceSchema(serviceGroups) {
  return serviceGroups.map(group => ({
    ...group,
    services: group.services.map(service => {
      const normalizedService = { ...service };
      // Is online bookable
      if (typeof service.is_online_bookable === 'undefined') {
        normalizedService.is_online_bookable = service.is_ob;
      }

      // Service time
      if (typeof service.duration_minutes === 'undefined') {
        normalizedService.duration_minutes = service.mins;
      }

      if (typeof service.total_duration_minutes === 'undefined') {
        normalizedService.total_duration_minutes = service.duration_minutes
          + service.finishing_time_minutes
          + service.processing_time_minutes;
      }

      // Use price range if cost is zero
      if (parseFloat(service.cost) === 0.0
        && service.price_range
        && parseFloat(service.price_range[0]) > 0) {
        normalizedService.cost = service.price_range[0];
        normalizedService.cost_and_up = (service.price_range[0] !== service.price_range[1]);
      }

      return normalizedService;
    }),
  }));
}

export function flattenServiceGroups(serviceGroups: ProviderServiceGroup[]): ProviderService[] {
  return serviceGroups.reduce((acc, cur) => ([
    ...acc,
    ...cur.services,
  ]), []);
}

/**
 * Calculates total services pro has from service groups
 * @param {Array} serviceGroups - array of service groups
 * @return {number} length of the services
 */
export const totalServiceCount = serviceGroups => flattenServiceGroups(serviceGroups).length;

/**
 * Gets the service groups for a salon or provider
 * @param {Object} profile The profile for which to load service groups, either a salon or provider
 * @param {Object} options An options hash, which is directly applied to the URI
 * @yields {Object} An object with a `results` key containing the service groups
 */
export const loadServiceGroups = async (profile: Pick<PublicProvider, 'id'>, options?: Record<string, any>):
Promise<IDRFResponse<ProviderServiceGroup>> => {
  let url = `/api/v2/providers/${profile.id}/servicegroups`;

  url = URI(url).addQuery(options).toString();

  return ssFetchJSON(url).then(json => ({
    ...json,
    results: normalizeServiceSchema(json.results),
  }));
};

export default loadServiceGroups;
