import { SWITCHED_FROM_PREMIUM_TO_BASIC } from '../components/provider/ScreenBlocker/Inactive/constants';
import {
  HOME_ROUTE,
  PROVIDER_ONBOARDING_PAYMENTS,
  PROVIDER_ONBOARDING_SETUP_SUBSCRIPTION,
  PROVIDER_SETTINGS_PAYMENTS_ADDRESS,
  PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
  PRO_PLAN_SETTINGS,
  PRO_TAX_SETTINGS,
  PRO_TAX_SETTINGS_EDELIVERY_ROUTE,
} from '../route-names';
import { ProviderPlan } from '../types/provider';
import { BlockerType } from './SubsBlocker.constants';

/**
 * Expected properties returned by getTrackingParams()
 */
export interface ITrackingParams {
  is_oldie: boolean;
  was_oldie: boolean;
  is_rejoined_oldie: boolean;
  is_on_commission_plan: boolean;
  is_commission_plan_default: boolean;
  is_trial_expired: boolean;
  is_canceled: boolean;
  is_payments_enabled: boolean;
  provider_id: number;
}

/**
 * State transformers for the SubsBlocker model.
 */
export default {
  /**
   * Given rematch model state, get the type of blocker that should show.
   *
   * @param state {any} - rematch model state
   * @returns {BlockerType}
   */
  getBlockerType(state: any, paymentState?: any): BlockerType {
    const providerId = state?.providers?.activeProviderId;
    const providerInfo = state?.providers?.providersById[providerId];
    const planName = providerInfo?.result?.plan;
    const isOnboarded = paymentState?.isOnboarded();
    const proSwitched = state?.userState?.values?.[SWITCHED_FROM_PREMIUM_TO_BASIC];

    if (state?.subsBlocker?.isBlockedFromApplication
      || state?.subsBlocker?.isTrialExpired
      || (
        planName === ProviderPlan.Commission
        && isOnboarded === false
        && proSwitched
      )
    ) {
      // The backend says this user is blocked, so show them the swiper.
      return BlockerType.Swiper;
    }
    return BlockerType.None;
  },

  /**
   * Given rematch model state, determine whether the pro has an active membership or not.
   *
   * @param state {any} - rematch model state
   * @returns {boolean}
   */
  hasActiveMembership(state: any): boolean {
    const { subsBlocker } = state;
    return !(
      subsBlocker?.isTrialExpired
      || subsBlocker?.isCanceled
    );
  },

  /**
   * Given rematch model state, determine whether the pro is eligible to join the
   * oldies-retrial plan.
   *
   * @param state {any} - rematch model state
   * @returns {boolean}
   */
  isEligibleForOldiesRetrial(state: any): boolean {
    const { subsBlocker } = state;
    return (
      subsBlocker?.isOldie
      && !subsBlocker?.wasOldie
    );
  },

  /**
   * Given rematch model state, determine whether this is a commission pro or not.
   *
   * Returns true if the pro is already on a commission plan, or is in in a
   * commission test metro.
   *
   * @param state {any} - rematch model state
   * @returns {boolean}
   */
  isCommission(state: any): boolean {
    const { subsBlocker } = state;
    return Boolean(
      // Is in a commission test metro area
      subsBlocker?.isCommissionPlanDefault
      // Or is currently on a commission plan
      || subsBlocker?.isOnCommissionPlan,
    );
  },

  /**
   * Given rematch model state, determine whether this is a SaaS pro (non-commission) or not.
   *
   * @param state {any} - rematch model state
   * @returns {boolean}
   */
  isSaaS(state: any): boolean {
    // If not commission, the pro is SaaS.
    return !this.isCommission(state);
  },

  /**
   * Given rematch model state, determine whether this pro is trying to switch plans
   * @param state {any} - rematch model state
   * @returns {boolean}
   */
  isChangingPlan(state: any): boolean {
    const { name, params: routeParams } = state.route ?? {};
    const { isOnCommissionPlan } = state?.subsBlocker ?? {};
    return Boolean(
      // Is in payment settings
      name === 'provider.settings_menu.payments'
      // And route has param 'plan' with value 'commission'
      && routeParams?.plan === ProviderPlan.Commission
      // And pro is currently in SaaS
      && !isOnCommissionPlan,
    );
  },

  /**
   * Given rematch model state, return the analytics tracking parameters for SubsBlocker components.
   *
   * @param state {any} - rematch model state
   * @returns {ITrackingParams}
   */
  getTrackingParams(state: any): ITrackingParams {
    const { subsBlocker } = state;
    const {
      isOldie,
      wasOldie,
      isRejoinedOldie,
      isCanceled,
      isTrialExpired,
      isOnCommissionPlan,
      isCommissionPlanDefault,
      isPaymentsEnabled,
      providerId,
    } = subsBlocker;
    return {
      is_oldie: isOldie,
      was_oldie: wasOldie,
      is_rejoined_oldie: isRejoinedOldie,
      is_on_commission_plan: isOnCommissionPlan,
      is_commission_plan_default: isCommissionPlanDefault,
      is_trial_expired: isTrialExpired,
      is_canceled: isCanceled,
      is_payments_enabled: isPaymentsEnabled,
      provider_id: providerId,
    };
  },

  /**
   * Given rematch model state, determine whether a blocker should be displayed for the
   * current route or not.
   *
   * If provided the routeName parameter, that route will be checked instead of the
   * current route.
   *
   * @param state {any} - rematch model state
   * @param routeName {(string|null)=}
   * @returns {bool}
   */
  shouldShowSubsBlocker(state: any, routeName: string | null = null, paymentState?: any): boolean {
    const whitelistedRoutes = [
      PRO_PLAN_SETTINGS,
      HOME_ROUTE,
      PRO_TAX_SETTINGS,
      PRO_TAX_SETTINGS_EDELIVERY_ROUTE,
      PROVIDER_SETTINGS_PAYMENTS_ADDRESS,
      PROVIDER_SETTINGS_PAYMENTS_IDENTITY,
      PROVIDER_ONBOARDING_SETUP_SUBSCRIPTION,
      PROVIDER_ONBOARDING_PAYMENTS,
    ];

    const name = routeName || state.route?.name;
    const blockerType = this.getBlockerType(state, paymentState);

    return (
      !!state.user.userId
      && blockerType !== BlockerType.None
      && this.isChangingPlan(state) === false
      && !whitelistedRoutes.includes(name)
    );
  },

  /**
   * Given rematch model state, determine whether the pro can join the retrial plan.
   *
   * @param state {any} - rematch model state
   * @returns {boolean}
   */
  canRetrial(state: any): boolean {
    const {
      subsBlocker,
    } = state;
    return Boolean(
      // is SaaS, not commission
      this.isSaaS(state)
      // is an oldie
      && subsBlocker?.isOldie
      // and has never previously re-trialed
      && !subsBlocker?.wasOldie,
    );
  },
};
