// @ts-strict-ignore
import moment from 'moment';
import { getPrivilegedProvider, PrivilegedProvider } from '../../../api/Providers';
import { IPaymentSettingsV3, VerificationStatus } from '../PaymentSettings.types';
import { Subscription, loadSubscription } from './Subscription';

import { stateManager } from '../../user/stateManager';
import { LAST_VERIFICATION_DETAILS_SUBMISSION_TIME_KEY } from '../../../components/provider/settings/constants';
import { fetchPaymentSettings } from '../PaymentSettings';

export enum SubscriptionBannerKey {
  /**
   * Pro has had a missed payment and is in their grace period.
   */
  GracePeriodBanner = 'GRACE_PERIOD_BANNER',

  /**
   * Pro has canceled their subscription but their subscription
   * end date has not passed.
   */
  CanceledAndNotExpiredBanner = 'CANCELED_AND_NOT_EXPIRED_BANNER',

  /**
   * Static banner that displays if the provider is payments onboarded but needs to provide Full SSN
   */
  FullSSNRequiredBanner = 'FULL_SSN_REQUIRED_BANNER',

  /**
   * Static banner that displays if the provider is payments onboarded but needs to provide a
   * legal document.
   */
  LegalDocumentRequiredBanner = 'LEGAL_DOCUMENT_REQUIRED_BANNER',

  /**
   * Static banner that displays if the provider is payments onboarded but needs to provide a
   * valid mailing address
   */
  MailingAddressRequiredBanner = 'MAILING_ADDRESS_REQUIRED_BANNER',

  /**
   * Static banner that displays if the provider is payments onboarded but needs to provide a
   * valid Company EIN
  */
  CompanyEINRequiredBanner = 'COMPANY_EIN_REQUIRED_BANNER',

  /**
   * General unverified banner that displays if stripe says unverified but our system doesn't have
   * a case for the fieldname they required
   */
  GeneralUnverifiedBanner = 'GENERAL_UNVERIFIED_BANNER',

  /**
   * Static banner that displays if the provider is pending payments verification.
   */
  PendingPaymentsVerificationBanner = 'PENDING_PAYMENTS_VERIFICATION_BANNER',
}

export class SubscriptionBannerState {
  subscription: Subscription;

  paymentsSettings: IPaymentSettingsV3;

  lastSubmissionTime: string;

  provider: PrivilegedProvider;

  bannerSource: string;

  /**
   * Initializes subscription banner state based on their subscription and their payments settings.
   *
   * @params {Subscription} Subscription from Api.loadSubscription
   * @params {IPaymentSettingsV3} PaymentsSettings
   * @params {String} lastSubmissionTime from stateManager.getActionValue
   * @params {PrivilegedProvider} Provider from Api.loadProvider
   * @params {String} bannerSource from ShortSubscriptionBanner
   * @returns {Boolean}
   */
  constructor(
    subscription: Subscription,
    paymentsSettings: IPaymentSettingsV3,
    lastSubmissionTime: string,
    provider: PrivilegedProvider,
    bannerSource: string,
  ) {
    this.subscription = subscription;
    this.paymentsSettings = paymentsSettings;
    this.lastSubmissionTime = lastSubmissionTime;
    this.provider = provider;
    this.bannerSource = bannerSource;
  }

  static async fetch(
    providerId: number,
    bannerSource?: string,
  ): Promise<SubscriptionBannerState> {
    const [
      subscription,
      paymentConfig,
      lastSubmissionTime,
      provider,
    ] = await Promise.all([
      loadSubscription(providerId),
      fetchPaymentSettings(providerId),
      stateManager.getActionValue(LAST_VERIFICATION_DETAILS_SUBMISSION_TIME_KEY),
      getPrivilegedProvider(providerId),
    ]);

    // Fetch the subscription banner state based on the subscription
    return new SubscriptionBannerState(
      subscription,
      paymentConfig,
      lastSubmissionTime,
      provider,
      bannerSource,
    );
  }

  /**
   * Calculate banner subscription state both for regular
   * subscription banners (used on the More tab) and short
   * subscription banners (used on the Daily, Calendar, &
   * Clients tabs).
   *
   * @returns {String}
   */
  async calculate(): Promise<SubscriptionBannerKey | void> {
    if (this.subscription.isGracePeriod()) {
      return SubscriptionBannerKey.GracePeriodBanner;
    }

    if (this.subscription.isCanceledAndNotExpired()) {
      return SubscriptionBannerKey.CanceledAndNotExpiredBanner;
    }

    const identityVerification = this.paymentsSettings.identity_verification;
    // status might take a few minutes to update, so hide "info needed" banners for two minutes
    // after submission.
    const recentlySubmitted = this.lastSubmissionTime && moment().diff(this.lastSubmissionTime, 'minutes') <= 2;
    const unverified = identityVerification?.status === VerificationStatus.UNVERIFIED;

    // Verification is pending banner
    if (identityVerification?.status === VerificationStatus.PENDING) {
      return SubscriptionBannerKey.PendingPaymentsVerificationBanner;
    }

    // Needs full SSN Banner
    if (!recentlySubmitted
      && identityVerification?.needs_full_ssn) {
      return SubscriptionBannerKey.FullSSNRequiredBanner;
    }

    // Needs legal document Banner
    if (!recentlySubmitted
      && identityVerification?.needs_legal_document) {
      return SubscriptionBannerKey.LegalDocumentRequiredBanner;
    }

    // Needs Company EIN
    if (!recentlySubmitted
      && identityVerification?.needs_company_ein) {
      return SubscriptionBannerKey.CompanyEINRequiredBanner;
    }

    // Needs Mailing Address
    if (!recentlySubmitted
      && identityVerification?.needs_valid_address?.length) {
      return SubscriptionBannerKey.MailingAddressRequiredBanner;
    }

    // user is unverified but there wasn't enough specific information, e.g.
    // Pro is a business and stripe required "relationship.representative" (possibly due to SSN
    // error)
    if (unverified) {
      return SubscriptionBannerKey.GeneralUnverifiedBanner;
    }

    return undefined;
  }

  async isActive(): Promise<boolean> {
    return this.calculate().then(value => !!value);
  }
}
