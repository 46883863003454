// @ts-strict-ignore
const r20 = /%20/g;
const rbracket = /\[\]$/;

function getType(obj) {
  if (obj == null) {
    return `${obj}`;
  }
  const class2type = {};
  const { toString } = class2type;
  // Support: Android < 4.0, iOS < 6 (functionish RegExp)
  return typeof obj === 'object' || typeof obj === 'function'
    ? class2type[toString.call(obj)] || 'object'
    : typeof obj;
}

function buildParams(
  prefix: string,
  obj: { [x: string]: any },
  add: (key: any, value: any) => void,
) {
  let value;

  if (Array.isArray(obj)) {
    Object.keys(obj).forEach(key => {
      value = obj[key];
      if (rbracket.test(prefix)) {
        add(prefix, value);
      } else {
        buildParams(`${prefix}[${typeof value === 'object' ? key : ''}]`, value, add);
      }
    });
  } else if (getType(obj) === 'object') {
    Object.keys(obj).forEach(name => {
      buildParams(`${prefix}[${name}]`, obj[name], add);
    });
  } else {
    add(prefix, obj);
  }
}

/**
 * Serialize objects into a string (URL encoded)
 * replaces jQuery's $.param.
 * @param {T} obj
 * @returns string
 */
export function serializeParams<T extends object = any>(obj: T): string {
  const serialized = [];

  if (obj === undefined || obj === null) {
    return '';
  }

  function add(key, value) {
    let result = value === null || value === undefined ? '' : value;
    result = getType(value) === 'function' ? value() : result;
    serialized[serialized.length] = `${encodeURIComponent(key)}=${encodeURIComponent(result)}`;
  }

  if (Array.isArray(obj)) {
    obj.forEach((value, index) => {
      add(`${index}`, value);
    });
  } else {
    Object.keys(obj).forEach(key => {
      buildParams(key, obj[key], add);
    });
  }

  return serialized.join('&').replace(r20, '+');
}
