// @ts-strict-ignore
import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import createIntersectionObserver from './createIntersectionObserver';

type ParamsMap = {
  onIntersect?: () => void;
  enabled?: boolean;
};

/**
 * Watches for intersections on a given element and triggers a callback when one occurs.
 * @param onIntersect Function to be called when an intersection is observed
 * @param enabled True if onIntersect should be fired
 * @returns A value to set as the ref of the element to monitor for intersections
 */
export const useIntersectionTrigger = (
  onIntersect?: () => void,
  enabled: boolean = true,
): React.LegacyRef<HTMLElement> => {
  const ioRef = useRef<IntersectionObserver>(null);
  const endRef = useRef<HTMLElement>(null);
  const paramsRef = useRef<ParamsMap>({
    onIntersect,
    enabled,
  });

  useEffect(() => {
    paramsRef.current.onIntersect = onIntersect;
    paramsRef.current.enabled = enabled;
  }, [
    onIntersect,
    enabled,
  ]);

  const setIntersectorRef = useCallback((end: HTMLElement) => {
    // component did mount functionality, initialize the intersection observer.
    if (!ioRef.current) {
      ioRef.current = createIntersectionObserver(entries => {
        if (entries.find(ioe => ioe.isIntersecting) && paramsRef.current.enabled) {
          paramsRef.current.onIntersect?.();
        }
      });
    }

    // component did update functionality, cleanup the old endRef
    if (endRef.current) {
      ioRef.current.unobserve(endRef.current);
    }

    // component did mount or update sets up the endRef current and also
    // observes the new end ref element.
    if (end) {
      endRef.current = end;
      ioRef.current.observe(endRef.current);
    }

    // component un-mount cleanup
    if (end === null) {
      ioRef.current.disconnect();
    }
  }, []);

  return setIntersectorRef;
};
