// @ts-strict-ignore
import { API_ROOT } from '../../config';
import { ssFetchJSON } from '../../modules/ssFetch';
import SimpleCache from '../../modules/SimpleCache';
import { DateTimeStamp } from '../../types/dateTime';

const RefundCache = new SimpleCache(15 * 60);
export interface RefundReasonChoice {
  label: string;
  value: number;
}

export const REFUND_REASONS: RefundReasonChoice[] = [
  { label: 'My client paid with cash', value: 1 },
  { label: 'I need to edit the amount charged', value: 2 },
  { label: 'I meant to cancel this appointment', value: 3 },
  { label: 'My client tipped in person', value: 4 },
  { label: 'My client paid in advance', value: 5 },
  { label: 'My client paid with a credit card', value: 6 },
];

export const DEPOSIT_REFUND_REASONS: RefundReasonChoice[] = [
  { label: 'I don\'t want a deposit for this client', value: 10 },
  { label: 'The deposit amount is not correct', value: 11 },
  { label: 'I need to change the service', value: 12 },
  { label: 'I need to cancel this appointment', value: 13 },
];

/** See styleseat.autocharge.models::AppointmentRefundRequest.REFUND_TYPE_CHOICES */
export enum RefundTypeChoice {
  CheckoutRefund = 0,
  /** @deprecated */
  PrepaymentRefund = 1,
  DepositRefund = 2,
}

export type RefundPayload = {
  amount: number;
  reason: string;
  notes?: string;
  refund_type?: RefundTypeChoice;
};

export type RefundApiResponse = {
  amount: string; // Decimal
  created: DateTimeStamp;
  refund_type: number;
};

export function refundAppointment(
  appointmentId: number,
  payload: RefundPayload,
): Promise<RefundApiResponse> {
  const url = new URL(`/api/v1/appointment/${appointmentId}/refund.json`, API_ROOT);
  RefundCache.clear();
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    body: payload,
  });
}

export function getRefunds(appointmentId): Promise<RefundApiResponse[]> {
  const url = new URL(`/api/v1/appointment/${appointmentId}/refund.json`, API_ROOT);
  return ssFetchJSON(url.toString(), {
    ssCache: RefundCache,
  });
}
