import { createModel } from '@rematch/core';
import produce from 'immer';
import { getProviderProductCategories } from '../api/Providers/Products/Products';
import type { RootModel } from './models';
import nonCriticalException from '../modules/exceptionLogger';

type State = {
  loading: boolean;
  providerId: number | null;
  list: any[];
  errors: any[] | null;
};

const defaultState = (): State => ({
  loading: false,
  providerId: null,
  list: [],
  errors: null,
});

/**
 * Manages the list of provider products for a given provider
 */
const ProviderProductCategory = createModel<RootModel>()({
  state: defaultState(),

  reducers: {
    onFetchStart: (_, payload: { providerId: number }) => ({
      ...defaultState(),
      providerId: payload.providerId,
      loading: true,
      errors: null,
    }),

    onFetchComplete: produce<State, [{ categories: any[] }]>((state, payload) => {
      state.list = [...payload.categories];
      state.loading = false;
    }),

    onDeleteStart: produce(state => {
      state.loading = true;
    }),

    onError: produce<State, [{ error: any }]>((state, { error }) => {
      state.loading = false;

      state.errors = [error];
    }),
    clear: () => defaultState(),
  },

  effects: dispatch => ({
    /**
     * Loads a list of products for the given provider
     * @param providerId
     */
    load: async ({ providerId }: { providerId: number }): Promise<any> => {
      dispatch.providerProductCategory.onFetchStart({ providerId });

      try {
        const categories = await getProviderProductCategories(providerId);
        return dispatch.providerProductCategory.onFetchComplete({ categories });
      } catch (error) {
        nonCriticalException(error);
        return dispatch.providerProductCategory.onError({ error });
      }
    },
  }),
});

export default ProviderProductCategory;
