// @ts-strict-ignore
import LogRocket from 'logrocket';

import { API_ROOT, SEARCH_API_ROOT } from '../../../config';
import { InterceptorConfig } from '../../../modules/ssFetch';

let recordingUrl: null | string;

/**
 * Intercept requests and adds logRocket URL to the request so that they show up in NewRelic errors
 */
export const logRocketInterceptor: InterceptorConfig = {
  request: req => {
    if (req.url?.indexOf(API_ROOT) < 0 && req.url?.indexOf(SEARCH_API_ROOT) < 0) {
      return req;
    }

    LogRocket.getSessionURL(sessionUrl => {
      recordingUrl = sessionUrl;
      globalThis?.newrelic?.setCustomAttribute('logrocket_url', sessionUrl);
    });

    if (!recordingUrl) {
      return req;
    }

    req.headers.set('X-LogRocket-URL', recordingUrl);

    return req;
  },
};
