// @ts-strict-ignore
import { API_ROOT } from '../../../../config';
import ssFetch from '../../../../modules/ssFetch';

/**
 * Data as it is returned from the UpdateServiceOrderView (on backend)
 */
export type UpdateOrderResponse = {
  response: 'OK';
};

/**
 * GET: provider/${providerId}/service/update_order/${serviceGroupId}
 * @param providerId
 * @param serviceGroupId
 * @param orderedServiceIds
 */
export function updateOrder(
  providerId: number | string,
  serviceGroupId,
  orderedServiceIds: number[],
) {
  const url = new URL(`${API_ROOT}/provider/${providerId}/service/update_order/${serviceGroupId}`);

  return ssFetch<UpdateOrderResponse>(
    url.toString(),
    {
      method: 'POST',
      form: { 'serviceList[]': orderedServiceIds },
      addRequestedWith: true,
    },
  );
}
