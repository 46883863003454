import moment from 'moment';
import Currency from '../Currency';
import type { State as User } from '../../store/CurrentUser.types';
import {
  ProAppointment,
  ProAppointmentDetails,
  Checkout,
  PaymentOption,
  ClientCardOnFile,
} from '../../api/Providers/Appointments';
import type { Moment } from '../dateUtils';
import type { IMinimalBankAccountData } from '../Api.types';
import { bookedWithKlarna } from '../UserAppointmentState';
import { PaymentMethodType } from '../../components/consumer/booking/BookingFlow/types';
import { ICard } from '../../store/PaymentMethods';
import { PrivilegedProvider } from '../../api/Providers';
import { AppointmentTotals } from '../../api/Providers/Appointments/AppointmentTotals';

export const isUserClient = (client: Pick<User, 'user_id'>): boolean => (
  !!client.user_id
);

export const calculateTax = (
  amount: number | string,
  percent: number,
): number => (
  Math.ceil(Currency.parse(amount) * percent) / 100
);

/**
 * Returns true if appt was checked out via credit card through StyleSeat
 * @param  {Object} checkout - checkout object
 * @return {Boolean}
 */
export const isCheckedOutByCard = (checkout?: Pick<Checkout, 'payment_types'>): boolean => (
  !!checkout
  && !!checkout.payment_types
  && !!checkout.payment_types[0]
  && checkout.payment_types[0].name.toLowerCase() === 'credit'
  && checkout.payment_types[0].is_payment
);

/**
 * Is the passed in payment method a credit card
 * @param   {Object/String} paymentMethod Payment method object or string
 * @returns {Boolean}
 */
export const isCreditCard = (
  paymentMethod?: IMinimalBankAccountData | string,
): boolean => !!(
  paymentMethod
  && typeof paymentMethod === 'object'
  && paymentMethod.last4
);

// -- AUTO CHECKOUT SECTION --

/**
 * Checks if auto-checkout failed at checkout
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const autoCheckoutFailAtCheckout = (
  appointment: Pick<ProAppointment, 'order_creation_source' | 'order_status'>,
): boolean => (
  appointment.order_creation_source === 'autocharge'
  && appointment.order_status === 'payment-failed'
);

/**
 * The appointment is an autocheckout appointment, but
 * the pro is checking out the appointment outside of the checkout window.
 * @param   {Object}  appointment The appointment object
 * @returns {Boolean}
 */
export const isManualAutoCheckoutFlow = (
  appointment: Pick<ProAppointment, 'is_autocheckout' | 'card_check' | 'order_creation_source' | 'order_status'>,
): boolean => (
  appointment.is_autocheckout
  && !(appointment.card_check && appointment.card_check.state_code === 'card_declined')
  && !autoCheckoutFailAtCheckout(appointment)
);

/**
 * Gets the auto-checkout countdown target time to start counting down
 * @param {object} appointment - the appointment object
 * @return {object} the target time as a moment object
 */
export const getAutoCheckoutCountdownTargetTime = (
  appointment: Pick<ProAppointment, 'local_end'>,
): Moment => (
  moment(appointment.local_end).add(1, 'hours')
);

/**
 * Checks to see if current time is before the autocheckout window
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
const isBeforeAutoCheckoutWindow = (
  appointment: Pick<ProAppointment, 'local_start'>,
): boolean => {
  const appointmentStartDate = moment(appointment.local_start);
  const windowStartDate = appointmentStartDate.subtract(1, 'hours');
  return moment().isBefore(windowStartDate);
};

/**
 * Checks to see if current time is after the auto checkout window
 * Used to determine if we should show the countdown as well
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isAfterAutoCheckoutWindow = (
  appointment: Pick<ProAppointment, 'local_end'>,
): boolean => moment().isAfter(getAutoCheckoutCountdownTargetTime(appointment));

/**
 * Checks to see appointment is within autocheckout window
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isWithinAutoCheckoutWindow = (
  appointment: Pick<ProAppointment, 'local_start' | 'local_end'>,
): boolean => !(
  isBeforeAutoCheckoutWindow(appointment)
  || isAfterAutoCheckoutWindow(appointment)
);

/**
 * Checks is an appointment is in the charge window for no shows
 * @param appointment
 * @returns {boolean}
 */
export const isWithinNoshowChargeWindow = (
  appointment: Pick<ProAppointment, 'local_start' | 'local_end' | 'is_autocheckout'>,
) => {
  if (appointment?.is_autocheckout) {
    return isWithinAutoCheckoutWindow(appointment);
  }
  return moment(appointment?.local_start).isBefore(moment());
};
// -- END AUTO CHECKOUT SECTION --

/**
 * Checks to see if current time is before the checkout window which ends start of tomorrow
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isBeforeCheckoutWindow = (
  appointment: Pick<ProAppointment, 'local_start'>,
): boolean => {
  const appointmentStartDate = moment(appointment.local_start);
  const windowStartDate = appointmentStartDate.clone().startOf('day');
  return moment().isBefore(windowStartDate);
};

/**
 * Checks to see if current time is after checkout window
 * which is either the end of the day or 6 hours past the appointment end time
 * if the appointment occurs within the last 6 hours of the day
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isAfterCheckoutWindow = (
  appointment: Pick<ProAppointment, 'local_end'>,
): boolean => {
  const appointmentEndDate = moment(appointment.local_end);
  const appointmentEndDateBuffer = appointmentEndDate.clone().add(6, 'hours');
  const endOfDay = appointmentEndDate.clone().endOf('day');
  const windowEndDate = (endOfDay.isBefore(appointmentEndDateBuffer))
    ? appointmentEndDateBuffer
    : endOfDay;
  return moment().isAfter(windowEndDate);
};

/**
 * Checks to see appointment is within checkout window
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isWithinCheckoutWindow = (
  appointment: Pick<ProAppointment, 'local_start' | 'local_end'>,
): boolean => !(
  isBeforeCheckoutWindow(appointment)
  || isAfterCheckoutWindow(appointment)
);

// -- PAYMENT METHOD STORAGE MANAGEMENT
// @TODO this should likely be managed some other way (redux, localforage at least)
export type StoredPaymentOption = PaymentOption | 'card_on_file';
type PaymentMethodStorage = Record<number, StoredPaymentOption>;
/**
 * Returns an appointments object from localStorage
 * @returns {Object} appointments
 */
const getAllAppointmentsPaymentMethod = (): PaymentMethodStorage => (
  JSON.parse(localStorage.getItem('appointmentsSelectedPaymentMethods') || '{}')
);

/**
 * Returns an appointment object with current appointment id from localStorage
 * @returns {string} paymentMethod
 */
const getAppointmentPaymentMethod = (
  appointmentId: number,
): StoredPaymentOption => {
  const appointmentsInLocalStorage = getAllAppointmentsPaymentMethod();
  return appointmentsInLocalStorage[appointmentId];
};

/**
 * Returns the last selected payment method from localStorage
 * @params {object} appointment object
 * @returns {string} previously selected payment method
 */
export const getLastSelectedPaymentMethod = (
  appointmentId: number,
): StoredPaymentOption => {
  const appointmentStorageDetails = getAppointmentPaymentMethod(appointmentId);
  return appointmentStorageDetails;
};

/**
 * Deletes the last selected payment method from a given appointment in localStorage
 * @params {object} appointment object
 */
export const deletePaymentMethod = (
  appointmentId: number,
) => {
  const appointmentsInLocalStorage = getAllAppointmentsPaymentMethod();
  delete appointmentsInLocalStorage[appointmentId];
  localStorage.setItem('appointmentsSelectedPaymentMethods', JSON.stringify(appointmentsInLocalStorage));
};

/**
 * Sets the last selected payment method into localStorage using the
 * appointment id as a reference
 * @param {object} appointment object
 * @param {object} provider object
 * @param {string} last selected payment method
 */
export const setLastSelectedPaymentMethod = (
  appointmentId: number,
  paymentMethod: StoredPaymentOption,
) => {
  const appointmentsInLocalStorage = getAllAppointmentsPaymentMethod();
  appointmentsInLocalStorage[appointmentId] = paymentMethod;
  localStorage.setItem('appointmentsSelectedPaymentMethods', JSON.stringify(appointmentsInLocalStorage));
};

/**
 * Gets a value indicating whether or not the appointment can be checked out through StyleSeat
 * payments for the current appointment. This is the case if a StyleSeat payments method is
 * selected or if nothing has been selected but the provider is payments eligible.
 * @param {Boolean} canProcessPayments True if the provider can process payments, otherwise
 * false.
 * @param {Appointment | Object} appointment The appointment object or raw data.
 * @returns {Boolean} True if the appointment can be checked out through StyleSeat payments,
 * otherwise false.
 */
export const isStyleSeatPaymentMethodAvailable = (
  canProcessPayments: boolean,
  appointmentId: number,
): boolean => {
  const paymentMethod = getLastSelectedPaymentMethod(appointmentId);
  const hasSelectedPaymentMethod = !!paymentMethod;
  const isCreditOrDebitCardSelected = (
    paymentMethod === 'stripe_connect' || paymentMethod === 'card_on_file'
  );

  return (
    isCreditOrDebitCardSelected
    // show card checkout if the pro is capable but hasn't explicitly
    // selected a payment method.
    || (canProcessPayments && !hasSelectedPaymentMethod)
  );
};

export type CheckoutTotals = {
  adjustedDiscountAmount: number;
  serviceCostWithSurcharge: number;
  serviceCostBeforeDiscount: number;
  serviceCostSumLessPromotions: number;
  isTotalAdjusted: boolean;
  depositAmount: number;
  subtotal: number;
  taxes: number;
  total: number;
  balanceRemaining: number;
  balanceRemainingBeforeDiscount: number;
  balanceRemainingBeforeServiceCostAdjustment: number;
  discountCode: string | null;
  discountAmount: number | null;
};

export const CHECKOUT_TOTALS_DEFAULTS: CheckoutTotals = {
  adjustedDiscountAmount: 0,
  serviceCostWithSurcharge: 0,
  serviceCostBeforeDiscount: 0,
  serviceCostSumLessPromotions: 0,
  isTotalAdjusted: false,
  depositAmount: 0,
  subtotal: 0,
  taxes: 0,
  total: 0,
  balanceRemaining: 0,
  balanceRemainingBeforeDiscount: 0,
  balanceRemainingBeforeServiceCostAdjustment: 0,
  discountCode: null,
  discountAmount: null,
};

export function paymentTypesAreDiscountable(paymentType: PaymentOption) {
  const discountableTypes = [
    'credit',
    'stripe_connect',
  ];
  return discountableTypes.includes(paymentType);
}

export function transformAppointmentTotals(
  appointmentTotals: AppointmentTotals,
): CheckoutTotals {
  return {
    adjustedDiscountAmount: Number(appointmentTotals.adjusted_discount_amount),
    serviceCostWithSurcharge: Number(appointmentTotals.service_cost_with_surcharge),
    serviceCostBeforeDiscount: Number(appointmentTotals.service_cost_before_discount),
    serviceCostSumLessPromotions: Number(appointmentTotals.service_cost_sum_less_promotions),
    isTotalAdjusted: appointmentTotals.is_total_adjusted,
    depositAmount: Number(appointmentTotals.deposit_amount),
    subtotal: Number(appointmentTotals.subtotal),
    taxes: Number(appointmentTotals.taxes),
    total: Number(appointmentTotals.total),
    balanceRemaining: Number(appointmentTotals.balance_remaining),
    balanceRemainingBeforeDiscount: Number(appointmentTotals.balance_remaining_before_discount),
    balanceRemainingBeforeServiceCostAdjustment: (
      Number(appointmentTotals.balance_remaining_before_service_cost_adjustment)
    ),
    discountCode: appointmentTotals.discount_code,
    discountAmount: (
      appointmentTotals.discount_amount ? Number(appointmentTotals.discount_amount) : 0
    ),
  };
}

export type CheckoutState = {
  tip: string;
  chargeTipLater: boolean;
  paymentType: PaymentOption;
  card?: ICard;
  cardOnFile?: ClientCardOnFile;
};

export type CheckoutDetails = ProAppointment & CheckoutTotals & CheckoutState;

// Consider updating this to account for "Frozen" appointments having all totals
// encoded into the checkout obect already
export function buildCheckoutDetails(
  appointment: ProAppointmentDetails,
  checkoutState: CheckoutState,
  appointmentTotals: AppointmentTotals,
): CheckoutDetails | undefined {
  if (
    !appointment?.id || !appointment.provider || !appointmentTotals
  ) {
    return undefined;
  }

  const checkoutTotals = transformAppointmentTotals(appointmentTotals);

  return {
    ...appointment,
    ...checkoutState,
    ...checkoutTotals,
    discountCode: paymentTypesAreDiscountable(checkoutState.paymentType)
      ? appointmentTotals.discount_code
      : null,
    discountAmount: paymentTypesAreDiscountable(checkoutState.paymentType)
      ? Number(appointmentTotals.discount_amount || 0)
      : 0,
  };
}

export function buildCheckoutTrackingParams(
  checkout: CheckoutDetails,
  pro: PrivilegedProvider,
) {
  let proAutochargeMethod = null;
  let providerPremiumUpchargeRate = 0;

  if (pro.autocharge_enabled_time || pro.can_process_payments) {
    if (pro.autocheckout_enabled) {
      proAutochargeMethod = 'automatic';
    } else {
      proAutochargeMethod = 'manual';
    }
    providerPremiumUpchargeRate = pro.premium_upcharge_rate;
  }

  return {
    client_id: checkout.client.id,
    appointment_id: checkout.id,
    payment_method: checkout.paymentType,
    client_cc_last4: checkout.card?.last4,
    checkout_type: checkout.checkout?.creation_source,
    pro_checkout_setting: proAutochargeMethod,
    service_cost: checkout.cost,
    surcharge: checkout.surcharge,
    product_cost: checkout.product_cost,
    tip_amount: checkout.tip,
    prepay_amount: checkout.prepay_amount,
    is_premium_appt: checkout.is_premium_appointment,
    premium_upcharge_rate: checkout.is_premium_appointment ? providerPremiumUpchargeRate : 0,
    discount_code: checkout.discountCode,
    is_deposit_appt: checkout.depositAmount > 0,
  };
}

/**
 * This function helps determine if the card on file *can* be used for checkout
 * (Not necessarily that it *is* being used for checkout)
 * To find if the card is selected for checkout, use the ProviderManageAppointment
 * rematch model's `isUsingCardOnFile` selector
 */
export const canUseCardOnFile = (
  appointment: Pick<ProAppointmentDetails, 'id'>,
  provider: Pick<PrivilegedProvider, 'autocheckout_enabled' | 'can_process_payments'>,
) => {
  const lastSelectedPaymentOption = getLastSelectedPaymentMethod(appointment.id);
  return provider.can_process_payments && (
    provider.autocheckout_enabled
    || !lastSelectedPaymentOption
    || lastSelectedPaymentOption === PaymentMethodType.CardOnFile
  );
};

/**
 * Get the initial payment method for an appointment, rules applied in this order:
 *
 * 1. Klarna is always klarna payment type
 * 2. If there is a card on file and autocheckout or there's no other saved method,
 *    use card on file (Stripe)
 * 3. If there's a saved method for payment in local storage, use that
 * 4. If the provider can process payments, use Stripe
 * 5. If all else fails, use cash
 */
export const getPaymentType = (
  appointment: ProAppointmentDetails,
  provider: PrivilegedProvider,
  card: ICard | ClientCardOnFile | null,
) => {
  if (bookedWithKlarna(appointment)) {
    return PaymentMethodType.Klarna;
  }
  const lastSelectedPaymentOption = getLastSelectedPaymentMethod(appointment.id);

  if (
    !!card
    && canUseCardOnFile(appointment, provider)
  ) {
    return PaymentMethodType.StripeConnect;
  }

  if (lastSelectedPaymentOption && lastSelectedPaymentOption !== PaymentMethodType.CardOnFile) {
    return lastSelectedPaymentOption;
  }

  if (provider.can_process_payments) {
    return PaymentMethodType.StripeConnect;
  }

  return PaymentMethodType.Cash;
};
