// Switches and flags
export const CHAT_GENERAL_SWITCH = 'general_messaging_beta_enabled_com911_20221026';
export const CHAT_TOUR_SWITCH = 'pro_messaging_tour_enabled_com911_20221026';
export const CHAT_CLIENT_INBOX_SWITCH = 'client_messaging_inbox_enabled_com1038_20230302';
export const REPORT_BUTTON_SWITCH = 'chat_report_button_enabled_com1048_20230310';
export const BLOCK_BUTTON_SWITCH = 'chat_block_button_enabled_com1200_20230605';
export const CHAT_UNREAD_BANNER_SWITCH = 'pro_messaging_unread_banner_com1059_20230317';
export const PRO_DESKTOP_CHAT_SWITCH = 'pro_desktop_chat_com942_20230424';
export const CHAT_IDS_MIGRATION_DONE = 'chat_migrations_done_com1137_20230419';

// User state actions for state manager
export const CHAT_TOUR_VIEWED = 'chat_tour_viewed';
export const CHAT_WELCOME_MESSAGE_SENT = 'chat_welcome_message_sent';
export const CHAT_CLIENT_HAS_SENT_MESSAGE = 'chat_client_has_sent_message';
export const CHAT_CLIENT_HAS_SEEN_FAKE_DOOR = 'chat_client_has_seen_fake_door';

// Storage keys
export const PROS_RESPONSIVENESS = 'PROS_RESPONSIVENESS';
