import { API_ROOT } from '../../../config';
import ssFetch from '../../../modules/ssFetch';

export function undoCheckout(
  providerId: number,
  appointmentId: number,
) {
  const url = new URL(
    `/api/v2.1/providers/${providerId}/appointments/${appointmentId}/undo_checkout`,
    API_ROOT,
  );

  return ssFetch(url.toString(), {
    method: 'POST',
  });
}
