// @ts-strict-ignore
/**
 * Resolves a promise once the DOM is fully loaded and ready for subscription
 * @param value The value to return when we can start listening to DOM changes
 * @param interval Interval between checks for readiness
 * @param timeout Maximum amount of time to wait for DOM to be "ready"
 * @yields The value initially passed in
 */
export async function waitForReady<T = any>(value: T, interval = 50, timeout = 500): Promise<T> {
  const endTime = Date.now() + timeout;
  const isDomReady = ((resolve, reject) => {
    if (document?.readyState === 'complete') {
      resolve(value);
    } else if (Date.now() < endTime) {
      setTimeout(() => isDomReady(resolve, reject), interval);
    } else {
      reject(new Error('timed out waiting for DOM to be ready'));
    }
  });

  return new Promise(isDomReady);
}
