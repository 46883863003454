// @ts-strict-ignore
import moment from 'moment';
import { loadSignupSettings } from '../../../api/Providers/SignupSettings';
import { ICard, InstrumentType } from '../../../store/PaymentMethods';
import analytics from '../../analytics';

import { IProviderSignupSettingsData } from '../../Api.types';

/**
 * Subscription status values calculated from subscription_state
 */
export enum SubscriptionStatus {
  Inactive = 'inactive',
  Active = 'active',
  Canceled = 'canceled',
  Expired = 'expired',
}

/**
 * Possible value of a subscription_state on a provider.
 * Pro is trialing without a card.
 *
 * Pro signed up, but hasn't added a card. As a result,
 * the pro doesn't have a Stripe subscription.
 */
export const TRIALING_WITHOUT_CARD: string = 'TRIALING_WITHOUT_CARD';

/**
 * Possible value of a subscription_state on a provider.
 * Pro has canceled, while trialing without a card.
 *
 * Pro signed up, but hasn't added a card. As a result,
 * the pro doesn't have a Stripe subscription. Pro then
 * opted to cancel their subscription (likely by speaking
 * with CS). Their subscription is not yet expired.
 */
export const CANCELED_AND_TRIALING_WITHOUT_CARD: string = (
  'CANCELED_AND_TRIALING_WITHOUT_CARD');

/**
 * Possible value of a subscription_state on a provider.
 * Pro has canceled, while trialing without a card, and is now expired.
 *
 * Pro signed up, but hasn't added a card. As a result,
 * the pro doesn't have a Stripe subscription. Pro then
 * opted to cancel their subscription (likely by speaking
 * with CS). Their subscription is expired.
 */
export const CANCELED_WHILE_TRIALING_WITHOUT_CARD_AND_EXPIRED: string = (
  'CANCELED_WHILE_TRIALING_WITHOUT_CARD_AND_EXPIRED');

/**
 * Possible value of a subscription_state on a provider.
 * Pro is trialing with a card.
 *
 * Pro signed up, entered a card, is still trialing, and has
 * a Stripe subscription.
 */
export const TRIALING_WITH_CARD: string = 'TRIALING_WITH_CARD';

/**
 * Possible value of a subscription_state on a provider.
 * Pro has canceled, while trialing with a card.
 *
 * Pro signed up and added a card. As a result,
 * the pro does have a Stripe subscription. Pro then
 * opted to cancel their subscription. Their subscription is not yet expired.
 */
export const CANCELED_AND_TRIALING_WITH_CARD: string = (
  'CANCELED_AND_TRIALING_WITH_CARD');

/**
 * Possible value of a subscription_state on a provider.
 * Pro has canceled, while trialing with a card, and is now expired.
 *
 * Pro signed up and added a card. As a result,
 * the pro does have a Stripe subscription. Pro then
 * opted to cancel their subscription. Their subscription is expired.
 */
export const CANCELED_WHILE_TRIALING_WITH_CARD_AND_EXPIRED: string = (
  'CANCELED_WHILE_TRIALING_WITH_CARD_AND_EXPIRED');

/**
 * Possible value of a subscription_state on a provider.
 * Pro has a trial that expired.
 *
 * Pro signed up, and their trial expired before they added a card.
 */
export const TRIAL_EXPIRED: string = 'TRIAL_EXPIRED';

/**
 * Possible value of a subscription_state on a provider.
 * Pro has an active subscription.
 *
 * Pro signed up, entered a card, and is out of the trial period.
 */
export const ACTIVE_MEMBER: string = 'ACTIVE_MEMBER';

/**
 * Possible value of a subscription_state on a provider.
 * Pro is in the grace period with a past due invoice.
 *
 * Pro signed up and entered a card. Their payment method became invalid
 * and their account went to "Past Due" in Stripe once their subscription
 * expired. The pro does not have a pending payment.
 */
export const GRACE_PERIOD: string = 'GRACE_PERIOD';

/**
 * Possible value of a subscription_state on a provider.
 * Pro is past the grace period with an unpaid invoice.
 *
 * Pro signed up and entered a card. Their payment method became invalid
 * and their account went to "Past Due" in Stripe once their subscription
 * expired. The pro got to keep using StyleSeat through their grace period.
 * Now that grace period has expired and their invoice is considered "Unpaid".
 */
export const GRACE_PERIOD_EXPIRED: string = 'GRACE_PERIOD_EXPIRED';

/**
 * Possible value of a subscription_state on a provider.
 * Pro has cancelled their subscription, but their subscription is still active.
 *
 * Pro signed up and entered a card. They've canceled their subscription
 * but their subscription is still active until their paid month is over.
 */
export const CANCELED_AND_ACTIVE: string = 'CANCELED_AND_ACTIVE';

/**
 * Possible value of a subscription_state on a provider.
 * Pro has cancelled their subscription, and their subscription is expired.
 *
 * Pro signed up and entered a card. They've canceled their subscription
 * and their subscription is no longer active.
 */
export const CANCELED_AND_EXPIRED: string = 'CANCELED_AND_EXPIRED';

/**
 * Possible value of a subscription_state on a provider.
 * Pro has no active subscription
 *
 * Pro signed up, was on a subscription in either trial or paid subscription
 * cancels their subscription themselves.
 */
export const CANCELED: string = 'CANCELED';

/**
 * Calculate the days remaining for a pro's trial.
 */
function getTrialDaysRemaining(data: IProviderSignupSettingsData): number {
  // Make sure that we have a subscription trial time remaining
  // and a subscription end time
  const { subscription_trial_time_remaining: timeRemainingString } = data;
  if (timeRemainingString === null) return 0;
  const timeRemaining = parseFloat(timeRemainingString);
  return Math.ceil(moment.duration(timeRemaining * 1000).asDays());
}

/**
 * Calculate the days remaining for a pro's subscription.
 */
function getSubscriptionDaysRemaining(data: IProviderSignupSettingsData): number {
  const endTime = data.subscription_end_time;
  if (endTime === null) return 0;
  return Math.ceil(
    // @ts-ignore
    moment.duration(moment.utc(endTime).subtract(moment.utc())).asDays(),
  );
}

/**
 * Calculate the days remaining for a pro's trial or subscription
 * (whichever is longer).
 */
export function getDaysRemaining(data: IProviderSignupSettingsData): number {
  return Math.max(
    0,
    getTrialDaysRemaining(data),
    getSubscriptionDaysRemaining(data),
  );
}

/**
 * Calculate if the trial is at least a week old
 */
export function getIntoLateTrial(data: IProviderSignupSettingsData): boolean {
  const trialStartTime = data.preview_subscription_trial_start_time;

  return !!(
    trialStartTime
    && moment
      .utc(trialStartTime)
      .clone()
      .add(7, 'days') <= moment.utc()
  );
}

/**
 * Calculate the price of the membership.
 */
export function getPrice(data: IProviderSignupSettingsData): number {
  return data.preview_subscription_plan_amount
    ? Math.round(parseFloat(data.preview_subscription_plan_amount))
    : 0;
}

/**
 * Get current subscription status.
 *
 * @param {Object} settings - signup settings data
 * @returns {string} status - can be one of:
 *    - inactive: never subscribed
 *    - active: actively subscribed
 *    - canceled: actively subscribed but subscription will end
 *    - expired: previously subscribed
 */
export function getStatusFromSubscriptionState(
  subscriptionState: string,
): SubscriptionStatus {
  switch (subscriptionState) {
    case 'ACTIVE_MEMBER':
    case 'GROUP_MEMBER':
    case 'PENDING_PAYMENT':
    case 'GRACE_PERIOD':
    case 'NO_MEMBERSHIP':
    case 'TRIALING_WITH_CARD':
      return SubscriptionStatus.Active;
    case 'TRIALING_WITHOUT_CARD':
    case 'TRIAL_EXPIRED':
      return SubscriptionStatus.Inactive;
    case 'CANCELED_AND_EXPIRED':
    case 'CANCELED_WHILE_TRIALING_WITHOUT_CARD_AND_EXPIRED':
    case 'CANCELED_WHILE_TRIALING_WITH_CARD_AND_EXPIRED':
    case 'GRACE_PERIOD_EXPIRED':
    case 'REMOVED_GROUP_MEMBER':
      return SubscriptionStatus.Expired;
    case 'CANCELED_AND_TRIALING_WITHOUT_CARD':
    case 'CANCELED_AND_ACTIVE':
    case 'CANCELED_AND_TRIALING_WITH_CARD':
      return SubscriptionStatus.Canceled;
    default:
      return undefined;
  }
}

/**
 * A model for calculating a provider's subscription state.
 */
export class Subscription {
  data: IProviderSignupSettingsData;

  provider_id: number;

  price: number;

  subscription_state: string | null;

  daysRemaining: string;

  isIntoLateTrial: boolean;

  requiresPaymentUpdate: boolean;

  status: string;

  constructor(
    providerId: number | string,
    data: IProviderSignupSettingsData,
  ) {
    const proId: number = typeof providerId === 'string'
      ? parseInt(providerId, 10)
      : providerId;
    this.data = data;
    this.provider_id = proId;
    this.subscription_state = this.data.subscription_state;
    this.price = getPrice(data);
    this.isIntoLateTrial = getIntoLateTrial(data);
    const days = getDaysRemaining(data);
    this.daysRemaining = days === 1 ? `${days} day` : `${days} days`;
    this.requiresPaymentUpdate = this.data.requires_payment_update;
    this.status = getStatusFromSubscriptionState(this.subscription_state);
  }

  /**
   * Determines whether the pro should see any subscription-related features in product. This is the
   * case as long as their trial start date is in the past.
   *
   * @returns True if enabled, otherwise false
   */
  isPastTrialStartTime(): boolean {
    const trialStartTime = this.data.preview_subscription_trial_start_time;
    return !!(
      trialStartTime
      && moment
        .utc(trialStartTime) <= moment.utc()
    );
  }

  /**
   * Calculate whether the pro is in the early phase of their trial
   * that is, less than a week into their trial, and is trialing
   * without a card.
   *
   * @returns {Boolean}
   */
  isEarlyTrial(): boolean {
    return (
      !this.isIntoLateTrial
      && this.data.subscription_state === TRIALING_WITHOUT_CARD
    );
  }

  /**
   * Calculate whether the pro is in any phase of their trial
   *
   * @returns {Boolean}
   */
  isTrial(): boolean {
    return (
      this.data.subscription_state === TRIALING_WITHOUT_CARD
      || this.data.subscription_state === TRIALING_WITH_CARD
    );
  }

  /**
   * Calculate whether the pro has had their trial expire.
   *
   * @returns {Boolean}
   */
  isTrialExpired(): boolean {
    return this.data.subscription_state === TRIAL_EXPIRED;
  }

  /**
   * True if is inactive status, past trial start time, and subscription trial not expired
   */
  isInTrialPeriod(): boolean {
    return this.status === SubscriptionStatus.Inactive
      && !this.data.subscription_trial_expired
      // Ensure that we are past the trial start time (if exists),
      // so that we don't scoop up pros who are on free plans (or current recurly pros)
      && this.isPastTrialStartTime();
  }

  /**
   * Calculate whether the pro is in the grace period.
   *
   * @returns {Boolean}
   */
  isGracePeriod(): boolean {
    return this.data.subscription_state === GRACE_PERIOD;
  }

  /**
   * Calculate whether the pro is canceled and active.
   *
   * @returns {Boolean}
   */
  isCanceledAndNotExpired(): boolean {
    return (
      this.data.subscription_state === CANCELED_AND_ACTIVE
      || this.data.subscription_state === CANCELED_AND_TRIALING_WITHOUT_CARD
      || this.data.subscription_state === CANCELED_AND_TRIALING_WITH_CARD
    );
  }

  /**
   * Calculate whether the pro is canceled and active.
   *
   * @returns {Boolean}
   */
  isCanceledAndExpired(): boolean {
    return (
      this.data.subscription_state === CANCELED_AND_EXPIRED
      || this.data.subscription_state
      === CANCELED_WHILE_TRIALING_WITHOUT_CARD_AND_EXPIRED
      || this.data.subscription_state
      === CANCELED_WHILE_TRIALING_WITH_CARD_AND_EXPIRED
      || this.data.subscription_state === GRACE_PERIOD_EXPIRED
    );
  }

  isCanceled(): boolean {
    return this.data.subscription_state === CANCELED;
  }

  /**
   * Calculates the discounted price
   * @returns Subscription amount with discount applied
   */
  discountAmount(): number {
    const fullPrice = parseInt(this.data.preview_subscription_plan_amount, 10);
    const discount = parseInt(this.data.preview_subscription_discount_amount, 10);

    if (!this.data.preview_subscription_discount_amount || !discount) {
      return 0;
    }
    return fullPrice - discount;
  }

  /**
   * Calculates percent discount
   * @returns Number as percent discount
   */
  discountPercent(): number {
    const fullPrice = parseInt(this.data.preview_subscription_plan_amount, 10);
    const discount = parseInt(this.data.preview_subscription_discount_amount, 10);

    if (!this.data.preview_subscription_discount_amount || !discount) {
      return 0;
    }

    return Math.round((discount / fullPrice) * 100);
  }
}

/**
 * Turn signup settings into a Subscription object
 *
 * @param {number} providerId The provider ID
 * @returns {Promise<Subscription>}
 */
export async function loadSubscription(
  providerId: number,
): Promise<Subscription> {
  const signupSettingsData = await loadSignupSettings(providerId);
  return new Subscription(providerId, signupSettingsData);
}

/**
 * Gets the type of payment method selected
 * @param   {Object} method The payment method (cc or bank acct)
 * @returns {String}        'bank' or 'cc'
 */
function getMethodType(method) {
  if (method) {
    if ([
      InstrumentType.CreditCard,
      InstrumentType.DebitCard,
      InstrumentType.PrepaidCard,
      InstrumentType.Card,
    ].includes(method.instrument_type)) {
      return 'cc';
    }

    if (method.instrument_type === InstrumentType.BankAccount) {
      return 'bank';
    }
  }

  return null;
}

/**
 * Handles update plan success response
 * @param signupSettings Subscription details
 * @param paymentMethod The payment method
 * @param {Boolean} isFirstSubscribe is a new subscription
 */
export function trackSubscriptionSuccess(
  signupSettings: IProviderSignupSettingsData,
  paymentMethod: ICard,
  isFirstSubscribe: boolean,
) {
  const amount = Math.round(Number(signupSettings.preview_subscription_plan_amount));
  const discountAmount = signupSettings.preview_subscription_discount_amount;
  const discountDuration = signupSettings.preview_subscription_discount_period_months;
  const planName = signupSettings.preview_subscription_plan_name;

  const params = {
    payment_method: getMethodType(paymentMethod),
    discount_amount: discountAmount,
    discount_period_months: discountDuration,
    plan_amount: amount,
    plan_name: planName,
  };

  if (isFirstSubscribe) {
    analytics.track('P Subscription Success', params);
    analytics.track('AddPaymentInfo', {
      value: amount,
      currency: 'USD',
    });
  } else {
    analytics.track('P Subscription Modified', params);
  }
}

export function trackSubscriptionAddCreditCard(card: ICard) {
  analytics.track('P Selected Payment Method', {
    payment_method: getMethodType(card),
  });
}
