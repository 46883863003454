// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from '../models';
import nonCriticalException from '../../modules/exceptionLogger';

import {
  ApiWorkPeriod,
  createWorkPeriods,
  deleteManyWorkPeriods,
  getAllWorkPeriods,
} from '../../api/Providers/WorkPeriods';
import { ParsedWorkPeriods, parseWorkPeriods } from '../../modules/provider/WorkPeriod';

type State = {
  loading: boolean;
  result?: ApiWorkPeriod[];
  errorMessage?: string;
};

export const providerWorkPeriods = createModel<RootModel>()({
  name: 'providerWorkPeriods',

  state: {
    loading: true,
    result: [],
  } as State,

  reducers: {
    onLoading: (state: State) => ({
      ...state,
      loading: true,
    }),
    onLoaded: (state: State, payload: { workPeriods: ApiWorkPeriod[] }) => ({
      ...state,
      loading: false,
      result: payload.workPeriods,
    }),
    onCreated: (state: State, payload: { workPeriods: ApiWorkPeriod[] }) => ({
      ...state,
      loading: false,
      result: [
        ...state.result,
        ...payload.workPeriods,
      ],
    }),
    onDeleted: (state: State, payload: { workPeriodIds: number[] }) => ({
      ...state,
      loading: false,
      result: state.result.filter(workPeriod => !payload.workPeriodIds.includes(workPeriod.id)),
    }),
    onFailed: (state: State, payload: { errorMessage: string }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
  },

  effects: dispatch => ({
    load: async (payload: { providerId: number }) => {
      const { providerId } = payload;
      try {
        await dispatch.providerWorkPeriods.onLoading();
        const workPeriods = await getAllWorkPeriods(providerId);
        await dispatch.providerWorkPeriods.onLoaded({ workPeriods });
      } catch (error) {
        nonCriticalException(error);
        dispatch.providerWorkPeriods.onFailed({ errorMessage: error.message });
      }
    },
    create: async (
      payload: { providerId: number; workPeriods: Omit<ApiWorkPeriod, 'id'>[] },
    ) => {
      const { providerId, workPeriods } = payload;
      try {
        const apiWorkPeriods = await createWorkPeriods(providerId, workPeriods);
        dispatch.providerWorkPeriods.onCreated({ workPeriods: apiWorkPeriods });
      } catch (error) {
        nonCriticalException(error);
        dispatch.providerWorkPeriods.onFailed({ errorMessage: error.message });
      }
    },
    delete: async (
      payload: { providerId: number; workPeriodIds: number[] },
    ) => {
      const { providerId, workPeriodIds } = payload;
      try {
        await deleteManyWorkPeriods(providerId, workPeriodIds);
        dispatch.providerWorkPeriods.onDeleted({ workPeriodIds });
      } catch (error) {
        nonCriticalException(error);
        dispatch.providerWorkPeriods.onFailed({ errorMessage: error.message });
      }
    },
  }),

  selectors: slice => ({
    getAll() {
      return slice((state): ParsedWorkPeriods => parseWorkPeriods(state.result));
    },
  }),
});
