import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const ClientProprofileBookClick: SegmentEventData = {
  eventName: AnalyticEvent.ProfileBookClicked,
  eventNameLegacy: 'client_proprofile_book_click',
  eventProperties: [
    'provider_id',
    'service_name',
    'profile_type',
    'service_id',
  ],
};

export default ClientProprofileBookClick;
