import type { NCD, ReminderType } from '../Appointments';
import { DateTimeStamp } from '../../../types/dateTime';
import type { PrivacyChoices } from '../GalleryImages';

export enum ProviderClientPhoneType {
  Mobile = 0,
  Home = 1,
  Work = 2,
}

export interface IProviderClientPhone {
  id: number;
  type: ProviderClientPhoneType;
  number: string;
}

export interface ISMSStatus {
  mobile_phone_number: {
    national: string;
    e164: string;
  };
  appointment_sms_enabled: boolean;
  appointment_sms_opted_out: boolean;
  appointment_sms_requested: boolean;
}

export interface IProviderClientListEntry {
  id: number;
  name: string;
}

export interface IProviderClient extends IProviderClientListEntry {
  address1: string | null;
  address2: string | null;
  birth_day: number | null;
  birth_month: number | null;
  blocked: boolean;
  city: string | null;
  creation_source: number;
  creation_time: string;
  default_tip_percent: number;
  email: string | null;
  is_unconfirmed_ncd: boolean;
  ncd: NCD | null;
  phones: IProviderClientPhone[];
  preferred_checkout_type: number;
  preferred_pronouns: string | null;
  provider: number;
  reminder_preference: ReminderType;
  sms_status: ISMSStatus | null;
  state: string | null;
  thumbnail: string | null;
  user: number | null;
  zipcode: string | null;
}

export function isFullClient(
  client: IProviderClient | IProviderClientListEntry,
): client is IProviderClient {
  return (
    (client as IProviderClient).address1 !== undefined
    || (client as IProviderClient).phones !== undefined
  );
}

export interface IClientNote {
  field: string;
  value: string;
  client: number;
  id: number;
}

export interface IHistoryAppointment {
  type: 'appointment';
  id: number;
  start: DateTimeStamp;
  end: DateTimeStamp;
  local_start: DateTimeStamp;
  local_end: DateTimeStamp;
  service_name: string;
  note: string;
  client_note: string;
  is_no_show: boolean;
  canceled: boolean;
  nslc_charge_declined: boolean;
  is_checked_out_late_cancel: boolean;
  is_checked_out_noshow: boolean;
  noshow_charge: string | number;
  cancellation_charge: string | number;
  creation_time?: DateTimeStamp;
  is_refundable: boolean;
}

export interface IHistoryNote {
  type: 'note';
  id: number;
  field: string;
  value: string;
  color: string;
  creation_time: DateTimeStamp; // UTC
}

export interface IHistoryPhoto {
  type: 'photo';
  id: number;
  thumbnail: string;
  url: string;
  privacy_status: PrivacyChoices;
  heart_count: number;
  blurb: string;
  creation_time: DateTimeStamp; // UTC
}

export type HistoryRecord = IHistoryAppointment | IHistoryNote | IHistoryPhoto;

export function isAppointment(record: HistoryRecord): record is IHistoryAppointment {
  return record.type === 'appointment';
}

export function isNote(record: HistoryRecord): record is IHistoryNote {
  return record.type === 'note';
}

export function isPhoto(record: HistoryRecord): record is IHistoryPhoto {
  return record.type === 'photo';
}
