import {
  getAndroidVersion,
  getIOSVersion,
  getAppVersion,
} from '../../../modules/AppInfo';
import { APP } from '../../../config';
import { InterceptorConfig, SSFetchError } from '../../../modules/ssFetch';
import Router from '../../../../../packages/router';
import { EXPIRED_APP_BLOCKER_ROUTE } from '../../../route-names';

const EXPIRED_APP_STATUS_CODE = 418;

/**
 * Intercept requests and adds app version information for the backend
 * Intercepts responses to check if the current app version is expired
 */
export const appVersionInterceptor: InterceptorConfig = {
  request: req => {
    req.headers.set('SS-App', APP ? 'native' : 'web');
    req.headers.set('SS-App-Version', getAppVersion());

    if (getAndroidVersion()) {
      req.headers.set('SS-Android-Version', getAndroidVersion());
    }

    if (getIOSVersion()) {
      req.headers.set('SS-IOS-Version', getIOSVersion());
    }

    return req;
  },
  response: res => {
    if (res.status === EXPIRED_APP_STATUS_CODE) {
      if (!APP) {
        window?.location?.reload();
      } else if (Router.getCurrentRoute()?.name !== EXPIRED_APP_BLOCKER_ROUTE) {
        Router.go(EXPIRED_APP_BLOCKER_ROUTE);
      }
      throw new SSFetchError('App is expired', EXPIRED_APP_STATUS_CODE, 'App is Expired');
    }

    return res;
  },
};
