import { Moment } from '../../dateUtils';

export type ProviderOnboardingPromptStatus = {
  prompt_for_payments_onboarding: boolean;
  prompt_for_availability: boolean;
  prompt_for_clients: boolean;
  prompt_for_location: boolean;
  prompt_for_appointments: boolean;
  prompt_for_google_calendar: boolean;
  prompt_for_setup_payments: boolean;
  prompt_for_profile_photo: boolean;
  prompt_for_gallery_image: boolean;
  prompt_for_subscribe?: boolean;
  prompt_for_services: boolean;
};

export type ProviderOnboardingStatus = {
  promptStatus: ProviderOnboardingPromptStatus;
  percentComplete: number;
};

export type SignupCountdownInfo = {
  showCountdown: boolean;
  cutoff?: Moment;
};

export enum OverallStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed = 'completed',
  Published = 'published',
}

export enum StepStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed = 'completed',
  Optional = 'optional',
}

export enum Step {
  Signup = 'signup',
  ProfilePhoto = 'profile_photo',
  Address = 'location',
  Services = 'services',
  Availability = 'availability',
  GalleryImage = 'gallery_image',
  PaymentsOnboarding = 'payments_onboarding',
  SetupSubscription = 'setup_subscription',
  Subscribe = 'subscribe', // Select plan page
  PlanSelect = 'plan_select', // Select plan page
  FreeTrialExplanation = 'free_trial',
  // The below steps are actually sub-steps of the payments onboarding larger step
  IdentityVerify = 'identity_verify',
  MailingAddress = 'mailing_address',
  BankAccount = 'bank_account',
  GoalSelect = 'goal_select',
}

export type ProviderOnboardingSteps = Partial<Record<Step, {
  status: StepStatus;
  selectedValue?: unknown;
}>>;

export enum OnboardingGoal {
  NewClients = 'new_clients',
  ManageClients = 'manage_clients',
  OnboardingGoals = 'onboarding_goals',

}

export type ProviderOnboardingResponse = {
  providerId: number;
  status: OverallStatus;
  appUpgradeRequired: boolean;
  stepOrder: Step[];
  steps: ProviderOnboardingSteps;
};

export type ProviderOnboardingUpdateRequest = {
  explicit_plan_selected?: boolean;
  goal_selected?: OnboardingGoal;
};

export type ProviderOnboardingDetailsResponse = {
  profile_published: boolean;
  explicit_plan_selected: boolean;
  version_started_on?: string;
  version_ended_on?: string;
  goal_selected: OnboardingGoal;
};
