import { useCallback } from 'react';
import moment from 'moment';
import { recordServiceInteraction, loadProvider } from '../modules/Api';
import FeatureFlags from '../modules/FeatureFlags';

export type ServiceInteractionCounterData = (
  providerId: number,
  serviceId: number,
  isEditMode: boolean
) => void;

const FLAG = 'pro_client_service_interaction_notification_fly_2685';

const useServiceInteractionCounter = (): ServiceInteractionCounterData => {
  return useCallback(async (providerId, serviceId, isEditMode) => {
    if (isEditMode) {
      return;
    }

    const { creation_time: creationTime } = await loadProvider(providerId);
    const inTime = moment().diff(moment(creationTime), 'months') < 6;

    if ((await FeatureFlags.isEnabled(FLAG)) && inTime) {
      recordServiceInteraction(providerId, serviceId);
    }
  }, []);
};

export default useServiceInteractionCounter;
