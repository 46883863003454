/* eslint-disable */
/**
 * This module provides a mechanism for locking interdependent asynchronous work.
 *
 * Lock acquisition requests are enqueued if a lock is already held, and locks are released to requests in FIFO order.
 *
 * Usage:
 *
 * let lock = new PromiseLock();
 *
 * let resolve, reject, promise = new Promise((res, rej) => {
 *   resolve = res;
 *   reject = rej;
 * });
 *
 * // If acquired with acquireForPromise, the lock will be released
 * // automatically when `promise` is resolved or rejected.
 *
 * lock.acquireForPromise(promise).then(() => {
 *   doSomething().then(resolve).catch(reject);
 * });
 *
 *
 * // If more fine-grained control over locking is desired, acquireToken()
 * // can be used to get a token that will hold the lock until explicitly released.
 * // Take great care that you do release the token in all code paths, otherwise your lock
 * // will never be released to other resources.  A timeout should be used when possible
 * // as a fallback to ensure bugs are not catastrophic.
 *
 * lock.acquireToken(10, 'Unreleased lock token for doSomething()').then((token)=>{
 *   doSomething.finally(() => token.release()));
 * });
 *
 * */

export default class PromiseLock {
  constructor() {
    this.currentToken = null;
    this.tokenQueue = [];
  }

  /**
   * Give the lock to the next token in the queue.
   */
  startNext() {
    this.currentToken = this.tokenQueue.shift() || null;
    if (this.currentToken) {
      this.currentToken.start();
    }
  }

  /**
   * Create a new token and return a promise that is resolved when it has acquired the lock.
   *
   * @param {Promise} promise The promise which when resolved or rejected will release the lock.
   * @param {Number} [timeout] The number of ms to wait before releasing the lock once acquired.
   * @param {String} [timeoutMessage] A message to log if the lock is released via timeout.
   * @returns {Promise}
   */
  acquireForPromise(promise, timeout, timeoutMessage) {
    if (!promise || !promise.then) {
      throw new Error(
        'acquireForPromise requires a promise as the first argument'
      );
    }
    const token = new Token(this, timeout, timeoutMessage);
    this.tokenQueue.push(token);
    // release the lock whether the promise is resolved or rejected
    promise.finally(() => {
      token.release();
    });
    // start it if no other tokens are in progress
    if (!this.currentToken) {
      this.startNext();
    }
    return token.promise;
  }

  /**
   * Create a new token and return a promise that is resolved when it has acquired the lock.
   *
   * @param timeout [Number] - the number of ms to wait before releasing the lock once acquired.
   * @param timeoutMessage [String] - a message to log if the lock is released via timeout.
   * @returns {Promise}
   */
  acquireToken(timeout, timeoutMessage) {
    const token = new Token(this, timeout, timeoutMessage);
    this.tokenQueue.push(token);
    // start it if no other tokens are in progress
    if (!this.currentToken) {
      this.startNext();
    }
    return token.promise;
  }
}

class Token {
  constructor(lock, timeout, timeoutMessage) {
    if (this.timeout != undefined && typeof timeout !== 'number') {
      throw new Error(
        'Invalid Token construction. See PromiseLock documentation for proper use.'
      );
    }
    const token = this;
    this.lock = lock;
    this.timeout = timeout;
    this.timeoutMessage = timeoutMessage;
    this.promise = new Promise(resolve => {
      token.resolve = () => {
        resolve(token);
      };
    });
    this.timer = null;
  }

  /**
   * Resolves the promise, indicating the lock is acquired.
   */
  start() {
    const token = this;
    if (typeof this.timeout === 'number') {
      this.timer = setTimeout(() => {
        token.release();
        console.warn(
          token.timeoutMessage || 'PromiseLock token timed out before release.'
        );
      }, this.timeout);
    }
    this.resolve(this);
  }

  /**
   * If the token being released is the holder of the lock (`currentToken`), this method gives the lock to the next token in the token queue.
   * Otherwise, it is a no-op, because the queue will be, and should only be, processed when the `currentToken` is released.
   */
  release() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.lock.currentToken === this) {
      this.lock.startNext();
    }
  }
}
