import { BrandColor } from './brandColors';

export const FeatureTheme = {
  IncentivesBackgroundColor: BrandColor.LightGreen,
  IncentivesColor: BrandColor.Green,

  ClientReferralBackgroundColor: BrandColor.LightGreen,
  ClientReferralColor: BrandColor.Green,

  DiscountsBackgroundColor: BrandColor.LightGreen,
  DiscountsColor: BrandColor.Green,
  DiscountsShadowColor: BrandColor.DarkGreen,

  SmartPriceBackgroundColor: BrandColor.LightPurple,
  SmartPriceColor: BrandColor.Purple,

  ProSpecialtiesIconColor: BrandColor.Yellow,
  ProSpecialtiesTextColor: BrandColor.Blue,

  AutopayIconColor: BrandColor.Flora,
};
