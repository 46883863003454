// @ts-strict-ignore
/**
 * useCanRender
 *
 * Example:
 *
 * const canRender = useCanRender(someDataLoaded, someResults.length > 0, inFooBarTest);
 *
 * return canRender && (
 *  <MyComponents>
 *     <Stuff />
 *  </MyComponents>
 * );
 *
 * This avoids needing to use a ternary or some other awkwardness when preventing a component
 * from rendering until various conditions are met.
 */
export default function useCanRender(...args): true | null {
  return args.reduce((last, current) => last && current, true) || null;
}
