import { Z_MODAL, Z_TOP_OF_THE_WORLD } from '../components/ui/utilities/zIndex';

type ModalRegistration = {
  id: string;
  inPortalElementRoot: boolean;
  forceTop?: boolean;
};

/**
 * Keeps track of opened modals and helps mitigate modal layering issues
 *
 * This is necessary for mitigating layering issues between modals opened
 * in the #modal-root element of index.html and modals that are opened in
 * the app content section, like ss-modal-wall.
 *
 * Ideally this registry will become obsolete and all modals will be opened
 * in the same DOM element which can naturally manage layering which is what
 * modalManager, dialogManager, and PortalOverlay modals already do.
 */
class ModalRegistry {
  private static instance: ModalRegistry = new ModalRegistry();
  public static getInstance() {
    return ModalRegistry.instance;
  }

  // To make the constructor private I need to define an empty private
  // constructor, which upsets the linter hence the ignore!
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
  }

  private stack: ModalRegistration[] = [];

  unregisterModal(id: string) {
    this.stack = this.stack.filter(registration => (
      registration.id !== id
    ));
    this.setModalStackIndex();
  }

  registerModal(registration: ModalRegistration) {
    this.stack.push(registration);
    this.setModalStackIndex();
  }

  private setModalStackIndex() {
    const modalRoot = document.getElementById('modal-root');
    if (modalRoot) {
      modalRoot.style.zIndex = this.top?.inPortalElementRoot
        ? `${Z_TOP_OF_THE_WORLD}` : `${Z_MODAL - 1}`;
    }
  }

  get top() {
    if (this.stack.length < 1) {
      return undefined;
    }
    return this.stack.find(reg => reg.forceTop) || this.stack[this.stack.length - 1];
  }
}

export default ModalRegistry.getInstance();
