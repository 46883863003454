// @ts-strict-ignore
import { v4 as uuidv4 } from 'uuid';
import { Platform } from 'react-native';
import { subscribe } from 'redux-subscriber';
import { APP_VERSION } from '../AppConstants';

import {
  APP,
  ENV,
  SHUTTLE_ENDPOINT,
  SEGMENT_WRITE_KEY,
} from '../../config';

import { Responsive } from '../Responsive';
import Analytics from '../../../../packages/analytics';
import ShuttleTracker from '../../../../packages/analytics/trackers/shuttle/ShuttleTracker';
import GoogleAnalyticsTracker from '../../../../packages/analytics/trackers/GoogleAnalyticsTracker';
import { FirebaseTracker } from '../../../../packages/analytics/trackers/FirebaseTracker';
import { AppsFlyerTracker } from '../../../../packages/analytics/trackers/AppsFlyerTracker';
import { brazeTracker } from './BrazeTracker';
import { LogRocketTracker } from './LogRocketTracker';
import {
  AnalyticsData,
  AnalyticsLocationData,
  AnalyticsUserData,
  UTMState,
} from '../../../../packages/analytics/types';
import {
  getDeviceScreenSize,
  getDeviceTimeZoneName,
  getDeviceOS,
  getDeviceLocale,
} from '../getSegmentRequestedData';
import { getLastTouchUtms, getParamsByPattern } from '../urlHelpers';
import { getAndroidVersion, getIOSVersion } from '../AppInfo';
import { getSessionId } from '../../../../packages/analytics/services/sessionId';
import nonCriticalException from '../exceptionLogger';
import Router from '../../../../packages/router';
import { SegmentTracker } from '../../../../packages/analytics/trackers/segment';
import type { PlatformProperty } from '../../../../packages/analytics/trackers/BaseTracker';
import type { RootState } from '../../store/models';
import createDefaultUser from '../user/createDefaultUser';
import { fetchGeoIP } from '../../api/GeoIP';

let user: {
  email: string;
  hashed_email?: string;
  userId?: number;
  is_anon: boolean;
  providerId?: number;
  ss_tracking_cookie?: string;
  date_joined?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  provider_name?: string;
  provider_plan?: string;
  user_token?: string;
  client_handle?: string;
} = {
  email: '',
  hashed_email: '',
  is_anon: true,
};

subscribe('user', (state: RootState) => {
  user = state.user || createDefaultUser();
});

const onError = (message, error) => {
  // eslint-disable-next-line no-console
  console.error(message, error?.stack);
  nonCriticalException(error);
};

function getDeviceType() {
  if (getIOSVersion()) {
    return `iOS - ${getIOSVersion()
      .replace(/_/g, '.')}`;
  }

  if (getAndroidVersion()) {
    return `Android - ${getAndroidVersion()
      .replace(/_/g, '.')}`;
  }

  return null;
}

const getUserAgent: () => string | null = () => {
  if (Platform.OS === 'web') {
    return window?.navigator?.userAgent;
  }

  return null;
};

const platform: PlatformProperty = APP ? 'app' : 'web';

const getAnalyticsData: () => Promise<AnalyticsData> = async () => {
  const { mobile: isMobile } = (await Responsive.isReady())?.layout || {};
  const currentRoute = Router.getCurrentRoute();

  const result: AnalyticsData = {
    appVersion: APP_VERSION,
    user: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      username: user?.username,
      provider_name: user?.provider_name,
      phone_number: user?.phone_number,
      date_joined: user?.date_joined,
      email: user.email,
      hashed_email: user.hashed_email,
      user_id: user.userId,
      provider_id: user.providerId,
      is_logged_in: !user.is_anon,
      session_id: getSessionId(uuidv4),
      ss_tracking_cookie: (user.ss_tracking_cookie
        || localStorage.getItem('ss_tracking_cookie')
      ),
      provider_plan: user?.provider_plan,
      client_handle: user?.client_handle,
      user_token: user?.user_token,
    },
    route: {
      title: document?.querySelector('title')?.textContent,
      page_route: currentRoute ? Router.getFullStateName(currentRoute.name) : undefined,
      previous_route: currentRoute?.previous?.name || 'unknown',
      page_key: (currentRoute && currentRoute.data)
        ? currentRoute.data.trackingName
        : undefined,
      referrer: document.referrer,
      window_location_search: window.location.search,
      window_location_pathname: window.location.pathname,
      window_location_origin: window.location.origin,
      window_location_hash: window.location.hash,
      window_location_href: window.location.href,
      last_touch_utm_params: getLastTouchUtms(),
      utm_parameters: getParamsByPattern(APP, window.location.href),
    },
    device: {
      device: getDeviceType(),
      platform,
      layout: isMobile ? 'mobile' : 'desktop',
      user_agent: getUserAgent(),
      screen: getDeviceScreenSize(),
    },
    extra: {
    },
    locale: getDeviceLocale(),
    os: getDeviceOS(),
    timezone_name: getDeviceTimeZoneName(),

  };

  if (typeof window.device?.model === 'string') {
    result.device.device_model = window.device?.model;
  }

  return result;
};

export default new class AnalyticsWrapper {
  private readonly analyticsPromise: Promise<Analytics>;

  private readonly analyticsForMetaPromise: Promise<Analytics>;

  private analyticsResolver: (analytics: Analytics) => void;

  private analyticsForMetaResolver: (analytics: Analytics) => void;

  constructor() {
    this.analyticsPromise = new Promise<Analytics>(resolve => {
      this.analyticsResolver = resolve;
    });
    this.analyticsForMetaPromise = new Promise<Analytics>(resolve => {
      this.analyticsForMetaResolver = resolve;
    });
  }

  async initialize() {
    const analytics = new Analytics({
      onError,
      getAnalyticsData,
      trackers: [
        new ShuttleTracker(ENV, platform, APP, APP_VERSION, SHUTTLE_ENDPOINT),
        new SegmentTracker(ENV, platform, SEGMENT_WRITE_KEY, APP_VERSION, 0),
        new AppsFlyerTracker(ENV, platform),
        new GoogleAnalyticsTracker(
          ENV,
          platform,
          () => getParamsByPattern(APP, window.location.href),
        ),
        new FirebaseTracker(ENV, platform, APP),
        brazeTracker,
        new LogRocketTracker(ENV, platform),
      ],
      getEventId: uuidv4,
    });

    // We need to make the instance available for update location
    // calls before it is generally available for track calls
    this.analyticsForMetaResolver(analytics);

    try {
      const data = await fetchGeoIP();
      await this.updateLocation({
        city: data.city,
        region: data.region,
        postal_code: data.postal_code,
        country: data.city_country_code,
        latitude: data.latitude ? Number.parseFloat(data.latitude) : null,
        longitude: data.longitude ? Number.parseFloat(data.longitude) : null,
      });
    } catch (e) {
      nonCriticalException(e);
    }

    // Before resolving analytics for track events which make sure
    // every track call includes the user's location data.
    this.analyticsResolver(analytics);

    return analytics;
  }

  async track(eventName: string, properties?: object, options?: object) {
    const analytics = await this.analyticsPromise;
    analytics.track(eventName, properties, options);
  }

  async land(properties) {
    const analytics = await this.analyticsPromise;
    analytics.trackPageView();
    // eslint-disable-next-line
    return new Promise(resolve => {
      const unsubscribe = subscribe('user', () => {
        resolve(analytics.track('landing', properties));
        unsubscribe();
      });
    });
  }

  async getTracker(name) {
    const analytics = await this.analyticsPromise;
    return analytics.getTracker(name);
  }

  async updateLocation(locationData: AnalyticsLocationData) {
    const analytics = await this.analyticsForMetaPromise;
    analytics.updateLocation(locationData);
  }

  async updateUser(userData: AnalyticsUserData) {
    const analytics = await this.analyticsForMetaPromise;
    analytics.updateUser(userData);
  }

  async updateUTMs(utmData: UTMState) {
    const analytics = await this.analyticsForMetaPromise;
    analytics.updateUTMs(utmData);
  }
}();
