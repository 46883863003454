/**
 * Correctly copies the value provided (depending if they're an array or an object)
 * @param {T} value
 * @returns {T}
 */
export const copyValue = <T>(value: T): T => {
  if (Array.isArray(value)) {
    return [...value] as unknown as T;
  }

  if (typeof value === 'object') {
    return { ...value };
  }

  return value;
};
