import moment, { Moment } from 'moment';
import FeatureFlags from '../FeatureFlags';
import { CLIENT_BOOKING_OPT_IN_AUTO_CHECKOUT_TEST_KEY } from '../../components/consumer/booking/constants';
import { ClientPreferredCheckoutType } from '../../api/Providers/Appointments';
import { UserAutocheckoutPreference, getUserPaymentSettings } from '../../api/Users/PaymentSettings';

export const CLIENT_PREFERRED_CHECKOUT_TYPE_DEFAULT = ClientPreferredCheckoutType.Manual;

/**
 * Converts the User's autocheckout_preference to the ClientPreferredCheckoutType
 * @param {UserAutocheckoutPreference} userPreference
 * @returns {ClientPreferredCheckoutType}
 */
export const getClientPreferredCheckoutType = (
  userPreference: UserAutocheckoutPreference,
): ClientPreferredCheckoutType => ({
  [UserAutocheckoutPreference.Auto]: ClientPreferredCheckoutType.Auto,
  [UserAutocheckoutPreference.Manual]: ClientPreferredCheckoutType.Manual,
}[userPreference]);

const fetchPreferredCheckoutMethod = async (): Promise<ClientPreferredCheckoutType> => {
  try {
    const { autocheckout_preference: value } = await getUserPaymentSettings();

    return getClientPreferredCheckoutType(value);
  } catch (error) {
    return CLIENT_PREFERRED_CHECKOUT_TYPE_DEFAULT;
  }
};

const calculateCheckoutPreference = async (
  startMoment: Moment,
): Promise<ClientPreferredCheckoutType> => {
  const daysInFuture = 14;
  const thresholdMoment = moment().endOf('day').add(daysInFuture, 'day');

  const autoCheckoutAssignment = startMoment.isBefore(thresholdMoment)
    ? await FeatureFlags.isEnabled(CLIENT_BOOKING_OPT_IN_AUTO_CHECKOUT_TEST_KEY)
    : null;
  return autoCheckoutAssignment
    ? ClientPreferredCheckoutType.Auto
    : fetchPreferredCheckoutMethod();
};

export default {
  fetchPreferredCheckoutMethod,
  calculateCheckoutPreference,
};
