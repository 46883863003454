import { createModel } from '@rematch/core';
import produce from 'immer';
import {
  getProviderProducts,
  deleteProviderProduct,
  updateProviderProduct,
  createProviderProduct,
} from '../modules/provider/providerProducts';
import type { RootModel } from './models';

type State = {
  loading: boolean;
  providerId: number | null;
  list: any[];
  errors: any[] | null;
};

const defaultState = (): State => ({
  loading: false,
  providerId: null,
  list: [],
  errors: null,
});

/**
 * Manages the list of provider products for a given provider
 */
const ProviderProducts = createModel<RootModel>()({
  state: defaultState(),

  reducers: {
    /**
     * Called when we've begun fetching products for a provider
     */
    onFetchStart: (_, payload: { providerId: number }) => ({
      ...defaultState(),
      providerId: payload.providerId,
      loading: true,
      errors: null,
    }),

    /**
     * Called when we've begun fetching products for a provider
     */
    onRefetchStart: produce(state => {
      // eslint-disable-next-line no-param-reassign
      state.errors = null;
      // eslint-disable-next-line no-param-reassign
      state.loading = true;
    }),

    /**
     * Called when we are finished fetching products for a provider
     */
    onFetchComplete: produce<State, [{ products: any[] }]>((state, payload) => {
      // eslint-disable-next-line no-param-reassign
      state.list = [...payload.products];
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
    }),

    onDeleteStart: produce(state => {
      // eslint-disable-next-line no-param-reassign
      state.loading = true;
    }),

    onError: produce<State, [{ error: any }]>((state, { error }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;

      state.errors = [error];
    }),

    updateItem: produce<State, [{ product: any }]>((state, payload) => {
      state.loading = false;
      state.errors = null;

      const idx = state.list.findIndex(item => item.id === payload.product.id);
      state.list[idx] = payload.product;
    }),

    addItem: produce<State, [{ product: any }]>((state, payload) => {
      state.loading = false;
      state.errors = null;
      state.list.push(payload.product);
    }),

    clear: () => defaultState(),
  },

  effects: dispatch => ({
    /**
     * Loads a list of products for the given provider
     * @param providerId
     */
    load: async ({ providerId }: { providerId: number }, rootState): Promise<any> => {
      if (providerId !== rootState.providerProducts.providerId) {
        dispatch.providerProducts.onFetchStart({ providerId });
      } else {
        dispatch.providerProducts.onRefetchStart();
      }

      try {
        const { results } = await getProviderProducts(providerId);
        return dispatch.providerProducts.onFetchComplete({ products: results });
      } catch (error) {
        console.error(error);
        return dispatch.providerProducts.onError({ error });
      }
    },

    delete: async ({
      providerId,
      productId,
    }: {
      providerId: number,
      productId: number,
    }): Promise<any> => {
      dispatch.providerProducts.onDeleteStart();
      try {
        await deleteProviderProduct(providerId, productId);
      } catch (error) {
        console.error(error);
        dispatch.providerProducts.onError({ error });
        return null;
      }

      return dispatch.providerProducts.load({ providerId });
    },

    /**
     * Edit or create a product
     */
    save: async ({ providerId, product }: { providerId: number, product: any }): Promise<any> => {
      dispatch.providerProducts.onRefetchStart();

      let result;

      try {
        if (product.id) {
          result = await updateProviderProduct(providerId, product);
          dispatch.providerProducts.updateItem({ product: result });
        } else {
          result = await createProviderProduct(providerId, product);
          dispatch.providerProducts.addItem({ product: result });
        }
      } catch (error) {
        console.error(error);
        dispatch.providerProducts.onError({ error });
        return null;
      }

      return result;
    },
  }),
});

export default ProviderProducts;
