import { Action, createModel } from '@rematch/core';
import type { RootModel, RootState } from './models';
import { STATE_MANAGER_ENDPOINT } from '../config';
import Deferred from '../testUtilities/deferred';
import type { DiscountStatus } from './ClientPromotions.types';
import { PromoTypeValues } from './NCDAndDiscountedPros.types';
import nonCriticalException from '../modules/exceptionLogger';

export const ONE_TIME_USE_CODE = 'one_time_use_code';

type LocalStorageStateEntry = {
  value: any;
  prefer_existing: boolean;
};

type LocalStorageState = Record<string, LocalStorageStateEntry>;

type Values = Record<string, any>;

export type AppliedDiscount = {
  code: string;
  source: string;
  status: DiscountStatus;
  key?: string;
  display_to_sender?: boolean;
  reward_id?: number;
  promo_type?: PromoTypeValues;
};

export type UserStateType = {
  userId: number | null;
  jwtToken?: string;
  preferLocalKeys: string[];
  loaded?: boolean;
  initialized?: boolean;
  values: Values;
};

const mapValuesToLocalStorageState: (
  values: Values,
  preferLocalKeys: string[],
) => LocalStorageState = (
  values,
  preferLocalKeys,
) => {
  const mapped: Record<string, any> = {};
  Object.keys(values).forEach(key => {
    mapped[key] = {
      value: values[key],
      prefer_existing: !(preferLocalKeys.includes(key)),
    };
  });

  return mapped;
};

const unmapValuesFromLocalStorageState: (
  storageState: LocalStorageState,
) => {
  values: Values;
  preferLocalKeys: string[];
} = storageState => {
  const result: Values = {};
  const preferLocalKeys: string[] = [];

  Object.keys(storageState).forEach(key => {
    result[key] = storageState[key].value;
    if (!storageState[key].prefer_existing) {
      preferLocalKeys.push(key);
    }
  });

  return {
    values: result,
    preferLocalKeys,
  };
};

const writeRemoteState = async (values: Values, jwtToken: string): Promise<Values> => {
  try {
    const res = await fetch(STATE_MANAGER_ENDPOINT, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwtToken,
      },
      body: JSON.stringify(values),
    });
    const json = await res.json();
    return json?.values;
  } catch (e) {
    nonCriticalException(e);
    return values;
  }
};

const readLocalStorage: () => LocalStorageState = () => {
  const localState = localStorage.getItem('userState') || null;
  if (!localState) return {};

  try {
    return JSON.parse(localState) || {};
  } catch (e) {
    return {};
  }
};

const writeLocalStorage: (state: LocalStorageState | null) => void = state => {
  localStorage.setItem('userState', JSON.stringify(state));
};

export const hasLoadedDeferred = new Deferred();

const getDefaultState: () => UserStateType = () => ({
  userId: null,
  preferLocalKeys: [],
  loaded: false,
  initialized: false,
  values: {},
});

export const UserState = createModel<RootModel>()({
  name: 'userState',

  state: getDefaultState(),

  reducers: {
    onActionStart(state: UserStateType) {
      return {
        ...state,
      };
    },

    onClearUserState() {
      return {
        ...getDefaultState(),
        initialized: true,
      };
    },

    onUserUpdateStart: (state: UserStateType, {
      userId,
      jwtToken,
    }: {
      userId: number | null;
      jwtToken?: string;
    }) => ({
      ...state,
      userId,
      jwtToken,
      loaded: false,
    }),

    onUpdateComplete(state: UserStateType, {
      values,
      preferLocalKeys,
    }: {
      values: Values;
      preferLocalKeys?: string[];
    }) {
      return {
        ...state,
        values,
        preferLocalKeys: preferLocalKeys || state.preferLocalKeys,
        loaded: true,
        initialized: true,
      };
    },
  },

  effects: dispatch => ({
    async refreshUserState(_?: undefined, rootState?): Promise<UserStateType['values']> {
      const {
        userState: {
          userId,
          values: oldValues,
          jwtToken,
        },
      } = rootState;
      const localState = readLocalStorage();

      if (userId && jwtToken) {
        const res = await writeRemoteState(localState, jwtToken);
        writeLocalStorage(null);
        dispatch.userState.onUpdateComplete({ values: res });
        return res;
      }

      const { values, preferLocalKeys } = unmapValuesFromLocalStorageState(localState);

      dispatch.userState.onUpdateComplete({
        values: {
          ...oldValues,
          ...values,
        },
        preferLocalKeys,
      });
      hasLoadedDeferred.resolve(null);
      return values;
    },

    async clearUserState() {
      dispatch.userState.onClearUserState();
      writeLocalStorage(null);
      hasLoadedDeferred.resolve(null);
      return {};
    },

    async handleUserUpdate(_?: undefined, rootState?): Promise<UserStateType['values']> {
      /**
       * Basics of the logic.
       *
       * If this is the first sync after a login we must do a couple of things,
       * 1. Fetch the remote user state.
       * 2. Load it into the current user state allowing it to override current userstate
       *      values where appropriate.
       * 3. We need to check the loaded current user state and if it has changed from the
       *      remote user state, update the remote user state.
       */
      const {
        user: {
          userId: newUserId,
          jwt_token: newJwtToken,
        },
        userState: {
          userId: oldUserId,
          jwtToken: oldJwtToken,
          values: existingState,
        },
      } = rootState;
      let state = existingState;
      if (oldUserId === newUserId && oldJwtToken === newJwtToken) {
        return existingState;
      }
      dispatch.userState.onUserUpdateStart({
        userId: newUserId || null,
        jwtToken: newJwtToken,
      });

      if (!newUserId && oldUserId) {
        state = await dispatch.userState.clearUserState();
      } else {
        state = await dispatch.userState.refreshUserState();
      }
      return state;
    },

    async addValues({
      values,
      preferLocalKeys = [],
    }: {
      values: Values;
      preferLocalKeys?: string[];
    }, rootState): Promise<Action<{ values: Values; preferLocalKeys?: string[] }, void>> {
      const {
        userState: {
          userId: loggedIn,
          jwtToken,
          values: existingValues,
          preferLocalKeys: existingLocalKeys,
        },
      } = rootState;
      let newState = null;
      const mappedNewValues = mapValuesToLocalStorageState(values, preferLocalKeys);

      if (!loggedIn || !jwtToken) {
        const mappedExistingValues = mapValuesToLocalStorageState(
          existingValues,
          existingLocalKeys,
        );
        writeLocalStorage({
          ...mappedExistingValues,
          ...mappedNewValues,
        });

        newState = {
          ...existingValues,
          ...values,
        };
      } else {
        newState = await writeRemoteState(mappedNewValues, jwtToken);
      }

      return dispatch.userState.onUpdateComplete({ values: newState });
    },
  }),
  selectors: (slice, createSelector, hasProps) => ({
    loaded() {
      return createSelector(
        slice,
        (state: UserStateType) => state.loaded,
      );
    },
    valueByKey: hasProps((_, key: string) => createSelector(
      slice,
      state => state?.values[key],
    )),
  }),
});

export const selectDiscounts: (state: RootState) => AppliedDiscount[] = state => Object
  .keys(state.userState.values)
  .filter(key => (
    /available_(discount|client_referral_reward)_\d+/.exec(key)
    || key === ONE_TIME_USE_CODE
  ))
  .map(key => ({ key, value: state.userState.values[key] }))
  .filter(value => {
    if (value.key.includes('available_client_referral')) {
      return value.value?.promo_type === PromoTypeValues.Reward;
    }
    return value.value?.promo_type !== PromoTypeValues.Reward;
  })
  .map(({ key, value }) => ({ key, ...value }));
