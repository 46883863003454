// @ts-strict-ignore
import { useFullAsyncState } from '../../../packages/hooks/src/useAsyncState';
import { loadProvider } from '../modules/Api';
import { PublicProvider, PrivilegedProvider } from '../api/Providers';

export type UseProviderData = {
  provider?: PublicProvider | PrivilegedProvider;
  error?: Error;
  loading?: boolean;
};

/**
 * A hook for getting a provider by id.
 *
 * @param { number } id - The provider id to get.
 * @return { provider, loading, error } - The result data
 * @deprecated please use Providers.model.ts
 *
 */
const useProvider = (id?: number | string): UseProviderData => {
  const {
    data: provider, error, loading,
  } = useFullAsyncState(
    () => loadProvider(id),
    [id],
  );
  return {
    provider, error, loading,
  };
};

export default useProvider;
