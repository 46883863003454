// @ts-strict-ignore
/**
 *
 * @param url
 * @param options
 */
import { ssFetchJSON } from './ssFetch';

export const fetchAllPages = async <T>(pageUrl, options): Promise<T[]> => {
  const data = await ssFetchJSON<{ results: T[]; next: string }>(pageUrl, options);

  if (data.next) {
    return [
      ...data.results,
      ...await fetchAllPages<T>(data.next, data.results),
    ];
  }

  return data.results;
};
