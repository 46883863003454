import { API_ROOT } from '../../../config';

import { GalleryImageType } from './types';

type ProviderIDLike = number | string | { id: number | string };

function buildImageCreationPath(type: GalleryImageType, provider: ProviderIDLike): string {
  const providerId = typeof provider === 'object' ? provider.id : provider;
  switch (type) {
    case GalleryImageType.ClientUpload:
      return 'review_image/';
    case GalleryImageType.ProviderProfileImport:
      return `provider/${providerId}/profile_image/`;
    default:
      return `provider/${providerId}/gallery_image/`;
  }
}

/* the only difference is the plural............. */
function buildImageQueryPath(type: GalleryImageType, providerId: number) {
  switch (type) {
    case GalleryImageType.ClientUpload:
      return 'review_images/';
    default:
      return `providers/${providerId}/gallery_images/`;
  }
}

export function buildImageQueryUrl(
  type: GalleryImageType,
  providerId: number,
  imageId?: number,
  authToken?: string,
): string {
  return [
    API_ROOT,
    '/api/v2/',
    buildImageQueryPath(type, providerId),
    imageId ? `${imageId}/` : '',
    authToken ? `${authToken}` : '',
  ].join('');
}

export function buildImageCreationUrl(
  type: GalleryImageType,
  provider: ProviderIDLike,
  action: 'import' | 'upload',
  authToken?: string,
): string {
  return [
    API_ROOT,
    '/api/v1/',
    buildImageCreationPath(type, provider),
    action,
    authToken ? `/${authToken}` : '',
  ].join('');
}
