import { MomentInput } from 'moment';

export interface SearchResultsStatsNotification {
  type: 'new_sr_stats_notification';
  uuid: 'new_sr_stats_notification';
  persistent: true;
  provider_id: number;
  creation_time: MomentInput;
}

export function createSearchResultStatsNotification(
  providerId: number,
): SearchResultsStatsNotification {
  return {
    type: 'new_sr_stats_notification',
    uuid: 'new_sr_stats_notification',
    persistent: true,
    provider_id: providerId,
    creation_time: new Date(),
  };
}
