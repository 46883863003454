import type { IGalleryImage } from '../api/Providers/GalleryImages/types';
import { PrivilegedProvider, PublicProvider } from '../api/Providers/Providers';
import type { ProviderService } from '../api/Providers/Services';
import { ProviderServiceGroup } from '../api/Providers/ServiceGroups';

/**
 * Interfaces which specify the data accepted by and returned from the various
 * methods in Api.
 *
 * Many of these interfaces correlate to the django-rest-framework serializers
 * used by the API endpoints.
 *
 *  NOTE!
 *  We're currently considering adopting a directory structure that would
 *  dictate predictable locations for all of these endpoint-related types
 *  to live in- and reconsidering the use of Caches in general in favor
 *  of "redux for everything".
 *
 *  Stay tuned and add to this file cautiously.
 *  In the meantime, consider adding utility types to ../types.ts
 *  and redux related types to store/nameOfModel.types.ts
 *
 */

export enum ProviderCancellationPolicy {
  NoPolicy,
  Flexible,
  Moderate,
  Strict,
}

export enum CheckoutType {
  NoCheckoutAttempted = 0,
  CheckoutAttemptMade = 1,
  CheckoutCompleted = 2,
  CheckoutNoShow = 3,
  CheckoutUncompleted = 4,
  CheckoutUnNoShow = 5,
  CheckoutCancelled = 6,
}

/** Data as returned by provider.v2.serializers_api.CheckoutSettingsSerializer */
export interface ICheckoutSettingsData {
  service_tax_rate: string;
  product_tax_rate: string;
  client_service_rate?: '0.00';
}

/** Data as returned by AcceptedTermsSerializer */
export interface IAcceptedTermsData {
  user: number;
  provider: number | null;
  ip_address: string;
  user_agent: string;
  accepted_time: string;
  accepted_version: string;
  terms_type: string;
}

/** Data as returned by ProviderInstantDepositsSettingsSerializer */
export interface IProviderInstantDepositsSettingsData {
  allowed: boolean;
  minimum: string;
  maximum: string;
  next_available_time: string;
}

/** Data as returned by MinimalBankAccountSerializer */
export interface IMinimalBankAccountData {
  last4: string;
}

/** Data as returned by MinimalProviderVerificationDetailsSerializer */
export interface IMinimalProviderVerificationDetailsData {
  status: string;
  in_probation: boolean;
  has_address: boolean;
  needs_full_ssn: boolean;
  needs_legal_document: boolean;
}

/** Data as returned by StripeSubscriptionCouponSerializer */
export interface IStripeSubscriptionCouponData {
  external_id: string;
  amount_off: number;
  external_created: string;
  currency: string | null;
  duration: string;
  duration_in_months: number | null;
  max_redemptions: number | null;
  metadata: string;
  percent_off: number | null;
  redeem_by: string | null;
  times_redeemed: number;
  valid: boolean;
}

/** Data as returned by ProviderSignupSettingsSerializer */
export interface IProviderSignupSettingsData {
  checklist_complete: boolean;
  checklist_eligible: boolean;
  completed_availability: boolean;
  profile_complete: boolean;
  synced_google_cal: boolean;
  preview_subscription_plan_name: string | null;
  preview_subscription_start_time: string | null;
  preview_subscription_trial_start_time: string | null;
  preview_subscription_cancellation_time: string | null;
  preview_subscription_true_cancellation_time: string | null;
  preview_subscription_trial_period_days: number | null;
  preview_subscription_discount_period_months: number | null;
  preview_subscription_plan_amount: string | null;
  preview_subscription_discount_amount: string | null;
  preview_subscription_stripe_instrument: number | null;
  preview_subscription_saved_card: number | null;
  preview_subscription_next_charge_time: string | null;
  preview_subscription_next_charge_amount: string | null;
  preview_subscription_pro_plan: number | null;
  preview_subscription_coupon: number | null;
  preview_subscription_background_job_start_time: string | null;
  first_payment_at: string | null;
  is_disabled: boolean;
  is_subscribed: boolean;
  on_commission_plan: boolean;
  subscription_discount_end_time: string | null;
  subscription_end_time: string | null;
  subscription_state: string | null;
  subscription_time_remaining: string | null;
  subscription_trial_end_time: string | null;
  subscription_trial_time_remaining: string | null;
  is_membership_transitioned_to_new_schema: boolean;
  requires_payment_update: boolean;
  has_suspended_subscription: boolean;
  tech_level: string | null;
  business_level: string | null;
  subscription_trial_expires_soon: boolean;
  subscription_trial_expired: boolean;
}

/**
 * Data that can be written to the PrivilegedProviderSerializer via PATCH.
 *
 * Please add fields to this interface as needed. Since this is for an HTTP
 * PATCH, any added fields should be typed as optional (`foo?:` instead of
 * `foo:`).
 */
export interface IPatchableProviderData {
  plan?: string;
}

export class ApiError extends Error {
  /**
   * The HTTP status corresponding to this API error.
   */
  status: number;

  /**
   * The parsed JSON from the response content.
   *
   * If JSON could not be parsed, this should be an empty object
   */
  data: any;

  constructor(status: number, data: any) {
    let d;
    let message;
    if (typeof data === 'string') {
      message = data;
    } else {
      d = data || {};
      message = d?.error
        // eslint-disable-next-line no-underscore-dangle
        || (d?.__all__ && d?.__all__[0])
        || 'An unknown error occurred, please contact support@styleseat.com for assistance.';
    }
    super(message);
    // Attach error data and HTTP status
    this.data = d;
    this.status = status;
  }
}

export interface IProClientsInLast30DaysCount {
  booked_clients_in_last_30_days_count: number;
}

export interface IClientCount {
  client_count: number;
}

export interface IServiceSection {
  index: number;
  startingOverallIndex: number;
  data: ProviderService[];
  name: string;
}

/** Data as returned by ProfileFeedbackSerializer */
export interface IProfileFeedback {
  profile_id: number;
  stars: number;
  message: string;
  name: string;
}

/** Data as returned by api/v1/providers/<proID>/search-appearances */
export interface ISearchAppearances {
  status: string;
  count: number;
}

/** Data as returned by api/v2/providers/<proID>/self_promotion_dates
 */
export interface IProviderSelfPromotionDates {
  last_self_promoted_date: string;
  last_invited_to_book_date: string;
}

export type ProSpecialty = {
  name: string;
  id: number;
};

export type FormattedService = ProviderService & {
  recently_added: boolean;
  total_duration_minutes?: number;
  tags?: Array<string>;
};

export type SubsetOfFieldsFromFormattedService<Y extends keyof FormattedService> = (
  Pick<FormattedService, Y>
);

/**
 * Pro's profile combined including service data and images
 */
export type PublicProviderProfile =
  PublicProvider
  & ProProfileServiceData
  & ProProfileGalleryImageData;

/**
 * Pro's privileged profile combined including service data and images
 */
export type PrivilegedProviderProfile =
  PrivilegedProvider & ProProfileServiceData & ProProfileGalleryImageData;

/**
 * This data is not returned from the provider endpoint
 * but is needed in pro profile components
 */
export type ProProfileServiceData = {
  service_groups: ProviderServiceGroup[];
  services: ProviderService[];
};

export type ProProfileGalleryImageData = {
  gallery_images: IGalleryImage[];
  gallery_images_count: number;
};

export interface IProReferralData {
  plan_name: string;
  referral_amount: string;
  referral_url: string;
}

export interface IProviderMetric {
  metrics: any[];
  status: string;
}

export interface IAccountFieldEditStatus {
  blocked?: boolean;
  current_editing_field?: number;
  id?: string;
  last_changed_field?: string;
}

export interface INCDDetails {
  source: string;
  term: string;
}

export type IEditBusinessInfo = {
  location_name: string;
  name: string;
  mobile_business: boolean;
  location_address1: string;
  location_address2: string;
  location_city: string;
  location_state: string;
  location_zipcode: string;
};

export type ProviderSearchRankStats = {
  completedAppointmentCount: number;
  completedOnStyleseatAppointmentCount: number;
  dateRangeStart?: string;
  ncdCompletedAppointmentCount: number;
  ncdCompletedOnStyleseatAppointmentCount: number;
  ncdDateRangeStart?: string;
  reviewTotalCount: number;
  reviewPositiveCount: number;
  reviewDateRangeStart?: string;
  ncdRefundedAppointmentCount?: number;
  ncdEnabled?: boolean;
  lastTenAppointments?: {
    completedAppointmentCount?: number;
    completedOnStyleseatAppointmentCount?: number;
    ncdCompletedAppointmentCount?: number;
    ncdMonetizedCount?: number;
  };
};

export interface IAppointmentCancellationLink {
  management_link: string;
}

export type ISmartPricingSettings = {
  smart_pricing_enabled: boolean;
};
