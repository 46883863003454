import SSInAppBrowser from './SSInAppBrowser';

/**
 * A library for receiving & validating cross-window messages via postMessage.
 *
 * Only a receiver is implemented here, since the bulk of the work in postMessage communication
 * is in validating a received event's origin domain and message format (event.data).
 *
 * A sender just needs to supply the following properties in the event data:
 *
 * {
 *   'format': 'pheidippides',
 *   'type': 'some_custom_string_indicating_what_type_of_message_this_is',
 *   'foo': 'bar', // some optional custom data
 *   'spam': 'eggs', // some other optional custom data
 * }
 */

/**
 * The unique name ensures that we only receive messages intended for processing by this library,
 * since postMessage data comes in pretty haphazardly and is used gratuitously by
 * Stripe.js, FB.js, and other 3rd-party libraries.
 *
 * The format is arbitrarily named after the ancient fellow who ran the 40km from Marathon to
 * Athens to deliver the news of victory after the Battle of Marathon. He died of exhaustion after
 * delivering his message.
 */
export const POST_MESSAGE_FORMAT = 'pheidippides';

/**
 * Test that string `b` matches the string or RegExp `a`.
 *
 * @param a - a String or RegExp instance
 * @param b - a String
 * @returns {boolean}
 */
const regexpOrStringMatch = (a, b) => {
  if (a && a instanceof window.RegExp) {
    return a.test(b);
  }
  return a === b;
};

/**
 * A class for handling postMessage responses.
 */
export default class PostMessageListener {
  static resolve = null;

  /**
   * Shortcut for creating and starting a Listener instance.
   * @param options - see Listener constructor docs.
   * @returns {PostMessageListener}
   */
  static listen(options) {
    const listener = new PostMessageListener(options);
    listener.listen();
    return listener;
  }

  static sendMessage(target, message, targetOrigin) {
    let origin = targetOrigin;
    if (!origin) {
      origin = '*';
    }
    const msg = {
      format: POST_MESSAGE_FORMAT,
      ...message,
    };
    target.postMessage(msg, origin);
  }

  static defaulOptions = Object.freeze({
    expectedOrigin: null,
    expectedType: null,
    expectIsTrusted: true,
    extraMatch: null,
    timeout: null,
    once: true,
    globalWindow: window,
  });

  /**
   * @param options - An object of the form:
   * {
   *   expectedOrigin: <string> || <RegExp>
   *      A string or regular expression to match the event's "origin" parameter against.
   *   expectedType: <string> || <RegExp>
   *      A string or regular expression to match the event message's "type" parameter against.
   *   extraMatch: <null> || <Function>
   *      An optional function which receives `event` and can be used to determine
   *      if a received event matches some additional criteria. If this returns true, the
   *      event is considered valid and will resolve the Listener's promise. If false, the
   *      event is ignored.
   *   timeout: <null> || <Number>
   *      The number of milliseconds to listen for events. If no matching event is received
   *      in that time, the promise will be rejected and the listener will stop listening.
   *   once: <boolean>
   *      If true, the listener will stop listening once the first matching event is received.
   *      If false, the listener will continue to listen for events indefinitely and should
   *      most likely be removed manually by calling code. Defaults to true, to prevent leaks.
   *   expectIsTrusted: <boolean>
   *     If true, verifies that the incoming event came from a window.postMessage call.
   *     If false, the incoming event can come from window.postMessage or window.dispatchEvent.
   *     (default: true)
   *     Note that this option has no effect when using SSInAppBrowser.
   *   popupWindow: <SSInAppBrowser|Window>
   *     A reference to a window to listen for incoming messages from. Only used in-app,
   *     and required.
   *   globalWindow: <Window>
   *     A reference to a window to listen for incoming messages on. Only used in browser,
   *     defaults to window.
   * }
   */
  constructor(options) {
    this.options = Object.freeze({
      ...PostMessageListener.defaulOptions,
      ...options,
    });
    if (SSInAppBrowser.enabled() && !this.options.popupWindow) {
      throw new window.Error('Cannot listen for messages in-app without a popupWindow');
    }
    if (!this.options.expectedOrigin) {
      throw new window.Error('Missing expectedOrigin');
    }
    if (!this.options.expectedType) {
      throw new window.Error('Missing expectedType');
    }
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.listening = false;
    this.timer = null;
  }

  /**
   * Determine if a received 'message' event from a browser window matches
   * the criteria for this listener.
   */
  windowEventMatch = event => {
    const {
      expectedOrigin,
      expectedType,
      expectIsTrusted,
      extraMatch,
    } = this.options;

    if (expectIsTrusted && !event.isTrusted) {
      // Check that this event was legitimately created by a window.postMessage call
      // rather than window.dispatchEvent, since with the former we are guaranteed
      // that the event's origin is not spoofed.
      return false;
    }

    if (!regexpOrStringMatch(expectedOrigin, event.origin)) {
      // This message does not come from the expected origin domains.
      return false;
    }

    const {
      data: message,
    } = event;

    if (!message || message.format !== POST_MESSAGE_FORMAT) {
      // This message is not intended for this library.
      return false;
    }

    if (!regexpOrStringMatch(expectedType, message.type)) {
      // This is not the desired message type.
      return false;
    }

    if (extraMatch) {
      return extraMatch(event);
    }

    return true;
  };

  /**
   * Determine if a received 'message' event from an SSInAppBrowser instance matches
   * the criteria for this listener.
   */
  inAppBrowserEventMatch = event => {
    const {
      expectedOrigin,
      expectedType,
      extraMatch,
      popupWindow,
    } = this.options;

    if (!regexpOrStringMatch(expectedOrigin, popupWindow.location.origin)) {
      // This message does not come from the expected origin domains.
      return false;
    }

    const {
      data: message,
    } = event;

    if (!message || message.format !== POST_MESSAGE_FORMAT) {
      // This message is not intended for this library.
      return false;
    }

    if (!regexpOrStringMatch(expectedType, message.type)) {
      // This is not the desired message type.
      return false;
    }

    if (extraMatch) {
      return extraMatch(event);
    }

    return true;
  };

  /**
   * Handler for the SSInAppBrowser's message.
   */
  onInAppBrowserMessage = event => {
    if (this.inAppBrowserEventMatch(event)) {
      if (this.options.once) {
        this.remove();
      }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      const source = this.options.popupWindow;
      const { origin } = source.location;
      this.resolve({
        source,
        origin,
        ...event,
      });
    }
  };

  /**
   * Handler for the 'message' event on window.
   */
  onWindowMessage = event => {
    if (this.windowEventMatch(event)) {
      if (this.options.once) {
        this.remove();
      }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.resolve(event);
    }
  };

  respond(message) {
    this.promise.then(event => {
      const responseMessage = typeof message === 'function' ? message(event) : message;
      this.respondToEvent(event, responseMessage);
    });
    return this;
  }

  respondToEvent = (event, message) => {
    const {
      source: target,
      origin: targetOrigin,
    } = event;
    PostMessageListener.sendMessage(target, message, targetOrigin);
  };

  /**
   * Start listening for matching events.
   */
  listen = () => {
    if (this.listening) {
      return;
    }
    const {
      popupWindow,
      globalWindow,
    } = this.options;
    if (popupWindow instanceof SSInAppBrowser) {
      popupWindow.addEventListener('message', this.onInAppBrowserMessage, false);
    } else {
      globalWindow.addEventListener('message', this.onWindowMessage, false);
    }
    const {
      timeout,
    } = this.options;

    if (timeout) {
      this.timer = setTimeout(() => {
        this.remove();
        this.reject('PostMessageListener: timed out');
      }, timeout);
    }
    this.listening = true;
  };

  /**
   * Stop listening for matching events.
   */
  remove = () => {
    if (!this.listening) {
      return;
    }
    const {
      popupWindow,
      globalWindow,
    } = this.options;

    if (popupWindow instanceof SSInAppBrowser) {
      popupWindow.removeEventListener('message', this.onInAppBrowserMessage, false);
    } else {
      globalWindow.removeEventListener('message', this.onWindowMessage, false);
    }
    this.listening = false;
  };
}
