import { Step } from './modules/api/providers/types';

export const LANDING_ROUTE = 'land';
export const MOBILE_APP_START_ROUTE = 'signup_wall.main';
export const EXPIRED_APP_BLOCKER_ROUTE = 'expired_app';
export const HOME_ROUTE = 'home';
export const SALON_ROUTE = 'salon';
export const ERROR_ROUTE = '404';
export const ERROR_BOUNDARY = 'errorboundary';
export const PROVIDER_WAITLIST_ROUTE = 'provider.waitlist';
export const PROVIDER_SIGNUP_ROUTE = 'provider_signup__account';
export const PROVIDER_SEARCH_RANK = 'provider.search_rank';
export const PROVIDER_SEARCH_RANK_NEW_CLIENTS = 'provider.search_rank_new_clients';
export const PROVIDER_SEARCH_RANK_PAYMENTS = 'provider.search_rank_payments';
export const PROVIDER_TOP_PRO = 'provider.top_pro';
export const PROVIDER_MARKETING_HUB = 'provider.marketing_hub';
export const CLIENT_PHONE_SETTINGS = 'client.phone_settings';
export const PRO_FEEDBACK = 'client.feedback.provider';
export const AUTH_WITH_STYLESEAT = 'auth-with-styleseat';

// Provider responsive
const PROVIDER_RESPONSIVE = 'responsive.provider';
export const PROVIDER_CLIENT_NOTE = `${PROVIDER_RESPONSIVE}.client_note`;
export const PROVIDER_EDIT_REVIEWS = `${PROVIDER_RESPONSIVE}.ratings_reviews`;
export const PROVIDER_EDIT_NSLC_POLICY = `${PROVIDER_RESPONSIVE}.nslc_policy`;
export const PRO_PROMOTE_PROFILE = `${PROVIDER_RESPONSIVE}.promote`;
export const PRO_PROMOTE_PROFILE_SOCIAL = `${PRO_PROMOTE_PROFILE}.social`;
export const PRO_PROMOTE_PROFILE_INBOUND = `${PRO_PROMOTE_PROFILE}.inbound`;
export const PRO_PROMOTE_PROFILE_IN_PERSON = `${PRO_PROMOTE_PROFILE}.inperson`;
export const PROVIDER_FEES = 'provider.fees';
export const PRO_CHANGE_PASSWORD = 'responsive.provider.account_password';
export const PRO_CHANGE_EMAIL = 'responsive.provider.account_email';
export const PRO_CHECKOUT_SETTINGS = 'responsive.provider.checkout_settings';

// Consumer
export const CLIENT_APPTS_LIST = 'consumer.appointments';
export const CLIENT_APPTS_LIST_DEFAULT = `${CLIENT_APPTS_LIST}.list`;
export const CLIENT_APPTS_LIST_FUTURE = `${CLIENT_APPTS_LIST}.future`;
export const ADJUST_TIP = 'consumer.adjust-tip';
export const ADJUST_TIP_THANK_YOU = 'consumer.adjust-tip.thank-you';
export const ADJUST_TIP_DEFAULT = 'consumer.adjust-tip.default';
export const ADJUST_TIP_REFUNDED = 'consumer.adjust-tip.refunded';
export const CLIENT_PAYMENT_REQUEST_CHECKOUT = 'client.payment_request_checkout.checkout';
export const CLIENT_INVITED_TO_BOOK = 'consumer.invited_to_book';
export const CLIENT_APPT_STATUS_DETAILS = 'consumer.appointment_status_details';
export const CLIENT_GET_HELP = 'consumer.appointment_get_help';
export const CLIENT_PREPAY_APPT = 'consumer.prepay_appointment';
export const CLIENT_ADD_CARD = 'add_card_redirect';
export const CLIENT_LIST_CARD = 'list_card_redirect';
export const CLIENT_DISCOUNT_ENROLLMENT = 'consumer.discount_enrollment';
export const CLIENT_MANAGE_APPT = 'consumer.manage_appointment';
export const CLIENT_MORE_MENU = 'consumer.more';

// Guest
export const GUEST_LANDING = 'guest.landing';

// Chat
export const CHAT = 'chat';
export const CLIENT_INBOX = 'client.inbox';

// Search
export const SEARCH_ROUTE = 'search';
export const CITY_SEARCH_LANDING = 'city_search_landing';

// Provider settings
const PROVIDER_SETTINGS = 'provider.settings_menu';
export const PROVIDER_SETTINGS_MORE = `${PROVIDER_SETTINGS}.more`;
export const PROVIDER_SETTINGS_ONLINE_BOOKING_MENU = `${PROVIDER_SETTINGS}.online_booking`;
export const PROVIDER_SETTINGS_SOCIAL = `${PROVIDER_SETTINGS}.social`;
export const PROVIDER_SETTINGS_ONLINE_BOOKING = `${PROVIDER_SETTINGS_ONLINE_BOOKING_MENU}.online_booking`;
export const PROVIDER_SETTINGS_ONLINE_BOOKING_DEEP_LINK = 'deep_link_pro_settings_online_booking';
export const PRO_PERSONAL_INFO_SETTINGS = `${PROVIDER_RESPONSIVE}.settings_personal`;
export const PRO_BUSINESS_INFO_SETTINGS = `${PROVIDER_RESPONSIVE}.settings_salon`;
export const PRO_TAPTOPAY_ONBOARD_SETTINGS = `${PROVIDER_SETTINGS}.tap_to_pay_setup`;
export const PRO_TAPTOPAY_ONBOARD_SETTINGS_DEEP_LINK = 'deep_link_tap_to_pay_setup';
export const PRO_NOTIFICATION_SETTINGS_SUBMENU = `${PROVIDER_SETTINGS}.notification_settings`;
export const PRO_NOTIFICATION_SETTINGS = `${PROVIDER_SETTINGS}.pro_notification_settings`;
export const PRO_CLIENT_NOTIFICATION_SETTINGS = `${PROVIDER_SETTINGS}.client_notification_settings`;
export const PROVIDER_SETTINGS_FRONT_DESK = `${PROVIDER_SETTINGS}.front_desk`;
export const PRO_CALENDAR_GOOGLE_SYNC = `${PROVIDER_SETTINGS}.pro_calendar_google_sync`;
export const PRO_BANK_ACCOUNT_SETTINGS = `${PROVIDER_SETTINGS}.bank_account`;
export const PRO_MESSAGES_LIST = `${PROVIDER_SETTINGS}.messages`;
export const PRO_SETTINGS_PLAN_SELECTION = `${PROVIDER_SETTINGS}.plan_selection`;
export const PRO_SETTINGS_PLAN_SELECTION_DEEP_LINK = 'deep_link_pro_settings_plan_selection';
export const PRO_ACCOUNT_MENU = `${PROVIDER_SETTINGS}.account`;
export const PRO_PLAN_SETTINGS = `${PROVIDER_RESPONSIVE}.subscription`;
export const PRO_NEW_CLIENT_DELIVERY_SETTINGS = `${PROVIDER_SETTINGS}.new_client_delivery`;
export const PRO_TRUSTED_CLIENT_SETTINGS = `${PROVIDER_SETTINGS}.trusted_client`;
export const PRO_SMART_PRICE_SETTINGS = `${PROVIDER_SETTINGS}.smart_pricing`;
export const PRO_FORM_SETTINGS = `${PROVIDER_SETTINGS}.form_settings`;
export const PRO_FORM_SETTINGS_QUESTION = `${PROVIDER_SETTINGS}.form_settings.question`;
export const PRO_TAX_SETTINGS = `${PROVIDER_SETTINGS}.tax_settings`;
export const PRO_TAX_SETTINGS_EDELIVERY_ROUTE = `${PROVIDER_SETTINGS}.tax_settings.edelivery`;
// Refactored mobile menu
export const PROVIDER_SETTINGS_BUSINESS_AND_PROFILE = `${PROVIDER_SETTINGS}.business_profile`;
export const PROVIDER_SETTINGS_CALENDAR = `${PROVIDER_SETTINGS}.calendar`;
export const PROVIDER_SETTINGS_CALENDAR_VIEW_SETTINGS = `${PROVIDER_SETTINGS}.calendar_view_settings`;
export const PROVIDER_SETTINGS_MARKETING = `${PROVIDER_SETTINGS}.marketing`;
export const PROVIDER_SETTINGS_INSTAGRAM_BOOK_BUTTON = `${PROVIDER_SETTINGS}.instagram_book_button`;
export const PROVIDER_SETTINGS_EARNINGS_AND_REPORTING = `${PROVIDER_SETTINGS}.earnings_reporting`;
export const PROVIDER_SETTINGS_PAYMENTS_AND_POLICIES = `${PROVIDER_SETTINGS}.payments_policies`;
export const PROVIDER_SETTINGS_ACCOUNT = `${PROVIDER_SETTINGS}.account_management`;

// Pro profile
export const PROFILE_ROUTE = 'profile';
export const PROFILE_EDIT_ROUTE = 'profile_edit';
export const PROFILE_EDIT_ROUTE_ONBOARDING = 'profile_edit_onboarding';
export const PROFILE_EDIT_SERVICE_INFO_ROUTE = `${PROFILE_EDIT_ROUTE}.service_info`;
export const PROFILE_EDIT_BOOKING_ROUTE = `${PROFILE_EDIT_ROUTE}.book`;
export const PROFILE_PHOTO = 'profile_photo';
export const PROVIDER_VANITY_PHOTO = 'provider_vanity_photo';
export const SALON_PHOTO = 'salon_photo';
export const PROFILE_SERVICE_INFO = 'profile.service_info';
export const PROFILE_MESSAGE = 'profile.message';
export const BOOKING_ROUTE = `${PROFILE_ROUTE}.book`;
export const BOOKING_SUCCESS_ROUTE = `${PROFILE_ROUTE}.booking.confirmation`;

// Provider payments settings
export const PROVIDER_SETTINGS_PAYMENTS_MENU = `${PROVIDER_SETTINGS}.payments`;
export const PROVIDER_SETTINGS_PAYMENTS_CHECKOUT = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.checkout`;
export const PROVIDER_SETTINGS_PAYMENTS_IDENTITY = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.identity`;
export const PROVIDER_SETTINGS_PAYMENTS_ADDRESS = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.address`;
export const PROVIDER_SETTINGS_PAYMENTS_POLICY = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.policy`;
export const PROVIDER_SETTINGS_PAYMENTS_DEPOSIT = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.deposit`;
export const PROVIDER_SETTINGS_PAYMENTS_ONBOARDING = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.onboarding`;
export const PROVIDER_SETTINGS_PAYMENTS_UPDATE_CREDIT_CARD = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.update_credit_card`;
export const PROVIDER_SETTINGS_PAYMENTS_UPDATE_CREDIT_CARD_REDIRECT = `${PROVIDER_SETTINGS_PAYMENTS_MENU}.update_credit_card.redirect`;

// Provider Growth Tab
export const PROVIDER_GROWTH = 'provider__growth_tab';
export const PROVIDER_GROWTH_GIFT_CARDS = `${PROVIDER_GROWTH}.gift_cards`;
export const PROVIDER_GROWTH_SUMMARY = `${PROVIDER_GROWTH}.summary`;
export const PROVIDER_GROWTH_TRANSACTIONS = `${PROVIDER_GROWTH}.transactions`;
export const PROVIDER_GROWTH_EARNINGS = `${PROVIDER_GROWTH}.net_earnings`;
export const PROVIDER_GROWTH_PAYOUTS = `${PROVIDER_GROWTH}.payouts`;
export const PROVIDER_GROWTH_REPORTING = 'provider_growth_stats';
export const PROVIDER_GROWTH_LOANS = `${PROVIDER_GROWTH}.loans`;

// schedule settings
export const PRO_MANAGE_SCHEDULE = `${PROVIDER_RESPONSIVE}.schedule`;
export const PRO_MANAGE_ONGOING_SCHEDULE = `${PROVIDER_RESPONSIVE}.availability`;
export const PRO_ADJUST_UPCOMING_HOURS = `${PRO_MANAGE_SCHEDULE}.upcoming`;
export const PRO_MANAGE_VACATION = `${PRO_MANAGE_SCHEDULE}.vacation`;

// Client Account Settings
export const CLIENT_ACCOUNT_SETTINGS_ROUTE = 'consumer.account';
export const CLIENT_PROMOTIONS_LIST_ROUTE = `${CLIENT_ACCOUNT_SETTINGS_ROUTE}.promotions_list`;
export const CLIENT_CONTACT_INFORMATION_ROUTE = `${CLIENT_ACCOUNT_SETTINGS_ROUTE}.profile`;
export const CLIENT_PAYMENT_METHOD_PARENT = `${CLIENT_ACCOUNT_SETTINGS_ROUTE}.user_credit_card`;
export const CLIENT_PAYMENT_METHOD_LIST = `${CLIENT_PAYMENT_METHOD_PARENT}.list`;
export const CLIENT_PAYMENT_METHOD_ADD = `${CLIENT_PAYMENT_METHOD_LIST}.add`;
export const CLIENT_PAYMENT_METHOD_DETAIL = `${CLIENT_PAYMENT_METHOD_PARENT}.detail`;
export const CLIENT_CHANGE_PASSWORD_ROUTE = `${CLIENT_ACCOUNT_SETTINGS_ROUTE}.change_password`;
export const CLIENT_AUTO_CHECKOUT_SETTINGS_ROUTE = `${CLIENT_ACCOUNT_SETTINGS_ROUTE}.autocheckout_settings`;
export const CLIENT_DEFAULT_TIP_SETTING_ROUTE = `${CLIENT_ACCOUNT_SETTINGS_ROUTE}.edit_default_tip`;
export const TRACKED_CLIENT_SIGNUP = 'consumer.trackedsignup';
export const CLIENT_PASSWORD_RESET_CONFIRM = 'consumer.password_reset_confirm';

// Pro appointment management routes
export const CALENDAR_ROUTE = 'provider.calendarNext';
export const PRO_CALENDAR_LIST_APPOINTMENTS = `${CALENDAR_ROUTE}.appointments`;
export const PRO_CALENDAR_VIEW_APPOINTMENT = `${CALENDAR_ROUTE}.appointment`;
export const PRO_CALENDAR_NEW_APPOINTMENT = `${CALENDAR_ROUTE}.new`;
export const PRO_CALENDAR_EDIT_APPOINTMENT = `${PRO_CALENDAR_VIEW_APPOINTMENT}.edit`;
export const PRO_CALENDAR_REFUND_APPOINTMENT = `${PRO_CALENDAR_VIEW_APPOINTMENT}.refund`;
export const PRO_PERSONAL_APPOINTMENT_VIEW = `${CALENDAR_ROUTE}.personal_appointment`;
export const PRO_PERSONAL_APPOINTMENT_EDIT = `${PRO_PERSONAL_APPOINTMENT_VIEW}.edit`;
export const PRO_NCDC_APPROVE_APPOINTMENT = `${CALENDAR_ROUTE}.ncdc`;

export const PRINTABLE_CALENDAR_ROUTE = 'printCalendar';

// Pro service management routes
export const PRO_SERVICE_MENU = `${PROVIDER_RESPONSIVE}.manage_services`;
export const PRO_ADD_SERVICE = `${PROVIDER_RESPONSIVE}-services-add_services`;
export const PRO_ADD_ADD_ON = `${PROVIDER_RESPONSIVE}-services-add_add_on`;
export const PRO_EDIT_SERVICE = `${PROVIDER_RESPONSIVE}-services-edit_services`;
export const PRO_EDIT_ADD_ON = `${PROVIDER_RESPONSIVE}-services-edit_add_on`;
export const PRO_SERVICE_ONBOARDING = `${PROVIDER_RESPONSIVE}.service_menu_wizard`;

// Pro products management routes
export const PRO_PRODUCT_PAGE = `${PROVIDER_RESPONSIVE}.products_page`;
export const PRO_EDIT_PRODUCT = `${PROVIDER_RESPONSIVE}-products_edit_products`;

// pro photos
export const PRO_IMAGE_LIST = `${PROVIDER_RESPONSIVE}.images`;
export const PRO_GALLERY = 'gallery';

// Pro client management routes
export const PRO_CLIENT_BASE = 'provider.client';
export const PRO_CLIENT_LIST = `${PRO_CLIENT_BASE}.list`;
export const PRO_CLIENT_VIEW = `${PRO_CLIENT_LIST}.view`;
export const PRO_CLIENT_GET_MORE = `${PRO_CLIENT_LIST}.get_more_clients`;
export const PRO_CLIENT_VIEW_APPOINTMENT = `${PRO_CLIENT_VIEW}.appointment`;
export const PRO_CLIENT_IMPORT = `${PRO_CLIENT_LIST}.import`;
export const PRO_CLIENT_VIEW_APPOINTMENTS = `${PRO_CLIENT_VIEW}.appointments`;

// Pro promotion management routes
export const PRO_PROMOTIONS_LIST = `${PROVIDER_RESPONSIVE}.promotions`;
export const PRO_PROMOTION_DETAIL = `${PRO_PROMOTIONS_LIST}.view`;
export const PRO_PROMOTION_CREATE = `${PRO_PROMOTIONS_LIST}.create`;
export const PRO_PROMOTION_EDIT = `${PRO_PROMOTIONS_LIST}.edit`;

// Legal Routes for All Users
export const USER_DEPENDENT_LEGAL = 'user_dependent_legal';
export const USER_INDEPENDENT_LEGAL = 'user_independent_legal';

// Pro Email marketing
export const PRO_EMAIL_MARKETING_DASHBOARD = `${PROVIDER_RESPONSIVE}.email_campaigns`;
export const PRO_EMAIL_MARKETING_CREATE_FLOW_ROUTE = 'email_marketing_create';
export const PRO_EMAIL_MARKETING_EDIT_FLOW_ROUTE = 'email_marketing_edit';

export const AUTH_LOGIN = 'auth_login';

// Pro Today tab routes
export const PRO_TODAY_TAB_HOME = 'provider__home_tab';
export const PRO_NOTIFICATIONS_LIST = 'provider__notifications_list';
export const PRO_CHAT_INBOX = 'provider__chat_inbox';

// Admin
export const ADMIN_USERS = 'admin_users';
export const ADMIN_PROS = 'admin_providers';
export const COMPARE_ENV_FLAGS_SUMMARY = 'compare_env_flags_summary';

// Onboarding
export const PROVIDER_ONBOARDING_ROUTE = 'onboarding';
export const PROVIDER_ONBOARDING_SIGNUP = `${PROVIDER_ONBOARDING_ROUTE}.${Step.Signup}`;
export const PROVIDER_ONBOARDING_PROFILE = `${PROVIDER_ONBOARDING_ROUTE}.${Step.ProfilePhoto}`;
export const PROVIDER_ONBOARDING_ADDRESS = `${PROVIDER_ONBOARDING_ROUTE}.${Step.Address}`;
export const PROVIDER_ONBOARDING_AVAILABILITY = `${PROVIDER_ONBOARDING_ROUTE}.${Step.Availability}`;
export const PROVIDER_ONBOARDING_GALLERY = `${PROVIDER_ONBOARDING_ROUTE}.${Step.GalleryImage}`;
export const PROVIDER_ONBOARDING_PAYMENTS = `${PROVIDER_ONBOARDING_ROUTE}.${Step.PaymentsOnboarding}`;
export const PROVIDER_ONBOARDING_SERVICES = `${PROVIDER_ONBOARDING_ROUTE}.${Step.Services}`;
export const PROVIDER_ONBOARDING_SETUP_SUBSCRIPTION = `${PROVIDER_ONBOARDING_ROUTE}.${Step.SetupSubscription}`;
export const PROVIDER_ONBOARDING_PLAN_SELECT = `${PROVIDER_ONBOARDING_ROUTE}.${Step.PlanSelect}`;
export const PROVIDER_ONBOARDING_GOAL_SELECT = `${PROVIDER_ONBOARDING_ROUTE}.${Step.GoalSelect}`;
export const PROVIDER_ONBOARDING_FREE_TRIAL_EXPLANATION = `${PROVIDER_ONBOARDING_ROUTE}.${Step.FreeTrialExplanation}`;

export const MARKETING_UNSUBSCRIBE = 'marketing.unsubscribe';
export const MARKETPLACE_RULES = 'marketplace_rules';
// Generic Deep link
export const GENERIC_DEEP_LINK = 'generic_deep_link';
