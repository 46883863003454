// @ts-strict-ignore
import URI from 'urijs';
import moment, { MomentInput } from 'moment';
import Promotion from './model';
import { IDRFResponse } from '../../../types';
import { SerializedPromotion } from './types';

import { ssFetchJSON } from '../../../modules/ssFetch';
import { getAllCachedServices } from '../ServiceGroups';
import { API_ROOT } from '../../../config';

interface PromotionRequestOpts {
  prefetchServices?: boolean;
}

interface PromotionListRequestOpts extends PromotionRequestOpts {
  page?: number;
  validAt?: MomentInput;
}

export function updatePromotion(
  providerId: number,
  promotion: Partial<SerializedPromotion>,
): Promise<SerializedPromotion> {
  return ssFetchJSON<SerializedPromotion>(
    `${API_ROOT}/api/v2/providers/${providerId}/promotions/${promotion.id}`,
    {
      method: 'PUT',
      body: promotion,
    },
  );
}

export function createPromotion(
  providerId: number,
  promotion: Partial<SerializedPromotion>,
): Promise<SerializedPromotion> {
  return ssFetchJSON<SerializedPromotion>(
    `${API_ROOT}/api/v2/providers/${providerId}/promotions`,
    {
      method: 'POST',
      body: {
        ...promotion,
        providerId,
      },
    },
  );
}

export function fetchPromotion(
  providerId: number,
  promotionId: number,
): Promise<SerializedPromotion> {
  const uri = new URI(`${API_ROOT}/api/v2/providers/${providerId}/promotions/${promotionId}`);
  return ssFetchJSON<SerializedPromotion>(uri.toString());
}

export async function getPromotion(
  providerId: number,
  promoId: number,
  opts: PromotionRequestOpts,
) {
  const promotion = await fetchPromotion(providerId, promoId);
  if (opts.prefetchServices) {
    const cachedServices = await getAllCachedServices(providerId);
    return Promotion.fromObject({
      ...promotion,
      services: cachedServices.filter(cs => (promotion.services as number[]).includes(cs.id)),
    });
  }
  return Promotion.fromObject(promotion);
}

export function fetchPromotions(
  providerId: number,
  queryParams: { page?: string; validAt?: string },
): Promise<IDRFResponse<SerializedPromotion>> {
  const uri = new URI(`${API_ROOT}/api/v2/providers/${providerId}/promotions`);
  uri.addQuery(queryParams);
  return ssFetchJSON<IDRFResponse<SerializedPromotion>>(uri.toString());
}

/**
 * Loads promotions for a given provider
 * @param {String} providerId The provider ID
 * @param {Object} options An options hash
 * @param {Number} [options.page=1] The page to load
 * @param {String} [validAt] The valid time for the promotions to load
 * @yields {Array<Object>} The list of promotion objects
 */
export async function getFormattedPromotions(
  providerId: number,
  options: PromotionListRequestOpts,
): Promise<Promotion[]> {
  const uri = new URI(`${API_ROOT}/api/v2/providers/${providerId}/promotions`);
  const opts = options || {};

  const page = options.page || 1;
  uri.addQuery('page', page);

  let { validAt } = opts;

  // Valid at
  if (validAt) {
    if (validAt === 'now') {
      validAt = moment();
    } else if (moment(validAt).isValid()) {
      validAt = moment(validAt);
    }

    if (moment.isMoment(validAt)) {
      validAt = validAt.utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    uri.addQuery('valid_at', validAt);
  }

  const { results: promotions } = await ssFetchJSON<IDRFResponse<SerializedPromotion>>(
    uri.toString(),
  );

  if (opts.prefetchServices) {
    const cachedServices = await getAllCachedServices(providerId);
    return promotions.map(promo => Promotion.fromObject(({
      ...promo,
      services: cachedServices.filter(cs => (promo.services as number[]).includes(cs.id)),
    })));
  }
  return promotions.map(Promotion.fromObject);
}

/**
 * Loads the currently active promotions for a provider or salon.
 * @param {String} providerId The provider ID
 * @param {Object} options An options hash
 * @param {Number} options.page The page number to load
 * @yields {Array<Object>} The list of promotion objects
 */
export function loadCurrentPromotions(
  providerId: number,
  options: { page?: number },
): Promise<Array<Promotion>> {
  return getFormattedPromotions(providerId, {
    ...(options || {}),
    validAt: moment(),
  }).then(results => results.filter(promo => {
    if (promo.disabled_time) {
      return !(promo.disabled_time.isAfter(promo.book_end_time, 'day') || promo.disabled_time.isBefore(promo.book_start_time, 'day'));
    }
    return true;
  }));
}
