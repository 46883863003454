// @ts-strict-ignore
import { API_ROOT } from '../../../config';
import { getOS } from '../../../modules/AppInfo';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { ICard } from '../../../store/PaymentMethods';
import { PaymentOption } from './Appointments';

export type CheckoutPayloadParams = {
  charge_tip_later?: boolean;
  tip?: string;
  do_not_send_client_receipt?: false;
  signature?: string;
  payment_type: PaymentOption;
  card_id?: string;
  creation_source: string;
};

interface CheckoutSuccessResponse {
  status: 'OK';
  token: string;
  callback: false;
  appointment_id: number;
}

interface CheckoutPaymentErrorResponse {
  status: 'ERROR';
  index: number | null;
  'error_message': string;
  'token': string;
  'callback': false;
}

type CheckoutErrorResponse = {
  __all__: string[];
} | Record<string, string[]>;

export type CheckoutResponse = (
  CheckoutSuccessResponse | CheckoutPaymentErrorResponse | CheckoutErrorResponse
);

export function isCheckoutSuccess(response: unknown): response is CheckoutSuccessResponse {
  return (
    typeof response === 'object'
    && 'status' in response
    && (response as CheckoutSuccessResponse | CheckoutPaymentErrorResponse).status === 'OK'
  );
}

export function isCheckoutPaymentError(
  response: unknown,
): response is CheckoutPaymentErrorResponse {
  return (
    typeof response === 'object'
    && 'status' in response
    && (response as CheckoutSuccessResponse | CheckoutPaymentErrorResponse).status === 'ERROR'
  );
}

export type CheckoutPayload = {
  product_cost: string;
  chargeTipLater: boolean;
  cost: string;
  depositAmount: string | number;
  tip?: string;
  balanceRemainingBeforeDiscount: number;
  paymentType: PaymentOption;
  card?: ICard;
  signature?: string;
};

export function buildCheckoutParams(checkout: CheckoutPayload): CheckoutPayloadParams {
  return {
    payment_type: checkout.paymentType,
    signature: checkout.signature,
    card_id: (checkout.card?.id || '').toString(),
    charge_tip_later: checkout.chargeTipLater,
    tip: checkout.tip || '0',
    do_not_send_client_receipt: false,
    creation_source: getOS(),
  };
}

export function checkoutAppointment(
  providerId: number,
  appointmentId: number,
  checkout: CheckoutPayload,
): Promise<CheckoutResponse> {
  const checkoutParams = buildCheckoutParams(checkout);
  const url = new URL(`/api/v2/providers/${providerId}/appointments/${appointmentId}/checkout`, API_ROOT);
  return ssFetchJSON<CheckoutResponse>(url.toString(), {
    method: 'POST',
    body: checkoutParams,
  });
}
