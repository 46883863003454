// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel, RootState } from './models';
import { ssFetchJSON } from '../modules/ssFetch';
import { RECOMMENDATIONS_ENGINE_PREFIX, RECOMMENDATIONS_SOURCE_URL } from '../config';

export type ServiceSearchData = {
  city: string;
  state: string;
  found: boolean;
  service_category: string;
};

export type CategoriesData = {
  category: string;
  percentage: number;
  search_term: string;
  image_url: string;
};

export type ProviderRecommendationData = {
  found: true;
  loading: false;
  city: string;
  state?: string;
  profession?: string;
  nearby_pros?: number;
  search_term?: string;
  top_categories?: CategoriesData[] | null;
};

type PlaceholderRecommendationData = {
  loading: boolean;
  found: false;
};

type StoredProviderRecommendationData =
  PlaceholderRecommendationData
  | ProviderRecommendationData;

type State = {
  services: {
    [id: string]: ServiceSearchData;
  };
  providers: {
    [id: string]: StoredProviderRecommendationData;
  };
};

const model = createModel<RootModel>()({
  name: 'recommendations',

  state: {
    services: {},
    providers: {},
  } as State,

  reducers: {
    onProviderLoadingStart(state: State, { providerId }: { providerId: number | string }) {
      return {
        ...state,
        providers: {
          ...state.providers,
          [providerId]: {
            loading: true,
            found: false,
          } as PlaceholderRecommendationData,
        },
      };
    },

    onServiceLoaded(state: State, {
      serviceId,
      serviceSearchData,
    }: {
      serviceId: string | number;
      serviceSearchData: ServiceSearchData;
    }) {
      return {
        ...state,
        services: {
          ...state.services,
          [`${serviceId}`]: {
            ...serviceSearchData,
            found: true,
          },
        },
      };
    },

    onProviderLoaded(state: State, {
      providerId,
      providerData,
    }: {
      providerId: string | number;
      providerData: ProviderRecommendationData;
    }) {
      return {
        ...state,
        providers: {
          ...state.providers,
          [`${providerId}`]: {
            found: true,
            ...providerData,
          },
        },
      };
    },

    onServiceNotFound(state: State, {
      serviceId,
    }: {
      serviceId: string | number;
    }) {
      return {
        ...state,
        services: {
          ...state.services,
          [`${serviceId}`]: {
            city: null,
            state: null,
            found: false,
            service_category: null,
          },
        },
      };
    },

    onProviderNotFound(state: State, {
      providerId,
    }: {
      providerId: string | number;
    }) {
      return {
        ...state,
        providers: {
          ...state.providers,
          [`${providerId}`]: {
            found: false,
            loading: false,
          },
        },
      };
    },
  },

  effects: dispatch => ({
    loadServiceSearchData: async ({
      serviceId,
    }: {
      serviceId: string | number;
    }, rootState: RootState) => {
      if (!rootState.recommendations.services[`${serviceId}`]) {
        try {
          const serviceSearchData = await ssFetchJSON(`${(
            RECOMMENDATIONS_SOURCE_URL
          )}service_id_to_category/${(
            RECOMMENDATIONS_ENGINE_PREFIX
          )}/${serviceId}.json`);

          dispatch.recommendations.onServiceLoaded({
            serviceId,
            serviceSearchData,
          });
        } catch (e) {
          dispatch.recommendations.onServiceNotFound({ serviceId });
        }
      }
    },
    loadProviderRecommendations: async ({
      providerId,
    }: {
      providerId: string | number;
    }, rootState: RootState) => {
      if (
        !rootState.recommendations.providers[providerId]?.found
        && !rootState.recommendations.providers[providerId]?.loading
      ) {
        dispatch.recommendations.onProviderLoadingStart({ providerId });
        try {
          const providerData = await ssFetchJSON(`${(
            RECOMMENDATIONS_SOURCE_URL
          )}provider_service_categories/${(
            RECOMMENDATIONS_ENGINE_PREFIX
          )}/${providerId}.json`);

          dispatch.recommendations.onProviderLoaded({
            providerId,
            providerData,
          });
        } catch (e) {
          dispatch.recommendations.onProviderNotFound({ providerId });
        }
      }
    },
  }),
});

export default model;
