import { DiscountDetails, PromoTypeValues } from './NCDAndDiscountedPros.types';
import { DateTimeStamp } from '../types/dateTime';

type DiscountType = 'amount' | 'percentage';

export enum DiscountStatus {
  Applied = 'applied',
  Redeemed = 'redeemed',
  Forfeit = 'forfeit',
  Available = 'available',
  Expired = 'expired',
}

export interface IDiscountDisplay extends Omit<DiscountDetails, 'isNewClientAppointment'> {
  id: string;
  discountCode: string;
  type: DiscountType;
  status: DiscountStatus;
  title?: string;
  description?: string;
  expiration?: DateTimeStamp;
  rewardId?: number;
  promoType?: PromoTypeValues;
  checkTestName?: string;
}
