import { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';
import { ProAppointment } from '../Appointments';
import { clientHistoryCache } from './History';

/**
 * Get past appointments, notes, and photos for a client.
 *
 * @param providerId The provider ID
 * @param clientId The client ID
 * @param size The number of records to retrieve
 * @param page The page to retrieve
 * @returns Array of client history objects
 */
export async function getClientAppointmentsWithinAYear(
  providerId: number | string,
  clientId: number | string,
): Promise<IDRFResponse<ProAppointment>> {
  const endDate = new Date();
  endDate.setFullYear(new Date().getFullYear() + 1);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);

  return ssFetchJSON<IDRFResponse<ProAppointment>>(
    `/api/v2.1/providers/${providerId}/clients/${clientId}/appointments?size=all&end_date=${endDate.toISOString()}`,
    {
      ssCache: clientHistoryCache,
      throwOnHttpError: true,
    },
  );
}
