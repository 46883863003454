// @ts-strict-ignore
import moment from 'moment';
import { ProStatsResponse, StatsFields } from '../../../../api/Providers/Stats';
import { ProStatsFormatted, WithDate } from '../types';
import { copyValue } from '../utils/copyValue';

const statFieldToList = <T>(statsByDate: { [date: string]: T }): WithDate<T>[] => {
  const stat = copyValue(statsByDate) || {};
  return (
    Object
      .keys(stat)
      .map(date => ({
        ...stat[date],
        date: moment(date, 'YYYY-MM-DD'),
      }))
      .sort((a, b) => {
        if (!a.date || !b.date) {
          return 0;
        }
        return a.date.valueOf() - b.date.valueOf();
      })
  );
};

export const toLists = <F extends StatsFields = StatsFields>(
  stats: ProStatsResponse<F>,
): ProStatsFormatted<F> => {
  const fields = Object.keys(stats);
  return fields.reduce((result, statName) => {
    let stat = stats[statName];
    if (statName !== 'status' && statName !== 'top_services') {
      stat = statFieldToList(stat);
    }

    return ({
      ...result,
      [statName]: stat,
    });
  }, {} as ProStatsFormatted<F>);
};
