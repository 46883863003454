import useMount from './useMount';
import useInTest from './useInTest';
import useAssignTest from './useAssignTest';
import type { ABTestVariationDetails } from '../store/ABTest';

export default function useAndAssignTest(
  testName: string,
  percent?: number,
  variations?: ABTestVariationDetails,
) {
  const assignTest = useAssignTest(testName, percent, variations);

  useMount(() => {
    assignTest();
  });

  return useInTest(testName);
}
