import { ProviderPlan } from '../types/provider';
import {
  MultiFactorAuthChoiceType,
  MultiFactorAuthMethod,
  MultiFactorAuthStatus,
} from '../components/shared/login/MultiFactorAuthForm/types';
import { DateTimeStamp } from '../types/dateTime';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    dataLayer?: {
      push?: (args: Record<string, any>) => void;
      find?: (args: (item: Record<string, any>) => boolean) => Record<string, any> | undefined;
    };
    braze?: {
      logCustomEvent: (eventName: string, eventProperties?: object) => boolean;
      changeUser: (userId: string, signature?: string) => void;
      getUser: () => object;
      getDeviceId: (callback: (deviceId: string) => void) => void;
      isPushSupported: () => boolean;
      requestPushPermission: () => void;
      unregisterPush: () => void;
      isPushPermissionGranted: () => boolean;
      isPushBlocked: () => boolean;
    };
    fbInitializationPromise?: Promise<unknown>;
    FB?: {
      getLoginStatus: unknown;
      login: unknown;
      init: unknown;
      getUserData: unknown;
      api: unknown;
    };
  }
}

export type SignupClientModalResponse = {
  status: string;
  type: 'Client';
  email: string;
  username: string;
  action: 'Updating Info' | 'Signup';
  user_id: number;
  num_booked: number;
  first_name: string;
  last_name: string;
  phone_number?: string;
  phone_type?: number;
  auth_token?: string;
  callback?: string;
};

export type SignupUserModalResponse = {
  status: string;
  type: 'User';
  email: string;
  action: 'Updating Info' | 'Signup';
  user_id: number;
  auth_token?: string;
  callback?: string;
};

export interface IResponseError extends Error {
  data: any;
  status: number;
}

export type MakeGoogleAuthPayload = {
  google_jwt: string;
  accepted_terms: any;
};

export type GoogleAuthResponse = {
  action: 'Login' | 'Signup';
  user_id: number;
  auth_token: string;
};

export type MakeSignupEffectPayload = {
  password?: string;
  password1?: string; // used by signupclient
  password2?: string; // used by signupuser
  subscribed?: boolean;
  name?: string;
  email: string;
  fb_data?: any;
  google_jwt?: any;

  firstName?: string;
  lastName?: string;
  fullName?: string;
  phoneNumber?: string;

  first_name?: string;
  last_name?: string;
  full_name?: string;
  phone_number?: string;

  accepted_terms?: string;
};

export type SetCurrentUserPayloadFields = {
  is_anon?: boolean;
  ss_tracking_cookie?: string;
  num_booked?: number;
  providers?: { id: number; name: string }[];
  user_id?: number;
  auth_token?: string;
  isLogin?: boolean;
  state_get_url?: string;
  state_post_url?: string;
  apple_id?: string;
  has_usable_password?: boolean;
  error?: number;
  facebook_id?: string;
  jwt_token?: string;
  is_new_user?: boolean;
  client_handle?: string;
};

export type SetCurrentUserPayload = { is_new_user?: boolean; client_handle?: string } & (
  SetCurrentUserPayloadFields
  | (SetCurrentUserPayloadFields & {
    provider_id: number;
    plan: ProviderPlan;
    num_appt: number;
    num_clients: number;
  })
  | AjaxLoginResponseLogin
  | AjaxLoginResponseMFA
);

export type UpdateCurrentUserPayload = {
  smsOptedIn?: boolean;
  sms_opted_in?: boolean;
  reminder_preference?: number;

  firstName?: string;
  preferredPronouns?: string;
  lastName?: string;
  fullName?: string;
  phoneNumber?: string;
  email?: string;

  first_name?: string;
  last_name?: string;
  full_name?: string;
  phone_number?: string;
  phone_type?: number;
  is_new_user?: boolean;
};

export type OnNotifyCurrentUserPayload = {
  isLogin?: boolean;
};

export type NotifyUserPayload = {
  isLogin?: boolean;
  setCurrentUserPayload: SetCurrentUserPayload;
};

/** Dev note: This is not an accurate representation of what's actually in the user state, as
 *  it can be set via ajax_login (which returns the client or pro version of the whoami serializer),
 *  via signup_(user|client)_modal (which returns a different data structure ), possibly more for
 *  Facebook/Apple signin, as well as a copy of whatever was in the user state at the time.
 */
export type State = {
  isLogin?: boolean;
  mfaRequired?: boolean;
  numBooked?: number;
  date_joined?: DateTimeStamp;
  email: string;
  hashed_email?: string;
  ss_tracking_cookie: string;
  errors?: string | { [key: string]: unknown } | null;
  numAppt?: number;
  userId?: number;
  providerId?: number;
  providers?: { id: number; name: string }[];
  is_superuser?: boolean;
  is_anon: boolean;
  auth_token?: string;
  numClients?: number;
  first_name: string | undefined;
  last_name: string | undefined;
  preferred_pronouns: string | undefined;
  plan?: ProviderPlan;
  user_id?: number;
  apple_id?: string | null;
  facebook_id?: string | null;
  username?: string;
  phone_number?: string;
  jwt_token?: string;
  // if true, the user signed up in this session
  is_new_user?: boolean;
  user_token?: string;
  client_handle?: string;
  has_usable_password?: boolean;
};

// TODO move this closer to fbAuth.js when it becomes typescript
export type FBAuthResult = {
  fbAuthStatus: unknown;
  fbUserData: {
    first_name: string;
    last_name: string;
    email: string;
  };
  callback?: string;
  provider_id?: number;
  whoami?: SetCurrentUserPayload;
};

export type AjaxLogoutResponse = {
  status: 'OK';
};

export interface IWhoAmIAnonymous {
  is_anon: true;
  ss_tracking_cookie: string;
}

interface IWhoAmIBase {
  date_joined: DateTimeStamp;
  ss_tracking_cookie: string;
  apple_id: string | null;
  auth_token: string | null;
  email: string;
  hashed_email: string;
  error: number;
  facebook_id: string | null;
  first_name: string;
  has_usable_password: boolean;
  is_anon: false;
  isLogin: true;
  is_staff: boolean;
  is_superuser: boolean;
  last_name: string;
  phone_number: string;
  state_get_url: string;
  state_post_url: string;
  status: 'OK';
  user_id: number;
  username: string;
}

export interface IWhoAmIPro extends IWhoAmIBase {
  num_appt: number;
  num_clients: number;
  plan: ProviderPlan;
  provider_id: number;
  providers: Array<{ id: number; name: string }>;
}

export interface IWhoAmIClient extends IWhoAmIBase {
  num_booked: number;
  loyalty_points: boolean | null;
}

export type WhoAmI = IWhoAmIAnonymous | IWhoAmIPro | IWhoAmIClient;

export function isWhoAmIAnonymous(
  whoami: WhoAmI,
): whoami is IWhoAmIAnonymous {
  return (whoami as IWhoAmIAnonymous).is_anon === true;
}
export function isWhoAmIPro(
  whoami: WhoAmI,
): whoami is IWhoAmIPro {
  const { is_anon: isAnon, provider_id: providerId } = whoami as IWhoAmIPro;
  return !isAnon && providerId !== undefined;
}

export function isWhoAmIClient(
  whoami: WhoAmI,
): whoami is IWhoAmIClient {
  const { is_anon: isAnon, loyalty_points: loyaltyPoints } = whoami as IWhoAmIClient;
  return !isAnon && loyaltyPoints !== undefined;
}

type AjaxLoginResponseMFA = {
  status: 'OK';
  user_id: never;
  mfa_required: true;
  mfa_status: MultiFactorAuthStatus;
  is_anon: true;
  mfa_delivery_method?: MultiFactorAuthMethod;
  mfa_delivery_options?: MultiFactorAuthChoiceType[];
  error?: string;
};

type AjaxLoginResponseLogin = (IWhoAmIPro | IWhoAmIClient) & {
  user_id: never;
  csrftoken: string;
};

type AjaxLoginResponseError = {
  error: string;
  password: string;
  [key: string]: string;
};

export type LoginWithGoogleParams = {
  google_jwt: string;
  accepted_terms: any;
};

export type AppleSSOResponse = {
  status: 'created' | 'logged_in';
  user: IWhoAmIBase;
};

/** status = 'OK' */
export type AjaxLoginOKResponse = AjaxLoginResponseMFA | AjaxLoginResponseLogin;

export type AjaxLoginResponse = AjaxLoginOKResponse | AjaxLoginResponseError;

export function isAjaxLoginOKResponse(obj: AjaxLoginResponse): obj is AjaxLoginOKResponse {
  return (obj as AjaxLoginOKResponse).status === 'OK';
}

export function isAjaxLoginResponseLogin(obj: AjaxLoginResponse): obj is AjaxLoginResponseLogin {
  return (obj as AjaxLoginResponseLogin).isLogin === true;
}

export function isAjaxLoginResponseMFA(obj: AjaxLoginResponse): obj is AjaxLoginResponseMFA {
  return (obj as AjaxLoginResponseMFA).mfa_required === true;
}

export function isAjaxLoginResponseMFAFail(obj: AjaxLoginResponse): obj is AjaxLoginResponseMFA {
  const mfaRequired = (obj as AjaxLoginResponseMFA).mfa_required;
  const mfaStatus = (obj as AjaxLoginResponseMFA).mfa_status;
  return mfaRequired && (
    mfaStatus !== MultiFactorAuthStatus.New
    && mfaStatus !== MultiFactorAuthStatus.SelectDeliveryMethod
  );
}
