// @ts-strict-ignore
/**
 List of header meta types (title, description, etc) and the elements that
 represent them.

 The format for this list is:
 ```
 <type>: [
 'selector1',
 'selector2'
 ]
 ```

 By default, the `content` attribute of the selector will be contain the value.
 If this is not the case for the selector, define the selector as an object,
 like this:
 ```
 image: [
 {
        selector: 'link[rel=image_src]',
        attribute: 'href'
      },
 'selector2'
 ]
 ```

 In this case the `href` attribute will be contain the main value in the
 element matching `link[rel=image_src]`,

 You can also set `attribute:false` to update the body content of the element
 instead of an attribute value.
 */

type MetaElementBuilder = () => HTMLElement;

type MetaMapEntryObject = {
  selector: string;
  attribute: string | boolean;
  builder?: MetaElementBuilder;
};

type MetaMapEntry = string | MetaMapEntryObject;

type MetaMap = {
  [name: string]: MetaMapEntry[];
};

const metaMappings: MetaMap = {
  title: [
    {
      selector: 'title',
      attribute: false,
    },
    'meta[property="og:title"]',
    'meta[name="twitter:title"]',
  ],
  description: [
    'meta[name=description]',
    'meta[property="og:description"]',
    'meta[name="twitter:description"]',
  ],
  image: [
    {
      selector: 'link[rel=image_src]',
      attribute: 'href',
    },
    'meta[property="og:image"]',
    'meta[name="twitter:image"]',
  ],
  type: ['meta[property="og:type"]'],
  twitterCard: ['meta[name="twitter:card"]'],
  canonical: [
    {
      selector: 'link[rel="canonical"]',
      attribute: 'href',
      builder: () => {
        const link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        return link;
      },
    },
    'meta[property="og:url"]',
    'meta[name="twitter:url"]',
  ],

  robots: [
    {
      selector: 'meta[name="robots"]',
      attribute: 'content',
    },
  ],
};

function updateMetaElement(element: Element, value: string, attr: string | boolean): void {
  // Update body of element
  if (attr === false) {
    // eslint-disable-next-line no-param-reassign
    (element as any).innerText = value;
  } else {
    element.setAttribute(attr as string, value);
  }
}

/**
 Set the index and sharing meta tags for the page

 Call this function with an object containing all the meta
 you want updated:

 * title
 * description
 * image
 * type
 * twitterCard

 In most cases these values will be normalized across open graph
 twitter and SEO meta tags.

 Example:

 ```
 page.setMeta({
        title: 'Book an appointment online with Gilbert Pickett',
        description: 'Gilbert Pickett at Salon Mio Mio in San Francisco',
        type: 'business.business',
        image: 'https://d220aniogakg8b.cloudfront.net/static/uploads/2014/07/15/047d3c54dc2e_1587444_base.jpg'
      });
 ```

 NOTE: Values that you don't set will retain their former value.
 */
export function setMeta(
  meta: { [type: string]: string } = {},
): void {
  // Update all changed meta tags
  Object.entries(meta).forEach(([type, value]) => {
    const elems = metaMappings[type];

    if (elems) {
      elems.forEach(selector => {
        let elementSelector: string;
        let attr: string | boolean = 'content';
        let builder: MetaElementBuilder | null;

        if (typeof selector === 'object') {
          const mapEntry: MetaMapEntryObject = selector;
          attr = mapEntry.attribute;
          elementSelector = mapEntry.selector;
          builder = mapEntry.builder || null;
        } else {
          elementSelector = selector;
        }

        const elements = document.head.querySelectorAll(elementSelector);

        if (elements.length > 0) {
          elements.forEach(el => {
            updateMetaElement(el, value, attr);
          });
        } else if (builder) {
          const el: HTMLElement = document.head.appendChild(builder());
          updateMetaElement(el, value, attr);
        }
      });
    }
  });
}

/**
 Get or set the page title

 @param {String} v (optional) If this is defined, the page
 title will be changed to this value
 @return {String} The page title
 */
export function title(v?: string) {
  if (v) {
    setMeta({
      title: v,
    });
  }
  return document.title;
}

/**
 Return the value for a meta type, or all values if a type isn't specified.

 @param {String} type The type to return. See `metaMappings`
 */
export function getMeta(type) {
  const values = {};

  // We don't have a mapping for that.
  if (type && typeof metaMappings[type] === 'undefined') {
    return null;
  }

  // Get the value from the first element of each meta type
  Object.entries(metaMappings).forEach(([elType, elems]) => {
    let attr: string | boolean = 'content';
    let selector: MetaMapEntry = elems[0];

    if (typeof selector === 'object') {
      attr = selector.attribute;
      selector = selector.selector;
    }

    document.head.querySelectorAll(selector).forEach(el => {
      if (attr === false) {
        values[elType] = (el as any).innerText;
      } else {
        values[elType] = el.getAttribute(attr as string);
      }
    });
  });

  if (type) {
    return values[type];
  }

  return values;
}
