import { PaymentMethodType } from '../../../components/consumer/booking/BookingFlow/types';
import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { DateTimeStamp } from '../../../types/dateTime';
import { PublicProviderLocation } from '../../Providers';
import {
  ClientCardOnFile,
  DepositStatus,
  DiscountCodeRedemption,
  TimeBlock,
} from '../../Providers/Appointments';
import { AppointmentTotals } from '../../Providers/Appointments/AppointmentTotals';
import {
  AutochargeStatus,
  CheckoutStatus,
  ProPromotionDiscount,
  transformAppointment,
  TransformAppointment,
} from './Appointments';
import { AppointmentProduct } from '../../Providers/Products';

export enum AppointmentDetailsStatus {
  Upcoming = 'upcoming',
  Current = 'current',
  Completed = 'completed',
}

// see UserAppointmentStatusDetailsProviderSerializer
export type AppointmentStatusDetailsProvider = {
  id: number;
  is_disabled: boolean;
  locations: PublicProviderLocation[];
  name: string;
  preferred_pronouns: string | null;
  profession: string;
  profile_photo: string | null;
  public_phone_number: string | null;
  rating_stars: number | null;
  ratings_count: number;
};

export enum AppointmentOrderedItemName {
  TIP = 'tip',
  PRODUCT = 'product',
  DEPOSIT = 'deposit',
  ADJUSTMENT = 'adjustment',
}

export interface AppointmentOrderedItem {
  product_name: AppointmentOrderedItemName | string;
  cost: number | string;
}

export interface CheckoutPaymentType {
  name: string;
  is_payment: boolean;
  memo: string;
  amount: string;
  bank_name: string | null;
  last4: string;
}

/**
 * UserAppointmentStatusDetailsSerializer
 * /users/{userId}/appointments/{apptId}/status_details
 */
export type AppointmentStatusDetailsResponse = {
  auth_amount: number | null;
  autocharge_status: AutochargeStatus;
  checkout?: {
    client_tipping_is_permitted: boolean;
    list_of_ordered_items: AppointmentOrderedItem[];
    cost: string;
    creation_source: string;
    payment_types: CheckoutPaymentType[];
    taxes: string;
    total: string;
  };
  cancellation_charge_remaining: string; // decimal
  checkout_status: CheckoutStatus;
  client_card_on_file: Pick<ClientCardOnFile, 'bank' | 'last4'> | null;
  cost: string;
  details_status: AppointmentDetailsStatus | null;
  deposit_status: DepositStatus | null;
  deposit_checkout?: {
    payment_types: CheckoutPaymentType[];
    total: string;
  };
  discount_code_redemption: DiscountCodeRedemption | null;
  id: number;
  is_cancellable: boolean;
  is_client_initiated_prepay_eligible: boolean;
  is_new_to_payments: boolean;
  is_rebookable: boolean;
  is_reschedulable: boolean;
  local_end: DateTimeStamp;
  local_start: DateTimeStamp;
  noshow_charge_remaining: string; // decimal
  manage_appointment_token: string;
  payment_method_type: PaymentMethodType | null;
  pro_adjustment: string;
  pro_promotion_discounts: ProPromotionDiscount[];
  product_cost: string;
  products?: AppointmentProduct[];
  provider: AppointmentStatusDetailsProvider;
  service_id: number | null;
  service_name: string;
  surcharge: string;
  tax: string;
  time_blocks: TimeBlock[];
  tip_is_confirmed: boolean;
  tip: string;
  total_charged: string;
  intake_form_id?: string | null;
  totals: AppointmentTotals;
};

export type AppointmentStatusDetails = TransformAppointment<AppointmentStatusDetailsResponse>;

/**
 * Gets a single appointment instance with status details for upcoming/current/completed screens
 * @param userId The user ID owning the appointment
 * @param appointmentId The ID of the appointment
 * @returns A promise resolving with the appointment
 */
export function loadAppointmentStatusDetails(
  userId: number,
  appointmentId: number,
): Promise<AppointmentStatusDetails> {
  const url = new URL(
    `/api/v2/users/${userId}/appointments/${appointmentId}/status_details`,
    API_ROOT,
  );

  return ssFetchJSON<AppointmentStatusDetailsResponse>(url.toString()).then(transformAppointment);
}
