// @ts-strict-ignore
import moment from 'moment';
import { ProviderBusinessHours } from '../../api/ProfileClientView/ProfileClientView';
import {
  getWorkPeriodsCurrentState,
  ICurrentStateWorkPeriodsResponse,
  WeekdayOrdinal,
} from '../../api/Providers/WorkPeriods';
import type { ICurrentWorkPeriods, ProviderScheduleDay } from '../../store/ProviderWorkSchedule.types';

/**
 * Returns work period entries that are recurring
 * @param entries
 * @returns {*[]}
 */
function getRecurringEntries<T extends { priority: number }[]>(entries: T = [] as T) {
  return entries.filter(entry => entry.priority === 2) as T;
}

// Takes a moment date and adds a time string to it
function getDateTime(day, time) {
  const dayStr = moment(day).format('YYYY-MM-DD ');
  return moment(dayStr + time, 'YYYY-MM-DD hh:mm');
}

/**
 * Converts a provider's work periods into a weekly schedule
 * @param workPeriods
 * @returns {[{day, closed, startTime, endTime}]}
 */
export function getWeeklyScheduleFromWorkPeriods(
  workPeriods: ICurrentStateWorkPeriodsResponse | ICurrentWorkPeriods,
): ProviderScheduleDay[] {
  const recurring = getRecurringEntries(workPeriods.current);

  // Create default weekday objects
  let weeklyHours = [];
  const weekDay = moment().startOf('isoWeek'); // starts on Monday
  for (let i = 0; i < 7; i++) {
    weeklyHours.push({
      day: weekDay.clone().toDate(),
      slots: [],
      closed: true,
    });
    weekDay.add(1, 'day');
  }

  // Add start/end times to each day
  recurring.forEach(period => {
    const dayHours = weeklyHours[period.day_of_week];
    const start = getDateTime(dayHours.day, period.start_time);
    const end = getDateTime(dayHours.day, period.end_time);

    // Start time
    if (!dayHours.startTime || start.isBefore(dayHours.startTime)) {
      dayHours.startTime = start.toDate();
      dayHours.closed = false;
    } else {
      dayHours.startTime = start.startOf('day').toDate();
    }
    // End time
    if (!dayHours.endTime || end.isAfter(dayHours.endTime)) {
      dayHours.endTime = end.toDate();
    } else {
      dayHours.endTime = end.startOf('day').toDate();
    }
  });

  weeklyHours = weeklyHours.map(day => {
    const slots = [];
    for (let i = 0; i < 96; i++) {
      const slot = moment(day.day).add(15 * i, 'minutes');
      if (slot.isSameOrAfter(day.startTime) && slot.isBefore(day.endTime)) {
        slots.push(i);
      }
    }
    return {
      ...day,
      slots,
    };
  });

  // Put Sunday at the start of the week
  weeklyHours.unshift(weeklyHours.pop());

  return weeklyHours;
}

/**
 * Gets the weekly schedule for the provided provider id
 * @param providerId
 * @returns {Promise<[]|Array>}
 */
export async function getWeeklySchedule(providerId: number) {
  const result = await getWorkPeriodsCurrentState(providerId);
  return result ? getWeeklyScheduleFromWorkPeriods(result) : [];
}

/**
 * Turns "business_hours" API response from into a ProviderScheduleDay
 * array
 * @param {ProviderBusinessHours} businessHours
 * @return {ProviderScheduleDay[]}
 */
export const getWeeklyScheduleFromBusinessHours = (
  businessHours: ProviderBusinessHours,
): ProviderScheduleDay[] => {
  const current = businessHours.filter(bh => bh.start_time !== null).map(bh => ({
    // priority 2 makes the work period "recurring" which is what
    // business hours is interested in
    priority: 2,
    start_time: bh.start_time,
    end_time: bh.end_time,
    // ProviderBusinessHours's "day" property starts the week on
    // a Monday (value of 0) and ends on Sunday (value of 6).
    // isoWeekday also starts on a Monday as well but the
    // values start at 1 so we remove one to line them up.
    day_of_week: moment(bh.day, 'dddd').isoWeekday() - 1 as WeekdayOrdinal,
  }))
    .sort((a, b) => a.day_of_week - b.day_of_week);

  return getWeeklyScheduleFromWorkPeriods({ current });
};
