// @ts-strict-ignore
import SimpleCache from '../SimpleCache';
import Analytics from '../analytics';
import { geocodeFromPosition } from '../maps/geocode';
import { APP } from '../../config';
import nonCriticalException from '../exceptionLogger';
import { fetchGeoIP } from '../../api/GeoIP';

export type DeviceLocation = {
  latitude: number;
  longitude: number;
  addressComponents?: {
    city?: string;
    state?: string;
  };
};

type GeoIPResult = {
  city: string;
  city_continent_code: string;
  city_country_code: string;
  latitude: string;
  longitude: string;
  metro_area: string;
  postal_code: string;
  region: string;
};

export enum LocationSource {
  Cache = 'cache',
  GeoIP = 'geoip',
  Device = 'device',
}

export type UserLocation = {
  latitude: number;
  longitude: number;
  address: string;
  source: LocationSource;
};

/**
 * Requests the user's device's current location.
 *
 * If this is the first time we've done this, the browser will open a modal asking the user if they
 * want to share their location.
 *
 * @returns {Promise} - A promise resolved when the geolocation API responds.
 * @yields {Object} An object containing location data.
 */

const CACHE_EXPIRE_SECS = 60;
const cache = new SimpleCache(CACHE_EXPIRE_SECS);

export async function getDeviceLocation(): Promise<DeviceLocation> {
  const CACHE_KEY = 'device-location';

  // Check cache
  if (cache.hasItem(CACHE_KEY)) {
    return Promise.resolve(cache.getItem(CACHE_KEY));
  }

  // Request location from device
  return new Promise((resolve, reject) => {
    const opts = {
      maximumAge: 10 * 60 * 10000, // Fresh as of 10 minutes ago
      timeout: 10000, // 10 seconds
      enableHighAccuracy: true,
    };

    if (navigator?.geolocation) {
      const { geolocation } = navigator;

      geolocation.getCurrentPosition(
        // Success
        position => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            altitude: position.coords.altitude,
            accuracy: position.coords.accuracy,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            speed: position.coords.speed,
          };
          geocodeFromPosition(location)
            .then(address => {
              Analytics.track('client_geolocation_success', address);

              // Cache
              cache.addItem(CACHE_KEY, address);

              resolve({
                ...location,
                addressComponents: {
                  city: address.city,
                  state: address.state,
                },
              });
            })
            .catch(err => {
              Analytics.track('client_geolocation_error', err);
              reject(err);
            });
        },
        // Error
        error => {
          Analytics.track('client_geolocation_error', error);
          /* if the error was a timeout and we are in the enableHighAccuracy
          test */
          if (error.code === error.TIMEOUT) {
            geolocation.getCurrentPosition(
              position => {
                const location = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  altitude: position.coords.altitude,
                  accuracy: position.coords.accuracy,
                  altitudeAccuracy: position.coords.altitudeAccuracy,
                  heading: position.coords.heading,
                  speed: position.coords.speed,
                };
                geocodeFromPosition(location)
                  .then(address => {
                    Analytics.track('client_geolocation_success', address);

                    // Cache
                    cache.addItem(CACHE_KEY, address);

                    resolve({
                      ...location,
                      addressComponents: {
                        city: address.city,
                        state: address.state,
                      },
                    });
                  })
                  .catch(err => {
                    Analytics.track('client_geolocation_error', err);
                    reject(err);
                  });
              },
              err => {
                Analytics.track('client_geolocation_error', err);
                reject(err);
              },
              {
                maximumAge: 10 * 60 * 10000, // Fresh as of 10 minutes ago
                timeout: 10000, // 10 seconds
              },
            );
          }
          reject(error);
        },
        opts,
      );
    } else {
      Analytics.track('client_geolocation_error', { error: 'unsupported' });
      reject(new Error('device does not support the Geolocation API.'));
    }
  });
}

/**
 * Checks if the user has already granted permission to use their
 * device location.
 * On Android, this uses the cordova permissions plugin
 * Elsewhere, the browser Permissions API is used
 * Currently, iOS does not support checking permissions
 *
 * @yields {boolean}
 */
export const checkLocationPermissionsNotDenied = async () => {
  if (APP) {
    const checkLocationAuthStatus = new Promise((resolve, reject) => {
      window.cordova.plugins.diagnostic.getLocationAuthorizationStatus(
        resolve,
        reject,
      );
    });

    try {
      const locationStatus = await checkLocationAuthStatus;
      switch (locationStatus) {
        case window.cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
        case window.cordova.plugins.diagnostic.permissionStatus.GRANTED:
        case window.cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
          return true;
        default:
          return false;
      }
    } catch (e) {
      nonCriticalException(e);
      return false;
    }
  }
  // Use the Permissions API for browser
  const { permissions } = navigator || {};
  try {
    const permissionStatus = await permissions?.query?.({ name: 'geolocation' });
    return (permissionStatus?.state === 'granted' || permissionStatus?.state === 'prompt');
  } catch (e) {
    nonCriticalException(e);
  }
  return false;
};

/**
 * Use our geoip service to return the user's latitude and longitude.
 * @yields {Object}
 */
export async function getGeoIP(): Promise<GeoIPResult | undefined> {
  // This doesn't work when running locally on docker, so you can point it to
  // another environment temporarily to test this. However, it should not be
  // checked in that way.
  try {
    const data = await fetchGeoIP();

    await Analytics.updateLocation({
      city: data.city,
      region: data.region,
      postal_code: data.postal_code,
      country: data.city_country_code,
      latitude: data.latitude ? Number.parseFloat(data.latitude) : null,
      longitude: data.longitude ? Number.parseFloat(data.longitude) : null,
    });

    Analytics.track('client_geoip_success');

    return data;
  } catch (error) {
    Analytics.track('client_geoip_error', error);
    /**
     * Note: this is awkward, but the promise code had a `.catch((e) => {
     *   Analytics.track('error', error);
     * });`
     * Which implicitly returned undefined on error, so this is
     * kept for consistency.
     */
    return undefined;
  }
}

/**
 * Gets either the users device location or, failing that, the geoIP location
 */
export async function getUserLocation(): Promise<UserLocation> {
  try {
    const result = await getDeviceLocation();
    return {
      latitude: result.latitude,
      longitude: result.longitude,
      address: `${result.addressComponents.city}, ${result.addressComponents.state}`,
      source: LocationSource.Device,
    };
  } catch {
    const result = await getGeoIP();
    if (!result) throw new Error('GeoIP did not return a valid location');

    return {
      latitude: Number(result.latitude),
      longitude: Number(result.longitude),
      address: [
        result.city,
        result.region,
      ].filter(Boolean).join(', '),
      source: LocationSource.GeoIP,
    };
  }
}
