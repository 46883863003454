// @ts-strict-ignore
/**
 * Utilities for File / URI / Blob manipulation.
 */

import promiseBreaker from './promiseBreaker';

export type BlobType = string | URL | Blob | File | HTMLImageElement | HTMLCanvasElement;

/**
 * Convert a base64 encoded string to a Uint8Array of char codes.
 */
export function base64ToUint8Array(b64: string): Uint8Array {
  // Decode base64
  const decodedData = Array.from(atob(b64));

  // Convert to an array of char codes
  return new Uint8Array(
    decodedData.map(v => v.charCodeAt(0)),
  );
}

/**
 * Parse a data URI into [mimetype, base64EncodedData].
 */
export function parseDataUri(dataUri: string | URL): Array<string> {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    d,
    type,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    b,
    encodedData,
  ] = dataUri.toString().split(/[:;,]/g);
  return [
    type,
    encodedData,
  ];
}

/**
 * Convert a data URI to a Blob.
 */
export function dataUriToBlob(dataUri: string | URL): Blob {
  const [type, encodedData] = parseDataUri(dataUri);
  const bytes = base64ToUint8Array(encodedData);
  return new Blob([bytes], { type });
}

/**
 * Convert a Blob or File to a data URI.
 */
export async function blobToDataUri(blob: Blob | File): Promise<string> {
  const reader = new FileReader();
  const promise = promiseBreaker(new Promise((resolve, reject) => {
    reader.onloadend = function (event) {
      if (event.target.result instanceof ArrayBuffer) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Expected string, got ArrayBuffer');
      } else {
        resolve(event.target.result);
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  }), {
    // If promise is canceled, abort the reader request
    onCancel: () => reader.abort(),
  });
  return promise;
}

/**
 * Convert an HTMLImageElement to a data URI.
 */
export function imageToDataUri(image: HTMLImageElement): string {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  // Copy the image contents to the canvas
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0);

  return canvas.toDataURL('image/png');
}

/**
 * Convert a variety of object types to a data URI.
 *
 * If provided a string, it is assumed to be either a data URI or a remote
 * URL. For the former it is a no-op, and for the latter, the remote object is
 * fetched and converted to a data URI.
 */
export async function toDataUri(
  item: BlobType,
): Promise<string> {
  if (typeof item === 'string' || item instanceof URL) {
    if (typeof item === 'string' && item.indexOf('data:') === 0) {
      // already a data URI
      return item;
    }
    if (item instanceof URL && item.protocol === 'data:') {
      // already a data URI
      return item.toString();
    }
    // Fetch remote object, convert to blob, and get data URI
    const response = await fetch(item.toString());
    const blob = await response.blob();
    return blobToDataUri(blob);
  }
  if ((item instanceof Blob) || (item instanceof File)) {
    return blobToDataUri(item);
  }
  if (item instanceof HTMLImageElement) {
    return imageToDataUri(item);
  }
  if (item instanceof HTMLCanvasElement) {
    return item.toDataURL('image/png');
  }
  // @ts-ignore
  throw new Error(`Unhandled item type for toDataUri: ${item?.constructor?.name}: ${JSON.stringify(item)}`);
}

/**
 * Convert a variety of object types to a Blob.
 */
export async function toBlob(
  item: BlobType,
): Promise<Blob> {
  return dataUriToBlob(await toDataUri(item));
}
