import { getProvider, PrivilegedProvider } from '../../../api/Providers';
import { IGalleryImage, getProviderGalleryImages } from '../../../api/Providers/GalleryImages';
import { getServiceGroups, ProviderServiceGroup } from '../../../api/Providers/ServiceGroups';

export type ProfileEditPageData = {
  profile: PrivilegedProvider;
  serviceGroups: ProviderServiceGroup[];
  galleryImages: IGalleryImage[];
  galleryImagesCount: number;
};

/**
 * Loads the edit profile data for endpoints that are not
 * a redux model
 * returns a FullProfile without the functions added by the Profile class
 * (profile + services + gallery images)
 * @param providerId
 */
export const loadProfileEditData = async (
  providerId: number,
): Promise<ProfileEditPageData> => {
  const [
    provider,
    serviceGroupsResults,
    galleryImagesResults,
  ] = await Promise.all([
    getProvider(providerId) as Promise<PrivilegedProvider>,
    getServiceGroups({ providerId }),
    getProviderGalleryImages(providerId),
  ]);

  return {
    profile: provider,
    serviceGroups: serviceGroupsResults?.results || [],
    galleryImages: galleryImagesResults.results,
    galleryImagesCount: galleryImagesResults.count,
  };
};
