import moment from 'moment';
import { compareData } from '..';
import { filterByDate } from '../filters';
import { groupDatasetBy } from '../groupBy';
import type {
  MasterDataSet,
  StatDataSet,
  YearlyBookingData,
} from '../types';

export const getYearlyBookings = (
  stats: MasterDataSet<'appts_booked'>,
): YearlyBookingData => {
  const startOfThisYear = moment().startOf('year');
  const endOfThisYear = moment().endOf('year');
  const lastYear = moment().subtract(1, 'year');
  const startOfLastYear = lastYear.clone().startOf('year');
  const endOfLastYear = lastYear.clone().endOf('year');

  const currentYearToTodayData = filterByDate(
    stats.appts_booked as StatDataSet<'appts_booked'>,
    startOfThisYear,
    moment(),
  );

  const {
    compared: lastYearData,
  } = compareData(
    stats.appts_booked,
    {
      start: startOfThisYear,
      end: endOfThisYear,
    },
    {
      start: startOfLastYear,
      end: endOfLastYear,
    },
  );
  return groupDatasetBy({
    lastYear: lastYearData,
    thisYear: currentYearToTodayData,
  } as YearlyBookingData, 'month') as YearlyBookingData;
};
