import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const SearchResultsReceived: SegmentEventData = {
  eventName: AnalyticEvent.SearchResultViewed,
  eventNameLegacy: 'search_results_received',
  eventProperties: [
    'search_id',
    'result_pro_ids',
  ],
};

export default SearchResultsReceived;
