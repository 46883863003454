export enum NCD_TYPES {
  NOT_NCD = 0,
  NCD = 3,
}

/**
 * cip is Client Incentives Program
 * These will either all be set, or all
 * be null together.
 */

export enum PromoTypeValues {
  Coupon = 'Coupon',
  AffiliateCode = 'Affiliate Code',
  ClientIncentivesProgramNewPro = 'Client Incentives Program - New Pro',
  Reward = 'Reward',
}

type CIPValues = {
  cip_new_pro_code: string;
  cip_new_pro_percent: number;
  cip_new_pro_max_amount: number;
} | {
  cip_new_pro_code: null;
  cip_new_pro_percent: null;
  cip_new_pro_max_amount: null;
};

export type DiscountDetails = {
  discountEligible: boolean;
  discountMax: number | null;
  discountCode: string | null;
  discountAmount: number | null;
  discountPercent: number | null;
  isNewClientAppointment: boolean;
  promoType?: PromoTypeValues | null;
  rewardId?: number | null;
};

export type DiscountDetailsWithKey = DiscountDetails & {
  discountKey: string;
};

export type RawNCDResponse = CIPValues & {
  ncd_type: NCD_TYPES;
  ncd_fee_percent: number;
  max_discount?: number;
  is_smart_price_eligible: boolean | null;
  is_new_client_appointment: boolean | null;
  discount_amount?: string;
  discount_percent?: string;
  code?: string;
  promo_type?: PromoTypeValues | null;
  key_name?: string;
  reward_id?: number;
};

export type DiscountInfo = CIPValues & DiscountDetails & {
  ncd_type: NCD_TYPES;
  ncd_fee_percent: number;
  discountEligible: boolean;
  is_smart_price_eligible: boolean | null;
  is_new_client_appointment: boolean | null;
};

export type StoredDiscountInfo = DiscountInfo & {
  is_known: boolean,
  ncd_source: string | null;
  ncd_tracking_token: string | null;
  wasFullVerification?: boolean | undefined;
  wasSingleCheck?: boolean | undefined;
};
