import produce from 'immer';
import { createSelector as createReduxSelector } from 'reselect';
import { createModel, RematchRootState } from '@rematch/core';
import type { RootModel } from '../models';
import type { ICard } from './PaymentMethods.types';
import {
  addCardToUser,
  getCardForUser,
  getCardsForUser,
  getDefaultCardFromList,
  InstrumentFilter,
  removeCardFromUser,
  setDefaultCardForUser,
} from '../../api/Users/Instruments';
import type { Stripe, StripeElementWithZipCode } from '../../components/shared/stripe/types';

type NewCardInfo = {
  stripe: Stripe;
  valid: boolean;
  newCardElement: StripeElementWithZipCode;
  errors?: string;
};

type OnMethodUpdatedPayload = {
  cardId: ICard['id'],
  card: Partial<ICard> & { id: ICard['id'] },
};

type OnMethodsLoadedPayload = {
  cards: Array<ICard>,
  filter?: InstrumentFilter,
};

let newCard: NewCardInfo | null = null;

type State = {
  loading: boolean;
  loaded: boolean;
  filter?: InstrumentFilter;
  methods: Array<ICard>;
  newCard?: null | {
    valid: boolean;
    errors?: string;
  }
};

const model = createModel<RootModel>()({
  name: 'paymentMethods',
  state: {
    loading: false,
    loaded: false,
    methods: [],
  } as State,

  reducers: {
    clearAllPaymentMethods: (state: State) => ({
      ...state, methods: [], newCard: null,
    }),
    onMethodUpdated: produce<State, [OnMethodUpdatedPayload]>((
      state: State,
      { cardId, card }: OnMethodUpdatedPayload,
    ) => {
      state.methods = state.methods.map(method => {
        if (method.id === cardId) {
          return {
            ...method,
            ...card,
          };
        }

        if (method.is_default && card.is_default) {
          return {
            ...method,
            is_default: false,
          };
        }

        return method;
      });
    }),
    onStartLoading: (state: State) => ({ ...state, loading: true }),
    onFinishLoading: (state: State) => ({ ...state, loading: false }),
    onMethodsLoaded: (state: State, { cards, filter }: OnMethodsLoadedPayload) => ({
      ...state,
      methods: [...cards],
      filter,
      loaded: true,
    }),
    onMethodRemoved: (state: State, cardId: number) => ({
      ...state,
      methods: state.methods.filter(card => card.id !== cardId),
    }),
    onMethodAdded: (state: State, payload: ICard) => {
      let found = false;
      const newMethods = state.methods.map(method => {
        if (method.id === payload.id) {
          found = true;
          return {
            ...method,
            ...payload,
          };
        }

        if (method.is_default && payload.is_default) {
          return {
            ...method,
            is_default: false,
          };
        }

        return method;
      });

      if (!found) {
        newMethods.push(payload);
      }

      return {
        ...state,
        methods: newMethods,
      };
    },

    /** Stores some metadata regarding a new card in state, but does not add it to the payment
     * methods list  */
    onNewCardStored: (state: State, payload: NewCardInfo): State => ({
      ...state,
      newCard: {
        valid: payload.valid,
        errors: payload.errors,
      },
    }),

    clearNewCard: (state: State): State => ({
      ...state,
      newCard: null,
    }),
  },

  effects: dispatch => ({
    /**
     * @private We only want to use this internally
     */
    async doLoad(
      payload: { filter: InstrumentFilter | undefined },
      rootState,
    ): Promise<Array<ICard>> {
      const { filter } = payload;

      await dispatch.paymentMethods.onStartLoading();

      let savedCards: Array<ICard>;

      try {
        savedCards = await getCardsForUser(rootState.user, filter);
        await dispatch.paymentMethods.onMethodsLoaded({
          cards: savedCards,
          filter,
        });
        return savedCards;
      } finally {
        await dispatch.paymentMethods.onFinishLoading();
      }
    },

    async forceLoadAll(): Promise<Array<ICard>> {
      return dispatch.paymentMethods.doLoad({ filter: undefined });
    },

    loadAll: async (
      _?: undefined,
      rootState?,
    ): Promise<Array<ICard>> => {
      if (rootState.paymentMethods.loaded && !rootState.paymentMethods.filter) {
        return rootState.paymentMethods.methods;
      }

      return dispatch.paymentMethods.forceLoadAll();
    },

    loadFiltered: async (
      filter: InstrumentFilter,
    ): Promise<Array<ICard>> => (
      dispatch.paymentMethods.doLoad({ filter })
    ),

    loadOne: async (cardId: number, rootState): Promise<ICard> => {
      await dispatch.paymentMethods.onStartLoading();
      let savedCard: ICard;

      try {
        savedCard = await getCardForUser(rootState.user, cardId);
        await dispatch.paymentMethods.onMethodAdded(savedCard);
        return savedCard;
      } finally {
        await dispatch.paymentMethods.onFinishLoading();
      }
    },

    updateDefault: async ({ card, cvcToken }: {
      card: ICard,
      cvcToken?: string,
    }, rootState): Promise<ICard> => {
      await dispatch.paymentMethods.onStartLoading();
      let savedCard: ICard;

      try {
        savedCard = await setDefaultCardForUser(rootState.user, card, cvcToken);

        await dispatch.paymentMethods.onMethodUpdated({
          cardId: card.id,
          card: savedCard,
        });

        return savedCard;
      } finally {
        await dispatch.paymentMethods.onFinishLoading();
      }
    },

    removeMethod: async (cardId: number, rootState): Promise<void> => {
      await dispatch.paymentMethods.onStartLoading();
      const toDelete = rootState.paymentMethods.methods.find(crd => crd.id === cardId);

      try {
        await removeCardFromUser(rootState.user, cardId);
        await dispatch.paymentMethods.onMethodRemoved(cardId);

        if (toDelete?.is_default) {
          // if we deleted the default, the backend will hopefully pick a different one, so let's
          // figure out what it is
          const savedCards = await getCardsForUser(rootState.user);
          await dispatch.paymentMethods.onMethodsLoaded({ cards: savedCards });

          if (!savedCards.find(item => item.is_default) && savedCards.length) {
            // if there *still* isn't a default, set the first one as default
            const newDefault = await setDefaultCardForUser(
              rootState.user,
              savedCards[0],
              undefined,
            );

            await dispatch.paymentMethods.onMethodUpdated({
              cardId: newDefault.id,
              card: newDefault,
            });
          }
        }
      } finally {
        await dispatch.paymentMethods.onFinishLoading();
      }
    },

    /**
     * Stores new card info in state for later retrieval when persisting to backend.
     * @param payload The new card data
     * @param rootState The current redux state
     */
    storeNewCardInfo(payload: NewCardInfo) {
      newCard = { ...payload };
      dispatch.paymentMethods.onNewCardStored(payload);
    },

    clearNewCard() {
      newCard = null;
    },

    /**
     * Saves a new card and adds it to the list of payment methods.
     */
    async saveNewCard(_?: undefined, rootState?): Promise<ICard> {
      if (!newCard) throw new Error('No new cards to save');
      const addedCard = await addCardToUser(
        newCard.stripe,
        rootState.user,
        newCard.newCardElement,
      );

      await dispatch.paymentMethods.onMethodAdded(addedCard);

      return addedCard;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    getDefault: () => createSelector(
      slice(state => state.methods),
      cards => getDefaultCardFromList(cards),
    ),
    getMethods: () => slice(state => state.methods),
    getMethod: hasProps((_: any, id: number | string) => (
      createSelector(
        slice,
        (state: State) => state.methods.find(card => card.id === Number(id)),
      )
    )),
  }),
});

export const selectors = {
  getDefault: createReduxSelector<RematchRootState<RootModel, Record<string, any>>, ICard, ICard>(
    rootState => getDefaultCardFromList(rootState.paymentMethods.methods),
    card => card,
  ),
};

export default model;
