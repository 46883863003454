export enum ProNCDExperienceReason {
  Disabled = 'disabled',
  IsSuperUser = 'superuser',
  OptedOut = 'opted_out',
  PaymentsDisabled = 'payments_disabled',
  NoCardOnFile = 'no_card_on_file',
  MarketingProNotInTest = 'marketing_pro_not_in_test',
  MarketingProInTest = 'marketing_pro_in_test',
  SchedulingProNotInTest = 'scheduling_pro_not_in_test',
  SchedulingProInTest = 'scheduling_pro_in_test',
  SchedulingProInHoldOut = 'scheduling_pro_in_holdout',
}

export enum NCDExperience {
  Version1 = 'v1',
  Version2 = 'v2',
  Version3 = 'v3',
}
