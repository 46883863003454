// @ts-strict-ignore
import { SegmentEventData } from '@styleseat/analytics/trackers/segment/events/types';
import { OnboardingGoal, Step } from '../../api/providers/types';
import {
  AnalyticsEvent,
  OnboardingSegmentProperty,
  TrackingEventsProp,
} from './types';
import { PrivilegedProvider } from '../../../api/Providers';
import { ICard, InstrumentType } from '../../../store/PaymentMethods';

export const onboardingSegmentEventProperties: OnboardingSegmentProperty[] = [
  OnboardingSegmentProperty.GoalSelected,
  OnboardingSegmentProperty.Last4CC,
  OnboardingSegmentProperty.AvailabilityDaysCount,
  OnboardingSegmentProperty.ServicesCount,
  OnboardingSegmentProperty.BusinessAddress,
  OnboardingSegmentProperty.BusinessName,
  OnboardingSegmentProperty.ProviderProfilePhoto,
  OnboardingSegmentProperty.GalleryImageCount,
];

export const ONBOARDING_TRACKING_EVENTS: { [key in Step]?: TrackingEventsProp } = {
  [Step.Signup]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Sign Up Page Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Sign Up Page Viewed',
        eventProperties: [],
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Sign Up Completed',
      segmentData: {
        eventName: 'Pro Onboarding Sign Up Completed',
        eventProperties: [],
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Sign Up Failed',
      segmentData: {
        eventName: 'Pro Onboarding Sign Up Failed',
        eventProperties: [],
      },
    },
  },
  [Step.Address]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Location Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Location Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Location Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Location Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Location Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Location Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.Availability]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Availability Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Availability Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Availability Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Availability Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Availability Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Availability Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.GalleryImage]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Add Photos Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Add Photos Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Add Photos Completed',
      segmentData: {
        eventName: 'Pro Onboarding Add Photos Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Add Photos Failed',
      segmentData: {
        eventName: 'Pro Onboarding Add Photos Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.PaymentsOnboarding]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Payments Onboarding Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Payments Onboarding Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Payments Onboarding Completed',
      segmentData: {
        eventName: 'Pro Onboarding Payments Onboarding Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Payments Onboarding Failed',
      segmentData: {
        eventName: 'Pro Onboarding Payments Onboarding Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.IdentityVerify]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Verify Information Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Verify Information Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Verify Information Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Verify Information Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Verify Information Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Verify Information Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.MailingAddress]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Mailing Address Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Mailing Address Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Mailing Address Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Mailing Address Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Mailing Address Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Mailing Address Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.BankAccount]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Bank Account Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Bank Account Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Bank Account Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Bank Account Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Bank Account Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Bank Account Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.ProfilePhoto]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Profile Picture Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Profile Picture Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Profile Picture Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Profile Picture Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Profile Picture Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Profile Picture Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.FreeTrialExplanation]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Free Trial Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Free Trial Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Free Trial Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Free Trial Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.Services]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Services Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Services Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Services Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Services Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Services Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Services Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.SetupSubscription]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Setup Subscription Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Setup Subscription Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Setup Subscription Completed',
      segmentData: {
        eventName: 'Pro Onboarding Setup Subscription Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Setup Subscription Failed',
      segmentData: {
        eventName: 'Pro Onboarding Setup Subscription Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.PlanSelect]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Select Plan Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Select Plan Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Select Plan Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Select Plan Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Select Plan Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Select Plan Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
  [Step.GoalSelect]: {
    [AnalyticsEvent.Viewed]: {
      name: 'Pro Onboarding Select Goal Screen Viewed',
      segmentData: {
        eventName: 'Pro Onboarding Select Goal Screen Viewed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Completed]: {
      name: 'Pro Onboarding Select Goal Screen Completed',
      segmentData: {
        eventName: 'Pro Onboarding Select Goal Screen Completed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
    [AnalyticsEvent.Failed]: {
      name: 'Pro Onboarding Select Goal Screen Failed',
      segmentData: {
        eventName: 'Pro Onboarding Select Goal Screen Failed',
        eventProperties: onboardingSegmentEventProperties,
      },
    },
  },
};

export const getOnboardingSegmentEventData = (eventName: string): SegmentEventData => {
  const onboardingSegmentEvents = Object.entries(ONBOARDING_TRACKING_EVENTS).flatMap((
    [, trackingEventsProp],
  ) => Object.entries(trackingEventsProp)).flatMap(([, eventConfig]) => eventConfig.segmentData);

  return onboardingSegmentEvents.find(segmentDataItem => segmentDataItem?.eventName === eventName);
};

export const getTrackingBaseProperties = (
  provider: PrivilegedProvider,
  galleryImageCount: number,
  availabilityDaysCount: number,
  selectedGoal: OnboardingGoal,
  paymentMethods: ICard[],
  totalServices: number,
) => {
  const baseProperties: { [key in OnboardingSegmentProperty]?: any } = {};
  baseProperties[OnboardingSegmentProperty.GoalSelected] = selectedGoal || '';
  baseProperties[OnboardingSegmentProperty.Last4CC] = paymentMethods?.find(method => [
    InstrumentType.DebitCard,
    InstrumentType.CreditCard,
    InstrumentType.PrepaidCard,
    InstrumentType.Card,
  ].includes(method.instrument_type))?.last4 ?? '';
  baseProperties[OnboardingSegmentProperty.AvailabilityDaysCount] = availabilityDaysCount === undefined ? '' : availabilityDaysCount;
  baseProperties[OnboardingSegmentProperty.ServicesCount] = totalServices;
  baseProperties[OnboardingSegmentProperty.BusinessAddress] = provider
    ?.locations?.[0]?.full_location_string ?? '';
  baseProperties[OnboardingSegmentProperty.BusinessName] = provider?.locations?.[0]?.name.length
    ? provider?.locations?.[0]?.name
    : '';
  baseProperties[OnboardingSegmentProperty.ProviderProfilePhoto] = provider?.profile_photo
      || '';
  baseProperties[OnboardingSegmentProperty.GalleryImageCount] = galleryImageCount;

  return baseProperties;
};
