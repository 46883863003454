// @ts-strict-ignore
import URI from 'urijs';
import { SEARCH_API_ROOT } from '../../config';
import ssFetch from '../../modules/ssFetch';
import { ISearchParameters, ISearchResponse } from './Salons.types';

export const SEARCH_VERSION = 'v3.0';

const SEARCH_URL = `${SEARCH_API_ROOT}/api/${SEARCH_VERSION}/salons.json`;

export async function getSalons(searchParameters: ISearchParameters): Promise<ISearchResponse> {
  const url = URI(SEARCH_URL).search(searchParameters).toString();
  // clear the headers because the search API gets confused with the ones we send by default
  const params = { headers: {} };
  // actual search request happens here
  const response = await ssFetch(url, params);

  if (!response.ok) {
    throw new Error(`${response.status}`);
  }

  return await response.json() as unknown as ISearchResponse;
}
