import { ssFetchJSON } from '../../modules/ssFetch';
import { IDRFResponse } from '../../types';
import { DateTimeStamp } from '../../types/dateTime';

interface InvoiceItem {
  amount: number; // cents
  currency: string;
  description: null | string;
  discountable: boolean;
  is_commission: boolean;
  line_item_type: 'subscription' | 'invoice_item';
  metadata: Record<string, any>;
  proration: boolean;
  quantity: number;
}

/**
 * @see StripeSubscriptionInvoiceSerializer
 */
interface Invoice {
  amount_due: number; // cents
  application_fee: number | null; // cents
  closed: boolean;
  currency: string;
  date: DateTimeStamp;
  discount: unknown; // If you need this field, please add info
  forgiven: boolean | null;
  items: InvoiceItem[];
  metadata: Record<string, any>;
  next_payment_attempt: DateTimeStamp | null;
  paid: boolean;
  period_start: DateTimeStamp | null;
  period_end: DateTimeStamp | null;
  statement_descriptor: string | null;
  subtotal: number; // cents
  tax: number | null; // cents
  tax_percent: string | null; // decimal percentage
  total: number; // cents
}

export enum SubscriptionChargeStatus {
  Succeeded = 'succeeded',
  Pending = 'pending',
  Failed = 'failed',
}

/**
 * @see ProviderSubscriptionStripeChargeListSerializer
 */
export interface APISubscriptionCharge {
  id: number;
  amount_dollars: string; // string dollar amount
  paid: boolean;
  captured: boolean;
  status: SubscriptionChargeStatus;
  failure_code: string | null;
  failure_message: string | null;
  external_created: DateTimeStamp;
  source: string;
  invoice: Invoice;
}

async function fetchSubscriptionCharges(
  providerId: number | string,
): Promise<IDRFResponse<APISubscriptionCharge>> {
  return ssFetchJSON<IDRFResponse<APISubscriptionCharge>>(
    `/api/v1/providers/${providerId}/subscription-charges`,
  );
}

export async function getSubscriptionCharges(
  providerId: number | string,
): Promise<APISubscriptionCharge[]> {
  const subscriptionCharges = await fetchSubscriptionCharges(providerId);
  return subscriptionCharges.results;
}
