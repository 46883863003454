export const BOT_USER_AGENT_STRINGS = [
  'Prerender (+https://github.com/prerender/prerender)',
  'APIs-Google',
  'AdsBot-Google-Mobile',
  'AdsBot-Google-Mobile',
  'AdsBot-Google',
  'Mediapartners-Google',
  'Googlebot',
  'Googlebot-Image',
  'Googlebot-News',
  'Googlebot-Video',
  'Mediapartners-Google',
  'AdsBot-Google-Mobile-Apps',
  'FeedFetcher-Google',
  'Google-Read-Aloud',
  'DuplexWeb-Google',
  'googleweblight',
  'Storebot-Google',
];
