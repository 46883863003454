import { useEffect, useState } from 'react';
import * as storage from '../modules/KeyValueStorage';

/**
 * Retrieves a value from storage
 * @param key: cache key
 * @param defaultValue: value returned if key does not exist
 * @returns {value}: any type
 */
const useStorage = (key, defaultValue = null) => {
  const [storedKey] = useState(key);
  const [result, setResult] = useState(null);

  const setValue = (value) => {
    storage.setItem(storedKey, value);
    setResult(value);
  };

  useEffect(() => {
    const fetchValue = async () => {
      const storedResult = await storage.getItem(key);
      const isKeyUnset = storedResult === null;
      setResult(isKeyUnset ? defaultValue : storedResult);
    };

    fetchValue();
  }, []);

  return [result, setValue];
};

export default useStorage;
