/**
 * Module for connecting and disconnecting Apple IDs from StyleSeat accounts.
 */

// NOTE: any imports here need to be relative, otherwise static builds (join page, etc) don't work
import { APP, API_ROOT } from '../config';
import { ssFetchJSON } from './ssFetch';
import PostMessageListener from './PostMessageListener';
import promiseBreaker from './promiseBreaker';
import { openPopupWindow } from './popupWindow';
import Janitor from './Janitor';
import { TERMS_VERSIONS } from './Terms';
import FeatureFlags from './FeatureFlags';

const authInitURI = `${API_ROOT}/sharing/apple_auth_init`;
const disconnectURI = `${API_ROOT}/sharing/apple_disconnect`;

const IOS_13_BROWSER = !APP && window.IOS_VERSION && window.IOS_VERSION >= 13;

export const FLOW_TYPES = {
  SAME_WINDOW: 'sameWindow',
  POPUP_WINDOW: 'popupWindow',
};

const errorMessages = {
  EMAIL_IN_USE:
    'The email address associated with your Apple ID is already associated with a StyleSeat Account. '
    + 'Please log in with email and password, or Facebook, then connect your Apple ID through your StyleSeat account '
    + 'settings.',
  NOT_CONNECTED:
    'There is no StyleSeat account associated with that Apple ID. Did you mean to sign up?',
  ALREADY_CONNECTED:
    'There is a StyleSeat account already associated with that Apple ID. Did you mean to log in?',
  NOT_LOGGED_IN:
    'There was an error connecting your StyleSeat account. Please log in and try again',
  INSUFFICIENT_DATA:
    'Apple did not provide enough information for us to connect your accounts. Please log in to '
    + 'your Apple account at https://appleid.apple.com and remove the StyleSeat app from your Apple account, then '
    + 'try again.',
  UNKNOWN_ERROR:
    'An unknown error occurred. Please try logging in with email and password or try again later.',
};

const getAcceptedTerms = () => [
  { accepted_version: TERMS_VERSIONS.eula, terms_type: 'eula' },
  {
    accepted_version: TERMS_VERSIONS.privacy_policy,
    terms_type: 'privacy_policy',
  },
  { accepted_version: TERMS_VERSIONS.consumer_tos, terms_type: 'tos' },
];

/**
 * If the user fires multiple auth attempts, we only care about the most recent one,
 * so we track the previous one here so it can be cancelled.
 * This prevents the user from seeing timeout errors for discarded auth attempts.
 */
let currentAuthRequest = null;

/**
 * Disconnect the user's Apple ID from their StyleSeat account.
 * @returns {Promise}
 */
export const disconnect = () => ssFetchJSON(disconnectURI, { method: 'POST' }).then(
  appleDisconnectResponse => {
    const { error } = appleDisconnectResponse;
    if (error) {
      throw new Error(error);
    }
    return appleDisconnectResponse;
  },
);

export const isEnabled = () => {
  const flagName = APP
    ? 'sign-in-with-apple-app'
    : 'sign-in-with-apple-mobileweb';
  return FeatureFlags.isEnabled(flagName);
};

/**
 * Transform auth options into a JSON-serializable object that should be passed along to Apple.
 *
 * This data ultimately gets POSTed to our apple_auth_receiver endpoint and indicates how to process
 * the auth response data.
 * @param options
 * @returns {{mode: string, destination: string|null, flowType: string,
 *            requestId: string}}
 */
const buildState = ({
  mode, flowType, destination, requestId,
}) => {
  // Data that gets passed along to Apple, then POSTed to apple_auth_receiver.html
  const state = {
    mode,
    flowType,
    destination,
    requestId,
    // targetOrigin is used in apple_auth_receiver.js to postMessage to the correct location
    targetOrigin: `${window.location.protocol}//${window.location.host}`,
    // Ensures we only process postMessage responses for this specific auth request
  };

  if (mode === 'signup') {
    // In locations where this is used for sign up, we have language indicating the user
    // is agreeing to current terms/privacy policy/etc, and we include links.
    state.acceptedTerms = getAcceptedTerms();
  }

  return state;
};

/**
 * Builds the URI for initiating the Apple ID OAuth flow.
 *
 * See https://developer.apple.com/documentation/signinwithapplejs/incorporating_sign_in_with_apple_into_other_platforms
 *
 * @param state - a JSON-serializable object that gets passed along to the redirect handler.
 * @returns {string}
 */
const buildAuthRequestURI = state => {
  const params = new URLSearchParams();
  params.set('state', JSON.stringify(state));
  if (IOS_13_BROWSER) {
    params.set('ios_13_browser', '1');
  }
  return `${authInitURI}?${params.toString()}`;
};

/**
 * Parse the apple_auth_receiver response. If an error is present, return a rejecting
 * promise with error info. If an error is not present, return a resolving promise
 * with the response object.
 * @param authResponse - apple_auth_receiver response object
 * @returns {Promise}
 */
export const handleAuthResponse = authResponse => {
  const { error } = authResponse;
  if (error) {
    let errorMessage;
    if (error in errorMessages) {
      errorMessage = errorMessages[error];
    } else {
      errorMessage = errorMessages.UNKNOWN_ERROR;
    }
    return Promise.reject(new Error(errorMessage));
  }
  return Promise.resolve(authResponse);
};

/**
 * Initiate the Apple OAuth flow within the current browser window.
 * Handling the response is up to calling code, since actual location redirects take place.
 */
const authWithFlowTypeSameWindow = ({
  mode, authToken, destination,
}) => {
  const flowType = FLOW_TYPES.SAME_WINDOW;

  // authToken gets picked up and removed by apple_auth_receiver.html
  localStorage.setItem('apple:authToken', authToken || '');

  const uri = buildAuthRequestURI(
    buildState({
      mode,
      destination,
      flowType,
    }),
  );

  // After auth, the user will be redirected to the current URL, with
  // apple:authResponseJSON stored in localStorage.
  // It is up the the state/view to manually handle authResponseJSON, since
  // the page will have unloaded and reloaded. This is necessary in cases
  // where browser popup blocking prevents us from automatically initiating the
  // Apple OAuth flow.
  window.location.replace(uri);
  // return a promise that never resolves, since user gets redirected above.
  return new Promise(() => {});
};

class AuthRequest {
  janitor = new Janitor();

  constructor({ authToken, mode }) {
    this.authToken = authToken;
    this.mode = mode;
    this.requestId = Math.random().toString();

    const uri = buildAuthRequestURI(
      buildState({
        mode,
        requestId: this.requestId,
        flowType: FLOW_TYPES.POPUP_WINDOW,
      }),
    );

    // Open the popup window
    // Messages are sent from apple_auth_receiver.js in the styleseat codebase.
    this.popupWindow = this.openPopupWindow(uri);

    window.popupWindow = this.popupWindow;

    window.url = uri;

    // When the popup requests the authToken, provide it
    this.sendAuthToken();

    // Listen for the `apple:authResponse` message from the popup.
    const authPromise = this.getAuthResponse()
      .then(
        event => handleAuthResponse(event.data.authResponse),
        error => handleAuthResponse({ error }),
      )
      .finally(() => this.cleanup(false));

    const breakerOpts = {
      onCancel: () => this.cleanup(false),
    };
    this.authPromise = promiseBreaker(authPromise, breakerOpts);
  }

  cleanup = (cancel = true) => {
    if (cancel) {
      this.authPromise.cancel();
    }
    this.janitor.cleanup();
    if (this === currentAuthRequest) {
      currentAuthRequest = null;
    }
  };

  onLoadStart = ({ url }) => {
    if (url.indexOf(API_ROOT) === 0) {
      clearTimeout(this.cleanupTimer);
    }
  };

  onLoadError = () => {
    // User cancelled or completed the native auth flow
    this.cleanupTimer = setTimeout(() => {
      this.cleanup();
    }, 1000);
  };

  openPopupWindow(uri) {
    const popupWindow = openPopupWindow(uri, {
      ssInAppBrowserFeatures: 'usewkwebview=yes,location=no',
      ssInAppBrowserCallbacks: {
        loadstart: this.onLoadStart,
        loaderror: this.onLoadError,
      },
      // When the popup is closed, cleanup
      onClose: () => {
        this.cleanup();
      },
    });
    this.janitor.addCleanup(() => {
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    });
    return popupWindow;
  }

  /**
   * Setup a PostMessageListener to respond to requests for the authToken.
   */
  sendAuthToken() {
    const authTokenMessage = {
      type: 'apple:authTokenResponse',
      requestId: this.requestId,
      authToken: this.authToken || null,
    };

    const authTokenListener = this.listen({
      expectedType: 'apple:authTokenRequest',
    }).respond(authTokenMessage);

    this.janitor.addCleanup(() => {
      authTokenListener.remove();
    });
  }

  /**
   * Listen for the authResponse from the popupWindow via postMessage.
   * @returns {Promise}
   */
  getAuthResponse() {
    const authResponseListener = this.listen({
      expectedType: 'apple:authResponse',
    });
    this.janitor.addCleanup(() => {
      authResponseListener.remove();
    });
    return authResponseListener.promise;
  }

  /**
   * Convenience wrapper around PostMessageListener.listen().
   */
  listen({ expectedType, timeout = 1000 * 60 * 5 }) {
    const opts = {
      expectedType,
      // Ensure we only listen for the response corresponding to this specific check
      extraMatch: event => event.data.requestId === this.requestId,
      popupWindow: this.popupWindow,
      // Only listen for messages from StyleSeat URIs
      expectedOrigin: /^[\d\w]+:\/\/([-\d\w]+\.)*styleseat\.((com)|(rocks))$/,
      // If no response received after 5 minutes, consider it failed so the listener gets removed
      timeout,
      // Stop listening after 1 matching event is received
      once: true,
    };
    return PostMessageListener.listen(opts);
  }
}

/**
 * Initiate the Apple OAuth flow within a new popup window.
 * Response is handled via postMessage handlers.
 */
const authWithFlowTypePopupWindow = options => {
  // If the user fires multiple auth attempts, we only care about the most recent one,
  // so we globally track the current one, should we need to cancel it.
  // This prevents the user from seeing timeout errors for discarded auth attempts
  // and ensures we properly cleanup popups and postMessage listeners.
  if (currentAuthRequest) {
    currentAuthRequest.cleanup();
  }
  const authRequest = new AuthRequest(options);
  currentAuthRequest = authRequest;
  return authRequest.authPromise;
};

/**
 * Initiating the OAuth flow to connect an Apple ID to a StyleSeat account.
 *
 * @param options - Object containing:
 *    authToken: <string> The user's authentication token (optional). This is used to connect
 *      a logged-in user with an Apple ID. If not provided, a new user will be created
 *      in the database corresponding to the Apple ID.
 *    flowType: <string> whether to use the 'popupWindow' or 'sameWindow' flow.
 *      The popupWindow type is preferred since it provides a faster user experience.
 *      The sameWindow flow requires the mobileweb app to fully reload after auth completes.
 *      (optional, default: 'popupWindow')
 *    destination - If flowType is "sameWindow", the location to navigate to on flow completion.
 * @returns {Promise<any>} - The promise is resolved with the authResponse object,
 *    generated by the apple_auth_receiver API endpoint. If authenticated fails, the promise will
 *    be rejected with a useful, user-facing error message from the backend.
 */
export const auth = options => {
  const opts = {
    authToken: null,
    flowType: FLOW_TYPES.POPUP_WINDOW,
    destination: window.location.toString(),
    ...options,
  };

  const {
    mode, authToken, flowType, destination,
  } = opts;

  if (mode !== 'signup' && mode !== 'login' && mode !== 'connect') {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(`Unknown mode ${mode}`);
  }
  switch (flowType) {
    case FLOW_TYPES.POPUP_WINDOW:
      return authWithFlowTypePopupWindow({
        mode,
        authToken,
      });
    case FLOW_TYPES.SAME_WINDOW:
      return authWithFlowTypeSameWindow({
        mode,
        authToken,
        destination,
      });
    default:
      return Promise.reject(new Error(`Unknown flowType ${flowType}`));
  }
};
