// @ts-strict-ignore
import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import { BrandColor } from '@styleseat/brand';
import { Inline } from '@styleseat/ui';

const SCALE_MIN_VALUE = 0.8;
const SCALE_MAX_VALUE = 1.0;
const OPACITY_MIN_VALUE = 0.5;
const OPACITY_MAX_VALUE = 1.0;
const DOT_SIZE = 10;

interface ButtonLoadingAnimProps {
  color?: string;
}

/**
 * Renders an "ellipsis" loading animation for buttons and other components.
 */
export default class ButtonLoadingAnim extends React.Component<ButtonLoadingAnimProps> {
  static defaultProps = {
    color: BrandColor.WhiteWash,
  };

  state = {
    dot1Scale: new Animated.Value(SCALE_MIN_VALUE),
    dot1Opacity: new Animated.Value(OPACITY_MIN_VALUE),
    dot2Scale: new Animated.Value(SCALE_MIN_VALUE),
    dot2Opacity: new Animated.Value(OPACITY_MIN_VALUE),
    dot3Scale: new Animated.Value(SCALE_MIN_VALUE),
    dot3Opacity: new Animated.Value(OPACITY_MIN_VALUE),
  };

  animation = null;

  componentDidMount() {
    const {
      dot1Scale,
      dot1Opacity,
      dot2Scale,
      dot2Opacity,
      dot3Scale,
      dot3Opacity,
    } = this.state;

    // initiate animation of our three dots, starting their individual animations 300ms apart
    this.animation = Animated.loop(
      Animated.stagger(300, [
        this.createAnimationSequence(dot1Opacity, dot1Scale),
        this.createAnimationSequence(dot2Opacity, dot2Scale),
        this.createAnimationSequence(dot3Opacity, dot3Scale),
      ]),
    );

    this.animation.start();
  }

  componentWillUnmount() {
    if (this.animation) {
      this.animation.stop();
    }
  }

  /**
   * Creates the "grow" animation for the given scale
   * @param {Animated.Value} scale The scale value
   */
  // eslint-disable-next-line class-methods-use-this
  createScaleUpAnimation(scale) {
    return Animated.timing(scale, {
      toValue: SCALE_MAX_VALUE,
      useNativeDriver: true,
    });
  }

  /**
   * Creates the "shrink" animation for the given scale
   * @param {Animated.Value} scale The scale value
   */
  // eslint-disable-next-line class-methods-use-this
  createScaleDownAnimation(scale) {
    return Animated.timing(scale, {
      toValue: SCALE_MIN_VALUE,
      useNativeDriver: true,
    });
  }

  /**
   * Creates the "darken" animation for the given opacity
   * @param {Animated.Value} opacity The opacity value
   */
  // eslint-disable-next-line class-methods-use-this
  createShowAnimation(opacity) {
    return Animated.timing(opacity, {
      toValue: OPACITY_MAX_VALUE,
      useNativeDriver: true,
    });
  }

  /**
   * Creates the "fade" animation for the given opacity
   * @param {Animated.Value} opacity The opacity value
   */
  // eslint-disable-next-line class-methods-use-this
  createHideAnimation(opacity) {
    return Animated.timing(opacity, {
      toValue: OPACITY_MIN_VALUE,
      useNativeDriver: true,
    });
  }

  /**
   * Creates the "expand and darken" animation for the given opacity and scale
   * @param {Animated.Value} opacity The opacity value
   * @param {Animated.Value} scale The scale value
   */
  createGrowAnimation(opacity, scale) {
    return Animated.parallel([
      this.createScaleUpAnimation(scale),
      this.createShowAnimation(opacity),
    ]);
  }

  /**
   * Creates the "shrink and fade" animation for the given opacity and scale
   * @param {Animated.Value} opacity The opacity value
   * @param {Animated.Value} scale The scale value
   */
  createShrinkAnimation(opacity, scale) {
    return Animated.parallel([
      this.createScaleDownAnimation(scale),
      this.createHideAnimation(opacity),
    ]);
  }

  /**
   * Creates the full animation sequence for a given opacity and scale
   * @param {Animated.Value} opacity The opacity value
   * @param {Animated.Value} scale The scale value
   */
  createAnimationSequence(opacity, scale) {
    return Animated.sequence([
      this.createGrowAnimation(opacity, scale),
      this.createShrinkAnimation(opacity, scale),
    ]);
  }

  /**
   * Generates the contents of a style prop with the given arguments in the expected places.∑
   * @param {Number|Object} opacity The opacity value
   * @param {String|Object} background The background value
   * @param {Number|Object} scale The scale value
   */
  generateStyle(opacity, scale) {
    const { color } = this.props;
    return StyleSheet.create({
      loadingDot: {
        opacity,
        transform: [{ scale }],

        height: DOT_SIZE,
        width: DOT_SIZE,
        borderRadius: DOT_SIZE / 2,
        backgroundColor: color,
      },
    });
  }

  render() {
    const {
      dot1Scale,
      dot1Opacity,
      dot2Scale,
      dot2Opacity,
      dot3Scale,
      dot3Opacity,
    } = this.state;

    return (
      <Inline
        space={2}
        justifyContent="center"
        alignItems="center"
        width={36}
      >
        <Animated.View
          style={this.generateStyle(dot1Opacity, dot1Scale).loadingDot}
        />
        <Animated.View
          style={this.generateStyle(dot2Opacity, dot2Scale).loadingDot}
        />
        <Animated.View
          style={this.generateStyle(dot3Opacity, dot3Scale).loadingDot}
        />
      </Inline>
    );
  }
}
