// @ts-strict-ignore
import _ from 'underscore';
import moment from 'moment';
import {
  IProviderClient,
  IProviderClientPhone,
  ProviderClientPhoneType,
} from '../../api/Providers/Clients/types';

export type IUIContactPhone = Required<IProviderClientPhone>;

type IUIContactPhoneOrEmpty = IUIContactPhone | {};

export function isValidContactPhone(value: IUIContactPhoneOrEmpty): value is IUIContactPhone {
  return !!(value as IUIContactPhone).number;
}

export interface IUIContactData {
  id: number;
  name: string | null;
  email: string | null;
  phones: {
    mobile?: IUIContactPhoneOrEmpty;
    home?: IUIContactPhoneOrEmpty;
    work?: IUIContactPhoneOrEmpty;
  };
  notifications: {
    sms: boolean; // Are SMS notifications enabled?
  };
  blocked: boolean;
  reminder_preference: number | null;
  location_address1: string | null;
  location_address2: string | null;
  location_city: string | null;
  location_state: string | null;
  location_zipcode: string | null;
  birthday: string | null;
}

function getPhoneNumbers(contact: Partial<Pick<IProviderClient, 'phones'>>): IProviderClientPhone[] {
  return contact.phones ?? [];
}

function getPhoneType(phone: IProviderClientPhone): number {
  // eslint-disable-next-line no-prototype-builtins
  if (phone.hasOwnProperty('type_id')) {
    return (phone as any).type_id;
  }
  // eslint-disable-next-line no-prototype-builtins
  if (phone.hasOwnProperty('type')) {
    return phone.type;
  }
  return phone.id;
}

function getPhoneByType(
  contact: Partial<Pick<IProviderClient, 'phones'>>,
  type: ProviderClientPhoneType,
): IUIContactPhoneOrEmpty {
  let number;
  if (getPhoneNumbers(contact).length) {
    number = _.find(getPhoneNumbers(contact), phone => getPhoneType(phone) === type);
  }

  const result = { ...number };
  // If a US country code is present in the phone number, that is
  // the phone number is 11 digits, and starts with a 1, we will
  // want to preserve the leading 1 in that phone number. This
  // extra added field lets us know that a country code exists.
  if (number) {
    const numberDigits = number.number.match(/\d/g).join('');
    result.has_us_country_code = numberDigits.length === 11 && numberDigits.startsWith('1');
  }

  return result;
}

export function parseBirthday(birthday?: string) {
  if (birthday) {
    const birthMoment = moment(birthday, 'MM/DD');
    return {
      birth_month: parseInt(birthMoment.format('M'), 10),
      birth_day: parseInt(birthMoment.format('D'), 10),
    };
  }
  return {
    birthday_day: null,
    birth_month: null,
  };
}

export function formatBirthday(contact?: Partial<Pick<IProviderClient, 'birth_month' | 'birth_day'>>): string | null {
  if (contact?.birth_month && contact?.birth_day) {
    return moment(`${contact.birth_month}/${contact.birth_day}`, 'M/D').format('MM/DD');
  }
  return null;
}

function buildData(rawContact: Partial<IProviderClient>): IUIContactData {
  const config = rawContact ?? {};

  return {
    id: config.id,
    name: config.name,
    email: config.email,
    phones: {
      mobile: getPhoneByType(config, ProviderClientPhoneType.Mobile),
      home: getPhoneByType(config, ProviderClientPhoneType.Home),
      work: getPhoneByType(config, ProviderClientPhoneType.Work),
    },
    notifications: {
      sms: config.reminder_preference === 1, // Are SMS notifications enabled?
    },
    blocked: !!config.blocked,
    reminder_preference: typeof config.reminder_preference === 'number'
      ? config.reminder_preference
      : null,
    location_address1: config.address1 || null,
    location_address2: config.address2 || null,
    location_city: config.city || null,
    location_state: config.state || null,
    location_zipcode: config.zipcode || null,
    birthday: formatBirthday(config),
  };
}

export class UIContact {
  readonly #data: IUIContactData;

  constructor(client: Partial<IProviderClient>) {
    this.#data = buildData(client);
  }

  setName(name) {
    this.#data.name = name;
  }

  getName() {
    return this.#data.name;
  }

  setEmail(email) {
    this.#data.email = email;
  }

  getEmail() {
    return this.#data.email;
  }

  setMobilePhone(phone) {
    this.#data.phones.mobile = phone;
  }

  getMobilePhone() {
    return this.#data.phones.mobile;
  }

  setHomePhone(phone) {
    this.#data.phones.home = phone;
  }

  getHomePhone() {
    return this.#data.phones.home;
  }

  setWorkPhone(phone) {
    this.#data.phones.work = phone;
  }

  getWorkPhone() {
    return this.#data.phones.work;
  }

  setSmsPreference(enabled) {
    this.#data.notifications.sms = enabled;
  }

  getSmsPreference() {
    return this.#data.notifications.sms;
  }

  getAllData() {
    return this.#data;
  }
}
