// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import type { Modify } from '../../../utils/UtilityTypes';
import { getWeeklyScheduleFromBusinessHours } from '../../../modules/provider/ProviderWorkSchedule';
import type { RootModel, RootState } from '../../../store/models';
import {
  ProfilePageData,
  isVanityUrl,
  loadProfileViewData,
} from '../../../modules/provider/Profile';
import type { ProSpecialty } from '../../../modules/Api.types';
import { getSpecialties } from '../../../modules/Api';
import { ProviderReviewState } from '../../../store/ProviderReviews.model';
import { RatingsSummary } from '../../../store/RatingsSummary.model';
import { ProviderScheduleDay } from '../../../store/ProviderWorkSchedule.types';
import { MarketplaceProfileModelState } from '../../../store/MarketplaceProfile/MarketplaceProfile.types';
import { ProviderServiceGroup } from '../../../api/Providers/ServiceGroups';
import { ProviderService } from '../../../api/Providers/Services';

export type ProfileLoadedState = ProfilePageData & {
  error: false;
  errorMessage?: string;
  loading: false;
  specialties?: ProSpecialty[];
};

export type ProfileLoadingState = Modify<ProfileLoadedState, {
  loading: true;
}>;

export type ProfileErrorState = Modify<ProfileLoadedState, {
  error: true;
  errorMessage: string | undefined;
  loading: false;
}>;

export type ProfileEntry = ProfileLoadingState | ProfileErrorState | ProfileLoadedState;

type State = {
  [id: number]: ProfileEntry;
};

export type CompleteProfileData = ProfilePageData & {
  reviews: ProviderReviewState;
  ratingsSummary: RatingsSummary;
  schedule: ProviderScheduleDay[];
  marketplaceProfile: MarketplaceProfileModelState;
  serviceGroups: ProviderServiceGroup[];
  bookmarked: boolean;
};

export type IdOrVanitySelectorData = ProfileEntry;

export type UserData = {
  hasEditPrivilege: boolean;
  isAPro: boolean;
  userId: number;
};

type ByIdOrVanitySelectorPayload = {
  providerId: number | string;
};

type OnFailedPayload = { providerId: number | string; errorMessage?: string };

type OnSpecialtiesLoadedPayload = { specialties: ProSpecialty[]; providerId: number | string };

/**
 * This model represents the provider profile page/feature
 * NOT the specifically the backend providers/profile API
 */
const model = createModel<RootModel>()({
  name: 'proProfile',

  state: {} as State,

  reducers: {
    onLoading: produce<State, [{ providerId: number }]>((
      state: State,
      payload: { providerId: number },
    ) => {
      const { providerId } = payload;
      state[providerId] = {
        // we want to keep the previous data (if it exists) while we're loading
        ...state[providerId],
        loading: true,
        error: undefined,
      };
    }),

    onLoaded: produce<State, [ProfilePageData]>((
      state: State,
      payload: ProfilePageData,
    ) => {
      const { id } = payload.profile;
      state[id] = {
        ...payload,
        loading: false,
        error: false,
      };
    }),

    onFailed: produce<State, [OnFailedPayload]>((
      state: State,
      payload: OnFailedPayload,
    ) => {
      const {
        providerId,
        errorMessage,
      } = payload;
      state[providerId] = {
        error: true,
        errorMessage,
      };
    }),

    onSpecialtiesLoaded: produce<State, [OnSpecialtiesLoadedPayload]>((
      state: State,
      payload: OnSpecialtiesLoadedPayload,
    ) => {
      const {
        specialties,
        providerId,
      } = payload;
      state[providerId] = {
        ...state[providerId],
        specialties,
      };
    }),
  },

  effects: dispatch => ({

    /**
     * User has landed on the profile (view, not edit) page
     */
    land: async (
      payload: { providerId: number | string; skipCache?: boolean },
      rootState: RootState,
    ): Promise<ProfilePageData | undefined> => {
      const existingProData: ProfileEntry = rootState.proProfile[payload.providerId];

      // if the pro data is already loaded and no error occurred
      // we don't need to load it again
      if (!payload.skipCache && existingProData?.profile && existingProData?.error === false) {
        return existingProData;
      }

      // ...otherwise load (or reload if it errored before) the data
      try {
        const profileData = await loadProfileViewData(
          payload.providerId,
          rootState.route?.name,
        );

        // profile was deleted, treat it as an error (we don't have info to do anything else)
        if ('profile_deleted_time' in profileData) {
          dispatch.proProfile.onFailed({ providerId: payload.providerId });
          return undefined;
        }

        const { profile } = profileData;

        dispatch.proProfile.onLoaded(profileData);

        if (profile.is_disabled) {
          return profileData;
        }

        dispatch.clientPromotions.loadPromotionsForPro(profile.id);
        dispatch.ratingsSummary.onLoaded({
          profileId: profile.id,
          result: {
            total: profile.ratings_count,
            stars: profile.ratings_count_by_stars,
          },
        });

        dispatch.providerWorkSchedule.set({
          providerId: profile.id,
          schedule: getWeeklyScheduleFromBusinessHours(profile.business_hours || []),
        });

        dispatch.providerReviewsSummary.load({ providerId: profile.id });

        return profileData;
      } catch (e) {
        dispatch.proProfile.onFailed({
          providerId: payload.providerId,
          errorMessage: e.message,
        });
      } finally {
        dispatch.loader.setIsLoading(false);
      }
      return undefined;
    },

    modalLand: async (
      payload: { providerId: number | string },
      rootState: RootState,
    ): Promise<ProfilePageData['profile'] | undefined> => {
      try {
        const profileData = await loadProfileViewData(
          payload.providerId,
          rootState.route?.name,
        );

        // profile was deleted, treat it as an error (we don't have info to do anything else)
        if ('profile_deleted_time' in profileData) {
          dispatch.proProfile.onFailed({ providerId: payload.providerId });
          return undefined;
        }

        dispatch.proProfile.onLoaded(profileData);
        return profileData.profile;
      } catch (e) {
        dispatch.proProfile.onFailed({
          providerId: payload.providerId,
          errorMessage: e.message,
        });
      } finally {
        dispatch.loader.setIsLoading(false);
      }
      return undefined;
    },

    /**
     * User has opened the about tab
     * @param {{providerId: number | string}} payload
     * @return {Promise<ProSpecialty[] | undefined>}
     */
    aboutTabLand: async (
      payload: { providerId: number | string },
    ): Promise<ProSpecialty[] | undefined> => {
      try {
        const { providerId } = payload;
        const { specialized_services: specialties } = await getSpecialties(providerId);
        const parsedSpecialties: ProSpecialty[] = specialties.map(
          ({
            service_id,
            service_name,
          }) => ({
            name: service_name,
            id: service_id,
          }),
        );
        dispatch.proProfile.onSpecialtiesLoaded({
          providerId,
          specialties: parsedSpecialties,
        });
        return parsedSpecialties;
      } catch (e) {
        dispatch.proProfile.onFailed({
          providerId: payload.providerId,
          errorMessage: e.message,
        });
      }

      return undefined;
    },

    /**
     * User has changed while in the profile page
     * @return {any}
     */
    userChanged: () => (
      // favorites are user specific so if the user
      // has logged in (or out) we need to reload it
      dispatch.favorites.loadFavorites()
    ),
  }),

  selectors: (slice, createSelector, hasProps) => ({
    byIdOrVanity: hasProps((
      _,
      payload: ByIdOrVanitySelectorPayload,
    ) => (
      createSelector(
        slice,
        (): boolean => isVanityUrl(payload.providerId),
        (state: State, isVanity: boolean): ProfileEntry => {
          let id: number | undefined = payload.providerId as number;

          if (isVanity) {
            const pros = Object.values(state);
            const proEntry = pros.find(proData => (
              proData?.profile?.vanity_url?.toLowerCase()
              === String(payload.providerId)?.toLowerCase()
            ));

            id = proEntry?.profile?.id;
          }

          return id ? state[id] : state[payload.providerId];
        },
      )
    )),

    // creates a memoized selector for each call to this selector with a
    // different pro id
    forPro: hasProps((
      _,
      { providerId }: { providerId: number },
    ) => createSelector(
      slice,
      (state: State): ProfileEntry => (
        state[providerId]
      ),
    )),

    /**
     * selects user data relevant to the profile page
     * @param models
     * @return {any}
     */
    userData: hasProps((models, props: { providerId: number }) => createSelector(
      models.user.getId,
      models.user.providerId,
      models.user.isSuperUser,
      models.user.providers,
      (userId, proId, isSuperUser, editableProviders): UserData => ({
        userId,
        isAPro: !!proId,
        hasEditPrivilege: (
          !!proId
          && (
            isSuperUser
            || !!editableProviders.find(p => p.id === props.providerId)
          )
        ),
      }),
    )),

    getCompleteProfile: hasProps((models, providerId: number) => (
      createSelector(
        slice(state => state[providerId]),
        models.providerReviews.forPro({ providerId }),
        models.ratingsSummary.forPro({ profileId: providerId }),
        models.providerWorkSchedule.forPro({ providerId }),
        models.favorites.isProviderFavorited({ providerId }),
        models.marketplaceProfile.getMarketplaceProfile,
        (
          profileEntry: ProfileEntry,
          reviews: ProviderReviewState,
          ratingsSummary: RatingsSummary,
          schedule: ProviderScheduleDay[],
          bookmarked: boolean,
          marketplaceProfile: MarketplaceProfileModelState,
        ): CompleteProfileData => ({
          ...profileEntry,
          reviews,
          ratingsSummary,
          schedule,
          bookmarked,
          marketplaceProfile,
        }),
      ))),

    getSpecialties: hasProps((_, props: { providerId: number }) => (
      createSelector(
        slice(state => state[props.providerId]),
        (profile: ProfileEntry): ProSpecialty[] => profile?.specialties || [],
      )
    )),

    getService: hasProps((
      _,
      props: { serviceId: number; providerId: number },
    ) => (
      createSelector(
        slice(state => state[props.providerId]),
        (profile: ProfileEntry): ProviderService => (profile?.serviceGroups || [])
          .map(serviceGroup => serviceGroup.services)
          .reduce((acc, cur) => acc.concat(cur || []), [])
          .find(service => service.id === props.serviceId),
      )
    )),
  }),
});

export default model;
