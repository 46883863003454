import { PrivilegedProvider, PublicProvider } from '../../../api/Providers';
import { IProviderClient } from '../../../api/Providers/Clients/types';
import { IWaitlistItemDetails, WaitlistService } from '../../../api/Providers/Waitlist';
import { ServiceColorMap } from '../Appointments/AppointmentColor';

export type WaitlistServiceWithColor = WaitlistService & {
  color: string
};

export type CombinedWaitlistRequestDetails =
  Omit<IWaitlistItemDetails, 'provider' | 'client' | 'services'> & {
    price: number;
    provider: PublicProvider | PrivilegedProvider;
    client: IProviderClient;
    services: WaitlistServiceWithColor[];
  };

const calculateWaitlistRequestPrice = (
  request: IWaitlistItemDetails,
  provider: PrivilegedProvider | PublicProvider,
): number => {
  const premiumUpchargeRate = provider.premium_upcharge_rate || 0;
  let total = request.services.reduce((sum, service) => Number(service.cost || 0) + sum, 0);

  // Calculate premium surcharge
  const surcharge = Math.ceil(total * premiumUpchargeRate) / 100;
  total += surcharge;

  return Number(total);
};

const addColorToServices = (
  services: WaitlistService[],
  servicesColors: { [x: number]: string; },
): WaitlistServiceWithColor[] => (
  services.map(srv => ({
    ...srv,
    color: servicesColors[srv.id],
  }))
);

export const getFullWaitlistRequest = (
  request: IWaitlistItemDetails,
  serviceColors: ServiceColorMap,
  provider: PublicProvider | PrivilegedProvider,
  client: IProviderClient,
): CombinedWaitlistRequestDetails => ({
  ...request,
  services: addColorToServices(request.services, serviceColors),
  price: calculateWaitlistRequestPrice(request, provider),
  client,
  provider,
});
