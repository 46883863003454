import moment, { MomentInput } from 'moment';
import { getProviderTaxSettings } from '../../../../api/Providers/Settings/Tax';
import nonCriticalException from '../../../exceptionLogger';

export interface TaxEDeliveryNotification {
  creation_time: MomentInput;
  persistent: true;
  type: 'tax_edelivery';
  uuid: 'tax-edelivery-notification';
}

export const TAX_EDELIVERY_REMINDER_DISMISSED_KEY = 'proTaxEDeliveryNotificationDismissed';

/**
 * Checks to see if the TaxEDeliveryNotification should be shown in notification dropdown
 *
 * This notification should show every 4 days during the November - January 15 time period
 * for pros that are on payments and not already opted into tax edlivery.
 *
 * @param {number} providerId
 */
export const shouldShowTaxEDeliveryNotification = async (
  providerId: number,
) => {
  // Check if we're in the reminder window at all
  const now = moment();

  const endReminderDate = moment([
    now.year(),
    0,
    15,
  ]); // January 15 this year
  const startReminderDate = moment([
    now.year(),
    10,
    1,
  ]); // November 1 this year

  // Not in the reminder window, return early
  if (now.isBetween(endReminderDate, startReminderDate, 'day')) {
    return false;
  }

  try {
    const { consented_to_paperless_delivery_time } = await getProviderTaxSettings(providerId);
    if (consented_to_paperless_delivery_time) {
      return false;
    }
    return true;
  } catch (e) {
    nonCriticalException(e);
    return false;
  }
};

/**
 * Creates the TaxEDeliveryNotification
 */
export const createTaxEDeliveryNotification = (): TaxEDeliveryNotification => ({
  creation_time: moment.utc(),
  persistent: true,
  type: 'tax_edelivery',
  uuid: 'tax-edelivery-notification',
});
