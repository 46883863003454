import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const BankAccountAnalyticsAction: SegmentEventData = {
  eventName: AnalyticEvent.BankAccountAction,
  eventProperties: [
    'bank_account_action',
    'last_four',
    'bank_name',
  ],
};

export default BankAccountAnalyticsAction;
