// @ts-strict-ignore
import loadable from '@loadable/component';
import modalManager from '../../../../modules/modalManager';

import analytics from '../../../../modules/analytics';
import { checkAttPermission } from '../../../../modules/analytics/AppTrackingTransparency';
import { AuthOrigins } from '../../../../modules/user/constants';
import { LoginModalMode } from '../LoginModalProvider/LoginModalProvider';
import { calculateCanShowSubscriptionFeatures } from '../../../../hooks/useShowSubscriptionFeatures';

export default loadable(() => import('./SignUpLoginModal'));

/**
 * Opens the login / signup modal
 * @param {String} mode The initial tab for the modal. One of "login" / "signup"
 * @param {String} origin The origin from which the modal was summoned, used for tracking
 * @param {Object} utmParams Params used for Google Analytics tracking
 * @yields {Object} User data (if applicable) and action ('signup', 'login', or 'cancel')
 * @deprecated Use `useTriggerLoginModal` instead
 */
export function openModal(mode, origin, utmParams = {}) {
  const tab = mode === 'login' ? 'Log In' : 'Sign Up';
  return new Promise(resolve => {
    import(/* webpackChunkName: "SignUpLoginModal" */'./SignUpLoginModal')
      .then(({ default: SignUpLoginModalContainer }) => {
        const closeModal = modalManager.openModal(SignUpLoginModalContainer, {
          tab,
          origin,
          utmParams,
          onCloseModal: () => {
            closeModal();
          },
          onCancel: () => {
            resolve({
              action: 'cancel',
              userData: null,
            });
          },
          onSignInComplete: (action, userData) => {
            resolve({
              action,
              userData,
            });
          },
        });
      });
  });
}

type RequireLoginPayload = {
  mode: 'login' | 'signup' | 'clientlogin' | 'clientsignup';
  origin?: AuthOrigins;
  helperText?: string;
  fullHeight?: boolean;
  zIndex?: number;
  initialEmail?: string;
};
/**
 * Opens the login / signup modal and waits for authentication completion
 * @param {String} mode The initial tab for the modal. One of "login", "clientlogin", "signup",
 *   or "clientsignup". Including "client" bypasses the "client/pro" selection screen
 * @param {String} [origin] The origin from which the modal was summoned, used for tracking
 * @param {String} [helperText] A message to indicate reason for login requirement to the user
 * @param {boolean} [fullHeight]
 * @param {number} [zIndex]
 * @param {string} [initialEmail] Use to pre-fill an email address
 * @param {boolean} [useNewExperience]
 * @returns {Promise} Resolves with user data and action ('signup' or 'login') or rejects, if login
 * is cancelled
 * @todo Review `authComplete` and `authCancel` event handlers to see if we need to add anything
 * here
 * @deprecated Use `useTriggerLoginModal` instead
 */
export const requireLogin = ({
  mode,
  origin,
  helperText,
  fullHeight,
  zIndex,
  initialEmail,
}: RequireLoginPayload): Promise<{ action: string; userData: any }> => {
  const tab = mode.includes('login') ? 'Log In' : 'Sign Up';
  const userType = mode.includes('client') ? 'client' : undefined;

  return new Promise((resolve, reject) => {
    import(/* webpackChunkName: "InlineLoginModal" */'../InlineLoginModal/InlineLoginModal')
      .then(async ({ InlineLoginModal }) => {
        const canShowSubscriptionFeatures = await calculateCanShowSubscriptionFeatures();

        const closeModal = modalManager.openModal(InlineLoginModal, {
          canShowSubscriptionFeatures,
          origin,
          tab,
          userType,
          helperText,
          fullHeight,
          zIndex,
          initialEmail,
          canDisplaySSO: false,
          onCloseModal: (trackingParams = {}) => {
            analytics.track('client_loginpage_closed', {
              ...trackingParams,
              initialTab: tab,
              origin,
            });
            closeModal();
            reject();
          },
          onLoginSuccess: async userData => {
            await checkAttPermission();
            closeModal();
            resolve({
              action: LoginModalMode.Login,
              userData,
            });
          },
          onSignupSuccess: async userData => {
            await checkAttPermission();
            closeModal();
            resolve({
              action: LoginModalMode.Signup,
              userData,
            });
          },
        }, modalManager.THROW_ERROR);
      })
      .catch(e => {
        reject(e);
      });
  });
};
