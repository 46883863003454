// @ts-strict-ignore
import type { calendar_v3 } from '@googleapis/calendar';
import _ from 'underscore';
import { GoogleCalendar } from '../../../../api/Users/GoogleCalendar';

export interface MergedCalendar extends Omit<calendar_v3.Schema$CalendarListEntry, 'id'>, Partial<Omit<GoogleCalendar, 'id'>> {
  id: number | string;
}

export function mergeExternalCalendars(
  remoteCalendars: calendar_v3.Schema$CalendarListEntry[],
  calendarMirrors: GoogleCalendar[],
): Array<MergedCalendar> {
  const indexedMirrors = _.indexBy(calendarMirrors, 'calendar_id');

  let combinedCalendars: Array<MergedCalendar> = _.map(remoteCalendars, calendar => {
    const { id: mirrorId, ...calMirror } = indexedMirrors[calendar.id] || {};
    return {
      ...calMirror,
      ...calendar,
      id: mirrorId || calendar.id,
      summary: calendar.summary || '',
    };
  });

  combinedCalendars = _.filter(combinedCalendars, calendar => !(calendar.summary === 'Birthdays'
    || calendar.summary.indexOf('Holidays in ') === 0));

  // Primary first, otherwise sort alphabetically
  return _.sortBy(combinedCalendars, calendar => [
    !calendar.primary,
    calendar.summary,
  ]);
}
