// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from '../models';
import type {
  ProviderAvailabilityState,
  OnAvailabilityLoadedPayload,
  OnAvailabilityLoadingPayload,
  OnAvailabilityLoadFailedPayload,
} from './ProviderAvailability.types';

import loadAvailability from '../../modules/provider/loadAvailability';
import type { ILoadAvailabilityResult } from '../../modules/provider/loadAvailability';

const model = createModel<RootModel>()({
  name: 'providerAvailability',

  state: {} as ProviderAvailabilityState,

  effects: dispatch => ({
    loadProviderAvailability: async ({
      serviceIds,
      providerId,
      days,
      startDate,
    }) => {
      try {
        dispatch.providerAvailability.onProviderAvailabilityLoading({
          providerId,
          serviceIds,
        });

        const availability: ILoadAvailabilityResult = await loadAvailability(
          providerId,
          serviceIds.map((serviceId: number) => ({ id: serviceId })),
          {
            days,
            startDate,
          },
        );

        if (availability && availability.results) {
          dispatch.providerAvailability.onProviderAvailabilityLoaded({
            providerId,
            serviceIds,
            results: availability.results,
          });
        } else {
          throw new Error('Unable to fetch data');
        }
      } catch (error) {
        dispatch.providerAvailability.onProviderAvailabilityLoadingFailed({
          providerId,
          serviceIds,
          error,
        });
      }
    },
  }),

  reducers: {
    onProviderAvailabilityLoading: (
      state: ProviderAvailabilityState,
      payload: OnAvailabilityLoadingPayload,
    ) => ({
      ...state,
      [payload.providerId]: {
        ...state[payload.providerId],
        [payload.serviceIds.join(',')]: {
          isLoading: true,
        },
      },
    }),
    onProviderAvailabilityLoaded: (
      state: ProviderAvailabilityState,
      payload: OnAvailabilityLoadedPayload,
    ) => ({
      ...state,
      [payload.providerId]: {
        ...state[payload.providerId],
        [payload.serviceIds.join(',')]: {
          isLoading: false,
          results: payload.results,
        },
      },
    }),
    onProviderAvailabilityLoadingFailed: (
      state: ProviderAvailabilityState,
      payload: OnAvailabilityLoadFailedPayload,
    ) => ({
      ...state,
      [payload.providerId]: {
        ...state[payload.providerId],
        [payload.serviceIds.join(',')]: {
          isLoading: false,
          error: payload.error,
        },
      },
    }),
  },

  selectors: (slice, createSelector, hasProps) => ({
    byProviderAndServices: hasProps((_, {
      providerId,
      serviceIds,
    }: {
      providerId: number;
      serviceIds: number[];
    }) => createSelector(
      slice,
      (state: ProviderAvailabilityState) => state?.[providerId]?.[serviceIds.join(',')],
    )),
  }),
});

export default model;
