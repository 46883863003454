// @ts-strict-ignore
/* eslint-disable arrow-body-style */
import type { Moment } from 'moment';
import * as uuid from 'react-native-uuid';
import { CLIENT_BOOKING_SEARCH_ENTRYPOINT_HAS_SEEN_TOASTER_KEY, CLIENT_BOOKING_SEARCH_ENTRYPOINT_SOURCE } from '../../../components/consumer/booking/BookingFlow/constants';
import loadAvailability, { ILoadAvailabilityResult } from '../../provider/loadAvailability';
import { SEARCH_PARAM_RESET_CODE } from './Constants';
import nonCriticalException from '../../exceptionLogger';
import { ITimeSection } from '../../timeSlotUtils';
import { IHighlightProStrengthsData } from '../../ProSearchMLData/types';
import { Responsive } from '../../Responsive';
import { getItem } from '../../KeyValueStorage';
import { isPreStage } from '../../env';
import {
  AtLeastOneService,
  getSalons,
  ILocationData,
  IMetadata,
  IProviderService,
  ISalonData,
  ISearchParameters,
  ISearchResponse,
  ISearchResultProviderData,
  SEARCH_VERSION,
} from '../../../api/search';
import { DateTimeStamp } from '../../../types/dateTime';

const DEFAULT_SEARCH_SIZE = 30;

interface IHasAvailableDays {
  available_days: ILoadAvailabilityResult['available_days'];
}

type WithAvailability = {
  meta: ISearchResponse['meta'];
  results: Array<ISearchResultProviderData & { availability?: ILoadAvailabilityResult }>;
};

/**
 * Represents a provider record within a salon
 */
interface IProviderData extends ISalonData {
  popular_categories: Array<{ category: string; popularity: number }>;
  // always at least 1 service
  matched_services: AtLeastOneService;
  profession?: string;
}

/**
 * Represents a single location record derived from search result data
 */
export interface ILocation extends ILocationData {
  latitude: number;
  longitude: number;
  distance?: number;
}

/**
 * Represents a single provider record derived from search result data
 */
export interface IProvider extends IProviderData {
  services: IProviderData['matched_services'];
  popularCategories: IProviderData['popular_categories'];
  location: ILocation;
  // The service with the min price
  matchedService: IProviderService;
}

/**
 * Represents a single top-level search result derived from search result data
 */
export interface ISearchResult {
  version: string;
  salon_id: number;
  salon_name: string;
  provider_id: number;
  provider_name: string;
  services: Array<IProviderService>;
  popularCategories: Array<{ category: string; popularity: number }>;
  num_ratings: number;
  average_rating: number;
  isNCDEligible: boolean;
  location: ILocation;
  searchId?: string;
  page?: number;
  index?: number;
  availability?: IHasAvailableDays;
  profile_photo?: string;
  vanity_url?: string;
  profession?: string;
  search_boost_start_date?: Moment;
  search_boost_duration_days?: number;
  nextTimeSlots?: Moment[];
  groups?: string[];
  new_pro_search_boost_end?: string;
  new_pro_search_boost_num_attributed_appointments?: number;
  new_pro_search_boost_start?: string;
  online_bookable?: boolean;
  payments_are_enabled?: number;
  ncd_enabled?: number;
  proStrengthHighlightData?: IHighlightProStrengthsData;
  matchedService?: IProviderService;
  matched_services: IProviderService[];
  top_pro: boolean;
  creation_time: DateTimeStamp;
}

export interface SearchSuggestionData {
  related_searches: string[];
  related_pros: string[];
  top_pros: string[];
  trending_services: string[];
}

/**
 * Parses a latitude and longitude in a search result from the search API.
 * @param {Object} providerData The result record from the search API to parse
 * @return {Object} An object with `latitude` and `longitude` keys
 */
const transformLocationData = (locationData: ILocationData): ILocation => {
  const geoPoint = locationData.geo_point || '';
  let latLng = geoPoint.split(',')
    .map(coordinate => Number(coordinate));

  if (latLng.length !== 2) {
    latLng = [
      0,
      0,
    ];
  }

  return {
    latitude: latLng[0],
    longitude: latLng[1],
    ...locationData,
  };
};

/**
 * Parses a provider in a search result from the search API. Converts properties to camel-case and
 * determines some helpful min/max properties.
 * @param {Object} providerData The result record from the search API to parse
 * @return {Object} The normalized provider data
 */
const transformProviderData = (providerData: IProviderData): IProvider => {
  const initialService: IProviderService = {
    price: 0,
    query_score: 0,
    id: 0,
    duration: 0,
    name: '',
    std_name: '',
    salon_id: 0,
    pro_id: 0,
    estimated_service_category: '',
    blurb: '',
    popularity_percentage: 0,
    matched_queries: [],
  };
  const provider = {
    ...providerData,
    services: providerData.matched_services,
    popularCategories: providerData.popular_categories,
    groups: providerData.groups || [],
    location: transformLocationData(providerData.location || {}),
    // Find the service with the min price
    matchedService: providerData.matched_services.reduce(
      (minService, service) => {
        return (!minService.price || service.price < minService.price) ? service : minService;
      },
      initialService,
    ),
  };

  return provider;
};

export const transformProSearchData = (
  searchResult: ISearchResultProviderData,
): ISearchResult => {
  const {
    matched_salon: salon,
  } = searchResult;
  let provider: IProvider = null;
  let isNCDEligible: boolean = false;

  provider = transformProviderData({
    ...searchResult,
    salonified: salon.salonified,
    mobile_business: salon.mobile_business,
  });
  isNCDEligible = searchResult.matched_services.some(s => s.is_ncd_eligible);

  return {
    ...provider,
    provider_id: provider.id,
    provider_name: provider.name,
    salon_id: salon.id,
    salon_name: salon.name,
    version: SEARCH_VERSION,
    location: transformLocationData(salon.location),
    isNCDEligible,
  };
};

export type SearchConfig = {
  bottomRightLat?: number;
  bottomRightLon?: number;
  topLeftLat?: number;
  topLeftLon?: number;
  lon: number;
  lat: number;
  loc?: string;
  query: string;
  date: string;
  page?: number;
  from?: number;
  size?: number;
  new_pro_search_boost?: boolean;
  timeSections?: ITimeSection[];
  sort?: string;
};

type SearchCreatePayload = {
  /** Basic search parameters */
  config: SearchConfig;
  /** Mapping from test parameter name to test assignment value */
  testFlags?: Record<string, boolean>;
  /** Metadata associated with the search */
  meta?: IMetadata;
};

/**
 * Determines whether to fetch availability for search results or not
 * This is a performance optimization because availability is slow
 * Only enable availability in completely necessary circumstances
 * @param date String The date to filter by
 * @returns boolean whether or not to load availability
 */
const shouldRequestAvailability = async (
  date?: string,
  meta?: IMetadata,
) => {
  /**
   * If you need to request availability for search results for a test,
   * Add the check for test here like so:
   *
   *  const inAvailabilityRequiringTest = store.getState().abTest.assignments[flag_name]?.inTest;
   *  return inAvailabilityRequiringTest || !!date;
   */
  // We need availability if there is a date filter applied
  let result = !!date;

  // We also need availability for the booking openings toaster
  if (meta?.source === CLIENT_BOOKING_SEARCH_ENTRYPOINT_SOURCE) {
    const hasSeenSearchEntry = await getItem(CLIENT_BOOKING_SEARCH_ENTRYPOINT_HAS_SEEN_TOASTER_KEY);
    result = !hasSeenSearchEntry || result;
  }

  return result;
};

/**
 * Loads the appropriate availability for the given salons on the given date.
 * @param {Array<Salon>} salons An array of salons to filter by availability.
 * @param {String} [date=null] The date for which to find availability, in YYYY-MM-DD format. If
 * this is falsy, 14 days of availability is loaded
 * @yields {Array<Salon>} The salons with availability in matched_pros/provider keys.
 *        Note this return value could be an empty array!!
 */
export const applyResultAvailability = async (
  salons: ISearchResult[] = [],
  date: string = '',
  // number of days after start date to fetch
  numberOfDays: number = 14,
  meta?: IMetadata,
): Promise<ILoadAvailabilityResult[]> => {
  if (!(await shouldRequestAvailability(date, meta))) {
    return Promise.resolve([]);
  }

  // TODO productize date time filter test ATL-3862
  const inDateTimeFilterTest = Responsive.is.mobile || Responsive.is.tablet;

  return Promise.all(salons.map(async (
    record: ISearchResult & { matchedService: IProviderService },
  ): Promise<ILoadAvailabilityResult> => {
    const providerId = record.provider_id;
    let services: IProviderService[] = [];
    // choose a service to find availability for
    let chosenService: IProviderService = null;
    let serviceDuration: number = -Infinity;
    services = record.services;

    if (inDateTimeFilterTest) {
      // use matched service
      const { matchedService } = record;
      chosenService = matchedService;
      serviceDuration = matchedService.duration;
    } else {
      // choose service with longest availability
      services?.forEach(service => {
        if (service.duration > serviceDuration) {
          chosenService = service;
          serviceDuration = service.duration;
        }
      });
    }

    const availabilityParams: {
      days: number;
      startDate?: string;
    } = {
      days: numberOfDays,
    };
    if (!!date || date === SEARCH_PARAM_RESET_CODE) {
      availabilityParams.days = inDateTimeFilterTest ? numberOfDays : 1;
      availabilityParams.startDate = date;
    }

    // get availability for the longest service for just the provided date
    const availability: ILoadAvailabilityResult = await loadAvailability(
      providerId,
      [chosenService],
      availabilityParams,
    );

    return availability;
  }));
};

/**
 * Use this function to generate a brand new search id
 *
 * @returns {String} a search id
 */
export function generateSearchID(): string {
  return `SEARCH-${uuid.v4()}`;
}

/**
 * The config we use on the front end does not map directly to the query parameters expected by
 * the search API. This function is responsible for creating the mapping.
 *
 * @name buildSearchParameters
 * @param {Object} config The search configuration. Any properties supplied get sent directly to
 * the search API as query parameters.
 * @param {Object} [config.mapBoundingBox] The bounding box for search results. Should contain
 * the following properties: `bottomRightLat`, `bottomRightLng`, `topLeftLat`, `topLeftLng`
 * @param {Number} config.lon The longitude around which to search.
 * @param {Number} config.lat The latitude around which to search.
 * @param {String} config.query The query string.
 * @param {String} config.date The date to search on (YYYY-MM-DD format)
 * @param {String} config.timeSections Select time sections as defined in timeUtils TIME_SECTIONS
 * @param {Number} [config.page=0] The zero-index page number to fetch
 * @param {Number} [config.from=0] The result to start the search at.
 * @param {Number} [config.size=30] The number of search results to retrieve.
 * @returns {Object} - The search API query parameters.
 */
export function buildSearchParameters(
  config: SearchConfig,
  meta,
  testFlags?: Record<string, boolean>,
): ISearchParameters {
  let {
    bottomRightLat,
    bottomRightLon,
    topLeftLat,
    topLeftLon,
    from,
    size,
    // eslint-disable-next-line prefer-const
    ...restConfig
  } = config;
  const {
    page,
    ...remainingConfig
  } = restConfig;

  // sanitize these inputs a bit
  if (bottomRightLat === null) {
    bottomRightLat = undefined;
  }

  if (bottomRightLon === null) {
    bottomRightLon = undefined;
  }

  if (topLeftLat === null) {
    topLeftLat = undefined;
  }

  if (topLeftLon === null) {
    topLeftLon = undefined;
  }

  if (typeof size !== 'number') {
    size = DEFAULT_SEARCH_SIZE;
  }

  if (typeof from !== 'number') {
    if (typeof page === 'number') {
      from = page * size;
    } else {
      from = 0;
    }
  }

  const cfg: ISearchParameters = {
    ...testFlags,
    ...remainingConfig,
    bottom_right_lat: bottomRightLat,
    bottom_right_lon: bottomRightLon,
    top_left_lat: topLeftLat,
    top_left_lon: topLeftLon,
    date: config.date || null,
    from,
    size,
    cookie_id: meta?.cookie_id || null,
    search_id: meta?.id || null,
  };

  cfg.lat ||= 0;
  cfg.lon ||= 0;

  return cfg;
}

/**
 * Returns true if there are enough parameters to perform a search
 *
 * @param {Object} searchConfig The search which is passed to `create`.
 * @return {Boolean}
 */
export function canPerformSearch(searchConfig: SearchConfig): boolean {
  return (
    (typeof searchConfig.lat === 'number' || typeof searchConfig.lat === 'string')
    && (typeof searchConfig.lon === 'number' || typeof searchConfig.lon === 'string')
    && (typeof searchConfig.query === 'string' && !!searchConfig.query.length)
  );
}

/**
 * Represents the results of a single call to the search API, including results and metadata.
 * Provides helper functions.
 */
export default class Search {
  results: Array<ISearchResult>;

  id: string;

  filters: SearchConfig;

  meta: IMetadata;

  suggestions: SearchSuggestionData;

  /**
   * Constructor. Generally, you will not want to call this function and instead should use the
   * static `create` function or the `nextPage` or `previousPage` functions of an existing `Search`
   * instance.
   * @param {Array<Object>} results The results array.
   * @param {Object} filters The filters for the search.
   * @param {Object} meta The metadata hash.
   * @param {SearchSuggestionData} suggestions Suggestions for empty searchs.
   */
  constructor(
    results: ISearchResultProviderData[] = [],
    filters: SearchConfig,
    meta: IMetadata = {},
    suggestions: SearchSuggestionData,
  ) {
    // Using the from and size data returned from the API, calculate
    // which page of results this is.
    const pageIndex = Math.floor(filters.from / filters.size);
    this.meta = {
      ...meta,
      page: pageIndex,
    };
    this.filters = { ...filters };
    this.suggestions = suggestions;
    this.results = results.map((searchResult, index) => {
      const result = {
        ...searchResult,
        searchId: meta.id,
        page: pageIndex,
        index,
      };

      return transformProSearchData(result as ISearchResultProviderData);
    });

    this.id = meta.id;
  }
}

/**
 * Performs a search for providers. Use this function to begin a search "session".
 *
 * @name create
 * @param {Object} searchConfig The search configuration.
 * @param {Object} [meta] Metadata to attach to the resulting object.
 * @returns {Promise<Search>} A promise which resolves with the search results.
 */
export async function createSearch({
  config: searchConfig,
  testFlags,
  meta,
}: SearchCreatePayload) {
  if (!canPerformSearch(searchConfig)) {
    const error = isPreStage() && new Error(
      `Don't have enough parameters to call Search API ${JSON.stringify(searchConfig)}`,
    );
    return Promise.reject(error);
  }

  let searchParameters: ISearchParameters;
  let search: Search;

  try {
    searchParameters = buildSearchParameters(searchConfig, meta, testFlags);

    const searchResults = await getSalons(searchParameters);

    let amendedSearchResults: WithAvailability['results'] = searchResults.results;

    if (searchParameters.date) {
      const availabilities: ILoadAvailabilityResult[] = await applyResultAvailability(
        searchResults.results.map(result => transformProSearchData(result)),
        searchParameters.date || null,
        undefined,
        meta,
      );

      const resultsWithAvailability: Array<(ISearchResultProviderData) & {
        availability?: ILoadAvailabilityResult;
      }> = searchResults.results;

      amendedSearchResults = availabilities.length > 0
        ? resultsWithAvailability.map((record, index: number) => ({
          ...record,
          availability: availabilities?.[index],
        }))
        : resultsWithAvailability;
    }

    search = new Search(amendedSearchResults, searchParameters, {
      ...meta,
      ...searchResults.meta,
    }, searchResults.suggestions);
  } catch (e) {
    nonCriticalException(e);
    throw new Error(`Search API call failed with an error: ${e.message}`);
  }

  return search;
}
