export enum TapToPayPluginErrors {
  CollectPaymentMethodError = 'COLLECT_PAYMENT_METHOD_ERROR',
  CompletePaymentError = 'COMPLETE_PAYMENT_ERROR',
  ConnectLocalMobileReaderError = 'CONNECT_LOCAL_MOBILE_READER_ERROR',
  DetectTapToPayDevicesError = 'DETECT_TAP_TO_PAY_DEVICES_ERROR',
  DisconnectReaderError = 'DISCONNECT_READER_ERROR',
  DiscoverReadersError = 'DISCOVER_READERS_ERROR',
  FetchConnectionTokenError = 'FETCH_CONNECTION_TOKEN_ERROR',
  LoadPluginError = 'LOAD_PLUGIN_ERROR',
  OpenSettingsAppError = 'OPEN_SETTINGS_APP_ERROR',
  ProcessPaymentError = 'PROCESS_PAYMENT_ERROR',
  RetrievePaymentIntentError = 'RETRIEVE_PAYMENT_INTENT_ERROR',
  UnexpectedReaderDisconnectError = 'UNEXPECTED_READER_DISCONNECT_ERROR',
  DeviceOwnerAuthenticationError = 'DEVICE_OWNER_AUTHENTICATION_ERROR',
}

// https://stripe.dev/stripe-terminal-ios/docs/Enums/SCPError.html
export enum TapToPayPluginErrorCodes {
  NotConnectedToReader = 1100, // SCPErrorNotConnectedToReader
  ProviderCancelled = 2020, // SCPErrorCanceled
  PasscodeDisabled = 2920, // SCPErrorPasscodeNotEnabled
  OngoingCall = 2930, // SCPErrorCommandNotAllowedDuringCall
  SessionExpired = 9060, // SCPErrorSessionExpired
}

export enum TapToPayPluginEvent {
  DidFinishDiscoverReaders = 'didFinishDiscoverReaders',
  DidReportUnexpectedReaderDisconnect = 'didReportUnexpectedReaderDisconnect',
  DidStartInstallingUpdate = 'didStartInstallingUpdate',
  DidReportReaderSoftwareUpdateProgress = 'didReportReaderSoftwareUpdateProgress',
  DidFinishInstallingUpdate = 'didFinishInstallingUpdate',
  DidCancelDiscoverReaders = 'didCancelDiscoverReaders',
  DidRequestReaderInput = 'didRequestReaderInput',
  DidRequestReaderDisplayMessage = 'didRequestReaderDisplayMessage',
  DidCollectPaymentMethod = 'didCollectPaymentMethod',
  DidProcessPaymentIntent = 'didProcessPaymentIntent',
  DidChangeLocationAuthorization = 'didChangeLocationAuthorization',
  DidDisconnectReader = 'didDisconnectReader',
  DidConnectReader = 'didConnectReader',
  DidRequestConnectionStatus = 'didRequestConnectionStatus',
}

// Mirrors SCPConnectionStatus: https://stripe.dev/stripe-terminal-ios/docs/Enums/SCPConnectionStatus.html#/c:@E@SCPConnectionStatus@SCPConnectionStatusConnecting
export enum TapToPayConnectionStatus {
  NotConnected = 0,
  Connected = 1,
  Connecting = 2,
}

export enum TapToPayGeolocationAuthorizationStatus {
  Unknown = 'unknown',
  NotDetermined = 'notDetermined',
  Authorized = 'authorized',
  Denied = 'denied',
  Restricted = 'restricted',
}

/**
 * For Tap to Pay on iPhone, the message that was displayed while the user is on Apple's Tap to Pay
 * Screen.
 *
 * [See Stripe's Documentation for additional information](https://stripe.dev/stripe-terminal-ios/docs/Enums/SCPReaderDisplayMessage.html#/c:@E@SCPReaderDisplayMessage@SCPReaderDisplayMessageTryAnotherReadMethod)
*/
export enum SCPReaderDisplayMessage {
  /** Retry the presented card */
  RetryCard = 0,

  /** Not applicable for TapToPay, we don't expect to see them, but it's possible since they're
   * not prevented from coming into our app */
  InsertCard = 1,
  InsertOrSwipeCard = 2,
  SwipeCard = 3,
  RemoveCard = 4,

  /** The reader detected multiple contactless card. Make sure only one contactless card or
   * NFC device is near the reader. */
  MultipleContactlessCardsDetected = 5,

  /** The card could not be read. Try another read method on the same card,
   * or use a different card. */
  TryAnotherReadMethod = 6,

  /** The card is invalid. Try another card. */
  TryAnotherCard = 7,
}
