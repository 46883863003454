import { useEffect, useState } from 'react';
import { APP } from '../../config';
import { getIOSVersion } from '../../modules/AppInfo';
import FeatureFlags from '../../modules/FeatureFlags';

/**
* Calculate whether or not the user should be allowed to create a pro account or upgrade
* @returns {Promise<boolean>} True if not in the iOS app or if the `appt-variation-test-override`
* feature flag is enabled and the `appt-variation-test` feature flag is disabled
*/
export const calculateCanShowSubscriptionFeatures = async (): Promise<boolean> => {
  // Early return if not in the iOS app
  const isIOSApp = !!(APP && getIOSVersion());
  if (!isIOSApp) {
    return true;
  }

  const apptVariationTestEnabled = await FeatureFlags.isEnabled('appt-variation-test');

  return apptVariationTestEnabled;
};

export function useShowSubscriptionFeatures(): [isAllowed: boolean, isLoading: boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllowed, setIsAllowed] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    calculateCanShowSubscriptionFeatures().then(result => {
      setIsAllowed(result);
      setIsLoading(false);
    }).catch(() => {
      setIsAllowed(false);
      setIsLoading(false);
    });
  }, []);

  return [
    isAllowed,
    isLoading,
  ];
}
