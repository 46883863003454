import { SCPReaderDisplayMessage } from '../../modules/TapToPay';
import { StripeTerminalError } from './StripeTerminalError';

const NAME = 'StripeTerminalCanceledError';

export class StripeTerminalCanceledError extends StripeTerminalError {
  public scpReaderDisplayMessage: SCPReaderDisplayMessage | null;

  constructor(scpReaderDisplayMessage?: SCPReaderDisplayMessage | null) {
    super(StripeTerminalCanceledError.errorMessage(scpReaderDisplayMessage));

    this.name = NAME;
    this.scpReaderDisplayMessage = scpReaderDisplayMessage || null;
  }

  public updateMessage(scpReaderDisplayMessage?: SCPReaderDisplayMessage | null) {
    this.message = StripeTerminalCanceledError.errorMessage(scpReaderDisplayMessage);
  }

  private static errorMessage(
    scpReaderDisplayMessage?: SCPReaderDisplayMessage | null,
  ): string {
    if (scpReaderDisplayMessage === null) {
      return 'null';
    } if (scpReaderDisplayMessage === undefined) {
      return 'Unknown reader display message';
    } if (scpReaderDisplayMessage in SCPReaderDisplayMessage) {
      return SCPReaderDisplayMessage[scpReaderDisplayMessage];
    }
    return `Unexpected reader display message: ${scpReaderDisplayMessage}`;
  }
}
