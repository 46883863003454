// @ts-strict-ignore
import Currency from '../../../modules/Currency';
import { IDiscountDisplay } from '../../../store/ClientPromotions.types';

export function formatDiscount(
  promotion: IDiscountDisplay,
  useParentheses: boolean = false,
): string {
  const {
    type,
    discountAmount,
    discountMax,
    discountPercent,
  } = promotion;

  if (type === 'amount') {
    return `${Currency.shortFormat(discountAmount)} off`;
  }

  let limitText: string = '';

  if (typeof discountMax === 'number') {
    if (useParentheses) {
      limitText = ` (up to ${Currency.shortFormat(discountMax)})`;
    } else {
      limitText = `, up to ${Currency.shortFormat(discountMax)}`;
    }
  }

  return `${discountPercent.toFixed(0)}% off${limitText}`;
}
