// @ts-strict-ignore
import { BrandColor } from '@styleseat/brand';
import React, { ReactNode } from 'react';
import { Text, TextStyle } from 'react-native';

import './HeaderText.module.scss';

export type AriaLevel = 1 | 2 | 3 | 4 | 5 | '1' | '2' | '3' | '4' | '5';

export type Level = 1 | 2 | 3 | 4 | '1' | '2' | '3' | '4';

export type Theme = 'client' | 'provider' | 'notification';

export type TextTransform = 'none' | 'capitalize' | 'uppercase' | 'lowercase';

export type TextAlign = 'auto' | 'left' | 'right' | 'center' | 'justify';

export type TextDecoration = 'none' | 'line-through' | 'underline';

enum FontWeight {
  Thin = '100',
  ExtraLight = '200',
  Light = '300',
  Normal = '400',
  Medium = '500',
  SemiBold = '600',
  Bold = '700',
  ExtraBold = '800',
  Heavy = '900',
}

// props will come in with lowercase names for the weights
// so we create a table of the same values in the enum but lower-cased
const weightTable: {
  [K in keyof typeof FontWeight as Lowercase<K>]: typeof FontWeight[K];
} | {} = Object.keys(FontWeight).reduce((result, v) => ({
  ...result,
  [v.toLowerCase()]: FontWeight[v],
}), {});

// 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

type HeaderTextProps = {
  children?: ReactNode;
  level?: Level;
  ariaLevel?: AriaLevel;
  theme?: Theme;
  className?: string;
  style?: TextStyle & { textDecoration?: TextDecoration };
  textAlign?: TextAlign;
  color?: BrandColor;
  fontSize?: number;
  lineHeight?: number;
  weight?: Lowercase<keyof typeof FontWeight>;
  textTransform?: TextTransform;
  textDecoration?: TextDecoration;
  testID?: string;
};

/**
 * Allows for display of header text using a particular client or provider header level based on
 * design guidelines (see storybook)
 *
 * The header text component *MUST* be enclosed in a View component
 *
 * To translate design font sizes to (client) header level, use this quick table:
 * Level 1: 24px
 * Level 2: 20px
 * Level 3: 16px
 * Level 4: 14px
 *
 * @param children
 * @param ariaLevel
 * @param {object} restProps
 * @param {number} [restProps.level = 1]
 * @param {'client' | 'provider'} [restProps.theme = 'client'] Deprecated; provider is going away
 * @param style
 * @param textAlign
 * @param color
 * @param fontSize
 * @param lineHeight
 * @param fontWeight
 * @param textTransform
 * @param textDecoration
 * @param testID
 */
export const HeaderText: React.FCWithChildren<HeaderTextProps> = ({
  level = '1',
  theme = 'client',
  style,
  textAlign,
  color,
  fontSize,
  lineHeight,
  textTransform,
  textDecoration,
  testID = '',
  children,
  ariaLevel = level,
  weight: fontWeight,
  ...restProps
}) => {
  const textDecorationLine = textDecoration || style?.textDecoration;
  const textColor = color || style?.color;
  const fontWeightValue = weightTable[fontWeight] || style?.fontWeight;
  return (
    <Text
      testID={testID}
      accessibilityRole="header"
      aria-level={ariaLevel}
      /* eslint-disable-next-line react/forbid-component-props */
      styleName={`header-text-component ${theme}-header${level}`}
      style={[
        style,
        {
          textAlign: textAlign || style?.textAlign,
          fontSize: fontSize || style?.fontSize,
          lineHeight: lineHeight || style?.lineHeight,
          color: textColor,
          fontWeight: fontWeightValue,
          textTransform: textTransform || style?.textTransform,
          textDecorationLine,
          textDecorationStyle: textDecorationLine ? 'solid' : undefined,
          textDecorationColor: textDecorationLine ? textColor : undefined,
        },
      ]}
      {...restProps}
    >
      {children}
    </Text>
  );
};
