// @ts-strict-ignore
import analytics from '../../modules/analytics';
import FeatureFlags from '../../modules/FeatureFlags';
import {
  ABTestAssignmentRequest,
  Assignment,
  AssignmentPayload,
  AssignTestRequest,
} from './ABTest.types';

/**
 * This module is factored out of the model file so that it can be use in both the model and
 * also be used to replace the existing abTest.isInTest function which has a strained api.
 *
 * note this uses localStorage deliberately to be fully backward compatible with the last
 * version of this function which stores test assignments in localStorage and not localForage.
 * */

type LocalStorageABTestStatus = {
  /** The original random number used to assign the value of this test */
  allocation?: number | null;
  /** This is the choice currently stored in local storage for v1 of this module */
  choice: boolean;
  /** This can be null, but returns the variation name */
  variant?: string | null;
};

export type ABTestStatus = LocalStorageABTestStatus & {
  /** Is this test assigned eg: is it present in local storage */
  assigned: boolean;
};

const getLSKey: (testName: string) => string = testName => `const.${testName}`;

const getTestData: (testName: string) => LocalStorageABTestStatus | null = testName => {
  const rawData: string | null = localStorage.getItem(getLSKey(testName));
  if (rawData) {
    try {
      const parsed = JSON.parse(rawData);
      const parsedKeys = Object.keys(parsed);

      if (parsedKeys.length === 1 && parsedKeys[0] === 'choice') {
        return parsed as LocalStorageABTestStatus;
      }

      if (parsedKeys.length === 3 && parsedKeys.includes('choice') && parsedKeys.includes('allocation') && parsedKeys.includes('variant')) {
        return parsed as LocalStorageABTestStatus;
      }
    } catch (e) {
      return null;
    }
  }

  return null;
};

const setTestData: (
  testName: string,
  status: LocalStorageABTestStatus,
) => void = (testName, status) => {
  localStorage.setItem(getLSKey(testName), JSON.stringify(status));
};

export const checkTestStatus = (testName: string): ABTestStatus => {
  const status = getTestData(testName);

  if (status) {
    return {
      ...status,
      assigned: true,
    };
  }

  return {
    assigned: false,
    choice: false,
  };
};

const computeTestChoice: (allocation: number, variations: { [key: string]: number }) => string = (
  allocation,
  variations,
) => {
  const variationNames = Object.keys(variations);

  const totalVariantAllocations = variationNames.reduce((acc, variant) => {
    const variantPercent = variations[variant];

    if (variantPercent < 0 || variantPercent > 1) {
      throw new Error(`'${variant}' has invalid percentage: ${variantPercent}
  Variant percentages must be greater than zero and less than one.`);
    }

    return acc + variantPercent;
  }, 0);

  if (totalVariantAllocations !== 1) {
    throw new Error(`test variant allocations must sum to 1 got ${totalVariantAllocations}`);
  }

  let lastLower = 0;

  /**
   * This creates an array of maps which hold the variant name along
   * with the upper and lower bounds. This is used to make the
   * logic in the find command somewhat easier to understand.
   *
   * Resultant shape in this array is:
   * [
   *  {
   *    lower: 0,
   *    upper: 0.34,
   *    variant: 'control'
   *  },
   *  {
   *    lower: 0.34,
   *    upper: 0.67,
   *    variant: 'version_1'
   *  },
   *  {
   *    lower: 0.67,
   *    upper: 1,
   *    variant: 'version_2'
   *  }
   * ]
   */
  const variantBounds = variationNames.map(variant => {
    const variantPercent = variations[variant];
    const lower = lastLower;
    const upper = lastLower + variantPercent;
    lastLower = upper;

    return {
      upper,
      lower,
      variant,
    };
  });

  const matchedVariant = variantBounds.find(
    variant => allocation > variant.lower && allocation <= variant.upper,
  );

  if (matchedVariant) {
    return matchedVariant.variant;
  }

  // There really is no code path that should actually get us to this point.
  throw new Error('Could not find test variant this really should not happen!'
    + ' Are you mocking Math.random()?'
    + ' Or did you manually mess with your allocation value in local storage?');
};

export const assignABTest = (
  assignmentRequest: ABTestAssignmentRequest,
): {
  assigned: boolean;
  variant?: string;
  allocation?: number;
  choice: boolean;
} => {
  let allocation: number;
  const status = getTestData(assignmentRequest.testName);

  if (status) {
    if (!status.allocation) {
      // eslint-disable-next-line no-console
      console.warn('Cannot re-compute legacy tests');
      return {
        assigned: true,
        ...status,
      };
    }

    allocation = status.allocation;
  } else {
    allocation = Math.random();
  }

  const variant = computeTestChoice(allocation, assignmentRequest.variations);

  const newStatus = {
    variant,
    allocation,
    // True if we're not in the control group
    choice: variant !== 'control',
  };

  setTestData(assignmentRequest.testName, newStatus);

  if (!status || status?.variant !== newStatus.variant) {
    analytics.track('client_ab_test_assignment', {
      test_variation: newStatus.variant,
      test_name: assignmentRequest.testName,
      test_group: newStatus.choice ? 'test' : 'control',
    });
  }

  return {
    assigned: true,
    ...newStatus,
  };
};

export const forceABTestAllocation = (
  testName: string,
  allocation: number | boolean,
) => {
  const status = getTestData(testName);
  let newStatus: LocalStorageABTestStatus = status;

  if (status && status.allocation === null) {
    if (typeof allocation === 'number') {
      // eslint-disable-next-line no-console
      console.warn('Cannot re-compute legacy tests');
      return {
        assigned: true,
        ...status,
      };
    }

    newStatus = {
      ...status,
      choice: allocation,
    };
  } else if (typeof allocation === 'number') {
    newStatus = {
      ...status,
      allocation,
    };
  }

  setTestData(testName, newStatus);

  return {
    assigned: true,
    ...newStatus,
  };
};

export function payloadToAssignment(payload: AssignmentPayload): Assignment {
  const {
    inTest,
    isEnabled,
    variationName,
    allocationPercent,
  } = payload;

  return {
    inTest,
    isEnabled,
    variationName,
    allocationPercent,
    isAssigned: isEnabled,
  };
}

export async function assignAllTests(
  assignTestRequests: AssignTestRequest[],
): Promise<Record<string, Assignment>> {
  const result: Record<string, Assignment> = {};
  const isTestEnabledList = await Promise.all(
    assignTestRequests.map(assignTestRequest => FeatureFlags.isEnabled(assignTestRequest.name)),
  );
  isTestEnabledList.forEach((isEnabled, index) => {
    const {
      name,
      variations,
      percent,
    } = assignTestRequests[index];
    if (isEnabled) {
      const status = assignABTest({
        testName: name,
        variations: variations || {
          control: 1 - percent,
          test: percent,
        },
      });

      result[name] = payloadToAssignment({
        name,
        isEnabled,
        inTest: status.choice,
        variationName: status.variant,
        allocationPercent: status.allocation,
      });
    } else {
      result[name] = payloadToAssignment({
        name,
        isEnabled,
        inTest: false,
      });
    }
  });

  return result;
}

export interface GenerateTestFlagsResult {
  testFlags: Record<string, boolean>;
  assignments: Record<string, Assignment>;
}

/**
 * Given a test config, determine test assignments and generate search test parameters.
 * @param testConfig The mapping from search parameter name to AB test config
 * @returns A mapping from search parameter name to assignment value
 */
export async function generateTestFlags(
  testConfig: Record<string, AssignTestRequest>,
): Promise<GenerateTestFlagsResult> {
  const mapToParameterKey: Record<string, string> = {};
  const testFlags: Record<string, boolean> = {};

  const assignmentRequests = Object.keys(testConfig).map((key): AssignTestRequest => {
    mapToParameterKey[testConfig[key].name] = key;
    return testConfig[key];
  });

  const assignments = await assignAllTests(assignmentRequests);

  Object.keys(assignments).forEach(testName => {
    const parameterKey = mapToParameterKey[testName];
    testFlags[parameterKey] = assignments[testName].inTest;
  });

  return { testFlags, assignments };
}
