// @ts-strict-ignore
import moment from 'moment';
import { SEARCH_PARAM_RESET_CODE, SEARCH_TEST_CONFIG } from './Constants';
import analytics from '../../analytics';
import {
  getTimeSlotsForTimeRange,
  ITimeSection,
  slotToDate,
} from '../../timeSlotUtils';
import type { ISearchResult } from './Search';
import { Responsive } from '../../Responsive';

// NOTE: If this event name changes, notify team Flywheel (FLY-2854) and data team (DT-1004)
export const DEFAULT_TRACKING_EVENT_NAME = 'search';
export const hasValue = param => (
  !!param
  && param !== SEARCH_PARAM_RESET_CODE
  && (!Array.isArray(param) || param.length > 0)
);

const filterDateTimeResults = (
  results: ISearchResult[],
  date,
  timeSlots: number[],
): ISearchResult[] => {
  const hasDateFilter = hasValue(date);
  const hasTimeFilter = hasValue(timeSlots);

  if (!hasDateFilter && !hasTimeFilter) {
    return results;
  }

  // If filters are applied, check result availability
  const filteredResults = results.reduce((result, searchRecord) => {
    const { availability } = searchRecord;
    let nextTimeSlots = [];

    // Find the next available day, make sure it matches the date filter if selected
    const nextAvailableDay = availability?.available_days.find(day => {
      if (hasDateFilter && day.date !== date) {
        return false;
      }
      // If the day matches, find the next time slot that fits with the time selection
      // If there is no time selection, it's just the next slot that exists
      nextTimeSlots = day.bookable_slots.online.filter(slot => {
        if (!hasTimeFilter) {
          return true;
        }
        return timeSlots.includes(slot);
      }).map(slot => slotToDate(slot, day.date));
      return nextTimeSlots.length > 0;
    });

    // If there are filters and no availability found, don't include this result
    if ((hasDateFilter || hasTimeFilter) && !nextAvailableDay) {
      return result;
    }
    // If we found a next available time,
    // include this record in results with the next available time in the data
    return [
      ...result,
      {
        ...searchRecord,
        nextTimeSlots,
      },
    ];
  }, [] as ISearchResult[]);
  return filteredResults;
};

const filterDateTimeResultsTest = (
  results: ISearchResult[],
  date,
  timeSlots: number[],
): ISearchResult[] => {
  const hasDateFilter = hasValue(date);
  const hasTimeFilter = hasValue(timeSlots);

  if (!hasDateFilter && !hasTimeFilter) {
    return results;
  }

  const filtered = results.filter(result => {
    const { availability } = result;
    // filter by available days
    if (!availability?.available_days.length) {
      return false;
    }

    // if no timefilter, don't check times
    if (!hasTimeFilter) {
      return true;
    }
    const timeSlotDict = {};
    timeSlots.forEach(timeSlot => {
      timeSlotDict[timeSlot] = true;
    });
    // filter out pros without timeslots in range
    const hasSlotsInRange = availability?.available_days.find(day => (
      !!day.bookable_slots.online.find(slot => (
        timeSlotDict[slot]
      ))
    ));

    return hasSlotsInRange;
  });
  return filtered;
};

export const filterResults = (
  results: ISearchResult[],
  date,
  timeSlots: number[],
): ISearchResult[] => {
  // TODO productize ATL-3862 date time filter test
  const inDateFilterV2Test = Responsive.is.mobile;

  const filteredResults = inDateFilterV2Test
    ? filterDateTimeResultsTest(results, date, timeSlots)
    : filterDateTimeResults(results, date, timeSlots);

  return filteredResults;
};

type SearchTrackingParameters = {
  results: ISearchResult[];
  searchID: string;
  date?: string;
  timeSections?: ITimeSection[];
  latitude?: number;
  longitude?: number;
  total?: number;
  query: string;
  topLeftLat?: number;
  topLeftLon?: number;
  bottomRightLat?: number;
  bottomRightLon?: number;
  from?: number;
  source?: string;
  origin?: string;
  selectedQuery: string[];
  sort: string | undefined;
  earnedBoostWeight?: number;
  newProBoostWeight?: number;
  newProSearchBoost?: boolean;
  numberOfDays?: number;
  suggestions?: {};
  repeatedSearch?: boolean;
};

type Boosts = {
  [key: string]: number;
};

type BoostedPro = {
  id: number;
  boosts: Boosts;
};

function getDateFilterRange(numberOfDays, date) {
  if (hasValue(numberOfDays) && hasValue(date)) {
    const end = moment(date).add(numberOfDays - 1, 'day').format('YYYY-MM-DD');

    return [
      date,
      end,
    ];
  }

  return null;
}

export const trackSearch = (
  eventName: string,
  {
    results,
    searchID,
    date,
    timeSections = [],
    latitude,
    longitude,
    total,
    query,
    topLeftLat,
    topLeftLon,
    bottomRightLat,
    bottomRightLon,
    from,
    source,
    origin,
    selectedQuery,
    sort,
    earnedBoostWeight,
    newProBoostWeight,
    newProSearchBoost,
    numberOfDays,
    repeatedSearch,
  }: SearchTrackingParameters,
  testFlags: Record<string, boolean>,
) => Promise.resolve().then(async () => {
  const timeSlots = timeSections?.reduce?.((slots, section) => [
    ...slots,
    ...getTimeSlotsForTimeRange(section.startTime, section.endTime),
  ], []) || [];
  const filteredResults = filterResults(results, date, timeSlots);
  const boostedPros = filteredResults.map(result => {
    const {
      search_boost_start_date: earnedStartDate,
      search_boost_duration_days: earnedDuration,
      provider_id: proId,
      new_pro_search_boost_end: newProBoostEnd,
      new_pro_search_boost_start: newProBoostStart,
      new_pro_search_boost_num_attributed_appointments: newProBoostAppointments,
    } = result;

    const boostedPro: BoostedPro = {
      id: proId,
      boosts: {},
    };

    // check if earned search boosted
    if (earnedStartDate
      && earnedDuration
      && moment().utc()
        .isBetween(earnedStartDate, moment(earnedStartDate).add(earnedDuration, 'days'))) {
      boostedPro.boosts.earned = earnedBoostWeight;
    }

    // check if new pro search boosted, but also make sure the search API actually applied the
    // new_pro_search_boost (contained in the meta of the search results)
    if (
      newProSearchBoost
      && newProBoostStart
      && newProBoostEnd
      && moment().isBetween(newProBoostStart, newProBoostEnd)
      && newProBoostAppointments < 2) {
      boostedPro.boosts.new_pro = newProBoostWeight;
    }

    return Object.keys(boostedPro.boosts).length > 0 ? boostedPro : null;
  }).filter(result => result);

  const ids = filteredResults.map(result => (
    [
      result.salon_id,
      result.provider_id,
      result.services?.[0]?.id,
    ]
  ));
  const hasBoundingBox = (
    topLeftLat
    && topLeftLon
    && bottomRightLat
    && bottomRightLon
  );

  // get date range if in ATL-3862 test and has range applied
  const dateRange = getDateFilterRange(numberOfDays, date);
  const testTracking: Record<string, boolean> = {};

  // copy assignment value from an object with keys from SEARCH_TEST_CONFIG to an object with keys
  // from SEARCH_TEST_CONFIG->trackingKey
  Object.keys(testFlags || {}).forEach(key => {
    testTracking[SEARCH_TEST_CONFIG[key].trackingKey] = testFlags[key];
  });

  analytics.track(eventName, {
    react_search: true,
    search_id: searchID,
    search_term: query,
    result_pro_ids: ids,
    boosted_pros: boostedPros,
    latitude,
    longitude,
    num_found: total,
    repeatedSearch,
    is_map_search: hasBoundingBox,
    search_component: source,
    search_page: origin,
    from,
    size: filteredResults.length,
    selected_query: selectedQuery,
    filters: {
      bottom_right_lat: bottomRightLat,
      bottom_right_lon: bottomRightLon,
      top_left_lat: topLeftLat,
      top_left_lon: topLeftLon,
      lon: longitude,
      lat: latitude,
      query,
      date,
      time_sections: timeSections?.map?.(s => s.name),
      sort,
      date_range: dateRange,
    },
    tests: testTracking,
  });
});
