import produce from 'immer';
import { Action, createModel } from '@rematch/core';
import type { RootModel } from './models';
import type { ProviderScheduleDay } from './ProviderWorkSchedule.types';

import { getWeeklySchedule } from '../modules/provider/ProviderWorkSchedule';

type State = {
  schedules: { [providerId: number]: ProviderScheduleDay[] | undefined },
};

const DEFAULT_STATE = {
  // pro schedules indexed by their id
  schedules: {},
};

type SetPayload = {
  providerId: number;
  schedule: ProviderScheduleDay[];
};

export default createModel<RootModel>()({
  name: 'providerWorkSchedule',

  state: DEFAULT_STATE as State,

  reducers: {
    /**
     * Sets the working schedule for the specified provider id
     */
    set: produce<State, [SetPayload]>((
      state: State, {
        providerId,
        schedule,
      }: SetPayload,
    ) => {
      state.schedules[providerId] = [...schedule];
    }),

    /**
     * Clears the working schedule for the specified provider id
     */
    clear: produce<State, [{ providerId: number }]>((
      state: State,
      { providerId }: { providerId: number },
    ) => {
      state.schedules[providerId] = undefined;
    }),

    /**
     * Clears the working schedules for all providers
     */
    clearAll: produce((state: State) => {
      state.schedules = {};
    }),
  },

  effects: dispatch => ({
    /**
     * Loads Provider Working Schedule for the specified provider ID
     * @param payload {object}
     *          providerId - provider to fetch working schedule for
     * @returns {Promise<void>}
     */
    load: async ({ providerId }: { providerId: number }): Promise<Action<SetPayload, void>> => {
      // clear any existing data for the provider before loading the new results
      await dispatch.providerWorkSchedule.clear({ providerId });
      return dispatch.providerWorkSchedule.set({
        providerId,
        schedule: await getWeeklySchedule(providerId),
      });
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    getAll() {
      return createSelector(
        slice,
        (state: State): State['schedules'] => state?.schedules,
      );
    },
    forPro: hasProps((_, props: { providerId: number }) => (
      createSelector(
        slice,
        (state: State) => state?.schedules?.[props.providerId],
      )
    )),
  }),
});
