export enum InstrumentType {
  BankAccount = 0,
  CreditCard = 1,
  DebitCard = 2,
  PrepaidCard = 3,
  Card = 4,
  AndroidPay = 5,
  ApplePay = 6,
  Klarna = 7,
}

export interface ICard {
  id: number;
  billing_address?: string;
  iin_record?: any;
  exp_month: number;
  exp_year: number;
  bank: string;
  last4: string;
  is_default: boolean;
  instrument_type: InstrumentType;
  instrument_consent_status?: null;
  requires_cvc_validation?: boolean;
  cvc_token?: string;
}

export type MinimalCard = Pick<ICard, 'id' | 'bank' | 'last4'>;
