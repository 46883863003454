/**
 * Staging configuration
 */
import { getRoot } from './getRoot';

const { isAdmin, root } = getRoot('styleseat.works');

export const ENV = 'staging';
export const API_ROOT = root;
export const SITE_ROOT = API_ROOT;
export const SEARCH_API_ROOT = 'https://search.styleseat.works';
export const STATIC_ASSET_URL = 'https://d3sad2rxyf0u9n.cloudfront.net';
export const STRIPE_JS_KEY = 'pk_test_BNt9NCN7U8ZqRGuFxx7C7D9M';
export const GOOGLE_API_KEY = 'AIzaSyAU1CAaNVdY54boh1jWRXgg1-rtKMgx93U';
export const GOOGLE_CLIENT_ID = '1041300213311-dhr7k4b8m1q8p50p061knpfb7frt0p1l.apps.googleusercontent.com';
export const GOOGLE_AUTH_SCOPE = 'https://www.googleapis.com/auth/calendar email';
export const GOOGLE_SIGNIN_CLIENT_ID = '1016475266319-4nmrl694j04irgjeb1q3btbvkjacl33b.apps.googleusercontent.com';
export const INSTAGRAM_APP_ID = '1836884766445421';
export const FACEBOOK_APP_ID = '111891482183773';
export const SHUTTLE_ENDPOINT = isAdmin ? false : 'https://shuttle.styleseat.works/log';
export const MIXPANEL_KEY = 'e98ab71c3ea4aff5904f2e7ccfe51578'; // sandbox key
export const FB_ANALYTICS_ENABLED = false;
export const IOS_CAMPAIGN_ID = 'kostyleseat-ios556f49f6d44f502492036c331d';
export const ANDROID_CAMPAIGN_ID = 'kostyleseat-android556f4a4116a065427890180fb0';
export const RECOMMENDATIONS_SOURCE_URL = 'https://d38u36p04f8gyu.cloudfront.net/';
export const RECOMMENDATIONS_ENGINE_PREFIX = 'stage';
export const RECOMMENDATIONS = 'stage_providers-by-city.json';
export const LOYALTY_METROS_FILE = 'stage/metros.json';
export const TIKTOK_PIXEL_ID = 'C1LJSDS8PMMOGUUNNR0G';
export const SEGMENT_WRITE_KEY = 'sz8ZeLKyxKzBTMEOxZ3ymPcjvHqC1DgQ';
export const BRAZE_KEY = '283ffde1-430e-4081-88be-9fdabdc875b9';
export const TALKJS_APP_ID = 'tz7loLDQ';

export const SEO_SERVICE_READ = 'https://okzgj5lri9.execute-api.us-east-1.amazonaws.com/seo_api/';

export const DEBUG = false;
export const APP = !!process.env.APP_BUILD;
export const ENABLE_APP_SIMULATOR = false;

export const LOGROCKET_KEY = 'o98ogu/styleseat-stage';
export const APP_ASSETS_URL = 'https://d4xif2uqocw53.cloudfront.net';
export const STATE_MANAGER_ENDPOINT = 'https://lltlcmj41k.execute-api.us-east-1.amazonaws.com/state_manager';
export const APPSFLYER_DEBUG = true;
export const STRIPE_TERMINAL_LOCATION_ID = 'tml_EoQAigDZxKzBha';

export const AWS_AUTOCOMPLETE_REGION = 'us-east-1';
// https://us-east-1.console.aws.amazon.com/cognito/v2/identity/identity-pools?region=us-east-1
export const AWS_AUTOCOMPLETE_COGNITO_IDENTITY_POOL_ID = 'us-east-1:cac83978-414b-4332-89f6-741af5bcbf8c';
// https://us-east-1.console.aws.amazon.com/location/places/home?region=us-east-1#/
export const AWS_AUTOCOMPLETE_INDEX_NAME = 'AutocompleteHEREIndex-stage';
export const AWS_AUTOCOMPLETE_ESRI_INDEX_NAME = 'AutocompleteESRIIndex';
