import { Platform } from 'react-native';
import { getIOSVersion, getIsApp } from './AppInfo';

function debugLog(message, ...args) {
  // eslint-disable-next-line no-console
  console.log(`[ImageTools] ${message}`, ...args);
}

/**
 * Convert a dataURI to a Blob object
 * @param {String} dataURI
 * @return {Blob}
 */
export function dataUriToBlob(dataURI) {
  const [headers, data] = dataURI.split(',');
  const [mime, base64] = headers.split(';');

  // Convert base64/URLEncoded data component to raw binary data held in a string
  const byteString = (base64) ? atob(data) : unescape(data);

  // write the bytes of the string to a typed array
  const byteArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([byteArray], { type: mime });
}

/**
 * Convert a remote image to a dataURI
 * @param {String} url - URL to an image
 * @yield {File}
 */
export function imageUrlToData(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const data = canvas.toDataURL('image/*', 1);
      resolve(data);
    };
    img.onerror = err => {
      reject(err);
    };
    img.crossOrigin = 'Anonymous';
    canvas.crossOrigin = 'Anonymous';
    img.src = url;
  });
}

/**
 * Converts an image from a URL to a `File` object using an intermediary `Blob`.
 * @param {String} url The URL of an image resource to convert to a file
 * @param {String} filename The filename for the `File` object yielded by this function.
 */
export function imageUrlToFile(url, filename = 'upload.jpg') {
  return imageUrlToData(url).then(dataUri => {
    const blob = dataUriToBlob(dataUri);
    return new File([blob], filename);
  });
}

/**
 * Checks the current browser to see if it's known to support the WebP image format
 * This will indicate whether we can use the .webp versions of the SR gallery images.
 */
export function doesBrowserSupportWebp() {
  if (window.device?.platform === 'iOS' && getIsApp()) {
    return getIOSVersion() >= '14';
  }
  const browser = window.Detectizr?.browser;
  if (browser?.name === 'safari') {
    return browser?.version >= '16';
  }
  if (browser?.name === 'ie') {
    return false;
  }
  return true;
}

/**
 * Checks the current browser to see if it's known to support the WebP image format
 * If so, replace the jpg extension of the image with webp
 */
export function webpImageUrl(image_url, webpEnabled) {
  return webpEnabled && doesBrowserSupportWebp() ? image_url?.replace(/.jpg$/, '.webp') : image_url;
}

/**
 * Checks the current browser to see if it's known to support EXIF Orientation
 * when rendering. This will indicate whether we need to manually rotate photos.
 */
export function isBrowserUsingExifOrientation() {
  const browser = window?.Detectizr?.browser || {};
  switch (browser?.name) {
    case 'chrome':
      return browser?.major >= '81';
    case 'safari':
      return browser?.version >= '13.1';
    default:
      return false;
  }
}

/**
 * Gets a photo from the device. This function rejects in non-cordova contexts.
 * @param {Boolean} encoded True to add an encoding, otherwise false
 * @param {Boolean} fromLibrary True to retrieve photo from the device library, otherwise false to
 * retrieve from a photo taken
 */
export function getPictureFromDevice(fromLibrary) {
  return new Promise((resolve, reject) => {
    debugLog('Getting picture from device');
    if (Platform.OS === 'web' && navigator.camera) {
      // Camera provided by cordova-plugin-camera
      const { Camera } = window;
      const sourceType = fromLibrary
        ? Camera.PictureSourceType.SAVEDPHOTOALBUM
        : Camera.PictureSourceType.CAMERA;

      const options = {
        quality: 20,
        destinationType: Camera.DestinationType.FILE_URI,
        correctOrientation: true,
        allowEdit: false,
        sourceType,
      };

      navigator.camera.getPicture(resolve, reject, options);
    } else {
      // We are not in phonegap.
      debugLog('not in phonegap');
      reject();
    }
  }).then(fileUrl => new Promise((resolve, reject) => {
    debugLog('Got image URL from device', fileUrl);
    // need to get a file "entry" so that we can get a file out of it.
    // See https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-camera/#take-a-picture-and-get-a-fileentry-object
    window.resolveLocalFileSystemURL(
      fileUrl,
      resolve,
      reject,
    );
  })).then(fileEntry => new Promise((resolve, reject) => {
    debugLog('Got image file from device');
    fileEntry.file(
      resolve,
      reject,
    );
  }));
}

/**
 * Gets one or more photos from the device. This function rejects in non-cordova contexts.
 * @param {Number} maximum number of images to select from library
 */
export function getPicturesFromDevice(maximumImagesCount) {
  return new Promise((resolve, reject) => {
    debugLog('Getting pictures from device');
    if (Platform.OS === 'web' && navigator.camera) {
      // imagePicker provided by cordova-plugin-telerik-imagepicker
      const { imagePicker } = window;

      const options = {
        maximumImagesCount,
        width: 720,
        height: 720,
        quality: 100,
      };

      imagePicker.getPictures(resolve, reject, options);
    } else {
      // We are not in phonegap.
      debugLog('not in phonegap');
      reject();
    }
  }).then(urls => Promise.all(urls.map(url => new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(url, resolve, reject);
  })))).then(entries => Promise.all(entries.map(entry => new Promise((resolve, reject) => {
    entry.file(resolve, reject);
  }))));
}
