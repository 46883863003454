// eslint-disable-next-line regex/invalid
// @ts-strict-ignore
import type { Dispatch, Action } from 'redux';
import {
  Models,
  RematchDispatch,
  RematchRootState,
} from '@rematch/core';
import abTest from './ABTest';
import booking from './Booking.model';
import clientNavBar from './ClientNavBar.model';
import clientPromotions from './ClientPromotions.model';
import favorites from './Favorites.model';
import { loader } from './Loader';
import location from './Location.model';
import marketplaceProfile from './MarketplaceProfile/MarketplaceProfile.model';
import ncdAndDiscountedPros from './NCDAndDiscountedPros.model';
import netEarnings from './NetEarnings.model';
import { PaymentMethods as paymentMethods } from './PaymentMethods';
import { MarketingVideos } from './MarketingVideos';
import previousAppointments from './PreviousAppointments.model';
import { providerCalendar } from './ProviderCalendar';
import providerGiftCard from './ProviderGiftCard.model';
import { ProviderOnboarding as providerOnboarding } from './ProviderOnboarding.model';
import { ProviderGoals as providerGoals } from './ProviderGoals.model';
import providerProducts from './ProviderProducts.model';
import providerProductsV2 from './ProviderProductsV2.model';
import providerReviews from './ProviderReviews.model';
import providers from './Providers.model';
import providerAvailability from './ProviderAvailability/ProviderAvailability.model';
import { providerClients } from './ProviderClients';
import { providerClientTimeline } from './ProviderClientTimeline';
import profileGalleries from './ProfileGalleries.model';
import { providerPlan } from './ProviderPlan';
import { profileServiceGroups } from './ProfileServiceGroups';
import providerServiceToPageMapping from './ProviderServiceToPageMapping.model';
import providerTopServices from './TopServices.model';
import providerWorkSchedule from './ProviderWorkSchedule.model';
import ratingsSummary from './RatingsSummary.model';
import recentSearches from './RecentSearches.model';
import recommendations from './Recommendations.model';
import referrals from './Referrals.model';
import route from './RouteState.model';
import search from './Search.model';
import searchConfig from './SearchConfig.model';
import seoData from './SEOData.model';
import showIncentives from './ShowIncentives.model';
import sidebar from './Sidebar.model';
import stripeIdentityVerification from './StripeIdentityVerification.model';
import { stripeTerminal } from './StripeTerminal';
import subsBlocker from './SubsBlocker.model';
import trackingId from './TrackingId.model';
import user from './CurrentUser.model';
import userAppointments from './UserAppointments';
import proCheckoutSettings from './ProviderCheckoutSettings/ProviderCheckoutSettings.model';
import providerSearchRank from './ProviderSearchRank.model';
import providerTopPro from './ProviderTopPro.model';
import chat from './Chat.model';
import providerProductCategory from './ProviderProductsCategory.model';
import { providerManageAppointment } from './ProviderManageAppointment';
import { providerAppointmentTotals } from './ProviderAppointmentTotals';
import { utmParameters } from './UTMParameters.model';
import { UserState as userState } from './UserState.model';
import { providerWorkPeriods } from './ProviderWorkPeriods';
import { providerEditAvailability } from './ProviderEditAvailability';
import { providerManageServices } from './ProviderManageServices';
import { providerManageServiceGroups } from './ProviderManageServiceGroups';
import { providerGallery } from './ProviderGallery';
import { providerStats } from './ProviderStats';
import { ncdc } from './NCDC/NCDC.model';
import { providerWaitlist } from './ProviderWaitlist';
import { proNCDExperienceEligibility } from './ProNCDExperience';
import { providerPromotions } from './ProviderPromotions';
import { ncdAppointmentCancellationReasons } from './NCDAppointmentCancellationReasons';
import { paymentsState } from './PaymentsState';
import providerReviewsSummary from './ProviderReviewsSummary.model';

// Page models
import bookingPage from '../components/pages/BookingPage/BookingPage.model';
import calendarPage from '../components/pages/provider/calendar/CalendarPage.model';
import clientAppointmentsList from '../components/pages/ClientAppointmentsList/ClientAppointmentsList.model';
import consumerProfilePage from '../components/pages/ConsumerProfilePage/ConsumerProfilePage.model';
import passwordResetConfirmPage from '../components/pages/PasswordResetConfirm/PasswordResetConfirmPage.model';
import proProfile from '../components/pages/ProProfile/ProProfile.model';
import proProfileEdit from '../components/pages/provider/profileEdit/ProProfileEdit.model';
import salonProfile from '../components/pages/Salon/SalonProfile.model';
import invitedToBookPage from '../components/pages/client/InvitedToBook/InvitedToBookLandingPage.model';
import proAppointmentDetails from '../components/pages/provider/AppointmentDetails/AppointmentDetails.model';
import proClientList from '../components/pages/provider/client/ProClientList.model';
import servicePages from '../components/pages/provider/services/ServicePages.model';
import { providerNotifications } from './ProviderNotifications';

export interface RootModel extends Models<RootModel> {
  abTest: typeof abTest;
  booking: typeof booking;
  bookingPage: typeof bookingPage;
  calendarPage: typeof calendarPage;
  chat: typeof chat;
  clientAppointmentsList: typeof clientAppointmentsList;
  clientNavBar: typeof clientNavBar;
  clientPromotions: typeof clientPromotions;
  consumerProfilePage: typeof consumerProfilePage;
  favorites: typeof favorites;
  invitedToBookPage: typeof invitedToBookPage;
  loader: typeof loader;
  location: typeof location;
  marketingVideos: typeof MarketingVideos;
  marketplaceProfile: typeof marketplaceProfile;
  ncdAndDiscountedPros: typeof ncdAndDiscountedPros;
  ncdAppointmentCancellationReasons: typeof ncdAppointmentCancellationReasons;
  ncdc: typeof ncdc;
  netEarnings: typeof netEarnings;
  passwordResetConfirmPage: typeof passwordResetConfirmPage;
  paymentMethods: typeof paymentMethods;
  paymentsState: typeof paymentsState;
  previousAppointments: typeof previousAppointments;
  proAppointmentDetails: typeof proAppointmentDetails;
  proCheckoutSettings: typeof proCheckoutSettings;
  proClientList: typeof proClientList;
  profileGalleries: typeof profileGalleries;
  profileServiceGroups: typeof profileServiceGroups;
  proNCDExperienceEligibility: typeof proNCDExperienceEligibility;
  proProfile: typeof proProfile;
  proProfileEdit: typeof proProfileEdit;
  providerAppointmentTotals: typeof providerAppointmentTotals;
  providerAvailability: typeof providerAvailability;
  providerCalendar: typeof providerCalendar;
  providerClients: typeof providerClients;
  providerClientTimeline: typeof providerClientTimeline;
  providerEditAvailability: typeof providerEditAvailability;
  providerGallery: typeof providerGallery;
  providerGiftCard: typeof providerGiftCard;
  providerGoals: typeof providerGoals;
  providerManageAppointment: typeof providerManageAppointment;
  providerManageServiceGroups: typeof providerManageServiceGroups;
  providerManageServices: typeof providerManageServices;
  providerNotifications: typeof providerNotifications;
  providerOnboarding: typeof providerOnboarding;
  providerPlan: typeof providerPlan;
  providerProductCategory: typeof providerProductCategory;
  providerProducts: typeof providerProducts;
  providerProductsV2: typeof providerProductsV2;
  providerPromotions: typeof providerPromotions;
  providerReviews: typeof providerReviews;
  providerReviewsSummary: typeof providerReviewsSummary;
  providers: typeof providers;
  providerSearchRank: typeof providerSearchRank;
  providerServiceToPageMapping: typeof providerServiceToPageMapping;
  providerStats: typeof providerStats;
  providerTopPro: typeof providerTopPro;
  providerTopServices: typeof providerTopServices;
  providerWaitlist: typeof providerWaitlist;
  providerWorkPeriods: typeof providerWorkPeriods;
  providerWorkSchedule: typeof providerWorkSchedule;
  ratingsSummary: typeof ratingsSummary;
  recentSearches: typeof recentSearches;
  recommendations: typeof recommendations;
  referrals: typeof referrals;
  route: typeof route;
  salonProfile: typeof salonProfile;
  search: typeof search;
  searchConfig: typeof searchConfig;
  seoData: typeof seoData;
  servicePages: typeof servicePages;
  showIncentives: typeof showIncentives;
  sidebar: typeof sidebar;
  stripeIdentityVerification: typeof stripeIdentityVerification;
  stripeTerminal: typeof stripeTerminal;
  subsBlocker: typeof subsBlocker;
  trackingId: typeof trackingId;
  user: typeof user;
  userAppointments: typeof userAppointments;
  userState: typeof userState;
  utmParameters: typeof utmParameters;
}

// Add models here, alphabetically please
export const models: RootModel = {
  abTest,
  booking,
  bookingPage,
  calendarPage,
  chat,
  clientAppointmentsList,
  clientNavBar,
  clientPromotions,
  consumerProfilePage,
  favorites,
  invitedToBookPage,
  loader,
  location,
  marketingVideos: MarketingVideos,
  marketplaceProfile,
  ncdAndDiscountedPros,
  ncdAppointmentCancellationReasons,
  ncdc,
  netEarnings,
  passwordResetConfirmPage,
  paymentMethods,
  paymentsState,
  previousAppointments,
  proAppointmentDetails,
  proCheckoutSettings,
  proClientList,
  profileGalleries,
  profileServiceGroups,
  proNCDExperienceEligibility,
  proProfile,
  proProfileEdit,
  providerAppointmentTotals,
  providerAvailability,
  providerCalendar,
  providerClients,
  providerClientTimeline,
  providerEditAvailability,
  providerGallery,
  providerGiftCard,
  providerGoals,
  providerManageAppointment,
  providerManageServiceGroups,
  providerManageServices,
  providerNotifications,
  providerOnboarding,
  providerPlan,
  providerProductCategory,
  providerProducts,
  providerProductsV2,
  providerPromotions,
  providerReviews,
  providerReviewsSummary,
  providers,
  providerSearchRank,
  providerServiceToPageMapping,
  providerStats,
  providerTopPro,
  providerTopServices,
  providerWaitlist,
  providerWorkPeriods,
  providerWorkSchedule,
  ratingsSummary,
  recentSearches,
  recommendations,
  referrals,
  route,
  salonProfile,
  search,
  searchConfig,
  seoData,
  servicePages,
  showIncentives,
  sidebar,
  stripeIdentityVerification,
  stripeTerminal,
  subsBlocker,
  trackingId,
  user,
  userAppointments,
  userState,
  utmParameters,
};

export type RootState = RematchRootState<RootModel>;

export interface IRootDispatch extends RematchDispatch<RootModel>, Dispatch<NonRematchActions> { }

type NonRematchActions = Action<'never'>;

// TODO: get this to work on strict mode
// export default models as ModelDefinitions<typeof models, keyof typeof models>;
export default models;
