// @ts-strict-ignore
import { API_ROOT } from '../../config';
import { ssFetchJSON } from '../../modules/ssFetch';

import nonCriticalException from '../../modules/exceptionLogger';

export type IntakeFormQuestionType = 'text' | 'select';
export type IntakeFormSelectOption = { key: string; label: string };

export type IntakeFormQuestion = {
  title: string;
  description: string;
  type: IntakeFormQuestionType;
  options?: IntakeFormSelectOption[];
};

export type IntakeForm = {
  questions: IntakeFormQuestion[];
  form_name: string;
};

export type IntakeFormResponse = {
  assigned_form: IntakeForm;
  responses: string[];
};

export const getIntakeFormResponseV1 = async (formId: string): Promise<IntakeFormResponse> => {
  const url = new URL(`${API_ROOT}/api/v1/form/${formId}`);
  try {
    return await ssFetchJSON(url.toString(), { includeResponseOnError: true });
  } catch (err) {
    // 404 is expected if the form has not been filled out yet, so don't log those
    if (err && err.status !== 404) {
      nonCriticalException(err);
    }
    return null;
  }
};
export const getIntakeFormResponse = async (formId: string): Promise<IntakeFormResponse> => {
  if (!formId) return null;

  const url = new URL(`${API_ROOT}/api/v1/form/${formId}/response`);
  try {
    return await ssFetchJSON(url.toString(), { includeResponseOnError: true });
  } catch (err) {
    // 404 is expected if the form has not been filled out yet, so don't log those
    if (err && err.status !== 404) {
      nonCriticalException(err);
    }
    return null;
  }
};
