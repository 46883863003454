// @ts-strict-ignore
import { useMemo } from 'react';
import { wrapWithTracking, createTrackingResolver } from '../modules/analytics/trackingUtils';

/**
 * Wraps a function property with a tracking call using the second argument to specify tracking
 * information
 */
export const useTracking = <T>(propFunc: T, tracking): T => useMemo<T>(() => {
  const resolveTrackingInfo = createTrackingResolver(tracking);

  return wrapWithTracking(propFunc, resolveTrackingInfo) as any;
}, [propFunc]);
