import nonCriticalException from '../../exceptionLogger';
import SimpleCache from '../../SimpleCache';
import { ssFetchJSON } from '../../ssFetch';
import type { IAppointmentRatings } from '../../../components/pages/ClientAppointmentsList/ClientAppointmentsList.model';

export const RatingHistoryCache = new SimpleCache(5 * 60);

export interface IRatingParams {
  num_stars: number;
  about_provider?: string,
  about_location?: string,
  about_hairtype?: string,
  recommendation_text?: string;
}

interface ICreateUserRating {
  token?: string;
  appointmentId?: number;
  providerVanityUrl?: string;
  params: IRatingParams;
}

interface IRatingSuccess {
  status: string;
  recommendation_created: boolean;
  recommendaton_share_url: string;
}

interface IRatingError {
  errors: any;
  message: string;
  status: string;
}

type IRatingResponse = IRatingSuccess | IRatingError;

/**
 * Create a user rating. Use either token for non-authenticated user or appointment_id for
 * authenticated user
 *
 * @param {String} token Appointment-based authentication token (optional)
 * @param {Number} appointment_id Appointment id (optional)
 * @param {String} providerVanityUrl Provider's vanity_url, used for anonymous feedback (optional)
 * @param {String} params A json that contains star rating "num_stars" and recommendation text
 * "recommendation_text.
 * @returns {Promise} A promise that is resolved when the rating is posted, or rejected if an error
 * occurs.
 */
export async function createUserRating(props: ICreateUserRating): Promise<IRatingResponse> {
  const {
    token,
    appointmentId,
    providerVanityUrl,
    params,
  } = props;

  let url: string = '';
  if (token) {
    url = `/feedback/a/${token}`;
  } else if (providerVanityUrl) {
    url = `/feedback/pro/${providerVanityUrl}`;
  } else {
    url = `/feedback/ai/${appointmentId}`;
  }

  const response: IRatingResponse = await ssFetchJSON(url, {
    method: 'POST',
    form: params,
  });

  if (response.status === 'OK') {
    RatingHistoryCache.clear();
    return response;
  }

  throw new Error((<IRatingError>response).message);
}

export interface IRating {
  pinned: boolean;
  review_text: string;
  num_stars: number;
  id: number;
  appointment: number;
}

export type RatingHistoryModel = {
  model: 'ratings.ratinghistory',
  pk: number,
  fields: IRating,
};

/**
 * Fetch ratings submitted by the user.
 * @todo Check that the return value of this matches the interface
 */
export async function getUserRatings(): Promise<Array<RatingHistoryModel>> {
  return ssFetchJSON('/feedback/a', { ssCache: RatingHistoryCache });
}

/**
 * Takes the raw data from getUserRatings and organizes it by appointment id
 */
export function transformUserRatings(ratings: RatingHistoryModel[]): IAppointmentRatings {
  return ratings.map(
    rating => rating.fields,
  ).reduce(
    (result, rating) => ({ ...result, [rating.appointment]: rating.num_stars }),
    {},
  );
}

export async function getRatingStarsForAppt(apptId: number): Promise<number | null> {
  let ratingsHistory: Array<RatingHistoryModel> = [];
  try {
    ratingsHistory = await getUserRatings();
  } catch (e) {
    nonCriticalException(e);
    return null;
  }

  if (!ratingsHistory?.length) return null;

  const rating = ratingsHistory.find(({ fields }) => fields.appointment === apptId);
  if (!rating?.fields?.num_stars) return null;

  return rating.fields.num_stars;
}
