// @ts-strict-ignore
import URI from 'urijs';
import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';

// see https://docs.celeryq.dev/en/stable/reference/celery.result.html#celery.result.AsyncResult.state
export enum UploadProgressState {
  Pending = 'UNKNOWN', // for some reason, PENDING is mapped to UNKNOWN in the backend
  Error = 'FAILURE',
  Success = 'SUCCESS',
}

const WAIT_DELAY_MS = 500;
const MAX_WAIT_CALLS = 40;

// see TaskStatus view in backend
interface TaskStatusResult {
  status: 'OK';
  tasks: Record<string, UploadProgressState>;
}

export function getTaskStatus(taskIds: Array<string>): Promise<TaskStatusResult> {
  const uri = new URI(`${API_ROOT}/api/v1/task-status/`);

  taskIds.forEach(taskId => {
    uri.addQuery('task_id[]', taskId);
  });

  return ssFetchJSON<TaskStatusResult>(
    uri.toString(),
    { method: 'DELETE' },
  );
}

/**
 * Wait for the upload task to complete
 * @param {string} taskId
 * @param count
 */
export function uploadWait(taskId, count = 0) {
  return new Promise((resolve, reject) => {
    getTaskStatus([taskId])
      .then((data: TaskStatusResult) => {
        const status = data.tasks[taskId];
        if (count > MAX_WAIT_CALLS) {
          throw new Error('Timed out while waiting for image to upload');
        } else if (status === UploadProgressState.Error) {
          throw new Error('An error occurred uploading this image');
        } else if (status === UploadProgressState.Success) {
          resolve(status);
        } else {
          setTimeout(() => resolve(uploadWait(taskId, count + 1)), WAIT_DELAY_MS);
        }
      })
      .catch(reject);
  });
}
