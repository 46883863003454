// @ts-strict-ignore
import { APP } from '../../../config';
import { Responsive } from '../../../modules/Responsive';
import { ShareChannelConfig } from '../../../modules/share';
import { BasicMoreHorizontalIcon } from '../../ui/Icons/BasicMoreHorizontalIcon';

const SMS_ICON = require('../../../../images/sharing/sms-icon.svg');
const FACEBOOK_ICON = require('../../../../images/sharing/facebook-icon.svg');
const EMAIL_ICON = require('../../../../images/sharing/email-icon.svg');
const TWITTER_ICON = require('../../../../images/sharing/twitter-icon.svg');

const groupBy = (items, key) => items.reduce((prev, item) => ({ ...prev, [item[key]]: item }), {});

/** Detect if the mobile app, i.e. from the app store or on a mobile device's browser */
const RESPONSIVE = Responsive.layout.mobile;

const DESKTOP = !APP && !RESPONSIVE;

/** Channel enum in display order. Note that an array is used instead of an object
    so we don't lose the intended order when rendering the menu */
const ORDERED_CHANNELS = [
  {
    id: 'sms',
    name: 'SMS',
    icon: SMS_ICON,
    showInApp: true,
    showInMobile: true,
    showInDesktop: false,
  },
  {
    id: 'facebook',
    name: 'Facebook',
    icon: FACEBOOK_ICON,
    showInApp: true,
    showInMobile: true,
    showInDesktop: true,
  },
  {
    id: 'email',
    name: 'Email',
    icon: EMAIL_ICON,
    showInApp: true,
    showInMobile: true,
    showInDesktop: true,
  },
  {
    id: 'twitter',
    name: 'Twitter',
    icon: TWITTER_ICON,
    showInApp: true,
    showInMobile: true,
    showInDesktop: true,
  },
  /** as of now instagram sharing is disabled
   *  because we can't pre-fill message or url
  */
  // {
  //   id: 'instagram',
  //   name: 'Instagram',
  //   icon: INSTAGRAM_ICON,
  //   showInMobile: true,
  //   showInApp: true,
  // },
  {
    id: 'more',
    name: 'More',
    icon: null,
    iconReplacementComponent: BasicMoreHorizontalIcon,
    showInApp: true,
    showInMobile: false,
    showInDesktop: false,
  },
] as Array<ShareChannelConfig>;

export const COPY_LINK = 'copy_link';

/** Channels by ID */
export const CHANNELS = groupBy(ORDERED_CHANNELS, 'id');

/** The IDs of the available sharing channels in display order */
export const AVAILABLE_CHANNELS = [
  ...ORDERED_CHANNELS.map(({ id }) => id),
  COPY_LINK,
];

/** The IDs of the platform-specific sharing channels in the app */
export const PLATFORM_CHANNELS = ORDERED_CHANNELS
  .filter(({
    showInApp,
    showInMobile,
    showInDesktop,
  }) => {
    if (APP) {
      return showInApp;
    }

    if (RESPONSIVE) {
      return showInMobile;
    }

    if (DESKTOP) {
      return showInDesktop;
    }

    return false;
  })
  .map(({ id }) => id);

/** The message to display when a user shares their own profile */
export const IS_MINE_MESSAGE = 'My beauty services are now available online on StyleSeat.'
  + ' Click on the link to book with me today';

export const CLIENT_REFERRALS_SHARE_CARD_UI_TEST_NAME = 'client_referral_share_card_test_atl4146_20220308';
export const CLIENT_REFERRALS_SHARE_CARD_UI_TEST_PERCENT = 0.5;
export const CLIENT_REFERRAL_REQUIRE_LOGIN_SWITCH = 'client_referral_require_login_atl4118_20211203';
export const CLIENT_REFERRAL_REQUIRE_RECIPIENT_LOGIN_SWITCH = 'client_referral_require_recipient_login_atl4210_20220207';
