import { API_ROOT } from '../../../config';
import { openExternalUrl } from '../../../modules/openExternalUrl';
import { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';

/**
 * ProviderTaxSettingsView
 * GET: api/v1/provider/{providerId}/settings/tax
 */
export interface TaxSettings {
  consented_to_paperless_delivery_time: string | false;
}

/**
 * ProviderTaxSettingsSerializer
 * POST: api/v1/provider/{providerId}/settings/tax
 */
export interface TaxSettingsSave {
  consented_to_paperless_delivery: boolean;
}

/**
 * ProviderTaxFormsListSerializer
 * GET: api/v1/provider/{providerId}/settings/tax/forms
 */
export interface TaxForm {
  year: string;
  form_id: string;
  form_type: string;
  humanized_form_type: string;
  file_name: string;
}

export function getProviderTaxSettings(providerId: number | string): Promise<TaxSettings> {
  const url = new URL(`${API_ROOT}/api/v1/provider/${providerId}/settings/tax`);
  return ssFetchJSON(url.toString());
}

export function saveProviderTaxSettings(providerId: number | string, data: TaxSettingsSave) {
  const url = new URL(`${API_ROOT}/api/v1/provider/${providerId}/settings/tax`);
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    form: data,
  });
}

export function getTaxFormsList(providerId: number | string): Promise<IDRFResponse<TaxForm>> {
  const url = new URL(`${API_ROOT}/api/v1/provider/${providerId}/tax/forms`);
  return ssFetchJSON(url.toString());
}

export async function displayTaxFormPdf(
  providerId: number | string,
  form: TaxForm,
): Promise<void> {
  const url = new URL(`${API_ROOT}/api/v1/provider/${providerId}/tax/form/${form.form_id}`);
  openExternalUrl(url.toString());
}
