// @ts-strict-ignore
import { IRouteDestination } from 'packages/router/types';
import analytics from '../analytics';
import nonCriticalException from '../exceptionLogger';
import { APPSFLYER_DEBUG } from '../../config';
import store from '../../store';
import Deferred from '../../testUtilities/deferred';
import {
  APPSFLYER_APP_ID,
  APPSFLYER_DEV_KEY,
  DeepLinkType,
  deepLinkTypeToRouteProps,
  resolveDeepLinkURLs,
} from './constants';
import {
  mapGenericParams,
  mapToRouteParams as mapToDestinationProps,
  trackDeepLinking,
} from './appsFlyer.utils';
import {
  DeepLinkResponse,
  DeepLinkResponseData,
  DeepLinkSub,
  RouteProps,
} from './types';

export const trackInstallAttribution: (data: string) => Promise<void> = async data => {
  try {
    const conversionData = JSON.parse(data)?.data;
    if (conversionData?.is_first_launch) {
      const segmentTracker = await analytics.getTracker('segment');
      const properties = {
        ...conversionData,
        utm_source: conversionData.media_source,
        utm_campaign: conversionData.campaign,
        provider: 'AppsFlyer',
      };
      segmentTracker.trackEvent('Install Attributed', properties);
    }
  } catch (e) {
    nonCriticalException(e);
  }
};

export const readyForDeepLinking = new Deferred();

export const markDeepLinkingReady = () => {
  if (!readyForDeepLinking.isFulfilled) {
    readyForDeepLinking.resolve();
  }
};

export const redirectDeepLink = async (
  deepLinkData: DeepLinkResponseData,
  dispatch,
  user,
) => {
  try {
    const {
      deep_link_value: accessType, // Generic flow: Generic type
      [DeepLinkSub.DeepLinkSub1]: accessValue, // Generic flow: route name
      [DeepLinkSub.DeepLinkSub2]: accessValue2, // Generic flow: parameters "value=1;value=2"
    } = deepLinkData;

    const toRouteProps: RouteProps = deepLinkTypeToRouteProps[accessType];
    if (!toRouteProps) {
      nonCriticalException('onAppOpenAttribution access type error');
      return;
    }

    let propertyValues;
    switch (accessType) {
      case DeepLinkType.ClientIntakeForm:
        propertyValues = [
          accessValue,
          true,
        ];
        break;
      case DeepLinkType.ProviderViewIntakeForm:
        propertyValues = [
          accessValue,
          accessValue2,
          true,
        ];
        break;
      default:
        propertyValues = [
          accessValue,
          accessValue2,
        ];
    }

    const routeDestinationProps: IRouteDestination = accessType === DeepLinkType.Generic
      ? { route: accessValue, params: mapGenericParams(accessValue2) }
      : mapToDestinationProps(toRouteProps, propertyValues);

    // Track only the needed deep links
    trackDeepLinking(deepLinkData, user);
    await dispatch.route.go(
      {
        ...routeDestinationProps,
        replace: true,
      },
    );
  } catch (e) {
    nonCriticalException(e);
  }
};

export const init = async () => {
  window?.plugins?.appsFlyer?.registerDeepLink(async res => {
    try {
      await readyForDeepLinking.promise;
      const { dispatch, getState } = store;
      const deepLinkData: DeepLinkResponse = JSON.parse(res);
      const user = getState()?.user;

      redirectDeepLink(deepLinkData?.data, dispatch, user);
    } catch (e) {
      nonCriticalException(e);
    }
  });

  window?.plugins?.appsFlyer?.setResolveDeepLinkURLs(resolveDeepLinkURLs);

  window?.plugins?.appsFlyer?.initSdk?.(
    {
      devKey: APPSFLYER_DEV_KEY,
      isDebug: APPSFLYER_DEBUG,
      appId: APPSFLYER_APP_ID,
      waitForATTUserAuthorization: 10, // time for the sdk to wait before launch - IOS 14 ONLY!
      onInstallConversionDataListener: true,
      onDeepLinkListener: true,
    },
    trackInstallAttribution, // success callback
    nonCriticalException, // error callback
  );
};
