import { API_ROOT } from '../../config';
import SimpleCache from '../../modules/SimpleCache';
import { ssFetchJSON } from '../../modules/ssFetch';

type GetParams = {
  professionId?: number;
  size: 'all' | number;
};

/**
 * Data as returned from the StdServiceCategoryOrderSerializer serializer
 * GET: api/v2/std_service_categories
 */
type StdCategoriesResponse = {
  results: StdCategory[];
};

const StdCategoriesCache = new SimpleCache(15 * 60);

export type StdService = { id: number; name: string; category: number };

export type StdCategory = {
  category: {
    id: number;
    name: string;
    services: StdService[];
  }

  order: number;

  // profession id
  profession: number;
};

export const getStdCategories = ({ professionId, size = 'all' }: GetParams) => {
  const url = new URL('/api/v2/std_service_categories', API_ROOT);

  if (professionId) {
    url.searchParams.append('profession', professionId.toString());
  }

  if (size) {
    url.searchParams.append('size', size.toString());
  }

  return ssFetchJSON<StdCategoriesResponse>(
    url.toString(),
    { ssCache: StdCategoriesCache },
  );
};
