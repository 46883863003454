import { SCPReaderDisplayMessage } from '../../modules/TapToPay/TapToPayPlugin.types';
import type { RootState } from '../models';
import { ConnectionStatus } from './StripeTerminal.model.types';

// These are in their own files instead of the main model to workaround cyclic dependencies
export const selectors = {
  connectionStatus: (state: RootState) => state.stripeTerminal.connectionStatus,
  isConnected: (state: RootState) => state.stripeTerminal.connectionStatus
    === ConnectionStatus.Connected,
  isNotConnected: (state: RootState) => state.stripeTerminal.connectionStatus
    === ConnectionStatus.NotConnected,
  isInProgress: (state: RootState) => state.stripeTerminal.connectionStatus
    === ConnectionStatus.InProgress,
  latestReaderDisplayMessage: (
    state: RootState,
  ): SCPReaderDisplayMessage | null => state.stripeTerminal.latestReaderDisplayMessage,
};
