export const AUTH_ORIGINS = {
  BOOKING: 'booking',
  HEARTING: 'hearting photo details',
  PROFILE_LANDING_SIGNUP_WALL: 'profile landing signup wall',
  APP_SIGNUP_WALL: 'app signup wall',
  SIGNUP_URL_PARAMETER: 'signup url parameter',
  PROFILE_HEADER_ICON_FAV_PRO: 'profile header icon favorite pro',
  SIGNUP_PROMO_MODAL_FACEBOOK: 'signup promo modal facebook',
  SIGNUP_PROMO_MODAL_EMAIL: 'signup promo modal email',
  HOME_ACCOUNT_ACTIONS: 'home account actions',
  SIDEBAR: 'sidebar',
  HEADER_NAV: 'header',
  DEEP_LINK_BOOKING: 'deep link booking',
  REFERRAL_LANDING: 'referral landing page',
  REFERRAL_SHARING: 'sharing referral modal',
  PRO_VS_CLIENT_MODAL: 'pro vs client modal',
  LOGIN_PAGE: 'login page',
  PROFILE_SEND_MESSAGE: 'profile send message',
  SALON_SEND_MESSAGE: 'salon send message',
  NOTIFY_ME_PROMPT: 'notify me prompt',
  PROFILE_GIFT_CARD: 'profile gift card',
  CLIENT_MESSAGE_MODAL: 'client_message_modal',
  APPT_STATUS_DETAILS: 'appt_status_details',
  PRO_BOOKED_APPT_CONFIRMATION: 'pro booked appointment confirmation screen',
};

export enum AuthOrigins {
  Booking = 'booking',
  Hearting = 'hearting photo details',
  ProfileLandingSignupWall = 'profile landing signup wall',
  AppSignupWall = 'app signup wall',
  SignupUrlParameter = 'signup url parameter',
  ProfileHeaderIconFavPro = 'profile header icon favorite pro',
  SignupPromoModalFacebook = 'signup promo modal facebook',
  SignupPromoModalEmail = 'signup promo modal email',
  Homepage = 'homepage',
  HomeAccountActions = 'home account actions',
  Sidebar = 'sidebar',
  HeaderNav = 'header',
  DeepLinkBooking = 'deep link booking',
  ReferralLanding = 'referral landing page',
  ReferralSharing = 'sharing referral modal',
  ProVsClientModal = 'pro vs client modal',
  LoginPage = 'login page',
  ProfileSendMessage = 'profile send message',
  SalonSendMessage = 'salon send message',
  NotifyMePrompt = 'notify me prompt',
  ProfileGiftCard = 'profile gift card',
  ClientMessageModal = 'client_message_modal',
  ApptStatusDetails = 'appt_status_details',
  ProBookedApptConfirmation = 'pro booked appointment confirmation screen',
  ProProfileMessageClick = 'pro_profile.message-click',
  ProProfileServiceMoreInfoClick = 'pro_profile.service_more_info.message-click',
}

export const AUTH_PROVIDERS = {
  FACEBOOK: 'facebook',
  APPLE: 'apple',
  EMAIL: 'default',
};

export const AUTH_MESSAGES = {
  'update-payment': 'Log in or sign up to update your payment info.',
  appointments: 'Log in or sign up to access your appointments.',
};
