import { MomentInput } from 'moment';
import { DateTimeStamp } from '../../../types/dateTime';
import type {
  AddClientNotification,
  AmbassadorToolkitNotification,
  MarketingHubNotification,
  NCDCancellationPolicyNotification,
  SearchBoostNotification,
  SearchResultsStatsNotification,
  WelcomeNotification,
  TaxEDeliveryNotification,
} from './StaticNotifications';

export interface ReviewNotification {
  'object_type': 'Review';
  'object_id': number;
  'type': 'client_review';
  'provider_id': number;
  'user_id': number;
  'review_text': string;
  'num_stars': number;
  'client_id': number | null;
  'client_name': string;
  'client_thumbnail': string | null;
}

export interface BookingNotification {
  'object_type': 'Appointment';
  'object_id': number;
  'appointment_id': number;
  'type': 'client_appt_booking' | 'first_sp_client_appt_booking';
  'provider_id': number;
  'user_id': number;
  'title': string;
  'client_id': number;
  'service_name': string;
  'client_name': string;
  'client_thumbnail': string;
  'local_start': DateTimeStamp;
  'is_new_client_delivery': boolean;
  'is_paid_new_client_delivery': boolean;
  'is_prepaid_new_client_delivery': boolean;
  'is_monetized_new_client_delivery': boolean;
  'is_user_first_time_booking_with_pro': boolean;
  'is_smart_price': boolean;
  'fee_breakdown': number[];
  'pretax_subtotal': number;
}

export interface CardAuthSuccessNotification {
  'type': 'client_card_auth_success';
  'appointment_id': number;
  'provider_id': number;
  'client_name': string;
  'appointment_date': DateTimeStamp;
  'auth_amount': string;
}

export interface ClientMessageNotification {
  'type': 'client_message';
  'provider_id': number;
  'client_id': number;
  'client_name': string;
  'message': string;
  'email': string;
  'phone': string;
  'thumbnail': string;
}

export interface UserWaitlistNotification {
  'type': 'user_requested_waitlist';
  'services': Array<{ id: number; name: string }>;
  'provider_id': number;
  'provider_premium_upcharge_rate': number;
  'client_id': number;
  'client_name': string;
  'client_note': string;
  'client_thumbnail': string;
  'waitlist_id': number;
  'received': DateTimeStamp;
}

export interface CancelNotification {
  'object_type': 'Appointment';
  'object_id': number;
  'type': 'client_appt_cancel' | 'appointment_cancelled_provider' | 'appointment_cancelled_provider_no_show';
  'provider_id': number;
  'user_id': number;
  'title': string;
  'client_id': number;
  'service_name': string;
  'client_name': string;
  'client_thumbnail': string;
  'local_start': DateTimeStamp;
  'is_ncd': boolean;
  'paid_ncd_fee': boolean;
  'deposit_amount': number | null;
}

export interface RescheduleNotification {
  'object_type': 'Appointment';
  'object_id': number;
  'type': 'client_appt_reschedule';
  'provider_id': number;
  'user_id': number;
  'title': string;
  'client_id': number;
  'original_appt_id': number;
  'service_name': string;
  'client_name': string;
  'client_thumbnail': string;
  'local_start': DateTimeStamp;
}

export interface ScheduleGapFillNotification {
  'creation_time': DateTimeStamp;
  'object_type': 'Appointment';
  'object_id': number;
  'schedule_gap_id': number;
  'type': 'client_appt_schedule_gap_fill';
  'provider_id': number;
  'user_id': number;
  'local_start': DateTimeStamp;
}

export interface NCDClientInsightNotification {
  'type': 'ncd_client_insight';
  'creation_time': DateTimeStamp;
  'appointment_id': number;
  'provider_id': number;
  'user_id': number;
  'title': string;
  'client_id': number;
  'service_name': string;
  'client_name': string;
  'client_thumbnail': string;
  'local_start': DateTimeStamp;
  'responses': {
    'answer_pro_display_text': string;
    'active': boolean;
    'sequence': number;
  }[];
  'ncd_fee_percent': number;
}

export interface PushOptInNotification {
  'type': 'push_notification_opt_in';
  'provider_id': number;
}

export interface VirtualServicesNotification {
  'type': 'virtual_services_education';
  'provider_id': number;
  'recommendation': string;
}

export interface GiftCardEducationNotification {
  'type': 'gift_card_education';
  'provider_id': number;
}

export interface ProductsEducationNotification {
  'type': 'product_sales_education';
  'provider_id': number;
}

export interface GiftCardNotification {
  'type': 'gift_card_purchased';
  'gift_card_code': string;
  'provider_id': number;
  'client_name': string;
  'gift_card_message': string;
  'gift_card_amount': number;
}

export interface NeedMailingAddressNotification {
  'type': 'need_mailing_address';
  'provider_id': number;
}

export interface NeedVerificationDetailsNotification {
  'type': 'need_verification_details';
  'provider_id': number;
}

export interface AppointmentWithoutCCNotification {
  'object_type': 'Appointment';
  'appointment_id': number;
  'type': 'appt_without_cc';
  'provider_id': number;
  'user_id': number;
  'title': string;
  'client_id': number;
  'service_name': string;
  'service_id': number;
  'client_name': string;
  'client_thumbnail': string;
  'local_end': DateTimeStamp;
  'total_appt_cost': string;
}

export interface NCDNonPaymentAppointmentNotification {
  'object_type': 'Appointment';
  'appointment_id': number;
  'type': 'ncd_non_payment_appointment_notification';
  'provider_id': number;
}

export interface NCDPaymentAppointmentNotification {
  'object_type': 'Appointment';
  'appointment_id': number;
  'type': 'ncd_appointment_paid_on_styleseat';
  'provider_id': number;
}

export interface ClientCheckoutNotification {
  'type': 'client_checkout';
  'appointment_id': number;
  'provider_id': number;
  'client_id': number;
  'client_name': string;
  'client_thumbnail': string;
  'client_checkout_type': string;
  'service_cost': number;
}

export interface SmartPricingAnnouncementNotification {
  'type': 'smart_pricing_announcement';
  'provider_id': number;
}

export interface ProfileFeedbackNotification {
  'type': 'profile_feedback';
  'provider_id': number;
  'stars': number;
  'message': string;
  'name': string;
  'choice_selected': number;
}

export interface SearchBoostEligibilityNotification {
  'type': 'search_boost_eligibility';
  'provider_id': number;
}

export interface ServiceInteractionNotification {
  'type': 'client_service_interaction';
  'provider_id': number;
  'service_id': number;
  'service_name': string;
  'interactions': number;
  'has_description': boolean;
}

export interface SearchBoostEarningExtensionNotification {
  'type': 'extend_search_boost_eligibility';
  'provider_id': number;
}

export type BaseDynamicNotification = {
  uuid: string;
  creation_time: DateTimeStamp;
};

export interface TaxFormAvailableNotification {
  provider_id: string | number;
  filing_year: string | number;
  creation_time: MomentInput;
  persistent: true;
  type: 'tax_form_available';
  uuid: 'tax-form-available-notification';
}

/**
 * Type specifying notifications come from server
 */
export type DynamicNotification = BaseDynamicNotification & (
  ReviewNotification
  | BookingNotification
  | CardAuthSuccessNotification
  | ClientMessageNotification
  | UserWaitlistNotification
  | CancelNotification
  | RescheduleNotification
  | ScheduleGapFillNotification
  | NCDClientInsightNotification
  | PushOptInNotification
  | VirtualServicesNotification
  | GiftCardEducationNotification
  | ProductsEducationNotification
  | GiftCardNotification
  | NeedMailingAddressNotification
  | NeedVerificationDetailsNotification
  | AppointmentWithoutCCNotification
  | NCDNonPaymentAppointmentNotification
  | NCDPaymentAppointmentNotification
  | ClientCheckoutNotification
  | SmartPricingAnnouncementNotification
  | ProfileFeedbackNotification
  | SearchBoostEligibilityNotification
  | ServiceInteractionNotification
  | SearchBoostEarningExtensionNotification
  | TaxFormAvailableNotification
);

export type StaticNotification = (
  AddClientNotification
  | SearchBoostNotification
  | AmbassadorToolkitNotification
  | MarketingHubNotification
  | NCDCancellationPolicyNotification
  | SearchResultsStatsNotification
  | WelcomeNotification
  | TaxEDeliveryNotification
);

export type Notification = DynamicNotification | StaticNotification;

export interface NotificationResponse {
  'results': DynamicNotification[];
  'profile_phase_one_complete_date': DateTimeStamp;
  'profile_search_boost_eligible_date': DateTimeStamp;
  'profile_search_boost_earning_start_date': DateTimeStamp;
  'profile_search_boost_earning_end_date': DateTimeStamp;
  'profile_search_boost_num_of_appts': number;
  'profile_search_boost_extension_creation_time': DateTimeStamp;
  'profile_creation_time': DateTimeStamp;
}

export const LAST_VIEWED_NOTIFICATION_STATE_KEY = 'last-viewed-pro-notification';
export const LAST_VIEWED_STATIC_NOTIFICATION_PREFIX = 'last_seen_notification_';
export const NOTIFICATION_POLLING_INTERVAL = 1000 * 60; // 1 minute
