import { ssFetchJSON } from '../../ssFetch';
import { PhoneSettingsResponse } from '../../user/phoneSettings';

export const enum ClientSMSStatus {
  /**
   * There is no sms permission history for this client and
   * the SMS notification invite can be sent
   */
  NoSmsPermissionHistory = 'NO_SMS_PERMISSION_HISTORY',

  /**
   * The client's SMS notifications have been disabled
   */
  ClientSmsDisabled = 'CLIENT_SMS_DISABLED',

  /**
   * SMS reminder invite has been sent to client, but
   * client has not responded
   */
  SmsInvitePending = 'SMS_INVITE_PENDING',

  /**
   * There is no mobile phone on record for the client OR
   * they have already opted into SMS notifications
   */
  InviteDisabled = 'INVITE_DISABLED',
}

/**
 * Determine if pro can send a client an appt opt-in SMS.
 *
 * @param {Number} clientId
 * @returns {Promise} resolves with true, false, or null if the client is ineligible.
 */
export async function canSendClientApptOptInSms(
  clientId: number | string,
): Promise<boolean | null | undefined> {
  const settings = await ssFetchJSON<PhoneSettingsResponse>(`/api/v2.1/clients/${clientId}/phone_settings`);

  if (settings.appointment_sms_opted_out) {
    // SMS is disabled, we can't send the invite
    return false;
  } if (settings.appointment_sms_enabled || settings.mobile_phone_number === null) {
    // No need to send the invite, we don't have a number or they've opted in
    return null;
  } if (settings.appointment_sms_requested) {
    // We're not sure if the client has seen, but we sent the request invite.
    return undefined;
  }
  // Fire away
  return true;
}

/**
 * Send the client an SMS to opt-in for appointment notifications.
 *
 * @param {Number} clientId
 * @param {String} source - A string that represents where this was called from.
 *                          (i.e. "new appt modal")
 * @returns {Promise}
 */
export async function sendClientApptSmsRequest(
  clientId: number | string, source: string,
): Promise<PhoneSettingsResponse> {
  return ssFetchJSON(
    `/api/v2.1/clients/${clientId}/sms_request_enable`,
    {
      method: 'POST',
      body: JSON.stringify({
        source: `mweb: ${source}`,
      }),
    },
  );
}

/**
 * Decode SMS status returned from `canSendClientApptOptInSms`
 * @param   {*} response   Can be true, false, undefined or null
 * @returns {Enum}         The SMS permission status
 */
export function getStatus(response: boolean | null | undefined) {
  if (response === false) {
    return ClientSMSStatus.ClientSmsDisabled;
  }

  if (response === undefined) {
    return ClientSMSStatus.SmsInvitePending;
  }

  if (response === null) {
    return ClientSMSStatus.InviteDisabled;
  }

  return ClientSMSStatus.NoSmsPermissionHistory;
}
