// @ts-strict-ignore
import { DeepLinkType } from './constants';
import { DeepLinkResponseData, RouteProps } from './types';
import analytics from '../analytics';
import nonCriticalException from '../exceptionLogger';

/**
   * Parses a string of key-value pairs separated by query string syntax and returns an object.
   *
   * @param paramsString - A string containing key-value pairs separated by query string syntax
   * @returns An object containing the key-value pairs extracted from the input string.
   *
   * @example
   * const paramsString = "key1=value1&key2=value2&key3=value3";
   * const params = mapGenericParams(paramsString);
   * console.log(params);
   * // Output: { key1: 'value1', key2: 'value2', key3: 'value3' }
   */
export const mapGenericParams = (paramsString: string) => {
  const params = {};

  try {
    const searchparams = new URLSearchParams(paramsString);
    return Object.fromEntries(searchparams.entries());
  } catch (e) {
    nonCriticalException(e);
  }

  return params;
};

/**
 * Takes a RouteProps object and an array of arguments and returns an
 * object with the route and its parameters.
 * @param {RouteProps} routeProps - The RouteProps object containing the route and its parameters.
 * @param {any[]} args - The array of arguments to be used as parameters for the route.
 * @returns {{routeName: string, paramKeys: object}} - An object containing the
 * route and its parameters.
 */
export const mapToRouteParams = (
  { routeName, paramKeys }: RouteProps,
  args: any[] = [],
): { route: string; params: object } => {
  const params = paramKeys?.length > 0 ? paramKeys.reduce((result, param, index) => ({
    ...result,
    [param]: args[index],
  }), {}) : undefined;

  return {
    route: routeName,
    params,
  };
};

export const trackDeepLinking = (props: DeepLinkResponseData, user) => {
  const {
    deep_link_value: accessType,
    deep_link_sub1: accessValue,
    deep_link_sub2: accessValue2,
  } = props;

  if (accessType === DeepLinkType.ChatId) {
    if (accessValue2 === 'provider') {
      const providerId = user?.providerId;
      analytics.track('chat_page_from_deeplinking_viewed', {
        provider_id: providerId,
        required_role: accessValue2,
        chat_id: accessValue,
      });
    } else if (accessValue2 === 'client') {
      const userId = user?.userId;
      analytics.track('chat_page_from_deeplinking_viewed', {
        client_user_id: userId,
        required_role: accessValue2,
        chat_id: accessValue,
      });
    }
  }
};
