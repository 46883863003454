import React from 'react';
import ReactDOM from 'react-dom';
import { uniqueId } from 'underscore';

import modalRegistry from './modalRegistry';
import withStore from '../components/withStore';

const CONTAINER_ID = 'portal-element-root';
export const HOST_CLASS = 'modal-host';

/**
 * Manages opening modals pragmatically and maintaining the modal stack.
 *
 * Opening pragmatically
 * ----------------------
 * If you want to open a modal via pure JS (you can also open it via the component & JSX).
 *
 * First, you should define your modal component. Remember to pass `renderContainer` down to
 * the modal:
 * @example
 *   function MyModal(props) {
 *     const { renderContainer } = props;
 *     return (
 *       <Modal
 *         title="My Special Modal"
 *         initialOpen
 *         renderContainer={renderContainer}
 *       >
 *         <p>Hi! I'm a modal.</p>
 *       </Modal>
 *     )
 *   }
 *
 * Now you can open it with modalManager.
 * @example
 *   // Open your modal
 *   this.unmountModal = modalManager.openModal(MyModal, {...props});
 *
 *   // Remember to cleanup in the componentWillUnmount function
 *   this.unmountModal();
 *
 */
class ModalManager {
  /**
   * The stack of all modals currently open.
   */
  modalStack = [];

  /**
   * Simple convenience constant for the throwError parameter.
   */
  THROW_ERROR = true;

  /**
   * Gets the container used by this manager.
   * @returns {DOMNode} The container
   */
  // eslint-disable-next-line class-methods-use-this
  getContainer() {
    let modalContainer = document.getElementById(CONTAINER_ID);
    if (!modalContainer) {
      const modalHostCandidates = document.getElementsByClassName(HOST_CLASS);

      modalContainer = document.createElement('div');
      modalContainer.id = CONTAINER_ID;

      if (modalHostCandidates.length) {
        const [modalHost] = modalHostCandidates;

        modalHost.append(modalContainer);
      } else {
        modalContainer = null;
      }
    }

    return modalContainer;
  }

  /**
   * Open a modal on the page.
   * Don't forget to unmount the modal when you're done.
   *
   * This function will pass the following props to your component:
   *   > renderContainer - The DOM node the modal was rendered into.
   *   > modalOpen - `true` - which can be passed to the <Modal> component's `initialOpen` prop.
   *
   * Component Setup
   * ---------------
   * In order to open a modal with this function, your component must receive the `renderContainer`
   * and pass it to the <Modal> component.
   *
   * Unmount & Cleanup
   * -----------------
   * @example
   *   openMyModal() {
   *     this.unmountModal = modalManager.openModal(MyModal, { ...props });
   *   }
   *   componentWillUnmount() {
   *     this.unmountModal();
   *   }
   *
   * @param {Component|Function} modalClass - React component class
   * @param {Object}  props - The modal's props
   * @param {boolean} throwError - Throw an error if the modal cannot be opened.
   *
   * @returns A Function which can be used to unmount the modal.
   */
  openModal(modalClass, props, throwError = false) {
    const noopFn = () => {};
    const modalContainer = this.getContainer();
    const uuid = uniqueId('modalManager');

    if (!modalClass.propTypes || typeof modalClass.propTypes.renderContainer === 'undefined') {
      console.warn('The modal class is not setup to be opened with this function. (missing `renderContainer` prop in propTypes)');
    }
    if (!modalContainer) {
      const errMsg = 'No container present to put your modal';
      if (throwError) {
        throw new Error(errMsg);
      } else {
        console.error(errMsg);
        return noopFn;
      }
    }

    let renderContainer = document.createElement('div');
    modalContainer.appendChild(renderContainer);
    modalRegistry.registerModal({
      id: uuid,
      inPortalElementRoot: true,
    });

    const unmount = () => {
      this.unmountModal(renderContainer);
      modalRegistry.unregisterModal(uuid);
      renderContainer = null; // free up memory if this function is held around
    };

    const localProps = {
      ...props,
      renderContainer,
      initialOpen: true,
      ref: ref => {
        unmount.modal = ref;
      },
    };

    const modal = React.createElement(withStore(modalClass), localProps);
    ReactDOM.render(modal, renderContainer);

    return unmount;
  }

  /**
   * Unmount a modal from it's renderContainer and then remove the container.
   *
   * @param {Element} renderContainer - The DOM node the modal was rendered into.
   */
  // eslint-disable-next-line class-methods-use-this
  unmountModal(renderContainer) {
    if (!renderContainer) {
      return;
    }
    ReactDOM.unmountComponentAtNode(renderContainer);
    const modalContainer = renderContainer.parentNode;
    if (modalContainer) {
      modalContainer.removeChild(renderContainer);
      const event = new CustomEvent('unmountModal', { renderContainer });
      modalContainer.dispatchEvent(event);
    }
  }

  /**
   * Make sure that the first modal on the stack is the only one visible
   */
  updateModalVisibility() {
    if (!this.modalStack.length) {
      return;
    }

    const top = this.modalStack[0];
    if (!top.isVisible()) {
      top.unhide();
    }
    this.modalStack.slice(1).forEach(modal => {
      if (modal.isVisible()) {
        modal.hide();
      }
    });
  }

  /**
   * Add this modal to the modal stack
   *
   * @param {Modal} modalInst - An instance of the Modal component.
   */
  modalOpened(modalInst) {
    // Add to stack if this modal instance isn't already in it.
    if (this.modalStack.indexOf(modalInst) < 0) {
      this.modalStack.unshift(modalInst);
      this.updateModalVisibility();
    } else {
      console.warn('This modal is already on the stack', modalInst);
    }
  }

  /**
   * Remove this modal from the modal stack.
   *
   * @param {Modal} modalInst - An instance of the Modal component.
   */
  modalClosed(modalInst) {
    const idx = this.modalStack.indexOf(modalInst);
    if (idx > -1) {
      this.modalStack.splice(idx, 1);
      this.updateModalVisibility();
    }
  }
}

/** @deprecated - Use the Portal Overlay pattern instead */
export default new ModalManager();
