import { createModel } from '@rematch/core';
import type { RootModel } from '../models';
import { NCDExperience, ProNCDExperienceReason } from '../../modules/provider/Appointments/NCD';

export interface ProNCDExperienceEligibilityState {
  experience: NCDExperience;
  loaded: boolean;
  reason: ProNCDExperienceReason | null;
}

interface OnQualificiationCheckCompletedPayload {
  experience: NCDExperience;
  reason: ProNCDExperienceReason | null;
}

export const getDefaultState = (): ProNCDExperienceEligibilityState => ({
  experience: NCDExperience.Version1,
  loaded: false,
  reason: null,
});

/** Redux store for handling NCDC Eligibility for a Pro */
export const proNCDExperienceEligibility = createModel<RootModel>()({
  name: 'proNCDExperienceEligibility',
  state: getDefaultState(),
  reducers: {
    onQualificationCheckCompleted(
      state: ProNCDExperienceEligibilityState,
      { experience, reason = null }: OnQualificiationCheckCompletedPayload,
    ): ProNCDExperienceEligibilityState {
      return ({
        ...state,
        loaded: true,
        experience,
        reason,
      });
    },
    onReset: (): ProNCDExperienceEligibilityState => getDefaultState(),

    'user/logout': () => getDefaultState(),
  },
  effects: {},
  selectors: slice => ({
    experience() {
      return slice((state: ProNCDExperienceEligibilityState) => state.experience);
    },
    isLoading() {
      return slice((state: ProNCDExperienceEligibilityState) => !state.loaded);
    },
  }),
});
