// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import { chunk } from 'underscore';
import { mergeClients as apiMergeClients } from '../../api/Providers/MergeClients';
import {
  updateClient as apiUpdateClient,
  getClient,
  getAllClients,
  deleteClient,
  clearAllClientsCache,
  createMultipleClients as apiCreateMultipleClients,
  blockClient,
  unblockClient,
} from '../../api/Providers/Clients';
import type { IProviderClient } from '../../api/Providers/Clients/types';
import type { RootModel } from '../models';
import { PartialProviderClient } from '../../modules/provider/Client';
import {
  canSendClientApptOptInSms,
  ClientSMSStatus,
  getStatus as getSMSStatus,
} from '../../modules/api/clients/sms';
import { ReminderType } from '../../api/Providers/Appointments';
import nonCriticalException from '../../modules/exceptionLogger';

const parallelPagesToRequests = 2;
let finishedInitialLoad: boolean = false;

export type ProviderClientsState = {
  providerId: number | null;
  loading: boolean;
  allClientsLoading: boolean;
  clients: Record<number, IProviderClient>;
  clientSmsInviteStatus: Record<number, ClientSMSStatus>;
};

type ProviderAndClientId = {
  providerId: number | string;
  clientId: number;
};

type LoadAllPayload = {
  providerId: number | string;
};

type LoadClientPayload = ProviderAndClientId;

type RemoveClientPayload = ProviderAndClientId;

type BlockClientPayload = ProviderAndClientId;

type UnblockClientPayload = ProviderAndClientId;

type MergeClientPayload = {
  providerId: number | string;
  clientIdA: number;
  clientIdB: number;
};

type UpdateClientPayload = {
  providerId: number | string;
  client: PartialProviderClient & Pick<IProviderClient, 'id'>;
};

type AddMultipleClientsPayload = {
  providerId: number | string;
  clients: PartialProviderClient[];
};

type UpdateReminderPreferencePayload = {
  providerId: number | string;
  clientId: number;
  reminderPreference?: ReminderType;
};

type OnClientLoadedPayload = {
  providerId: number | string;
  client: IProviderClient;
};

type OnSmsStatusLoadedPayload = {
  providerId: number | string;
  clientId: number | string;
  status: ClientSMSStatus;
};

type OnAllClientsLoadedPayload = {
  providerId: number | string;
};

type OnClientsPageLoadedPayload = {
  clients: Array<IProviderClient>;
};

type OnClientBlockUpdatedPayload = {
  clientId: number;
  blocked: boolean;
};

/**
 *
 * @param dispatch The dispatch function
 * @param providerId Provider ID
 * @returns promise without value as values are dispatched
 * Note: the disabled linting is on purpose as is the behavior we want
 * Context: https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it
 */
async function fetchAllClientsByBatches(
  dispatch,
  providerId: number,
): Promise<void> {
  // First fetch the first page to get the number of pages to fetch later async
  const { results: clients, num_pages } = await getAllClients(
    providerId,
  );

  await dispatch.providerClients.onClientsPageLoaded({
    clients,
  });

  if (num_pages === 1) {
    await dispatch.providerClients.onAllClientsLoaded({
      providerId,
    });
    return;
  }

  const pendingPages = num_pages - 1;

  // We already now the number of pages we will fetch. We create the list of pages so we can
  // fetch the pages in parallel from parallelPagesToRequests to the last page
  const pagesToFetch = Array.from(
    { length: pendingPages },
    (_, i) => i + parallelPagesToRequests,
  );

  // Idea is the same as fetchAllClients. Every to pages we dispatch the results
  // By doing this we are able to sequentially dispatch the results and avoid memory issues
  const chunkPages = chunk(pagesToFetch, parallelPagesToRequests);

  // eslint-disable-next-line no-restricted-syntax
  for (const promises of chunkPages) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const promisesResults = await Promise.all(
        promises.map(page => getAllClients(
          providerId,
          { queryParams: { page } },
        )),
      );
      const clientsToDispatch = promisesResults.reduce((acc, { results }) => [
        ...acc,
        ...results,
      ], []);
      dispatch.providerClients.onClientsPageLoaded({
        clients: clientsToDispatch,
      });
    } catch (error) {
      nonCriticalException(error);
    }
  }

  await dispatch.providerClients.onAllClientsLoaded({
    providerId,
  });
}

function getInitialState(): ProviderClientsState {
  return {
    allClientsLoading: false,
    loading: false,
    providerId: null,
    clients: {},
    clientSmsInviteStatus: {},
  };
}

export const providerClients = createModel<RootModel>()({
  name: 'providerClients',

  state: getInitialState(),

  reducers: {
    'user/logout': () => getInitialState(),

    onClientLoading: (state: ProviderClientsState): ProviderClientsState => ({
      ...state,
      loading: true,
    }),

    onClientLoaded: produce<ProviderClientsState, [OnClientLoadedPayload]>((
      state: ProviderClientsState,
      payload: OnClientLoadedPayload,
    ) => {
      const providerId = Number(payload.providerId);

      if (providerId === state.providerId) {
        state.clients[payload.client.id] = payload.client;
      } else {
        state.providerId = providerId;
        state.clients = { [payload.client.id]: payload.client };
      }

      state.loading = false;
    }),

    onSMSStatusLoaded: produce<ProviderClientsState, [OnSmsStatusLoadedPayload]>((
      state: ProviderClientsState,
      payload: OnSmsStatusLoadedPayload,
    ) => {
      const providerId = Number(payload.providerId);

      if (providerId === state.providerId) {
        state.clientSmsInviteStatus[payload.clientId] = payload.status;
      } else {
        state.providerId = providerId;
        state.clientSmsInviteStatus = { [Number(payload.clientId)]: payload.status };
      }

      state.loading = false;
    }),

    onClientsPageLoaded: (
      state: ProviderClientsState,
      payload: OnClientsPageLoadedPayload,
    ): ProviderClientsState => {
      const clientMap: Record<number, IProviderClient> = { ...(state.clients || {}) };

      payload.clients
        .filter(client => client.provider === state.providerId)
        .forEach(client => {
          clientMap[client.id] = client;
        });

      return {
        ...state,
        clients: clientMap,
      };
    },

    onAllClientsLoading: (
      state: ProviderClientsState,
      payload: LoadAllPayload,
    ): ProviderClientsState => ({
      ...state,
      providerId: Number(payload.providerId),
      allClientsLoading: true,
    }),

    onAllClientsLoaded: (
      state: ProviderClientsState,
      payload: OnAllClientsLoadedPayload,
    ): ProviderClientsState => {
      finishedInitialLoad = true;

      return ({
        ...state,
        allClientsLoading: false,
        providerId: Number(payload.providerId),
        // since each page has already added the clients to the store
        // we don't need to do it here
        clients: state.clients,
        clientSmsInviteStatus: {},
      });
    },

    onClientBlockUpdated: produce<ProviderClientsState, [OnClientBlockUpdatedPayload]>((
      state: ProviderClientsState,
      payload: OnClientBlockUpdatedPayload,
    ) => {
      state.clients[payload.clientId].blocked = payload.blocked;
    }),

    onClientRemoved: produce<ProviderClientsState, [RemoveClientPayload]>((
      state: ProviderClientsState,
      payload: RemoveClientPayload,
    ) => {
      delete state.clients[payload.clientId];
    }),

    onReloading: (): ProviderClientsState => {
      finishedInitialLoad = false;

      return ({ ...getInitialState() });
    },
  },

  effects: dispatch => ({
    async loadAll({
      providerId,
    }: LoadAllPayload, rootState): Promise<ProviderClientsState['clients']> {
      if (
        !finishedInitialLoad
        || providerId !== rootState.providerClients.providerId
        || !Object.keys(rootState.providerClients.clients).length
      ) {
        // if the provider ID is different OR there are no clients in the list, refresh from DB
        dispatch.providerClients.onAllClientsLoading({ providerId });

        // Without await is on purpose as we don't care when it finishes
        fetchAllClientsByBatches(
          dispatch,
          Number(providerId),
        );
      }

      return rootState.providerClients.clients;
    },

    async reloadAll({ providerId }: LoadAllPayload) {
      dispatch.providerClients.onReloading();
      clearAllClientsCache();
      await dispatch.providerClients.loadAll({ providerId });
    },

    async loadClient({ providerId, clientId }: LoadClientPayload) {
      dispatch.providerClients.onClientLoading();

      let client: IProviderClient;

      try {
        client = await getClient(providerId, clientId);

        dispatch.providerClients.onClientLoaded({
          providerId,
          client,
        });
      } catch (err) {
        if (err?.code === 404) {
          // If the response code is 404, remove this client and continue with the error pipeline
          dispatch.providerClients.onClientRemoved({ providerId, clientId });
        }

        throw err;
      }

      return client;
    },

    async loadClientSMSStatus(
      { providerId, clientId }: LoadClientPayload,
      rootState,
    ): Promise<ClientSMSStatus> {
      let client: IProviderClient = rootState.providerClients.clients[clientId];

      if (!client) {
        client = await getClient(providerId, clientId);
        dispatch.providerClients.onClientLoaded({ providerId, client });
      }

      dispatch.providerClients.onClientLoading();

      const status = getSMSStatus(await canSendClientApptOptInSms(clientId));

      dispatch.providerClients.onSMSStatusLoaded({
        providerId,
        clientId,
        status,
      });

      return status;
    },

    async updateReminderPreference(
      {
        clientId,
        reminderPreference,
        providerId,
      }: UpdateReminderPreferencePayload,
      rootState,
    ): Promise<void> {
      const newPreference: ReminderType | null = reminderPreference || null;
      const oldPreference = rootState.providerClients.clients[clientId].reminder_preference;
      if (oldPreference !== undefined && (oldPreference !== newPreference)) {
        await dispatch.providerClients.updateClient({
          providerId,
          client: {
            id: clientId,
            reminder_preference: newPreference,
          },
        });

        if (
          clientId
          && (
            reminderPreference === ReminderType.SmsEmailReminder
            || reminderPreference === ReminderType.SmsOnlyReminder
          )
        ) {
          dispatch.providerClients.loadClientSMSStatus({ providerId, clientId });
        }
      }
    },

    async removeClient({ providerId, clientId }: RemoveClientPayload) {
      await deleteClient(Number(providerId), clientId);

      dispatch.providerClients.onClientRemoved({ providerId, clientId });
    },

    /**
     * Takes two client IDs and merges them, yielding the ID of the resulting client
     */
    async mergeClients({
      providerId, clientIdA, clientIdB,
    }: MergeClientPayload): Promise<number> {
      const result = await apiMergeClients(Number(providerId), clientIdA, clientIdB);

      await Promise.all([
        dispatch.providerClients.onClientRemoved({ providerId, clientId: clientIdA }),
        dispatch.providerClients.onClientRemoved({ providerId, clientId: clientIdB }),
        dispatch.providerClients.loadClient({ providerId, clientId: result.new_client }),
      ]);

      return result.new_client;
    },

    async updateClient({ providerId, client }: UpdateClientPayload): Promise<IProviderClient> {
      const clientPayload = { phones: [], ...client };

      const result = await apiUpdateClient(providerId, clientPayload);

      dispatch.providerClients.onClientLoaded({ providerId, client: result });

      return result;
    },

    async blockClient({ providerId, clientId }: BlockClientPayload): Promise<void> {
      await blockClient(providerId, clientId);

      dispatch.providerClients.onClientBlockUpdated({ clientId, blocked: true });
    },

    async unblockClient({ providerId, clientId }: UnblockClientPayload): Promise<void> {
      await unblockClient(providerId, clientId);

      dispatch.providerClients.onClientBlockUpdated({ clientId, blocked: false });
    },

    async createMultipleClients(
      { providerId, clients }: AddMultipleClientsPayload,
    ): Promise<Array<IProviderClient>> {
      const resultClients = await apiCreateMultipleClients(
        Number(providerId),
        clients,
      );

      dispatch.providerClients.onClientsPageLoaded({
        clients: resultClients,
      });

      return resultClients;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    client: hasProps((_, clientId: number) => (
      createSelector(
        slice,
        (state: ProviderClientsState) => state.clients[clientId],
      )
    )),

    clientSMSStatus: hasProps((_, clientId: number | undefined) => (
      createSelector(
        slice,
        (state: ProviderClientsState): ClientSMSStatus => (
          clientId ? (state.clientSmsInviteStatus[clientId]
            || ClientSMSStatus.NoSmsPermissionHistory) : ClientSMSStatus.NoSmsPermissionHistory
        ),
      )
    )),

    sortedClients: () => (
      createSelector(
        slice((state: ProviderClientsState) => state.clients),
        slice((state: ProviderClientsState) => state.providerId),
        (clients: ProviderClientsState['clients'], providerId): IProviderClient[] => (
          // default sort is ASCII ascending, which should be sufficient
          Object.values(clients).filter(client => client.provider === providerId
            && !client.is_unconfirmed_ncd).sort()
        ),
      )
    ),

    clientDeleted: hasProps((_, clientId: number) => (
      createSelector(
        slice,
        (state: ProviderClientsState): boolean => !state.loading && !state.clients[clientId],
      )
    )),
  }),
});
