import React from 'react';
import {
  bool,
  func,
  string,
  node,
  any,
} from 'prop-types';
import classNames from 'classnames';

import './Button.scss';

/**
 * Web implementation of button, which wraps the DOM `button` tag
 */
const Button = React.forwardRef(({
  disabled,
  onClick,
  size,
  appearance,
  loading,
  className,
  title,
  ariaLabel,
  active,
  children,
  testID,
  ...restProps
}, ref) => {
  const allClassNames = classNames(
    'ss-button',
    size,
    appearance,
    className,
    { active },
    { 'is-loading': loading },
  );

  return (
    <button
      type="button"
      ref={ref}
      className={allClassNames}
      disabled={disabled}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel}
      data-testid={testID}
      {...restProps}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  disabled: bool,
  onClick: func,
  theme: string,
  size: string,
  appearance: string,
  loading: bool,
  className: string,
  title: string,
  ariaLabel: string,
  type: string,
  active: bool,
  children: node,
  testID: string,
  style: any,
};

Button.defaultProps = {
  disabled: false,
  onClick: null,
  theme: null,
  size: null,
  appearance: null,
  loading: false,
  className: null,
  title: null,
  ariaLabel: null,
  type: 'button',
  active: false,
  children: null,
  testID: null,
  style: null,
};

export default Button;
