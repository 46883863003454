import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { IRootDispatch, RootState } from '../store/models';
import { useDeepSelector } from './useDeepSelector';

/**
 * @name useUserStateRedux
 * @description This is the new user state manager hook that uses the redux store
 *
 * @param {string} key - User State Key
 *
 * @returns {Array} - An array of the current user state, the user state update function,
 * and the user state loaded status
 */
const useUserStateRedux = <T = any>(
  key: string,
): [
    value: T | undefined,
    setter: (newValue: T) => void,
    loaded?: boolean,
  ] => {
  const { userState } = useDispatch<IRootDispatch>();
  const loaded = useSelector((state: RootState) => state.userState.loaded);
  const value = useDeepSelector<RootState, undefined | T>(
    (state: RootState) => state.userState.values[key],
  );

  const updateValue = useCallback((newValue: T): void => {
    userState.addValues({
      values: { [key]: newValue },
      preferLocalKeys: [key],
    });
  }, [
    key,
    userState,
  ]);

  return [
    value,
    updateValue,
    loaded,
  ];
};

export default useUserStateRedux;
