import { MomentInput } from 'moment';
import StateManageInstance from '../../../user/stateManager';
import { MARKETING_HUB_NOTIFICATION_ACTION } from '../../MarketingHub';

export interface MarketingHubNotification {
  creation_time: MomentInput;
  persistent: true;
  providerId: number;
  type: 'marketing_hub_announcement';
  uuid: 'marketing-hub-announcement-notification';
}

export const shouldShowMarketingHubNotification = async (profileCreationTime: string) => {
  const launchDate = new Date('17 Sept 2021 00:00:00 PST');
  const isEligibleBasedOnDate = (
    new Date(profileCreationTime) < launchDate
    && new Date().getDate() < (new Date().setDate(launchDate.getDate() + 30))
  );
  if (!isEligibleBasedOnDate) return false;

  const seenNotification = await StateManageInstance.getActionValue(
    MARKETING_HUB_NOTIFICATION_ACTION,
  );
  return !seenNotification;
};

export const createMarketingHubNotification = (
  providerId: number,
): MarketingHubNotification => ({
  creation_time: new Date(),
  persistent: true,
  providerId,
  type: 'marketing_hub_announcement',
  uuid: 'marketing-hub-announcement-notification',
});
