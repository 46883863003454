// @ts-strict-ignore
import BaseTracker, { PlatformProperty } from './BaseTracker';
import { AnalyticsUserData } from '../types';

export const whitelist = [
  'CL Completed',
  'client_appointment_attributed_newclient_booked',
  'client_appointment_booked',
  'client_appointment_ncd_booked',
  'client_appointment_newclient_booked',
  'client_proprofile_viewed',
  'CS Completed',
  'landing',
  'P Viewed Signup Sell Page',
  'pro_appointment_booked',
  'pro_join_page_viewed',
  'pro_payments_onboarding_completed',
  'pro_profile_completed',
  'PS Completed',
  'search',
];
export const FIREBASE_EVENT_ALIASES = {
  'PS Completed': 'ps_completed',
  client_appointment_attributed_newclient_booked: 'client_appt_attributed_newclient_booked',
};

export function getCordovaAnalytics() {
  return (window as any).cordova?.plugins?.firebase?.analytics;
}

export class FirebaseTracker extends BaseTracker {
  constructor(env: string, platform: PlatformProperty, isApp: boolean) {
    super('Firebase', 'Tracker (Firebase)', env, platform, isApp);
  }

  updateUser(userData: AnalyticsUserData) {
    if (this.env === 'production' && this.isApp) {
      const cordovaAnalytics = getCordovaAnalytics();

      if (userData) {
        cordovaAnalytics.setUserId(userData.is_logged_in ? userData.user_id : null);
        this.debugLog('setUserId', userData.is_logged_in ? userData.user_id : null);
        cordovaAnalytics.setUserProperty('email', userData.email);
        cordovaAnalytics.setUserProperty('is_anon', String(!userData.is_logged_in));
        cordovaAnalytics.setUserProperty('cookie_id', String(userData.ss_tracking_cookie));

        if (userData.provider_id) {
          cordovaAnalytics.setUserProperty('provider_id', String(userData.provider_id));
          this.debugLog('setUserProperty', 'provider_id');
        }
      } else {
        cordovaAnalytics.setUserId(null);
        this.debugLog('setUserId', null);
      }
    } else {
      this.debugLog('updateUser', `env: ${this.env}, isApp: ${this.isApp}`);
    }
  }

  /**
   * Performs the actual tracking call.
   * @param {String} eventName The name of the event to track
   * @param properties
   * @returns {Promise} Resolves when the event has been logged.
   * */
  async trackEvent(eventName: string, properties: { [key: string]: any }) {
    let firebaseEventName = null;
    if (whitelist.includes(eventName)) {
      // ps_completed and client_appt_attributed_newclient_booked are for firebase only
      if (eventName in FIREBASE_EVENT_ALIASES) {
        firebaseEventName = FIREBASE_EVENT_ALIASES[eventName];
      } else {
        firebaseEventName = eventName;
      }
      this.debugLog(`logEvent (is app: ${this.isApp}, env: ${this.env})`, firebaseEventName, properties);
      if (this.isApp && this.env === 'production') {
        const cordovaAnalytics = getCordovaAnalytics();
        cordovaAnalytics.logEvent(firebaseEventName, properties);
        this.debugLog('trackEvent', firebaseEventName, properties);
      } else {
        this.debugLog('trackEvent', `${firebaseEventName} not sent, env: ${this.env}, isApp: ${this.isApp}`);
      }
    }
  }
}
