// @ts-strict-ignore
import type { View } from 'react-native';
import React from 'react';
import { flattenChildren } from '../../utils/modules';
import { Box, IBoxProps } from '../Box';
import type { PaddingSize } from '../styles.types';

export interface InlineProps extends Omit<IBoxProps, 'flexDirection'> {
  space?: PaddingSize;
  key?: string | number;
}

/**
 * Component which implements much of the Inline API here https://seek-oss.github.io/braid-design-system/components/Inline
 *
 * Since this component adds elements for spacing, there may be issues with using percentage-based
 * width layouts (using either width or flex-basis). Note that you can accomplish percentage-based
 * width using flex-grow on children instead. See the storybook for an example.
 */
export const Inline = React.forwardRef<View, InlineProps>(({
  children,
  key,
  space,
  className = '',
  ...rest
}, ref) => {
  // Flatten provided React children in case there are nested fragments.
  // https://stackblitz.com/edit/react-stack-children-flatten?file=App.tsx
  const childrenFlattened = flattenChildren(children).filter(child => React.isValidElement(child));

  return (
    <Box
      {...rest}
      flexDirection="row"
      className={className}
      ref={ref}
    >
      {React.Children.map(childrenFlattened, (child, index) => (
        <React.Fragment key={key}>
          {child}
          {index !== childrenFlattened.length - 1 && !!space && (
            <Box paddingRight={space} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
});
