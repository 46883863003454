// @ts-strict-ignore
import { OKResponse } from '../types';
import { API_ROOT } from '../../config';
import SimpleCache from '../../modules/SimpleCache';
import ssFetch, { ssFetchJSON } from '../../modules/ssFetch';
import { getStripe } from '../../modules/stripeV3';

import { clearCache as clearSignupSettingsCache } from './SignupSettings';

const BankAccountCache = new SimpleCache(60 * 15); // 15 minutes

export function clearCache() {
  BankAccountCache.clear();
}

export enum AccountType {
  Company = 'Company',
  Individual = 'Individual',
}

export enum BankAccountStatus {
  Error = 0,
  New = 2,
}

/* See BankAccountSerializer */
export interface BankAccountResponse {
  delay_days: number;
  id: number;
  last4: '' | `${number}${number}${number}${number}`;
  last_transfer_failed: boolean;
  status: BankAccountStatus;
  transfer_schedule: 'manual' | 'daily' | 'weekly' | 'monthly';
}

export interface BankAccountPayload {
  routingNumber: string;
  accountNumber: string;
  accountHolderName: string;
  accountType: AccountType;
}

export async function addStripeAccount(
  providerId: number,
  tokens: string[],
): Promise<OKResponse> {
  const url = new URL(
    `/api/v1/providers/${providerId}/bank-accounts.json`,
    API_ROOT,
  );
  clearCache();
  clearSignupSettingsCache();
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    body: tokens,
    includeResponseOnError: true,
  });
}

const buildStripeRequestParams = (
  account: BankAccountPayload,
): stripe.BankAccountTokenOptions => {
  const params = {
    country: 'US',
    currency: 'USD',
    routing_number: account.routingNumber,
    account_number: account.accountNumber,
    account_holder_name: account.accountHolderName,
    account_holder_type: account.accountType,
  };

  return params;
};

/**
 * Adds a bank account instrument for the provider
 */
export async function getStripeTokens(
  account: BankAccountPayload,
) {
  const stripe = await getStripe();
  const params = buildStripeRequestParams(account);

  // we need two tokens from stripe, one for creating bank account
  // & another to create a user account
  const responses = await Promise.all([
    stripe.createToken('bank_account', params),
    stripe.createToken('bank_account', params),
  ]);
  const tokens = responses.map(response => response.token);

  return tokens;
}

export async function fetchBankAccount(
  providerId: number,
) {
  const url = new URL(
    `/api/v1/providers/${providerId}/bank-accounts.json`,
    API_ROOT,
  );

  const bankAccount = await ssFetchJSON<BankAccountResponse>(
    url.toString(),
    {
      includeResponseOnError: true,
    },
  );
  if (!bankAccount.last4) {
    // if there is no stripe bank account, we'll get a {last4: ""} object
    throw new Error('No bank account number.');
  }
  return bankAccount;
}

export async function enableTransfer(
  providerId: number,
) {
  const url = new URL(
    `/api/v1/providers/${providerId}/enable-transfer`,
    API_ROOT,
  );

  // The patch requires no extra data and also returns no data (only status codes)
  return ssFetch(url.toString(), {
    method: 'PATCH',
    body: {},
  });
}
