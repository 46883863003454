// @ts-strict-ignore
import { useState, useEffect } from 'react';

export type useElementGeometryReturn = {
  width: number;
  height: number;
  left: number;
  top: number;
};

/**
 * A hook for tracking element geometry (x, y, width, height).
 *
 * @param ref mutable reference to the DOM object that we are measuring.
 * @param cb callback to capture element geometry.
 * @param deps optional array of dependency values.
 */
const useElementGeometry = (
  ref: React.MutableRefObject<any>,
): useElementGeometryReturn => {
  const [geometry, setGeometry] = useState(null);

  useEffect(() => {
    const element = ref.current || null;
    if (!element) return;
    element.measureInWindow((left: number, top: number, width: number, height: number) => {
      setGeometry((current: useElementGeometryReturn): useElementGeometryReturn => {
        if (current) return current;
        return {
          left,
          top,
          width,
          height,
        };
      });
    });
  });

  return geometry;
};

export default useElementGeometry;
