// @ts-strict-ignore
import moment from 'moment';
import URI from 'urijs';
import { API_ROOT } from '../../config';
import ssFetch from '../ssFetch';
import { SmartPriceSource } from '../../components/provider/smartPricing/constants';

type LoadAvailabilityOptions = {
  /** The date to start looking at availability from. */
  startDate?: Date | string;
  /** The number of days of availability you want returned. */
  days?: number;
  /** The cache value to use for this ssFetch request. */
  cache?: number;
  /** If we should apply smart price from the old model vs the new model */
  smartPriceSource?: number;
};

type IdValue = number | string;

interface IBookableSlots {
  direct: number[];
  online: number[];
  smart_prices?: {
    id: string;
    service_upcharge_cap: string;
    service_upcharge: string;
  }[];
}

export interface IAvailabilityResult {
  date: string;
  bookable_slots: IBookableSlots;
}

export interface ILoadAvailabilityResult {
  results: IAvailabilityResult[];
  quantum: 15;
  next: {
    start_date: string;
    url: string;
  } | null;
  available_days: IAvailabilityResult[];
}

/**
 * Fetches a pro's availability for a list of services.
 * This returns the standard API result set (results, next, quantum, time_zone),
 * as well as a property (available_days) that lists only the days with availability.
 *
 * ```json
 *   {
  *     results: [
  *       {
  *         date: "2017-03-29",
  *         bookable_slots: {
  *           direct: [],
  *           online: [36, 37, 38, 39]
  *         }
  *       },
  *       { ... },
  *       { ... }
  *     ],
  *     quantum: 15,
  *     next: {
  *       start_date: "2017-04-12",
  *       url: "/api/v2/providers/..."
  *     },
  *
  *     available_days: [
  *       { ... },
  *       { ... },
  *       { ... }
  *     ]
  *   }
  * ```
  *
  *
  * @param {number} providerId
  * @param {Array} services - A list of services that will make up the block of
  *                           time you're checking for.
  * @param {Object} options - Additional options for this request}
  * @param {Date}   options.startDate The date to start looking at availability from.
  * @param {number} options.days      The number of days of availability you want returned.
  * @param {number} options.cache     The cache value to use for this ssFetch request.
  * @param {number} options.smartPriceSource - if we should apply the new model (time_to_appt)
  *                                            or the old model
  */
export default function loadAvailability(
  providerId: IdValue,
  services: { id: IdValue }[],
  options: LoadAvailabilityOptions = {},
): Promise<ILoadAvailabilityResult> {
  const opts = {
    // Default options
    startDate: new Date(),
    days: 5,
    cache: 120, // 2 minutes
    ...options,
  };

  const serviceIds = services.map(service => service.id);
  const dateStr = moment(opts.startDate).format('YYYY-MM-DD');
  const uri = URI(`${API_ROOT}/api/v2/providers/${providerId}/schedules/availability`).search({
    days: opts.days,
    start_date: dateStr,
    service_ids: serviceIds,
    smart_price_source: opts.smartPriceSource || SmartPriceSource.Model,
  });

  return ssFetch(uri.toString(), { ssCache: opts.cache })
    .then(response => response.json())
    .then(json => ({
      ...json,
      // Get a list of dates with availability
      available_days: (json.results || []).filter((result: IAvailabilityResult) => (
        result.bookable_slots.online.length > 0
      )),
    }));
}
