export const KLARNA_ANNOUNCEMENT_FLAG = 'pro_klarnaannouncement_ev3653_20220120';
export const KLARNA_ANNOUNCEMENT_KEY = 'pro_klarnaannouncement_seen_ev3653';
export const MAX_ANNOUNCEMENT_SEEN = 3;
export const MIN_ANNOUNCEMENT_INTERVAL = 24; // hours
export const KLARNA_MINIMUM_SERVICE_COST = 40;
export const KLARNA_MAX_DAYS = 28;
export const KLARNA_MAX_COST = 1000;
export enum KlarnaAnalyticEvents {
  Viewed = 'klarna__viewed',
  Clicked = 'klarna__clicked',
  Selected = 'klarna__selected',
  Approved = 'klarna__approved',
  NotApproved = 'klarna__not-approved',
  ProAnnouncementViewed = 'pro_klarnaintro__viewed',
  ProAnnouncementLinkClicked = 'pro_klarnaintrolink__clicked',
  ProAnnouncementClosed = 'pro_klarnaintro__closed',
}
export const KLARNA_ERROR_NOT_EDITABLE = 'This appointment is booked and will be paid through Klarna. It cannot be edited.';
export const KLARNA_ERROR_RESCHEDULE_FAILED = 'The appointment could not be rescheduled.';
