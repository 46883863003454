import { ssFetchJSON } from '../../modules/ssFetch';

type ClientMergeResult = {
  new_client: number;
  client1: number;
  client2: number;
};
export async function mergeClients(
  providerId: number,
  clientIdA: number,
  clientIdB: number,
): Promise<ClientMergeResult> {
  return ssFetchJSON<ClientMergeResult>(
    `/api/v2/providers/${providerId}/merge-clients`,
    {
      method: 'POST',
      body: {
        client1: clientIdA,
        client2: clientIdB,
      },
      throwOnHttpError: true,
    },
  );
}
