import React from 'react';
import PropTypes from 'prop-types';
import nonCriticalException from '../modules/exceptionLogger';
import { isPreStage } from '../modules/env';

/**
 * A generic error boundary which will report any errors generated by
 * child components to newrelic and not output those components.
 *
 * @example
 *   <SSErrorBoundary>
 *     <MyComponent />
 *   </SSErrorBoundary>
 *
 * More info about Error Boundaries:
 * https://reactjs.org/docs/error-boundaries.html
 */
export default class SSErrorBoundary extends React.Component {
  static propTypes = {
    /** Additional data sent with the newrelic log. */
    info: PropTypes.any,

    children: PropTypes.any,
    renderFallback: PropTypes.func,
  };

  static defaultProps = {
    info: null,
    children: null,
    renderFallback: null,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error, boundaryInfo) {
    const { info } = this.props;
    nonCriticalException(error, {
      extra: {
        info,
        boundaryInfo,
      },
      tags: {
        source: 'SSErrorBoundary',
      },
    });

    // eslint-disable-next-line no-console
    if (console.error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  render() {
    const { hasError } = this.state;
    const { renderFallback } = this.props;

    if (hasError) {
      if (renderFallback) {
        return renderFallback();
      }
      if (isPreStage()) {
        return (
          <div>
            Oops. There is an error in a downstream React component 😢
            Check the console for more details about this error boundary.
          </div>
        );
      }
      return null;
    }
    return this.props.children;
  }
}
