// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import { StoreSelectors } from '@rematch/select';
import { Modify } from '../../../utils/UtilityTypes';
import type { RootModel, RootState } from '../../../store/models';
import { SalonProfilePageData, loadSalonProfileViewData } from '../../../modules/salon/Salon';
import { RatingsSummary } from '../../../store/RatingsSummary.model';

export type SalonProfileLoadingState = Modify<SalonProfileLoadedState, {
  loading: true;
}>;

export type SalonProfileErrorState = Modify<SalonProfileLoadedState, {
  error: true;
  errorMessage?: string;
  loading: false;
}>;

export type SalonProfileLoadedState = SalonProfilePageData & {
  error: false;
  errorMessage?: string | undefined;
  loading: false;
};

export type SalonProfileEntry =
  SalonProfileLoadingState | SalonProfileErrorState | SalonProfileLoadedState;

export type SalonProfileData = SalonProfileEntry & {
  ratingsSummary: RatingsSummary;
};

type State = {
  [id: number]: SalonProfileEntry;
};

/**
 * This model represents the provider profile page/feature
 * NOT the specifically the backend providers/profile API
 */
const model = createModel<RootModel>()({
  name: 'salonProfile',

  state: {} as State,

  reducers: {
    onLoading: produce<State, [{ salonId: number }]>((
      state: State,
      payload: { salonId: number },
    ) => {
      const { salonId } = payload;
      state[salonId] = {
        loading: true,
        profile: undefined,
        error: undefined,
      };
    }),

    onLoaded: produce<State, [SalonProfilePageData]>((
      state: State,
      payload: SalonProfilePageData,
    ) => {
      const { id } = payload.profile;
      state[id] = {
        ...payload,
        loading: false,
        error: false,
      };
    }),

    onFailed: produce<State, [{ salonId: number | string; errorMessage?: string }]>((
      state: State,
      payload: { salonId: number | string; errorMessage?: string },
    ) => {
      const {
        salonId,
        errorMessage,
      } = payload;
      state[salonId] = {
        error: true,
        errorMessage,
        profile: undefined,
      };
    }),
  },

  effects: dispatch => ({
    land: async (
      payload: { salonId: number; skipCache?: boolean },
      rootState: RootState,
    ): Promise<SalonProfilePageData | undefined> => {
      const existingProData: SalonProfileEntry = rootState.salonProfile[payload.salonId];

      // if the pro data is already loaded and no error occurred
      // we don't need to load it again
      if (!payload.skipCache && existingProData?.profile && existingProData?.error === false) {
        return existingProData;
      }

      if (Number.isNaN(payload.salonId)) {
        dispatch.salonProfile.onFailed({
          salonId: payload.salonId,
          errorMessage: 'Salon id is not a number',
        });
        return undefined;
      }

      // otherwise load (or reload if it errored before) the data
      try {
        const salonData = await loadSalonProfileViewData(
          payload.salonId,
          rootState.route?.name,
        );
        const salonId = salonData.profile.id;
        if (!salonData.profile.is_disabled) {
          dispatch.ratingsSummary.loadSalon({
            salonId,
          });
        }
        dispatch.salonProfile.onLoaded(salonData);
        return salonData;
      } catch (e) {
        dispatch.salonProfile.onFailed({
          salonId: payload.salonId,
          errorMessage: e.message,
        });
      } finally {
        dispatch.loader.setIsLoading(false);
      }
      return undefined;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    forSalon: hasProps((_, props: { salonId: number }) => createSelector(
      slice,
      (salons): SalonProfileEntry => salons[props.salonId],
    )),

    getCompleteProfile: hasProps((
      models: StoreSelectors<RootModel, Record<string, never>>,
      props: { salonId: number },
    ) => createSelector(
      slice,
      models.ratingsSummary.forSalon(props),
      (salons, ratingsSummary): SalonProfileData => ({
        ...salons[props.salonId],
        ratingsSummary,
      }),
    )),
  }),
});

export default model;
