// @ts-strict-ignore
import { PrivilegedProvider, PublicProvider } from '../api/Providers';
import { AppointmentStatusDetailsProvider } from '../api/Users/Appointments/AppointmentsStatusDetails';

export const CANCELLATION_POLICY = {
  NO_POLICY: 0,
  FLEXIBLE: 1,
  MODERATE: 2,
  STRICT: 3,
};

export const CANCELLATION_POLICY_NAMES = {
  [CANCELLATION_POLICY.FLEXIBLE]: 'Flexible',
  [CANCELLATION_POLICY.MODERATE]: 'Moderate',
  [CANCELLATION_POLICY.STRICT]: 'Strict',
};

export const CANCELLATION_POLICY_OPTIONS = [
  {
    name: CANCELLATION_POLICY_NAMES[CANCELLATION_POLICY.FLEXIBLE],
    value: CANCELLATION_POLICY.FLEXIBLE.toString(),
  },
  {
    name: CANCELLATION_POLICY_NAMES[CANCELLATION_POLICY.MODERATE],
    value: CANCELLATION_POLICY.MODERATE.toString(),
  },
  {
    name: CANCELLATION_POLICY_NAMES[CANCELLATION_POLICY.STRICT],
    value: CANCELLATION_POLICY.STRICT.toString(),
  },
];

export const CANCELLATION_POLICY_PERCENTAGES = {
  [CANCELLATION_POLICY.FLEXIBLE]: {
    CANCELLATION: 0,
    NOSHOW: 50,
  },
  [CANCELLATION_POLICY.MODERATE]: {
    CANCELLATION: 25,
    NOSHOW: 50,
  },
  [CANCELLATION_POLICY.STRICT]: {
    CANCELLATION: 50,
    NOSHOW: 100,
  },
};

/**
 * Returns `true` if a Provider has an NSLC policy; `false` otherwise.
 *
 * @name hasNSLCPolicy
 * @param {Object} provider - The provider whose NSLC policy we are checking.
 * @returns {Boolean} `true` if `provider` has an NSLC policy; `false` otherwise.
 */
export function hasNSLCPolicy(provider: PublicProvider | AppointmentStatusDetailsProvider) {
  if (typeof provider !== 'object') {
    return false;
  }

  return ('cancellation_policy' in provider
    && provider.cancellation_policy !== null
    && provider.cancellation_policy !== CANCELLATION_POLICY.NO_POLICY);
}

export function isProviderOnPayments(
  provider: Pick<PrivilegedProvider, 'can_process_payments' | 'autocharge_enabled_time'>,
) {
  return !!(
    provider?.can_process_payments
    && provider?.autocharge_enabled_time
  );
}

export function isProviderAutoPay(provider: Pick<PrivilegedProvider, 'autocheckout_enabled'>) {
  return (
    provider?.autocheckout_enabled
  );
}

export function isProviderIn30DayAutochargeWindow(provider: Pick<PrivilegedProvider, 'autocharge_enabled_time'> = null) {
  const todayDate = new Date();
  const todayMinus30Days = new Date(todayDate.setDate(todayDate.getDate() - 30));
  return provider && !(provider.autocharge_enabled_time
    && new Date(provider.autocharge_enabled_time) < todayMinus30Days);
}
