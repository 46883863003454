import SSInAppBrowser from './SSInAppBrowser';

/**
 * Open a popup window and returns a reference to it.
 * @param uri - The URI to open
 * @param onClose - An optional handler to be called when the window is closed;
 * @param closeCheckInterval - How many ms between checks that the window is closed. Browser only.
 * @param target - The target window name.
 * @param ssInAppBrowserFeatures - extra params to pass SSInAppBrowser.open()
 * @param ssInAppBrowserCallbacks - callbacks to pass SSInAppBrowser.open()
 * @param browserFeatures - extra params to pass window.open()
 * @returns {Window | SSInAppBrowser}
 */
export const openPopupWindow = (
  uri,
  {
    onClose,
    closeCheckInterval = 2000,
    target = '_blank',
    ssInAppBrowserFeatures,
    ssInAppBrowserCallbacks,
    browserFeatures,
  },
) => {
  if (SSInAppBrowser.enabled()) {
    const ssInAppBrowser = SSInAppBrowser.open(
      uri,
      target,
      ssInAppBrowserFeatures,
      ssInAppBrowserCallbacks,
    );
    if (onClose) {
      const onExit = () => {
        onClose();
        ssInAppBrowser.removeEventListener('exit', onExit);
      };
      ssInAppBrowser.addEventListener('exit', onExit);
    }
    return ssInAppBrowser;
  }
  const popupWindow = window.open(uri, target, browserFeatures);
  if (onClose) {
    const interval = setInterval(() => {
      if (popupWindow?.closed) {
        onClose();
        clearInterval(interval);
      }
    }, closeCheckInterval);
  }
  return popupWindow;
};
