// @ts-strict-ignore
import type { ProviderCancellationPolicy } from '../../modules/Api.types';
import SimpleCache from '../../modules/SimpleCache';
import { ssFetchJSON } from '../../modules/ssFetch';
import { API_ROOT } from '../../config';
import type { SecondsTimeString24Hr } from '../../types';
import type { DateTimeStamp } from '../../types/dateTime';
import type { PublicProvider, PublicProviderLocation } from '../Providers';
import { getProviderLocation } from '../../modules/provider/getProviderLocation';

export const ApiCache = new SimpleCache(5);

export function getProProfileClientView(
  providerId: number | string,
): Promise<ProfileClientViewResponse> {
  return ssFetchJSON(
    `${API_ROOT}/api/v1/consumer/${providerId}/providerprofileclientview`,
    { ssCache: ApiCache },
  );
}

export type ProviderLocationCoords = {
  latitude: number | null;
  longitude: number | null;
};

export type ProviderBusinessHours = {
  closed: boolean;
  day: 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
  start_time: SecondsTimeString24Hr;
  end_time: SecondsTimeString24Hr;
}[];

export type ProviderProfileLocation = {
  id: number;
  name: string;
  vanity_url: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  neighborhood?: string;
  timezone: string;
  timezoneoffset: number;
  mobile_business: boolean;
  coords: ProviderLocationCoords;
  full_location_string: string;
};

export type ProfileClientViewResponse = ClientProviderProfile | ProfileClientViewDeleted;

export const PROFILE_CLIENT_VIEW_PROFESSION = {
  SALON: 'Salon',
  STYLIST: 'Stylist',
} as const;

// see ProviderProfileClientViewSerializer
export type ClientProviderProfile = {
  address1: string;
  address2: string;
  blurb: string;
  business_hours: ProviderBusinessHours;
  cancellation_policy: ProviderCancellationPolicy;
  cancellation_policy_text?: string;
  can_process_payments: boolean;
  autocheckout_enabled: boolean;
  city: string;
  coords: ProviderLocationCoords;
  creation_time: DateTimeStamp;
  email?: string;
  id: number;
  is_chat_enabled: boolean;
  is_disabled: boolean;
  is_top_pro: boolean;
  is_paid_ncd_eligible?: boolean;
  instagram?: string;
  location_instructions: string | null;
  mobile_business?: boolean;
  name: string;
  neighborhood?: string;
  public_phone_number?: string;
  preferred_pronouns?: string;
  profession: keyof typeof PROFILE_CLIENT_VIEW_PROFESSION | string;
  profile_photo: string | null;
  rating_stars: number | null;
  smart_pricing_enabled: boolean;
  service_tax_rate: number;
  state: string;
  twitter?: string;
  vanity_url: string;
  zipcode: string;
  pixel_id?: string;
  has_products: boolean;
  business_name: string;
  ratings_count: number;
  ratings_count_by_stars: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
  is_effectively_online_bookable: boolean;
  timezone: string;
  enabled_online_booking_multi: boolean;
  has_at_least_1000_appointments: boolean;
  pro_waitlist_user_requested_enabled: boolean;
  full_location_string: string;
  cancellation_notice_hours: number;
  deposit_policy_text: string | null;
};

// These will be the only fields returned if the profile has been deleted
export type ProfileClientViewDeleted = {
  id: number;
  city: string;
  state: string;
  profession: keyof typeof PROFILE_CLIENT_VIEW_PROFESSION | string;
  profile_deleted_time: string;
};

export function isClientProviderProfile(
  value: PublicProvider | ClientProviderProfile,
): value is ClientProviderProfile {
  return (
    !!value
    && !(value as PublicProvider).locations
    && !!(value as ClientProviderProfile).business_name
  );
}

export function getProfileLocation(
  profile: PublicProvider | ClientProviderProfile,
): PublicProviderLocation | ClientProviderProfile {
  return isClientProviderProfile(profile) ? profile : getProviderLocation(profile);
}

export function getBusinessName(
  profile: PublicProvider | ClientProviderProfile,
): string {
  return isClientProviderProfile(profile)
    ? profile.business_name
    : getProviderLocation(profile)?.name;
}
