import moment, { MomentInput } from 'moment';
import StateManagerInstance from '../../../user/stateManager';

export interface WelcomeNotification {
  type: 'welcome';
  provider_id: number;
  creation_time: MomentInput;
  uuid: 'welcome-notification';
  persistent: true;
}

const WELCOME_NOTIFICATION_KEY = 'shownWelcomeNotification';

/**
 * Set the user state the time of first welcome notification shown
 */
export function setWelcomeNotificationSeenState() {
  StateManagerInstance.getActionValue(WELCOME_NOTIFICATION_KEY)
    .then(timestamp => {
      if (!timestamp) {
        StateManagerInstance.addAction(WELCOME_NOTIFICATION_KEY, moment().toISOString());
      }
    });
}

/**
 * Add a welcome notification to pro notification feed if not shown
 */
export function createWelcomeNotification(
  providerId: number,
  firstNotificationCreationTime?: MomentInput,
): WelcomeNotification {
  const creationTime: MomentInput = (
    firstNotificationCreationTime
    || moment().toISOString()
  );
  return {
    type: 'welcome',
    provider_id: providerId,
    creation_time: creationTime,
    uuid: 'welcome-notification',
    persistent: true,
  };
}

/**
 * Check if the welcome notification has to be shown for the pro.
 */
export async function shouldShowWelcomeNotification(): Promise<boolean> {
  return StateManagerInstance.getActionValue(WELCOME_NOTIFICATION_KEY).then(timestamp => {
    // Show the welcome notification for 3 days since the first shown day
    const showNotificationUntil = moment(timestamp).startOf('day').add(3, 'days');
    const today = moment().startOf('day');

    if (!timestamp || today.isBefore(showNotificationUntil)) {
      return true;
    }
    return false;
  });
}
