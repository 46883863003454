// @ts-strict-ignore
/**
 * Recursively call a function on all the datasets contained within a master dataset
 *
 * @param {Object} dataset The master dataset object
 * @param {Function} func The function to call for each value dataset
 *
 * @return {Object} The return from all value datasets
 */
import {
  DataFields,
  GraphableDataSet,
  MasterDataSet,
} from '../types';
import { copyValue } from './copyValue';
import { isObject } from './is';

export const loopOverDatasets = <F extends DataFields = DataFields, R = any>(
  dataset: MasterDataSet<F> | GraphableDataSet,
  func: (fieldData, field) => R,
): any => (
    Object.keys(dataset)
      .reduce((result, field) => {
        const newResult = copyValue(result);
        const fieldData = dataset[field];

        if (isObject(fieldData) || Array.isArray(fieldData)) {
          newResult[field] = func(fieldData, field);
        }

        return newResult;
      }, dataset)
  );
