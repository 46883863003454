// @ts-strict-ignore
/* eslint-disable array-element-newline */
import LogRocket from 'logrocket';
import { createModel } from '@rematch/core';
import type { RootModel } from '../models';
import {
  CordovaTapToPayInitSuccessPayload,
  CordovaTapToPayCollectPaymentSuccessPayload,
  CordovaTapToPayDetectDeviceSuccessPayload,
  CordovaTapToPayErrorPayload,
} from '../../modules/CordovaPlugins.types';

import { getIOSVersion, getIsApp } from '../../modules/AppInfo';
import {
  getStripeConnectionToken,
  isCordovaTapToPayErrorPayload,
  SCPReaderDisplayMessage,
  TapToPayConnectionStatus,
  TapToPayGeolocationAuthorizationStatus,
  TapToPayPlugin,
  TapToPayPluginErrorCodes,
  TapToPayPluginErrors,
  TapToPayPluginEvent,
} from '../../modules/TapToPay';
import { ConnectionStatus, StripeTerminalState } from './StripeTerminal.model.types';
import { StripeTerminalConnectError } from './StripeTerminalConnectError';
import { StripeTerminalCollectPaymentError } from './StripeTerminalCollectPaymentError';
import { StripeTerminalGeolocationAuthorizationError } from './StripeTerminalGeolocationAuthorizationError';
import { StripeTerminalDisconnectError } from './StripeTerminalDisconnectError';
import { StripeTerminalSyncReaderConnectionStatusError } from './StripeTerminalSyncReaderConnectionStatusError';
import { StripeTerminalProcessPaymentError } from './StripeTerminalProcessPaymentError';
import { formatError, log } from '../../modules/TapToPay/TapToPayLogger';
import { StripeTerminalCanceledError } from './StripeTerminalCanceledError';
import { StripeTerminalPasscodeNotEnabledError } from './StripeTerminalPasscodeNotEnabledError';
import { StripeTerminalCommandNotAllowedDuringCallError } from './StripeTerminalCommandNotAllowedDuringCallError';
import { StripeTerminalError } from './StripeTerminalError';
import { addPageActionNR } from '../../modules/exceptionLogger';

const connectionStatusMap = new Map<ConnectionStatus, TapToPayConnectionStatus>([
  [ConnectionStatus.NotConnected, TapToPayConnectionStatus.NotConnected],
  [ConnectionStatus.Connected, TapToPayConnectionStatus.Connected],
  [ConnectionStatus.InProgress, TapToPayConnectionStatus.Connecting],
]);

export const getDefaultState = (): StripeTerminalState => ({
  pluginLoaded: false,
  connectionStatus: ConnectionStatus.NotConnected,
  softwareUpdateProgress: 0,
  shouldAutoReconnect: false,
  geolocationAuthorizationStatus: TapToPayGeolocationAuthorizationStatus.Unknown,
  latestReaderDisplayMessage: null,
});

export const stripeTerminal = createModel<RootModel>()({
  name: 'stripeTerminal',
  state: getDefaultState() as StripeTerminalState,
  selectors: slice => ({
    isPluginLoaded() {
      return slice((state: StripeTerminalState) => state.pluginLoaded);
    },
    isConnected() {
      return slice(
        (state: StripeTerminalState) => state.connectionStatus === ConnectionStatus.Connected,
      );
    },
    isConnecting() {
      return slice(
        (state: StripeTerminalState) => state.connectionStatus === ConnectionStatus.InProgress,
      );
    },
    isNotConnected() {
      return slice(
        (state: StripeTerminalState) => state.connectionStatus === ConnectionStatus.NotConnected,
      );
    },
    connectionProgress() {
      return slice(
        (state: StripeTerminalState) => state.softwareUpdateProgress,
      );
    },
    shouldAutoReconnect() {
      return slice(
        (state: StripeTerminalState) => state.shouldAutoReconnect,
      );
    },
    geolocationAuthorizationStatus() {
      return slice(
        (state: StripeTerminalState) => state.geolocationAuthorizationStatus,
      );
    },
  }),
  reducers: {
    onReaderConnected(state): StripeTerminalState {
      return ({
        ...state,
        connectionStatus: ConnectionStatus.Connected,
      });
    },
    onReaderConnecting(state): StripeTerminalState {
      return ({
        ...state,
        connectionStatus: ConnectionStatus.InProgress,
      });
    },
    onReaderMessageDisplayed(
      state,
      scpReaderDisplayMessage: SCPReaderDisplayMessage,
    ): StripeTerminalState {
      return ({
        ...state,
        latestReaderDisplayMessage: scpReaderDisplayMessage,
      });
    },
    onClearReaderDisplayMessages(state): StripeTerminalState {
      return ({
        ...state,
        latestReaderDisplayMessage: null,
      });
    },
    onReaderNotConnected(state): StripeTerminalState {
      return ({
        ...state,
        connectionStatus: ConnectionStatus.NotConnected,
      });
    },
    onPluginLoaded(state, success: boolean): StripeTerminalState {
      return ({
        ...state,
        pluginLoaded: success,
      });
    },
    onSoftwareUpdateProgressUpdated(state, softwareUpdateProgress: number): StripeTerminalState {
      return ({
        ...state,
        softwareUpdateProgress,
      });
    },
    onGeolocationAuthorizationStatusUpdated(
      state,
      status: TapToPayGeolocationAuthorizationStatus,
    ): StripeTerminalState {
      return ({
        ...state,
        geolocationAuthorizationStatus: status,
      });
    },
    onReset(): StripeTerminalState {
      return getDefaultState();
    },
    setShouldAutoReconnect(state, shouldAutoReconnect: boolean): StripeTerminalState {
      return ({
        ...state,
        shouldAutoReconnect,
      });
    },
  },
  effects: dispatch => ({
    async init(): Promise<void> {
      const isIOSApp = getIsApp() && getIOSVersion();
      if (!isIOSApp) {
        dispatch.stripeTerminal.onPluginLoaded(false);
        log('Not running in iOS App');
        return;
      }

      // It's in this format instead of async/await in a try/catch block because
      // we plan to keep and reuse one of these callbacks as the terminal delegate to deal with
      // events such as didDisconnectReader
      TapToPayPlugin.init(
        getStripeConnectionToken,
        event => {
          dispatch.stripeTerminal.handleInitSuccess(event);
        },
        (payload?: CordovaTapToPayErrorPayload) => {
          dispatch.stripeTerminal.handleInitFailure(payload);
        },
      );
    },
    handleInitSuccess(event: CordovaTapToPayInitSuccessPayload) {
      dispatch.stripeTerminal.onPluginLoaded(true);
      if (typeof event !== 'object') {
        // Initial load of plugin completed
        try {
          dispatch.stripeTerminal.updateGeolocationAuthorizationStatus();
        } catch {
          log('Updating geolocation authorization status failed after plugin init');
        }
        return;
      }

      switch (event?.event) {
        case TapToPayPluginEvent.DidReportUnexpectedReaderDisconnect:
          log('didReportUnexpectedReaderDisconnect');
          dispatch.stripeTerminal.onReaderNotConnected();
          break;
        case TapToPayPluginEvent.DidChangeLocationAuthorization:
          log('Geolocation Authorization Changed');
          dispatch.stripeTerminal.updateGeolocationAuthorizationStatus();
          break;
        default:
          log(`Unexpected event for TapToPayPlugin.init() onSuccess: ${event}`);
      }
    },
    handleInitFailure(payload: CordovaTapToPayErrorPayload | undefined) {
      log(`Failed to initialize Plugin: ${formatError(payload)}`);
      dispatch.stripeTerminal.onPluginLoaded(false);
    },
    async connectReader(payload: {
      locationId: string;
      merchantName: string;
      stripeAcctId: string;
    }, state): Promise<void> {
      log('connectReader start');
      if (!state.stripeTerminal.pluginLoaded) {
        log('Connection aborted: Plugin not loaded');
        throw new StripeTerminalConnectError('Plugin not loaded');
      }
      if (state.stripeTerminal.connectionStatus === ConnectionStatus.Connected) {
        log('Connection skipped: Reader already connected');
        return;
      }
      const {
        locationId, merchantName, stripeAcctId,
      } = payload;

      TapToPayPlugin.setShouldFetchToken(true);

      const instance = new TapToPayPlugin({
        locationId,
        merchantName,
        onBehalfOfId: stripeAcctId,
      });
      dispatch.stripeTerminal.onReaderConnecting();

      const connectionStatus = await TapToPayPlugin.connectionStatus();

      if ([
        TapToPayConnectionStatus.Connecting,
        TapToPayConnectionStatus.Connected,
      ].includes(connectionStatus)) {
        if (connectionStatus === TapToPayConnectionStatus.Connected) {
          dispatch.stripeTerminal.onReaderConnected();
        }
        throw new StripeTerminalConnectError(`Connection aborted: Reader is ${TapToPayConnectionStatus[connectionStatus]}`);
      }

      try {
        log(`Connecting with locId=${locationId}, name=${merchantName}, OBO=${stripeAcctId}`);
        dispatch.stripeTerminal.onSoftwareUpdateProgressUpdated(0);
        await new Promise((resolve, reject) => {
          instance.connectToReader(
            event => {
              dispatch.stripeTerminal.handleConnectReaderSuccess({ resolve, event });
            },
            errorPayload => {
              dispatch.stripeTerminal.handleConnectReaderFailure({ reject, errorPayload });
            },
          );
        });
      } catch (e) {
        log('Connection Failed', formatError(e));
        throw new StripeTerminalConnectError(e);
      }
    },
    /** Due to the way Cordova is architected, the plugin hangs on to a reference to the callback
     * which dispatches this effect to tell us that the reader has connected and other reader events
     * such as when updates have started installing, update progress, when updates have finished
     * installing, when the reader was requested to present the Tap to Pay screen, etc.
     */
    handleConnectReaderSuccess(
      { resolve, event }:
      {
        resolve: (value: unknown) => void;
        event: CordovaTapToPayDetectDeviceSuccessPayload;
      },
      state,
    ) {
      if (typeof event !== 'object') return;
      log(JSON.stringify(event));
      const userId = state.user?.userId;
      // It's okay to use the providerId from user since superusers aren't allowed
      // to use Tap to Pay
      const providerId = state.user?.providerId;

      const pageActionAttributes = {
        userId, providerId,
      };

      switch (event.event) {
        case TapToPayPluginEvent.DidFinishDiscoverReaders:
          break;
        case TapToPayPluginEvent.DidStartInstallingUpdate:
          break;
        case TapToPayPluginEvent.DidConnectReader:
          dispatch.stripeTerminal.onReaderConnected();
          log('Reader connected');
          resolve(event);
          break;
        case TapToPayPluginEvent.DidReportReaderSoftwareUpdateProgress:
          dispatch.stripeTerminal.onSoftwareUpdateProgressUpdated(event.payload);
          break;
        case TapToPayPluginEvent.DidFinishInstallingUpdate:
          break;
        case TapToPayPluginEvent.DidRequestReaderInput:
          LogRocket.track('Tap to Pay Checkout Reader Input Requested');
          addPageActionNR('TapToPay_didRequestReaderInput', pageActionAttributes);
          break;
        case TapToPayPluginEvent.DidRequestReaderDisplayMessage: {
          dispatch.stripeTerminal.onReaderMessageDisplayed(event.payload);
          const readerDisplayMessage = SCPReaderDisplayMessage[event.payload];

          // We're tracking this in LogRocket here as we want to see these events as they happen
          LogRocket.track(`Tap to Pay Checkout Reader Message Displayed: ${readerDisplayMessage}`);
          addPageActionNR('TapToPay_didRequestReaderDisplayMessage', {
            ...pageActionAttributes,
            reader_message_displayed: readerDisplayMessage,
          });
          break;
        }
        case TapToPayPluginEvent.DidCancelDiscoverReaders:
          dispatch.stripeTerminal.onReaderNotConnected();
          resolve(event);
          break;
        default:
          log('Unknown event');
          break;
      }
    },
    handleConnectReaderFailure(
      { reject, errorPayload = { name: 'unknown', description: '' } }:
      {
        reject: (reason?: any) => void;
        errorPayload?: CordovaTapToPayErrorPayload;
      },
    ) {
      log(`Connect to reader failed: ${formatError(errorPayload)}`);
      dispatch.stripeTerminal.onReaderNotConnected();
      reject(errorPayload);
    },
    async disconnectReader({ force = false }: { force: boolean }, state) {
      log('willReaderDisconnect', `force: ${force}`);
      if (!state.stripeTerminal.pluginLoaded) {
        log('didAbortReaderDisconnect: Plugin not loaded');
        throw new StripeTerminalDisconnectError('Plugin not loaded');
      }

      TapToPayPlugin.setShouldFetchToken(false);

      try {
        await TapToPayPlugin.disconnectReader();
        log('didReaderDisconnect', `force: ${force}`);
        dispatch.stripeTerminal.onReaderNotConnected();
      } catch (e) {
        log('didReaderDisconnectFail', `force: ${force}`, e);
        if (e instanceof StripeTerminalDisconnectError) throw e;
        throw new StripeTerminalDisconnectError(e);
      }
    },

    async syncReaderConnectionStatus({ context }: { context: string }, state) {
      if (!state.stripeTerminal.pluginLoaded) {
        log('Sync Reader Connection Status aborted: Plugin not loaded');
        throw new StripeTerminalSyncReaderConnectionStatusError('Plugin not loaded');
      }
      try {
        const expectedStatus = connectionStatusMap.get(state.stripeTerminal.connectionStatus);
        const pluginStatus = await TapToPayPlugin.connectionStatus();
        if (pluginStatus !== expectedStatus) {
          switch (pluginStatus) {
            case TapToPayConnectionStatus.NotConnected:
              dispatch.stripeTerminal.onReaderNotConnected();
              break;
            case TapToPayConnectionStatus.Connected:
              dispatch.stripeTerminal.onReaderConnected();
              break;
            case TapToPayConnectionStatus.Connecting:
              dispatch.stripeTerminal.onReaderConnecting();
              break;
            default:
              throw new StripeTerminalSyncReaderConnectionStatusError(`Unknown TapToPayConnectionStatus on sync: ${pluginStatus}`);
          }

          const properties = {
            context,
            expected: expectedStatus ? TapToPayConnectionStatus[expectedStatus] : 'unknown',
            actual: TapToPayConnectionStatus[pluginStatus],
          };
          log('Tap to Pay Reader Connection Status Out of Sync', context);
          LogRocket.track('Tap to Pay Reader Connection Status Out of Sync', properties);
          addPageActionNR('TapToPay_didReaderConnectionStatusOutOfSync', properties);
        }
      } catch (e) {
        log('Sync Reader Connection Status failed', e);
        if (e instanceof StripeTerminalSyncReaderConnectionStatusError) throw e;
        throw new StripeTerminalSyncReaderConnectionStatusError(e);
      }
    },

    async collectPayment(payload: {
      clientSecret: string;
    }, state): Promise<void> {
      if (!state.stripeTerminal.pluginLoaded) {
        log('Collect Payment aborted: Plugin not loaded');
        throw new StripeTerminalCollectPaymentError('Plugin not loaded');
      }
      if (state.stripeTerminal.connectionStatus !== ConnectionStatus.Connected) {
        log('Collect Payment aborted: Reader not connected');
        throw new StripeTerminalCollectPaymentError('Reader not connected');
      }

      const { clientSecret } = payload;
      dispatch.stripeTerminal.onClearReaderDisplayMessages();
      try {
        log('didStartCollectPayment', clientSecret);
        await new Promise((resolve, reject) => {
          TapToPayPlugin.collectPayment(
            clientSecret,
            event => {
              dispatch.stripeTerminal.handleCollectPaymentSuccess({ resolve, event });
            },
            errorPayload => reject(errorPayload),
          );
        });
        LogRocket.track('Tap to Pay Checkout collectPayment Succeeded');
      } catch (e) {
        throw await dispatch.stripeTerminal.handleCollectPaymentFailure(e) as StripeTerminalError;
      }
    },
    handleCollectPaymentSuccess(
      { resolve, event }:
      { resolve: (value: unknown) => void; event: CordovaTapToPayCollectPaymentSuccessPayload },
    ) {
      if (typeof event !== 'object') return;
      log(JSON.stringify(event));
      switch (event.event) {
        case TapToPayPluginEvent.DidCollectPaymentMethod:
          log('didCollectPaymentMethod');
          break;
        case TapToPayPluginEvent.DidProcessPaymentIntent:
          log('didProcessPaymentIntent');
          resolve(event);
          break;
        default:
          log('Unknown event');
          break;
      }
    },
    /** Examines the rejection payload from collectPayment and throws the corresponding error */
    handleCollectPaymentFailure(e: unknown): StripeTerminalError {
      log('collectPayment Failed', formatError(e));

      if (!isCordovaTapToPayErrorPayload(e)) {
        return new StripeTerminalCollectPaymentError(e);
      }

      if (e?.name === TapToPayPluginErrors.ProcessPaymentError) {
        LogRocket.track(
          'Tap to Pay Checkout collectPayment Failed PROCESS_PAYMENT_ERROR',
          { errorCode: e?.code },
        );
        return new StripeTerminalProcessPaymentError(e);
      }

      if (e?.name === TapToPayPluginErrors.CollectPaymentMethodError) {
        switch (e?.code) {
          case TapToPayPluginErrorCodes.ProviderCancelled:
            LogRocket.track(
              'Tap to Pay Checkout collectPayment Failed Provider Cancelled',
              { errorCode: e?.code },
            );
            return new StripeTerminalCanceledError();
          case TapToPayPluginErrorCodes.PasscodeDisabled:
            LogRocket.track(
              'Tap to Pay Checkout collectPayment Failed Passcode Disabled',
              { errorCode: e?.code },
            );
            return new StripeTerminalPasscodeNotEnabledError(e);
          case TapToPayPluginErrorCodes.OngoingCall:
            LogRocket.track(
              'Tap to Pay Checkout collectPayment Failed Ongoing Call',
              { errorCode: e?.code },
            );
            return new StripeTerminalCommandNotAllowedDuringCallError(e);
          default:
            LogRocket.track(
              'Tap to Pay Checkout collectPayment Failed COLLECT_PAYMENT_METHOD_ERROR',
              { errorCode: e?.code },
            );
        }
      }

      return new StripeTerminalCollectPaymentError(e);
    },
    async updateGeolocationAuthorizationStatus(payload?, state?) {
      if (!state.stripeTerminal.pluginLoaded) {
        log('updateGeolocationAuthorizationStatus aborted: Plugin not loaded');
        throw new StripeTerminalGeolocationAuthorizationError('Plugin not loaded');
      }
      try {
        const status = await TapToPayPlugin.geolocationAuthorizationStatus();
        await dispatch.stripeTerminal.onGeolocationAuthorizationStatusUpdated(status);
      } catch (e) {
        log('updateGeolocationAuthorizationStatus Failed', e);
        throw new StripeTerminalGeolocationAuthorizationError(e);
      }
    },
  }),
});
