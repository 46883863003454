import nonCriticalException from '../exceptionLogger';
import { ssFetchJSON } from '../ssFetch';

export type PhoneSettingsResponse = {
  appointment_sms_enabled: boolean;
  appointment_sms_opted_out: boolean;
  appointment_sms_requested: boolean;
  mobile_phone_number: {
    e164: string | number;
    national: string;
  };
};

export const getUserSettings = async (
  userId: number | string,
): Promise<PhoneSettingsResponse> => ssFetchJSON(
  `/api/v2.1/users/${userId}/phone_settings`,
);

/**
 * Get the client & appointment details for a tracked client.
 *
 * @param {Object} options - Options for the request
 * @param {number} options.client_id - Required. Id of the client to retrieve phone settings for
 * @param {string} options.settings_token - auth token, required if user is anonymous
 *
 * @return {Promise}
 */
export const getClientSettings = async (options: {
  client_id: number;
  settings_token?: string;
}): Promise<PhoneSettingsResponse> => {
  const queryParams = options.settings_token ? `?settings_token=${options.settings_token}` : '';
  return ssFetchJSON(`/api/v2.1/clients/${options.client_id}/phone_settings${queryParams}`);
};

type PhoneSettingsPayload = {
  name?: string;
  mobile_phone_number: string | number;
  appointment_sms_enabled: boolean;
  source: string;
  ss_messaging_sms_enabled?: boolean;
};

export const saveUserSettings = async (userId: number, payload: Partial<PhoneSettingsPayload>) => {
  const form = {
    ...payload,
    source: `mweb: ${payload.source}`,
  };

  return ssFetchJSON(`/api/v2.1/users/${userId}/phone_settings`, {
    form,
    throwOnHttpError: true,
    method: 'POST',
    headers: {
      // make sure the server thinks this is ajax
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
};

export const saveClientSettings = async (
  clientId: number,
  token: string,
  payload: PhoneSettingsPayload,
) => {
  const form = {
    ...payload,
    settings_token: token,
    source: `mweb: ${payload.source}`,
  };

  return ssFetchJSON(`/api/v2.1/clients/${clientId}/phone_settings`, {
    form,
    throwOnHttpError: true,
    method: 'POST',
    headers: {
      // make sure the server thinks this is ajax
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
};

/**
 * Returns whether the user has opted in to receiving SMS messages.
 *
 * Note that a user may be eligible to receive SMS, even if the user has
 * not opted in, because appointment level sms messages are sent with no prior permission given.
 *
 * @param {number} userId The ID of the user to check the opt-in status for.
 */
export const isUserOptedInToSMS = async (userId: number): Promise<boolean> => {
  let userSettings: {
    appointment_sms_enabled?: boolean;
  } = {};
  try {
    userSettings = await getUserSettings(userId);
  } catch (e) {
    nonCriticalException(e);
  }
  const {
    appointment_sms_enabled: smsEnabled = false,
  } = userSettings;
  return smsEnabled;
};
