import React from 'react';
import { colors } from '@styleseat/brand';

type FinanceAutoCheckoutIconProps = {
  width?: number;
  height?: number;
  color?: string;
} & React.SVGProps<SVGSVGElement>;

export const FinanceAutoCheckoutIcon = ({
  width = 24,
  height = 24,
  color = colors.blackInBlack,
}: FinanceAutoCheckoutIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill={color}
      fillRule="evenodd"
    >
      <path
        d="M14.986 1.783l-.561 2.014c3.653.945 6.55 3.898 7.094 7.699.724 5.066-3.003 9.752-8.31 10.443-5.306.692-10.212-2.867-10.937-7.934-.605-4.234 1.904-8.194 5.839-9.788l.22 1.542c.042.293.428.4.628.175l2.756-3.095a.332.332 0 00-.072-.504L8.118.06c-.255-.164-.596.037-.555.33l.247 1.72C2.68 3.903-.66 8.908.11 14.285c.888 6.205 6.897 10.565 13.395 9.718 6.498-.848 11.063-6.585 10.176-12.79-.666-4.658-4.217-8.275-8.695-9.43"
      />
      <path
        d="M12.563 16.184c.838-.112 1.256-.475 1.256-1.161 0-.625-.406-.963-1.256-1.213v2.374zM11.411 9.55c-.784.101-1.15.537-1.15 1.1 0 .611.392.899 1.15 1.124V9.55zm-.064 8.033c-1.689-.162-2.644-.986-3.285-1.985l1.427-.875c.535.711 1.046 1.286 1.922 1.449v-2.65c-1.583-.374-3.087-.923-3.087-2.697 0-1.574 1.177-2.586 3.023-2.724V7.24h1.255v.899c1.452.187 2.238.862 2.813 1.648l-1.413.975c-.379-.525-.772-.961-1.439-1.136v2.435c1.819.413 3.192 1.013 3.192 2.774 0 1.374-.93 2.562-3.153 2.748v1.05h-1.255v-1.05z"
      />
    </g>
  </svg>
);
