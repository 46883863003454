import type { MarketingVideo } from './MarketingVideos.model';

export const getHomepageSlotData: () => MarketingVideo[] = () => [
  {
    videoSources: [
      {
        type: 'video/mp4',
        src: 'https://d220aniogakg8b.cloudfront.net/marketing/homepage/new/Manuel.mp4',
      },
    ],
    videoThumbnail: 'https://d220aniogakg8b.cloudfront.net/marketing/homepage/new/makeup-artist-manuel1.png',
    videoDescription: 'Between glam looks and good vibes, it’s no surprise why Manuel’s clients keep coming back.',
    videoTitle: 'Manuel, Makeup Artist',
    thumbnailDescription: 'Makeup artist sitting on a couch and engaged in a conversation with a smiling client.',
    videoDescriptionLink: {
      text: 'Manuel’s clients keep coming back',
      url: 'https://www.styleseat.com/m/v/manuelespinoza2',
    },
  },
  {
    videoSources: [
      {
        type: 'video/mp4',
        src: 'https://d220aniogakg8b.cloudfront.net/marketing/homepage/new/Kamara.mp4',
      },
    ],
    videoThumbnail: 'https://d220aniogakg8b.cloudfront.net/marketing/homepage/new/natural-hairstylist-kamara.png',
    videoDescription: 'Kamara has the skills to make women and girls feel confident about wearing their natural hair.',
    videoTitle: 'Kamara, Natural Hairstylist',
    thumbnailDescription: 'Natural hairstylist adding an extension to client’s hair.',
    videoDescriptionLink: {
      text: 'Kamara has the skills',
      url: 'https://www.styleseat.com/m/v/hairbyintuition',
    },
  },
  {
    videoSources: [
      {
        type: 'video/mp4',
        src: 'https://d220aniogakg8b.cloudfront.net/marketing/homepage/new/Marissa.mp4',
      },
    ],
    videoThumbnail: 'https://d220aniogakg8b.cloudfront.net/marketing/homepage/brow-artist-marissa.png',
    videoDescription: 'Check out Marissa’s work and you’ll see why her clients choose the pro to be their eyebrows’ BFF.',
    videoTitle: 'Marissa, Brow Artist',
    thumbnailDescription: 'Brow artist holding an epilator and spoolie brush as she services a client.',
    videoDescriptionLink: {
      text: 'Check out Marissa’s work',
      url: 'https://www.styleseat.com/m/v/browsbymari',
    },
  },
];
