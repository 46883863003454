// @ts-strict-ignore
import { ssFetchJSON } from '../ssFetch';
import { IAccountFieldEditStatus } from '../Api.types';

type EditType = 'email' | 'phone number' | 'bank account' | 'password' | 'debit card';

export const EDIT_TYPES = {
  1: 'email',
  2: 'phone number',
  3: 'bank account',
  4: 'password',
  5: 'debit card',
};

export const REVERSE_EDIT_TYPES = {
  email: 1,
  'phone number': 2,
  'bank account': 3,
  password: 4,
  'debit card': 5,
};

/**
 * Gets a record of which fields, if any, have been modified in the previous 48 hours
 * @param {Number} providerId The ID of the provider to load
 */
export async function getAccountStatus(
  providerId: number | string,
): Promise<IAccountFieldEditStatus> {
  const accountStatus = await ssFetchJSON<IAccountFieldEditStatus>(
    `/api/v2/providers/${providerId}/account_field_edit_status`,
  );
  return accountStatus;
}

/**
 * Get the last edit account status for the field that is going to be edited
 * @param {Number} providerId The ID of the provider to load
 * @param {String} editType - Field name that is going to be edited
 */
export async function getLastEditStatusForType(
  providerId: number | string,
  editType: EditType,
): Promise<undefined | string> {
  const accountStatus = await ssFetchJSON<IAccountFieldEditStatus>(
    `/api/v2/providers/${providerId}/account_field_edit_status?field_type=${REVERSE_EDIT_TYPES[editType]}`,
  );
  return accountStatus.blocked ? EDIT_TYPES[accountStatus.last_changed_field] : undefined;
}
