/**
 * Wraps a promise and gives you the ability to cancel it by calling
 * the .cancel() function on the returning object. When canceled, neither the `then` nor `catch`
 * callbacks will be fired.
 *
 * @example
 *   import promiseBreaker from 'modules/promiseBreaker';
 *
 *   const myPromise = promiseBreaker(new Promise((resolve, reject) => {
 *     // Do something here
 *   }));
 *
 *   // Cancel it
 *   myPromise.cancel();
 *
 * @param {Promise} promise - The promise to wrap
 * @param {Object} options
 */
export default function promiseBreaker(promise, options = {}) {
  // Fire callback functions
  const fireCallbacks = callbacks => {
    callbacks.forEach(fn => {
      try {
        fn();
      } catch (e) {
        console.error(e);
      }
    });
  };

  // The promise is already a promise breaker, return it
  if (promise?.isPromiseBreaker === true) {
    if (typeof options.onCancel === 'function') {
      if (promise.wasCanceled) {
        fireCallbacks([options.onCancel]);
      }
      promise.onCancelHandlers.push(options.onCancel);
    }
    return promise;
  }

  // Wrap promise
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => {
        if (!wrappedPromise.wasCanceled) {
          wrappedPromise.wasCompleted = true;
          return resolve(val);
        }
        return undefined;
      },
      error => {
        if (!wrappedPromise.wasCanceled) {
          wrappedPromise.wasCompleted = true;
          return reject(error);
        }
        return undefined;
      },
    );
  });
  wrappedPromise.isPromiseBreaker = true;
  wrappedPromise.wasCanceled = false;
  wrappedPromise.wasCompleted = false;
  wrappedPromise.onCancelHandlers = [];

  if (typeof options.onCancel === 'function') {
    wrappedPromise.onCancelHandlers.push(options.onCancel);
  }

  /**
   * Cancel the promise
   */
  wrappedPromise.cancel = () => {
    if (wrappedPromise.wasCanceled || wrappedPromise.wasCompleted) {
      return wrappedPromise;
    }
    wrappedPromise.wasCanceled = true;
    fireCallbacks(wrappedPromise.onCancelHandlers);
    return wrappedPromise;
  };

  return wrappedPromise;
}
