import { formatError } from '../../modules/TapToPay/TapToPayLogger';
import { isCordovaTapToPayErrorPayload } from '../../modules/TapToPay/TapToPay';

export class StripeTerminalError extends Error {
  public code?: number | string;
  public description?: string;

  constructor(error: unknown, code?: number | string) {
    super(formatError(error));
    this.name = 'StripeTerminalError';
    this.code = (isCordovaTapToPayErrorPayload(error) && code === undefined)
      ? error.code
      : code;
    this.description = isCordovaTapToPayErrorPayload(error) ? error.description : '';
  }
}
