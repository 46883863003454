// @ts-strict-ignore
import { API_ROOT } from '../../../config';
import { ProviderService, ServicesCache } from '../Services';
import nonCriticalException from '../../../modules/exceptionLogger';
import SimpleCache from '../../../modules/SimpleCache';
import ssFetch, { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';

/**
 * Data as it is returned from the ServiceGroupSerializer serializer
 */
export type ProviderServiceGroup = {
  id: number;
  color: string;
  name: string;
  order: number;
  is_default: boolean;
  is_package: boolean;
  services: ProviderService[];
  add_ons?: ProviderService[];
};

export type ApiProviderCreateServiceGroupParams = {
  providerId: number;
  name: string;
  color_id: number | null;
};

export type ApiProviderSaveServiceGroupParams = {
  providerId: number;
  id: number;
  name: string;
  color_id: number | null;
};

export type ApiProviderServiceGroupsParams = {
  providerId: number;
  /** if ServiceFilter is omitted, API will return services where is_private = false. */
  serviceFilter?: 'all';
  size?: 'all' | number;
};

/** Retrieves public service groups for a pro
 * @param {ApiProviderServiceGroupsParams} obj - Params for the Api call
 * @param {number} obj.providerId
 * @param {'all'=} obj.serviceFilter - if 'all', includes private service groups
 * @param {'all'|number=} obj.size - Pagination page size
 * @param {SimpleCache=} [cache] - Pass in a SimpleCache to cache results
 * @returns {Promise<ProviderServiceGroup[]>} - A promise of an array of ProviderServiceGroup
 */
export async function getServiceGroups(
  {
    providerId,
    serviceFilter,
    size,
  }: ApiProviderServiceGroupsParams,
  cache?: SimpleCache,
): Promise<IDRFResponse<ProviderServiceGroup>> {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/servicegroups`);

  if (serviceFilter) {
    url.searchParams.append('service_filter', serviceFilter);
  }

  if (size) {
    url.searchParams.append('size', size.toString());
  }

  try {
    return await ssFetchJSON<IDRFResponse<ProviderServiceGroup>>(
      url.toString(),
      {
        method: 'GET',
        ...(cache && { ssCache: cache }),
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: 'getServiceGroups: Failed to retrieve service groups',
        providerId,
        serviceFilter,
        size,
      },
    );
    return {
      count: 0,
      results: [],
    };
  }
}

/**
 * Retrieves the cached service groups if available
 * @param {ApiProviderServiceGroupsParams} params
 * @returns {Promise<IDRFResponse<ProviderServiceGroup>>}
 */
export async function getCachedServiceGroups(
  params: ApiProviderServiceGroupsParams,
): Promise<IDRFResponse<ProviderServiceGroup>> {
  return getServiceGroups(params, ServicesCache);
}

/**
 * Gets all provider services from cache / api
 * @param {number} providerId
 * @returns {Promise<ProviderService[]>}
 */
export async function getAllCachedServices(providerId: number): Promise<ProviderService[]> {
  const serviceGroups = await getCachedServiceGroups({
    providerId,
    size: 'all',
  });
  return serviceGroups.results.reduce(
    (allServices, { services }) => allServices.concat(services),
    [],
  );
}

/**
 * Creates a new service group
 * @param {number} providerId
 * @param {string} name
 * @param {number} color_id
 * @returns {Promise<ProviderServiceGroup>}
 */
export function createServiceGroup(
  {
    providerId,
    name,
    color_id,
  }: ApiProviderCreateServiceGroupParams,
): Promise<ProviderServiceGroup> {
  return ssFetchJSON<ProviderServiceGroup>(
    `${API_ROOT}/api/v2/providers/${providerId}/servicegroups`,
    {
      method: 'POST',
      body: { name, color_id },
    },
  );
}

/**
 * Saves changes to the provided service group
 * @param {number} providerId
 * @param {string} name
 * @param {number} color_id
 * @param {number} id
 * @returns {Promise<ProviderServiceGroup>}
 */
export function saveServiceGroup(
  {
    providerId,
    name,
    color_id,
    id,
  }: ApiProviderSaveServiceGroupParams,
): Promise<ProviderServiceGroup> {
  return ssFetchJSON<ProviderServiceGroup>(
    `${API_ROOT}/api/v2.1/providers/${providerId}/servicegroups/${id}`,
    {
      method: 'PATCH',
      body: {
        id, name, color_id,
      },
    },
  );
}

/**
 * Deletes the given service group
 * @param {number} providerId
 * @param {number} serviceGroupId
 * @returns {any}
 */
export function deleteServiceGroup(
  providerId: number,
  serviceGroupId: number,
) {
  return ssFetch(
    `${API_ROOT}/api/v2.1/providers/${providerId}/servicegroups/${serviceGroupId}`,
    {
      method: 'DELETE',
    },
  );
}
