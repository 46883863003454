import { useEffect, useRef } from 'react';

/**
 * A hook identical to `useEffect` except:
 *  - the effect function doesn't fire on first load (only when state in the
 *  dependency array is _changed_).
 *
 * It has the same signature as `useEffect`:
 *  - a function which runs an effect
 *  - a dependency array of state that governs when the effect function is run
 *
 * @param {function} effect - The effect function to be called.
 * @param {array} dependencies - An array whose change in data evokes the
 *                               effect function.
 */
const useDepsEffect = (effect, dependencies) => {
  const isFirstLoad = useRef(true);
  useEffect(
    () => {
      if (isFirstLoad.current) {
        isFirstLoad.current = false;
        return;
      }
      // eslint-disable-next-line consistent-return
      return effect();
    },
    dependencies,
  );
};

export default useDepsEffect;
