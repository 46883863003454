// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import { PaymentOption } from '../../api/Providers/Appointments';
import type { RootModel } from '../models';
import {
  AppointmentTotals,
  AppointmentTotalsParams,
  fetchAppointmentTotals,
} from '../../api/Providers/Appointments/AppointmentTotals';

export type ProviderAppointmentTotalsState = {
  /**
   * The appointment that this store is currently storing totals for
   */
  selectedAppointmentId?: number;
  /**
   * Stores the totals for a saved appointment. Totals are based
   * only on the saved appointment data, no temporary changes.
   *
   * For totals related to change requests, see 'checkoutRequest.totals'
   */
  totals?: AppointmentTotals;
  /**
   * Stores totals related to a pending checkout including payment type and tip
   *
   * The selected payment type impacts the applicability of discounts while the
   * tip affects the total amount due. This reflects changes to both.
   */
  checkoutRequest?: {
    params: UpdateCheckoutPayload;
    totals: AppointmentTotals;
  };
  loading: boolean;
};

type OnLoadedPayload = AppointmentIdPayload & {
  totals: AppointmentTotals;
};

type OnCheckoutUpdatedPayload = {
  totals: AppointmentTotals;
  params: UpdateCheckoutPayload;
};

type AppointmentIdPayload = {
  appointmentId: number;
};

type IdPayload = AppointmentIdPayload & {
  providerId: number;
};

type LoadPayload = IdPayload;

export type UpdateCheckoutPayload = {
  tip?: string;
  selectedPaymentType?: PaymentOption;
};

export type Discount = {
  discountCode?: string | null;
  discountAmount: number;
};

function getInitialState(): ProviderAppointmentTotalsState {
  return {
    loading: false,
  };
}

/**
 * providerAppointmentTotals model
 *
 * This model is responsible for cost and cost related calculation information
 * for an appointment. The totals are calculated by the backend and stored here.
 */
export const providerAppointmentTotals = createModel<RootModel>()({
  name: 'providerAppointmentTotals',

  state: getInitialState() as ProviderAppointmentTotalsState,

  reducers: {
    'user/logout': () => getInitialState(),

    onLoad(
      state: ProviderAppointmentTotalsState,
      payload: Partial<IdPayload>,
    ): ProviderAppointmentTotalsState {
      return {
        ...state,
        ...payload,
        loading: true,
      };
    },

    onLoaded: produce<ProviderAppointmentTotalsState, [OnLoadedPayload]>((
      state: ProviderAppointmentTotalsState,
      { appointmentId, totals }: OnLoadedPayload,
    ): ProviderAppointmentTotalsState => {
      state.selectedAppointmentId = appointmentId;
      state.totals = totals;

      state.loading = false;
      return state;
    }),

    onCheckoutTotalsUpdated: produce<ProviderAppointmentTotalsState, [OnCheckoutUpdatedPayload]>((
      state: ProviderAppointmentTotalsState,
      { params, totals }: OnCheckoutUpdatedPayload,
    ): ProviderAppointmentTotalsState => {
      state.checkoutRequest = {
        params,
        totals,
      };

      return state;
    }),
  },

  effects: dispatch => ({
    async load({
      appointmentId,
      providerId,
    }: LoadPayload): Promise<AppointmentTotals> {
      dispatch.providerAppointmentTotals.onLoad({ appointmentId, providerId });

      const appointmentTotals = await fetchAppointmentTotals(
        providerId,
        appointmentId,
      );

      dispatch.providerAppointmentTotals.onLoaded({
        appointmentId,
        totals: appointmentTotals,
      });

      return appointmentTotals;
    },

    async updateCheckoutTotals({
      appointmentId,
      providerId,
      tip,
      selectedPaymentType,
    }: IdPayload & UpdateCheckoutPayload): Promise<AppointmentTotals> {
      const fetchParams: AppointmentTotalsParams = {
        tip: Number(tip),
      };

      if (selectedPaymentType) {
        fetchParams.selected_payment_type = selectedPaymentType;
      }

      const appointmentTotals = await fetchAppointmentTotals(
        providerId,
        appointmentId,
        fetchParams,
      );

      dispatch.providerAppointmentTotals.onCheckoutTotalsUpdated({
        params: {
          tip,
          selectedPaymentType,
        },
        totals: appointmentTotals,
      });

      return appointmentTotals;
    },
  }),

  selectors: (slice, createSelector) => ({
    totals: () => slice(state => state.totals),

    checkoutTotals: () => createSelector(
      slice(state => state.totals),
      slice(state => state.checkoutRequest?.totals),
      (
        totals: AppointmentTotals | null,
        checkoutTotals: AppointmentTotals | null,
      ): AppointmentTotals | null => ({
        ...totals,
        ...checkoutTotals,
      }),
    ),
  }),
});
