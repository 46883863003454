// @ts-strict-ignore
import type { calendar_v3 } from '@googleapis/calendar';
import { DateTimeStamp } from '../../../types/dateTime';
import IDRFResponse from '../../../types/IDRFResponse';
import ssFetch, { IResponse, ssFetchJSON } from '../../../modules/ssFetch';
import nonCriticalException from '../../../modules/exceptionLogger';

// see GoogleCalendarBaseSerializer writable fields
export interface GoogleCalendarPayload {
  user: number;
  calendar_id: number | string;
  should_receive_events: boolean;
}

// see GoogleCalendarBaseSerializer
export interface GoogleCalendar {
  id: number;
  user: number;
  calendar_id: string;
  should_receive_events: boolean;
  should_send_events: boolean;
  sync_start_time: DateTimeStamp;
  sync_completion_time: DateTimeStamp;
  creation_time: DateTimeStamp;
  modification_time: DateTimeStamp;
  unsync_start_time: DateTimeStamp;
  unsync_completion_time: DateTimeStamp;
  is_syncing: boolean;
  is_unsyncing: boolean;
  is_synced: boolean;
}

export interface GoogleCredentialPayload {
  one_time_code: string;
  redirect_uri: string;
}

// see GoogleCalendarCredentialCreateSerializer
export interface GoogleCredential {
  id: number;
  user: number;
  email: string;
  creation_time: DateTimeStamp;
  modification_time: DateTimeStamp;
  is_expired: boolean;
  access_token: string;
  access_token_expired: boolean;
}

/**
 * Get external calendars already stored on StyleSeat.
 *
 * @returns Resolves with list of calendars.
 */
export async function getExternalCalendars(userId: number | string): Promise<GoogleCalendar[]> {
  return (await ssFetchJSON<IDRFResponse<GoogleCalendar>>(
    `/api/v2/users/${userId}/google_calendars`,
  )).results;
}

/**
 * Updates the internal configuration for an external calendar.
 *
 * @param param0 The payload
 * @param param0.should_receive_events Whether or not to sync this calendar.
 * @param param0.user The user ID to associate with the calendar
 * @param param0.calendar_id The external ID of the calendar
 * @returns {Promise} Resolves when complete.
 */
export async function createExternalCalendar({
  user: userId,
  ...payload
}: GoogleCalendarPayload): Promise<GoogleCalendar> {
  return ssFetchJSON<GoogleCalendar>(
    `/api/v2/users/${userId}/google_calendars`,
    {
      method: 'POST',
      body: payload,
    },
  );
}

/**
 * Updates the internal configuration for an external calendar.
 *
 * @param param0 The payload
 * @param param0.should_receive_events Whether or not to sync this calendar.
 * @param param0.user The user ID to associate with the calendar
 * @param param0.calendar_id The *internal* ID of the calendar
 * @returns {Promise} Resolves when complete.
 */
export async function updateExternalCalendar({
  user: userId,
  calendar_id: calendarId,
  ...payload
}: GoogleCalendarPayload): Promise<GoogleCalendar> {
  return ssFetchJSON<GoogleCalendar>(
    `/api/v2/users/${userId}/google_calendars/${calendarId}`,
    {
      method: 'PATCH',
      body: payload,
    },
  );
}

/**
 * Get user's google calendars.
 *
 * @param userId The user ID
 * @param accessToken Access token for authenticating with google.
 * @returns Resolves with calendar list.
 */
export async function getRemoteGoogleCalendars(
  userId: number | string,
  accessToken: string,
): Promise<calendar_v3.Schema$CalendarListEntry[]> {
  const response = await ssFetchJSON<calendar_v3.Schema$CalendarList>(
    `/api/v2/users/${userId}/remote_google_calendars?access_token=${accessToken}`,
  );

  return response.items;
}

/**
 * Retrieve google account information.
 * @param userId The user ID
 * @returns {Promise} - Resolves with account data
 */
export async function getGoogleCredential(userId: number | string): Promise<GoogleCredential> {
  const response = await ssFetchJSON<IDRFResponse<GoogleCredential>>(
    `/api/v2/users/${userId}/google_calendar_credentials`,
  );
  const firstCred = response.results[0];

  if (firstCred && !firstCred.is_expired) {
    return firstCred;
  }

  throw new Error('Google account not found');
}

/**
 * Send a one-time auth code to the backend to retrieve and store an offline access token.
 * @param userId The user ID
 * @param code The one-time auth code
 * @param redirectUri The optional redirect URI associated with the code
 * @returns A promise resolving to the google credential information, if available.
 */
export async function createGoogleCredential(
  userId: number | string,
  code: string,
  redirectUri?: string,
): Promise<GoogleCredential | null> {
  let response: IResponse<GoogleCredential>;

  try {
    response = await ssFetch<GoogleCredential>(
      `/api/v2/users/${userId}/google_calendar_credentials`,
      {
        body: {
          one_time_code: code,
          // redirect_uri is optional
          // backend defaults to 'postmessage'
          redirect_uri: redirectUri,
        } as GoogleCredentialPayload,
        method: 'POST',
      },
    );
  } catch (err) {
    nonCriticalException(err);
    throw new Error('Error registering google credential');
  }

  if (response.status === 201) {
    return response.json();
  }

  if (response.ok) {
    // 202 means the backend hasn't finished processing,
    // but we can consider this to be successful.
    return null;
  }

  throw new Error(await response.text());
}
