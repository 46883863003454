import produce from 'immer';

import { Action, createModel } from '@rematch/core';
import type { RootModel } from './models';
import { getItem, setItem } from '../modules/KeyValueStorage';

type RecentSearchesState = {
  recentQueries: string[];
};

const MAX_RECENT_SEARCHES = 5;

const model = createModel<RootModel>()({
  name: 'recentSearches',

  state: {
    recentQueries: [],
  } as RecentSearchesState,

  reducers: {
    onInitialize: produce<RecentSearchesState, [{ queries: string[]; }]>((
      state: RecentSearchesState,
      payload: {
        queries: string[];
      },
    ) => {
      state.recentQueries = payload.queries;
    }),
    onPush: produce<RecentSearchesState, [{ query: string; }]>((
      state: RecentSearchesState,
      payload: {
        query: string;
      },
    ) => {
      const updatedRecentQueries = state.recentQueries
        .filter(q => q !== payload.query);

      updatedRecentQueries.push(payload.query);
      state.recentQueries = updatedRecentQueries.slice(-MAX_RECENT_SEARCHES);
    }),
  },

  effects: dispatch => ({
    afterPush: (payload?: undefined, state?) => {
      setItem('recent_searches', state.recentSearches.recentQueries);
    },
    push: async (payload: {
      query: string;
    }): Promise<void> => {
      await dispatch.recentSearches.onPush(payload);
      return dispatch.recentSearches.afterPush();
    },
    initialize: async (): Promise<Action<{ queries: string[]; }, void>> => {
      const queries = await getItem('recent_searches') || [];
      return dispatch.recentSearches.onInitialize({
        queries: queries.filter((x: string) => (typeof x === 'string' && x.length > 0)),
      });
    },
  }),
});

export default model;
