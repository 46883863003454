// @ts-strict-ignore
import moment from 'moment';
import URI from 'urijs';
import analytics from '../analytics';
import { setItem as kvSetItem } from '../KeyValueStorage';
import { SmartPricingPreferences } from '../../components/provider/smartPricing/types';
import { calculateSmartPriceUpcharge } from '../provider/smartPricing';
import type { StoredDiscountInfo } from '../../store/NCDAndDiscountedPros.types';
import { SmartPriceSource } from '../../components/provider/smartPricing/constants';
import { Moment } from '../dateUtils';
import { slotToTime } from '../timeSlotUtils';
import { ssFetchJSON } from '../ssFetch';
import { State as CurrentUserState } from '../../store/CurrentUser.types';
import { isInstagramBrowserFromBookButton, isSocialBrowser } from '../BrowserInfo';
import { getIOSVersion } from '../AppInfo';
import * as ncd from '../newClientDelivery';
import trackNcdEvents from '../analytics/trackNcdEvents';
import type { BookingState, IBookingConfirmation } from '../../store/Booking.types';
import { hasNSLCPolicy } from '../ProviderState';
import { ClientPreferredCheckoutType } from '../../api/Providers/Appointments';
import { PaymentMethodType } from '../../components/consumer/booking/BookingFlow/types';
import nonCriticalException from '../exceptionLogger';
import GoogleAnalyticsTracker from '../../../../packages/analytics/trackers/GoogleAnalyticsTracker';
import type { BookingCost, PromotionsByServiceId } from '../../store/Booking.model';
import type { State } from '../../store/Providers.model';
import { UTMState } from '../../../../packages/analytics/types';
import { CJParams } from '../CJTracking/CJTracking';
import { isPercentFee, IBookingFeeConfig } from '../../api/Providers/Providers';
import { PublicProvider } from '../../api/Providers';
import { ProviderService } from '../../api/Providers/Services';
import { getProviderLocation } from '../provider/getProviderLocation';

export const CVC_ERROR = 'CVC Token Required!';
export const FALLBACK_BOOKING_FEE_CENTS = 100;
export const DEFERRED_CLIENT_APPT_BOOKED_PARAMS_KEY = 'deferred-client-appt-booked-params';
export const CANCELLATION_POLICY_FEE_COPY = (
  'Pay booking fee now, this card will be charged at time of service.'
);

/**
 * Calculates the total booking fee
 * @param fee The fee config
 * @param total The total cost on which to base the fee
 * @yields The fee, in cents
 */
export function calculateFee(total: number, fee?: IBookingFeeConfig): number {
  if (fee) {
    if (isPercentFee(fee)) {
      const percent = parseFloat(fee.percent) / 100;
      if (Number.isNaN(percent)
        || Number.isNaN(fee.min)
        || Number.isNaN(fee.max)
      ) {
        return FALLBACK_BOOKING_FEE_CENTS;
      }
      const totalInCents = total * 100;
      const amount = Math.floor(percent * totalInCents);
      return Math.max(fee.min, Math.min(amount, fee.max));
    }

    return fee.amount;
  }

  return FALLBACK_BOOKING_FEE_CENTS;
}
/**
   * Fires the 'C Booking Error' analytics tracking event for errors collected
   * during client booking.
   */
export function logBookingError(opts: {
  step: string;
  user: { userId?: number };
  provider?: { id: number };
  code: number;
  reason: any;
}) {
  let { reason } = opts;

  if (typeof opts.reason === 'string') {
    reason = opts.reason.substring(0, 300);
  } else if (typeof opts.reason === 'object') {
    reason = JSON.stringify(opts.reason);
  }

  analytics.track('C Booking Error', {
    reason,
    userId: opts.user.userId,
    providerId: opts.provider?.id,
    step: opts.step,
    code: opts.code,
  });
}

export function isInNSLCWindow(
  provider: PublicProvider,
  apptStartTime: Moment,
  currentTime?: Moment,
): boolean {
  const now = currentTime || moment();

  const proIsNSLC = hasNSLCPolicy(provider);
  if (
    !provider
    || !proIsNSLC // pro does not use NSLC
    // no location means no timezone, no timezone means we don't know the NSLC window.
    || !provider.locations?.length
  ) {
    return false;
  }
  const proLocation = getProviderLocation(provider);
  const localProTime = moment.utc(now).utcOffset(proLocation.timezoneoffset);

  // if an appointment starts before the minStartTime, then it's in NSLC window.
  const minStartTime = moment(localProTime);
  if (minStartTime) {
    // We hardwire the cancellation notice hours to 24 if NSLC is on because the
    // backend send over the wrong info.
    const cancellationNoticeHours = 24;
    minStartTime.add(cancellationNoticeHours, 'hours');
  }

  if (!apptStartTime) {
    return false;
  }

  const inTheFuture = apptStartTime.isAfter(localProTime);
  // not within X hours of appointment time and appt is in the future.
  return minStartTime.isAfter(apptStartTime) && inTheFuture;
}

/**
 * Calculates the service cost price
 * @param serviceCost The service cost
 * @param smartPrice The smartPrice to apply
 * @returns the service cost
 */
export function calculateServiceCost(
  serviceCost: number,
  smartPrice: SmartPricingPreferences,
): number {
  return Number(serviceCost) + calculateSmartPriceUpcharge(serviceCost, smartPrice);
}

interface IPromotionalPriceProvider {
  getPromotionalPrice(serviceCost: number): number;
}

/**
 * Calculate the total cost for this booking, account for all services and promotions.
 * This basically follows the same logic as the backend method
 * ProPromotionManager.applicable_for_timeblocks()
 *
 * @param services Array of service objects
 * @param promotionsByServiceId Hash of promotion objects, keyed by service id
 * @returns The total service cost
 */
export function calculateTotalServiceCost(
  services: Array<Pick<ProviderService, 'id' | 'cost'>> = [],
  promotionsByServiceId: Record<number, IPromotionalPriceProvider[]> = {},
  smartPrice?: SmartPricingPreferences,
): number {
  return services.map(service => {
    // Calculate the base service cost considering smart pricing
    const serviceCost = calculateServiceCost(Number(service.cost), smartPrice);
    // see if there are any promotions for this service
    const promotions = promotionsByServiceId[service.id];
    // if so, find the biggest discount and use that
    if (promotions && promotions.length) {
      const discountedCosts = promotions.map(p => p.getPromotionalPrice(serviceCost));
      return Math.min(...discountedCosts);
    }
    // if no promotion, just return the service cost.
    return serviceCost;
    // sum it all together
  }).reduce((memo, cost) => memo + cost, 0.0);
}

export function baseAndSmartPrice(
  currentBooking: BookingState,
  smartPricingEligible: boolean,
  smartPrice: SmartPricingPreferences,
  promotionsByServiceId: PromotionsByServiceId,
): BookingCost {
  const costReport = {
    baseCost: 0,
    smartPriceCost: 0,
  };
  const useSmartPrice: boolean = smartPricingEligible && !!smartPrice;

  (currentBooking?.services || []).forEach(service => {
    // the base cost without smart pricing
    const addOnsPrice = service.addOns?.reduce((acc, addOn) => acc + Number(addOn.cost), 0) || 0;
    const baseCost = Number(service.cost) + addOnsPrice;

    // Calculate the base service cost considering smart pricing
    const smartPriceCost = baseCost + calculateSmartPriceUpcharge(
      baseCost,
      useSmartPrice ? smartPrice : null,
    );
    // see if there are any promotions for this service
    const promotions = promotionsByServiceId[service.id];
    // if so, find the biggest discount and use that
    if (promotions && promotions.length) {
      const discountedBaseCosts: number[] = [];
      const discountedCosts: number[] = [];

      // calculate costs separately for base and smart priced
      promotions.forEach(promo => {
        discountedBaseCosts.push(promo.getPromotionalPrice(baseCost));
        discountedCosts.push(promo.getPromotionalPrice(smartPriceCost));
      });

      costReport.baseCost += Math.min(...discountedBaseCosts);
      costReport.smartPriceCost += Math.min(...discountedCosts);
    } else {
      // if no promotion, just return the service cost.
      costReport.baseCost += baseCost;
      costReport.smartPriceCost += smartPriceCost;
    }
  });

  return costReport;
}

// TODO move this selector into the selectors export from providers.model
export function isSmartPricingEligible(state: State) {
  return state.providersById[state.activeProviderId]?.isSmartPriceEligible;
}

/**
 * Gets params to send with all booking-related tracking calls.
 * @returns {Object} A map containing `provider_id` from route state and `search_id` from local
 * storage
 */
export function getBookingParams(routeParams: Record<string, any>, existing: Record<string, any>) {
  const searchId = routeParams.sid || null;
  const utmParams = [
    'utm_source',
    'utm_campaign',
    'utm_content',
    'utm_medium',
  ]
    .reduce((acc, curr) => {
      if (routeParams[curr]) {
        acc[curr] = routeParams[curr];
      }
      return acc;
    }, {});
  let proId: number | undefined;

  if (existing?.provider_id_viewed) {
    proId = existing?.provider_id_viewed;
  } else if (routeParams.providerId) {
    proId = parseInt(routeParams.providerId, 10);

    if (Number.isNaN(proId)) {
      proId = undefined;
    }
  }

  return {
    pro_id: proId,
    search_id: searchId,
    ...utmParams,
  };
}

export type BookAppointmentParams = {
  routeParams: Record<string, any>;
  ncdAndDiscountData: StoredDiscountInfo;
  wasBookedFromSearch?: boolean;
  clientToken?: string;
  stripeCvcToken: string;
  stripeInstrumentId: string;
  paymentMethodType: PaymentMethodType;
  checkoutPreference: ClientPreferredCheckoutType;
  shouldAuthCard?: boolean;
  shouldHaltBooking?: boolean;
  smartPriceSource?: SmartPriceSource;
  smartPriced?: boolean;
  atl4064Variant?: string;
  note: string;
  serviceIds: Array<number>;
  discountCode?: string;
  rewardId?: number;
  providerId: number;
  startDate: Moment;
  startSlot: number;
  isRescheduling?: boolean;
  reschedulingId?: number;
  bookingFeeChoice?: string;
  recordEvent: (eventName: string, params: Record<string, any>) => Promise<any>;
  tipAmount?: number;
  tipPercentage?: number;
  cjParams?: CJParams;
};

function buildBookingUrl(
  routeParams: Record<string, any>,
  ncdAndDiscountData: StoredDiscountInfo,
  providerId: number,
) {
  // If we have ncd_source in the url we grab it, otherwise we use the ncd_source
  // from the ncdAndDiscountedPros model which holds the NCD recommended
  // pro attribution data, finally check if we should use social_media
  const getNcdSource = () => {
    if (routeParams.ncd_source) {
      return routeParams.ncd_source;
    }
    if (ncdAndDiscountData?.ncd_source) {
      return ncdAndDiscountData?.ncd_source;
    }
    if (isSocialBrowser()) {
      return 'social_media';
    }
    return null;
  };
  const params = {
    utm_source: routeParams.utm_source,
    utm_term: routeParams.utm_term,
    utm_campaign: routeParams.utm_campaign,
    utm_medium: routeParams.utm_medium,
    ncd_source: getNcdSource(),
    // Since the ncdAndDiscountedPros model does not have ncd term we first grab the
    // ncd term from the url if it's present. If it's not present we then see if
    // the ncdAndDiscountedPros model contains an ncd_source, if it does we use the
    // provider's id for the ncd_term since we know that there was the NCD
    // recommended pro attribution.
    ncd_term: routeParams.ncd_term || ncdAndDiscountData?.ncd_source ? providerId : null,
    ncd_tracking_token: ncdAndDiscountData?.ncd_tracking_token,
  };
  const bookingUrl = new URI(`/schedule/book/${providerId}/submit/onmob/`);

  const keys = Object.keys(params);
  keys.forEach(key => bookingUrl.addSearch(key, params[key]));
  return bookingUrl;
}

function buildBookingFormData({
  rewardId,
  discountCode,
  startDate,
  startSlot,
  serviceIds,
  note,
  routeParams,
  wasBookedFromSearch,
  clientToken,
  smartPriced,
  smartPriceSource,
  paymentMethodType,
  checkoutPreference,
  stripeCvcToken,
  stripeInstrumentId,
  reschedulingId,
  tipAmount,
  tipPercentage,
  bookingFeeChoice,
  shouldAuthCard,
  shouldHaltBooking,
  cjParams,
}: Partial<BookAppointmentParams>) {
  const cjFormParams = (cjParams?.TYPE || cjParams?.CJEVENT)
    ? {
      cj_params: JSON.stringify({
        action_id: cjParams.TYPE,
        cj_event: cjParams.CJEVENT,
      }),
    }
    : {};
  const clientRequestedCheckoutType = paymentMethodType === PaymentMethodType.Klarna
    ? ClientPreferredCheckoutType.Auto
    : checkoutPreference;
  return {
    reward_id: rewardId,
    discount_code: discountCode,
    appointment_date: startDate.format('YYYY-MM-DD'),
    start_time: slotToTime(startSlot),
    service_list: serviceIds.join(','),
    client_note: note,
    page_ref: routeParams.pref,
    page_ref_index: routeParams.rank,
    page_ref_query: routeParams.q,
    booked_from_search: wasBookedFromSearch,
    token: clientToken || '',
    smart_priced: smartPriced,
    smart_price_source: smartPriceSource,
    client_requested_checkout_type: clientRequestedCheckoutType,
    cvc_token: stripeCvcToken || '',
    instrument_id: stripeInstrumentId,
    payment_type: paymentMethodType,
    rescheduling_from_appointment_id: reschedulingId,
    tip_amount: tipAmount,
    tip_percentage: tipPercentage,
    booking_fee: bookingFeeChoice,
    should_auth: shouldAuthCard,
    should_failed_auth_halt_booking: shouldHaltBooking,
    ...cjFormParams,
  };
}

export async function bookAppointment({
  routeParams,
  ncdAndDiscountData,
  wasBookedFromSearch,
  clientToken,
  stripeCvcToken,
  stripeInstrumentId,
  paymentMethodType,
  checkoutPreference,
  shouldAuthCard,
  shouldHaltBooking,
  smartPriceSource,
  smartPriced,
  atl4064Variant,
  note,
  serviceIds,
  discountCode,
  rewardId,
  providerId,
  startDate,
  startSlot,
  isRescheduling,
  reschedulingId,
  bookingFeeChoice,
  recordEvent,
  tipAmount,
  tipPercentage,
  cjParams,
}: BookAppointmentParams): Promise<IBookingConfirmation> {
  const bookingUrl = buildBookingUrl(routeParams, ncdAndDiscountData, providerId);

  const formData = buildBookingFormData({
    rewardId,
    discountCode,
    startDate,
    startSlot,
    serviceIds,
    note,
    routeParams,
    wasBookedFromSearch,
    clientToken,
    smartPriced,
    smartPriceSource,
    paymentMethodType,
    checkoutPreference,
    stripeCvcToken,
    stripeInstrumentId,
    reschedulingId,
    tipAmount,
    tipPercentage,
    bookingFeeChoice,
    shouldAuthCard,
    shouldHaltBooking,
    cjParams,
  });

  if (!isRescheduling) {
    delete formData.rescheduling_from_appointment_id;
  }

  // Track our attempt to submit the booking
  const eventParams = {
    ...formData,
    ...(atl4064Variant ? { atl4064_variant: atl4064Variant } : {}),
  };
  await recordEvent('Booking - Submitting', eventParams);

  return ssFetchJSON<IBookingConfirmation>(bookingUrl.toString(), {
    method: 'POST',
    form: formData,
    addRequestedWith: true,
    throwOnHttpError: true,
  });
}

/**
 * Fire tracking event for successfully booked appointment
 * @param isAutoCharge - true if appt is eligible for autocharge
 * @param clientId - id of client who booked the appt
 * @param providerId - id of the pro the appt was booked with
 * @param apptId - id of successfully booked appt
 * @param trackingData Additional tracking data to send
 */
function trackBookingSuccessWithNCD(
  provider: Pick<PublicProvider, 'cancellation_policy' | 'can_process_payments' | 'name' | 'premium_upcharge_rate' | 'locations' | 'creation_time'>,
  response: IBookingConfirmation,
  routeParams: Record<string, any>,
  userData: CurrentUserState,
  servicesById: Record<number, ProviderService>,
  ncdData: StoredDiscountInfo,
  shouldChargePrepayment: boolean,
  clientId: number,
  providerId: number,
  apptId: number,
  paymentMethodType: PaymentMethodType,
  trackingData: Record<string, any>,
) {
  // Client booked appt tracking
  let containsPopularService = false;
  const serviceIds = [];
  const proLocation = getProviderLocation(provider);

  response.timeblocks.forEach(svc => {
    serviceIds.push(svc.service);

    if ((servicesById?.[svc.service] as { tags?: Array<string> })?.tags?.includes?.('popular')) {
      containsPopularService = true;
    }
  });

  const isNewClientAppointment = ncdData?.is_new_client_appointment;
  let isFirstBookingWithPro; // default to undefined for mixpanel purposes
  if (typeof response.is_user_first_booking_with_pro !== 'undefined') {
    isFirstBookingWithPro = !!(response.is_user_first_booking_with_pro);
  }

  let isClientFirstBookingWithPro; // default to undefined for mixpanel purposes
  if (typeof response.is_client_first_booking_with_pro !== 'undefined') {
    isClientFirstBookingWithPro = !!(response.is_client_first_booking_with_pro);
  }

  return ncd.getNCDAttributionTrackingParams(routeParams, providerId)
    .then(async ncdTrackingParams => {
      const isFirstClientProConnection = (
        isFirstBookingWithPro || isClientFirstBookingWithPro
      );
      const properties = {
        ...trackingData,
        ...ncdTrackingParams,
        // These SMS attributes are kept from old booking logic, but seem vestigial
        client_sms_optin: true,
        client_sms_enabled: undefined,
        client_id: clientId,
        client_type: 'client_user',
        nslc_eligible: provider.cancellation_policy > 0,
        pre_pay_eligible: false,
        new_client_appointment: isFirstBookingWithPro,
        is_first_client_pro_connection: isFirstClientProConnection,
        is_monetized_ncd: response.is_monetized_new_client_delivery,
        pro_nslc_policy: provider.cancellation_policy,
        ep_eligibility: provider.can_process_payments,
        created_by: 'client',
        appt_id: apptId,
        local_end: response.local_end,
        premium_upcharge_rate: provider.premium_upcharge_rate,
        service_ids: serviceIds.join(','),
        is_popular: containsPopularService,
        paid_ncd_flow_seen: shouldChargePrepayment,
        search_id: routeParams.sid || ncdTrackingParams.search_id,
        is_pro_found_through_SS_search_tool: !!routeParams.sid,
        with_ncd_params: !!(routeParams.ncd_source && routeParams.ncd_term),
        is_payment_pro: provider.can_process_payments,
        session_id: sessionStorage.getItem('sessionStorage_ID') || undefined,
        is_new_client_appointment: isNewClientAppointment,
        provider_name: provider.name,
        salon_name: proLocation?.name,
        pro_location: proLocation?.full_location_string,
        pro_signup_date: provider.creation_time,
        user_id: userData.user_id,
        payment_method_type: paymentMethodType,
        order_id: apptId,
        value: parseFloat(response.revenue_take),
        af_revenue: parseFloat(response.revenue_take),
        currency: 'USD',
        is_attributed: !!ncdData?.ncd_source,
        attribution_source: ncdData?.ncd_source,
      };
      const eventName = (
        (properties.created_by === 'client')
          ? 'client_appointment_booked'
          : 'pro_appointment_booked'
      );

      const eventPayload = {
        ...getBookingParams(routeParams, properties),
        ...properties,
      };
      if (eventName === 'client_appointment_booked') {
        Object.assign(eventPayload, {
          provider_id: eventPayload.pro_id,
          appointment_id: eventPayload.appt_id,
          fee_amount: 'booking_fee_charged' in response
            ? response.booking_fee_charged : 0,
          service_ids: serviceIds,
          cost: parseFloat(response.cost),
          is_lmc: response.smart_price_source === 'SCHEDULE_GAP_FILL',
          is_smart_priced: 'smart_priced' in response
            ? response.smart_priced : false,
          is_par: false,
        });
        delete eventPayload.pro_id;
        delete eventPayload.appt_id;
      }

      if (trackingData.payment_method_type === 'klarna'
        && properties.created_by === 'client') {
        try {
          await kvSetItem(DEFERRED_CLIENT_APPT_BOOKED_PARAMS_KEY, { eventName, eventPayload });
        } catch (err) {
          nonCriticalException(
            err,
            { message: `Failed to set deferred event payload: ${err}` },
          );
        }
      } else {
        analytics.track(eventName, eventPayload);
        trackNcdEvents(eventName, properties);
      }
    });
}

/**
 * Send out all the tracking calls that mark a successful booking
 */
export async function trackBookingSuccess(
  provider: Pick<PublicProvider, 'vanity_url' | 'id' | 'cancellation_policy' | 'can_process_payments' | 'name' | 'premium_upcharge_rate' | 'locations' | 'creation_time'>,
  booking: BookingState,
  userData: CurrentUserState,
  routeParams: Record<string, any>,
  servicesById: Record<number, ProviderService>,
  ncdAndDiscountData: StoredDiscountInfo | undefined,
  response: IBookingConfirmation,
  utmParams?: UTMState,
) {
  const gaTracker = await analytics.getTracker('ga') as GoogleAnalyticsTracker;

  gaTracker.trackPageView('onmob_post');

  gaTracker.trackPageView(`/profile/ob/confirmed/${provider.vanity_url}`);

  const hasNote = booking.note !== '';
  // TODO: Coordinate with Payments team to switch to server-side definition
  const isFirstBooking = userData.numBooked === 0;
  const depositRequired: boolean = !!booking?.cartMetadata?.deposit_required;

  let isFirstBookingWithPro; // default to undefined for mixpanel purposes
  if (typeof response.is_user_first_booking_with_pro !== 'undefined') {
    isFirstBookingWithPro = !!(response.is_user_first_booking_with_pro);
  }

  let isClientFirstBookingWithPro; // default to undefined for mixpanel purposes
  if (typeof response.is_client_first_booking_with_pro !== 'undefined') {
    isClientFirstBookingWithPro = !!(response.is_client_first_booking_with_pro);
  }

  const trackingData = {
    pro_id: provider.id,
    booking_note: hasNote,
    booked_services: booking.services.length,
    attribution_response: 'not asked',
    booked_with_card: booking.paymentMethodType
      ? booking.paymentMethodType === PaymentMethodType.Card
      : true,
    ...(
      ncdAndDiscountData && ncdAndDiscountData.discountCode && ncdAndDiscountData.discountEligible
        ? {
          coupon_used: true,
          discount_max: ncdAndDiscountData.discountMax,
          discount_amount: ncdAndDiscountData.discountAmount,
          discount_percent: ncdAndDiscountData.discountPercent,
        }
        : {
          coupon_used: false,
        }
    ),
    is_first_booking: isFirstBooking,
    is_first_booking_with_pro: isFirstBookingWithPro,
    is_client_first_booking_with_pro: isClientFirstBookingWithPro,
    deposit_required: depositRequired,
    smart_priced: response.smart_priced,
    smart_price_source: response.smart_price_source,
    local_start: response.local_start,
    cost: response.cost,
    pros_viewed: 0,
    ...utmParams,
  };

  if (isInstagramBrowserFromBookButton()) {
    analytics.track('client_booked', {
      'appointment id': response.appointment_id,
      'provider id': provider.id,
      'client user id': response.user_id,
      'is iOS': !!getIOSVersion(),
    });
  }

  if (isFirstBooking) {
    gaTracker.trackLegacyEvent(
      'Booking',
      'First Booked For User',
      undefined,
      parseFloat(response.cost),
    );
  }

  if (isFirstBookingWithPro) {
    gaTracker.trackLegacyEvent(
      'Booking',
      'First Booked With Pro For User',
      undefined,
      parseFloat(response.cost),
    );
  }

  if (isClientFirstBookingWithPro) {
    gaTracker.trackLegacyEvent(
      'Booking',
      'First Booked With Pro For Client',
      undefined,
      parseFloat(response.cost),
    );
  }

  let proLocation: { lat: any; lng: any };
  const { location } = response;

  if (location) {
    const { latitude, longitude } = location;
    proLocation = {
      lat: latitude,
      lng: longitude,
    };
  }

  const {
    client_id: clientId,
    appointment_id: appointmentId,
  } = response;

  await trackBookingSuccessWithNCD(
    provider,
    response,
    routeParams,
    userData,
    servicesById,
    ncdAndDiscountData,
    booking.shouldChargePrepayment,
    clientId,
    provider.id,
    appointmentId,
    booking.paymentMethodType || PaymentMethodType.Card,
    trackingData,
  );

  const localStartUnix = moment(response.local_start).unix();
  const localEndUnix = moment(response.local_end).unix();
  const serviceNames = booking.services.map(s => s.name);
  const appointmentInfo = {
    appointmentId: response.appointment_id,
    pro_id: provider.id,
    localStart: localStartUnix,
    localEnd: localEndUnix,
    cost: response.cost,
    serviceNames,
    duration: (localEndUnix - localStartUnix) / 60.0,
    serviceIds: booking.services.map(service => service.id),
    isNCD: response.is_monetized_new_client_delivery,
  };

  await analytics.track('booking', {
    appointment_id: appointmentInfo.appointmentId,
    // This seems like it could be either or for some reason.
    pro_id: appointmentInfo.pro_id,
    local_start: appointmentInfo.localStart,
    local_end: appointmentInfo.localEnd,
    cost: appointmentInfo.cost,
    service_names: appointmentInfo.serviceNames,
    duration: appointmentInfo.duration,
    location_latitude: proLocation?.lat,
    location_longitude: proLocation?.lng,
    search_id: null,
    service_ids: appointmentInfo.serviceIds.join(','),
  });
}
