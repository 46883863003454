// @ts-strict-ignore
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Talk from 'talkjs';

import ChatManager from '../modules/chat/ChatManager';
import type { IRootDispatch, RootState } from '../store/models';
import { CHAT_CLIENT_HAS_SENT_MESSAGE } from '../modules/chat/constants';
import { ChatRoles } from '../modules/chat/types';
import { CHAT } from '../route-names';
import useCurrentChatSession from './useCurrentChatSession';
import { PublicProvider } from '../api/Providers';
import useUserStateRedux from './useUserStateRedux';

const useGoToChatWithPro = (provider: Pick<PublicProvider, 'id' | 'name'>, additionalQueryParams?: { [param: string]: string }) => {
  const dispatch: IRootDispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.user);
  const [selectedUser, setSelectedUser] = useState(null);
  const getChatSession = useCurrentChatSession(selectedUser, ChatRoles.Client);
  const [
    clientHasSentMessage, setClientHasSentMessage, clientHasSentMessageLoaded,
  ] = useUserStateRedux(CHAT_CLIENT_HAS_SENT_MESSAGE);

  useEffect(() => {
    setSelectedUser(currentUser);
  }, [currentUser]);

  return useCallback(async () => {
    await getChatSession();
    const clientChatUser = ChatManager.chatUser;
    const proChatUser = new Talk.User({
      id: provider.id,
      name: provider.name,
      role: ChatRoles.Provider,
    });

    if (clientHasSentMessageLoaded && !clientHasSentMessage) {
      setClientHasSentMessage(true);
    }

    const chatId = await ChatManager.createOneOnOneConversation(
      proChatUser,
      clientChatUser,
    );

    await dispatch.route.go({
      route: CHAT,
      params: {
        chatId,
        requiredRole: ChatRoles.Client,
        ...additionalQueryParams,
      },
    });
  }, [
    selectedUser,
    clientHasSentMessageLoaded,
    additionalQueryParams,
  ]);
};

export default useGoToChatWithPro;
