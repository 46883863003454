// @ts-strict-ignore
/**
 * Take a key and remove things we don't care about, like case and leading/trailing space.
 * @param {String} key - The key to normalize
 * @return {String}
 */
function normalizeKey(key: string): string {
  return key.toLowerCase().trim();
}

/**
 * Makes a shallow copy of the input data
 * @param {any} data - Any data value.
 * @return {any} Copied data
 */
function copyData(data: any): any {
  if (Array.isArray(data)) {
    return data.slice(0);
  }
  // A promise-like object cannot be copied.
  if (typeof data.then === 'function') {
    return data;
  }

  if (typeof data === 'object') {
    return { ...data };
  }
  return data;
}

/**
 * Very simple caching tool that caches a piece of data to memory for a specified # of seconds.
 * The data is not retained when the app is closed.
 *
 * By default the cache keys are case insensitive and leading/trailing space is ignored.
 * You can alter this by overriding the `normalizeKey` function.
 *
 * @class SimpleCache
 * @param {number} defaultExpireSecs - The default number of seconds items are cached for.
 */
export default class SimpleCache {
  defaultExpireSecs: number;

  id: string;

  cache: Map<string, { key: string; data: any; expires: number }>;

  constructor(defaultExpireSecs: number = 10, id?: string) {
    this.cache = new Map();
    this.defaultExpireSecs = defaultExpireSecs;
    this.id = id;
  }

  /**
   * Clear all items from the cache
   */
  clear() {
    this.cache.clear();
  }

  /**
   * Does a cache entry exist for this key.
   * @param {String} key - The key to check
   * @return True if the cache contains the key, otherwise false.
   */
  hasItem(key: string): boolean {
    this.pruneCache();
    return this.cache.has(normalizeKey(key));
  }

  /**
   * Add a response to cache.
   * @param {String} key - The key to cache the data under
   * @param {Any} data - The data to cache.
   * @param {Number} [time] - The number of seconds to keep this cache for
   */
  addItem(key: string, data: any, time: number = this.defaultExpireSecs) {
    const normalizedKey: string = normalizeKey(key);

    this.pruneCache();
    this.cache.set(key, {
      key: normalizedKey,
      data: copyData(data),
      expires: Date.now() + (time * 1000),
    });
  }

  /**
   * Return cached data for a key.
   * @param {String} key - The key for the cache item you want to retrieve.
   */
  getItem(key: string) {
    const normalizedKey = normalizeKey(key);
    this.pruneCache();
    if (this.hasItem(normalizedKey)) {
      return copyData(this.cache.get(normalizedKey).data);
    }
    return undefined;
  }

  /**
   * Force expire a specific cache item.
   * @param {String} key - The key for the cache item you want to expire.
   */
  expireItem(key: string) {
    this.cache.delete(normalizeKey(key));
  }

  /**
   * Go through all cache entries and remove the expired ones.
   */
  pruneCache() {
    const now = Date.now();
    this.cache.forEach((entry, key) => {
      if (entry.expires <= now) {
        this.cache.delete(key);
      }
    });
  }
}
