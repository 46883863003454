// @ts-strict-ignore
/* eslint-disable prefer-arrow-callback */
import produce from 'immer';
import { createModel } from '@rematch/core';
import { ssFetchJSON } from '../modules/ssFetch';
import type { RootModel, RootState } from './models';
import nonCriticalException from '../modules/exceptionLogger';

type Result = {
  total: number;
  stars: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
};

export type RatingsSummary = Result & {
  average_rating: number;
};

type State = {
  byProfileId: {
    [profileId: number]: {
      loading: boolean;
      result?: RatingsSummary;
    };
  };

};

const getAverageRating = (result: Result) => {
  if (result.total === 0) {
    return 0;
  }
  return (
    1 * result.stars['1']
    + 2 * result.stars['2']
    + 3 * result.stars['3']
    + 4 * result.stars['4']
    + 5 * result.stars['5']
  ) / result.total;
};

const model = createModel<RootModel>()({
  name: 'ratingsSummary',

  state: { byProfileId: {} } as State,

  reducers: {
    onLoading: produce<State, [{ profileId: number }]>((
      state: State,
      payload: { profileId: number },
    ) => {
      const { profileId } = payload;
      state.byProfileId[profileId] = { loading: true };
    }),
    onLoaded: produce<State, [{ profileId: number; result: Result }]>((
      state: State,
      payload: { profileId: number; result: Result },
    ) => {
      const {
        profileId,
        result,
      } = payload;
      state.byProfileId[profileId] = {
        loading: false,
        result: {
          ...result,
          average_rating: getAverageRating(result),
        },
      };
    }),
    onFailed: produce<State, [{ profileId: number }]>((
      state: State,
      payload: { profileId: number },
    ) => {
      const { profileId } = payload;
      state.byProfileId[profileId] = { loading: false };
    }),
  },

  effects: dispatch => ({
    loadProvider: async (
      payload: { salonId: number; providerId: number; noCache?: boolean },
      rootState: RootState,
    ) => {
      const {
        salonId,
        providerId,
        noCache,
      } = payload;
      if (noCache || !rootState.ratingsSummary.byProfileId[providerId]) {
        try {
          await dispatch.ratingsSummary.onLoading({ profileId: providerId });
          const result = await ssFetchJSON(`/api/v2/salons/${salonId}/ratings_summary?provider_id=${providerId}`);
          await dispatch.ratingsSummary.onLoaded({
            profileId: providerId,
            result,
          });
        } catch (e) {
          nonCriticalException(e);
          await dispatch.ratingsSummary.onFailed({ profileId: providerId });
        }
      }
    },
    loadSalon: async (
      payload: { salonId: number; noCache?: boolean },
      rootState: RootState,
    ) => {
      const {
        salonId,
        noCache,
      } = payload;
      if (noCache || !rootState.ratingsSummary.byProfileId[salonId]) {
        try {
          await dispatch.ratingsSummary.onLoading({ profileId: salonId });
          const result = await ssFetchJSON(`/api/v2/salons/${salonId}/ratings_summary`);
          await dispatch.ratingsSummary.onLoaded({
            profileId: salonId,
            result,
          });
        } catch (e) {
          nonCriticalException(e);
          await dispatch.ratingsSummary.onFailed({ profileId: salonId });
        }
      }
    },
  }),

  selectors: (
    slice,
    createSelector,
    hasProps,
  ) => ({
    getAll() {
      return slice((summaries: State): State['byProfileId'] => summaries?.byProfileId);
    },

    forPro: hasProps(function forPro(_, props: { profileId: number }) {
      return (
        createSelector(
          slice(summaries => summaries?.byProfileId),
          (ratingsSummaries: State['byProfileId']): RatingsSummary => ratingsSummaries?.[props.profileId]?.result,
        )
      );
    }),

    totalForPro: hasProps(function totalForPro(_, props: { profileId: number }) {
      return (
        createSelector(
          slice(summaries => summaries?.byProfileId),
          (ratingsSummaries: State['byProfileId']): number => ratingsSummaries?.[props.profileId]?.result?.total,
        )
      );
    }),

    forSalon: hasProps(function forSalon(_, props: { salonId: number }) {
      return (
        createSelector(
          slice(summaries => summaries?.byProfileId),
          (ratingsSummaries: State['byProfileId']): RatingsSummary => ratingsSummaries?.[props.salonId]?.result,
        )
      );
    }),
  }),
});

export default model;
