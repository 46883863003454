/* eslint-disable eqeqeq */
import moment from 'moment';
import { bookedWithKlarna } from './UserAppointmentState';
import {
  BaseTimeBlock,
  CardCheckState,
  Checkout,
  ClientPreferredCheckoutType,
  OrderCreationSource,
  OrderStatus,
  ProAppointment,
  ProAppointmentDetails,
  ProAppointmentListPromotionDiscount,
  TimeBlock,
} from '../api/Providers/Appointments';
import { PublicProvider } from '../api/Providers';
import { CheckoutType } from './Api.types';
import { Moment } from './dateUtils';
import {
  AppointmentTypes,
  ProPromotion,
  ProPromotionDiscount,
} from '../api/Users/Appointments';
import { shouldShowNCDControls } from './provider/Appointments/NCD';
import { PromotionDiscountType } from '../api/Providers/Promotions';

type ProAppointmentIgnoringProvider = Omit<ProAppointment, 'provider'>;

/**
 * Provider appointments module
 */

export const BookingMethods = {
  Google: 127,
};

export const dateFormat = 'YYYY-MM-DD';

export const timeFormat = 'HH:mm:ss';

export enum AppointmentTimeStatus {
  Before24Hours = 'before24Hours', // before 24 hours of appointment
  Within24Hours = 'within24Hours', // between 24 hours and 1 hour before appointment
  WithinOneHourBefore = 'withinOneHourBefore', // before appointment (between 1 hour and start time)
  During = 'during', // during appointment (between start and end time)
  After = 'after', // after appointment
}

export const isCheckedOutNoShow = ({
  checkout_status,
  is_noshow,
  is_no_show,
  is_checked_out_noshow,
}: {
  checkout_status?: CheckoutType;
  is_noshow?: boolean;
  is_no_show?: boolean;
  is_checked_out_noshow?: boolean;
}) => {
  const checkoutStatus = checkout_status || 0;

  return (!!is_noshow
    || !!is_checked_out_noshow
    || !!is_no_show
    || checkoutStatus === CheckoutType.CheckoutNoShow);
};

export const isCheckedOutLateCancel = (appointment: ProAppointmentIgnoringProvider) => (
  !!appointment.is_checked_out_late_cancel
);

/**
 * Returns true if appointment has been marked as NSLC
 * @param {object} appointment - the appointment object
 */
export const isCheckedOutNSLC = (appointment: ProAppointmentIgnoringProvider) => (
  isCheckedOutLateCancel(appointment)
  || isCheckedOutNoShow(appointment)
);

/**
 * Returns true if client consents to receiving sms reminders
 * @param   {Object}  appointment object
 * @returns {Boolean}
*/
export const isClientSmsEnabled = (appointment: ProAppointmentIgnoringProvider) => (
  appointment.client?.sms_status?.appointment_sms_enabled
);

export const isAppointmentFrozen = (appointment: ProAppointmentIgnoringProvider) => (
  !!appointment.checkout
  || !!appointment.is_noshow
  || !!appointment.is_checked_out_late_cancel
  || !!appointment.deletion_time
);

/**
 * Returns true for appointments that has been checked out and is not a no show or late cancel
 */
export const isCheckedOut = (appointment: ProAppointmentIgnoringProvider) => (
  !!appointment.checkout
  && !(isCheckedOutLateCancel(appointment) || isCheckedOutNoShow(appointment))
);

/**
 * Was this appointment checked out via a payment request
 * @param {Object} appointment The appointment object
 * @return {Boolean}
 */
export const isCheckedOutRequestPay = (appointment: ProAppointmentIgnoringProvider) => (
  !!appointment.has_payment_request
  && isCheckedOut(appointment)
  && (appointment.order_creation_source === OrderCreationSource.RequestPay)
);

/**
 * Does this appointment start in the future
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export const isFutureAppointment = (appointment: Pick<ProAppointmentIgnoringProvider, 'local_start'>) => (
  moment(appointment.local_start).isAfter(moment())
);

/**
 * Gets a value indicating whether or not the given appointment has begun and has not ended
 * @param appointment The appointment object
 * @returns True if the appointment is currently occurring.
 */
export const isCurrentAppointment = (appointment: ProAppointmentIgnoringProvider): boolean => {
  const now = moment();
  const start = moment(appointment.local_start, dateFormat);
  const end = moment(appointment.local_end, dateFormat);
  return now > start && now < end;
};

/**
 * Is this appointment checked out early via payment request
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export const isPaidAheadAppointment = (appointment: ProAppointmentIgnoringProvider) => (
  isCheckedOutRequestPay(appointment)
  && isFutureAppointment(appointment)
);

/**
 * Can this appointment be rescheduled
 * @param  {Object} appointment Appointment object
 * @return {Boolean}
 */
export const canReschedule = (appointment: ProAppointmentIgnoringProvider) => (
  // Checked out appointments are read-only unless paid in full early via payment request
  (!appointment.checkout || isPaidAheadAppointment(appointment))
  && !isCheckedOutNSLC(appointment)
);

export const isGoogleBooked = (appointment: ProAppointmentIgnoringProvider) => (
  appointment.booking_method === BookingMethods.Google
);

export const isClientAppt = (appointment: ProAppointmentIgnoringProvider) => (
  appointment.type === AppointmentTypes.ClientAppointment
);

export const hasTip = (checkout: Checkout) => (checkout && !!parseFloat(checkout.tip));

export const recurrenceText = (appointment: ProAppointmentIgnoringProvider) => {
  if (appointment.recurrence_interval) {
    // eslint-disable-next-line eqeqeq
    if (appointment.recurrence_interval == 1) {
      return 'Every week';
    }

    return `Every ${appointment.recurrence_interval} weeks`;
  }

  return null;
};

export const isRecurring = (appointment: ProAppointmentIgnoringProvider) => (
  Boolean(appointment.recurrence_interval)
);

export const getCancellationCharge = (appointment: ProAppointmentDetails) => (
  Number(appointment.cancellation_charge_remaining)
);

export const hasLateCancellationPolicy = (appointment: ProAppointmentIgnoringProvider) => (
  (appointment.cancellation_charge !== null)
  && (Number(appointment.cancellation_charge) > 0)
);

export const hasCardCheckFailure = ({
  card_check, order_status,
}: Pick<ProAppointmentIgnoringProvider, 'card_check' | 'order_status'>): boolean => {
  if (!card_check || order_status === OrderStatus.PaymentComplete) return false;
  if ([
    CardCheckState.FailedError,
    CardCheckState.CardDeclined,
  ].includes(card_check.state_code)) {
    return true;
  }
  return false;
};

export const hasAutochargeFailure = ({
  order_status, order_creation_source,
}: Pick<ProAppointmentIgnoringProvider, 'order_status' | 'order_creation_source'>): boolean => {
  if (order_status === OrderStatus.PaymentComplete) return false;
  if (order_status == OrderStatus.PaymentFailed
    && order_creation_source == OrderCreationSource.Autocharge
  ) {
    return true;
  }
  return false;
};

export const hasNoShowChargeFailure = ({
  order_status, order_creation_source,
}: Pick<ProAppointmentIgnoringProvider, 'order_status' | 'order_creation_source'>): boolean => {
  if (order_status === OrderStatus.PaymentComplete) return false;
  if (order_status == OrderStatus.PaymentFailed
    && order_creation_source == OrderCreationSource.NoShow
  ) {
    return true;
  }
  return false;
};

export const hasCardError = (
  appointment: ProAppointmentIgnoringProvider,
): boolean => hasCardCheckFailure(appointment) || hasAutochargeFailure(appointment);

/**
 * Does this appointment have non-adjacent timeblocks
 * This is a determining factor for a power booked appointment
 * @param   {[type]}  appointment [description]
 * @returns {Boolean}             [description]
 */
export const hasNonAdjacentTimeblocks = (appointment: ProAppointmentIgnoringProvider) => {
  if (!appointment.time_blocks || appointment.time_blocks.length < 2) return false;
  for (let i = 1; i < appointment.time_blocks.length; i++) {
    if (appointment.time_blocks[i].local_start !== appointment.time_blocks[i - 1].local_end) {
      return true;
    }
  }
  return false;
};

/**
 * Does this appointment have clean up time attached
 * This is one of the determining factors for a
 * power booked appointment
 * @param   {Object}  appointment Appointment object
 * @returns {Boolean}
 */
export const hasCleanUpTime = (appointment: ProAppointmentIgnoringProvider) => {
  if (!appointment.time_blocks) return false;
  return !!appointment.time_blocks.filter(timeblock => timeblock.service_padding_time).length;
};

/**
 * Is this a power booked apopintment
 * @param   {Object}  appointment The appointment object
 * @returns {Boolean}
 */
export const isPowerBooked = (appointment: ProAppointmentIgnoringProvider) => (
  hasNonAdjacentTimeblocks(appointment)
  || hasCleanUpTime(appointment)
);

export const isCancelled = (appointment: ProAppointmentIgnoringProvider) => (
  !!appointment.is_cancelled || !!appointment.deletion_time
);

/**
 * Show payment icons if appointment is in charging window or is a future appointment
 */
export const showPaymentIcons = (appointment: ProAppointmentIgnoringProvider) => {
  const windowEnd = (
    appointment.is_autocheckout
      ? moment(appointment.local_end).add(1, 'hours')
      : moment(appointment.local_end).add(4, 'hours')
  );
  return moment().isBefore(windowEnd) || moment(appointment.local_end).isAfter(moment());
};

/**
 * Returns true when the client requested auto pay for an appointment
 */
export const isClientRequestedAutopay = (appointment: ProAppointmentIgnoringProvider) => (
  appointment?.client_requested_checkout_type === ClientPreferredCheckoutType.Auto
);

/**
 * Returns true when the client requested manual pay for an appointment
 */
export const isClientRequestedManualPay = (appointment: ProAppointmentIgnoringProvider) => (
  appointment?.client_requested_checkout_type === ClientPreferredCheckoutType.Manual
);

/**
 * Returns true when the client requested payment of either auto or manual
 */
export const hasClientRequestedPayment = (appointment: ProAppointmentIgnoringProvider) => (
  isClientRequestedAutopay(appointment)
  || isClientRequestedManualPay(appointment)
);

/**
 * Returns true for appointments that can be auto checked out
 */
export const isExpressPayAuto = (appointment: ProAppointmentIgnoringProvider) => {
  const isAutoCheckout = (
    appointment.is_autocheckout
    || appointment.smart_priced
    || isClientRequestedAutopay(appointment)
  );
  return !appointment.checkout && isAutoCheckout;
};

/**
 * Returns true for appointments that has to be manually checked out and no known errors on
 * client card
 */
export const isExpressPayManual = (appointment: ProAppointmentIgnoringProvider) => (
  !appointment.checkout
  && appointment.is_expresspay
  && !appointment.is_autocheckout
  && !hasCardError(appointment)
);

/**
 * Returns true if appointment is an express pay appointment with card error
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isExpressPayWithCardError = (appointment: ProAppointmentIgnoringProvider) => (
  !appointment.checkout
  && appointment.is_expresspay
  && hasCardError(appointment)
);

/**
 * Returns true for appointments that was attempted to checkout(manual or auto) with card
 * error
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isAutochargeDeclined = (appointment: ProAppointmentIgnoringProvider) => (
  isExpressPayWithCardError(appointment)
  && (appointment.order_status === OrderStatus.PaymentFailed)
);

/**
 * Returns true if pre auth failed for auto checkout appointments
 * @param {object} appointment - the appointment object
 * @return {boolean}
 */
export const isAutoCheckoutPreAuthFail = (appointment: ProAppointmentIgnoringProvider) => (
  isExpressPayWithCardError(appointment)
  && isExpressPayAuto(appointment)
  && appointment.card_check.state_code === CardCheckState.CardDeclined
);

/**
 * Returns true for appointments that require client signature to checkout even if client has
 * card on file with no errors
 */
export const needClientSignature = (appointment: ProAppointmentIgnoringProvider) => (
  !appointment.checkout
  && !appointment.is_expresspay
  && !hasCardError(appointment)
  && appointment.client_has_card
  && !isCheckedOutNoShow(appointment)
);

/**
 * Returns whether the appointment is considered personal or not.
 * @param   {Object} type An appointment to check
 * @returns {Boolean}
 */
export const isPersonalTime = (appointment: ProAppointmentIgnoringProvider) => (
  appointment?.type === AppointmentTypes.PersonalTime
);

/**
 * Does this appointment have a prepayment NCD charge
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export const isPrepayNCDAppointment = (appointment: ProAppointmentIgnoringProvider) => (
  appointment.is_prepaid_new_client_delivery
);

/**
 * Is this appointment within late cancelation time
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export const isWithinLateCancelTime = (appointment: ProAppointmentIgnoringProvider) => (
  moment().add(24, 'h') > moment(appointment.local_start)
);

/**
 * Is this appointment within NCD pro cancelation window
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export const isWithinNCDCancellationWindow = (appointment: ProAppointmentIgnoringProvider) => (
  !shouldShowNCDControls(appointment) || moment().isBefore(moment(appointment.local_start))
);

/**
 * Can this NCD appointment be cancelled
 * @param  {Object} appointment The appointment object
 * @return {Boolean}
 */
export const canCancelNCDAppointment = (appointment: ProAppointmentIgnoringProvider) => (
  !shouldShowNCDControls(appointment) || moment(appointment.local_start).diff(moment(), 'hours') >= 1
);

export const appointmentTimeStatus = (
  appointment: ProAppointmentIgnoringProvider,
): AppointmentTimeStatus => {
  const now = moment();
  const before24Hours = moment(appointment.local_start).subtract(24, 'hours');
  const before1Hour = moment(appointment.local_start).subtract(1, 'hours');
  const startApptTime = moment(appointment.local_start);
  const endApptTime = moment(appointment.local_end);
  if (now < before24Hours) {
    return AppointmentTimeStatus.Before24Hours;
  } if (now >= before24Hours && now < before1Hour) {
    return AppointmentTimeStatus.Within24Hours;
  } if (now >= before1Hour && now < startApptTime) {
    return AppointmentTimeStatus.WithinOneHourBefore;
  } if (now >= startApptTime && now <= endApptTime) {
    return AppointmentTimeStatus.During;
  }

  return AppointmentTimeStatus.After;
};

/**
 * Appointment that has valid card on file with pro on payments
 */
export const hasValidCardOnFile = (
  appointment: ProAppointmentIgnoringProvider,
  provider: PublicProvider,
) => (
  (provider?.can_process_payments)
  && appointment.client_has_card
  && !isCheckedOut(appointment)
);

/** Get the primary icon for appt, it will either be one of three icons:
*  1. Valid card on file
*  2. Appt checked out
*  3. Error notification type (e.g. pre-auth fail)
* @param {object} appointment - the appointment object
* @param {object} provider - the provider object
* @return {object} the icon object with classname and label
*/
export const getApptPrimaryIcon = (
  appointment: ProAppointmentIgnoringProvider,
  provider: PublicProvider,
) => {
  const validCardOnFile = {
    className: 'ss-icon-card-on-file',
    label: 'Card on file',
    testId: 'card-on-file',
  };
  const klarnaBooked = {
    className: 'ss-icon-klarna',
    label: 'Klarna Booked',
    testId: 'klarna-booked',
  };
  const apptCheckedOut = {
    className: 'cal-icon-checkedout',
    label: 'Checked out',
    testId: 'checked-out',
  };
  const autoChargeDeclined = {
    className: 'ss-icon-autocharge-declined',
    label: 'Automatic charge declined',
    testId: 'auto-charge-declined',
  };
  const noShowChargeDeclined = {
    className: 'ss-icon-client-noshow-declined',
    label: 'No-show charge declined',
    testId: 'no-show-charge-declined',
  };

  if (bookedWithKlarna(appointment)) {
    return klarnaBooked;
  }

  if (isCheckedOut(appointment)) {
    return apptCheckedOut;
  }

  if (isAutochargeDeclined(appointment)) {
    return autoChargeDeclined;
  }

  if (hasNoShowChargeFailure(appointment)) {
    return noShowChargeDeclined;
  }

  if (hasValidCardOnFile(appointment, provider)) {
    return validCardOnFile;
  }

  return null;
};

export enum PowerBookingType {
  Busy = 'busy',
  Free = 'free',
}

export enum PowerBookingDesc {
  Initial = 'initial',
  Processing = 'processing',
  Finishing = 'finishing',
  Cleanup = 'cleanup',
}

export interface PowerBookingTimeBlock extends TimeBlock {
  powerBookingType?: PowerBookingType;
  powerBookingDesc?: PowerBookingDesc;
  powerBookingId?: string;
}

export interface BasePowerBookingTimeBlock extends BaseTimeBlock {
  powerBookingType?: PowerBookingType;
  powerBookingDesc?: PowerBookingDesc;
  powerBookingId?: string;
}

/**
   * Calculate and return power booking time blocks, including expanding all power booked sections
   * into their own time blocks.
   *
   * Power bookings
   * --------------
   * Power booked services will be expanded to have one time block per power booking duration.
   *
   * Power booked timeblocks will have these additional properties:
   *    + powerBookingId - All related powerbooked timeblocks will have the same value.
   *                       Use this to group powerbooked timeblocks together.
   *    + powerBookingType - Either 'busy' or 'free'. (i.e. processing is marked as 'free')
   *    + powerBookingDesc - 'initial', 'finishing', 'processing', 'cleanup'
   *
   * @param {ProAppointmentIgnoringProvider} appointment
   * @return {PowerBookingTimeBlock[]}
   */
export const getPowerBookingTimeBlocks = (
  appointment: ProAppointmentIgnoringProvider,
): PowerBookingTimeBlock[] | null => {
  if (!isPowerBooked(appointment)) return null;

  const timeblocks: PowerBookingTimeBlock[] = [];
  let powerBookingIndex = 0;

  let skipBlock: TimeBlock | null;

  const lastTimeblock = appointment.time_blocks[appointment.time_blocks.length - 1];
  appointment.time_blocks.forEach((timeblock, i) => {
    let currBlock: PowerBookingTimeBlock = { ...timeblock };

    // Already processed this block in the previous group of powerbookings
    if (skipBlock?.id === currBlock.id) return;
    skipBlock = null;

    const nextBlock: PowerBookingTimeBlock = { ...appointment.time_blocks[i + 1] };
    const hasTimeGap = (nextBlock.local_end && currBlock.local_end !== nextBlock.local_start);

    // If there is a gap between this and the last block, it is a power booking
    // If not, but there's padding time, it's still power booking, but without processing time.
    // TODO: Backend Bug: https://styleseat.atlassian.net/browse/BB-1603
    if (hasTimeGap || currBlock.service_padding_time) {
      const powerBookingId = `${appointment.id}:${powerBookingIndex}`;
      powerBookingIndex++;

      // Initial block
      nextBlock.powerBookingType = PowerBookingType.Busy;
      currBlock.powerBookingDesc = PowerBookingDesc.Initial;
      currBlock.powerBookingId = powerBookingId;
      timeblocks.push(currBlock);

      // Processing block
      if (hasTimeGap) {
        timeblocks.push({
          ...currBlock,
          powerBookingId,
          powerBookingType: PowerBookingType.Free,
          powerBookingDesc: PowerBookingDesc.Processing,
          local_start: currBlock.local_end,
          local_end: nextBlock.local_start,
        });

        // Finishing block
        nextBlock.powerBookingType = PowerBookingType.Busy;
        nextBlock.powerBookingDesc = PowerBookingDesc.Finishing;
        nextBlock.powerBookingId = powerBookingId;
        timeblocks.push(nextBlock);
        currBlock = nextBlock;
        skipBlock = nextBlock;
      }

      // Cleanup - only added if it's the last timeblock
      if (lastTimeblock.id === currBlock.id || lastTimeblock.id === nextBlock.id) {
        const cleanupEnd = moment(currBlock.local_end)
          .add(currBlock.service_padding_time, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss');
        timeblocks.push({
          ...currBlock,
          powerBookingId,
          powerBookingType: PowerBookingType.Busy,
          powerBookingDesc: PowerBookingDesc.Cleanup,
          local_start: currBlock.local_end,
          local_end: cleanupEnd,
        });
      }
    } else {
      timeblocks.push(currBlock);
    }
  });

  return [...timeblocks];
};

/**
 * Calculates and returns all timeblocks
 *
 * @param {ProAppointmentIgnoringProvider} appointment
 * @returns {PowerBookingTimeBlock[]}
 */
export const getAllTimeBlocks = (
  appointment: ProAppointmentIgnoringProvider,
): PowerBookingTimeBlock[] => {
  if (!isPowerBooked(appointment)) {
    return [...(appointment.time_blocks || [])];
  }

  return getPowerBookingTimeBlocks(appointment) || [];
};

interface DiscountObject {
  actualCost?: number;
  discount?: number;
  discountQuantity?: number;
  discountType?: ProPromotion['discount_type'];
}

export interface ServiceObject extends DiscountObject {
  id: number;
  service_name: string;
}

const isProPromotionDiscount = (
  discount: ProAppointmentListPromotionDiscount | ProPromotionDiscount,
): discount is ProPromotionDiscount => !!(discount as ProPromotionDiscount)?.pro_promotion;

export function getDiscountObjectFromTimeBlock(
  block: Pick<TimeBlock, 'service_cost_cents'>,
  discountObj: ProAppointmentListPromotionDiscount | ProPromotionDiscount | null | undefined,
): DiscountObject {
  let actualCost = block.service_cost_cents / 100;
  let discount;
  let discountQuantity: number = 0;
  let discountType: ProPromotion['discount_type'] = PromotionDiscountType.Amount;
  if (discountObj) {
    actualCost = Number(discountObj.new_cost);
    discount = Number(discountObj.old_cost) - actualCost;

    if (isProPromotionDiscount(discountObj) && typeof discountObj.pro_promotion !== 'number') {
      discountQuantity = Number(discountObj.pro_promotion.discount_quantity);
      discountType = discountObj.pro_promotion.discount_type;
    } else {
      discountQuantity = discount;
      discountType = PromotionDiscountType.Amount;
    }
  }
  return {
    discount,
    actualCost,
    discountQuantity,
    discountType,
  };
}

function getServiceObjectFromTimeBlock(
  block: TimeBlock,
  discountObj: ProAppointmentListPromotionDiscount | null | undefined,
): ServiceObject {
  return {
    ...getDiscountObjectFromTimeBlock(block, discountObj),
    service_name: block.service_name,
    id: block.id,
  };
}

/**
 * Get the list of services objects to display for this appointment.
 * Power booked timeblocks will be consolidated to 1 service.
 * Discounts will be added to each service object
 */
export const getServiceObjects = (appointment: ProAppointmentIgnoringProvider): ServiceObject[] => {
  const timeblocks: PowerBookingTimeBlock[] = getAllTimeBlocks(appointment);
  const discounts = appointment.pro_promotion_discounts || [];

  // Get all unique timeblock items
  const serviceItems = [
    ...(new Map(
      timeblocks.map(block => [
        block.powerBookingId || block.id,
        block,
      ]),
    ).values()),
  ];

  // Add discounts
  return serviceItems.map(block => {
    const discountObj = discounts.find(d => d.service === block.service);
    return getServiceObjectFromTimeBlock(block, discountObj);
  });
};

/**
 * Gets the service ids for all the timeblocks, removing duplicates from power booking
 *
 * @param timeblocks
 * @returns
 */
export const getServiceIdsFromTimeBlocks = (
  timeblocks: BaseTimeBlock[],
): number[] => {
  const serviceIds: number[] = [];

  let skipBlock: BaseTimeBlock | null;

  timeblocks.forEach((timeblock, i) => {
    const currBlock: BasePowerBookingTimeBlock = { ...timeblock };

    // Already processed this block in the previous group of powerbookings
    if (skipBlock?.local_start === currBlock.local_start) return;
    skipBlock = null;

    const nextBlock: BasePowerBookingTimeBlock = { ...timeblocks[i + 1] };
    const hasTimeGap = (nextBlock.local_end && currBlock.local_end !== nextBlock.local_start);

    if (hasTimeGap) {
      skipBlock = nextBlock;
    }
    serviceIds.push(currBlock.service);
  });

  return serviceIds;
};

/**
 * Gets the number of minutes to add as buffer time at the end of the given appointment.
 * @param appointment The appointment in question
 */
export function getEndPaddingTime(timeblocks: TimeBlock[]): number {
  let paddingTime: number = 0;
  // Add padding time
  if (timeblocks?.length) {
    paddingTime = (
      timeblocks[timeblocks.length - 1].service_padding_time
    ) || 0;
  }

  return paddingTime;
}

export function getTimeRange(
  appointment: ProAppointmentIgnoringProvider,
): { start: Moment; end: Moment } {
  const {
    local_start,
    local_end,
    time_blocks = [],
  } = appointment;
  const paddingTime = getEndPaddingTime(time_blocks);

  const start = moment(local_start);
  const end = moment(local_end).add(paddingTime, 'minutes');

  return { start, end };
}
