import { SegmentEventData } from './types';

// TODO: According to kunal<kunal@styleseat.com>, this should be a attribute for Search Started
// which is an event not yet in Segment
const ClientSearchPopoverServiceItemClicked: SegmentEventData = {
  eventName: 'client_search_popover_service_item_clicked',
  eventProperties: [
    'query',
  ],
};

export default ClientSearchPopoverServiceItemClicked;
