import { ssFetchJSON } from '../../../modules/ssFetch';
import {
  InvoiceItemType,
  Payout,
  Transaction,
} from '../../Payouts/Payouts';

export type PayoutWithoutTransactions = Omit<Payout, 'transactions'>;
export interface TransactionWithPayout extends Transaction {
  payout: PayoutWithoutTransactions;
}

export type AppointmentTransactionResponse = Partial<
Record<InvoiceItemType, TransactionWithPayout[]>
>;

export function fetchAppointmentTransactions(
  providerId: number,
  appointmentId: number,
): Promise<AppointmentTransactionResponse> {
  return ssFetchJSON<AppointmentTransactionResponse>(
    `/api/v2/providers/${providerId}/appointments/${appointmentId}/transactions`,
  );
}
