// @ts-strict-ignore
import {
  Client,
  NCDState,
  ProAppointment,
} from '../../../../api/Providers/Appointments';
import { getNCDDetails } from '../../../Api';
import Currency from '../../../Currency';
import FeatureFlags from '../../../FeatureFlags';
import nonCriticalException from '../../../exceptionLogger';
import { ProviderPlanConfiguration } from '../../ProviderPlanConfiguration';
import { NCDExperience } from './NCD.types';
import { USE_CLIENT_NCC_STATE_SWITCH_NAME } from './constants';

export const checkShouldAdjudicateNCD = (
  appointment: ProAppointment | null,
  appointmentsAdjudicated: Record<number, number> = {},
  experience: NCDExperience,
): boolean => {
  if (!appointment) {
    return false;
  }

  const {
    discount_code_redemption: discountCodeRedemption,
    has_ncd_fee_payment_attempt: hasNcdFeePaymentAttempt,
    is_cancelled: isCancelled,
    is_monetized_new_client_delivery: isMonetizedNewClientDelivery,
    id,
  } = appointment;

  const qualified = experience !== NCDExperience.Version1;

  const recorded = !!appointmentsAdjudicated[id];

  const shouldAdjudicate = !discountCodeRedemption
    && !hasNcdFeePaymentAttempt
    && isMonetizedNewClientDelivery
    && !isCancelled;

  return qualified && !recorded && shouldAdjudicate;
};

type MinimalProAppointment = Pick<ProAppointment, 'id' | 'is_monetized_new_client_delivery' | 'client'>;

export function shouldShowClientNCDControls(
  appointment: MinimalProAppointment | null,
): boolean {
  if (!appointment) {
    return false;
  }

  const ncd = appointment.client?.ncd;

  if (ncd) {
    return (
      ncd.state === NCDState.Chargeable
      || ncd.state === NCDState.Pending
      || (ncd.state === NCDState.Charged && ncd.charged_appointment_id === appointment.id)
    );
  }

  return false;
}

export function shouldShowNCDControls(
  appointment: MinimalProAppointment | null,
  additionalCheck?: boolean,
): boolean {
  if (!appointment) {
    return false;
  }

  if (FeatureFlags.isEnabled(USE_CLIENT_NCC_STATE_SWITCH_NAME)) {
    // post HZ-1 approach
    return shouldShowClientNCDControls(appointment);
  }

  // backward compatibility prior to HZ-1
  return appointment.is_monetized_new_client_delivery && additionalCheck !== false;
}

/**
 * Gets a human-friendly string containing a phrase describing the new client
 * fee, including the max fee and percentage. The returned text is meant to be wrapped in a
 * larger phrase such as "a {fee phrase} will be added to each appointment".
 * @param planConfiguration The plan configuration to pull from
 * @returns A string containing the fee phrase
 */
export function getNCDFeeText(
  planConfiguration?: ProviderPlanConfiguration,
): string {
  let feeText: string = '';

  if (planConfiguration?.newClientFeePercent) {
    const fee = parseFloat(Number(planConfiguration.newClientFeePercent).toFixed(2));
    feeText = ` ${fee}%`;
  }

  if (planConfiguration?.newClientFeeMax) {
    const formattedMax = Currency.shortFormat(planConfiguration.newClientFeeMax);
    feeText += ` (up to ${formattedMax})`;
  }

  return `one-time${feeText} New Client Fee`;
}

export async function resolveNCDSource(
  appointmentId: number,
  providerId: number,
  client: Client,
): Promise<string> {
  const useClientNccStateSwitch = await FeatureFlags.isEnabled(USE_CLIENT_NCC_STATE_SWITCH_NAME);

  if (useClientNccStateSwitch) {
    return client?.ncd?.source;
  }

  try {
    // Could be a 204, so we don't want to throw an error if is the case
    return (await getNCDDetails(providerId, appointmentId))?.source;
  } catch (e) {
    nonCriticalException(e);
    return undefined;
  }
}
