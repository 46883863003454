import './bundles/libs';
import './bundles/shared/styles';
import { appVersionInterceptor } from './api/interceptors/appVersion';
import { authRedirectInterceptor } from './api/interceptors/authRedirect';
import { logRocketInterceptor } from './api/interceptors/logRocket/logRocket';
import ssFetch from './modules/ssFetch';
// We need to register w/ FCM as early and often as possible
// to avoid registration expiration problems
// so we're going to check if we need to re-register
// on every app resume and also immediate after deviceready
import universalLinks from './modules/universalLinks';
import fbAPI from './modules/fbAPI';
import storeReady from './store/ready';
import store from './store';
import {
  APP_VERSION,
  APP_STORE_ID,
  PLAY_STORE_ID,
} from './modules/AppConstants';
import { setupLogRocket } from './bootstrap/logRocket';
import initTrackingId from './modules/initTrackingId';
import { bootstrap as bootstrapReact } from './bootstrap/react-init';
import analytics from './modules/analytics';
import { brazeTracker } from './modules/analytics/BrazeTracker';
import { init as initAppsFlyer } from './modules/appsFlyer';
import { listenForGoogleAuthViaIFrame } from './modules/provider/Calendar/ExternalCalendar';

window.APP_VERSION = APP_VERSION;
window.console = console || {
  log() { },
};

window.APP_STORE_ID = APP_STORE_ID;
window.PLAY_STORE_ID = PLAY_STORE_ID;

// initialize and mount our main React app
bootstrapReact();

// eslint-disable-next-line func-names
(function () {
  const bootstrap = () => {
    // Initialize tracking cookie
    initTrackingId(store).then();

    // Initialize Facebook
    fbAPI.init();

    setupLogRocket();

    ssFetch.intercept(appVersionInterceptor);
    ssFetch.intercept(authRedirectInterceptor);
    ssFetch.intercept(logRocketInterceptor);

    analytics.initialize(brazeTracker);

    // App-only initializations
    if (window.cordova) {
      // Connect the in-app browser plugin
      window.open = window.cordova.InAppBrowser.open;

      // doing this after monkey-patching `window.open` as we might get shuffled off somewhere
      universalLinks.appInit((eventName, params) => (
        analytics.track(eventName, params)
      ));
    } else {
      listenForGoogleAuthViaIFrame();
    }
  };

  const bootWhenReady = () => {
    bootstrap();

    /**
     * This is primarily waiting for the redux store to load the cached user data
     * from LocalForage. AppsFlyer uses the store to determine user role, so
     * it needs to wait on storeReady. The rest of the bootstrapping code has
     * no such dependencies.
     */
    storeReady.then(() => {
      if (window.cordova) {
        // Initialize AppsFlyer
        initAppsFlyer();
      }
    });
  };

  if (window.cordova) {
    document.addEventListener('deviceready', bootWhenReady, false);

    // Store the legacy deep link if we receive it before
    // app initialization. See: deepLinkService
    window.handleOpenURL = url => {
      localStorage.setItem('DEEP_LINK_URL', url);
    };
  } else {
    window.addEventListener('load', bootWhenReady, false);
  }
}());
