import { useMemo, useState } from 'react';
import debouncer from '../modules/debouncer';

type UseDebouncedStateResult<T = any> = {
  immediateValue: T,
  delayedValue: T,
  setValue: (newValue: T) => void,
};

export function useDebouncedState<T = any>(
  defaultValue: T,
  delayMS: number = 300,
): UseDebouncedStateResult<T> {
  const debounce = useMemo(() => debouncer(delayMS), [delayMS]);

  const [immediateValue, setImmediateValue] = useState<T>(defaultValue);
  const [delayedValue, setDelayedValue] = useState<T>(defaultValue);

  const setValue = (value: T) => {
    setImmediateValue(value);
    debounce(() => {
      setDelayedValue(value);
    });
  };

  return {
    immediateValue,
    delayedValue,
    setValue,
  };
}
