// @ts-strict-ignore
import TalkJS from 'talkjs';
import { TALKJS_APP_ID } from '../../config';
import { ChatRoles } from './types';
import type { State as User } from '../../store/CurrentUser.types';
import analytics from '../analytics';
import { talkJsIdToStyleSeatId } from './utils';

class ChatManager {
  chatUser: TalkJS.User;
  conversation: TalkJS.ConversationBuilder;
  session: TalkJS.Session;
  unreadConversationsCounter: number;
  unreadConversations: TalkJS.UnreadConversation[];
  talkReady: boolean;

  constructor() {
    this.chatUser = undefined;
    this.conversation = undefined;
    this.session = undefined;
    this.unreadConversationsCounter = 0;
    this.unreadConversations = [];
    this.talkReady = false;
  }

  createChatUser(currentUser: User, chatRole: ChatRoles, talkJsUserId: string) {
    if (chatRole === this.chatUser?.role) {
      return this.chatUser;
    }
    this.chatUser = new TalkJS.User({
      id: talkJsUserId,
      name: `${currentUser.first_name} ${currentUser.last_name}`,
      email: currentUser.email,
      role: chatRole,
    } as TalkJS.UserOptions);
    return this.chatUser;
  }

  async getOrCreateSession(
    chatUser: TalkJS.User,
    onUnreadChangeCallback?: (unreadCounter: number) => void,
  ): Promise<TalkJS.Session> {
    if (chatUser && !this.session) {
      this.session = new TalkJS.Session({
        appId: TALKJS_APP_ID,
        me: chatUser,
      });
    }
    this.session.unreads.onChange(unreadConversations => {
      this.unreadConversationsCounter = unreadConversations.length;
      onUnreadChangeCallback?.(unreadConversations.length);
      this.unreadConversations = unreadConversations;
    });
    return this.session;
  }

  async createOneOnOneConversation(user1: TalkJS.User, user2: TalkJS.User): Promise<string> {
    const chatId = TalkJS.oneOnOneId(user1, user2);
    const conversation = await this.session.getOrCreateConversation(chatId);
    await conversation.setParticipant(user1);
    await conversation.setParticipant(user2);
    if (user1.role === 'provider') {
      analytics.track('conversation_created', {
        provider_id: String(user1.id),
        client_user_id: talkJsIdToStyleSeatId(user2.id),
        conversation_id: chatId,
      });
    } else {
      analytics.track('conversation_created', {
        provider_id: String(user2.id),
        client_user_id: talkJsIdToStyleSeatId(user1.id),
        conversation_id: chatId,
      });
    }
    this.conversation = conversation;
    return chatId;
  }

  async blockUser(
    conversationId: string,
    blockedBy: TalkJS.User,
    blockedUser: TalkJS.User,
  ): Promise<void> {
    const conversation = await this.session.getOrCreateConversation(conversationId);
    await conversation.setAttributes({
      custom: {
        blocked: 'true',
        blocked_by: String(blockedBy.id),
      },
    });
    await conversation.setParticipant(blockedUser, { access: 'Read' });
    await conversation.setParticipant(blockedBy, { access: 'Read' });
    this.conversation = conversation;
  }

  async setCustomAttributes(
    conversationId: string,
    custom: {},
  ): Promise<void> {
    const conversation = await this.session.getOrCreateConversation(conversationId);
    await conversation.setAttributes({ custom });
    this.conversation = conversation;
  }

  async unblockUser(
    conversationId: string,
    unblockedBy: TalkJS.User,
    unblockedUser: TalkJS.User,
  ): Promise<void> {
    const conversation = await this.session.getOrCreateConversation(conversationId);
    await conversation.setAttributes({
      custom: {
        blocked: 'false',
        blocked_by: '',
      },
    });
    await conversation.setParticipant(unblockedUser, { access: 'ReadWrite' });
    await conversation.setParticipant(unblockedBy, { access: 'ReadWrite' });
    this.conversation = conversation;
  }

  async sendWelcomeMessage(): Promise<string> {
    const styleSeatBot = new TalkJS.User({
      id: 'the-styleseat-bot',
      name: 'StyleSeat',
      role: ChatRoles.Moderator,
      email: 'fake-email@styleseat.com',
    });
    const chatId = TalkJS.oneOnOneId(this.chatUser, styleSeatBot);

    const conversation = await this.session.getOrCreateConversation(chatId);

    await conversation.setAttributes({
      subject: 'Meet your new inbox!',
      welcomeMessages: [
        `Hey ${this.chatUser.name}! Meet your new inbox.

Endless calls, texts, and DMs can be overwhelming.

With your new inbox, you can consolidate your client messages in one easy to manage place.

Two major benefits:

- Clients can now send inspiration photos to help you chat through their next look.

- You’ll have record of your conversations and chat history to refer to later on.`,
      ],
    });
    await conversation.setParticipant(this.chatUser);
    await conversation.setParticipant(styleSeatBot);
    this.conversation = conversation;

    return chatId;
  }

  cleanUp(): void {
    this.session?.destroy();
    this.session = undefined;
    this.conversation = undefined;
    this.chatUser = undefined;
  }

  async setCurrentConversation(chatId?: string, newChatObject?: TalkJS.ConversationBuilder):
  Promise<void> {
    if (chatId) {
      if (this.session?.getOrCreateConversation) {
        const newConversation = await this.session.getOrCreateConversation(chatId);
        this.conversation = newConversation;
      }
    }
    if (newChatObject) {
      this.conversation = newChatObject;
    }
  }

  async getAndSetTalkReady(): Promise<void> {
    await TalkJS.ready;
    this.talkReady = true;
  }
}
const currentChatSession = new ChatManager();

export default currentChatSession;

const validateHiddenInformation = (
  text: string,
  event: {
    sender: { role: string };
    senderId: string;
    isByMe: boolean;
  },
) => {
  const competitorsWords = /|booksy|acuity|vagaro|fresha|square|glossgenius|/;
  const regexCompetitors = new RegExp(`\\b(${competitorsWords})\\b`, 'g');
  let applyPhoneValidation = false;
  let applyUrlsValidation = false;
  const sentByClient = event.senderId.includes('client') && !event.isByMe;
  const textWithoutDots = text.replace('.', '');

  // Validate phone numbers sent by client in pro side
  if (event?.sender?.role === 'client' && sentByClient) {
    const phoneRegex = /(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}/g;
    applyPhoneValidation = phoneRegex.exec(text)?.length > 0;
  }
  if (event?.sender?.role === 'provider') {
    const competitorsUrls = new RegExp(`(https?://)?(www\\.)?(${competitorsWords})(/[^\\s]*)?`, 'gi');
    applyUrlsValidation = competitorsUrls.exec(text)?.length > 0;
  }
  return (
    regexCompetitors.exec(textWithoutDots)?.length > 0
    || applyUrlsValidation
    || applyPhoneValidation
  );
};

export const addHiddenMessageListener = (
  chatSession: TalkJS.Session,
  setDisplayHiddenInfoBannerWrapper: (displayHiddenBanner: boolean) => void,
  conversationId: string | undefined,
): TalkJS.Subscription => {
  const subscription = chatSession?.onMessage(async event => {
    const displayBanner = validateHiddenInformation(event.body, event);
    if (event.conversation.id === conversationId) {
      const displayForProOnly = (
        event.sender.role === 'client'
        && !event.isByMe
        && event?.conversation?.custom?.show_broken_rule_banner_pro === 'true'
      );
      setDisplayHiddenInfoBannerWrapper(displayForProOnly || displayBanner);
    }
  });
  return subscription;
};
