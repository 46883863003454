import moment from 'moment';
import { DateTimeStamp } from '../../../types/dateTime';
import { UNIT_OF_TIME } from './constants';

export function shouldHideEligibilityNotification(
  profileSearchBoostEligibleDate?: DateTimeStamp,
): boolean {
  return !!profileSearchBoostEligibleDate;
}

export function shouldHideExtendEligibilityNotification(
  profileSearchBoostEarningEndDate: DateTimeStamp | undefined,
  profileSearchBoostExtensionCreationTime: DateTimeStamp | undefined,
): boolean {
  const deadlineToExtend = moment.utc(
    profileSearchBoostEarningEndDate,
  ).add(7, UNIT_OF_TIME);
  const eligibleToExtendBoostExpired = moment.utc() > deadlineToExtend;

  return !!profileSearchBoostExtensionCreationTime || eligibleToExtendBoostExpired;
}
