// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { getAvailability, ProviderAvailability } from '../../api/Providers/Availability';
import { editAvailability, providerAvailabilityToPayload } from '../../api/Providers/EditAvailability';
import { clearCache } from '../../api/Providers/WorkPeriods';
import { fetchPaymentSettings } from '../../modules/provider/PaymentSettings';
import { IPaymentSettingsV3 } from '../../modules/provider/PaymentSettings.types';
import type { RootModel } from '../models';

export type ProviderEditAvailabilityState = {
  providerId: number | null;
  loading: boolean;
  saving: boolean;
  availability: ProviderAvailability | null;
  errors: { [errorField: string]: string } | null;
  canToggleOnlineBookable: boolean | null;
};

type LandPayload = {
  providerId: number;
};

type SavePayload = {
  providerId: number;
  availability: ProviderAvailability;
};

type OnLandingPayload = {
  providerId: number;
};

type OnSavingPayload = {
  providerId: number;
};

type OnSavedPayload = {
  providerId: number;
  availability?: ProviderAvailability;
};

type OnErroredPayload = {
  providerId: number;
  errors: { [errorField: string]: string };
};

type OnLandedPayload = {
  providerId: number;
  availability: ProviderAvailability;
  paymentSettings: IPaymentSettingsV3;
};

function getInitialState(): ProviderEditAvailabilityState {
  return {
    loading: false,
    saving: false,
    providerId: null,
    availability: null,
    errors: null,
    canToggleOnlineBookable: null,
  };
}

export const providerEditAvailability = createModel<RootModel>()({
  name: 'providerEditAvailability',

  state: getInitialState(),

  reducers: {
    'user/logout': () => getInitialState(),

    onLanding: (
      state: ProviderEditAvailabilityState,
      { providerId }: OnLandingPayload,
    ): ProviderEditAvailabilityState => ({
      ...state,
      providerId,
      errors: null,
      loading: true,
    }),

    onLanded: (
      state: ProviderEditAvailabilityState,
      {
        providerId,
        availability,
        paymentSettings,
      }: OnLandedPayload,
    ): ProviderEditAvailabilityState => {
      if (providerId !== state.providerId) {
        return state;
      }

      return ({
        ...state,
        providerId,
        availability,
        canToggleOnlineBookable: !paymentSettings.on_commission_plan,
        loading: false,
      });
    },

    onSaving: (
      state: ProviderEditAvailabilityState,
      { providerId }: OnSavingPayload,
    ): ProviderEditAvailabilityState => ({
      ...state,
      providerId,
      errors: null,
      saving: true,
    }),

    onSaved: (
      state: ProviderEditAvailabilityState,
      { providerId, availability }: OnSavedPayload,
    ): ProviderEditAvailabilityState => {
      if (providerId !== state.providerId) return state;

      return ({
        ...state,
        providerId,
        availability,
        errors: null,
        saving: false,
      });
    },

    onErrored: (
      state: ProviderEditAvailabilityState,
      { providerId, errors }: OnErroredPayload,
    ): ProviderEditAvailabilityState => {
      if (providerId !== state.providerId) return state;

      return ({
        ...state,
        providerId,
        errors,
        saving: false,
      });
    },
  },

  effects: dispatch => ({
    async land({ providerId }: LandPayload) {
      dispatch.providerEditAvailability.onLanding({ providerId });

      try {
        const [availability, paymentSettings] = await Promise.all([
          getAvailability(providerId),
          fetchPaymentSettings(providerId),
        ]);

        dispatch.providerEditAvailability.onLanded({
          providerId,
          availability,
          paymentSettings,
        });
      } catch (e) {
        dispatch.providerEditAvailability.onErrored({
          providerId,
          errors: e as any,
        });
      }
    },

    async save({ providerId, availability }: SavePayload) {
      dispatch.providerEditAvailability.onSaving({ providerId });
      const savePayload = providerAvailabilityToPayload(availability);

      try {
        const result = await editAvailability(providerId, savePayload);
        clearCache();
        if (result.status === 200) {
          dispatch.providerEditAvailability.onSaved({
            providerId,
            availability,
          });
        } else {
          dispatch.providerEditAvailability.onErrored({
            providerId,
            errors: result as any,
          });
        }
      } catch (e) {
        dispatch.providerEditAvailability.onErrored({
          providerId,
          errors: e,
        });
      }
    },
  }),

  selectors: slice => ({
    availability: () => (
      slice((state: ProviderEditAvailabilityState) => state.availability)
    ),

    saving: () => (
      slice((state: ProviderEditAvailabilityState) => state.saving)
    ),

    loading: () => (
      slice((state: ProviderEditAvailabilityState) => state.loading)
    ),

    errors: () => (
      slice((state: ProviderEditAvailabilityState) => state.errors)
    ),

    canToggleOnlineBookable: () => (
      slice((state: ProviderEditAvailabilityState) => state.canToggleOnlineBookable)
    ),
  }),
});
