// @ts-strict-ignore
import URI from 'urijs';
import { API_ROOT } from '../../../config';
import ssFetch, { ssFetchJSON, IResponse } from '../../../modules/ssFetch';
import { IProviderClient, IProviderClientListEntry } from './types';
import { StatusCodeError } from '../../../modules/statusCode';
import { IDRFResponse } from '../../../types';
import { parseResponse } from '../../../modules/Api';
import SimpleCache from '../../../modules/SimpleCache';
import {
  disallowBlank,
  filterEmptyFields,
  getPhonesList,
  PartialProviderClient,
  PartialProviderClientWithId,
} from '../../../modules/provider/Client';

function createClientPayload(
  client: PartialProviderClient,
) {
  let payload: PartialProviderClient = {
    address1: disallowBlank(client.address1),
    address2: disallowBlank(client.address2),
    birth_day: client.birth_day,
    birth_month: client.birth_month,
    city: disallowBlank(client.city),
    creation_source: client.creation_source,
    email: client.email,
    id: client.id,
    name: client.name,
    phones: getPhonesList(client),
    preferred_pronouns: client.preferred_pronouns,
    reminder_preference: client.reminder_preference,
    state: disallowBlank(client.state),
    zipcode: disallowBlank(client.zipcode),
  };

  payload = filterEmptyFields(payload);

  return payload;
}

async function submitUpdateClient(
  providerId: number | string,
  clientPayload: PartialProviderClientWithId,
): Promise<IProviderClient> {
  try {
    const url = `${API_ROOT}/api/v2/providers/${providerId}/clients/${clientPayload.id}`;
    const opts = {
      method: 'PATCH',
      body: clientPayload,
    };
    const response = await ssFetch(url, opts);

    if (!response.ok) {
      throw new StatusCodeError(response.status);
    }

    return await response.json();
  } catch (err) {
    if (err?.status === 0) {
      throw new StatusCodeError(0);
    }

    throw err;
  }
}

export async function updateClient(
  providerId: number | string,
  client: PartialProviderClientWithId,
): Promise<IProviderClient> {
  return submitUpdateClient(
    providerId,
    {
      id: client.id,
      ...createClientPayload(client),
    },
  );
}

export async function blockClient(
  providerId: number | string,
  clientId: number,
): Promise<Partial<IProviderClient>> {
  return submitUpdateClient(
    providerId,
    {
      id: clientId,
      blocked: true,
    },
  );
}

export async function unblockClient(
  providerId: number | string,
  clientId: number,
): Promise<Partial<IProviderClient>> {
  return submitUpdateClient(
    providerId,
    {
      id: clientId,
      blocked: false,
    },
  );
}

const clientRequestCache = new SimpleCache(1); // cache for 1 second to avoid duplicate requests
const allClientRequestCache = new SimpleCache(); // cach for default 10 seconds

/**
 * Get a single pro client from the server.
 *
 * @param {Number} providerId
 * @param {Number} clientId
 * @returns {Promise} resolves with client object
 */
export async function getClient(
  providerId: number | string,
  clientId: number | string,
): Promise<IProviderClient> {
  return ssFetchJSON<IProviderClient>(
    `/api/v2/providers/${providerId}/clients/${clientId}`,
    {
      ssCache: clientRequestCache,
      throwOnHttpError: true,
    },
  );
}

/**
 * Get the pro's full client list from the server.
 * When successful, the client list will be saved to the device and pushed to the client stream.
 *
 * @param providerId
 * @param url URL for subsequent request pages
 * @returns resolves with full client list
 */
export async function getAllClients(
  providerId: number,
  {
    url,
    queryParams,
  }: {
    url?: string;
    queryParams?: { page?: number; size?: number | string };
  } = {
    queryParams: { size: 'all' },
  },
): Promise<IDRFResponse<IProviderClient>> {
  const uri = new URI(`${API_ROOT}/api/v2/providers/${providerId}/clients`);

  const params = { ...queryParams };
  if (!params.size) {
    params.size = 'all';
  }

  uri.addQuery(params);
  return ssFetchJSON<IDRFResponse<IProviderClient>>(
    url || uri.toString(),
    {
      ssCache: allClientRequestCache,
    },
  );
}
/**
 * Get the pro's paginated client list
 *
 * @param providerId
 * @param params
 * @param params.page The page of results to fetch
 * @param params.is_trusted Filter results by whether the clients are trusted or not
 * @returns resolves with client list results
 */
export async function getClients(
  providerId: number,
  params?: {
    page?: number;
    is_trusted?: boolean;
  },
): Promise<IDRFResponse<IProviderClientListEntry>> {
  const uri = new URI(`${API_ROOT}/api/v2/providers/${providerId}/my-clients`);
  uri.addQuery(params);

  return ssFetchJSON<IDRFResponse<IProviderClientListEntry>>(
    uri.toString(),
  );
}
/**
 * Update trusted clients
 *
 * @param providerId
 * @param clientsPayload
 * @param clientsPayload.add_trusted_clients Client ids to add to the trusted client list
 * @param clientsPayload.remove_trusted_clients Client ids to remove from trusted clients list
 * @returns null
 */
export async function updateTrustedClients(
  providerId: number,
  clientsPayload: {
    add_trusted_clients: number[];
    remove_trusted_clients: number[];
  },
): Promise<null> {
  return ssFetchJSON<null>(
    `/api/v2/providers/${providerId}/trusted-clients`,
    {
      method: 'POST',
      body: clientsPayload,
      throwOnHttpError: true,
    },
  );
}

/**
 * Clears the cache of all clients requests
 */
export function clearAllClientsCache() {
  allClientRequestCache.clear();
}

export async function deleteClient(
  providerId: number,
  clientId: number,
): Promise<void> {
  await ssFetch(
    `/api/v2/providers/${providerId}/clients/${clientId}`,
    {
      method: 'DELETE',
    },
  ).catch((response: IResponse<void>) => {
    parseResponse(response);
  });
}

export async function createMultipleClients(
  providerId: number,
  clients: Array<PartialProviderClient>,
): Promise<Array<IProviderClient>> {
  const clientsPayload = clients.map(c => createClientPayload(c));
  return ssFetchJSON<Array<IProviderClient>>(
    `/api/v2/providers/${providerId}/clients`,
    {
      method: 'POST',
      body: clientsPayload,
      throwOnHttpError: true,
    },
  );
}
