// @ts-strict-ignore
import { parseResponse } from '../../modules/Api';
import nonCriticalException from '../../modules/exceptionLogger';
import ssFetch, { ssFetchJSON } from '../../modules/ssFetch';
import { IntakeFormV1 } from '../IntakeForms/contractV1';

export enum FormAudienceOptions {
  All = 'all',
  Returning = 'returning',
  New = 'new',
}

export type FormSettings = {
  enabled: boolean;
  audience: FormAudienceOptions;
};

export async function saveFormSettings(
  providerId: number | string,
  settings: Partial<FormSettings>,
): Promise<any> {
  const response = await ssFetch(
    `/api/v1/providers/${providerId}/form-settings`,
    {
      body: settings,
      method: 'PATCH',
    },
  );
  return parseResponse(response);
}

export async function fetchFormSettings(
  providerId: number | string,
): Promise<FormSettings> {
  return ssFetchJSON<FormSettings>(
    `/api/v1/providers/${providerId}/form-settings`,
  );
}

export async function fetchFormWithAnswers(
  formId: number | string,
): Promise<IntakeFormV1> {
  try {
    return await ssFetchJSON<IntakeFormV1>(
      `/api/v1/form/${formId}`,
    );
  } catch (err) {
    // 404 is expected if the form has not been filled out yet, so don't log those
    if (err && err.status !== 404) {
      nonCriticalException(err);
    }
    return null;
  }
}
