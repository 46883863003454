// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { StoreSelectors } from '@rematch/select';
import produce from 'immer';
import { PrivilegedProvider } from '../../../../api/Providers';
import { ProAppointmentDetails } from '../../../../api/Providers/Appointments';
import { IProviderClient } from '../../../../api/Providers/Clients/types';
import type { RootModel } from '../../../../store/models';

type State = {
  currentAppointmentId: number;
  isLoading: boolean;
};

export type AppointmentDetailsPageData = {
  appointment: ProAppointmentDetails;
  client: IProviderClient;
  provider: PrivilegedProvider;
};

type AppointmentIdPayload = { appointmentId: number };

/**
 * This model represents the pro appointment details page/feature
 * NOT specifically the backend appointment API
 */
const model = createModel<RootModel>()({
  name: 'proAppointmentDetails',

  state: {} as State,

  reducers: {
    onLoading: produce<State, [AppointmentIdPayload]>((
      state: State,
      payload: AppointmentIdPayload,
    ) => {
      const { appointmentId } = payload;
      state.currentAppointmentId = appointmentId;
      state.isLoading = true;
    }),

    onLoaded: produce<State, [AppointmentIdPayload]>((
      state: State,
      payload: AppointmentIdPayload,
    ) => {
      state.currentAppointmentId = payload.appointmentId;
      state.isLoading = false;
    }),

    onFailed: produce((
      state: State,
    ) => {
      state.isLoading = false;
    }),
  },

  effects: dispatch => ({

    /**
     * User has landed on the profile (view, not edit) page
     */
    land: async (
      payload: { appointmentId: number | string },
    ): Promise<AppointmentDetailsPageData | undefined> => {
      const { appointmentId } = payload;
      try {
        const {
          appointment,
        } = await dispatch.providerManageAppointment.land({
          appointmentId: Number(appointmentId),
        });
        if (appointment.client?.id) {
          await dispatch.providerClients.loadClient({
            providerId: appointment.provider,
            clientId: appointment.client?.id,
          });
        }
      } finally {
        dispatch.proAppointmentDetails.onLoaded({
          appointmentId: Number(appointmentId),
        });
        dispatch.loader.setIsLoading(false);
      }

      return undefined;
    },
  }),

  selectors: (slice, createSelector) => ({
    appointmentDetails: (
      models: StoreSelectors<RootModel, Record<string, never>>,
    ) => createSelector(
      models.providerManageAppointment.appointment,
      models.providers.activeProvider,
      state => state.providerClients.clients,
      (appointment: ProAppointmentDetails, provider, clients): AppointmentDetailsPageData => ({
        appointment: appointment as ProAppointmentDetails,
        provider: provider as PrivilegedProvider,
        client: clients?.[appointment?.client?.id],
      }),
    ),

    isLoading: () => createSelector(
      slice(state => state.currentAppointmentId),
      slice(state => state.isLoading),
      state => state.route.params?.viewApptId,
      (loadedAppointmentId, isLoading, viewingApptId) => (
        isLoading
        || loadedAppointmentId !== Number(viewingApptId)
      ),
    ),
  }),
});

export default model;
