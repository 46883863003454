// @ts-strict-ignore
import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';
import nonCriticalException from '../../../modules/exceptionLogger';
import SimpleCache from '../../../modules/SimpleCache';

import type { ProductCategoryShort } from './ProductCategories';
import { DateTimeStamp } from '../../../types/dateTime';

/**
 * Data as returned from the ProviderProducts serializer
 */
export type ProviderProduct = {
  id: number;
  name: string;
  price: number;
  description: string;
  product_category: ProductCategoryShort;
  created_at: DateTimeStamp;
};

export type SaveProviderProduct = Partial<Omit<ProviderProduct, 'product_category' | 'created_at'>> & {
  product_category_id: number;
};

export type ProviderProductCategory = {
  id: number;
  name: string;
};

export type AppointmentProduct = {
  id: number;
  creation_time: string;
  product: ProviderProduct;
  deletion_time: string;
  name: string;
  price: number;
};

export type EditingAppointmentProduct = AppointmentProduct & {
  type?: 'existing' | 'new';
};

export const getProviderProducts = async (
  providerId: number | string,
  cache?: SimpleCache,
): Promise<ProviderProduct[]> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/products`);

  try {
    return await ssFetchJSON<ProviderProduct[]>(
      url.toString(),
      {
        method: 'GET',
        ...(cache && { ssCache: cache }),
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: 'getProviderProducts: Failed to retrieve provider products',
        providerId,
      },
    );
    return [];
  }
};

export const addProviderProduct = async (
  providerId: number | string,
  providerProduct: SaveProviderProduct,
): Promise<ProviderProduct | {}> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/products`);

  try {
    return await ssFetchJSON<ProviderProduct>(
      url.toString(),
      {
        method: 'POST',
        body: providerProduct,
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: e,
        providerId,
      },
    );
    return e;
  }
};

export const editProviderProduct = async (
  providerId: number | string,
  providerProduct: SaveProviderProduct,
): Promise<ProviderProduct | {}> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/products/${providerProduct.id}`);

  try {
    return await ssFetchJSON<ProviderProduct>(
      url.toString(),
      {
        method: 'PATCH',
        body: providerProduct,
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: e,
        providerId,
      },
    );
    return e;
  }
};

export const deleteProviderProduct = async (
  providerId: number | string,
  providerProductId: number | string,
): Promise<ProviderProduct | {}> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/products/${providerProductId}`);

  try {
    return await ssFetchJSON<ProviderProduct>(
      url.toString(),
      {
        method: 'DELETE',
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: 'getProviderProducts: Failed to delete provider product',
        providerId,
      },
    );
    return {};
  }
};

export const getProviderProductCategories = async (
  providerId: number | string,
  cache?: SimpleCache,
): Promise<ProviderProductCategory[]> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/product-categories`);

  try {
    return await ssFetchJSON<ProviderProduct[]>(
      url.toString(),
      {
        method: 'GET',
        ...(cache && { ssCache: cache }),
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: 'getProviderProductCategories: Failed to retrieve provider product categories',
        providerId,
      },
    );
    return [];
  }
};

export const removeProductFromAppointment = async (
  providerId: number,
  appointmentId: number,
  appointmentProductIds: number[],
): Promise<void> => {
  const url = new URL(`${API_ROOT}/api/v2.1/providers/${providerId}/appointment/${appointmentId}/appointment_products/remove_list`);

  try {
    return await ssFetchJSON<void>(
      url.toString(),
      {
        method: 'POST',
        body: {
          ids: appointmentProductIds,
        },
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: 'getProviderProducts: Failed to retrieve provider products',
        providerId,
      },
    );
  }
};

export const addProductToAppointment = async (
  providerId: number,
  appointmentId: number,
  providerProductId: number[],
): Promise<void> => {
  const url = new URL(`${API_ROOT}/api/v2.1/providers/${providerId}/appointment_products`);

  try {
    return await ssFetchJSON<void>(
      url.toString(),
      {
        method: 'POST',
        body: {
          appointment_id: appointmentId,
          product_ids: providerProductId,
        },
      },
    );
  } catch (e) {
    nonCriticalException(
      e,
      {
        error: 'getProviderProducts: Failed to retrieve provider products',
        providerId,
      },
    );
  }
};
