// @ts-strict-ignore
import { createModel } from '@rematch/core';
import moment from 'moment';
import _ from 'underscore';
import {
  createPromotion,
  fetchPromotions,
  SerializedPromotion,
  Promotion,
  updatePromotion,
  fetchPromotion,
} from '../../api/Providers/Promotions';
import { getAllServices, ProviderService } from '../../api/Providers/Services';
import nonCriticalException from '../../modules/exceptionLogger';
import type { RootModel } from '../models';

export type ProviderPromotionsState = {
  providerId: number | null;
  loaded: boolean;
  updating: boolean;
  promotions: Record<number, SerializedPromotion>;
  promotionIdOrder: number[];
  services: Record<number, ProviderService>;
  selectedPromotionId: number | null;
  nextPage: number | null;
  errors: { [errorField: string]: string } | null;
};

type LandPayload = {
  providerId: number;
};

type LandSelectedPayload = {
  providerId: number;
  promotionId: string;
};

type CreatePayload = {
  providerId: number;
  promotion: Omit<Promotion, 'id'>;
};

type UpdatePayload = {
  providerId: number;
  promotion: Promotion;
};

type OnLandingPayload = {
  providerId: number;
};

type OnSavingPayload = {
  providerId: number;
};

type OnCreatedOrUpdatedPayload = {
  providerId: number;
  promotion: SerializedPromotion;
};

type OnErroredPayload = {
  providerId: number;
  errors: { [errorField: string]: string };
};

type OnResultsLoadedPayload = {
  providerId: number;
  promotions: SerializedPromotion[];
  services: ProviderService[];
  next: number | null;
};

type OnSelectedResultLoadedPayload = {
  providerId: number;
  promotion: SerializedPromotion;
  services?: ProviderService[];
};

export function formatPromotionsWithServices(
  promotion: SerializedPromotion,
  services: Record<number, ProviderService>,
): SerializedPromotion {
  return {
    ...promotion,
    services: promotion.services.map(svcId => services[svcId]).filter(service => !!service),
  };
}

function getInitialState(): ProviderPromotionsState {
  return {
    providerId: null,
    loaded: false,
    updating: false,
    promotions: {},
    promotionIdOrder: [],
    services: {},
    selectedPromotionId: null,
    nextPage: 1,
    errors: null,
  };
}

export const providerPromotions = createModel<RootModel>()({
  name: 'providerPromotions',

  state: getInitialState(),

  reducers: {
    'user/logout': () => getInitialState(),

    onLanding: (
      state: ProviderPromotionsState,
      { providerId }: OnLandingPayload,
    ): ProviderPromotionsState => ({
      ...state,
      providerId,
      errors: null,
      loaded: false,
      promotions: {},
      nextPage: 1,
      promotionIdOrder: [],
    }),

    onLandSelected: (
      state: ProviderPromotionsState,
      { providerId }: OnLandingPayload,
    ): ProviderPromotionsState => ({
      ...state,
      providerId,
      errors: null,
      loaded: false,
    }),

    onResultsLoaded: (
      state: ProviderPromotionsState,
      {
        providerId,
        promotions,
        services,
        next,
      }: OnResultsLoadedPayload,
    ): ProviderPromotionsState => {
      if (providerId !== state.providerId) {
        return state;
      }

      const promotionsById = _.indexBy(promotions, 'id');
      const serviceResultsById = _.indexBy(services, 'id');
      const promotionIdOrder = promotions.map(promo => promo.id);

      return ({
        ...state,
        providerId,
        promotions: { ...state.promotions, ...promotionsById },
        promotionIdOrder: [
          ...state.promotionIdOrder,
          ...promotionIdOrder,
        ],
        services: serviceResultsById,
        nextPage: next ? state.nextPage + 1 : null,
        loaded: true,
      });
    },

    onSelectedResultLoaded: (
      state: ProviderPromotionsState,
      {
        providerId,
        promotion,
        services,
      }: OnSelectedResultLoadedPayload,
    ): ProviderPromotionsState => {
      if (providerId !== state.providerId) {
        return state;
      }

      const serviceResultsById = services ? _.indexBy(services, 'id') : state.services;

      return ({
        ...state,
        providerId,
        promotions: { ...state.promotions, [promotion.id]: promotion },
        selectedPromotionId: promotion.id,
        services: serviceResultsById,
        loaded: true,
      });
    },

    onUpdating: (
      state: ProviderPromotionsState,
      { providerId }: OnSavingPayload,
    ): ProviderPromotionsState => ({
      ...state,
      providerId,
      errors: null,
      updating: true,
    }),

    onErrored: (
      state: ProviderPromotionsState,
      { providerId, errors }: OnErroredPayload,
    ): ProviderPromotionsState => ({
      ...state,
      providerId,
      errors,
      updating: false,
      loaded: true,
    }),

    onCreated: (
      state: ProviderPromotionsState,
      { providerId, promotion }: OnCreatedOrUpdatedPayload,
    ): ProviderPromotionsState => {
      if (providerId !== state.providerId) return state;
      return ({
        ...state,
        providerId,
        promotions: { ...state.promotions, [promotion.id]: promotion },
        promotionIdOrder: [
          promotion.id,
          ...state.promotionIdOrder,
        ],
        errors: null,
        updating: false,
      });
    },

    onUpdated: (
      state: ProviderPromotionsState,
      { providerId, promotion }: OnCreatedOrUpdatedPayload,
    ): ProviderPromotionsState => {
      if (providerId !== state.providerId) return state;
      return ({
        ...state,
        providerId,
        promotions: { ...state.promotions, [promotion.id]: promotion },
        errors: null,
        updating: false,
      });
    },
  },

  effects: dispatch => ({
    async loadNext(payload: LandPayload, rootState): Promise<void> {
      const { providerId } = payload;
      const { nextPage } = rootState.providerPromotions;

      try {
        const [
          { results: promoResults, next },
          { results: serviceResults },
        ] = await Promise.all([
          fetchPromotions(providerId, { page: nextPage?.toString?.() || '1' }),
          getAllServices({ providerId, size: 'all' }),
        ]);

        dispatch.providerPromotions.onResultsLoaded({
          providerId,
          promotions: promoResults,
          services: serviceResults,
          next: next ? Number(next) : null,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerPromotions.onErrored({
          providerId,
          errors: e as any,
        });
      }
    },

    async land(payload: LandPayload): Promise<void> {
      const { providerId } = payload;
      dispatch.providerPromotions.onLanding({ providerId });
      await dispatch.providerPromotions.loadNext({ providerId });
    },

    async loadSelected(payload: LandSelectedPayload, rootState): Promise<void> {
      const { providerId, promotionId } = payload;
      const { promotions } = rootState.providerPromotions;

      if (promotions?.[promotionId]) {
        dispatch.providerPromotions.onSelectedResultLoaded({
          providerId,
          promotion: promotions[promotionId],
        });
        return;
      }

      try {
        const [
          promotion,
          { results: serviceResults },
        ] = await Promise.all([
          fetchPromotion(providerId, Number(promotionId)),
          getAllServices({ providerId, size: 'all' }),
        ]);

        dispatch.providerPromotions.onSelectedResultLoaded({
          providerId,
          promotion,
          services: serviceResults,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerPromotions.onErrored({
          providerId,
          errors: e as any,
        });
      }
    },

    async landSelected(payload: LandSelectedPayload): Promise<void> {
      const { providerId, promotionId } = payload;
      dispatch.providerPromotions.onLandSelected({ providerId });
      await dispatch.providerPromotions.loadSelected({ providerId, promotionId });
    },

    async create({ providerId, promotion }: CreatePayload): Promise<void> {
      try {
        const result = await createPromotion(providerId, promotion.getSerializedObject());
        dispatch.providerPromotions.onCreated({
          providerId,
          promotion: result,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerPromotions.onErrored({
          providerId,
          errors: e as any,
        });
      }
    },

    async update({ providerId, promotion }: UpdatePayload): Promise<void> {
      try {
        const result = await updatePromotion(providerId, promotion.getSerializedObject());
        dispatch.providerPromotions.onUpdated({
          providerId,
          promotion: result,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerPromotions.onErrored({
          providerId,
          errors: e as any,
        });
      }
    },

    async delete({ providerId, promotion }: UpdatePayload): Promise<void> {
      try {
        const disabledTime = moment().toISOString();
        const result = await updatePromotion(
          providerId,
          {
            ...promotion.getSerializedObject(),
            disabled_time: disabledTime,
          },
        );
        dispatch.providerPromotions.onUpdated({
          providerId,
          promotion: result,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerPromotions.onErrored({
          providerId,
          errors: e as any,
        });
      }
    },
  }),

  selectors: slice => ({
    promotions: () => (
      slice((state: ProviderPromotionsState): Promotion[] => state.promotionIdOrder.map(
        promoId => Promotion.fromObject(
          formatPromotionsWithServices(state.promotions[promoId], state.services),
        ),
      ))
    ),

    selectedPromotion: () => (
      slice((state: ProviderPromotionsState): Promotion => (
        state.promotions[state.selectedPromotionId]
          ? Promotion.fromObject(
            formatPromotionsWithServices(
              state.promotions[state.selectedPromotionId],
              state.services,
            ),
          )
          : undefined
      ))
    ),

    updating: () => (
      slice((state: ProviderPromotionsState) => state.updating)
    ),

    loaded: () => (
      slice((state: ProviderPromotionsState) => state.loaded)
    ),

    errors: () => (
      slice((state: ProviderPromotionsState) => state.errors)
    ),

    hasNextPage: () => (
      slice((state: ProviderPromotionsState): boolean => !!state.nextPage)
    ),
  }),
});
