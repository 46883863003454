import { useEffect, useRef } from 'react';

/**
 * A hook for running a function on unmount.
 *
 * @param {function} fn - The function to be called on unmount.
 * @param {Array} values - The values that are needed in the fn call.
 *  These values cannot be used from scope, because when this function will be
 *  called, the scope will have the values from the first render. Usually this
 *  would be solved by passing those values in as the second parameter (the
 *  dependencies array) to useEffect where fn would be called. However, we
 *  cannot do that or fn would be called anytime one of the values changes,
 *  and not just on unmount. Thus the solution is to update a ref (whose value
 *  is mutated in time, so even when fn is called with a scope from the first
 *  render, the ref will have the up-to-date values).
 */
const useUnmount = (fn, values=[]) => {
  const valuesRef = useRef(values);

  // Use a useEffect to monitor values and update the values ref when they change.
  useEffect(() => {
    valuesRef.current = values;
  }, values);

  // Use a useEffect to hook into unmount.
  useEffect(() => {
    // Returned function called on cleanup. Since the dependency array is empty,
    // this means only on unmount.
    return () => fn(valuesRef.current);
  }, []);
};

export default useUnmount;
