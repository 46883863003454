// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';

import { Box, IBoxProps } from '@styleseat/ui';
import { BrandColor, Color } from '@styleseat/brand';
import { StopPropagationBoundary } from '../StopPropagationBoundary';

import './PortalOverlayModal.module.scss';

type PositionClass = 'dialog' | 'growDialog' | 'menu' | 'dialogBottom' | 'splash' | 'fullScreen' | 'fullHeight' | 'snackbar' | 'minimalDialog' | 'sidebar';

export type PortalOverlayModalProps = {
  children: React.ReactNode | React.ReactNode[];
  position?: PositionClass;
  testID?: string;
  borderRadius?: number;
  borderRadiusTop?: number;
  borderRadiusBottom?: number;
  color?: Color;
  maxWidth?: number;
  boxProps?: IBoxProps;
};

export default function PortalOverlayModal({
  children,
  position = 'dialog',
  testID,
  borderRadius,
  borderRadiusTop,
  borderRadiusBottom,
  color = BrandColor.WhiteWash,
  maxWidth = (position === 'snackbar' ? 400 : undefined),
  boxProps,
}: PortalOverlayModalProps) {
  return (
    <Box
      color={color}
      borderRadius={borderRadius}
      borderRadiusTop={borderRadiusTop}
      borderRadiusBottom={borderRadiusBottom}
      pointerEvents="auto"
      styleName={classNames(
        position,
      )}
      testID={testID}
      maxWidth={maxWidth}
      {...boxProps}
    >
      <StopPropagationBoundary>
        {children}
      </StopPropagationBoundary>
    </Box>
  );
}
