// @ts-strict-ignore
import { IProMembershipProgram } from '../../api/Providers/Plan';

export type ProviderPlanConfiguration = {
  numericAmount: number;
  newClientFeePercent: number;
  newClientFeeMax: number;
} & IProMembershipProgram;

export function getDefaultSelectedPlan(
  configurations: Array<ProviderPlanConfiguration>,
): ProviderPlanConfiguration {
  return configurations.find(plan => plan.default_program);
}

export const formatProviderPlanConfiguration = (planData: Array<IProMembershipProgram>):
Array<ProviderPlanConfiguration> => planData?.map(plan => ({
  ...plan,
  numericAmount: Number(plan.amount),
  newClientFeePercent: Number(plan.new_client_fee_percent),
  newClientFeeMax: Number(plan.new_client_fee_max),
}));
