// @ts-strict-ignore
import moment from 'moment';
import { filterByDate } from '../filters';
import { copyValue } from '../utils/copyValue';
import { loopOverDatasets } from '../utils/loopOverDatasets';
import {
  DataFields,
  DataSet,
  MasterDataSet,
  StatDataSet,
} from '../types';
import { valueByDate } from '../valueByDate';

export type ComparedDataSet<F extends DataFields = DataFields> = {
  [k in F]: {
    source: MasterDataSet<F>[k];
    compared: MasterDataSet<F>[k];
  }
};

export function compareDatasets<F extends DataFields = DataFields>(
  dataset: MasterDataSet<F>,
  sourceDates: { start: moment.Moment | string; end: moment.Moment | string },
  comparedDates: { start: moment.Moment | string; end: moment.Moment | string },
): ComparedDataSet<F>;

export function compareDatasets<F extends DataFields = DataFields>(
  dataset: StatDataSet<F>,
  sourceDates: { start: moment.Moment | string; end: moment.Moment | string },
  comparedDates: { start: moment.Moment | string; end: moment.Moment | string },
): ComparedDataSet[F];

/**
 * Split a dataset into two that can be compared by date.
 *
 * For example, compare every day's value from this month to what it was
 * last month:
 *
 * ```
 * var today = moment();
 * var lastMonth = moment().subtract(1, 'month');
 *
 * var data = compareData(
 *   dataset,
 *   {
 *     start: today.startOf('month'),
 *     end: today.endOf('month')
 *   },
 *   {
 *     start: lastMonth.startOf('month'),
 *     end: lastMonth.endOf('month')
 *   });
 * ```
 *
 * This will return an object with 2 datasets: `source`, `compared`:
 *   + `source` - The data from the first date range (this month, in this case)
 *   + `compared` - The data from the second date range (last month, in this case)
 *
 *
 * NOTE: For parity, the date in the compared dataset will the `source` date
 * it matches to. There is an additional field, `actualDate`, that will be the
 * actual date for that value.
 *
 * There is an added property `compareDate` added to all `compared` data objects.
 * This will be set to the actual date of the value contained there.
 *
 * @param {Object} dataset The dataset object
 * @param {Object} sourceDates A date range for the current source of data
 * @param {Object} comparedDates The date range to compare source to.
 *
 * @return {Object} An object with a `source` and `compared` dataset
 */
export function compareDatasets<F extends DataFields = DataFields>(
  dataset: DataSet<F>,
  sourceDates: { start: moment.Moment | string; end: moment.Moment | string },
  comparedDates: { start: moment.Moment | string; end: moment.Moment | string },
): ComparedDataSet<F> | ComparedDataSet[F] {
  // Recursive call for all value sets
  if (!Array.isArray(dataset)) {
    return (
      loopOverDatasets(dataset, valueSet => (
        compareDatasets(valueSet, sourceDates, comparedDates)
      )) as ComparedDataSet<F>
    );
  }

  // Make the date moment objects (if they're already moment objects, it's okay)
  const sourceStart = moment(sourceDates.start, 'YYYY-MM-DD');
  const sourceEnd = moment(sourceDates.end, 'YYYY-MM-DD');
  const compareStart = moment(comparedDates.start, 'YYYY-MM-DD');
  const compareEnd = moment(comparedDates.end, 'YYYY-MM-DD');
  const dayDiff = moment.duration(compareStart.diff(sourceStart)).asDays();

  const compareDataset = filterByDate(dataset, compareStart, compareEnd);

  const retDataset = {
    source: filterByDate(dataset, sourceStart, sourceEnd),
    compared: [],
  };

  // Go through source data and find matching compare data
  const currentDay = sourceStart.clone();
  while (currentDay.isSameOrBefore(sourceEnd)) {
    const sourceDate = currentDay.clone();
    const compareDate = sourceDate.clone().add(dayDiff, 'days');
    let compareVal: {
      date?: moment.Moment;
      actualDate?: moment.Moment;
    } = valueByDate(compareDataset, compareDate) || {};

    compareVal = copyValue(compareVal);
    compareVal.date = sourceDate;
    // compareVal.actualDate = compareDate;

    // Copy values over that match this day
    retDataset.compared.push(compareVal);

    currentDay.add(1, 'day');
  }

  return retDataset as ComparedDataSet[F];
}
