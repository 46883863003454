import React from 'react';
import { BrandColor } from '@styleseat/brand';

type BasicCircleCheckedIconProps = {
  width?: number;
  height?: number;
  color?: string;
  fillColor?: string;
  circleColor?: string;
} & React.SVGProps<SVGSVGElement>;

export const BasicCircleCheckedIcon = ({
  width = 24,
  height = 24,
  color = BrandColor.BackInBlack,
  fillColor = BrandColor.Transparent,
  circleColor,
}: BasicCircleCheckedIconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={12}
      cy={12}
      r={10}
      strokeWidth={2}
      fill={fillColor}
      stroke={circleColor || color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 7.2 12.8 l 3.2 3.2 l 6.4 -6.4 l -1.2 -1.2 l -5.2 5.2 l -2 -2 z"
      fill={color}
    />
  </svg>
);
