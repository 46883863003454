// @ts-strict-ignore
import { GetPlaceCommandOutput } from '@aws-sdk/client-location';
import SimpleCache from '../SimpleCache';
import { ssFetchJSON } from '../ssFetch';
import { API_ROOT } from '../../config';
import { FormattedAddress, getAddressFromPosition } from './locationAutocomplete';
import { fetchPlaceFromId } from '../../api/thirdParty/AwsPlaceIndex';

const cache = new SimpleCache(60 * 60 * 24); // 1 day in seconds

export type GeocodeLocation = {
  location?: {
    lat?: number;
    lng?: number;
    latitude?: number;
    longitude?: number;
  };
  address?: string;
  placeId?: string;
};

type GoogleLocation = {
  locality?: string;
  country?: string;
  administrative_area_level_2?: string;
  neighborhood?: string;
  administrative_area_level_1?: string;
  route?: string;
  street_number?: string;
  postal_code?: string;
};

type NormalizedGoogleLocation = {
  city: string;
  country: string;
  county: string;
  neighborhood: string;
  state: string;
  streetName: string;
  streetNumber: string;
  zipCode: string;
};

type SSGeocodeResult = {
  latitude: number;
  longitude: number;
  addressComponents: NormalizedGoogleLocation | {};
};

/**
 * Get the cache key for geocode data
 *
 * @param {Object} dataToGeocode Same value passed to `geocode`
 * @return {String} Key or undefined if there was an error
 */
function geocodeCacheKey(dataToGeocode): string {
  let cacheKey;

  // Convert an object into a collection of sorted key/value arrays
  function objectToSortedArray(obj) {
    if (typeof obj !== 'object') {
      return obj;
    }
    return Object.keys(obj)
      .sort()
      .map(k => {
        const val = obj[k];
        return [
          k,
          objectToSortedArray(val),
        ];
      });
  }

  if (!dataToGeocode) {
    return undefined;
  }

  try {
    cacheKey = dataToGeocode;
    if (typeof dataToGeocode === 'object') {
      cacheKey = JSON.stringify(objectToSortedArray(dataToGeocode));
    }
    cacheKey = String(cacheKey)
      .toLowerCase();
  } catch (e) {
    window.console.error('Error converting data to geocode to string', dataToGeocode, e);
  }

  return cacheKey;
}

/**
 * Extracts the latitude, longitude from the provided `GeocoderGeometry`.
 *
 * @param {Object} geometry The GeocodeGeometry from google geocoding API.
 * @returns {Object} An object with the latitude and longitude of the geocided geometry.
 * @see
 * https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderGeometry
 */
function extractCoordinates(geometry) {
  const { location } = geometry;
  if (typeof location.lat === 'function') {
    return {
      latitude: location.lat(),
      longitude: location.lng(),
    };
  }
  return {
    latitude: location.lat,
    longitude: location.lng,
  };
}

/**
 * Normalizes a set of address components to an object containing friendly keys.
 *
 * @param {Array<Object>} addressComponents The address components to normalize.
 * @returns {Object} The normalized address components.
 */
function normalizeAddressComponents(addressComponents): NormalizedGoogleLocation | {} {
  const normalized: GoogleLocation = {};
  if (!addressComponents) {
    return {};
  }
  // map the address components to their `short_name` or `long_name`, depending on their purpose
  addressComponents.forEach(addressComponent => {
    if (addressComponent.types.length) {
      const type = addressComponent.types[0];
      if ([
        'country',
        'administrative_area_level_1',
      ].indexOf(type) !== -1) {
        normalized[type] = addressComponent.short_name;
      } else {
        normalized[type] = addressComponent.long_name;
      }
    }
  });

  // map the keys from google to something a little less vague
  return {
    city: normalized.locality,
    country: normalized.country,
    county: normalized.administrative_area_level_2,
    neighborhood: normalized.neighborhood,
    state: normalized.administrative_area_level_1,
    streetName: normalized.route,
    streetNumber: normalized.street_number,
    zipCode: normalized.postal_code,
  };
}

// Package up data retrieved by places and geocode APIs
const packageData = (region, cacheKey) => {
  const locale = {
    ...extractCoordinates(region.geometry),
    addressComponents: normalizeAddressComponents(region.address_components),
  };
  if (cacheKey) {
    cache.addItem(cacheKey, locale);
  }
  return locale;
};

export const findFirstResult = results => {
  if (results) {
    const region = results.find(result => result.types.includes('neighborhood') || result.types.includes('locality'));
    return region;
  }
  return undefined;
};

/**
 * Gets geocoding information using the SS Geocoding API, based on an address String.
 * @param address A string representing the address.
 */
function getSSGeocoding(address: string, cacheKey): Promise<SSGeocodeResult> {
  return new Promise(resolve => {
    const url = `${API_ROOT}/api/v1/geocoding?location=${address}`;
    ssFetchJSON(url)
      .then(parsed => {
        if (parsed.status === 'ZERO_RESULTS') {
          const data = {
            addressComponents: {},
            latitude: undefined,
            longitude: undefined,
          };
          resolve(data);
        } else {
          const { results } = parsed;
          const region = findFirstResult(results);
          const data = packageData(region || (results && results[0]), cacheKey);
          resolve(data);
        }
      });
  });
}

/**
 * Query google's geoip API and return StyleSeat normalized location data.
 * @see https://developers.google.com/maps/documentation/javascript/reference/geocoder#Geocoder.geocode
 *
 * The returned object contains:
 *   - lat/lng of the center point
 *   - bounding box: top/left and bottom/right latitude and longitudes.
 *   - addressComponents = The address components for the location
 *
 * @param {String} address A string location (e.g. "San Francisco, Ca")
 * @param {GeocoderRequest} dataToGeocode The query to geocode
 * @yields {Object} Lat/lng coordinates, address components and bounding box for the region.
 */
export async function geocodeFromAddress(address: string): Promise<SSGeocodeResult> {
  // Cached value
  const cacheKey = geocodeCacheKey(address);
  const cacheValue = (cacheKey) ? cache.getItem(cacheKey) : null;
  if (cacheValue) {
    return Promise.resolve(cacheValue);
  }

  // ...for location -> lat-lon coding use our cached geocoding backend to
  // reduce API calls to google.
  const result = getSSGeocoding(address, cacheKey);
  cache.addItem(cacheKey, result);
  return result;
}

export async function geocodeFromPlaceId(
  placeId,
  useFiltered: boolean = false,
): Promise<GetPlaceCommandOutput> {
  // Cached value
  const cacheKey = geocodeCacheKey(placeId);
  const cacheValue = (cacheKey) ? cache.getItem(cacheKey) : null;
  if (cacheValue) {
    return Promise.resolve(cacheValue);
  }

  const place = await fetchPlaceFromId(placeId, useFiltered);
  cache.addItem(cacheKey, place);
  return place;
}

export async function geocodeFromPosition(
  {
    latitude,
    longitude,
  },
  useFiltered: boolean = false,
): Promise<FormattedAddress | null> {
  // Cached value
  const cacheKey = geocodeCacheKey({ latitude, longitude });
  const cacheValue = (cacheKey) ? cache.getItem(cacheKey) : null;

  if (cacheValue) {
    return Promise.resolve(cacheValue);
  }

  const address = await getAddressFromPosition({
    latitude,
    longitude,
  }, useFiltered);
  cache.addItem(cacheKey, address);
  return address;
}
