import { STATIC_ASSET_URL } from '../../config';

/**
 * Formats a profile photo URL to point to the correct location
 * @param profilePhoto The profile photo path value, usually coming from something like
 * `provider.profile_photo`
 * @param suffix The suffix to append to the path containing the photo dimensions
 */
export default function formatProfilePhoto(profilePhoto: string, suffix: string = '_90x90.jpg'): string {
  let prefix = STATIC_ASSET_URL;
  let separator = '';

  if (profilePhoto.startsWith('http')) {
    // this is an absolute link, so we don't need to add the root URL
    prefix = '';
  } else if (!profilePhoto.startsWith('/')) {
    separator = '/';
  }

  return `${prefix}${separator}${profilePhoto}${suffix}`.replace('styleseat/styleseat', 'styleseat');
}
/**
 * Removes the sizing suffix (i.e. _300x300.jpg) from photo URLs if they exist
 *
 * @param photoUrl The source URL of the photo to strip
 */
export function stripPhotoSuffix(photoUrl: string): string {
  return photoUrl?.replace?.(/_\d+x\d+.jpg$/, '');
}
