import FeatureFlags from '../modules/FeatureFlags';
import { useFullAsyncState } from '../../../packages/hooks/src/useAsyncState';

export type FeatureFlagData = {
  loading: boolean;
  enabled: boolean;
};

/**
 * A hook for returning the value of a feature flag.
 *
 * @param {string} featureFlagName - The name of the feature flag/switch.
 * @return {FeatureFlagData} - An object specifying loading and enabled.
 */
const useFeatureFlag = (featureFlagName: string): FeatureFlagData => {
  const { loading, data } = useFullAsyncState(
    () => FeatureFlags.isEnabled(featureFlagName),
    [featureFlagName],
  );
  return { loading, enabled: data };
};

export default useFeatureFlag;
