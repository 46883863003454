/**
 * Test configuration for Jest unit tests.
 */
export const ENV = 'test';
export const API_ROOT = 'http://localhost';
export const SITE_ROOT = '';
export const SEARCH_API_ROOT = '';
export const SHUTTLE_ENDPOINT = '';
export const STATIC_ASSET_URL = 'https://fake-cdn.com';
export const STRIPE_JS_KEY = 'pk_test_BNt9NCN7U8ZqRGuFxx7C7D9M';
export const GOOGLE_API_KEY = 'AIzaSyAP1MyrnxOd4_Tocqhy-2Xdvz4dNyZVBfY';
export const GOOGLE_CLIENT_ID = '1041300213311-dhr7k4b8m1q8p50p061knpfb7frt0p1l.apps.googleusercontent.com';
export const GOOGLE_AUTH_SCOPE = 'https://www.googleapis.com/auth/calendar email';
export const GOOGLE_SIGNIN_CLIENT_ID = '1016475266319-4nmrl694j04irgjeb1q3btbvkjacl33b.apps.googleusercontent.com';
export const INSTAGRAM_APP_ID = '1836884766445421';
export const FACEBOOK_APP_ID = '111891482183773';
export const IOS_CAMPAIGN_ID = 'kostyleseat-ios556f49f6d44f502492036c331d';
export const ANDROID_CAMPAIGN_ID = 'kostyleseat-android556f4a4116a065427890180fb0';
export const RECOMMENDATIONS_SOURCE_URL = 'https://fake-cdn.com/';
export const RECOMMENDATIONS_ENGINE_PREFIX = 'unit';
export const SEGMENT_WRITE_KEY = 'unit';
export const BRAZE_KEY = 'unit';
export const TALKJS_APP_ID = 'tz7loLDQ';

export const SEO_SERVICE_READ = 'test-seo-server';

export const DEBUG = false;
export const APP = false;
export const ENABLE_APP_SIMULATOR = false;
export const ENABLE_TRACKING_LOGS = false;

export const STATE_MANAGER_ENDPOINT = 'https://example.com/state_manager';
export const APPSFLYER_DEBUG = true;
export const STRIPE_TERMINAL_LOCATION_ID = 'tml_EoQAigDZxKzBha';

export const AWS_AUTOCOMPLETE_REGION = 'us-east-1';
export const AWS_AUTOCOMPLETE_COGNITO_IDENTITY_POOL_ID = 'test';
export const AWS_AUTOCOMPLETE_INDEX_NAME = 'test_index';
export const AWS_AUTOCOMPLETE_ESRI_INDEX_NAME = 'AutocompleteESRIIndex';
