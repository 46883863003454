export const NCC_HUB_SWITCH = 'pro_appointment_acceptdecline_apo4190_20231114_2023';
export const EXPIRED_APPT_MODAL_SEEN = 'expired_appt_modal_seen';

export enum TimeUrgency {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Expired = 'Expired',
}

// Time urgency (In hours)
// Higher than
// https://styleseat.atlassian.net/browse/APO-4171

export const timeUrgencyThresholds = {
  [TimeUrgency.Low]: 12,
  [TimeUrgency.Medium]: 1,
  [TimeUrgency.High]: 0,
};
