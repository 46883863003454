import React from 'react';

type Props = {
  label: string;
  symbol: React.ReactNode;
  style?: object;
};

export const Emoji: React.FunctionComponent<Props> = ({
  label,
  symbol,
  style = {},
}) => (
  <span
    role="img"
    aria-label={label}
    style={{
      paddingRight: 4,
      ...style,
    }}
  >
    {symbol}
  </span>
);
