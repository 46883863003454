import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import analytics from '../../modules/analytics';
import Janitor from '../../modules/Janitor';
import Router from '../../../../packages/router';

import '../ui/Button/Button.scss';
import nonCriticalException from '../../modules/exceptionLogger';

function makeComputeUrl() {
  let lastUrl = null;
  let lastHref = null;
  let lastParams = null;
  let lastUseReactEvent = null;
  let lastTo = null;
  return (href, to, params, useReactEvent) => {
    if (
      href === lastHref
      && params === lastParams
      && to === lastTo
      && useReactEvent === lastUseReactEvent
    ) {
      return lastUrl;
    }

    lastHref = href;
    lastParams = params;
    lastTo = to;
    lastUseReactEvent = useReactEvent;
    // only use the router URL if it is non-empty, otherwise, we retain the original value of the
    // url value, which is the same as the value of `href`, unless useReactEvent is true
    lastUrl = Router.href(to, params) || (useReactEvent ? '#' : href);

    return lastUrl;
  };
}

/**
 * Provides a simple way to link to another Angular UI router state within
 * the app from JSX.
 *
 * @example
 *   import StateLink from 'components/StateLink';
 *
 *   <StateLink to="profile" params={{ providerId: 32 }}>
 *     Profile
 *   </StateLink>
 *
 * With onClick handlers
 * --------------------
 * You can additionally add an `onClick` handler, which is guaranteed to fire before
 * changing state:
 *
 * @example
 *   trackClick = () => {
 *     // insert click tracking here.
 *   };
 *
 *   render() {
 *     <StateLink
 *       to="home"
 *       onClick={trackClick}
 *     >
 *       Home
 *     </StateLink>
 *   }
 *
 * Click Tracking
 * --------------
 *
 * Send "C Profile Clicked" to mixpanel
 * @example
 * <StateLink clickTracking="C Profile Clicked" ...>Click Me</StateLink>
 *
 * Sends "C Profile Clicked" to mixpanel with the object { source: 'header' }
 * @example
 * <StateLink
 *   clickTracking={{
 *     name: 'C Profile Clicked',
 *     params: { source: 'header' }
 *   }}
 * >
 *   Click Me
 * </StateLink>
 */
export default class StateLink extends React.Component {
  static propTypes = {
    /** The state which is linked to */
    to: (props, propName, componentName) => {
      if (!props.to && !props.href) {
        return new Error(`One of props 'to' or 'href' was not specified in '${componentName}'.`);
      }

      return null;
    },

    /** State parameters */
    params: PropTypes.object,

    /**
     * UI Router transition options
     * https://ui-router.github.io/ng1/docs/latest/interfaces/transition.transitionoptions.html
     */
    options: PropTypes.object,

    /** A click handler to be called as soon as the user clicks the element. */
    onClick: PropTypes.func,

    /** Either a string or an object used to track the click to mixpanel. See doc above */
    clickTracking: PropTypes.oneOfType([
      /** The name of the mixpanel event to track. */
      PropTypes.string,

      /** An object with `name` and `params` values */
      PropTypes.shape({
        name: PropTypes.string,
        params: PropTypes.object,
      }),
    ]),

    /** A class to apply to the resulting anchor element. */
    className: PropTypes.string,

    /** If disabled, clicking the link does not do anything. */
    disabled: PropTypes.bool,

    /** The value or elements to be linked */
    children: PropTypes.node,

    /** True to force the component to use React event binding. By default, native event binding is
     * used. */
    useReactEvent: PropTypes.bool,

    /** The link target, e.g. `_blank` */
    target: PropTypes.string,

    style: PropTypes.object,

    /** A URL to directly link to */
    href: (props, propName, componentName) => {
      if (!props.to && !props.href) {
        return new Error(`One of props 'to' or 'href' was not specified in '${componentName}'.`);
      }

      return null;
    },

    /**
     * A unique ID used for querying during unit testing
     */
    testID: PropTypes.string,
  };

  static defaultProps = {
    to: null,
    params: {},
    options: {},
    className: '',
    clickTracking: null,
    children: null,
    onClick: () => {},
    disabled: false,
    useReactEvent: false,
    href: null,
    target: undefined,
    style: null,
    testID: null,
  };

  anchorRef = React.createRef();

  janitor = new Janitor();

  computeUrl = makeComputeUrl();

  componentDidMount() {
    const anchor = this.anchorRef.current;
    const { useReactEvent } = this.props;
    if (anchor && !useReactEvent) {
      // Need to apply the click handler manually, because React won't let
      // us preventDefault on the anchor click.
      // https://medium.com/@ericclemmons/react-event-preventdefault-78c28c950e46
      this.janitor.addDOMEvent(anchor, 'click', this.clickHandler);
    }
  }

  componentWillUnmount() {
    this.janitor.cleanup();
  }

  /**
   * Track the click to mixpanel
   */
  async sendClickTracking() {
    const { clickTracking } = this.props;

    if (!clickTracking) {
      return;
    }

    let name = clickTracking;
    let params = {};
    if (typeof clickTracking === 'object') {
      name = clickTracking.name;
      params = clickTracking.params || {};
    }

    try {
      await analytics.track(name, params);
    } catch (e) {
      nonCriticalException(e);
    }
  }

  /**
   * Handle the user clicking on the anchor.
   */
  clickHandler = e => {
    const {
      onClick,
      useReactEvent,
      href,
    } = this.props;

    this.sendClickTracking();

    if (typeof onClick === 'function') {
      onClick(e);
    }

    // Don't do anything if the event has already been cancelled or the user
    // is trying to open in a new tab/window (i.e. right-click or using cmd key)
    if ((!useReactEvent && e.defaultPrevented) || e.button > 0 || e.metaKey || e.ctrlKey) {
      return;
    }

    // Also don't do anything if we've been given a direct link
    if (href) {
      return;
    }

    // The link is disabled
    if (this.props.disabled) {
      return;
    }

    e.preventDefault();

    const {
      to,
      params,
      options,
    } = this.props;

    Router.go(to, params, options);
  };

  render() {
    const {
      disabled,
      target,
      style,
      testID,
    } = this.props;
    const onClick = this.props.useReactEvent ? this.clickHandler : null;
    const url = this.computeUrl(
      this.props.href,
      this.props.to,
      this.props.params,
      this.props.useReactEvent,
    );
    return (
      <a
        target={target}
        href={url}
        className={classNames(
          this.props.className,
          { disabled },
        )}
        ref={this.anchorRef}
        onClick={onClick}
        style={style}
        data-testid={testID}
      >
        {this.props.children}
      </a>
    );
  }
}
