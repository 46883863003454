// @ts-strict-ignore
import moment, { Moment } from 'moment';
import _ from 'underscore';
import formatPromotionDiscount from '../../../modules/formatters/formatPromotionDiscount';
import {
  ProviderServicePromotion,
  SerializedPromotion,
  PromotionDiscountType,
  PromotionPayload,
} from './types';
import { ProviderService } from '../Services';

function parseTime(timeString: string): Moment {
  if (!timeString) {
    return null;
  }

  const stringToParse = timeString.toUpperCase().endsWith('Z') ? timeString : `${timeString}Z`;

  return moment(stringToParse);
}

/**
 * Promotion model describes the promotion object from the API and transforms it for a view ready
 * model object
 *
 * To add any tranformation logic use the fromObject method.
 * All necessary attributes required by the view had to be defined in DEFAULTS.
 * Custom or dervied attributes also can be added to DEFAULTS and add appropriate getters/setters
 *
 * */
export default class Promotion implements ProviderServicePromotion {
  best_services: any[];
  client_redemption_limit: number;
  creation_time: any | Moment;
  description: string;
  disabled_time: null | Moment;
  discount_quantity: number;
  discount_type: PromotionDiscountType;
  book_end_time: Moment;
  id: number | null;
  name: string | null;
  provider: any;
  services: ProviderService[] | null;
  book_start_time: Moment;
  modification_time: null | Moment;

  private static DEFAULTS = {
    best_services: [],
    client_redemption_limit: 1,
    creation_time: null,
    description: '',
    disabled_time: null,
    discount_quantity: 0,
    discount_type: 'flat-rate',
    book_end_time: moment().startOf('day'),
    id: null,
    name: null,
    provider: null,
    services: [],
    book_start_time: moment().startOf('day'),
    modification_time: null,
  };

  constructor(attrs: Partial<ProviderServicePromotion>) {
    this.assignAttributes(attrs);
  }

  private assignAttributes(attrs: Partial<ProviderServicePromotion>) {
    Object.keys(Promotion.DEFAULTS).forEach((key: string) => {
      this[key] = attrs[key] || Promotion.DEFAULTS[key];
    });
  }

  /**
   * Type casts an object to a Promotion if it is not already an instance of Promotion.
   *
   * @name fromObject
   * @param {Object} promotion - The promotion to type cast.
   * @returns {Promotion}
   */
  static fromObject(attr: Promotion | Partial<SerializedPromotion>): Promotion {
    if (attr instanceof Promotion) {
      return attr;
    }

    return new Promotion({
      ...attr,
      // The backend seems to trim the 'Z' from the ISO Datetime string we use in the JSON. It is
      // essential to parse the datetime to the users local timezone. So manually adding the 'Z' to
      // the datetime strings returned by the API.
      book_start_time: parseTime(attr.book_start_time),
      book_end_time: parseTime(attr.book_end_time),
      disabled_time: parseTime(attr.disabled_time),
      creation_time: parseTime(attr.creation_time),
      modification_time: parseTime(attr.modification_time),
      discount_quantity: parseFloat(attr.discount_quantity),
      services: attr.services as ProviderService[], // TODO: fix
    });
  }

  /**
     * Get the discounted as a formatted string
     */
  getFormattedDiscount() {
    return formatPromotionDiscount(this);
  }

  /**
   * Return the promotional price for a service
   *
   * @name getPromotionalPrice
   * @param serviceCost
   * @returns {Number} promotional price
   */
  getPromotionalPrice(serviceCost: number): number {
    if (this.discount_type === 'flat-rate') {
      return parseFloat((serviceCost - this.discount_quantity).toFixed(2));
    }

    return parseFloat((serviceCost - serviceCost * (this.discount_quantity / 100)).toFixed(2));
  }

  /**
   * From the list of service objects get the joined service names for display
   * @returns {string}
   */
  getPromotionServiceNames() {
    return _.uniq(this.services.map(s => s.name)).join(', ');
  }

  /**
   * Get the serialized promotion model object ready for the API
   *
   * @returns {Object}
   */
  getSerializedObject(): PromotionPayload {
    // Convert book_end_time to end_of_day until selection of time option available on the
    // frontend
    return {
      ...this,
      book_start_time: this.book_start_time.toISOString(),
      // we need startOf('second') here, otherwise we send something like 23:59:59.999 and the
      // server rounds up to the next second and then the FE displays the wrong end day / the BE
      // might use the wrong end day
      book_end_time: this.book_end_time.clone().endOf('day').startOf('second').toISOString(),
      disabled_time: (this.disabled_time ? this.disabled_time.toISOString() : null),
      services: _.uniq(this.services.map(s => s.id)) as number[],
      discount_quantity: String(this.discount_quantity),
    } as PromotionPayload;
  }

  /**
   * Returns true if the promotion is set diabled during the promotion timespan
   * @returns {boolean}
   */
  isStopped() {
    if (this.disabled_time) {
      return moment(this.disabled_time).isBetween(this.book_start_time, this.book_end_time)
        || this.disabled_time.isSame(this.book_start_time, 'day') || this.disabled_time.isSame(this.book_end_time, 'day');
    }
    return false;
  }

  /**
   * Returns true if the promotion is ongoing
   * @returns {boolean}
   */
  isCurrent() {
    if (this.book_start_time) {
      return (moment().isSame(this.book_start_time, 'day')
        || moment().isBetween(this.book_start_time, this.book_end_time));
    }
    return false;
  }

  /**
   * Returns true if the promotion has not started
   * @returns {boolean}
   */
  isFuture() {
    return moment().isBefore(this.book_start_time, 'day');
  }

  /**
   * Returns true if the promotion has completed
   * @returns {boolean}
   */
  isPast() {
    if (this.book_end_time) {
      return moment().isAfter(this.book_end_time, 'day');
    }
    return false;
  }

  /**
   * Returns true if the promotion is disabled after or before the promotion timespan
   * @returns {boolean}
   */
  isDeleted() {
    if (this.disabled_time) {
      return (this.disabled_time.isAfter(this.book_end_time, 'day') || this.disabled_time.isBefore(this.book_start_time, 'day'));
    }
    return false;
  }
}
