// eslint-disable-next-line import/no-unresolved
import 'detectizr/dist/detectizr?noModules';
import { APP } from '../config';
import {
  APP_VERSION,
  APP_STORE_ID,
  PLAY_STORE_ID,
} from './AppConstants';

let androidVersionDetermined = false;
let androidVersion;
let iosVersionDetermined = false;
let iosVersion;

/**
 * Return a boolean indicating whether this is the app or not.
 *
 * Usually you can just use config.APP, but for unit testing
 * it may be valuable to override this function's behavior.
 */
export function getIsApp() {
  return APP;
}

export function getAppVersion() {
  return APP_VERSION;
}

/**
 * Returns Android version number of current device, from app or mobile browser.
 */
export function getAndroidVersion() {
  if (androidVersionDetermined) {
    return androidVersion;
  }

  const match = navigator.appVersion.match(/Android ([\d.]+)/);
  if (match) {
    androidVersion = match[1];
  }

  androidVersionDetermined = true;
  return androidVersion;
}

/**
 * Returns IOS version number of the current device, from app or mobile browser.
 */
export function getIOSVersion() {
  // If device is available from cordova, use that
  if (window.device?.platform === 'iOS') {
    return window.device.version;
  }

  if (iosVersionDetermined) {
    return iosVersion;
  }

  const match = navigator.appVersion.match(/OS ([\d_]+)/);
  if (match) {
    iosVersion = match[1].replace(/_/g, '.');
  }

  iosVersionDetermined = true;
  return iosVersion;
}

/**
 * Check that a device's iOS major version is at least a specified version
 *
 * @param {number} majorVersion integer specifying the iOS major version to test for
 * @returns {boolean} true if the iOS device's version is equal to or greater than the
 *          argument value, false otherwise
 */
export function isIOSMajorVersionAtLeast(majorVersion) {
  // Don't want to use node-version-compare because '16.0' and  '16.0.0' don't compare as equal
  const version = getIOSVersion();
  if (!version || typeof version !== 'string') {
    return false;
  }
  const majorPart = version.split('.')[0];
  return parseInt(majorPart, 10) >= majorVersion;
}

export function isIOSApp() {
  return getIsApp() && !!getIOSVersion();
}

export function getAPPStoreID() {
  return APP_STORE_ID;
}

export function getPlayStoreID() {
  return PLAY_STORE_ID;
}

export function getOS() {
  if (getIOSVersion()) return 'ios';
  if (getAndroidVersion()) return 'android';
  return window.Detectizr?.os?.name || 'unknown';
}

export function getBrowser() {
  return window.Detectizr.browser;
}

export function getCordovaVersion() {
  if (window.device) {
    return window.device.cordova;
  }
  return '';
}

export function getIsTablet() {
  if (window.Detectizr?.device?.type === 'tablet') return true;
  if (window.device?.model?.includes('iPad')) return true;
  return false;
}

/**
 * Gets a map of strings describing the platform.
 * @returns An object containing a `platform` key with string value summarizing platform and
 * `version` key containing relevant app version.
 */
export function getPlatformStrings() {
  let platform = 'N/A';
  let platformVersion = '';

  if (getIsApp()) {
    platform = 'mobile-app-';
  } else {
    platform = 'mobile-web-';
  }
  if (getIOSVersion()) {
    platform += 'ios';
    platformVersion = getIOSVersion();
  } else if (getAndroidVersion()) {
    platform += 'android';
    platformVersion = getAndroidVersion();
  }

  return {
    platform,
    version: platformVersion,
  };
}
