// @ts-strict-ignore
const emailRegEx = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,})\b$/i;
const POTENTIAL_ZIPCODE = /(^|\s+)[0-9]{5}/;

/**
 * Given a string, returns the contents of the string with any non-numeric characters removed
 * @param value Any string
 */
function onlyDigits(value: string | number): string {
  return String(value || '').replace(/\D/g, '');
}

/**
 * Returns true of the given value is a valid full name
 * @param value
 * @returns {boolean|boolean}
 */
export const isValidFullName = value => {
  const names = String(value || '').split(/\s+/);
  const lastName = names.pop();
  const firstName = names.join(' ');
  return !!firstName && !!lastName;
};

/**
 * Returns true if the provided value is considered a valid phone number
 * @param value
 * @returns {boolean|boolean}
 */
export const isValidPhone = (value: number | string = ''): boolean => {
  const parsedVal = onlyDigits(value);
  return parsedVal.length === 10 || (parsedVal.length === 11 && parsedVal.charAt(0) === '1');
};

/**
 * Returns true if the provided value is considered a valid email address
 * @param value
 * @returns {*|boolean}
 */
export const isValidEmail = (value: string): boolean => (
  !!value && value.length > 0 && !!value.match(emailRegEx)
);

/**
 * Returns true if the provided value is considered a valid birthday entry (without year)
 * @param value The value to check
 * @returns True if valid, otherwise false
 */
export const isValidBirthday = (value: string): boolean => (
  /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])$/.test(value)
);

/**
 * Returns true if the provided value is considered a valid birth date (with year)
 * @param value The value to check
 * @returns True if valid, otherwise false
 */
export const isValidBirthDate = (value: string): boolean => (
  /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/]((?:19|20)\d\d)$/.test(value)
);

/**
 * Returns true if the provided value is considered a valid zip code
 * @param value
 * @returns {*|boolean}
 */
export const isValidZipcode = (value: string): boolean => POTENTIAL_ZIPCODE.test(value);

/**
 * Returns true if the provided value is considered a valid steet address
 * @param value
 * @returns {*|boolean}
 */
export const isValidStreetAddress = (value: string): boolean => /^\d+\s(\d+|\D+)+$/.test(value);

/**
 * Returns true if the provided value can be parsed to a URL and starts with http or https
 */
export const isValidUrl = (value: string): boolean => {
  try {
    const url = new URL(value);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};

const isNineDigits = (value: string): boolean => onlyDigits(value).length === 9;

/**
 * Returns true if the provided value is considered a valid SSN
 * @param value The value to check
 * @returns True if valid, otherwise false
 */
export const isValidSSN = isNineDigits;

/**
 * Returns true if the provided value is considered a valid SSN last 4 entry
 * @param value The value to check
 * @returns True if valid, otherwise false
 */
export const isValidSSNLast4 = (value: string): boolean => onlyDigits(value).length === 4;

/**
 * Returns true if the provided value is considered a valid employer identification number.
 * **NOTE**: This is functionally the same as `isValidSSN` but provided separately for improved
 * semantics.
 * @param value The value to check
 * @returns True if valid, otherwise false
 */
export const isValidEIN = isNineDigits;

/** Returns true if the provided value is considered a valid routing number.
 * Routing numbers are always 9 digits
 * @param value The value to check
 * @returns True if valid, otherwise false
 */
export const isValidRoutingNumber = isNineDigits;

/** Returns true if the provided value is considered a valid bank account number.
 * Bank account numbers are often between 5-17 digits. We're allowing as few as three digits here.
 * @param value The value to check
 * @returns True if valid, otherwise false
 */
export const isValidBankAccountNumber = (value: string) => /^[0-9]{3,17}$/.test(value);
