// @ts-strict-ignore
import produce from 'immer';
import { createModel } from '@rematch/core';
import type { RootState, RootModel } from './models';
import nonCriticalException from '../modules/exceptionLogger';

import {
  getProvider,
  isProvider,
  PrivilegedProvider,
  PublicProvider,
} from '../api/Providers';
import { BankAccountStatus } from '../api/Providers/BankAccounts';

export interface ProviderRecord {
  loading: boolean;
  result?: PublicProvider | PrivilegedProvider;
  // TODO: Move this derived value into a selector
  isSmartPriceEligible?: boolean;
  isBankInError?: boolean;
  refundsEnabled?: boolean;
}

export type State = {
  // activeProviderId is used on the pro side to demarcate the current logged in provider info
  // Or for a superuser, the current pro that they are viewing
  activeProviderId?: number;
  providersById: Record<number, ProviderRecord>;
};

type ProviderIdPayload = { providerId: number };

/**
 * This is a model to store providers from /api/v2/providers/{id}
 * This endpoint will return either a Provider or a PrivilegedProvider depending on user status
 *
 * @TODO - split this model to be separate for a provider vs a client
 */
const model = createModel<RootModel>()({
  name: 'providers',

  state: {
    providersById: {},
  } as State,

  reducers: {
    onLoading: produce<State, [ProviderIdPayload]>((
      state: State,
      payload: ProviderIdPayload,
    ) => {
      const { providerId } = payload;
      state.providersById[providerId] = { loading: true };
    }),
    onLoaded: produce<State, [PublicProvider | PrivilegedProvider]>((
      state: State,
      payload: PublicProvider | PrivilegedProvider,
    ) => {
      const { id } = payload;
      state.providersById[id] = {
        loading: false,
        result: payload,
        isSmartPriceEligible: payload.can_process_payments && payload.smart_pricing_enabled,
        isBankInError: BankAccountStatus.Error === (payload as PrivilegedProvider).bank_status,
        refundsEnabled: (payload as PrivilegedProvider).refunds_enabled,
      };
    }),
    onFailed: produce<State, [ProviderIdPayload]>((
      state: State,
      payload: ProviderIdPayload,
    ) => {
      const { providerId } = payload;
      state.providersById[providerId] = { loading: false };
    }),

    setActiveProviderId(state: State, payload: number) {
      return {
        ...state,
        activeProviderId: payload,
      };
    },
  },

  effects: dispatch => ({
    loadProvider: async (
      payload: { providerId: number; noCache?: boolean; setActive?: boolean; noLoading?: boolean },
      rootState: RootState,
    ): Promise<PublicProvider | PrivilegedProvider> => {
      const {
        providerId,
        noCache,
        setActive,
        noLoading,
      } = payload;
      const existingRecord = rootState.providers.providersById[providerId];

      if (noCache || !existingRecord || !existingRecord.loading || !existingRecord.result) {
        try {
          if (!existingRecord || !noLoading) {
            await dispatch.providers.onLoading({ providerId });
          }
          const providerResult = await getProvider(providerId);

          if (isProvider(providerResult)) {
            if (setActive) {
              dispatch.providers.setActiveProviderId(providerId);
            }
            await dispatch.providers.onLoaded(providerResult);
            return providerResult;
          }

          throw new Error('Error occurred retrieving provider.');
        } catch (e) {
          nonCriticalException(e);
          await dispatch.providers.onFailed({ providerId });
          return null;
        }
      }
      if (setActive) {
        dispatch.providers.setActiveProviderId(providerId);
      }
      return existingRecord.result;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    activeProvider() {
      return createSelector(
        slice(state => state.providersById),
        slice(state => state.activeProviderId),
        (providersById, activeProviderId) => (activeProviderId
          ? providersById[activeProviderId]?.result || null
          : null),
      );
    },

    providerById: hasProps((_, providerId: number) => createSelector(
      slice(state => state.providersById),
      (providersById): PublicProvider | null => (providerId > 0
        ? providersById[providerId]?.result || null
        : null),
    )),

    privilegedProviderById: hasProps((_, providerId: number) => createSelector(
      slice(state => state.providersById),
      (providersById): PrivilegedProvider | null => (providerId > 0
        ? providersById[providerId]?.result as PrivilegedProvider || null
        : null),
    )),

    isSmartPricingEligible() {
      return createSelector(
        slice(state => state.providersById),
        slice(state => state.activeProviderId),
        (providersById, activeProviderId): boolean => (activeProviderId > 0
          ? providersById[activeProviderId]?.isSmartPriceEligible || false
          : false),
      );
    },

    isBankInError() {
      return createSelector(
        slice(state => state.providersById),
        slice(state => state.activeProviderId),
        (providersById, activeProviderId): boolean => (activeProviderId > 0
          ? providersById[activeProviderId]?.isBankInError || false
          : false),
      );
    },

    refundsEnabled() {
      return createSelector(
        slice(state => state.providersById),
        slice(state => state.activeProviderId),
        (providersById, activeProviderId): boolean => (activeProviderId > 0
          ? providersById[activeProviderId]?.refundsEnabled || false
          : false),
      );
    },
  }),
});

export default model;

export const selectors = {
  activeProvider: (state: RootState) => state
    .providers
    .providersById[state.providers.activeProviderId]?.result,
  isSmartPricingEligible: (state: RootState): boolean => !!(state
    .providers
    .providersById[state.providers.activeProviderId]?.isSmartPriceEligible),
  isBankInError: (state: RootState): boolean => !!(state
    .providers
    .providersById[state.providers.activeProviderId]?.isBankInError),
};
