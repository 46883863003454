/**
 * Return a formatted representation of the given discount type and quantity.
 *
 * @param promo {Object} - an object with discount_type and discount_quantity properties.
 * @returns {string}
 */
export default function formatPromotionDiscount(promo) {
  let formatted = String(promo.discount_quantity);
  // Remove trailing zeros
  formatted = formatted.replace('.00', '');
  if (promo.discount_type === 'percentage') {
    formatted = `${formatted}%`;
  } else {
    formatted = `$${formatted}`;
  }
  return formatted;
}
