import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import type { IRootDispatch } from '../store/models';

export type LinkTo = (
  route: string,
  params?: Record<string, any>,
  notify?: boolean,
  reload?: boolean,
  replace?: boolean,
) => Promise<any>;

export type UseLinkToParams = {
  notify?: boolean;
  reload?: boolean;
  replace?: boolean;
};

const useLinkTo = (options?: UseLinkToParams) => {
  const dispatch: IRootDispatch = useDispatch();
  const effectiveOptions = {
    notify: true,
    reload: false,
    replace: false,
    ...options,
  };

  return useCallback(
    (
      route: string,
      params: Record<string, any> = {},
      notify: boolean = effectiveOptions.notify,
      reload: boolean = effectiveOptions.reload,
      replace: boolean = effectiveOptions.replace,
    ) => dispatch.route.go({
      route,
      params,
      notify,
      reload,
      replace,
    }),
    [
      dispatch?.route,
      effectiveOptions?.notify,
      effectiveOptions?.reload,
      effectiveOptions?.replace,
    ],
  );
};

export default useLinkTo;
