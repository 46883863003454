/* eslint-disable no-restricted-properties */

/**
 * Is the passed in amount a valid
 * currency decimal
 * @param  {Number/String} amount The amount to validate
 * @return {Boolean}
 */
const decimalIsValid = (amount: string | number) => {
  const split = String(amount).split('.');
  let result = true;
  if (split.length === 2) {
    result = split[1].length <= 2;
  } else if (split.length > 2) {
    result = false;
  }
  return result;
};

/**
 * Helper that checks whether user input is or could end up being a valid decimal
 * @param {String} inputText Text input by a user
 * @returns {Boolean} Whether text is a prefix or full match for a decimal
 */
export const isValidDecimalAsUserTypes = (
  inputText: string,
  decimalPlaces: number = 2,
): boolean => {
  const startsWithNumericDigits = String.raw`^\d+`;
  const hasDecimalSuffix = String.raw`\.?\d{0,${decimalPlaces}}`;
  const endOfString = String.raw`$`;
  const pattern = new RegExp(
    String.raw`${startsWithNumericDigits}${decimalPlaces > 0 ? hasDecimalSuffix : ''}${endOfString}`,
  );

  return !!inputText.match(pattern);
};

const Currency = {
  /**
   * Parses a numeric value from a currency string.
   * @example
   * // returns 20.0
   * Currency.parse('$20.00');
   * @param {String|Number} currency - The formatted curremcy amount
   * @returns {Number} Returns the passed in value as a float (or 0 if NaN)
   */
  parse(currency: string | number | undefined | null): number {
    if (currency === undefined || currency === null) {
      return 0;
    }
    let result = currency;
    // eslint-disable-next-line no-useless-escape
    result = parseFloat(result.toString().replace(/[^\.\d]+/g, ''));
    return !Number.isNaN(result) ? result : 0;
  },

  /**
   * Format a dollar amount to a string currency value
   *
   * @param {Number|String} value - Dollar amount
   * @return {String} - Formatted string with pattern $NN.NN
   */
  format(value: number | string): string {
    const numValue = Number.parseFloat(String(value));
    if (Number.isNaN(numValue)) {
      return '';
    }
    return numValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  },

  /**
   * Format a dollar amount to a shortened string currency value
   *
   * @param {Number | String} value - Dollar amount
   * @return {String} - Formatted string with pattern $NN[.NN] (.00 gets stripped)
   */
  shortFormat: (value: string | number): string => Currency.format(value).replace(/\.00$/, ''),

  /**
   * The the passed in amount a valid tip
   * @param  {Number/String}  tipAmount The tip amount
   * @return {Boolean}
   */
  isValidTip(tipAmount: number | string): boolean {
    const tipMax = 100000;
    const isValid = (
      tipAmount !== ''
      && Number.isFinite(Number(tipAmount))
      && Number(tipAmount) < tipMax
      && decimalIsValid(tipAmount)
    );
    return isValid;
  },

  /**
   * Format a dollar amount to a positive/negative string currency value.
   *
   * Negative values are surrounded by parentheses.
   *
   * @param {Number} value - Dollar amount
   * @return {String} ($1,000.00) or $1,000.00
   */
  formatLedgerAmount(value: number | string): string {
    const numValue = Number.parseFloat(String(value));
    return numValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  },

  /**
   * Insert commas between every three digits of the whole number part of a number.
   *
   * @param {String} x
   * @returns {string}
   */
  delimitWithCommas(x: string | number): string {
    const numValue = Number.parseFloat(String(x));
    return numValue.toLocaleString('en-US', {
      style: 'decimal',
    });
  },

  /**
   * Converts numbers to K, M, B format
   * This algorithm will include either 1 or 2 decimal places as long as the decimal is non-zero
   * @param value - number to format
   * @returns string
   * @example convertToKMB(10100) 10.1K
   */
  convertToKMB(value: number): string {
    const compactDisplay = value.toLocaleString('en-US', {
      style: 'decimal',
      notation: 'compact',
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
    // Additional logic to handle stripping trailing 0 decimals.
    // Ideally we could use trailingZeroDisplay option, but it's not supported by Node/all browsers
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#trailingzerodisplay
    if (compactDisplay.match(/^\d*\.?\d*[A-Z]/)) {
      const formattedNumber = compactDisplay.substring(
        0,
        compactDisplay.length - 1,
      ).replace(/\.0$/, '');
      const qualifier = compactDisplay.charAt(compactDisplay.length - 1);
      return `${formattedNumber}${qualifier}`;
    }
    return compactDisplay.replace(/\.0$/, '');
  },

  isValueNonZeroDollars(value: string | number): boolean {
    return Currency.parse(value) !== 0;
  },
};

export default Currency;
