// @ts-strict-ignore
import React, { useMemo } from 'react';
import { AppsFlyerSmartBanner } from './AppsFlyerSmartBanner';
import { getIsApp } from '../../../modules/AppInfo';
import { useIsMobile } from '../../../hooks';

export type SmartBannerContextType = {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SmartBannerContext = React.createContext<SmartBannerContextType>({
  setIsVisible: null,
});

export const SmartBannerProvider = ({
  children,
}) => {
  const [isVisible, setIsVisible] = React.useState(true);
  const value = useMemo(() => ({
    setIsVisible,
  }), [setIsVisible]);
  const isMobile = useIsMobile();
  const displayBanner = useMemo(() => (
    isMobile && typeof window?.AF !== 'undefined' && !getIsApp()
  ), [isMobile]);

  return (
    <SmartBannerContext.Provider value={value}>
      {children}
      { displayBanner && (
        <AppsFlyerSmartBanner
          isVisible={isVisible}
        />
      )}
    </SmartBannerContext.Provider>
  );
};
