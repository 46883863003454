import { openExternalUrl } from './openExternalUrl';

export const SUPPORT_PAGE_URL = 'https://styleseat.freshdesk.com/support/home';
export const LIVE_CHAT_SUPPORT_PAGE_URL = 'https://styleseat.freshdesk.com/support/home?live_chat=1';

/**
 * Module that manages our support service solution (used to be for ZenDesk).
 * currently only exposes the ability to open a support link
 */
class SupportServiceWrapper {
  static open() {
    openExternalUrl(SUPPORT_PAGE_URL);
  }
}

export default SupportServiceWrapper;
