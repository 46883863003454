import regeneratorRuntime from 'regenerator-runtime';
// Always include this first
// https://stackoverflow.com/questions/40897966/objects-are-not-valid-as-a-react-child-in-internet-explorer-11-for-react-15-4-1
import '@babel/polyfill';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import '../3rdParty/sdks';

window.regeneratorRuntime = regeneratorRuntime;

/**
 * Primary libraries needed for the app and installed from npm.
 */

// React
window.React = React;
window.ReactDOM = ReactDOM;

// Moment
window.moment = extendMoment(moment);
