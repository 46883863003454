// @ts-strict-ignore
import { RematchRootState, createModel } from '@rematch/core';
import { ModelSelectorFactories, StoreSelectors } from '@rematch/select';
import type { RootModel } from '../../../../store/models';
import { PrivilegedProvider, PublicProvider } from '../../../../api/Providers';
import { DateString } from '../../../../types';
import {
  CalendarModeOptions,
  getCalendarMode,
  parseSelectedDateFromParams,
  normalizeDateParams,
  isNormalizedDateSame,
  parseDateParamsFromDateString,
} from '../../../../modules/provider/Calendar';
import { CalendarAppointment } from '../../../../store/ProviderCalendar';
import { IWaitlistItem } from '../../../../api/Providers/Waitlist';
import { ParsedWorkPeriods } from '../../../../modules/provider/WorkPeriod';

interface CalendarPageState {
  loaded: boolean;
  providerId: number;
  error: boolean;
}

interface ChangeSelectedDateRangePayload {
  selectedDate: DateString;
  selectedHour?: number;
}

interface LandPayload {
  providerId: number;
}

export interface CalendarData {
  provider: PrivilegedProvider | PublicProvider;
  selectedDate: DateString;
  selectedAppt: CalendarAppointment | null;
  mode: CalendarModeOptions;
  loaded: boolean;
  error: boolean;
  waitlist: IWaitlistItem[];
  workPeriods: ParsedWorkPeriods;
}

export interface CalendarPageData extends CalendarPageState {
  serviceNames: Array<string>;
}

const CalendarPage = createModel<RootModel>()({
  name: 'calendarPage',

  state: {
    loaded: false,
    providerId: null,
    error: false,
  } as CalendarPageState,

  reducers: {
    onLand: (
      state: CalendarPageState,
      payload: Partial<CalendarPageState>,
    ) => ({
      ...state,
      error: false,
      ...payload,
    }),

    onFailed: (
      state: CalendarPageState,
    ) => ({
      ...state,
      error: true,
      loaded: true,
    }),

    onLanded: (
      state: CalendarPageState,
    ): CalendarPageState => ({
      ...state,
      loaded: true,
    }),
  },

  effects: dispatch => ({
    async land({
      providerId,
    }: LandPayload, rootState) {
      dispatch.calendarPage.onLand({
        providerId,
      });

      const {
        mode: routeMode,
        hour: selectedHour,
      } = rootState.route.params || {};

      const {
        year, month, day,
      } = normalizeDateParams(rootState.route.params);
      const selectedDate = parseSelectedDateFromParams({
        year, month, day,
      });

      const mode = await getCalendarMode(routeMode);
      if (
        routeMode !== mode
        || !isNormalizedDateSame(rootState.route.params)
      ) {
        await dispatch.route.addParams({
          params: {
            mode,
            year,
            month,
            day,
            selectedHour,
          },
          replace: true,
        });
      }

      try {
        await Promise.all([
          dispatch.providers.loadProvider({
            providerId,
            setActive: true,
          }),
          dispatch.providerCalendar.load({
            providerId,
            selectedDate,
          }),
          // We need to wait for this so we know if pros can create appointments or not
          dispatch.stripeIdentityVerification.load(),
        ]);
        dispatch.calendarPage.onLanded();
      } catch {
        dispatch.calendarPage.onFailed();
        return;
      }

      await dispatch.loader.setIsLoading(false);
    },

    async changeSelectedDateRange(
      {
        selectedDate,
        selectedHour,
      }: ChangeSelectedDateRangePayload,
      rootState,
    ): Promise<void> {
      const { params } = rootState.route;
      const prevSelectedDate = parseSelectedDateFromParams(params);
      const prevSelectedHour = params?.hour ? Number(params.hour) : undefined;

      // Nothing to update if the params already match
      if (
        prevSelectedDate === selectedDate
        && (typeof selectedHour !== 'number' || selectedHour === prevSelectedHour)
      ) {
        return;
      }

      const dateParams = parseDateParamsFromDateString(selectedDate);
      await dispatch.route.addParams({
        params: {
          ...dateParams,
          hour: selectedHour,
        },
        replace: true,
      });

      dispatch.providerCalendar.loadSchedule({
        providerId: rootState.providerCalendar.providerId,
        month: selectedDate,
      });

      await Promise.all([
        dispatch.providerCalendar.loadThisWeek({
          date: selectedDate,
        }),
        dispatch.providerCalendar.loadNextWeek({
          date: selectedDate,
        }),
      ]);
    },
  }),

  selectors: (
    slice,
    createSelector,
  ): ModelSelectorFactories<RootModel, Record<string, never>> => ({
    calendarData(models: StoreSelectors<RootModel, Record<string, never>>) {
      return createSelector(
        models.providers.activeProvider,
        models.calendarPage.selectedDate,
        models.calendarPage.selectedAppt,
        models.calendarPage.mode,
        slice(state => state.loaded),
        slice(state => state.error),
        models.providerWaitlist.list,
        models.providerWorkPeriods.getAll,
        (
          provider,
          selectedDate,
          selectedAppt,
          mode,
          loaded,
          error,
          waitlist,
          workPeriods,
        ): CalendarData => ({
          provider,
          selectedDate,
          selectedAppt,
          mode,
          loaded,
          error,
          waitlist,
          workPeriods,
        }),
      );
    },

    selectedDate() {
      return createSelector(
        rootState => rootState.route.params?.year,
        rootState => rootState.route.params?.month,
        rootState => rootState.route.params?.day,
        (year, month, day) => (
          parseSelectedDateFromParams({
            year, month, day,
          })
        ),
      );
    },

    selectedHour: () => (
      rootState => (rootState.route.params?.hour ? Number(rootState.route.params?.hour) : null)
    ),

    mode: () => (
      rootState => rootState.route.params?.mode as CalendarModeOptions || CalendarModeOptions.Week
    ),

    isSelecting: () => (
      rootState => rootState.route.params?.select === 'true'
    ),

    selectedApptId: () => createSelector(
      (rootState: RematchRootState<RootModel>) => rootState.route.params?.reschedule === 'true',
      (rootState: RematchRootState<RootModel>) => rootState.route.params?.appointmentId,
      (isRescheduling, appointmentId) => {
        if (isRescheduling && appointmentId) {
          return Number(appointmentId);
        }
        return null;
      },
    ),

    selectedAppt: models => createSelector(
      models.calendarPage.selectedApptId,
      models.providerCalendar.appointmentsById,
      (selectedApptId, appointments) => (
        selectedApptId ? appointments[`${selectedApptId}`] : null
      ),
    ),
  }),
});

export default CalendarPage;
