import moment from 'moment';
import {
  compareData,
  fill,
  groupBy,
} from '..';
import { ComparedDataSet } from '../compare';
import { filterByDate } from '../filters';
import type {
  MasterDataSet,
  MonthlyRevenueData,
  StatDataSet,
} from '../types';

export const getMonthlyRevenue = (
  stats: MasterDataSet<'actual_revenue'>,
): MonthlyRevenueData => {
  const startOfThisMonth = moment().startOf('month');
  const endOfThisMonth = moment().endOf('month');
  const lastMonth = moment().subtract(1, 'month');
  const startOfLastMonth = lastMonth.clone().startOf('month');
  const endOfLastMonth = lastMonth.clone().endOf('month');

  const data = filterByDate(
    stats as MasterDataSet<'actual_revenue' | 'projected_revenue'>,
    startOfLastMonth,
    moment(),
  );

  const filledData = fill(
    data,
    {
      gross_revenue: 0,
      processed_revenue: 0,
      nonprocessed_revenue: 0,
    },
    lastMonth,
    endOfThisMonth,
  );
  const comparedResult = compareData<'actual_revenue'>(
    filledData.actual_revenue as StatDataSet<'actual_revenue'>,
    {
      start: startOfThisMonth,
      end: endOfThisMonth,
    },
    {
      start: startOfLastMonth,
      end: endOfLastMonth,
    },
  );
  const {
    source: currentMonthData,
    compared: lastMonthData,
  } = groupBy<'actual_revenue'>(
    comparedResult as ComparedDataSet<'actual_revenue'>['actual_revenue'],
    'week',
  );

  return {
    currentMonth: currentMonthData,
    lastMonth: lastMonthData,
  };
};
