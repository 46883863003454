// @ts-strict-ignore
import LogRocket from 'logrocket';
import { StripeTerminalConnectError } from '../../store/StripeTerminal/StripeTerminalConnectError';
import { selectors } from '../../store/StripeTerminal/StripeTerminal.selectors';
import { TapToPayPluginErrorCodes } from './TapToPayPlugin.types';
import { log } from './TapToPayLogger';
import nonCriticalException from '../exceptionLogger';

type WaitForReaderConnectionOptions = {
  getState: () => any;
  connectReader?: () => any;
  syncReaderConnectionStatus?: () => Promise<void>;
  pollInterval?: number;
  timeout?: number;
};
/** Allows application logic to await on a connection to complete; includes
     * timeout logic to gracefully fail if the model is stuck in the connecting state */
export const waitForReaderConnection = async (
  {
    getState, connectReader, syncReaderConnectionStatus, pollInterval = 500, timeout = 30000,
  }: WaitForReaderConnectionOptions,
): Promise<boolean> => {
  try {
    await syncReaderConnectionStatus?.();
  } catch (e) {
    log('waitForReaderConnection: syncReaderConnectionStatus failed', e);
    nonCriticalException(e);
    LogRocket.captureException(e, {
      tags: {
        TapToPay: 'WaitForReaderConnection',
      },
    });
  }

  if (selectors.isConnected(getState())) {
    return true;
  }
  if (selectors.isNotConnected(getState())) {
    if (typeof connectReader === 'function') {
      connectReader();
    } else {
      throw new StripeTerminalConnectError('Reader Not Connected', TapToPayPluginErrorCodes.NotConnectedToReader);
    }
  }

  let pollingId: ReturnType<typeof setInterval>;
  let timeoutId: ReturnType<typeof setTimeout>;
  return new Promise((resolve, reject) => {
    pollingId = setInterval(() => {
      if (!selectors.isInProgress(getState())) {
        if (timeoutId) clearTimeout(timeoutId);
        if (selectors.isConnected(getState())) {
          resolve(true);
        } else {
          reject(new StripeTerminalConnectError('Reader Not Connected', TapToPayPluginErrorCodes.NotConnectedToReader));
        }
        clearInterval(pollingId);
      }
    }, pollInterval);

    timeoutId = setTimeout(() => {
      if (pollingId) clearInterval(pollingId);
      if (selectors.isConnected(getState() as any)) {
        resolve(true);
      } else {
        LogRocket.track('Tap to Pay Checkout Reader Connection Timed Out');
        reject(new StripeTerminalConnectError('Connection timed out'));
      }
    }, timeout);
  });
};
