// @ts-strict-ignore
import moment, { MomentInput } from 'moment';

import analytics from '../../analytics';
import {
  DynamicNotification,
  Notification,
  StaticNotification,
} from './Notifications.types';
import { DateTimeStamp } from '../../../types/dateTime';

export const NOTIFICATION_CLICKED_EVENT = 'pro_notification_clicked';

export const getFromNowDisplay = (time: MomentInput) => {
  const now = moment();
  const creationTime = moment(time);
  const diff = now.diff(creationTime, 'minutes');
  if (diff <= 5) {
    return 'Just Now';
  }
  return creationTime.fromNow();
};

function valueOrUndefined<T = any>(notification: Notification, field: string): T | undefined {
  return field in notification ? notification[field] : undefined;
}

/**
 * creates the notification params for tracking purposes
 * @param {Object} notification - the notification object
 * @return {Object} params - params for tracking
 */
export const createNotificationParams = (notification: Notification) => ({
  notification_key: notification.uuid,
  appointment_id: valueOrUndefined(notification, 'appointment_id'),
  client_id: valueOrUndefined(notification, 'client_id'),
  provider_id: valueOrUndefined(notification, 'provider_id'),
  service_id: valueOrUndefined(notification, 'service_id'),
  service_name: valueOrUndefined(notification, 'service_name'),
  service_cost: valueOrUndefined(notification, 'total_appt_cost'),
  is_new_client_delivery: valueOrUndefined(notification, 'is_new_client_delivery'),
  is_smart_price: valueOrUndefined(notification, 'is_smart_price'),
  notification_type: notification.type,
});

/**
 * Track a notification click
 * @param   {Object} notification The notification object
 * @param   {Object} params The tracking params
 * @returns {undefined}
 */
export const trackNotificationClick = (notification: Notification) => {
  const params = createNotificationParams(notification);
  analytics.track(NOTIFICATION_CLICKED_EVENT, params);
};

export function getProviderIdFromNotification(notification: Notification): number | undefined {
  return valueOrUndefined<number>(notification, 'provider_id');
}

export function getLocalStartFromNotification(
  notification: Notification,
): DateTimeStamp | undefined {
  return valueOrUndefined<DateTimeStamp>(notification, 'local_start');
}

export function isStaticNotification(
  notification: Notification,
): notification is StaticNotification {
  return 'persistent' in notification && notification.persistent;
}

export function isDynamicNotification(
  notification: Notification,
): notification is DynamicNotification {
  return !isStaticNotification(notification);
}
