// @ts-strict-ignore
import { createModel } from '@rematch/core';
import moment from 'moment';
import produce from 'immer';
import type { RootModel } from '../models';
import {
  BookingNotification,
  DynamicNotification,
  LAST_VIEWED_NOTIFICATION_STATE_KEY,
  LAST_VIEWED_STATIC_NOTIFICATION_PREFIX,
  NOTIFICATION_POLLING_INTERVAL,
  Notification,
  NotificationResponse,
  fetchNotifications,
} from '../../api/Providers/Notifications';
import { DateTimeStamp } from '../../types/dateTime';
import {
  createAddClientNotification,
  createAmbassadorToolkitNotification,
  createMarketingHubNotification,
  createNcdCancellationPolicyNotification,
  createSearchBoostNotification,
  createSearchResultStatsNotification,
  createWelcomeNotification,
  getProviderIdFromNotification,
  isDynamicNotification,
  isStaticNotification,
  shouldShowAddClientNotification,
  shouldShowAmbassadorToolkitNotification,
  shouldShowMarketingHubNotification,
  shouldShowNcdCancellationPolicyNotification,
  shouldShowSearchBoostNotification,
  shouldShowWelcomeNotification,
  createTaxEDeliveryNotification,
  shouldShowTaxEDeliveryNotification,
  TAX_EDELIVERY_REMINDER_DISMISSED_KEY,
} from '../../modules/provider/Notifications';
import {
  PRO_SEARCH_BOOST_FLAG,
  shouldHideEligibilityNotification,
  shouldHideExtendEligibilityNotification,
} from '../../modules/provider/SearchBoost';
import { stateManager } from '../../modules/user/stateManager';

export interface NotificationsWithUnreadCount {
  notifications: Notification[];
  unreadCount: number;
}

export interface StaticNotificationFlags {
  showAddClientNotification: boolean;
  showSearchBoostNotification: boolean;
  showAmbassadorToolkitNotification: boolean;
  showMarketingHubNotification: boolean;
  showNcdCancellationPolicyNotification: boolean;
  showWelcomeNotification: boolean;
  showTaxEDeliveryNotification: boolean;
}

interface ProviderNotificationState extends StaticNotificationFlags {
  loaded: boolean;
  providerId: number | undefined;
  dynamicNotifications: DynamicNotification[];
  /** profile creation time */
  profileCreationTime?: DateTimeStamp;
  /** date at which pro has earned search boost and now boosted in search */
  profileSearchBoostEligibleDate?: DateTimeStamp;
  /** start date at which pro can start earning search boost by booking 6+ appts */
  profileSearchBoostEarningStartDate?: DateTimeStamp;
  /** end date at which pro can earn a search boost by booking 6+ appts */
  profileSearchBoostEarningEndDate?: DateTimeStamp;
  /** datetime pro extended time to earn a search boost */
  profileSearchBoostExtensionCreationTime?: DateTimeStamp;
  lastViewedNotificationTimestamp?: DateTimeStamp;
  lastViewedStaticNotificationTimestamps: Record<string, DateTimeStamp>;
  pollingProviderId?: number;
  pollingId?: number;
}

interface OnLoadedPayload {
  providerId: number;
  response: NotificationResponse;
  staticNotificationFlags: StaticNotificationFlags;
  lastViewedNotificationTimestamp?: DateTimeStamp;
  lastViewedStaticNotificationTimestamps: Record<string, DateTimeStamp>;
}

interface OnStaticNotificationsSeenPayload {
  timestamps: Record<string, DateTimeStamp>;
}

interface OnPollingStartedPayload {
  providerId: number;
  timerId: number;
}

interface OnPolledPayload {
  providerId: number;
  response: NotificationResponse;
}

function getDefaultState(): ProviderNotificationState {
  return {
    loaded: false,
    dynamicNotifications: [],
    providerId: undefined,
    showAddClientNotification: false,
    showAmbassadorToolkitNotification: false,
    showMarketingHubNotification: false,
    showNcdCancellationPolicyNotification: false,
    showSearchBoostNotification: false,
    showWelcomeNotification: false,
    showTaxEDeliveryNotification: false,
    lastViewedStaticNotificationTimestamps: {},
  };
}

interface LoadPayload {
  providerId: number;
  cached?: boolean;
}

interface StartPollingPayload {
  /** The provider for whom to start polling for notifications */
  providerId: number;
  /** The interval, in milliseconds, between polling attempts */
  interval?: number;
}

export const providerNotifications = createModel<RootModel>()({
  name: 'providerNotifications',
  state: getDefaultState(),
  reducers: {
    onLoaded: (state: ProviderNotificationState, payload: OnLoadedPayload) => ({
      loaded: true,
      providerId: payload.providerId,
      dynamicNotifications: payload.response.results,
      profileCreationTime: payload.response.profile_creation_time,
      profileSearchBoostEligibleDate: payload.response.profile_search_boost_eligible_date,
      profileSearchBoostEarningStartDate: payload.response.profile_search_boost_earning_start_date,
      profileSearchBoostEarningEndDate: payload.response.profile_search_boost_earning_end_date,
      profileSearchBoostExtensionCreationTime: (
        payload.response.profile_search_boost_extension_creation_time
      ),
      lastViewedStaticNotificationTimestamps: payload.lastViewedStaticNotificationTimestamps,
      lastViewedNotificationTimestamp: payload.lastViewedNotificationTimestamp,
      ...payload.staticNotificationFlags,
    }),

    onStaticNotificationsSeen: produce((
      state: ProviderNotificationState,
      { timestamps }: OnStaticNotificationsSeenPayload,
    ) => {
      Object.entries(timestamps).forEach(([key, timestamp]) => {
        state.lastViewedStaticNotificationTimestamps[key] = timestamp;
      });
    }),

    onLastNotificationSeen: produce((
      state: ProviderNotificationState,
      payload: DateTimeStamp,
    ) => {
      state.lastViewedNotificationTimestamp = payload;
    }),

    onPollingStarted: (
      state: ProviderNotificationState,
      payload: OnPollingStartedPayload,
    ) => ({
      ...state,
      pollingId: payload.timerId,
      pollingProviderId: payload.providerId,
    }),

    onPollingStopped: (
      state: ProviderNotificationState,
    ) => ({
      ...state,
      pollingId: undefined,
      pollingProviderId: undefined,
    }),

    onPolled: (
      state: ProviderNotificationState,
      payload: OnPolledPayload,
    ) => {
      if (payload.providerId === state.providerId) {
        return {
          ...state,
          dynamicNotifications: payload.response.results,
          profileCreationTime: payload.response.profile_creation_time,
          profileSearchBoostEligibleDate: payload.response.profile_search_boost_eligible_date,
          profileSearchBoostEarningStartDate: (
            payload.response.profile_search_boost_earning_start_date
          ),
          profileSearchBoostEarningEndDate: payload.response.profile_search_boost_earning_end_date,
          profileSearchBoostExtensionCreationTime: (
            payload.response.profile_search_boost_extension_creation_time
          ),
        };
      }

      return state;
    },
  },
  effects: dispatch => ({
    async load(payload: LoadPayload): Promise<void> {
      const [
        response,
        stateRecord,
      ] = await Promise.all([
        fetchNotifications(
          payload.providerId,
          { cached: payload.cached },
        ),
        stateManager.getActions(),
      ]);

      const staticNotificationFlags: StaticNotificationFlags = {
        showAddClientNotification: await shouldShowAddClientNotification(
          response.profile_creation_time,
          payload.providerId,
        ),
        showAmbassadorToolkitNotification: await shouldShowAmbassadorToolkitNotification(),
        showMarketingHubNotification: await shouldShowMarketingHubNotification(
          response.profile_creation_time,
        ),
        showNcdCancellationPolicyNotification: await shouldShowNcdCancellationPolicyNotification(),
        showSearchBoostNotification: await shouldShowSearchBoostNotification(
          response.profile_search_boost_eligible_date,
          response.profile_search_boost_earning_start_date,
        ),
        showWelcomeNotification: await shouldShowWelcomeNotification(),
        showTaxEDeliveryNotification: await shouldShowTaxEDeliveryNotification(
          payload.providerId,
        ),
      };

      const lastViewedStaticNotificationTimestamps: Record<string, DateTimeStamp> = {};

      Object.keys(stateRecord).forEach(key => {
        if (key.startsWith(LAST_VIEWED_STATIC_NOTIFICATION_PREFIX)) {
          lastViewedStaticNotificationTimestamps[key] = stateRecord[key].value;
        }
      });

      dispatch.providerNotifications.onLoaded({
        providerId: payload.providerId,
        response,
        staticNotificationFlags,
        lastViewedNotificationTimestamp: stateRecord[LAST_VIEWED_NOTIFICATION_STATE_KEY]?.value,
        lastViewedStaticNotificationTimestamps,
      });
    },

    /**
     * Remembers that the persistent notifications in the passed argument have been seen. For this
     * to work, the notification must have a `type` attribute, that will be used to compose a
     * key used to store the time in the user state. E.g., if a persistent notification has a
     * type of "monthly_goal" and is present in the passed in notifications, the current date will
     * be set as a user state value under the `last_seen_notification_monthly_goal`.
     * @param {Array} notifications
     * @return {Promise} Promise that resolves when seen dates for all candidate notifications are
     * stored in user state.
     */
    async markStaticNotificationsSeen(notifications: Notification[], rootState): Promise<void> {
      const nowTimestamp = moment().toISOString();
      const promises = [];
      const currentUserProviderId = rootState.user.providerId;
      const timestamps = rootState.providerNotifications.lastViewedStaticNotificationTimestamps;
      const newTimestamps: Record<string, DateTimeStamp> = {};

      notifications.forEach(
        notification => {
          if (
            isStaticNotification(notification)
            && getProviderIdFromNotification(notification) === currentUserProviderId
            && notification.type
          ) {
            const seenKey = `${LAST_VIEWED_STATIC_NOTIFICATION_PREFIX}${notification.type}`;
            if (!timestamps[seenKey]) {
              promises.push(stateManager.addAction(seenKey, nowTimestamp));
              newTimestamps[seenKey] = nowTimestamp;
            }
          }
        },
        [],
      );

      await Promise.all(promises);
      await dispatch.providerNotifications.onStaticNotificationsSeen({ timestamps: newTimestamps });
    },

    /**
     * Mark the the notifications in the list as "seen". This is accomplished by finding the latest
     * non-persistent notification (aka those created by the backend which will expire after some
     * time in the stream) and remembering its creation time. Any transient notification created on
     * or after that date will be considered new next time we check in getNewNotificationCount. If
     * the provider_id in the latest notification does not match the primary provider ID on the
     * logged in user, the notifications will not be marked (to prevent admins impersonating pros
     * from remembering/clearing notifications not meant for them).
     *
     * @param {Array} notifications   Ordered list of notifications
     * @return {Promise}
     */
    async markLastNotificationSeen(notifications: Notification[], rootState): Promise<void> {
      const dynamicNotifications: DynamicNotification[] = (
        (notifications || []).filter(isDynamicNotification)
      );

      if (!dynamicNotifications.length) {
        return;
      }

      // We will be storing the creation time of the latest one
      // (notifications are sorted descending order)
      const latestDynamicNotification = dynamicNotifications[0];
      const providerId = getProviderIdFromNotification(latestDynamicNotification);
      const currentUser = rootState.user;

      // Only update if the notifications are for the logged in pro.
      // i.e. don't mark as viewed for super or admin users.
      if (currentUser.providerId !== Number(providerId)) {
        return;
      }

      const timestamp = moment.utc(latestDynamicNotification.creation_time).format('YYYY-MM-DDTHH:mm:ss');
      // Remember the creation time of the last transient notification in user state.
      await stateManager
        .addAction(LAST_VIEWED_NOTIFICATION_STATE_KEY, timestamp);
      await dispatch.providerNotifications.onLastNotificationSeen(timestamp);
    },

    async poll(payload: { providerId: number }): Promise<void> {
      const response = await fetchNotifications(
        payload.providerId,
        { cached: true },
      );

      await dispatch.providerNotifications.onPolled({
        providerId: payload.providerId,
        response,
      });
    },

    async stopPolling(_?: any, rootState?): Promise<void> {
      const { pollingId } = rootState.providerNotifications;

      if (pollingId) {
        clearInterval(rootState.providerNotifications.pollingId);
      }

      await dispatch.providerNotifications.onPollingStopped();
    },

    async startPolling(
      {
        providerId,
        interval = NOTIFICATION_POLLING_INTERVAL,
      }: StartPollingPayload,
      rootState,
    ): Promise<void> {
      if (
        providerId === rootState.providerNotifications.pollingProviderId
        && rootState.providerNotifications.pollingId
      ) {
        // we're already polling for the provider, no need to restart
        return;
      }

      await dispatch.providerNotifications.stopPolling();

      const timer = setInterval(() => {
        dispatch.providerNotifications.poll({ providerId });
      }, interval);

      await dispatch.providerNotifications.onPollingStarted({
        providerId,
        // @ts-expect-error We don't have node types
        timerId: timer,
      });
    },
  }),
  selectors: (slice, createSelector/* , hasProps */) => ({
    notificationsWithUnreadCount: () => (
      createSelector(
        slice(state => state),
        slice(state => ({
          showAddClientNotification: state.showAddClientNotification,
          showAmbassadorToolkitNotification: state.showAmbassadorToolkitNotification,
          showMarketingHubNotification: state.showMarketingHubNotification,
          showNcdCancellationPolicyNotification: state.showNcdCancellationPolicyNotification,
          showSearchBoostNotification: state.showSearchBoostNotification,
          showWelcomeNotification: state.showWelcomeNotification,
          showTaxEDeliveryNotification: state.showTaxEDeliveryNotification,
        } as StaticNotificationFlags)),
        rootState => rootState.abTest.oldFlags?.[PRO_SEARCH_BOOST_FLAG],
        rootState => rootState.userState.values?.[TAX_EDELIVERY_REMINDER_DISMISSED_KEY],
        (
          modelState: ProviderNotificationState,
          staticNotificationFlags: StaticNotificationFlags,
          isSearchBoostEnabled: boolean,
          taxNotificationDismissedDate: DateTimeStamp | undefined,
        ): NotificationsWithUnreadCount => {
          const {
            dynamicNotifications: notifications,
            providerId,
            loaded,
            profileCreationTime,
            profileSearchBoostEarningEndDate,
            profileSearchBoostEarningStartDate,
            profileSearchBoostEligibleDate,
            profileSearchBoostExtensionCreationTime,
            lastViewedStaticNotificationTimestamps,
            lastViewedNotificationTimestamp,
          } = modelState;

          const {
            showAddClientNotification,
            showAmbassadorToolkitNotification,
            showMarketingHubNotification,
            showNcdCancellationPolicyNotification,
            showSearchBoostNotification,
            showWelcomeNotification,
            showTaxEDeliveryNotification,
          } = staticNotificationFlags;

          let staticCount: number = 0;
          let filteredNotifications: Notification[] = [...notifications];

          if (showSearchBoostNotification) {
            const searchBoostNotification = createSearchBoostNotification(
              profileSearchBoostEligibleDate,
              profileSearchBoostEarningStartDate,
              providerId,
              isSearchBoostEnabled,
            );
            filteredNotifications.unshift(searchBoostNotification);
            staticCount += 1;
          }

          // if pro has has a search boost eligible date, it means the pro has met the requirement
          // to actually be search boosted. We will want to forever hide the search boost
          // eligibility notification to prevent confusion (e.g. pro could be search boosted again
          // after first boost)
          if (shouldHideEligibilityNotification(profileSearchBoostEligibleDate)) {
            filteredNotifications = filteredNotifications.filter(
              notification => notification.type !== 'search_boost_eligibility',
            );
          }

          const hideExtendEligibilityNotification = shouldHideExtendEligibilityNotification(
            profileSearchBoostEarningEndDate,
            profileSearchBoostExtensionCreationTime,
          );

          if (hideExtendEligibilityNotification) {
            filteredNotifications = filteredNotifications.filter(
              notification => notification.type !== 'extend_search_boost_eligibility',
            );
          }

          if (showAddClientNotification) {
            const addClientNotification = createAddClientNotification(
              profileCreationTime,
              providerId,
            );
            filteredNotifications.unshift(addClientNotification);
            staticCount += 1;
          }

          if (showAmbassadorToolkitNotification) {
            const ambassadorToolkitNotification = createAmbassadorToolkitNotification(providerId);
            filteredNotifications.unshift(ambassadorToolkitNotification);
            staticCount += 1;
          }

          const firstSmartPricedAppt: BookingNotification = filteredNotifications.find(
            notification => notification.type === 'first_sp_client_appt_booking',
          ) as BookingNotification;
          if (firstSmartPricedAppt) {
            filteredNotifications = filteredNotifications.filter(notification => !(
              notification.type === 'client_appt_booking'
              && notification.appointment_id === firstSmartPricedAppt.appointment_id
            ));
          }

          if (showMarketingHubNotification) {
            const marketingHubNotification = createMarketingHubNotification(providerId);
            filteredNotifications.unshift(marketingHubNotification);
            staticCount += 1;
          }

          if (showNcdCancellationPolicyNotification) {
            const ncdPolicyNotification = createNcdCancellationPolicyNotification(providerId);
            filteredNotifications.unshift(ncdPolicyNotification);
            staticCount += 1;
          }

          filteredNotifications.unshift(
            createSearchResultStatsNotification(providerId),
          );
          staticCount += 1;

          // Has the tax notification been dismissed within the past 4 days?
          const isTaxNotificationDismissed = !!taxNotificationDismissedDate
            && moment(taxNotificationDismissedDate).add(4, 'days').isAfter(moment());
          if (!isTaxNotificationDismissed && showTaxEDeliveryNotification) {
            const taxEDeliveryNotification = createTaxEDeliveryNotification();
            filteredNotifications.unshift(taxEDeliveryNotification);
            staticCount += 1;
          }

          if (showWelcomeNotification) {
            filteredNotifications.unshift(createWelcomeNotification(
              providerId,
              filteredNotifications[0].creation_time,
            ));
            staticCount += 1;
          }

          let unreadCount: number = 0;

          // wait to display any unread count until the user state has been successfully loaded
          if (loaded) {
            const lastSeenTimesCount = Object.values(
              lastViewedStaticNotificationTimestamps,
            ).filter(Boolean).length;
            const staticUnreadCount = Math.max(staticCount - lastSeenTimesCount, 0);
            const dynamicNotifications = filteredNotifications.filter(
              isDynamicNotification,
            );

            // haven't seen any notifications, all must be new.
            if (!lastViewedNotificationTimestamp) {
              unreadCount = staticUnreadCount + dynamicNotifications.length;
            } else {
              const lastViewedNotificationCreation = moment(lastViewedNotificationTimestamp);

              // Notifications are sorted by descending date. The index of the first notification
              // created before the last view date maps corresponds to the number of unseen
              // notifications.
              const lastViewedIndex = dynamicNotifications.findIndex(
                notification => (
                  moment(notification.creation_time) <= lastViewedNotificationCreation
                ),
              );

              // All notifications are newer than the last one we saw.
              if (lastViewedIndex === -1) {
                unreadCount = staticUnreadCount + dynamicNotifications.length;
              } else {
                unreadCount = staticUnreadCount + lastViewedIndex;
              }
            }
          }

          return {
            notifications: filteredNotifications,
            unreadCount,
          };
        },
      )
    ),
  }),
});
