import { useSelector } from 'react-redux';
import type { RootState } from '../store/models';

/**
 * This hook excludes non-superusers (returns true) from the consideration and
 * returns the argument when it encounters the superuser account.
 * @param allowSuperUser - boolean that allows/forbids the superuser
 * @returns - boolean
 */
export const useAllowSuperUser = (allowSuperUser: boolean = false): boolean => {
  const isSuperUser = useSelector((state: RootState) => state.user?.is_superuser);

  return !isSuperUser || (isSuperUser && allowSuperUser);
};
