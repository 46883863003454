import { createModel } from '@rematch/core';
import type { RootModel } from '../models';
import { HistoryRecord, IProviderClient } from '../../api/Providers/Clients/types';
import { ProAppointment } from '../../api/Providers/Appointments';
import { getClientHistory } from '../../api/Providers/Clients/History';
import { getClientAppointmentsWithinAYear } from '../../api/Providers/Clients/Appointments';
import nonCriticalException from '../../modules/exceptionLogger';
import { isFutureAppointment } from '../../modules/ProAppointmentState';

export const ERROR_MESSAGE = 'An error occurred loading details for this client.';

export interface ClientTimelineState {
  loading: boolean;
  error: string | null;
  history: HistoryRecord[];
  future: ProAppointment[];
}

export interface AppointmentClientTimelineData {
  client: IProviderClient;
  future: ProAppointment[];
  history: HistoryRecord[];
  loading: boolean;
}

export interface ClientDetailsTimelineData {
  client: IProviderClient;
  nextAppointment: ProAppointment | null;
  futureAppointments: ProAppointment[];
  history: HistoryRecord[];
  loading: boolean;
}

interface LandPayload {
  providerId: number;
  clientId: number;
}

interface OnLandPayload {
  history: HistoryRecord[];
  future: ProAppointment[];
}

interface OnLoadHistoryPayload {
  history: HistoryRecord[];
}

interface OnLandErrorPayload {
  error: string;
}

function historyWithoutSpecifiedOrFutureAppointments(
  history: HistoryRecord[],
  appointmentId: number | null = null,
): HistoryRecord[] {
  return history.filter(historyItem => (
    [
      'note',
      'photo',
      'appointment',
    ].includes(historyItem.type)
    && (typeof appointmentId !== 'number' || appointmentId !== historyItem.id)
    && !(historyItem.type === 'appointment' && isFutureAppointment(historyItem))
  ));
}

function getInitialState(): ClientTimelineState {
  return {
    loading: false,
    history: [],
    future: [],
    error: null,
  };
}

export const providerClientTimeline = createModel<RootModel>()({
  name: 'providerClientTimeline',
  state: getInitialState(),
  reducers: {
    onLand: state => ({
      ...state,
      error: null,
      loading: true,
    }),
    onLandSuccess: (_, payload: OnLandPayload) => ({
      error: null,
      loading: false,
      ...payload,
    }),
    onLandError: (_, payload: OnLandErrorPayload) => ({
      loading: false,
      history: [],
      future: [],
      error: payload.error,
    }),
    onLoadHistory: (state, payload: OnLoadHistoryPayload) => ({ ...state, ...payload }),
  },

  effects: dispatch => ({
    async loadHistory({
      clientId,
      providerId,
    }: LandPayload): Promise<void> {
      const clientHistoryResponse = await getClientHistory(providerId, clientId);

      dispatch.providerClientTimeline.onLoadHistory({ history: clientHistoryResponse.results });
    },

    async land({
      clientId,
      providerId,
    }: LandPayload): Promise<void> {
      await dispatch.providerClientTimeline.onLand();

      try {
        const [
          clientHistoryResponse,
          clientFutureResponse,
        ] = await Promise.all([
          getClientHistory(
            providerId,
            clientId,
          ),
          getClientAppointmentsWithinAYear(providerId, clientId),
          dispatch.providerClients.loadClient({
            providerId,
            clientId,
          }),
        ]);

        await dispatch.providerClientTimeline.onLandSuccess({
          history: clientHistoryResponse.results,
          future: clientFutureResponse.results,
        });
      } catch (err) {
        nonCriticalException(err);
        dispatch.providerClientTimeline.onLandError({ error: ERROR_MESSAGE });
      }
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    historyWithoutFutureAppointments: () => (
      createSelector(
        slice(state => state.history),
        historyWithoutSpecifiedOrFutureAppointments,
      )
    ),
    appointmentClientData: hasProps((
      models,
      { appointmentId, clientId }: { appointmentId: number; clientId: number },
    ) => (
      createSelector(
        models.providerClients.client(clientId),
        slice(s => s.future),
        slice(state => state.history),
        slice(s => s.loading),
        (
          client: IProviderClient,
          future: ProAppointment[],
          history: HistoryRecord[],
          loading: boolean,
        ): AppointmentClientTimelineData => {
          const futureAppointmentsWithoutSpecified = future.filter(
            appt => appt.id !== appointmentId,
          );
          const filteredHistory = historyWithoutSpecifiedOrFutureAppointments(
            history,
            appointmentId,
          );

          return {
            client,
            future: futureAppointmentsWithoutSpecified,
            history: filteredHistory,
            loading,
          };
        },
      )
    )),

    clientDetailsData: hasProps((
      models,
      { clientId }: { clientId: number },
    ) => (
      createSelector(
        models.providerClients.client(clientId),
        slice(s => s.future),
        slice(state => state.history),
        slice(s => s.loading),
        (
          client: IProviderClient,
          future: ProAppointment[],
          history: HistoryRecord[],
          loading: boolean,
        ): ClientDetailsTimelineData => {
          const historyWithoutFutureAppointments = historyWithoutSpecifiedOrFutureAppointments(
            history,
          );

          const indexOfFirstFutureActiveAppointment = future.findIndex(appt => appt.is_active);
          const futureAppointments = future.slice();

          let nextAppointment: ProAppointment | null = null;
          if (indexOfFirstFutureActiveAppointment >= 0) {
            [nextAppointment] = futureAppointments.splice(indexOfFirstFutureActiveAppointment, 1);
          }

          return {
            client,
            nextAppointment,
            futureAppointments,
            history: historyWithoutFutureAppointments,
            loading,
          };
        },
      )
    )),
  }),
});
