import React from 'react';

/**
 * useOnce this will call the effect once the first time the dependency is not null
 *
 * This is kind of like a useMount except it waits for a variable to be defined before
 * executing the effect once.
 *
 * @param cb
 * @param dep
 */
export default function useOnce(cb: () => void | any, dep: any | null | undefined) {
  const hasRunRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!hasRunRef.current && !!dep) {
      hasRunRef.current = true;
      cb();
    }
  }, [
    cb,
    dep,
  ]);
}
