// @ts-strict-ignore
import { uniqueId } from 'underscore';
import { AccessibilityInfo } from 'react-native';
import modalRegistry from '../../../modules/modalRegistry';
import styles from './PortalElement.module.scss';
import {
  IBackdropManager,
  ModalAnimationStyle,
  PortalElementArgs,
} from './types';

export default class PortalElement {
  readonly uuid = uniqueId('portalElement');
  private readonly args: PortalElementArgs;

  private el: HTMLElement;

  private elDest: HTMLElement;

  private readonly animationStyle: ModalAnimationStyle;

  private backdropManager: IBackdropManager;

  private backdropClickHandler: (evt: MouseEvent) => void | null = null;

  private requestExternalCloseHandler: () => void;

  constructor(
    backdropManager: IBackdropManager,
    args: PortalElementArgs,
  ) {
    this.args = args;
    this.backdropManager = backdropManager;
    this.animationStyle = args.disableAnimation ? null : args.animationStyle;

    modalRegistry.registerModal({
      id: this.uuid,
      inPortalElementRoot: true,
      forceTop: args?.forceTop,
    });
  }

  getElement(): HTMLElement {
    // NOTE: previously, the code that adds the element to the DOM was running in the constructor.
    // Because of how things are called in PortalOverlay and interplay between React and angular,
    // this was causing some backdrops to be orphaned. The overlay was essentially rendering once,
    // running the useEffect that runs `PortalElement.getElement`, but not rendering again to get
    // into the DOM.

    if (!this.elDest) {
      // #portal-element-root element is in index.html and should always be present
      // except in jest-dom, where we want to use the document body instead
      const domEl = document.getElementById('portal-element-root') || document.body;
      domEl.appendChild(this.backdropManager.backdrop);

      this.el = this.backdropManager.createChild(this.args);

      this.elDest = document.createElement('div');
      this.el.appendChild(this.elDest);
      this.el.classList.add(styles['portal-element']);

      this.elDest.addEventListener('click', this.handleBackdropClick);
      this.elDest.classList.add(styles['portal-element__inside']);
      if (this.animationStyle) {
        this.elDest.classList.add(styles[this.animationStyle]);
      }
      this.elDest.classList.add(styles['portal-element__inside--opening']);
    }

    return this.elDest;
  }

  private handleBackdropClick = (evt: MouseEvent) => {
    if (typeof this.backdropClickHandler !== 'function') {
      return;
    }

    if (evt.target !== this.elDest) {
      return;
    }

    this.backdropClickHandler(evt);
  };

  setClickHandler(handler: (evt: MouseEvent) => void): void {
    this.backdropClickHandler = handler;
  }

  setRequestExternalCloseHandler(handler: () => void): void {
    this.requestExternalCloseHandler = handler;
  }

  remove = (): void => {
    // Running this code in unit tests breaks a lot of stuff to do with test ids
    // because it duplicates parts of the dom causing testing-library to throw
    // duplicate test id found exceptions
    // this also checks if reduced motion is enabled so that we skip all this
    // animation handling setup (since no animations will play out)
    if (process.env.NODE_ENV !== 'test' && this.animationStyle && !AccessibilityInfo.isReduceMotionEnabled()) {
      const elCopy = this.elDest.cloneNode(true) as HTMLElement;
      // if we have an animation then we want to let it play out before closing the modal
      elCopy.addEventListener('animationend', this.handleRemoveAnimationEnd);
      elCopy.classList.add(styles['portal-element__inside--closing']);
      this.el.replaceChild(elCopy, this.elDest);
    } else {
      // if there's no animation to play out before closing
      // then we just skip listening to any events
      this.handleRemoveAnimationEnd();
    }
  };

  handleRemoveAnimationEnd = (): void => {
    modalRegistry.unregisterModal(this.uuid);
    this.backdropManager.backdrop.remove();
  };

  requestExternalClose(): void {
    this.requestExternalCloseHandler?.();
  }

  setTestId(name: string) {
    this.elDest.dataset.testid = name;
  }
}
