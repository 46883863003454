// @ts-strict-ignore
import React from 'react';
import { Box, IBoxProps } from '@styleseat/ui';
import { ViewStyle } from 'react-native';

export type AbsoluteStyle = {
  zIndex?: number;
  all?: ViewStyle['top'];
} & Pick<ViewStyle, 'top' | 'left' | 'bottom' | 'right'>;

export type AbsoluteProps = {
  children?: React.ReactNode | React.ReactNode[];
} & AbsoluteStyle & Omit<IBoxProps, 'top' | 'left' | 'bottom' | 'right'>;

export default function Absolute({
  children,
  all,
  top = all,
  left = all,
  right = all,
  bottom = all,
  zIndex = null,
  style: restStyle,
  ...restProps
}: AbsoluteProps) {
  const style: AbsoluteStyle & ViewStyle & {
    position: 'absolute' | 'relative';
  } = {
    position: 'absolute',
  };

  if (top !== null) {
    style.top = top;
  }

  if (left !== null) {
    style.left = left;
  }

  if (right !== null) {
    style.right = right;
  }

  if (bottom !== null) {
    style.bottom = bottom;
  }

  if (zIndex !== null) {
    style.zIndex = zIndex;
  }

  return (
    <Box
      {...restProps}
      style={[
        style,
        restStyle,
      ]}
    >
      {children}
    </Box>
  );
}
