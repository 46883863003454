import {
  useSelector,
} from 'react-redux';
import type {
  RootState,
} from '../store/models';

export default function useIsAssigned(
  testName: string,
): boolean {
  return useSelector((state: RootState) => (
    state.abTest.assignments[testName]?.isAssigned
  ));
}
