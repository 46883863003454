import moment from 'moment';
import { PublicProvider } from '../../api/Providers';
import {
  KLARNA_MAX_COST,
  KLARNA_MAX_DAYS,
  KLARNA_MINIMUM_SERVICE_COST,
} from './constants';
import { getIsApp } from '../AppInfo';

/**
 * Determine whether the appointment is eligible for paying through Klarna.
 *
 * @param provider The provider of this appointment.
 * @param startDate Appointment start date.
 * @param cost Appointment cost (dollars).
 * @param bookingFee Appointment booking fee (cents).
 * @returns {Boolean}
 */
export function isEligible(
  provider: Pick<PublicProvider, 'klarna_enabled'>,
  startDate: moment.MomentInput,
  cost: number,
  bookingFee: number,
): boolean {
  const isWebBrowser = !getIsApp();
  const momentStartDate = moment(startDate).clone().startOf('day');
  const daysOut = moment.duration(momentStartDate.diff(moment().startOf('day'))).asDays();

  return (
    provider.klarna_enabled
    && cost + bookingFee <= KLARNA_MAX_COST
    && cost >= KLARNA_MINIMUM_SERVICE_COST
    && daysOut <= KLARNA_MAX_DAYS
    && isWebBrowser
  );
}
