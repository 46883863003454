// @ts-strict-ignore
import { createModel } from '@rematch/core';
import Router, { IRouteDestination, IRouteState } from '../../../packages/router';
import type { RootModel } from './models';

export type State = IRouteState & {
  loading: boolean;
  previous?: IRouteState;
};

export default createModel<RootModel>()({
  state: {
    loading: true,
    params: {},
    name: '',
    previous: undefined,
  } as State,
  reducers: {
    setRoute(state: State, route: IRouteState) {
      return {
        ...route,
        loading: false,
      };
    },
  },

  effects: () => ({
    /**
     * Go effect which proxies out to the angular ui-router go function to change the
     * page route. It also updates the current route info with the passed in info.
     *
     * @param payload - an object with:
     *  - route - (String) name of the route to switch to (must be defined in routes.js)
     *  - params - (Map<String, String>) parameters of the route.
     *  - notify - (Boolean) if this is true forces a re-render of the page.
     *  - reload - (Boolean) if true does not reload the existing route.
     *  - replace - (Boolean) if true does not add a new entry to the browser history.
     */
    go(payload: IRouteDestination): Promise<any> {
      const {
        route,
        params: rawParams,
        notify,
        reload,
        replace,
      } = payload;

      const options: Record<string, any> = {};

      if (notify !== undefined) {
        options.notify = notify;
      }

      if (replace !== undefined) {
        options.replace = replace;
      }

      if (reload !== undefined) {
        options.reload = reload;
      }

      const params = rawParams || {};

      return Router.go(route, params, options);
    },

    /**
     * goBack effect
     * Sends the user to the previous route - if none is present
     * go to whatever the "defaultState" is set to.
     *
     * @param payload
     *  - defaulState - (String) ui-router state the user is sent to if there's none to go back to
     *  - params (Object) - url query params for the state they're going back to
     *  - options (Object) - ui-router .go options
     */
    goBack(payload: {
      defaultState?: any;
      params?: Record<string, any>;
      options?: Record<string, any>;
    } | undefined) {
      const {
        defaultState,
        params,
        options,
      } = payload || {};

      return Router.goBack(defaultState, params, options);
    },

    /**
     * AddParams updates the query params map without changing the route/page
     * you are currently on.
     *
     *
     * @param payload - an object with:
     *  - params - (Map<String, String>) parameters of the route.
     *  - notify - (Boolean) if this is true forces a re-render of the page.
     *  - replace - (Boolean) if true does not add a new entry to the browser history.
     *  - reload - (Boolean) if true does not reload the existing route.
     *  - clearOld - (Boolean) if true, only the new parameters will be set on the url,
     *                    previous params will be reset.
     * @param rootState
     */
    addParams(payload: {
      params: Record<string, any>;
      notify?: boolean;
      replace?: boolean;
      reload?: boolean;
      clearOld?: boolean;
    }) {
      const {
        params,
        notify,
        replace,
        reload,
        clearOld,
      } = payload;

      const options: Record<string, any> = {};

      if (notify !== undefined) {
        options.notify = notify;
      }

      if (replace !== undefined) {
        options.replace = replace;
      }

      if (reload !== undefined) {
        options.reload = reload;
      }
      if (clearOld) {
        options.clearParams = clearOld;
      }

      return Router.go('.', params, options);
    },

    /**
     * AddParams updates the query params map without changing the route/page
     * you are currently on.
     *
     *
     * @param payload - an object with:
     *  - params - (Map<String, String>) parameters of the route.
     *  - notify - (Boolean) if this is true forces a re-render of the page.
     *  - replace - (Boolean) if true does not add a new entry to the browser history.
     *  - reload - (Boolean) if true does not reload the existing route.
     *  - clearOld - (Boolean) if true, only the new parameters will be set on the url,
     *                    previous params will be reset.
     * @param rootState
     */
    removeParams(payload: {
      params: string[];
      notify?: boolean;
      replace?: boolean;
      reload?: boolean;
    }) {
      const {
        params,
        notify,
        replace,
        reload,
      } = payload;

      const options: Record<string, any> = {};

      if (notify !== undefined) {
        options.notify = notify;
      }

      if (replace !== undefined) {
        options.replace = replace;
      }

      if (reload !== undefined) {
        options.reload = reload;
      }

      const newParams = params.reduce((result, paramKey) => ({
        ...result,
        [paramKey]: undefined,
      }), {});

      return Router.go('.', newParams, options);
    },
  }),

  selectors: (slice, createSelector) => ({
    getCurrent() {
      return createSelector(
        slice,
        state => state,
      );
    },
    getCurrentRouteName() {
      return createSelector(
        slice,
        state => state?.name || '',
      );
    },
  }),
});

/**
 * Returns the href that corresponds to an angular route and parameters
 * @param route The route we want the href for
 * @param params Parameters that will help define the href according to the route specification.
 */
export function stateHref(route: string, params: Record<string, any>) {
  return Router.href(route, params) || '';
}

export const goBack = (
  defaultState: string,
  defaultStateParams: Record<string, any>,
  defaultOptions: Record<string, any>,
) => Router.goBack(defaultState, defaultStateParams, defaultOptions);

export const selectors = {
  /**
   * Get the providerId from the current URL's stateParams.
   * Note that this is not necessarily the same as the currently-logged-in
   * user's providerId; StyleSeat staff might be "viewing as" a pro,
   * or this might be useful on views that Client users access.
   */
  getProviderId: (state): (string | undefined) => state?.route?.params?.providerId,
};
