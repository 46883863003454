import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const ClientRequestPaymentCheckoutComplete: SegmentEventData = {
  eventName: AnalyticEvent.CheckoutComplete,
  eventNameLegacy: 'client_request_payment_checkout_complete',
  eventProperties: [
    'appointment_id',
    'provider_id',
    'client_id',
    'is_premium_appt',
    'premium_upcharge_rate',
    'tip_amount',
  ],
};

export default ClientRequestPaymentCheckoutComplete;
