// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from '../../../store/models';

import ssFetch from '../../../modules/ssFetch';

import { PASSWORD_RESET_ENDPOINT, PASSWORD_RESET_VALIDATE_TOKEN_ENDPOINT } from './PasswordResetConfirmPage.constants';

type PasswordResetConfirmPageState = {
  isValidatingToken: boolean;
  isTokenValid: boolean;
  isResettingPassword: boolean;
  resetWasSuccessful: boolean;
};

const model = createModel<RootModel>()({
  name: 'passwordResetConfirmPage',

  state: {
    isValidatingToken: undefined,
    isTokenValid: undefined,
    isResettingPassword: undefined,
    resetWasSuccessful: undefined,
  } as PasswordResetConfirmPageState,

  effects: dispatch => ({
    /**
     * Confirms the validity of the password reset token
     */
    validateResetPasswordToken: async (
      { token, userId }:
      { token: string; userId: number | string },
    ) => {
      dispatch.passwordResetConfirmPage.onValidatingResetPasswordToken();

      const response = await ssFetch(
        PASSWORD_RESET_VALIDATE_TOKEN_ENDPOINT,
        {
          method: 'POST',
          body: JSON.stringify({
            token,
            uid: userId,
          }),
        },
      );

      if (response.ok) {
        const data = await response.json();

        // Note: the above endpoint will return `success: "Password reset token is valid."`,
        // so we can look for the existence of something in the `success` key when the
        // token is valid
        const isTokenValid = !!data?.success;
        dispatch.passwordResetConfirmPage.onValidatingResetPasswordTokenComplete(isTokenValid);
      } else {
        dispatch.passwordResetConfirmPage.onValidatingResetPasswordTokenComplete(false);
      }
    },

    /**
     * Resets the user's password via the token-based reset flow
     */
    resetPassword: async ({
      password,
      passwordConfirm,
      userId,
      token,
    }: {
      password: string;
      passwordConfirm: string;
      userId: number | string;
      token: string;
    }) => {
      dispatch.passwordResetConfirmPage.onResettingPassword();

      const response = await ssFetch(PASSWORD_RESET_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          new_password1: password,
          new_password2: passwordConfirm,
          token,
          uid: userId,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        // Note: the above endpoint will return
        // `success: "Password has been reset with the new password."`,
        // so we can look for the existence of something in the `success` key when the
        // reset is successful
        const resetWasSuccessful = !!data?.success;
        dispatch.passwordResetConfirmPage.onResettingPasswordComplete(resetWasSuccessful);
      } else {
        dispatch.passwordResetConfirmPage.onResettingPasswordComplete(false);
      }
    },
  }),

  reducers: {
    onValidatingResetPasswordToken: (state: PasswordResetConfirmPageState) => ({
      ...state,
      isValidatingToken: true,
    }),
    onValidatingResetPasswordTokenComplete: (
      state: PasswordResetConfirmPageState,
      isTokenValid: boolean,
    ) => ({
      ...state,
      isValidatingToken: false,
      isTokenValid,
    }),
    onResettingPassword: (state: PasswordResetConfirmPageState) => ({
      ...state,
      isResettingPassword: true,
    }),
    onResettingPasswordComplete: (
      state: PasswordResetConfirmPageState,
      resetWasSuccessful: boolean,
    ) => ({
      ...state,
      isResettingPassword: false,
      resetWasSuccessful,
    }),
  },

  selectors: (slice, createSelector) => ({
    isResettingPassword() {
      return createSelector(
        slice,
        (state: PasswordResetConfirmPageState): boolean => state.isResettingPassword,
      );
    },
    tokenIsValidAndReady() {
      return createSelector(
        slice,
        (state: PasswordResetConfirmPageState): boolean => (
          state.isTokenValid && !state.isValidatingToken
        ),
      );
    },
    tokenValidationFailed() {
      return createSelector(
        slice,
        (state: PasswordResetConfirmPageState): boolean => (
          state.isTokenValid === false
          && state.isValidatingToken === false),
      );
    },
    resetPasswordSucceeded() {
      return createSelector(
        slice,
        (state: PasswordResetConfirmPageState): boolean => (
          state.resetWasSuccessful === true
          && state.isResettingPassword === false),
      );
    },
    resetPasswordFailed() {
      return createSelector(
        slice,
        (state: PasswordResetConfirmPageState): boolean => (
          state.resetWasSuccessful === false
          && state.isResettingPassword === false),
      );
    },
  }),
});

export default model;
