import React from 'react';
import { colors } from '@styleseat/brand';

type NotificationsAlertCircleIconProps = {
  width?: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
} & React.SVGProps<SVGSVGElement>;

export const NotificationsAlertCircleIcon = ({
  width = 24,
  height = 24,
  color = colors.blackInBlack,
  backgroundColor = colors.whiteWash,
  borderColor = undefined,
}: NotificationsAlertCircleIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    id="notifications-alert-circle"
  >
    <g transform="matrix(1,0,0,1,-1,-1)">
      <path
        fill={backgroundColor}
        d="M1,12C1,18.075 5.925,23 12,23C18.075,23 23,18.075 23,12C23,5.925 18.075,1 12,1C5.925,1 1,5.925 1,12Z"
      />
      <path
        fill={borderColor || color}
        d="M1,12C1,18.075 5.925,23 12,23C18.075,23 23,18.075 23,12C23,5.925 18.075,1 12,1C5.925,1 1,5.925 1,12ZM21,12C21,16.971 16.971,21 12,21C7.029,21 3,16.971 3,12C3,7.029 7.029,3 12,3C16.971,3 21,7.029 21,12Z"
      />
      <path
        fill={color}
        d="M12,17.998C12.553,17.998 13.001,17.551 13.001,16.998C13.001,16.446 12.553,15.998 12,15.998C11.448,15.998 11,16.446 11,16.998C11,17.551 11.448,17.998 12,17.998ZM13.004,5.998L11.003,5.998L11.003,13.998L13.004,13.998L13.004,5.998Z"
      />
    </g>
  </svg>
);
