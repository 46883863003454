// @ts-strict-ignore
import React from 'react';

/**
 * A hook for setting state from an async function, protected against both race
 * conditions (promises returning out of order) and memory leaks (unmounting
 * of listening component during asynchronously retrieval).
 * It has the same signature as `useEffect`:
 *  - a function which runs an effect - in this case getting the async data
 *  - a dependency array that governs when the effect function is run
 * Unlike `useEffect`, the hook returns a value - which is the data we want.
 *
 * Example:
 * ```
 * const reviews = useAsyncState(React, getReviews(page), [page]);
 * ```
 *
 * @param {function} fn - The function to be called to get the state, which returns the state.
 * @param {array} deps - A dependency array which governs when the async getter is run.
 * @param {any} initialState - The initial value, returned before function returns the first time.
 * @return {any} - The asynchronously retrieved data.
 */
const useAsyncState = <T>(
  fn: () => Promise<T>,
  deps: Array<any>,
  initialState: T | undefined = undefined,
): T => {
  const [state, setState] = React.useState(initialState);
  let isSubscribed = true;
  React.useEffect(
    () => {
      (async () => {
        const value = await fn();
        // Only update state if the component is still subscribed
        // The setter intentionally uses anonymous function to allow for
        // functions as state value.
        if (isSubscribed) setState(() => value);
      })();
      return () => {
        isSubscribed = false;
      };
    },
    deps,
  );
  return state;
};

/**
 * A hook for setting state from an async function, protected against both race
 * conditions (promises returning out of order) and memory leaks (unmounting
 * of listening component during asynchronously retrieval). It differs from the
 * above `useAsyncState` in that it return loading and error state as well.
 * It has the same signature as `useEffect`:
 *  - a function which runs an effect - in this case getting the async data
 *  - a dependency array that governs when the effect function is run
 * Unlike `useEffect`, the hook returns a value - which is the data we want.
 *
 * Example:
 * ```
 * const { loading, data, error } = useFullAsyncState(getReviews(page), [page]);
 * ```
 *
 * @param {function} fn - The function to be called to get the state, which returns the state.
 * @param {array} deps - A dependency array which governs when the async getter is run.
 * @return {loading, data, error} - An object of loading, data, error state.
 */
export const useFullAsyncState = <T>(fn: () => Promise<T>, deps: Array<any>): {
  data: T;
  error: any;
  loading: boolean;
} => {
  const [data, setData] = React.useState<T | undefined>();
  const [error, setError] = React.useState<unknown>();
  const [loading, setLoading] = React.useState<boolean>(true);
  let isSubscribed = true;
  React.useEffect(
    () => {
      (async () => {
        let newData: T;
        let newError;
        try {
          newData = await fn();
        } catch (e) {
          newError = e;
        }
        // Only update state if the component is still subscribed
        // The setter intentionally uses anonymous function to allow for
        // functions as state value.
        if (isSubscribed) {
          if (newError) {
            setError(newError);
            setData(undefined);
          } else {
            setData(() => newData);
            setError(undefined);
          }
          setLoading(false);
        }
      })();
      return () => {
        isSubscribed = false;
      };
    },
    deps,
  );
  return {
    data, error, loading,
  };
};

export default useAsyncState;
