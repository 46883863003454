// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { StoreSelectors } from '@rematch/select';
import produce from 'immer';
import type { RootModel, RootState } from './models';

type State = {
  homeBubbleTooltipProps?: {
    text: string;
    key: string;
    testID: string;
  };
  showHomeUnreadBadge: boolean;
  showTooltips: boolean;
};

export default createModel<RootModel>()({
  state: {
    homeBubbleTooltipProps: undefined,
    showHomeUnreadBadge: false,
    showTooltips: true,
  } as State,

  reducers: {
    showHomeUnreadBadge: produce((state: State) => {
      state.showHomeUnreadBadge = true;
    }),
    showHomeBubbleTooltip: produce<State, [State['homeBubbleTooltipProps']]>((
      state: State,
      props: State['homeBubbleTooltipProps'],
    ) => {
      state.homeBubbleTooltipProps = props;
    }),
    onHomeBubbleTooltipClosed: produce((state: State) => {
      state.homeBubbleTooltipProps = undefined;
    }),
    onNavigatedToHome: produce((state: State) => {
      state.homeBubbleTooltipProps = undefined;
      state.showHomeUnreadBadge = false;
    }),
    onHideToolTips: produce((state: State) => {
      state.showTooltips = false;
    }),
    onShowToolTips: produce((state: State) => {
      state.showTooltips = true;
    }),
  },

  effects: dispatch => ({
    closeHomeBubbleTooltip: async (_?: undefined, rootState?: RootState) => {
      await dispatch.clientNavBar.onHomeBubbleTooltipClosed();
      await dispatch.favorites.onHomeBubbleTooltipClosed({
        previousProps: rootState.clientNavBar.homeBubbleTooltipProps,
      });
    },
  }),

  selectors: (_, createSelector) => ({
    shouldShow: (models: StoreSelectors<RootModel, Record<string, never>>) => createSelector(
      models.user.isLoggedIn,
      (isLoggedIn: boolean) => isLoggedIn,
    ),
  }),
});
