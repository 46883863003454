/**
 * This constant is used in both newClientDelivery.js and getPriorNCDAttribution.ts
 * see the comments in getPriorNCDAttribution.ts about why that was split out.
 */
export const NCD_ATTRIBUTION_KEY_PREFIX = 'NCDRecommendedPro';

/*
This constant is used in getPriorNCDAttribution.ts
Number of hours since a Profile visit that came through an
NCD eligible channel, i.e., search (others)
Currently, set at (7 days x 24 hours x 2 weeks) 336 hours
*/
export const NCD_ATTRIBUTION_WINDOW_HOURS = 336;
export const NCD_POLICY_NOTIFICATION_ACTION = 'pro-notification-ncd-policy-clicked';
