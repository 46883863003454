import React from 'react';
import { BrandColor } from '@styleseat/brand';
import { useLinkTo } from '../../../hooks';
import SupportServiceWrapper from '../../../modules/SupportServiceWrapper';
import {
  Stack,
  HeaderText,
  BodyText,
  Button,
} from '../../ui';
import { LANDING_ROUTE } from '../../../route-names';

const PageLoadError = () => {
  const linkTo = useLinkTo();
  const handleContactSupport = () => {
    SupportServiceWrapper.open();
  };

  return (
    <Stack
      padding={24}
      space={16}
      color={BrandColor.SuperLight}
      flexGrow={1}
      justifyContent="center"
      alignItems="stretch"
    >
      <HeaderText ariaLevel={1} level={3} textAlign="center">
        We&apos;re having trouble loading this page right now
      </HeaderText>
      <BodyText color={BrandColor.Metal} textAlign="center">
        Double check your connection, refresh the page, and if this keeps up, contact support.
      </BodyText>
      <Stack alignItems="center" paddingTop={16} paddingBottom={40}>
        <Button
          appearance="primary-neue"
          size="medium"
          style={{
            width: '100%',
            maxWidth: 350,
            marginBottom: 16,
          }}
          onClick={() => window?.location?.reload?.()}
          testID="page-load-error__refresh"
        >
          Refresh
        </Button>
        <Button
          appearance="outline-thin"
          size="medium"
          style={{
            width: '100%',
            maxWidth: 350,
            marginBottom: 16,
          }}
          onClick={() => linkTo(LANDING_ROUTE)}
          testID="page-load-error__home"
        >
          Home
        </Button>
        <Button
          onClick={handleContactSupport}
          appearance="link-lowercase"
          size="medium"
          testID="page-load-error__support"
        >
          Contact Support
        </Button>
      </Stack>
    </Stack>
  );
};

export default PageLoadError;
