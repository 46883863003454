// @ts-strict-ignore
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import URI from 'urijs';

import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import { ssFetchJSON } from '../modules/ssFetch';
import {
  loadReview,
  IReview,
  addReviewImageSources,
} from '../modules/provider/reviews';

/**
 * State tracked by this model for a specific provider
 */
export type ProviderReviewState = {
  /** The list of reviews for the provider */
  reviews: Array<IReview>;
  /** The total (ignoring pagination) of reviews for the provider. Set to -1 if it has not yet been
   * retrieved */
  total: number;
  /** The most recent page queried for the provider. This a 1-based index. */
  latestPage: number;
  /** True if an additional page of reviews is available for the provider, otherwise false */
  hasNextPage: boolean;
  /** The pinned review for the provider, if any */
  pinnedReview?: IReview;
};

/**
 * The state stored by this model
 */
type ReviewState = {
  /** Mapping from provider ID to review state for that provider */
  providerMap: { [key: number]: ProviderReviewState };
  /** True if review data is loading, otherwise false */
  loading: boolean;
};

/**
 * Creates a default state
 */
const createDefaultState = (): ReviewState => ({
  providerMap: {},
  loading: false,
});

/**
 * Ensures that there is an object in the state for storing provider-specific data.
 * @param state The current state
 * @param providerId The provider ID
 */
const ensureProvider = (state: ReviewState, providerId: number): ReviewState => {
  state.providerMap[providerId] = state.providerMap[providerId] || {
    reviews: [],
    total: -1,
    latestPage: 0,
    hasNextPage: true,
  };
  return state;
};

/** Standardized payload for various setters in this model */
type ProviderPayload<T> = {
  providerId: number;
  value: T;
};

/** Create a payload for setters given a payload containing providerId and the value to provide. */
const createPayload = <T>(
  originalPayload: { providerId: number },
  value: T,
): ProviderPayload<T> => ({
    providerId: originalPayload.providerId,
    value,
  });

const model = createModel<RootModel>()({
  name: 'providerReviews',

  state: createDefaultState() as ReviewState,

  reducers: {
    /** Sets the reviews for the given provider */
    setReviews: produce<ReviewState, [ProviderPayload<Array<IReview>>]>((
      state: ReviewState,
      payload: ProviderPayload<Array<IReview>>,
    ) => {
      ensureProvider(state, payload.providerId);
      state.providerMap[payload.providerId].reviews = payload.value;
    }),
    /** Sets the loading status common to all providers */
    setLoading: produce<ReviewState, [boolean]>((
      state: ReviewState,
      payload: boolean,
    ) => {
      state.loading = payload;
    }),
    /** Sets the total reviews for the given provider */
    setTotal: produce<ReviewState, [ProviderPayload<number>]>((
      state: ReviewState,
      payload: ProviderPayload<number>,
    ) => {
      ensureProvider(state, payload.providerId);
      state.providerMap[payload.providerId].total = payload.value;
    }),
    /** Sets the latest loaded page for the given provider */
    setLatestPage: produce<ReviewState, [ProviderPayload<number>]>((
      state: ReviewState,
      payload: ProviderPayload<number>,
    ) => {
      ensureProvider(state, payload.providerId);
      state.providerMap[payload.providerId].latestPage = payload.value;
    }),
    /** Sets the next page flag for the given provider */
    setHasNextPage: produce<ReviewState, [ProviderPayload<boolean>]>((
      state: ReviewState,
      payload: ProviderPayload<boolean>,
    ) => {
      ensureProvider(state, payload.providerId);
      state.providerMap[payload.providerId].hasNextPage = payload.value;
    }),
    /** Replaces the review record with matching ID with the given review record. Useful for
   * in-place updates. */
    replaceReview: produce<ReviewState, [ProviderPayload<IReview>]>((
      state: ReviewState,
      payload: ProviderPayload<IReview>,
    ) => {
      ensureProvider(state, payload.providerId);
      const { reviews } = state.providerMap[payload.providerId];
      const idx = reviews.findIndex(item => item.id === payload.value.id);

      if (idx !== -1) {
        reviews[idx] = payload.value;
      }
    }),
    /** Sets the pinned review for the given provider */
    setPinnedReview: produce<ReviewState, [ProviderPayload<IReview>]>((
      state: ReviewState,
      payload: ProviderPayload<IReview>,
    ) => {
      ensureProvider(state, payload.providerId);
      state.providerMap[payload.providerId].pinnedReview = payload.value;
    }),
  },

  effects: dispatch => ({
    /** Execute an API call to mark the given review as "pinned". This also updates the review
     * record internally and sets the pinned review for the provider as expected. Yields the updated
     * review record. */
    pinReview: async (payload: { review: IReview; providerId: number }) => {
      const uri = URI(`/api/v2/providers/${payload.providerId}/ratings/${payload.review.id}/pin`);
      uri.addQuery({ pinned: true });

      const updatedReview = addReviewImageSources(await ssFetchJSON(uri.toString(), {
        method: 'PATCH',
      }));

      dispatch.providerReviews.replaceReview(createPayload(payload, updatedReview));
      dispatch.providerReviews.setPinnedReview(createPayload(payload, updatedReview));

      return updatedReview;
    },
    /**
     * Execute an API call to mark the given review as "unpinned". Also updates the review record
     * internally and unsets the pinned review for the provider. Yields the updated review record.
     */
    unpinReview: async (payload: { review: IReview; providerId: number }) => {
      const uri = URI(`/api/v2/providers/${payload.providerId}/ratings/${payload.review.id}/pin`);
      uri.addQuery({ pinned: false });

      const updatedReview = addReviewImageSources(await ssFetchJSON(uri.toString(), {
        method: 'PATCH',
      }));

      dispatch.providerReviews.replaceReview(createPayload(payload, updatedReview));
      dispatch.providerReviews.setPinnedReview(createPayload<IReview>(payload, null));

      return updatedReview;
    },
    /**
     * Reload data for a specific review from the API. Yields the updated review record.
     */
    reloadReview: async (payload: { review: IReview; providerId: number }) => {
      const updatedReview: IReview = await loadReview(payload.review.id, payload.providerId);

      dispatch.providerReviews.replaceReview(createPayload(payload, updatedReview));
      return updatedReview;
    },
    /**
     * Loads the next page of reviews for the given provider. The `showRatingsAndReviews` determines
     * whether or not to include reviews with just a star and no text.
     */
    loadNextPage: async (
      payload: { providerId: number; showRatingsAndReviews?: boolean; search_term?: string },
      rootState: any,
    ) => {
      const {
        providerId,
        showRatingsAndReviews,
        search_term,
      } = payload;

      const hasSeachTerm = () => search_term && search_term?.length > 0;

      const {
        latestPage = 0,
        reviews = [],
      } = rootState.providerReviews.providerMap[providerId] || {};

      const nextReviewPage = latestPage + 1;

      const queryParams = {
        page: nextReviewPage,
        exclude_star_only: !showRatingsAndReviews,
      };

      if (hasSeachTerm()) {
        (<any>queryParams).search_term = search_term;
      }

      const uri = URI(`/api/v2/providers/${providerId}/ratings`);
      uri.addQuery(queryParams);

      const response = await ssFetchJSON(uri.toString());
      const reviewIds = {};
      let transformedReviews: Array<IReview> = reviews;

      reviews.forEach(review => {
        reviewIds[String(review.id)] = true;
      });

      const dedupedReviews = response.results.filter(review => {
        const { id } = review;
        if (review.pinned) {
          dispatch.providerReviews.setPinnedReview(createPayload(payload, review));
        }
        if (!reviewIds[String(id)]) {
          reviewIds[String(id)] = true;
          return true;
        }

        return false;
      }).map(addReviewImageSources);

      transformedReviews = transformedReviews.concat(dedupedReviews);

      dispatch.providerReviews.setReviews(
        createPayload(payload, hasSeachTerm() ? response.results : transformedReviews),
      );
      dispatch.providerReviews.setTotal(createPayload(payload, response.count));
      dispatch.providerReviews.setHasNextPage(createPayload(payload, response.next !== null));
      dispatch.providerReviews.setLatestPage(createPayload(payload, nextReviewPage));
    },
    /**
     * Loads the first page of reviews for the given provider. Identical to `loadNextPage` except
     * that it resets hasNextPage, latestPage, and total for the given provider.
     */
    loadFirstPage: async (
      payload: { providerId: number; showRatingsAndReviews?: boolean; search_term?: string },
    ) => {
      dispatch.providerReviews.setHasNextPage(createPayload(payload, true));
      dispatch.providerReviews.setLatestPage(createPayload(payload, 0));
      dispatch.providerReviews.setTotal(createPayload(payload, -1));
      await dispatch.providerReviews.loadNextPage(payload);
    },
    /**
     * Loads the pinned review for the provider
     */
    loadPinnedReview: async (payload: { providerId: number }) => {
      const { providerId } = payload;
      const uri = URI(`/api/v2/providers/${providerId}/ratings?pinned=true`);

      const pinnedReviewResult: { results: Array<IReview> } = await ssFetchJSON(uri.toString());
      let { results: pinnedResults } = pinnedReviewResult;
      const { length: pinnedLength } = pinnedReviewResult.results;
      let pinnedReview: IReview = null;

      if (pinnedLength) {
        if (pinnedLength > 1) {
          pinnedResults = pinnedResults.filter(result => result.autopin_source === 'provider');
        }

        pinnedReview = addReviewImageSources(pinnedResults[0]);
      }

      dispatch.providerReviews.setPinnedReview(createPayload({ providerId }, pinnedReview));
      return pinnedReview;
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    getAll() {
      return createSelector(
        slice,
        ((reviews: ReviewState) => reviews?.providerMap),
      );
    },

    forPro: hasProps((_, props: { providerId: number }) => (
      createSelector(
        slice(reviews => reviews?.providerMap),
        (reviews: ReviewState['providerMap']) => reviews?.[props.providerId],
      )
    )),
  }),
});
export default model;
