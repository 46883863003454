// @ts-strict-ignore
import moment, { MomentInput } from 'moment';
import FeatureFlags from '../../../FeatureFlags';
import { DateTimeStamp } from '../../../../types/dateTime';
import { PRO_SEARCH_BOOST_DAYS_BOOSTED, PRO_SEARCH_BOOST_FLAG } from '../../SearchBoost';

export interface SearchBoostNotification {
  type: 'search_boost';
  uuid: 'search_boost';
  creation_time: MomentInput;
  daysLeft: number;
  persistent: true;
  providerId: number;
}

const calculateDaysLeft = profilePhaseOneCompleteDate => {
  const days = moment.duration(moment.utc().diff(moment.utc(profilePhaseOneCompleteDate))).asDays();

  return Math.ceil(PRO_SEARCH_BOOST_DAYS_BOOSTED - days);
};

/**
 * Checks to see if the search boost notification should be shown in notification dropdown
 * @param {string} profileSearchBoostEligibleDate - Date the pro earned search boost eligibility.
 * @param {string} profileSearchBoostEarningStartDate - Start date to earn search boost.
 */
export const shouldShowSearchBoostNotification = async (
  profileSearchBoostEligibleDate: DateTimeStamp,
  profileSearchBoostEarningStartDate: DateTimeStamp,
) => {
  const searchBoostFlag = await FeatureFlags.isEnabled(PRO_SEARCH_BOOST_FLAG);

  const eligibilityDate = searchBoostFlag
    ? profileSearchBoostEligibleDate
    : profileSearchBoostEarningStartDate;

  if (eligibilityDate) {
    const daysLeft = calculateDaysLeft(eligibilityDate);

    if (daysLeft > -1) {
      return true;
    }

    return false;
  }

  return false;
};

/**
 * creates the search boost notification notification
 * @param {string} profileSearchBoostEligibleDate - Date the pro earned search boost eligibility.
 * @param {string} profileSearchBoostEarningStartDate - Start date to earn search boost.
 * @param {number} providerId
 */
export const createSearchBoostNotification = (
  profileSearchBoostEligibleDate: DateTimeStamp,
  profileSearchBoostEarningStartDate: DateTimeStamp,
  providerId: number,
  isSearchBoostEnabled: boolean,
): SearchBoostNotification => {
  const eligibilityDate = isSearchBoostEnabled
    ? profileSearchBoostEligibleDate
    : profileSearchBoostEarningStartDate;

  const daysLeft = calculateDaysLeft(eligibilityDate);

  return {
    type: 'search_boost',
    uuid: 'search_boost',
    daysLeft,
    persistent: true,
    providerId,
    creation_time: new Date(),
  };
};
