// @ts-strict-ignore
import { createModel } from '@rematch/core';
import {
  AppointmentListParams,
  loadUserAppointmentList,
  UserAppointment,
  UserAppointmentListResults,
} from '../../api/Users/Appointments';
import { getProsForAppointments } from '../../modules/consumer/appointments';
import { UserAppointmentWithProviderWithLocation } from '../../modules/UserAppointmentState';
import nonCriticalException from '../../modules/exceptionLogger';
import type { RootModel } from '../models';

export interface IAppointmentRatings {
  [id: number]: number;
}

type State = {
  appointments: { [id: number]: UserAppointment };
  loading: boolean;
  error: Error;
  errorMessage: string;
};

export type UserAppointmentsState = State;

const model = createModel<RootModel>()({
  name: 'userAppointments',
  state: {
    appointments: {},
    loading: false,
    error: null,
  },

  reducers: {
    onStartLoadingAppts: (state: State) => ({ ...state, loadingAppts: true }),
    onFinishLoadingAppts: (
      state: State,
      payload: UserAppointmentWithProviderWithLocation[],
    ) => ({
      ...state,
      appointments: {
        ...state.appointments,
        ...payload.reduce((result, appt) => ({ ...result, [appt.id]: appt }), {}),
      },
      loadingAppts: false,
    }),
    onError: (state: State, payload: Error) => ({
      ...state,
      error: payload,
      errorMessage: payload.message,
    }),
  },

  effects: dispatch => ({
    loadAppointmentsWithProviders: async (
      payload: { userId: number; params: AppointmentListParams },
    ): Promise<UserAppointmentListResults> => {
      dispatch.userAppointments.onStartLoadingAppts();
      try {
        const results = await loadUserAppointmentList(payload.userId, {
          ...payload.params,
          useCache: false,
        });
        const newAppts = await getProsForAppointments(payload.userId, results.results);
        dispatch.userAppointments.onFinishLoadingAppts(newAppts);
        return results;
      } catch (error) {
        nonCriticalException(error);
        dispatch.userAppointments.onError(error);
        return {
          allCount: 0, count: 0, results: [],
        };
      }
    },
  }),

  selectors: slice => ({
    getUserAppointments: () => slice(state => state.appointments),
  }),
});

export default model;
