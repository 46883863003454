import FeatureFlags from '../modules/FeatureFlags';
import { LOGROCKET_KEY } from '../config';
import { init as initLogRocket } from '../modules/LogRocket';

const flagName = 'logrocket_enabled';

export const setupLogRocket = async () => {
  if (LOGROCKET_KEY) {
    const useLogRocket = await FeatureFlags.isEnabled(flagName);

    if (useLogRocket) {
      initLogRocket(LOGROCKET_KEY);
    }
  }
};
