import { API_ROOT } from '../../config';
import { ssFetchJSON } from '../../modules/ssFetch';

export type AppointmentRequestResponse = {
  totals: {
    balance_remaining: string;
    booking_fee: string;
    deposit_due: string | null;
    deposit_paid: string | null;
  };
  metadata: {
    booking_fee_required: boolean;
    deposit_required: boolean;
  };
};

export type ProviderAppointmentRequestResponse = {
  service_cost_before_discount: string;
  service_cost_sum_less_promotions: string;
  pro_adjustments: string;
  is_total_adjusted: boolean;
  premium_upcharge: string;
};

export type AppointmentRequestParams = {
  appointment_date: string;
  booking_fee_type: unknown;
  discount_code?: string;
  rescheduled_from_appointment_id?: number;
  reward_id: number;
  service_list: number[];
  smart_price_source?: string | number;
  smart_priced?: boolean;
  start_time: string;
};

export type ProviderAppointmentRequestParams = {
  appointment_date: string;
  service_list: number[];
  start_time: string;
  cost_override?: number;
  is_premium_appointment?: boolean;
};

// AppointmentRequestSerializer on backend
export const getAppointmentRequest = (
  providerId: number,
  params: AppointmentRequestParams,
) => {
  const url = new URL(`/api/v1/appointment-request/${providerId}`, API_ROOT);

  return ssFetchJSON<AppointmentRequestResponse>(
    url.toString(),
    {
      method: 'POST',
      body: params,
    },
  );
};

// ProviderAppointmentRequestSerializer on backend
export const getProviderAppointmentRequest = (
  providerId: number,
  params: ProviderAppointmentRequestParams,
) => {
  const url = new URL(`/api/v1/provider-appointment-request/${providerId}`, API_ROOT);

  return ssFetchJSON<ProviderAppointmentRequestResponse>(
    url.toString(),
    {
      method: 'POST',
      body: params,
    },
  );
};
