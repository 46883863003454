/**
 * @type {string}
 */

export const APP_VERSION = '123.5.0';

export const APP_STORE_ID = '414313281';
export const PLAY_STORE_ID = 'com.styleseat.promobile';

export const IOS_APP_URL = 'https://itunes.apple.com/us/app/styleseat-hair-nails-massage/id414313281?mt=8';
export const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.styleseat.promobile&hl=en';

export { AUTH_ORIGINS } from './user/constants';
