import { API_ROOT } from '../../config';
import type { IEditBusinessInfo } from '../../modules/Api.types';
import SimpleCache from '../../modules/SimpleCache';
import ssFetch, { ssFetchJSON } from '../../modules/ssFetch';
import { clearApiCache } from './Providers';

export type ProviderInfo = {
  blog_url: string;
  blurb: string;
  email: string;
  facebook_url: string;
  field_group: number;
  instagram_username: string;
  location_address1: string;
  location_address2: string;
  location_city: string;
  location_editable: true;
  location_id: number;
  location_name: string;
  location_phone_number: string;
  location_state: string;
  location_zipcode: string;
  location_instructions: string | null;
  mobile_business: boolean;
  name: string;
  num_colleagues: number;
  phone_number: string;
  preferred_pronouns: string | null;
  sms_subscribe_marketing: number;
  sms_subscribe_notifications: number;
  sub_type: number;
  time_zone: string;
  to_show_number: number;
  twitter: string;
  vanity_url: string;
  website: string;
  yelp_url: string;
};

export type ProviderInfoSaveSuccessResponse = {
  status: string;
};

export type ProviderInfoSaveErrorResponse = {
  instagram_username?: string[];
  facebook_url?: string[];
  twitter?: string[];
  website?: string[];
  yelp_url?: string[];
  name?: string[];
  email?: string[];
  phone_number?: string[];
  location_phone_number?: string[];
  location_name?: string[];
  location_address1?: string[];
  location_address2?: string[];
  location_city?: string[];
  location_state?: string[];
  location_zipcode?: string[];
  vanity_url?: string[];
};

export type ProviderInfoSavePayload = Partial<ProviderInfo> & { specializations?: string };

export const InfoCache = new SimpleCache(5);

export function clearProviderInfoCache() {
  InfoCache.clear();
}

export function getProviderInfo(providerId: number): Promise<ProviderInfo> {
  const url = new URL(`${API_ROOT}/api/v1/provider/${providerId}/info.json`);
  return ssFetchJSON(
    url.toString(),
    { ssCache: InfoCache },
  );
}

export function saveProviderInfo(
  providerId: number,
  data: ProviderInfoSavePayload,
): Promise<ProviderInfoSaveSuccessResponse> {
  const url = new URL(`${API_ROOT}/provider/${providerId}/edit_info`);
  clearProviderInfoCache();
  clearApiCache();
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    form: data,
  });
}

export const patchEditProviderInfo = async (
  providerId: number,
  provider: Partial<IEditBusinessInfo>,
): Promise<void> => {
  const url = `${API_ROOT}/provider/${providerId}/edit_info`;
  await ssFetch(
    url,
    {
      body: provider,
      method: 'PUT',
    },
  );
};
