// @ts-strict-ignore
import ssFetch, { ssFetchJSON } from '../../modules/ssFetch';

export enum PolicyCalculation {
  None = 'None',
  Amount = 'Amount',
  Percent = 'Percent',
}

export enum DepositSettingsClientOptions {
  New = 1,
  All = 2,
}

/**
 * @see ProviderDepositPolicySerializer
 */
export interface DepositPolicy {
  new_client_policy_calculation: PolicyCalculation;
  new_client_policy_value: string; // Decimal
  existing_client_policy_calculation: PolicyCalculation;
  existing_client_policy_value: string; // Decimal
}

/**
 * @see ProviderDepositPolicySettingsSerializer
 */
export interface DepositPolicySettings {
  policy: DepositPolicy | null;
  trusted_clients_count?: number; // Integer
  deposit_enabled_services_count?: number; // Integer
  services_count?: number; // Integer
}

/**
 * @see ClientDepositPolicySerializer
 */
export interface ClientDepositPolicy {
  policy_calculation: PolicyCalculation;
  policy_value: string; // Decimal
}

const DEPOSITS_INELIGIBLE_HTTP_STATUS_CODE = 403;
export class DepositsIneligibleError extends Error {}

/**
 * Fetches the active deposit policy for a provider. If the provider is eligible for deposits
 * but does not have an active policy (deposits disabled) then the result of policy is null.
 *
 * If the provider is not eligible for deposits, this throws an error with a 403 response.
 *
 * @param providerId - id of the provider to fetch the deposit policy for
 * @returns the current deposit policy, or throws if not found or pro is ineligible
 */
export async function fetchDepositPolicySettings(
  providerId: number | string,
): Promise<DepositPolicySettings | null> {
  try {
    const response = await ssFetchJSON<DepositPolicySettings>(
      `/api/v2/providers/${providerId}/deposit_policy_settings`,
      { includeResponseOnError: true },
    );
    return response;
  } catch (e) {
    if (e?.status === DEPOSITS_INELIGIBLE_HTTP_STATUS_CODE) {
      throw new DepositsIneligibleError(e?.message);
    }
    throw e;
  }
}

export async function updateDepositPolicy(
  providerId: number | string,
  policy: DepositPolicy,
): Promise<DepositPolicySettings> {
  return ssFetchJSON<DepositPolicySettings>(
    `/api/v2/providers/${providerId}/deposit_policy_settings`,
    {
      method: 'POST',
      body: { policy },
    },
  );
}

export async function deleteDepositPolicy(providerId: number | string): Promise<void> {
  await ssFetch(`/api/v2/providers/${providerId}/deposit_policy_settings`, { method: 'DELETE' });
}
