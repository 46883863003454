import React from 'react';
import { NotificationProvider } from './provider/notifications/NotificationProvider';
import { LoginModalProvider } from './shared/login/LoginModalProvider';
import { SnackbarProvider } from './shared/SnackbarContext/SnackbarContext';
import { SmartBannerProvider } from './shared/AppsFlyerSmartBanner/SmartBannerContext';

/**
 * This component contains all the top level context providers required by the app
 *
 * Conditional changes to this component's render cause full page renrenders.
 * Create top level contexts sparingly. Instead, consider using Redux.
 */
export const ContextProvider: React.FCWithChildren = ({
  children,
}) => (
  <SmartBannerProvider>
    <SnackbarProvider>
      <LoginModalProvider>
        <NotificationProvider>
          {children}
        </NotificationProvider>
      </LoginModalProvider>
    </SnackbarProvider>
  </SmartBannerProvider>
);
