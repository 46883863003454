import Analytics from '.';

import { CURRENT_GUEST_DATA } from './constants';
import nonCriticalException from '../exceptionLogger';

/**
  * Logs the data sent to the underlying analytics engine.
  * @param {String} trackerName name of the tracker.
  * @param {String} methodName Log a call to a tracker method.
  * @param  {...any} args Arguments sent to the tracker.
  */

const trackNcdEvents = (trackEventName, properties) => {
  let ncdEventName;
  if (trackEventName === 'client_appointment_booked' && properties.is_first_client_pro_connection) {
    ncdEventName = 'client_appointment_newclient_booked';
    Analytics.track(ncdEventName, properties);
    if (properties.is_pro_found_through_SS_search_tool) {
      ncdEventName = 'client_appointment_attributed_newclient_booked';
      Analytics.track(ncdEventName, properties);
      if (properties.is_monetized_ncd) {
        ncdEventName = 'client_appointment_ncd_booked';
        Analytics.track(ncdEventName, properties);
      }
    }
    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.removeItem(CURRENT_GUEST_DATA);
      }
    } catch (error) {
      nonCriticalException(error);
    }
  }
};

export default trackNcdEvents;
