// @ts-strict-ignore
// Note: Disabling as underscore is added as a dev dep
// in the package.json for the analytics package.
//
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'underscore';
import loadSegment from './loadSegment';
import BaseTracker, { PlatformProperty, TrackerProperties } from '../BaseTracker';
import { AnalyticsUserData, UTMState } from '../../types';
import { SegmentContext, SegmentContextTraits } from './SegmentTracker.types';
import SegmentEventMap, { SegmentEventLegacyMap } from './events';
import { BOT_USER_AGENT_STRINGS } from '../../constants';
import { getOnboardingSegmentEventData } from '../../../../app/scripts/modules/provider/onboarding';

export type Segment = {
  track: (eventName: string, properties, options: {
    anonymousId?: string;
    context: SegmentContext;
  }) => void;
  identify: (
    userId: number | string | null,
    traits: SegmentContextTraits,
    options?: {
      anonymousId?: string;
    }) => void;
  user: () => {
    traits: (clearTraits?: {}) => object;
  };
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    analytics: Segment;
  }
}

function isSet(value): boolean {
  if (value !== null && value !== undefined) {
    if (typeof value === 'object') {
      return Object.keys(value).length > 0;
    }

    return !!value;
  }

  return false;
}

function setIfDefined(key, value) {
  if (isSet(value)) {
    return {
      [key]: value,
    };
  }

  return {};
}

function isTriggeredByBot() {
  const useragent = window?.navigator?.userAgent;
  const match = BOT_USER_AGENT_STRINGS.find(element => useragent.includes(element));
  return match !== undefined;
}

export default class SegmentTracker extends BaseTracker {
  private readonly build: number;
  private readonly appName: string;
  private readonly version: string;
  private readonly isBotUser: boolean;
  private user: AnalyticsUserData | null;

  constructor(
    env: string,
    platform: PlatformProperty,
    apiKey: string,
    version: string,
    build: number,
    appName: string = 'StyleSeat',
  ) {
    super('segment', 'Segment', env, platform);
    this.build = build;
    this.version = version;
    this.appName = appName;
    loadSegment(apiKey);
    this.isBotUser = isTriggeredByBot();
    this.isApp = platform === 'app';
    this.user = null;
    this.utmData = {};
  }

  async trackEvent(eventName: string, properties: TrackerProperties, options?: any): Promise<void> {
    // Support both, new and old naming conventions
    // additionally, include new onboarding events
    const SegmentEvent = SegmentEventMap[eventName]
        || SegmentEventLegacyMap[eventName]
        || getOnboardingSegmentEventData(eventName);

    if (!SegmentEvent) return;
    // Don't send segment events for bots
    if (this.isBotUser) return;
    const filteredProperties = _.pick(properties, SegmentEvent.eventProperties);
    let traits = this.getTraitsFromUser(properties?.user_data);
    const utmTraits = this.getTraitsFromUTMData();

    if (options && options.override_traits) {
      traits = {
        ...traits,
        ...options.override_traits,
      };
    }

    const context = {
      // This should always be true from the front end.
      active: true,
      app: {
        name: this.appName,
        build: this.build,
        version: this.version,
      },
      ...setIfDefined('campaign', utmTraits),
      ...setIfDefined('locale', properties?.locale),
      ...setIfDefined('location', {
        ...setIfDefined('latitude', properties.user_data?.latitude),
        ...setIfDefined('longitude', properties.user_data?.longitude),
        ...setIfDefined('region', properties?.user_data?.region),
        ...setIfDefined('city', properties?.user_data?.city),
        ...setIfDefined('country', properties?.user_data?.country),
        ...setIfDefined('postal_code', properties?.user_data?.postal_code),
      }),
      ...setIfDefined('os', {
        ...setIfDefined('name', properties?.os?.name),
        ...setIfDefined('version', properties?.os?.version),
      }),
      ...setIfDefined('page', {
        ...setIfDefined('url', properties?.window_location_href),
        ...setIfDefined('path', properties?.window_location_pathname),
        ...setIfDefined('search', properties?.window_location_search),
        ...setIfDefined('title', properties?.title),
        ...setIfDefined('referrer', properties?.referrer),
      }),
      ...setIfDefined('screen', {
        ...setIfDefined('density', properties?.screen?.density),
        ...setIfDefined('width', properties?.screen?.width),
        ...setIfDefined('height', properties?.screen?.height),
      }),
      ...setIfDefined('traits', traits),
      ...setIfDefined('timezone', properties?.timezone_name),
      ...setIfDefined('userAgent', properties?.user_agent),
      ...setIfDefined('ss_tracking_cookie', properties?.ss_tracking_cookie),
    } as SegmentContext;

    // Note: the segment SDK re-defines what window.analytics is several
    // times. If we don't always call the instance currently assigned to
    // window.analytics by segment we will lose tracking events.
    window.analytics.track(SegmentEvent.eventName, filteredProperties, {
      context,
      anonymousId: properties?.ss_tracking_cookie,
    });

    this.debugLog(SegmentEvent.eventName, {
      ...filteredProperties,
      context,
      anonymousId: properties?.ss_tracking_cookie,
    });
  }

  /**
   * Returns a Traits object that satisfies segment requirements as specified via
   * https://styleseat.atlassian.net/wiki/spaces/DATA/pages/1371930642/Identify+Calls#traits
   */
  getTraitsFromUser(userData: AnalyticsUserData | null): SegmentContextTraits {
    const external_id = (
      this.user?.user_token || this.user?.client_handle
    );
    const traits = {
      is_app: this.isApp,
      ...setIfDefined('external_id', external_id),
      ...setIfDefined('pro_id', userData?.provider_id),
      ...setIfDefined('email', userData?.email),
      ...setIfDefined('hashed_email', userData?.hashed_email),
      ...setIfDefined('created_at', userData?.date_joined),
      ...setIfDefined('phone', userData?.phone_number),
      /**
       * The segment -> braze integration requires that
       * first name and last name be camel cased things will
       * break if you change this casing!
       */
      ...setIfDefined('firstName', userData?.first_name),
      ...setIfDefined('lastName', userData?.last_name),
      ...setIfDefined('username', userData?.username),
      ...setIfDefined('name', userData?.provider_name),
      ...setIfDefined('company', {
        ...setIfDefined('plan', userData?.provider_plan),
      }),
      ...setIfDefined('user_id', userData?.user_id),
    } as SegmentContextTraits;
    return traits;
  }

  // eslint-disable-next-line class-methods-use-this
  updateUser(userData: AnalyticsUserData | null) {
    // Don't send segment events for bots
    if (this.isBotUser) return;
    this.user = userData;

    const traits = this.getTraitsFromUser(userData);

    if (
      // is_app will always be present on user traits
      Object.keys(traits).length === 1
      && window.analytics
      && typeof window.analytics.user === 'function'
    ) {
      window.analytics.user().traits({});
    }
    if (userData?.user_id || userData?.client_handle) {
      window.analytics?.identify(userData?.user_id || null, traits, userData.ss_tracking_cookie ? {
        anonymousId: userData.ss_tracking_cookie,
      } : {});
    }
    this.debugLog('identify', traits, {
      anonymousId: userData.ss_tracking_cookie,
    });
  }

  getTraitsFromUTMData() {
    const {
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
      referrer,
    } = this.validateUTMs();

    const utmTraits = {
      ...setIfDefined('name', utm_campaign),
      ...setIfDefined('source', utm_source),
      ...setIfDefined('medium', utm_medium),
      ...setIfDefined('term', utm_term),
      ...setIfDefined('content', utm_content),
      ...setIfDefined('referrer', referrer),
    } as SegmentContextTraits;
    return utmTraits;
  }

  async updateUTMs(utmData: UTMState | null) {
    if (!utmData) {
      this.utmData = {};
    } else {
      this.utmData = utmData;
    }
  }
}
