import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import type { IRootDispatch } from '../store/models';

export default () => {
  const dispatch: IRootDispatch = useDispatch();
  return useCallback(
    (
      defaultState: string,
      params?: { [key: string]: any },
      options?: { [key: string]: any },
    ) => dispatch.route.goBack({
      defaultState,
      params,
      options,
    }),
    [],
  );
};
