// @ts-strict-ignore
import { API_ROOT, APP } from '../../config';
import { APP_VERSION } from '../../modules/AppConstants';
import { isTest } from '../../modules/env';
import ssFetch from '../../modules/ssFetch';

/*
* Pings the backend, sending either a blank post or a post with the app version string in
* order to register information about the user agent or app version of the product that this
* logged in user is using.
* */
export const logUserAgent = (): void => {
  if (isTest()) return;

  const data = {
    app_version: undefined,
    device_model: undefined,
  };

  if (APP) {
    data.app_version = APP_VERSION;
  }

  if (window.cordova) {
    data.device_model = window?.device?.model;
  }

  ssFetch(`${API_ROOT}/api/v1/useragentlog`, {
    method: 'POST',
    body: data,
  });
};
