import { Unpacked } from '../../../../types/Unpacked';
import { loopOverDatasets } from '../utils/loopOverDatasets';
import {
  DataFields,
  DataSet,
  MasterDataSet,
  StatDataSet,
} from '../types';

/**
 * Dataset returned as the result of adding all data field values together into one object
 */
export type SummedDataSet<F extends DataFields = DataFields> = {
  [k in F]: Unpacked<MasterDataSet<F>[k]>
};

/**
 * Return the sum of all the data in the dataset
 *
 * ```
 *   var data = {
 *      { date: <2016-01-01>, appts_count: 1 },
 *      { date: <2016-01-02>, appts_count: 1 },
 *      { date: <2016-01-03>, appts_count: 4 },
 *      { date: <2016-01-04>, appts_count: 0 },
 *    };
 *   var sum = sum(data);
 *   // { date: <2016-01-01>, appts_count: 6 }
 * ```
 *
 * @param {Object} dataset The dataset object
 *
 * @return {Object} An object with the sum of all values
 */
export function sumDataset<T extends StatDataSet = StatDataSet>(
  dataset: T,
): Unpacked<T>;

export function sumDataset<F extends DataFields = DataFields>(
  dataset: MasterDataSet<F>,
): SummedDataSet<F>;

export function sumDataset<F extends DataFields = DataFields>(
  dataset: DataSet<F>,
) {
  let sumValue = {};

  // Recursive call for all value sets
  if (!Array.isArray(dataset)) {
    return loopOverDatasets(dataset, sumDataset);
  }

  // Invalid data to sum up
  if (!dataset.length) {
    return dataset;
  }

  sumValue = (dataset as any[]).reduce((result, fieldValue) => {
    const newResult = { ...result };
    // Add up each value, except date, in the object
    Object
      .keys(fieldValue)
      .forEach(prop => {
        const val = fieldValue[prop];
        if (typeof val === 'number') {
          newResult[prop] = (newResult[prop] || 0) + val;
        } else {
          newResult[prop] = val;
        }
      }, {});

    return newResult;
  }, dataset);

  return sumValue;
}
