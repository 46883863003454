/**
 * Constants used by the SubsBlocker model.
 */

export const OldiesRecapturePlanName = 'oldies-recapture-35-14-day-trial';

export enum BlockerType {
  Swiper = 'swiper',
  None = 'none',
}
