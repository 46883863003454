import React, {
  JSXElementConstructor,
  ReactElement,
  useState,
} from 'react';
import { useMount } from '../hooks';
import storeReady from '../store/ready';

interface PersistGateProps {
  // The return type of React.FC and type of children don't match, but they should
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

/**
 * This component blocks rendering until the Redux store is ready
 * (persist plugin has rehydrated or timed out)
 * This is based off of redux-persist's PersistGate but uses our own
 * store ready resolver for consistency.
 */
export const PersistGate: React.FCWithChildren<PersistGateProps> = ({
  children,
}) => {
  const [isReady, setIsReady] = useState<boolean>(false);

  useMount(async () => {
    await storeReady;
    setIsReady(true);
  });

  if (isReady) {
    return children;
  }

  return null;
};
