import { stateManager } from './user/stateManager';
import { ssFetchJSON } from './ssFetch';
import SimpleCache from './SimpleCache';
import { getPriorNCDAttribution } from './getPriorNCDAttribution';
import { NCD_ATTRIBUTION_KEY_PREFIX } from './newClientDelivery.constants';
import { filterParamsByPattern } from './urlHelpers';
import { getLandingInfo } from './landingInfo';
import { PROFILE_EDIT_ROUTE, PROFILE_ROUTE } from '../route-names';

/**
 * The maximum amount (in dollars) to require for prepayment for new client delivery appointments
 */
export const PREPAYMENT_MAXIMUM = 50;
export const NCD_TYPE_WITH_DEPOSIT = 2;
export const NCD_TYPE_PAID_AT_CHECKOUT = 3;

const SEO_NCD_SOURCE = 'seo';
const SEO_REFERRER_HOSTNAMES = [
  'www.google.com',
  'www.bing.com',
  'www.yahoo.com',
  'duckduckgo.com',
  // For Stage and LC testing using a page with links
  'styleseat-recommendations.s3.amazonaws.com',
];

const ncdCache = new SimpleCache(5 * 60); // 5 minute cache

/**
 * Clears the cache of the `ncd_details` endpoint.
 */
export function clearNCDCache() {
  ncdCache.clear();
}

/**
 * Gets the Url helper angular service (need to do this here b/c a race condition to get angular
 * service is causing one of our karma tests to fail)
 * @param {Object} routeParams A hash of route params
 * @return {promise} - object containing the urlHelper service and if ncd is in URL
 */
function isNcdInURL(routeParams) {
  const ncdParams = filterParamsByPattern(routeParams, '^ncd_');

  // at least one param has a value
  return !!Object.values(ncdParams).find(v => v !== undefined);
}

/**
 * Creates the NCD attribution obect to store as a key pair for both local storage and user state
 * @param {object} ncdParams - the ncd params for an NCD appointment
 * @param {string} searchId The search ID which led to the viewing of this pro, if any
 * @return {object} the NCD attribution to store to local storage/user state
 */
function createSerializedNCDAttributionKeyPair(ncdParams, searchId, seoReferrer) {
  const viewedTimestamp = Date.now();

  return {
    key: `${NCD_ATTRIBUTION_KEY_PREFIX}-${ncdParams.ncd_term}`,
    attribution: {
      ncdParams,
      viewedTimestamp,
      searchId,
      seoReferrer,
      proId: ncdParams.ncd_term,
    },
  };
}

/**
 * Checks if this pro matches a landing from an SEO source and returns the NCD params if so.
 * @param {object} provider - The provider object for the current profile page
 * @return {object} the SEO referrer NCD params or null
 */
function ncdParamsFromSEOReferrer(provider) {
  const landingInfo = getLandingInfo();
  if (landingInfo?.referrer
    && [
      PROFILE_EDIT_ROUTE,
      PROFILE_ROUTE,
    ].includes(landingInfo.page)) {
    const referrerHostname = new URL(landingInfo.referrer).hostname;
    if (SEO_REFERRER_HOSTNAMES.includes(referrerHostname)) {
      const landingPathParts = new URL(landingInfo.url).pathname.split('/');
      const landingVanity = landingPathParts[landingPathParts.length - 1];
      if (landingVanity === provider.vanity_url) {
        return {
          ncdParams: {
            ncd_source: SEO_NCD_SOURCE,
            ncd_term: provider.id.toString(),
          },
          seoReferrer: landingInfo.referrer,
        };
      }
    }
  }
  return { ncdParams: null };
}

/**
 * Stores NCD params for viewed pro to user state and local storage
 * @param {Object} routeParams Hash of route params
 * @param {boolean} isLoggedIn - boolean indicating if the user is logged in
 * @param {string} searchId - The search ID which led to the viewing of this pro, if any
 * @param {object} provider - The provider object for the current profile page
 * @return {promise}
 */
export function markProSeenForNCD(routeParams, isLoggedIn, searchId, provider) {
  let result;
  let ncdParams;
  let seoReferrer;

  if (isNcdInURL(routeParams)) {
    ncdParams = filterParamsByPattern(routeParams, '^ncd_');
  } else {
    ({ ncdParams, seoReferrer } = ncdParamsFromSEOReferrer(provider));
  }

  if (ncdParams?.ncd_source && ncdParams?.ncd_term) {
    const {
      key,
      attribution,
    } = createSerializedNCDAttributionKeyPair(ncdParams, searchId, seoReferrer);
    if (isLoggedIn) {
      result = stateManager.addAction(key, attribution);
    }
    result = localStorage.setItem(key, JSON.stringify(attribution));
  }

  return Promise.resolve(result);
}

/**
 * Updates the user state with the pro NCD attribution if it exists in local storage
 * @param {number} providerId - the provider id
 */
export function updateUserStateWithLocalStorageNCDAttribution(providerId) {
  const key = `${NCD_ATTRIBUTION_KEY_PREFIX}-${providerId}`;
  const attribution = JSON.parse(localStorage.getItem(key));
  if (attribution) {
    stateManager.addAction(key, attribution);
  }
}

/**
 * Get tracking params for NCD appts as a result of the 48hrs attribution
 * @param {Object} routeParams Hash of route params
 * @param {number} providerId - the provider id
 * @return {object} trackingParams for 48hrs UTM attribution
 */
export async function getNCDAttributionTrackingParams(routeParams, providerId) {
  const ncdIsInUrl = isNcdInURL(routeParams);

  return {
    is_ncd_in_url: ncdIsInUrl,
    ...(!ncdIsInUrl ? await getPriorNCDAttribution(providerId) : {}),
  };
}

export function fetchQuestions() {
  const questionsUrl = '/api/v2/clients/insight_questions';
  return ssFetchJSON(questionsUrl);
}

export function fetchClientResponses(clientId) {
  const responsesUrl = `/api/v2/clients/${clientId}/insight_responses`;
  return ssFetchJSON(responsesUrl);
}

export function fetchSurveyData(clientId) {
  return Promise.all([
    fetchQuestions(),
    fetchClientResponses(clientId),
  ]);
}

export function postSurveyResponse(answers, clientId) {
  const url = `/api/v2/clients/${clientId}/insight_responses`;
  return ssFetchJSON(url, {
    method: 'POST',
    body: JSON.stringify({
      answer_ids: answers,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
