// @ts-strict-ignore
import {
  DependencyList,
  useEffect,
  useState,
} from 'react';

export function useAsyncMemo<T = any>(
  factory: () => Promise<T>,
  deps: DependencyList,
  onError?: (e: any) => void,
  defaultValue: T = null,
): T | null {
  const [value, setValue] = useState<T | null>(defaultValue);

  useEffect(() => {
    factory().then(setValue).catch(onError);
  }, deps);

  return value;
}
