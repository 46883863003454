// @ts-strict-ignore
import moment from 'moment';
import { Unpacked } from '../../../../types/Unpacked';
import {
  DataFields,
  DataSet,
  MasterDataSet,
  StatDataSet,
} from '../types';
import { copyValue } from '../utils/copyValue';

export function valueByDate<F extends DataFields = DataFields>(
  dataset: MasterDataSet<F>,
  date: moment.Moment | string,
): MasterDataSet<F>;

/**
 * Returns the first value in each value set that match the date
 *
 * @param {Object} dataset The dataset object
 * @param {String|Moment} date The date to look for
 *
 * @return {Object}
 */
export function valueByDate<F extends DataFields = DataFields>(
  dataset: StatDataSet<F>,
  date: moment.Moment | string,
): Unpacked<StatDataSet<F>>;

/**
 * Returns the first value in each value set that match the date
 *
 * @param {Object} dataset The dataset object
 * @param {String|Moment} date The date to look for
 *
 * @return {Object}
 */
export function valueByDate<F extends DataFields = DataFields>(
  dataset: DataSet<F>,
  date: moment.Moment | string,
): Unpacked<DataSet<F>> {
  const mDate = moment(date, 'YYYY-MM-DD').startOf('day');
  if (!Array.isArray(dataset)) {
    return undefined;
  }

  return copyValue(
    (dataset as any[]).find(value => mDate.isSame(value.date)),
  );
}
