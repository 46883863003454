import { openExternalUrl } from '../openExternalUrl';

export * from './constants';
export { isEligible } from './isEligible';
export { paymentIntentRequiresCapture } from './paymentIntentRequiresCapture';

const KLARNA_APP_URL = 'https://www.klarna.com/us/klarna-app/';
export const openKlarnaAppUrl = () => {
  openExternalUrl(KLARNA_APP_URL);
};

const KLARNA_BUSINESS_URL = 'https://www.klarna.com/us/business/';
export const openKlarnaBusinessUrl = () => {
  openExternalUrl(KLARNA_BUSINESS_URL);
};

export class KlarnaError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'KlarnaError';
  }
}
