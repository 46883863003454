// @ts-strict-ignore
/**
 * Module to retrieve colors associated with provider services and appointments
 */

import { getCachedServiceGroups } from '../../../api/Providers/ServiceGroups';

let serviceColors = {};

export function resetServiceColorMap() {
  serviceColors = {};
}

export type ServiceColorMap = {
  [serviceId: number]: string;
};

/**
 * Return the map of service ID to colors.
 * @param   {int} providerId
 * @return {Promise}
 */
export async function getAppointmentColorMap(providerId): Promise<ServiceColorMap> {
  if (serviceColors && Object.keys(serviceColors).length) {
    return serviceColors;
  }

  const { results } = await getCachedServiceGroups({ providerId });

  resetServiceColorMap();

  results.forEach(serviceGroup => {
    serviceGroup.services.forEach(service => {
      serviceColors[service.id] = serviceGroup.color;
    });
  });

  return serviceColors;
}
