// @ts-strict-ignore
import loadable from '@loadable/component';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps, Route } from 'react-router-dom';
import {
  useAsyncEffect,
  useLinkTo,
  useMount,
  useProviderId,
} from '../../../hooks';
import { useAsyncMemo } from '../../../../../packages/hooks';
import nonCriticalException from '../../../modules/exceptionLogger';
import { RouteGuardConfig } from '../../../routes';
import store from '../../../store';
import type { IRootDispatch, RootState } from '../../../store/models';
import storeReady from '../../../store/ready';
import SubsBlockerSelectors from '../../../store/SubsBlocker.selectors';
import { requireLogin } from '../login/SignUpLoginModal';
import { AuthOrigins } from '../../../modules/user/constants';
import * as Page from '../../../modules/PageMeta';
import { HOME_ROUTE } from '../../../route-names';
import { fetchPaymentState } from '../../../modules/provider/PaymentSettings';
import PaymentsState from '../../../modules/PaymentsState';

type GuardableRouteProps = RouteProps & RouteGuardConfig;

const InactiveBlockerContainer = loadable(
  () => import('../../provider/ScreenBlocker/Inactive/InactiveBlockerContainer'),
  { resolveComponent: components => components.InactiveBlockerContainer },
);

export const GuardableRoute: React.FCWithChildren<GuardableRouteProps> = ({
  loginFailedRedirect,
  loginRequired,
  loginOrigin,
  loginMessage,
  loggedOutTitle,
  subsRequired,
  ...routeProps
}) => {
  const redirectTo = useLinkTo({ replace: true });
  const [authorized, setAuthorized] = useState<boolean>(false);
  const dispatch = useDispatch<IRootDispatch>();
  const userId = useSelector<RootState, number | null>(state => state.user.userId);
  const providerId = useProviderId();

  const paymentState = useAsyncMemo<PaymentsState>(
    async () => {
      if (providerId && subsRequired) {
        return fetchPaymentState(providerId);
      }
      return null;
    },
    [
      providerId,
      subsRequired,
    ],
  );
  const showSubsBlocker = useSelector<RootState, boolean>(
    state => subsRequired
    && SubsBlockerSelectors
      .shouldShowSubsBlocker(state, undefined, paymentState),
  );

  useEffect(() => {
    if (loggedOutTitle !== undefined) {
      Page.setMeta({ title: loggedOutTitle });
    }
  }, [loggedOutTitle]);

  useMount(async () => {
    await storeReady;

    try {
      // if we're a direct land, we may not have loaded user information yet
      await dispatch.user.whoami({});
    } catch (e) {
      nonCriticalException(e);
    }

    if (loginRequired) {
      const loggedInUserId = store.getState().user?.userId;

      if (!loggedInUserId) {
        dispatch.loader.setIsLoading(false);
        requireLogin({
          mode: 'clientlogin',
          origin: loginOrigin as AuthOrigins,
          helperText: loginMessage,
        })
          .then(({ userData }) => {
            if (!userData.user_id) {
              throw new Error(`${routeProps.path}: Successful login but user_id is undefined`);
            }
            setAuthorized(true);
          })
          .catch(e => {
            nonCriticalException(
              `${routeProps.path}: Anon user cancelled login`,
              { ...(e?.msg && { message: e.msg }) },
            );
            redirectTo(loginFailedRedirect || HOME_ROUTE);
          });
      } else {
        // login is required, but there's already a user ID
        setAuthorized(true);
      }
    } else {
      // always wait for store ready in guarded routes, regardless of user requirements
      setAuthorized(true);
    }
  });

  useAsyncEffect(async () => {
    if (!subsRequired) return;
    await store.dispatch.subsBlocker.update();
  }, [
    userId,
    subsRequired,
  ]);

  const canRenderContents = authorized && !showSubsBlocker;

  return (
    <React.Fragment>
      {showSubsBlocker && <InactiveBlockerContainer />}
      {canRenderContents && <Route {...routeProps} />}
    </React.Fragment>
  );
};
