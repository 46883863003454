// @ts-strict-ignore
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import URI from 'urijs';

import { createModel } from '@rematch/core';
import type { RootModel, RootState } from './models';
import { ssFetchJSON } from '../modules/ssFetch';

export type ProviderServiceReviewsSummaryState = {
  service_id: number;
  number_of_reviews: number;
  average: string;

};

export type ProviderAllServiceReviewsSummaryState = {
  [key: number]: ProviderServiceReviewsSummaryState;
};

/**
 * The state stored by this model
 */
type ReviewSummaryState = {
  providerMap: { [key: number]: ProviderAllServiceReviewsSummaryState };
  /** True if review data is loading, otherwise false */
  loading: boolean;
};

/**
 * Creates a default state
 */
const createDefaultState = (): ReviewSummaryState => ({
  providerMap: {},
  loading: false,
});

/**
 * Ensures that there is an object in the state for storing provider-specific data.
 * @param state The current state
 * @param providerId The provider ID
 */
const ensureProvider = (state: ReviewSummaryState, providerId: number): ReviewSummaryState => {
  state.providerMap[providerId] = state.providerMap[providerId] || {};
  return state;
};

/** Standardized payload for various setters in this model */
type ProviderPayload<T> = {
  providerId: number;
  value: T;
};

/** Create a payload for setters given a payload containing providerId and the value to provide. */
const createPayload = <T>(
  originalPayload: { providerId: number },
  value: T,
): ProviderPayload<T> => ({
    providerId: originalPayload.providerId,
    value,
  });

const model = createModel<RootModel>()({
  name: 'providerReviewsSummary',

  state: createDefaultState() as ReviewSummaryState,

  reducers: {
    /** Sets the reviews for the given provider */
    setReviews: produce<ReviewSummaryState, [
      ProviderPayload<ProviderAllServiceReviewsSummaryState>,
    ]>((
      state: ReviewSummaryState,
      payload: ProviderPayload<ProviderAllServiceReviewsSummaryState>,
    ) => {
      ensureProvider(state, payload.providerId);
      state.providerMap[payload.providerId] = payload.value;
    }),
    /** Sets the loading status common to all providers */
    setLoading: produce<ReviewSummaryState, [boolean]>((
      state: ReviewSummaryState,
      payload: boolean,
    ) => {
      state.loading = payload;
    }),
  },

  effects: dispatch => ({
    load: async (
      payload: { providerId: number },
      rootState: RootState,
    ) => {
      const {
        providerId,
      } = payload;
      if (rootState.providerReviewsSummary.providerMap[providerId]) {
        return;
      }
      dispatch.providerReviewsSummary.setLoading(true);
      const uri = URI(`/api/v2/providers/${providerId}/ratings/summary/`);
      try {
        const response = await ssFetchJSON(uri.toString());
        dispatch.providerReviewsSummary.setReviews(
          createPayload(payload, response.data),
        );
      } catch (e) {
        dispatch.providerReviewsSummary.setReviews(
          createPayload(payload, {}),
        );
      }
      dispatch.providerReviewsSummary.setLoading(false);
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    getAll() {
      return createSelector(
        slice,
        ((reviews: ReviewSummaryState) => reviews?.providerMap),
      );
    },

    forPro: hasProps((_, props: { providerId: number }) => (
      createSelector(
        slice(reviews => reviews?.providerMap),
        (reviews: ReviewSummaryState['providerMap']) => reviews?.[props.providerId],
      )
    )),
  }),
});
export default model;
