// @ts-strict-ignore
import produce from 'immer';
import { getPersistor } from '@rematch/persist';
import { Platform } from 'react-native';

import { Action, createModel } from '@rematch/core';
import analytics from '../modules/analytics';
import { clear as clearStorage } from '../modules/KeyValueStorage';
import { identify as identifyWithLogRocket } from '../modules/LogRocket';
import ssFetch, { ssFetchCache, ssFetchJSON } from '../modules/ssFetch';
import { auth as fbAuth, disconnect as fbDisconnect } from '../modules/fbAuthManager';
import { confirmEmailForPasswordReset } from '../modules/passwordReset';
import { isValidEmail } from '../modules/validators';
import { auth as appleAuth, disconnect as appleDisconnect } from '../modules/appleAuthManager';
import createDefaultUser from '../modules/user/createDefaultUser';
import debouncer from '../modules/debouncer';
import * as SignupSettings from '../api/Providers/SignupSettings';
import { REMOVE_NAME_FIELDS_TEST_NAME } from '../components/provider/Signup/constants';
import stateMgr from '../modules/user/stateManager';
import type { IRootDispatch, RootModel } from './models';
import {
  SignupClientModalResponse,
  SignupUserModalResponse,
  IResponseError,
  MakeSignupEffectPayload,
  SetCurrentUserPayload,
  UpdateCurrentUserPayload,
  State,
  FBAuthResult,
  AjaxLogoutResponse,
  NotifyUserPayload,
  OnNotifyCurrentUserPayload,
  AjaxLoginResponse,
  isAjaxLoginResponseLogin,
  isAjaxLoginResponseMFA,
  isAjaxLoginOKResponse,
  isAjaxLoginResponseMFAFail,
  LoginWithGoogleParams,
  MakeGoogleAuthPayload,
  GoogleAuthResponse,
  AppleSSOResponse,
} from './CurrentUser.types';
import { clearPaymentSettingsCache } from '../modules/provider/PaymentSettings';
import nonCriticalException from '../modules/exceptionLogger';
import { logUserAgent } from '../api/LogUserAgent';
import { clearNCDCache } from '../modules/newClientDelivery';
import ChatManager from '../modules/chat/ChatManager';
import { MultiFactorAuthMethod } from '../components/shared/login/MultiFactorAuthForm/types';
import fbAPI from '../modules/fbAPI';
import { ReminderType } from '../api/Providers/Appointments';
import { HOME_ROUTE } from '../route-names';

const debounce = debouncer();

export const USER_REMINDER_PREFERENCES = {
  NONE: 0,
  SMS_EMAIL: 1,
  EMAIL: 2,
  SMS: 3,
};

/**
 * Sends a request using form encoding.
 * @param {String} url The request url.
 * @param {Object} [form={}] A hash of form data to send.
 * @yields {Object} The JSON response data
 */
export const sendPostRequest = async <T = any>(url: string, form: { [key: string]: any } = {}) => {
  const response = await ssFetch<T>(url, {
    form,
    throwOnHttpError: true,
    method: 'POST',
    headers: {
      // make sure the server thinks this is ajax :face_with_rolling_eyes:
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  return response.json();
};

/**
 * Create an `Error` object using the given data.
 * @param {String} message The message to attach to the error
 * @param {Object} data Data to place in the `data` property of the error
 */
const newResponseError = (message: string, data: any) => {
  const err = new Error(message) as unknown as IResponseError;
  err.data = data;
  err.status = 200; // OK HTTP status
  return err;
};

const makeGoogleAuthEffect = (dispatch: IRootDispatch, url: string) => async (
  payload: MakeGoogleAuthPayload,
): Promise<GoogleAuthResponse> => ssFetchJSON(url, { body: payload, method: 'POST' })
  .then(async (json: GoogleAuthResponse) => {
    const isSigningUp = json.action === 'Signup';
    await dispatch.user.notifyCurrentUser({
      isLogin: !isSigningUp,
      setCurrentUserPayload: {
        ...json,
        is_new_user: isSigningUp,
      } as any,
    });
    await dispatch.ncdAndDiscountedPros.clearAll();
    await dispatch.user.whoami({ force: true });
    await dispatch.user.notifyAnalytics();

    if (isSigningUp) {
      analytics.track('user_model_signup_succeeded');
      analytics.track('user_model_google_sso_signup_succeeded');
    } else {
      analytics.track('user_model_login_succeeded');
      analytics.track('user_model_google_sso_login_succeeded');
    }
    return json;
  })
  .catch(async json => {
    const parsedJson = await JSON.parse(json);
    await dispatch.user.setErrors({
      ...parsedJson,
      ssoError: true,
    });
    analytics.track('user_model_google_sso_failed');
    throw newResponseError('Signup failed', json);
  });

const makeSignupEffect = <T extends { status: string } & SetCurrentUserPayload>(
  dispatch,
  url: string,
  isClientSignup?: boolean,
) => async (payload: MakeSignupEffectPayload): Promise<T | unknown> => {
    const firstName = payload.firstName || payload.first_name;
    const lastName = payload.lastName || payload.last_name;
    const fullName = firstName ? `${firstName} ${lastName}` : payload.fullName || payload.full_name;
    const password = payload.password || payload.password1;

    let userCanSkipEnteringName = false;
    const userCanSkipEnteringNameTestValue = JSON.parse(
      localStorage.getItem(`const.${REMOVE_NAME_FIELDS_TEST_NAME}`),
    );

    if (userCanSkipEnteringNameTestValue) {
      userCanSkipEnteringName = userCanSkipEnteringNameTestValue.choice && !isClientSignup;
    }

    const errors = {
      fullName: !userCanSkipEnteringName && (!fullName || String(fullName).split(' ').length < 2),
      email: !isValidEmail(payload.email),
      // do not require password for facebook login
      password: !payload.fb_data && !payload.google_jwt && !password,
    };

    if (Object.values(errors).find(value => value === true)) {
      return dispatch.user.setErrors(errors);
    }

    const reqData = {
      fb_data: payload.fb_data,
      first_name: firstName,
      last_name: lastName,
      full_name: fullName,
      password1: password,
      ...payload,
    };
    analytics.track('user_model_signup_started', {
      signup_url: url,
    });
    return sendPostRequest(url, reqData)
      .then(async (json: T) => {
        if (json.status === 'OK') {
        // Since response.data returned by userSignupUrl and consumerSignupUrl are not exactly
        // the same as `whoami` data, re-request a proper `whoami` response. Except we don't
        // want to return that data, because code might rely on the data from the signup
        // request.
          await dispatch.user.notifyCurrentUser({
            isLogin: true,
            setCurrentUserPayload: {
              ...json,
              is_new_user: true,
            } as any,
          });
          await dispatch.ncdAndDiscountedPros.clearAll();
          await dispatch.user.whoami({ force: true });
          await dispatch.user.notifyAnalytics(null);

          analytics.track('user_model_signup_succeeded');
          return json;
        }

        await dispatch.user.setErrors(json as any);
        analytics.track('user_model_signup_failed');
        throw newResponseError('Signup failed', json);
      });
  };

const CurrentUser = createModel<RootModel>()({
  state: createDefaultUser(),

  reducers: {
    /**
     * Fully replaces the current user with data sent via the payload.
     */
    setCurrentUser: (state: State, payload: SetCurrentUserPayload) => {
      const payloadObj: (
        SetCurrentUserPayload | Partial<State>
      ) = payload || {};

      const defaultUser = createDefaultUser(state);
      const client_handle = payload?.client_handle || state?.client_handle;
      const {
        jwt_token,
        ss_tracking_cookie: tkm,
      } = payloadObj as any;
      if (!payload || payload.is_anon) {
        return {
          ...defaultUser,
          ...(client_handle ? { client_handle } : {}),
          jwt_token,
          ss_tracking_cookie: tkm,
        };
      }

      let provider: Partial<State> = {
        numBooked: undefined,
        providerId: undefined,
        plan: undefined,
        numAppt: undefined,
        numClients: undefined,
        providers: undefined,
      };

      if ('provider_id' in payload) {
        provider = {
          ...provider,
          providerId: payload.provider_id,
          plan: payload.plan,
          numAppt: payload.num_appt,
          numClients: payload.num_clients,
        };
      }

      if ('num_booked' in payload) {
        provider.numBooked = payload.num_booked;
      }

      if ('providers' in payload) {
        provider.providers = payload.providers;
      }

      return {
        ...defaultUser,
        ...payloadObj,
        ...provider,
        is_new_user: !!payloadObj.is_new_user || !!state.is_new_user,
        userId: (payloadObj as any).user_id,
        is_anon: !(payloadObj as any).user_id,
        errors: null,
        ss_tracking_cookie: tkm,
      };
    },

    /**
     * Sets the current user to an anonymous user.
     */
    clearCurrentUser: (state: State) => createDefaultUser(state),

    /**
     * Updates the user email
     */
    setUserEmail: produce<State, [string]>((
      state: State,
      payload: string,
    ) => {
      state.email = payload;
    }),

    /**
     * Updates the user ss_tracking_cookie
     */
    setTrackingId: produce<State, [string]>((
      state: State,
      payload: string,
    ) => {
      state.ss_tracking_cookie = payload;
    }),

    setErrors: produce<State, [string | { [key: string]: unknown }]>((
      state: State,
      payload: string | { [key: string]: unknown },
    ) => {
      state.errors = payload;
    }),

    clearErrors: produce((state: State) => {
      state.errors = null;
    }),

    increaseNumAppt: produce((state: State) => {
      state.numAppt = (state.numAppt || 0) + 1;
    }),

    decreaseNumAppt: produce((state: State) => {
      state.numAppt = (state.numAppt || 1) - 1;
    }),
  },

  effects: dispatch => ({
    /**
     * Sets the current user and handle any actions that should be performed
     * when the user is updated. In most cases this should be used instead of
     * calling dispatch.user.setCurrentUser directly.
     */
    notifyCurrentUser: async (payload: NotifyUserPayload | null) => {
      if (payload === null) {
        await dispatch.user.clearCurrentUser();
        await dispatch.user.onNotifyCurrentUser(undefined);
      } else {
        await dispatch.user.setCurrentUser(payload.setCurrentUserPayload);
        await dispatch.user.onNotifyCurrentUser({ isLogin: payload.isLogin });
      }
    },
    notifyAnalytics: async (payload?, state?) => {
      await analytics.updateUser({
        ss_tracking_cookie: state.user.ss_tracking_cookie,
        user_id: state.user.user_id,
        email: state.user.email,
        hashed_email: state.user.hashed_email,
        date_joined: state.user.date_joined,
        username: state.user.username,
        last_name: state.user.last_name,
        first_name: state.user.first_name,
        phone_number: state.user.phone_number,
        provider_id: state.user.providerId,
        provider_name: state.user.providers?.[0]?.name,
        provider_plan: state.user.plan,
        is_logged_in: state.user.isLogin,
        user_token: state.user.user_token,
        client_handle: state.user.client_handle,
      });
    },
    /**
     * separate from notifyCurrentUser to get access to the updated state after setCurrentUser
     */
    onNotifyCurrentUser: async (
      payload: OnNotifyCurrentUserPayload,
      rootState,
    ): Promise<void> => {
      const { user, abTest } = rootState;
      stateMgr.updateUser(dispatch, user);

      if (dispatch.userState) {
        dispatch.userState.handleUserUpdate();
      }

      if (!user) return;

      if (payload) {
        identifyWithLogRocket(user, abTest?.assignments);

        if (payload.isLogin) {
          // If the user has just logged in, fetch & calculate the subs blocker state
          logUserAgent();
          debounce(dispatch.subsBlocker.update);
          debounce(dispatch.stripeIdentityVerification.load);
          debounce(clearNCDCache);
        }
      }
    },

    /**
     * Updates the current user with what is stored on the server.
     * @param payload {Object} Send `{ force: true }` to force querying the server, rather than
     * using a cache.
     * @yields {Object} The user data.
     */
    whoami: async (
      payload: { force?: boolean; reIdentify?: boolean } = {},
      rootState,
    ): Promise<SetCurrentUserPayload> => {
      const { user: { is_anon: wasAnonymous } } = rootState;
      try {
        const response = await ssFetch('/accounts/whoami/', {
          ssCache: !payload.force,
          method: 'GET',
          useFormContentType: true,
          throwOnHttpError: true,
        });

        const json: SetCurrentUserPayload = await response.json();

        await dispatch.user.notifyCurrentUser({ setCurrentUserPayload: json });

        if ((wasAnonymous && !json.is_anon) || payload.reIdentify) {
          await dispatch.user.notifyAnalytics();
        }

        return json;
      } catch (e) {
        if (e.code === 401) {
          await dispatch.user.logout();
          await dispatch.route.go({
            route: HOME_ROUTE,
          });
          return createDefaultUser();
        }
        throw e;
      }
    },

    login: async (payload: {
      email: string;
      password: string;
      userName?: string;
      mfa_delivery_method?: MultiFactorAuthMethod;
      mfa_code?: string;
      remember_me?: number;
    }): Promise<Action<string | { [key: string]: unknown }, void> | AjaxLoginResponse> => {
      const errors = {
        email: !isValidEmail(payload.email),
        password: !payload.password,
      };

      if (Object.values(errors).find(value => value === true)) {
        return dispatch.user.setErrors(errors);
      }

      await dispatch.user.notifyCurrentUser(null);

      try {
        const loginResponse = await sendPostRequest<AjaxLoginResponse>('/accounts/ajax-login/', payload);
        if (isAjaxLoginOKResponse(loginResponse)
          && (isAjaxLoginResponseLogin(loginResponse) || isAjaxLoginResponseMFA(loginResponse))
        ) {
          dispatch.ncdAndDiscountedPros.clearAll();
          await dispatch.user.notifyCurrentUser({
            isLogin: true,
            setCurrentUserPayload: loginResponse,
          });

          await dispatch.user.notifyAnalytics();

          if (isAjaxLoginResponseLogin(loginResponse)) {
            analytics.track('user_model_login_succeeded');
          }

          return loginResponse;
        }
        dispatch.user.setErrors(loginResponse);
        throw newResponseError('Login failed', loginResponse);
      } catch (response) {
        analytics.track('user_model_login_failed', {
          reason: isAjaxLoginResponseMFAFail(response.data) ? 'mfa' : 'wrong_email_password_combination',
        });
        if (response.code === 429) {
          dispatch.user.setErrors({
            errorMessage: 'You\'ve made too many login attempts, please try again later',
            errorCode: 429,
          });
        }
        throw newResponseError('Login failed', response);
      }
    },
    /**
     * Creates a client account using information provided in the payload and loads the information
     * of the created user into the current user information.
     * @param payload {Object} Any information to be sent to the server for signup. Payload should
     * contain firstName, lastName, email, and possibly password. fullName may be provided instead
     * of firstName and lastName. A 'fb_data' field may also be provided. Note that we do *not*
     * create providers here.
     * @yields {Object} The data contained in the signup response.
     */
    signupClient: makeSignupEffect<SignupClientModalResponse>(dispatch, '/signup-client-modal/', true),
    googleAuth: makeGoogleAuthEffect(dispatch, '/api/v1/google-auth/'),
    /**
     * Creates a basic user account using information provided in the payload and loads the
     * information of the created user into the current user information.
     * @param payload {Object} Any information to be sent to the server for signup. Payload should
     * contain firstName, lastName, email, and possibly password. fullName may be provided instead
     * of firstName and lastName. A 'fb_data' field may also be provided. Note that we do *not*
     * create providers here.
     * @yields {Object} The data contained in the signup response.
     */
    signupUser: makeSignupEffect<SignupUserModalResponse>(dispatch, '/signup-user-modal/'),

    connectWithFacebook: async (): Promise<SetCurrentUserPayload> => {
      await dispatch.user.clearErrors();
      try {
        const fbAuthResult: FBAuthResult = await fbAuth();
        const {
          fbAuthStatus,
          fbUserData,
          whoami,
        } = fbAuthResult;
        if (whoami) {
          // we already have an account
          await dispatch.user.notifyCurrentUser({
            isLogin: true,
            setCurrentUserPayload: whoami,
          });

          analytics.track('user_model_login_with_facebook_succeeded');
          dispatch.user.notifyAnalytics();

          return whoami;
          // maybe we should track this somehow
        }

        // create an account somehow
        return await dispatch.user.signupClient({
          fb_data: JSON.stringify(fbAuthStatus),
          firstName: fbUserData.first_name,
          lastName: fbUserData.last_name,
          email: fbUserData.email,
        });
      } catch (ssoError) {
        const errorReason = ssoError instanceof Error ? ssoError.message : 'There was a problem connecting with Facebook.';
        await dispatch.user.setErrors({
          facebook_connect: errorReason,
          ssoError: true,
        });
        throw ssoError;
      }
    },
    /**
     * Authenticate via facebook. If a connected account does not exist, one is created.
     */
    loginWithFacebook: async (): Promise<SetCurrentUserPayload> => {
      await dispatch.user.notifyCurrentUser(null);
      analytics.track('user_model_login_with_facebook_started');
      return dispatch.user.connectWithFacebook();
    },

    /**
     * Authenticate via Google. If a connected account does not exist, one is created.
     */
    loginWithGoogle: async ({
      google_jwt,
      accepted_terms,
    }: LoginWithGoogleParams): Promise<SetCurrentUserPayload> => {
      await dispatch.user.notifyCurrentUser(null);
      analytics.track('user_model_google_sso_started');
      return dispatch.user.googleAuth({
        google_jwt,
        accepted_terms,
      });
    },

    disconnectFacebookAccount: async () => {
      try {
        await dispatch.user.clearErrors();
        try {
          await fbAPI.logout();
        } catch {
          // Throws if we weren't logged in, which is fine, do nothing in that case
        }
        await fbDisconnect();
        analytics.track('user_disconnect_facebook');
      } catch (e) {
        const errorReason = e instanceof Error ? e.message : e;
        dispatch.user.setErrors({ facebook_disconnect: errorReason });
      } finally {
        // reload the user
        dispatch.user.whoami({ force: true });
      }
    },

    /**
     *
     * @param client_handle (String) client identification handle
     * @param user: (CurrentUser) user object in the current state
     * @returns user (CurrentUser) user with the new client handle
     */
    setClientHandle: async (
      client_handle: string,
      rootState,
    ): Promise<SetCurrentUserPayload> => {
      const { user } = rootState;
      await dispatch.user.notifyCurrentUser({ setCurrentUserPayload: { ...user, client_handle } });
      await dispatch.user.notifyAnalytics();
      return { ...user, client_handle };
    },

    /**
     * Authenticate via Apple. If a connected account does not exist, one is created.
     */
    loginWithApple: async (options: {
      mode?: string;
      authToken?: string;
      flowType?: string;
      destination?: string;
    } | undefined): Promise<AppleSSOResponse> => {
      if (options?.mode !== 'connect') {
        await dispatch.user.notifyCurrentUser(null);
      }
      await dispatch.user.clearErrors();
      analytics.track('user_model_login_with_apple_started');
      return appleAuth(options).then(authResponse => {
        const { user } = authResponse;
        dispatch.user.notifyCurrentUser({
          isLogin: true,
          setCurrentUserPayload: user,
        });
        analytics.track('user_model_login_with_apple_succeeded');
        return authResponse;
      }, authError => {
        const errorReason = authError instanceof Error ? authError.message : authError;
        dispatch.user.whoami({ force: true });
        dispatch.user.setErrors({ apple_connect: errorReason, ssoError: true });
        return Promise.reject(authError);
      });
    },

    /**
     * Disconnect the apple account from the user
     */
    disconnectAppleAccount: async (): Promise<void> => {
      try {
        await dispatch.user.clearErrors();
        await appleDisconnect();
      } catch (e) {
        const errorReason = e instanceof Error ? e.message : e;
        dispatch.user.setErrors({ apple_disconnect: errorReason });
      } finally {
        dispatch.user.whoami({ force: true });
      }
    },

    /**
     * Logs the current user out and clears user information.
     */
    logout: async (): Promise<any> => {
      let json: AjaxLogoutResponse;
      try {
        json = await sendPostRequest<AjaxLogoutResponse>('/accounts/ajax-logout/');

        if (json.status !== 'OK') {
          throw newResponseError('Logout failed', json);
        }
        await dispatch.user.notifyCurrentUser(null);
        dispatch.user.notifyAnalytics();
      } catch (e) {
        analytics.track('user_model_logout_failed');
        nonCriticalException(e, { source: 'logout' });
        return undefined;
      }

      dispatch.paymentMethods.clearAllPaymentMethods();
      dispatch.ncdAndDiscountedPros.clearAll();
      dispatch.utmParameters.clearUTMParams();

      // clear the fetch cache so we don't retrieve a cached user on whoami calls
      ssFetchCache.clear();

      // Clear the signup settings cache too
      SignupSettings.clearCache();
      clearPaymentSettingsCache();
      clearNCDCache();
      dispatch.stripeIdentityVerification.reset();

      // Clear chat sessions
      ChatManager.cleanUp();

      try {
        await getPersistor().flush();
        await getPersistor().purge();
        await clearStorage();

        if (Platform.OS === 'web') {
          // clearStorage only clears localForage on web
          // we should also clear localStorage
          localStorage.clear();
          sessionStorage.clear();
        }
      } catch (e) {
        // In case the call to flush caused something in our store to trip
        // we want to know.
        nonCriticalException(e, { source: 'logout' });
      }

      analytics.track('user_model_logout_complete');

      // give time for any async clears to happen
      // and avoid canceling the endpoint request by redirecting
      // too quickly
      return new Promise(resolve => {
        setTimeout(() => resolve(json), 500);
      });
    },

    /**
     * Sends a forgot password request to the API
     */
    forgotPassword: async (
      payload: { email: string },
    ): Promise<Action<string | { [key: string]: unknown }, void> | { data: any }> => {
      if (!isValidEmail(payload.email)) {
        return dispatch.user.setErrors({ email: true });
      }

      try {
        return await confirmEmailForPasswordReset(payload.email);
      } catch (errors) {
        return dispatch.user.setErrors(errors);
      }
    },

    /**
     * Updates the user information on the API
     * @param payload
     * @returns {Promise<*>}
     */
    updateUser: async (payload: UpdateCurrentUserPayload): Promise<{ status: string }> => {
      const reqData = {
        ...payload,
        first_name: payload.firstName || payload.first_name,
        preferred_pronouns: payload.preferredPronouns,
        last_name: payload.lastName || payload.last_name,
        full_name: payload.fullName || payload.full_name,
        phone_number: payload.phoneNumber || payload.phone_number,
        reminder_preference: payload.reminder_preference || (
          (payload.sms_opted_in || payload.smsOptedIn) ? ReminderType.SmsEmailReminder : undefined
        ),
      };

      if (reqData.smsOptedIn) {
        reqData.reminder_preference = USER_REMINDER_PREFERENCES.SMS_EMAIL;
      }

      const json: { status: string } = await sendPostRequest('/signup-client-modal/', reqData);
      if (json.status === 'OK') {
        // we don't get much from the result of this call so
        // in order to update the user in this model we need to
        // dispatch whoami.
        await dispatch.user.whoami({ force: true });
        return json;
      }

      throw newResponseError('Update failed', json);
    },

    /**
     * Update the password of the currently logged-in user.
     */
    updatePassword: async ({
      oldPassword,
      newPassword,
    }: {
      oldPassword: string;
      newPassword: string;
    }): Promise<any> => {
      const form = {
        new_password1: newPassword,
        new_password2: newPassword,
        old_password: undefined,
      };
      if (oldPassword) {
        // Only necessary if user has a password set already.
        // Facebook/Apple/Google OAuth users don't necessarily have a password set yet.
        form.old_password = oldPassword;
      }
      return sendPostRequest('/ajax_change_password', form)
        .then(json => {
          if (json.status !== 'OK') {
            throw newResponseError('Update password failed', json);
          }
          return json;
        });
    },

    /**
     * Update the email of the currently logged-in user.
     */
    updateEmail: async (email: string): Promise<{ status: string }> => {
      const form = {
        email,
      };
      const json: { status: string } = await sendPostRequest('/ajax_update_login', form);
      if (json.status !== 'OK') {
        throw newResponseError('Update password failed', json);
      }
      return json;
    },

    /**
     * Deactivates the current user.
     */
    deactivate: async (): Promise<any> => {
      const response = await ssFetch('/api/v1/profile', {
        method: 'DELETE',
      });

      if (Number(response.headers.get('content-length')) > 0) {
        return response.json();
      }

      return undefined;
    },
  }),

  selectors: (slice, createSelector) => ({
    isLoggedIn() {
      return createSelector(
        slice,
        (state: State): boolean => !state?.is_anon,
      );
    },

    providerId() {
      return createSelector(
        slice,
        (state: State) => state?.providerId,
      );
    },

    isSuperUser() {
      return createSelector(
        slice,
        (state: State): boolean => state?.is_superuser,
      );
    },

    isNotLoggedIn() {
      return createSelector(
        slice,
        (state: State): boolean => state?.is_anon === true,
      );
    },

    isClientUser() {
      return createSelector(
        slice,
        (state: State): boolean => state?.is_anon === false && !state?.providerId,
      );
    },

    isProviderUser() {
      return createSelector(
        slice,
        (state: State): boolean => state?.is_anon === false && !!state?.providerId,
      );
    },

    providers() {
      return createSelector(
        slice,
        (state: State) => state?.providers || [],
      );
    },

    getId() {
      return createSelector(
        slice,
        (state: State): number | undefined => state?.userId,
      );
    },

    getFullName() {
      return createSelector(
        slice,
        (state: State): string => `${state.first_name} ${state.last_name}`,
      );
    },

    getPhoneNumber() {
      return createSelector(
        slice,
        (state: State): string | undefined => state?.phone_number,
      );
    },

    getEmail() {
      return createSelector(
        slice,
        (state: State): string | undefined => state?.email,
      );
    },

    getErrors() {
      return createSelector(
        slice,
        (state: State): string | { [key: string]: unknown } | null => state?.errors,
      );
    },

    getUsername() {
      return createSelector(
        slice,
        (state: State): string => state?.username || '',
      );
    },
  }),
});

export default CurrentUser;

export const selectors = {
  isLoggedIn: state => state?.user && !state.user.is_anon,
  isProvider: state => !!state?.user?.providerId,
  isSuperUser: (state): boolean => !!state.user?.is_superuser,
  getAuthToken: state => state?.user?.auth_token,
  getTrackingId: state => state?.user?.ss_tracking_cookie || null,
  hasFewClients: state => {
    const { numClients = 0 } = state?.user || {};
    return numClients >= 0 && numClients < 10;
  },
  getNumAppt: state => state?.user?.numAppt || 0,
  getLoggedInProviderId: state => state?.user?.providerId,
  fullName: state => `${state.user.first_name} ${state.user.last_name}`,
  getEmail: state => state?.user?.email,
  getHashedEmail: state => state?.user?.hashed_email,
  getId: state => state?.user?.userId,
  getProviderId: state => state?.user?.providerId,
  hasPrivilegedAccessToProvider: (state, providerId: number) => (
    selectors.isSuperUser(state)
    || state?.user?.providerId === providerId
    || !!state?.user?.providers?.some?.(p => p.id === providerId)
  ),
  /** Retrieves the current providerId with accomodations for when the current user has
   * privileged access (such as a salon admin or superuser).
   * In the latter case, we'll grab it from the route before trying the user store
   */
  getSuperAwareProviderId: (
    state,
  ): number => {
    const {
      route: {
        params: {
          providerId: providerIdParamFromRoute,
        } = { providerId: 0 },
      } = {},
      user: { providerId: userProviderId } = { providerId: 0 },
    } = state;
    const providerIdFromRoute = parseInt(providerIdParamFromRoute, 10);
    if (
      providerIdFromRoute
      && selectors.hasPrivilegedAccessToProvider(state, providerIdFromRoute)
    ) {
      return providerIdFromRoute;
    }
    return Number(userProviderId) || providerIdFromRoute || 0;
  },
  getProviderPlan: (state): string | null => state?.user?.plan || null,
  getErrors: (state): string | { [key: string]: unknown } | null => state?.user?.errors
    || null,
};

/**
 * Determines if the currently logged in user is able to edit the profile with the given ID, which
 * is generally an indication that the user is viewing their own profile.
 * @param {Number} profileProviderId The ID of the profile currently being viewed
 * @param {Object} state The current redux state
 */
export function isViewingEditableProfile(profileProviderId: number, state) {
  return selectors.hasPrivilegedAccessToProvider(state, profileProviderId);
}
