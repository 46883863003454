import { CALENDAR_ROUTE, PRO_PROMOTE_PROFILE } from '../../../route-names';
import { ENV } from '../../../config';

export const PRO_SEARCH_BOOST_FLAG = 'pro_searchboost_fly2270_20200831';
export const PRO_SEARCH_BOOST_DAYS_BOOSTED = 7;
export const PRO_SEARCH_BOOST_DAYS_BOOST_ELEGIBLE = 14;
export const PRO_SEARCH_BOOST_LIVE_SYNC_DELAY = 30;
export const PRO_SEARCH_BOOST_ANIMATION_VIEWED = 'searchBoostAnimationViewed';
export const PRO_SEARCH_BOOST_MODES = {
  preEarning: 'pre_earning', // not yet p1c complete
  earning: 'earning', // p1c complete but 6 appts threshold not yet reached
  active: 'active', // p1c complete and 6+ appts booked, actively in search boost
  extending: 'extending', // p1c complete, less than 6 appts booked, after day 14
};
export const PRO_SEARCH_BOOST_CTAS = {
  shareProfile: {
    text: 'Share Profile',
    tracking: 'share_clicked',
    route: PRO_PROMOTE_PROFILE,
  },
  addAppointments: {
    text: 'Add Appointments',
    tracking: 'add_appointment_clicked',
    route: CALENDAR_ROUTE,
  },
};
export const UNIT_OF_TIME = ENV === 'production' ? 'days' : 'minutes';
