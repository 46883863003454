export enum MARKETPLACE_PROFILE_UI {
  NOT_ELIGIBLE = 'not-eligible',
  SERVICE_TILES_MOBILE = 'service-tiles-mobile',
  PRO_PHOTOS_MOBILE = 'pro-photos-mobile',
  EMBEDDED_SEARCH_MOBILE = 'embedded-search-mobile',
  SERVICE_TILES_DESKTOP = 'service-tiles-desktop',
  PHOTO_BROWSING_MOBILE = 'photo-browsing-mobile',
}
export type MarketplaceProfileServiceListCount = 0 | 2 | 4 | 6;
export type MarketplaceProfileShouldRender = boolean;

export type MarketplaceProfileModelState = {
  ui?: MARKETPLACE_PROFILE_UI;
  serviceListCount?: MarketplaceProfileServiceListCount;
  shouldRender: MarketplaceProfileShouldRender;
};

export type MarketplaceProfileModelUI = {
  ui?: MARKETPLACE_PROFILE_UI;
  serviceListCount?: MarketplaceProfileServiceListCount;
  shouldRender?: MarketplaceProfileShouldRender;
};
