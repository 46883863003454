import type { TapToPayGeolocationAuthorizationStatus, SCPReaderDisplayMessage } from '../../modules/TapToPay';

export enum ConnectionStatus {
  NotConnected,
  Connected,
  InProgress,
}

export enum ReaderDisplayEvent {
  RetryCard = 'retry_card',
  MultipleContactlessCardsDetected = 'multiple_contactless_cards_detected',
  TryAnotherReadMethod = 'try_another_read_method',
  TryAnotherCard = 'try_another_card',
}

/* eslint-disable max-classes-per-file */
export type StripeTerminalState = {
  pluginLoaded: boolean;
  connectionStatus: ConnectionStatus;
  softwareUpdateProgress: number;
  shouldAutoReconnect: boolean;
  geolocationAuthorizationStatus: TapToPayGeolocationAuthorizationStatus;
  latestReaderDisplayMessage: SCPReaderDisplayMessage | null;
};

/** PaymentIntent type
 * Placeholder, replace with the actual typing from Stripe once we find it */
export type PaymentIntent = {
  id: string;
  client_secret: string;
  [key: string]: any;
};
