import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const DiscountEnrollPageViewed: SegmentEventData = {
  eventName: AnalyticEvent.DiscountCodeEnrolled,
  eventNameLegacy: 'discount_enroll',
  eventProperties: [],
};

export default DiscountEnrollPageViewed;
