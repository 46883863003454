/**
 * Exports a promise which resolves when the redux store has
 * loaded all persistent state information and is ready to be used.
 *
 * This can be used to delay app bootstrapping functionality until this data has loaded.
 * Without this, parts of the app will not get the user data and will send API requests
 * as an anonymous user, which can lead to inconsistent behavior in the app.
 */
export const storeReadyResolver = {};
const ready = new Promise(resolve => {
  storeReadyResolver.resolve = resolve;
});

export default ready;

/**
 * Safety timeout, just in case something breaks and never resolves the ready state.
 * Context: If this times out, it means the persistent redux storage hasn't loaded yet
 * which means the app might not be getting the cached user data.
 */
const TIMEOUT_MS = 7000; // 7 seconds because the iPhone6 is slooooow.
const storeReadyTimeout = setTimeout(() => {
  console.warn('Store ready timeout! This can lead to inconsistent behavior in the app!');
  storeReadyResolver.resolve();
}, TIMEOUT_MS);

ready.then(() => {
  clearTimeout(storeReadyTimeout);
});
