// @ts-strict-ignore
import { useState } from 'react';
import useAsyncEffect from '../../../packages/hooks/src/useAsyncEffect';
import nonCriticalException from '../modules/exceptionLogger';
import { getRecommendationsData } from '../modules/getRecommendationsData';

export const useS3Data = <T>(
  namespace: string, path: string | number, deps: any[],
): readonly [boolean, T] => {
  const [response, setResponse] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useAsyncEffect(async () => {
    setLoading(true);
    if (!namespace || !path) return;

    try {
      setResponse(await getRecommendationsData<T>(namespace, path));
    } catch (error) {
      nonCriticalException(error);
    }

    setLoading(false);
  }, deps);

  return [
    loading,
    response,
  ] as const;
};

export default useS3Data;
