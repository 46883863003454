export const CLIENT_BOOKING_DISPLAY_TRUST_TEXT_TEST = 'display_trust_text_block_20191112';
export const CLIENT_BOOKING_DISPLAY_TRUST_TEXT_TEST_PERCENT = 0.5;
export const CLIENT_BOOKING_SMART_PRICE_TREATMENT_ON_QUICK_AVAIL_TEST_KEY = 'client_booking_smart_pricing_on_quick_avail_fly-1941_20200617';
export const CLIENT_BOOKING_SMART_PRICE_TREATMENT_ON_QUICK_AVAIL_TEST_PERCENT = 0.5;
export const CLIENT_BOOKING_URGENCY_FLAG = 'client_proprofile_urgency_08082020';
export const CLIENT_BOOKING_NCD_NO_SMART_PRICE_TEST_KEY = 'client_booking_ncd_no_smart_price_20220119_atl4064';
export const CLIENT_BOOKING_NCD_NO_SMART_PRICE_TEST_PERCENT = 0.5;
export const CLIENT_BOOKING_OPT_IN_AUTO_CHECKOUT_TEST_KEY = 'client_booking_opt_in_auto_checkout_20220722_res62';
export const CLIENT_BOOKING_OPT_IN_AUTO_CHECKOUT_TEST_PERCENT = 0.5;
export const COM_746_TEST_NAME = 'client_booking_reduced_availability_time_pills_com_746_20220222';
export const COM_746_TEST_PERCENT = 0.5;
export const GUEST_BOOKING_URGENCY_TEXT = 'StyleSeat will hold this appointment for ';
export const CLIENT_BOOKING_URGENCY_TEXT = 'minutes remaining to snag your spot!';
export const CLIENT_BOOKING_2TO7DAYS_TEST_NAME = 'client_booking_2to7dayauth_res118_20220920';
export const CLIENT_BOOKING_2TO7DAYS_TEST_PERCENT = 0.5;
export const CLIENT_BOOKING_48HRAUTH_UPDATE_CARD_PROMPT_TEST_NAME = 'client_booking_48hrauth_update_card_prompt_res119_20221028';
export const CLIENT_BOOKING_48HRAUTH_UPDATE_CARD_PROMPT_TEST_PERCENT = 0.5;
