import { createContext } from 'react';
import { Notification } from '../../../../api/Providers/Notifications';

export interface NotificationContextValue {
  notifications: Notification[];
  unreadCount: number;
}

export const NotificationContext = createContext<NotificationContextValue>({
  notifications: [],
  unreadCount: 0,
});
