/**
 * Related Salons
 * Integrated into StyleSeat Blog, Definition Pages, Inactive salon Profiles
 */

import moment from 'moment';
import { createSearch } from './Search';
import { getGeoIP } from '../DeviceLocation';
import { generateTestFlags } from '../../../store/ABTest/ABTest.service';
import { SEARCH_TEST_CONFIG } from './Constants';
import { geocodeFromAddress } from '../../maps/geocode';

const DEFAULT_SALON_COUNT = 6;
const SEARCH_SOURCE = 'related_salons';

const professionToService = {
  'Hair Stylist': 'Haircut',
  'Makeup Artist': 'Makeup',
  Barber: 'Barber',
  Professional: 'Haircut',
  Colorist: 'Color',
  Stylist: 'Haircut',
  Esthetician: 'Skin Care',
  'Massage Therapist': 'Massage',
  Manicurist: 'Nails',
  Cosmetologist: 'Makeup',
  'Nail Technician': 'Nails',
  Salon: 'Beauty Salon',
};

const RelatedSalons = {
  /**
   * Convert location string to lat+lng. If locationString is not provided,
   * try to get current location from the browser, or fall back to IP-based location.
   *
   * @param locationString - e.g. 'san-francisco-ca'.
   *  This parameter is optional - if locationString is an empty string or null,
   *  we will try to detect current location using the device or IP.
   *
   * @yields {Object} An object containing {latitude, longitude}
   */
  getLocation(locationString) {
    if (!locationString) {
      return getGeoIP();
    }

    return geocodeFromAddress({ address: locationString.replace('-', '+') });
  },

  /**
   * Search for salons by service name and location.
   *
   * @param serviceName - e.g. 'haircut' or 'nail-art'
   * @param locationString - e.g. 'san-francisco-ca'
   * @yields {Search} A search object
   */
  async getRelatedSalons(serviceName, locationString, { origin } = {}) {
    if (typeof serviceName !== 'string' || !serviceName) {
      return Promise.reject(new Error('Invalid argument: service name must be a non-empty string'));
    }

    const {
      testFlags,
    } = await generateTestFlags(SEARCH_TEST_CONFIG);

    return RelatedSalons
      .getLocation(locationString)
      .then(locationData => (
        createSearch({
          config: {
            from: 0,
            size: DEFAULT_SALON_COUNT,
            lat: locationData.latitude,
            lon: locationData.longitude,
            query: serviceName,
            date: moment().format('YYYY-MM-DD'),
          },
          testFlags,
          meta: {
            source: SEARCH_SOURCE,
            origin,
            trackingEventName: 'client_proprofile_fetched_relatedsalons',
            cookie_id: '',
          },
        })
          .catch(err => {
            throw new Error(`Search error: ${err}`);
          })
      ));
  },

  getRelatedServiceForProfession(profession) {
    return professionToService[profession] || 'Haircut';
  },

  async getRelatedSalonsForPro(provider, options) {
    const service = RelatedSalons.getRelatedServiceForProfession(provider.profession);
    const { results } = await RelatedSalons.getRelatedSalons(service, provider.zipcode, options);
    return results;
  },
};

export default RelatedSalons;
