import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';

type DeleteAppointmentResponse = {
  code: number;
  status: 'OK';
};

export function deleteAppointment(
  appointmentId: number,
  opts?: {
    charge?: boolean;
    ncd_cancellation_survey_reason?: string;
  },
): Promise<DeleteAppointmentResponse> {
  const url = new URL(
    `/api/v1/checkout/appointment/${appointmentId}/mark-deleted`,
    API_ROOT,
  );
  return ssFetchJSON(url.toString(), {
    method: 'POST',
    body: opts,
    includeResponseOnError: true,
  });
}
