import promiseBreaker from './promiseBreaker';
/**
 * Class that assists with cleanup of asynchronous callbacks when those callbacks
 * should no longer fire (such as when a React component has been unmounted).
 *
 * Can be used with promises, Rx.JS observables, timeouts, and DOM event handlers.
 *
 * @example
 *   import Janitor from 'modules/Janitor';
 *
 *   class MyComponent extends React.PureComponent {
 *     janitor = new Janitor();
 *
 *     componentDidMount() {
 *       // delay a task
 *       this.janitor.setTimeout(this.timeoutCallback, 100);
 *
 *       // fetch some stuff
 *       this.janitor.addPromise(fetchStuff())
 *         .then(this.doStuff);
 *
 *       // add a subscription to a cool Rx.JS observable stream
 *       this.janitor.addStream(coolStream)
 *         .subscribe(this.doCoolThings);
 *
 *       // add a handler for the 'scroll' event on the document
 *       this.janitor.addDOMEvent(document, 'scroll', this.handleScroll);
 *     }
 *
 *     componentWillUnmount() {
 *       // Clears timeouts, cancels promises, disposes subscriptions, unbinds event handlers
 *       // that were created in componentDidMount()
 *       this.janitor.cleanup();
 *     }
 *   }
 *
 */
export default class Janitor {
  promises = new Set();
  timeouts = new Set();
  cleanups = [];

  /**
   * Set a timeout, track it for clearing on cleanup, and attach handlers to untrack it on cleanup.
   *
   * @param {Function} callback - the function to timeout with
   * @param {Number} timeoutMs - number of milliseconds
   * @returns {Number} timeout - the timeout ID
   */
  setTimeout(callback, timeoutMs) {
    const timeout = setTimeout(() => {
      try {
        callback();
      } finally {
        this.removeTimeout(timeout);
      }
    }, timeoutMs);
    this.addTimeout(timeout);
    return timeout;
  }

  /**
   * Clear a timeout and untrack it.
   *
   * @param {Number} timeout - the timeout ID
   */
  clearTimeout(timeout) {
    clearTimeout(timeout);
    this.removeTimeout(timeout);
  }

  /**
   * Stop tracking a timeout for clearing on cleanup.
   *
   * @param {Number} timeout - the timeout ID
   */
  removeTimeout(timeout) {
    this.timeouts.delete(timeout);
  }

  /**
   * Track a timeout for clearing on cleanup.
   *
   * @param {Number | Timeout} timeout - the timeout ID
   * @returns {Number} timeout - the timeout ID
   */
  addTimeout(timeout) {
    this.timeouts.add(timeout);
    return timeout;
  }

  /**
   * Track a promise for cancellation on cleanup.
   *
   * @param {Promise} promise - the promise to cancel on cleanup
   * @returns {Promise}
   */
  addPromise(promise) {
    const breakable = promiseBreaker(promise);
    this.promises.add(breakable);
    return breakable;
  }

  /**
   * Attach an event handler to an element that will be removed on cleanup.
   *
   * @param {EventTarget} target - the DOM node or other event-attachable target
   * @param {String} type - the event type
   * @param {Function} callback - the event handler function
   * @param args - See docs for addEventListener
   */
  addDOMEvent(target, type, callback, ...args) {
    target.addEventListener(type, callback, ...args);
    // on destroy, remove the event listener
    this.cleanups.push(() => {
      target.removeEventListener(type, callback, ...args);
    });
  }

  /**
   * Attach a callback function to be called on cleanup.
   * @param {Function} callback - function to call on cleanup.
   */
  addCleanup(callback) {
    if (typeof callback === 'function') {
      this.cleanups.push(callback);
    }
  }

  /**
   * Clears timeouts, cancels promises, disposes subscriptions, unbinds event handlers.
   */
  cleanup = () => {
    new Set(this.timeouts).forEach(timeout => {
      this.clearTimeout(timeout);
    });
    new Set(this.promises).forEach(promise => {
      promise.cancel();
      this.promises.delete(promise);
    });
    this.cleanups.forEach(cleanup => cleanup());
    return this;
  };
}
