import { API_ROOT } from '../../../config';
import SimpleCache from '../../../modules/SimpleCache';
import ssFetch, { ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';

/**
 * Data as returned from the ServiceClassificationSerializer serializer
 */
export type ProviderStandardService = {
  id: number;
  category_id: number;
  name: string;
};

/**
 * Data as returned from the ServiceSerializerV2RUD serializer
 */
export type ProviderService = {
  blurb: string;
  blurb_preference: number;
  cost: string;
  cost_and_up: boolean;
  cost_cents: number;
  creation_time: string;
  duration_minutes: number;
  finishing_time_minutes: number;
  id: number;
  is_online_bookable: boolean;
  is_package?: boolean;
  is_private?: boolean;
  name: string;
  padding_minutes?: number;
  popularity_percentage?: number;
  overall_popularity_percentage?: number;
  processing_time_minutes: number;
  require_deposit: boolean;
  servicegroup: number;
  servicegroup_name: string;
  std_services: ProviderStandardService[];
  total_duration_minutes?: number;
  service_images?: string[];

  // ATL-4045
  num_reviews?: number;
  num_photos?: number;

  // for tracking in ServiceListContainer
  isBlurbDefaulted?: boolean;
  // For ABTest COM-1252
  isHighlight?: boolean;

  // Added property to check if comes from BE
  saved?: boolean;

  // COM-1566 - AddOns
  is_add_on?: boolean;
  add_on_time_preference?: 'BEFORE' | 'AFTER';
};

/** Adds write-only property from BaseServiceSerializer */
export type ProviderServiceWriteOnly = ProviderService & {
  std_service_ids: number[];
  std_service_id?: number;
  convert_to_add_on?: boolean;
};

export const ServicesCache = new SimpleCache(10);

export function clearApiCache() {
  ServicesCache.clear();
}

export const getServiceById = async (
  providerId: number,
  serviceId: number,
): Promise<ProviderService> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/services/${serviceId}`);

  return ssFetchJSON(
    url.toString(),
    { ssCache: ServicesCache },
  );
};

type GetAllServicesParams = {
  providerId: number;
  size: 'all' | number;
  includeAddOns?: boolean;
};

export const getAllServices = ({
  providerId, size, includeAddOns = false,
}: GetAllServicesParams) => {
  const url = new URL(`/api/v2/providers/${providerId}/services`, API_ROOT);

  if (size) {
    url.searchParams.append('size', size.toString());
  }
  if (includeAddOns) {
    url.searchParams.append('include_add_ons', 'true');
  }

  return ssFetchJSON<IDRFResponse<ProviderService>>(
    url.toString(),
  );
};

export const editService = (
  providerId: number | string,
  service: Partial<ProviderService>,
) => {
  const url = new URL(`/api/v2.1/providers/${providerId}/services/${service.id}`, API_ROOT);
  clearApiCache();
  return ssFetch<ProviderService>(
    url.toString(),
    {
      method: 'PATCH',
      body: service,
    },
  );
};

/**
 * @deprecated Use createServiceV2 instead
 */
export const createService = (
  providerId: number | string,
  service: Partial<ProviderService>,
) => {
  const url = new URL(`/api/v2/providers/${providerId}/services`, API_ROOT);

  return ssFetch<ProviderService>(
    url.toString(),
    {
      method: 'POST',
      body: service,
    },
  );
};

export const createServiceV2 = (
  providerId: number | string,
  services: Partial<ProviderServiceWriteOnly>[],
) => {
  const url = new URL(`/api/v2.1/providers/${providerId}/services`, API_ROOT);

  return ssFetchJSON<ProviderService[]>(
    url.toString(),
    {
      method: 'POST',
      body: services,
    },
  );
};

export const deleteService = (
  providerId: number | string,
  serviceId: number,
) => {
  const url = new URL(`/api/v2.1/providers/${providerId}/services/${serviceId}`, API_ROOT);

  return ssFetch(
    url.toString(),
    {
      method: 'DELETE',
    },
  );
};
