import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';

const DiscountCodeRedeemed: SegmentEventData = {
  eventName: AnalyticEvent.DiscountCodeRedeemed,
  eventNameLegacy: 'discount_code_redeemed',
  eventProperties: [
    'email',
    'first_name',
    'last_name',
    'discount_code',
    'source',
  ],
};

export default DiscountCodeRedeemed;
