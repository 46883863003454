// @ts-strict-ignore
import BaseTracker, { PlatformProperty } from './BaseTracker';

/**
 * Sends events to Google Analytics via Google Tag Manager dataLayer
 */
class GoogleAnalyticsTracker extends BaseTracker {
  getPageViewParams: () => Record<string, any>;

  constructor(
    env: string,
    platform: PlatformProperty,
    getPageViewParams: () => Record<string, any>,
  ) {
    super('ga', 'Tracker (GA)', env, platform);

    if (this.platform === 'web') {
      const anyWindow = window as any;
      anyWindow.dataLayer = anyWindow.dataLayer || [];
    }

    this.getPageViewParams = getPageViewParams;
  }

  /**
   * Track a virtual page view
   * @param {String} path Optional path to append to '/mobileweb'
   */
  trackPageView(path?: string) {
    if (this.platform !== 'web') {
      return;
    }

    const anyWindow = window as any;
    let gaPath;
    if (path) {
      const params = this.getPageViewParams();
      let qs = '';
      Object.keys(params).forEach(param => {
        qs += `${(qs ? '&' : '?') + param}=${params[param]}`;
      });
      gaPath = `/mobileweb${path}${qs}`;
    } else {
      gaPath = window.location.href;
    }

    // Google Tag Manager docs recommend quoting the keys
    // eslint-disable-next-line quote-props
    anyWindow.dataLayer.push({ 'event': 'pageview', 'pagePath': gaPath });
    this.debugLog('trackPageView', gaPath);
  }

  /**
   * Performs the actual tracking call.
   * @param {String} eventName The name of the event to track
   * @param {Object} properties The properties to track
   * @returns {Promise} Resolves when the event has been logged.
   */
  trackEvent(eventName, properties) {
    if (this.platform !== 'web') {
      return;
    }

    const anyWindow = window as any;
    const gtmWhitelist = anyWindow.fbGaTrackerEventWhitelist || [];
    // if the event to track is in the Google Tag Management whitelist
    if (gtmWhitelist.includes(eventName)) {
      // Google Tag Manager docs recommend quoting the keys
      /* eslint-disable quote-props, @typescript-eslint/dot-notation */
      const event = {
        'event': eventName,
        'transactionId': properties.event_id,
      };
      if (properties.eventName) {
        event['eventName'] = properties.eventName;
      }
      if (properties.eventCategory) {
        event['category'] = properties.eventCategory;
      }
      if (properties.eventLabel !== undefined) {
        event['label'] = properties.eventLabel;
      }
      if (properties.eventValue !== undefined) {
        event['value'] = properties.eventValue;
      }

      anyWindow.dataLayer.push(event);
      this.debugLog('trackEvent', eventName, properties);
    }
  }

  trackLegacyEvent(
    eventCategory: string,
    eventName: string,
    eventLabel?: string,
    eventValue?: number,
  ) {
    this.trackEvent('legacyGAEvent', {
      eventName,
      eventCategory,
      eventLabel,
      eventValue,
    });
  }
}

export default GoogleAnalyticsTracker;
