import React from 'react';
import { StyleSheet } from 'react-native';
import {
  Box,
  IBoxProps,
  Inline,
  InlineProps,
} from '@styleseat/ui';
import { BrandColor, FeatureTheme } from '@styleseat/brand';
import type { DeprecatedIconClass, SnackbarState } from '../shared/SnackbarContext';
import PortalOverlayModal from './PortalOverlay/PortalOverlayModal';
import PortalOverlay from './PortalOverlay/PortalOverlay';
import {
  BasicCircleCheckedIcon,
  NotificationsAlertCircleIcon,
  NotificationsInfoIcon,
} from './Icons';
import Stack from './Stack';
import BodyText from './BodyText';
import Button from './Button';
import { FinanceAutoCheckoutIcon } from './Icons/FinanceAutoCheckoutIcon';
import { HeaderText } from './HeaderText';
import { Z_TOP_OF_THE_WORLD } from './utilities/zIndex';

const SNACKBAR_BORDER_RADIUS = 4;

const mapDeprecatedIcon = (iconClass: DeprecatedIconClass) => {
  switch (iconClass) {
    case 'info-icon':
      return (
        <NotificationsInfoIcon color={BrandColor.DarkBlue} />
      );
    case 'error-icon':
      return (
        <NotificationsAlertCircleIcon color={BrandColor.Red} />
      );
    case 'success-icon':
      return (
        <BasicCircleCheckedIcon color={BrandColor.Green} />
      );
    case 'auto-checkout-icon':
      return <FinanceAutoCheckoutIcon color={FeatureTheme.AutopayIconColor} />;
    case 'sparkles-icon':
      return <BodyText appearance="large">✨</BodyText>;
    default:
      return null;
  }
};

export type SnackbarProps = Partial<Omit<SnackbarState, 'show'>> & {
  onClose: () => void;
};

/**
 * This component is responsible for rendering a snackbar
 * You should probably not be rendering this component directly. Instead,
 * you can dispatch props for this component to the Snackbar.model.ts rematch store
 *
 * i.e.
 * snackbar.open({
 *   header: 'Snackbar title',
 *   inlineHighlight: 'An important part of the description',
 *   subHeader: 'and then a less important ending',
 *   sideColor: BrandColor.green,
 *   sideContent: <Box><BasicCircleCheckIcon color={BrandColor.green} />,
 * });
 */
export const Snackbar: React.FCWithChildren<SnackbarProps> = ({
  subHeader,
  inlineHighlight,
  header,
  confirm,
  confirmWidget,
  dismiss,
  removeFormatting,
  sideColor,
  sideContent,
  mainContent,
  icon,
  onClose,
}) => {
  /**
   * Callback when confirm button is clicked
   */
  const handleConfirmClick = () => {
    confirm?.action?.();
    onClose();
  };

  /**
     * Callback when dismiss button is clicked
     */
  const handleDismissClick = () => {
    dismiss?.action?.();
    onClose();
  };

  const containerProps: IBoxProps = removeFormatting ? {} : {
    borderRadius: SNACKBAR_BORDER_RADIUS,
    borderWidth: StyleSheet.hairlineWidth,
    borderLeftWidth: sideColor === BrandColor.WhiteWash ? StyleSheet.hairlineWidth : 0,
    borderStyle: 'solid',
    borderColor: BrandColor.Silver,
    showBoxShadow: true,
  };

  const layoutProps: InlineProps = removeFormatting ? {} : {
    borderRadius: SNACKBAR_BORDER_RADIUS,
    borderLeftWidth: 4,
    borderColor: sideColor,
    borderStyle: 'solid',
    padding: 16,
    space: 16,
  };

  return (
    <PortalOverlay
      name="snackbar"
      hideBackdrop
      zIndex={Z_TOP_OF_THE_WORLD}
    >
      <PortalOverlayModal
        position="snackbar"
        testID="react-snackbar-component"
        boxProps={containerProps}
      >
        <Inline {...layoutProps}>
          {sideContent}
          {!sideContent && icon ? (
            mapDeprecatedIcon(icon)
          ) : null}
          <Box flex={1}>
            {mainContent || (
              <Stack space={8}>
                {header ? (
                  <HeaderText level={4} testID="Snackbar--header">
                    {header}
                  </HeaderText>
                ) : null}
                {inlineHighlight || subHeader ? (
                  <BodyText color={BrandColor.Metal} appearance="medium" testID="Snackbar--description">
                    {inlineHighlight ? (
                      <BodyText weight="bold" color={BrandColor.Metal} appearance="medium">
                        {inlineHighlight}{' '}
                      </BodyText>
                    ) : null}
                    {subHeader}
                  </BodyText>
                ) : null}
                {dismiss?.text || confirm?.text || confirmWidget ? (
                  <Inline justifyContent="flex-end" space={12}>
                    {dismiss?.text ? (
                      <Button
                        onClick={handleDismissClick}
                        appearance="text-light"
                        size="small"
                        testID="Snackbar--dismiss"
                      >
                        {dismiss.text}
                      </Button>
                    ) : null}
                    {confirm?.text ? (
                      <Button
                        onClick={handleConfirmClick}
                        appearance="primary-neue"
                        size="small"
                        testID="Snackbar--confirm"
                      >
                        {confirm.text}
                      </Button>
                    ) : null}
                    {confirmWidget}
                  </Inline>
                ) : null}
              </Stack>
            )}
          </Box>
        </Inline>
      </PortalOverlayModal>
    </PortalOverlay>
  );
};
