// @ts-strict-ignore
import moment, { Moment } from 'moment';
import URI from 'urijs';

import { PaymentMethodType } from '../../../components/consumer/booking/BookingFlow/types';
import SimpleCache from '../../../modules/SimpleCache';
import ssFetch, { SSFetchOptions, ssFetchJSON } from '../../../modules/ssFetch';
import { IDRFResponse } from '../../../types';
import { DateTimeStamp } from '../../../types/dateTime';
import type {
  DepositStatus,
  DiscountCodeRedemption,
  TimeBlock,
} from '../../Providers/Appointments';
import type { PromotionDiscountType } from '../../Providers/Promotions';

const appointmentRequestCache = new SimpleCache(600 /* 10 minutes */);
export const DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss';

export type ProPromotion = {
  id: number;
  discount_type: PromotionDiscountType;
  discount_quantity: string;
};

export type ProPromotionDiscount = {
  id: number;
  name: string;
  new_cost: number;
  old_cost: number;
  pro_promotion: ProPromotion | number;
  service: number;
  timeblock: number;
};

export enum AutochargeStatus {
  NotEPEligible = 10,
  EPAuto = 20,
  NotEP = 21,
  EPManual = 22,
}

export enum PrepayStatus {
  PrepaySuccessful = 1,
  PrepayFailed = 2,
  PrepayRefunded = 3,
  PrepayTransferred = 4,
}

export enum CheckoutStatus {
  NoCheckoutAttempted = 0,
  AttemptMade = 1,
  Completed = 2,
  NoShow = 3,
  Uncompleted = 4,
  UnNoShow = 5,
  Cancelled = 6,
  CompletedWTip = 7,
}

export enum BookingMethod {
  BookedInternal = 1,
  BookedImported = 2,
  BookedProMobile = 3,
  BookedSignupFlow = 4,
  BookedSample = 5,
  BookedOnProfile = 50,
  BookedOnFacebook = 51,
  BookedOnMobileProfile = 52,
  BookedOnSLProfile = 60,
  BookedOnMobileSLProfile = 62,
  RecurringAppt = 100,
  MobileRecurringAppt = 101,
  ReceivedICalEvent = 126,
  ReceivedGoogleEvent = 127,
  PremiumAppt = 128,
  PremiumApptV2 = 129,
  ScheduleGap = 130,
}

export enum AppointmentTypes {
  ClientAppointment = 1,
  PersonalTime = 2,
  BookingInProgress = 3,
}
/**
 * Matches UserAppointmentReadSerializer
 * GET: api/v2/users/{userId}/appointments
 * GET: api/v2/users/{userId}/appointments/{appointmentId}
 */
export type UserAppointmentResponse = {
  autocharge_status: AutochargeStatus;
  booked_by: number;
  booking_fee_charged: number;
  booking_method: BookingMethod;
  can_change_payment_type: boolean;
  cancellation_charge: string | null;
  cancellation_charge_remaining: string; // decimal
  cancellation_time: string | null;
  checkout_status: CheckoutStatus;
  client: number;
  client_note: string;
  cost: string;
  creation_time: string;
  deletion_time: string | null;
  discount_code_redemption: DiscountCodeRedemption | null;
  end: DateTimeStamp;
  followup_note: string | null;
  id: number;
  is_checked_out: boolean;
  is_client_first_booking_with_pro: boolean;
  is_client_initiated_prepaid: boolean;
  is_client_initiated_prepay_eligible: boolean;
  is_monetized_new_client_delivery: boolean;
  is_new_client_delivery: boolean;
  is_no_show: boolean;
  is_paid_new_client_delivery: boolean;
  is_prepaid_new_client_delivery: boolean;
  is_pro_booked: boolean;
  is_rebookable: boolean;
  local_end: DateTimeStamp;
  local_start: DateTimeStamp;
  manage_appointment_token: string;
  modification_time: DateTimeStamp;
  ncd_source: string;
  noshow_charge: string | null;
  noshow_charge_remaining: string; // decimal
  note: string | null;
  paid_booking_fee: boolean;
  payment_method_type: PaymentMethodType | null;
  prepay_amount: string | null;
  prepay_status: PrepayStatus | null;
  deposit_amount: string | null;
  deposit_status: DepositStatus | null;
  pro_promotion_discounts: ProPromotionDiscount[];
  provider: number;
  service_name: string;
  start: DateTimeStamp;
  surcharge: string;
  time_blocks: TimeBlock[];
  title: string | null;
  type: AppointmentTypes;
  user: number;
};

export type TransformableAppointment = Pick<UserAppointmentResponse,
'local_start'
| 'local_end'
| 'service_name'
>;

export type TransformAppointment<T extends TransformableAppointment> = T & {
  startDate: Moment;
  endDate: Moment;
  serviceNameList: Array<string>;
};

export type UserAppointment = TransformAppointment<UserAppointmentResponse>;

export type AppointmentListParams = {
  days?: number;
  end_date?: string;
  is_cancelled?: boolean;
  ordering?: string;
  page?: number;
  provider_id?: number;
  size?: number | 'all';
  start_date?: string;
  useCache?: boolean;
};

export type UserAppointmentListResults = {
  allCount: number;
  count: number;
  results: UserAppointment[];
};

/**
 * Clears the request cache
 */
export function clearCache() {
  appointmentRequestCache.clear();
}

/**
 * Transform an appointment from the backend to one with some shortcut properties
 * @param appt The appointment data from the API
 * @returns The appointment with some helpful extra properties
 */
export function transformAppointment<T extends TransformableAppointment>(
  appt: T,
): TransformAppointment<T> {
  const startDate = moment(appt.local_start, DATE_FORMAT);
  const endDate = moment(appt.local_end, DATE_FORMAT);
  const serviceNameList = appt.service_name.split(/,\s+/);

  return {
    ...appt,
    startDate,
    endDate,
    serviceNameList,
  };
}

/**
 * Gets a single appointment instance
 * @param userId The user ID owning the appointment
 * @param appointmentId The ID of the appointment
 * @returns A promise resolving with the appointment
 */
function getUserAppointment(
  userId: number,
  appointmentId: number,
): Promise<UserAppointmentResponse> {
  const url = URI(`/api/v2/users/${userId}/appointments/${appointmentId}`);

  return ssFetchJSON<UserAppointmentResponse>(url.toString(), { ssCache: appointmentRequestCache });
}

/**
 * Gets a single appointment instance, transformed with additional data
 * @param userId The user ID owning the appointment
 * @param appointmentId The ID of the appointment
 * @returns A promise resolving with the appointment
 */
export function loadUserAppointment(
  userId: number,
  appointmentId: number,
): Promise<UserAppointment> {
  return getUserAppointment(userId, appointmentId).then(transformAppointment);
}

/**
 * Process the appointment objects after loading from the server.
 *
 *   + Create start/end moment objects (`startDate`, `endDate`)
 *   + Split services names into a list (`serviceNameList`)
 *   + Remove appointments that end before they start.
 */
function transformListResponse(
  json: IDRFResponse<UserAppointmentResponse>,
): UserAppointmentListResults {
  const {
    results,
    ...restResponse
  } = json;
  const transformedResponse: UserAppointmentListResults = {
    ...restResponse,
    allCount: json.count,
    results: [],
  };
  if (results) {
    transformedResponse.results = results
      .map(transformAppointment)
      .filter(appt => appt.endDate.isSameOrAfter(appt.startDate));
  }
  return transformedResponse;
}

function getUserAppointmentList(
  userId: number,
  params: AppointmentListParams,
): Promise<IDRFResponse<UserAppointmentResponse>> {
  const url = URI(`/api/v2/users/${userId}/appointments`);
  const { useCache, ...queryParams } = params;
  const requestOpts: SSFetchOptions = useCache ? { ssCache: appointmentRequestCache } : undefined;

  url.addQuery(queryParams);

  return ssFetchJSON(url.toString(), requestOpts);
}

export function loadUserAppointmentList(
  userId: number,
  params: AppointmentListParams,
): Promise<UserAppointmentListResults> {
  return getUserAppointmentList(userId, params).then(transformListResponse);
}

/**
 * Cancels an appointment. The backend determines what amount may be charged for this cancellation.
 *
 * @param userId The user ID owning the appointment
 * @param appointmentId The ID of the appointment
 * @returns A promise resolving when the appointment is cancelled
 */
export function cancelAppointment(
  userId: number,
  appointmentId: number,
): Promise<any> {
  const url = URI(`/api/v2/users/${userId}/appointments/${appointmentId}`);

  return ssFetch(url.toString(), { method: 'DELETE' });
}

/**
 * Loads appointments ending between a day ago and 2 years ago
 * @param {int} userId The user ID for which to load appointments
 * @param {Object} params A hash of options to add to the query
 * @param {int} [params.size=25] The number of results to return in each response
 * @param {int} [params.page=0] The page to return in the response
 * @param {String} [params.start_date] The date to begin searching. Defaults to the beginning of the
 * year two years ago
 * @param {String} [params.end_date] The date to end searching. Defaults to the beginning of today.
 * @param {int} [params.provider_id] The ID of the provider with which the appointment was booked
 * @yields {Object} An object with { allCount, results }, where `results` is an array of
 * appointments.
 */
export function loadPreviousAppointments(
  userId: number,
  params: AppointmentListParams,
): Promise<UserAppointmentListResults> {
  return loadUserAppointmentList(userId, {
    page: 1,
    size: 25,
    start_date: moment().subtract(2, 'year').startOf('year').toISOString(),
    end_date: moment().startOf('day').toISOString(),
    ...params,
  });
}

/**
  Keep only upcoming appointment.
  This must be run AFTER transformResponse

  This also adds an `allCount` property that specifies
  the total number of past & future appointments the user has.
*/
function filterForUpcoming(json: UserAppointmentListResults): UserAppointmentListResults {
  const now = moment();
  const data = json;
  if (data.results) {
    data.allCount = data.count;
    data.results = data.results.filter(appointment => appointment.endDate >= now);
    data.count = data.results.length;
  }
  return data;
}

/**
 * Loads appointments happening in the future
 * @param {int} userId The user ID for which to load appointments
 * @param {Object} params A hash of options to add to the query
 * @yields {Object} An object with { allCount, results, count }, where
 *    `results` is an array of appointments ordered by soonest to farthest out
 *    `count` is the size of `results`
 *    `allCount` is the total number of appointments
 */
export function loadUpcomingAppointments(
  userId: number,
  params: AppointmentListParams,
): Promise<UserAppointmentListResults> {
  return loadUserAppointmentList(
    userId,
    {
      start_date: moment().toISOString(),
      is_cancelled: false,
      ordering: 'start',
      ...params,
    },
  )
    .then(filterForUpcoming);
}
