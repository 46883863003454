import React from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../store/models';
import { getLocation, LocationInfo } from '../store/Search.model';

export default function useLocation(): LocationInfo | null {
  const [location, setLocation] = React.useState<LocationInfo | null>(null);
  const rootState = useSelector((state: RootState) => state);

  React.useEffect(() => {
    getLocation({}, rootState).then((loc: LocationInfo) => setLocation(loc));
  }, [
    setLocation,
    rootState,
  ]);

  return location;
}
