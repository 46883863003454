// @ts-strict-ignore
import { isEqual } from 'underscore';
import { createModel } from '@rematch/core';

import {
  ABTestStatus,
  assignAllTests,
  payloadToAssignment,
  assignABTest,
  checkTestStatus,
  forceABTestAllocation,
} from './ABTest.service';
import type { RootModel } from '../models';
import type {
  Assignment,
  AssignmentPayload,
  AssignTestRequest,
  FlagList,
} from './ABTest.types';

import FeatureFlags from '../../modules/FeatureFlags';
import Deferred from '../../testUtilities/deferred';
import nonCriticalException from '../../modules/exceptionLogger';

export type Assignments = {
  [key: string]: Assignment;
};

type State = {
  isLoaded: boolean;
  oldFlags: FlagList | undefined;
  assignments: Assignments;
};

const defaultState = (): State => ({
  isLoaded: false,
  oldFlags: undefined,
  assignments: {},
});

export const hasLoadedDeferred = new Deferred<State>();

class ABTestNotAssignedError extends Error {
  name: 'ABTestNotAssignedError';
}

const model = createModel<RootModel>()({
  name: 'abTest',

  state: defaultState(),

  reducers: {
    onUpdateComplete(state: State, {
      oldFlags,
      assignments,
    }: {
      oldFlags: FlagList;
      assignments: Assignments;
    }) {
      const updatedState: State = {
        ...state,
        oldFlags,
        assignments,
        isLoaded: true,
      };
      hasLoadedDeferred.resolve(updatedState);
      return updatedState;
    },
    onTestsAssigned(state: State, testAssignments: Record<string, Assignment>) {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          ...testAssignments,
        },
      };
    },

    onTestAssigned(state: State, payload: AssignmentPayload) {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          [payload.name]: payloadToAssignment(payload),
        },
      };
    },
  },

  effects: dispatch => ({
    updateFlags: async (flagList: FlagList, rootState) => {
      const assignments: Assignments = {};
      const { oldFlags } = rootState.abTest;

      if (!isEqual(flagList, oldFlags)) {
        Object.keys(flagList)
          .forEach(key => {
            const isEnabled = flagList[key];
            if (isEnabled) {
              const status: ABTestStatus = checkTestStatus(key);

              assignments[key] = {
                isAssigned: status.assigned,
                inTest: status.choice,
                isEnabled: true,
              };

              if (status.variant) {
                assignments[key].variationName = status.variant;
              }

              if (status.allocation) {
                assignments[key].allocationPercent = status.allocation;
              }
            } else {
              assignments[key] = {
                isEnabled,
                inTest: false,
                isAssigned: false,
              };
            }
          });

        dispatch.abTest.onUpdateComplete({
          oldFlags: flagList,
          assignments,
        });
      }
    },

    forceAssignTest: async ({
      name,
      allocation,
    }: {
      name: string;
      allocation: number | boolean;
    }): Promise<Assignment> => {
      const isEnabled = await FeatureFlags.isEnabled(name);

      if (isEnabled) {
        const status = forceABTestAllocation(
          name,
          allocation,
        );

        dispatch.abTest.onTestAssigned({
          name,
          isEnabled,
          inTest: status.choice,
          variationName: status.variant,
          allocationPercent: status.allocation,
        });

        return {
          isEnabled,
          inTest: status.choice,
          isAssigned: status.assigned,
          variationName: status.variant,
          allocationPercent: status.allocation,
        };
      }

      dispatch.abTest.onTestAssigned({
        name,
        isEnabled,
        inTest: false,
      });

      return {
        inTest: false,
        isEnabled: false,
        isAssigned: false,
      };
    },
    assignTests: async (
      assignTestRequests: AssignTestRequest[],
    ): Promise<Record<string, Assignment>> => {
      const result = await assignAllTests(assignTestRequests);

      dispatch.abTest.onTestsAssigned(result);
      return result;
    },
    assignTest: async ({
      name,
      percent,
      variations,
    }: AssignTestRequest): Promise<Assignment> => {
      const isEnabled = await FeatureFlags.isEnabled(name);

      if (isEnabled) {
        const status = assignABTest({
          testName: name,
          variations: variations || {
            control: 1 - percent,
            test: percent,
          },
        });

        dispatch.abTest.onTestAssigned({
          name,
          isEnabled,
          inTest: status.choice,
          variationName: status.variant,
          allocationPercent: status.allocation,
        });

        return {
          isEnabled,
          inTest: status.choice,
          isAssigned: status.assigned,
          variationName: status.variant,
          allocationPercent: status.allocation,
        };
      }

      dispatch.abTest.onTestAssigned({
        name,
        isEnabled,
        inTest: false,
      });

      return {
        inTest: false,
        isEnabled: false,
        isAssigned: false,
      };
    },
  }),
  selectors: (slice, createSelector, hasProps) => ({
    selectIsAssignmentEnabled: hasProps((_, props: { switchOrFlag: string }) => createSelector(
      slice(state => state),
      state => state.isLoaded && state.assignments[props.switchOrFlag]?.isEnabled,
    )),
    isEnabled: hasProps((_, testName: string) => createSelector(
      slice((state): Assignment | undefined => state.assignments?.[testName]),
      assignment => {
        if (!assignment) return false;
        return assignment.isEnabled;
      },
    )),
    inTest: hasProps((_, testName: string) => createSelector(
      slice((state): Assignment | undefined => state.assignments?.[testName]),
      assignment => {
        if (!assignment || !assignment.isEnabled) return false;

        if (!assignment.isAssigned) {
          nonCriticalException(new ABTestNotAssignedError(`${testName} is not assigned`));
        }
        return assignment.inTest;
      },
    )),
  }),
});

export default model;
