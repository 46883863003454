import { API_ROOT } from '../../../config';
import SimpleCache from '../../../modules/SimpleCache';
import ssFetch, { ssFetchJSON } from '../../../modules/ssFetch';
import type { PaymentMethodType } from '../../../components/consumer/booking/BookingFlow/types';
import { IAppointmentCancellationLink } from '../../../modules/Api.types';
import type { ISMSStatus } from '../Clients/types';
import { IDRFResponse } from '../../../types';
import { DateTimeStamp } from '../../../types/dateTime';
import type {
  AppointmentTypes,
  BookingMethod,
  ProPromotionDiscount,
} from '../../Users/Appointments';
import { AppointmentProduct } from '../Products';
import type { CompletedIntakeForm } from '../../../components/provider/IntakeForms/IntakeFormResponse/IntakeFormResponse.types';
import { NCDSource } from '../../../modules/newClientDelivery.types';
import type { TimeBlockItem } from '../../../modules/provider/Appointments';
import { IntakeFormV1 } from '../../IntakeForms/contractV1';
import type { ICard } from '../../../store/PaymentMethods';

const AppointmentCache = new SimpleCache(10);

export enum ClientPreferredCheckoutType {
  Auto = 1,
  Manual = 2,
}

export enum DiscountCodeType {
  Coupon = 1,
  AffiliateCode = 2,
  CIPNewPro = 3,
}

export enum DiscountDiscountType {
  DiscountTypeAmount = 1,
  DiscountTypePercent = 2,
}

export enum CardCheckState {
  Attempted = 'attempted',
  Successful = 'successful',
  FailedError = 'failed_error',
  CardDeclined = 'card_declined',
}

export type DiscountCode = {
  id: number;
  type: DiscountCodeType;
  code: string;
  discount_type: DiscountDiscountType | null;
  discount_amount: string | null;
  discount_percent: string | null;
  max_discount_amount: string | null;
};

export type DiscountCodeRedemption = {
  id: number;
  amount: string | null;
  discount_code: DiscountCode;
};

export type ClientCardOnFile = {
  id: number;
  exp_month: number;
  exp_year: number;
  bank: string;
  last4: string;
  source?: number;
  save_for_future?: boolean | null;
};

export type CardCheck = {
  last4: string;
  state_code: CardCheckState;
  bank: string;
};

export type NCD = {
  appointment_id: number;
  charged_appointment_id: number | null;
  state: NCDState;
  source?: string;
};

export type Client = {
  id: number;
  name: string;
  email: string;
  thumbnail: string | null;
  sms_status: ISMSStatus | null;
  ncd: NCD | null;
  preferred_pronouns: string | null;
};

export type NonCardPaymentOption = 'cash' | 'dumb_credit' | 'check' | 'gift_cert';
export type CardPaymentOption = 'stripe_connect';
export type KlarnaPaymentOption = 'klarna';
export type PaymentOption = CardPaymentOption | NonCardPaymentOption | KlarnaPaymentOption;

export type CheckoutPaymentType = {
  amount: string;
  memo: string;
  name: string;
  is_payment: boolean;
};

export type Checkout = {
  client_service_fee_exists: boolean;
  client_service_fee: string;
  cost: string;
  creation_source: string;
  creation_time: DateTimeStamp;
  payment_types: CheckoutPaymentType[] | null;
  sub_total: string;
  taxes: string;
  tip_exists: boolean;
  tip_included_in_total: boolean;
  tip: string;
  total: string;
};

/**
 * @see ProviderAppointmentTipCheckoutSerializer
 */
export interface TipCheckout {
  creation_source: string;
  creation_time: DateTimeStamp | null;
  tip: string; // Decimal
  payment_types: CheckoutPaymentType[];
  payment_instrument: Pick<ICard, 'bank' | 'last4' | 'instrument_type'> | null;
}

export interface BareBoneTimeBlock {
  id: number;
  service_name: string;
  cost: string;
}

export interface BaseTimeBlock {
  service_name: string;
  service_cost_cents: number;
  service: number;
  abbr_name: string | null;
  service_is_powerbooked: boolean;
  service_padding_time: number;
  start: DateTimeStamp;
  end: DateTimeStamp;
  local_start: DateTimeStamp;
  local_end: DateTimeStamp;
  duration_minutes: string;
}

export interface TimeBlock extends BaseTimeBlock {
  id: number;
}

export interface ProAppointmentListPromotionDiscount {
  new_cost: number;
  old_cost: number;
  service: number;
}

export enum ReminderType {
  SmsEmailReminder = 1,
  EmailReminder = 2,
  SmsOnlyReminder = 3,
}

export type Reminder = {
  appointment: number;
  confirmed_by_service_time: DateTimeStamp | null;
  creation_time: DateTimeStamp;
  id: number;
  message: string;
  rescheduled: boolean;
  submitted_to_service_time: DateTimeStamp;
  transmitted_from: string | null;
  transmitted_to: string | null;
  type: ReminderType;
};

export enum ConfirmationStatus {
  Unavailable = 1,
  Pending = 2,
  ProBookedConfirmed = 3,
  ClientBookedConfirmed = 4,
  KlarnaPending = 5,
}

export enum FeeStatusValues {
  Paid = 'paid',
  Unpaid = 'unpaid',
}

export enum FeeTypeValues {
  NewClientDelivery = 'ncd_fee',
}

export type Fee = {
  amount: number;
  percent: number;
  status: FeeStatusValues;
  type: FeeTypeValues | string;
  max: number;
};
/** Order Status
 *
 * From SSOrder model on the backend */
export enum OrderStatus {
  /** Undergoing checkout */
  Checkout = 'checkout',

  /** Waiting for payment  */
  PaymentPending = 'payment-pending',

  /** Pre paid */
  PrePaymentComplete = 'pre-payment-complete',

  /** Paid */
  PaymentComplete = 'payment-complete',

  /** Pre payment failed */
  PrePaymentFailed = 'pre-payment-failed',

  /** Payment failed */
  PaymentFailed = 'payment-failed',

  /** Cancelled  */
  Cancelled = 'cancelled',
}

/**
 * NCDState
 *
 * From NCD model on the backend
 */
export enum NCDState {
  /** All clients are eligible for NCD by default */
  Eligible = 1,

  /** Ineligible: When an eligible client books their first appointment without NCD params */
  NotChargeable = 2,

  /** When an eligible client books their first appointment with NCD params */
  Chargeable = 3,

  /** When a CHARGEABLE client has checked out an appointment and paid the NCD fee */
  Charged = 4,

  /** Ineligible: Client is a duplicate of another client (but we don't merge them) */
  Duplicate = 5,

  /** Ineligible: Provider has NCD setting disabled on their account */
  ProDisabled = 6,

  /** Ineligible: A provider verified that they know the client */
  Misattributed = 7,

  /** When there is a new client with NCD params and can be accepted
   * in a time frame of the first 24 hours  */
  Pending = 8,

  /** Ineligible: CX has disabled this client from being eligible for the NCD fee */
  ManualDisable = 9,
}

export enum OrderCreationSource {
  Autocharge = 'autocharge',
  RequestPay = 'request_pay',
  NoShow = 'no-show',
}

export enum RefundableStatus {
  Refundable = 'refundable',
  NoPayment = 'no_payment',
  AlreadyRefunded = 'already_refunded',
  OutsideRefundWindow = 'outside_refund_window',
}

export enum DepositStatus {
  Successful = 'Successful',
  Failed = 'Failed',
  Refunded = 'Refunded',
  Pending = 'Pending',
}

/**
 * ProviderNCCPendingAppointmentListItemSerializer
 * GET: api/{v2.1, v2}/providers/{providerId}/appointments/ncc-pending-clients
 */
export interface ProNCCPendingAppointment {
  client_id: number;
  client_first_name: string;
  client_last_initial: string;
  client_note: string;
  cost: string; // Formatted Decimal 00.00
  start: DateTimeStamp;
  end: DateTimeStamp;
  id: number;
  local_start: DateTimeStamp;
  local_end: DateTimeStamp;
  smart_priced: boolean;
  time_blocks: BareBoneTimeBlock[];
  accept_expiration_time_utc: DateTimeStamp;
  ncd_source: NCDSource;
  ncd_fee_total: number | string;
  estimated_earnings: number | string;
  estimated_yearly_earnings: number | string;
  pro_promotion_discounts: ProPromotionDiscount[];
}

/**
 * ProviderAppointmentListItemSerializer
 * GET: api/{v2.1, v2}/providers/{providerId}/appointments
 */
export interface ProAppointment {
  auth_amount: number | null;
  booked_by: number;
  booking_method: BookingMethod;
  can_change_payment_type: boolean;
  cancellation_charge: string | null;
  card_check: CardCheck;
  checkout: Checkout | null;
  client: Client;
  client_card_on_file: ClientCardOnFile | null;
  client_has_card: boolean;
  client_id: number;
  client_note: string;
  client_requested_checkout_type: ClientPreferredCheckoutType | null;
  confirmation_status: ConfirmationStatus;
  cost: string;
  declined_bank: string | null;
  declined_last4: string | null;
  deletion_time: DateTimeStamp | null;
  discount_code_redemption: DiscountCodeRedemption | null;
  end: DateTimeStamp;
  estimated_total: string | null; // Decimal
  fees?: Fee[];
  followup_note: string | null;
  has_payment_request: boolean;
  id: number;
  in_cancellation_charge_window: boolean;
  is_active: boolean;
  is_autocharge: boolean;
  is_autocheckout: boolean;
  is_booked_online: boolean;
  is_cancelled: boolean;
  is_checked_out_late_cancel: boolean;
  is_checked_out_noshow: boolean;
  is_expresspay: boolean;
  is_monetized_new_client_delivery: boolean;
  is_new_client_delivery: boolean;
  is_noshow: boolean;
  is_paid_new_client_delivery: boolean;
  is_premium_appointment: boolean;
  is_prepaid_new_client_delivery: boolean;
  is_refundable: boolean;
  is_user_first_booking_with_pro: boolean;
  latest_client_card_check: CardCheck | null;
  local_creation_time: DateTimeStamp;
  local_end: DateTimeStamp;
  local_start: DateTimeStamp;
  modification_time: DateTimeStamp;
  refundable_status?: RefundableStatus | null;
  noshow_charge: string | null;
  note: string | null;
  nslc_charge_declined: boolean;
  order_creation_source: OrderCreationSource | null;
  order_status: OrderStatus | null;
  paid_booking_fee: boolean;
  payment_method_type: PaymentMethodType | null;
  prepay_amount: string | null;
  prepay_status: number | null;
  deposit_amount: string | null; // Decimal
  deposit_status: DepositStatus | null;
  pro_client_notes: { field: string; value: string }[];
  pro_promotion_discounts: ProAppointmentListPromotionDiscount[];
  product_cost: string;
  products: AppointmentProduct[];
  provider: number;
  recurrence_interval: number | null;
  scheduled_via_schedule_gap: boolean;
  smart_price_service_upcharge: string | null;
  smart_priced: boolean;
  sms_eligible: boolean;
  start: DateTimeStamp;
  surcharge: string;
  time_blocks: TimeBlock[];
  title: string | null;
  type: AppointmentTypes;
  has_ncd_fee_payment_attempt: boolean | null;
  intake_form_id?: string | null;
}

/**
 * ProviderClientAppointmentGetByIdSerializer
 * GET: api/{v2.1, v2}/providers/{providerId}/appointments/{appointmentId}
 */
export interface ProAppointmentDetails extends ProAppointment {
  booked_by_user: string | null;
  cancellation_charge_remaining: string;
  creation_time: DateTimeStamp;
  intake_form_response?: CompletedIntakeForm | null;
  intake_form_v1_response?: IntakeFormV1 | null;
  noshow_charge_remaining: string;
  payments_charged_cents: number;
  pro_promotion_discounts: ProPromotionDiscount[];
  refunded_amount_cents: number;
  reminders: Reminder[];
  serializer_version: string;
  waitlist_id: number | null;
  tip_checkout: TipCheckout | null;
}

export function isAppointmentDetails(
  appointment: ProAppointment | ProAppointmentDetails,
): appointment is ProAppointmentDetails {
  return typeof (appointment as ProAppointmentDetails).payments_charged_cents === 'number';
}

type QueryParams = {
  providerId: number;
  days?: number;
  start_date?: string; // start_date=2022-05-22T00:00:00-04:00
  page?: number;
  size?: 'all' | number;
};

export type ProAppointmentUpdatePayloadTimeBlock = Pick<TimeBlockItem, 'local_start' | 'local_end' | 'service'> & Partial<TimeBlockItem>;

export type ProAppointmentUpdatePayload = Partial<Omit<ProAppointment, 'time_blocks'> & {
  waitlist_id: ProAppointmentDetails['waitlist_id'];
  smart_price_source: string;
  time_blocks: ProAppointmentUpdatePayloadTimeBlock[];
}>;

export function getProAppointmentList({
  providerId,
  days,
  start_date: startDate,
  page = 1,
  size,
}: QueryParams): Promise<IDRFResponse<ProAppointment>> {
  const url = new URL(`/api/v2.1/providers/${providerId}/appointments`, API_ROOT);

  url.searchParams.set('page', page.toString());

  if (size) {
    url.searchParams.set('size', size.toString());
  }

  if (days) {
    url.searchParams.set('days', days.toString());
  }

  if (startDate) {
    url.searchParams.set('start_date', startDate.toString());
  }

  return ssFetchJSON<IDRFResponse<ProAppointment>>(
    url.toString(),
  );
}

export function getProAppointmentDetails(
  providerId: number,
  appointmentId: number,
): Promise<ProAppointmentDetails> {
  const url = new URL(
    `/api/v2.1/providers/${providerId}/appointments/${appointmentId}`,
    API_ROOT,
  );

  return ssFetchJSON<ProAppointmentDetails>(
    url.toString(),
    { includeResponseOnError: true },
  );
}

export function updateProAppointment(
  providerId: number,
  appointment: ProAppointmentUpdatePayload,
) {
  const url = new URL(
    `/api/v2.1/providers/${providerId}/appointments/${appointment.id}`,
    API_ROOT,
  );

  return ssFetch(url.toString(), {
    method: 'PATCH',
    body: appointment,
  }).then(res => {
    if (!res.ok && res.status !== 304) { // the endpoint may return 304 on success?
      return res.text().then(Promise.reject.bind(Promise));
    }

    return res.json();
  });
}

export function createProAppointment(
  providerId: number,
  appointment: ProAppointmentUpdatePayload,
) {
  const url = new URL(
    `/api/v2.1/providers/${providerId}/appointments`,
    API_ROOT,
  );

  return ssFetchJSON(url.toString(), {
    method: 'POST',
    body: appointment,
  });
}

export const getAppointmentCancellationLink = async (
  providerId: number,
  appointmentId: number,
): Promise<IAppointmentCancellationLink> => {
  const url = new URL(`${API_ROOT}/api/v2/providers/${providerId}/appointment/${appointmentId}/manage_appointment_link?cancel=true`);

  return ssFetchJSON(
    url.toString(),
    { ssCache: AppointmentCache },
  );
};

/**
 * Allows provider to remove smart price upcharge from appointment
 *
 * @param providerId: the provider primary key
 * @param appointmentId: the appointment primary key
 * @returns {Promise<boolean>}
 */
export const removeSmartPrice = async (
  providerId: string | number,
  appointmentId: string | number,
): Promise<boolean> => {
  const url = `/api/v2.1/providers/${providerId}/appointments/${appointmentId}/remove-smart-price`;
  const response = await ssFetchJSON(url, { method: 'POST' });
  return response === 'OK';
};
