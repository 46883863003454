export const TAP_TO_PAY_FEATURE_FLAG = 'pro_checkout_taptopay_ev3978_20221027';
export const STRIPE_MIN_TRANSACTION_AMOUNT = 0.5;
export const TAP_TO_PAY_TRANSACTION_ERROR_TEST_SWITCH = 'pro_checkout_taptopay_test_ev3892_20220728';
export const TAP_TO_PAY_PAYMENT_ERROR_TEST_SWITCH = 'pro_checkout_taptopay_test_ev3893_20220728';
export const TAP_TO_PAY_CHECKOUT_CONNECTION_ERROR_TEST_SWITCH = 'pro_checkout_taptopay_test_ev4195_20230127';
export const TAP_TO_PAY_CARD_ERROR_TEST_SWITCH = 'pro_checkout_taptopay_test_ev3894_20220728';
export const TAP_TO_PAY_DEVICE_ELIGIBLE_TEST_SWITCH = 'pro_taptopay_optin_force_eligibility_ev3987_20221012';
export const TAP_TO_PAY_DISABLE_RECONNECT_ON_APP_RESUME_TEST_FLAG = 'pro_taptopay_disable_reconnect_on_app_resume_ev4190_20230220';
export const TAP_TO_PAY_APPOINTMENT_GENERIC_ERROR_CODE = 422;
export const TAP_TO_PAY_HELP_URL = 'https://mss.styleseat.com/tap-to-pay/';
export const HAD_SUCCESSFUL_CONNECTION_USERSTATE_KEY = 'tapToPayHadSuccessfulConnection';
export const TTP_OLD_SETUP_HOME_CARD_VIEW_DATE_USERSTATE_KEY = 'tapToPaySetupHomeCardViewDate';
export const TTP_NEW_SETUP_HOME_CARD_COLLAPSE_DATE_USERSTATE_KEY = 'tapToPaySetupNewHomeCardCollapseDate';

// A passcode isn’t set on the device.
// This constant is specified in Apple's Local Authentication docs:
// https://developer.apple.com/documentation/localauthentication/laerror/code/passcodenotset
export const PASSCODE_NOT_SET_ERROR_CODE = -5;

export enum FlowStep {
  ProHandPhoneToClient = 'Pro hands phone to client',
  ClientTipSelection = 'Client selects tip',
  CollectClientContactInfo = 'Client enters contact info',
  ClientHandPhoneToPro = 'Client hands phone to pro',
  ProTapsCardOnPhone = 'Pro taps card on phone',
}

export enum ErrorStep {
  TransactionError = 'Transaction Error',
  PaymentError = 'Payment Error',
  CardError = 'Card Error',
  OngoingCallError = 'Ongoing Call Error',
  ReaderConnectionError = 'Reader Connection Error',
}

export type TapToPayStep = FlowStep | ErrorStep;

export enum FlowEvent {
  UserCanceled = 'Tap to Pay Checkout User Clicked Cancel',
}

export enum TipSelectionEvent {
  Submitted = 'Checkout Tap2Pay Tips Submitted',
}

export enum ContactInfoEvent {
  Viewed = 'Checkout Tap2Pay Contact Info Viewed',
  Saved = 'Checkout Tap2Pay Contact Info Saved',
  SaveFailed = 'Checkout Tap2Pay Contact Info Save Failed',
  Waived = 'Checkout Tap2Pay Contact Info Receipt Waived',
}

export enum PaymentEvent {
  Started = 'Checkout Tap2Pay Started',
  Completed = 'Checkout Tap2Pay Completed',
  Failed = 'Checkout Tap2Pay Failed',
  Canceled = 'Checkout Tap2Pay Canceled',
  ConnectReader = 'Checkout Tap2Pay Connect Reader',
}

export enum ErrorEvent {
  Dismissed = 'Tap to Pay Checkout Error Dismissed',
}
