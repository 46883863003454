/**
 * Module for connecting trackingId (tkm) cookie and rematch models.
 */
import { subscribe } from 'redux-subscriber';
import debouncer from './debouncer';
import { trackingIdReadyResolver } from './trackingIdReady';

let unsubUser;
let unsubTrackingId;
export const unsubscribe = () => {
  if (unsubUser) unsubUser();
  if (unsubTrackingId) unsubTrackingId();
};

/**
 * Debouncer to prevent React 'Should not already be working' errors when calling
 * a rematch reducer from a redux subscriber.
 *
 * This is contained in a singleton for test mocking.
 */
export const debounce = {
  debounce: debouncer(10),
};

const initTrackingId = async store => {
  try {
    const {
      dispatch,
    } = store;

    // Prevent subscription leak if called twice
    unsubscribe();

    // Restore trackingId model state from cookie/storage
    await dispatch.trackingId.initializeTrackingId();

    // Populate `user.ss_tracking_cookie` model from the `tkm` cookie
    await dispatch.trackingId.syncTrackingId();

    // When `user.ss_tracking_cookie` changes, update the `trackingId` model and `tkm` cookie.
    unsubUser = subscribe('user.ss_tracking_cookie', () => {
      debounce.debounce(dispatch.trackingId.syncTrackingId);
    });

    // When `trackingId.trackingId` changes, update the `user` model.
    unsubTrackingId = subscribe('trackingId.trackingId', () => {
      debounce.debounce(dispatch.trackingId.syncTrackingId);
    });
  } finally {
    // Let listeners know initialization is complete (even if not successful)
    trackingIdReadyResolver.resolve();
  }
};

export default initTrackingId;
