// @ts-strict-ignore
import { createModel } from '@rematch/core';
import moment from 'moment';
import { ModelSelectorFactories, Selector } from '@rematch/select';
import {
  ProStats,
  getYearBookingStats,
  getYearClientStats,
  getYearSalesStats,
  ProBookingStats,
  ProSalesStats,
  ProClientStats,
  ActualRevenueStats,
  ProjectedRevenueStats,
} from '../../api/Providers/Stats';
import {
  filterByDate,
  toLists,
  deriveGrossRevenue,
  groupBy,
  getLast30DayClientData,
} from '../../modules/provider/ProStats';
import nonCriticalException from '../../modules/exceptionLogger';
import {
  Last30DayClientData,
  MasterDataSet,
  ProStatsFormatted,
  StatDataSet,
  WithDate,
} from '../../modules/provider/ProStats/types';
import { getMonthlyRevenue } from '../../modules/provider/ProStats/derived/getMonthlyRevenue';
import { getYearlyRevenue } from '../../modules/provider/ProStats/derived/getYearlyRevenue';
import type { RootModel, RootState } from '../models';
import { getMonthlyBookings } from '../../modules/provider/ProStats/derived/getMonthlyBookings';
import { getYearlyBookings } from '../../modules/provider/ProStats/derived/getYearlyBookings';

export interface ProviderStatsError {
  sales?: any;
  clients?: any;
  booking?: any;
}

export type ProviderStatsState = {
  loading: boolean;
  providerId: number;
  error?: ProviderStatsError | null;
  stats: {
    [k in keyof ProStats]: ProStats[k] | undefined;
  };
};

type LoadYearSalesPayload = {
  providerId: number;
};

type LoadYearBookingStatsPayload = {
  providerId: number;
};

type LoadYearClientStatsPayload = {
  providerId: number;
};

type OnLoadedYearSalesPayload = {
  providerId: number;
  stats: ProSalesStats;
};

type OnLoadedYearBookingStatsPayload = {
  providerId: number;
  stats: ProBookingStats;
};

type OnLoadedYearClientsStats = {
  providerId: number;
  stats: ProClientStats;
};

type OnLoadingPayload = {
  providerId: number;
};

type OnLoadErrorPayload = {
  error: ProviderStatsError;
};

const getInitialState = (): ProviderStatsState => ({
  error: null,
  providerId: null,
  loading: false,
  stats: {
    status: undefined,
    actual_revenue: undefined,
    appts_booked: undefined,
    client_counts: undefined,
    projected_revenue: undefined,
    top_services: undefined,
  },
});

const combineObject = <A extends Object, B extends Object>(a: A, b: B) => {
  if (!a) {
    return b;
  }
  if (!b) {
    return a;
  }

  if (Array.isArray(a)) {
    return a.concat(b || []);
  }

  if (Array.isArray(b)) {
    return b.concat(a || []);
  }

  return { ...(a || {}), ...(b || {}) };
};

const combineObjects = <A extends Object, B extends Object>(a: A, b: B): A & B => (
  Object.keys(a).reduce((result, prop) => ({
    ...result,
    [prop]: combineObject(a?.[prop], b?.[prop]),
  }), {}) as A & B
);

/**
 * Rematch model for the provider side create/edit service screen(s)
 */
export const providerStats = createModel<RootModel>()({
  name: 'providerStats',

  state: getInitialState(),

  reducers: {
    'user/logout': () => getInitialState(),

    onLoading(
      state: ProviderStatsState,
      payload: OnLoadingPayload,
    ): ProviderStatsState {
      const {
        providerId,
      } = payload;

      return {
        ...getInitialState(),
        loading: true,
        error: null,
        providerId,
      };
    },

    onLoadedYearSales(
      state: ProviderStatsState,
      payload: OnLoadedYearSalesPayload,
    ): ProviderStatsState {
      const {
        providerId,
        stats,
      } = payload;

      // in case data is still coming for a previous provider we want to ignore it
      if (state.providerId !== providerId) return state;

      return {
        ...state,
        error: null,
        loading: false,
        // combine each stat with the previous entries in the state
        stats: combineObjects(state.stats, stats),
      };
    },

    onLoadedYearBookingStats(
      state: ProviderStatsState,
      payload: OnLoadedYearBookingStatsPayload,
    ): ProviderStatsState {
      const {
        providerId,
        stats,
      } = payload;

      // in case data is still coming for a previous provider we want to ignore it
      if (state.providerId !== providerId) {
        return state;
      }

      return {
        ...state,
        error: null,
        loading: false,
        // combine each stat with the previous entries in the state
        stats: combineObjects(state.stats, stats),
      };
    },

    onLoadedYearClientStats(
      state: ProviderStatsState,
      payload: OnLoadedYearClientsStats,
    ): ProviderStatsState {
      const {
        providerId,
        stats,
      } = payload;

      // in case data is still coming for a previous provider we want to ignore it
      if (state.providerId !== providerId) return state;

      return {
        ...state,
        error: null,
        loading: false,
        // combine each stat with the previous entries in the state
        stats: combineObjects(state.stats, stats),
      };
    },

    onLoadError(
      state: ProviderStatsState,
      payload: OnLoadErrorPayload,
    ): ProviderStatsState {
      const { error } = payload;

      return {
        ...getInitialState(),
        loading: false,
        error,
      };
    },
  },

  effects: dispatch => ({
    loadYearSalesStats: async ({
      providerId,
    }: LoadYearSalesPayload) => {
      dispatch.providerStats.onLoading({ providerId });
      try {
        const stats = await getYearSalesStats(providerId);
        dispatch.providerStats.onLoadedYearSales({
          providerId,
          stats,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerStats.onLoadError({ error: { sales: e } });
      }
    },

    loadYearClientStats: async ({
      providerId,
    }: LoadYearClientStatsPayload) => {
      dispatch.providerStats.onLoading({ providerId });
      try {
        const stats = await getYearClientStats(providerId);
        dispatch.providerStats.onLoadedYearClientStats({
          providerId,
          stats,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerStats.onLoadError({ error: { clients: e } });
      }
    },

    loadYearBookingStats: async ({
      providerId,
    }: LoadYearBookingStatsPayload) => {
      dispatch.providerStats.onLoading({ providerId });
      try {
        const stats = await getYearBookingStats(providerId);
        dispatch.providerStats.onLoadedYearBookingStats({
          providerId,
          stats,
        });
      } catch (e) {
        nonCriticalException(e);
        dispatch.providerStats.onLoadError({ error: { booking: e } });
      }
    },
  }),

  selectors: (slice, createSelector): ModelSelectorFactories<RootModel, Record<string, never>> => ({
    stats: () => (
      slice((state: ProviderStatsState): ProStatsFormatted<keyof ProStats> => (
        toLists(state.stats)
      ))
    ),

    derivedStats(models): Selector<RootState, MasterDataSet> {
      return createSelector(
        models.providerStats.stats,
        (stats: ProStatsFormatted<keyof ProStats>): MasterDataSet => {
          const result = { ...stats };
          if (result.actual_revenue) {
            result.actual_revenue = deriveGrossRevenue(
              result.actual_revenue as WithDate<ActualRevenueStats>[],
            );
          }
          if (result.projected_revenue) {
            result.projected_revenue = filterByDate(
              result.projected_revenue as WithDate<ProjectedRevenueStats>[],
              moment(),
              moment().endOf('year'),
            );
          }
          return result;
        },
      );
    },

    weeklySalesData(models) {
      return createSelector(
        models.providerStats.derivedStats,
        (
          stats: MasterDataSet<'actual_revenue' | 'projected_revenue'>,
        ): MasterDataSet<'actual_revenue' | 'projected_revenue'> => {
          const result = filterByDate(
            stats as MasterDataSet<'actual_revenue' | 'projected_revenue'>,
            moment().startOf('week'),
            moment().endOf('week'),
          );
          return result;
        },
      );
    },

    monthlySalesData(models) {
      return createSelector(
        models.providerStats.derivedStats,
        getMonthlyRevenue,
      );
    },

    yearlySalesData(models) {
      return createSelector(
        models.providerStats.derivedStats,
        getYearlyRevenue,
      );
    },

    weeklyBookingData(models) {
      return createSelector(
        models.providerStats.stats,
        (stats: ProStatsFormatted<keyof ProStats>): StatDataSet<'appts_booked'> => {
          const result = filterByDate<'appts_booked'>(
            stats.appts_booked as StatDataSet<'appts_booked'>,
            moment().startOf('week'),
            moment().endOf('week'),
          );
          return result;
        },
      );
    },

    monthlyBookingData(models) {
      return createSelector(
        models.providerStats.stats,
        getMonthlyBookings,
      );
    },

    yearlyBookingData(models) {
      return createSelector(
        models.providerStats.stats,
        getYearlyBookings,
      );
    },

    yearToDateClientData(models) {
      return createSelector(
        models.providerStats.stats,
        (stats: ProStatsFormatted<keyof ProStats>): MasterDataSet<'client_counts'> => {
          const result = filterByDate(
            stats as MasterDataSet<'client_counts'>,
            moment().startOf('year'),
            moment().endOf('year'),
          );

          return groupBy(result, 'month');
        },
      );
    },

    last30DayClientData(models) {
      return createSelector(
        models.providerStats.yearToDateClientData,
        (stats: MasterDataSet<'client_counts'>): Last30DayClientData => (
          getLast30DayClientData(stats)
        ),
      );
    },

    error: () => (
      slice((state: ProviderStatsState) => state.error)
    ),
  }),
});
