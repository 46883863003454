// @ts-strict-ignore
import URI from 'urijs';

import { ssFetchJSON } from './ssFetch';
import { RECOMMENDATIONS_ENGINE_PREFIX, RECOMMENDATIONS_SOURCE_URL } from '../config';

type Stringable = string | number;

/**
 * Retrieves a structure derived using machine learning data for a particular entity.
 * @param collectionKey The collection key for the ML data, e.g. "ATL-3063"
 * @param pathSegments Any additional path segments to include in the path. At least 1 must be
 * provided as the name of the json file, for example a provider ID.
 */
export default async function loadMLData<T>(
  collectionKey: string,
  ...pathSegments: [Stringable, ...Stringable[]]
): Promise<T> {
  const restSegments = pathSegments.slice(0, pathSegments.length - 1);
  const id = pathSegments[pathSegments.length - 1];
  let url = `${RECOMMENDATIONS_SOURCE_URL}${collectionKey}/${RECOMMENDATIONS_ENGINE_PREFIX}/${id}.json`;

  if (restSegments.length) {
    const uri = URI(RECOMMENDATIONS_SOURCE_URL);

    uri.segment([
      collectionKey,
      RECOMMENDATIONS_ENGINE_PREFIX,
      ...restSegments,
      `${id}.json`,
    ]);

    url = uri.toString();
  }

  return await ssFetchJSON(url) as T;
}
