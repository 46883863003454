import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { IRootDispatch } from '../store/models';
import { AssignTestRequest } from '../store/ABTest/ABTest.types';

const validateTestAssignmentParameters = ({
  name,
  percent,
  variations,
}: AssignTestRequest) => {
  if (!(percent || variations) && percent !== 0) {
    throw new Error(`Either percent or variations must be specified for test: ${name}`);
  }

  if (percent && variations) {
    throw new Error(`Use percent or variations not both for test: ${name}`);
  }
};
const useAssignTests = (testAssignmentParametersArray: AssignTestRequest[]): void => {
  const dispatch = useDispatch<IRootDispatch>();

  // could not use useMount here because it introduces a circular dependency error
  useEffect(() => {
    testAssignmentParametersArray.forEach(validateTestAssignmentParameters);
    dispatch.abTest.assignTests(testAssignmentParametersArray);
    // eslint-disable-next-line
  }, []);
};

export default useAssignTests;
