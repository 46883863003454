import _ from 'underscore';
import { clearHistoryCache } from '../../../api/Providers/Clients/History';
import ssFetch, { ssFetchJSON } from '../../ssFetch';
import { IClientNote } from '../../../api/Providers/Clients/types';

const noteTypesByProfession = [
  {
    profession: 'Stylist',
    field_group: 1,
    note_types: [
      'Formula',
      'Products',
      'Note',
    ],
  },
  {
    profession: 'Esthetician',
    field_group: 2,
    note_types: [
      'Treatment',
      'Products',
      'Note',
    ],
  },
  {
    profession: 'Massage Therapist',
    field_group: 3,
    note_types: [
      'Other Goal',
      'Findings',
      'Assessment',
      'Notes',
    ],
  },
  {
    profession: 'StyleSeat Staff',
    field_group: 4,
    note_types: ['Notes'],
  },
  {
    profession: 'Personal Trainer',
    field_group: 5,
    note_types: [],
  },
  {
    profession: 'Professional',
    field_group: 6,
    note_types: [],
  },
  {
    profession: 'Yoga Instructor',
    field_group: 7,
    note_types: [
      'Session Notes',
      'Activities',
    ],
  },
  {
    profession: 'Manicurist',
    field_group: 8,
    note_types: [],
  },
  {
    profession: 'Chiropractor',
    field_group: 9,
    note_types: [],
  },
  {
    profession: 'Acupuncturist',
    field_group: 10,
    note_types: [],
  },
  {
    profession: 'Fitness Instructor',
    field_group: 11,
    note_types: [],
  },
  {
    profession: 'Pilates Instructor',
    field_group: 12,
    note_types: [],
  },
  {
    profession: 'Hypnotherapist',
    field_group: 13,
    note_types: [],
  },
  {
    profession: 'Meditation Leader',
    field_group: 14,
    note_types: [],
  },
  {
    profession: 'Cranial Sacral Practitioner',
    field_group: 15,
    note_types: [],
  },
  {
    profession: 'Psychologist',
    field_group: 16,
    note_types: [],
  },
  {
    profession: 'Counselor',
    field_group: 17,
    note_types: [],
  },
  {
    profession: 'Midwife',
    field_group: 18,
    note_types: [],
  },
  {
    profession: 'Nutritionist',
    field_group: 19,
    note_types: [],
  },
  {
    profession: 'Physical Therapist',
    field_group: 20,
    note_types: [],
  },
  {
    profession: 'Rolfer',
    field_group: 21,
    note_types: [],
  },
  {
    profession: 'Martial Arts Instructor',
    field_group: 22,
    note_types: [],
  },
  {
    profession: 'Image Consultant',
    field_group: 23,
    note_types: [],
  },
  {
    profession: 'Life Coach',
    field_group: 24,
    note_types: [],
  },
  {
    profession: 'Colorist',
    field_group: 25,
    note_types: [],
  },
  {
    profession: 'Pilates Instructor',
    field_group: 26,
    note_types: [],
  },
  {
    profession: 'Cosmetologist',
    field_group: 27,
    note_types: [
      'Formula',
      'Products',
      'Note',
    ],
  },
  {
    profession: 'Nail Technician',
    field_group: 28,
    note_types: [],
  },
  {
    profession: 'Beauty Educator',
    field_group: 29,
    note_types: [],
  },
  {
    profession: 'Makeup Artist',
    field_group: 30,
    note_types: [],
  },
  {
    profession: 'Barber',
    field_group: 31,
    note_types: [],
  },
  {
    profession: 'Marriage and Family Therapist',
    field_group: 32,
    note_types: [],
  },
  {
    profession: 'Psychotherapist',
    field_group: 33,
    note_types: [],
  },
  {
    profession: 'Body Artist',
    field_group: 34,
    note_types: [],
  },
];

export function getNoteTypesByProfession(professionLabel: string): Array<string> {
  const profession = _.findWhere(noteTypesByProfession, { profession: professionLabel });
  return profession && profession.note_types.length > 0 ? profession.note_types : ['Note'];
}

/**
 * Create client notes.
 *
 * @param {number} providerId
 * @param {number} clientId
 * @param {Object} note - client note to be saved
 * @return {Promise}
 */
export async function saveNote(
  providerId: number | string,
  clientId: number | string,
  note: Partial<IClientNote>,
): Promise<IClientNote> {
  const url = `/api/v2/providers/${providerId}/clients/${clientId}/notes`;

  clearHistoryCache();
  return ssFetchJSON<IClientNote>(
    url,
    {
      method: 'POST',
      body: note,
    },
  );
}

/**
 * Update client note.
 *
 * @param {number} providerId
 * @param {number} clientId
 * @param noteId
 * @param textValue
 * @return {Promise}
 */
export async function updateNote(
  providerId: number | string,
  clientId: number | string,
  noteId: number | string,
  textValue: string,
) {
  const noteText = { value: textValue };
  const url = `/api/v2/providers/${providerId}/clients/${clientId}/notes/${noteId}`;
  clearHistoryCache();

  return ssFetch(url, {
    method: 'PATCH',
    body: noteText,
  }).then(res => {
    if (!res.ok && res.status !== 304) { // the endpoint may return 304 on success?
      return res.text().then(Promise.reject.bind(Promise));
    }

    return res;
  });
}

/**
 * Delete client note.
 *
 * @param providerId
 * @param clientId
 * @param noteId - id for client note to be deleted
 */
export async function deleteNote(
  providerId: number | string,
  clientId: number | string,
  noteId: number | string,
): Promise<void> {
  const url = `/api/v2/providers/${providerId}/clients/${clientId}/notes/${noteId}`;
  clearHistoryCache();
  await ssFetch(url, {
    method: 'DELETE',
  });
}
