import moment from 'moment';
import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../../modules/ssFetch';

export type ProSalesStats = ProStatsResponse<'status' | 'actual_revenue' | 'projected_revenue' | 'appts_booked'>;
export type ProBookingStats = ProStatsResponse<'status' | 'appts_booked' | 'top_services'>;
export type ProClientStats = ProStatsResponse<'status' | 'client_counts'>;

// maps to the ClientCountSerializer on the  API
export type ClientCountStats = {
  current_returning: number;
  current_new: number;
};

/**
 * maps to the ApptsBookedSerializer on the API
 */
export type ApptsBookedStats = {
  appts_count: number;
};

/**
 * maps to the ActualRevenueSerializer
 */
export type ActualRevenueStats = {
  nonprocessed_revenue: number;
  processed_revenue: number;
};

/**
 * maps to the ProjectedRevenueSerializer
 */
export type ProjectedRevenueStats = {
  projected_revenue: number;
};

/**
 maps to TopServiceSerializer
 */
export type TopServiceStats = {
  count: number;
  // maps to ServiceIdentitySerializer
  service: {
    id: number;
    name: string;
  }
};

export type StatsFieldByDates<T> = {
  [date: string]: T
};

/**
 *  maps to the ProviderDailyStatsReportSerializer on the API
 */
export type ProStats = {
  // backend always sends status as this static object
  status: {
    pending: {};
  };
  client_counts: StatsFieldByDates<ClientCountStats>;
  appts_booked: StatsFieldByDates<ApptsBookedStats>;
  actual_revenue: StatsFieldByDates<ActualRevenueStats>;
  projected_revenue: StatsFieldByDates<ProjectedRevenueStats>;
  top_services: TopServiceStats[];
};

export type StatsFields = keyof ProStats;

// maps to the ProviderDailyStatsReportSerializer on the API
export type ProStatsResponse<F extends keyof ProStats = keyof ProStats> = {
  [k in F]: ProStats[F];
};

export type ApiGetProStatsParams<F extends keyof ProStats = keyof ProStats> = {
  fields?: F[];
  startDate: string;
  endDate: string;
};

/**
 * Fetches the provider's stats for the given fields from the API
 * @param {number | string} providerId
 * @param {ApiGetProStatsParams<F>} params
 * @returns {Promise<ProStatsResponse<F>>}
 */
export async function getStats<F extends keyof ProStats>(
  providerId: number | string,
  params: ApiGetProStatsParams<F>,
): Promise<ProStatsResponse<F>> {
  const uri = new URL(`${API_ROOT}/api/v2/providers/${providerId}/stats`);

  if (params.fields) {
    uri.searchParams.append('fields', params.fields.join(','));
  }

  if (params.startDate) {
    uri.searchParams.append('start', params.startDate);
  }

  if (params.endDate) {
    uri.searchParams.append('end', params.endDate);
  }

  return ssFetchJSON<ProStatsResponse<F>>(
    uri.toString(),
    {
      method: 'GET',
    },
  );
}

/**
 * Get the provider stats for the given fields over the past 12 months
 * @param {number | string} providerId
 * @param {Omit<ApiGetProStatsParams<F>, "startDate" | "endDate">} params
 * @returns {Promise<ProStatsResponse<keyof ProStats>>}
 */
export async function getYearStats<F extends StatsFields = StatsFields>(
  providerId: number | string,
  params: Omit<ApiGetProStatsParams<F>, 'startDate' | 'endDate'>,
) {
  const DATE_FORMAT = 'YYYY-MM-DD';
  const lastYearStart = moment()
    .startOf('year')
    .subtract(1, 'year')
    .format(DATE_FORMAT);

  const yearEnd = moment()
    .endOf('year')
    .format(DATE_FORMAT);

  const completeParams: ApiGetProStatsParams<F> = {
    ...params,
    startDate: lastYearStart,
    endDate: yearEnd,
  };

  return getStats(providerId, completeParams);
}

/**
 * Get the provider sales stats over the past 12 months
 * @param {number | string} providerId
 * @returns {Promise<ProStatsResponse<keyof ProStats>>}
 */
export async function getYearSalesStats(
  providerId: number | string,
) {
  return getYearStats(
    providerId,
    {
      fields: [
        'status',
        'actual_revenue',
        'projected_revenue',
        'appts_booked',
      ],
    },
  );
}

/**
 * Get the provider client stats over the past 12 months
 * @param {number | string} providerId
 * @returns {Promise<ProStatsResponse<keyof ProStats>>}
 */
export async function getYearClientStats(
  providerId: number | string,
) {
  return getYearStats(
    providerId,
    {
      fields: [
        'status',
        'client_counts',
      ],
    },
  );
}

/**
 * Get the provider booking stats over the past 12 months
 * @param {number | string} providerId
 * @returns {Promise<ProStatsResponse<keyof ProStats>>}
 */
export async function getYearBookingStats(
  providerId: number | string,
) {
  return getYearStats(
    providerId,
    {
      fields: [
        'status',
        'appts_booked',
        'top_services',
      ],
    },
  );
}
