// @ts-strict-ignore
import { useCallback } from 'react';
import type TalkJS from 'talkjs';
import { ChatRoles } from '../modules/chat/types';
import type { State as User } from '../store/CurrentUser.types';
import ChatManager from '../modules/chat/ChatManager';
import { useGetTalkJsUserIdFromSsUserOrProviderId } from '../modules/chat/utils';

const useCurrentChatSession = (user: User, chatRole: ChatRoles): () => Promise<TalkJS.Session> => {
  const isLoggedIn = user?.is_anon === false;
  const talkJsUserId = useGetTalkJsUserIdFromSsUserOrProviderId(
    chatRole === ChatRoles.Provider ? user?.providerId : user?.user_id,
    chatRole,
  );

  return useCallback(async () => {
    await ChatManager.getAndSetTalkReady();
    const chatUser = ChatManager.createChatUser(user, chatRole, talkJsUserId);
    return ChatManager.getOrCreateSession(chatUser);
  }, [isLoggedIn]);
};

export default useCurrentChatSession;
