import type NewRelicBrowser from 'new-relic-browser';
import { API_ROOT } from '../config';
import {
  getAndroidVersion,
  getAppVersion,
  getIOSVersion,
  getIsApp,
  getOS,
} from './AppInfo';
import { isPreStage, isTest } from './env';

declare global {
  interface Window {
    newrelic: typeof NewRelicBrowser;
  }

  // also adding a definition for the globalThis object
  // for nodelike environments
  type newrelic = typeof NewRelicBrowser;
}

/**
 * Returns an error based on the type of e. If e is an object,
 * then it returns an Error with a JSON stringified version of the object.
 *
 * @param {unknown} e
 * @returns {Error}
 */
function getError(e: unknown): Error {
  if (e instanceof Error) return e;
  if (typeof e === 'object') return new Error(JSON.stringify(e));
  return new Error(String(e));
}

/**
 * Send error to newrelic, optionally adding data.
 *
 * Also logs the error to the console if not in prod.
 *
 * @param   {unknown} error
 * @param   {Object} data  Optional data passed Page Action
 * @returns {undefined}
 */
export default function nonCriticalException(
  error: unknown,
  data: Record<string, any> | undefined = undefined,
) {
  if (typeof window === 'object' && 'newrelic' in window) {
    const attributes = {
      environmentName: process.env.ENV_NAME || 'local',
      apiRoot: API_ROOT,
      appVersion: getAppVersion(),
      isApp: getIsApp(),
      osType: getOS(),
      iosVersion: getIOSVersion(),
      androidVersion: getAndroidVersion(),
    };

    window.newrelic.noticeError(
      getError(error),
      attributes,
    );

    if (typeof data !== 'undefined') {
      const message = error instanceof Error ? error.message : String(error);
      window.newrelic.addPageAction(message, data);
    }
  }

  if (isPreStage() && !isTest()) {
    // eslint-disable-next-line no-console
    console.error(error, data);
  }
}

/** Calls NewRelic's addPageAction method. Please note that NewRelic has
 * [reserved keywords and values for attributes](https://docs.newrelic.com/docs/data-apis/custom-data/custom-events/data-requirements-limits-custom-event-data/#reserved-words).
 */
export function addPageActionNR(actionName: string, attributes: Record<string, any> = {}) {
  if (typeof window === 'object' && 'newrelic' in window) {
    const requiredAttributes = {
      environmentName: process.env.ENV_NAME || 'local',
      apiRoot: API_ROOT,
      appVersion: getAppVersion(),
      isApp: getIsApp(),
      osType: getOS(),
      iosVersion: getIOSVersion(),
      androidVersion: getAndroidVersion(),
    };
    window.newrelic.addPageAction(actionName, { ...attributes, ...requiredAttributes });
  }
}
