import type { IRouteDestination } from '../../../../../packages/router';
import type { VerificationIssueKey } from '../../../store/StripeIdentityVerification.types';
import {
  FULL_SSN,
  COMPANY_EIN,
  VALID_ADDRESS,
  VALID_PHONE,
  CATCHALL,
} from '../StripeVerificationModal/VerificationIssues';

/* Banners */
export type VerificationIssueBanner = {
  key: VerificationIssueKey;
  header: React.ReactNode;
  body: React.ReactNode;
  action?: IRouteDestination;
  ctaText?: string;
};

export const VERIFICATION_ISSUE_BANNER: Record<VerificationIssueKey, VerificationIssueBanner> = {
  'full-ssn': {
    key: 'full-ssn',
    header: 'Verification required',
    body: 'Clients won’t be able to book with you until you provide a valid social security number.',
    action: FULL_SSN.action,
    ctaText: FULL_SSN.ctaText,
  },
  'legal-doc': {
    key: 'legal-doc',
    header: 'Verification required',
    body: 'Clients won’t be able to book with you until you email a photo of your valid driver’s license to verification@styleseat.com',
  },
  'company-ein': {
    key: 'company-ein',
    header: 'Verification required',
    body: 'We are unable to verify your identity. Clients will not be able to book with you until issues have been resolved.',
    action: COMPANY_EIN.action,
    ctaText: COMPANY_EIN.ctaText,
  },
  'valid-address': {
    key: 'valid-address',
    header: 'Verification required',
    body: 'Clients won’t be able to book with you until you add your mailing address.',
    action: VALID_ADDRESS.action,
    ctaText: VALID_ADDRESS.ctaText,
  },
  'valid-phone': {
    key: 'valid-phone',
    header: 'Verification required',
    body: 'Clients won’t be able to book with you until you update your phone number.',
    action: VALID_PHONE.action,
    ctaText: VALID_PHONE.ctaText,
  },
  catchall: {
    key: 'catchall',
    header: 'Verification required',
    body: 'Clients won’t be able to book with you until you update and review your information.',
    action: CATCHALL.action,
    ctaText: CATCHALL.ctaText,
  },
};
