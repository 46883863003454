// @ts-strict-ignore
import { createModel } from '@rematch/core';

import type { RootModel, RootState } from './models';
import type { Assignment } from './ABTest';
import { CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME, INCENTIVES_DISCOUNT_CODE } from '../components/consumer/incentives/constants';
import { ShareTarget } from '../modules/share';
import FeatureFlags from '../modules/FeatureFlags';
import { IncentivesCheck } from './ShowIncentives.model';
import { CLIENT_REFERRALS_SHARE_CARD_UI_TEST_NAME, CLIENT_REFERRALS_SHARE_CARD_UI_TEST_PERCENT } from '../components/consumer/sharing/constants';

export enum RecipientState {
  Loading = 'loading',
  Recipient = 'yes',
  NotRecipient = 'no',
}

export type ReferralRecipient = {
  state: RecipientState;
  target?: ShareTarget;
};

type State = {
  inAdditionalEntrypointTest: boolean;
  inUpdatedShareCardTest: boolean;
  assignedUpdatedShareCardTest: boolean;
  assignedAdditionalEntrypointTest: boolean;
  referralRecipient: ReferralRecipient;
};

const REFERRAL_SOURCE = 'client_referral';

const model = createModel<RootModel>()({
  name: 'referrals',

  state: {
    inAdditionalEntrypointTest: false,
    inUpdatedShareCardTest: false,
    assignedUpdatedShareCardTest: false,
    assignedAdditionalEntrypointTest: false,
    referralRecipient: {
      state: RecipientState.Loading,
    },
  } as State,

  reducers: {
    setUpdatedShareCardTestAssignment: (state: State, payload: Assignment) => ({
      ...state,
      inUpdatedShareCardTest: payload.inTest,
      assignedUpdatedShareCardTest: payload.isAssigned,
    }),

    setAdditionalEntrypointTest: (state: State, payload: Assignment) => ({
      ...state,
      inAdditionalEntrypointTest: payload.inTest,
      assignedAdditionalEntrypointTest: payload.isAssigned,
    }),

    setRecipient: (state: State, payload: ReferralRecipient) => ({
      ...state,
      referralRecipient: { ...payload },
    }),
  },

  effects: dispatch => ({
    assignUpdatedShareCardTest: async (_?: undefined, rootState?: RootState): Promise<boolean> => {
      if (rootState.referrals.assignedUpdatedShareCardTest) {
        return rootState.referrals.inUpdatedShareCardTest;
      }

      if (!rootState.referrals.assignedUpdatedShareCardTest) {
        const assignment = await dispatch.abTest.assignTest({
          name: CLIENT_REFERRALS_SHARE_CARD_UI_TEST_NAME,
          percent: CLIENT_REFERRALS_SHARE_CARD_UI_TEST_PERCENT,
        });

        await dispatch.referrals.setUpdatedShareCardTestAssignment(assignment);
        return assignment.inTest;
      }

      return false;
    },

    checkReferralRecipient: async (
      payload: {
        target: ShareTarget;
        providerId?: number;
      },
      rootState: RootState,
    ): Promise<ReferralRecipient> => {
      // wait for flags to load and wait for incentives check before doing anything important

      let clientReferralEnabled: boolean;

      if (rootState.abTest.isLoaded) {
        clientReferralEnabled = rootState.abTest.assignments[
          CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME
        ]?.isEnabled;
      } else {
        clientReferralEnabled = await FeatureFlags.isEnabled(
          CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME,
        );
      }

      let referralIncentiveState: IncentivesCheck;

      if (rootState.showIncentives.firstCheckCompleted) {
        referralIncentiveState = rootState.showIncentives.incentivesChecks[
          CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME
        ];
      } else {
        referralIncentiveState = await dispatch.showIncentives.checkSingleIncentiveStatus({
          programName: CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME,
          enrollmentType: 'singleAppointment',
        });
      }

      if (
        rootState.referrals.referralRecipient.state !== RecipientState.Loading
      ) {
        return rootState.referrals.referralRecipient;
      }

      const { ncd_source: ncdSource } = rootState.route.params;
      const isInClientReferralIncentives = referralIncentiveState?.wasPreviouslyEnrolled;

      let state: RecipientState = RecipientState.NotRecipient;
      if (!isInClientReferralIncentives && clientReferralEnabled && ncdSource === REFERRAL_SOURCE) {
        state = RecipientState.Recipient;
      }

      if (state === RecipientState.Recipient) {
        await dispatch.showIncentives.enterOneAppointmentEnrollment(
          CLIENT_REFERRAL_INCENTIVES_FEATURE_FLAG_NAME,
        );
        await dispatch.showIncentives.checkIncentivesStatus();

        let { activeProviderId } = rootState.providers;
        activeProviderId = activeProviderId || payload.providerId;

        if (activeProviderId) {
          const existingNcdInfo = rootState.ncdAndDiscountedPros.providers[activeProviderId];

          if (!existingNcdInfo?.wasFullVerification) {
            await dispatch.ncdAndDiscountedPros.checkProviderDiscountEligibility({
              providerId: activeProviderId,
              discountCode: INCENTIVES_DISCOUNT_CODE,
              rewardId: null,
            });
          }
        }
      }

      const recipient = { state, target: payload.target };

      await dispatch.referrals.setRecipient(recipient);

      return recipient;
    },
  }),
});

export default model;
