// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from './models';

import PaymentsState from '../modules/PaymentsState';
import * as SignupSettings from '../api/Providers/SignupSettings';
import { selectors as userSelectors } from './CurrentUser.model';
import { fetchPaymentState } from '../modules/provider/PaymentSettings';
import { getProvider, instanceOfPrivilegedProvider } from '../api/Providers';
import { loadSubscription, Subscription } from '../modules/provider/Subscription';
import { PRO_PLAN_SETTINGS } from '../route-names';
import { ProviderPlanConfiguration } from '../modules/provider/ProviderPlanConfiguration';
import { updateProviderSelectedPlan } from '../api/Providers/Plan';
import { ProviderPlan } from '../types/provider';

interface ISubsBlockerState {
  /** true if the pro is blocked from using StyleSeat */
  isBlockedFromApplication: boolean;
  /** the provider's primary key */
  providerId: number | null;
  /** true if the pro has a canceled subscription */
  isCanceled: boolean;
  /** true if the pro has an expired trial */
  isTrialExpired: boolean;
  /** true if the pro has completed payments onboarding */
  isPaymentsEnabled: boolean;
  /** true if the pro has not had an active subscription in 6 months */
  isOldie: boolean;
  /** true if the pro was previously an oldie, signed up for a retrial, and the
   *  retrial subscription has expired or been cancelled. */
  wasOldie: boolean;
  /** true if the pro is an active member but was previously an oldie */
  isRejoinedOldie: boolean;
  /** true if the pro is currently on a commission plan */
  isOnCommissionPlan: boolean;
  /** true is the pro have complete payment onboarding */
  isOnboarded?: boolean;
  isCommissionPlanDefault: boolean;
}

let loaded: Promise<void>;

/**
 * Generate an initial state for the SubsBlocker model.
 *
 * @param extra - extra data to be added to the model state.
 * @returns {ISubsBlockerState} see calculate() for field documentation.
 */
export function defaultState(extra: object = {}): ISubsBlockerState {
  return {
    isBlockedFromApplication: false,
    providerId: null,
    isCanceled: false,
    isTrialExpired: false,
    isPaymentsEnabled: true,
    isOldie: false,
    wasOldie: false,
    isRejoinedOldie: false,
    isOnCommissionPlan: false,
    isOnboarded: false,
    isCommissionPlanDefault: false,
    ...extra,
  };
}

/**
 * A redux model for tracking a provider's subscription blocker state.
 *
 * A provider who has an expired/cancelled subscription should be blocked
 * from using StyleSeat until they complete those steps.
 *
 * The meat of this model's logic is in the calculate() effect, which fetches
 * various data about the pro and transforms it into the redux model state.
 * The key decider on whether the pro is blocked from using StyleSeat is
 * the provider is_disabled field. That value comes from the backend,
 * Provider.is_disabled, and is used to decide whether to
 * show or hide site content.
 *
 * For development and QA, you can put a provider into a blocked state by using
 * the `Mark "oldie"` admin action at /ssadminx/provider/provider/
 */
const model = createModel<RootModel>()({
  name: 'subsBlocker',
  state: defaultState() as ISubsBlockerState,
  reducers: {
    /**
     * Replace the existing model state with payload.
     */
    setBlocker(prevSubsBlocker: ISubsBlockerState, payload: ISubsBlockerState) {
      return { ...payload };
    },

    /**
   * When user logs out, clear subs blocker state.
   */
    'user/logout': () => defaultState(),
  },

  effects: dispatch => ({
    /**
     * Fetch, calculate, and update the model's state.
     */
    async update(): Promise<ISubsBlockerState> {
      await dispatch.providerPlan.loadPlanConfigurationData();
      const subsBlocker = await dispatch.subsBlocker.calculate();
      dispatch.subsBlocker.setBlocker(subsBlocker);
      return subsBlocker;
    },

    /**
     * Returns a promise that resolves when the model state has been calculated
     * for the first time.
     *
     * @returns {Promise}
     */
    loaded() {
      if (!loaded) {
        // Have to ts-ignore this because TypeScript thinks the return type of
        // async effects is Action<any, any> instead of Promise, though that's
        // not actually true.
        // @ts-ignore
        loaded = dispatch.subsBlocker.update();
      }
      return loaded;
    },

    /**
     * Calculate the logged-in provider's subscription blocker state.
     *
     * @returns {Promise}
     */
    async calculate(payload?: undefined, rootState?): Promise<ISubsBlockerState> {
      const providerId = userSelectors.getProviderId(rootState);
      if (!providerId) {
        return defaultState();
      }

      const provider = await getProvider(providerId);

      if (!instanceOfPrivilegedProvider(provider)) {
        // Shouldn't ever get here, but TypeScript demands this guard.
        // The logged in user will always have privileged access to this provider,
        // since the providerId is pulled from user data.
        return defaultState({ providerId });
      }

      const isBlockedFromApplication = provider.is_disabled;
      if (!isBlockedFromApplication) {
        return defaultState({ providerId });
      }

      function defaultPlanConfiguration() {
        let plans = rootState?.providerPlan?.planConfigurations?.filter(p => p.default_program);
        if (plans && !plans.length) {
          plans = rootState.providerPlan?.planConfigurations;
        }
        return plans[0];
      }

      async function selectIsCommissionPlanDefault() {
        const defaultPlan = defaultPlanConfiguration();
        return defaultPlan?.plan_name === ProviderPlan.Deluxe;
      }

      const [
        subscription,
        paymentsState,
        isCommissionPlanDefault,
      ]: [
        Subscription,
        PaymentsState,
        boolean,
      ] = await Promise.all([
        loadSubscription(providerId),
        fetchPaymentState(providerId),
        selectIsCommissionPlanDefault(),
      ]);

      const wasOldie = provider.was_oldie;
      const isOldie = provider.is_oldie;
      const isRejoinedOldie = provider.is_rejoined_oldie;
      const isOnCommissionPlan = paymentsState.isOnCommissionPlan();
      const isPaymentsEnabled = paymentsState.isEnabled();
      const isCanceled = subscription.isCanceled()
        || subscription.isCanceledAndExpired();
      const isTrialExpired = subscription.isTrialExpired();
      const isOnboarded = paymentsState?.isOnboarded();

      return {
        providerId,
        isBlockedFromApplication,
        isCanceled,
        isTrialExpired,
        isPaymentsEnabled,
        isOldie,
        wasOldie,
        isRejoinedOldie,
        isOnCommissionPlan,
        isOnboarded,
        isCommissionPlanDefault,
      };
    },

    /**
     * CTA handler for blockers; either puts the pro into an unblocked state or
     * takes them somewhere to follow further unblocking steps.
     *
     * @returns {Promise<void>}
     */
    async unblock(
      payload: { plan?: ProviderPlanConfiguration;
        stripeInstrumentId?: number;
        withoutRedirect?: boolean;
      } | undefined,
      rootState,
    ): Promise<void> {
      const { subsBlocker } = rootState;
      const {
        providerId,
      } = subsBlocker;

      // If a payload is specified, do what it says
      if (payload?.plan) {
        const { plan, stripeInstrumentId } = payload;

        if (plan.numericAmount > 0 && stripeInstrumentId) {
          await SignupSettings.updateStripeInstrument(
            providerId,
            stripeInstrumentId,
            plan?.plan_name,
          );
        }

        await updateProviderSelectedPlan(providerId, plan.plan_name);

        await dispatch.subsBlocker.update();

        return;
      }

      if (payload?.withoutRedirect) {
        return;
      }

      // If the pro hasn't picked a plan yet, take them to the plan selection page.
      dispatch.route.go({
        route: PRO_PLAN_SETTINGS,
        params: {
          providerId,
          utm_medium: 'blocker',
          utm_campaign: 'blocker',
        },
        reload: true,
        replace: true,
        notify: true,
      });
    },

  }),
});

export default model;
