import { createModel, Action } from '@rematch/core';
import type { RootModel } from './models';
import { ProviderService } from '../api/Providers/Services';

type ProviderTopServiceState = {
  providerById: {
    [id: string]: {
      services: ProviderService[],
      topServiceRankingMap: Record<number, number>,
    },
  },
  isLoaded: boolean,
  hasTopServices: boolean,
};

const model = createModel<RootModel>()({
  name: 'providerTopServices',

  state: {} as ProviderTopServiceState,

  // updating the data
  // effects: dispatch => (payload(as object), origin state)
  effects: dispatch => ({
    updateData: ({
      proId,
      popularServices,
    }: {
      proId: any;
      popularServices: ProviderService[];
    }): Action<{ proId: any; popularServices: ProviderService[]; }, void> => (
      dispatch.providerTopServices.onUpdatingData({
        proId,
        popularServices,
      })
    ),
  }),

  // setting data
  reducers: {
    onUpdatingData: (
      state: ProviderTopServiceState,
      {
        proId,
        popularServices,
      }: {
        proId: any;
        popularServices: ProviderService[];
      },
    ) => {
      const topServiceRankingMap: Record<number, number> = {};
      popularServices.map((service, index) => {
        const serviceId = service.id;
        if (!topServiceRankingMap[serviceId]) {
          topServiceRankingMap[serviceId] = index + 1;
        }
        return topServiceRankingMap;
      });
      return ({
        ...state,
        providerById: {
          [proId]: {
            services: popularServices,
            topServiceRankingMap,
          },
        },
        isLoaded: true,
        hasTopServices: popularServices.length > 0,
      });
    },
  },

  // getting data from the state;
  selectors: (slice, createSelector, hasProps) => ({
    getTopServices: hasProps((_, {
      proId,
    }: {
      proId: any,
    }) => createSelector(
      slice,
      (
        state: ProviderTopServiceState,
      ) => state?.providerById?.[proId],
    )),
  }),
});

export default model;
