// @ts-strict-ignore
import nonCriticalException from '../../../modules/exceptionLogger';
import { isBrowserUsingExifOrientation } from '../../../modules/ImageTools';
import ssFetch from '../../../modules/ssFetch';
import { uploadWait } from './TaskStatus';
import { buildImageCreationUrl } from './buildUrl';
import { clearProviderCache } from './cache';

export enum ExifSupport {
  Supported = '1',
  Unsupported = '2',
  Unknown = '3',
}

/**
 * Update the image on the server
 */
export async function createImage(image, imageData?: File, authToken?: string) {
  const formData = new FormData();

  if (image.id) {
    formData.append('id', image.id);
  }
  if (image.blurb) {
    formData.append('blurb', image.blurb);
  }
  if (image.service_id) {
    formData.append('service_id', image.service_id);
  }
  if (image.client) {
    formData.append('client', image.client);
  }

  formData.append(
    'browser_exif_support',
    isBrowserUsingExifOrientation() ? ExifSupport.Supported : ExifSupport.Unsupported,
  );

  // Add image data
  if (imageData) {
    const filename = imageData?.name || 'upload.jpg';
    try {
      formData.append('file', imageData, filename);

      const {
        crop_top: top,
        crop_bottom: bottom,
        crop_left: left,
        crop_right: right,
        rotate,
      } = image;
      if (rotate) {
        formData.append('rotate', rotate);
      }
      if (top || bottom || left || right) {
        formData.append('crop_top', top);
        formData.append('crop_left', left);
        formData.append('crop_bottom', bottom);
        formData.append('crop_right', right);
      }
    } catch (e) {
      nonCriticalException(e);
      throw new Error('An error occured trying to update the image data. Please try again.');
    }
  }

  const postUrl = buildImageCreationUrl(
    image.type,
    image.provider,
    'upload',
    authToken,
  );

  const response = await ssFetch(postUrl, {
    method: 'POST',
    body: formData,
    headers: {},
  });

  const data = await response.json();
  if (data.task_id) {
    const status = await uploadWait(data.task_id);
    await clearProviderCache();
    return status;
  }

  throw new Error(`Upload failed: ${JSON.stringify(data)}`);
}
