import React from 'react';
import { Provider } from 'react-redux';
import store from '../store';
import { LoadingLionContainer } from './shared/LoadingLion';
import PageLoadError from './shared/PageLoadError';
import StateRouterConnector from './shared/StateRouterConnector';
import { routeConfigs } from '../routes';
import InitGlobalComponents from './InitGlobalComponents';
import { ContextProvider } from './ContextProvider';
import { PersistGate } from './PersistGate';
import SSErrorBoundary from './SSErrorBoundary';

/**
 * Main React App
 *
 * !!! WARNING: BEFORE YOU ADD TO THIS FILE READ HERE !!!
 *
 * Are you trying to add a component that's needed on every page?
 * You should check out the InitGlobalComponents file instead
 *
 * Are you trying to add a new global context wrapper?
 * You should check out the ContextProvider file instead
 *
 * Are you trying to apply a common layout to a set of multiple pages?
 * Use route nesting instead and apply the common layout in the parent route page
 * with the children routes marked with `isChild`: `true`
 *
 * Are you looking for landing analytics?
 * You'll find it in the StateRouterConnector, where it can hook into navigation events.
 */
const App = () => (
  <Provider store={store}>
    <SSErrorBoundary
      info="App error boundary"
      renderFallback={() => <PageLoadError />}
    >
      <LoadingLionContainer />
      <PersistGate>
        <ContextProvider>
          <StateRouterConnector
            routes={routeConfigs}
          />
          <InitGlobalComponents />
        </ContextProvider>
      </PersistGate>
    </SSErrorBoundary>
  </Provider>
);

export default App;
