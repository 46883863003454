// @ts-strict-ignore
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { IRootDispatch } from '../store/models';
import type { ABTestVariationDetails } from '../store/ABTest';
import type { Assignment } from '../store/ABTest/ABTest.types';
import useIsEnabled from './useIsEnabled';
import { useAllowSuperUser } from './useAllowSuperUser';
import { useIsInProduction } from './useIsInProduction';
import useUserStateRedux from './useUserStateRedux';

type AssignTest = () => Promise<Assignment>;

function useAssignProTest(
  testName: string,
  percent: number,
  variations?: undefined,
  allowSuperUserInTest?: boolean
): AssignTest;

function useAssignProTest(
  testName: string,
  percent: undefined,
  variations: ABTestVariationDetails,
  allowSuperUserInTest?: boolean
): AssignTest;

/**
 * @name useAssignProTest
 * @description since pros often use multiple devices, locally assigned
 * tests can fail to segment pros. This hook bypasses this issue by adding
 * test assignments to UserState.
 *
 * @param testName - Switch/Flag name
 * @param percent - number: decimal representative of test used in A/B tests
 * @param variations - ABTestVariationDetails: for multi-variate tests
 * @param allowSuperUserInTest - boolean: should super users always be in control group
 *
 * @returns Promise<Assignment>
 */
function useAssignProTest(
  testName: string,
  percent?: number,
  variations?: ABTestVariationDetails,
  allowSuperUserInTest?: boolean,
): AssignTest {
  const enabled: boolean = useIsEnabled(testName);
  const { abTest } = useDispatch<IRootDispatch>();
  const [testState, saveTestState] = useUserStateRedux<Assignment | undefined>(testName);
  const allowSuperUser = useAllowSuperUser(allowSuperUserInTest);
  const inProduction = useIsInProduction();

  if (!(percent || variations) && percent !== 0) {
    throw new Error('useAssignProTest, either percent or variations must be specified');
  }

  if (percent && variations) {
    throw new Error('useAssignProTest, use percent or variations not both');
  }

  /* TODO: We need a version of this as a store effect */
  return useCallback<AssignTest>(
    async (): Promise<Assignment> => {
      if (!enabled) {
        return {
          inTest: false,
          isEnabled: false,
          isAssigned: true,
        };
      }

      if (!allowSuperUser) {
        return {
          inTest: false,
          isEnabled: false,
          isAssigned: true,
        };
      }
      // if testState exists and isAssigned it
      // contains all data necessary for the test
      // however if it's in a testing environment (local, LC, stage)
      // don't return it since automation needs to set it via localstorage
      if (testState?.isAssigned && inProduction) {
        return testState as Assignment;
      }

      let assignment: Assignment | undefined;

      // make assiment based on params
      if (percent) {
        assignment = await abTest.assignTest({
          name: testName,
          percent,
        });
      } else if (variations) {
        assignment = await abTest.assignTest({
          name: testName,
          variations,
        });
      } else {
        throw new Error('useAssignProTest, either percent or variations must be specified');
      }

      // save assignment to UserState for future use
      saveTestState({ ...assignment });

      return assignment;
    },
    [
      testName,
      percent,
      variations,
      abTest,
      saveTestState,
      testState,
      inProduction,
      allowSuperUser,
      enabled,
    ],
  );
}

export default useAssignProTest;
