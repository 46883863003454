import SimpleCache from '../modules/SimpleCache';
import { ssFetchJSON } from '../modules/ssFetch';

const CACHE_EXPIRE_SECS = 60;
const GeoIpCache = new SimpleCache(CACHE_EXPIRE_SECS);

type GeoIPResult = {
  city: string;
  city_continent_code: string;
  city_country_code: string;
  latitude: string;
  longitude: string;
  metro_area: string;
  postal_code: string;
  region: string;
};

export function fetchGeoIP(): Promise<GeoIPResult> {
  return ssFetchJSON<GeoIPResult>('/xxgeoip', {
    ssCache: GeoIpCache,
    throwOnHttpError: true,
  });
}
