import { SegmentEventData } from './types';
import { AnalyticEvent } from './events';
import { onboardingSegmentEventProperties, OnboardingSegmentProperty } from '../../../../../app/scripts/modules/provider/onboarding';

const ProviderOnboardingNextBestActionClicked: SegmentEventData = {
  eventName: AnalyticEvent.ProviderOnboardingNextBestActionClicked,
  eventProperties: [
    ...onboardingSegmentEventProperties,
    OnboardingSegmentProperty.NextBestAction,
  ],
};

export default ProviderOnboardingNextBestActionClicked;
