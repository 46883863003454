// @ts-strict-ignore
import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { Box } from '@styleseat/ui';

/**
 * This component intercepts onClick/onPress events on the PortalOverlay DOM layer
 * to avoid accidental interactions with components in the react DOM layer below.
 *
 * This is needed due to react-native-web. PortalElements render in the
 * PortalOverlay DOM, which is not inherently respected by react-native-web.
 * Without this, cursor clicks would appear to drop through the PortalOverlay
 * and activate underlying react-native-web components' onClick/onPress events.
 */
export const StopPropagationBoundary = ({ children }) => (
  <TouchableWithoutFeedback>
    <Box flexGrow={1} flexShrink={1} style={{ cursor: 'default' }}>
      {children}
    </Box>
  </TouchableWithoutFeedback>
);
