// @ts-strict-ignore
import moment from 'moment';
import _ from 'underscore';
import { copyValue } from '../utils/copyValue';
import { isObject } from '../utils/is';
import { valueByDate } from '../valueByDate';
import type {
  DataFields,
  GraphableDataSet,
  MasterDataSet,
} from '../types';

/**
 * Convert the dataset into a custom table of data.
 *
 * This is most commonly used to generate a data table for google charts.
 *
 * EXAMPLE:
 * -------
 * ```
 *   var data = {
 *     actual_revenue: [
 *       { date: <2016-01-01>, processed_revenue: 444.2383, nonprocessed_revenue: 269.3457},
 *       ...
 *     ]
 *     appts_booked: [
 *       { date: <2016-01-01>, appts_count: 10},
 *       ...
 *     ]
 *   }
 *   var rowTemplate = ['#date', 'actual_revenue/processed_revenue', 'appts_booked/appts_count'];
 *   var table = generateTable(data, rowTemplate);
 *   // table = [ ["2016-01-01", 444.2383, 10] ]
 * ```
 *
 * TEMPLATE:
 * --------
 * In order to generate the table, we need to know what goes into every row.
 * For this we pass in a template. Let's look at the one used in the example:
 *
 * ```
 *   ['#date', 'actual_revenue/processed_revenue', 'appts_booked/appts_count']
 * ```
 *
 * This is telling us each row will have 3 columns:
 *   + The date string
 *   + The `processed_revenue` value from the `actual_revenue` dataset
 *   field (actual_revenue.[date].processed_revenue)
 *   + The `appts_count` value from the `appts_booked` dataset
 *   field (appts_booked.[date].appts_count)
 *
 * DATE COLUMN:
 * ----------
 * If you enter `#date` as a date field, it will enter the date as it
 * appears in the data set for that row.
 *
 * You can also add the date format to the string, like this:
 *
 * ```
 *   ['#date : MMM D']
 * ```
 *
 * The text after the colon (:) should follow the momentjs formating rules:
 * http://momentjs.com/docs/#/displaying/format/
 *
 * FORMATTER:
 * ---------
 * You might want to format some of the data being put into the table.
 * In this case, you'll pass a formatter function. This function will receive the value
 * and the template name that was used and should return the formatted value.
 *
 * ```
 *   function myFormatter(key, value, date) {
 *     switch(key) {
 *
 *       case '#date':
 *         // convert to friendlier date
 *         return moment(value, 'YYYY-MM-DD').format('MMM DD');
 *
 *       case 'actual_revenue/processed_revenue':
 *         // 2 decimal precision
 *         return value.toFixed(2);
 *
 *     }
 *     return value;
 *   }
 * ```
 *
 * @param {Object} data A master dataset object
 * @param {Array} template The table row template (see above)
 * @param {Function} formatterFn A function used to format the data being put into the table.
 *                             This function will receive 3 parameters: key, value & date
 *                               = key: the template key used in the template
 *                               = value: the dataset value
 *                               = date: the date we're processing in the dataset
 *
 * @return {Array} A multi-dimensional array of rows
 */
export function asDataTable<F extends DataFields = DataFields, R = any>(
  data: MasterDataSet<F> | GraphableDataSet,
  template: string[],
  formatterFn?: (key: string, value: any, date: moment.Moment, options?: any) => R,
): (string | number)[][] {
  const dataset = copyValue(data);
  let dates = [];
  const table = [];
  const defaultDateFormat = 'YYYY-MM-DD';

  // Default formatter
  const formatter = formatterFn || function f(key, value) { return value; };

  // Generate a resolver that gets a dataset value
  // by date, field and property names.
  function createDatasetResolver(key: string, field: string, property: string) {
    return date => {
      const value = valueByDate(copyValue(dataset[field]), date);
      if (value) {
        return formatter(key, value[property], date);
      }
      return undefined;
    };
  }

  // Generate generic resolver
  function createGenericResolver(origKey: string) {
    let key: string = origKey;
    let options = null;

    // Field options come after the key name, separated by a colon
    options = typeof key === 'string' ? key.split(':') : [];
    if (options.length > 1) {
      key = options[0];
      options = options.slice(1).join(':');

      // trim space
      const trim = /^\s+|\s+$/g;
      options = options.replace(trim, '');
      key = key.replace(trim, '');
    } else {
      options = null;
    }

    // Date
    if (key === '#date') {
      return date => {
        const value = moment(date, 'YYYY-MM-DD').format(options || defaultDateFormat);
        return formatter(key, value, date, options);
      };
    }
    // Unknown, pass as entered
    return date => formatter(origKey, key, date);
  }

  // Create template field resolver functions
  // These will be used to fill in each column of data
  const resolvers = [];
  template.forEach((key, i) => {
    const path = typeof key === 'string' ? key.split('/') : [];

    if (path.length === 2 && dataset[path[0]]) {
      resolvers[i] = createDatasetResolver(key, path[0], path[1]);
    } else {
      resolvers[i] = createGenericResolver(key);
    }
  });

  // Merge the dates from all field data lists
  Object.keys(dataset).forEach(key => {
    const fieldData = dataset[key];
    if (Array.isArray(fieldData) === false) {
      return;
    }

    const fieldDates = fieldData.map(value => value.date);
    dates = dates.concat(fieldDates);
  });

  // Sort and remove duplicates
  dates.sort((a, b) => a.valueOf() - b.valueOf());
  dates = _.unique(dates, true, d => d.valueOf());

  // Build table
  dates.forEach((date, row) => {
    table[row] = [];
    resolvers.forEach((resolver, col) => {
      table[row][col] = resolver(date);
    });
  });

  return table;
}

/**
 * Go through a data table (created from generateTable) and remove any columns that
 * have no data. If no columns have any data, an empty array will be returned.
 *
 * @param {Array} data The data table used to create the chart
 * @return {Array} A new data table
 */
export function removeEmptyTableColumns(
  data: (string | number)[][],
) {
  const dataTable = [...data];
  const columnIdxs = [];
  const removeColumns = [];
  const trimmedTable = [];
  const dataRows = dataTable.slice(1);

  if (dataRows.length === 0) {
    return [];
  }

  // Get column indexes for value columns (ignore customization columns)
  for (let i = 1; i < dataTable[0].length; i++) {
    const col = dataTable[0][i];
    removeColumns[i] = false;

    if (!isObject(col)) {
      columnIdxs.push(i);
      removeColumns[i] = true;
    }
  }

  // No data columns
  if (!columnIdxs.length) {
    return [];
  }

  // Look through each column and see which ones have data
  columnIdxs.forEach(i => {
    const hasData = dataRows.some(row => typeof row[i] === 'number');

    if (hasData) {
      removeColumns[i] = false;
    }
  });

  // How many columns are we removing
  const removeCount = removeColumns.reduce((prev, curr) => ((curr === true) ? prev + 1 : prev), 0);

  if (removeCount === 0) {
    return dataTable;
  }
  if (removeCount === columnIdxs.length) {
    return [];
  }

  // Remove empty columns
  for (let rowIdx = 0; rowIdx < dataTable.length; rowIdx++) {
    const row = copyValue(dataTable[rowIdx]);

    trimmedTable[rowIdx] = [];
    _.each(row, (value, i) => {
      if (removeColumns[i] !== true) {
        trimmedTable[rowIdx].push(value);
      }
    });
  }

  return trimmedTable;
}
