// @ts-strict-ignore
import { createModel } from '@rematch/core';
import type { RootModel } from '../../../../store/models';
import { PublicProvider } from '../../../../api/Providers';
import { ProviderService } from '../../../../api/Providers/Services';

type InvitedToBookLandingPageState = {
  loading: boolean;
  proProfile: PublicProvider;
};

export type InvitedToBookLandingPageData = InvitedToBookLandingPageState & {
  serviceNames: Array<string>;
};

const InvitedToBookLandingPage = createModel<RootModel>()({
  name: 'invitedToBookPage',

  state: {
    loading: true,
    proProfile: null,
  } as InvitedToBookLandingPageState,

  reducers: {
    onLand: (
      state: InvitedToBookLandingPageState,
      payload: Partial<InvitedToBookLandingPageState>,
    ) => ({
      ...state,
      ...payload,
    }),
  },

  effects: dispatch => ({
    async land({
      providerId,
    }: {
      providerId: number;
    }) {
      const proProfile = await dispatch.providers.loadProvider({ providerId });
      await dispatch.profileServiceGroups.loadProvider({ providerId });

      await dispatch.invitedToBookPage.onLand({
        loading: false,
        proProfile,
      });

      await dispatch.loader.setIsLoading(false);
    },
  }),

  selectors: (slice, createSelector, hasProps) => ({
    getPageData: hasProps((
      models,
      { providerId, selectedServices }: { providerId: number; selectedServices: Array<string> },
    ) => createSelector(
      slice,
      models.profileServiceGroups.servicesForPro({ providerId }),
      (
        state: InvitedToBookLandingPageState,
        allProServices: Array<ProviderService>,
      ): InvitedToBookLandingPageData => {
        const services = allProServices.filter(
          service => selectedServices.indexOf(String(service.id)) > -1,
        );
        const serviceNames = services.map(service => service.name);
        return {
          ...state,
          serviceNames,
        };
      },
    )),
  }),
});

export default InvitedToBookLandingPage;
