/**
  Device widths used as responsive breakpoints by both the SCSS
  (via dynamic mixin in tasks/common.js) and JS device detection.
*/
module.exports = {
  // Primary breakpoints
  mobile: { max: 400 },
  tablet: { min: 400.1, max: 736 },
  desktop: { min: 736.1 },
  wide: { min: 1024 },

  // Edge cases
  'mobile-narrow': { max: 321 },
  'mobile-layout': { max: 736 },
  'desktop-narrow': { min: 736.1, max: 930 },
};
