// @ts-strict-ignore
import moment, { MomentInput, Moment } from 'moment';
import { createModel } from '@rematch/core';
import { ModelSelectorFactories, Selector } from '@rematch/select';
import { createSelector as createReduxSelector } from 'reselect';
import {
  AppointmentRequestParams,
  AppointmentRequestResponse,
  getAppointmentRequest,
} from '../api/AppointmentRequest';
import analytics from '../modules/analytics';
import {
  logBookingError,
  bookAppointment,
  trackBookingSuccess,
  isInNSLCWindow as checkInNSLCWindow,
  CVC_ERROR,
  baseAndSmartPrice,
  isSmartPricingEligible,
} from '../modules/consumer/Booking';
import { getIsSmartPriceEligible } from '../modules/provider/smartPricing';
import { slotToDate, slotToTime } from '../modules/timeSlotUtils';
import { isUserOptedInToSMS } from '../modules/user/phoneSettings';
import { wasPromptedForPhoneNumber } from '../modules/user/User';
import { ProviderServicePromotion } from '../api/Providers/Promotions';
import type {
  IRootDispatch,
  RootModel,
  RootState,
} from './models';
import BookingPreferences, { CLIENT_PREFERRED_CHECKOUT_TYPE_DEFAULT } from '../modules/consumer/BookingPreferences';
import { SmartPriceSource } from '../components/provider/smartPricing/constants';
import nonCriticalException from '../modules/exceptionLogger';
import {
  BookingData,
  BookingService,
  BookingState,
  BookingStatus,
  IBookingConfirmation,
} from './Booking.types';
import { NCD_TYPES, StoredDiscountInfo } from './NCDAndDiscountedPros.types';
import { INCENTIVES_DISCOUNT_CODE } from '../components/consumer/incentives/constants';
import { selectors as paymentSelectors } from './PaymentMethods';
import { findAppliedPromotion } from './ClientPromotions.model';
import {
  CLIENT_BOOKING_NCD_NO_SMART_PRICE_TEST_KEY,
  CLIENT_BOOKING_NCD_NO_SMART_PRICE_TEST_PERCENT,
  CLIENT_BOOKING_2TO7DAYS_TEST_NAME,
  CLIENT_BOOKING_2TO7DAYS_TEST_PERCENT,
  CLIENT_BOOKING_48HRAUTH_UPDATE_CARD_PROMPT_TEST_NAME,
  CLIENT_BOOKING_48HRAUTH_UPDATE_CARD_PROMPT_TEST_PERCENT,
} from '../components/consumer/booking/constants';
import { PaymentMethodType } from '../components/consumer/booking/BookingFlow/types';
import { KlarnaAnalyticEvents } from '../modules/klarna';
import * as CJTracking from '../modules/CJTracking/CJTracking';
import { BookingFeeConfig, PublicProvider } from '../api/Providers';
import { getProfileLocation } from '../api/ProfileClientView/ProfileClientView';
import type { MultiProfileServiceGroupState } from './ProfileServiceGroups';
import type { IDiscountDisplay } from './ClientPromotions.types';
import type { ProviderService } from '../api/Providers/Services';
import type { SmartPricingPreferences } from '../components/provider/smartPricing/types';

export type BookingCost = {
  baseCost: number;
  smartPriceCost: number;
};
export type PromotionsByServiceId = Record<number, Array<Pick<ProviderServicePromotion, 'getPromotionalPrice'>>>;

export type SelectTimeSlotResult = {
  resolvedSmartPrice: SmartPricingPreferences;
  atl4064Variant?: string;
};

type ActiveBookingSelector = Selector<RootState, BookingState>;
type ActiveSmartPricePreferenceSelector = Selector<RootState, SmartPricingPreferences>;
type PromotionsByServiceIdSelector = Selector<RootState, PromotionsByServiceId>;

export class BookingInterruptionError extends Error {
  nextStatus: BookingStatus;

  constructor(message: string, nextStatus: BookingStatus) {
    super(message);
    this.nextStatus = nextStatus;
  }
}

/**
 * Recalculates the status the given booking state based on other properties of the booking
 * @param booking The current booking state
 * @returns The booking state with updated status
 */
function recalculateStatus(booking: BookingState, desiredStatus?: BookingStatus): BookingState {
  let possibleStatus = desiredStatus || booking.status;

  if (!possibleStatus) {
    possibleStatus = BookingStatus.NotReady;
  }

  if (possibleStatus === BookingStatus.Ready || possibleStatus === BookingStatus.NotReady) {
    if (
      (booking.stripeInstrumentId || booking.stripeInstrumentValid)
      && typeof booking.slot === 'number'
      && booking.date
      && booking.services.length
    ) {
      possibleStatus = BookingStatus.Ready;
    } else {
      possibleStatus = BookingStatus.NotReady;
    }
  }

  booking.status = possibleStatus;

  return booking;
}

type State = {
  [key: number]: BookingState | undefined;
};

async function clearDiscountCode(
  providerId: number,
  isGuest: boolean,
  rootState,
  dispatch: IRootDispatch,
) {
  const {
    ncdAndDiscountedPros,
  } = rootState;

  // figure out the current discount code, then remove it from eligibility
  let discountCode: string;
  let rewardId: number;

  const discountInfo: StoredDiscountInfo = (
    ncdAndDiscountedPros.providers[providerId]
  );

  if (discountInfo?.discountCode) {
    if (discountInfo.discountCode !== INCENTIVES_DISCOUNT_CODE) {
      discountCode = discountInfo.discountCode;
      rewardId = discountInfo.rewardId;
    } else if (discountInfo.isNewClientAppointment || isGuest) {
      discountCode = discountInfo.discountCode;
    }
  }

  await dispatch.ncdAndDiscountedPros.clearProvider({ providerId });

  await dispatch.ncdAndDiscountedPros.removeDiscountCode({
    code: discountCode,
    rewardId,
  });
}

const buildDefaultBooking = (): BookingState => ({
  services: [],
  shouldChargePrepayment: false,
  readyToBook: false,
  status: BookingStatus.NotReady,
  cartTotals: {
    balance_remaining: '0',
    booking_fee: '0',
    deposit_due: '0',
    deposit_paid: '0',
  },
});

const activeBooking = createReduxSelector<
RootState,
number,
State,
BookingState
>(
  (rootState): number => rootState.providers.activeProviderId,
  (rootState): State => rootState.booking,
  (activeProviderId: number, state: State): BookingState => state[activeProviderId],
);

const activeSmartPricePreference = createReduxSelector(
  activeBooking,
  (currentBooking: BookingState) => currentBooking?.smartPrice,
);

const promotionsByServiceId = createReduxSelector<
RootState,
number,
MultiProfileServiceGroupState,
Record<number, ProviderServicePromotion[]>
>(
  (rootState): number => rootState.providers.activeProviderId,
  rootState => rootState.profileServiceGroups,
  (activeProviderId: number, profileServiceGroups: RootState['profileServiceGroups']) => (
    profileServiceGroups[activeProviderId]?.promotionsByServiceId
  ),
);

const bookingFeeChoice = createReduxSelector<
RootState,
number,
RootState['providers'],
BookingFeeConfig
>(
  rootState => rootState.providers.activeProviderId,
  rootState => rootState.providers,
  (providerId: number, providers: RootState['providers']): BookingFeeConfig => {
    const activeProvider = providers.providersById[providerId]?.result;

    if (!activeProvider?.booking_fees?.[BookingFeeConfig.Default]) {
      // Default config not found, fallback to OneDollar
      return BookingFeeConfig.OneDollar;
    }

    return BookingFeeConfig.Default;
  },
);

const bookingStartMoment = createReduxSelector(
  activeBooking,
  (currentBooking: BookingState): Moment | null => {
    if (
      !currentBooking
      || !currentBooking.date
      || (!currentBooking.slot && currentBooking.slot !== 0)
    ) {
      return null;
    }

    return slotToDate(currentBooking.slot, currentBooking.date);
  },
);

const isInNSLCWindow = createReduxSelector(
  (rootState: RootState) => rootState.providers.providersById[
    rootState.providers.activeProviderId
  ].result,
  bookingStartMoment,
  (provider: PublicProvider, apptStartTime: Moment): boolean => (
    checkInNSLCWindow(provider, apptStartTime)
  ),
);

async function recordBookingEvent(
  payload: {
    eventName: string;
    params: Record<string, any>;
    opts: Record<string, any>;
    providerId: number;
  },
  rootState,
) {
  const { providerId } = payload;
  const booking: BookingState = rootState.booking[providerId];
  const {
    eventName,
    params,
    opts,
  } = payload;
  const eventParams = {
    pro_id: providerId, // Old prop we prob shouldn't change in case its relied upon
    provider_id: providerId, // Using provider_id enables addt'l features in ETL script
    service_name: booking.services.map(s => s.name).join(),
    service_id: booking.services.map(s => s.id).join(),
    service_cost: booking.services.map(s => s.cost).join(),
    appointment_start_time: booking.slot,
    appointment_start_date: moment(booking.date).format('YYYY-MM-DD'),
    from_search: false,
    is_prepay_appt: booking.shouldChargePrepayment,
    payment_method_type: booking.paymentMethodType,
    ...params,
  };
  return analytics.track(eventName, eventParams, opts);
}

const isEligibleForAuth = async (
  rootState,
  provider: Pick<PublicProvider, 'preauth_enabled'>,
  isKlarna: boolean,
) => {
  const cost = baseAndSmartPrice(
    activeBooking(rootState),
    isSmartPricingEligible(rootState.providers),
    activeSmartPricePreference(rootState),
    promotionsByServiceId(rootState),
  );

  return (
    (cost.baseCost > 0 || cost.smartPriceCost > 0)
    && provider.preauth_enabled
    && !isKlarna
  );
};

const shouldAuth = async (
  dispatch: IRootDispatch,
  rootState: RootState,
  startMoment: Moment,
  provider: Pick<PublicProvider, 'preauth_enabled'>,
  isKlarna: boolean,
) => {
  const hoursInFutureForAllAuth = 48;
  const hoursInFutureForAuthTest = 168;
  const thresholdMomentForAllAuth = moment().add(hoursInFutureForAllAuth, 'hours');
  const thresholdMomentForAuthTest = moment().add(hoursInFutureForAuthTest, 'hours');

  const inEligibleAllAuthTimeWindow = startMoment.isBefore(thresholdMomentForAllAuth);
  const inEligibleAuthTestTimeWindow = startMoment.isBefore(thresholdMomentForAuthTest);

  const eligible = isEligibleForAuth(rootState, provider, isKlarna);

  if (eligible) {
    if (inEligibleAllAuthTimeWindow) {
      const shouldHaltBooking = (await dispatch.abTest.assignTest({
        name: CLIENT_BOOKING_48HRAUTH_UPDATE_CARD_PROMPT_TEST_NAME,
        percent: CLIENT_BOOKING_48HRAUTH_UPDATE_CARD_PROMPT_TEST_PERCENT,
      })).inTest;

      return { shouldAuthCard: true, shouldHaltBooking };
    }

    const shouldAuthCard = (
      inEligibleAuthTestTimeWindow
        ? (await dispatch.abTest.assignTest({
          name: CLIENT_BOOKING_2TO7DAYS_TEST_NAME,
          percent: CLIENT_BOOKING_2TO7DAYS_TEST_PERCENT,
        })).inTest
        : false
    );
    return { shouldAuthCard, shouldHaltBooking: false };
  }
  /* Not eligible */
  return { shouldAuthCard: false, shouldHaltBooking: false };
};

export default createModel<RootModel>()({
  name: 'booking',
  state: {} as State,
  reducers: {
    updateState(state: State, payload: Partial<State>) {
      return {
        ...state,
        ...payload,
      };
    },

    onUpdateServices(state: State, {
      providerId,
      services,
    }: {
      providerId: number;
      services: BookingService[];
    }) {
      return {
        ...state,
        [providerId]: recalculateStatus({
          ...state[providerId],
          services,
        }),
      };
    },

    onStartLoadingDeposits(state: State, {
      providerId,
    }: {
      providerId: number;
    }) {
      return {
        ...state,
        [providerId]: {
          ...state[providerId],
          cartLoaded: false,
        },
      };
    },

    onUpdateTotals(state: State, {
      providerId,
      totals,
    }: {
      providerId: number;
      totals: AppointmentRequestResponse;
    }) {
      return {
        ...state,
        [providerId]: {
          ...state[providerId],
          cartTotals: totals.totals,
          cartMetadata: {
            ...totals.metadata,
          },
          cartLoaded: true,
        },
      };
    },

    updateTimeSlot(state: State, {
      providerId,
      date,
      slot,
      smartPrice,
      forceServerSmartPricingDisabled,
      atl4064Variant,
    }: {
      providerId: number;
      date: MomentInput;
      slot: number;
      smartPrice?: SmartPricingPreferences;
      forceServerSmartPricingDisabled?: boolean;
      atl4064Variant?: string;
    }) {
      return {
        ...state,
        [providerId]: recalculateStatus({
          ...state[providerId],
          services: state[providerId]?.services || [],
          date: moment(date).toISOString(),
          slot,
          smartPrice,
          forceServerSmartPricingDisabled,
          atl4064Variant,
        }),
      };
    },

    clearTimeSlot(state: State, {
      providerId,
    }: {
      providerId: number;
    }) {
      return {
        ...state,
        [providerId]: recalculateStatus({
          ...state[providerId],
          slot: undefined,
          smartPrice: null,
        }),
      };
    },

    clearDate(state: State, {
      providerId,
    }: {
      providerId: number;
    }) {
      return {
        ...state,
        [providerId]: recalculateStatus({
          ...state[providerId],
          slot: undefined,
          smartPrice: null,
          date: null,
        }),
      };
    },

    onUpdateBooking(state: State, payload: {
      providerId: number;
      booking: Partial<BookingState>;
    }): State {
      const {
        providerId,
        booking,
      } = payload;

      return {
        ...state,
        [providerId]: recalculateStatus({
          ...state[providerId],
          services: booking.services || state[providerId]?.services || [],
          ...booking,
        }, booking.status),
      };
    },

    onStart(state: State, {
      providerId,
      rescheduleAppointmentId,
    }: {
      providerId: number;
      rescheduleAppointmentId?: number;
    }) {
      if (!providerId) {
        return state;
      }

      return {
        ...state,
        [providerId]: { ...buildDefaultBooking(), rescheduleAppointmentId },
      };
    },
  },

  effects: dispatch => ({
    async shouldAuth(payload: {
      startMoment: Moment;
      provider: Pick<PublicProvider, 'preauth_enabled'>;
      isKlarna: boolean;
    }, rootState) {
      return shouldAuth(
        dispatch,
        rootState,
        payload.startMoment,
        payload.provider,
        payload.isKlarna,
      );
    },

    onServiceSelected(payload: {
      providerId: number;
      service: BookingService;
    }, rootState) {
      const { providerId, service } = payload;
      const { booking: state } = rootState;

      const providerBooking = {
        ...state[providerId] || buildDefaultBooking(),
      };
      const serviceIndex = providerBooking.services.findIndex(svc => svc.id === service.id);

      if (serviceIndex <= -1) {
        providerBooking.services = [
          ...providerBooking.services,
          {
            ...service,
            addOns: [],
          },
        ];
      } else {
        const newServiceList = [...providerBooking.services];
        newServiceList.splice(serviceIndex, 1);
        providerBooking.services = newServiceList;
      }

      dispatch.booking.updateState({
        [providerId]: providerBooking,
      });
    },

    onAddOnListUpdate(payload: {
      baseService: BookingService;
      serviceAddOns: ProviderService[];
      providerId: number | string;
    }, rootState) {
      const {
        baseService, serviceAddOns, providerId,
      } = payload;
      const { booking: state } = rootState;

      const providerBooking = {
        ...state[providerId] || buildDefaultBooking(),
      };

      const serviceIndex = providerBooking.services.findIndex(svc => svc.id === baseService.id);

      if (serviceIndex <= -1) {
        return;
      }

      providerBooking.services[serviceIndex].addOns = serviceAddOns;

      dispatch.booking.updateState({
        [providerId]: providerBooking,
      });
    },

    updateServices(
      payload: {
        providerId: number;
        services: BookingService[];
      },
    ) {
      dispatch.booking.onUpdateServices(payload);
    },

    async updateTotals(payload: {
      providerId: number;
      params: AppointmentRequestParams;
    }) {
      if (!payload.params) return;

      try {
        dispatch.booking.onStartLoadingDeposits({ providerId: payload.providerId });
        const totals = await getAppointmentRequest(payload.providerId, payload.params);
        dispatch.booking.onUpdateTotals({ totals, providerId: payload.providerId });
      } catch (e) {
        nonCriticalException(e);
      }
    },

    selectServicesByIds(payload: {
      providerId: number;
      serviceIds: Array<number>;
    }, rootState) {
      const { providerId, serviceIds } = payload;
      const { booking: state } = rootState;

      const providerBooking = {
        ...state[providerId] || buildDefaultBooking(),
      };

      const serviceGroups = rootState.profileServiceGroups[providerId];
      if (serviceGroups) {
        providerBooking.services = serviceIds
          .map(serviceId => serviceGroups.servicesById[serviceId])
          .filter(Boolean);
      }

      dispatch.booking.updateState({
        [providerId]: recalculateStatus(providerBooking),
      });
    },

    async updateBooking(payload: {
      providerId: number;
      booking: Partial<BookingState>;
    }): Promise<void> {
      await dispatch.booking.onUpdateBooking(payload);
    },

    // this effect allows us to get the updated booking state within `completeBooking`. Without it,
    // we get the return value of the reducer of the same name, which is not what we want.
    async checkBooking(payload: {
      providerId: number;
    }, rootState): Promise<BookingState> {
      return rootState.booking[payload.providerId];
    },

    async completeBooking(payload: {
      providerId: number;
      clientToken?: string;
    }, rootState): Promise<BookingState> {
      const { providerId, clientToken } = payload;
      let booking: BookingState = rootState.booking[providerId];
      const userData = rootState.user;
      const recordEvent = async (eventName?: string, params?: Record<string, any>) => (
        recordBookingEvent({
          providerId,
          eventName,
          params,
          opts: undefined,
        }, rootState)
      );

      if (booking.status === BookingStatus.Pending) {
        throw new BookingInterruptionError(
          'Booking already in progress',
          BookingStatus.Pending,
        );
      }

      // Just in case the request doesn't go through we don't want an infinite loop
      await dispatch.booking.updateBooking({
        providerId,
        booking: {
          status: BookingStatus.Pending,
        },
      });
      booking = await dispatch.booking.checkBooking({ providerId });

      await recordEvent('Booking - Started');
      const provider = rootState.providers.providersById[providerId]?.result;
      const routeParams = rootState.route.params;
      const {
        stripeCvcToken,
        stripeInstrumentId,
      } = booking;
      // the ncdAndDiscountedPros model will include ncd data which comes from
      // the NCD recommended pro attribution (hours-ago based)
      // this logic does not check ncd_type.
      const ncdAndDiscountData: StoredDiscountInfo | undefined = (
        rootState.ncdAndDiscountedPros.providers[providerId]
      );
      const isRescheduling = (
        (rootState.route.params.reschedule === true || rootState.route.params.reschedule === 'true')
        && rootState.route.params.rescheduleAppointmentId
      );

      const isGuest = userData.is_anon;
      const startTime = slotToTime(booking.slot);
      const startMoment = moment(`${moment(booking.date).format('YYYY-MM-DD')} ${startTime}`);

      let response: IBookingConfirmation;

      let { rescheduleAppointmentId } = routeParams;

      if (typeof rescheduleAppointmentId === 'string') {
        rescheduleAppointmentId = parseInt(rescheduleAppointmentId, 10);
      }

      const smartPriceSource: SmartPriceSource = (
        booking.smartPriceSource || booking.smartPrice?.source
      ) as SmartPriceSource;

      const smartPriced = (
        booking.forceServerSmartPricingDisabled
          ? false
          : await getIsSmartPriceEligible(providerId)
      );

      const { shouldAuthCard } = await dispatch.booking.shouldAuth({
        startMoment,
        provider,
        isKlarna: booking.paymentMethodType === PaymentMethodType.Klarna,
      });

      // Always use default checkout type for Guests. Fetch it for Users if it's not in the store
      const checkoutPreference = isGuest ? CLIENT_PREFERRED_CHECKOUT_TYPE_DEFAULT : (
        booking.checkoutPreference
          ?? await BookingPreferences.calculateCheckoutPreference(startMoment)
      );

      const appointmentIdsAndAddOns = booking.services.map(service => {
        const addOnsBefore = service?.addOns?.filter(addOn => addOn.add_on_time_preference === 'BEFORE')?.map(addOn => addOn.id) || [];
        const addOnsAfter = service?.addOns?.filter(addOn => addOn.add_on_time_preference === 'AFTER')?.map(addOn => addOn.id) || [];
        return [
          ...addOnsBefore,
          service.id,
          ...addOnsAfter,
        ];
      });

      const cjParams = await CJTracking.getParams();
      try {
        response = await bookAppointment({
          checkoutPreference,
          shouldAuthCard,
          shouldHaltBooking: booking.shouldAuthFailureHaltBooking,
          ncdAndDiscountData,
          paymentMethodType: booking.paymentMethodType,
          routeParams,
          note: booking.note,
          providerId,
          serviceIds: appointmentIdsAndAddOns.flat(),
          startDate: moment(booking.date),
          startSlot: booking.slot,
          stripeCvcToken: stripeCvcToken ? String(stripeCvcToken) : '',
          stripeInstrumentId: stripeInstrumentId ? String(stripeInstrumentId) : '',
          wasBookedFromSearch: false,
          // We need to send this value from the FE so that mobile apps that do not
          // yet support smart price do not get the upcharge upon booking.
          // We can remove this once we can confirm that 100% of apps
          // support smart priced appointments.
          smartPriced,
          smartPriceSource,
          atl4064Variant: booking.atl4064Variant,
          discountCode: findAppliedPromotion(rootState)?.discountCode,
          rewardId: findAppliedPromotion(rootState)?.rewardId,
          clientToken,
          isRescheduling,
          reschedulingId: rescheduleAppointmentId,
          bookingFeeChoice: bookingFeeChoice(rootState),
          recordEvent,
          tipAmount: booking.tipAmount,
          tipPercentage: booking.tipPercentage,
          cjParams,
        });
        CJTracking.clearParams();
      } catch (error) {
        if (error.status !== 200) {
          await recordEvent('Booking - Error', error);
          logBookingError({
            step: 'Booking.js onBookingFailure',
            provider,
            user: userData,
            reason: error.data,
            code: error.status,
          });

          // RES-119 open update payment method popup when auth failed due to insufficient funds
          if (error.code === 402
            && booking.shouldAuthFailureHaltBooking
            && error.data?.reason?.includes('insufficient_funds')) {
            await dispatch.booking.updateBooking({
              providerId,
              booking: {
                error,
                status: BookingStatus.UpdatePaymentMethod,
              },
            });

            throw new BookingInterruptionError(
              'This card does not have enough funds to cover the service plus tip.',
              BookingStatus.UpdatePaymentMethod,
            );
          } else if (error.code === 402 && booking.paymentMethodType === PaymentMethodType.Klarna) {
            throw new BookingInterruptionError(
              'Please try again. We were unable to complete payment through Klarna.',
              BookingStatus.UpdatePaymentMethod,
            );
          }

          await dispatch.booking.updateBooking({
            providerId,
            booking: {
              error, // in the future, move parsing of this error message into this model
              status: BookingStatus.Error,
            },
          });

          throw error;
        } else if (error.data.not_client_ready === true) {
          await dispatch.booking.updateBooking({
            providerId,
            booking: {
              status: BookingStatus.RequestingPhoneNumber,
            },
          });

          throw new BookingInterruptionError(
            `Please provide your phone number to Book with ${provider.name}`,
            BookingStatus.RequestingPhoneNumber,
          );
        } else if (error.data.discount_code) {
          await clearDiscountCode(
            providerId,
            isGuest,
            rootState,
            dispatch,
          );

          await dispatch.booking.updateBooking({
            providerId,
            booking: {
              error, // in the future, move parsing of this error message into this model
            },
          });

          throw error;
        } else {
          await recordEvent('Booking - Error', error);
          await dispatch.booking.updateBooking({
            providerId,
            booking: {
              error, // in the future, move parsing of this error message into this model
              status: BookingStatus.Error,
            },
          });
          throw error;
        }
      }

      let coords = '<unknown>';
      const { location: locationCoords } = response;

      dispatch.user.setClientHandle(response.client_handle);

      if (locationCoords) {
        const { latitude, longitude } = locationCoords;

        if (latitude || longitude) {
          coords = `${latitude}, ${longitude}`; // technically Y, X format
        }
      }

      let isAutocharge: boolean = false;
      let isAutocheckout: boolean = false;

      if (response.timeblocks && response.timeblocks.length > 0) {
        isAutocharge = response.timeblocks[0].is_expresspay;
        isAutocheckout = response.timeblocks[0].is_autocheckout;
      }

      // Ignore tracking failures due to privacy extensions/firewalls, so that
      // the client can complete the booking flow after a successful booking
      try {
        await trackBookingSuccess(
          provider,
          booking,
          userData,
          routeParams,
          rootState.profileServiceGroups[provider.id]?.servicesById,
          ncdAndDiscountData,
          response,
          rootState.utmParameters,
        );

        const location = getProfileLocation(provider);

        await recordEvent('Booking - Booked', {
          appointment_id: response.appointment_id,
          autocharge_enabled: isAutocharge,
          autocheckout_enabled: isAutocheckout,
          salon_coords: coords,
          mobile_business: location?.mobile_business,
          is_prepay_appt: booking.shouldChargePrepayment,
        });

        await clearDiscountCode(
          providerId,
          isGuest,
          rootState,
          dispatch,
        );

        await dispatch.booking.updateBooking({
          providerId,
          booking: {
            status: BookingStatus.Completed,
            confirmation: response,
          },
        });
        booking = await dispatch.booking.checkBooking({ providerId });
      } catch (e) {
        nonCriticalException(e);
      }

      return booking;
    },

    async phoneNumberPrompted(payload: {
      providerId: number;
      clientToken?: string;
    }): Promise<BookingState> {
      return dispatch.booking.completeBooking(payload);
    },

    async acceptNSLC(payload: {
      providerId: number;
      clientToken?: string;
      phoneNumberCancelled?: boolean;
    }, rootState): Promise<BookingState> {
      const { providerId, phoneNumberCancelled } = payload;
      const booking: BookingState = rootState.booking[providerId];
      const userData = rootState.user;

      if (!userData.is_anon) {
        const isSMSOptIn = await isUserOptedInToSMS(userData.userId);
        const wasPhonePrompted = await wasPromptedForPhoneNumber(undefined);
        // Show phone confirmation step
        if (
          (!isSMSOptIn && (!wasPhonePrompted || !booking.smsOptInShown)) || phoneNumberCancelled
        ) {
          await wasPromptedForPhoneNumber(true);
          await dispatch.booking.updateBooking({
            providerId,
            booking: {
              smsOptInShown: true,
              status: BookingStatus.RequestingPhoneNumber,
            },
          });

          throw new BookingInterruptionError(
            'Where should we send your StyleSeat booking reminders and receipt?',
            BookingStatus.RequestingPhoneNumber,
          );
        } else {
          return dispatch.booking.completeBooking(payload);
        }
      } else {
        return dispatch.booking.completeBooking(payload);
      }
    },

    async submitBooking(payload: {
      providerId: number;
      clientToken?: string;
    }, rootState): Promise<BookingState> {
      const { providerId, clientToken } = payload;
      const booking: BookingState = rootState.booking[providerId];
      const isGuest = rootState.user.is_anon;

      if (
        // this is the normal case for booking
        booking?.status !== BookingStatus.Ready
        // this is the case for when an error occurs
        && booking?.status !== BookingStatus.Error
        // this can happen if someone cancels the NSLC prompt and retries
        && booking?.status !== BookingStatus.NSLCConfirmation
      ) {
        // if none of those cases applies...
        throw new Error('Appointment not ready to be booked.');
      }

      // start by determining the payment method
      let card = booking.selectedCard || paymentSelectors.getDefault(rootState);
      const cardIsValid = booking.stripeInstrumentId || booking.stripeInstrumentValid;
      let cvcToken = booking.stripeCvcToken;

      if (booking.paymentMethodType === PaymentMethodType.Card) {
        if (card && cardIsValid) {
          if (card.requires_cvc_validation && !cvcToken) {
            throw new Error(CVC_ERROR);
          }
          if (!isGuest && !card.is_default) {
            await (dispatch as IRootDispatch).paymentMethods.updateDefault({
              card,
              cvcToken: cvcToken ? String(cvcToken) : undefined,
            });
            // stripe's CVC tokens are one-time use. if we we successfully set the card as
            // default, we've used up any token provided to validate the CVC. we now throw
            // the token away so the booking flow doesn't fail trying to reuse it
            cvcToken = undefined;
          }
        } else if (
          // Submit credit card if the form is present and the user has entered
          // something into it.
          !isGuest && rootState.paymentMethods.newCard?.valid
        ) {
          card = await (dispatch as IRootDispatch).paymentMethods.saveNewCard();
        }

        await dispatch.booking.updateBooking({
          providerId,
          booking: {
            stripeCvcToken: cvcToken,
            stripeInstrumentId: card.id,
          },
        });
      } else if (booking.paymentMethodType === PaymentMethodType.Klarna) {
        recordBookingEvent({
          eventName: KlarnaAnalyticEvents.Selected,
          providerId,
          params: {
            source: 'bookingcheckout',
          },
          opts: undefined,
        }, rootState);
      }
      // now that we have a payment method, we're ready to continue our journey

      // Confirm NSLC if booking in NSLC window and pro is NSLC
      if (isInNSLCWindow(rootState)) {
        await dispatch.booking.updateBooking({
          providerId,
          booking: {
            status: BookingStatus.NSLCConfirmation,
          },
        });

        throw new BookingInterruptionError(
          'Appointment start time is within 24 hours',
          BookingStatus.NSLCConfirmation,
        );
      } else {
        return dispatch.booking.acceptNSLC({
          providerId,
          clientToken,
        });
      }
    },

    async selectTimeSlot({
      providerId,
      date,
      timeSlot,
      smartPrice,
    }: {
      providerId: number;
      date: Date;
      timeSlot: number;
      smartPrice: SmartPricingPreferences;
    }, rootState): Promise<SelectTimeSlotResult> {
      let forceServerSmartPricingDisabled: boolean = false;
      let atl4064Variant: string;
      const {
        ncdAndDiscountedPros,
      } = rootState;
      let resolvedSmartPrice = smartPrice ?? activeBooking(rootState)?.smartPrice;

      if (!!smartPrice
        && ncdAndDiscountedPros?.providers?.[providerId]?.ncd_type === NCD_TYPES.NCD) {
        const testConfig = await dispatch.abTest.assignTest({
          name: CLIENT_BOOKING_NCD_NO_SMART_PRICE_TEST_KEY,
          percent: CLIENT_BOOKING_NCD_NO_SMART_PRICE_TEST_PERCENT,
        });
        atl4064Variant = testConfig.variationName;
        if (testConfig.inTest) {
          resolvedSmartPrice = undefined;
          forceServerSmartPricingDisabled = true;
        }
      }

      dispatch.booking.updateTimeSlot({
        providerId,
        slot: timeSlot,
        date,
        smartPrice: resolvedSmartPrice,
        forceServerSmartPricingDisabled,
        atl4064Variant,
      });

      return {
        resolvedSmartPrice,
        atl4064Variant,
      };
    },
  }),

  selectors: (slice, createSelector): ModelSelectorFactories<RootModel, Record<string, never>> => ({
    activeBooking() {
      return activeBooking;
    },

    activeSmartPricePreference() {
      return activeSmartPricePreference;
    },

    paymentMethodType(models) {
      return createSelector(
        models.booking.activeBooking,
        (currentBooking: BookingState) => currentBooking?.paymentMethodType,
      );
    },

    promotionsByServiceId() {
      return promotionsByServiceId;
    },

    useSmartPrice(models) {
      return createSelector(
        models.providers.isSmartPricingEligible,
        models.booking.activeSmartPricePreference,
        (smartPricingEligible: boolean, smartPrice: SmartPricingPreferences) => (
          smartPricingEligible && !!smartPrice
        ),
      );
    },

    baseAndSmartPrice(models) {
      return createSelector<
      RootState,
      BookingState,
      boolean,
      SmartPricingPreferences,
      PromotionsByServiceId,
      BookingCost
      >(
        models.booking.activeBooking as unknown as ActiveBookingSelector,
        models.providers.isSmartPricingEligible,
        models.booking.activeSmartPricePreference as unknown as ActiveSmartPricePreferenceSelector,
        models.booking.promotionsByServiceId as unknown as PromotionsByServiceIdSelector,
        baseAndSmartPrice,
      );
    },

    bookingDuration(models) {
      return createSelector(
        models.booking.activeBooking,
        (currentBooking: BookingState): number => (currentBooking?.services || [])
          .reduce((acc, cur) => {
            const serviceDuration = cur.duration_minutes || 0;
            const addOnsDuration = (cur.addOns || []).reduce(
              (acc1, addOn) => acc1 + (addOn.duration_minutes || 0),
              0,
            );
            return acc + serviceDuration + addOnsDuration;
          }, 0),
      );
    },

    bookingStartMoment() {
      return bookingStartMoment;
    },

    bookingStart(models) {
      return createSelector(
        models.booking.bookingStartMoment,
        (startMoment: Moment | null): Date | null => {
          if (!startMoment) {
            return null;
          }

          return startMoment.toDate();
        },
      );
    },

    bookingEndMoment(models) {
      return createSelector(
        models.booking.bookingStartMoment,
        models.booking.bookingDuration,
        (startMoment: Moment | null, duration: number): Moment | null => {
          if (!startMoment) {
            return null;
          }

          return startMoment.clone().add(duration, 'minutes');
        },
      );
    },

    bookingEnd(models) {
      return createSelector(
        models.booking.bookingEndMoment,
        (bookingEndMoment: Moment | null): Date | null => {
          if (!bookingEndMoment) {
            return null;
          }

          return bookingEndMoment.toDate();
        },
      );
    },

    isRequestReady(models) {
      return createSelector(
        models.booking.activeBooking,
        (currentBooking: BookingState): boolean => !!(
          currentBooking
          && currentBooking.date
          && (currentBooking.slot || currentBooking.slot === 0)
          && currentBooking.services.length > 0
          && currentBooking.status === BookingStatus.Ready
        ),
      );
    },

    isInNSLCWindow() {
      return isInNSLCWindow;
    },

    bookingData(models) {
      return createSelector(
        models.booking.activeBooking,
        models.booking.bookingStartMoment,
        models.booking.bookingEndMoment,
        (
          currentBooking: BookingState,
          startMoment: Moment,
          endMoment: Moment,
        ): BookingData => ({
          activeBooking: currentBooking,
          startMoment,
          endMoment,
        }),
      );
    },

    bookingFeeChoice() {
      return bookingFeeChoice;
    },

    bookingFeeAmountDollars(models) {
      return createSelector<RootState, BookingState, number>(
        models.booking.activeBooking as unknown as ActiveBookingSelector,
        (booking: BookingState): number => (
          Number(booking?.cartTotals?.booking_fee) || 0
        ),
      );
    },

    cartLoaded(models) {
      return createSelector(
        models.booking.activeBooking,
        (booking: BookingState): boolean => booking?.cartLoaded || false,
      );
    },

    depositRequired(models) {
      return createSelector(
        models.booking.activeBooking,
        (booking: BookingState): boolean => {
          const depositRequired = !!booking?.cartMetadata?.deposit_required;
          const depositDue = booking?.cartTotals?.deposit_due || 0;
          return depositRequired && Number(depositDue) > 0;
        },
      );
    },

    bookingInfo(models) {
      return createSelector(
        models.booking.activeBooking,
        models.providers.activeProvider,
        models.clientPromotions.appliedPromotion,
        (
          booking: BookingState | null,
          activeProvider: PublicProvider | null,
          appliedPromotion: IDiscountDisplay | null,
        ) => {
          if (!booking || !activeProvider) {
            return {};
          }

          return {
            activeBooking: booking,
            activeProvider,
            appliedPromotion,
          };
        },
      );
    },
  }),
});
