// @ts-strict-ignore
import { createModel } from '@rematch/core';
import { ModelSelectorFactories } from '@rematch/select';
import { IProviderClient } from '../../api/Providers/Clients/types';
import {
  addWaitlistItem,
  deleteWaitlistItem,
  getWaitlist,
  getWaitlistItem,
  IWaitlistItem,
  IWaitlistItemDetails,
  updateWaitlistItemNote,
} from '../../api/Providers/Waitlist';
import { getAppointmentColorMap, ServiceColorMap } from '../../modules/provider/Appointments/AppointmentColor';
import { CombinedWaitlistRequestDetails, getFullWaitlistRequest } from '../../modules/provider/Waitlist';
import type { RootModel } from '../models';

export type ProviderWaitlistState = {
  providerId: number | null;
  selectedItemId: number | null;
  details: IWaitlistItemDetails | null;
  list: IWaitlistItem[];
  serviceColors: ServiceColorMap;
  loaded: boolean;
};

type LandPayload = {
  providerId: number;
  selectedItemId?: number;
};

type SelectItemPayload = {
  selectedItemId: number;
  providerId: number;
};

type RemoveWaitlistItemPayload = {
  itemId: number;
};

type UpdateNotePayload = {
  itemId: number;
  note: string;
};

type OnLandPayload = {
  providerId: number;
};

type OnLandedPayload = {
  providerId: number;
  items: IWaitlistItem[];
};

type OnDetailsLoadingPayload = {
  providerId: number;
  itemId: number;
};

type OnDetailsLoadedPayload = {
  providerId: number;
  details: IWaitlistItemDetails;
  serviceColors: ServiceColorMap;
};

type CreatePayload = {
  providerId: number;
  clientId: number;
  note: string;
};

type OnCreatedPayload = {
  newItems: IWaitlistItem[];
  providerId: number;
};

type OnUpdatedNotePayload = {
  itemId: number;
  note: string;
};

type OnRemovedPayload = {
  itemId: number;
};

function getInitialState(): ProviderWaitlistState {
  return {
    providerId: null,
    list: [],
    selectedItemId: null,
    details: null,
    loaded: false,
    serviceColors: {},
  };
}

export const providerWaitlist = createModel<RootModel>()({
  name: 'providerWaitlist',

  state: getInitialState(),

  reducers: {
    'user/logout': () => getInitialState(),

    onLand: (
      state: ProviderWaitlistState,
      { providerId }: OnLandPayload,
    ): ProviderWaitlistState => ({
      ...state,
      providerId,
      loaded: false,
    }),

    onLanded: (
      state: ProviderWaitlistState,
      { providerId, items }: OnLandedPayload,
    ): ProviderWaitlistState => {
      if (providerId !== state.providerId) {
        return state;
      }

      return ({
        ...state,
        providerId,
        list: items,
        loaded: true,
      });
    },

    onUnselectItem: (state: ProviderWaitlistState): ProviderWaitlistState => ({
      ...state,
      selectedItemId: null,
      details: null,
    }),

    onDetailsLoading: (
      state: ProviderWaitlistState,
      { providerId, itemId }: OnDetailsLoadingPayload,
    ): ProviderWaitlistState => ({
      ...state,
      providerId,
      selectedItemId: itemId,
      details: null,
      loaded: false,
    }),

    onDetailsLoaded: (
      state: ProviderWaitlistState,
      {
        providerId,
        details,
        serviceColors,
      }: OnDetailsLoadedPayload,
    ): ProviderWaitlistState => {
      if (details.id !== state.selectedItemId) {
        return state;
      }

      return ({
        ...state,
        providerId,
        details,
        serviceColors,
        loaded: true,
      });
    },

    onRemoved: (
      state: ProviderWaitlistState,
      { itemId }: OnRemovedPayload,
    ): ProviderWaitlistState => ({
      ...state,
      selectedItemId: null,
      details: null,
      list: state.list.filter(i => i.id !== itemId),
    }),

    onCreated: (
      state: ProviderWaitlistState,
      { newItems, providerId }: OnCreatedPayload,
    ): ProviderWaitlistState => {
      if (providerId !== state.providerId) {
        return state;
      }

      return ({
        ...state,
        providerId,
        list: newItems,
      });
    },

    onUpdatedNote: (
      state: ProviderWaitlistState,
      { note }: OnUpdatedNotePayload,
    ): ProviderWaitlistState => ({
      ...state,
      details: { ...state.details, note } as IWaitlistItemDetails,
    }),
  },

  effects: dispatch => ({
    async land({ providerId }: LandPayload): Promise<void> {
      dispatch.providerWaitlist.onLand({
        providerId,
      });
      const items = await getWaitlist(providerId);
      dispatch.providerWaitlist.onLanded({
        providerId,
        items: items.results,
      });
    },

    async selectItem({
      selectedItemId,
      providerId,
    }: SelectItemPayload): Promise<void> {
      dispatch.providerWaitlist.onDetailsLoading({
        itemId: selectedItemId,
        providerId,
      });

      const [details, serviceColors] = await Promise.all([
        getWaitlistItem(providerId, selectedItemId),
        getAppointmentColorMap(providerId),
      ]);

      await dispatch.providerClients.loadClient({ providerId, clientId: details.client_id });

      dispatch.providerWaitlist.onDetailsLoaded({
        providerId,
        serviceColors,
        details,
      });
    },

    async unselectItem(): Promise<void> {
      dispatch.providerWaitlist.onUnselectItem();
    },

    async remove(
      { itemId }: RemoveWaitlistItemPayload,
      state,
    ): Promise<void> {
      await deleteWaitlistItem(Number(state.providerWaitlist.providerId), itemId);
      dispatch.providerWaitlist.onRemoved({ itemId });
    },

    async updateNote(
      { itemId, note }: UpdateNotePayload,
      state,
    ): Promise<void> {
      await updateWaitlistItemNote(
        Number(state.providerWaitlist.providerId),
        itemId,
        note,
      );

      dispatch.providerWaitlist.onUpdatedNote({ itemId, note });
    },

    async create(
      {
        providerId,
        clientId,
        note,
      }: CreatePayload,
    ): Promise<void> {
      await addWaitlistItem(providerId, {
        client_id: clientId,
        note,
      });

      const items = await getWaitlist(providerId);
      dispatch.providerWaitlist.onCreated({ newItems: items.results, providerId });
    },
  }),

  selectors: (slice, createSelector): ModelSelectorFactories<RootModel, any> => ({
    list() {
      return slice((state: ProviderWaitlistState): IWaitlistItem[] => state.list);
    },

    loaded() {
      return slice((state: ProviderWaitlistState) => state.loaded);
    },

    selectedItemId() {
      return slice((state: ProviderWaitlistState) => state.selectedItemId);
    },

    selectedRequestClient() {
      return createSelector(
        slice((state: ProviderWaitlistState) => Number(state.details?.client_id)),
        state => state.providerClients.clients,
        (clientId, clients): IProviderClient | undefined => (clients?.[clientId]),
      );
    },

    detailsData(models) {
      return createSelector(
        slice((state: ProviderWaitlistState) => state.details),
        slice((state: ProviderWaitlistState) => state.serviceColors),
        models.providerWaitlist.selectedRequestClient,
        models.providers.activeProvider,
        (
          waitlistRequest,
          serviceColors,
          client: IProviderClient,
          provider,
        ): CombinedWaitlistRequestDetails | null => {
          if (!waitlistRequest || !client || !provider) {
            return null;
          }

          return getFullWaitlistRequest(waitlistRequest, serviceColors, provider, client);
        },
      );
    },
  }),
});
