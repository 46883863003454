import { useSelector } from 'react-redux';
import type { RootState } from '../store/models';

export type FeatureFlagData = {
  loading: boolean;
  enabled: boolean;
  inTest: boolean;
};

/**
 * A hook for returning the value of a feature flag.
 *
 * @param {string} featureFlagName - The name of the feature flag/switch.
 * @return {FeatureFlagData} - An object specifying loading, enabled and inTest.
 */
const useFeatureFlagRedux = (featureFlagName: string): FeatureFlagData => {
  const [
    isLoaded, isEnabled, inTest,
  ] = useSelector((state: RootState) => (
    [
      state.abTest.isLoaded,
      state.abTest.assignments[featureFlagName]?.isEnabled,
      state.abTest.assignments[featureFlagName]?.inTest,
    ]
  ), (oldValues, newValues) => (
    oldValues[0] === newValues[0] && oldValues[1] === newValues[1] && oldValues[2] === newValues[2]
  ));

  return {
    loading: !isLoaded,
    enabled: isLoaded && isEnabled,
    inTest: isLoaded && inTest,
  };
};

export default useFeatureFlagRedux;
