import React from 'react';
import classes from './Typography.module.scss';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'body-large'
  | 'body'
  | 'body-medium'
  | 'body-small'
  | 'body-smaller'
  | 'legal';

type Props = {
  variant: Variant;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  noWrap?: boolean;
};

const variants: {
  [Property in Variant]: {
    Component: keyof JSX.IntrinsicElements;
    className: string;
  }
} = {
  h1: { Component: 'h1', className: classes.typographyH1 },
  h2: { Component: 'h2', className: classes.typographyH2 },
  h3: { Component: 'h3', className: classes.typographyH3 },
  h4: { Component: 'h4', className: classes.typographyH4 },
  'body-large': { Component: 'p', className: classes.typographyP1 },
  body: { Component: 'p', className: classes.typographyP2 },
  'body-medium': { Component: 'p', className: classes.typographyP3 },
  'body-small': { Component: 'p', className: classes.typographyP4 },
  'body-smaller': { Component: 'p', className: classes.typographyP5 },
  legal: { Component: 'p', className: classes.typographyLegal },
};

export const Typography: React.FunctionComponent<Props> = ({
  variant,
  children,
  style,
  className,
  noWrap,
}) => {
  const typography = variants[variant];

  return (
    <typography.Component
      className={`${className ? `${className} ` : ''}${typography.className}`}
      style={{
        whiteSpace: !noWrap ? 'unset' : 'nowrap',
        ...style,
      }}
    >
      {children}
    </typography.Component>
  );
};
