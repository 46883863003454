// we just have a couple of small error classes in here
// eslint-disable-next-line max-classes-per-file
export enum GalleryImageType {
  ProviderUpload = 1,
  ProviderProfile = 2,
  ClientUpload = 3,
  LocationUpload = 4,
  ProviderMobile = 5,
  ProductImg = 6,
  ClientProfile = 8,
  ProviderGalleryImport = 9,
  ProviderProfileImport = 10,
  ProviderInstagramSync = 11,
}

export enum PrivacyChoices {
  Public = 1,
  Private = 2,
  Newsletter = 3,
  Reviews = 4,
}

export interface IBasicImage {
  id: number;
  url: string;
}

export interface ProfileGalleryImage extends IBasicImage {
  provider: number;
  service_id: number | null;
  blurb: string;
  alt_text: string | null;
}

export interface IGalleryImage extends ProfileGalleryImage {
  client: number | null;
  url: string;
  path_root: string;
  hearted?: boolean;
  cover_image?: boolean;
  crop_left: number | null;
  crop_right: number | null;
  crop_top: number | null;
  crop_bottom: number | null;
  rotate: number;
  appointment_id: number | null;
  order: number;
  type: GalleryImageType;
}

export type GalleryImageForImport = IGalleryImage & {
  data: any; // a raw File instance
  variant: string;
  supportsAdvancedFields: boolean;
};

export type ImageUploadParams = Partial<{
  crop: Partial<{
    rotate: number;
    box: Partial<{
      top: number;
      left: number;
      right: number;
      bottom: number;
    }>
  }>;
  serviceId: number;
  apptId: number;
  isCover: boolean;
  privacy: PrivacyChoices;
  clientId: number;
  order: number;
  blurb: string;
  uploaderInstagramID: string;
}>;

// from BaseGalleryImageView
type BatchUploadResponseForbiddenError = {
  message: string;
};

// from BaseGalleryImageView
type BatchUploadResponseBadRequestError = {
  status: 'error';
  error: {
    non_field_errors?: Array<string>
  } & Record<string, Array<string>>
};

// from BaseGalleryImageView
type BatchUploadResponseSuccess = {
  status: 'OK';
  task_id: string;
};

export type BatchUploadResponse = BatchUploadResponseSuccess
| BatchUploadResponseForbiddenError
| BatchUploadResponseBadRequestError;

export type ImageUploadResult = {
  cancel: () => void;
  result: Promise<BatchUploadResponse>;
};

export type BatchUploadResult = {
  cancel: () => void;
  result: Promise<Array<string>>;
};

export class BatchImportError extends Error {
  readonly errors: Array<Error>;

  constructor(message: string, errors: Array<Error>) {
    super(message);
    this.errors = [...errors];
  }
}

export class BatchImportCancelledError extends Error { }

export type UploadProgressEvent = {
  numTotal: number;
  numComplete: number;
};

export type PhotoPayload = {
  public?: boolean;
  url: string | File;
  userId?: string;
  caption?: string;
  clientId?: number;
  serviceId?: number;
  isCover?: boolean;
  crop?: ImageUploadParams['crop'];
};
