// @ts-strict-ignore
import { Moment } from 'moment';
import { ProviderService } from '../Services';

export enum PromotionDiscountType {
  Percent = 'percentage',
  Amount = 'flat-rate',
}

export type PromotionResponse = {
  best_services: number[];
  client_redemption_limit: number;
  description: string;
  disabled_time: string | null;
  discount_quantity: string; // always a floating point number as a string
  discount_type: PromotionDiscountType;
  book_end_time: string;
  id: number | null;
  name: string | null;
  provider: number;
  services: number[] | null; // can this be null?
  book_start_time: string;
  creation_time: string | null;
  modification_time: string | null;
};

export interface PromotionPayload {
  best_services: number[];
  client_redemption_limit: number;
  description: string;
  disabled_time: string | null;
  discount_quantity: string; // always a floating point number as a string
  discount_type: PromotionDiscountType;
  book_end_time: string;
  id: number | null;
  name: string | null;
  provider: number;
  services: ProviderService[] | number[] | null; // TODO: fix; can this be null?
  book_start_time: string;
}

export interface SerializedPromotion extends PromotionPayload {
  creation_time: string | null;
  modification_time: string | null;
}

export interface ProviderServicePromotion {
  best_services: any[];
  client_redemption_limit: number;
  creation_time: Moment | null;
  description: string;
  disabled_time: null | Moment;
  discount_quantity: number;
  discount_type: PromotionDiscountType;
  book_end_time: Moment;
  id: number | null;
  name: string | null;
  provider;
  services: ProviderService[] | null;
  book_start_time: Moment;
  modification_time: null | Moment;
  getFormattedDiscount: () => string;
  getPromotionalPrice: (cost: number) => number;
  getSerializedObject: () => PromotionPayload;
  isCurrent: () => boolean;
  isFuture: () => boolean;
  isPast: () => boolean;
  isStopped: () => boolean;
  isDeleted: () => boolean;
}
