// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useOnce } from '../../../hooks';

export type AppsFlyerSmartBannerType = {
  isVisible: boolean;
};

export const AppsFlyerSmartBanner: React.FC<AppsFlyerSmartBannerType> = ({
  isVisible,
}) => {
  const [isBannerClose, setIsBannerClose] = useState<boolean>(true);

  const domChangedCallback = () => {
    const closeButton = document
      .querySelector('div')
      .querySelectorAll('[data-qa-id="close-button"]');
    if (closeButton.length > 0) {
      setIsBannerClose(false);
    }
    closeButton[0]?.addEventListener('click', () => {
      setIsBannerClose(true);
    });
  };
  useOnce(() => {
    // Listen DOM changes to add on click event for banner close button once banner is inserted
    const config = {
      childList: true,
    };
    const targetNode = document.getElementById('smart-banner-container');
    const observer = new MutationObserver(domChangedCallback);
    observer.observe(targetNode, config);
  }, []);

  useEffect(() => {
    if (!isVisible) {
      window?.AF(
        'banners',
        'hideBanner',
      );
    } else {
      window?.AF(
        'banners',
        'showBanner',
        { bannerContainerQuery: '#smart-banner-container', bannerZIndex: 1 },
      );
    }
  }, [isVisible]);

  return (
    <div id="smart-banner-container">
      {!isBannerClose && isVisible ? (
        <style>
          {`
            .react-responsive-layout {
              position: absolute;
              top: 88px !important;
            }
          `}
        </style>
      ) : null}
    </div>
  );
};
