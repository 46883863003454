// @ts-strict-ignore
import moment, { MomentInput, unitOfTime } from 'moment';
import type { Moment } from 'moment';

export const MINUTES_PER_SLOT = 15;
const SLOTS_PER_HOUR = 60 / MINUTES_PER_SLOT;
const SLOTS_PER_DAY = 24 * SLOTS_PER_HOUR;

export type TimeSlotInput = string | Date | Moment;

export type ITimeSection = {
  name: string;
  startTime: string;
  startTimeFormatted: string;
  endTime: string;
  endTimeFormatted: string;
};

export const TIME_SECTIONS: ITimeSection[] = [
  {
    name: 'Morning',
    startTime: '6:00',
    endTime: '12:00',
    startTimeFormatted: '6:00am',
    endTimeFormatted: '12:00pm',
  },
  {
    name: 'Afternoon',
    startTime: '12:00',
    endTime: '16:00',
    startTimeFormatted: '12:00pm',
    endTimeFormatted: '4:00pm',
  },
  {
    name: 'Evening',
    startTime: '16:00',
    endTime: '20:00',
    startTimeFormatted: '4:00pm',
    endTimeFormatted: '8:00pm',
  },
  {
    name: 'Night',
    startTime: '20:00',
    endTime: '6:00',
    startTimeFormatted: '8:00pm',
    endTimeFormatted: '6:00am',
  },
];

export const ALL_TIME_SLOTS = Array(SLOTS_PER_DAY).fill(0).map((_, i) => i);

/**
 * Return a moment date object for the slot number
 *
 * @param slotIdx - The slot number
 * @param dayIn - The day the slot is on. If not provided, defaults to today
 * @returns Moment or null if the slot is invalid
 */
export function slotToDate(slotIdx: string | number, dayIn?: MomentInput): Moment {
  const slot = parseInt(slotIdx as unknown as string, 10);

  if (Number.isNaN(slot) || slot < 0 || slot > SLOTS_PER_DAY) {
    return null;
  }

  // Convert day to moment
  let day;
  if (!dayIn) {
    day = moment();
  } else {
    day = moment(dayIn);
  }
  day.startOf('day');

  // Get hours and minutes
  const hours = Math.floor(slot / SLOTS_PER_HOUR);
  const mins = (slot % SLOTS_PER_HOUR) * MINUTES_PER_SLOT;

  day.hour(hours);
  day.minute(mins);

  return day;
}

/**
 * Given the slot number, return the time that it represents
 * in 24 hour format
 *
 * @param {int} slot - The slot number
 * @returns {String}
 */
export function slotToTime(slot, format: string = 'H:mm') {
  const date = slotToDate(slot);
  if (date) {
    return date.format(format);
  }
  return null;
}

/**
 * Converts a time string to a slot number
 *
 * @param {String | Moment | Date} timeIn - A time string (in 24 hour format) or moment object
 */
export function timeToSlot(timeIn: TimeSlotInput) {
  // Normalize time
  let time;

  if (!timeIn) {
    return null;
  }

  if (typeof timeIn === 'string') {
    time = moment(timeIn, 'HH:mm');
  } else if (timeIn instanceof Date) {
    time = moment(timeIn);
  } else if (moment.isMoment(timeIn)) {
    time = timeIn;
  } else {
    throw new Error(`Invalid time value: ${timeIn}`);
  }

  const hours = time.hours();
  const minutes = time.minutes();
  return (hours * SLOTS_PER_HOUR) + Math.floor(minutes / MINUTES_PER_SLOT);
}

/**
 * Gets the set of timeslots contained in the time range.
 * This also works across midnight.
 *
 * @param {String | Moment | Date} startTime - A time string (in 24 hour format) or moment object
 * @param {String | Moment | Date} endTime - A time string (in 24 hour format) or moment object
 */
export const getTimeSlotsForTimeRange = (
  startTime: TimeSlotInput,
  endTime: TimeSlotInput,
) => {
  const startSlot = timeToSlot(startTime);
  const endSlot = timeToSlot(endTime);
  if (!startTime || !endTime) {
    return [];
  }
  if (startSlot > endSlot) {
    return [
      ...getTimeSlotsForTimeRange(startTime, '23:59'),
      ...getTimeSlotsForTimeRange('0:00', endTime),
    ];
  }
  return (new Array(endSlot - startSlot + 1)).fill(startSlot).map((slot, i) => slot + i);
};

/**
 * This function translates 24 hour time formatted like H:mm
 * to 12 hour am/pm time
 *
 * Note that this is currently only used by an Angular filter
 * @param {string} timeData 24 hour time string formatted as H:mm
 */
export function timeToAmPm(timeData) {
  if (!timeData) {
    return null;
  }

  const timeMoment = moment(timeData, 'H:mm');
  return timeMoment.format('h:mm A');
}

export function padTimeZone(offset = 0) {
  const z = '0';
  const justNumber = String(Math.abs(offset));
  let sign = '';
  if (offset < 0) {
    sign = '-';
  }
  const result = justNumber.length >= 2
    ? justNumber
    : new Array(2 - justNumber.length + 1).join(z) + justNumber;
  return sign + result;
}

/**
 * Rounds a given moment to the start of the nearest time slot. Adapted from moment-round.
 * @param momentValue The value to round
 * @returns The resulting value
 * @see https://github.com/SpotOnInc/moment-round
 */
export function roundToNearestTimeslot(
  momentValue: Moment,
): Moment {
  const maxValues: Partial<Record<unitOfTime.All, number>> = {
    hours: 24,
    minutes: 60,
    seconds: 60,
    milliseconds: 1000,
  };
  let value = 0;
  let rounded = false;
  let subRatio = 1;
  let maxValue: number;

  Object.keys(maxValues).forEach((k: unitOfTime.All) => {
    const theMax = maxValues[k].valueOf();
    if (k === 'minutes') {
      value = momentValue.get(k);
      maxValue = theMax;
      rounded = true;
    } else if (rounded) {
      subRatio *= maxValues[k];
      value += momentValue.get(k) / subRatio;
      momentValue.set(k, 0);
    }
  });

  value = Math.round(value / MINUTES_PER_SLOT) * MINUTES_PER_SLOT;
  value = Math.min(value, maxValue);

  momentValue.set('minutes', value);

  return momentValue;
}
