import { createModel } from '@rematch/core';
import type { RootModel } from '../models';

/**
 * A model for managing the visibility of the global lion loader.
 */
export const loader = createModel<RootModel>()({
  state: {
    isLoading: true,
  },
  reducers: {
    setIsLoading: (state, payload: boolean) => ({
      isLoading: payload,
    }),
  },

  // empty effects object is needed so typescript picks up the correct type
  // for the reducers and doesn't confuse them with effects
  effects: () => ({}),

  selectors: slice => ({
    loading() {
      return slice(state => state.isLoading);
    },
  }),
});
