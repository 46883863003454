import React from 'react';

export default function useComponentOrReduxState<T>(
  selector: (...passed: any[]) => T,
  ...args: any[]
): [value: T, setState: (t: T) => void] {
  const reduxResult: T = selector(...args);
  const hasComponentStateRef = React.useRef<boolean>(false);

  const [localValue, setLocalValue] = React.useState<T>(reduxResult);

  React.useEffect(() => {
    if (!hasComponentStateRef.current) {
      setLocalValue(reduxResult);
    }
  }, [reduxResult, setLocalValue, hasComponentStateRef]);

  const wrappedSetLocalValue = (value: T) => {
    hasComponentStateRef.current = true;
    setLocalValue(value);
  };

  return [localValue, wrappedSetLocalValue];
}
