// @ts-strict-ignore
import { MergedCalendar } from './mergeExternalCalendars';

export enum CalendarSyncStatus {
  NotSynced = 1,
  Syncing = 2,
  Unsyncing = 3,
  Synced = 4,
}

/**
 * Mapping from calendar_id to calendar status
 */
export type ExternalCalendarStatusMap = Record<number | string, CalendarSyncStatus>;

export function determineSyncStatus(calendar: MergedCalendar): CalendarSyncStatus {
  let status;

  if (calendar.is_syncing) {
    status = CalendarSyncStatus.Syncing;
  } else if (calendar.is_unsyncing) {
    status = CalendarSyncStatus.Unsyncing;
  } else if (calendar.is_synced) {
    status = CalendarSyncStatus.Synced;
  } else {
    status = CalendarSyncStatus.NotSynced;
  }

  return status;
}

/**
 * Build a mapping from calendar_id to calendar sync status using flag fields in the given objects.
 * @param calendars List of calendar proxy objects from the API
 * @returns A mapping from calendar_id (not to be confused with calendar id) to calendar sync
 * status.
 */
export function mapSyncStatus(
  calendars: MergedCalendar[],
): Record<number | string, CalendarSyncStatus> {
  const result: Record<number | string, CalendarSyncStatus> = {};

  calendars.forEach(calendar => {
    result[calendar.id] = determineSyncStatus(calendar);
  });

  return result;
}

interface DiffSyncStatusResult {
  newSynced: number[];
  newUnsynced: number[];
}

export function diffSyncStatus(
  oldMappings: ExternalCalendarStatusMap,
  newMappings: ExternalCalendarStatusMap,
): DiffSyncStatusResult {
  const newSynced = [];
  const newUnsynced = [];

  Object.entries(newMappings).forEach(([calendarId, status]) => {
    const prevStatus = oldMappings[calendarId];
    if (
      status === CalendarSyncStatus.Synced
      && prevStatus
      && prevStatus !== CalendarSyncStatus.Synced
    ) {
      newSynced.push(calendarId);
    }

    if (
      status === CalendarSyncStatus.NotSynced
      && prevStatus
      && prevStatus !== CalendarSyncStatus.NotSynced
    ) {
      newUnsynced.push(calendarId);
    }
  });

  return { newSynced, newUnsynced };
}
