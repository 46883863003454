// @ts-strict-ignore
import loadable from '@loadable/component';
import React, {
  createContext,
  useCallback,
  useRef,
  useState,
} from 'react';

import analytics from '../../../../modules/analytics';
import { checkAttPermission } from '../../../../modules/analytics/AppTrackingTransparency';
import { AuthOrigins } from '../../../../modules/user/constants';
import { useShowSubscriptionFeatures } from '../../../../hooks/useShowSubscriptionFeatures';

const LoginModalAdapter = loadable(() => import('../LoginModalAdapter'), {
  resolveComponent: mod => mod.LoginModalAdapter,
});

export enum LoginModalMode {
  Login = 'login',
  Signup = 'signup',
  ClientLogin = 'clientlogin',
  ClientSignup = 'clientsignup',
}

export enum LoginResultAction {
  Signup = 'signup',
  Login = 'login',
  Cancel = 'cancel',
}

type LoginModalPayload = {
  mode: LoginModalMode;
  origin?: AuthOrigins;
  message?: string;
  fullHeight?: boolean;
  zIndex?: number;
  initialEmail?: string;
  useNewExperience?: boolean;
  loginRequired: boolean;
  helperText?: string;
};

type LoginModalResult = {
  userData: Record<string, any>;
  action: LoginResultAction;
};

export type LoginModalFunction = (payload: LoginModalPayload) => Promise<LoginModalResult>;

export const LoginModalContext = createContext<LoginModalFunction>(() => Promise.reject());

export const LoginModalProvider: React.FCWithChildren = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loginProps, setLoginProps] = useState<LoginModalPayload | null>(null);
  const loginPromise = useRef<Promise<LoginModalResult>>();
  const loginResolve = useRef<(value: LoginModalResult) => void>();
  const loginReject = useRef<(reason?: any) => void>();
  const tab = (loginProps?.mode || '').includes('login') ? 'Log In' : 'Sign Up';

  const [canShowSubscriptionFeatures] = useShowSubscriptionFeatures();

  const doLogin = useCallback((payload: LoginModalPayload): Promise<LoginModalResult> => {
    if (!Promise.allSettled([loginPromise.current])) {
      return loginPromise.current;
    }

    loginPromise.current = new Promise<LoginModalResult>((resolve, reject) => {
      loginResolve.current = resolve;
      loginReject.current = reject;

      setLoginProps(payload);
      setShowModal(true);
    });

    return loginPromise.current;
  }, []);

  const handleClose = useCallback((trackingParams: Record<string, any>) => {
    if (loginProps?.loginRequired) {
      analytics.track('client_loginpage_closed', {
        ...trackingParams,
        initialTab: tab,
        origin: loginProps?.origin,
      });
    }
    setShowModal(false);
  }, [
    loginProps?.origin,
    loginProps?.loginRequired,
    tab,
  ]);

  const handleLoginSuccess = async (userData: Record<string, any>) => {
    await checkAttPermission();
    setShowModal(false);
    loginResolve.current({
      action: LoginResultAction.Login,
      userData,
    });
  };

  const handleSignupSuccess = async (userData: Record<string, any>) => {
    await checkAttPermission();
    setShowModal(false);
    loginResolve.current({
      action: LoginResultAction.Signup,
      userData,
    });
  };

  return (
    <LoginModalContext.Provider value={doLogin}>
      {children}
      {showModal && (
        <LoginModalAdapter
          canShowSubscriptionFeatures={canShowSubscriptionFeatures}
          onClose={handleClose}
          onLoginSuccess={handleLoginSuccess}
          onSignupSuccess={handleSignupSuccess}
        />
      )}
    </LoginModalContext.Provider>
  );
};
