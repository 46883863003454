import moment, { MomentInput, unitOfTime } from 'moment';
import { Moment } from '../dateUtils';

function fromNow(mode: 'Day' | 'Week' | 'Month') {
  return function formatDate(dateInput: MomentInput): string {
    const date: Moment = moment(dateInput);
    const momentMode: unitOfTime.Diff = mode.toLowerCase() as unitOfTime.Diff;

    const start = moment().startOf(momentMode);
    const diff = date.clone().startOf(momentMode).diff(start, momentMode);

    if (mode === 'Day') {
      const day_of_week = date.format('dddd');
      switch (diff) {
        case 0:
          return `Today - ${day_of_week}`;
        case 1:
          return `Tomorrow - ${day_of_week}`;
        case -1:
          return `Yesterday - ${day_of_week}`;
        default:
          return day_of_week;
      }
    } else if (diff === 0) {
      return `This ${mode}`;
    } else {
      const number = Math.abs(diff);
      const word = number > 1 ? `${mode}s` : mode;
      const suffix = diff < 0 ? 'Ago' : 'Out';

      return [number, word, suffix].join(' ');
    }
  };
}

export default {
  day: fromNow('Day'),
  week: fromNow('Week'),
  month: fromNow('Month'),
};
