import React from 'react';

export default function useDefaultState<T>(defaultValue: T): [T, (val: T) => void] {
  const [hasSet, setHasSet] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<T>(defaultValue);

  React.useEffect(() => {
    if (!hasSet) {
      setValue(defaultValue);
    }
  }, [hasSet, defaultValue]);

  const wrappedSetValue = React.useCallback<(newValue: T) => void>((newValue: T) => {
    setHasSet(true);
    setValue(newValue);
  }, []);

  return [value, wrappedSetValue];
}
