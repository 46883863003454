import localforage from 'localforage';
/**
 * Sets/gets the localstorage value of "WAS_PROMPTED_FOR_PHONE_NUMBER"
 * @param wasPrompted if provided it will SET the value to the given value
 * @returns {Promise<boolean | void>}
 */
export const wasPromptedForPhoneNumber = function (wasPrompted) {
  const key = 'WAS_PROMPTED_FOR_PHONE_NUMBER';
  if (typeof wasPrompted !== 'undefined') {
    return localforage.setItem(key, wasPrompted);
  }

  return !!localforage.getItem(key);
};
