// @ts-strict-ignore
export type SanitizedProperties = {
  appointment_id?: number | string;
  appointment_provider_id?: number | string;
  checkout_type?: string;
  city?: string;
  client_has_card?: boolean;
  client_user_id?: number;
  client_id?: number;
  is_client_first_booking_with_pro?: boolean;
  is_mobile?: boolean;
  is_new_client_appointment?: boolean;
  is_new_client_delivery?: boolean;
  is_paid_new_client_delivery?: boolean;
  is_smart_price?: boolean;
  location?: string; // loc
  matched_service_id?: number | string; // matchedserviceid
  ncd_type?: number;
  new_client_appointment?: boolean;
  opt_in?: boolean;
  plan_name?: string;
  platform?: string;
  pro_id_viewed?: number;
  result_pro_ids?: number[];
  salon_coords?;
  salon_id_viewed?: number;
  salon_id?: number;
  salon_pro_ids?: number[];
  search_id?: string;
  service_cost?: number;
  service_ids?: number[];
  service_index_in_group?: number[];
  services_to_book?: number[];
  smart_priced?: boolean;
  status?;
  url?: string;
  smsEnabled?: boolean;
  sub_providers?: number[];
  test_name?: string;
  test_percent?: number;
  test_variation?: string;
  tip_amount?: number;
  title?: string;
  toggle_clicked?: boolean;
  total?: number;
  tracked_client?;
  user_rating_source?: string;
  user_type?: string;
  booking_source?: string;
  campaign_template_name?: string;
  city_country_code?: string;
  client_sms_opt_in?: boolean;
  client_type?: string;
  control?;
  cost?: number;
  created_by?: string;
  current_screen?: string;
  date_filter_start?: string;
  date_filter_type?: string;
  days_left_in_boost?: string;
  displayed_pros?: number[];
  enabled?: boolean;
  event_id?: string;
  groups?;
  has_phone_mobile?: boolean;
  is_app?: boolean;
  is_oldie?: boolean;
  is_on_commission_plan?: boolean;
  is_prepay_appt?: boolean;
  is_pro_found_through_ss_search_tool?: boolean;
  mobile_number?: string;
  ncd_fee_percent?: number;
  ncd_tracking_token?: string;
  nslc_eligible?: boolean;
  nslc_screen_action?: string;
  num_photos?: number;
  num_reviews?: number;
  offset?;
  online_booking_enabled?: boolean;
  page_key?: string;
  page_route?: string;
  percent_complete?: number;
  phone_number_required?: boolean;
  plan?: string;
  popular_service?;
  postal_code?: string;
  prepay_amount?: string;
  product_name?: string;
  search_source?: string;
  search_term?: string;
  service_index?: number;
  service_names?: string[];
  servicename?: string;
  sms_text?;
  source?: string;
  sourceurl?: string;
  test_group?: string;
  topservice_index?: number;
  type?: string;
  vanityurl?: string;
  is_map_search?: boolean;
  logged_in?: boolean;
  query?: string;
  region?: string;
  appointment_type?: string;
  autocharge_enabled?: boolean;
  autocheckout_enabled?: boolean;
  client_sms_enabled?: boolean;
  from_page?: string;
  has_email?: boolean;
  is_payment_pro?: boolean;
  is_premium_appt?: boolean;
  is_pro?: boolean;
  pre_pay_eligible?: boolean;
  pro_name?: string;
  pro_nslc_policy?: string;
  react_search?: boolean;
  search_location?;
  calendar_view_date?;
  selected_month?: string;
  signup_method?: string;
  standard_service?;
  total_photos_in_gallery?: number;
  value?;
  'x-real-ip'?;
  checkout_payment_type?;
  checkout_prompt_clicked?: boolean;
  custom_tip?;
  imageid?: number;
  is_autocheckout_appt?: boolean;
  is_first_booking?: boolean;
  is_monetized_ncd?: boolean;
  is_monetized_new_client_delivery?: boolean;
  new_pro_search_boost_num_attributed_appointments?: boolean;
  paid_ncd_eligible?: boolean;
  photo_id?: number;
  photo_index?: number;
  premium_upcharge_rate?;
  product_clicked?;
  af_revenue?: number;
};

/**
 * preferInteger - takes an argument which might be a number or something else.
 * If the argument can be converted to an integer it gets converted to a number
 * otherwise the original argument is passed through un-altered.
 *
 * @param arg anything.
 */
function preferInteger(arg: any): number | string | undefined {
  if (typeof arg === 'number') {
    return arg as number;
  }

  if (typeof arg === 'string') {
    const result: number = Number.parseInt(arg, 10);

    if (Number.isNaN(result)) {
      return arg as string;
    }

    return result;
  }

  return undefined;
}

/**
 * Replaces properties with blank strings with a '?'.
 * @param {Object} properties Hash of properties to filter
 * @returns {Object} Hash of properties, with empty strings set to '?'.
 */
export const sanitize = function (properties = {}) {
  const result: SanitizedProperties = {};
  // Trim and replace empty props with a question mark
  Object.entries(properties)
    .forEach(([key, value]) => {
      if (value && key === 'appointment_provider_id') {
        result.appointment_provider_id = preferInteger(value);
      } else if (value && (key === 'matchedserviceid' || key === 'matched_service_id')) {
        result.matched_service_id = preferInteger(value);
      } else {
        result[key] = value;
      }
    });

  // List of properties to convert to ints
  const intProps = ['provider_id'];

  // Check and fix props that should be an int
  intProps.forEach(key => {
    if (key in result && typeof (result[key]) === 'string') {
      result[key] = Number.parseInt(result[key], 10);
    }
  });

  return result;
};
