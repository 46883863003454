// @ts-strict-ignore
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APISubscriptionCoupon } from '../../../../../api/Providers/SubscriptionCoupon';
import { IRootDispatch, RootState } from '../../../../../store/models';

export const useCoupon = (couponId: string | null = null) => {
  const dispatch = useDispatch<IRootDispatch>();
  const coupon = useSelector<RootState, APISubscriptionCoupon>(state => state.providerPlan.coupon);

  /**
   * Fetch Subscription Coupon from the query string params, if present
   */
  const loadCoupon = useCallback(async (
    providerId: number | null = null,
  ) => {
    if (!coupon && couponId) {
      await dispatch.providerPlan.loadCoupon({ providerId, couponId });
    }
  }, [
    coupon,
    couponId,
    dispatch.providerPlan,
  ]);

  /** Apply a Subscription Coupon to Pro for future use */
  const applyCoupon = useCallback(async (providerId: number) => {
    if (!couponId) return;

    const props = {
      providerId,
      couponId,
    };

    return dispatch.providerPlan.applyCoupon({ ...props });
  }, [
    couponId,
    dispatch.providerPlan,
  ]);

  const hasDiscount = useMemo(() => coupon?.amount_off > 0, [coupon?.amount_off]);

  const couponAmountOff = useMemo(() => {
    const couponAmount = coupon?.amount_off ?? 0;
    if (!couponAmount) return 0;

    return couponAmount / 100;
  }, [coupon?.amount_off]);

  const monthlySavings = useCallback((monthlyCost: number) => {
    if (!couponAmountOff) return monthlyCost;

    const newCost = monthlyCost - couponAmountOff;

    if (newCost <= 0) return 0;

    return newCost;
  }, [couponAmountOff]);

  return {
    coupon,
    hasDiscount,
    couponAmountOff,
    applyCoupon,
    loadCoupon,
    monthlySavings,
  };
};
