// @ts-strict-ignore
/* eslint-disable no-param-reassign */
import { createModel } from '@rematch/core';
import { ModelSelectorFactories } from '@rematch/select';
import moment from 'moment';
import { Selector } from 'reselect';
import {
  geocodeFromAddress,
  geocodeFromPlaceId,
  geocodeFromPosition,
} from '../modules/maps/geocode';
import { getGeoIP } from '../modules/consumer/DeviceLocation';
import { getItem, setItem } from '../modules/KeyValueStorage';
import {
  matchIgnoringCase,
  toSlugCase,
  unSlugify,
  unSlugifyAddress,
} from '../modules/stringUtils';
import nonCriticalException from '../modules/exceptionLogger';
import { SEARCH_PARAM_RESET_CODE, SEARCH_TEST_CONFIG } from '../modules/consumer/search/Constants';
import {
  trackSearch,
  filterResults,
  DEFAULT_TRACKING_EVENT_NAME,
} from '../modules/consumer/search/searchHelpers';
import { getTimeSlotsForTimeRange } from '../modules/timeSlotUtils';
import type { RootModel, RootState } from './models';
import { SEARCH_ROUTE } from '../route-names';
import { recordSearchAppearance } from '../api/Providers';
import { generateTestFlags } from './ABTest/ABTest.service';
import {
  createSearch,
  generateSearchID,
  ISearchResult,
  SearchSuggestionData,
  SearchConfig,
} from '../modules/consumer/search/Search';
import { SortOptions } from './Search.types';
import type { LocationRecord } from './Location.model';
import { LAST_SEARCH_DESKTOP_LOCATION } from '../components/consumer/search/SearchPage/Constants';

export interface BoundingBox {
  topLeftLat: number;
  topLeftLon: number;
  bottomRightLat: number;
  bottomRightLon: number;
}

export type LocationInfo = BoundingBox & {
  address: string;
  latitude: number;
  longitude: number;
  placeId: string;
};

const PAGE_SIZE = 30;

const DATE_FORMAT = 'YYYY-MM-DD';

export function unslugifySearchParams(params) {
  /** *
   * Ensures that the query and address parameters are no longer in slug format
   */
  params.query = unSlugify(params.query);
  params.address = unSlugifyAddress(params.address);
  // modifying in place, but return for clarity.
  return params;
}

export function slugifySearchParams(params) {
  /** *
   * Ensures that the query and address parameters are in slug format.
   */
  const result = { ...params };
  const query = result.query || result.q;
  const address = result.address || result.loc;

  if (query) {
    result.query = toSlugCase(decodeURI(query));
    result.q = result.query;
  }

  if (address) {
    result.address = toSlugCase(decodeURI(address));
    result.loc = result.address;
  }

  return result;
}

function isNumber(value) {
  // null and empty string both evaluate to 0 when converted to a number, whereas undefined and
  // non-numeric strings evaluate to NaN.
  return value !== null && value !== '' && !Number.isNaN(Number(value));
}

/**
 * Update the address in search filters state for a latitude and longitude.
 * @param {Number} latitude
 * @param {Number} longitude
 * @param {Boolean} useFiltered
 * @yields {String} "<City>, <State>"
 */
async function getCityStateFromCoords(latitude, longitude, useFiltered): Promise<string> {
  const address = await geocodeFromPosition({
    latitude,
    longitude,
  }, useFiltered);

  return [
    address.city,
    address.state,
  ].filter(Boolean).join(', ');
}

/**
 * Set the lat/long coordinates for a particular address or placeId.
 * @param {String} address - A textual address
 * @param {Number} placeId - An ID returned from google APIs.
 * @param {Boolean} useFiltered - Whether or not to use the DP358 test index.
 * @yields {Object} Latitude/longitude coordinates
 */
function getCoordsForAddress(
  address,
  placeId,
  useFiltered: boolean = false,
): Promise<{ latitude: number | undefined; longitude: number | undefined }> {
  if (placeId) {
    return geocodeFromPlaceId(placeId, useFiltered).then(result => ({
      latitude: result.Place.Geometry.Point[1],
      longitude: result.Place.Geometry.Point[0],
    })).catch(e => {
      nonCriticalException(e);
      return { latitude: undefined, longitude: undefined };
    });
  }

  return geocodeFromAddress(address).then(({
    latitude,
    longitude,
  }) => ({
    latitude,
    longitude,
  })).catch(e => {
    nonCriticalException(e);
    return { latitude: undefined, longitude: undefined };
  });
}

/**
 * Used to determine if one of the core parameters has changed.
 * Currently only if date, query, address, or bounding box changes do we actually
 * fire a new search.
 * @param prevParams - object with (date, query, address, and lat/lon) properties!
 * @param nextParams - object with (date, query, address, and lat/lon) properties!
 * @returns {boolean}
 */
function isNewSearch(prevParams, nextParams) {
  if (!matchIgnoringCase(prevParams.query, nextParams.query)
    || !matchIgnoringCase(prevParams.address, nextParams.address)
    || prevParams.topLeftLat !== nextParams.topLeftLat
    || prevParams.topLeftLon !== nextParams.topLeftLon
    || prevParams.bottomRightLat !== nextParams.bottomRightLat
    || prevParams.bottomRightLon !== nextParams.bottomRightLon
    || prevParams.sort !== nextParams.sort
  ) {
    return true;
  }

  return false;
}

/**
 * Used to determine if one of the core parameters has changed.
 * Currently only if date, query, or address change do we actually
 * fire a new search.
 * @param prevParams - object with (date, query, and address) properties!
 * @param nextParams - object with (date, query, and address) properties!
 * @returns {boolean}
 */
function isValueUpdated(prevValue, nextValue) {
  const notUndefinedPrevValue = prevValue ?? null;
  const notUndefinedNextValue = nextValue ?? null;
  const hasPrevValue = !!notUndefinedPrevValue && notUndefinedPrevValue !== SEARCH_PARAM_RESET_CODE;
  const hasNextValue = !!notUndefinedNextValue && notUndefinedNextValue !== SEARCH_PARAM_RESET_CODE;
  return (hasPrevValue !== hasNextValue || notUndefinedPrevValue !== notUndefinedNextValue);
}

/**
 * Get's the search parameters out of the current search state.
 * @param search - the Search.model state.
 * @returns {{
 * date: *,
 * topLeftLon: *,
 * address: *,
 * query: *,
 * latitude: *,
 * bottomRightLon: *,
 * bottomRightLat: *,
 * topLeftLat: *,
 * longitude: *
 * }}
 */
function getParamsFromState(search) {
  const {
    date,
    timeSections,
    query,
    address,
    latitude,
    longitude,
    topLeftLat,
    topLeftLon,
    bottomRightLat,
    bottomRightLon,
    sort,
    hairType,
    numberOfDays,
  } = search;

  return {
    date,
    timeSections,
    query,
    address,
    latitude,
    longitude,
    topLeftLat,
    topLeftLon,
    bottomRightLat,
    bottomRightLon,
    sort,
    hairType,
    numberOfDays,
  };
}

/**
 * Used get a valid date from the url or user input.
 * @param inputDate - (String)
 * @param routeDate - (String)
 * @returns {null|*}
 */
function getDate(inputDate = null) {
  if (!inputDate || inputDate === SEARCH_PARAM_RESET_CODE) {
    return inputDate;
  }

  const inputMoment = moment(inputDate);

  if (inputMoment.isValid()) {
    return inputMoment.format(DATE_FORMAT);
  }

  return null;
}

function getInput(input, ...params) {
  if (input === SEARCH_PARAM_RESET_CODE) {
    return null;
  }

  return input || params.find(p => !!p);
}

/**
 * Update the location filters and automatically geocode any missing information.
 * @param {Object} location - Location filters.
 */
export async function getLocation(
  payload: any = {},
  state: any = {},
  useFiltered: boolean = false,
): Promise<LocationInfo> {
  const {
    route: { params: routeParams },
    search: lastSearch,
    location: geolocation,
  } = state;
  const placeId = getInput(payload.placeId, routeParams.placeId);
  let address = getInput(payload.address, routeParams.loc, lastSearch.address);
  let latitude = getInput(payload.latitude, routeParams.lat, lastSearch.latitude);
  let longitude = getInput(payload.longitude, routeParams.lon, lastSearch.longitude);
  const bottomRightLat = getInput(
    payload.bottomRightLat,
    routeParams.bottomRightLat,
    lastSearch.bottomRightLat,
  ) || null;
  const bottomRightLon = getInput(
    payload.bottomRightLon,
    routeParams.bottomRightLon,
    lastSearch.bottomRightLon,
  ) || null;
  const topLeftLat = getInput(
    payload.topLeftLat,
    routeParams.topLeftLat,
    lastSearch.topLeftLat,
  ) || null;
  const topLeftLon = getInput(
    payload.topLeftLon,
    routeParams.topLeftLon,
    lastSearch.topLeftLon,
  ) || null;

  // If there is literally no location information from input, route, or last search
  // try using geolocation (loaded in the Location model)
  if (!placeId && !address && !latitude && !longitude) {
    address = geolocation.address;
    latitude = geolocation.latitude;
    longitude = geolocation.longitude;
  }
  const hasCoords = (isNumber(latitude) && isNumber(longitude));

  let result = {
    address,
    latitude: Number(latitude),
    longitude: Number(longitude),
    placeId,
    bottomRightLat: isNumber(bottomRightLat) ? Number(bottomRightLat) : bottomRightLat,
    bottomRightLon: isNumber(bottomRightLon) ? Number(bottomRightLon) : bottomRightLon,
    topLeftLat: isNumber(topLeftLat) ? Number(topLeftLat) : topLeftLat,
    topLeftLon: isNumber(topLeftLon) ? Number(topLeftLon) : topLeftLon,
  };

  // Has coordinates, but needs an address
  if (hasCoords && (!address || address === SEARCH_PARAM_RESET_CODE)) {
    const addr = await getCityStateFromCoords(latitude, longitude, useFiltered);
    result = {
      ...result,
      address: addr,
    };
  } else if (!hasCoords && (address || placeId)) {
    // Has address (or placeId), needs latitude/longitude
    const {
      latitude: lat,
      longitude: long,
    } = await getCoordsForAddress(address, placeId, useFiltered)
      .catch();
    result = {
      ...result,
      latitude: Number(lat),
      longitude: Number(long),
    };
  } else if (!hasCoords && (!address || address === SEARCH_PARAM_RESET_CODE)) {
    // Doesn't have address or lat/lng, use geolocation with geo ip fallback.
    try {
      const geoIpLocation = await getGeoIP();
      const {
        latitude: lat,
        longitude: long,
        city,
        region,
      } = geoIpLocation;

      result = {
        ...result,
        latitude: Number(lat),
        longitude: Number(long),
        address: [
          city,
          region,
        ].filter(Boolean).join(', '),
      };
    } catch (e) {
      nonCriticalException(e);
    }
  }

  const {
    topLeftLat: northernBound,
    topLeftLon: westernBound,
    bottomRightLat: southernBound,
    bottomRightLon: easternBound,
    latitude: newLatitude,
    longitude: newLongitude,
  } = result;

  // If the new location coordinates aren't within the bounding box, clear the bounding box
  // Logic for lat and lon is different because:
  // for latitude, North (topLeftLat) > South (bottomRightLat)
  // for longitude, West (topLeftLon) < East (bottomRightLon)
  if (
    newLatitude > northernBound // latitude is North of Northern bound
    || newLatitude < southernBound // latitude is South of Southern bound
    || newLongitude < westernBound // longitude is West of Western bound
    || newLongitude > easternBound // longitude is East of Eastern bound
  ) {
    result = {
      ...result,
      topLeftLat: null,
      topLeftLon: null,
      bottomRightLat: null,
      bottomRightLon: null,
    };
  }

  return result;
}

/**
 * Gets the current search parameters from the url or from user inputs.
 * @param routeParams
 * @param lastSearch the state from the last search
 * @param inputs
 * @returns {Promise<{
 * date: *,
 * query: *,
 * bottomRightLon: number | null,
 * timeSections: (Array|(function(*, *, *, *, *, *): (undefined))),
 * source: *,
 * topLeftLon: number | null,
 * inMapView: *,
 * bottomRightLat: number | null,
 * topLeftLat: number | null, longitude,
 * sort:  'best' | 'rating' | 'distance' | 'price-asc' | 'price-desc',
 * }> }
 */
function getSearchParamsFromInputs(routeParams, lastSearch, inputs: any = {}) {
  const date = getInput(getDate(inputs.date), getDate(routeParams.date), getDate(lastSearch.date));
  const query = getInput(inputs.query, routeParams.q);
  const source = getInput(inputs.source, routeParams.source);
  const timeSections = getInput(inputs.timeSections, lastSearch.timeSections);
  const topLeftLat = Number(getInput(inputs.topLeftLat, routeParams.topLeftLat)) || null;
  const topLeftLon = Number(getInput(inputs.topLeftLon, routeParams.topLeftLon)) || null;
  const bottomRightLat = Number(getInput(inputs.bottomRightLat, routeParams.bottomRightLat))
    || null;
  const bottomRightLon = Number(getInput(inputs.bottomRightLon, routeParams.bottomRightLon))
    || null;
  const sort = getInput(inputs.sort, routeParams.sort, lastSearch.sort)
    || null;
  const hairType = getInput(inputs.hairType, lastSearch.hairType)
    || null;
  const numberOfDays = getInput(inputs.numberOfDays, lastSearch.numberOfDays)
    || null;

  const {
    inMapView,
  } = routeParams;

  return {
    date,
    query,
    source,
    inMapView,
    timeSections,
    topLeftLat,
    topLeftLon,
    bottomRightLat,
    bottomRightLon,
    sort,
    hairType,
    numberOfDays,
  };
}

/**
 * Gets the search id or builds a new search id.
 * @param rootState
 * @returns {String|*}
 */
function getSearchID(rootState, newSearch, replace = true) {
  const {
    results,
  } = rootState.search;

  const {
    sid: routeSid,
  } = rootState.route.params;

  if (!newSearch) {
    // We know we're just showing the existing results, get search id from url or state.
    return rootState.route.params.sid || rootState.search.id;
  }

  if (newSearch && results.length === 0 && replace && routeSid) {
    // We know we're reloading the page use the sid on the url.
    return routeSid;
  }

  // We know this is a new search, create a new id.
  return generateSearchID();
}

type UpdateSearchParamsPayload = {
  date?: string | number;
  timeSections?: string | number;
  query?: string | number;
  source?: string | number;
  address?: string | number;
  latitude?: string | number;
  longitude?: string | number;
  topLeftLat?: string | number;
  topLeftLon?: string | number;
  bottomRightLat?: string | number;
  bottomRightLon?: string | number;
  sort?: string | number;
  hairType?: string | number;
  numberOfDays?: string | number;
};

export type State = {
  id: string | null;
  source: string;
  loading: boolean;
  inMapView: boolean;
  results: any[];
  date: string | null;
  meta: Record<string, any>;
  query: string | null;
  timeSections: any[];
  availableSlots: number;
  address: string | null;
  latitude: number | null;
  longitude: number | null;
  topLeftLat: number | null;
  topLeftLon: number | null;
  hasNextPage: boolean;
  bottomRightLat: number | null;
  bottomRightLon: number | null;
  sort: string;
  hairType: string | null;
  numberOfDays: number | null;
  scrollDepth: number;
  suggestions: SearchSuggestionData;
  clickedBackButton?: boolean;
  useFiltered?: boolean;
  lastSearchLocation?: {
    address: string | null;
    latitude: number | null;
    longitude: number | null;
  };
};

const DEFAULT_STATE: State = {
  id: null,
  source: '',
  loading: true,
  inMapView: false,
  results: [],
  date: null,
  meta: {},
  query: null,
  timeSections: [],
  availableSlots: 0,
  address: null,
  latitude: null,
  longitude: null,
  topLeftLat: null,
  topLeftLon: null,
  hasNextPage: false,
  bottomRightLat: null,
  bottomRightLon: null,
  sort: SortOptions.BestMatch,
  hairType: null,
  numberOfDays: null,
  scrollDepth: 0,
  suggestions: null,
  clickedBackButton: false,

  useFiltered: true,
  lastSearchLocation: {
    address: null,
    latitude: null,
    longitude: null,
  },
};

const SearchModel = createModel<RootModel>()({
  state: DEFAULT_STATE,

  reducers: {
    setScrollDepth: (
      state,
      payload: { scrollDepth: number },
    ) => {
      const { scrollDepth } = payload;

      return {
        ...state,
        scrollDepth,
      };
    },
    updateResults: (
      state,
      payload: {
        results: any[];
        query?: string;
        meta: Record<string, any>;
        suggestions?: SearchSuggestionData;
      },
      originalProviderId?: number,
    ) => {
      let { results } = state;
      let { query } = payload;

      query = query || state.query;

      recordSearchAppearance(payload.results.map(({ provider_id: providerId }) => providerId));

      const newResults = payload.results.filter(result => (
        !results.find(existing => (
          existing.provider_id === result.provider_id
        ))));

      // Only update the results array if there are new results to add
      if (newResults.length) {
        results = [
          ...results,
          ...newResults,
        ];
      }

      let { availableSlots } = state;
      const oneWeekFromNow = Date.now() + (1000 * 60 * 60 * 24 * 7);

      newResults.forEach(({ availability }) => {
        availability?.results?.forEach?.(({
          date,
          bookable_slots: { online },
        }) => {
          if (new Date(date).getTime() <= oneWeekFromNow) {
            availableSlots += online.length;
          }
        });
      });

      // If new results were not added, then there is no next page of results.
      // It is possible search-api could try to look for additional results and not return any,
      // in which case, newResults.length = 0 and there is no next page.
      const hasNextPage = (payload.meta.total > results.length) && (newResults.length > 0);

      return {
        ...state,
        query,
        results: results
          .filter(
            (result => Number(originalProviderId) !== result.provider_id),
          ),
        hasNextPage,
        availableSlots,
        meta: payload.meta,
        id: payload.meta.id,
        loading: false,
        suggestions: payload.suggestions,
      };
    },

    updateSearchParams: (state, payload: Partial<UpdateSearchParamsPayload>) => {
      const newState = { ...state };
      const searchParamKeys = [
        'date',
        'timeSections',
        'query',
        'source',
        'address',
        'latitude',
        'longitude',
        'topLeftLat',
        'topLeftLon',
        'bottomRightLat',
        'bottomRightLon',
        'sort',
        'hairType',
        'numberOfDays',
      ];

      searchParamKeys.forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (payload.hasOwnProperty(key)) {
          newState[key] = payload[key] === SEARCH_PARAM_RESET_CODE
            ? DEFAULT_STATE[key]
            : payload[key];
        }
      });

      return newState;
    },

    clearResults: (state, payload: { inMapView: boolean }) => {
      const {
        inMapView,
      } = payload;
      return {
        ...state,
        inMapView,
        results: [],
        loading: true,
        availableSlots: 0,
        suggestions: null,
        clickedBackButton: false,
      };
    },

    setLoading: (state, payload: boolean) => ({
      ...state,
      loading: payload,
    }),

    setClickedBackButton: state => ({
      ...state,
      clickedBackButton: true,
    }),

    clearClickedBackButton: state => ({
      ...state,
      clickedBackButton: false,
    }),

    clearCoordinates: state => ({
      ...state,
      latitude: null,
      longitude: null,
      topLeftLat: null,
      topLeftLon: null,
      bottomRightLat: null,
      bottomRightLon: null,
    }),

    setResults: (state, payload: any[]) => ({
      ...state,
      results: payload,
    }),

    setSuggestions: (state, payload: SearchSuggestionData) => ({
      ...state,
      suggestions: payload,
    }),

    setSearchId: (state, id: string) => ({
      ...state,
      id,
    }),

    setHairTypeFilter: (state, hairType: string) => ({
      ...state,
      hairType,
    }),

    setUseFiltered: (state, useFiltered: boolean) => ({
      ...state,
      useFiltered,
    }),

    setLastSearchLocation: (state, payload: any) => ({
      ...state,
      lastSearchLocation: {
        address: payload.address,
        latitude: payload.latitude,
        longitude: payload.longitude,
      },
    }),
  },

  effects: dispatch => ({
    searchAll: async (): Promise<void> => {
      dispatch.search.updateFilters({
        date: moment(new Date()).format(DATE_FORMAT),
        numberOfDays: 15,
      });
    },

    loadLastSearchLocation: async (): Promise<void> => {
      const lastSearchLocation = await getItem(LAST_SEARCH_DESKTOP_LOCATION);
      if (typeof lastSearchLocation === 'object' && lastSearchLocation !== null) {
        dispatch.search.setLastSearchLocation(lastSearchLocation);
      }
    },

    updateFilters: async (
      payload: Partial<UpdateSearchParamsPayload>,
      rootState,
    ): Promise<any[]> => {
      const {
        applyResultAvailability,
        // eslint-disable-next-line global-require
      } = require('../modules/consumer/search/Search');

      const promises = [];
      promises.push(
        dispatch.search.updateSearchParams(payload),
      );

      promises.push(
        dispatch.search.setScrollDepth({ scrollDepth: 0 }),
      );

      const hasDateChanged = isValueUpdated(rootState.search.date, payload.date);
      const hasNumberOfDaysChanged = isValueUpdated(
        rootState.search.numberOfDays,
        payload.numberOfDays,
      );

      // Only need to fetch new availability if the date has changed
      if (hasDateChanged || hasNumberOfDaysChanged) {
        dispatch.search.setLoading(true);
        try {
          const availabilities = await applyResultAvailability(
            rootState.search.results,
            payload.date,
            payload.numberOfDays,
          );

          const newResults = rootState.search.results.map((salon, i) => {
            const availability = availabilities[i];

            return {
              ...salon,
              availability,
            };
          });
          promises.push(
            dispatch.search.setResults(newResults),
            dispatch.search.setSuggestions(rootState.search.suggestions),
          );
        } catch (e) {
          nonCriticalException(e);
        } finally {
          dispatch.search.setLoading(false);
        }
      }
      return Promise.all(promises);
    },
    // The same as updateFilter, but with an extra trackSearch call
    updateFiltersAndTrack: async (
      payload,
      rootState,
    ): Promise<any[]> => {
      const {
        applyResultAvailability,
        // eslint-disable-next-line global-require
      } = require('../modules/consumer/search/Search');

      const promises = [];
      promises.push(
        dispatch.search.updateSearchParams(payload),
      );

      promises.push(
        dispatch.search.setScrollDepth({ scrollDepth: 0 }),
      );

      const hasDateChanged = isValueUpdated(rootState.search.date, payload.date);
      const hasNumberOfDaysChanged = isValueUpdated(
        rootState.search.numberOfDays,
        payload.numberOfDays,
      );

      // Only need to fetch new availability if the date has changed
      if (hasDateChanged || hasNumberOfDaysChanged) {
        dispatch.search.setLoading(true);
        try {
          const availabilities = await applyResultAvailability(
            rootState.search.results,
            payload.date,
            payload.numberOfDays,
          );

          const newResults = rootState.search.results.map((salon, i) => {
            const availability = availabilities[i];

            return {
              ...salon,
              availability,
            };
          });

          const { query } = payload;
          const { searchID } = payload;
          promises.push(
            dispatch.search.setResults(newResults),
            dispatch.search.setSuggestions(rootState.search.suggestions),
            trackSearch(DEFAULT_TRACKING_EVENT_NAME, {
              query,
              results: newResults,
              searchID,
              total: rootState.search.meta?.total,
              from: rootState.search.meta?.from || 0,
              origin,
              ...payload.searchParams,
            }, payload.testFlags),
          );
        } catch (e) {
          nonCriticalException(e);
        } finally {
          dispatch.search.setLoading(false);
        }
      }
      return Promise.all(promises);
    },
    /**
     * Runs a new search, if the params have changed since the last search.
     * @param payload
     * @param rootState
     * @returns {Promise<any>|*}
     */
    search: async (payload: Record<string, any>, rootState): Promise<any> => {
      if (!rootState?.route?.name?.includes?.(SEARCH_ROUTE)) {
        return Promise.resolve();
      }

      const {
        replace = true,
      } = payload;
      let searchID = null;
      const previousParams = getParamsFromState(rootState.search);
      const basicSearchParams = getSearchParamsFromInputs(
        rootState.route.params,
        rootState.search,
        payload,
      );

      return getLocation(payload, rootState, rootState.search.useFiltered)
        .then(location => {
          const searchParams = {
            ...basicSearchParams,
            ...location,
          };
          unslugifySearchParams(searchParams);
          let {
            query,
          } = searchParams;
          const {
            inMapView,
          } = searchParams;

          // We want to retire the query for lashes and redirect it to
          // eyelashes.
          if (query?.toLowerCase() === 'lashes') {
            query = 'eyelashes';
          }

          const hasDateChanged = isValueUpdated(rootState.search.date, searchParams.date);
          const hasTimeChanged = isValueUpdated(
            rootState.search.timeSections,
            searchParams.timeSections,
          );
          const hasHairTypeFilterChanged = isValueUpdated(
            rootState.search.hairType,
            searchParams.hairType,
          );
          const hasNumberOfDaysFilterChanged = isValueUpdated(
            rootState.search.numberOfDays,
            searchParams.numberOfDays,
          );
          const filtersUpdated = hasDateChanged
            || hasTimeChanged || hasHairTypeFilterChanged || hasNumberOfDaysFilterChanged;

          const newSearch = isNewSearch(previousParams, searchParams);
          searchID = getSearchID(rootState, newSearch || filtersUpdated, replace);

          let searchResponse;
          let assignments; let
            testFlags;

          return Promise.resolve()
            .then(async () => {
              ({
                assignments,
                testFlags,
              } = await generateTestFlags(SEARCH_TEST_CONFIG));

              if (newSearch) {
                dispatch.search.clearResults({ inMapView });
                dispatch.search.setSearchId(searchID);
                dispatch.search.setScrollDepth({ scrollDepth: 0 });

                const searchQuery: SearchConfig = {
                  query,
                  date: searchParams.date,
                  timeSections: searchParams.timeSections,
                  lat: searchParams.latitude,
                  lon: searchParams.longitude,
                  loc: searchParams.address,
                  topLeftLat: searchParams.topLeftLat,
                  topLeftLon: searchParams.topLeftLon,
                  bottomRightLat: searchParams.bottomRightLat,
                  bottomRightLon: searchParams.bottomRightLon,
                };

                if (searchParams.sort) {
                  searchQuery.sort = searchParams.sort;
                }

                dispatch.search.updateSearchParams(searchParams);

                dispatch.abTest.onTestsAssigned(assignments);

                searchResponse = await createSearch({
                  config: {
                    ...searchQuery,
                    from: 0,
                  },
                  testFlags,
                  meta: {
                    id: searchID,
                    source: payload.source,
                    origin: payload.origin,
                    cookie_id: rootState.user.ss_tracking_cookie,
                  },
                });

                await setItem(
                  LAST_SEARCH_DESKTOP_LOCATION,
                  { ...location, address: searchResponse.filters.loc },
                );
                await dispatch.search.onSearchResults({
                  results: searchResponse,
                  query,
                });
                return null;
              }
              if (filtersUpdated) {
                searchID = getSearchID(rootState, true, true);
                dispatch.search.setSearchId(searchID);
                return Promise.all([
                  dispatch.search.updateFiltersAndTrack({
                    date: searchParams.date,
                    timeSections: searchParams.timeSections,
                    sort: searchParams.sort,
                    hairType: searchParams.hairType,
                    numberOfDays: searchParams.numberOfDays,
                    query,
                    searchID,
                    origin: payload.origin,
                    searchParams,
                    testFlags,
                  }),
                ]);
              }
              return null;
            })
            .then(() => {
              // When re-forming the route params, make sure to slugify the query and address
              // for SEO purposes (i.e. search/<location>/<query>).
              const paramsPayload = {
                params: {
                  inMapView,
                  sid: searchID,
                  source: searchParams.source,
                  // ensure the path these route params will build is SEO
                  // compliant (no URL encoding)
                  q: toSlugCase(query),
                  date: searchParams.date,
                  // ensure the path these route params will build is SEO
                  // compliant (no URL encoding)
                  loc: toSlugCase(searchParams.address),
                  lat: searchParams.latitude,
                  lon: searchParams.longitude,
                  topLeftLat: searchParams.topLeftLat,
                  topLeftLon: searchParams.topLeftLon,
                  bottomRightLat: searchParams.bottomRightLat,
                  bottomRightLon: searchParams.bottomRightLon,
                  sort: basicSearchParams.sort,
                  app: true,
                },
                replace,
                notify: true,
              };
              return dispatch.route.addParams(paramsPayload);
            }).then(() => {
              const searchPropsForTracking = searchResponse || rootState.search;
              trackSearch(DEFAULT_TRACKING_EVENT_NAME, {
                query,
                results: searchPropsForTracking.results,
                latitude: searchParams.latitude,
                longitude: searchParams.longitude,
                searchID,
                total: searchPropsForTracking?.meta?.total,
                source: payload.source,
                origin: payload.origin,
                from: rootState.search.meta?.from || 0,
                selectedQuery: searchPropsForTracking?.meta?.selected_query,
                topLeftLat: searchParams.topLeftLat,
                topLeftLon: searchParams.topLeftLon,
                bottomRightLat: searchParams.bottomRightLat,
                bottomRightLon: searchParams.bottomRightLon,
                date: searchParams.date,
                timeSections: searchParams.timeSections,
                sort: basicSearchParams.sort,
                earnedBoostWeight: searchPropsForTracking?.meta?.earnedBoostWeight,
                newProBoostWeight: searchPropsForTracking?.meta?.newProBoostWeight,
                newProSearchBoost: searchPropsForTracking?.meta?.newProSearchBoost,
                suggestions: searchPropsForTracking.suggestions,
                repeatedSearch: !searchResponse,
              }, testFlags || {});
            });
        })
        .catch(e => {
          nonCriticalException(e, { where: 'Search' });
          dispatch.search.setLoading(false);

          // Still set the search ID, otherwise we will not render the page
          if (searchID) {
            return dispatch.route.addParams({
              params: { sid: searchID },
              replace,
              notify: false,
            });
          }
          return undefined;
        });
    },

    /**
     * Loads the next page of results, does not change or alter the search parameters.
     * @param payload
     * @param rootState
     * @returns {*}
     */
    nextPage: async (payload, rootState): Promise<any> => {
      if (rootState.route.name.includes(SEARCH_ROUTE) && !rootState.search.loading) {
        const {
          results,
        } = rootState.search;
        const {
          sid: id,
        } = rootState.route.params;
        const searchParams = getParamsFromState(rootState.search);

        if (results.length === 0) {
          throw new Error('Cannot load next page if no search has been performed!');
        } else {
          const searchQuery = {
            date: searchParams.date,
            lat: searchParams.latitude,
            lon: searchParams.longitude,
            loc: searchParams.address,
            topLeftLat: searchParams.topLeftLat,
            topLeftLon: searchParams.topLeftLon,
            timeSections: searchParams.timeSections,
            bottomRightLat: searchParams.bottomRightLat,
            bottomRightLon: searchParams.bottomRightLon,
            query: searchParams.query,
            sort: searchParams.sort,
            hairType: searchParams.hairType,
            numberOfDays: searchParams.numberOfDays,
          };

          const timeSlots = searchParams.timeSections?.reduce?.((slots, section) => [
            ...slots,
            ...getTimeSlotsForTimeRange(section.startTime, section.endTime),
          ], []) || [];
          const filteredResults = filterResults(results, searchParams.date, timeSlots);

          dispatch.search.updateSearchParams(searchParams);

          const {
            assignments,
            testFlags,
          } = await generateTestFlags(SEARCH_TEST_CONFIG);

          await dispatch.abTest.onTestsAssigned(assignments);

          return createSearch({
            config: {
              ...searchQuery,
              size: PAGE_SIZE,
              from: results.length,
            },
            testFlags,
            meta: {
              id,
              source: payload.source,
              origin: payload.origin,
              trackingFrom: filteredResults.length,
              cookie_id: rootState.user.ss_tracking_cookie,
            },
          })
            .then(searchResponse => dispatch.search.updateResults(searchResponse));
        }
      }

      return Promise.resolve();
    },

    /**
     * Determines if the search page is currently in the map view or not.
     * @param inMapView
     * @param rootState
     * @returns {Promise<void>}
     */
    setInMapView: async (inMapView, rootState) => {
      if (rootState.route.name.includes(SEARCH_ROUTE)) {
        await dispatch.route.addParams({
          params: {
            inMapView,
          },
        });
      }
    },

    /**
     * Called once we get fresh search results from the API
     * @param results
     * @param query
     * @param rootState
     * @return {Promise<undefined|*>}
     */
    onSearchResults: async ({
      results,
      query,
    }: {
      results: {
        results: any[];
        query?: string;
        meta: Record<string, any>;
        id: string;
      };
      query: string;
    }, rootState) => {
      if (rootState.search.id === results.id) {
        dispatch.search.updateResults(results, rootState.route.params.original);
        return dispatch.recentSearches.push({ query });
      }

      return undefined;
    },
  }),

  selectors: (
    slice,
    createSelector,
  ): ModelSelectorFactories<RootModel, Record<string, never>> => ({
    // When the selected timeSections changes in the store,
    // get the corresponding time slots for those selections
    getTimeSlots() {
      return createSelector(
        slice(state => state.timeSections),
        (timeSections): number[] => timeSections?.reduce?.((slots, section) => [
          ...slots,
          ...getTimeSlotsForTimeRange(section.startTime, section.endTime),
        ], []) || [],
      );
    },

    // Any time the results, selected date, or selected time sections changes,
    // recalculate the results that are visible to the user
    getFilteredResults(models) {
      return createSelector(
        slice(state => state.results),
        slice(state => state.date),
        (models as any).search.getTimeSlots,
        slice(state => state.hairType),
        (
          results,
          date,
          timeSlots: number[],
        ): ISearchResult[] => filterResults(
          results,
          date,
          timeSlots,
        ),
      );
    },

    getSuggestions() {
      return createSelector(
        slice(state => state.suggestions),
        (
          suggestions,
        ): SearchSuggestionData => (suggestions),
      );
    },

    // return a search location if one exists, otherwise return the userLocation
    searchLocation(models): Selector<RootState, LocationRecord> {
      return createSelector(
        slice(state => state.address),
        slice(state => state.latitude),
        slice(state => state.longitude),
        models.location.userLocation,
        (
          searchAddress,
          searchLatitude,
          searchLongitude,
          userLocation,
        ): LocationRecord => {
          if (searchAddress) {
            return {
              address: searchAddress,
              latitude: searchLatitude,
              longitude: searchLongitude,
            };
          }
          return {
            address: userLocation.address,
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          };
        },
      );
    },
  }),
});

export default SearchModel;
