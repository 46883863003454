// @ts-strict-ignore
import moment, { Moment } from 'moment';
import { getClientCount } from '../../../Api';
import { DateTimeStamp } from '../../../../types/dateTime';
import SimpleCache from '../../../SimpleCache';

export const shouldShowClientNotificationCache = new SimpleCache(300);

export interface AddClientNotification {
  type: 'add_client_notification';
  provider_id: number;
  creation_time: Moment;
  uuid: 'add-client-notification';
  persistent: true;
}

/**
 * Checks if it has been one day since provider has joined StyleSeat
 * @param {string} profileCreationTime - creation time of when provider joined StyleSeat
 * @return {boolean}
 */
export const isAfterDayOne = profileCreationTime => (
  moment.utc().diff(moment.utc(profileCreationTime), 'days') >= 1
);

/**
 * Checks to see if the add client notification should be shown in notification dropdown
 * Will show if after day one of pro joining and pro has no clients
 * @param {string} profileCreationTime - creation time of when provider joined StyleSeat
 * @param {number} providerId
 */
export const shouldShowAddClientNotification = async (profileCreationTime, providerId) => {
  const afterDayOne = isAfterDayOne(profileCreationTime);

  if (afterDayOne) {
    const clientsCount = await getClientCount(providerId, shouldShowClientNotificationCache);
    return clientsCount === 0;
  }

  return false;
};

/**
 * creates the add notification notification
 * @param {string} profileCreationTime - creation time of when provider joined StyleSeat
 * @param {number} providerId
 */
export const createAddClientNotification = (
  profileCreationTime: DateTimeStamp,
  providerId: number,
): AddClientNotification => {
  const creationTime = moment.utc(profileCreationTime).add(1, 'days');
  return {
    type: 'add_client_notification',
    provider_id: providerId,
    creation_time: creationTime,
    uuid: 'add-client-notification',
    persistent: true,
  };
};
