// @ts-strict-ignore
import analytics from '.';
import { getIdfaPlugin } from '../CordovaPlugins';
import { IDFATrackingPermission } from '../CordovaPlugins.types';

/**
 * useAttPrompt will return an async function that will prompt an iOS App user to allow or deny app
 * tracking if tracking permission is not determined.
 */

export const checkAttPermission = async () => {
  const IdfaPlugin = getIdfaPlugin();
  if (!IdfaPlugin) return;

  const { trackingLimited, trackingPermission } = await IdfaPlugin.getInfo();

  if (!trackingLimited) {
    analytics.track('ios_att_allowed');
    return;
  }

  if (trackingPermission === IdfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
    try {
      const response = await IdfaPlugin.requestPermission();
      analytics.track('ios_att_prompted', {
        // toUpperCase called to maintain backwards compatibility
        // after fixing enum linting issue
        trackingPermission: IDFATrackingPermission[response]?.toUpperCase(),
      });
    } catch {
      // Device is on iOS < 14, so we don't care
      analytics.track('ios_att_unsupported');
    }
    return;
  }

  analytics.track('ios_att_denied');
};
