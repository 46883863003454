import moment from 'moment';

import { API_ROOT } from '../../../config';
import { ssFetchJSON } from '../../ssFetch';
import { ServicesCache } from '../../../api/Providers/Services';

export type BookingStatsAPIResponse = {
  pro_booked: number;
  total_booked: number;
};

export const getProviderAppointmentStatsForCurrentMonth = async (
  providerId: number,
): Promise<BookingStatsAPIResponse> => {
  const currentMonth = moment().format('MM');
  const currentYear = moment().format('YYYY');
  const firstDayOfMonth = '01';
  const url = new URL(
    `${API_ROOT}/api/v2/providers/${providerId}/appointments/stats/${currentYear}-${currentMonth}-${firstDayOfMonth}`,
  );

  return ssFetchJSON(
    url.toString(),
    { ssCache: ServicesCache },
  );
};
