import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { selectors } from '../store/CurrentUser.model';
import type { RootState } from '../store/models';

const InitUserComponents = loadable(() => import('./shared/InitUserComponents'), {
  resolveComponent: mod => mod.InitUserComponents,
});
const InitProviderComponents = loadable(
  () => import('./provider/InitProviderComponents/InitProviderComponents'),
  { resolveComponent: mod => mod.InitProviderComponents },
);

const EXCLUDED_ROUTES = [
  'admin_users',
  'admin_providers',
];

/**
 * The Global components are a set of components that are needed on EVERY page of the application
 * These are snackbars, modals and/or global banners that may need to show throughout different
 * user flows.
 */
const InitGlobalComponents = () => {
  const isLoggedIn = useSelector(selectors.isLoggedIn);
  const isProvider = useSelector(selectors.isProvider);
  const isExcludedRoute = useSelector<RootState, boolean>(
    state => EXCLUDED_ROUTES.includes(state.route?.name),
  );

  const shouldRenderUserComponents = useMemo(() => (
    isLoggedIn && !isExcludedRoute
  ), [
    isLoggedIn,
    isExcludedRoute,
  ]);

  const shouldRenderProviderComponents = useMemo(() => (
    isProvider && !isExcludedRoute
  ), [
    isProvider,
    isExcludedRoute,
  ]);

  return (
    <React.Fragment>
      {/* Add components that must be on EVERY page regardless of user role  here */}
      {/* For role specific components, add them to appropriate subcomponent here
      to load dynamically */}
      {shouldRenderUserComponents ? (
        <InitUserComponents />
      ) : null}

      {shouldRenderProviderComponents ? (
        <InitProviderComponents />
      ) : null}
    </React.Fragment>
  );
};

export default InitGlobalComponents;
