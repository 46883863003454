import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useLinkTo from '../../../hooks/useLinkTo';
import analytics from '../../../modules/analytics';
import { brazeTracker } from '../../../modules/analytics/BrazeTracker';
import { getIsApp } from '../../../modules/AppInfo';
import { mapGenericParams } from '../../../modules/appsFlyer';
import { openExternalUrl } from '../../../modules/openExternalUrl';
import type {
  BrazeCard,
  BrazeCardEvent,
  BrazeContentCardFeedContextType,
  BrazeContentCardFilterFn,
  BrazeFeedFilter,
} from './types';

export const BrazeContentCardFeedContext = createContext<BrazeContentCardFeedContextType>({
  cards: [],
  markCardAsViewed: () => {},
  selectCard: () => {},
});

interface BrazeContentCardFeedProviderProps {
  typeFilter: BrazeFeedFilter;
  children: ReactNode | ReactNode[];
}

const getUniqueCards = (cards: BrazeCard[]): BrazeCard[] => {
  const uniqueCards = new Map<string | undefined, BrazeCard>();
  [...cards].reverse().forEach(card => {
    uniqueCards.set(card?.extras?.bannerId || card.id, card);
  });
  return Array.from(uniqueCards.values());
};

export const BrazeContentCardFeedProvider = ({
  typeFilter,
  children,
}: BrazeContentCardFeedProviderProps) => {
  const subscriptionId = useRef<string | undefined>();
  const isApp = getIsApp();
  const linkTo = useLinkTo();

  const [
    cards,
    setCards,
  ] = useState<BrazeCard[]>(() => ([]));

  const markCardAsViewed = React.useCallback(({ card }: BrazeCardEvent) => {
    brazeTracker.logContentCardImpression(card);
  }, []);

  const handleCardPress = React.useCallback(({ card }: BrazeCardEvent) => {
    const {
      route,
      routeParams,
    } = card.extras || {};

    analytics.track('Content Card Clicked', {
      contentCardType: card?.extras?.type,
      contentCardId: card.id,
      bannerId: card?.extras?.bannerId,
    });

    brazeTracker.logContentCardClick(card);

    if (isApp && route) {
      return linkTo(route, mapGenericParams(routeParams));
    }

    if (!isApp && card.extras.url) {
      return openExternalUrl(card.extras.url, '_self');
    }
  }, [
    isApp,
    linkTo,
  ]);

  const filter = useMemo<BrazeContentCardFilterFn>(() => {
    if (typeof typeFilter === 'string') {
      return (card: BrazeCard) => card.extras?.type === typeFilter;
    }
    return typeFilter;
  }, [typeFilter]);

  const handleFeedUpdate = useCallback((update: { cards: BrazeCard[]; lastUpdated: string }) => {
    const newCards = [...(update?.cards || [])];
    const filteredCards = getUniqueCards(newCards)
      .filter(c => !c.dismissed)
      .filter(filter)
      // Sort by the content cards' "order" key/value pair.
      // Cards without an "order" value appear last in the list.
      .sort((a, b) => {
        const aOrder = parseInt(a.extras?.order, 10) ?? Number.MAX_VALUE;
        const bOrder = parseInt(b.extras?.order, 10) ?? Number.MAX_VALUE;
        return (aOrder - bOrder);
      });
    setCards(filteredCards);
  }, [filter]);

  useEffect(() => {
    subscriptionId.current = brazeTracker
      // @ts-expect-error
      .subscribeToContentCardsUpdates(handleFeedUpdate);

    return () => {
      if (subscriptionId.current) {
        brazeTracker.removeSubscription(subscriptionId.current);
        subscriptionId.current = undefined;
      }
    };
  }, [handleFeedUpdate]);

  const context = useMemo<BrazeContentCardFeedContextType>(() => ({
    cards,
    markCardAsViewed,
    selectCard: handleCardPress,
  }), [
    cards,
    markCardAsViewed,
    handleCardPress,
  ]);

  return (
    <BrazeContentCardFeedContext.Provider value={context}>
      {children}
    </BrazeContentCardFeedContext.Provider>
  );
};
