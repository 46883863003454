// @ts-strict-ignore
import { createModel } from '@rematch/core';
import {
  ChatResponsiveness,
  ChatResponsivenessBuckets,
  getChatResponsiveness,
} from '../api/Providers/Providers';
import { CHAT_GENERAL_SWITCH, PROS_RESPONSIVENESS } from '../modules/chat/constants';
import type { RootModel } from './models';

type ChatState = {
  unreadMessages: number;
  chatEntryViewedCount: number;
  hasUpdatedUnreadCounter: boolean;
  clientHasSeenInboxIcon: boolean;
  proChatResponsivenessById: {
    [key: number]: ChatResponsivenessBuckets;
  };
};

const DEFAULT_STATE: ChatState = {
  unreadMessages: 0,
  chatEntryViewedCount: 0,
  clientHasSeenInboxIcon: false,
  hasUpdatedUnreadCounter: false,
  proChatResponsivenessById: {},
};

const getResponsivenessFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(PROS_RESPONSIVENESS)) || {};
  } catch {
    return {};
  }
};

const Chat = createModel<RootModel>()({
  name: 'chat',
  state: DEFAULT_STATE,
  reducers: {
    onChatResponsivenessLoaded: (state: ChatState, chatResponsiveness: ChatResponsiveness) => ({
      ...state,
      proChatResponsivenessById: {
        ...state.proChatResponsivenessById,
        [chatResponsiveness.provider_id]: chatResponsiveness.bucket,
      },
    }),
    updateUnreadCounter: (state: ChatState, newUnreadNumber: number) => ({
      ...state,
      unreadMessages: newUnreadNumber,
      hasUpdatedUnreadCounter: true,
    }),
    onClientHasSeenInboxIcon: (state: ChatState) => ({
      ...state,
      clientHasSeenInboxIcon: true,
    }),
    onChatEntryViewed: (state: ChatState) => ({
      ...state,
      chatEntryViewedCount: state.chatEntryViewedCount + 1,
    }),
    onChatViewed: (state: ChatState) => ({
      ...state,
      chatEntryViewedCount: 0,
    }),
  },
  effects: dispatch => ({
    loadProResponsiveness: async (payload: { providerId: number }):
    Promise<void> => {
      const cachedProsResponsiveness = getResponsivenessFromStorage();
      const proResponsiveness = cachedProsResponsiveness[payload.providerId];
      const proResponsivenessCacheDate = proResponsiveness?.cacheDate;
      if (proResponsivenessCacheDate && proResponsivenessCacheDate === new Date().getDay()) {
        dispatch.chat.onChatResponsivenessLoaded({
          bucket: proResponsiveness.bucket,
          provider_id: proResponsiveness.provider_id,
        });
        return;
      }
      let chatResponsivenessResult: ChatResponsiveness;
      try {
        chatResponsivenessResult = await getChatResponsiveness(payload.providerId);
      } catch (e) {
        chatResponsivenessResult = {
          provider_id: payload.providerId,
          bucket: ChatResponsivenessBuckets.Default,
        };
      }
      const updatedCache = {
        ...cachedProsResponsiveness,
        [payload.providerId]: { ...chatResponsivenessResult, cacheDate: new Date().getDay() },
      };
      localStorage.setItem(PROS_RESPONSIVENESS, JSON.stringify(updatedCache));
      dispatch.chat.onChatResponsivenessLoaded(chatResponsivenessResult);
    },
  }),
  selectors: (slice, createSelector, hasProps) => ({
    selectIsLoggedProChatEnabled: models => createSelector(
      models.providers.activeProvider,
      models.abTest.selectIsAssignmentEnabled({ switchOrFlag: CHAT_GENERAL_SWITCH }),
      (activeProvider, isChatFeatureEnabled) => {
        if (!activeProvider) {
          return undefined;
        }

        if (!isChatFeatureEnabled || !activeProvider.is_chat_enabled) {
          return false;
        }

        return true;
      },
    ),
    selectIsProChatEnabledById: hasProps((models, providerId: number) => createSelector(
      models.abTest.selectIsAssignmentEnabled({ switchOrFlag: CHAT_GENERAL_SWITCH }),
      models.providers.providerById(providerId),
      models.proProfile.forPro({ providerId }),
      (isChatFeatureEnabled, provider, proProfile) => {
        const isProChatEnabled = provider?.is_chat_enabled || proProfile?.profile?.is_chat_enabled;
        return isChatFeatureEnabled && isProChatEnabled;
      },
    )),
    selectProChatResponsiveness: hasProps((_, providerId: number) => createSelector(
      slice(state => state.proChatResponsivenessById),
      proResponsivenessById => proResponsivenessById[providerId],
    )),
  }),
});

export default Chat;
